import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from '../../lib/secure_reactsession';
import { useSearchParams, useNavigate } from "react-router-dom";
import {InformationTilesBox} from "../themes/userq/InformationTilesBox/information_tiles_box"
import { Text } from '../themes/userq/Text/Text';
import { Input } from '../themes/userq/Input/Input';
import { DropdownComponent } from '../themes/userq/Dropdown/Dropdown';
import { CheckBox } from '../themes/userq/CheckBox/CheckBox';
import { Button } from '../themes/userq/Button/Button';
import { Icon } from '../themes/userq/Icon/Icon';
import { PhoneNumberInput } from '../themes/userq/PhoneNumberInput/PhoneNumberInput';
import { getProfile, updateStep1, updateStep2, updateWelcomeMessage } from "../../services/user.js";
import { isValidPhoneNumber } from 'react-phone-number-input'
import { LoadingIcon } from '../themes/userq/Loader/loadingIcon';
import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../data/settings';
import { PageProgressBar } from '../themes/userq/ProgressBar/page_progress_bar';
import { RadioButton } from '../themes/userq/RadioButton/RadioButton';
import { CountryDropdownSingle } from '../themes/userq/CountryDropdown/CountryDropdownSingle';
import {InputCalendar} from '../themes/userq/InputCalendar/InputCalendar';
import { LayoutTester } from '../themes/userq/Layouts/layout_tester';
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from '../themes/userq/IncDecCounter/IncDecCounter';
import {TesterSlider} from "./tester_slider";
import { SignUpInformationalCard } from '../themes/userq/SignUpInformationalCard/sign_up_informational_card';


export default function Step1() {

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const [startPosition, setStartPosition] = useState(0);

    const navigate = useNavigate();

    let jobRolesSelect = useRef();

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({ captcha: '',  phone_number: "",  whatsapp_phone_number:"", whatsapp_checkbox:true, first_name: '', last_name: '', company_name: '', job_role: '', job_role_other: '', nationality:'', spoken_language:[], people:1, newsletter: true,  role: 'Tester' });

    const [formErrors, setFormErrors] = useState({ phone_number: null, whatsapp_phone_number: null, first_name: null, last_name: null, password: null, company_name: null, job_role: null, job_role_other: null, job_role_other: null, terms: null });

    const [employmentFieldsReadOnly, setEmploymentFieldsReadOnly] = useState(false);

    const employmentOptions = [
		{ label: "Student", value: "Student" },
		{ label: "Self employed", value: "Self employed" },
		{ label: "Unemployed", value: "Unemployed" },
		{ label: "Private employee", value: "Private employee" },
		{ label: "Public employee", value: "Public employee" },
		{ label: "Homemaker", value: "Homemaker" },
		{ label: "Retired", value: "Retired" },
	];

	const industryOptions = [
        {
            label: "Accountancy, banking and finance",
            value: "Accountancy, banking and finance",
        },
        {
            label: "Armed force, defence & military",
            value: "Armed force, defence & military",
        },
        {
            label: "Aviation",
            value: "Aviation",
        },
        {
            label: "Business, consulting and management",
            value: "Business, consulting and management",
        },
        {
            label: "Charity and voluntary work",
            value: "Charity and voluntary work",
        },
        { label: "Creative arts and design", value: "Creative arts and design" },
        { label: "Digital", value: "Digital" },
        { label: "Energy and utilities", value: "Energy and utilities" },
        {
            label: "Engineering and manufacturing",
            value: "Engineering and manufacturing",
        },
        {
            label: "Environment and agriculture",
            value: "Environment and agriculture",
        },
        {
            label: "Food and beverage",
            value: "Food and beverage",
        },
        { label: "Healthcare", value: "Healthcare" },
        {
            label: "Hospitality and events management",
            value: "Hospitality and events management",
        },
        { label: "Information technology", value: "Information technology" },
        { label: "Law", value: "Law" },
        {
            label: "Law enforcement and security",
            value: "Law enforcement and security",
        },
        {
            label: "Leisure, sport and tourism",
            value: "Leisure, sport and tourism",
        },
        {
            label: "Marketing, advertising and PR",
            value: "Marketing, advertising and PR",
        },
        { label: "Media, Internet & telecommunications", value: "Media, Internet & telecommunications" },
        { label: "Property and construction", value: "Property and construction" },
        { label: "Nonprofit", value: "Nonprofit" },
        {
            label: "Public services and administration",
            value: "Public services and administration",
        },
        { label: "Real Estate", value: "Real Estate" },
        { label: "Recruitment and HR", value: "Recruitment and HR" },
        { label: "Retail", value: "Retail" },
        { label: "Sales", value: "Sales" },
        {
            label: "Science and pharmaceuticals",
            value: "Science and pharmaceuticals",
        },
        {
            label: "Security",
            value: "Security",
        },
        { label: "Social care", value: "Social care" },
        {
            label: "Teacher training and education",
            value: "Teacher training and education",
        },
        {
            label: "Transport and logistics",
            value: "Transport and logistics",
        },
        
         { label: "Other (please specify)", value: "Other (please specify)" },
    ];

	const departmentOptions = [
		{ label: "Finance", value: "Finance" },
		{ label: "HR", value: "HR" },
		{ label: "Admin", value: "Admin" },
		{ label: "Design & research", value: "Design & research" },
		{ label: "IT & tech", value: "IT & tech" },
		{ label: "Branding & marketing", value: "Branding & marketing" },
		{ label: "Sales", value: "Sales" },
		{ label: "Purchase", value: "Purchase" },
		{ label: "Legal", value: "Legal" },
		{ label: "Other (please specify)", value: "Other (please specify)" },
	];
    
	const incomelevelOptions = [
    { label: "$0 - No income", value:"$0 - No income"},
    { label:"$1 - $250", value:"$1 - $250"},
    { label: "$251 - $500", value: "$251 - $500"},
    { label: "$501 - $1,500", value: "$501 - $1,500" },
		{ label: "$1,501 - $2,500", value: "$1,501 - $2,500" },
		{ label: "$2,501 - $5,000", value: "$2,501 - $5,000" },
		{ label: "$5,001 - $7,500", value: "$5,001 - $7,500" },
		{ label: "$7,501 - $10,000", value: "$7,501 - $10,000" },
		{ label: "$10,001 - $12,500", value: "$10,001 - $12,500" },
		{ label: "$12,501 - $15,000", value: "$12,501 - $15,000" },
		{ label: "Over $15,000", value: "Over $15,000" },
	];

    useEffect(() => {
        window.animate();
        document.title = "Step 2 | "+process.env.REACT_APP_NAME;

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response=> {
            setLoading(false);

            if(response.success){

                /*if(response.data.user.completed_step>=1){

                    var user =  ReactSession.get("user");

                    user["completed_step"] = response.data.user.completed_step;

                    user["welcome_message_shown"] = response.data.user.welcome_message_shown;

                    ReactSession.set("user", user);
                    //navigate('/dashboard');
                }*/
                if(response.data.user.completed_step<1){
                    navigate("/tester/profile/step1")
                }
                setFormValues({...formValues,
                    employment: response.data.user.employment ? response.data.user.employment : '',
                    industry: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ? "" : response.data.user.industry ? response.data.user.industry : '',
                    industry_other: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ?"" : response.data.user.industry_other ? response.data.user.industry_other : '',
                    department: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ?"": response.data.user.department ? response.data.user.department : '',
                    department_other:["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ?"": response.data.user.department_other ? response.data.user.department_other : '',
                    income_slab: response.data.user.income_slab ? response.data.user.income_slab : '',
                    people: response.data.user.people ? response.data.user.people : 1,
                })
                if(["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment)){

                    setEmploymentFieldsReadOnly(true);

                    
                } else {
                    setEmploymentFieldsReadOnly(false);
                }
            }
        });


    }, []);
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    
    const completeProfile = () => {
                
    
    
        setFormLoading(true);

        const token = ReactSession.get("token");

        updateStep2(formValues, token)
        .then(response=>{
            setFormLoading(false);
        
            if(response.success){
                
                let user = ReactSession.get("user");

                user.completed_step = response.user.completed_step;


                ReactSession.set("user",user);

                
                navigate("/tester/profile/step3");

            } else {
                showError(response.message);
            }
        })
            

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value, inline) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = resetFormErrors();
        }

        
        var firsterrorid = null;

        if(!key || key=="employment"){

            var field_value = key  ? value : formValues.employment;
            
            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    employment: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "employment" : firsterrorid;
            }
            if(["Student","Unemployed","Homemaker","Retired"].includes(field_value)){
                form_errors = {
                    ...form_errors,
                    industry: null,
                    industry_other: null,
                    department:null
                };
            }
        }
        if(!key || key=="industry"){

            var field_value = key  ? value : formValues.industry;
            
            if (!field_value &&  !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {

                form_errors = {
                    ...form_errors,
                    industry: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "industry" : firsterrorid;
            }
        }
        if(!key || key=="department"){

            var field_value = key  ? value : formValues.department;
            
            if (!field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {

                form_errors = {
                    ...form_errors,
                    department: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "department" : firsterrorid;
            }
        }
        if(!key || key=="income_slab"){

            var field_value = key  ? value : formValues.income_slab;
            
            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    income_slab: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }
        if(!key || key=="people"){

            var field_value = key && inline ? value : formValues.people;
            
            if (!field_value || parseInt(field_value)==0) {

                form_errors = {
                    ...form_errors,
                    people: "People must be > 0",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }
        if(!key || key=="industry_other"){

            var field_value = key && inline ? value : formValues.industry_other;
            
            if ((formValues.industry=="Other (please specify)") && !field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {
                form_errors = {
                    ...form_errors,
                    industry_other: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "industry_other" : firsterrorid;
            }
        }
        if(!key || key=="department_other"){

            var field_value = key && inline ? value : formValues.department_other;
            
            if ((formValues.department=="Other (please specify)") && !field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {
                form_errors = {
                    ...form_errors,
                    department_other: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "department_other" : firsterrorid;
            }
        }
        
        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        
        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const checkBtnState = () => {

        var error = false;



        if (!formValues.employment) {
            error = true;
        }
        if(!["Student","Unemployed","Homemaker","Retired"].includes(formValues.employment)){
            if (!formValues.industry) {
                error = true;
            }
            if (formValues.industry == 'Other (please specify)' && !formValues.industry_other) {
                error = true;
            }
        
        
            if (!formValues.department) {
        
                error = true;
            }
            if (formValues.department == 'Other (please specify)' && !formValues.department_other) {
                error = true;
            }
        }
        if (!formValues.income_slab) {
            error = true;
        }
        if (parseInt(formValues.people) == 0) {
            error = true;
        }
        return error;
    }
    const onSubmit = async (event) => {
        
        event.preventDefault();
        
        if (!isLoading) {

            var error = validateFullForm(true,null);

            if (!error) {
                completeProfile();
            }
        }
        
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues(oldValues => ({ ...oldValues, [name]: value }));
                }
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value? value:'',true)
        }
    };
    const clearFormValue = (name) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
    };
    
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={true}
        >
            <div className='container'>
                {isLoading &&
                    <div className="accountloading-box"> <LoadingIcon /></div>
                }
                {!isLoading &&
                  <form method="POST" onSubmit={onSubmit}>
                    <div className='register-page-min-hegiht'>
                <div className='row tester_sign_up_step_row'>
                    <div className='col-md-6 graytext researcher-profilebox-right-outerbox mb-20'>
                        <PageProgressBar
                            current_step={2}
                            total_steps={3}    
                        />
                        <div className="mt-32">
                            <Text type={"h3"} fontWeight={"semi-bold-font"}>What do you do for a living?</Text>
                            <Text type={"body-text-2"} fontWeight={"medium-font"}>Tell us about your work</Text>
                        </div>
                
                        <div className="login-form-inner-data researcher-profilebox tester-profilebox mt-40">
                                <div className="formbox" id="employment">
                                    <div className='profile-form-group'>
                                        <DropdownComponent 
                                            options={employmentOptions}
                                            value={formValues.employment}
                                            onChange={(item)=>{
                                                
                                                
                                                if(item){
                                                    if(["Student","Unemployed","Homemaker","Retired"].includes(item.value)){

                                                        setEmploymentFieldsReadOnly(true);
    
                                                        setFormValues({...formValues,employment:item.value, department:'', industry:'', industry_other:''})
    
                                                    } else {
                                                        setEmploymentFieldsReadOnly(false);
                                                        setFormValues({...formValues,employment:item.value})
                                                    }

                                                    
                                                } else {
                                                    setEmploymentFieldsReadOnly(false);
                                                    setFormValues({...formValues,employment:''})
                                                }
                                                validateFullForm(false,"employment",item?item.value:'');
                                            }}
                                            isClearable={true}
                                            label="Employment status"
                                            error={formErrors.employment}
                                            error_class={formErrors.error_class}
                                        />
                                    </div>
                                    
                                </div>
                                <div className="formbox" id="industry">
                                    <div className='profile-form-group'>
                                        <DropdownComponent 
                                            options={industryOptions}
                                            value={formValues.industry}
                                            readOnly={employmentFieldsReadOnly}
                                            onChange={(item)=>{
                                                
                                                if(item){
                                                    setFormValues({...formValues,industry:item.value})

                                                    
                                                } else {
                                                    setFormValues({...formValues,industry:''})
                                                }
                                                validateFullForm(false,"industry",item?item.value:'');
                                            }}
                                            isClearable={true}
                                            label="Work industry"
                                            error={formErrors.industry}
                                            error_class={formErrors.error_class}
                                        />
                                    </div>
                                    
                                </div>
                                {formValues.industry=="Other (please specify)" &&
                                <div className="formbox">
                                    <Input
                                        type="text"
                                        id="industry_other"
                                        name="industry_other"
                                        value={formValues.industry_other}
                                        onChange={(e)=>{setFormValues({...formValues,industry_other:e.target.value})

                                                validateFullForm(false,"industry_other",e.target.value, true);
                                            
                                            }}
                                        label={"Industry (Other)"}
                                        onClear={()=>{clearFormValue("industry_other")}}
                                        readOnly={employmentFieldsReadOnly}
                                        required={true}
                                        error={formErrors.industry_other}
                                        error_class={formErrors.error_class}
                                        onBlur={()=>{
                                            validateFullForm(false,"industry_other")
                                        }}
                                    />
                                </div>
                                }
                                <div className="formbox" id="department">
                                    <div className='profile-form-group'>
                                        <DropdownComponent 
                                            options={departmentOptions}
                                            value={formValues.department}
                                            readOnly={employmentFieldsReadOnly}
                                            onChange={(item)=>{
                                                
                                                if(item){
                                                    setFormValues({...formValues,department:item.value})

                                                    
                                                } else {
                                                    setFormValues({...formValues,department:''})
                                                }
                                                validateFullForm(false,"department",item?item.value:'');
                                            }}
                                            isClearable={true}
                                            label="What department do you work in?"
                                            error={formErrors.department}
                                            error_class={formErrors.error_class}
                                        />
                                    </div>
                                    
                                </div>
                                {formValues.department=="Other (please specify)" &&
                                <div className="formbox">
                                    <Input
                                        type="text"
                                        id="department_other"
                                        name="department_other"
                                        value={formValues.department_other}
                                        onChange={(e)=>{setFormValues({...formValues,department_other:e.target.value})

                                                validateFullForm(false,"department_other",e.target.value, true);
                                            
                                            }}
                                        label={"Department (Other)"}
                                        onClear={()=>{clearFormValue("department_other")}}
                                        readOnly={employmentFieldsReadOnly}
                                        required={true}
                                        error={formErrors.department_other}
                                        error_class={formErrors.error_class}
                                        onBlur={()=>{
                                            validateFullForm(false,"department_other")
                                        }}
                                    />
                                </div>
                                }
                                <div className="formbox" id="income_slab">
                                    <div className='profile-form-group'>
                                        <DropdownComponent 
                                            options={incomelevelOptions}
                                            value={formValues.income_slab}
                                            onChange={(item)=>{
                                                
                                                if(item){
                                                    setFormValues({...formValues,income_slab:item.value})
                                                } else {
                                                    setFormValues({...formValues,income_slab:''})
                                                }
                                                

                                                validateFullForm(false,"income_slab",item?item.value:'');
                                            }}
                                            isClearable={true}
                                            label="Monthly household income (USD)"
                                            error={formErrors.income_slab}
                                            error_class={formErrors.error_class}
                                        />
                                    </div>
                                    
                                </div>
                                <div className="formbox" id='people'>
                                    <label>How many people live in your household?</label>
                                    <IncDecCounter min={1} max={10} 
                                        value={formValues.people}
                                        onChange={(value) => {
                                            if(value!=formValues.people){
                                                setFormValues({ ...formValues, people: value ? value :1 });

                                                validateFullForm(false,"people",value?value:1, true);
                                            }

                                        }} 
                                        readOnly={false}
                                    />
                                    {formErrors.people &&
                                    <span className={formErrors.error_class}>{formErrors.people}
                                    </span>
                                    }
                                </div>
                               
                           
                        </div>
                        <div className='sign-up-btn-wrap tester_signup_btns mt-32'>
                   <Button 
                                type='secondary'
                                size='large'
                                id='r_registration' 
                                label="Back"
                                iconLeft={
                                    <Icon 
                                        colorClass="gray-900-svg"
                                        value="back-arrow"
                                        size="large"
                                    />
                                }
                                tag={"link"}
                                state={""}
                                href="/tester/profile/step1"
                            />

                   <Button 
                                        type='primary'
                                        size='large'
                                        id='r_registration' 
                                        label="Next"
                                        iconRight={
                                            <Icon 
                                                colorClass="gray-50-svg"
                                                value="forward-arrow"
                                                size="large"
                                            />
                                        }
                                        microLoading={formLoading}
                                        state={checkBtnState()?"disabled":"active"}
                                    />
                   </div>

                    </div>   

                    <div className='col-md-6 graytext position-relative none-for-mobile'>
                     
                       <SignUpInformationalCard icon={<Icon colorClass={"secondary-purple-200"} value={"flag"} />} title="Be part of the change" info="Help shape digital products from some of the coolest brand in the MENA region."/>
                        
                    </div> 
                </div>
                </div>
                  

                </form>
                }
            </div>
        </LayoutTester>
    );
}