import PropTypes from 'prop-types';
import React from "react";
import { Text } from './../Text/Text';
import { Icon } from '../Icon/Icon';
import { Tag } from '../Tag/Tag';
export const SquareTile = ({icon, id, title, size, tag, cssclasses, onClick})=>{
    return (
        <div id={id} onClick={onClick} className={`square-tile-wrap ${cssclasses ? cssclasses : ""}`}>
            <div className='square-tile-inner'>
                <div className='square-icon-wrap'>
                    {icon &&
                        <Icon size={size} hover={true} value={icon} />
                    }
                </div>
                <div className='square-time-heading'>
                    <Text type={'subtitle-2'} fontWeight={'medium-font'}>{title}</Text>
                </div>
                
                {tag && 
                    <Tag title={tag}/>
                }  

            </div>
        </div>
    )
}
