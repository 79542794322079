export const theme = {
    "colors" : {
        "gray":{
            "50": "#FFFFFF",
            "100": "#FAFAFA",
            "200": "#F5F5F5",
            "300": "#F0F0F0",
            "400": "#E9E9E9",
            "500": "#CCCCCC",
            "600": "#9C9C9C",
            "700": "#676767",
            "800": "#343434",
            "900": "#000000",
        },
        "primary-pink":{
            "50": "#FFA7D0",
            "100": "#FF8AC1",
            "200": "#FF6DB1",
            "300": "#E32F83",
            "400": "#A72561"
        },
        "secondary-purple":{
            "50": "#C09BE0",
            "100": "#AC7AD5",
            "200": "#9759CB",
            "300": "#793EAB",
            "400": "#5C208D"
        },
        "tertiary-gold":{
            "50": "#FFE9D0",
            "100": "#FFE2C0",
            "200": "#F2C690",
            "300": "#D7A56A",
            "400": "#C38D4D"
        },
        "warning-yellow":{
            "50": "#FCDDAE",
            "200": "#FFB800",
            "400": "#CB7A00"
        },
    },
    sizes:[
        "small",
        "medium",
        "large"
    ]
};