import { propTypes } from "cleave.js/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { callOldUI, callNewUI } from "../../lib/helpers";
import { ReactSession } from "../../lib/secure_reactsession";

export default function ScrollToTop(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearCacheData();
     
    let user = ReactSession.get("user");

    document.body.classList.remove('open-m-menu');


    /*if(pathname.includes("researcher/test") && pathname.includes("target-group")){
      callNewUI()
    }
    else if(pathname.includes("researcher/register")){
      callNewUI()
    }
    else if(pathname.includes("researcher/sign-up")){
      callNewUI()
    }
    else if(pathname.includes("researcher/forgot-password")){
      callNewUI()
    }
    else if(pathname.includes("researcher/reset-password")){
      callNewUI()
    }
    else if(pathname.includes("researcher/registration/success")){
      callNewUI()
    }
    else if(pathname.includes("researcher/verify")){
      callNewUI()
    }
    else if(pathname.includes("researcher/login")){
      callNewUI()
    }
    else if(pathname.includes("researcher/sign-in")){
      callNewUI()
    }
    else if(pathname.includes("tester/register")){
      callNewUI()
    }
    else if(pathname.includes("tester/sign-up")){
      callNewUI()
    }
    else if(pathname.includes("tester/forgot-password")){
      callNewUI()
    }
    else if(pathname.includes("tester/reset-password")){
      callNewUI()
    }
    else if(pathname.includes("tester/registration/success")){
      callNewUI()
    }
    else if(pathname.includes("tester/verify")){
      callNewUI()
    }
    else if(pathname.includes("tester/login")){
      callNewUI()
    }
    else if(pathname.includes("tester/sign-in")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("target-group")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("recruit")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("introduction")){
        callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("welcome")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("conclusions")){
        callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("thank-you")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("card-sorting-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("card-sorting-test")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("preference-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("preference-test")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("tree-sort-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("tree-sort-test")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("tree-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("tree-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/analytics") && pathname.includes("tree-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/analytics") && pathname.includes("tree-chart")){
      callNewUI()
    }
    else if(pathname.includes("/r/results") && pathname.includes("tree-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/results") && pathname.includes("tree-chart")){
      callNewUI()
    }
    else if(pathname.includes("/r/results") && pathname.includes("tree-view")){
      callNewUI()
    }
    else if(pathname.includes("/researcher/analytics") && pathname.includes("tree-test")){
      callNewUI()
    }
    else if(pathname.includes("/researcher/analytics") && pathname.includes("tree-chart")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("survey")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("survey")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("five-seconds-test")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("five-seconds-test")){
      callNewUI()
    }
    else if(pathname.includes("researcher/test") && pathname.includes("publish")){
      callNewUI()
    }
    else if(pathname.includes("/r/test") && pathname.includes("publish")){
      callNewUI()
    }
    else if(pathname.includes("/researcher/analytics") && pathname.includes("participants")){
      callNewUI()
    }
    else if(pathname.includes("/r/analytics") && pathname.includes("participants")){
      callNewUI()
    }
    else if(pathname.includes("/r/results") && pathname.includes("participants")){
      callNewUI()
    }
    else if(user && user.role=="Researcher" && pathname.includes("dashboard")){
      callNewUI()
    }else if(user && user.role=="Tester" && pathname.includes("dashboard")){
      callNewUI()
    }else if( pathname.includes("/r/invite-and-earn")){
      callNewUI()
    }else if( pathname.includes("/r/credits")){
      callNewUI()
    }else if( pathname.includes("/r/payment-methods")){
      callNewUI()
    }else if( pathname.includes("/r/invoice-history")){
      callNewUI()
    }else if( pathname.includes("/researcher/billing")){
      callNewUI() 
    }else if( pathname.includes("/r/billing-information")){
      callNewUI() 
    }else if( pathname.includes("/r/result-export")){
      callNewUI()
    }
    else if(user && user.role=="Researcher" && pathname.includes("buy-credits")){
      callNewUI()
    }
    else if(user && user.role=="Researcher" && pathname.includes("profile")){
      callNewUI()
    }
    else if(user && user.role=="Tester" && pathname.includes("tester/profile/step1")){
      callNewUI()
    }else if(pathname.includes("t/tests/") || pathname.includes("tester/tests/")){
      callNewUI()
    }else if(pathname.includes("t/wallet/") || pathname.includes("tester/wallet/")){
      callNewUI()
    }
    else if(user && user.role=="Tester" && pathname.includes("tester/profile/step2")){
      callNewUI()
    }
    else if(user && user.role=="Tester" && pathname.includes("tester/profile/step3")){
      callNewUI()
    }
    else if(pathname.includes("settings")){
      callNewUI()
    }
    else if(pathname.includes("404")){
      callNewUI()
    }
    else if(pathname.includes("email/change")){
      callNewUI()
    }
    else if(pathname.includes("email/confirm")){
      callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("briefing")){
        callNewUI()
    } 
    else if(pathname.includes("results") && pathname.includes("introduction-questions")){
      callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("overview")){
      callNewUI()
    }
    else if(pathname.includes("results") && pathname.includes("overview")){
      callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("debriefing")){
        callNewUI()
    }
    else if(pathname.includes("results") && pathname.includes("conclusion-questions")){
      callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("survey")){
      callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("five-seconds-test")){
      callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("card-sorting")){
      callNewUI()
    } else if(pathname.includes("analytics") && pathname.includes("card-sorting-similarity")){
      callNewUI()
    }else if(pathname.includes("analytics") && pathname.includes("card-sorting-chart")){
        callNewUI()
    }
    else if(pathname.includes("analytics") && pathname.includes("preference-test")){
      callNewUI()
    }
    else if(pathname.includes("results") && pathname.includes("survey")){
      callNewUI()
    }
    else if(pathname.includes("results") && pathname.includes("5-second-test")){
      callNewUI()
    }
    else if(pathname.includes("results") && pathname.includes("card-sorting")){
      callNewUI()
    } else if(pathname.includes("results") && pathname.includes("similarity-matrix")){
      callNewUI()
    }else if(pathname.includes("results") && pathname.includes("results-grid")){
        callNewUI()
    }
    else if(pathname.includes("results") && pathname.includes("preference-test")){
      callNewUI()
    }
    else if(pathname.includes("/p/") || pathname.includes("/t/")){
      callNewUI()
    }
    else if(pathname.includes("support")){
      callNewUI()
    }
    else {

      callOldUI();
    }*/

  }, [pathname]);

  const clearCacheData = () => {
    var date = new Date;

    fetch("/meta.json?"+date.getTime())
    .then((response) => response.json())
    .then((meta) => {
        if(meta.maintenance_mode==1){
            navigate("/maintenance");
        } 
    });

};
  
  return <>{props.children}</>;
}