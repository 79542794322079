import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
//import LayoutResearcher from '../../layouts/layout_researcher.js';
import {LayoutResearcher} from "../../themes/userq/Layouts/layout_researcher";
import ResearcherProfileNavigation from '../../profile/researcher_profile_navigation.js';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import toast from 'react-hot-toast';
import moment from 'moment';
import { getCurrentPlanService } from '../../../services/user.js';
import { getInvoicesService } from '../../../services/invoices.js';
import UpdateBillingInfo from './update-billing-info.js';
import PaymentMethods from './payment-methods.js';
import {LoadingIcon} from "../../themes/userq/Loader/loadingIcon";
import {Text} from "../../themes/userq/Text/Text";
import BillingNavigation from "./billing-navigation";
import {getBillingInfoService, updateBillingInfoService} from "../../../services/user";
import {countries} from "../../../data/stripe_countries";
import Select from "react-select";
import {DropdownIndicator, showToast} from "../../../lib/helpers";
import {Input} from "../../themes/userq/Input/Input";
import {DropdownComponent} from "../../themes/userq/Dropdown/Dropdown";
import {Button} from "../../themes/userq/Button/Button";
import { AutoCompleteSingleSelect } from '../../themes/userq/Dropdown/AutoCompleteSingleSelect';

export default function Billing() {

    const navigate = useNavigate();




    const user = ReactSession.get("user");

    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [isLoading, setLoading] = useState(false);

    const [isCancel, setCancel] = useState(false);

    const [billingLoading, setBillingLoading] = useState(false);

    const [isBillingEditable, setBillingEditable] = useState(true);

    const [isExpanded, setExpanded] = useState(false);

    const [formValues, setFormValues] = useState({"name": "", "country":"","city":"","address":""});

    const [billingInfo, setBillingInfo] = useState({"name": "", "country":"","city":"","address":""});

    const [formErrors, setFormErrors] = useState({email:null,"name": null, "country":null,"city":null,"address":null,  "error_class":null});

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const set = name => {
        return ({ target: { value } }) => {
            setCancel(true);
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)

        }
    };
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({...oldValues, [name]: value }));
        setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
    };
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal['error_class'] = 'input_error';

        setFormErrors(formErrorsLocal);


    }

    const getBillingInfo = ()=>{
        let token = ReactSession.get("token");

        setLoading(true);

        resetFormErrors();

        getBillingInfoService(token).then(response=> {
            setLoading(false);

            setCancel(false); // set cancel button false after saving data

            if(response.success){
                for(var key in response.billing_address){
                    if(response.billing_address[key]==null){
                        response.billing_address[key] = '';
                    }
                }
                var country = null;

                //console.log(response.billing_address);
                countries.forEach(function(item){
                    if(item.value == response.billing_address.country){
                        country = item.label;
                    }
                })
                if(response.billing_address.country==''){
                    setCountryLabelClass('inactive_label');
                } else {
                    setCountryLabelClass('active_label');
                }
                setBillingInfo(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                setFormValues(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                window.setLabels();
            } else {
                showToast(response.message,"error");
            }
        });
    }


    useEffect(() => {

        document.title = "Billing information | "+process.env.REACT_APP_NAME;

        getBillingInfo();

    }, []);

    const validateBillingInfoForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid=null;

        if (formValues.name=='') {
            form_errors = {
                ...form_errors,
                name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid=firsterrorid==null?"name":firsterrorid;
        }
        if (!formValues.country) {
            form_errors = {
                ...form_errors,
                country: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid=firsterrorid==null?"contry":firsterrorid;
        }

        if (formValues.city=='') {
            form_errors = {
                ...form_errors,
                city: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid=firsterrorid==null?"city":firsterrorid;
        }
        if (formValues.address=='') {
            form_errors = {
                ...form_errors,
                address: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid=firsterrorid==null?"address":firsterrorid;
        }



        setFormErrors(form_errors);

        if(firsterrorid!=null){
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };
    const updateBillingInfo = () => {

        resetFormErrors();

        if(!billingLoading){

            var error = false;

            error = validateBillingInfoForm();

            if(!error){
                setBillingLoading(true);

                const token = ReactSession.get("token");

                var formData =  {};

                formData["name"] = formValues.name;

                formData["address"] = formValues.address;

                //formData["country"] = formValues.country;
                countries.forEach(function(item){
                    if(item.label == formValues.country){
                        formData["country"] = item.value;
                    }
                })


                formData["city"] = formValues.city;

               // console.log(formValues)

                updateBillingInfoService(formData,token)
                    .then(response=>{

                        setBillingLoading(false);

                        if(response.success){

                            setCancel(false);

                            for(var key in response.billing_address){
                                if(response.billing_address[key]==null){
                                    response.billing_address[key] = '';
                                }
                            }
                            var country = null;

                            countries.forEach(function(item){
                                if(item.value == response.billing_address.country){
                                    country = item.label;
                                }
                            })
                            if(response.billing_address.country==''){
                                setCountryLabelClass('inactive_label');
                            } else {
                                setCountryLabelClass('active_label');
                            }

                            setBillingInfo(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                            setFormValues(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                            setBillingEditable(false);

                            window.setLabels();
                            showToast(response.message,"success");
                        } else {
                            showToast(response.message,"error");
                        }
                    })
            }
        }
    }

    return (
        <LayoutResearcher
            isLoading={false}
            wrapclassName="rs-inner-profile-wrapper"
            skipCheck={false}
            extendedFooter={false}
            activeMenu={"billing"}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <div className="billing-plan-page-wrapper">

                <div className="page-heading-wrap accounts-page--heading billing-info-section">
                    <Text type={"h1"}>Billing</Text>
                </div>

                <BillingNavigation activeMenu="billing" />

                {isLoading && (
                    <div className={'page-loader accountloading-box'}>
                        <LoadingIcon/>
                    </div>
                )}
                {!isLoading && (
                    <>
                        {/*<PaymentMethods showRadioBtns={false} />*/}
                        <>
                            <div className="profile-form-repeat">
                                <div className="row">

                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6 formbox">
                                                <Input
                                                    label="Name/Company name"
                                                    onChange={set("name")}
                                                    //onClear={clearFormValue('name')}
                                                    value={formValues.name}
                                                    error={formErrors.name}
                                                    error_class={formErrors.error_class}
                                                    id="name"
                                                    clearField={false}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-md-6 formbox">
                                                <Input
                                                    label="Email address"
                                                    onChange={() => { }}
                                                    onClear={() => { }}
                                                    value={user.email}
                                                    id="email"
                                                    clearField={false}
                                                    required={true}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className="col-md-6 formbox">
                                                <div className="profile-form-group">
                                                  <AutoCompleteSingleSelect 
                                                        type={"billing_country"}
                                                        isClearable
                                                        id={"contry"}
                                                        label="Country"
                                                        options={countries}
                                                        value={formValues.country}
                                                        error={formErrors.country}
                                                        onChange={(country)=>{
                                                            
                                                            if(country){
                                                                setCancel(true);
                                                                setFormValue("country",country.value )
                                                            } else {
                                                            setCancel(true);
                                                                setFormValue("country",null)
                                                            }
                                                        }}
                                                    />
                                                   

                                                </div>
                                            </div>
                                            <div className="col-md-6 formbox">
                                                <Input
                                                    label="City"
                                                    onChange={set("city")}
                                                    //onClear={clearFormValue('name')}
                                                    value={formValues.city}
                                                    error={formErrors.city}
                                                    error_class={formErrors.error_class}
                                                    id="city"
                                                    clearField={false}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-md-12 formbox">
                                                <Input
                                                    label="Address"
                                                    onChange={set("address")}
                                                    //onClear={clearFormValue('name')}
                                                    value={formValues.address}
                                                    error={formErrors.address}
                                                    error_class={formErrors.error_class}
                                                    id="city"
                                                    clearField={false}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-md-12 formbox formbuttombox">
                                                <div className="flex-buttons-wrap">
                                                    <Button type="primary"
                                                            size={`large`}
                                                            microLoading={billingLoading}
                                                            label="Save"
                                                            onClick={()=>{
                                                                if(isCancel) {
                                                                    updateBillingInfo();
                                                                }
                                                            }}
                                                            state={isCancel?'active':'disabled'}
                                                    />

                                                    <Button type="secondary"
                                                            size={`large`}
                                                            label="Cancel"
                                                            state={isCancel?'':'disabled'}
                                                            onClick={()=>{
                                                                if(isCancel){
                                                                    getBillingInfo();
                                                                }

                                                            }}
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </>
                    </>
                )}
            </div>
            <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                    Support
                                </a>
        </LayoutResearcher>
    );
}