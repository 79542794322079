import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import { CheckBox } from "../CheckBox/CheckBox";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";

export default function ReportTesterConfirmation({
                                                     openModal,
                                                     total_test,
                                                     confirm_title,
                                                     confirm_btn_title,
                                                     cancel_btn_title,
                                                     close,
                                                     confirm,
                                                     btnLoading,
                                                     confirm_title_class,
                                                     cssClass,
                                                     confirm_btn_disabled,
                                                     confirm_btn_loading,
                                                 }) {
    const closeModal = () => {
        close();
    };
    const wrapperRef = useRef(null);

    const navigate = useNavigate();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className={`fade custom-modal-wrap  confirmation-modal-wrap report-tester-confirmation-modal ${cssClass}`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">

                            <h3 className={`heading h3 ${confirm_title_class?confirm_title_class:''}`}>{confirm_title}</h3>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                            </button>
                        </div>

                        <div className={'report-tester-confirmation-data-wrap'}>
                            <Text type={'body-text-2'} cssClasses={'tester-selected-data'} fontWeight={"semi-bold-font"}>
                                {total_test === 1?total_test+' participant':total_test+' participants'} will be notified to the admin for review.
                            </Text>

                            <Text type={'body-text-2'} cssClasses={'tester-selected-message'} fontWeight={"semi-bold-font"}>
                                Are you sure you want to submit your request?<br/>Once the process is completed, you will receive an email and a credit refund to your wallet for approved requests.
                            </Text>
                        </div>

                        <div className="confirm-buttons-wrap">


                            <Button type="secondary" size="large" label={cancel_btn_title ? cancel_btn_title : 'Cancel'} onClick={() => { closeModal(); }} />

                            <Button type="primary" microLoading={confirm_btn_loading?confirm_btn_loading:false} size="large" state={confirm_btn_disabled?confirm_btn_disabled:"active"} label={confirm_btn_title ? confirm_btn_title : "OK"} onClick={() => {  confirm(); } } />

                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
