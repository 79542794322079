import { Text } from "../../../themes/userq/Text/Text";
import React from "react";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";

export default function PreferenceTestIntroduction({ goToTask, test }) {
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap task-tree-testpage-wrap ">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                    <div className="tester-screens-hold">
                        <div className="tester-selected-page-wrap">
                            <div className="selected-page-right-side">
                                <div className="selected-test-info-img">
                                    <img src={process.env.REACT_APP_URL + "img/preferencetest-placeholder.svg"} />
                                </div>
                            </div>
                            <div className="selected-page-left-side">
                                <Text type={"h1"}>
                                    {test.language == "en" ? "Preference test" : "اختبار التفضيل"}
                                </Text>

                                <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                    {test.language == "en" ?
                                        "We’ll ask your preference between some options that will be shown. Before indicating your choice, please take a moment to carefully review the options."
                                        : "سنطلب تفضيلك بين بعض الخيارات التي سيتم عرضها أمامك. قبل الإشارة إلى اختيارك، يرجى تخصيص بعض الوقت لمراجعة الخيارات بعناية."}
                                </Text>

                                <div className={'remember-text'}>
                                    <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                        {test.language == "en" ? "Remember, there’s no right or wrong answer here - just do what comes naturally."
                                            : "تذكر أنه لا توجد إجابة صحيحة أو خاطئة هنا - فقط لاحظ كما تفعل بشكل طبيعي."}
                                    </Text>
                                </div>

                                <div className="button-wrapper none-for-mobile">
                                    <Button
                                        size={'large'}
                                        iconRight={<Icon colorClass={"gray-50-svg"} value={test.language=="ar"?'back-arrow':'forward-arrow'} />}
                                        label={test.language == "en" ? "Let’s start" : "لنبدأ"}
                                        onClick={() => {
                                            goToTask();
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    <Button
                        size={'large'}
                        iconRight={<Icon colorClass={"gray-50-svg"} value={test.language=="ar"?'back-arrow':'forward-arrow'} />}
                        label={test.language == "en" ? "Let’s start" : "لنبدأ"}
                        onClick={() => {
                            goToTask();
                        }}
                    />
                </div>
            </div>

        </div>

    );
}