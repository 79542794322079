import { CheckBox } from "../../../themes/userq/CheckBox/CheckBox";
import CreditCardInput from 'react-credit-card-input';
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import validator from "validator";
import creditCardType from "credit-card-type";
import { useState } from "react";
export const CreditCardForm = ({formValues, setFormValues, isValid, isInValid,isSavedForFuture})=>{

    const [isActiveState, setActiveState] = useState({cc:true, exp:true, cvv:true});

    const [activeClass, setActiveClass] = useState(null);

    const validateExpDate = (exp_date) => {
        if(!exp_date){
            return false;
        }
        var error = false;

        var new_exp_date = exp_date.replaceAll(" ","");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {


            if (new Date().getTime() > new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime() || isNaN(new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime())
            ) {

                error = true;

            }

        } else if (new_exp_date.length > 0) {

            error = true;

        }

        return error;
    };
    const validateCreditCard = (value) => {
        if(!value){

            return false;
        }
        var error = false;
        if (!validator.isCreditCard(value)) {
            error = true;
        }
        var card_type = creditCardType(value);

        if(card_type.length > 0 && card_type[0].length)

            if (card_type[0].type != "visa" && card_type[0].type != "mastercard") {
                error = true;
            }
        return error;
    };
    return (<>
        <Text type={"overline"}>ADD NEW CARD</Text>
        <div className="cards-accepted-wrap">
        <span>
            <img
                src={process.env.REACT_APP_URL + "images/visa-icon.svg"}
                alt="icon"
            />
        </span>
            <span>
            <img
                src={process.env.REACT_APP_URL + "img/Mastercard.png"}
                alt="icon"
            />
        </span>
        </div>

        <div className="row add-paymentmethod-wrap">
        <span className={`credit-card-field ${activeClass} ${isInValid ? "same-card-error" :""} ${validateCreditCard(formValues.card_no) && isActiveState.cc ? "credit-card-error" : ''} ${validateExpDate(formValues.exp_date) && isActiveState.exp ? "exp-date-error" : ''}`}>
        <CreditCardInput
            cardNumberInputProps={{ value: formValues.card_no, onFocus:(e)=>{ setActiveClass("active-card-input") }, onChange: (e)=>{  setActiveState({...isActiveState,cc:false}); setFormValues({...formValues, card_no:e.target.value, payment_method_id:""}) }, onBlur: (e) => { setActiveClass(null); setActiveState({...isActiveState,cc:true}); }  }}
            cardExpiryInputProps={{ value: formValues.exp_date, onFocus:(e)=>{ setActiveClass("active-card-input") }, onChange:  (e)=>{ setActiveState({...isActiveState,exp:false}); setFormValues({...formValues, exp_date:e.target.value, payment_method_id:""}) } , onBlur: (e) => { setActiveClass(null); setActiveState({...isActiveState,exp:true}); } }}
            cardCVCInputProps={{ value: formValues.cvv, onFocus:(e)=>{ setActiveClass("active-card-input") }, onChange: (e)=>{ setActiveState({...isActiveState,cvv:false}); setFormValues({...formValues, cvv:e.target.value, payment_method_id:""}) }, onBlur: (e) => { setActiveClass(null); setActiveState({...isActiveState,cvv:true}); } }}
            fieldClassName="input"
        />
        </span>
            {isInValid
            ?
            <Icon value={"error-red"} size={"medium"} />
            :
            <>
            {isValid &&
            <Icon value={"Success-Green"} size={"medium"} />
            }
            {( (validateCreditCard(formValues.card_no) && isActiveState.cc) || (validateExpDate(formValues.exp_date) && isActiveState.exp) ) &&
            <Icon value={"error-red"} size={"medium"} />
            }
            </>
            }
            
            {/*<div className="col-md-7 publish-field-whidth">
            <div className="add-card-field-hold">
                <div className="profile-form-group">
                    
                    <div className="input form-group input-field">
                        <div className="position-relative w-100">
                            <Cleave
                                id="card-number"
                                options={{
                                    creditCard: true,
                                    onCreditCardTypeChanged: (type) => {

                                        setFormValues({ ...formValues, card_type: type })
                                    }
                                }}
                                onChange={set("card_no")}
                                className={`form-control ${formErrors.card_no != null
                                    ? formErrors.error_class
                                    : ""
                                    } `}
                                value={formValues.card_no}
                                onKeyUp={(e) => {
                                    validateCreditCard(e.target.value);
                                }}


                            />


                            <label htmlFor="card-number">
                                Card number
                            </label>

                        </div>
                        {formValues.card_type && formValues.card_type != 'unknown' &&
                            <span className="selected_card_type">
                                <img src={
                                    process.env.REACT_APP_URL +
                                    "images/" + formValues.card_type + "-icon.svg"
                                } />
                            </span>
                        }
                        {formErrors.card_no != null && (
                            <span className={formErrors.error_class}>
                                {formErrors.card_no}
                            </span>
                        )}

                    </div>

                </div>
            </div>
        </div>
        <div className="col-md-7 publish-field-whidth">
            <div className="profile-form-group">
                <div className="input form-group input-field">
                    <Cleave
                        id="expiration-date"
                        options={{
                            date: true,
                            datePattern: ["m", "y"],
                        }}
                        onChange={set("exp_date")}
                        className={`form-control ${formErrors.exp_date != null
                            ? formErrors.error_class
                            : ""
                            } `}
                        value={formValues.exp_date}
                    />
                    <label htmlFor="expiration-date">
                        Expiration date mm/yy
                    </label>

                    {formErrors.exp_date != null && (
                        <span className={formErrors.error_class}>
                            {formErrors.exp_date}
                        </span>
                    )}
                </div>
            </div>
        </div>
        <div className="col-md-7 publish-field-whidth">
            <div className="profile-form-group">
                <div className="input form-group input-field">
                    <div className="position-relative w-100">
                        {formValues.card_type == "amex" &&
                            <Cleave
                                id="cvv"
                                options={{
                                    blocks: [4],
                                    numericOnly: true,
                                }}
                                onChange={set("cvv")}
                                className={`form-control ${formErrors.cvv != null
                                    ? formErrors.error_class
                                    : ""
                                    } `}
                                value={formValues.cvv}
                            />
                        }
                        {formValues.card_type != "amex" &&
                            <Cleave
                                id="cvv"
                                options={{
                                    blocks: [3],
                                    numericOnly: true,
                                }}
                                onChange={set("cvv")}
                                className={`form-control ${formErrors.cvv != null
                                    ? formErrors.error_class
                                    : ""
                                    } `}
                                value={formValues.cvv}
                            />
                        }
                        <label
                            className="control-label"
                            htmlFor="cvv"
                        >
                            CVV/CVC
                        </label>

                        {formErrors.cvv != null && (
                            <span
                                className={formErrors.error_class}
                            >
                                {formErrors.cvv}
                            </span>
                        )}
                    </div>


                </div>
            </div>
                        </div>*/}
        </div>
        {isSavedForFuture &&
        <div className="row">
            <div className="col-md-12 save-card-for-future">
                <CheckBox checked={formValues.future_use} onChange={() => {
                    setFormValues({...formValues, future_use: !formValues.future_use})
                }} label={"Save card for future payment"}/>
            </div>
        </div>
        }

    </>);

}