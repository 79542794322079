import React, { Children, useEffect } from "react";
import {useLayoutEffect, useRef, useState} from 'react';
import { Button } from "../Button/Button";
import { Text } from "../Text/Text"

export const FilterButtons = ({options, selected, onChange}) => {

    const [selectedOption, setSelectedOption] = useState(selected);

    useEffect(()=>{
        setSelectedOption(selected);
    }, [selected]);

    return (
        <div className="filter-buttons-wrap">
            {
                options.map(function(item){
                    return (
                        <div key={"filter-button-"+item} onClick={(e)=>{  setSelectedOption(item); if(onChange){  onChange(item); } }} className={`filter-button-option ${item==selectedOption?"filter-button-selected":""}`}>{item}</div>
                    )
                })
            }
        </div>        
    );
};