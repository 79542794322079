import React, { useEffect, useRef, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Text } from "../../../themes/userq/Text/Text";
import { Input } from "../../../themes/userq/Input/Input";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { Link as UQLink } from "../../../themes/userq/Link/Link";

import { EmailInput } from "../../../themes/userq/Input/EmailInput";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { CreditCardForm } from "../../tests/components/credit_card_form";
import validator from "validator";
import { ToggleButton } from "../../../themes/userq/ToggleButton/ToggleButton";
import { Accordian } from "../../../themes/userq/Accordion/Accordion.js";
import InputField from "../../../layouts/elements/fields/input";
import {
    applyTeamWorkspaceCouponService,
    removeTeamWorkspaceCouponService,
    upgradeTeamOrderService,
    workspaceBillCycleUpdateService,
    comparePlanService,
    upgradeWorkspaceService,
    upgradeMakePaymentService,
    threeDSFailService,
} from "../../../../services/workspaces";
import {
    amountFormat,
    roundNumber,
    encryptClient,
    showToast,
} from "../../../../lib/helpers.js";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon.js";
import { Helmet } from "react-helmet";
import { Tag } from "../../../themes/userq/Tag/Tag.js";
import PromoCode from "../../components/promo_code.js";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import { getPaymentMethodsService } from "../../../../services/payment_methods.js";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton.js";
import IndividualWorkspaceModal from "../modals/individual-workspace-modal.js";
import ComparePlanModal from "../modals/compare-plan-modal.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import { FilterButtons } from "../../../themes/userq/FilterButtons/FilterButtons.js";

let colors_used_create = [];

var colorsPalleteCreate = [
    "rgba(227, 47, 131, 1)",
    "rgba(121, 62, 171, 1)",
    "rgba(215, 165, 106, 1)",
    "rgba(55, 139, 16, 1)",
    "rgba(33, 81, 245, 1)",
    "rgba(255, 109, 177, 1)",
    "rgba(151, 89, 203, 1)",
    "rgba(5, 43, 174, 1)",
    "rgba(22, 65, 3, 1)",
    "rgba(255, 184, 0, 1)",
    "rgba(6, 58, 236, 1)",
    "rgba(167, 37, 97, 1)",
    "rgba(92, 32, 141, 1)",
    "rgba(188, 113, 1, 1)",
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const UpgradeWorkspace = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentComponent {...props} />
        </Elements>
    );
};
const PaymentComponent = (props) => {
    const stripe = useStripe();
    ReactSession.setStoreType("localStorage");

    const [formErrors, setFormErrors] = useState({
        email: null,
        card_no: null,
        card_name: null,
        expiration_date: null,
        cvv: null,
        credits: null,
        promo_code: null,
        error_class: null,
        title: null,
    });

    const [openWorkspaceModal, setOpenWorkspaceModal] = useState(false);

    const [firstName, setFirstName] = useState("");

    const [formValues, setFormValues] = useState({
        workspaceName: "Team workspace",
        emails: [],
        card_no: "",
        exp_date: "",
        cvv: "",
        payment_method_id: "",
    });
    const [numSeats, setNumSeats] = useState(1);
    const [seatData, setSeatData] = useState([]);
    const [couponError, setCouponError] = useState("");

    const [maxSeats, setMaxSeats] = useState(0);
    const [pageLoading, setPageLoading] = useState(false);
    const [billingCycleLoading, setBillingCycleLoading] = useState(false);
    const [openComparePlanModal, setOpenComparePlanModal] = useState(false);

    const [individualPlan, setIndividualPlan] = useState(true);
    const [teamPlanMonthly, setTeamPlanMonthly] = useState(true);
    const [teamPlanYearly, setTeamPlanYearly] = useState(true);
    const [activeTeamPlan, setActiveTeamPlan] = useState(true);
    const workspaceMaxlength = 30;
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [accordians, setAccordians] = useState(false);
    const navigate = useNavigate();
    const [couponLoading, setCouponLoading] = useState(false);

    const [couponValues, setCouponValues] = useState({
        promo_code: "",
        description: "",
        discount: 0,
    });

    const [user, setUser] = useState(ReactSession.get("user"));

    const [orderDetails, setOrderDetails] = useState({
        id: null,
        plan_id: null,
        status: null,
        amount: 0,
        coupon: null,
        coupon_desc: null,
        coupon_id: null,
        stripe_coupon_id: null,
        discount: 0,
        total_amount: 0,
        discount_desc: null,
    });

    const [monthlyPlan, setMonthlyPlan] = useState({
        id: null,
        title: null,
        stripe_pricing_id: null,
        price: 0,
        billing_cycle: "monthly",
        status: null,
        plan_price: 0,
    });

    const [yearlyPlan, setYearlyPlan] = useState({
        id: null,
        title: null,
        stripe_pricing_id: null,
        price: 0,
        billing_cycle: "yearly",
        status: null,
        discount_perc: null,
        plan_price: 0,
    });

    const [selectedPlan, setSelectedPlan] = useState("yearly");

    const { workspaceid } = useParams();

    const [nextBillingDate, setNextBillingDate] = useState(
        moment(new Date()).format("DD-MM-YYYY")
    );

    const [upgradeWorkspaceMicroLoading, setUpgradeWorkspaceMicroLoading] =
        useState(false);
    const [WorkspaceLoading, setWorkspaceLoading] = useState(false);

    const [sameCardError, setSameCardError] = useState(false);

    const [filterOption, setFilterOption] = useState("Pay-As-You-Go");

    const [cardFormVisible, setCardFormVisible] = useState(false);

    const handleAddMore = () => {
        if (numSeats < maxSeats) {
            setNumSeats(numSeats + 1);

            colorsPalleteCreate = colorsPalleteCreate.filter(function (el) {
                return colors_used_create.indexOf(el) < 0;
            });

            const randomIndex = Math.floor(
                Math.random() * colorsPalleteCreate.length
            );

            // Access the element at the random index
            //let color = colorsPalleteCreate[randomIndex];

            let color = colorsPalleteCreate[numSeats];

            // colors_used_create.push(color);

            setSeatData([
                ...seatData,
                { email: "", color: color, readOnly: false, role: "member" },
            ]);
            // resetFormValues();
        }
    };

    // Validation check
    const validateFullForm = (
        focus,
        key,
        value = null,
        inline = false,
        index
    ) => {
        var error = false;
        var mailformat =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-0]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        var form_errors = { ...formErrors };
        if (key) {
            form_errors[key] = null;
        }

        if (focus) {
            form_errors = resetFormErrors();
        }

        if (key) {
            if (key === "title") {
                let field_value = value
                    ? value
                    : formValues.workspaceName
                    ? formValues.workspaceName
                    : "";

                if (
                    field_value === "" ||
                    field_value === null ||
                    field_value === undefined
                ) {
                    form_errors = {
                        ...form_errors,
                        [key]: "A workspace name is required",
                        error_class: "input_error",
                    };
                    error = true;
                }
            } else {
                var field_value =
                    key && inline
                        ? value
                        : seatData.length && seatData[index].email
                        ? seatData[index].email
                        : "";

                // if (
                //   field_value === "" ||
                //   field_value === null ||
                //   field_value === undefined
                // ) {
                //   form_errors = {
                //     ...form_errors,
                //     [key]: "Required field",
                //     error_class: "input_error",
                //   };
                //   error = true;
                // } else
                if (
                    !(
                        field_value === "" ||
                        field_value === null ||
                        field_value === undefined
                    )
                ) {
                    if (!field_value.match(mailformat)) {
                        form_errors = {
                            ...form_errors,
                            [key]: "Please enter a valid email address",
                            error_class: "input_error",
                        };
                        error = true;
                    } else {
                        // Check for duplicate emails
                        let isDuplicate =
                            index > 0 &&
                            seatData.some(
                                (data, i) =>
                                    i !== index && data.email === field_value
                            );
                        if (isDuplicate) {
                            form_errors = {
                                ...form_errors,
                                [key]: "Email already exists",
                                error_class: "input_error",
                            };
                            error = true;
                        }
                    }
                }
            }
        }

        if ((inline && formErrors[key]) || !inline) {
            setFormErrors(form_errors);
        }

        // setFormErrors(form_errors);
        return error;
    };

    const validateFullFormOnly = (showError = false) => {
        var error = false;
        var mailformat =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-0]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        let fieldsToValidate = ["title", "email"];

        var form_errors = { ...formErrors };

        if (showError) {
            form_errors = resetFormErrors();
        }

        let firsterrorid = null;

        for (let key of fieldsToValidate) {
            form_errors[key] = null;

            if (key === "title") {
                if (
                    !formValues ||
                    formValues.workspaceName === "" ||
                    formValues.workspaceName === null ||
                    formValues.workspaceName === undefined
                ) {
                    error = true;
                    if (showError) {
                        form_errors = {
                            ...form_errors,
                            [key]: "Required field",
                            error_class: "input_error",
                        };

                        firsterrorid =
                            firsterrorid == null
                                ? "workspaceName"
                                : firsterrorid;
                        // break;
                        // setFormErrors(form_errors);
                    } else {
                        break;
                    }
                }
            }

            if (key === "email") {
                if (seatData.length > 1 && seatData.length <= maxSeats) {
                    for (const [index, teamMember] of seatData.entries()) {
                        if (index !== 0) {
                            // if (
                            //   teamMember.email === "" ||
                            //   teamMember.email === null ||
                            //   teamMember.email === undefined
                            // ) {
                            //   error = true;
                            //   break;
                            // } else
                            if (
                                !(
                                    teamMember.email === "" ||
                                    teamMember.email === null ||
                                    teamMember.email === undefined
                                )
                            ) {
                                if (!teamMember.email.match(mailformat)) {
                                    error = true;

                                    if (showError) {
                                        form_errors = {
                                            ...form_errors,
                                            [`email_${index}`]:
                                                "Please enter a valid email address",
                                            error_class: "input_error",
                                        };

                                        firsterrorid =
                                            firsterrorid == null
                                                ? `email_${index}`
                                                : firsterrorid;

                                        // break;
                                        // setFormErrors(form_errors);
                                    } else {
                                        break;
                                    }
                                } else {
                                    // Check for duplicate emails
                                    let isDuplicate =
                                        index > 0 &&
                                        seatData.some((data, i) => {
                                            return (
                                                i !== index &&
                                                data.email === teamMember.email
                                            );
                                        });
                                    if (isDuplicate) {
                                        error = true;
                                        if (showError) {
                                            form_errors = {
                                                ...form_errors,
                                                [`email_${index}`]:
                                                    "Email already exists",
                                                error_class: "input_error",
                                            };

                                            firsterrorid =
                                                firsterrorid == null
                                                    ? `email_${index}`
                                                    : firsterrorid;

                                            // break;
                                            // setFormErrors(form_errors);
                                        } else {
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    //   error = true;
                    //   break;
                }
            }
        }

        if (showError) {
            setFormErrors(form_errors);
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };
    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 1000000,
            }
        );
    };

    // Email Input chnage handler
    const handleEmailChange = (index, value) => {
        setSeatData((prevSeatData) => {
            const updatedSeatData = [...prevSeatData];
            updatedSeatData[index].email = value;
            return updatedSeatData;
        });
    };

    // Reset form Error if not error then null error messages
    const resetFormErrors = () => {
        // setFormErrors({
        //     email: null,
        //     card_no: null,
        //     card_name: null,
        //     expiration_date: null,
        //     cvv: null,
        //     credits: null,
        //     promo_code: null,
        //     error_class: null,
        // });

        let formErrorsLocal = Object.assign({}, formErrors);

        for (let key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const [cardFormValues, setCardFormValues] = useState({
        card_no: "",
        card_name: "",
        exp_date: "",
        cvv: "",
        payment_method_id: "",
    });

    const validatePaymentForm = (showError = false) => {
        var error = false;

        let firsterrorid = null;

        if (cardFormValues.card_no == "") {
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(cardFormValues.card_no)) {
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }

        if (cardFormValues.exp_date == "") {
            error = true;
            firsterrorid = firsterrorid == null ? "card-expiry" : firsterrorid;
        }
        var new_exp_date = cardFormValues.exp_date.replaceAll(" ", "");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {
            if (
                new Date().getTime() >
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime() ||
                isNaN(
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime()
                )
            ) {
                error = true;
                firsterrorid =
                    firsterrorid == null ? "card-expiry" : firsterrorid;
            }
        } else if (new_exp_date.length > 0) {
            error = true;
            firsterrorid = firsterrorid == null ? "card-expiry" : firsterrorid;
        }
        if (cardFormValues.cvv == "") {
            error = true;
            firsterrorid = firsterrorid == null ? "cvc" : firsterrorid;
        }

        if (showError) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    };

    const removePromoCode = () => {
        setCouponLoading(true);

        const token = ReactSession.get("token");

        var formData = {};

        formData.order_id = orderDetails.id;

        removeTeamWorkspaceCouponService(formData, token).then((response) => {
            setCouponLoading(false);

            if (response.success) {
                setCouponValues({
                    ...couponValues,
                    discount: 0,
                    promo_code: "",
                    description: "",
                });
                setOrderDetails({
                    ...orderDetails,
                    stripe_coupon_id: null,
                    coupon: "",
                    discount: response.order.total_amount,
                    total_amount: response.order.total_amount,
                    discount_desc: response.order.discount_desc,
                });
            } else {
                showToast(response.message, "error");
            }
        });
    };
    const applyCouponApi = () => {
        if (!couponLoading) {
            // resetFormErrors();

            var error = false;

            if (!couponValues.promo_code) {
                error = true;
                setFormErrors({
                    ...formErrors,
                    promo_code: "No promocode has been entered",
                    error_class: "input_error",
                });
            }
            if (!error) {
                setCouponLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData.promo_code = couponValues.promo_code;
                formData.order_id = orderDetails.id;

                applyTeamWorkspaceCouponService(formData, token).then(
                    (response) => {
                        setCouponLoading(false);

                        if (response.success) {
                            setCouponValues({
                                ...couponValues,
                                promo_code: response.order.coupon_code,
                                discount: response.order.coupon_discount,
                                //description: response.order.coupon_desc,
                                description: null,
                            });

                            setOrderDetails({
                                ...orderDetails,
                                stripe_coupon_id:
                                    response.order.stripe_coupon_id,
                                coupon: response.order.coupon_code,
                                discount: response.order.coupon_discount,
                                total_amount: response.order.total_amount,
                                discount_desc:
                                    response.order.coupon_discount_desc,
                            });

                            showToast(response.message, "success");
                        } else {
                            setCouponError(
                                "You have entered an invalid promo code"
                            );
                        }
                    }
                );
            }
        }
    };

    useEffect(() => {
        colors_used_create = [];

        colorsPalleteCreate = [
            "rgba(227, 47, 131, 1)",
            "rgba(121, 62, 171, 1)",
            "rgba(215, 165, 106, 1)",
            "rgba(55, 139, 16, 1)",
            "rgba(33, 81, 245, 1)",
            "rgba(255, 109, 177, 1)",
            "rgba(151, 89, 203, 1)",
            "rgba(5, 43, 174, 1)",
            "rgba(22, 65, 3, 1)",
            "rgba(255, 184, 0, 1)",
            "rgba(6, 58, 236, 1)",
            "rgba(167, 37, 97, 1)",
            "rgba(92, 32, 141, 1)",
            "rgba(188, 113, 1, 1)",
        ];

        setFirstName(user.first_name);
        // createWorkspaceOrder();
        // getWorkspaceApi();
        upgradeWorkspaceOrder();
        comparePlanApi();
    }, []);

    const addAdditionalDefaultMembers = (members, membersCount) => {
        for (var i = 0; i < membersCount; i++) {
            colorsPalleteCreate = colorsPalleteCreate.filter(function (el) {
                return colors_used_create.indexOf(el) < 0;
            });

            var randomIndex = Math.floor(
                Math.random() * colorsPalleteCreate.length
            );

            // Access the element at the random index
            //var color = colorsPalleteCreate[randomIndex];

            var color = colorsPalleteCreate[i + 1];

            //colors_used_create.push(color);

            members.push({
                email: "",
                color: color,
                readOnly: false,
                role: "member",
            });
        }
        setNumSeats(members.length);

        setSeatData(members);
    };

    const upgradeWorkspaceOrder = () => {
        const token = ReactSession.get("token");
        setPageLoading(true);
        let formData = {};
        formData.workspace_id = workspaceid;

        upgradeTeamOrderService(formData, token).then((response) => {
            let activePlanId = 0;

            if (response.success) {
                if (response.workspace) {
                    if (response.workspace.title) {
                        let nameWorkspace = response.workspace.title.slice(
                            0,
                            30
                        );
                        setFormValues({
                            ...formValues,
                            workspaceName: nameWorkspace,
                        });
                    } else {
                        let nameWorkspace = (user.first_name + "'s Team").slice(
                            0,
                            30
                        );

                        setFormValues({
                            ...formValues,
                            workspaceName: nameWorkspace,
                        });
                    }
                }

                if (response.order) {
                    let order = response.order;
                    setOrderDetails(order);
                    if (response.order.plan_id) {
                        activePlanId = response.order.plan_id;
                    }

                    colorsPalleteCreate = colorsPalleteCreate.filter(function (
                        el
                    ) {
                        return colors_used_create.indexOf(el) < 0;
                    });

                    const randomIndex = Math.floor(
                        Math.random() * colorsPalleteCreate.length
                    );

                    // Access the element at the random index
                    //const color = colorsPalleteCreate[randomIndex];

                    const color = colorsPalleteCreate[0];

                    //colors_used_create.push(color);

                    addAdditionalDefaultMembers(
                        [
                            {
                                email: response.user.email,
                                color: color,
                                readOnly: true,
                                role: "admin",
                            },
                        ],
                        2
                    );
                }

                if (response.plans && response.plans.length) {
                    response.plans.map((item) => {
                        if (item.billing_cycle === "monthly") {
                            setMonthlyPlan(item);
                        } else if (item.billing_cycle === "yearly") {
                            setYearlyPlan(item);
                        }

                        if (item.id === activePlanId) {
                            if (item.billing_cycle === "monthly") {
                                setSelectedPlan("monthly");
                                calculateNextBillingDate("monthly", new Date());
                            } else {
                                setSelectedPlan("yearly");
                                calculateNextBillingDate("yearly", new Date());
                            }
                        }
                    });
                }

                if (response.seats) {
                    setMaxSeats(+response.seats);
                }
                getPaymentMethods();
            } else {
                setPageLoading(false);
                showToast(response.message, "error");
            }
        });
    };

    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method, index) {
            render.push(
                <>
                    <div
                        key={"methods" + method.id}
                        className="checkboxes radio_btn_class gender_btn d-flex card-radio-wrap"
                    >
                        <div className="d-flex addedpayment-cards-wrap">
                            <div className="card-left-side">
                                <label
                                    htmlFor={method.stripe_payment_method_id}
                                    className="mt-0"
                                >
                                    {method.card_expire_status!==2 &&
                                    <RadioButton
                                        id={method.stripe_payment_method_id}
                                        type="radio"
                                        isChecked={
                                            cardFormValues.payment_method_id ==
                                            method.id
                                                ? true
                                                : false
                                        }
                                        onChange={() => {
                                            setCardFormValues({
                                                ...cardFormValues,
                                                payment_method_id: method.id,
                                                card_no: "",
                                                cvv: "",
                                                exp_date: "",
                                            });
                                            setSameCardError(false);
                                        }}
                                        name="payment_method"
                                        value={method.stripe_payment_method_id}
                                        className="hidden radio_btn_input"
                                        required="required"
                                    />
                                    }
                                    <p>
                                        <img
                                            src={
                                                process.env.REACT_APP_URL +
                                                "images/" +
                                                method.brand +
                                                "-icon.svg"
                                            }
                                            alt="brand-icon"
                                        />{" "}
                                    </p>
                                </label>
                                {/*{method.default == 1 && (
                                    <span className="primary-card">Primary</span>
                                )}*/}
                            </div>

                            <div className="card-right-side">
                                {method.card_expire_status == 2 
                                ?
                                    <Tag title="Expired" type="danger-tag" />
                                :
                                <>
                                
                                </>
                                }
                                <b className="card-last-number">
                                    **** {method.last_4}{" "}
                                </b>
                            </div>
                        </div>
                    </div>
                    {(cardFormValues.payment_method_id !== "" &&
                    cardFormValues.payment_method_id === method.id
                        ? true
                        : false) && (
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={
                                "gray-color d-flex align-items-center mt-0 mb-32 crad-saved-for-future"
                            }
                            key={"methods-tick" + method.id}
                        >
                            <Icon
                                value={"check mark"}
                                size={"medium"}
                                colorClass={"success-green-200-svg"}
                            />
                            This card will be used for the future subscription
                            payments
                        </Text>
                    )}
                </>
            );
        });

        if (render.length > 0) {
            return render;
        } else {
            return <></>;
            return (
                <div className="no_payment_add">No payment methods added.</div>
            );
        }
    };
    const getPaymentMethods = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getPaymentMethodsService(token).then((response) => {
            setPageLoading(false);

            if (response.success) {
                var exist = false;
                response.payment_methods.forEach(function (method) {
                    if (method.default == 1) {

                        if(method.card_expire_status!==2){
                            exist = true;
                            setCardFormValues({
                                ...cardFormValues,
                                payment_method_id: method.id,
                            });
                        }
                    }
                });
                if (!exist) {
                    if (response.payment_methods.length > 0) {

                        if(response.payment_methods[0].card_expire_statuss!==2){
                            setCardFormValues({
                                ...cardFormValues,
                                payment_method_id: response.payment_methods[0].id,
                            });
                        }
                    }
                }
                setPaymentMethods(response.payment_methods);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const billingCycleChange = (cycle) => {
        const token = ReactSession.get("token");

        setSelectedPlan(cycle);

        let planId = 0;

        if (cycle === "monthly") {
            planId = monthlyPlan.id;
            calculateNextBillingDate("monthly", new Date());
        } else {
            planId = yearlyPlan.id;
            calculateNextBillingDate("yearly", new Date());
        }

        if (orderDetails && orderDetails.id && planId) {
            let formData = {};
            formData.plan_id = planId;
            formData.order_id = orderDetails.id;

            setBillingCycleLoading(true);

            workspaceBillCycleUpdateService(formData, token).then(
                (response) => {
                    setBillingCycleLoading(false);
                    if (response.success) {
                        if (response.order) {
                            let order = response.order;
                            setOrderDetails({ ...orderDetails, ...order });
                        }
                    } else {
                        showToast(response.message, "error");
                    }
                }
            );
        }
    };

    const checkNextBtnState = (showError = false) => {
        let error = false;

        let errorMsg =
            "One or more pieces of information are incorrect or missing. Please check before proceeding.";

        error = validateFullFormOnly(showError);

        if (!error) {
            let errorPayment =
                cardFormValues.payment_method_id == ""
                    ? validatePaymentForm(showError) || sameCardError
                    : false;
            let errorCoupon = couponError ? true : false;

            if (errorPayment || errorCoupon) {
                error = true;
                if (!errorPayment) {
                    errorMsg =
                        "You have entered an invalid promo code. Please remove it or apply a valid one before proceeding.";
                    if (showError) {
                        setAccordians(true);
                        let firsterrorid = document.getElementById(
                            "promotional-code-title"
                        )
                            ? document.getElementById("promotional-code-title")
                            : document.getElementById("promotional-code-wrap");
                        firsterrorid.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                } else if (!errorCoupon) {
                    errorMsg =
                        "Invalid payment method. Please review your credit card information before proceeding.";
                }
            }
        }

        if (error) {
            if (showError) {
                showToast(errorMsg, "error");
            }
            return "disabled";
        } else {
            return "active";
        }
    };

    const upgradeToTeamWorkSpaceApi = () => {
        if (!WorkspaceLoading && !upgradeWorkspaceMicroLoading) {
            if (checkNextBtnState(true) === "active") {
                setUpgradeWorkspaceMicroLoading(true);
                // setWorksapceLoader(true);

                const token = ReactSession.get("token");
                let formData = {};

                formData.members = seatData.filter((item) => {
                    return !(
                        item.email === null ||
                        item.email === "" ||
                        item.email === undefined
                    );
                    // && item.role !== "admin"
                });
                formData.order_id = orderDetails.id;
                formData.title = formValues.workspaceName;
                formData.promo_code = orderDetails.coupon;
                formData.plan_id = orderDetails.plan_id;
                formData.workspace_id = workspaceid;

                formData["card_no"] = encryptClient(cardFormValues.card_no);

                formData["cvv"] = encryptClient(cardFormValues.cvv);

                formData["exp_date"] = cardFormValues.exp_date;

                formData["payment_method_id"] =
                    cardFormValues.payment_method_id;

                // console.log("formdatea", formData);

                upgradeWorkspaceService(formData, token).then((response) => {
                    // setUpgradeWorkspaceMicroLoading(false);
                    // setWorksapceLoader(false);
                    if (response.success) {
                        if (response.client_secret) {
                            setFormValues({
                                ...formValues,
                                order_id: response.order_id,
                                invoice_id: response.invoice_id,
                            });

                            stripe
                                .confirmCardPayment(response.client_secret)
                                .then(handleStripeJsResult);
                        } else {
                            setUpgradeWorkspaceMicroLoading(false);
                            if (response.workspace.id) {
                                let user = ReactSession.get("user");
                                user.signup_plan = null;
                                ReactSession.set("user", user);
                                navigate(`/wsp/${response.workspace.id}`);

                                if (response.testers_emails) {
                                    showError(response.tester_emails_message);
                                }
                            }
                        }
                    } else {
                        if (
                            response.error_code &&
                            response.error_code === "same_card_twice"
                        ) {
                            setSameCardError(true);
                        }
                        setUpgradeWorkspaceMicroLoading(false);
                        showToast(response.message, "error");
                    }
                });
            } else {
                // showToast(
                //     "One or more pieces of information are incorrect or missing. Please check before proceeding.",
                //     "error",
                // );
            }
        }
    };

    function handleStripeJsResult(result) {
        if (result.error) {
            // setUpgradeWorkspaceMicroLoading(false);

            if (result.error.code == "card_declined") {
                showToast(result.error.message, "error");
            }  else if (result.error.code) {
                showToast(result.error.message, "error");
            } else {
                showToast(
                    "Some error while processing card. Please try again.",
                    "error"
                );
            }

            let formData = {};
            
            const token = ReactSession.get("token");
            
            formData.order_id = orderDetails.id;

            if(cardFormValues.payment_method_id){

                formData.payment_method_id = cardFormValues.payment_method_id;
            }
            formData.error_code = result.error.code ? result.error.code : null ;

            threeDSFailService(formData, token).then((response) => {
                setUpgradeWorkspaceMicroLoading(false);
                if (response.success) {
                    upgradeWorkspaceOrder();
                } else {
                    showToast(response.message, "error");
                }
            });

            // Show error in payment form
        } else {
            setUpgradeWorkspaceMicroLoading(false);
            setWorkspaceLoading(true);

            var formData = {
                order_id: formValues.order_id,
                invoice_id: formValues.invoice_id,
            };

            formData.workspace_id = workspaceid;

            formData.members = seatData.filter((item) => {
                return !(
                    item.email === null ||
                    item.email === "" ||
                    item.email === undefined
                );
                // && item.role !== "admin"
            });
            formData.title = formValues.workspaceName;
            formData.plan_id = orderDetails.plan_id;

            formData["payment_intent_id"] = result.paymentIntent.id;

            const token = ReactSession.get("token");

            upgradeMakePaymentService(formData, token).then((response) => {
                setWorkspaceLoading(false);

                if (response.success) {
                    if (response.workspace.id) {
                        let user = ReactSession.get("user");
                        user.signup_plan = null;
                        ReactSession.set("user", user);
                        navigate(`/wsp/${response.workspace.id}`);

                        if (response.testers_emails) {
                            showError(response.tester_emails_message);
                        }
                    }
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    }
    const countEmail = () => {
        let count = 0;
        for (const [index, teamMember] of seatData.entries()) {
            if (
                !(
                    teamMember.email === "" ||
                    teamMember.email === null ||
                    teamMember.email === undefined
                )
            ) {
                count++;
            }
        }

        return maxSeats - count;
    };

    const closeIndividualWorkspaceModal = () => {
        setOpenWorkspaceModal(false);
    };

    const openModal = () => {
        setOpenComparePlanModal(true);
    };

    const closeComparePlanModal = () => {
        setOpenComparePlanModal(false);
    };

    const comparePlanApi = () => {
        const token = ReactSession.get("token");
        setPageLoading(true);

        comparePlanService(token).then((response) => {
            if (response.success) {
                response.plans.forEach((plan) => {
                    if (plan.type === "Individual") {
                        setIndividualPlan(plan);
                    } else if (
                        plan.type === "Team" &&
                        plan.billing_cycle === "yearly"
                    ) {
                        setTeamPlanYearly(plan);
                        setActiveTeamPlan(plan);
                    } else if (
                        plan.type === "Team" &&
                        plan.billing_cycle === "monthly"
                    ) {
                        setTeamPlanMonthly(plan);
                    }
                });
                // workSpaceList();
            } else {
                setPageLoading(false);
                showToast(response.message, "error");
            }
        });
    };

    function calculateNextBillingDate(billingInterval, startDate) {
        if (billingInterval === "monthly") {
            let nextDate = startDate;

            nextDate = new Date(
                startDate.getFullYear(),
                startDate.getMonth() + 1,
                startDate.getDate()
            );
            setNextBillingDate(moment(nextDate).format("DD-MM-YYYY"));
        } else if (billingInterval === "yearly") {
            let nextDate = startDate;
            nextDate = new Date(
                startDate.getFullYear() + 1,
                startDate.getMonth(),
                startDate.getDate()
            );
            setNextBillingDate(moment(nextDate).format("DD-MM-YYYY"));
        }
        //  else {
        //     throw new Error("Invalid billing interval");
        // }
    }

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass="create-workspace-page-hold"
        >
            <Helmet>
                <title>{"Upgrade workspace"} | UserQ</title>
            </Helmet>
            <div className="create_team_workspace-page_wrap">
                {pageLoading ? (
                    <>
                        <div className="workspace-page-loader">
                            <div
                                className={
                                    "page-loader accountloading-box creditloading-box"
                                }
                            >
                                <LoadingIcon />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {WorkspaceLoading ? (
                            <>
                                <div className="creating-workspace-loader text-center">
                                    <div className="creating-workspace-loader-inner">
                                        <img
                                            src={
                                                process.env.REACT_APP_URL +
                                                "/img/icons/Team-Loader.gif"
                                            }
                                            alt="loader-img"
                                        />
                                        <Text
                                            type={"body-text-1"}
                                            fontWeight={"medium-font"}
                                        >
                                            Creating Team workspace...
                                        </Text>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="team-workspace-form-info">
                                    <div className="team-setting-min-height">
                                        <div className="workspace-form-head-wrap">
                                            <Text type={"h3"}>
                                                Upgrade the workspace to Team
                                                Plan
                                            </Text>
                                            <Link
                                                to={"#"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openModal();
                                                }}
                                                className="link-text"
                                            >
                                                Compare plans
                                            </Link>
                                        </div>
                                        <div className="create-workspace-name mt-32 mb-32">
                                            <Input
                                                type="text"
                                                id="workspaceName"
                                                value={formValues.workspaceName}
                                                onChange={(e) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        workspaceName:
                                                            e.target.value,
                                                    });
                                                    validateFullForm(
                                                        false,
                                                        `title`,
                                                        e.target.value,
                                                        true
                                                    );
                                                }}
                                                onBlur={() => {
                                                    validateFullForm(
                                                        false,
                                                        "title"
                                                    );
                                                }}
                                                label={"Workspace name"}
                                                clearField={false}
                                                error={formErrors["title"]}
                                                error_class={
                                                    formErrors.error_class
                                                }
                                                maxLength={workspaceMaxlength}
                                            />
                                        </div>
                                        <div className="invite-mamber-to-workspace">
                                            <div className="invite-member-top-info  mb-16">
                                                <Text
                                                    type={"body-text-2"}
                                                    fontWeight={
                                                        "semi-bold-font"
                                                    }
                                                >
                                                    Invite your team to join the
                                                    workspace
                                                </Text>
                                                <Text
                                                    type={"body-text-3"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={"gray-color"}
                                                >
                                                    The invitations will be sent
                                                    once the payment is
                                                    completed.
                                                </Text>
                                            </div>

                                            <div className="add-workspace-member-hold">
                                                {seatData.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="workspace-member-repeat mb-16 d-flex align-items-center"
                                                    >
                                                        <div className="workspace-email-wrap">
                                                            <EmailInput
                                                                label={`${
                                                                    index === 0
                                                                        ? "Admin"
                                                                        : `Email Address ${
                                                                              index +
                                                                              1
                                                                          }`
                                                                }`}
                                                                type="text"
                                                                value={
                                                                    seatData.length &&
                                                                    seatData[
                                                                        index
                                                                    ].email
                                                                        ? seatData[
                                                                              index
                                                                          ]
                                                                              .email
                                                                        : ""
                                                                }
                                                                id={`email_${index}`}
                                                                required={true}
                                                                leftIcon={true}
                                                                color={
                                                                    seatData[
                                                                        index
                                                                    ].color
                                                                }
                                                                readOnly={
                                                                    seatData.length &&
                                                                    seatData[
                                                                        index
                                                                    ].readOnly
                                                                        ? seatData[
                                                                              index
                                                                          ]
                                                                              .readOnly
                                                                        : ""
                                                                }
                                                                onClear={() => {
                                                                    handleEmailChange(
                                                                        index,
                                                                        ""
                                                                    );
                                                                    setFormErrors(
                                                                        {
                                                                            ...formErrors,
                                                                            [`email_${index}`]:
                                                                                null,
                                                                        }
                                                                    );
                                                                }}
                                                                onBlur={() =>
                                                                    validateFullForm(
                                                                        false,
                                                                        `email_${index}`,
                                                                        seatData[
                                                                            index
                                                                        ],
                                                                        false,
                                                                        index
                                                                    )
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const inputValue =
                                                                        e.target
                                                                            .value;

                                                                    const re =
                                                                        /^\S*$/;

                                                                    if (
                                                                        !re.test(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    ) {
                                                                        e.preventDefault();
                                                                    } else {
                                                                        handleEmailChange(
                                                                            index,
                                                                            inputValue
                                                                        );
                                                                        validateFullForm(
                                                                            false,
                                                                            `email_${index}`,
                                                                            inputValue,
                                                                            true,
                                                                            index
                                                                        );
                                                                    }
                                                                }}
                                                                onKeyDown={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ===
                                                                        " "
                                                                    ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                    }
                                                                }}
                                                                error={
                                                                    formErrors[
                                                                        `email_${index}`
                                                                    ]
                                                                }
                                                                error_class={
                                                                    formErrors.error_class
                                                                }
                                                            />
                                                        </div>
                                                        <div className="add-workspace-role">
                                                            {index === 0 ? (
                                                                <Text
                                                                    type={
                                                                        "body-text-3"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    Admin
                                                                </Text>
                                                            ) : (
                                                                <Text
                                                                    type={
                                                                        "body-text-3"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    Member
                                                                </Text>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="add-member-btn-hold d-flex align-items-center justify-content-between  mt-20">
                                                {numSeats < maxSeats && (
                                                    <div className="d-flex">
                                                        <Button
                                                            id="r_email_sign_in"
                                                            type="secondary"
                                                            label="Add member"
                                                            onClick={
                                                                handleAddMore
                                                            }
                                                            size={"medium"}
                                                            iconLeft={
                                                                <Icon
                                                                    value="add"
                                                                    size="small"
                                                                    colorClass={
                                                                        "gray-900-svg"
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                {+countEmail() !== 0 && (
                                                    <>
                                                        <span>&nbsp;</span>
                                                        <div className="seats-available-count d-flex align-items-center">
                                                            <Icon
                                                                value={"group"}
                                                                size={"small"}
                                                                colorClass={
                                                                    "gray-900-svg"
                                                                }
                                                            />
                                                            <Text
                                                                type={"caption"}
                                                            >{`${
                                                                +countEmail() ===
                                                                1
                                                                    ? "1 seat available"
                                                                    : `${countEmail()} seats available`
                                                            }`}</Text>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {numSeats === maxSeats &&
                                                +countEmail() === 0 && (
                                                    <>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <span>&nbsp;</span>
                                                            <div className="seats-available-count d-flex align-items-center">
                                                                <Icon
                                                                    value={
                                                                        "group"
                                                                    }
                                                                    size={
                                                                        "small"
                                                                    }
                                                                    colorClass={
                                                                        "gray-900-svg"
                                                                    }
                                                                />
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                >{`0 seats available`}</Text>
                                                            </div>
                                                        </div>
                                                        <ToastMessage
                                                            type={"info"}
                                                            closable={false}
                                                            icon={"profile"}
                                                            message={
                                                                <>
                                                                    To add more
                                                                    members,
                                                                    please
                                                                    contact our
                                                                    <Link
                                                                        to={
                                                                            "/r/support/contact"
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        support
                                                                        team
                                                                    </Link>
                                                                </>
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </div>
                                    </div>
                                    {user && user.signup_plan && (
                                        <div className="pay-as-you-go-link">
                                            <Link
                                                className="link-text"
                                                to="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenWorkspaceModal(true);
                                                }}
                                            >
                                                Proceed with a Pay-As-You-Go
                                                workspace instead
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div className="workspace-payment-section">
                                    <div className="workspace-billing-cycle-wrap">
                                        <div className="workspace-billing-sycle-top d-flex align-items-center justify-content-between mb-20">
                                            <Text
                                                type={"subtitle-2"}
                                                fontWeight={"semi-bold-font"}
                                            >
                                                Billing cycle
                                            </Text>

                                            <div
                                                className={`plan-type-wrap d-flex align-items-center`}
                                            >
                                                <Text
                                                    type={"body-text-3"}
                                                    cssClasses={`monthly-plan ${
                                                        selectedPlan ===
                                                        "monthly"
                                                            ? "active-plan"
                                                            : ""
                                                    }`}
                                                    fontWeight={"medium-font"}
                                                >
                                                    Monthly
                                                </Text>
                                                <ToggleButton
                                                    isChecked={
                                                        selectedPlan ===
                                                        "yearly"
                                                            ? true
                                                            : false
                                                    }
                                                    label={
                                                        <Text
                                                            type={"body-text-3"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                            cssClasses={`${
                                                                selectedPlan ===
                                                                "yearly"
                                                                    ? "active-plan"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {" "}
                                                            Yearly{" "}
                                                        </Text>
                                                    }
                                                    onChange={(e) => {
                                                        if (
                                                            !billingCycleLoading
                                                        ) {
                                                            if (
                                                                e.currentTarget
                                                                    .checked
                                                            ) {
                                                                billingCycleChange(
                                                                    "yearly"
                                                                );
                                                            } else {
                                                                billingCycleChange(
                                                                    "monthly"
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                                <div
                                                    className={`plan-offer-wrap ${
                                                        selectedPlan ===
                                                        "monthly"
                                                            ? "disabled-offer"
                                                            : ""
                                                    }`}
                                                >
                                                    <Tag
                                                        type={"pink-filled-tag"}
                                                        title={`Save ${yearlyPlan.plan_disc_perc}%`}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="workspace-teamplan-pay-option d-flex justify-content-between">
                                            <div className="workspace-teamplanpay-lft">
                                                <Text
                                                    type={"body-text-2"}
                                                    fontWeight={"medium-font"}
                                                >
                                                    Team plan
                                                </Text>
                                                <div className="seats-count-wrap d-flex align-items-center">
                                                    <Icon
                                                        value={"group"}
                                                        size={"small"}
                                                        colorClass={
                                                            "gray-700-svg"
                                                        }
                                                    />

                                                    <Text
                                                        type={"caption"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {maxSeats
                                                            ? maxSeats
                                                            : 0}{" "}
                                                        seats included
                                                    </Text>
                                                </div>
                                            </div>

                                            <div className="workspace-teampay-rht build-team-amount">
                                                {!billingCycleLoading ? (
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "semi-bold-font"
                                                        }
                                                    >
                                                        $
                                                        {selectedPlan ===
                                                        "yearly"
                                                            ? amountFormat(
                                                                  roundNumber(
                                                                      yearlyPlan.amount /
                                                                          12,
                                                                      2
                                                                  ),
                                                                  2
                                                              )
                                                            : amountFormat(
                                                                  monthlyPlan.amount,
                                                                  2
                                                              )}{" "}
                                                        <sub>
                                                            {" "}
                                                            / per month <br />
                                                            <span className="gray-color">
                                                                billed{" "}
                                                                {selectedPlan}
                                                            </span>
                                                        </sub>
                                                    </Text>
                                                ) : (
                                                    <Skeleton
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="workspace-promocode-wrap mt-20"
                                            id="promotional-code-wrap"
                                        >
                                            <Accordian
                                                title={"I have Promo Code"}
                                                Accordion_small={true}
                                                open={accordians ? true : false}
                                                onChange={() => {
                                                    setAccordians(!accordians);
                                                }}
                                            >
                                                <Text
                                                    type={"body-text-3"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={"color-black"}
                                                    id={
                                                        "promotional-code-title"
                                                    }
                                                >
                                                    Enter your promotional code
                                                    here. Click “Apply” to
                                                    update your order.
                                                </Text>

                                                <PromoCode
                                                    discount={
                                                        couponValues.discount
                                                    }
                                                    applyCoupon={() => {
                                                        setCouponError("");
                                                        applyCouponApi();
                                                    }}
                                                    removeCoupon={() => {
                                                        if (couponError) {
                                                            setCouponError("");
                                                            setCouponValues({
                                                                ...couponValues,
                                                                promo_code: "",
                                                            });
                                                        } else {
                                                            setCouponError("");
                                                            removePromoCode();
                                                        }
                                                    }}
                                                    formErrors={formErrors}
                                                    formValues={couponValues}
                                                    coupon_code={
                                                        couponValues.promo_code
                                                    }
                                                    couponLoading={
                                                        couponLoading
                                                    }
                                                    setFormValues={
                                                        setCouponValues
                                                    }
                                                    coupon_code_desc={
                                                        couponValues.description
                                                    }
                                                    couponError={couponError}
                                                    section={"checkout"}
                                                />
                                                {/* </div> */}
                                            </Accordian>
                                        </div>

                                        {orderDetails && orderDetails.coupon && (
                                            <div className="offer-implemented-wrap d-flex align-items-center justify-content-between mb-12">
                                                <Text
                                                    type={"caption"}
                                                    cssClasses={"gray-color"}
                                                >
                                                    Promo Code
                                                </Text>
                                                <Text
                                                    type={"caption"}
                                                    cssClasses={"green-color"}
                                                >
                                                    -
                                                    {orderDetails.discount_desc}
                                                </Text>
                                            </div>
                                        )}

                                        {selectedPlan === "yearly" &&
                                            yearlyPlan &&
                                            yearlyPlan.discount_perc > 0 && (
                                                <div className="offer-implemented-wrap d-flex align-items-center justify-content-between mb-12">
                                                    <Text
                                                        type={"caption"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                    >
                                                        Yearly plan discount
                                                    </Text>
                                                    <Text
                                                        type={"caption"}
                                                        cssClasses={
                                                            "green-color"
                                                        }
                                                    >
                                                        {`-${yearlyPlan.discount_perc}%`}
                                                    </Text>
                                                </div>
                                            )}

                                        <div className="plan-total-bill d-flex align-items-center justify-content-between">
                                            <Text
                                                type={"body-text-1"}
                                                fontWeight={"medium-font"}
                                            >
                                                Total payment
                                            </Text>
                                            <Text
                                                type={"h4"}
                                                cssClasses={
                                                    "d-flex align-items-center total-amount-hold"
                                                }
                                            >
                                                {selectedPlan === "yearly" && (
                                                    <Text
                                                        type={"body-text-3"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        $
                                                        {amountFormat(
                                                            yearlyPlan.plan_price,
                                                            2
                                                        )}
                                                    </Text>
                                                )}

                                                <span>
                                                    $
                                                    {amountFormat(
                                                        orderDetails.total_amount,
                                                        2
                                                    )}
                                                </span>
                                            </Text>
                                        </div>

                                        <div className="workspace-add-card-wrap mt-32">
                                            <div className="added-payment-method-cards-wrapper row">
                                                {paymentMethods.length > 0 && (
                                                    <Text type={"overline"}>
                                                        SAVED CARD
                                                        {paymentMethods.length >
                                                        1
                                                            ? "S"
                                                            : ""}
                                                    </Text>
                                                )}
                                                {renderPaymentMethods()}
                                                {paymentMethods.length > 0 && !cardFormVisible && 
                                                <div className="m-auto">
                                                    <Button
                                                        onClick={() => {
                                                            setCardFormVisible(
                                                                true
                                                            );
                                                        }}
                                                        type="ghost"
                                                        label={`Add new card`}
                                                        size={"small"}
                                                        iconLeft={
                                                            <Icon
                                                                value="add"
                                                                size="small"
                                                                colorClass="mr-2"
                                                            />
                                                        }
                                                    />
                                                </div>
                                                }
                                            </div>
                                            {(cardFormVisible || paymentMethods.length ==0) &&
                                            <>
                                            <CreditCardForm
                                                formValues={cardFormValues}
                                                setFormValues={(
                                                    cardFormValues
                                                ) => {
                                                    setCardFormValues(
                                                        cardFormValues
                                                    );
                                                    setSameCardError(false);
                                                }}
                                                isValid={
                                                    !validatePaymentForm() &&
                                                    formValues.payment_method_id ==
                                                        ""
                                                        ? true
                                                        : false
                                                }
                                                isInValid={
                                                    sameCardError ? true : false
                                                }
                                            />

                                            {!validatePaymentForm() && (
                                                <Text
                                                    type={"body-text-3"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={
                                                        "gray-color d-flex align-items-center mt-20 crad-saved-for-future"
                                                    }
                                                >
                                                    <Icon
                                                        value={"check mark"}
                                                        size={"medium"}
                                                        colorClass={
                                                            "success-green-200-svg"
                                                        }
                                                    />
                                                    This card will be used for
                                                    the future subscription
                                                    payments
                                                </Text>
                                            )}

                                            {/* Other components for the workspace payment section */}
                                            <div className="workspace-card-info-text mt-20">
                                                <Text
                                                    type={"caption"}
                                                    cssClasses={
                                                        "gray-color d-flex align-items-center"
                                                    }
                                                >
                                                    <Icon
                                                        value={"lock"}
                                                        size={"medium"}
                                                        colorClass={
                                                            "gray-700-svg"
                                                        }
                                                    />
                                                    This is a secure 128-bit SSL
                                                    encrypted payment
                                                </Text>
                                            </div>
                                            </>
                                            }
                                            <div className="workspace-payment-button mt-32 mb-32">
                                                <Button
                                                    type="primary"
                                                    cssclass={"w-100"}
                                                    label={`Pay $${amountFormat(
                                                        orderDetails.total_amount,
                                                        2
                                                    )}`}
                                                    size={"large"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        upgradeToTeamWorkSpaceApi();
                                                    }}
                                                    state={checkNextBtnState(
                                                        false
                                                    )}
                                                    microLoading={
                                                        upgradeWorkspaceMicroLoading
                                                    }
                                                />
                                            </div>

                                            <div className="next-billing-plan-info text-center">
                                                <Text
                                                    type={"caption"}
                                                    cssClasses={"gray-color"}
                                                >
                                                    Your next billing date for
                                                    the subscription will be on
                                                    {" " + nextBillingDate}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {openComparePlanModal && (
                                    <ComparePlanModal
                                        open={openComparePlanModal}
                                        close={closeComparePlanModal}
                                        cssClass={"compare-modal-wrap"}
                                        body={
                                            <>
                                                <div className="modal-header-top">
                                                    <Text type="h3">
                                                        Compare plans
                                                    </Text>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        onClick={() => {
                                                            closeComparePlanModal();
                                                        }}
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            <Icon
                                                                value="Close"
                                                                size="medium"
                                                                hover={true}
                                                            />
                                                        </span>
                                                    </button>
                                                </div>

                                                <div className="plan-options-hold row none-for-desktop">
                                                    <div className="plan-filter mb-32">
                                                        <FilterButtons
                                                            options={[
                                                                "Pay-As-You-Go",
                                                                `Team plan`,
                                                            ]}
                                                            selected={
                                                                filterOption
                                                            }
                                                            onChange={(
                                                                item
                                                            ) => {
                                                                setFilterOption(
                                                                    item
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {filterOption ==
                                                        "Pay-As-You-Go" && (
                                                        <>
                                                            {individualPlan && (
                                                                <div className="col-md-6 plan-options-repeat">
                                                                    <div className="plan-options-data p-32">
                                                                        <div className="plan-icon text-center">
                                                                            <Icon
                                                                                value="user"
                                                                                size={
                                                                                    "large"
                                                                                }
                                                                                colorClass={
                                                                                    "gray-900-svg"
                                                                                }
                                                                            />
                                                                        </div>

                                                                        <div className="plan-top-area text-center">
                                                                            <Text
                                                                                type={
                                                                                    "h1"
                                                                                }
                                                                                cssClasses={
                                                                                    "mt-12 mb-12"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    individualPlan.title
                                                                                }
                                                                            </Text>

                                                                            <Text
                                                                                cssClasses={
                                                                                    "plan-name purple-color"
                                                                                }
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    individualPlan.type
                                                                                }{" "}
                                                                                workspace
                                                                            </Text>

                                                                            <Text
                                                                                type={
                                                                                    "body-text-3"
                                                                                }
                                                                                cssClasses={
                                                                                    "gray-color mt-32 mb-32"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    individualPlan.subtitle
                                                                                }
                                                                            </Text>
                                                                        </div>

                                                                        <div className="plan-switch-wrap">
                                                                            &nbsp;
                                                                        </div>

                                                                        <div className="plan-row-hold">
                                                                            <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                                                <div>
                                                                                    <Text
                                                                                        type={
                                                                                            "subtitle-2"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "semi-bold-font"
                                                                                        }
                                                                                    >
                                                                                        Workspace
                                                                                        pricing
                                                                                    </Text>
                                                                                </div>
                                                                                <div>
                                                                                    <Text
                                                                                        type={
                                                                                            "h4"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "semi-bold-font"
                                                                                        }
                                                                                    >
                                                                                        {individualPlan.price
                                                                                            ? "$" +
                                                                                              amountFormat(
                                                                                                  individualPlan.price,
                                                                                                  2
                                                                                              )
                                                                                            : "Free"}
                                                                                    </Text>
                                                                                </div>
                                                                            </div>

                                                                            <div className="plan-row-repeat d-flex justify-content-between">
                                                                                <div className="plan-row-left">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "gray-color"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        Team
                                                                                        size
                                                                                    </Text>
                                                                                </div>

                                                                                <div className="plan-row-right">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "black-color d-flex align-items-center max-seat-col"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        <Icon
                                                                                            value="user"
                                                                                            size={
                                                                                                "medium"
                                                                                            }
                                                                                            colorClass={
                                                                                                "gray-900-svg"
                                                                                            }
                                                                                        />
                                                                                        {
                                                                                            individualPlan.max_seats
                                                                                        }
                                                                                    </Text>
                                                                                </div>
                                                                            </div>

                                                                            <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                                                <div className="plan-row-left">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "gray-color"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        Test
                                                                                        publishing
                                                                                        fee
                                                                                    </Text>
                                                                                </div>
                                                                                <div className="plan-row-right">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        {"$" +
                                                                                            amountFormat(
                                                                                                individualPlan.publishing_fees,
                                                                                                2
                                                                                            )}
                                                                                    </Text>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {filterOption ==
                                                        "Team plan" && (
                                                        <>
                                                            {activeTeamPlan && (
                                                                <div className="col-md-6 plan-options-repeat">
                                                                    <div className="plan-options-data p-32">
                                                                        <div className="plan-icon text-center">
                                                                            <Icon
                                                                                value="group"
                                                                                size={
                                                                                    "large"
                                                                                }
                                                                                colorClass={
                                                                                    "gray-900-svg"
                                                                                }
                                                                            />
                                                                        </div>

                                                                        <div className="plan-top-area text-center">
                                                                            <Text
                                                                                type={
                                                                                    "h1"
                                                                                }
                                                                                cssClasses={
                                                                                    "mt-12 mb-12"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    activeTeamPlan.title
                                                                                }
                                                                            </Text>

                                                                            <Text
                                                                                cssClasses={
                                                                                    "plan-name purple-color"
                                                                                }
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    activeTeamPlan.type
                                                                                }{" "}
                                                                                workspace
                                                                            </Text>

                                                                            <Text
                                                                                type={
                                                                                    "body-text-3"
                                                                                }
                                                                                cssClasses={
                                                                                    "gray-color mt-32 mb-32"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    activeTeamPlan.subtitle
                                                                                }
                                                                            </Text>
                                                                        </div>

                                                                        <div className="plan-switch-wrap">
                                                                            <div
                                                                                className={`plan-type-wrap d-flex align-items-center`}
                                                                            >
                                                                                <Text
                                                                                    type={
                                                                                        "body-text-3"
                                                                                    }
                                                                                    cssClasses={`monthly-plan ${
                                                                                        activeTeamPlan.billing_cycle ===
                                                                                        "monthly"
                                                                                            ? "active-plan"
                                                                                            : ""
                                                                                    }`}
                                                                                    fontWeight={
                                                                                        "medium-font"
                                                                                    }
                                                                                >
                                                                                    Monthly
                                                                                </Text>
                                                                                <ToggleButton
                                                                                    isChecked={
                                                                                        activeTeamPlan.billing_cycle ===
                                                                                        "yearly"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    label={
                                                                                        <Text
                                                                                            type={
                                                                                                "body-text-3"
                                                                                            }
                                                                                            fontWeight={
                                                                                                "medium-font"
                                                                                            }
                                                                                            cssClasses={`${
                                                                                                activeTeamPlan.billing_cycle ===
                                                                                                "yearly"
                                                                                                    ? "active-plan"
                                                                                                    : ""
                                                                                            }`}
                                                                                        >
                                                                                            {" "}
                                                                                            Yearly{" "}
                                                                                        </Text>
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        activeTeamPlan.billing_cycle ===
                                                                                        "yearly"
                                                                                            ? setActiveTeamPlan(
                                                                                                  teamPlanMonthly
                                                                                              )
                                                                                            : setActiveTeamPlan(
                                                                                                  teamPlanYearly
                                                                                              );
                                                                                    }}
                                                                                />

                                                                                {teamPlanYearly && (
                                                                                    <div
                                                                                        className={`plan-offer-wrap ${
                                                                                            activeTeamPlan.billing_cycle ===
                                                                                            "monthly"
                                                                                                ? "disabled-offer"
                                                                                                : ""
                                                                                        }`}
                                                                                    >
                                                                                        <Tag
                                                                                            type={
                                                                                                "pink-filled-tag"
                                                                                            }
                                                                                            title={`Save ${teamPlanYearly.plan_disc_perc}%`}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="plan-row-hold">
                                                                            <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                                                <div>
                                                                                    <Text
                                                                                        type={
                                                                                            "subtitle-2"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "semi-bold-font"
                                                                                        }
                                                                                    >
                                                                                        Workspace
                                                                                        pricing
                                                                                    </Text>
                                                                                </div>
                                                                                <div>
                                                                                    <Text
                                                                                        type={
                                                                                            "h4"
                                                                                        }
                                                                                    >
                                                                                        {activeTeamPlan.amount
                                                                                            ? "$" +
                                                                                              (activeTeamPlan.billing_cycle ==
                                                                                              "yearly"
                                                                                                  ? amountFormat(
                                                                                                        activeTeamPlan.amount /
                                                                                                            12,
                                                                                                        2
                                                                                                    )
                                                                                                  : amountFormat(
                                                                                                        activeTeamPlan.amount,
                                                                                                        2
                                                                                                    ))
                                                                                            : "Free"}{" "}
                                                                                        <sub>
                                                                                            /
                                                                                            per
                                                                                            month
                                                                                            <br />
                                                                                            <span className="gray-color">
                                                                                                billed{" "}
                                                                                                {activeTeamPlan.billing_cycle ==
                                                                                                "yearly"
                                                                                                    ? "yearly"
                                                                                                    : "monthly"}
                                                                                            </span>
                                                                                        </sub>{" "}
                                                                                    </Text>
                                                                                </div>
                                                                            </div>

                                                                            <div className="plan-row-repeat d-flex justify-content-between">
                                                                                <div className="plan-row-left">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "gray-color"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        Team
                                                                                        size
                                                                                    </Text>
                                                                                </div>

                                                                                <div className="plan-row-right">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "black-color d-flex align-items-center max-seat-col"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        <Icon
                                                                                            value="group"
                                                                                            size={
                                                                                                "medium"
                                                                                            }
                                                                                            colorClass={
                                                                                                "gray-900-svg"
                                                                                            }
                                                                                        />
                                                                                        {
                                                                                            activeTeamPlan.max_seats
                                                                                        }
                                                                                    </Text>
                                                                                </div>
                                                                            </div>

                                                                            <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                                                <div className="plan-row-left">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "gray-color"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        Test
                                                                                        publishing
                                                                                        fee
                                                                                    </Text>
                                                                                </div>
                                                                                <div className="plan-row-right">
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-2"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            activeTeamPlan.publishing_fees
                                                                                        }
                                                                                    </Text>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>

                                                <div className="plan-options-hold row none-for-mobile">
                                                    {individualPlan && (
                                                        <div className="col-md-6 plan-options-repeat">
                                                            <div className="plan-options-data p-32">
                                                                <div className="plan-icon text-center">
                                                                    <Icon
                                                                        value="user"
                                                                        size={
                                                                            "large"
                                                                        }
                                                                        colorClass={
                                                                            "gray-900-svg"
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="plan-top-area text-center">
                                                                    <Text
                                                                        type={
                                                                            "h1"
                                                                        }
                                                                        cssClasses={
                                                                            "mt-12 mb-12"
                                                                        }
                                                                        fontWeight={
                                                                            "bold-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            individualPlan.title
                                                                        }
                                                                    </Text>

                                                                    <Text
                                                                        cssClasses={
                                                                            "plan-name purple-color"
                                                                        }
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            individualPlan.type
                                                                        }{" "}
                                                                        workspace
                                                                    </Text>

                                                                    <Text
                                                                        type={
                                                                            "body-text-3"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color mt-32 mb-32"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            individualPlan.subtitle
                                                                        }
                                                                    </Text>
                                                                </div>

                                                                <div className="plan-switch-wrap">
                                                                    &nbsp;
                                                                </div>

                                                                <div className="plan-row-hold">
                                                                    <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                                        <div>
                                                                            <Text
                                                                                type={
                                                                                    "subtitle-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "semi-bold-font"
                                                                                }
                                                                            >
                                                                                Workspace
                                                                                pricing
                                                                            </Text>
                                                                        </div>
                                                                        <div>
                                                                            <Text
                                                                                type={
                                                                                    "h4"
                                                                                }
                                                                                fontWeight={
                                                                                    "semi-bold-font"
                                                                                }
                                                                            >
                                                                                {individualPlan.price
                                                                                    ? "$" +
                                                                                      amountFormat(
                                                                                          individualPlan.price,
                                                                                          2
                                                                                      )
                                                                                    : "Free"}
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    <div className="plan-row-repeat d-flex justify-content-between">
                                                                        <div className="plan-row-left">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                cssClasses={
                                                                                    "gray-color"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                Team
                                                                                size
                                                                            </Text>
                                                                        </div>

                                                                        <div className="plan-row-right">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                cssClasses={
                                                                                    "black-color d-flex align-items-center max-seat-col"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                <Icon
                                                                                    value="user"
                                                                                    size={
                                                                                        "medium"
                                                                                    }
                                                                                    colorClass={
                                                                                        "gray-900-svg"
                                                                                    }
                                                                                />
                                                                                {
                                                                                    individualPlan.max_seats
                                                                                }
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                                        <div className="plan-row-left">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                cssClasses={
                                                                                    "gray-color"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                Test
                                                                                publishing
                                                                                fee
                                                                            </Text>
                                                                        </div>
                                                                        <div className="plan-row-right">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                {"$" +
                                                                                    amountFormat(
                                                                                        individualPlan.publishing_fees,
                                                                                        2
                                                                                    )}
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                                <div className="plan-row-left">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        UserQ
                                                                        panel
                                                                    </Text>
                                                                </div>
                                                                <div className="plan-row-right">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses={
                                                                            "black-color"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            individualPlan.researcher_fees
                                                                        }
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "caption"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color per-response-text"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        per
                                                                        response
                                                                    </Text>
                                                                </div>
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {activeTeamPlan && (
                                                        <div className="col-md-6 plan-options-repeat">
                                                            <div className="plan-options-data p-32">
                                                                <div className="plan-icon text-center">
                                                                    <Icon
                                                                        value="group"
                                                                        size={
                                                                            "large"
                                                                        }
                                                                        colorClass={
                                                                            "gray-900-svg"
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="plan-top-area text-center">
                                                                    <Text
                                                                        type={
                                                                            "h1"
                                                                        }
                                                                        cssClasses={
                                                                            "mt-12 mb-12"
                                                                        }
                                                                        fontWeight={
                                                                            "bold-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            activeTeamPlan.title
                                                                        }
                                                                    </Text>

                                                                    <Text
                                                                        cssClasses={
                                                                            "plan-name purple-color"
                                                                        }
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            activeTeamPlan.type
                                                                        }{" "}
                                                                        workspace
                                                                    </Text>

                                                                    <Text
                                                                        type={
                                                                            "body-text-3"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color mt-32 mb-32"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            activeTeamPlan.subtitle
                                                                        }
                                                                    </Text>
                                                                </div>

                                                                <div className="plan-switch-wrap">
                                                                    <div
                                                                        className={`plan-type-wrap d-flex align-items-center`}
                                                                    >
                                                                        <Text
                                                                            type={
                                                                                "body-text-3"
                                                                            }
                                                                            cssClasses={`monthly-plan ${
                                                                                activeTeamPlan.billing_cycle ===
                                                                                "monthly"
                                                                                    ? "active-plan"
                                                                                    : ""
                                                                            }`}
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                        >
                                                                            Monthly
                                                                        </Text>
                                                                        <ToggleButton
                                                                            isChecked={
                                                                                activeTeamPlan.billing_cycle ===
                                                                                "yearly"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            label={
                                                                                <Text
                                                                                    type={
                                                                                        "body-text-3"
                                                                                    }
                                                                                    fontWeight={
                                                                                        "medium-font"
                                                                                    }
                                                                                    cssClasses={`${
                                                                                        activeTeamPlan.billing_cycle ===
                                                                                        "yearly"
                                                                                            ? "active-plan"
                                                                                            : ""
                                                                                    }`}
                                                                                >
                                                                                    {" "}
                                                                                    Yearly{" "}
                                                                                </Text>
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                activeTeamPlan.billing_cycle ===
                                                                                "yearly"
                                                                                    ? setActiveTeamPlan(
                                                                                          teamPlanMonthly
                                                                                      )
                                                                                    : setActiveTeamPlan(
                                                                                          teamPlanYearly
                                                                                      );
                                                                            }}
                                                                        />

                                                                        {teamPlanYearly && (
                                                                            <div
                                                                                className={`plan-offer-wrap ${
                                                                                    activeTeamPlan.billing_cycle ===
                                                                                    "monthly"
                                                                                        ? "disabled-offer"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                <Tag
                                                                                    type={
                                                                                        "pink-filled-tag"
                                                                                    }
                                                                                    title={`Save ${teamPlanYearly.plan_disc_perc}%`}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="plan-row-hold">
                                                                    <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                                        <div>
                                                                            <Text
                                                                                type={
                                                                                    "subtitle-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "semi-bold-font"
                                                                                }
                                                                            >
                                                                                Workspace
                                                                                pricing
                                                                            </Text>
                                                                        </div>
                                                                        <div>
                                                                            <Text
                                                                                type={
                                                                                    "h4"
                                                                                }
                                                                            >
                                                                                {activeTeamPlan.amount
                                                                                    ? "$" +
                                                                                      (activeTeamPlan.billing_cycle ==
                                                                                      "yearly"
                                                                                          ? amountFormat(
                                                                                                activeTeamPlan.amount /
                                                                                                    12,
                                                                                                2
                                                                                            )
                                                                                          : amountFormat(
                                                                                                activeTeamPlan.amount,
                                                                                                2
                                                                                            ))
                                                                                    : "Free"}{" "}
                                                                                <sub>
                                                                                    /
                                                                                    per
                                                                                    month
                                                                                    <br />
                                                                                    <span className="gray-color">
                                                                                        billed{" "}
                                                                                        {activeTeamPlan.billing_cycle ==
                                                                                        "yearly"
                                                                                            ? "yearly"
                                                                                            : "monthly"}
                                                                                    </span>
                                                                                </sub>{" "}
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    <div className="plan-row-repeat d-flex justify-content-between">
                                                                        <div className="plan-row-left">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                cssClasses={
                                                                                    "gray-color"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                Team
                                                                                size
                                                                            </Text>
                                                                        </div>

                                                                        <div className="plan-row-right">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                cssClasses={
                                                                                    "black-color d-flex align-items-center max-seat-col"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                <Icon
                                                                                    value="group"
                                                                                    size={
                                                                                        "medium"
                                                                                    }
                                                                                    colorClass={
                                                                                        "gray-900-svg"
                                                                                    }
                                                                                />
                                                                                {
                                                                                    activeTeamPlan.max_seats
                                                                                }
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                                        <div className="plan-row-left">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                cssClasses={
                                                                                    "gray-color"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                Test
                                                                                publishing
                                                                                fee
                                                                            </Text>
                                                                        </div>
                                                                        <div className="plan-row-right">
                                                                            <Text
                                                                                type={
                                                                                    "body-text-2"
                                                                                }
                                                                                fontWeight={
                                                                                    "medium-font"
                                                                                }
                                                                            >
                                                                                {
                                                                                    activeTeamPlan.publishing_fees
                                                                                }
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                                <div className="plan-row-left">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        UserQ
                                                                        panel
                                                                    </Text>
                                                                </div>
                                                                <div className="plan-row-right">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        cssClasses={
                                                                            "black-color"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            activeTeamPlan.researcher_fees
                                                                        }
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "caption"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color per-response-text"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        per
                                                                        response
                                                                    </Text>
                                                                </div>
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        }
                                    />
                                )}
                                {openWorkspaceModal && (
                                    <IndividualWorkspaceModal
                                        openModal={openWorkspaceModal}
                                        closeModal={
                                            closeIndividualWorkspaceModal
                                        }
                                        workspaceCount={0}
                                        firstName={firstName}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </LayoutResearcher>
    );
};
export default UpgradeWorkspace;