import { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import {Box} from "../../../themes/userq/Box/Box";
import { Text } from "../../../themes/userq/Text/Text";
import BubbleChart from "./bubble_chart_v2";
import exportAsImage from "../../../../lib/exportAsImage";
import { Icon } from "../../../themes/userq/Icon/Icon";
export const AppsStatsBox = ({apps, title}) =>{

	const [width, setWidth] = useState(0);

	const divref = useRef();
	const exportPngRef = useRef();

	useEffect(()=>{
		if(divref && divref.current ){

			setWidth(divref.current.getBoundingClientRect().width-48);
		}
	},[divref]);

	useEffect(() => {
		function handleResize() {

			if(divref && divref.current ){

				setWidth(divref.current.getBoundingClientRect().width-48);

			}

		}

		window.addEventListener('resize', handleResize)
	})
	const renderAppsBubbleChart = () => {


		let cheight = 400;


		var lChartData = [];

		var index = 0;

		var data = apps;

		var max = 1;



		for (var key in data) {

			if(max < data[key]){
				max = data[key];
			}
		}
		var area = 0

		for (var key in data) {

			area += Math.PI*50*50;

			lChartData.push({
				label: key,
				value: data[key] == max ? 30 : 15,
				labelValue:  data[key]
			});
			index++;

		}
		if(area > cheight*(width-48)){

			if(width > 0){
				cheight = area / (width-48);
			}
		}
		if (lChartData.length > 0 && width >0) {
			return (
				<div className="most-usedapp-graph-wrap" style={{width:"100%"}}><BubbleChart data={{ "children": lChartData }} width={width} height={cheight} /></div>
			);
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}


	return (
		<div className="app-box position-relative chart-download-with-heading" >
			<div  className="mb-20 box-outer-heading">
				<Text type="h4">{title}</Text>
			</div>
			<div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title)}>
				<Icon value={'download'} size={'small'} hover={'true'}></Icon>
				<div className="tooltip-wrapper top-tooltip">
					<p className="body-text body-text-2 normal-font ">Download</p>
				</div>
			</div>

			<div ref={exportPngRef}>
				<Box size={"large"} >
					<div ref={divref}>
						{renderAppsBubbleChart()}
					</div>
				</Box>
			</div></div>);
}