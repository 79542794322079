import { Icon } from "../Icon/Icon";
import { useEffect, useRef, useState } from "react";
import { Preview } from "../Preview/preview";
import "./QuestionImage.css";
import { Button } from "../Button/Button";

export  const TestResultQuestionImage = ({url, type})=>{

    const [image_url, setImageURL] = useState(url?process.env.REACT_APP_IMG_URL+"/"+url:null);

    const [previewModal, setPreviewModal] = useState({open:false});

    
    useEffect(()=>{

        setImageURL(url?process.env.REACT_APP_IMG_URL+"/"+url:null);

    },[url]);

    
    
    
    return (<><div className="question-result-img dropdown-toggle-disabled" onClick={(e)=>{
        e.preventDefault();
        e.stopPropagation();
        setPreviewModal({open: true})
    }}>
            {type=="button"
            ?
            <Button 
                type={"secondary"} 
                size="small" 
                label={"View image"}
                iconLeft={<Icon value={"image"} size="medium" />}
                onClick={(e)=>{
                    e.preventDefault();
        e.stopPropagation();
        setPreviewModal({open: true});
        return false;
                }}
            />
            :
            <>
            <img src={image_url} />
            <div className="view-full-view">
                <Icon value={"eye"} size="medium" colorClass={'gray-50-svg eye-icon'}/>  
            </div>
            </>
            }
        </div>
        <Preview 
            open={previewModal.open} 
            img={image_url}
            close={()=>{
                setPreviewModal({...previewModal, open: false});
            }}
        />
        </>
    );

}