import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import {
    getLoadMoreQuestionData,
    getResearcherTestResultData,
    getTestQuestionResultData
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import { getCurrentPlansService } from "../../../services/plans";
import HideParticipant from './dialog/hide-participant';
import moment from "moment";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { Dropdown } from "react-bootstrap";
import ReportAnswer from "./dialog/report-answer";
import { atom, useRecoilState } from 'recoil';
import InputSearchField from "../../layouts/elements/fields/inputSearch";
import AnyChart from 'anychart-react'
import PieChartSingleChoice from "./components/pie_chart_singlechoice";
import { TestResultsHeader } from './../../themes/userq/TestResultsHeader/TestResultsHeader';
import { QuestionsDropdown } from '../../themes/userq/QuestionsDropdown/QuestionsDropdown';
import { ByUserTable } from '../../themes/userq/ByUserTable/ByUserTable';
import { Text } from '../../themes/userq/Text/Text';
import { Search } from './../../themes/userq/Search/Search';
import { TestResultsSidebarNavigation } from './components/test_results_sidebar_navigation';
import { AggregatedDataTable } from './../../themes/userq/AggregatedDataTable/AggregatedDataTable';
import { renderTestTitle, showToast } from '../../../lib/helpers';
import { PieStatsBox } from './components/pie_stats_box';
import { PieStatsBoxSingleChoice } from './components/pie_stats_box_singlechoice';
import { LoadingIcon } from '../../loader/loadingIconNew';
import { Box } from './../../themes/userq/Box/Box';
import { HorizontalBarChartStatsBoxMultipleChoice } from './components/horizontal_bar_chart_stats_box_multiplechoice';
import { LikertScaleChart } from '../../themes/userq/LikertScaleChart/LikertScaleChart';
import { LikertScaleByUser } from './../../themes/userq/LikertScaleByUser/LikertScaleByUser';
import { Icon } from './../../themes/userq/Icon/Icon';
import { LikertScaleAggregateData } from './../../themes/userq/LikertScaleAggregateData/LikertScaleAggregateData';
import { HorizontalBarChartStatsBoxRankingScale } from './components/horizontal_bar_chart_stats_box_rankingscale';
import { Button } from '../../themes/userq/Button/Button';
import { Helmet } from 'react-helmet';
import { RatingScaleChart } from '../../themes/userq/RatingScale/RatingScaleChart';
import { RatingMultiSelect } from '../../themes/userq/MultiSelecteDropdownList/RatingMultiSelect';

export function compare(a, b) {

    const value1 = a[1]["averageranking"];
    const value2 = b[1]["averageranking"];

    let comparison = 0;

    if (value1 > value2) {
        comparison = -1;
    } else if (value1 < value2) {
        comparison = 1;
    }
    return comparison;
}

export default function TestResultQuestions({isSharedLink}) {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    var is_introduction = location.pathname.indexOf("conclusion-questions") > 0 ? false : true;


    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [questionArray, setQuestionArray] = useState([]);
    const [isActive, setActive] = useState(0);
    const [activeMenu, setActiveMenu] = useState("chart");
    const [dropdownQuestions, setDropdownQuestions] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, test_result_id: null, hidden_result: false });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null });

    const [answerArray, setAnswerArray] = useState([]);
    const [aggregrateArray, setAggregrateArray] = useState([]);
    const [metaData, setMetaData] = useState({ questionType: "", anyOptionHaveImage:false, totalResultCount: 0, userType: "", aggregate_total_voters: 0, sort_time: "", sort_date: "", sort_vote: "", load_more: "", test_question_id: "", page: 1 });
    const [search, setSearch] = useState("");
    const [searchByUser, setSearchByUser] = useState("");
    const [rating, setRating] = useState([]);
    const [searchAggregateData, setSearchAggregateData] = useState("");
    const [searchLoading, setSearchLoading] = useState("");
    const [matrixArray, setMatrixArray] = useState([]);
    const [matrixheight, setMatrixHeight] = useState(600);
    const [chartData, setChartData] = useState({});
    const [likertQuestion, setLikertQuestion] = useState({});
    const [predictions, setPredictions] = useState({"average":null, csat:null, nps:null});

    const [sortedchartData, setSortedChartData] = useState([]);
    const colorArray = ["rgb(252,131,189)", "rgb(248,160,206)", "rgb(245,190,222)", "rgb(241,219,239)", "rgb(241,219,239)"];

    const [sortLoading, setSortLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [rankingchartData, setRankingChartData] = useState({});
    const [rankingTableData, setRankingTableData] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [aggrgateQuestionsResultCount, setAggrgateQuestionsResultCount] = useState(0);

    const projectState = atom({
        key: 'testresultproject-' + test_id,
        default: null
    });
    const [project, setProject] = useRecoilState(projectState);

    let searchController = useRef();

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
		let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                showToast(response.message, "error");
            }
        })

    }

    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';
            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';

            if(location.pathname.substr(1, 1) === 'a'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token,admin_result_token:admin_result_token  }, token).then((response) => {
                setLoading(false);

                if (response.success) {
                    if(response.workspace_project_team_member){
                        if(response.workspace_project_team_member.status!='active'){
                            navigate("/wsp/"+response.workspace_project_team_member.workspace_id+"/p/"+response.workspace_project_team_member.workspace_project_id+"/project-no-access")
                        }
                    }
                    if(response.test && response.test.workspace_project){
                        setProject(response.test.workspace_project);
                    }
                    // check test is available for sharing
                    if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
                        navigate(`/v/results/${test_id}/results-not-available`);
                    }

                    // check if guest user not validate there password
                    if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

                        navigate(`/v/results/${test_id}/password`);
                    }

                    setTest(response.test);
                    getQuestions();


                } else {
                    if(response.message == "Project not found.") {
                        navigate("/404");
                    } else if(response.message == "Test Record Not found") {
                        navigate("/404");
                    } else {
                        if(location.pathname.substr(1, 1) === 'v'){

                            if(response.test_shared_results && !response.test_password_verify){
                                return navigate(`/v/results/${test_id}/password`);
                            }
                            else if(!response.test_shared_results){
                                return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        if(location.pathname.substr(1, 1) === 'a'){

                            if(!response.test_results_available){
                                return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        //navigate("/dashboard");
                        showToast(response.message, "error");
                    }
                }
            });
        } else {
            getQuestions();
        }
    };

    const getQuestions = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getTestQuestionResultData({ test_id: test_id, type: location.pathname.split("/").pop() == 'introduction-questions' ? 'briefing' : 'debriefing',guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                var aggregate_result_count = 0;
                response.data.forEach(function(question) {
                    aggregate_result_count += question.result_count;
                })
                setAggrgateQuestionsResultCount(aggregate_result_count);
                setQuestionArray(response.data);
                changeActiveQuestion(1, response.data);

            } else {
                showToast(response.message, "error");
            }
        });
    };

    const getPlan = () => {

        if (!planType) {
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    };


    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;
    
        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [rankingTableData]);

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - test result questions";
        getTest();
        // getPlan();

    }, [location]);


    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    }

    const showPreQuestion = (type) => {
        if (type === "increase") {
            // decrease active question length
            if (isActive !== 1) {
                changeActiveQuestion(isActive - 1, questionArray);
            }
        } else {
            // increase active question length
            if (questionArray.length !== isActive) {
                changeActiveQuestion(isActive + 1, questionArray);
            }
        }
    }

    var chart1_settings = {
        id: "Aera chart 1 ",
        width: "100%",
        background: 'transparent',
        height: matrixheight,
        type: 'heatMap',
        data: matrixArray,
        colors: {
            darken: "#ff0000"
        },
        darken: {
            fill: "#ff0000"
        },
        hovered: {
            fill: "#FF66AD"
        },
        tooltip: {
            enabled: true,
            // background: "#defcfc",
            background: {
                fill: "#defcfc",
                corners: 17
            },
            fontColor: "#171637",
            fontSize: "14px",
            fontFamily: "sans-serif",
            // fontWeight: 100,
            title: false,
            separator: false,
            padding: "20px 40px",
            textAlign: "center",
            format: "{%participants}",
            offsetX: 30,
            offsetY: -30

        },
        xAxis: {
            // staggerMode:true,
            labels: {
                // rotation: -40,
                fontFamily: test.language == "ar" ? "Cairo" : "sans-serif",
                fontSize: "12px",
            }
        },
        yAxis: {
            labels: {
                fontFamily: test.language == "ar" ? "Cairo" : "sans-serif",
            }
        }

    };

    const jumptoSection = (area, questiontype) => {
        setActiveMenu(area);
        document.getElementById(area + "-" + questiontype).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });

    }

    const changeActiveQuestion = (index, data) => {
        setActive(index)
        setDropdownQuestions(false); // hide dropdown value
        setSearch(""); // hide dropdown value
        setActiveMenu("chart");
        setSearchByUser("");
        setRating([]);
        // filter data from array
        const dataAnswer = data.filter((item, indexItem) => indexItem + 1 === index)[0];


        if (dataAnswer) {

            if (dataAnswer.question_type == "multiplechoice") {
                setChartData(dataAnswer.chartDataMultipleChoice);
            }
            else {
                setChartData(dataAnswer.chartData);
            }
            //dataAnswer.result.sort((a, b) => (a.time_spent > b.time_spent) ? 1 : -1)
            setAnswerArray(dataAnswer.result);  // set all test result data list
            setAggregrateArray(dataAnswer.aggregate);
            setMetaData({
                'anyOptionHaveImage': dataAnswer.any_option_have_image ? dataAnswer.any_option_have_image : false,
                'questionType': dataAnswer.question_type,
                'totalResultCount': dataAnswer.result_count,
                'userType': (dataAnswer.question_type === 'likertscale') ? "chart" : (dataAnswer.question_type === 'free') ? "by-user" : "chart",
                'aggregate_total_voters': dataAnswer.aggregate_total_voters,
                sort_result_id: "asc",
                sort_time: "",
                sort_date: "",
                sort_vote: "",
                load_more: (dataAnswer.result_count > 10) ? true : false,
                test_question_id: dataAnswer.id,
                page: 1
            });
            if (dataAnswer.question_type == "rankingscale") {
                setRankingChartData(dataAnswer.rankingchartData);
                setRankingTableData([dataAnswer.rankingtableData]);
            }
            if (dataAnswer.question_type == "likertscale") {
                let matrixData = [];

                dataAnswer.matrix.forEach((items) => {

                    var totalheat = 0;
                    dataAnswer.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat
                        }
                    })
                    var fill = '';
                    if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 80) {
                        fill = '#5C208D';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 60 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 80) {
                        fill = '#793EAB';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 40 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 60) {
                        fill = '#9759CB';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 20 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 40) {
                        fill = '#BF8EE8';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) > 0 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 20) {
                        fill = '#EFDCFF';
                    }
                    else {
                        fill = '#E9E9E9';
                    }


                    matrixData.push({
                        x: items.x,
                        // x: items.x.length > 15 ? (test.language == "ar" ? "..." + items.x.substring(0, 14) : items.x.substring(0, 14) + "..") : items.x,
                        y: items.y,
                        // y: items.y.length > 20 ? (test.language == "ar" ? "..." + items.y.substring(0, 19) : items.y.substring(0, 19) + "...") : items.y,
                        // heat: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "%",
                        heat: items.heat,
                        fill: fill,
                        // participants:items.heat>1?items.heat+" Participants":items.heat+" Participant"
                        participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "% Participants",
                        percent_participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100),
                    })
                })


                setMatrixArray(matrixData);
                setMatrixHeight(dataAnswer.subquestionscount * 30 + 50);
                setLikertQuestion(dataAnswer.questions);
            }
            if (dataAnswer.question_type == "ratingscale") {
                
                setMatrixArray(dataAnswer.matrix);
                setLikertQuestion(dataAnswer.questions);
                setPredictions({"average":dataAnswer.average, "nps":dataAnswer.nps, "csat":dataAnswer.csat});
            }
            let sortable = [];
            for (var option in dataAnswer.chartData) {
                sortable.push([option, dataAnswer.chartData[option]]);
            }

            sortable.sort(function (a, b) {
                return b[1] - a[1];
            });

            setSortedChartData(sortable);

        }

    }

    const sortData = (type) => {
        if (type === 'time') {
            if (metaData.sort_time === 'asc') {
                sortDataApi('time', 'desc');
            } else {
                sortDataApi('time', 'asc');
            }
        } else if (type === 'date') {
            if (metaData.sort_date === 'asc') {
                sortDataApi('date', 'desc');
            } else {
                sortDataApi('date', 'asc');
            }
        } else if (type === 'vote') {
            if (metaData.sort_vote === 'asc') {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote < b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "desc" });
            } else {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote > b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "asc" });
            }
        }else if (type === 'result_id') {
            if (metaData.sort_result_id === 'asc') {
                // setAnswerArray([]);
                sortDataApi('result_id', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('result_id', 'asc');
            }
        }


    }

    const sortDataApi = (sortColumn, orderBy) => {

        //setMetaData({ ...metaData, load_more: true });
        // setLoadMoreLoading(true);
        setSortBy(sortColumn);
        setSortOrder(orderBy);
        setSortLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }

        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            admin_result_token = ReactSession.get("admin_result_token");
        }


        let token = ReactSession.get("token");
        getLoadMoreQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: metaData.questionType == 'free'?search:searchByUser,
            sort_column: sortColumn,
            order_by: orderBy,
            guest_result_token: guest_result_token,
            admin_result_token: admin_result_token
        }, token).then((response) => {
            //setLoadMoreLoading(false);
            setSortLoading(false);

            if (response.success) {
                // setAnswerArray(answerArray.concat(response.data));
                setAnswerArray(response.data);

                if (sortColumn === 'time') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "desc", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"",sort_time: "asc", sort_date: "", page: 1, load_more: response.more_records });
                    }
                }
                else if (sortColumn === 'result_id') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"desc", sort_time: "", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"asc", sort_time: "", sort_date: "", page: 1, load_more: response.more_records });
                    }
                }
                 else {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "", sort_date: "desc", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "", sort_date: "asc", page: 1, load_more: response.more_records });
                    }
                }
                // setMetaData({...metaData,page:metaData.page+1,load_more:response.more_records});

            } else {
                showToast(response.message, "error");
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            guest_result_token = ReactSession.get("admin_result_token");
        }

        let token = ReactSession.get("token");
        getLoadMoreQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: metaData.page + 1,
            type: metaData.questionType,
            search: metaData.questionType == 'free'?search:searchByUser,
            sort_column: sortBy,
            order_by: sortOrder,
            guest_result_token: guest_result_token,
            admin_result_token:admin_result_token
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setAnswerArray(answerArray.concat(response.data));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                showToast(response.message, "error");
            }
        });
    }

    // delete modal popup functionlity
    const hideParticipantModal = (id, hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true, test_result_id: id, hidden_result: hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            const getAnswerArray = answerArray;


            for (var i = 0; i < getAnswerArray.length; i++) {
                if (openDeleteAnswerModal.test_result_id === getAnswerArray[i].test_result_id) {


                    if (getAnswerArray[i].result.is_hidden === 1) {
                        getAnswerArray[i].result.is_hidden = 0;
                    } else {
                        getAnswerArray[i].result.is_hidden = 1;
                    }
                }
            }
        }
        setOpenDeleteAnswerModal({ active: false, test_result_id: null, hidden_result: false })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }

    const searchData = (search) => {



        // setMetaData({...metaData,"search":search})
        setSearch(search);


        // if search record from aggregated data
        if (metaData.userType === 'aggregated') {
            const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
            if (dataAnswer) {
                const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(search.toLowerCase()) > -1);
                setAggregrateArray(newAggregrateArray)

            }
        } else {
            setSearchLoading(true);
            //setTimeout(function () {
            if (searchController.current) {
                searchController.current.abort();

            }
            const controller = new AbortController();

            searchController.current = controller;

            let guest_result_token = '';
            if(location.pathname.substr(1, 1) === 'v'){

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';
            if(location.pathname.substr(1, 1) === 'a'){

                admin_result_token = ReactSession.get("admin_result_token");
            }


            let token = ReactSession.get("token");
            getLoadMoreQuestionData({
                test_id: test_id,
                test_question_id: metaData.test_question_id,
                page: 1,
                type: metaData.questionType,
                search: search,
                guest_result_token:guest_result_token,
                admin_result_token:admin_result_token
            }, token, searchController.current?.signal).then((response) => {


                if (response.success) {
                    setSearchLoading(false);
                    setAnswerArray(response.data);
                    setMetaData({ ...metaData, page: 1, load_more: response.more_records });

                } else {
                    setSearchLoading(false);
                    showToast(response.message, "error");
                }
            });
            // },1000);
        }

        //;
    }

    const searchByUserData = (searchByUser) => {
        // setMetaData({...metaData,"search":search})
        setSearchByUser(searchByUser);

        setSearchLoading(true);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();

        }
        const controller = new AbortController();

        searchController.current = controller;

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            admin_result_token = ReactSession.get("admin_result_token");
        }

        let token = ReactSession.get("token");
        getLoadMoreQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: searchByUser,
            guest_result_token:guest_result_token,
            admin_result_token:admin_result_token
        }, token, searchController.current?.signal).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({ ...metaData, page: 1, load_more: response.more_records });

            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    }
    const searchByRating = (rating) => {
        // setMetaData({...metaData,"search":search})
        //setSearchByUser(searchByUser);

        setSearchLoading(true);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();

        }
        const controller = new AbortController();

        searchController.current = controller;


        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            admin_result_token = ReactSession.get("admin_result_token");
        }

        let token = ReactSession.get("token");
        getLoadMoreQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            rating: rating,
            questiontype: "Survey",
            guest_result_token:guest_result_token,
            admin_result_token:admin_result_token
        }, token, searchController.current?.signal).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({ ...metaData, page: 1, load_more: response.more_records });

            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    }
    const searchByAggregateData = (searchAggregateData) => {
        setSearchAggregateData(searchAggregateData);
        const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
        if (dataAnswer) {
            const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(searchAggregateData.toLowerCase()) > -1);
            setAggregrateArray(newAggregrateArray)
        }
    }



    // highlight text if matches
    const escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    );

    const renderRankingQuestionTable = () => {

        var colors = [
            { color: "#FF6DB1", text: "" },
            { color: "#9759CB", text: "" },
            { color: "#F2C690", text: "" },
            { color: "#698CE7", text: "" },
            { color: "#31F0E5", text: "" },
            { color: "#378B10", text: "" },
            { color: "#E32F83", text: "" },
            { color: "#793EAB", text: "" },
            { color: "#D7A56A", text: "" },
            { color: "#3668E7", text: "" },
            { color: "#11BFB4", text: "" },
            { color: "#164103", text: "" },
            { color: "#FF8AC1", text: "" },
            { color: "#AC7AD5", text: "" },
            { color: "#FFE2C0", text: "" },
            { color: "#9EB4ED", text: "" },
            { color: "#90F2EC", text: "" },
            { color: "#BFF0A8", text: "" },
            { color: "#A72561", text: "" },
            { color: "#5C208D", text: "" },
            { color: "#C38D4D", text: "" },
            { color: "#0D3BB0", text: "" },
            { color: "#0A877F", text: "" },
            { color: "#A72561", text: "" },
            { color: "#5C208D", text: "" },
            { color: "#C38D4D", text: "" },
            { color: "#06236C", text: "" },
            { color: "#045A54", text: "" },
        ];

        var tableData = [];
        for (var i in rankingTableData[0])
            tableData.push([i, rankingTableData[0][i]]);
        tableData.sort(compare);

        

        return (
            <div ref={scrollContainerRef} className={`ranking-table-holder max-height-table  ${isScrolled ? 'scrolled' : ''} ${tableData && tableData.length>8 ? "responsive-table-scroll":""}`}>
                <table className="table prefer-aggregatd-data-table ranking-table">
                    <thead>
                        <tr>
                            <th>Answers</th>
                            {tableData.map(function (item, index) {
                                return (<th>{index + 1 == 1 ? "1st" : index + 1 == 2 ? "2nd" : index + 1 == 3 ? "3rd" : index + 1 + "th"}</th>)
                            })}
                            <th className="score-heading-table"><b>Score</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map(function (item, index) {
                            return (
                                <tr className={`${index == 0 ? "ranking-table-first-row" : ""}`}>
                                    <td>
                                        <div className='d-flex table-answer-column-outer'>
                                            <div style={{ backgroundColor: colors[index].color }} className='table-color-box'></div>
                                            <span className={`table-answer-column ${test.language == "ar" ? "arabic-font" : ""}`}>{item[0]}</span>
                                        </div>
                                    </td>
                                    {tableData.map(function (itemm, i) {
                                        return (
                                            <td>
                                                <div className='d-flex table-answer-column-votes'>
                                                    <span className='table-votes'>{item[1]["votes"][i + 1]}</span>
                                                    <span className='table-percentage'> ({Math.round((item[1]["votes"][i + 1] * 100 / metaData.totalResultCount) * 100) / 100}%)</span>
                                                </div>

                                            </td>
                                        )
                                    })}
                                    <td>
                                        {Math.round(item[1]["averageranking"] * 100) / 100}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* <div className='ranking-table-footer'>
                    Total: <span>{metaData.totalResultCount == 1 ? metaData.totalResultCount + ' response' : metaData.totalResultCount + ' responses'}</span>
                </div> */}
            </div>
        )
    }

    function Position(obj) {
        console.log(obj);
        console.log("obj");
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }
    const scrollto = (area) => {

        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight + document.querySelector('.test-results-header-researcher').offsetHeight + document.querySelector('.question-navigator-active-link').offsetHeight;
        console.log(area);
        var offsetTop = Position(document.getElementById(area));
        console.log(offsetTop - fixedElementHeight);
        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

    }




    return (
        <LayoutResearcher isSharedLink={isSharedLink}  fixed_header_target={true} isLoading={false} wrapClass={"researcher-test-view-overflow overflow-visible researcher-resultwrap"} resultHeaderClass={"result-header"} skipCheck={false} extendedFooter={false} activeMenu={""} openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}>
            <Helmet>
                <title>{is_introduction ? "Introduction questions" : "Conclusion questions"} {test && test.methodology ? " - "+renderTestTitle(test.methodology):""}  | UserQ</title>
            </Helmet>
            {isLoading &&
                <div className="page-loader loader_section full-page-loader">
                    <LoadingIcon />
                </div>
            }



            {!isLoading && test &&
                <div className="three-column-layout two-column-layout">
                    <TestResultsHeader project={project} isSharedLink={isSharedLink}  test={test} endedOn={test.status === 'completed' ? moment.utc(test.ended_on).format('DD-MM-Y') : ''} callbackTopbar={callbackTopbar} />
                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            {/* <Sidebar test={test} planType={planType} activeMenu={location.pathname.split("/").pop()} /> */}

                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink} 
                                methodology={(test.methodology === 'Five Seconds Test') ? "5 second test" : test.methodology}
                                selected_index={location.pathname.split("/").pop() == 'introduction-questions' ? 3 : 5}
                                test_id={(location.pathname.substr(1, 1) === 'v')?test_id:test.id}
                                screening={test && test.screening_questions  && test.screening_questions.length>0 ? "Yes" : "No"}
								
                            />

                        </div>

                        <div className="two-column-right-side">


                            {sectionLoading &&
                                <div className="page-loader accountloading-box result-sections-loader">
                                    <LoadingIcon />
                                </div>

                            }
                            {!sectionLoading && questionArray && questionArray.length > 0 &&
                                <div className="bd-question-view-wrap">
                                    {aggrgateQuestionsResultCount > 0   &&
                                    <div className="question-result-header question-result-data" >
                                        <QuestionsDropdown questionArray={questionArray} questionType={'conclusionOrIntroduction'} changeActiveQuestionProp={(index) => {
                                            changeActiveQuestion(index, questionArray);
                                            setSortBy(null);
                                            setSortOrder(null);
                                            scrollto("chart-"+metaData.questionType);
                                            }} dropdownOpen={(state) => setDropdownOpen(state)} resultCount={metaData.totalResultCount} test={test} active={isActive}/>
                                        {metaData.totalResultCount >= 1 &&
                                        <>
                                        {(metaData.questionType == 'singlechoice' || metaData.questionType == 'multiplechoice' || metaData.questionType == 'likertscale' || metaData.questionType == 'ratingscale') ?
                                            <div className="accountnaviagtion-outer result-navigation-outer question-navigator-active-link">
                                                <span
                                                    className={`${activeMenu == 'chart' ? "active" : ''}`}
                                                    onClick={() => { jumptoSection("chart", metaData.questionType) }}
                                                >
                                                    <a data-href={`#chart-${metaData.questionType}`}>Chart</a>
                                                </span>
                                                {metaData.questionType != 'ratingscale' &&
                                                <span
                                                    className={`${activeMenu == 'aggregate-data' ? "active" : ''}`}
                                                    onClick={() => { jumptoSection("aggregate-data", metaData.questionType) }}
                                                >
                                                    <a data-href={`#aggregate-data-${metaData.questionType}`}>Aggregated data</a>
                                                </span>
                                                }
                                                <span
                                                    className={`${activeMenu == 'byuser' ? "active" : ''}`}
                                                    onClick={() => { jumptoSection("byuser", metaData.questionType) }}
                                                >
                                                    <a data-href={`#byuser-${metaData.questionType}`}>By user</a>
                                                </span>
                                            </div>
                                            : <div className="accountnaviagtion-outer result-navigation-outer question-navigator-active-link border-0">
                                            </div>}
                                        </>
                                        }
                                    </div>
                                    }
                                    {metaData.totalResultCount < 1 ?

                                        <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                                            <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                                <div className="no-data-icon">
                                                    <Icon value={"attention"} colorClass={'gray-700-svg'} size={"large"} />
                                                </div>

                                                <Text type={"subtitle-1"} fontWeight="medium-font">{aggrgateQuestionsResultCount > 0 ? <>Details are not available at the moment as this question didn’t receive any response yet. Users might have skipped this question.</> : <>Details are not available at the moment as the test didn’t receive any response yet. Please come back later.</>}</Text>
                                            </div>
                                        </div>
                                        :
                                        <div className={`question-result-data  ${dropdownOpen ? "black-overlay" : ""}`}>


                                            {metaData.questionType === 'free' &&
                                                <div className='free-question-result-data test-result-inner-data'>
                                                    <div className='free-question-table-header'>
                                                        <div className='free-question-table-header-left' id="chart-free">
                                                            <Text type="subtitle-1" fontWeight="medium-font">Free text</Text>
                                                            {/* <Text type="body-text-3" fontWeight="medium-font">{metaData.totalResultCount}
                                                            {metaData.totalResultCount == 1 ? ' answer' : ' answers'}
                                                        </Text> */}
                                                        </div>
                                                        <div className='free-question-table-header-right'>
                                                            <Search
                                                                size={"small"}
                                                                placeholder="Search in answers"
                                                                onChange={(e) => searchData(e.target.value)}
                                                                onClear={() => {
                                                                    setTimeout(() => {
                                                                        setSearch("");
                                                                        searchData("")
                                                                    }, 1000)
                                                                }}
                                                                loading={searchLoading}
                                                                value={search}
                                                            />
                                                        </div>
                                                    </div>
                                                    <ByUserTable  metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={search} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearch("");
                                                                        searchData("")}}/>
                                                </div>
                                            }
                                            {metaData.questionType === 'singlechoice' &&
                                                <div className='free-question-result-data test-result-inner-data test-class'>
                                                    <div className='free-question-table-header' id="chart-singlechoice">
                                                        <div className='free-question-table-header-left'>
                                                            <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                        </div>
                                                    </div>
                                                    <div className='chart-data'>
                                                        <PieStatsBoxSingleChoice data={chartData} sortedchartData={sortedchartData} title={<span>{metaData.totalResultCount}
                                                            {metaData.totalResultCount == 1 ? ' Answer' : ' Answers'}
                                                        </span>} test={test} totalAnswers={metaData.totalResultCount} />
                                                    </div>
                                                    <div className='aggregated-data-table' id="aggregate-data-singlechoice">
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                                            </div>
                                                            <div className='free-question-table-header-right'>
                                                                <Search
                                                                    size={"small"}
                                                                    placeholder="Search in answers"
                                                                    onChange={(e) => searchByAggregateData(e.target.value)}
                                                                    onClear={() => {
                                                                        setTimeout(() => {
                                                                            setSearchAggregateData("");
                                                                            searchByAggregateData("")
                                                                        }, 0)
                                                                    }}
                                                                    loading={false}
                                                                    value={searchAggregateData}
                                                                />
                                                            </div>
                                                        </div>
                                                        <AggregatedDataTable metaData={metaData} aggregrateArray={aggregrateArray} sortData={sortData} search={searchAggregateData} test={test} clearSearch={()=>{setSearchAggregateData("");
                                                                        searchByAggregateData("")}}/>
                                                    </div>
                                                    <div className='by-user-table'>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">By user</Text>
                                                            </div>
                                                            <div className='free-question-table-header-right'>
                                                                <Search
                                                                    size={"small"}
                                                                    placeholder="Search in answers"
                                                                    onChange={(e) => searchByUserData(e.target.value)}
                                                                    onClear={() => {
                                                                        setTimeout(() => {
                                                                            setSearchByUser("");
                                                                            searchByUserData("")
                                                                        }, 0)
                                                                    }}
                                                                    loading={searchLoading}
                                                                    value={searchByUser}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="byuser-singlechoice"></div>
                                                        <ByUserTable metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={searchByUser} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearchByUser("");
                                                                        searchByUserData("")}}/>
                                                    </div>
                                                </div>
                                            }
                                            {metaData.questionType === 'multiplechoice' &&
                                                <div className='free-question-result-data test-result-inner-data'>
                                                    <div className='free-question-table-header' id="chart-multiplechoice">
                                                        <div className='free-question-table-header-left'>
                                                            <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                        </div>
                                                    </div>
                                                    <div className='chart-data' >
                                                        <div className="parti-ga-grapg-area">
                                                            <HorizontalBarChartStatsBoxMultipleChoice resultCount={metaData.totalResultCount} test={test} title={<span>{metaData.totalResultCount}{metaData.totalResultCount == 1 ? ' Answer' : ' Answers'}</span>} data={chartData} />
                                                        </div>
                                                    </div>
                                                    <div className='aggregated-data-table' id="aggregate-data-multiplechoice">
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                                            </div>
                                                            <div className='free-question-table-header-right'>
                                                                <Search
                                                                    size={"small"}
                                                                    placeholder="Search in answers"
                                                                    onChange={(e) => searchByAggregateData(e.target.value)}
                                                                    onClear={() => {
                                                                        setTimeout(() => {
                                                                            setSearchAggregateData("");
                                                                            searchByAggregateData("")
                                                                        }, 0)
                                                                    }}
                                                                    loading={false}
                                                                    value={searchAggregateData}
                                                                />
                                                            </div>
                                                        </div>
                                                        <AggregatedDataTable metaData={metaData} aggregrateArray={aggregrateArray} sortData={sortData} search={searchAggregateData} test={test} clearSearch={()=>{setSearchAggregateData("");
                                                                        searchByAggregateData("")}}/>
                                                    </div>
                                                    <div className='by-user-table'>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">By user</Text>
                                                            </div>
                                                            <div className='free-question-table-header-right'>
                                                                <Search
                                                                    size={"small"}
                                                                    placeholder="Search in answers"
                                                                    onChange={(e) => searchByUserData(e.target.value)}
                                                                    onClear={() => {
                                                                        setTimeout(() => {
                                                                            setSearchByUser("");
                                                                            searchByUserData("")
                                                                        }, 0)
                                                                    }}
                                                                    loading={searchLoading}
                                                                    value={searchByUser}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="byuser-multiplechoice"></div>
                                                        <ByUserTable metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={searchByUser} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearchByUser("");
                                                                        searchByUserData("")}}/>
                                                    </div>
                                                </div>
                                            }
                                            {metaData.questionType === 'likertscale' &&
                                                <div className='free-question-result-data test-result-inner-data'>
                                                    <div className='free-question-table-header' id="chart-likertscale">
                                                        <div className='free-question-table-header-left'>
                                                            <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                        </div>
                                                    </div>
                                                    <div className='chart-data' >
                                                        {matrixArray.length > 0 && Object.keys(likertQuestion).length !== 0 &&
                                                            <LikertScaleChart data={matrixArray} likertQuestion={likertQuestion} test={test} />
                                                        }
                                                    </div>
                                                    <div className='aggregated-data-table' id="aggregate-data-likertscale">
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                                            </div>
                                                        </div>
                                                        <div className={`likert-area-chart ${test.language == "ar" ? "arabic-font" : ""}`}>
                                                            {/* <AnyChart
                                                            {...chart1_settings}
                                                        /> */}
                                                            {matrixArray.length > 0 && Object.keys(likertQuestion).length !== 0 &&
                                                                <LikertScaleAggregateData data={matrixArray} likertQuestion={likertQuestion} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='by-user-table'>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">By user</Text>
                                                            </div>
                                                        </div>
                                                        <div id="byuser-likertscale"></div>
                                                        {Object.keys(likertQuestion).length !== 0 &&
                                                            <LikertScaleByUser likertQuestion={likertQuestion} metaData={metaData} test_id={test_id} test={test} answerArray={answerArray} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} loadMore={loadMore} loadMoreLoading={loadMoreLoading} />
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            {metaData.questionType == 'rankingscale' &&
                                                (<div className="test-result-inner-data parti-ga-grapg-area">
                                                    <div className="barchart-ranking-scale" id="chart-rankingscale">
                                                        {/* {renderRankingQuestionBarChart()} */}
                                                        <HorizontalBarChartStatsBoxRankingScale test={test} rankingchartData={rankingchartData} />
                                                    </div>
                                                    <div className="ranking-scale-table mt-40">
                                                        {renderRankingQuestionTable()}
                                                    </div>
                                                    <div className="ranking-page-infodata-wrap">
                                                        <h3>How to read this bar chart?</h3>
                                                        <p>The bar chart is based on the score each ranked option has received. The scores represent the average ranking.</p>
                                                        <h3>How the scores are calculated?</h3>
                                                        <p>Think of the score as a weighted average. Suppose you have 5 options for testers to rank. The testers’ most preferred option (ranked as #1 most of the times) is given the largest weight (in this case 5). Their least preferred option has a weight of 1. The weights are then multiplied by the numbers of testers selected a particular option.
                                                            Their sum is divided by the total number of testers.</p>
                                                        <div className="response-text-wrap mathmaticbox">
                                                            <p>X<sub>1</sub> W<sub>1</sub> + X<sub>2</sub> W<sub>2</sub>
                                                                + X<sub>3</sub> W<sub>3</sub> + ... + X<sub>n</sub> W<sub>n</sub></p>
                                                            <span>Total responses</span>
                                                        </div>
                                                        <div className="ranking-wrap-info-bottom">
                                                            <strong>Where:</strong>
                                                            <p>X - number of testers</p>
                                                            <p>W - weight of the ranked option</p>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                            {metaData.questionType === 'ratingscale' &&
                                                <div className='free-question-result-data test-result-inner-data'>
                                                    <div className='free-question-table-header' id="chart-ratingscale">
                                                        <div className='free-question-table-header-left'>
                                                            <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                        </div>
                                                    </div>
                                                    <div className='chart-data'>
                                                        
                                                        {matrixArray && Object.keys(likertQuestion).length !== 0 &&
                                                        <>
                                                        
                                                        <RatingScaleChart 
                                                            test={test} 
                                                            data={matrixArray} 
                                                            question={{"type":likertQuestion.scale_type=="Emotions"?"emoticons":likertQuestion.scale_type,"scale":likertQuestion.scale_length.match(/\d+/)[0], labels: likertQuestion.labels}} 
                                                            predictions={predictions}
                                                        />
                                                        </>
                                                        }
                                                        
                                                    </div>
                                                    
                                                    <div className='by-user-table'>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">By user</Text>
                                                            </div>
                                                        </div>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text cssClasses={"gray-color"} type="body-text-3" fontWeight="medium-font">{metaData.totalResultCount} answer{metaData.totalResultCount>1?'s':''}</Text>
                                                            </div>

                                                            <div className='free-question-table-header-right'>  
                                                                {Object.keys(likertQuestion).length !== 0 &&
                                                                <RatingMultiSelect onChange={(rating)=>{

                                                                        searchByRating(rating);
                                                                    }} 
                                                                    value={rating}
                                                                    type={
                                                                        function(){
                                                                            var scale = {"Stars":"stars","Emotions":"emoticons","Numeric":"numeric"};
                                                                            
                                                                            return scale[likertQuestion.scale_type];
                                                                            
                                                                        }()} 
                                                                    scale={likertQuestion.scale_length.match(/\d+/)[0]} />
                                                                }
                                                                {/*<Search
                                                                    label="Search"
                                                                    onChange={(e) => searchByUserData(e.target.value)}
                                                                    onClear={() => {
                                                                        setTimeout(() => {
                                                                            setSearchByUser("");
                                                                            searchByUserData("")
                                                                        }, 0)
                                                                    }}
                                                                    loading={searchLoading}
                                                                    value={searchByUser}
                                                                />*/}
                                                            </div>
                                                        </div>
                                                        <div id="byuser-ratingscale"></div>
                                                        {Object.keys(likertQuestion).length !== 0 &&
                                                            <ByUserTable  
                                                                metaData={metaData} 
                                                                answerArray={answerArray} 
                                                                sortData={sortData} 
                                                                hideParticipantModal={hideParticipantModal} 
                                                                reportAnswerModal={reportAnswerModal} 
                                                                search={search} 
                                                                test={test} 
                                                                loadMore={loadMore} 
                                                                loadMoreLoading={loadMoreLoading} 
                                                                clearSearch={()=>{
                                                                    setRating([]);
                                                                    searchByRating([]);
                                                                }}
                                                                question={{"type":likertQuestion.scale_type=="Emotions"?"emoticons":likertQuestion.scale_type,"scale":likertQuestion.scale_length.match(/\d+/)[0]}} 
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="bd-question-bottom-pagination-arrow prev-next-button-bottom">
                                                <div className="bd-que-slide-arrow tablebottompagination">
                                                    <Button
                                                        type={"secondary"}
                                                        microLoading={false}
                                                        label={"Previous question"}
                                                        size={"medium"}
                                                        onClick={(e) => {
                                                            e.preventDefault(); if (isActive > 1) {
                                                                changeActiveQuestion(isActive - 1, questionArray)
                                                            }
                                                            scrollto("chart-"+metaData.questionType);
                                                        }}
                                                        iconLeft={
                                                            <Icon value={"back-arrow"} size={"medium"} />
                                                        }
                                                        state={`${isActive === 1 ? 'disabled' : ''}`}
                                                    />
                                                    <Button
                                                        type={"secondary"}
                                                        label={"Next question"}
                                                        size={"medium"}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (isActive < questionArray.length) { changeActiveQuestion(isActive + 1, questionArray) }
                                                            scrollto("chart-"+metaData.questionType);
                                                        }}
                                                        iconRight={
                                                            <Icon value={"forward-arrow"} size={"medium"} />
                                                        }
                                                        state={`${isActive === questionArray.length ? 'disabled' : ''}`}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    }

                                    

                                </div>
                            }

                            {!sectionLoading && questionArray && questionArray.length === 0 &&
                                <div className={`no-participants-data no-questions-data no-questions-added`}>
                                    <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                        <div className="no-data-icon">
                                            <Icon value={"attention"} colorClass={'gray-700-svg'} size={"large"} />
                                        </div>

                                        <Text type={"subtitle-1"} fontWeight="medium-font">You have not added any
                                            {location.pathname.split("/").pop() === 'introduction-questions' ? ' introduction ' : ' conclusion '}

                                            questions to your test.</Text>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                    <HideParticipant
                        test_id={test_id}
                        openModal={openDeleteAnswerModal}
                        closeModal={closeDeleteAnswerModal} />
                    <ReportAnswer
                        test_id={test_id}
                        openModal={openReportAnswerModal}
                        closeModal={closeReportAnswerModal} />
                </div>
            }

            {!isSharedLink &&
            <a onClick={(e) => {
                e.preventDefault();

                setShowSupportPanel(true);

            }} href={process.env.REACT_APP_URL + "r/support"} target="_blank" className='support-button'>
                <Icon
                    value={"support"}
                    colorClass={"gray-50-svg"}
                    size={"medium"}
                />
                Support
            </a>
            }
        </LayoutResearcher>
    )
}