//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import "./invite-members-modal.css";

export default function InviteMembersModal({ open, close, cssClass, body }) {
    const navigate = useNavigate();

    const { workspaceid } = useParams();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        close();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap invite-member-modal  ${cssClass}`}
        >
            <Modal.Body className={""} ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="active-plan-modal-data">
                        <div className="active-plan-icon-hold mb-32">
                            <span className="active-plan-icon-wrap">
                                <Icon
                                    value={"group"}
                                    size={"large"}
                                    colorClass={"gray-50-svg"}
                                />
                            </span>
                        </div>
                        <div className="text-center">
                            <Text type={"h2"}>
                                {
                                    "Upgrade to Team plan to add members to workspace"
                                }
                            </Text>

                            <Text
                                type={"body-text-2"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-color mt-20 mb-32"}
                            >
                                Adding members to individual workspaces is not
                                available. Please switch to a Team workspace to
                                collaborate with your team.
                            </Text>

                            <Button
                                type="primary"
                                size={`large`}
                                iconLeft={
                                    <Icon
                                        value={"group"}
                                        colorClass={"gray-50-svg"}
                                        size={"small"}
                                    />
                                }
                                onClick={() => {
                                    navigate(
                                        "/wsp/" +
                                            workspaceid +
                                            "/upgrade-workspace"
                                    );
                                }}
                                label="Upgrade workspace"
                            />

                            <Button
                                type="ghost"
                                size={`medium`}
                                cssclass={"mt-32"}
                                iconLeft={<Icon value={"add"} size={"small"} />}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    let navigate_to =
                                        "/r/create-team-workspace";

                                    const searchParams = new URLSearchParams(
                                        window.location.search
                                    );

                                    const from = searchParams.get("from");

                                    if (
                                        searchParams.has("from") &&
                                        from !== null &&
                                        from === "noaccess"
                                    ) {
                                        navigate_to =
                                            "/r/create-team-workspace?from=noaccess";
                                    }
                                    navigate(navigate_to);
                                }}
                                label="Create new Team workspace"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
