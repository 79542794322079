import {Text} from "../../../themes/userq/Text/Text";
import {Icon} from "../../../themes/userq/Icon/Icon";
import {Button} from "../../../themes/userq/Button/Button";
import { useState } from "react";
import { Preview } from "../../../themes/userq/Preview/preview";
import GoalScreen from "./goal_screen";
import Skeleton from "react-loading-skeleton";

export const DefineFlow = ({language,read_mode, device, start_screen, goal_screen, objective, updateGoalScreen, goal_screen_loading, resync_loading,error})=>{

    const [previewModal, setPreviewModal] = useState({open:false, img:null, loading:false});

    const [goalScreen, setGoalScreen] = useState({open:false});

    return (
        <div className={`define_flow_container ${device && device=="mobile" ? "mobile-preview-define-flow":""}  ${language=="ar"?"arabic-wrapper":""} `}>
            {!read_mode &&
            <div class="figma-flow-top-head">
                <Text type={"subtitle-2"} fontWeight={"medium-font"}>
                    Define flow
                </Text>
                {objective=="closed" &&
                <Text type={"body-text-2"}  fontWeight={"medium-font"}>
                    Set up a goal screen to establish the user flow
                </Text>
                }
            </div>
            }
            <div className="figma-define-row-wrap">

                <div className="define-start-design-box">
                    <div class="define-design-box-head">
                        {resync_loading &&
                        <div className={`skeleton-icon-wrapper ${language=="ar"?"ar-icon-wrapper":""} `}>
                            <Skeleton className={`skeleton-icon ${language=="ar"?"ar-icon":""} `}  width={24} style={{ borderRadius:"24px"}} height={24}  />
                        </div>
                        }
                        {!resync_loading &&
                        <Icon value={"success"} colorClass={'success-200-svg'} size={"medium"} />
                        }
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>{language=="ar"?"صفحة البداية":"Start screen"}</Text>
                    </div>
                    {resync_loading &&
                    <div className="skeleton-height">
                        <Skeleton  width={"100%"} height={120} />
                    </div>
                    }
                    {!resync_loading &&
                    <div class="define-box-image-preview">


                        <div class="define-box-prew-inner">
                            <img src={start_screen.thumb.search("https://")!=-1?start_screen.thumb:process.env.REACT_APP_IMG_URL+"/"+start_screen.thumb} />
                        </div>

                    </div>
                    }
                    <div class="define-box-btn-wrap">
                        {resync_loading &&
                        <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton  width={148} height={46}  borderRadius={56}  /></div>
                        }
                        {!resync_loading && !read_mode &&
                        <Button
                            type={"secondary"}
                            label={language=="ar"?"عرض":"Preview"}
                            iconLeft={
                                <Icon value={"eye"} size={"medium"}  />
                            }
                            onClick={(e)=>{
                                e.preventDefault();
                                setPreviewModal({...previewModal, open:true, background:"#FFFFFF", file_type:"image", img:start_screen.file.search("https://")!=-1?start_screen.file:process.env.REACT_APP_IMG_URL+"/"+start_screen.file});
                            }}
                        />
                        }
                    </div>
                </div>
                {objective=="closed" &&
                <div class="define-design-arrow">
                    <Icon value="forward-arrow" colorClass={'gray-600-svg'} size={"large"} />
                </div>
                }
                {objective=="closed" &&
                <div className="define-start-design-box goal-screen-design-box">

                    {goal_screen &&
                    <>
                        <div class="define-design-box-head">
                            <div className="defined-design-edit-left">
                                {resync_loading &&
                                <div className={`skeleton-icon-wrapper ${language=="ar"?"ar-icon-wrapper":""} `}>
                                    <Skeleton className={`skeleton-icon ${language=="ar"?"ar-icon":""} `}  width={24} style={{ borderRadius:"24px"}} height={24}  />
                                </div>
                                }
                                {!resync_loading &&
                                <Icon value={"success"}  colorClass={'success-200-svg'}  size={"medium"} />
                                }
                                <Text type={"body-text-2"} fontWeight={"medium-font"}>{language=="ar"?"صفحة الهدف":"Goal screen"}</Text>
                            </div>
                            {!read_mode &&
                            <span className="define-design-edit" onClick={()=>{
                                setGoalScreen({...goalScreen, open:true})
                            }}>
                            {resync_loading &&

                            <div className={`skeleton-icon-wrapper ${language=="ar"?"ar-icon-wrapper":""} `}>
                                <Skeleton className={`skeleton-icon ${language=="ar"?"ar-icon":""} `}  width={24} style={{marginRight:"8px"}} height={24}  />
                            </div>
                            }
                                {!resync_loading &&
                                <Icon value={"edit"} size={"medium"} hover={true} />
                                }
                        </span>
                            }
                        </div>
                        {resync_loading &&
                        <div className="skeleton-icon-wrapper-loading">
                            <Skeleton  width={"100%"} height={120} />
                        </div>
                        }
                        {!resync_loading &&
                        <div class="define-box-image-preview">
                            <div class="define-box-prew-inner">
                                <img src={goal_screen.thumb.search("https")!=-1?goal_screen.thumb:process.env.REACT_APP_IMG_URL+"/"+goal_screen.thumb} />
                            </div>

                        </div>
                        }
                        <div class="define-box-btn-wrap">
                            {resync_loading &&
                            <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton  width={165} height={46}  borderRadius={56}  /></div>
                            }
                            {!resync_loading && !read_mode &&
                            <Button
                                type={goal_screen_loading?"primary":"secondary"}
                                label={language=="ar"?"عرض":"Preview"}
                                iconLeft={
                                    <Icon value={"eye"} size={"medium"}  />
                                }
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setPreviewModal({...previewModal, open:true,  background:"#FFFFFF", file_type:"image", img:goal_screen.file.search("https://")!=-1?goal_screen.file:process.env.REACT_APP_IMG_URL+"/"+goal_screen.file});
                                }}
                                microLoading={goal_screen_loading}
                            />
                            }
                        </div>
                    </>

                    }
                    {!goal_screen && !read_mode &&
                    <>
                        <div class="define-design-box-head">
                            {resync_loading &&
                            <div className={`skeleton-icon-wrapper ${language=="ar"?"ar-icon-wrapper":""} `}>
                                <Skeleton className={`skeleton-icon ${language=="ar"?"ar-icon":""} `}  width={24} style={{borderRadius:"24px"}} height={24}  />
                            </div>
                            }
                            {!resync_loading &&
                            <>
                                {!error &&
                                <Icon value={"error"} colorClass={'gray-700-svg'} size={"medium"} />
                                }
                                {error &&
                                <Icon value={"error"} colorClass={'danger-200-svg'} size={"medium"} />
                                }

                            </>
                            }
                            <Text type={"body-text-2"} fontWeight={"medium-font"}>{language=="ar"?"صفحة الهدف":"Goal screen"}</Text>
                        </div>
                        {resync_loading &&
                        <div className="skeleton-height">
                            <Skeleton  width={"100%"} height={120} />
                        </div>
                        }
                        {!resync_loading &&
                        <div class="define-box-image-preview  border-one-px-gray">
                            <div class="define-box-prew-inner">
                            </div>
                        </div>
                        }
                        <div class="define-box-btn-wrap">
                            {resync_loading &&
                            <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton  width={148} height={46}  borderRadius={56}  /></div>
                            }
                            {!resync_loading &&
                            <Button
                                type={"primary"}
                                label={`${language=="ar"?"حدد صفحة الهدف":"Set a goal screen"}`}
                                iconLeft={
                                    <Icon value={"target"} colorClass={'gray-50-svg'} size={"medium"}  />
                                }
                                onClick={(e)=>{
                                    e.preventDefault();

                                    setGoalScreen({...goalScreen, open:true})

                                }}

                                microLoading={goal_screen_loading}
                            />
                            }
                        </div>
                    </>

                    }
                </div>
                }
            </div>

            <Preview open={previewModal.open} withMinimizeIcon={true} background={previewModal.background} loading={false} file_type={previewModal.file_type} img={previewModal.img} close={()=>{

                setPreviewModal({open:false, img:null, loading:false});
            }}
            />

            <GoalScreen
                rtl={language=="ar"?true:false}
                file_key={start_screen.file_key}
                file_name={start_screen.file_name}
                source_node_id={start_screen.node_id}
                goal_node_id={goal_screen?goal_screen.node_id:start_screen.node_id}
                version={start_screen.version}
                open={goalScreen.open}
                close={()=>{
                    setGoalScreen({...goalScreen, open:false})
                }}
                updateGoalScreen={(node_id)=>{
                    setGoalScreen({...goalScreen, open:false})
                    updateGoalScreen(node_id);
                }}
                device={device}
            />
        </div>
    )
}