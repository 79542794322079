import React, { useState, useMemo, useRef, useEffect } from "react";
import AreaClosed from "@visx/shape/lib/shapes/AreaClosed";
import { LinePath } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { scaleLinear, coerceNumber } from "@visx/scale";
import { Axis, Orientation } from "@visx/axis";
import { GridRows, GridColumns } from "@visx/grid";
import {
	AnimatedAxis,
	AnimatedGridRows,
	AnimatedGridColumns,
} from "@visx/react-spring";

export const backgroundColor = "rgb(233, 215, 237)";
const axisColor = "rgb(0, 0, 0)";
const tickLabelColor = "rgb(0, 0, 0)";
export const labelColor = "#340098";
const gridColor = "rgb(240, 177, 215)";
const margin = {
	top: 0,
	right: 10,
	bottom: 0,
	left: 5,
};

const tickLabelProps = () => ({
	fill: tickLabelColor,
	fontSize: 12,
	fontFamily: "sans-serif",
	textAnchor: "middle",
});

const getMinMax = (vals) => {
	const numericVals = vals.map(coerceNumber);
	return [Math.min(...numericVals), Math.max(...numericVals)];
};

export default function LinearAxisChart({
	id,
	width: outerWidth = 100,
	height: outerHeight = 200,
	percentage,
	expandData
}) {
	const ref = useRef();

    

	// use non-animated components if prefers-reduced-motion is set
	const prefersReducedMotionQuery =
		typeof window === "undefined"
			? false
			: window.matchMedia("(prefers-reduced-motion: reduce)");
	const prefersReducedMotion =
		!prefersReducedMotionQuery || !!prefersReducedMotionQuery.matches;
	const [useAnimatedComponents, setUseAnimatedComponents] = useState(
		!prefersReducedMotion
	);

	const data = [
		{ x: 0, y: 0 },
		{ x: percentage, y: 0 },
	];

	const data_line = [
		{ x: percentage, y: 0 },
		{ x: percentage, y: 100 },
	];

	// in svg, margin is subtracted from total width/height
	const [width, setWidth] = useState(outerWidth - margin.left - margin.right);
	const height = outerHeight - margin.top - margin.bottom;
	const [animationTrajectory, setAnimationTrajectory] = useState("center");

	const AxisComponent = useAnimatedComponents ? AnimatedAxis : Axis;
	const GridRowsComponent = useAnimatedComponents ? AnimatedGridRows : GridRows;
	const GridColumnsComponent = useAnimatedComponents
		? AnimatedGridColumns
		: GridColumns;

	const handlePointerMove = (event) => {
		let containerX =
			("clientX" in event ? event.clientX : 0) -
			ref.current.getBoundingClientRect().left;
		let containerY =
			("clientY" in event ? event.clientY : 0) -
			ref.current.getBoundingClientRect().top;

			var tool_width = 222;

			var tool_height = 46;
	  
			var window_width = window.innerWidth;
	  
			var window_height = window.innerHeight;
		 
			if(event.clientX+60+tool_width > window_width){
			 // alert(event.clientX+tooltipref.current.getBoundingClientRect().width+30+tool_width )
			  containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width +20;
			}
			if(event.clientY+10+tool_height > window_height){
			  //alert(window_width)
			  containerY = ('clientX' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height -10;
			}

		showTooltip({
			tooltipLeft: containerX,
			tooltipTop: containerY,
			tooltipData: percentage+"%",
			open: true,
			
		});
	};
    useEffect( ()=>{
		if(ref && ref.current){
        setWidth(ref.current.getBoundingClientRect().width);

		window.addEventListener('resize', function(){
			if(ref && ref.current){
			setWidth(ref.current.getBoundingClientRect().width);
			}
		});
	}

    },[percentage,expandData]);



	const axes = useMemo(() => {
		// toggle between two value ranges to demo animation
		const linearValues = [0, 20, 40, 60, 80, 100];

        

		return [
			{
				scale: scaleLinear({
					domain: getMinMax(linearValues),
					range: [0, width-margin.left-margin.right],
				}),
				values: linearValues,
				tickFormat: (v) => `${v}`,
				label: "linear",
			},
		];
	}, [width]);

	const scalePadding = 40;
	const scaleHeight = height / axes.length - scalePadding;

	const yScale = scaleLinear({
		domain: [100, 0],
		range: [scaleHeight, 0],
	});
	const [tooltip, showTooltip] = useState({
		tooltipLeft: 0,
		tooltipTop: 0,
		tooltipData: "",
		open: false,
	});
	return (
		<div id={id} ref={ref} style={{ position: "relative", width:"100%" }} onMouseMove={percentage<=10?handlePointerMove:()=>{}} onMouseLeave={() => {
            showTooltip({ ...tooltip, open: false });
        }}>
			{tooltip.open ? (
				<>
					<div
						className={"tooltipChart"}
						key={Math.random()} // needed for bounds to update correctly
						style={{
							zIndex: 2,
							position: "absolute",
							left: tooltip.tooltipLeft + 20 + "px",
							top: tooltip.tooltipTop + 0 + "px",
						}}
					>
						{tooltip.tooltipData}
					</div>
				</>
			) : (
				<></>
			)}
			<svg width={width} height={outerHeight}>
				<g transform={`translate(${margin.left},${margin.top})`}>
					{axes.map(({ scale, values, label, tickFormat }, i) => (
						<g
							key={`scale-${i}`}
							transform={`translate(0, ${i * (scaleHeight + scalePadding)})`}
							
						>
							<GridRowsComponent
								// force remount when this changes to see the animation difference
								key={`gridrows-${animationTrajectory}`}
								scale={yScale}
								stroke={gridColor}
								width={width-margin.left-margin.right}
								numTicks={1}
								animationTrajectory={animationTrajectory}
							/>
							<GridColumnsComponent
								// force remount when this changes to see the animation difference
								key={`gridcolumns-${animationTrajectory}`}
								scale={scale}
								stroke={gridColor}
								height={scaleHeight}
								numTicks={6}
								animationTrajectory={animationTrajectory}
							/>
							<AreaClosed
								data={data.map((x) => [scale(x.x), yScale(x.y)])}
								yScale={yScale}
								curve={curveMonotoneX}
								fill={gridColor}
								fillOpacity={0.2}
								onPointerMove={handlePointerMove}
								onClick={(event)=>{
									var ele = handlePointerMove(event);
										ele();
								}}
								onPointerLeave={() => {
									showTooltip({ ...tooltip, open: false });
								}}
							/>
							<AxisComponent
								// force remount when this changes to see the animation difference
								key={`axis-${animationTrajectory}`}
								orientation={Orientation.bottom}
								top={scaleHeight}
								scale={scale}
								tickFormat={tickFormat}
								stroke={axisColor}
								tickStroke={axisColor}
								tickLabelProps={tickLabelProps}
								tickValues={values}
								numTicks={undefined}
								labelProps={{
									x: width,
									y: 0,
									fill: labelColor,
									fontSize: 18,
									strokeWidth: 0,
									stroke: "#fff",
									paintOrder: "stroke",
									fontFamily: "sans-serif",
									textAnchor: "start",
								}}
								animationTrajectory={animationTrajectory}
							/>
							<LinePath
								data={data_line}
								x={(d) => scale(d.x)}
								y={(d) => yScale(d.y)}
								stroke="rgb(255, 102, 173)"
								strokeWidth={1}
								shapeRendering="geometricPrecision"
								//onPointerMove={handlePointerMove}
								/*onPointerLeave={() => {
									showTooltip({ ...tooltip, open: false });
								}}*/
							/>
						</g>
					))}
				</g>
			</svg>
		</div>
	);
}
