import React, { useState } from "react";
import PropTypes from 'prop-types';

export const Link = ({ url, children, underline, target, onClick, cssClasses }) => {

    return (
        <a href={url}  onClick={onClick} className={`link-text ${cssClasses ? cssClasses : ''} ${underline==true?"underline-link":""}`} target={target}>{children}</a>

    )
}

Link.propTypes = {
    url: PropTypes.string,
    underline: PropTypes.bool,
    children: PropTypes.node,
    target: PropTypes.string,

};
Link.defaultProps = {
    url: "",
    underline:false,
    children:<></>,
    target:""

};