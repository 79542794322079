import React, { useEffect, useMemo, useState, useRef } from "react";
import { Group } from "@visx/group";
import { Tree, hierarchy } from "@visx/hierarchy";
import { LinkHorizontal } from "@visx/shape";
import { pointRadial } from 'd3-shape';
import getLinkComponent from './getLinkComponent';



const peach = "#fd9b93";
const pink = "#fe6e9e";
const blue = "#03c0dc";
const green = "#26deb0";
const plum = "#71248e";
const lightpurple = "#374469";
const white = "#ffffff";
export const background = "#272b4d";

const rawTree = {
	name: "T",
	children: [
		{
			name: "A",
			children: [
				{ name: "A1", percentage: 90 },
				{ name: "A2", percentage: 80 },
				{ name: "A3", percentage: 70 },
				{
					name: "C",
					children: [
						{
							name: "C1",
							percentage: 90,
						},
						{
							name: "D",
							children: [
								{
									name: "D1",
									percentage: 90,
								},
								{
									name: "D2",
									percentage: 90,
								},
								{
									name: "D3",
									percentage: 90,
								},
							],
						},
					],
				},
			],
		},
		{ name: "Z", percentage: 90 },
		{
			name: "B",
			children: [
				{ name: "B1", percentage: 90 },
				{ name: "B2", percentage: 90 },
				{ name: "B3", percentage: 90 },
			],
		},
	],
};

/** Handles rendering Root, Parent, and other Nodes. */
function Node({ node, radial, answer_path, handlePointerMove,handlePointerOut, test }) {
	const width = 120;
	const height = 30;
	const centerX = -width / 2;
	const centerY = -height / 2;
	const isRoot = node.depth === 0;
	const isParent = !!node.children;

	const getStrokeColor = (id) => {
		var exist = false;

		answer_path.forEach(function (item) {
			if (item.id == id) {
				exist = true;
			}
		});
		if (exist) {
			return "rgb(81, 178, 51)";
		} else {
			return "rgba(47, 46, 85, 1)";
		}
	};

	if (isParent) return <ParentNode test={test} radial={radial} node={node} answer_path={answer_path}  handlePointerOut={handlePointerOut} handlePointerMove={handlePointerMove} />;

	let top=0;
	let left=0;
	if (radial) {
	const [radialX, radialY] = pointRadial(node.x, node.y);
	top = radialY;
	left = radialX;
	}  else {
	top = node.x;
	left = node.y;
	}
	return (
		<Group top={top} left={left}>
			<rect
				height={height}
				width={width}
				y={centerY}
				x={centerX}
				fill={"white"}
				stroke={getStrokeColor(node.data.id)}
				strokeWidth={2}
				rx={10}
				onClick={() => {
					//alert(`clicked: ${JSON.stringify(node.data.value)}`);
				}}
                onMouseMove={ function(d){

                    handlePointerMove(d,node.data.title,node.data.participants, node.data);
                }}
                onMouseOut={ function(d){
                    handlePointerOut(d)
                }}
			/>
			<text
				dy=".33em"
				fontSize={"12"}
				className={`${test.language=='ar'?'cairo-font':''}`}
				fontFamily="Arial"
				textAnchor="middle"
				fill={"rgba(47, 46, 85, 1)"}
				style={{ pointerEvents: "none" }}
			>
				{node.data.title.length >16?node.data.title.substring(0,16)+"...":node.data.title}
			</text>
		</Group>
	);
}

function ParentNode({ node, radial, answer_path,  handlePointerMove, handlePointerOut, test }) {
	const width = 120;
	const height = 30;
	const centerX = -width / 2;
	const centerY = -height / 2;

	const getStrokeColor = (id) => {
		var exist = false;

		answer_path.forEach(function (item) {
			if (item.id == id) {
				exist = true;
			}
		});
		if (exist) {
			return "rgb(81, 178, 51)";
		} else {
			return "rgba(255, 102, 173, 1)";
		}
	};
	let top=0;
	let left=0;
	if (radial) {
	const [radialX, radialY] = pointRadial(node.x, node.y);
	top = radialY;
	left = radialX;
	}  else {
	top = node.x;
	left = node.y;
	}

	return (
		<Group top={top} left={left}>
			<rect
				className={`${test.language=='ar'?'cairo-font':''}`}
				height={height}
				width={width}
				y={centerY}
				x={centerX}
				fill={"white"}
				stroke={getStrokeColor(node.data.id)}
				strokeWidth={2}
				onClick={() => {
					//alert(`clicked: ${JSON.stringify(node.data.value)}`);
				}}
                onMouseMove={ function(d){
                    handlePointerMove(d,node.data.title,node.data.participants, node.data);
                }}
                onMouseOut={ function(d){
                    handlePointerOut(d)
                }}
			/>
			<text
				dy=".33em"
				fontSize={12}
				fontFamily="Arial"
				textAnchor="middle"
				style={{ pointerEvents: "none" }}
				fill={"rgba(47, 46, 85, 1)"}
			>
				{node.data.title.length >16?node.data.title.substring(0,16)+"...":node.data.title}
			</text>
		</Group>
	);
}

const defaultMargin = { top: 0, left: 0, right: 40, bottom: 0 };

export default function SankeyChart({
	depth,
	owidth,
	height,
	margin = defaultMargin,
	rawdata,
	answer_path,
	total_navigation,
	expandData,
	radial,
	test
}) {
	const data = hierarchy(rawdata);
	const [width, setWidth] = useState(owidth);
	const ref = useRef();

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false , data
    });
    const handlePointerMove = (event, title, value,data) => {
        console.log(event)
        let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left + 30 ;
        let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top ;
        
		if(event.clientX + 360 + 30 > width){
			containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - 360 -60;
		}
		
		if(event.clientY + 224 +60 > window.innerHeight){
			containerY = ('clientY' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - 224 -30  ;
		}
		showTooltip({
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipData: title.length > 5 ? ( (parseInt(value) > 1 ? <>{title}<br/>{value} Participants</> : <>{title}<br/>{value} Participant</>) ) : ( (parseInt(value) > 1 ? <>{value} Participants</> : <>{value} Participant</>) ) ,
            open:true,
			data:data
        });
    }
    const handlePointerOut = (event)=>{
        showTooltip({...tooltip, open:false}) 
    }

	useEffect(() => {
		if(ref && ref.current){
			setWidth(ref.current.getBoundingClientRect().width);
			
			setXMax(
				ref.current.getBoundingClientRect().width - margin.left - margin.right
			);

			window.addEventListener('resize', function(){

				if(ref && ref.current){
					setWidth(ref.current.getBoundingClientRect().width);
				
					setXMax(
						ref.current.getBoundingClientRect().width - margin.left - margin.right
					);
				}
			});
		}


	}, [expandData]);
	const yMax = height - margin.top - margin.bottom;
	const [xMax, setXMax] = useState(width - margin.left - margin.right);
	var strokeWidth = (perc) => {
		var max = 36;
		var min = 1;
		return (perc / 100) * (max-min) > 1 ? ((perc / 100) * (max-min)) + min : min;
	};
	var strokeColor = (link, answer_path2) => {
		var exist = false;

		answer_path2.forEach(function (item) {
			if (link.target && item.id == link.target.data.id) {
				exist = true;
			}
		});
		if (exist) {
			return "rgb(81, 178, 51)";
		} else {
			return "rgba(47, 46, 85, 1)";
		}
	};
	const LinkComponent = getLinkComponent({"layout":radial ? 'polar':'cartesian', "linkType":  radial ? "line" : "diagonal", "orientation": "horizontal"});

	const innerWidth = xMax-82;
	const innerHeight = yMax-85;

	let origin =  { x: 0, y: 0 };
	let sizeWidth = 0;
	let sizeHeight = 0;

		if (radial) {
			origin = {
				x: innerWidth / 2,
				y: innerHeight / 2 + 50,
			};
			sizeWidth = 2 * Math.PI;
			sizeHeight = Math.min(innerWidth, innerHeight) / 2;
		} else {
			
			origin = { x: 0, y: 0 };
			sizeWidth = innerHeight;
			sizeHeight = innerWidth;
		}
	return (
		<div ref={ref} style={{position:'relative'}}>
            {tooltip.open ? (
            <>
                <div
                    className={"tooltipChart"}
                    key={Math.random()} // needed for bounds to update correctly
                    style={{ zIndex:2, position:"absolute",left:(tooltip.tooltipLeft+30)+"px",top:(tooltip.tooltipTop+30)+"px"}}
                >
                    {/*tooltip.tooltipData*/}
					<div className="chart-tooltip-top-tree d-flex justify-content-between ">
						<div className="chart-tooltip-left-tree">
							<h3 className={`${test.language=='ar'?'cairo-font':''}`}>{tooltip.data.title}</h3>
							<p>participants from here proceed to</p>
						</div>
						<div  className="chart-tooltip-right-tree">
							{tooltip.data.participants} visits
						</div>
					</div>
					<div  className="tree-chart-tooltip-table">
						<table>
							<tr>
								<td>Correct path</td>
								<td><b>{tooltip.data.correct_answer_count}</b> visits</td>
								<td><b>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.correct_answer_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100): 0}%</b></td>
							</tr>
							<tr>
								<td>Incorrect path</td>
								<td><b>{tooltip.data.incorrect_answer_count}</b> visits</td>
								<td><b>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.incorrect_answer_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100): 0}%</b></td>
							</tr>
							{tooltip.data.id!=-1 &&
							<tr>
								<td>Back</td>
								<td><b>{tooltip.data.back_count}</b> visits</td>
								<td><b>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.back_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100) : 0}%</b></td>
							</tr>
							}
							<tr>
								<td>Selected answer</td>
								<td><b>{tooltip.data.nominated_answer_count}</b> visits</td>
								<td><b>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.nominated_answer_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100) : 0}%</b></td>
							</tr>
							<tr>
								<td>Skip Task</td>
								<td><b>{tooltip.data.skipped_count}</b> visits</td>
								<td><b>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.skipped_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100) : 0}%</b></td>
							</tr>
						</table>
					</div>
					
                </div>
            </>
            ): (
                <></>
            )}
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
				<Tree root={data} size={[sizeWidth, sizeHeight]}>
					{(tree) => (
						<>
							{//console.log(tree.links())
							 // - width / (depth + 2) / 2
							}
							<Group
							
								top={origin.y}
								left={origin.x+61}
							>
								{tree.links().map((link, i) => (
									<>
										<LinkComponent
											key={`link-${i}`}
											data={link}
											stroke={strokeColor(link, answer_path)}
											strokeWidth={strokeWidth(
												total_navigation > 0
													? (link.target.data.participants / total_navigation) * 100
													: 0
											)}
											fill="none"
										/>
									</>
								))}
								{tree.descendants().map((node, i) => (
									<>
										<Node
											test={test}
											key={`node-${i}`}
											node={node}
											radial={radial}
											answer_path={answer_path}
											handlePointerMove = {handlePointerMove}
											handlePointerOut={handlePointerOut}
										/>
									</>
								))}
							</Group>
						</>
					)}
				</Tree>
			</svg>
		</div>
	);
}
