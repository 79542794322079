import Header from "./elements/header.js"
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from "../../lib/secure_reactsession";
import { getProfile } from "../../services/user.js";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FullPageLoadingIcon } from "../loader/full_page_loader_lcon.js";


export default function Layout(props) {

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    ReactSession.setStoreType("localStorage");

    useEffect(() => {

        const user = ReactSession.get("user");

        console.log(user)

        const token = ReactSession.get("token");

        if(!props.skipCheckLogin) {
            if (user.role == "Tester" && user.completed_step != 5 && !props.skipCheck) {

                /* if(user.completed_step!=5 ){
                     navigate('/profile/step1');
                 }*/

                ///setLoading(true);

                getProfile(token).then(response => {
                    setLoading(false);

                    if (response.success) {

                        if (response.data.user.completed_step != 5) {
                            navigate('/profile/step1');
                        }

                    }
                });


            }
        }
    }, []);
    const getFooterYear = () => {
        const d = new Date(); let year = d.getFullYear(); return <>{year}</>;
    }
    return (
        <SkeletonTheme baseColor="#dae5f1" highlightColor="#ebf4ff">
            <div className={`inner-page-wrapper${(isLoading || props.isLoading) ? '-1' : ''} ${props.wrapClass} `}>
                {(isLoading || props.isLoading) &&
                    <FullPageLoadingIcon />
                }
                {(!isLoading && !props.isLoading) &&
                    <>
                        <Header fixed_header_target={props.fixed_header_target} skipCheckLogin={props.skipCheckLogin} username={props.username} activeMenu={props.activeMenu} setCreditZero={props.setCreditZero} profileCompleted={props.profileCompleted}/>
                        <div className={`${(props.extended404Footer === false) ? '' : (props.isFullWidth)?'':'container inner-page-container'}`}>
                            <>{props.children}</>
                        </div>
                        {props.extendedFooter &&
                            <>
                                <div className="full home-marquee-banner section">
                                    <div className="home-marque-banner-box">
                                        <div className="scrolling-box">Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                    </div>
                                </div>

                                <div className="full homeafooterbox section researcher-homeafooterbox">
                                    <div className="container inner-page-container">
                                        <div className="footerboxhome minicontainer-outer">
                                            <div className="minicontainer">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 homefooterbox-headingbox">
                                                        <div className="h1">At User<span>Q</span>, we take your<br /> data privacy and security<br /> seriously</div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-12 homefooterbox-infobox">
                                                        <div className="row align-items-center">
                                                            <div className="col-xs-12 col-sm-12 col-md-5 homefooterbox-left">
                                                                <div className="full homefooterbox-info">
                                                                    <p>GDPR is a European Union regulation, designed to improve the data security and privacy of European citizens. Basically, companies in Europe have to make sure your data is safe, and that you can access and control it.</p>
                                                                    <p>Our Privacy Policy was prepared in accordance with the GDPR.</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-7 homefooterbox-right">
                                                                <div className="full homefooterbox-imgbox"> <img src="images/Frame-257.png" alt="UserQ" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {(props.extended404Footer !== false) &&
                            <footer className="researcher-footer">
                            <div className="container inner-page-container">
                                <div className="footerbottom-bar">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                                            <div className="footer-left-side-data">
                                                <span>&copy; {getFooterYear()} USERQ. All rights reserved.
                                                    {/*<a href={process.env.REACT_APP_SITE_URL + "terms-of-use/"}>Terms of use</a>*/}
                                                </span>
                                                {/*<div className="footer-polify-data">*/}
                                                {/*    <span className="cookie-link">*/}
                                                {/*        &nbsp;|&nbsp;*/}
                                                {/*    </span>*/}
                                                {/*    <a href="https://userq.com/privacy-policy/">Privacy Policy</a>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 footerbottom-bar-right">
                                            <div className="footerbottom-bar-right-inner">
                                                <span className={"terms-of-use-txt-link-dsk"}><a href="https://userq.com/terms-of-use/">Terms of use</a></span>
                                                <span className={"privacy-policy-txt-link-dsk"}><a href="https://userq.com/privacy-policy/">Privacy policy</a></span>
                                                <span className="cookie-link"><a href="https://userq.com/privacy-policy/#cookies-policy">Cookie policy <img
                                                    src={process.env.REACT_APP_URL + "images/cookie-icon.svg"}
                                                    alt="img" /></a></span>
                                                <span className="proudly-saying-text">
                                                    Proudly created by <a
                                                        href="https://www.digitalofthings.com/" target="_blank">Digital Of Things</a>                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </footer>
                        }
                        {/*<footer id="footer" className="fullwidth cf">
                <div id="footerInnerWrapper">
                    <div className="container inner-page-container">
                        <div className="row">
                            <div className="footer-top-wrap">
                                <div className="footerheading full d3">
                                    Need help?<br/>  <a href="mailto:">Contact us</a>                
                                </div>
                                <div className="footer-researcher-link-wrap none-for-mobile">
                                    <a className="link linktype-1" href="#">I’m a researcher</a>
                                </div>
                            </div>
                            <div className="footerlinkbox full">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 footerbox-outer footerbox1 none-for-mobile">
                                        <div className="full footerbox-inner">
                                            <a className="footerlogo" href={process.env.REACT_APP_TESTER_URL} ><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                                            <div className="full footerbox-info">
                                                <p>With our no-fuss platfrom, brands can get real feedback from their customers in MENA region quickly and easily.</p>
                                            </div>
                                            <div className="footerlinkbox-inner full">
                                                <div className="footerlink">
                                                    <a href="#"><img src={process.env.REACT_APP_URL+"images/facebook-icon.svg"} alt="img"/></a>
                                                </div>
                                                <div className="footerlink">
                                                    <a href="#"><img src={process.env.REACT_APP_URL+"images/twitter-icon.svg"} alt="img"/></a>
                                                </div>
                                                <div className="footerlink">
                                                    <a href="#"><img src={process.env.REACT_APP_URL+"images/insta-icon.svg"} alt="img"/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 footerbox-outer footerbox2">
                                        <div className="full footerbox-inner">
                                            <div className="footerboxheading">Company</div>
                                            <div className="footer-linksbox">
                                                <ul id="menu-footer-menu-support" className="menu">
                                                    <li>
                                                        <a href="#">About us</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Careers</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 footerbox-outer footerbox3">
                                        <div className="full footerbox-inner">
                                            <div className="footerboxheading">Support</div>
                                            <div className="footer-linksbox">
                                                <div id="nav_menu-3" className="widget widget_nav_menu">
                                                    <div className="menu-footer-menu-support-container">
                                                        <ul id="menu-footer-menu-support" className="menu">
                                                            <li  className="menu-item">
                                                                <a href={process.env.REACT_APP_SITE_URL+"faq"}>FAQ</a>
                                                            </li>
                                                            <li className="menu-item">
                                                                <a href={process.env.REACT_APP_SITE_URL+"privacy-policy"} >Privacy Policy</a>
                                                            </li>
                                                            <li className="menu-item">
                                                                <a href={process.env.REACT_APP_SITE_URL+"terms-of-service"}>Terms of service</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 footerbox-outer footerbox1 none-for-desktop">
                                        <div className="full footerbox-inner">
                                            <div className="footer-social-for-mobile">
                                                <span><a className="link linktype-1" href="#">I’m a researcher</a></span>
                                                <div className="footerlinkbox-inner full">
                                                    <div className="footerlink">
                                                        <a href="#"><img src={process.env.REACT_APP_URL+"images/facebook-icon.svg"} alt="img"/></a>
                                                    </div>
                                                    <div className="footerlink">
                                                        <a href="#"><img src={process.env.REACT_APP_URL+"images/twitter-icon.svg"} alt="img"/></a>
                                                    </div>
                                                    <div className="footerlink">
                                                        <a href="#"><img src={process.env.REACT_APP_URL+"images/insta-icon.svg"} alt="img"/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 footerbottom-bar full">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 footerbottom-bar-left ">
                                        &copy; {getFooterYear()} USERQ. All rights reserved.                        
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 footerbottom-bar-right">
                                        <div className="footerbottom-bar-right-inner">
                                            <span className="cookie-link"><a href="">Cookie policy <span><img src={process.env.REACT_APP_URL+"images/cookie-icon.svg"} alt="img"/></span></a></span>
                                            <span className="proudly-saying-text">
                                            Proudly created by <a href="#">Digital Of Things</a>                                
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </footer>*/}
                    </>
                }
            </div>
        </SkeletonTheme>
    )
}