import React, { useState } from "react";

export const OptionsReadMore = ({ options }) => {

    const [viewAll, setViewAll] = useState(false);

    return (
        <div>{viewAll?
            <>
            {options.map(
                (option, i) => (
                    <div className="" key={option+"-"+i}>
                        {option}
                    </div>
                )
            )}
            </>:
            <>
            {options.map(
                (option, i) => (
                    <div className="" key={option+"-"+i}>
                        {i < 3 && option}
                    </div>
                )
            )}
            {options.length > 3 &&
                <div className="addadas" onClick={()=>{setViewAll(true)}}>
                    +{options.length - 3} more
                </div>
            }
            </>
        }
        </div>
    )
}