import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { updatePrototypeTestTasksOrderService } from "../../../../services/test";
import { showError } from "../../../../lib/helpers";
import { ReactSession } from "../../../../lib/secure_reactsession";

import { isEmpty } from "lodash";


export default function ChangeOrderModal({objective, open, tasks, rtl, close, updateOrder }) {

	const [formValues, setFormValues] = useState({ tasks: [] });

	const [isLoading, setLoading] = useState(false);

	const [placeholderProps, setPlaceholderProps] = useState({});


	const queryAttr = "data-rbd-drag-handle-draggable-id";

	useEffect(() => {

		setFormValues({ ...formValues, tasks: tasks });
	}, [open])

	const closeModal = () => {
		close();
	};
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    console.log(curr)
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingLeft
            ),
           // classValue:'draggable-element-start',
             background:'#CCCCCC',
             border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                console.log(curr)
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
           // classValue:'draggable-element-move',
             background:'none',
             border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };
	function onDragEnd(result) {
		setPlaceholderProps({});
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const tasks = reorder(
			formValues.tasks,
			result.source.index,
			result.destination.index
		);


		setFormValues({ ...formValues, "tasks": tasks });

	}
	const renderTasks = () => {
		var designs_render = [];

		formValues.tasks.forEach(function (item, i) {
			designs_render.push(
				<div className="w-100">
					<Draggable
						draggableId={"task" + item.id}
						key={"task" + item.id}
						index={i}

					>
						{(provided, snapshot) => {
							var transform = provided.draggableProps.style.transform;
							if(transform){
								var t = transform.split(",")[1]
								provided.draggableProps.style.transform = "translate(0px," + t
							}
							return (
								<div
								id={"task-"+item.id}
									className={`added-card-cat-repeat ${snapshot.isDragging?'draggable-element-start':''}`}
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									style={{...provided.draggableProps.style, left:"40px"}}
								>
									
									<div  className={`added-card-cat-repeat-sub-items ${snapshot.isDragging?'draggable-inner-element-start':''}`}>
										<div className="task-drag-img">
											<Icon value={"move-object"} hover='true' size="medium"></Icon>
										</div>
										<div className="task-item-data">
											<h3 className="subtitle-2 semi-bold-font">{(objective=="closed" ? "Task ":"Flow ") + (i + 1)}</h3>
											{rtl}
											<p className={`body-text body-text-1 medium-font ${rtl?"arabic-font":""}`}>{item.description}</p>
										</div>
									</div>
								</div>
							);
						}}
					</Draggable>
				</div>
			);
		});


		return (
			<DragDropContext 
				onDragStart={handleDragStart}
				onDragUpdate={handleDragUpdate}
				onDragEnd={onDragEnd} 
				className="row">
				<Droppable droppableId={"list"}>
					{(provided) => (
						<div ref={provided.innerRef} 
							{...provided.droppableProps}
							

						>
							{designs_render}
							{provided.placeholder}
							{!isEmpty(placeholderProps) && (
								<div
									className={`placeholder`}
									style={{
										top: placeholderProps.clientY,
										left: placeholderProps.clientX,
										height: placeholderProps.clientHeight,
										width: placeholderProps.clientWidth,
										position: "absolute",
										borderColor: "#000000",
										background:placeholderProps.background,
										borderRadius:'10px',
										border:placeholderProps.border,
										margin: '10px 0px 10px'
									}}
								/>
							)}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
	const updateTasksOrder = (form, shouldnavigate) => {

		setLoading(true);

		var task_ids = [];

		formValues.tasks.forEach(function (item) {
			task_ids.push(item.id);
		});

		const data = new FormData();
		data.append("task_ids", task_ids);

		updatePrototypeTestTasksOrderService(data, ReactSession.get("token")).then(
			(response) => {
				setLoading(false);

				if (response.success) {

					var tasksLoc = Object.assign([], formValues.tasks);

					formValues.tasks.forEach(function (item, i) {
						tasksLoc[i].sequence = i;
					})

					updateOrder(tasksLoc)

				} else {

					showError(response.message);
				}
			}
		);
	};
	return (
		<Modal
			show={open}
			centered
			className={`fade custom-modal-wrap ${rtl ? "arabic-wrapper" : ""}  confirmation-modal-wrap task-order-modal`}
			onHide={() => {
				closeModal();
			}}

		>
			<Modal.Body className=""  >
				{isLoading ?
					<div className="modal-loader text-center">
						<LoadingIcon />
					</div>
					:
					<div className="modal-inner-text">
						<div className="modal-header-top">
							<Text type={'h3'}>Tasks order</Text>
							
							<button
								type="button"
								className="close"
								onClick={() => {

									closeModal();
								}}
								aria-label="Close"
							>
								<span aria-hidden="true">
									<Icon value="Close" size="medium" hover={true} />
								</span>
							</button>
						</div>
						<Text type={"body-text-2"}  fontWeight={"medium-font"}>Click and drag to change tasks order</Text>
						<div className="added-cardsorting-category-wrap" style={{position:"relative"}}>{renderTasks()}</div>
						<div className="d-flex justify-content-center mt-32 order-actions-row">
							<Button
								type={"secondary"}
								label={"Cancel"}
								size={"large"}
								onClick={() => {

									close();
								}}
							/>
							<Button
								type={"primary"}
								label={"Confirm"}
								size={"large"}
								onClick={() => {

									updateTasksOrder();
								}}
							/>
						</div>

					</div>
				}
			</Modal.Body>
		</Modal>
	);
}