import React, {useState} from "react";

export default function CommonPlans(props) {

    
    const [collapse, setCollapse] = useState(true);

    
    let features = [
        {
            "Section":"Studies",
            "headings" : true,
            "features" : [
                {"label":"Number of live studies","key":"live_tests"},
                {"label":"Length/duration of study","key":"duration_of_study"},
                {"label":"Pre/post test questions","key":"post_test_questions"} 
            ]
        },
        {
            "Section":"Methodologies",
            "headings" : false,
            "features" : [
                {"label":"Card sorting","key":"card_sorting"} ,
                {"label":"Tree testing","key":"tree_testing"},
                {"label":"Preference testing","key":"preference_test"},
                {"label":"Surveys","key":"survey","coming_soon":true},
                {"label":"Five-second testing","key":"5_seconds_test","coming_soon":true},
                {"label":"First click testing","key":"first_click","coming_soon":true},

            ]
        },
        {
            "Section":"Testers panel",
            "headings" : false,
            "features" : [
                {"label":"Access to UserQ testers panel","key":"card_sorting","sub_heading":"Userq panel is priced separately"},
                {"label":"Study link to share with your own testers","key":"card_sorting"},
                {"label":"Define UserQ panel demographics","key":"card_sorting"},
                {"label":"Number of respondents","key":"respondents_test"}
                
            ]
        },
        {
            "Section":"Studies reports & storage",
            "headings" : false,
            "features" : [
                {"label":"Online report availability","key":"online_report_availability"},
                {"label":"CSV export","key":"csv_export"},
                {"label":"Unlimited storage","key":"unlimited_storage"},
                {"label":"Studies archive","key":"studies_archive"}
                
            ]
        },
        {
            "Section":"Customisation",
            "headings" : false,
            "features" : [
                {"label":"Welcome/thank you page customisation (image)","key":"thank_you_page_customization"},
                
            ]
        },
        {
            "Section":"Collaboration",
            "headings" : false,
            "features" : [
                {"label":"Multiple seats","key":"multiple_seats"},
                {"label":"Roles and permissions","key":"roles_and_permissions"}
                
            ]
        },
        {
            "Section":"Credits",
            "headings" : false,
            "features" : [
               /* {"label":"Monthly credits included","key":"monthly_credits_included"},*/
                {"label":"Shared credits between account members","key":"shared_credits_between_account_members"}
            ]
        }
    ];
    const toggleCheck = (value) => {
        
        props.setTerm(value==1 ? "Yearly" : "Monthly");
    };
    const renderPrice = (plan) => {
        if(plan.plan_category=='FREE'){
            return "0";
        } else if (props.term=='Yearly'){
            return plan.price_yearly;
        } else {
            return plan.price_monthly;
        }
    }
    const renderTitle = (plan) => {
        if(plan.plan_category=='FREE'){
            return "forever";
        } else if (props.term=='Yearly'){
            return "per seat per year";
        } else {
            return  "per seat per month";
        }
    }
    const isActivePlan = (plan)=>{
        if(props.currentPlan){

            if(props.currentPlan.plan_id == plan.id){

                if(plan.plan_category=="FREE"){
                    return true;
                } else {
                    if(props.currentPlan.term=="month" && props.term=='Monthly'){
                        return true
                    } else if(props.currentPlan.term=="year" && props.term=='Yearly'){
                        return true
                    }
                    return false;
                }
            }
        } else {
            return false;
        }
    }
    const renderPlans = ()=>{
        var render_plans = [];

        props.plans.forEach(function(plan){
            render_plans.push(
              <div
                key={"plan" + plan.id}
                className="col-xs-12 col-sm-6 planbox-outer"
              >
                <div
                  className={`full themebg-type2 planbox-inner  ${
                    isActivePlan(plan) ? "plan-selected" : ""
                  } `}
                >
                  <div className="full plantitle">
                    <h3 className="rajfont">{plan.plan_name=="Business"?<>Pro</>:plan.plan_name}</h3>
                    <p>{plan.sub_heading}</p>
                  </div>
                  <div className="full planprice">
                    <span className="doller">$</span>
                    <span className="planpricemain monthlyprice showit">
                      {renderPrice(plan)}
                    </span>
                    <span className="normaltext">/{renderTitle(plan)}</span>
                  </div>
                  <div className="full planfeatures">
                    <div className="featurelist-row">
                      <p>Users</p>
                      <h4>{plan.number_of_users}</h4>
                    </div>
                    <div className="featurelist-row">
                      <p>Live studies per month</p>
                      <h4>{plan.live_tests}</h4>
                    </div>
                    <div className="featurelist-row">
                      <p>Number of respondents</p>
                      <h4>{plan.respondents_test}</h4>
                    </div>
                    {/*<div className={`featurelist-row ${plan.plan_category=="PAID" ? 'month-cre-last-row' : ''}`}>
                                <p>Monthly credits included*</p>
                                {plan.plan_category=="PAID" &&
                                    <h4 className='inc-credit-charge'>{plan.monthly_credits} <sub> / Per seat per {props.term=="Monthly"?"month":"year"}</sub></h4>
                                }
                                {plan.plan_category!="PAID" &&
                                    <h4 className='inc-credit-charge'>0</h4>
                                }
                            </div>*/}
                    {plan.plan_category == "PAID" && (
                      <p className="necessary-use-text">
                        *You'll need to buy credits to test with the UserQ panel
                      </p>
                    )}
                    {plan.plan_category != "PAID" && (
                      <p className="necessary-use-text">&nbsp;</p>
                    )}
                  </div>
                  <div className="full planbuttonbox">
                    {isActivePlan(plan) && (
                      <span className="active-plan-btn">
                        <img
                          src={
                            process.env.REACT_APP_URL + "images/pink-check.svg"
                          }
                        />{" "}
                        Active plan
                      </span>
                    )}
                    {!isActivePlan(plan) && (
                      <a
                        className="btn themebtn-secondory-large"
                        onClick={props.selectPlan(plan)}
                      >
                        SELECT
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
        })
        return (
            <>{render_plans}</>
        )
    }
    const renderComparePlans = () => {
        var sections = [];

        features.forEach(function(section){
            sections.push((
                <div key={"Section"+section.Section} >
                    <div className="features-heading-row headrow featurerow">

                        <div key={"plan_section"+section.Section} className="feature-heading">
                            {section.Section}
                        </div>
                        {section.headings && 
                            <>
                            {
                                props.plans.map((plan)=>{
                                    return ( 
                                        <div key={"plan_name"+plan.id} className="feature-listbox feature-listbox-1">
                                            {plan.plan_name=="Business" ? "PRO" : plan.plan_name.toUpperCase()}
                                        </div>
                                    );
                                    
                                    
                                })   
                            }
                            </>    
                        }
                        
                    </div>
                    {section.features.map(function(feature){
                        return (
                            <div key={"feature_head"+feature.label} className="features-heading-row featurerow">

                                <div className="feature-heading">
                                    {feature.label}

                                    {feature.coming_soon &&
                                    <span className="coming_soon_tag">Coming Soon</span>
                                    }
                                    {feature.sub_heading &&
                                    <p className="sub_heading_tag">{feature.sub_heading}</p>
                                    }

                                </div>
                            
                            
                                {
                                    props.plans.map((plan)=>{

                                        var featurelabel = null;

                                        if(plan[feature.key]=="Yes"){
                                            featurelabel = (<img className="tickimage" src={process.env.REACT_APP_URL+"images/tick.svg"} alt="icon"/>);
                                        } else if(plan[feature.key]=="No"){
                                            featurelabel = "-";
                                        } else {
                                            featurelabel = plan[feature.key];
                                        }

                                        return ( 
                                            <div  key={plan.id+"feature"+feature.key} className="feature-listbox feature-listbox-1">
                                                {featurelabel}
                                            </div>
                                            
                                        );
                                        
                                        
                                    })   
                                }
                            </div>
                        )
                    })}
                </div>
            ))
        });

        return sections;
    }
    return (
        <>
            <div className="dashboard-top-wrapper billing-plan-top-wrap">
                <div className="dash-top-left">
                    <h1 className="page-main-sm-heading">Select your plan
                    </h1>
                    <p className="page-small-info-text">Choose the right plan for your user research needs.</p>
                </div>
                <div className="dashboard-top-right">
                    <label className="check-switch plan-change-switch">
                        <p className="monthly-plan-text"><b>Monthly</b></p>
                        <input type="checkbox" checked={props.term=="Yearly"?true:false} onChange={(event)=>toggleCheck( event.currentTarget.checked ? 1 : 0)} />
                        <span className="check-slider"></span>
                        <p className="yearly-plan-text">Yearly</p>
                    </label>
                </div>
            </div>
        
            <div className="plan-option-wrap">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 planboxes">
                        <div className="row">
                            {renderPlans()}
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 compare-plan-features-outer">
                        <h2 className={`h2 plancollapse ${collapse!=1?'active_collapse':''} `} onClick={()=>{ setCollapse(!collapse) }}>Compare plans <span></span> </h2>
                        <div className={` ${collapse!=1?'expanded_section':'collapsed_section'} `}>
                            {renderComparePlans()}
                        </div>
                    </div>
                </div>
            
            </div>
        </>
    )
}