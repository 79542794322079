import React, { useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./AILoaderModal.css";
import { Text } from "../Text/Text";

export default function AILoaderModal({ showModal, open }) {
    const wrapperRef = useRef(null);
    const svgContainer1 = useRef(null);
    useEffect(() => {
        if (window.lottie && svgContainer1.current) {
            const animItem = window.lottie.loadAnimation({
                container: svgContainer1.current, // Reference to the container div
                renderer: "svg",
                loop: true,
                autoplay: true,
                path: `${process.env.REACT_APP_URL}animations/AILoader.json`,
            });

            return () => {
                if (animItem) {
                    animItem.destroy();
                }
            };
        }
    }, []);

    return (
        <Modal
            show={showModal}
            centered
            data-bs-backdrop="static"
            className="fade custom-modal-wrap ai_loader_modal"
        >
            <Modal.Body className="modal-lg" ref={wrapperRef}>
                <div className="modal-inner-text text-center">
                    <div className="mb-60 mt-32 AI_loader_icon">
                        <div className="lottie-hold">
                            <div
                                className="animation-svg-hold"
                                id="svg"
                                ref={svgContainer1}
                                style={{ width: 300, height: 300 }}
                            ></div>
                        </div>
                    </div>
                    <Text type={"body-text-3"} fontWeight={"medium-font"}>
                        Generating your survey...
                    </Text>
                </div>
            </Modal.Body>
        </Modal>
    );
}
