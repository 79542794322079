import {Link, useNavigate} from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession.js";
import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/layout.js";
import { Dropdown } from "reactjs-dropdown-component";
import Select from "react-select";
import {
    getProfile,
    deleteAccount,
    updateTesterProfile,
    disconnectSocialAccountService,
} from "../../services/user.js";
import { nationalities } from "../../data/nationalities.js";
import { languages } from "../../data/languages_others.js";
import { countries } from "../../data/countries.js";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";
import { countries as sanctioned_countries } from "../../data/countries_sanctioned.js";
import IntlTelInput from "react-intl-tel-input";
import { PhoneNumberUtil } from "google-libphonenumber";
import toast from "react-hot-toast";
import ConfirmModal from "../dialog/confirm_modal.js";
import CheckboxNormal from "../layouts/elements/fields/checkbox_normal.js";
import { getAge , removeItemFromArray } from "../../lib/helpers.js";
import SelectElement from "../layouts/elements/fields/select_element.js";
import { FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { format } from "date-fns";
import { LoadingIcon } from "../loader/loadingIcon";
import { sendChangeEmailToUser } from './../../services/user';
import {unnormalize, normalize, DropdownIndicator} from "../../lib/helpers";
import TesterProfileNavigation from "./tester_profile_navigation.js";
import TesterAccountNavigation from "./tester_account_navigation.js";
import ChangePasswordModal from "./reset_password_modal.js";
import { Text } from "../themes/userq/Text/Text.js";
import { Input } from "../themes/userq/Input/Input.js";
import { Button } from "../themes/userq/Button/Button.js";
import { Icon } from "../themes/userq/Icon/Icon.js";
import { PhoneNumberInput } from "../themes/userq/PhoneNumberInput/PhoneNumberInput.js";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown.js";
import {AutoCompleteSingleSelect} from "../themes/userq/Dropdown/AutoCompleteSingleSelect";
import ConfirmationModal from '../themes/userq/Modal/ConfirmationModal.js';
import DeleteAccountModal from '../themes/userq/Modal/DeleteAccountModal.js';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { LayoutTester } from "../themes/userq/Layouts/layout_tester.js";
import { CheckBox } from "../themes/userq/CheckBox/CheckBox.js";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton.js";
import { InputCalendar } from "../themes/userq/InputCalendar/InputCalendar.js";
import { CountryDropdownSingle } from "../themes/userq/CountryDropdown/CountryDropdownSingle.js";
import { Chip, Chips } from "../themes/userq/Chips/Chips.js";
import { IncDecCounter } from "../themes/userq/IncDecCounter/IncDecCounter.js";
import { MultiSelectDropdown } from "../themes/userq/MultiSelectDropdown/MultiSelectDropdown.js";
import { SearchDropdownMultiple } from "../themes/userq/CountryDropdown/SearchDropdownMultiple.js";
import { settings } from "../../data/settings.js";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage.js";
import { showToast } from "../../lib/helpers.js";
import { nationalities_other } from "../../data/nationalities_other";
import { InputDatePicker } from "../themes/userq/InputDatePicker/InputDatePicker.js";
import { ScoreChip } from "../themes/userq/ScoreChip/ScoreChip.js";
import Skeleton from "react-loading-skeleton";
import { Box } from "../themes/userq/Box/Box.js";
import {VerifyId} from "../idVerification/VerifyId";
import {ExpiryId} from "../idVerification/ExpiryId";
import DocumentNotMatch from "../themes/userq/Modal/DocumentNotMatch";
import {Link as UserQLink} from "../themes/userq/Link/Link";
import SetPasswordModal from "./set_password_modal.js";
import Cookies from 'js-cookie';
import { getLanguagesService } from "../../services/language.js";

export default function ProfileTester() {
    ReactSession.setStoreType("localStorage");

    const accountstatus=ReactSession.get("accountstatus");

    const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);

    const [socialDisconnectLoading, setSocialDisconnectLoading] = useState(false);

    let years = [];

    for (
        let year = new Date().getFullYear() - 8;
        year > new Date().getFullYear() - 8 - 50;
        year--
    ) {
        years.push({ label: year + "", value: year + "" });
    }
    const [openModal, setOpenModal] = useState(false);
    const [testerscore, setTesterScore] = useState(null);

    var today = new Date();


    const locations = nationalities;

    const hoursOptions = [
        { label: "1-3 hours", value: "1-3 hours" },
        { label: "4-6 hours", value: "4-6 hours" },
        { label: "more than 6 hours", value: "more than 6 hours" },
    ];

    const industryOptions = [
        {
            label: "Accountancy, banking and finance",
            value: "Accountancy, banking and finance",
        },
        {
            label: "Armed force, defence & military",
            value: "Armed force, defence & military",
        },
        {
            label: "Aviation",
            value: "Aviation",
        },
        {
            label: "Business, consulting and management",
            value: "Business, consulting and management",
        },
        {
            label: "Charity and voluntary work",
            value: "Charity and voluntary work",
        },
        { label: "Creative arts and design", value: "Creative arts and design" },
        { label: "Digital", value: "Digital" },
        { label: "Energy and utilities", value: "Energy and utilities" },
        {
            label: "Engineering and manufacturing",
            value: "Engineering and manufacturing",
        },
        {
            label: "Environment and agriculture",
            value: "Environment and agriculture",
        },
        {
            label: "Food and beverage",
            value: "Food and beverage",
        },
        { label: "Healthcare", value: "Healthcare" },
        {
            label: "Hospitality and events management",
            value: "Hospitality and events management",
        },
        { label: "Information technology", value: "Information technology" },
        { label: "Law", value: "Law" },
        {
            label: "Law enforcement and security",
            value: "Law enforcement and security",
        },
        {
            label: "Leisure, sport and tourism",
            value: "Leisure, sport and tourism",
        },
        {
            label: "Marketing, advertising and PR",
            value: "Marketing, advertising and PR",
        },
        { label: "Media, Internet & telecommunications", value: "Media, Internet & telecommunications" },
        { label: "Property and construction", value: "Property and construction" },
        { label: "Nonprofit", value: "Nonprofit" },
        {
            label: "Public services and administration",
            value: "Public services and administration",
        },
        { label: "Real Estate", value: "Real Estate" },
        { label: "Recruitment and HR", value: "Recruitment and HR" },
        { label: "Retail", value: "Retail" },
        { label: "Sales", value: "Sales" },
        {
            label: "Science and pharmaceuticals",
            value: "Science and pharmaceuticals",
        },
        {
            label: "Security",
            value: "Security",
        },
        { label: "Social care", value: "Social care" },
        {
            label: "Teacher training and education",
            value: "Teacher training and education",
        },
        {
            label: "Transport and logistics",
            value: "Transport and logistics",
        },

        { label: "Other (please specify)", value: "Other (please specify)" },
    ];

    const employmentOptions = [
        { label: "Student", value: "Student" },
        { label: "Self employed", value: "Self employed" },
        { label: "Unemployed", value: "Unemployed" },
        { label: "Private employee", value: "Private employee" },
        { label: "Public employee", value: "Public employee" },
        { label: "Homemaker", value: "Homemaker" },
        { label: "Retired", value: "Retired" },
    ];

    const incomelevelOptions = [
        { label: "$0 - No income", value:"$0 - No income"},
        { label:"$1 - $250", value:"$1 - $250"},
        { label: "$251 - $500", value: "$251 - $500"},
        { label: "$501 - $1,500", value: "$501 - $1,500" },
        { label: "$1,501 - $2,500", value: "$1,501 - $2,500" },
        { label: "$2,501 - $5,000", value: "$2,501 - $5,000" },
        { label: "$5,001 - $7,500", value: "$5,001 - $7,500" },
        { label: "$7,501 - $10,000", value: "$7,501 - $10,000" },
        { label: "$10,001 - $12,500", value: "$10,001 - $12,500" },
        { label: "$12,501 - $15,000", value: "$12,501 - $15,000" },
        { label: "Over $15,000", value: "Over $15,000" },
    ];


    const [formLoading, setFormLoading] = useState(false);

    const [profileChanged, setProfileChanged] = useState(false);

    const [employmentFieldsReadOnly, setEmploymentFieldsReadOnly] = useState(false);

    const [documentNotMatch, setDocumentNotMatch] = useState({ open: false });

    const [primaryLangs, setPrimaryLangs] = useState([]);

    const [langs, setLangs] = useState([]);

    const [formValues, setFormValues] = useState({
        document_provided_fields:[],
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        whatsapp_checkbox: true,
        whatsapp_phone_number:"",
        date_of_birth: "",
        gender: "male",
        nationality: "",
        spoken_language: [],
        language: [],
        language_ids: [],
        country: "",
        region:"",
        apps: [],
        hours_spend_online: "",
        tech_gadgets: "",
        highest_education_level: "",
        employment: "",
        industry:"",
        industry_other: "",
        department: "",
        people: 0,
        income_slab: ""
    });


    const [formErrors, setFormErrors] = useState({
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        whatsapp_checkbox: true,
        whatsapp_phone_number:null,
        date_of_birth: null,
        gender: null,
        nationality: null,
        spoken_language: null,
        language: null,
        country: null,
        region:null,
        apps: null,
        hours_spend_online: null,
        tech_gadgets: null,
        highest_education_level: null,
        employment: null,
        industry:null,
        industry_other: null,
        department: null,
        people: null,
        income_slab: null,
        error_class: "input_error",
    });


    const [isLoading, setLoading] = useState(false);

    const [username, setUsername] = useState("");

    const [accountLoading, setAccountLoading] = useState(false);

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [confirm, setConfirm] = useState({ open: false });


    const [changeEmailModal, setChangeEmailModal] = useState({ open: false });

    const [isProfileVerified, setIsProfileVerified] = useState('');
    const [isProfileVerifiedAt, setIsProfileVerifiedAt] = useState('');
    const [isProfileExpiredAt, setIsProfileExpiredAt] = useState('');
    const [isProfileAttempts, setIsProfileAttempts] = useState(0);


    const gadgetsOptions = [
        { label: "Desktop", value: "Desktop" },
        { label: "Smartphone", value: "Smartphone" },
        { label: "Tablet", value: "Tablet" },
    ];

    const appsOptions = [
        "Shopping",
        "Hobbies",
        "Social media",
        "News",
        "Gaming",
        "Chat",
        "Collaboration",
        "Banking & finance",
        "Health",
        "Transportation",
        "Travel",
        "Office",
        "Mail",
        "Food delivery",
        "Content creation",
        "Learning",
        "Maintenance",
        "Streaming",
        "Messaging",
        "Fitness",
    ];


    const educationOptions = [
        { label: "Primary school", value: "Primary school" },
        { label: "High school", value: "High school" },
        { label: "Bachelor degree", value: "Bachelor degree" },
        { label: "Master degree", value: "Master degree" },
        { label: "Post graduate degree", value: "Post graduate degree" },
        { label: "Doctorate", value: "Doctorate" }
    ];

    const departmentOptions = [
        { label: "Finance", value: "Finance" },
        { label: "HR", value: "HR" },
        { label: "Admin", value: "Admin" },
        { label: "Design & research", value: "Design & research" },
        { label: "IT & tech", value: "IT & tech" },
        { label: "Branding & marketing", value: "Branding & marketing" },
        { label: "Sales", value: "Sales" },
        { label: "Purchase", value: "Purchase" },
        { label: "Legal", value: "Legal" },
        { label: "Other (please specify)", value: "Other (please specify)" },
    ];

    let user = ReactSession.get("user");
    let score = ReactSession.get("testerscore");

    const [whatsapp_country, setWhatsAppCountry] = useState("+971");

    const [displaySanctionedMessage, setDisplaySanctionedMessage] = useState(false);


    const navigate = useNavigate();


    useEffect(() => {
        document.title = "Your profile | "+process.env.REACT_APP_NAME;

        getLanguagesApi();

    }, []);

    useEffect(()=>{
        if(langs.length > 0) {
        getProfileData();
        }
    },[langs])
    const getProfileData = ()=>{

        let token = ReactSession.get("token");
        setLoading(true);

        getProfile(token).then((response) => {
            setLoading(false);

            // check document not matched then open madal
            if(ReactSession.get("document_not_matched_model")){

                setDocumentNotMatch({
                    open: true,

                });
                console.log('adsa')
                ReactSession.set("document_not_matched_model",false);
            }

            if (response.success) {

                // check profile is verified or not
                setIsProfileVerified(response.data.user.profile_verification_status);
                setIsProfileVerifiedAt(response.data.user.verified_at);


                // checked profile expired date
                // if(response.data.user.document_expiry_date_left){
                setIsProfileExpiredAt(response.data.user.document_expiry_date_left);
                setIsProfileAttempts(response.data.user.expired_document_attempt);
                // }else{
                //     setIsProfileExpiredAt('');
                // }


                setTesterScore(response.data.user.score);

                var sanctioned_country = false;

                sanctioned_countries.forEach(function(country){
                    if(country.value==response.data.user.country){
                        sanctioned_country = true;
                    }
                });
                setDisplaySanctionedMessage(sanctioned_country);

                // console.log(response.data.user.date_of_birth)
                // console.log(format(new Date(response.data.user.date_of_birth), "d/M/Y"))
                // console.log(moment(response.data.user.date_of_birth).format("d/M/Y"))
                // console.log(moment(response.data.user.date_of_birth).format("Y-MM-DD"))

                var languages = [];

                var spoken_languages = [];

                var language_ids = [];

                response.data.user.languages.forEach(function(userLang){

                    primaryLangs.forEach(function(primaryLang){
                        if(primaryLang.id === userLang.language_id){
                            
                            spoken_languages.push(primaryLang.iso_name_english);
                        }
                    });
                    langs.forEach(function(lang){

                        if(lang.id === userLang.language_id){
                            
                            languages.push(lang.label);
                        }
                       
                    });
                    language_ids.push(userLang.language_id);                    
                });            
                if(language_ids.length == 0){
                    
                    primaryLangs.forEach(function(primaryLang){
                        if(primaryLang.is_selected==1){
                            
                            spoken_languages.push(primaryLang.iso_name_english);

                            language_ids.push(primaryLang.id);   
                        }
                    });
                }

                setFormValues({
                    ...formValues,
                    password_updated_at: response.data.user.password_updated_at,
                    password_set: response.data.user.password_set,
                    provider: response.data.user.provider,
                    first_name: response.data.user.first_name,
                    last_name: response.data.user.last_name,
                    email: response.data.user.email,
                    phone_number:
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number
                            : "",
                    whatsapp_phone_number:
                        response.data.user.whatsapp_phone_number != null
                            ? response.data.user.whatsapp_phone_number
                            : "",
                    whatsapp_checkbox: response.data.user.whatsapp_phone_number == response.data.user.phone_number ? true: false,
                    date_of_birth: response.data.user.date_of_birth
                        ? moment(response.data.user.date_of_birth).format("Y-MM-DD")
                        : "",
                    gender:
                        response.data.user.gender != null
                            ? response.data.user.gender
                            : "male",
                    nationality: response.data.user.nationality,
                    /*spoken_language:
                        response.data.user.spoken_language == null
                            ? []
                            : response.data.user.spoken_language.split(","),*/
                    //language: response.data.user.language == null || response.data.user.language == ''? [] : response.data.user.language.split(","),
                    spoken_language: spoken_languages,
                    language: languages,
                    language_ids: language_ids,
                    country: response.data.user.country,
                    region: response.data.user.region,
                    hours_spend_online: response.data.user.hours_spend_online,
                    tech_gadgets: response.data.user.tech_gadgets,
                    apps:
                        response.data.user.apps != null
                            ? response.data.user.apps.split(",")
                            : [],
                    highest_education_level: response.data.user.highest_education_level,
                    employment: response.data.user.employment,
                    industry: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ? "" : response.data.user.industry,
                    industry_other: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ? "" : (response.data.user.industry_other ? response.data.user.industry_other : ""),
                    department: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ? "" : response.data.user.department,
                    department_other: ["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment) ? "" : (response.data.user.department_other ? response.data.user.department_other : ""),
                    people: response.data.user.people,
                    income_slab:response.data.user.income_slab,
                    document_provided_fields: response.data.user.document_provided_fields ? response.data.user.document_provided_fields.split(",") : [],
                });
                if(["Student","Unemployed","Homemaker","Retired"].includes(response.data.user.employment)){

                    setEmploymentFieldsReadOnly(true);


                } else {
                    setEmploymentFieldsReadOnly(false);
                }

            } else {

                let message = response.message;
                if (response.message == "Unauthenticated.") {
                    message = "If you are unable to see your profile, kindly logout and log in again";
                }
                var id = toast(
                    <div className="toastinner">
                        {message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    }
    const getLanguagesApi = () => {
        
        setLoading(true);


        getLanguagesService().then(response => {
            

            if (response.success) {

                var primary_langs = [];

                var other_langs = [];

                

                response.languages.forEach(lang => {
                    if(lang.is_primary==1){
                        primary_langs.push(lang);
                    } else {
                        other_langs.push({"label":lang.iso_name_english, "value":lang.iso_name_english, "id":lang.id});
                    }
                    
                });

                setPrimaryLangs(primary_langs.sort(function (a, b) {
                    if (a.iso_name_english < b.iso_name_english) {
                        return 1;
                      }
                      return -1;
                }));

                setLangs(other_langs);

                //getProfileApi();
                
            } else {
                setLoading(false);
            }
        });
    }
    const set = (name) => {
        return ({ target: { value } }) => {
            if (name == "income") {
                if (value == 0) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value.slice(0, -1),
                    }));
                } else {
                    setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
                    setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
                setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            }
            setProfileChanged(true);
        };
    };

    const setAppOption = (option) => {
        return (event) => {
            let apps = formValues.apps;
            if (apps.includes(option)) {
                const index = apps.indexOf(option);
                if (index > -1) {
                    apps.splice(index, 1);
                }
            } else {
                apps.push(option);
            }
            setFormValues((oldValues) => ({ ...oldValues, ["apps"]: apps }));
            setFormErrors((oldValues) => ({ ...oldValues, apps: null }));
        };
    };


    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const userToClass = (option) => {
        if (formValues.apps.includes(option)) {
            return "active";
        }
        return "";
    };
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };
    const showError = (error) => {

        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {


        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const closeModal = (reload) => {
        setOpenModal(false);
    }
    const resetFormErrors = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };


    const validateFullForm = (focus, key, value, inline) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }

        if(focus){

            form_errors = resetFormErrors();
        }


        var firsterrorid = null;

        if(!key || key=="first_name"){

            var field_value = key && inline ? value : formValues.first_name;

            if (field_value == '') {

                form_errors = {
                    ...form_errors,
                    first_name: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "first_name" : firsterrorid;
            }
        }
        if(!key || key=="last_name"){

            var field_value = key && inline ? value : formValues.last_name;

            if (field_value == '') {

                form_errors = {
                    ...form_errors,
                    last_name: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "last_name" : firsterrorid;
            }
        }
        if(!key || key=="phone_number"){

            var field_value = key && inline ? value : formValues.phone_number;

            // console.log(field_value.toString())
            // console.log('+'+field_value.toString())
            // console.log(isValidPhoneNumber('+918266064171'))
            // console.log(isValidPhoneNumber('+'+field_value.toString()))

            if(!field_value || !isValidPhoneNumber('+'+field_value.toString())){

                form_errors = {
                    ...form_errors,
                    phone_number: "Phone number is not valid",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "phone_number" : firsterrorid;
            }
        }
        if(formValues.whatsapp_phone_number!='' && formValues.whatsapp_phone_number!=undefined && formValues.whatsapp_phone_number!="+"+whatsapp_country){

            if(!key || key=="whatsapp_phone_number"){

                var field_value = key && inline ? value : formValues.whatsapp_phone_number;

                if(!field_value ||  !isValidPhoneNumber('+'+field_value.toString())){

                    form_errors = {
                        ...form_errors,
                        whatsapp_phone_number: "WhatsApp number is not valid",
                        error_class: "input_error"
                    };
                    error = true;
                    firsterrorid = firsterrorid == null ? "whatsapp_phone_number" : firsterrorid;
                }
            }
        }
        if(!key || key=="date_of_birth"){

            var field_value = key && inline ? value : formValues.date_of_birth;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    date_of_birth: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "date_of_birth" : firsterrorid;
            } else if(getAge(formValues.date_of_birth)<18) {

                form_errors = {
                    ...form_errors,
                    date_of_birth: "You must be 18 years of age",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "date_of_birth" : firsterrorid;
            }
        }
        if(!key || key=="nationality"){

            var field_value = key=="nationality" ? value : formValues.nationality;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    nationality: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "nationality" : firsterrorid;
            }
        }
        if(!key || key=="country"){

            var field_value = key=="country" ? value : formValues.country;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    country: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "country" : firsterrorid;
            }
        }
        if(!key || key=="region"){

            var field_value = key && inline ? value : formValues.region;

            if ((formValues.country=="United Arab Emirates" || formValues.country=="Suadi Arabia") && !field_value) {
                form_errors = {
                    ...form_errors,
                    region: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "region" : firsterrorid;
            }
        }
        if(!key || key=="spoken_language"){

            var field_value = key && inline ? value : formValues.spoken_language;

            if (field_value.length==0) {
                form_errors = {
                    ...form_errors,
                    spoken_language: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "spoken_language" : firsterrorid;
            }
        }

        /*if(!key || key=="language"){

            var field_value = key && inline ? value : formValues.language;

            if (field_value.length==0) {
                form_errors = {
                    ...form_errors,
                    language: "Please select atleast one other language",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "language" : firsterrorid;
            }
        }*/
        if(!key || key=="hours_spend_online"){

            var field_value = key  ? value : formValues.hours_spend_online;

            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    hours_spend_online: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "hours_spend_online" : firsterrorid;
            }
        }
        if(!key || key=="tech_gadgets"){

            var field_value = key  ? value : formValues.tech_gadgets;

            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    tech_gadgets: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "tech_gadgets" : firsterrorid;
            }
        }
        if(!key || key=="apps"){

            var field_value = key && inline ? value : formValues.apps;

            if (field_value.length==0) {

                form_errors = {
                    ...form_errors,
                    apps: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "apps" : firsterrorid;
            }
        }
        if(!key || key=="highest_education_level"){

            var field_value = key  ? value : formValues.highest_education_level;

            if (!field_value) {
                form_errors = {
                    ...form_errors,
                    highest_education_level: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "highest_education_level" : firsterrorid;
            }
        }

        if(!key || key=="industry"){

            var field_value = key ? value : formValues.industry;

            if (!field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {

                form_errors = {
                    ...form_errors,
                    industry: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "industry" : firsterrorid;
            }
        }
        if(!key || key=="industry_other"){

            var field_value = key && inline ? value : formValues.industry_other;

            if ((formValues.industry=="Other (please specify)") && !field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {
                form_errors = {
                    ...form_errors,
                    industry_other: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "industry_other" : firsterrorid;
            }
        }
        if(!key || key=="department"){

            var field_value = key  ? value : formValues.department;

            if (!field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {

                form_errors = {
                    ...form_errors,
                    department: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "department" : firsterrorid;
            }
        }
        if(!key || key=="department_other"){

            var field_value = key && inline ? value : formValues.department_other;

            if ((formValues.department=="Other (please specify)") && !field_value && !["Student","Unemployed","Homemaker","Retired"].includes( formValues.employment)) {
                form_errors = {
                    ...form_errors,
                    department_other: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "department_other" : firsterrorid;
            }
        }
        if(!key || key=="employment"){

            var field_value = key  ? value : formValues.employment;

            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    employment: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "employment" : firsterrorid;
            }
            if(["Student","Unemployed","Homemaker","Retired"].includes(field_value)){
                form_errors = {
                    ...form_errors,
                    industry: null,
                    industry_other: null,
                    department:null
                };
            }
        }
        if(!key || key=="income_slab"){

            var field_value = key  ? value : formValues.income_slab;

            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    income_slab: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }
        if(!key || key=="people"){

            var field_value = key && inline ? value : formValues.people;

            if (parseInt(field_value)==0) {

                form_errors = {
                    ...form_errors,
                    people: "People must be > 0",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "income_slab" : firsterrorid;
            }
        }

        if((inline && formErrors[key] ) || !inline){

            setFormErrors(form_errors);
        }

        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const checkBtnState = () => {

        console.log(formValues);

        var error = false;


        if (formValues.first_name == '') {
            error = true;
        }
        if (formValues.last_name == '') {
            error = true;
        }



        if(!formValues.phone_number || !isValidPhoneNumber('+'+formValues.phone_number.toString())){

            error = true;

        }
        if(formValues.whatsapp_phone_number!='' && formValues.whatsapp_phone_number!=undefined && formValues.whatsapp_phone_number!="+"+whatsapp_country){
            if(!formValues.whatsapp_checkbox  && formValues.whatsapp_phone_number){

                if(!formValues.whatsapp_phone_number || !isValidPhoneNumber('+'+formValues.whatsapp_phone_number.toString())){
                    error = true;
                }

            }
        }
        if (!formValues.date_of_birth || (formValues.date_of_birth && getAge(formValues.date_of_birth)<18)) {
            error = true;
        }
        if (!formValues.nationality) {
            error = true;
        }
        if (formValues.spoken_language.length==0) {
            error = true;
        }
        /*if (formValues.language.length==0) {
            error = true;
        }*/
        if (!formValues.hours_spend_online) {
            error = true;
        }
        if (!formValues.tech_gadgets) {
            error = true;
        }


        if (formValues.apps.length==0) {

            error = true;
        }
        if (!formValues.highest_education_level) {
            error = true;
        }
        if(!["Student","Unemployed","Homemaker","Retired"].includes(formValues.employment)){
            if (!formValues.industry) {
                error = true;
            }
            if (formValues.industry == 'Other (please specify)' && !formValues.industry_other) {
                error = true;
            }


            if (!formValues.department) {

                error = true;
            }
            if (formValues.department == 'Other (please specify)' && !formValues.department_other) {
                error = true;
            }
        }
        if (!formValues.employment) {
            error = true;
        }

        if (!formValues.country) {
            error = true;
        } else {
            if(!formValues.region && (formValues.country=="United Arab Emirates" || formValues.country=="Saudi Arabia")){

                error = true;
            }
        }


        if (!formValues.income_slab) {
            error = true;
        }
        if (parseInt(formValues.people) == 0) {
            error = true;
        }


        return error;
    }
    const onSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        resetFormErrors();

        if (!formLoading) {
            var error = validateFullForm(true,null);

            if (!error) {
                setFormLoading(true);

                setProfileChanged(false);

                const token = ReactSession.get("token");

                var formData = { ...formValues };

                /*if (formData.whatsapp_checkbox) {
                    formData.whatsapp_phone_number = "";
                }*/
                formData.date_of_birth = moment(formValues.date_of_birth).format("Y-MM-DD")

                formData["extra_date_of_birth"] = format(new Date(formValues.date_of_birth), "dd-MM-yy");
                //formData["language"] = normalize(formValues["language"]).join(",");
                //formData["industry"] = normalize(formValues["industry"]);
                //formData["department"] = normalize(formValues["department"]);


                //console.log(normalize(formValues["industry"]))

                updateTesterProfile(formData, token).then((response) => {
                    setFormLoading(false);

                    if (response.success) {
                        let user = ReactSession.get("user");

                        user.first_name = response.user.first_name;

                        user.last_name = response.user.last_name;

                        ReactSession.set("user", user);

                        //toast.success(response.message);

                        //setSuccessMessage(response.message);

                        setUsername(user.first_name + " " + user.last_name);

                        showSuccess(response.message);
                        /*toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );*/
                    } else {
                        //setErrorMessage(response.message);

                        showError(response.message);

                        /* toast(
                             <div className="toastinner">
                                 {response.message}
                                 <a
                                     onClick={() => {
                                         toast.dismiss();
                                     }}
                                 >
                                     &times;
                                 </a>
                             </div>,
                             {
                                 className: "errortoast",
                                 position: "bottom-center",
                                 duration: 3000,
                             }
                         );*/
                    }
                });
            } else {
                showError(<FormattedMessage id="There are one or more errors on the form. Please fix to proceed ahead." />);
                /*toast(
                    <div className="toastinner">
                        <FormattedMessage id="There are one or more errors on the form. Please fix to proceed ahead." />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 3000,
                    }
                );*/
            }
        }
        return false;
    };
    const deleteUserAccount = (event) => {
        setAccountLoading(false);

        if (!accountLoading) {
            setAccountLoading(true);

            const token = ReactSession.get("token");

            deleteAccount(token).then((response) => {
                setAccountLoading(false);

                if (response.success) {
                    ReactSession.set("user", {});

                    ReactSession.set("token", null);

                    Cookies.set("last_login_method_tester",null);

                    navigate("/tester/login");
                } else {
                    //setErrorMessage(response.message);

                    showToast(response.message,"error");
                }
            });
        }
    };
    const disconnectSocialAccount = async (provider) => {


        const token = ReactSession.get("token");

        let body = Object.assign({}, {provider:provider});

        setSocialDisconnectLoading(true);

        disconnectSocialAccountService(body, token)
            .then(response => {
                setSocialDisconnectLoading(false);

                if (response.success) {

                    Cookies.set("last_login_method_tester",null);

                    let form_values = {...formValues};

                    form_values.provider = null;

                    setFormValues(form_values);

                    showToast(response.message, "success");


                } else {

                    showToast(response.message, "error");

                }
            })
        return false;
    }
    const sendChangeEmail = (event) => {
        setAccountLoading(false);
        if (!accountLoading) {

            setAccountLoading(true);

            const token = ReactSession.get("token");

            sendChangeEmailToUser(token)
                .then(response => {
                    setAccountLoading(false);

                    if (response.success) {

                        setChangeEmailModal({ ...changeEmailModal, open: false });


                        showToast(response.message,"success");

                        // ReactSession.set("user",{});

                        // ReactSession.set("token",null);

                        // navigate("/researcher/login");

                    } else {
                        showToast(response.message,"error");
                        // setErrorMessage(response.message);
                    }
                })

        }
    }

    return (
        <LayoutTester
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            username={username}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"profile"}
        >
            {accountstatus=="blocked" && <div className="account-blocked-message">
                <div><Icon colorClass={"danger-red-200"} value={"blocked"} size="large"/></div>
                <div>
                    <Text type={"subtitle-1"} children="Your payout cannot be redeemed as your profile is currently blocked."/>
                    <Text type={"body-text-2"}>Please contact <a className='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile"} target="_blank">UserQ support</a>, to guide you through the next steps.</Text>
                </div>
            </div>}
            <div className='page-heading-wrap accounts-page--heading justify-content-between tester-profile-account-head-wrap'>
                <div className="tester-profile-account-head d-flex align-items-center">
                    <Text type="h1">Account
                    <div className="researcher-profile-form-btn none-for-desktop">
                    {user.role == "Tester" && accountstatus!="blocked" && (
                        <>
                            {profileChanged ?
                                <Button type="primary" size="large" label="Save updates" state={checkBtnState()?"disabled":"active"}  onClick={onSubmit} microLoading={formLoading} />
                                :
                                <Button type="primary" size="large" label="Save updates" state={formLoading?"active":"disabled"} onClick={()=>{validateFullForm(true, null)}} microLoading={formLoading}  />
                            }
                        </>                              
                    )}
                    {user.role == "Tester" && accountstatus=="blocked" && (                         
                        <Button type="primary" size="large" label="Save updates" state={"disabled"}  onClick={()=>{}}  />                                  
                    )}

                </div>
                    </Text>

                    <div className="accounts-score-chip">
                        <ScoreChip testerscore={score}/>
                        <div className='account-guidelines-link'>
                            <Text type="body-text-3">
                                Read more about  <a className='faq-support-link' href={process.env.REACT_APP_URL+"t/tester-guidelines"} target="_blank">tester guidelines</a>
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="researcher-profile-form-btn none-for-mobile">
                    {user.role == "Tester" && accountstatus!="blocked" && (
                        <>
                            {profileChanged ?
                                <Button type="primary" size="large" label="Save updates" state={checkBtnState()?"disabled":"active"}  onClick={onSubmit} microLoading={formLoading} />
                                :
                                <Button type="primary" size="large" label="Save updates" state={formLoading?"active":"disabled"} onClick={()=>{validateFullForm(true, null)}} microLoading={formLoading}  />
                            }
                        </>                              
                    )}
                    {user.role == "Tester" && accountstatus=="blocked" && (                         
                        <Button type="primary" size="large" label="Save updates" state={"disabled"}  onClick={()=>{}}  />                                  
                    )}

                </div>

            </div> 
            <TesterAccountNavigation activeMenu="profile" /> 

            {isLoading ? (
                <div className="accountloading-box"> <LoadingIcon /></div>
            ) : (
                <div className={`tester-profile ${accountstatus=="blocked"?"blocked-profile":"active-profile"}`}>

                    <div className="idverify-message mb-32">
                        {(isProfileVerified === 'id_not_matched_profile' || isProfileVerified === 'unverified' || (isProfileVerified === 'expired_document' && isProfileExpiredAt === "") || isProfileVerified === 'invalid_document') &&
                        <>
                            <VerifyId isType={'banner'} isProfileVerifiedAt={isProfileVerifiedAt} isProfileVerified={isProfileVerified} confirmHandler={(status)=>{
                                if(status){
                                    navigate("/dashboard");
                                }else{
                                    getProfileData();
                                }
                            }} />
                        </>
                        }
                    </div>

                    <div className="idexpiry-message mb-32">
                        {((isProfileVerified === 'verified' || isProfileVerified === 'expired_document') && isProfileExpiredAt !== "" && isProfileExpiredAt >=0) &&
                        <ExpiryId profileExpiredDate={isProfileExpiredAt} isProfileAttempts={isProfileAttempts} isProfileVerifiedAt={isProfileVerifiedAt} confirmHandler={()=>{

                            getProfileData();
                        }}/>
                        }

                    </div>

                    <div className="tester_profole_guide_info mb-32">
                        <Text type="body-text-1">Please note that the information in your profile will never be shared with the researchers.</Text>
                        <Link className={'link-text'} to={"/t/support/"}>Learn more about how we use your data.</Link>
                    </div>

                    <div className="profile-form-wrap profile-tester-wrap ">
                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Personal information</Text>
                            </div>

                            {/* After onfido verification */}
                            {isProfileVerified === 'verified' &&
                            <div className={`tester_verified_profile_info ${(formValues.document_provided_fields.includes("date_of_birth") && formValues.document_provided_fields.includes("first_name") && formValues.document_provided_fields.includes("last_name") && formValues.document_provided_fields.includes("nationality") && formValues.document_provided_fields.includes("gender"))?'':'mb-32'} `}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Box
                                            cssClasses="sign-up-verified-info gray-box p-20 tester_verified_profile_box">

                                            <div
                                                className='signup_veri_infotop d-flex align-items-center mb-20 verified_info_success'>
                                                <Icon colorClass="success-green-200" size={"medium"} value="ID"/>
                                                <Text type={"body-text-1"} fontWeight={"medium-font"}
                                                      cssClasses="green-color"> Verified information</Text>
                                            </div>

                                            <div className='signup_user_info'>

                                                {(formValues.document_provided_fields.includes("first_name")) &&
                                                <div className='signup_user_inforepeat'>
                                                    <div
                                                        className='signup_user_left_info d-flex align-items-center justify-content-between'>
                                                        <Text type={"body-text-2"}
                                                              cssClasses="color-black user_info_label">First name</Text>
                                                        <Text type={"body-text-2"} fontWeight={"medium-font"}
                                                              cssClasses="color-black">{formValues.first_name ? formValues.first_name : '-'}
                                                            <Icon  colorClass="success-green-200" value="verified"/>
                                                        </Text>
                                                    </div>
                                                </div>
                                                }

                                                {(formValues.document_provided_fields.includes("last_name")) &&
                                                <div className='signup_user_inforepeat'>
                                                    <div
                                                        className='signup_user_left_info d-flex align-items-center justify-content-between'>
                                                        <Text type={"body-text-2"}
                                                              cssClasses="color-black user_info_label">Last name</Text>
                                                        <Text type={"body-text-2"} fontWeight={"medium-font"}
                                                              cssClasses="color-black">{formValues.last_name ? formValues.last_name : '-'}
                                                            <Icon  colorClass="success-green-200" value="verified"/>
                                                        </Text>
                                                    </div>
                                                </div>
                                                }

                                                {(formValues.document_provided_fields.includes("date_of_birth"))  &&
                                                <div className='signup_user_inforepeat'>
                                                    <div
                                                        className='signup_user_left_info d-flex align-items-center justify-content-between'>
                                                        <Text type={"body-text-2"}
                                                              cssClasses="color-black user_info_label">Date of
                                                            birth</Text>
                                                        <Text type={"body-text-2"} fontWeight={"medium-font"}
                                                              cssClasses="color-black">
                                                            {format(
                                                                new Date(formValues.date_of_birth),
                                                                "dd/MM/yyyy"
                                                            )}
                                                            {/*{(new Date(formValues.date_of_birth).getDate()) + '/' + (new Date(formValues.date_of_birth).getMonth() + 1) + '/' + new Date(formValues.date_of_birth).getFullYear()}*/}
                                                            <Icon  colorClass="success-green-200" value="verified"/> </Text>
                                                    </div>
                                                </div>
                                                }

                                                {(formValues.document_provided_fields.includes("nationality"))  &&
                                                <div className='signup_user_inforepeat'>
                                                    <div
                                                        className='signup_user_left_info d-flex align-items-center justify-content-between'>
                                                        <Text type={"body-text-2"}
                                                              cssClasses="color-black user_info_label">Nationality</Text>
                                                        <Text type={"body-text-2"} fontWeight={"medium-font"}
                                                              cssClasses="color-black">{formValues.nationality} <Icon  colorClass="success-green-200" value="verified"/>
                                                        </Text>
                                                    </div>
                                                </div>
                                                }

                                                {(formValues.document_provided_fields.includes("gender"))  &&
                                                <div className='signup_user_inforepeat'>
                                                    <div
                                                        className='signup_user_left_info d-flex align-items-center justify-content-between'>
                                                        <Text type={"body-text-2"}
                                                              cssClasses="color-black user_info_label">Gender</Text>
                                                        <Text type={"body-text-2"} fontWeight={"medium-font"}
                                                              cssClasses="color-black text-capitalize">{formValues.gender} <Icon  colorClass="success-green-200" value="verified"/>
                                                        </Text>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            }

                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className={`row ${(!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("first_name"))) || (!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("last_name")))?'':'green-flow-tester-profile'}`}>
                                            {!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("first_name"))  &&
                                            <div className="formbox col-md-6">
                                                <div className="profile-form-group">
                                                    <Input
                                                        label="First name"
                                                        onChange={set("first_name")}
                                                        onClear={() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                first_name: "",
                                                            }));
                                                        }}
                                                        onBlur={() => {
                                                            validateFullForm(false, "first_name")
                                                        }}
                                                        value={formValues.first_name}
                                                        maxLength="45"
                                                        hideMaxChars={true}
                                                        error={formErrors.first_name}
                                                        error_class={formErrors.error_class}
                                                        id="first_name"
                                                        clearField={false}
                                                        required={true}
                                                        readOnly={accountstatus == "blocked" ? true : false}
                                                        disabled={accountstatus == "blocked" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            }

                                            {!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("last_name"))  &&
                                            <div className="formbox col-md-6">
                                                <div className="profile-form-group">
                                                    <Input
                                                        label="Last name"
                                                        onChange={(accountstatus == "blocked") ? () => {
                                                        } : set("last_name")}
                                                        onBlur={() => {
                                                            validateFullForm(false, "last_name")
                                                        }}
                                                        onClear={() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                last_name: "",
                                                            }));
                                                        }}
                                                        value={formValues.last_name}
                                                        maxLength="45"
                                                        hideMaxChars={true}
                                                        error={formErrors.last_name}
                                                        error_class={formErrors.error_class}
                                                        id="last_name"
                                                        clearField={false}
                                                        required={true}
                                                        readOnly={accountstatus == "blocked" ? true : false}
                                                        disabled={accountstatus == "blocked" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            }

                                            {!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("date_of_birth"))  &&
                                            <div className="formbox col-md-6" id="date_of_birth">
                                                <InputDatePicker
                                                    value={formValues.date_of_birth}
                                                    onChange={(date) => {
                                                        //console.log(date);
                                                        if (date && accountstatus != "blocked") {
                                                            setFormValues({...formValues, "date_of_birth": date});
                                                            validateFullForm(false, "date_of_birth", date, true);
                                                            setProfileChanged(true);
                                                        }

                                                    }}
                                                    label="Date of birth"
                                                    error={formErrors.date_of_birth}
                                                    error_class={formErrors.error_class}
                                                    onBlur={() => {
                                                        validateFullForm(false, "date_of_birth")
                                                    }}
                                                    readOnly={accountstatus == "blocked" ? true : false}
                                                />
                                            </div>
                                            }

                                            {!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("nationality")) &&
                                            <div className={`formbox col-md-6 ${!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("gender")) ? '':'mb-0'}`} id="nationality">
                                                <AutoCompleteSingleSelect
                                                    type={"nationality"}
                                                    id={"nationality_others"}
                                                    name="nationality_others"
                                                    title="Select levels of education..."
                                                    searchable={[
                                                        "Search for country level",
                                                        "No matching country level",
                                                    ]}
                                                    value={formValues.nationality}
                                                    onChange={(item) => {
                                                        if (accountstatus != "blocked") {
                                                            if (item) {
                                                                setFormValues({...formValues, nationality: item.value});


                                                            } else {
                                                                setFormValues({...formValues, nationality: ''});
                                                            }

                                                            validateFullForm(false, "nationality", item ? item.value : '');

                                                            setProfileChanged(true);
                                                        }
                                                    }}
                                                    options={nationalities_other}
                                                    label={"Nationality"}
                                                    isClearable={true}
                                                    error={formErrors.nationality}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>
                                            }

                                            {!(isProfileVerified == 'verified' && formValues.document_provided_fields.includes("gender")) &&
                                            <div className="formbox col-md-6 d-flex-radiobox mb-0" id="gender">
                                                <label className="bold">Gender</label>
                                                <div className="d-flex-radiobtn">
                                                    <RadioButton
                                                        name="gender"
                                                        label="Male"
                                                        isChecked={formValues.gender == "Male" ? true : false}
                                                        onChange={() => {
                                                            if (accountstatus != "blocked") {
                                                                setFormValues({...formValues, gender: "Male"});
                                                                setProfileChanged(true);
                                                            }
                                                        }}
                                                    />
                                                    <RadioButton
                                                        name="gender"
                                                        label="Female"
                                                        isChecked={formValues.gender == "Female" ? true : false}
                                                        onChange={() => {
                                                            if (accountstatus != "blocked") {
                                                                setFormValues({...formValues, gender: "Female"});
                                                                setProfileChanged(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Contact details</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row change-email-row">
                                            <div className="col-md-6 formbox fullformbox">
                                                <div className="profile-form-group ">
                                                    <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                        <Input
                                                            label="Email address"
                                                            onChange={() => { }}
                                                            onClear={() => { }}
                                                            value={user.email}
                                                            error={formErrors.email}
                                                            error_class={formErrors.error_class}
                                                            id="email"
                                                            clearField={false}
                                                            required={true}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 formbox fullformbox">
                                                <div className="profile-form-group ">
                                                    <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                        <Button type="primary" size="small" label="Change email" onClick={() => { if(formValues.provider){
                                                            showError("You can’t change your email because your account is authenticated with "+formValues.provider+". For more information, contact our support team.","error");
                                                            return;
                                                        }  if(accountstatus!="blocked"){setChangeEmailModal({ ...changeEmailModal, open: true })} }} state={accountstatus=="blocked"?"disabled":"active"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row phoneno-field-row">
                                            <div className='col-md-6 formbox mb-0'>
                                                <div className="profile-form-group" id="phone_number">
                                                    <PhoneNumberInput
                                                        value={formValues.phone_number}
                                                        onBlur={()=>{
                                                            validateFullForm(false,"phone_number")
                                                        }}
                                                        onCountryChange={()=>{
                                                            setFormErrors({
                                                                ...formErrors,
                                                                phone_number: null,
                                                                error_class: "input_error"
                                                            });
                                                        }}
                                                        onChange={(value) => {
                                                            if(accountstatus!="blocked"){
                                                                var form_values = Object.assign({}, formValues);
                                                                if(value!=formValues.whatsapp_phone_number){
                                                                    form_values = {...form_values, whatsapp_checkbox:false};
                                                                } else {
                                                                    form_values = {...form_values, whatsapp_checkbox:true};
                                                                }
                                                                setFormErrors({...formErrors, phone_number:null});
                                                                setFormValues({ ...form_values, phone_number: value });
                                                                validateFullForm(false,"phone_number",value?value:'', true);
                                                                setProfileChanged(true);
                                                            }
                                                        }}
                                                        error={formErrors.phone_number}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                    <div className="whatsupplabel">
                                                        <CheckBox
                                                            checked={formValues.whatsapp_checkbox}
                                                            onChange={(event) => {
                                                                if(accountstatus!="blocked"){
                                                                    var form_values = Object.assign({}, formValues);

                                                                    if(event.currentTarget.checked){
                                                                        form_values = {...form_values, whatsapp_phone_number:form_values.phone_number, whatsapp_checkbox: true};

                                                                        if(formValues.phone_number){
                                                                            if(isValidPhoneNumber('+'+formValues.phone_number)){
                                                                                setFormErrors({
                                                                                    ...formErrors,
                                                                                    whatsapp_phone_number: null,
                                                                                    error_class: "input_error"
                                                                                });
                                                                            }else{

                                                                                setFormErrors({
                                                                                    ...formErrors,
                                                                                    whatsapp_phone_number: "WhatsApp number is not valid",
                                                                                    error_class: "input_error"
                                                                                });
                                                                            }
                                                                        } else {
                                                                            setFormErrors({
                                                                                ...formErrors,
                                                                                whatsapp_phone_number: null,
                                                                                error_class: "input_error"
                                                                            });
                                                                        }

                                                                    }  else {
                                                                        form_values = {...form_values, whatsapp_phone_number:'', whatsapp_checkbox: false};

                                                                        setFormErrors({
                                                                            ...formErrors,
                                                                            whatsapp_phone_number: null,
                                                                            error_class: "input_error"
                                                                        });
                                                                    }
                                                                    setFormValues(form_values);

                                                                    setProfileChanged(true);
                                                                }
                                                            }}
                                                            id="whatsapp_number"
                                                            label={"I use WhatsApp on this number"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*!formValues.whatsapp_checkbox &&*/}
                                            <div className="formbox col-md-6  mb-0" id="whatsapp_phone_number"  style={{position:"relative"}}>
                                                <div className="profile-form-group">
                                                    <PhoneNumberInput
                                                        value={formValues.whatsapp_phone_number}
                                                        onBlur={()=>{
                                                            validateFullForm(false,"whatsapp_phone_number")
                                                        }}
                                                        label={"WhatsApp number"}
                                                        onCountryChange={(country)=>{

                                                            setWhatsAppCountry(country);

                                                            setFormErrors({
                                                                ...formErrors,
                                                                whatsapp_phone_number: null,
                                                                error_class: "input_error"
                                                            });
                                                            setProfileChanged(true);
                                                        }}
                                                        onChange={(value) => {
                                                            if(accountstatus!="blocked"){

                                                                var form_values = Object.assign({}, formValues);

                                                                if(value!=formValues.phone_number){
                                                                    form_values = {...form_values, whatsapp_checkbox:false};
                                                                } else {
                                                                    form_values = {...form_values, whatsapp_checkbox:true};
                                                                }

                                                                setFormErrors({...formErrors, whatsapp_phone_number:null})
                                                                setFormValues({ ...form_values, whatsapp_phone_number: value });

                                                                validateFullForm(false,"whatsapp_phone_number",value?value:'', true);
                                                                setProfileChanged(true);
                                                            }
                                                        }}
                                                        error={formErrors.whatsapp_phone_number}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Residency</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox col-md-6 mb-0">
                                                <div className='profile-form-group'  id={"country"}>
                                                    <AutoCompleteSingleSelect
                                                        id={"country"}
                                                        type={"country"}
                                                        name="country"
                                                        title="Select levels of education..."
                                                        searchable={[
                                                            "Search for country level",
                                                            "No matching country level",
                                                        ]}
                                                        isSanctionedCountry={displaySanctionedMessage?true:false}
                                                        options={countries}
                                                        value={formValues.country}
                                                        onChange={(item) => {
                                                            if(accountstatus!="blocked"){
                                                                if(item){
                                                                    setFormValues({...formValues, country:item.value, region:''});
                                                                } else {
                                                                    setDisplaySanctionedMessage(false);
                                                                    setFormValues({...formValues, country:'', region:''});
                                                                }
                                                                validateFullForm(false,"country", item ? item.value:'');
                                                                setProfileChanged(true);

                                                                var sanctioned_country = false;

                                                                sanctioned_countries.forEach(function(country){
                                                                    if(item.value==country.value){
                                                                        sanctioned_country = true;
                                                                    }
                                                                });
                                                                setDisplaySanctionedMessage(sanctioned_country);
                                                            }
                                                        }}
                                                        label={"Country of residency"}
                                                        isClearable={true}
                                                        error={formErrors.country}
                                                        error_class={formErrors.error_class}
                                                    />
                                                </div>
                                            </div>
                                            {displaySanctionedMessage &&
                                            <div className="formbox col-md-12 mb-0 mt-20">
                                                <div className="row">
                                                    <div className="formbox col-md-6 mb-0">
                                                        <div className='profile-form-group  sanctioned-info'>
                                                            <ToastMessage closable={false} type={"warning"} message={<>Please be aware that, in compliance with both local and international regulations, we may not be able to process payouts to certain countries. If you need more information, please refer to our <UserQLink target="_blank" url="https://userq.com/terms-of-use">Terms of use</UserQLink>.</>} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {formValues.country=="United Arab Emirates" &&
                                            <div className="formbox col-md-6 mb-0">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={emirates}
                                                        value={formValues.region}
                                                        onChange={(item)=>{setFormValues({...formValues,region:item.value})

                                                            validateFullForm(false,"region",item?item.value:'', true);
                                                            setProfileChanged(true);
                                                        }}
                                                        isClearable={false}
                                                        label="Emirates"
                                                        error={formErrors.region}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                </div>

                                            </div>
                                            }
                                            {formValues.country=="Saudi Arabia" &&
                                            <div className="formbox col-md-6 mb-0 saudi_arabia_row">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={saregions}
                                                        value={formValues.region}
                                                        onChange={(item)=>{setFormValues({...formValues,region:item.value})

                                                            validateFullForm(false,"region",item?item.value:'', true);

                                                            setProfileChanged(true);

                                                        }}
                                                        isClearable={false}
                                                        label="What's your region of residency?"
                                                        error={formErrors.region}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />

                                                </div>

                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Spoken language</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formbox   d-flex-radiobox" id='spoken_language'>
                                                    <div className="d-flex-radiobtn mt-0">
                                                        {primaryLangs.map(function(lang) {
                                                            return (
                                                                <CheckBox
                                                                    name="spoken_language"
                                                                    label={lang.iso_name_english}
                                                                    checked={
                                                                        formValues.language_ids.includes(
                                                                            lang.id
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={(event) => {
                                                                        if (event.currentTarget.checked) {
                                                                            formValues.language_ids.push(
                                                                                lang.id
                                                                            );
                                                                            formValues.spoken_language.push(
                                                                                lang.iso_name_english
                                                                            );
                                                                            setFormValues({...formValues, spoken_language: formValues.spoken_language, language_ids:formValues.language_ids});
                                                                        } else {
                                                                            formValues.spoken_language =
                                                                                removeItemFromArray(
                                                                                    formValues.spoken_language,
                                                                                    lang.iso_name_english
                                                                                );

                                                                            formValues.language_ids =
                                                                                removeItemFromArray(
                                                                                    formValues.language_ids,
                                                                                    lang.id
                                                                                );
                                                                            setFormValues({...formValues, spoken_language: formValues.spoken_language, language_ids:formValues.language_ids});
                                                                        }
                                                                        setProfileChanged(true);

                                                                        validateFullForm(false, "spoken_language");
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                        
                                                    </div>
                                                    {formErrors.spoken_language &&
                                                    <span className={formErrors.error_class}>{formErrors.spoken_language}
                                                    </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="formbox col-md-6 employment-type-wrap mb-0 tester_search_lan_wrap" id='language'>
                                                <SearchDropdownMultiple
                                                    options={langs}
                                                    value={formValues.language}
                                                    onChange={(items)=>{
                                                        if(accountstatus!="blocked"){
                                                            let selected = [];

                                                            let selected_id = [];

                                                            primaryLangs.forEach(function(primaryLang) {

                                                                formValues.language_ids.forEach(function(lang){

                                                                    if(lang == primaryLang.id) {
                                                                        selected_id.push(lang);
                                                                    }
                                                                })
                                                            });
                                                            
                                                            items.forEach(function (item) {
                                                                selected.push(item.label);

                                                                langs.forEach(function(lang){
                                                                    if(lang.value == item.value){
                                                                        selected_id.push(lang.id);
                                                                    }
                                                                })

                                                                
                                                            })
                                                            setFormValues({...formValues,"language": selected,"language_ids": selected_id});

                                                            validateFullForm(false, "language", selected, true);

                                                            setProfileChanged(true);
                                                        }

                                                    }}
                                                    isClearable={true}
                                                    label="Other languages"
                                                    error={formErrors.language}
                                                    error_class={formErrors.error_class}
                                                />
                                                {formErrors.language &&
                                                <span className={formErrors.error_class}>{formErrors.language}
                                                </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Digital proficiency</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox col-md-6" id="hours_spend_online">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={hoursOptions}
                                                        value={formValues.hours_spend_online}
                                                        onChange={(item)=>{

                                                            if(item){
                                                                setFormValues({...formValues,hours_spend_online:item.value})


                                                            } else {
                                                                setFormValues({...formValues,hours_spend_online:''})
                                                            }
                                                            validateFullForm(false,"hours_spend_online",item?item.value:'');

                                                            setProfileChanged(true);
                                                        }}
                                                        isClearable={true}
                                                        label="Daily hours spent online"
                                                        error={formErrors.hours_spend_online}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                </div>
                                            </div>

                                            <div className="formbox col-md-6" id="tech_gadgets">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={gadgetsOptions}
                                                        value={formValues.tech_gadgets}
                                                        onChange={(item)=>{

                                                            if(item){
                                                                setFormValues({...formValues,tech_gadgets:item.value})


                                                            } else {
                                                                setFormValues({...formValues,tech_gadgets:''})
                                                            }
                                                            validateFullForm(false,"tech_gadgets",item?item.value:'');

                                                            setProfileChanged(true);
                                                        }}
                                                        isClearable={true}
                                                        label="Most used device"
                                                        error={formErrors.tech_gadgets}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                </div>

                                            </div>

                                            <div className="formbox col-md-12 mb-0" id='apps'>
                                                <label>
                                                    <Text cssClasses={"color-black"} type={"body-text-2"} fontWeight="semi-bold-font">Which mobile apps do you use in your daily routine?</Text>
                                                </label>
                                                <Chips>
                                                    {appsOptions.map(function (app) {
                                                        return (<Chip
                                                            key={"app-" + app}
                                                            title={app}
                                                            value={app}
                                                            type={formValues.apps.includes(app) ? "selected" : "default"}
                                                            onClick={() => {
                                                                if(accountstatus!="blocked"){
                                                                    let localApps = Object.assign([], formValues.apps);

                                                                    var exist = false;

                                                                    var newApps = [];

                                                                    localApps.forEach(function (app_item) {
                                                                        if (app_item == app) {
                                                                            exist = true;
                                                                        } else {
                                                                            newApps.push(app_item);
                                                                        }
                                                                    });

                                                                    if (exist) {

                                                                        setFormValues({ ...formValues, apps: newApps });
                                                                    } else {

                                                                        localApps.push(app);
                                                                        setFormValues({ ...formValues, apps: localApps });
                                                                    }
                                                                    validateFullForm(false,"apps",localApps, true);

                                                                    setProfileChanged(true);
                                                                }
                                                            }}
                                                        />);
                                                    })}
                                                </Chips>
                                                {formErrors.apps &&
                                                <span className={formErrors.error_class}>{formErrors.apps}
                                                </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Your education level</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox col-md-6 mb-0" id="highest_education_level">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={educationOptions}
                                                        value={formValues.highest_education_level}
                                                        onChange={(item)=>{

                                                            if(item){
                                                                setFormValues({...formValues,highest_education_level:item.value})


                                                            } else {
                                                                setFormValues({...formValues,highest_education_level:''})
                                                            }
                                                            validateFullForm(false,"highest_education_level",item?item.value:'');

                                                            setProfileChanged(true);
                                                        }}
                                                        isClearable={true}
                                                        label="Highest level of education"
                                                        error={formErrors.highest_education_level}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">What do you do for living?</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox col-md-6" id="industry">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={industryOptions}
                                                        value={formValues.industry}
                                                        readOnly={accountstatus=="blocked"?true:employmentFieldsReadOnly}
                                                        onChange={(item)=>{
                                                            if(item){
                                                                setFormValues({...formValues,industry:item.value})
                                                            }
                                                            else {
                                                                setFormValues({...formValues,industry:''})
                                                            }
                                                            validateFullForm(false,"industry",item?item.value:'');

                                                            setProfileChanged(true);
                                                        }}
                                                        isClearable={true}
                                                        label="What industry do you work in?"
                                                        error={formErrors.industry}
                                                        error_class={formErrors.error_class}
                                                    />
                                                </div>
                                            </div>
                                            {formValues.industry=="Other (please specify)" &&
                                            <div className="formbox  col-md-6">
                                                <Input
                                                    type="text"
                                                    readOnly={accountstatus=="blocked"?true:employmentFieldsReadOnly}
                                                    id="industry_other"
                                                    name="industry_other"
                                                    value={formValues.industry_other}
                                                    onChange={(e)=>{
                                                        setFormValues({...formValues,industry_other:e.target.value})
                                                        validateFullForm(false,"industry_other",e.target.value, true);
                                                        setProfileChanged(true);
                                                    }}
                                                    label={"Industry (Other)"}
                                                    onClear={()=>{
                                                        setFormValues((oldValues) => ({
                                                            ...oldValues,
                                                            industry_other: "",
                                                        }));
                                                    }}
                                                    required={true}
                                                    error={formErrors.industry_other}
                                                    error_class={formErrors.error_class}
                                                    onBlur={()=>{
                                                        validateFullForm(false,"industry_other")
                                                    }}
                                                    disabled={accountstatus=="blocked"?true:false}
                                                />
                                            </div>
                                            }
                                            <div className="col-md-12 mb-32">
                                                <div className="row">
                                                    <div className="formbox col-md-6 mb-0" id="department">
                                                        <div className='profile-form-group'>
                                                            <DropdownComponent
                                                                options={departmentOptions}
                                                                value={formValues.department}
                                                                readOnly={accountstatus=="blocked"?true:employmentFieldsReadOnly}
                                                                onChange={(item)=>{

                                                                    if(item){
                                                                        setFormValues({...formValues,department:item.value})


                                                                    } else {
                                                                        setFormValues({...formValues,department:''})
                                                                    }
                                                                    validateFullForm(false,"department",item?item.value:'');

                                                                    setProfileChanged(true);
                                                                }}
                                                                isClearable={true}
                                                                label="What department do you work in?"
                                                                error={formErrors.department}
                                                                error_class={formErrors.error_class}
                                                            />
                                                        </div>
                                                    </div>
                                                    {formValues.department=="Other (please specify)" &&
                                                    <div className="formbox  col-md-6 mb-0">
                                                        <Input
                                                            type="text"
                                                            readOnly={accountstatus=="blocked"?true:employmentFieldsReadOnly}
                                                            id="department_other"
                                                            name="department_other"
                                                            value={formValues.department_other}
                                                            onChange={(e)=>{
                                                                setFormValues({...formValues,department_other:e.target.value})
                                                                validateFullForm(false,"department_other",e.target.value, true);
                                                                setProfileChanged(true);
                                                            }}
                                                            label={"Department (Other)"}
                                                            onClear={()=>{
                                                                setFormValues((oldValues) => ({
                                                                    ...oldValues,
                                                                    department_other: "",
                                                                }));
                                                            }}
                                                            required={true}
                                                            error={formErrors.department_other}
                                                            error_class={formErrors.error_class}
                                                            onBlur={()=>{
                                                                validateFullForm(false,"department_other")
                                                            }}
                                                            disabled={accountstatus=="blocked"?true:false}
                                                        />
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12 ">
                                                <div className="row">
                                                    <div className="formbox col-md-6 mb-0" id="employment">
                                                        <div className='profile-form-group'>
                                                            <DropdownComponent
                                                                options={employmentOptions}
                                                                value={formValues.employment}
                                                                onChange={(item)=>{
                                                                    if(item){
                                                                        if(["Student","Unemployed","Homemaker","Retired"].includes(item.value)){
                                                                            setEmploymentFieldsReadOnly(true);
                                                                            setFormValues({...formValues,employment:item.value, department:'', industry:'', industry_other:'', department_other:''})
                                                                        }
                                                                        else {
                                                                            setEmploymentFieldsReadOnly(false);
                                                                            setFormValues({...formValues,employment:item.value})
                                                                        }
                                                                    } else {
                                                                        setEmploymentFieldsReadOnly(false);
                                                                        setFormValues({...formValues,employment:''})
                                                                    }
                                                                    validateFullForm(false,"employment",item?item.value:'');
                                                                    setProfileChanged(true);
                                                                }}
                                                                isClearable={true}
                                                                label="Your employment status"
                                                                error={formErrors.employment}
                                                                error_class={formErrors.error_class}
                                                                readOnly={accountstatus=="blocked"?true:false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-sec-wrap mb-60">
                            <div className='account-subtitle mb-32'>
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Household</Text>
                            </div>
                            <div className="profile-form-repeat pb-0 profile-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="formbox col-md-12" id='people'>
                                                <label className={'body-text body-text-2 semibold-font'}>How many people live in your household?</label>
                                                <IncDecCounter min={1} max={10}
                                                               value={formValues.people}
                                                               onChange={(value) => {
                                                                   if(value!=formValues.people){
                                                                       setFormValues({ ...formValues, people: value });

                                                                       validateFullForm(false,"people",value?value:'', true);

                                                                       setProfileChanged(true);
                                                                   }

                                                               }}
                                                               readOnly={accountstatus=="blocked"?true:false}
                                                />
                                                {formErrors.people &&

                                                <span className={formErrors.error_class}>{formErrors.people}
                                                </span>
                                                }
                                            </div>
                                            <div className="formbox  col-md-6" id="income_slab">
                                                <div className='profile-form-group'>
                                                    <DropdownComponent
                                                        options={incomelevelOptions}
                                                        value={formValues.income_slab}
                                                        onChange={(item)=>{

                                                            if(item){
                                                                setFormValues({...formValues,income_slab:item.value})


                                                            } else {
                                                                setFormValues({...formValues,income_slab:''})
                                                            }
                                                            validateFullForm(false,"income_slab",item?item.value:'');

                                                            setProfileChanged(true);
                                                        }}
                                                        isClearable={true}
                                                        label="Monthly household income (USD)"
                                                        error={formErrors.income_slab}
                                                        error_class={formErrors.error_class}
                                                        readOnly={accountstatus=="blocked"?true:false}
                                                    />
                                                </div>

                                            </div>
                                            <div className="formbox  col-md-12 mb-0">
                                                <div className="researcher-profile-form-btn">
                                                    {user.role == "Tester" && accountstatus!="blocked" && (
                                                        <div className="change-email-button">
                                                            {profileChanged ?
                                                                <Button type="primary" size="large" label="Save updates" state={checkBtnState()?"disabled":"active"}  onClick={onSubmit} microLoading={formLoading} />
                                                                :
                                                                <Button type="primary" size="large" label="Save updates" state={formLoading?"active":"disabled"} onClick={()=>{validateFullForm(true, null)}} microLoading={formLoading}  />
                                                            }
                                                        </div>
                                                    )}
                                                    {user.role == "Tester" && accountstatus=="blocked" && (
                                                        <div className="change-email-button">
                                                            <Button type="primary" size="large" label="Save updates" state={"disabled"}  onClick={()=>{}}  />
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="change-password-section profile-details">
                            <Text type="subtitle-2" fontWeight="semi-bold-font">
                                {formValues.provider && !formValues.password_set ?
                                    <>Password settings</>
                                    :
                                    <>Password settings</>
                                }
                            </Text>
                            {formValues && formValues.provider
                                ?
                                <>
                                    <div className='profile-connected-with-social mt-32'>
                                        <div className={`profile-social-login-btn mb-20 d-flex align-items-center ${formValues.provider.toLowerCase()}-login`}>
                                            <div className="social-btn-icon">
                                                <Icon value={formValues.provider} size={"extralarge"} />
                                            </div>
                                            <div className="social-btn-info">
                                                <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>You are connected with {formValues.provider}</Text>
                                                <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"gray-color"}>{formValues.email.includes("privaterelay")?"Email is hidden":formValues.email}</Text>
                                            </div>
                                        </div>
                                        {formValues.password_set
                                            ?
                                            <>
                                                <Button type="secondary" microLoading={socialDisconnectLoading}  size="medium" label={"Disconnect "+formValues.provider} onClick={() => { disconnectSocialAccount(formValues.provider); }}  />
                                            </>
                                            :
                                            <></>
                                        }
                                    </div>
                                    {formValues.password_set
                                        ?
                                        <div className='profile-change-password-btn-wrap d-flex align-items-center mt-32'>
                                            <Text type="body-text-2" fontWeight="medium-font" cssClasses={"mr-4"}>Password created on {moment(formValues.password_updated_at,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                            <Button type="secondary" size="medium" label="Change password" onClick={() => { if(accountstatus!="blocked"){setOpenModal(true);} }} iconLeft={<Icon value={"lock"} size={"small"} />} state={accountstatus=="blocked"?"disabled":""} />
                                        </div>
                                        :
                                        <div className='mt-32 setapassword-info'>
                                            <Text type="body-text-2" cssClasses={"mb-8"} fontWeight="medium-font">Do you want to enable a password?</Text>
                                            <Text type="body-text-3" cssClasses={"gray-text"} fontWeight="medium-font">Once you set a password for your registered email, you'll have the option to log in using either your {formValues.provider} account or your<br/> email and password.</Text>
                                            <Button type="secondary" cssclass={"mt-20"} size="medium" label="Set a password" onClick={() => { setOpenSetPasswordModal(true); }} iconLeft={<Icon value={"lock"} size={"small"} />} />
                                        </div>
                                    }

                                </>
                                :
                                <Button type="secondary" cssclass="mt-20" size="large" label="Change password" onClick={() => { if(accountstatus!="blocked"){setOpenModal(true);} }} iconLeft={<Icon value={"lock"} size={"small"} />} state={accountstatus=="blocked"?"disabled":""} />
                            }

                        </div>

                        {user.role == "Tester" && (
                            <div className="delete-account-section mt-60">
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Delete account</Text>
                                <p className={`body-text body-text-2`}>Once you delete your account, all tests and unused credits will be removed.  <b>This action cannot be undone.</b></p>
                                <Button type="secondary" size="medium" label="Delete account" onClick={() => { setConfirm({ open: true }); }} />
                            </div>
                        )}

                        <ChangePasswordModal
                            openModal={openModal}
                            closeModal={closeModal}
                        />
                        <SetPasswordModal
                            openModal={openSetPasswordModal}
                            closeModal={(user)=>{
                                setOpenSetPasswordModal(false);

                                if(user){
                                    let form_values = {...formValues};

                                    form_values.password_updated_at = user.password_updated_at;

                                    form_values.password_set = user.password_set;

                                    setFormValues(form_values);
                                }
                            }}
                        />
                        <DeleteAccountModal
                            openModal={confirm.open}
                            confirm_title="Delete account"
                            confirm_title_class='text-danger'
                            cancel_btn_title={"No, keep"}
                            confirm_btn_title={"Yes, delete"}
                            close={() => {
                                setConfirm({ ...confirm, open: false });
                            }}
                            confirm={() => {
                                deleteUserAccount();


                            }}
                            btnLoading={accountLoading}
                        />

                        <ConfirmationModal
                            openModal={changeEmailModal.open}
                            confirm_title="Change email"
                            confirm_message="An email will be sent to current email address for you to verify."
                            confirm_btn_title={"Confirm"}
                            cancel_btn_title={"Cancel"}
                            close={() => {
                                setChangeEmailModal({ ...changeEmailModal, open: false });
                            }}
                            confirm={() => {
                                sendChangeEmail();
                            }}
                            btnLoading={accountLoading}
                        />

                        <DocumentNotMatch
                            confirm_title={"We need to update your profile to match your ID"}
                            openModal={documentNotMatch.open}
                            close={() => {
                                setDocumentNotMatch({ open: false });
                            }}
                            confirmHandler={(status)=>{

                                if(status){
                                    navigate("/dashboard");
                                }else{
                                    getProfileData();
                                }
                            }}
                        />

                    </div>

                </div>
            )}
        </LayoutTester>
    );
}