import React, { useEffect, useState, useCallback, useRef } from "react";
import { Group } from "@visx/group";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import {Annotation} from "@visx/annotation"
import { animated, useTransition, interpolate } from "react-spring";
import {Tooltip} from "../../../themes/userq/Tooltip/Tooltip";
import { autoType } from "d3";
import { ca } from "date-fns/locale";
import { roundAnlyticsNumber } from "../../../../lib/helpers";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export default function PieChart({
	width,
	height,
	margin = defaultMargin,
	animate = false,
	data,
    colors,
	txtcolors
}) {
	const [chartData, setChartData] = useState([]);

	const [tooltip, showTooltip] = useState({
		tooltipLeft:0,
		tooltipTop:0,
		tooltipData:"",
		open: false,
		show:false
	});

	//if (width < 10) return null;
	const ref = useRef();
	const tipref = useRef();
	const innerWidth = width - margin.left - margin.right;
	const innerHeight = height - margin.top - margin.bottom;
	const radius = Math.min(innerWidth, innerHeight) / 2;
	const centerY = innerHeight / 2;
	const centerX = innerWidth / 2;

	var calculated_width = null;

	var calculated_height = null;

	const handlePointerMove = (key) => {
		

		return (
			(event) => {

				
				let tipPosition = "bottom";
				
				var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 150;


				var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 106;

				
				

				let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
				let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;
				
				
				var window_width = window.innerWidth;

				var window_height = window.innerHeight;
				
				if(event.clientX + tool_width/2 > window_width){
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;
				
				}
				if(event.clientX - tool_width/2 < 0){
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
				}
				//console.log(containerX)
				/*if(event.clientY-10+tool_height > window_height){
					//alert(window_width)
					containerY = ('clientX' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height -10;

					<><div></div><div>{key["count"]+" out of "+key["total"]}</div><div>{key["subLabel"] +"%"}</div></>
				}*/
				showTooltip({
					tooltipLeft: containerX,
					tooltipTop: containerY,
					tooltipData: <><div style={{color:key["color"]}}>{key["label"]}</div><div>{key["count"]+" out of "+key["total"]}</div><div>{key["subLabel"]}</div></>,
					open:true,
					show: tooltip.open && tipref && tipref.current ? true: false,
					tipPostion: tipPosition
				});
			});
	}
	  
	useEffect(() => {
		var lChartData = [];

        var total = 0;

		

        for (var key in data) {

            total += data[key];
        }

		var index = 0;
		for (var key in data) {

			var percentage = roundAnlyticsNumber((data[key]/total)*100);
			
			if (index == 0) {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					testers: data[key],
					value: percentage,
					color: colors[key],
					total: total
					//text_color:txtcolors[key]
				});
			} else if (index == 1) {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					value: percentage,
					color: colors[key],
					testers: data[key],
					total: total
					//text_color:txtcolors[key]
				});
			} else if (index == 2) {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					value: percentage,
					color: colors[key],
					testers: data[key],
					total: total
					//text_color:txtcolors[key]
				});
			} else {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					value: percentage,
					color: colors[key],
					testers: data[key],
					total: total
					//text_color:txtcolors[key]
				});
			}
			index++;
		}
        console.log(lChartData)
		setChartData(lChartData);
	}, [data]);

	return (
		<div ref={ref} style={{position:'relative'}}>
				{tooltip.open ? (
				<>
					<Tooltip
						ref={tipref}
						type={tooltip.tipPosition}
						key={Math.random()} // needed for bounds to update correctly
						style={{ zIndex:2, display: tooltip.show? "block" : 'block', width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
						data={tooltip.tooltipData}
						className="tooltipChart"
					
					/>
				</>
				): (
					<></>
				)}
				
				
			<svg width={width} height={height}>
				<Group top={centerY + margin.top} left={centerX + margin.left}>
					<Pie
						cornerRadius={0}
						padAngle={0.0}
						data={chartData}
						pieValue={(node) => {
							return node.value;
						}}
						pieSortValues={() => -1}
						outerRadius={radius+10}
					>
						{(pie) => (
							<AnimatedPie
								{...pie}
								animate={animate}
								getKey={(node) => {
                                    //return '1 ';
									return node.data.label;
									//return '';
								}}
								getSubKey={(node) => {
                                    return '';
									return node.data.subLabel;
								}}
								getColor={(node) => {
									return node.data.color;
								}}
								getTextColor={(node) => {
									return "rgb(0,0,0)";
								}}
								handlePointerMove={handlePointerMove}
								tooltip={tooltip}
								showTooltip={showTooltip}
								getTesters={(node) => {
									return {"label":node.data.label, color:node.data.color, count:node.data.testers, total: node.data.total, subLabel:node.data.subLabel};
								}}
							/>
						)}
					</Pie>
				</Group>
			</svg>
		</div>
	);
}

const fromLeaveTransition = ({ endAngle }) => ({
	// enter from 360° if end angle is > 180°
	startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
	startAngle,
	endAngle,
	opacity: 1,
});

function AnimatedPie({ animate, arcs, path, getKey, getColor, getTextColor, getSubKey,handlePointerMove, tooltip, showTooltip, getTesters }) {
	const transitions = useTransition(arcs, {
		from: animate ? fromLeaveTransition : enterUpdateTransition,
		enter: enterUpdateTransition,
		update: enterUpdateTransition,
		leave: animate ? fromLeaveTransition : enterUpdateTransition,
		keys: getKey,
	});
	return transitions((props, arc, { key }) => {
		const [centroidX, centroidY] = path.centroid(arc);
		const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

		return (
			<g key={key} 
			onPointerMove={handlePointerMove(getTesters(arc)) }
			onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
			>
				<animated.path
					// compute interpolated path d attribute from intermediate angle values
					d={interpolate(
						[props.startAngle, props.endAngle],
						(startAngle, endAngle) =>
							path({
								...arc,
								startAngle,
								endAngle,
							})
					)}
					fill={getColor(arc)}
					
				/>
				{hasSpaceForLabel && (
					<animated.g style={{ opacity: props.opacity }}>
						<text
							fill={getTextColor(arc)}
							x={centroidX}
							y={centroidY}
							dy=".33em"
							fontSize={14}
							textAnchor="middle"
							pointerEvents="none"
						>
							{/*getKey(arc)*/}
						</text>
                        <text
							fill={getTextColor(arc)}
							x={centroidX}
							y={centroidY}
							dy=".33em"
							fontSize={10}
							textAnchor="middle"
							pointerEvents="none"
						>
							{getSubKey(arc)}
						</text>
					</animated.g>
				)}
			</g>
		);
	});
}
