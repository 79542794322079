import React from "react";
import { Text } from "./../Text/Text";
import { Icon } from "../Icon/Icon";

export const SignUpInformationalCard = ({ icon, title, info }) => {
    return (
        <div className="signofinformational-wrap gray-box p-32">
            <div className="signup-info-inner">
                <div className="signup_info_icon mb-20">
                    {icon && typeof icon === "object" && <>{icon}</>}
                    {icon && typeof icon === "string" && (
                        <>
                            <Icon colorClass={"icon-size-200"} value={icon} />
                        </>
                    )}
                </div>
                <div className="signup-info-content">
                    <Text
                        type={"body-text-2"}
                        cssClasses={"black-color"}
                        fontWeight={"semibold-font"}
                    >
                        {title}
                    </Text>
                    <Text
                        type={"body-text-3"}
                        cssClasses={"gray-text"}
                        fontWeight={"medium-font"}
                    >
                        {info}
                    </Text>
                </div>
            </div>
        </div>
    );
};