import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { ReactSession } from "../../../../lib/secure_reactsession";

export default function NoAccess(){

    const navigate = useNavigate();

    return (
        <div className="no-access-page-wrap">
            <div className="no-access-inner-wrap  mb-32">
                <div className="tester-disbled-account-notice mb-60">
                    <Icon
                        colorClass={"danger-red-200"}
                        value={"blocked"}
                        size="large"
                    />

                    <Text type={"subtitle-1"}>
                        You don't have access to this page.
                    </Text>
                    <Text type={"body-text-2"}>
                        Please get in touch with your team or{" "}
                        <a
                            className="faq-support-link disabled-support-link"
                            href={
                                process.env.REACT_APP_URL +
                                "r/support/contact"
                            }
                            target="_blank"
                        >
                            UserQ support
                        </a>{" "}
                        if you are unsure why this has occurred.
                    </Text>
                </div>

                
                    <div className="back-dash-btn">
                        <Button
                            type="primary"
                            label="Back to dashboard"
                            size={"large"}
                            onClick={(e) => {
                                e.preventDefault();

                                let session_workspace_id = null;

                                    let session_workspace = ReactSession.get("workspace");

                                    if (session_workspace) {
                                        session_workspace_id = session_workspace.workspace_id;

                                        navigate("/wsp/"+session_workspace_id);
                                    } else {
                                        navigate("/dashboard/");
                                    }
                                
                            }}
                        />
                    </div>
                
            </div>
        </div>
    );
}