import {handleApiErrorResponse} from "../lib/helpers";

export function createSurveyQuestion(formValues, token,) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/survey/create-question",
        {
            method: "POST",
            body: formValues,
            headers: { Authorization: "Bearer " + token },
        }

    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function deleteSingleSurveyQuestion(formValues, token,) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/survey/delete-question",
        {
            method: "POST",
            body: formValues,
            headers: { Authorization: "Bearer " + token },
        }

    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updateSingleSurveyQuestion(formValues, token,) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/survey/update-question",
        {
            method: "POST",
            body: formValues,
            headers: { Authorization: "Bearer " + token },
        }

    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function reorderSurveyQuestion(formValues, token,signal) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/survey/reorder-question",
        {
            method: "POST",
            body: formValues,
            headers: { Authorization: "Bearer " + token },
            signal:signal,
        }

    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function duplicateSurveyQuestion(formValues, token,signal) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/survey/duplicate-question",
        {
            method: "POST",
            body: formValues,
            headers: { Authorization: "Bearer " + token },
            signal:signal,
        }

    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function updateLogicSurveyQuestion(formValues, token,signal) {
    return fetch(
        process.env.REACT_APP_API_END_POINT + "test/survey/update-logic-question",
        {
            method: "POST",
            body: formValues,
            headers: { Authorization: "Bearer " + token },
            signal:signal,
        }

    ).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}