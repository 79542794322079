import React from "react";
import PropTypes from 'prop-types';
import { Text } from "../Text/Text";
import {Box} from "../Box/Box";

export const TesterTestHistory = ({completed,abandoned,reported}) => {


    return (
        <div className="participants-overview-results tester-test-history">

            <Box title="Total number of tests">

                <div className="participants-overview-data-wrap d-flex">
                    <div className="participants-overview-left tester-test-history-left">

                        <Text
                            type={"h2"}
                            cssClasses={"participants-results-count"}
                        >
                            {completed+abandoned+reported}
                        </Text>

                    </div>
                    <div className="participants-overview-right tester-test-history-right">

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                        >
                            {completed<10?"0"+completed:completed} Completed
                        </Text>

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                        >
                            {abandoned<10?"0"+abandoned:abandoned} Abandoned
                        </Text>

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                        >
                            {reported<10?"0"+reported:reported} Reported
                        </Text>

                    </div>
                </div>


                

            </Box>

        </div>
    );
};



TesterTestHistory.defaultProps = {
    completed: PropTypes.number,
    abandoned:PropTypes.number,
    reported:PropTypes.number,
};
