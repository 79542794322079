import React, { useState,  useEffect, useRef  } from 'react';
import {useNavigate, Link, useSearchParams} from 'react-router-dom';
import { encryptClient, preventClick, saveLocalCache, callOldUI } from '../../../lib/helpers';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { Modal } from 'react-bootstrap';
import {FullPageLoadingIcon} from "../../loader/full_page_loader_lcon";
import { load } from 'recaptcha-v3'
import GoogleAuth from '../login-with-google';
import { Logo } from '../../themes/userq/Logo/Logo';
import { Text } from '../../themes/userq/Text/Text';
import { Input } from '../../themes/userq/Input/Input';
import { CheckBox } from '../../themes/userq/CheckBox/CheckBox';
import { Button } from '../../themes/userq/Button/Button';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../../data/settings';
import { LoadingIcon } from '../../loader/loadingIconNew';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Footer } from '../../themes/userq/Footer/Footer';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import AccountInUseModal from '../modals/AccountInUseModal';


let login_token = null;

let verify_token = null;



export default function Login() {

    ReactSession.setStoreType("localStorage");

    let passwordInput = useRef();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    const [formErrors, setFormErrors] = useState({password:null, email: null, error_class:null});

    const [pageHeading, setPageHeading] = useState(<>Sign in to your <span className='themeclr'>researcher</span> account</>);

    const [subHeading, setSubHeading] = useState(
      <></>
    );

    const [social, setSocial] = useState(false);

    const [cacheLogin, setCacheLogin] = useState(false);

    const [isEmailVerified, setEmailVerified] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [isAccountExist, setAccount] = useState(true);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [socialLoading, setSocialLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', password:'', remember_me:true});

    const [accountLinked, setAccountLinked] = useState(false);

    const [hoverClass, setHoverClass] = useState("");

    const [accountInUseModal, setAccountInUseModal] = useState({open:false});
    
    useEffect(() => {
        ReactSession.set("register_email","");
        ReactSession.set("successVerifiedMessage","");
        /*setTimeout(function(){
            window.animate();
        },1000);*/
        
        document.title = "Researcher Sign In - User research and testing platform | UserQ";

        
    }, [isEmailVerified]);


    useEffect(()=>{  
        
        let login_type = Cookies.get("last_login_method_researcher");

        if(login_type=="Google"){

            setAccountLinked(true);

            setSocial(true);

            setCacheLogin(true);

            setPageHeading(<>Sign in to your <span className='themeclr'>researcher</span> account.</>);
            
            setSubHeading('');
        }

        document.body.classList.remove('taking-test-body');      
        regenerateCaptcha();

    },[]);

    const regenerateCaptcha = ()=>{
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;
    
                setFormValues({...formValues, login_token:login_token});
                
            })
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;           
            })
        })
    }
    const generateCaptchaLogin = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;
    
                setFormValues({...formValues, login_token:login_token});

                callback(token);
                
            })
        })
    }
    const generateCaptchaVerify = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;      
                
                callback(token);
            })
        })
    }
    const loginUser = () => {
        
        generateCaptchaLogin(function(token){
            
            formValues.login_token = token;

            setLoading(true);

            setErrorMessage(null);

            

            var body = Object.assign({},formValues);

            

            body['password'] = encryptClient(body.password);

            body['role'] = 'Researcher';
            
            fetch(process.env.REACT_APP_API_END_POINT+'login', {
                method: 'POST',
                body: JSON.stringify(body),
                headers:{"Content-Type":"application/json"}
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);
                if(response.success){   
                    
                    if(response.data.verified){ 
                        ReactSession.set("token", response.token);
                        ReactSession.set("user", response.user);
                        Cookies.set("last_login_method_researcher","manual", { expires: 365*24*60*60 });

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event' : 'sign_in',
                            'usertype': 'researcher',
                            'method': 'email',
                            'userID' : response.user.user_id
                        });

                        if(formValues.remember_me){
                            var date = new Date();

                            Cookies.set("expires_at", date.getTime()+30*24*60*60*1000, { expires: 30 });

                            ReactSession.set("expires_at", date.getTime()+30*34*60*60*1000);
                        } else {
                            Cookies.set("expires_at", date.getTime()+60*60*1000);
                        }

                        if(searchParams.get("redirect_to")){
                            navigate(searchParams.get("redirect_to"));
                        } else {
                            if(response.workspace){
                                console.log(response.workspace);
                                ReactSession.set("workspace", {workspace_id: response.workspace.id, type: response.workspace.type});
                                navigate('/wsp/'+response.workspace.id);    
                            } else if(response.completed_step==1 && response.user.signup_plan=="Team"){
                                navigate('/r/create-team-workspace');
                            } else if(response.completed_step==0){
                                navigate('/r/profile/complete');
                            } else {
                                navigate('/dashboard');
                            }
                        }
                        
                    } else {

                        navigate("/researcher/registration/success");
                            
                    }
                    
                } else {

                    if(response.data && response.data.account && response.data.used_with_social){
                        
                        setPageHeading(response.message);

                        setSubHeading(response.data.sub_heading);

                        setSocial(true);

                        setAccountLinked(true);
                    }
                    else if(response.data &&  !response.data.account){
                        
                        ReactSession.set("register_email", formValues.email);
                        if(searchParams.get('refID')){
                            navigate({
                                "pathname":"/researcher/sign-up",
                                "search" : '?refID='+searchParams.get('refID')
                            });
                        }else{
                            navigate("/researcher/sign-up");
                        }
                    } else if(response.message=="Already loggedin."){
                        setAccountInUseModal({...accountInUseModal, open: true});
                    } else {
                    
                        showError(response.message);
                    }
                    //setErrorMessage(response.message);
                }
            });
        })
    }
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const checkIfEmailVerified = () => {
        
        generateCaptchaVerify(function(token){
            
            verify_token = token;

            setLoading(true);

            setErrorMessage(null);

            fetch(process.env.REACT_APP_API_END_POINT+'check_if_email_verified?verify_token='+verify_token+'&email='+formValues.email+"&role=Researcher", {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                regenerateCaptcha();
                setLoading(false);

                if(response.success){
                    
                    if(response.data.verified){ 
                        setEmailVerified(true);
                        passwordInput.current.focus();
                    } else {
                        navigate("/researcher/registration/success");
                        setErrorMessage(response.message);    
                    }
                } else {
                    
                    if(response.data && response.data.account && response.data.used_with_social){
                        
                        setPageHeading(response.message);

                        setSubHeading(response.data.sub_heading);

                        setSocial(true);

                        setAccountLinked(true);
                    }
                    else if(response.data &&  !response.data.account){
                        
                        ReactSession.set("register_email", formValues.email);
                        if(searchParams.get('refID')){
                            navigate({
                                "pathname":"/researcher/sign-up",
                                "search" : '?refID='+searchParams.get('refID')
                            });
                        }else{
                            navigate("/researcher/sign-up");
                        }
                    } else {
                        
                        setErrorMessage(response.message);
                    }
                    
                }
            });
        })
        
        
    }
    const ForgotPasswordService = () => {
        
        setLoading(true);

        generateCaptchaLogin(function(token){

            setErrorMessage(null);

            fetch(process.env.REACT_APP_API_END_POINT+'forgot-password?email='+formValues.email+"&role=Researcher&captcha="+token, {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);

                if(response.success){
                    
                    //navigate("/forgot-password-email");

                    setModal({
                    modalHeading: "Reset your password now",
                    modalMessage:
                        "We've just sent you an email with the link to reset your password.",
                    modalOpen: true,
                    });

                    /*if(response.data.verified){ 
                        setEmailVerified(true);

                    } else {
                        setErrorMessage(response.message);    
                    }*/
                } else {
                    if(!response.data.account){
                        //setAccount(false);
                        ReactSession.set("register_email", formValues.email);
                        navigate("/register");
                    } else {
                        //setAccount(true);
                        setErrorMessage(response.message);
                    }
                    
                }
            });
        });
        
    }

    const checkValidBtnState = ()=>{

        var error = false;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if(formValues.email=='' || !formValues.email.match(mailformat)){
            
            error = true
            
            
        }
        if(formValues.password==''){
           
            error = true;
        }
        return error;

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value=null, inline=false) => {

        var error = false;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = resetFormErrors();
        }

        
        var firsterrorid = null;

        if(!key || key=="email"){

            
            var field_value = key && inline ? value : formValues.email;
            
            if (field_value == '' || field_value == null){

                
                    form_errors = {
                        ...form_errors,
                        email: "Required field",
                        error_class: "input_error"
                    };
                
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
            else if (field_value != null && !field_value.match(mailformat)) {

                
                    form_errors = {
                        ...form_errors,
                        email: "Please enter a valid email",
                        error_class: "input_error"
                    };
                
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
        }
        if(!key || key=="password"){

            var field_value = key && inline ? value : formValues.password;

            if (field_value == '' || field_value == null) {

                
                    form_errors = {
                        ...form_errors,
                        password: "Required field",
                        error_class: "input_error"
                    };
                
                error = true;
                firsterrorid = firsterrorid == null ? "password" : firsterrorid;
            }
        }
        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const onSubmit =  async (event) => {
        event.preventDefault();

        setFormErrors({email: null, password: null, "error_class": null});
        
        setErrorMessage(null);

        if(!isLoading){

            var error = validateFullForm(true);;

            /*if(formValues.email=='' || !formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                setLoading(false);
                setFormErrors({email: 'Please enter a valid email', password: null, "error_class": "input_error"});
                
            }  else {
                if(isEmailVerified==false){
                    checkIfEmailVerified();
                } else {
                    if(formValues.password==''){
                        setLoading(false);
                        setFormErrors({email: null, password: "Required field", "error_class": "input_error"});
                    } else {
                        loginUser();
                    }
                    
                }
            }*/
            if(!error){
                loginUser();
            }
            
        }
        return false;
    }
    const forgotPassword = async (event) => {
        event.preventDefault();

        setFormErrors({email: null, password: null, "error_class": null});
        

        if(!isLoading){
            if(formValues.email=='' || !formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                setLoading(false);
                setFormErrors({email: 'Please enter a valid email', password: null, "error_class": "input_error"});
                
            } else {
                ForgotPasswordService();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setErrorMessage(null);
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    }
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    return (
        <div className="login-page-wrapper  new-login-page-wrap researcher-login-page">
            <Helmet>
                <title>Researcher Sign In - User research and testing platform | UserQ</title>
                <meta
                    name="description"
                    content="Sign in to your UserQ researcher account to access your dashboard, publish a new test, and review the results of the ones published"
                />
            </Helmet>
            {socialLoading &&
            <div className={'dashboard-filter-loader full-screen-loader'} style={{height:"100vh"}}>
                <LoadingIcon/>
            </div>
            }
            {!socialLoading &&
            <>
            <div className="login-page-header">
                <div className="researcher-registerheader login-page-header">
                    <div className="container">
                           <div className='row align-items-center'>
                        <div className="col-xs-6 col-sm-6 login-hdr-left">
                            <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                                
                                <Logo theme={"black"} background={"white"} />
                            </a>
                        </div>
                        <div className="col-xs-6 col-sm-6 login-hdr-right text-right">      
                            <Button 
                                type={"primary"}
                                size={"medium"}
                                label={"Get paid to test"}
                                iconRight={
                                    <Icon colorClass="gray-50-svg" value={"forward-arrow"} size={"small"} />
                                }
                                tag="link"
                                href="/tester/sign-in"
                            />

                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className={`login-data-wrap researcher-register-wrap ${cacheLogin?"cache_login_wrapper":""} ${social?"social_login_wrapper":""} ${social?"single-social-box":""}`}>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            {accountLinked?
                            <Text type="h1" cssClasses={"h3"} fontWeight="semi-bold-font">{pageHeading}</Text>
                            : 
                            <Text type="h1" cssClasses={"h3"} fontWeight="semi-bold-font">{pageHeading}</Text>
                            }
                            {subHeading &&
                            <Text type="subtitle-2" fontWeight="medium-font">{subHeading}</Text>        
                            }
                            <div className="login-form-inner-data">
                                {errorMsg &&
                                    <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                        <div className="pro-lft-wrap">
                                            {errorMsg}
                                            {!isAccountExist &&
                                                <span> 
                                                    Click <Link to="/tester/sign-up">here</Link> to create an account.
                                                </span>
                                            }
                                        </div>
                                        <div className="pro-right-wrap">
                                            <div className="delete-progress-row">
                                                <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {successMsg &&
                                    <div className="profile-complete-progress-wrap login-message-wrap">
                                        <div className="pro-lft-wrap">
                                            {successMsg}
                                        </div>
                                        <div className="pro-right-wrap">
                                            <div className="delete-progress-row">
                                                <img onClick={clearSuccessMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <form method='POST' onSubmit={onSubmit}>
                                    {!social &&
                                    <div className='mt-40'>
                                        <div className='formbox'>
                                            <Input
                                                id="email"
                                                type="text"
                                                name="email"
                                                autocomplete={"username"}
                                                value={formValues.email}
                                                onChange={(e)=>{ 
                                                    setFormValues({...formValues,email:e.target.value});
                                                    validateFullForm(false, "email", e.target.value ? e.target.value:'',true)
                                                }}
                                                required={true}
                                                label={"Work email"}
                                                onClear={()=>{ setFormValues({...formValues,email:""})} }
                                                onBlur={()=>{validateFullForm(false,'email')}}
                                                //onFocus={()=>{ setFormErrors({...formErrors, email:null}) }}
                                                error={formErrors.email}
                                                error_class={formErrors.error_class}
                                            />
                                        </div>
                                        <div className='formbox'>
                                            <Input
                                                id="password"
                                                type="password"
                                                value={formValues.password}
                                                onChange={(e)=>{ setFormValues({...formValues,password:e.target.value})
                                                    validateFullForm(false, "password", e.target.value ? e.target.value:'',true)
                                                }}
                                                autocomplete={"current-password"}
                                                required={true}
                                                label={"Password"}
                                                passwordVisible={passwordVisible}
                                                passwordInput={true}
                                                clearField={false}
                                                passwordField={true}
                                                onBlur={()=>{validateFullForm(false,'password')}}
                                                onFocus={()=>{ setFormErrors({...formErrors, password:null}) }}
                                                error={formErrors.password}
                                                error_class={formErrors.error_class}
                                            />
                                             </div>
                                            <div className="signremeberbox">
                                                <CheckBox 
                                                    label={"Remember me"}
                                                    checked={formValues.remember_me}
                                                    onChange={(event) => { toggleCheck('remember_me', event.currentTarget.checked) }}
                                                
                                                />
                                                <Link to="/researcher/forgot-password">Forgot password?</Link>
                                            </div>
                                       
                                    </div>
                                    }
                                    <div className="full createaccount-btnbox">
                                        {!social &&
                                        <Button
                                            id="r_email_sign_in" 
                                            type="primary" className="btn btn-block form-btn"
                                            label="Sign in"
                                            microLoading={isLoading}
                                            state={checkValidBtnState()?"disabled":"active"}
                                            size={"large"}
                                        />
                                        }
                                        
                                        {!social &&
                                        <div className={`full ordivider`}><span>Or</span></div>
                                        }
                                        {cacheLogin &&
                                        <>
                                        <div className='profile-connected-with-social mt-32 mb-32 cache-profile-login-btn'>
                                            <div className={`profile-social-login-btn mb-20 d-flex align-items-center google-login`}>
                                                <div className="social-btn-icon">
                                                    <Icon value={"Google"} size={"extralarge"} />
                                                </div>
                                                <div className="social-btn-info">
                                                    <Text  type={"body-text-2"} fontWeight={"semi-bold-font"}>You are connected with Google</Text>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                        <GoogleAuth 
                                            className={cacheLogin?"cache-login btn primary-large":""} 
                                            title={cacheLogin?"Continue with your Google account":null} 
                                            redirect_to={searchParams.get("redirect_to")}  
                                            role="researcher" 
                                            setLoading={(loading)=>{setSocialLoading(loading);}} 
                                            setErrorMessage={(message)=>{showError(message);}}
                                            alreadyLogin={()=>{ setAccountInUseModal({...accountInUseModal, open: true})}}
                                        />
                                        
                                        {social &&
                                        <div className={`full ordivider ${cacheLogin?"mt-32 mb-32":""} `}><span>Or</span></div>
                                        }
                                        {social &&
                                        <>
                                        {!cacheLogin
                                        ? 
                                        <div className="login-bottom-link form-group nothaveaccount resarcher-btn-register">
                                            Back to <a href='#' onClick={(e)=>{ e.preventDefault(); 
                                                    setCacheLogin(false); Cookies.set("last_login_method_researcher",null); setPageHeading(<>Sign in to your <span className='themeclr'>researcher</span> account.</>); setSubHeading(''); setFormValues({...formValues, email:'', password:''}); setSocial(false) }}>Sign in</a>
                                        </div>
                                        :
                                        <div className="login-bottom-link form-group nothaveaccount resarcher-btn-register">
                                            <Button 
                                                type={"secondary"} 
                                                onClick={(e)=>{
                                                    e.preventDefault(); 
                                                    Cookies.set("last_login_method_researcher",null); 
                                                    setPageHeading(<>Sign in to your <span className='themeclr'>researcher</span> account.</>); 
                                                    setSubHeading(''); 
                                                    setFormValues({...formValues, email:'', password:''}); 
                                                    setSocial(false);
                                                    setCacheLogin(false);
                                                }} 
                                                iconLeft={<Icon  value={"back arrow"} size={"medium"} />}
                                                size={"large"}
                                                label="Back to normal login"
                                            />
                                           
                                        </div>
                                        }
                                        </>
                                        }
                                        {!social &&
                                        <div className="login-bottom-link form-group nothaveaccount resarcher-btn-register">
                                            You don't have an account? <Link to="/researcher/sign-up">Sign up</Link>
                                        </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    
                        {(!social || cacheLogin) &&
                        <div className={`col-md-6  login-right-side text-right`} onMouseOver={()=>{ setHoverClass('hovered_fade')}} onMouseOut={()=>{ setHoverClass('')}}>
                            <img src={process.env.REACT_APP_URL+"/img/large-Sign-in.png"} />
                        </div>
                        }
                    </div>
                </div>
                <AccountInUseModal 
                    open={accountInUseModal.open}
                    close={()=>{
                        setAccountInUseModal({...accountInUseModal, open:false});
                    }}
                />
            </div>
            
             <Footer />
             </>
            }
        </div>
    )
}