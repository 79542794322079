import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import { hasFlag } from "country-flag-icons";
import Flags from "country-flag-icons/react/3x2";
import React, { useRef, useState } from "react";
import { Button } from "../../../themes/userq/Button/Button";
import { Modal } from "react-bootstrap";
import { roundAnlyticsNumber } from "../../../../lib/helpers";
import { ChevronLeft } from "@mui/icons-material";
import exportAsImage from "../../../../lib/exportAsImage";


export const  HorizontalBarChartResidencyStatsBox = ({title, data}) =>{

    const [otherModal, setOtherModal] = useState({open:false});

    const [viewAEAll, setViewAEAll] = useState(false);
    const [viewSAAll, setViewSAAll] = useState(false);


    const ref = useRef();
    const exportPngRef = useRef();

    const modref = useRef();

    const tipref = useRef();

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false,
        show:false
    });

    let countries_main = [];

    let countries_ksa_emirates = '';

    let countries_others = [];

    var sum = 0;

    const [isSubResidencyVisible, setSubResidencyVisible] = useState(false);
    const toggleSubResidency = (countryCode) => {

        if(countryCode == 'SA'){
            setViewSAAll(!viewSAAll);
        }else{
            setViewAEAll(!viewAEAll);
        }
        // setSubResidencyVisible(!isSubResidencyVisible);
    };


    const handlePointerMove = (data) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 150;


                var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 650;

                let local_ref = otherModal.open ? modref : ref;
                console.log(ref)
                if(!local_ref || !local_ref.current){
                    return;
                }


                let containerX = ('clientX' in event ? event.clientX : 0) - local_ref.current.getBoundingClientRect().left - tool_width/2;
                let containerY = ('clientY' in event ? event.clientY : 0)  - local_ref.current.getBoundingClientRect().top - tool_height - 10 ;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if(event.clientX + tool_width/2 > window_width){
                    containerX = ('clientX' in event ? event.clientX : 0) - local_ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

                }
                if(event.clientX - tool_width/2 < 0){
                    containerX = ('clientX' in event ? event.clientX : 0) - local_ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
                }
                showTooltip({
                    tooltipLeft: containerX,
                    tooltipTop: containerY,
                    tooltipData: data,
                    open:true,
                    show: tooltip.open && tipref && tipref.current ? true: false,
                    tipPostion: tipPosition
                });
            });
    }

    const renderFlagIcon = (code, country)=>{
        const Component = Flags[code];

        return  <Component
            title={country}
            style={{ display: "inline-block", height: "1em", width: "1em" }}
        />
    }
    const renderTooltipData = (code, count, country)=>{

        var tooltipRows = [];
        if(code=="AE"){

            for(var key in data["emirates"]["countries"]){
                tooltipRows.push(<div>{data["emirates"]["countries"][key]["count"]+" "+data["emirates"]["countries"][key]["country"]}</div>)
            }

            return <><div>{count+" United Arab Emirates"}</div>{tooltipRows}</>

        } else if(code=="SA"){
            for(var key in data["ksa"]["countries"]){
                tooltipRows.push(<div>{data["ksa"]["countries"][key]["count"]+" "+data["ksa"]["countries"][key]["country"]}</div>)
            }
            return <><div>{count+" Saudi Arabia"}</div>{tooltipRows}</>
        }

        return count > 1 ? count + " "+country : count + ' '+country
    }

    const renderHorizontalBarChart = () => {

        var max = 0;

        for (var key in data["others"]["countries"]) {

            if (data["others"]["countries"][key] > max) {
                max = data["others"]["countries"][key]["count"];
            }
        }


        for (var key in data["others"]["countries"]) {

            sum += data["others"]["countries"][key]["count"];

        }
        if(data["others"]["countries"].length > 8){

            for(var i=0; i< 7; i++){
                countries_main.push(data["others"]["countries"][i]);
            }
            var others = 0;

            for(var j=i; j< data["others"]["countries"].length; j++){
                others += data["others"]["countries"][j]["count"];

                countries_others.push(data["others"]["countries"][j]);
            }
            countries_main.push({"country":"More","short_code":"other","count":others});
        } else {
            countries_main = data["others"]["countries"];
        }

        countries_ksa_emirates = ({'emirates':data['emirates'],'ksa':data["ksa"]})
        /*countries_main.sort(function(a, b) {
            var keyA = a["count"],
              keyB = b["count"];
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          });*/




        return renderBars(countries_main,countries_ksa_emirates, sum, false, ref);


    }

    const renderBars = (countries,countries_ksa_emirates, sum, insideModal, local_ref)=>{

        var bars = [];

        //console.log(sum)
        //console.log(countries_ksa_emirates)

        for (var key in countries) {
            //Math.round(((countries[key]["count"] / sum) * 100) * 100) / 100
            var percentage = sum > 0 ? roundAnlyticsNumber((countries[key]["count"] / sum) * 100, true) : 0;
            var percentage_label = sum > 0 ? roundAnlyticsNumber((countries[key]["count"] / sum) * 100, true) : 0;

            var color = "rgba(151, 89, 203, 1)";

            bars.push((

                <React.Fragment key={"bar"+key}>


                    <div className={`horiz-chart-data-repeat ${countries[key]["short_code"]=="other"?'cursor-pointer':''}`} key={"bar"+key} onClick={countries[key]["short_code"]=="other" ? ()=>{ if(countries[key]["short_code"]=="other"){
                        setOtherModal({...otherModal, open:true});
                    }} : undefined}

                    >
                        <div className={`horizonatal-chart-left-side `}>

                            <div className="horiz-chart-top-info d-flex align-items-end">
                                <h3  style={{ color: color }} className={`h3 heading`}>{percentage_label + "%"}</h3>
                                <Text type={'caption'} fontWeight={'medium-font'} cssClasses={'color-black ml-2'}> {countries[key]["count"]} {countries[key]["count"] === 1?'participant':'participants'}</Text>

                            </div>

                            <div className="horiz-chart-hold-bg">
                                <div className={`horiz-chart-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
                                     style={{ width: percentage + "%", backgroundColor: color  }}
                                    //         onPointerMove={handlePointerMove(renderTooltipData(countries[key]["short_code"], countries[key]["count"],  countries[key]["country"])) }
                                    // onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
                                >
                                    <div
                                        className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
                                        style={{ backgroundColor: color }}
                                    >

                                    </div>


                                </div>

                            </div>




                        </div>



                        <div className={`horizonatal-chart-right-side ${countries[key]["short_code"]=="Unknown"?'unknown-label':''}`}>
                            <div className="horiz-chart-text-wrap d-flex align-items-center">
                                {hasFlag(countries[key]["short_code"]) &&
                                <span className="country-flag-icon">
                                {renderFlagIcon(countries[key]["short_code"], countries[key]["country"])}
                            </span>
                                }
                                {countries[key]["short_code"]=="Unknown" &&
                                <span className="country-flag-icon">
                                <Icon value={"support"} size={"medium"} />
                            </span>
                                }
                                <Text type={'body-text-2'} fontWeight={'semi-bold-font'} cssClasses={'color-black'}>{countries[key]["country"]}</Text>

                                {countries[key]["short_code"]=="Unknown" &&
                                <span className="info-icon info-wrap position-relative ml-2 cursor-pointer">

                                <Icon value={"information"} colorClass={'gray-700-svg'} size="medium" />
                                <Tooltip
                                    className={"tooltip-wrapper"}
                                    type={"bottom"}
                                    data="If you directly shared the link with others, you might see unknown users outside your target demographic group."
                                />
                                </span>
                                }
                                {countries[key]["short_code"]=="other" &&
                                <span className="info-icon info-wrap position-relative ">
                                    <Icon value={"global"} size="medium" />
                                </span>
                                }

                                {/*setViewAEAll AE view show more and less for soudi arab & UAE*/}
                                {(countries[key]["short_code"] == 'SA' || countries[key]["short_code"] == 'AE') &&
                                <div className="sub-res-info-hold d-flex align-items-center">
                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                        {countries[key]["short_code"] == 'SA' ?
                                            <>{(countries_ksa_emirates.ksa.countries.length !== 0) && <>From {countries_ksa_emirates.ksa && countries_ksa_emirates.ksa.countries.length} {countries_ksa_emirates.ksa.countries.length == 1?'Region':'Regions'}</>}</>
                                            :
                                            <>{(countries_ksa_emirates.emirates.countries.length !== 0) && <>From {countries_ksa_emirates.emirates && countries_ksa_emirates.emirates.countries.length} {countries_ksa_emirates.emirates.countries.length == 1?'Region':'Regions'}</>}</>
                                        }

                                    </Text>

                                    {(countries[key]["short_code"] == 'SA') ?
                                        <>
                                            {(countries_ksa_emirates.ksa.total_count !== 0) &&
                                            <span className="sub-res-arrow cursor-pointer"
                                                  onClick={() => toggleSubResidency('SA')}>
                                                       <Icon value={(viewSAAll) ? 'Chevron-Up' : 'Chevron'} size={'medium'}
                                                             hover={'true'}/>
                                                    </span>
                                            }
                                        </>
                                        :
                                        <>
                                            {(countries_ksa_emirates.emirates.total_count !== 0) &&
                                            <span className="sub-res-arrow cursor-pointer"
                                                  onClick={() => toggleSubResidency('AE')}>
                                                       <Icon value={(viewAEAll) ? 'Chevron-Up' : 'Chevron'} size={'medium'}
                                                             hover={'true'}/>
                                                    </span>
                                            }
                                        </>
                                    }

                                </div>
                                }

                            </div>
                        </div>


                    </div>

                    {(countries[key]["short_code"] === 'SA' && viewSAAll)  && (
                        <div className="sub-residency-wrap">

                            {countries_ksa_emirates.ksa && countries_ksa_emirates.ksa.countries.length > 0 &&
                            <>
                                {renderSubResidencyData(countries_ksa_emirates.ksa.countries,color,countries_ksa_emirates.ksa.total_count)}

                            </>
                            }

                        </div>
                    )}

                    {(countries[key]["short_code"] === 'AE' && viewAEAll)  && (
                        <div className="sub-residency-wrap">

                            {countries_ksa_emirates.emirates && countries_ksa_emirates.emirates.countries.length > 0 &&
                            <>
                                {renderSubResidencyData(countries_ksa_emirates.emirates.countries,color,countries_ksa_emirates.emirates.total_count)}

                            </>
                            }

                        </div>
                    )}

                </React.Fragment>


            ))
        }
        return <div className="edu-label-graph-bg" ref={local_ref} style={{position:'relative'}}>
            <div className="edu-graph-inner-hold" >

                {bars}
            </div>
            {tooltip.open && (!otherModal.open || insideModal) ? (
                <>
                    <Tooltip
                        ref={tipref}
                        type={tooltip.tipPosition}
                        key={Math.random()} // needed for bounds to update correctly
                        style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                        data={tooltip.tooltipData}
                        className="tooltipChart"

                    />
                </>
            ): (
                <></>
            )}
        </div>;
    }

    const renderSubResidencyData = (data,color,sum)=>{

        var dataSubResidencyData = [];

        data.forEach((item)=>{

            var percentage = sum > 0 ? roundAnlyticsNumber((item.count / sum) * 100, true) : 0;
            var percentage_label = sum > 0 ? roundAnlyticsNumber((item.count / sum) * 100, true) : 0;

            dataSubResidencyData.push(
                <div className="sub-residency-repeat d-flex align-items-center">
                    <div className="sub-res-left-side d-flex align-items-center">
                        <div className="sub-res-info-area d-flex align-items-center">
                            <p  style={{ color: color }} className={`body-text body-text-3 semibold-font`}>{percentage_label + "%"}</p>
                            <Text type={'caption'} cssClasses={`gray-color ${item.count == 1?'single-participant':''}`}>{item.count} {item.count == 1?' participant':' participants'} </Text>
                        </div>
                        <div className="sub-res-chart-area">
                            <div className="horiz-chart-hold-bg">
                                <div className={`horiz-chart-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
                                     style={{
                                         width: percentage + "%",
                                         backgroundColor: color
                                     }}>
                                    <div className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
                                         style={{backgroundColor: color}}>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="sub-res-right-side">
                        <Text type={'body-text-3'} fontWeight={'medium-font'}>{item.country}</Text>
                    </div>
                </div>
            )
        })

        return (
            <>
                {dataSubResidencyData}
            </>
        );
    }

    return <div className="position-relative chart-download-with-heading">
        <div className="mb-20 box-outer-heading">
            <Text type={"h4"} fontWeight={"medium-font"}>{title}</Text>
        </div>

        <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title)}>
            <Icon value={'download'} size={'small'} hover={'true'}></Icon>
            <div className="tooltip-wrapper top-tooltip">
                <p className="body-text body-text-2 normal-font ">Download</p>
            </div>
        </div>

        <div ref={exportPngRef}>

            <Box  size={"large"}>
                {renderHorizontalBarChart()}

            </Box>
            <Modal
                show={otherModal.open}
                centered
                className="fade custom-modal-wrap  confirmation-modal-wrap nationality-modal-wrap"
                onHide={() => {
                    setOtherModal({...otherModal, open:false});
                }}
            >

                <Modal.Body className="">
                    <Box  size={"large"}>
                        {renderBars(countries_others,countries_ksa_emirates, sum, true, modref)}
                    </Box>
                    <Button
                        type={"primary"}
                        size={"medium"}
                        label={"Close"}
                        onClick={()=>{
                            setOtherModal({...otherModal, open:false});
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    </div>;
}