import { useNavigate, useParams, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState } from "react";
//import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import {LayoutResearcher} from "../../themes/userq/Layouts/layout_researcher";
import { getProject } from "../../../services/project.js";
import NewTargetGroupModal from "../target_groups/new_target_modal";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import NewTestModal from "./../tests/new_test_modal";
import { getTestsService } from "../../../services/test";
import toast from "react-hot-toast";
import { Dropdown } from "react-bootstrap";
import ConfirmModal from "../../dialog/confirm_modal";
import CopyLinkModal from "../../dialog/copy_link_modal";
import MoveTestModal from "../../dialog/move_test_modal";
import {
    deleteTest,
    changeTestStatus,
    duplicateTest,
    moveTestToAnotherProject,
    updateTestStep,
    renameTestService,
} from "./../../../services/test";
import { deleteProject, getProjects } from "./../../../services/project";
import TestFrame from "./project frames/test_frame";
import { getCurrentPlansService } from "./../../../services/plans";
import {
    deleteTargetGroupService,
    renameTargetGroupService,
} from "./../../../services/target_group";
import RenameModal from "./../../dialog/rename_model";
import ProjectSettingsModal from "./project_settings_modal";
import { FormattedMessage } from "react-intl";
import { atom, useRecoilState } from 'recoil';
import { settings } from "../../../data/settings";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { LoadingIcon } from "../../loader/loadingIcon";
import { encryptId } from "../../../lib/helpers";

export default function Project() {
    let { project_id } = useParams();

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const [credits, setCredits] = useRecoilState(creditsState);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1600 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1600, min: 1000 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 1000, min: 0 },
            items: 1,
        },
    };

    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [testsLoading, setTestsLoading] = useState(false);

    const [userSettings, setUserSettings] = useState({"publishing_credits":{"card_sorting":null,"preference_test":null,"tree_test":null,"survey":null,"five_seconds_test":null}});

    const [planType, setPlanType] = useState("FREE");
    const [userProjects, setUserProjects] = useState([]);

    const [project, setProject] = useState({ target_groups: [] });
    const [rename, setRename] = useState({ open: false, isLoading: false });
    const [renameTest, setRenameTest] = useState({
        open: false,
        isLoading: false,
    });
    const [title, setTitle] = useState(null);
    const [targetId, setTargetId] = useState(null);

    const [tests, setTests] = useState({
        draft: [],
        completed: [],
        published: [],
        archived: [],
    });

    const [openModal, setOpenModal] = useState(false);

    const [openNewTestModal, setOpenNewTestModal] = useState(false);
    const [modalMethodology, setModalMethodology] = useState("");

    const [settingsModal, setSettingsModal] = useState({
        open: false,
        target_id: null,
    });

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        target_id: null,
        confirmation_for: null,
    });

    const [copyLinkModal, setCopyLinkModal] = useState({
        open: false,
        target_id: null,
    });

    const [moveTestModal, setMoveTestModel] = useState({
        open: false,
        target_id: null,
    });

    const showErrorToast = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 2000,
            }
        );
    };

    const showSuccessToast = (message) => {
        toast(
            <div className="toastinner">
                {message}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: 2000,
            }
        );
    };
    const getUserProjects = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProjects(token).then((response) => {
            setLoading(false);

            if (response.success) {
                let projects = [];
                response.projects.forEach((project) => {
                    projects.push({
                        value: project.id,
                        label: project.project_name,
                    });
                });
                // setUserProjects(projects);
                var projectexceptCurrentProject = projects.filter(function (item) {
                    return item.value != project_id;
                });

                setUserProjects(projectexceptCurrentProject);
            } else {
                // setErrorMessage(response.setErrorMessage);
                showErrorToast(
                    <FormattedMessage id="User projects cann't be fetched" />
                );
            }
        });
    };

    const getProjectApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProject({ project_id: project_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setProject(response.project);
                setUserSettings(response.settings);
                getTestsApi();
                getUserProjects();
            } else {
                // setErrorMessage(response.setErrorMessage);
                navigate("/dashboard");
                showErrorToast(
                    <FormattedMessage id="Project Detail can't be fetched" />
                );
            }
        });
    };
    const getTestsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestsService({ project_id: project_id }, token).then((response) => {
            setLoading(false);
            if (response.success) {
                setTests({ ...response.test_list });

            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getPlan = () => {
        let token = ReactSession.get("token");

        getCurrentPlansService(token).then((response) => {
            if (response.success) {
                setPlanType(response.plan.type);
            } else {
                showErrorToast(<FormattedMessage id="Plan cann't be fetched" />);
            }
        });
    };

    useEffect(() => {
        getProjectApi();
    }, [testsLoading]);

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Project";

        getPlan();
    }, []);

    // loading target group

    const renderTargetGroups = () => {

        var render = [];
        if (project) {
            project.target_groups.forEach(function (target_group,index) {
                render.push(
                    <div key={"target_group" + index} 
                        className={`target-group-slide`}>
                        <div className="d-flex justify-content-between target-group-hdr-text">
                            <h3>
                                <Link to={"/researcher/target_group/" + target_group.id}>
                                    {target_group.target_group_name}
                                </Link>

                            </h3>
                            <span className="pro-menu-icon small-dropdown   mid-menu-wrap dropdown-withclose-btn">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id={"dropdownMenuButton" + project.id}
                                        className="dropdown-toggle"
                                    >
                                        <img
                                            src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                                            alt="img"
                                            onClick={() => {
                                                document.getElementById(
                                                    "dropdownMenu" + target_group.id
                                                ).style.visibility = "visible";
                                            }}
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu id={"dropdownMenu" + target_group.id}>
                                        <div className="dropdown-close">
                                            <img
                                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                alt="img"
                                                onClick={() => {
                                                    document.getElementById(
                                                        "dropdownMenu" + target_group.id
                                                    ).style.visibility = "hidden";
                                                }}
                                            />
                                        </div>
                                        <div className="drop-down-link-hold">
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setTitle(target_group.target_group_name);
                                                    setRename({ open: true, isLoading: false });
                                                    setTargetId(target_group.id);
                                                }}
                                            >
                                                Rename
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/edit-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                            </Dropdown.Item>
                                            {target_group.total_times_assigned_to_test == 0 &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setConfirmModal({
                                                            open: true,
                                                            confirm_btn_title: "Delete",
                                                            confirm_title: "Delete target group",
                                                            confirm_message:
                                                                " Are you sure you want to delete this target group?",
                                                            target_id: target_group.id,
                                                            confirmation_for: "targetgroupdelete",
                                                        });
                                                    }}
                                                >
                                                    {" "}
                                                    Delete
                                                    <span>
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_URL +
                                                                "images/trash-black.svg"
                                                            }
                                                            alt="img"
                                                        />
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </div>
                        <div className="group-slide-inner">
                            <div className="group-slide-left">

                                <>
                                    {/*<img
                                        src={
                                            process.env.REACT_APP_URL + "images/user-profile-icon.png"
                                        }
                                        alt="img"
                                    />
                                    <span>
                                        <b>{target_group.testers_count}</b> testers
                                    </span>*/}
                                </>

                            </div>
                            <div className="group-slide-right">
                                <p>

                                    Active in  {target_group.tests_count} tests

                                </p>

                                <img
                                    src={process.env.REACT_APP_URL + "images/dotts.svg"}
                                    className="slide-dott-img"
                                    alt="img"
                                />
                                {target_group.tests_count == 0 &&
                                    <span className="call-made-icon">
                                        <Link to={"/researcher/target_group/" + target_group.id}>
                                            <img
                                                src={process.env.REACT_APP_URL + "images/call-made.svg"}
                                                alt="img"
                                            />
                                        </Link>
                                    </span>
                                }
                                {target_group.tests_count > 0 &&
                                    <span className="call-made-icon">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/lock-icon.svg"}
                                            alt="img"
                                        />
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return render;

    };

    // loading tests

    const renderTests = (status) => {
        var render = [];
        if (tests[status].length > 0) {
            tests[status].forEach(function (test, index) {
                render.push(
                    <TestFrame
                        key={"test" + index}
                        planType={planType}
                        uniquekey={"test" + index}
                        test={test}
                        duplicate={() => {
                            setConfirmModal({
                                open: true,
                                confirm_btn_title: "Duplicate",
                                confirm_title: "Duplicate Test",
                                confirm_message:
                                    " Are you sure you want to make a copy of this test?",
                                target_id: test.id,
                                confirmation_for: "duplicatetest",
                            });
                        }}
                        moveToAnotherProject={() => {
                            setMoveTestModel({
                                open: true,
                                movetest_btn_title: "Move",
                                movetest_title: "Move test to another project",
                                movetest_message:
                                    "The test will be visible only to the collaborators active in the project destination folder.",
                                target_id: test.id,
                            });
                        }}
                        deleteTest={() => {
                            setConfirmModal({
                                open: true,
                                confirm_btn_title: "Delete",
                                confirm_title: "Delete test",
                                confirm_message: " Are you sure you want to delete this test?",
                                target_id: test.id,
                                confirmation_for: "testdelete",
                            });
                        }}
                        openCopyLink={() => {
                            setCopyLinkModal({
                                open: true,
                                target_id: test.id,
                                password: test.password,
                                link: process.env.REACT_APP_URL + "t/" + encryptId(test.id, test),
                            });
                        }}
                        endTest={() => {
                            setConfirmModal({
                                open: true,
                                confirm_btn_title: test.methodology=="Survey"?"End Survey":"End Test",
                                confirm_title: test.methodology=="Survey"?"Are you sure you want to end this survey?":"Are you sure you want to end this test?",
                                confirm_message:
                                test.methodology=="Survey"?" By ending this survey, you’ll stop collecting results. But don’t worry, any unspent credits will be refunded to your account.":" By ending this test, you’ll stop collecting results. But don’t worry, any unspent credits will be refunded to your account.",
                                target_id: test.id,
                                confirmation_for: "endtest",
                            });
                        }}
                        archive={() => {
                            setConfirmModal({
                                open: true,
                                confirm_btn_title: "Archive",
                                confirm_title: "Archive Test",
                                confirm_message: " Are you sure you want to archive this test?",
                                target_id: test.id,
                                confirmation_for: "archivetest",
                            });
                        }}
                        renameTest={() => {
                            setTitle(test.test_name);
                            setRenameTest({ open: true, isLoading: false });
                            setTargetId(test.id);
                        }}
                    />
                );
            });
        }

        return render;
    };

    const closeModal = (reload) => {
        setOpenModal(false);
    };

    const closeNewTestModal = (reload) => {
        setOpenNewTestModal(false);
    };

    const closeSettingsModal = (reload) => {
        setSettingsModal({
            open: false,
            target_id: null,
        });

        if (reload) {
            getProjectApi();
        }
    };

    const clearErrorMessage = () => {
        setErrorMessage(null);
    };

    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    };
    const showTestUnavailabily = () => {
        toast(
            <div className="toastinner">
                {"Test Not Available"}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 2000,
            }
        );
    };

    const deleteTestApi = (test_id) => {
        let token = ReactSession.get("token");
        setLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        deleteTest(data, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {
                    getTestsApi();
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const endTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        data.append("status", "completed");
        changeTestStatus(data, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {

                    toast(
                        <div className="toastinner">
                            Your test has ended successfully.
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "successtoast",
                            position: "bottom-center",
                        }
                    );

                    ReactSession.set("credits", response.credits_balance);
                    setCredits(response.credits_balance);
                    getTestsApi();
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const archiveTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        data.append("status", "archived");
        changeTestStatus(data, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {
                    getTestsApi();
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const moveTestApi = (test_id, project_id) => {
        let token = ReactSession.get("token");

        setLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        data.append("project_id", project_id);
        moveTestToAnotherProject(data, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {
                    getTestsApi();
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const updateTestStepApi = (test_id) => {
        let token = ReactSession.get("token");
        setLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        data.append("saved_step", 3);
        updateTestStep(data, token)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                    getTestsApi();
                }
            })
            .catch(function (err) {
                console.error(` Err: ${err}`);
            });
    };

    const duplicateTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        duplicateTest(data, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {
                    getTestsApi();
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const deleteProjectApi = (project_id) => {
        let token = ReactSession.get("token");

        setLoading(true);
        var data = new FormData();
        deleteProject({ project_id: project_id }, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {

                    navigate("/dashboard");
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };
    const deleteTargetGroupApi = (target_group_id) => {
        let token = ReactSession.get("token");

        setLoading(true);

        deleteTargetGroupService({ id: target_group_id }, token)
            .then((response) => {
                setLoading(false);

                if (response.success) {
                    // navigate(-1);
                    // getTestsApi();
                    getProjectApi();
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const renameTargetGroup = (targetId, title) => {
        let token = ReactSession.get("token");
        setLoading(true);
        setRename({ ...rename, isLoading: true });

        renameTargetGroupService({ id: targetId, name: title }, token).then(
            (response) => {
                setLoading(false);

                if (response.success) {
                    setRename({ ...rename, open: false, isLoading: false });
                    setTitle(null);
                    setTargetId(null);
                    getProjectApi();
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            }
        );
    };

    const renameTestApi = (test_id, title) => {
        let token = ReactSession.get("token");
        // setLoading(true);
        setRenameTest({ ...rename, isLoading: true });

        renameTestService({ test_id: test_id, title: title }, token).then(
            (response) => {
                setLoading(false);

                if (response.success) {
                    setRenameTest({ ...rename, open: false, isLoading: false });
                    getTestsApi();
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            }
        );
    };

    return (
        <LayoutResearcher
            fixed_header_target={true}
            isLoading={false}
            skipCheck={false}
            activeMenu={"dashboard"}
        >
            <div className="dashboard-data-wrapper mt-0">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <div className="profile-info-section">
                            <div className="target-back-option-data">
                                <div className="backto-list-wrap">
                                    <Link
                                        to={'/dashboard'}
                                    >
                                        <img
                                            src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
                                            alt="img"
                                        />{" "}
                                        Back to Dashboard
                                    </Link>
                                </div>

                                <div className="profile-info-text">
                                    <>
                                        <div className="dash-top-left target-group-heading-top studyint-top-flex-wrap">
                                            <h1 className="page-main-sm-heading dash-flex-heading ">
                                                {!isLoading ?
                                                    <>
                                                        {project.image && (
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_IMG_URL + project.image
                                                                }
                                                                className="project-selected-image"
                                                            />
                                                        )}
                                                        {!project.image && (
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/project-img.png"
                                                                }
                                                                className="project-selected-image"
                                                            />
                                                        )}
                                                    </>
                                                    :
                                                    <Skeleton
                                                        circle
                                                        height="40px"
                                                        width="40px"
                                                        containerClassName="avatar-skeleton"
                                                    />

                                                }
                                                <span className="project-name-text">
                                                    {!isLoading ?
                                                        project.project_name
                                                        :
                                                        <Skeleton width={'200px'} height={'40px'} />
                                                    }
                                                </span>
                                            </h1>
                                            <div className="study-draft-and-save-wrap">
                                                {!isLoading &&
                                                    <p>
                                                        {project.collaborators &&
                                                            project.collaborators.length}{" "}
                                                        Project collaborator
                                                        {project.collaborators &&
                                                            project.collaborators.length == 1
                                                            ? ""
                                                            : "s"}
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <div className="dashboard-top-right project-das-top-right target-group-btns-top top-wdropd">
                                            <div className="top-buttons-wdropd auto-width-button">
                                                <button
                                                    id="r_new_target_group"
                                                    className="btn form-btn secondary-btn"
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                    }}
                                                >
                                                    NEW TARGET GROUP
                                                </button>

                                                <button
                                                    type="submit"
                                                    className="btn form-btn"
                                                    onClick={() => {
                                                        setOpenNewTestModal(true);
                                                    }}
                                                >
                                                    NEW TEST
                                                </button>
                                            </div>

                                            <span className="pro-menu-icon small-dropdown" id="r_project_menu">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        id="dropdownMenuButton"
                                                        className="dropdown-toggle"
                                                    >
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_URL +
                                                                "images/menu-bg-icon.svg"
                                                            }
                                                            alt="img"
                                                        />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="drop-down-link-hold">
                                                            <Dropdown.Item
                                                                className="dropdown-item"
                                                                onClick={() => {
                                                                    setSettingsModal({
                                                                        open: true,
                                                                        target_id: project_id,
                                                                    });
                                                                }}
                                                            >
                                                                Settings
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/setting-icon.svg"
                                                                        }
                                                                        alt="img"
                                                                        width="24"
                                                                        height="24"
                                                                    />
                                                                </span>
                                                                {/* </Link> */}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="dropdown-item"
                                                                onClick={() => {
                                                                    setConfirmModal({
                                                                        open: true,
                                                                        confirm_btn_title: "Delete",
                                                                        confirm_title: "Delete project",
                                                                        confirm_message:
                                                                            " Are you sure you want to delete this project?",
                                                                        target_id: project_id,
                                                                        confirmation_for: "deleteproject",
                                                                    });
                                                                }}
                                                            >
                                                                Delete
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/trash-black.svg"
                                                                        }
                                                                        alt="img"
                                                                        width="24"
                                                                        height="24"
                                                                    />
                                                                </span>
                                                            </Dropdown.Item>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inner-page-container project-page-dashboard-wrap">
                    <div
                        className={
                            !isLoading && project.target_groups.length > 0
                                ? "dashboard-inner-data-wrapper  progect-detail-dash-wrap pt-0"
                                : "dashboard-inner-data-wrapper pt-64"
                        }
                    >
                        {isLoading && (
                            <LoadingIcon />
                        )}

                        {!isLoading && (
                            <>

                                <div className="target-group-wrapper project-field-detaildas-wrap dash-target-group-slider project-detail-dashboard-data mt-64 mb-0">
                                    <h2 className="h2">
                                        Target groups
                                    </h2>
                                    {project && project.target_groups.length==0 && 
                                    <span className="noGroupMessage">You have no target groups.
                                    &nbsp;
                                       <button
                                           className="new-data-btn"
                                           onClick={() => {
                                               setOpenModal(true);
                                           }}
                                       >Create a new group
                                       </button>
                                       &nbsp;
                                        with a defined set of demographics.</span>
                                    }
                                    <OwlCarousel
                                        className="owl-theme"
                                        loop={project.target_groups.length>3?true:false}
                                        dots={false}
                                        margin={30}
                                        nav
                                        mouseDrag={project.target_groups.length>3?true:false}
                                        touchDrag={project.target_groups.length>3?true:false}
                                        responsiveclassName={true}
                                        responsive={{
                                            0: { items: 1 },
                                            600: { items: 2 },
                                            1000: { items: 3.5 },
                                            1600: { items: 3.5 },
                                        }}
                                    >
                                        {renderTargetGroups()}
                                    </OwlCarousel>
                                </div>

                                {tests.completed.length == 0 &&
                                    tests.draft.length == 0 &&
                                    tests.published.length == 0 &&
                                    tests.archived.length == 0 && (
                                        <>
                                            <h2 className="h2 mb-10 mt-64">
                                                Your project is empty.
                                            </h2>
                                            <p className="page-small-info-text mb-64">
                                                <button
                                                    className="new-data-btn"
                                                    onClick={() => {
                                                        setOpenNewTestModal(true);
                                                    }}
                                                >Get started</button> with your first test.
                                            </p>
                                        </>
                                    )}

                                <div className="das-ongoing-test-wrap project-detail-dashboard-data project-field-detaildas-wrap">
                                    {tests.published.length > 0 && (
                                        <>
                                            <h2 className="h2 mt-64">
                                                Live Studies
                                            </h2>
                                            <OwlCarousel
                                                className="owl-theme all-live-studies"
                                                loop={tests.published.length>3?true:false}
                                                dots={false}
                                                margin={30}
                                                nav
                                                mouseDrag={tests.published.length>3?true:false}
                                                touchDrag={tests.published.length>3?true:false}
                                                responsiveclassName={true}
                                                responsive={{
                                                    0: { items: 1 },
                                                    600: { items: 2 },
                                                    1000: { items: 3.5 },
                                                    1600: { items: 3.5 },
                                                }}
                                            >
                                                {renderTests("published")}
                                            </OwlCarousel>
                                        </>
                                    )}

                                    {tests.draft.length > 0 && (
                                        <>
                                            <h2 className="h2 mt-64">
                                                Drafts
                                            </h2>
                                            <OwlCarousel
                                                className="owl-theme"
                                                loop={tests.draft.length>3?true:false}
                                                dots={false}
                                                margin={30}
                                                nav
                                                mouseDrag={tests.draft.length>3?true:false}
                                                touchDrag={tests.draft.length>3?true:false}
                                                responsiveclassName={true}
                                                responsive={{
                                                    0: { items: 1 },
                                                    600: { items: 2 },
                                                    1000: { items: 3.5 },
                                                    1600: { items: 3.5 },
                                                }}
                                            >
                                                {renderTests("draft")}
                                            </OwlCarousel>
                                        </>
                                    )}
                                    {tests.completed.length > 0 && (
                                        <>
                                            <h2 className="h2 mt-64">Completed</h2>
                                            <OwlCarousel
                                                className="owl-theme all-completed-studies"
                                                loop={tests.completed.length>3?true:false}
                                                dots={false}
                                                margin={30}
                                                nav
                                                mouseDrag={tests.completed.length>3?true:false}
                                                touchDrag={tests.completed.length>3?true:false}
                                                responsiveclassName={true}
                                                responsive={{
                                                    0: { items: 1 },
                                                    600: { items: 2 },
                                                    1000: { items: 3.5 },
                                                    1600: { items: 3.5 },
                                                }}
                                            >
                                                {renderTests("completed")}
                                            </OwlCarousel>
                                        </>
                                    )}


                                    {tests.archived.length > 0 && (
                                        <>
                                            <h2 className="h2 mt-64">Archived</h2>
                                            <OwlCarousel
                                                className="owl-theme"
                                                loop={tests.archived.length>3?true:false}
                                                dots={false}
                                                margin={30}
                                                nav
                                                mouseDrag={tests.archived.length>3?true:false}
                                                touchDrag={tests.archived.length>3?true:false}
                                                responsiveclassName={true}
                                                responsive={{
                                                    0: { items: 1 },
                                                    600: { items: 2 },
                                                    1000: { items: 3.5 },
                                                    1600: { items: 3.5 },
                                                }}
                                            >
                                                {renderTests("archived")}
                                            </OwlCarousel>
                                        </>
                                    )}
                                </div>


                                <p className="page-small-info-text">&nbsp;</p>
                                {project &&
                                    project.target_groups.length == 0 &&
                                    tests.completed.length == 0 &&
                                    tests.draft.length == 0 &&
                                    tests.published.length == 0 &&
                                    tests.archived.length == 0 &&
                                    (
                                        <div className="dashboard-cards-wrapper">
                                            <div className="row">
                                                <div
                                                    className="col-md-3"
                                                    onClick={() => {
                                                        setOpenNewTestModal(true);
                                                        setModalMethodology("Card Sorting");
                                                    }}
                                                >
                                                    <div className="dash-cards-wrap flex-wrap">
                                                        <div className="cards-data">
                                                            <h3>CARD SORTING</h3>
                                                            <p>
                                                                Find out how users think content should be
                                                                organised and labelled to create a validated
                                                                information architecture.
                                                            </p>

                                                        </div>
                                                        <div className="dash-create-test-btn-wrap">
                                                            <p className="card-credit-info-wrap">
                                                                <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                />
                                                                {userSettings.publishing_credits.card_sorting!==null ? userSettings.publishing_credits.card_sorting :settings.publishing_credits_card_sorting} credits/publish
                                                            </p>

                                                            <button className="create-btn">
                                                                Create{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/arrow-right.png"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-md-3"
                                                    onClick={() => {
                                                        setOpenNewTestModal(true);
                                                        setModalMethodology("Preference Test");
                                                    }}
                                                >
                                                    <div className="dash-cards-wrap dash-card-2 flex-wrap">
                                                        <div className="cards-data">
                                                            <h3>PREFERENCE TEST</h3>
                                                            <p>
                                                                Measure impact by having users evaluate
                                                                different designs to understand which ones
                                                                perform better.
                                                            </p>
                                                        </div>
                                                        <div className="dash-create-test-btn-wrap">
                                                            <p className="card-credit-info-wrap">
                                                                <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                />
                                                                {userSettings.publishing_credits.preference_test!==null ? userSettings.publishing_credits.preference_test : settings.publishing_credits_preference_test} credits/publish
                                                            </p>

                                                            <button className="create-btn">
                                                                Create{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/arrow-right.png"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-md-3"
                                                    onClick={() => {
                                                        setOpenNewTestModal(true);
                                                        setModalMethodology("Tree Test");
                                                    }}
                                                >
                                                    <div className="dash-cards-wrap dash-card-3 flex-wrap">
                                                        <div className="cards-data">
                                                            <h3>TREE TEST</h3>
                                                            <p>
                                                                Pinpoint exactly where your users are getting
                                                                lost within your existing “tree” of information
                                                                & hierarchical structure.
                                                            </p>
                                                            
                                                        </div>
                                                        <div className="dash-create-test-btn-wrap">
                                                            <p className="card-credit-info-wrap">
                                                                <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                />
                                                                {userSettings.publishing_credits.tree_test!==null ? userSettings.publishing_credits.tree_test : settings.publishing_credits_tree_test} credits/publish
                                                            </p>

                                                            <button className="create-btn">
                                                                Create{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/arrow-right.png"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="col-md-3"
                                                    onClick={() => {
                                                        setOpenNewTestModal(true);
                                                        setModalMethodology("Survey");
                                                    }}
                                                >
                                                    <div className="dash-cards-wrap dash-card-4 flex-wrap">
                                                        <div className="cards-data">
                                                            <h3>SURVEY</h3>
                                                            <p>
                                                                Get your target customers to answer your burning
                                                                questions and acquire precise insights and data
                                                                points.
                                                            </p>
                                                            
                                                        </div>
                                                        <div className="dash-create-test-btn-wrap">
                                                            <p className="card-credit-info-wrap">
                                                                <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                />
                                                                {userSettings.publishing_credits.survey!==null ? userSettings.publishing_credits.survey : settings.publishing_credits_survey} credits/publish
                                                            </p>

                                                            <button className="create-btn">
                                                                Create{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/arrow-right.png"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title={confirmModal.confirm_btn_title}
                confirm_title={confirmModal.confirm_title}
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    if (confirmModal.confirmation_for === "testdelete") {
                        deleteTestApi(confirmModal.target_id);
                        setTestsLoading(!testsLoading);
                    } else if (confirmModal.confirmation_for === "endtest") {
                        endTestApi(confirmModal.target_id);
                        setTestsLoading(!testsLoading);
                    } else if (confirmModal.confirmation_for === "archivetest") {
                        archiveTestApi(confirmModal.target_id);
                        setTestsLoading(!testsLoading);
                    } else if (confirmModal.confirmation_for === "duplicatetest") {
                        duplicateTestApi(confirmModal.target_id);
                        setTestsLoading(!testsLoading);
                    } else if (confirmModal.confirmation_for === "deleteproject") {
                        deleteProjectApi(confirmModal.target_id);
                        setTestsLoading(!testsLoading);
                    } else if (confirmModal.confirmation_for === "targetgroupdelete") {
                        deleteTargetGroupApi(confirmModal.target_id);
                    }
                }}
            />
            {moveTestModal.open && (
                <MoveTestModal
                    movetest_message={moveTestModal.movetest_message}
                    movetest_btn_title={moveTestModal.movetest_btn_title}
                    movetest_title={moveTestModal.movetest_title}
                    openModal={moveTestModal.open}
                    project_id={project_id}
                    userProjects={userProjects}
                    close={() => {
                        setMoveTestModel({ ...moveTestModal, open: false });
                    }}
                    move={(project_id) => {
                        if (project_id) {
                            moveTestApi(moveTestModal.target_id, project_id);
                            setMoveTestModel({ ...moveTestModal, open: false });
                            showSuccessToast(
                                <FormattedMessage id="Test moved successfully" />
                            );
                            setTestsLoading(true);
                        } else {
                            showErrorToast(
                                <FormattedMessage id="Please select a project to move" />
                            );
                        }
                    }}
                />
            )}
            {copyLinkModal.target_id && (
                <CopyLinkModal
                    link={copyLinkModal.link}
                    password={copyLinkModal.password}
                    test_id={copyLinkModal.target_id}
                    openModal={copyLinkModal.open}
                    close={() => {
                        setCopyLinkModal({ ...copyLinkModal, open: false });
                    }}
                />
            )}
            {openNewTestModal && (
                <NewTestModal
                    openModal={openNewTestModal}
                    closeModal={closeNewTestModal}
                    project_id={project_id}
                    project_name={project.project_name}
                    selectedMethodology={modalMethodology}
                    publishing_credits={userSettings.publishing_credits}
                />
            )}

            {settingsModal.open && (
                <ProjectSettingsModal
                    openModal={settingsModal.open}
                    closeModal={closeSettingsModal}
                    planType={planType}
                    project_id={settingsModal.target_id}
                />
            )}

            <NewTargetGroupModal
                project_id={project.id}
                openModal={openModal}
                closeModal={closeModal}
            />
            <RenameModal
                title={title ? title : ""}
                open={rename.open}
                heading={"Rename target group"}
                close={() => {
                    setRename({ open: false, isLoading: false });
                    setTargetId(null);
                    setTitle(null);
                }}
                language={"en"}
                isLoading={rename.isLoading}
                confirm={(title) => {
                    renameTargetGroup(targetId, title);
                }}
            />

            <RenameModal
                title={title ? title : ""}
                open={renameTest.open}
                heading={"Rename test"}
                close={() => {
                    setRenameTest({ open: false, isLoading: false });
                    setTargetId(null);
                }}
                language={"en"}
                isLoading={renameTest.isLoading}
                confirm={(title) => {
                    renameTestApi(targetId, title);
                    setTestsLoading(!testsLoading);
                }}
            />
        </LayoutResearcher>
    );
}
