import React, { useEffect, useRef, useState } from "react";
import "./TakeTestQuestionImage.css";
import {Icon} from "../Icon/Icon";
import {Preview} from "../Preview/preview";

export  const TakeTestQuestionImage = ({url,type})=>{


    const [image_url, setImageURL] = useState(url?process.env.REACT_APP_IMG_URL+"/"+url:null);

    const [previewModal, setPreviewModal] = useState({open:false, img:null, loading:false});

    useEffect(()=>{

        setImageURL(url?process.env.REACT_APP_IMG_URL+"/"+url:null);

    },[url]);


    return (
        <>
            {type === 'question' ?
                     <>
                        {image_url
                            ?
                                <div className="taking-test-question-image take-test-question-image-wrap">
                                    <img src={image_url} className="question-img-thum"/>
                                </div>
                            :
                            <></>
                        }
                    </>

                :
                <></>
            }

            {type === 'option' ?
                <>
                    {image_url
                        ?
                        <div className="answer-option-image-wrap position-relative cursor-pointer" onClick={(e)=>{
                            e.preventDefault();
                            setPreviewModal({...previewModal, open:true, img:image_url});
                        }}>
                                <div className="max-icon-hold">
                                <div className="max-icon cursor-pointer">
                                        <Icon colorClass="gray-50-svg" value="maximize" size={"large"}/>
                                </div>
                                </div>  
                                <img src={image_url} className="question-img-thum"/>
                            
                        </div>
                        :
                        <></>
                    }
                  </>
                :
                <></>
            }
            <Preview withMinimizeIcon={true} open={previewModal.open} loading={false} img={previewModal.img} close={()=>{

                setPreviewModal({open:false, img:null, loading:false});
            }}/>
        </>

    );



}