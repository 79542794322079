import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import Layout from "../layouts/layout";
import {LayoutResearcher} from "../themes/userq/Layouts/layout_researcher";
import {LayoutTester} from "../themes/userq/Layouts/layout_tester";
import {Icon} from "../themes/userq/Icon/Icon";
import {Text} from "../themes/userq/Text/Text";
import {Button} from "../themes/userq/Button/Button";
import {TakingTestHeader} from "../themes/userq/Header/TakingTestHeader";
import {Footer} from "../themes/userq/Footer/Footer";
import ExitTestModal from "./dialog/exit_test_modal";

export default function ErrorPage({errorType,test}) {
    const [user,setUser] = useState({"role":""});
    const [counter,setCounter] = useState(10);
    const [openExitTestModal, setOpenExitTestModal] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {

        let user = ReactSession.get("user");
        setUser(user);

    }, []);

    const closeExitTestModal = (data) => {
        setOpenExitTestModal(false);
    };

    useEffect(() => {
        // set time taken by test
        function tickTime() {
            if(counter === 0){
                if(!user){
                    navigate('/tester/sign-up');
                }else{
                    navigate('/');
                }

            }else {
                setCounter(prevSeconds => prevSeconds - 1)
            }

        }
        let timeData = setInterval(() => tickTime(), 1000)

        return () => clearInterval(timeData);

    }, [counter]);



    const errorHtml = () =>{
        return (
            <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap error-404">
                <div className="container">
                    <div className={`no-participants-data no-questions-data no-questions-added`}>
                        <div className="no-page-data-wrap" style={{marginTop:"80px"}}>
                            <div className="no-data-icon">
                                <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                            </div>

                            <Text type={"subtitle-1"} fontWeight="medium-font">
                                It looks like this test is no longer available.

                            </Text>

                        </div>

                        <div className={"fotter-wrap text-center"}>
                            {(ReactSession.get("token")) &&
                            <Button
                                type={"primary"}
                                size={"large"}
                                iconRight={<Icon colorClass={"gray-50-svg"} value={'forward-arrow'}/>}
                                label={"Back to dashboard"}
                                onClick={() => {
                                    let user = ReactSession.get("user");

                                    if(user && user.role === "Researcher" && test){
                                        navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
                                    } else {
                                        navigate('/dashboard');
                                    }
                                }}
                            />
                            }

                            {!(ReactSession.get("token")) &&
                            <Button
                                type={"primary"}
                                size={"large"}
                                iconRight={<Icon colorClass={"gray-50-svg"} value={'forward-arrow'}/>}
                                label={"Signup to become a tester"}
                                onClick={() => {
                                    navigate("/tester/sign-up")
                                }}
                            />
                            }
                        </div>
                    </div>

                    <div className={'error-page-footer-wrap d-flex text-center'}>
                        <Text type={"body-text-3"} fontWeight="medium-font">
                            You will be redirected back to the homepage in:

                        </Text>

                        <span className="error-timer-hold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path d="M13 6V14H19M25 13C25 14.5759 24.6896 16.1363 24.0866 17.5922C23.4835 19.0481 22.5996 20.371 21.4853 21.4853C20.371 22.5996 19.0481 23.4835 17.5922 24.0866C16.1363 24.6896 14.5759 25 13 25C11.4241 25 9.86371 24.6896 8.4078 24.0866C6.95189 23.4835 5.62902 22.5996 4.51472 21.4853C3.40042 20.371 2.5165 19.0481 1.91345 17.5922C1.31039 16.1363 1 14.5759 1 13C1 9.8174 2.26428 6.76516 4.51472 4.51472C6.76516 2.26428 9.8174 1 13 1C16.1826 1 19.2348 2.26428 21.4853 4.51472C23.7357 6.76516 25 9.8174 25 13Z" stroke="#676767" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <Text type={"h4"} fontWeight="medium-font">
                            {String(counter).padStart(2, "0")}

                        </Text>
                        </span>
                    </div>

                    {/*<div className="login-left-side">*/}
                    {/*    <p className="error-small-text">*/}
                    {/*        BACK HOME IN 00:00:{String(counter).padStart(2, "0")}*/}
                    {/*    </p>*/}
                    {/*    <h1 className="form-heading">*/}
                    {/*        {errorType && errorType === '404' &&*/}
                    {/*           <> UMMMM! Well this is Strange </>*/}
                    {/*        }*/}
                    {/*        {errorType && errorType === 'general' &&*/}
                    {/*             <>OH NO! stay with us</>*/}
                    {/*        }*/}

                    {/*    </h1>*/}

                    {/*</div>*/}
                </div>
                {/*<div className="full home-marquee-banner section">*/}
                {/*    {errorType && errorType === 'general' &&*/}
                {/*    <div className="home-marque-banner-box">*/}
                {/*        <div className="scrolling-box"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>*/}
                {/*    </div>*/}
                {/*    }*/}
                {/*    {errorType && errorType === '404' &&*/}
                {/*    <div className="home-marque-banner-box">*/}
                {/*        <div className="scrolling-box"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*        <div className="scrolling-box" aria-hidden="true"> Error 404</div>*/}
                {/*    </div>*/}
                {/*    }*/}
                {/*</div>*/}


                {/*<div className="login-form-inner-data">*/}
                {/*    {!(ReactSession.get("token")) &&*/}
                {/*    <Link to={'/tester/sign-in'} className="btn btn-block form-btn login-page-btn mt-0">*/}
                {/*        Login to become a tester*/}
                {/*    </Link>*/}
                {/*    }*/}

                {/*    {(ReactSession.get("token")) &&*/}
                {/*    <Link to={'/'} className="btn btn-block form-btn login-page-btn mt-0">*/}
                {/*        Back to home*/}
                {/*    </Link>*/}
                {/*    }*/}
                {/*</div>*/}

            </div>
        );
    }

    return (
        <>
            {(ReactSession.get("token")) && ((user.role==="Tester")) &&
                    <LayoutTester
                        isLoading={false}
                        isAccount={true}
                        skipCheck={false}
                        activeMenu={""}
                    >

                    <div className="login-page-wrapper 404-err0r-wrap">
                        <div className="error-inner-404">
                            { errorHtml()}
                        </div>
                    </div>
                </LayoutTester>
            }

            {(ReactSession.get("token")) && ((user.role==="Researcher")) &&
            <LayoutResearcher
                isLoading={false}
                  skipCheck={false}
                  wrapClass={"create-test-data-wrapper"}>

                <div className="login-page-wrapper 404-err0r-wrap">
                    <div className="error-inner-404">
                        { errorHtml()}
                    </div>
                </div>
            </LayoutResearcher>
            }

            {!(ReactSession.get("token")) &&
                <>
                <TakingTestHeader test={test} exitText={() => {
                    setOpenExitTestModal(true)
                }} />

                    { errorHtml()}
                <Footer />
                </>
            }

            <ExitTestModal
                openExitTestModal={openExitTestModal}
                closeExitTestModal={closeExitTestModal}
                test={test}
            />
            </>
       );
}
