import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import { navigate } from "@storybook/addon-links";
import { useEffect } from "react";

export const TestResultAdminAccess = ({})=>{

    const {test_id} = useParams();

    const navigate = useNavigate();    

    useEffect(()=>{


            const query = new URLSearchParams(window.location.search);

            const access_key = query.get('access_key'); 


            ReactSession.set("admin_result_token", access_key);

            navigate("/a/results/"+test_id+"/overview");
    },[])
    return <></>
}