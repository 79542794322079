import React, { useEffect, useState, useRef } from "react";
import PrototypeTestIntroduction from "./prototype_test_introduction";
import PrototypeTestTaskFlow from "./prototype_test_task_flow";
import PrototypeTestFreeFlow from "./prototype_test_free_flow";
import TaskNotCompleted from "../../elements/TaskNotCompleted";

export default function PrototypeTest({
    test,
    result,
    questionCallback,
    callback,
    questionType,
    timeSpent,
    isPaidUser,
    onAlreadyTaken
}) {
    const [isActiveTask, setActiveTask] = useState(0);
    const [initialScreen, setInitialScreen] = useState(true);
    const [taskScreen, setTaskScreen] = useState(false);

    const [taskError, setTaskError] = useState(null);

    const renderTask = (test)=>{
        
        var task = test.prototype_test_tasks[isActiveTask];

        var error = false;

        if (!task.prototype_link) {
            error = true;
        } else if (!task.is_synced) {
            error = true;
        } else if (!task.description) {
            error = true;
        } else if (
            test.prototype_test_setting
                .objective == "closed" &&
            !task.goal_screen_node_id
        ) {
            error = true;
        } else if (
            test.prototype_test_setting
                .objective == "open" &&
            task.prototype_test_task_question.length == 0
        ) {
            error = true;
        } else if (
            test.prototype_test_setting
                .objective == "open" &&
            task.prototype_test_task_question.length > 0
        ) {
            var no_of_optional = 0;

            task.prototype_test_task_question.forEach(function (item) {
                if (item.is_optional) {
                    no_of_optional++;
                }
            });
            if (
                no_of_optional ==
                task.prototype_test_task_question.length
            ) {
                error = true;
            }
        }
        if(error){
            return <TaskNotCompleted 
                to={"/r/test/"+test.id+"/prototype-test"}
            />;
        }
        return <>
                {test.prototype_test_setting.objective == "closed" ?
                        <PrototypeTestTaskFlow goToFirstQuestion={
                            () => {
                                setTaskScreen(false);
                            }
                        }
                            test={test}
                            task={test.prototype_test_tasks[isActiveTask]}
                            active_task={isActiveTask}
                            result={result}
                            timeSpent={timeSpent}
                            moveToNextTask={(task) => {
                                console.log(task);
                                console.log(test.prototype_test_tasks.length);
                                if (task < test.prototype_test_tasks.length) {
                                    setActiveTask(task);
                                } else {
                                    callback();
                                }
                            }}
                            isPaidUser={isPaidUser}
                            onAlreadyTaken={onAlreadyTaken}
                        />
                        :
                        <PrototypeTestFreeFlow goToFirstQuestion={
                            () => {
                                setTaskScreen(false);
                            }
                        }
                            test={test}
                            task={test.prototype_test_tasks[isActiveTask]}
                            active_task={isActiveTask}
                            result={result}
                            timeSpent={timeSpent}
                            moveToNextTask={(task) => {
                                console.log(task);
                                console.log(test.prototype_test_tasks.length);
                                if (task < test.prototype_test_tasks.length) {
                                    setActiveTask(task);
                                } else {
                                    callback();
                                }
                            }}
                            isPaidUser={isPaidUser}
                            onAlreadyTaken={onAlreadyTaken}
                        />
                    }
            </>;
    }
    return (
        <>
            {initialScreen &&
                <PrototypeTestIntroduction goToFirstTask={
                    () => {
                        setInitialScreen(false);
                        setTaskScreen(true);
                    }
                }
                    testlanguage={test.language}
                    test={test}
                />
            }
            {taskScreen &&
                <>
                    {renderTask(test)}                    
                </>
            }
        </>
    );
}
