import { useNavigate } from "react-router-dom";
import { Text } from '../Text/Text';
import { GuideLink } from "../GuideLink/GuideLink";

export const Guidelines = ({onHide,...props})=>{

    return (<div className="guidlines-section">
        <div className='mt-16'>
            <Text type={"subtitle-1"} cssClasses="purple-color" fontWeight={"medium-font"}>Guides</Text>
        </div>
        

        <div className="guideline-iframe-wrap mt-32 mb-32">
        <div className='mb-20'>
            <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>Introduction to UserQ</Text>
        </div>
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/CBNq0tPgD68" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div> 
        <div className="nps-guideline-link">
            <Text type={"body-text-2"} fontWeight={"semibold-font"}>How to create a test</Text>
            <GuideLink methodology={"prototype test"}/>
            <GuideLink methodology={"tree test"}/>
            <GuideLink methodology={"card sorting test"}/>
            <GuideLink methodology={"first click test"}/>
            <GuideLink methodology={"5 second test"}/>
            <GuideLink methodology={"preference test"}/>
            <GuideLink methodology={"survey"}/>

        </div>
    </div>);
}