import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Text } from "../themes/userq/Text/Text";

export const CountdownTimer = ({timerData})=>{


    
    const [timer, setTimer] = useState(0);

    let clearTimer;

    useEffect(() => {

        if(timer>0){
            countDown();
        }

    }, [timer]);

    useEffect(() => {
        return () => {
            if(clearTimer){
                clearTimeout(clearTimer);
            }
          };
    },[])
    useEffect(()=>{

        
        if(clearTimer){
            clearTimeout(clearTimer);
        }
        let date1 = moment();
        let date2 = moment(timerData).utc(true);
        let difference = date1.diff( date2, "seconds" );
        console.log(date1, date2, difference);
        setTimer(24*60*60 - difference);

        
    },[timerData])

    

    const countDown = ()=>{

        if(clearTimer){
            clearTimeout(clearTimer);
        }
        clearTimer = setTimeout(()=>{
            
            setTimer(timer-1);

        },1000);
    }

    const formatTime =(seconds)=>{

        if(isNaN(seconds)){
            return '00:00:00';
        }

        const result = new Date(seconds * 1000)
            .toISOString()
            .slice(11, 19);

        return result;
    }




    return (
        <>
            <Text type={"body-text-1"} display={'inline'} fontWeight={"medium-font"} cssClasses={"color-back"}>{formatTime(timer)}</Text>
        </>
    );
}
