import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
export default function EditingUnavailableModal({
    open,
    user,
    test,
    close,
}) {
    const closeModal = () => {
        close();
    };

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap "
            onHide={() => {
                closeModal();
            }}
        >
            <Modal.Body className="">
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h2">Editing unavailable</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="used-accound-mid-data">
                        <Text
                            type={"body-text-2"}
                            cssClasses={
                                "gray-text mb-32 d-flex align-items-center purple-color"
                            }
                            fontWeight={"medium-font"}
                        >
                            <Icon
                                value={"lock"}
                                colorClass={"secondary-purple-200-svg mr-2"}
                            />
                            You cannot edit this test at the moment
                        </Text>

                        <Text
                            type={"body-text-2"}
                            cssClasses={"gray-text mb-32"}
                            fontWeight={"medium-font"}
                        >
                            {test && test.test_name} is currently being edited by{" "}
                            <b className="color-black">{user}.</b>
                            
                        </Text>

                        <div className="modal-btn">
                            <Button
                                type="primary"
                                size={`large`}
                                label={"Close"}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
