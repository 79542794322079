import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { Dropdown } from 'reactjs-dropdown-component';
import { useSearchParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'
import { PhoneNumberUtil } from 'google-libphonenumber';
import ReCAPTCHA from "react-google-recaptcha";
import { load } from 'recaptcha-v3'
import { Text } from '../../themes/userq/Text/Text';
import { CheckBox } from '../../themes/userq/CheckBox/CheckBox';
import { Button } from '../../themes/userq/Button/Button';
import { Input } from '../../themes/userq/Input/Input';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../../data/settings';
import { Logo } from '../../themes/userq/Logo/Logo';
import { Footer } from '../../themes/userq/Footer/Footer';
import GoogleAuth from '../login-with-google';
import { LoadingIcon } from '../../loader/loadingIconNew';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Helmet } from 'react-helmet';
import AccountInUseModal from '../modals/AccountInUseModal';

let captcha = null;


export default function Register() {

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    let jobRolesSelect = useRef();

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];

    const [jobRoleLabelClass, setJobRoleLabelClass] = useState("inactive_label");

    const [errorMsg, setErrorMessage] = useState(null);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', captcha: '', dialcode_phone: null, phone_number: "", first_name: '', last_name: '', password: '', company_name: '', job_role: '', job_role_other: '', newsletter: true, terms: false, role: 'Tester' });

    const [formErrors, setFormErrors] = useState({ phone_no: null, first_name: null, last_name: null, password: null, company_name: null, job_role: null, job_role_other: null, job_role_other: null, passwordErrorStyle: { color: '#000' }, terms: null });

    const [modal, setModal] = useState({ modalHeading: null, modalMessage: null, modalOpen: false });

    const [newPasswordError, setNewPasswordError] = useState({error:false, length:0, letters:0, numbers:0, special:0});

    const [socialLoading, setSocialLoading] = useState(false);

    const emailAlreadySet = ReactSession.get('register_email') ? true : false;

    const location = useLocation();

    let signUpPlan = location.pathname.includes("/team-plan/sign-up") ? "Team" : "Individual";

    const [invite_code, setInviteCode] = useState('');

    const [accountInUseModal, setAccountInUseModal] = useState({open:false});

    useEffect(() => {
        window.animate();
        document.title = "Researcher Sign Up - User research and resting platform | UserQ";


        //console.log();

        if (searchParams.get("plan_id")) {
            ReactSession.set("term", searchParams.get("term").toUpperCase());

            ReactSession.set("plan_id", searchParams.get("plan_id"));
        } else {
            ReactSession.set("term", null);

            ReactSession.set("plan_id", null);
        }
        if (!ReactSession.get('register_email')) {
            //navigate("/researcher/sign-in");
        }
        regenerateCaptcha();

        console.log("what is plan", signUpPlan);

        const param = searchParams.get('invite_code'); 
        if (param) {
            setInviteCode(param);
        }

    }, []);

    useEffect(() => {
        console.log("this invite code is ", invite_code);
    }, [invite_code])




    
    const regenerateCaptcha = () => {
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                setFormValues({ ...formValues, captcha: captcha });

            })
        })
    }
    const generateCaptcha = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                setFormValues({ ...formValues, captcha: captcha });

                callback(token);

            })
        })
    }
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const registerUser = () => {

        setLoading(true);

        generateCaptcha(function (token) {

            formValues.captcha = token;
            if(invite_code){
                formValues.invite_code = invite_code;
            } else {
                formValues.signup_plan = signUpPlan;
            }

            setErrorMessage(null);

            let body = formValues;

            body['role'] = 'Researcher';
            body['referralCode'] = searchParams.get('refID');

            /*if (body["job_role"] == "Other...") {
                body["job_role"] = body["job_role_other"];
            }*/
            body["fingerprint"] = window.fingerprintvisitorid;
            fetch(process.env.REACT_APP_API_END_POINT + 'register', {
                method: 'POST',
                body: JSON.stringify(formValues),
                headers: { "Content-Type": "application/json" }
            })
                .then(res => res.json())
                .then(response => {
                    setLoading(false);
                    if (response.success) {
                        ReactSession.set("register_email", formValues.email);
                        navigate("/researcher/registration/success");

                    } else {
                        showError(response.message);
                    }
                });

        })

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value='',inline=false ) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
       
        if(!key || key=="email"){
            
            var field_value = key && inline ? value : formValues.email;

            if (field_value == '') {


                form_errors = {
                    ...form_errors,
                    email: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
            else if (field_value!=null && !field_value.match(mailformat)) {

                form_errors = {
                    ...form_errors,
                    email: "Please enter a valid email",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
        }
        if(!key || key=="password"){

            var field_value = key && inline ? value : formValues.password;

            if(!inline){
                validateNewPassword(field_value,true);
            } else {
                validateNewPassword(field_value,false);
            }
            

            if (field_value.length < 6 || !field_value.match(re)) {

                /*form_errors = {
                    ...form_errors,
                    password: "Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)",
                    error_class: "input_error",
                    password_error_class: "password_error",
                };*/
                error = true;
                firsterrorid = firsterrorid == null ? "pass_log_id" : firsterrorid;

                if(!field_value){
                    form_errors = {
                        ...form_errors,
                        password: "Required field",
                        error_class: "input_error",
                        password_error_class: "password_error",
                    };
                    
                }
            } 
        }
        if(!key){
            if (!formValues.terms) {

                if(focus){
                    showError(<FormattedMessage id="You must agree to our Terms and Conditions and Privacy Policy" />);
                }
                error = true;

            }
        }
        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        

        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const validateBtnState = () => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if (formValues.email == '') {
            error = true;
        }
        else if (formValues.email!=null && !formValues.email.match(mailformat)) {

            error = true;
        }
        if (formValues.password.length < 6 || !formValues.password.match(re)) {
   
            error = true;            
        }        
        if (!formValues.terms) {

            error = true;
        }
        return error;
    }
    const onSubmit = async (event) => {
        event.preventDefault();

        //if (formValues.terms) {
            setErrorMessage(null);

            if (!isLoading) {

                var error = validateFullForm(true);

                if (!error) {
                    registerUser();
                }
            }
        //}
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues(oldValues => ({ ...oldValues, [name]: value }));
                }
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            }
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value: '',true)
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {

            setTimeout(function () {
                setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
            }, 1000)

        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const validateForm = (name) => {


        return (event) => {

            setFormValues(oldValues => ({ ...oldValues, [name]: event.target.value }));


            var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

            if (formValues.password.length < 6 || !formValues.password.match(re)) {
                setFormErrors(oldValues => ({ ...oldValues, ['password']: 'Invalid password' }));
            } else {
                setFormErrors(oldValues => ({ ...oldValues, ['password']: '' }));
            }
        }

    }
    
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const jobRoleChange = (item) => {
        jobRolesSelect.current.selectSingleItem({ value: item.value });
        setFormValue("job_role", item.value);
        setJobRoleLabelClass('active_label');
        setFormErrors((oldValues) => ({ ...oldValues, job_role: null }));
        window.animate();
    }
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    const validateNewPassword = (password, blur) =>{

        var new_password_error = Object.assign({},newPasswordError);

        if(password.length>0){
            
            new_password_error = {...new_password_error, error: true};

            
            if(password.length >= 6){

                new_password_error = {...new_password_error, length: 1};
                
            } else {
                
                if(blur){
                    new_password_error = {...new_password_error, length: 2};
                } else {
                    if(new_password_error.length==0){
                        new_password_error = {...new_password_error, length: 0};
                    } else {
                        new_password_error = {...new_password_error, length: 2};
                    }
                }
            }
            const specialChars =/[@$!%*#?&]/;

            if(specialChars.test(password)){
                new_password_error = {...new_password_error, special: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, special: 2};
                }  else {
                    if(new_password_error.special==0){
                        new_password_error = {...new_password_error, special: 0};
                    } else {
                        new_password_error = {...new_password_error, special: 2};
                    }
                }
            }
            const numbers =/[0-9]/;

            if(numbers.test(password)){
                new_password_error = {...new_password_error, numbers: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, numbers: 2};
                } else {
                    if(new_password_error.numbers==0){
                        new_password_error = {...new_password_error, numbers: 0};
                    } else {
                        new_password_error = {...new_password_error, numbers: 2};
                    }
                }
            }
            const letters =/[a-zA-Z]/;

            if(letters.test(password)){
                new_password_error = {...new_password_error, letters: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, letters: 2};
                } else {
                    if(new_password_error.letters==0){
                        new_password_error = {...new_password_error, letters: 0};
                    } else {
                        new_password_error = {...new_password_error, letters: 2};
                    }
                }
            }
            setNewPasswordError(new_password_error);

        } else {

            var new_password_error = Object.assign({},{error:false, length:0, letters:0, numbers:0, special:0});

            setNewPasswordError({...new_password_error, error: false});
        }
    }

    return (
        <div className="login-page-wrapper">
            <Helmet>
                <title>Researcher Sign Up - User research and testing platform | UserQ</title>
                <meta
                    name="description"
                    content="Sign up to create a new UserQ account. Access user testing tools and get feedback from MENA-based testers."
                />
            </Helmet>
            {socialLoading &&
            <div className={'dashboard-filter-loader'}  style={{height:"100vh"}}>
                <LoadingIcon/>
            </div>
            }
            {!socialLoading &&
            <>
            <div className="researcher-registerheader login-page-header">
                <div className="container">
                    <div className='row align-items-center'>
                        <div className="col-xs-6 col-sm-6 login-hdr-left">
                            <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                                
                                <Logo theme={"black"} background={"white"} />
                            </a>
                        </div>
                        <div className="col-xs-6 col-sm-6 login-hdr-right text-right">      
                            <Button 
                                type={"primary"}
                                size={"medium"}
                                label={"Get paid to test"}
                                iconRight={
                                    <Icon colorClass="gray-50-svg" value={"forward-arrow"} size={"small"} />
                                }
                                tag="link"
                                href="/tester/sign-up"
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div className={`login-data-wrap researcher-register-wrap`}>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className="col-md-6">
                        <Text type={"h1"} cssClasses={"h3 mb-20"} fontWeight={"semi-bold-font"}>Create a <span className="themeclr">researcher</span> account</Text>
                            
                            <Text type={"body-text-3"} fontWeight={"medium-font"}>It's free to sign up, no credit card needed.</Text>  
                            
                            <div className="login-form-inner-data mt-40">
                                {errorMsg && (
                                    <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                        <div className="pro-lft-wrap">{errorMsg}</div>
                                        <div className="pro-right-wrap">
                                            <div className="delete-progress-row">
                                                <img
                                                    onClick={clearErrorMessage}
                                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="formbox">
                                        <Input 
                                            type="text"
                                            id="email"
                                            //name="email"
                                            value={formValues.email}
                                            onChange={set("email")}
                                            label={"Work email"}
                                            onBlur={()=>{validateFullForm(false, "email")}}
                                            onClear={()=>{setFormValues({...formValues,email:''})}}
                                            onFocus={()=>{ setFormErrors({...formErrors, email:null}) }}
                                            required={true}
                                            error={formErrors.email}
                                        />
                                        
                                    </div>    
                                    <div className="formbox">
                                        <Input 
                                            passwordField={true}
                                            type={"password"}
                                            id="pass_log_id"
                                            name="password"
                                            value={formValues.password}
                                            onChange={set("password")}
                                            /*onInput={(e)=>{
                                                validateNewPassword(e.target.value);
                                            }}*/
                                            onBlur={()=>{ validateFullForm(false,"password")}}
                                            onFocus={()=>{ setFormErrors({...formErrors, password:null}) }}
                                            required={true}
                                            label={"Create password"}
                                            clearField={false}
                                            newPasswordError={newPasswordError}
                                            error={formErrors.password}
                                            // passwordSuggestion={"Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)"}
                                        />
                                        
                                        {formErrors.password_error_class != 'none' &&
                                            <p
                                                className={
                                                    formErrors.password_error_class != null
                                                        ? "password-guide-text " +
                                                        formErrors.password_error_class
                                                        : "password-guide-text "
                                                }
                                                style={formErrors.passwordErrorStyle}
                                            >
                                                
                                            </p>
                                        }
                                    </div>
                                    <div className="account-term-and-condition-wrap">
                                        <div className="form-group resarcher-termcond custom-checkbox">
                                            <CheckBox checked={formValues.terms}
                                                onChange={(event) => { toggleCheck('terms', event.currentTarget.checked) }}
                                                id="termsCheckBox"
                                                label={
                                                    <>
                                                     I agree to the{" "}
                                                
                                                    <a
                                                        href={
                                                            process.env.REACT_APP_SITE_URL +
                                                            "terms-of-use"
                                                        }
                                                        target="_blank"
                                                    >
                                                        Terms of Use
                                                    </a>
                                                {" "}
                                                and have read the{" "}
                                               
                                                    <a
                                                        href={
                                                            process.env.REACT_APP_SITE_URL + "privacy-policy"
                                                        }
                                                        target="_blank"
                                                    >
                                                        Privacy Policy
                                                    </a>
                                               </>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="full createaccount-btnbox">
                                    <Button 
                                        label={"Create your account"}
                                        state={!validateBtnState()?"enabled":"disabled"}
                                        microLoading={isLoading}
                                        type={"primary"}
                                        size={"large"}
                                    />
                                    </div>
                                    
                                    <div className="full ordivider">
                                        <span>Or</span>
                                    </div>                                          
        
                                    <div className="full createaccount-googlebtn">
                                        <GoogleAuth 
                                            role="researcher" 
                                            fromSignup={true} 
                                            setLoading={(loading)=>{setSocialLoading(loading);}} 
                                            setErrorMessage={(message)=>{showError(message);}} 
                                            planType={signUpPlan} inviteCode={invite_code}
                                            alreadyLogin={()=>{ setAccountInUseModal({...accountInUseModal, open: true})}}
                                        />
                                    </div>
                                    <div className="login-bottom-link form-group resarcher-btn-register">
                                        Already have an account? <Link to="/researcher/sign-in">Sign in</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-6  login-right-side text-right'>
                            <img src={process.env.REACT_APP_URL+"/img/large-Sign-in.png"} />
                        </div>
                    </div>
                </div>
                <AccountInUseModal 
                    open={accountInUseModal.open}
                    close={()=>{
                        setAccountInUseModal({...accountInUseModal, open:false});
                    }}
                />
            </div>
            
            <Footer />
            </>
            }
        </div>
                    
    );
}