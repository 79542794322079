import { useEffect, useState, useRef } from 'react';
import { Button } from '../../../themes/userq/Button/Button.js';
import { Icon } from '../../../themes/userq/Icon/Icon.js';
import EditScreeningQuestionInput from '../../../themes/userq/Question/EditScreeningQuestionInput.js';
import { ReactSession } from "../../../../lib/secure_reactsession.js";
import { Text } from '../../../themes/userq/Text/Text';
import { Question } from "../../../themes/userq/Question/Question";
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import toast from "react-hot-toast";
import { saveIntroductionQuestions, saveScreeningQuestions } from "../../../../services/test";
import { ToastMessage } from '../../../themes/userq/ToastMessage/ToastMessage';
import EditQuestionInput from "../../../themes/userq/Question/EditQuestionInput";


import { ScreeningQuestionInput } from '../../../themes/userq/Question/ScreeningQuestionInput.js';
import { settings } from "../../../../data/settings.js";
import { ScreeningQuestion } from '../../../themes/userq/Question/ScreeningQuestion.js';

export default  function ScreeningQuestions({questionsProp=[], updateQuestions, test, setIsSaved, cleanFormErrors, questionErrors, onAddQuestion, onEditQuestion }){

    const [questions, setQuestions] = useState(questionsProp);

    const [addQuestion, setAddQuestion] = useState(false);

    const [edittingBriefingQuestions, setEdittingBriefingQuestions] =
        useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [language, setLanguage] = useState(test.language);
    const lang = useRef("en");
    const [dataSaving, setDataSaving]=useState(false);
    const [dataSavingEnabled, setDataSavingEnabled]=useState(false);

    let screenController = useRef();

    

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    

    const removequestion = (index) => {
        const list = [...questions];
        list.splice(index, 1);
        setQuestions(list);
        setEdittingBriefingQuestions(false);
        
    };
    
    const cancelEditQuestionHandler = () => {
        setEdittingBriefingQuestions(false);
        setEditQuestionId(null);
        onEditQuestion(null);
    };

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    const duplicatequestion = (index) => {
        if (!edittingBriefingQuestions) {
            if(questions.length<3){
                let list = [...questions];
                let question = Object.assign({},questions[index]);
                list.splice(index, 0, question);
                setQuestions(list);

                //scrolling to duplicated question

                var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight+document.querySelector('.three-column-layout-top-header').offsetHeight;
                var offsetTop = Position(document.getElementById("intro-"+(index)));
                console.log(offsetTop - fixedElementHeight);
                window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

            } else {
                showError("You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones.");
            }
        } else {
            showError("Finish editing question");
        }
    };
    const edittingQuestionsHandler = (index) => {
        if (!edittingBriefingQuestions) {
            setEdittingBriefingQuestions(true);
            setEditQuestionId(index);

            onEditQuestion(questions[index]);

        } else {
            showError("Finish editing earlier question");
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
        parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
             border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
             border:'1px dashed'
        });
    };

    const handleDragEnd = result => {
        setPlaceholderProps({});
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            questions,
            result.source.index,
            result.destination.index
        );

        setQuestions(items);
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const saveQuestions = () => {

        if (screenController.current) {
            screenController.current.abort();

        }
        const controller = new AbortController();

        screenController.current = controller;

        let token = ReactSession.get("token");
        var data = new FormData();

        data.append("test_id", test.id);

        updateQuestions(questions);

        questions.forEach((item) => {
            data.append("questions[]", JSON.stringify(item));
        });
        setIsSaved({'status':'loading','message':''});
            
        saveScreeningQuestions(data, token, screenController.current?.signal).then((response) => {
            if (response.success) {
                setIsSaved({'status':'success','message':''});
            } else {
                setIsSaved({'status':'error','message':response.message});
            }
        });
        
    };

    useEffect(() => {
        saveQuestions(true);

    }, [questions]);

    useEffect(()=>{
        setQuestions(questions);

    },[questionsProp])
    

    const renderAddQuestion = ()=>{
        
        if(!addQuestion){

            return <></>
        }
        return <div className={`${(test.language == "en") ? "" : "arabic_wrapper"}`}>
            <ScreeningQuestionInput
                onAddQuestion={(addQuestion)=>{
                    onAddQuestion(addQuestion);
                }}
                addQuestions={(question) => {
                    var questions_sub = [...questions];

                    questions_sub.push(question);

                    setQuestions(questions_sub);

                    setAddQuestion(false);

                    cleanFormErrors();

                    onAddQuestion(null);
                    setEdittingBriefingQuestions(false);
                }}
                cancelQuestion={()=>{
                    setAddQuestion(false);

                    onAddQuestion(null);

                    setEdittingBriefingQuestions(false);
                }}
                language={test.language}
                questionType={"singlechoice"}
                questionlist={questions}
                questionno={questions.length + 1}
                addQuestionErrors={questionErrors}
            />

        </div>
    }
    return (
        <>
           <div className='seceening-info-text mt-32'>
            <Text type="body-text-2" fontWeight="medium-font">Filter participants based on answers to initial questions. You'll only be charged for those that proceed to complete the test. Response times may be slower when using screening questions. You can add up to 3 screening questions.</Text>
            </div>
            <div className={`ans-list-wrap question-prev-wrap`}>
                {questions.length === 0 ? (
                    <div className="nodata-wrap"></div>
                ) : (
                    <div className="added-question-wrap mb-32" style={{ position: 'relative' }}>
                        <DragDropContext
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                            onDragUpdate={handleDragUpdate}
                        >
                            <Droppable droppableId="droppableintro">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {questions.map((question, index) => (
                                            <div key={index}
                                                className="survey-added-question-repeat"
                                                
                                            >
                                                <Draggable
                                                    draggableId={"draggableintro-" + index}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={
                                                                snapshot.isDragging
                                                                    ? "question-answers-dragging draggable-element-start"
                                                                    : ""
                                                            }
                                                        >
                                                            {editQuestionId === index ? (
                                                                <></>
                                                            ) : (
                                                                <div
                                                                    key={index}
                                                                    className={`survey-question-preview ${snapshot.isDragging?'draggable-inner-element-start':'grey-bg'}`}
                                                                >
                                                                    <div
                                                                        className={`question-answer-holder`}>
                                                                        <div {...provided.dragHandleProps}>
                                                                            <ScreeningQuestion 
                                                                                index={index} 
                                                                                question={question} 
                                                                                onCopy={()=>duplicatequestion(index)} 
                                                                                onDelete={()=>removequestion(index) } 
                                                                                onEdit={()=>edittingQuestionsHandler(index)} 
                                                                                language={test.language}
                                                                                saveScreening={(index, question)=>{
                                                                                    var questions_loop = [...questions];

                                                                                    questions_loop[index] = question;

                                                                                    setQuestions(questions_loop);
                                                                                }}  
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div id={"intro-"+index}></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        );
                                                    }}
                                                </Draggable>
                                                <div className="question-edit">
                                                    {edittingBriefingQuestions &&
                                                        editQuestionId === index && (
                                                            <EditScreeningQuestionInput
                                                                questionDetails={
                                                                    questions[index]
                                                                }
                                                                cancelEditQuestion={
                                                                    cancelEditQuestionHandler
                                                                }
                                                                editQuestions={(question) => {
                                                                    questions.splice(
                                                                        index,
                                                                        1,
                                                                        question
                                                                    );
                                                                    setQuestions([
                                                                        ...questions,
                                                                    ]);
                                                                }}
                                                                language={test.language}
                                                                questionno={index + 1}
                                                                questionlist={questions}
                                                                onDelete={()=>removequestion(index) }
                                                                onEditQuestion={onEditQuestion}
                                                                editQuestionErrors={questionErrors}
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        ))}
                                        {provided.placeholder}
                                        {!isEmpty(placeholderProps) && (
                                            <div
                                                className="placeholder"
                                                style={{
                                                    top: placeholderProps.clientY,
                                                    left: placeholderProps.clientX,
                                                    height: placeholderProps.clientHeight,
                                                    width: placeholderProps.clientWidth,
                                                    position: "absolute",
                                                    borderColor: "#000000",
                                                    background:placeholderProps.background,
                                                    borderRadius:'10px',
                                                    border:placeholderProps.border,
                                                    margin: '0px 0px 10px'
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                )}
            
                {renderAddQuestion()}
                {!addQuestion && questions.length>=0 && questions.length<3 &&
                <Button
                    type="ghost"
                    label="Add screening question"
                    iconLeft={<Icon value="add" />}
                    size={"medium"}
                    onClick={()=>{
                        onAddQuestion({question:"", options:["",""], screeningOptions:["Good fit (continue)","Not a fit (end test)"]});
                        setAddQuestion(true);
                        setEdittingBriefingQuestions(true);
                        cleanFormErrors();
                    }}
                />
                }
            </div>
        </>
    )
    
}