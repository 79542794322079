import { Text } from "../../../themes/userq/Text/Text";
import PieChart from "./pie_chart_v2";
import { theme } from "../../../themes/userq/theme";
import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const NoParticipants = ({ small }) => {

    const slides = [{ "title": "Sharing research findings", 
    "subtitle": "87% of researchers share their research results in live presentations" }, 
    { "title": "Test your IA", "subtitle": "71% of publishers say content that looks well-organized on mobile devices boosts positive user experience feedback." },
     { "title": "Preference test your designs", "subtitle": "3.42 seconds is the time needed for users to judge a web site’s credibility merely on the basis of its aesthetic appeal" }]


    return <div className="no-participants-data">
        <div className="no-page-data-wrap">
            <div className="no-data-icon">
                <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
            </div>

            <Text type={"subtitle-1"} fontWeight="medium-font">Participants details are not available at the moment as the test didn’t receive any response yet. Please come back later.</Text>
        </div>
        {!small &&
        <div className="did-you-know-slider">
            <div className="row">
                <div className="col-lg-6">
                    <div className="did-you-know-left-box">
                        <Box size={"large"}>

                            <Text type={"h1"}>
                                Did you know?
                            </Text>

                            <Text type={"body-text-2"} fontWeight={"medium-font"}>
                                While you are waiting for your participants to take the test, we would like to share some facts about UserQ
                            </Text>

                        </Box> 
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className="did-you-know-slider-hold">
                        <Box size={"large"}>

                            <OwlCarousel
                                className="owl-theme"
                                loop={false}
                                dots={true}
                                margin={0}
                                nav
                                mouseDrag={true}
                                touchDrag={true}
                                responsiveclassName={true}
                                responsive={{
                                    0: { items: 1 },
                                    600: { items: 1 },
                                    1000: { items: 1 },
                                    1600: { items: 1 }
                                }}
                            >
                                {slides.map((item, index) => {
                                    return <div key={"slide" + index} className={`did-you-know-slide`}>
                                        <Text type={"h1"}>
                                            {item.title}
                                        </Text>
                                        <Text type={"subtitle-1"} fontWeight="medium-font">
                                            {item.subtitle}
                                        </Text>
                                    </div>
                                })}
                            </OwlCarousel>
                        </Box>
                    </div>
                </div>
            </div>
        </div>

        }
    </div>;
}