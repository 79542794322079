import { useD3 } from '../../../../hooks/useD3';
import React from 'react';
import * as d3 from 'd3';

function AreaChart({ data=[{ x:0, y:10 },
    { x: 1, y: 15, },
    { x: 2, y: 35, },
    { x: 3, y: 20, }], lineData,pwidth }) {
  
  
  const ref = useD3(
    (svg) => {
      svg.selectAll('*').remove();

        var margin = {top: 0, right: 30, bottom: 50, left: 30},
            width =  pwidth - margin.left - margin.right,
            height = 350 - margin.top - margin.bottom;
        
        var x = d3.scaleLinear()
            
            .domain([d3.min(data, function(d) { return d.x; }), d3.max(data, function(d) { return d.x; })])
            .range([0, width ]);

        //.domain([0, d3.max(data, function(d) { return d.y; })])
        var y = d3.scaleLinear()
            .domain([0, d3.max(data, function(d) { return d.y; })])
            .range([height, 0]);

        var values = [];

        lineData.forEach(function(item){
          values.push(item[0].x);
        })
            
        var xAxis = d3.axisBottom()
            .scale(x)
            .tickValues(values)
            .tickFormat(function(d) { return d3.format(",.0f")(d) + " $" });

            
        
        //var yAxis = d3.axisLeft()
          //  .scale(y);

        //console.log(lineData)
        
        var area = d3.area()
        
            .x(function(d) { console.log(d); return x(d.x); })
            .y0(height)
            .y1(function(d) { return y(d.y); }).curve(d3.curveMonotoneX) ;

         var line = d3.line()
        
            .x(function(d) { return x(d.x); })
            .y(function(d) { return y(d.y0); }).curve(d3.curveMonotoneX);

        
        
        var svg = d3.select(".plot-area-income")
            .attr("width", pwidth )
            .attr("height", height)
          .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
        svg.append("path")
            .datum(data)
            .attr("fill", 'rgb(243, 158, 204)')
            .attr("class", "area")
            .attr("d", area);
        lineData.forEach(function(data,index){
          svg.append("path")
            .datum(data)
            .attr("stroke", 'rgb(256, 256, 256)')
            .attr("stroke-width", 0.6)
            .attr("stroke-dasharray","5,5")
            .attr("class", "line")
            .attr("d", line);

          

        })

        for(var index=0; index<lineData.length; index++){
       
          if(index<3){
            svg.append("text")
              .attr("x", function() { return x(lineData[index][0].x) + (x(lineData[index+1][0].x) - x(lineData[index][0].x))/2 ; })
              .attr("y",  function() { return y(lineData[index][1].y/2); })
              .attr("text-anchor",  "middle")
              .text(lineData[index][0].label);
          } else if(index>3){
            svg.append("text")
              .attr("x", function() { return x(lineData[index][0].x) - (x(lineData[index][0].x) - x(lineData[index-1][0].x))/2 ; })
              .attr("y",  function() { return y(lineData[index][1].y/2); })
              .attr("text-anchor",  "middle")
              .text(lineData[index][0].label);
          }
          
        
        }
        svg.append("g")
            .attr("class", "x axis")
            .style("font-size", "12px")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);
        
       /* svg.append("g")
            .attr("class", "y axis")
            .call(yAxis);*/
    },
    [data]
  );

  return (
    <svg
      ref={ref}
      style={{
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
      className="plot-area-income"
    >
    </svg>
  );
}

export default AreaChart;