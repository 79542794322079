import React, { useState } from "react";
import { Input } from "../../themes/userq/Input/Input";
import { Text } from "../../themes/userq/Text/Text";
const PromoCode = ({ discount, section, formValues, formErrors, coupon_code, couponError, coupon_code_desc, couponLoading, applyCoupon, removeCoupon, setFormValues }) => {
    const [expand, setExpand] = useState(false);

    const checkValidState = ()=>{
        if(couponError!=""){
            return "No";
        } else {
            if(discount>0){
                return "Yes";
            }
        }
        return "None";
    }
    const checkReadOnlyState = ()=>{
        if(couponError!=""){
            return true;
        } else {
            if(discount>0){
                return true;
            }
        }
        return false;
    }
    if(section){
        return (
            <div className="pay-on-fly-coupon-code-checkout-wrap mb-32">
                <div className="promo-code-inner">
                        {/*discount == 0 &&*/
                            <>
                                <div className="promocode-field-wrap row" id={discount>0?"r_promo_code_applied":"r_promo_code_apply"}>
                                    <div className="col-md-7">
                                        {console.log(formValues.promo_code)}
                                        <Input
                                            label="Promo Code"
                                            onChange={(e) => { setFormValues({ ...formValues, 'promo_code': e.target.value }) }}
                                            onClear={() => {
                                                setTimeout(() => {
                                                    setFormValues({
                                                        ...formValues,
                                                        promo_code: ""
                                                    })
                                                }, 1000);
                                            }}
                                            value={formValues.promo_code}
                                            id="promo_code"
                                            error={formErrors.promo_code}
                                            error_class={formErrors.error_class}
                                            clearField={false}
                                            readOnly={checkReadOnlyState()}
                                            isValid={checkValidState()}
                                            required={true}
                                            focussed={false}
                                        />
                                        
                                    </div>
                                    
                                    {discount== 0 && !couponError &&
                                    <div className="apply-promo-code-btn">
                                        <button
                                            type="button"
                                            className={"secondary-btn "+(!formValues.promo_code?"btn-disabled":"active")}
                                            onClick={() => {
                                                if(formValues.promo_code){

                                                
                                                    applyCoupon();
                                                }
                                            }}
                                            id="r_promo_code"
                                        >
                                            Apply
                                            {couponLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                    }
                                    {discount > 0 &&
                                    <div className="remove-promo-code-btn">
                                        <button
                                            type="button"
                                            className="secondary-btn"
                                            onClick={() => {
                                                removeCoupon();
                                            }}
                                            id="r_promo_code"
                                        >
                                            Remove
                                            {couponLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                    }
                                    {couponError!="" &&
                                    <div className="remove-promo-code-btn">
                                        <button
                                            type="button"
                                            className="secondary-btn"
                                            onClick={() => {
                                                removeCoupon();
                                            }}
                                            id="r_promo_code"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                    }
                                    {couponError!='' &&
                                            <div className="coupon-code-error">
                                            {couponError}
                                            </div>
                                        }
                                    {discount > 0 &&
                                    <div className={"promocode_description"}><p>{coupon_code_desc}</p></div>
                                    }
                                </div>
                            </>

                        }
                </div>
            </div>
        );
    }

    return (
        <div className="pay-on-fly-coupon-code-wrap">
            <div className="promo-code-inner">
                <div className="pay-on-fly-promo-code-hdr" onClick={() => { setExpand(!expand) }}>
                    <Text type={'subtitle-2'} fontWeight={'medium-font'}>Promo Code</Text>
                    <div className="pay-promo-close">
                        {!expand &&
                            <span >
                                <img
                                    src={process.env.REACT_APP_URL + "img/chevron.svg"}
                                    alt="icon" />
                            </span>
                        }
                        {expand &&
                            <span >
                                <img
                                    src={process.env.REACT_APP_URL + "img/chevron-up.svg"}
                                    alt="icon" />
                            </span>
                        }
                    </div>
                </div>

                {expand &&
                    <div className="promocode-inner-form-wrap">
                        {/*discount == 0 &&*/
                            <>
                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Enter your promotional code here. Click “Apply” to update your order</Text>
                                <div className="promocode-field-wrap row" id={discount>0?"r_promo_code_applied":"r_promo_code_apply"}>
                                    <div className="col-md-7">
                                        <Input
                                            label="Code"
                                            onChange={(e) => { setFormValues({ ...formValues, 'promo_code': e.target.value }) }}
                                            onClear={() => {
                                                setTimeout(() => {
                                                    setFormValues({
                                                        ...formValues,
                                                        promo_code: ""
                                                    })
                                                }, 1000);
                                            }}
                                            value={formValues.promo_code}
                                            id="promo_code"
                                            error={formErrors.promo_code}
                                            error_class={formErrors.error_class}
                                            clearField={false}
                                            readOnly={checkReadOnlyState()}
                                            isValid={checkValidState()}
                                            required={true}
                                        />
                                        
                                    </div>
                                    
                                    {discount== 0 && !couponError &&
                                    <div className="apply-promo-code-btn">
                                        <button
                                            type="button"
                                            className="secondary-btn secondary-large"
                                            onClick={() => {
                                                applyCoupon();
                                            }}
                                            id="r_promo_code"
                                        >
                                            Apply
                                            {couponLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                    }
                                    {discount > 0 &&
                                    <div className="remove-promo-code-btn">
                                        <button
                                            type="button"
                                            className="secondary-btn secondary-large"
                                            onClick={() => {
                                                removeCoupon();
                                            }}
                                            id="r_promo_code"
                                        >
                                            Remove
                                            {couponLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                    }
                                    {couponError!="" &&
                                    <div className="remove-promo-code-btn">
                                        <button
                                            type="button"
                                            className="secondary-btn secondary-large"
                                            onClick={() => {
                                                removeCoupon();
                                            }}
                                            id="r_promo_code"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                    }
                                    {couponError!='' &&
                                         <div className="coupon-code-error">
                                         {couponError}
                                         </div>
                                        }
                                    {discount > 0 &&
                                    <div className={"promocode_description"}><p>{coupon_code_desc}</p></div>
                                    }
                                </div>
                            </>

                        }

                        {/*discount > 0 &&
                            <div id="r_promo_code_applied" className="applied-promo-code-wrap">
                                <>
                                    <div className="promo-code-applied-wrap">
                                        <p className="promocode-text" id="r_promo_code_applied">
                                            Promo Code applied : <span className="green-color-txt">{coupon_code}</span>
                                        </p>

                                        <span className="remove-promocode-icon">
                                            <img
                                                src={process.env.REACT_APP_URL + "img/delete.svg"}
                                                alt="trash-icon" onClick={() => {
                                                    removeCoupon();
                                                }}
                                            />
                                        </span>
                                    </div>
                                    <div className={"promocode_description"}><p>{coupon_code_desc}</p></div>
                                </>
                            </div>
                        */}

                    </div>
                }
            </div>
        </div>
    )
};
export default PromoCode;