//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Input } from "../../../themes/userq/Input/Input";
import "./delete-workspace-modal.css";
export default function DeleteWorkspaceProjectModal({
    open,
    close,
    total_tests,
    total_members,
    project_id,
    confirm,
    microloading,
}) {
    //console.log(test);
    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    const [formValues, setFormValues] = useState({
        signature: "",
    });

    const [formErrors, setFormErrors] = useState({
        error_class: null,
    });

    const closeModal = (send = false) => {
        if (send) {
            close(true, { ...formValues });
        } else {
            close(false, null);
        }
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const deleteProject = () => {
        
        if (total_tests >= 1 ) {
            if(formValues.signature){
                confirm(project_id, formValues.signature);
            }
        } else {
            confirm(project_id);
        }
    };
    useEffect(() => {
        if (open) {
            setFormValues({ ...formValues, signature: "" });
        }
    }, [open]);

    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap delete-workspace-modal`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={"h3"} cssClasses={"red-text"}>
                            Delete project
                        </Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                        </button>
                    </div>

                    {total_tests === 0 && total_members === 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                cssClasses={"gray-color"}
                                fontWeight="medium-font"
                            >
                                Are you sure you want to delete this project?
                                All tests within this project will be
                                permanently deleted.
                            </Text>
                        </div>
                    )}
                    {total_tests === 0 && total_members > 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                cssClasses={"gray-color"}
                                fontWeight="medium-font"
                            >
                                Are you sure you want to delete this project?
                                Once deleted, no member will be able to access
                                the project or the tests it contains. All tests
                                within this project will also be permanently
                                removed.
                            </Text>
                        </div>
                    )}
                    {total_tests >= 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                cssClasses={"gray-color"}
                                fontWeight="medium-font"
                            >
                                Are you sure you want to delete this project?
                                This action will permanently remove all tests
                                within the project. Please ensure you move any
                                tests you wish to keep to another project before
                                proceeding.
                            </Text>

                            <div className="mt-32">
                                <Text
                                    type="body-text-2"
                                    cssClasses={"gray-color"}
                                    fontWeight="medium-font"
                                >
                                    Kindly confirm by signing your full name:
                                </Text>
                                <div className="add-name-input mt-16">
                                    <Input
                                        cssClasses={"w-100"}
                                        type="text"
                                        label="Your full name"
                                        value={formValues.signature}
                                        onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                signature: e.target.value,
                                            });
                                        }}
                                        onClear={() => {
                                            setFormValues({
                                                ...formValues,
                                                signature: "",
                                            });
                                        }}
                                        // readOnly={btnLoading ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="confirm-buttons-wrap delete-project-modal">
                        <Button
                            type="primary"
                            size="large"
                            iconLeft={
                                <Icon
                                    value={"delete"}
                                    colorClass={"gray-50-svg"}
                                />
                            }
                            label="Delete project"
                            onClick={() => {
                                deleteProject();
                            }}
                            state={
                                total_tests >= 1
                                    ? !formValues.signature
                                        ? "disabled"
                                        : ""
                                    : ""
                            }
                            microLoading={microloading}
                        />
                        <Button
                            type="secondary"
                            size="large"
                            label={"Close"}
                            onClick={() => {
                                closeModal();
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
