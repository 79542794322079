import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./downgrade-workspace-modal.css";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
import moment from "moment/moment";
import { MultiSelecteDropdownList } from "../../../themes/userq/MultiSelecteDropdownList/MultiSelecteDropdownList";
import { Input } from "../../../themes/userq/Input/Input";
export default function ConfirmDowngradeWorkspaceModal({ btnLoading, confirmDowngradeAction, workspace,...props}) {
    const wrapperRef = useRef(null);
    
    const [downgradeReasons, setDowngradeReasons] = useState([]);

    const [downgradeReasonOther, setDowngradeReasonOther] = useState("");

    const [form_errors, setFormErrors] = useState({downgradeReasons:null, downgradeReasonOther:null});

    useEffect(() => {
        setDowngradeReasonOther("");

        setDowngradeReasons([]);

        setFormErrors({downgradeReasons:null, downgradeReasonOther:null});
    },[props.open]);

    const closeModal = () => {
        if (props.close) {
            props.close();
        }
    };
    const checkIfFormValid = () => {
        
        if(downgradeReasons.length == 0) {
            return false;
        }
        if(downgradeReasons.includes("Other") && !downgradeReasonOther){
            return false;
        }
        return true;
    }
    const validateForm = () => {

        var error = false;
        var formErrors = {downgradeReasons:null, downgradeReasonOther:null};

        if(downgradeReasons.length == 0) {
            formErrors = {...formErrors, downgradeReasons:"This field is required."};

            error = true;
        }
        if(downgradeReasons.includes("Other") && !downgradeReasonOther){
            formErrors = {...formErrors, downgradeReasonOther:"This field is required."};

            error = true;
        }
        if(error){
            setFormErrors(formErrors);
        }
        return error;
    }
    const confirmDowngrade = () => {
        var error = validateForm();

        
        if(!error){

            var reasons = [];

            if(downgradeReasons.includes("Other")){
                reasons.push(downgradeReasonOther);
            } else {
                reasons = downgradeReasons;
            }
            confirmDowngradeAction(reasons);
        }

    }
    return (
        <Modal
            show={props.open}
            onHide={props.close}
            centered
            size="lg"
            className="fade downgrad-workspace-modal"
        >
            <Modal.Body ref={wrapperRef}>
                <div className="downgrad-workspace-modal-data">
                    <div className="modal-header-top">
                        <Text type={"h3"}>Confirm downgrade</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="downgrad-modal-inner-data">
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"gray-color"}
                        >
                            Your Team plan is active until {workspace && workspace.nextPaymentDate?workspace.nextPaymentDate:"-"}. After
                            this date you will be downgraded to{" "}
                            <b className="black-color">Pay-As-You-Go</b> and
                            will no longer have access to the following
                            benefits:
                        </Text>

                        <div className="downgrade-options-checklist mt-20">
                            <ul>
                                <li className="d-flex align-items-center mb-8">
                                    <Icon
                                        value={"close"}
                                        colorClass={"danger-200-svg"}
                                    />
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-text"}
                                    >
                                        Access to workspace for team members
                                    </Text>
                                </li>
                                <li className="d-flex align-items-center mb-8">
                                    <Icon
                                        value={"close"}
                                        colorClass={"danger-200-svg"}
                                    />
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-text"}
                                    >
                                        All collaboration features
                                    </Text>
                                </li>
                                <li className="d-flex align-items-center">
                                    <Icon
                                        value={"close"}
                                        colorClass={"danger-200-svg"}
                                    />
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-text"}
                                    >
                                        Free test publishing
                                    </Text>
                                </li>
                            </ul>
                        </div>

                        <div className="downgrade-plan-reason mt-20">
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                            >
                                Please provide feedback on why you chose to
                                downgrade?
                            </Text>
                        </div>
                        <div className="downgrade-plan-reason mt-20">
                            <MultiSelecteDropdownList 
                                value={downgradeReasons}
                                label={"Select the reason"}
                                options={[
                                    {"label":"The plan is too expensive", "value": "The plan is too expensive"},
                                    {"label":"The plan doesn’t fulfill my team's needs", "value":"The plan doesn't fulfill my team's needs"},
                                    {"label":"I don’t work in team", "value":"I don't work in team"},
                                    {"label":"My research pipeline is not full","value":"My research pipeline is not full"},
                                    {"label":"Other", value: "Other"}
                                ]}
                                type={"text"}
                                onChange={(selected)=>{
                                    
                                    setFormErrors({...form_errors, downgradeReasons:null});
                                    if(selected.includes("Other")){
                                        setDowngradeReasons(["Other"]);
                                    } else{
                                        setDowngradeReasonOther("");
                                        setDowngradeReasons(selected);
                                    }
                                    
                                }}
                                error={form_errors.downgradeReasons}
                            />
                        </div>
                        {downgradeReasons.includes("Other") &&
                        <div className="downgrade-plan-reason mt-20">
                            <Input 
                                type="text"
                                label="Specify other"
                                value={downgradeReasonOther}
                                onChange={(e)=>{setFormErrors({...form_errors, downgradeReasonOther:null}); setDowngradeReasonOther(e.target.value);}}
                                onClear={(e)=>{setDowngradeReasonOther("");}}
                                error={form_errors.downgradeReasonOther}
                            />
                        </div>
                        }
                        <div className="confirm-buttons-wrap modal-button-wrap confirm-downgrade-btn">
                            <Button
                                type="secondary"
                                size={`large`}
                                label={"Confirm downgrade"}
                                state={!checkIfFormValid()?"disabled":"active"}
                                onClick={(e)=>{ confirmDowngrade()}}
                                microLoading={btnLoading}
                            />

                            <Button
                                type="primary"
                                size={`large`}
                                label={"Cancel"}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
