import React from 'react';
import { useState, useEffect } from "react";
import { LayoutTester } from '../themes/userq/Layouts/layout_tester';
import { Text } from '../themes/userq/Text/Text';
import { Icon } from '../themes/userq/Icon/Icon';
import SVG from 'react-inlinesvg';
import { LayoutResearcher } from '../themes/userq/Layouts/layout_researcher';
import { Link } from 'react-router-dom';


export default function ReportingGuidelines(props) {

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Reporting Guidelines";
    }, []);

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={""}
        >
            <div className='tester-guidelines-wrap researcher-guide-line-wrap'>
                <div className='r-guide-se-1'>
                    <Text type="h1" cssClasses={'text-left'}>Reporting Guidelines</Text>
                    <Text type="body-text-2" cssClasses={'gray-text'} fontWeight={"medium-font"}>At UserQ we prioritise the quality of participants  available on the platform. While we carefully select the best participants  from our panel, we understand that there may be instances where you are unsatisfied with the feedback provided by a specific participant. In such cases, we want to ensure that you only pay for high-quality results and not for subpar ones.
                    </Text>

                    <div className="guide-flag-wrap mt-32 mb-40">
                        
                        <div className="guide-flag-icon">
                           <Icon value="guide-flag" size={"extra-large"}></Icon> 
                        </div>

                        <div className="guide-flag-data">
                            <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black"}>Accordingly, we provide you with the ability to report any participant who fails to meet our quality guidelines or violates our Terms of Use. You will in turn receive a credit refund, ensuring that you do not pay for unproductive responses.</Text>
                        </div>

                    </div>
                </div>

                <div className='report-guide-section-2'>
                    <Text type="h4">Reporting participants </Text>
                    <Text type="body-text-2" fontWeight={"medium-font"} cssClasses={"mt-20"}> You can start reviewing participant responses and save the report in draft mode, until you’re ready to submit the request to our QA team. <b className='color-black'>You can only submit one request per test,</b> so make sure to do so only after receiving back all the responses you need for the test.
                    <br/>  <br/>

Once your request is approved by our QA team, the reported testers' responses will be hidden from your test report.</Text>
                </div>

                <div className='guide-reporting-criteria-wrap mt-32'>
                    <Text type={"subtitle-2"} fontWeight={"semi-bold-font"} cssClasses={"purple-color"}>Reporting Criteria</Text>
                    <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"mt-20"}>Here’s a list of instances for which you can report testers for on the UQ platform:</Text>
                
                    <div className='guide-criteria-text-repeat mt-20 mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Poor/incoherent responses</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>When a participant provides low-quality or incomprehensible feedback.</Text>
                    </div>

                    <div className='guide-criteria-text-repeat mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Off topic/guessed responses</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>When a participant gives responses that are unrelated to the question/task or it is clear that the responses are guessed, rather than with a clear understanding.</Text>
                    </div>

                    <div className='guide-criteria-text-repeat mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Identical responses</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>
                        Case 1: When a participant has provided the same response for 2 or more of the free text questions
                      </Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>
                       Case 2: When any 2 participants have submitted identical responses on a free text question.
                       </Text>
                    </div>

                    <div className='guide-criteria-text-repeat mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Rushed test completion</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>When it is evident that a participant has quickly completed the test without providing thorough feedback.</Text>
                    </div>

                    <div className='guide-criteria-text-repeat mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Skipped/given up on majority of tasks</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>When a participant has skipped or given up on a significant number of tasks.</Text>
                    </div>

                    <div className='guide-criteria-text-repeat mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Profanity</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>When a participant has used inappropriate language in a response.</Text>
                    </div>

                    <div className='guide-criteria-text-repeat mb-20'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Derogatory remarks</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>When a participant has made disrespectful/ discriminatory remarks about individuals or groups in their response.</Text>
                    </div>


                    <div className='guide-criteria-text-repeat'>
                      <Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses={"color-black"}>Other</Text>
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>You can use this section to highlight any other violations you want to report that do not fit into the above categories.</Text>
                    
                      <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"mt-20"}>When reporting any of the above violations, please ensure to include clear, additional notes for our QA team to be able to clearly validate the claim and avoid any delays in the review process. </Text> 
                    </div>



                
                </div>

                <div className='guide-reporting-criteria-wrap mt-32'>
                  <Text type={"subtitle-2"} fontWeight={"semi-bold-font"} cssClasses={"purple-color"}>Reporting process</Text> 
                  <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mt-20 mb-20"}>You can follow the below steps when reporting a participant to our QA team:</Text>

                  <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-20"}>1- Download the xlsx report from the results page and analyse feedback from all test participants.</Text>
                  <img src={process.env.REACT_APP_URL+"img/guide-btn-group.svg"} className="guide-img" alt="img"/>
                  <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mt-40"}>2- Identify the participants you want to report. Please note that you can only report participants from the UserQ panel and not unknown participants who have accessed the test from shared links.</Text>

                  <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mt-40 mb-20"}>3- Return to the online results page and click on the 'Report Participants' button located in the top right corner. You can also select individual participants from each question/task showing on the test results page.</Text>
                  <img src={process.env.REACT_APP_URL+"img/guide-btn-group-1.svg"} className="guide-img" alt="img"/>
                
                  <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mt-40 mb-20"}>4- Select all the participants  you want to report and specify the violations they’ve committed. Include any additional notes for clarity and accuracy.</Text>

                  <img src={process.env.REACT_APP_URL+"img/guid-report-tester-img.svg"} className="guide-img" alt="img"/>
                  <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mt-40 mb-20"}>5- Submit the Report: When ready, submit your request. Note that you can only submit one request per test. Ensure you have received all responses before submitting the report to the QA team.</Text>
                  
                  <img src={process.env.REACT_APP_URL+"img/guid-report-submit.svg"} className="guide-img" alt="img"/>

                </div>

                <div className='guide-review-process-wrap mt-32'>
                  <Text type={"h4"} cssClasses={"mb-20 d-block"}>Review process & credit refunds</Text>
                  <Text type={"body-text-2"} fontWeight={"medium-font"}>After your report is submitted, our QA team will review each request to approve or decline them, based on our guidelines.
If the request is approved, we will issue you a refund of credits that will be added back to your wallet, as compensation and the participant’s responses will be hidden from your test results.<br/>
Once the review process has been completed by the QA team, you will receive an email notification regarding the status of your request. You can also visit the results page of the test to view the full list of approved or declined requests.
</Text>

<Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses="mt-20">
Once your request is approved, a refund of credits will be added back to your wallet, which can be used towards your next study.</Text>

<div className='mt-32 '>               
<Text type={"h4"}>Participant Quality Measures</Text>

  <div className='quality-guide-repeat mt-20 mb-20'>
      <div className='quality-guide-top-hd d-flex align-items-center'>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4802 4.4289C11.5224 4.32573 11.5945 4.23748 11.6871 4.17536C11.7797 4.11325 11.8886 4.08008 12.0002 4.08008C12.1117 4.08008 12.2206 4.11325 12.3132 4.17536C12.4058 4.23748 12.4779 4.32573 12.5202 4.4289L14.6452 9.5399C14.6849 9.63554 14.7503 9.71835 14.8341 9.77921C14.9179 9.84008 15.0169 9.87665 15.1202 9.8849L20.6382 10.3269C21.1372 10.3669 21.3392 10.9899 20.9592 11.3149L16.7551 14.9169C16.6766 14.9841 16.6181 15.0716 16.586 15.1699C16.5539 15.2681 16.5494 15.3733 16.5732 15.4739L17.8582 20.8589C17.884 20.9669 17.8772 21.0802 17.8387 21.1844C17.8002 21.2886 17.7317 21.379 17.6418 21.4443C17.5519 21.5096 17.4447 21.5468 17.3337 21.5511C17.2227 21.5555 17.1129 21.5269 17.0182 21.4689L12.2932 18.5839C12.2049 18.5301 12.1035 18.5017 12.0002 18.5017C11.8968 18.5017 11.7954 18.5301 11.7072 18.5839L6.98215 21.4699C6.88741 21.5279 6.7776 21.5565 6.66661 21.5521C6.55561 21.5478 6.4484 21.5106 6.35852 21.4453C6.26864 21.38 6.20011 21.2896 6.1616 21.1854C6.12309 21.0812 6.11632 20.9679 6.14215 20.8599L7.42715 15.4739C7.45098 15.3733 7.4466 15.2681 7.41449 15.1698C7.38238 15.0715 7.32379 14.984 7.24515 14.9169L3.04115 11.3149C2.95696 11.2425 2.89606 11.1468 2.8661 11.0399C2.83614 10.933 2.83846 10.8196 2.87277 10.714C2.90707 10.6085 2.97184 10.5154 3.05892 10.4465C3.14599 10.3776 3.25149 10.336 3.36215 10.3269L8.88015 9.8849C8.9834 9.87665 9.08237 9.84008 9.16618 9.77921C9.24998 9.71835 9.31537 9.63554 9.35515 9.5399L11.4802 4.4299V4.4289Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<Text type={"body-text-1"} fontWeight={"medium-font"} cssClasses={'purple-color'}>Score system</Text>
      </div>

      <Text type={"body-text-2"} fontWeight={"medium-font"}>One of our key initiatives is a comprehensive participant Scoring System. Every participant is assigned a score between 0% to 100% that changes over time, based on every contribution on tests - enabling us to prioritise test access to participants  with high scores. To ensure high quality of the test results, participants  with a score lower than 50% are excluded from participating in future tests.</Text>
  </div>

  <div className='quality-guide-repeat mb-20'>
      <div className='quality-guide-top-hd d-flex align-items-center'>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 3.92969V5.42969M3 5.42969L5.77 4.73669C7.8544 4.21573 10.0564 4.45764 11.978 5.41869L12.086 5.47269C13.9688 6.41399 16.1219 6.66558 18.171 6.18369L21.281 5.45169C20.9029 8.94123 20.9046 12.4615 21.286 15.9507L18.172 16.6827C16.1227 17.1651 13.9692 16.9139 12.086 15.9727L11.978 15.9187C10.0564 14.9576 7.8544 14.7157 5.77 15.2367L3 15.9297M3 5.42969V15.9297M3 21.9297V15.9297" stroke="#FF6DB1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<Text type={"body-text-1"} fontWeight={"medium-font"} cssClasses={'pink-color'}>Report a participant</Text>
      </div>

      <Text type={"body-text-2"} fontWeight={"medium-font"}>We’ve also empowered researchers by giving you the ability to report any participants  that fail to meet quality standards or violate our Terms of Use. Each report is reviewed by our QA team and issued a refund, so that researchers only pay for those that are acceptable and of good quality standards.</Text>
  </div>


  <div className='quality-guide-repeat mb-20'>
      <div className='quality-guide-top-hd d-flex align-items-center'>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 9.92969H18.75M15 12.9297H18.75M15 15.9297H18.75M4.5 20.4297H19.5C20.0967 20.4297 20.669 20.1926 21.091 19.7707C21.5129 19.3487 21.75 18.7764 21.75 18.1797V7.67969C21.75 7.08295 21.5129 6.51065 21.091 6.0887C20.669 5.66674 20.0967 5.42969 19.5 5.42969H4.5C3.90326 5.42969 3.33097 5.66674 2.90901 6.0887C2.48705 6.51065 2.25 7.08295 2.25 7.67969V18.1797C2.25 18.7764 2.48705 19.3487 2.90901 19.7707C3.33097 20.1926 3.90326 20.4297 4.5 20.4297ZM10.5 10.3047C10.5 10.5509 10.4515 10.7947 10.3573 11.0222C10.263 11.2497 10.1249 11.4564 9.95083 11.6305C9.77672 11.8046 9.57002 11.9427 9.34253 12.037C9.11505 12.1312 8.87123 12.1797 8.625 12.1797C8.37877 12.1797 8.13495 12.1312 7.90747 12.037C7.67998 11.9427 7.47328 11.8046 7.29917 11.6305C7.12506 11.4564 6.98695 11.2497 6.89273 11.0222C6.7985 10.7947 6.75 10.5509 6.75 10.3047C6.75 9.80741 6.94754 9.33049 7.29917 8.97886C7.65081 8.62723 8.12772 8.42969 8.625 8.42969C9.12228 8.42969 9.59919 8.62723 9.95082 8.97886C10.3025 9.33049 10.5 9.80741 10.5 10.3047ZM11.794 16.6407C10.8183 17.1604 9.72947 17.4314 8.624 17.4297C7.5192 17.4311 6.4311 17.1601 5.456 16.6407C5.69429 15.9919 6.12594 15.4319 6.69267 15.0364C7.25939 14.6408 7.93387 14.4287 8.625 14.4287C9.31613 14.4287 9.99061 14.6408 10.5573 15.0364C11.1241 15.4319 11.5557 15.9919 11.794 16.6407Z" stroke="#D7A56A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


<Text type={"body-text-1"} fontWeight={"medium-font"} cssClasses={'gold-color'}>Participant profile accuracy</Text>
      </div>

      <Text type={"body-text-2"} fontWeight={"medium-font"}>We also carry out regular checks on our participant profiles to ensure that the information provided is accurate and up to date. Any discrepancies are reported by our QA team until the issue is resolved.</Text>
      <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"mt-20"}>If you have any questions or concerns, please use our <a target={"_blank"} href={process.env.REACT_APP_URL+"r/support/contact"} className="link-text">contact form</a>
      &nbsp;to get in touch with the support team. </Text>
  </div>


</div>               

</div>

            </div>
        </LayoutResearcher>
    )
}