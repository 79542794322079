import { TrendingUp } from "@mui/icons-material";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { showError } from "../../../../lib/helpers";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { savePrototypeTestTaskService, resyncPrototypeTaskService, unsyncPrototypeTestTaskService, syncPrototypeLinkService, updatePrototypeTestTaskGoalScreenService, getPrototypeTestTaskQuestionsService, validateFigmaTokenService, connectWithFigmaService } from "../../../../services/test";
import { Button } from "../../../themes/userq/Button/Button";
import FigmaAuth, { openFigmaAuthWindow } from "../../../themes/userq/FigmaAuth/figma_auth";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Input } from "../../../themes/userq/Input/Input";
import { Text } from "../../../themes/userq/Text/Text";
import { DefineFlow } from "./define_flow";
import FollowupQuestions from "./followup_questions";
import PreviewPrototype from "./preview_prototype";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import ConfirmationModal from "../../../themes/userq/Modal/ConfirmationModal";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";


const Task = React.forwardRef(({language, overrideErrors, updateTaskAndDontAsk, opentestSettingModal, dont_ask_again, openHelpModal, changeOrder, total_tasks,taskErrors, index, task, test, test_id, objective, updateTask, setIsSaved, setConfirmModal, setStepperError}, ref)=>{



    const [formValues, setFormValues] = useState({prototype_link: task.prototype_link  ? task.prototype_link :'', description: task.description  ? task.description :'' });

    const [formErrors, setFormErrors] = useState({prototype_link: null});

    const [syncLoading, setSyncLoading] = useState(false);

    const [unsyncLoading, setUnsyncLoading] = useState(false);

    const [resyncLoading, setResyncLoading] = useState(false);

    const [previewPrototypeModal, setPreviewPrototypeModal] =useState({open:false});

    const [followupQuestions, setFollowupQuestions] = useState(task.questions);

    const [followupLoading, setFollowupLoading] = useState(true);

    const [unsyncConfirmModal, setUnsyncConfirmModal] =useState({open:false});

    const [resyncConfirmModal, setResyncConfirmModal] =useState({open:false});

    var taskTakingLongTime = null;

    const [showMessageTakingLong, setShowMessageTakingLong] = useState(false);

    const [showMockupMessage, setShowMockupMessage] = useState(false);

    var servicetype = "";

    var dont_ask_again_func = false;

    var goal_node_id = null;

    const followupRef = useRef(null);

    const [tempAddQuestion, setTempAddQuestion] = useState({
        question: "",
        is_optional: false,
        is_logic: false,
        jump_to: "End Survey",
        options:[]
    });

    useImperativeHandle(ref, () => { return{
        followupRef: followupRef.current
    }},[tempAddQuestion]);

    useEffect(()=>{
        //getFollowupQuestions();

        //console.log(task.questions)

        setFormValues({...formValues,prototype_link: task.prototype_link  ? task.prototype_link :'', description: task.description  ? task.description :''})

        setFollowupQuestions(task.questions);
        //task
    },[task]);

    useEffect(()=>{

        var form_errors = overrideErrors ? {...taskErrors} : {...formErrors} ;


        if(taskErrors.prototype_link){

            form_errors = {...form_errors, prototype_link:taskErrors.prototype_link};

        }
        else if(taskErrors.prototype_link_sync){

            form_errors = {...form_errors, prototype_link_sync:taskErrors.prototype_link_sync};

        }
        else if(taskErrors.description  && !formErrors.description){

            form_errors = {...form_errors, description:taskErrors.description};

        }
        else if(taskErrors.goal_screen){

            form_errors = {...form_errors, goal_screen:taskErrors.goal_screen};

        }
        else if(taskErrors.questions){

            form_errors = {...form_errors, questions:taskErrors.questions};

        }
        setFormErrors(form_errors);

    },[taskErrors]);


    const getFollowupQuestions = ()=>{

        setFollowupLoading(true);

        var data={task_id: task.id, test_id:test_id};


        getPrototypeTestTaskQuestionsService(data, ReactSession.get("token")).then(
            (response) => {
                setFollowupLoading(false);





                if (response.success) {

                    setFollowupQuestions(response.followupQuestions)
                } else {

                    showError(response.message);
                }
            }
        );

    }
    const syncBtnState = ()=>{
        var reg = /^(?:https:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/?([^\?]+)?(.*))?$/

        var error = false;

        if(!reg.test(formValues.prototype_link)){

            error = true;
        } else {

            let paramString = formValues.prototype_link.split('?')[1];

            const queryParams = new URLSearchParams(paramString);



            /*if(!queryParams.get("node-id")){


                 error = true;
             }
             else*/ if(!queryParams.get("starting-point-node-id")){
                error = true;

            }


        }
        return error;
    }
    const validateFigmaLink = (link=null)=>{

        // setFormErrors({...formErrors, prototype_link:null});

        var link_test = link ? link : formValues.prototype_link;

        var reg = /^(?:https:\/\/)?(?:www\.)?figma\.com\/(proto)\/([0-9a-zA-Z]{22,128})(?:\/?([^\?]+)?(.*))?$/

        var error = false;

        if(!link_test){

            setFormErrors({...formErrors, prototype_link:"A figma prototype link is required"});

            error = true;
        } else if(!reg.test(link_test)){

            setFormErrors({...formErrors, prototype_link:"We couldn’t recognise that Figma link, please check it and try again"});

            error = true;
        } else {
            let paramString = link_test.split('?')[1];

            const queryParams = new URLSearchParams(paramString);

            /*if(!queryParams.get("node-id")){

                setFormErrors({...formErrors, prototype_link:"It must contain atleast one screen."});

                error = true;
            } 
            else*/ if(!queryParams.get("starting-point-node-id")){
                error = true;
                setFormErrors({...formErrors, prototype_link:"The prototype is missing a starting screen. Include a starting screen in Figma and share the updated prototype link."});
            } else {
                setFormErrors({...formErrors, prototype_link:null});
            }


        }
        if(!error){
            setStepperError(false);
        }
        return error;
    }
    const validateDescription = (description=null)=>{

        setFormErrors({...formErrors, description:null});

        var description_test = description ? description : formValues.description;

        var error = false;

        if(!description_test){

            if(objective=="closed"){
                setFormErrors({...formErrors, description:"A task description is required"});
            } else {
                setFormErrors({...formErrors, description:"An instruction is required"});
            }
            error = true;
        }
        if(!error){
            setStepperError(false);
        }
        return error;
    }

    
    const syncPrototype = ()=>{


        setSyncLoading(true);


        var pattern = /^(?:https:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/?([^\?]+)?(.*))?$/

        var matched = formValues.prototype_link.match(pattern);

        var url = new URL(formValues.prototype_link);

        var data={task_id: task.id, test_id:test_id, file_key:matched[2], node_id:url.searchParams.get("starting-point-node-id"),  prototype_link: formValues.prototype_link};

        taskTakingLongTime =  setTimeout(()=>{
            setShowMessageTakingLong(true);
        },30000)

        syncPrototypeLinkService(data, ReactSession.get("token")).then(
            (response) => {
                setSyncLoading(false);

                clearTimeout(taskTakingLongTime);

                setShowMessageTakingLong(false);

                if (response.success) {

                    if(response.big_prototype){
                        setFormErrors({...formErrors, prototype_link:response.big_prototype})
                    }  else {
                        if(response.mockup_info){
                            setShowMockupMessage(true);
                        }  else {
                            setShowMockupMessage(false);
                        }
                        updateTask(response.task, index);
                    }



                } else {
                    showError(response.message);
                }
            }
        );

    }
    const validateToken = ()=>{

        if(servicetype=="sync"){
            setSyncLoading(true);
        }
        var pattern = /^(?:https:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/?([^\?]+)?(.*))?$/

        var matched = formValues.prototype_link.match(pattern);

        var url = new URL(formValues.prototype_link);

        var data={task_id: task.id, test_id:test_id, file_key:matched[2], node_id:url.searchParams.get("starting-point-node-id"),  prototype_link: formValues.prototype_link};

        validateFigmaTokenService(data, ReactSession.get("token")).then(
            (response) => {


                if (response.success) {

                    if(response.token_state=="not_exist"){
                        setSyncLoading(false);
                        openFigmaAuthWindow(responseFigma);

                    } else {


                        if(servicetype=="sync"){
                            syncPrototype();
                        }

                    }
                } else {
                    setSyncLoading(false);

                    setResyncLoading(false);

                    showError(response.message);
                }
            }
        );

    }
    const validateTokenAfterSync = ()=>{

        if(servicetype=="resync"){
            setResyncLoading(true);
        } else if(servicetype=="set_goal_screen"){
            setSyncLoading(true);
        }
        
        var data={task_id: task.id, test_id:test_id};

        validateFigmaTokenService(data, ReactSession.get("token")).then(
            (response) => {

                
                if (response.success) {

                    if(response.token_state=="not_exist"){
                        if(servicetype=="resync"){
                            setResyncLoading(false);
                        } else if(servicetype=="set_goal_screen"){
                            setSyncLoading(false);
                        }
                        openFigmaAuthWindow(responseFigma);

                    } else {


                        if(servicetype=="resync"){
                            resyncTask(dont_ask_again_func);
                        } else if(servicetype=="set_goal_screen"){
                            updateGoalScreen(goal_node_id);
                        }

                    }
                } else {
                    setSyncLoading(false);
                    setResyncLoading(false);

                    showError(response.message);
                }
            }
        );

    }
    const responseFigma = (state, code)=>{

        setSyncLoading(false);
        if(servicetype=="sync"){
            setSyncLoading(true);
        } else if(servicetype=="resync"){
            setResyncLoading(false);
        }


        var data={state:state, test_id:test_id, code:code,redirect_uri:process.env.REACT_APP_URL+"figma/login"};

        connectWithFigmaService(data, ReactSession.get("token")).then(
            (response) => {


                if (response.success) {

                    if(servicetype=="resync"){
                        resyncTask(dont_ask_again);
                    } else if(servicetype=="sync"){
                        syncPrototype();
                    } else if(servicetype=="set_goal_screen"){
                        updateGoalScreen(goal_node_id);
                    }

                } else {
                    setSyncLoading(false);

                    setResyncLoading(false);

                    showError(response.message);
                }
            }
        );

    }
    
    const resyncTask = (dont_ask_again)=>{


        setResyncLoading(true);

        var data={task_id: task.id, test_id:test_id, dont_ask_again: dont_ask_again};

        taskTakingLongTime =  setTimeout(()=>{
            setShowMessageTakingLong(true);
        },30000)

        resyncPrototypeTaskService(data, ReactSession.get("token")).then(
            (response) => {
                setResyncLoading(false);


                clearTimeout(taskTakingLongTime);

                setShowMessageTakingLong(false);


                if (response.success) {

                    if(response.big_prototype){
                        setFormErrors({...formErrors, resync_error:response.big_prototype})
                    }  else {

                        if(response.mockup_info){
                            setShowMockupMessage(true);
                        } else {
                            setShowMockupMessage(false);
                        }
                        if(dont_ask_again){

                            updateTaskAndDontAsk(response.task, index);
                        } else {
                            updateTask(response.task, index);
                        }
                    }


                } else {
                    showError(response.message);
                }
            }
        );

    }
    const saveTask = (form_data=null)=>{



        var data={description:formValues.description, task_id: task.id, test_id:test_id, prototype_link: formValues.prototype_link };

        if(form_data){
            data.prototype_link = form_data.prototype_link;

            data.description = form_data.description;
        }
        setIsSaved({status:"loading",message:""})

        savePrototypeTestTaskService(data, ReactSession.get("token")).then(
            (response) => {
                //setSyncLoading(false);

                if (response.success) {

                    if(formErrors.description){
                        if(form_data && form_data.description){
                            setStepperError(false);
                        } else {
                            if(formValues.description){
                                setStepperError(false);
                            }
                        }
                    }

                    setIsSaved({status:"",message:""})
                } else {
                    setIsSaved({status:"error",message:response.message})
                    showError(response.message);
                }
            }
        );

    }
    const updateGoalScreen = (node_id)=>{

        if(!node_id){
            showError("Please wait while screen is loading.");
        } else {

            var data={node_id:node_id, task_id: task.id, test_id:test_id};

            setIsSaved({status:"loading",message:""})


            setSyncLoading(true);

            updatePrototypeTestTaskGoalScreenService(data, ReactSession.get("token")).then(
                (response) => {
                    setSyncLoading(false);

                    if (response.success) {

                        if(formErrors.goal_screen){
                            setStepperError(false);
                        }

                        setFormErrors({...formErrors, goal_screen:null})

                        updateTask(response.task, index)

                        setIsSaved({status:"",message:""})
                    } else {
                        setIsSaved({status:"error",message:response.message})
                        showError(response.message);
                    }
                }
            );
        }

    }
    const unsyncTask = ()=>{



        var data={task_id: task.id, test_id:test_id};

        setIsSaved({status:"loading",message:""});

        setUnsyncLoading(true);


        unsyncPrototypeTestTaskService(data, ReactSession.get("token")).then(
            (response) => {
                setUnsyncLoading(false);

                if (response.success) {



                    updateTask(response.task, index)

                    setIsSaved({status:"",message:""})
                } else {
                    setIsSaved({status:"error",message:response.message})
                    showError(response.message);
                }
            }
        );

    }

    return (
        <div ref={ref}>
            {task.is_synced==0 &&
            <div className="card-sorting-test-wrap prefer-design-wrap">

                <div className="cardsorting-wrap-top prototype-test-title d-flex justify-content-between align-items-center">
                    <div className="device-test-icon-wrap">
                        <div className="tooltip-deviceinfo none-for-mobile">
                            {test.device=="all" &&
                            <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"desktop-and-phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">All devices</span>
                            </span>
                            }
                            {test.device=="mobile" &&
                            <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">Mobile only</span>
                            </span>
                            }
                            {test.device=="desktop" && 
                             <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"desktop"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">Desktop only</span>
                            </span>
                            }
                            {test.device === 'all' ?
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible on {test.device} devices. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                                :
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible only on {test.device} screens. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                            }
                        </div>


                        <Text type={"subtitle-2"} fontWeight={"medium-font"}>{objective=="closed"?"Task":"Flow"} {index+1}</Text>
                        
                        <div className="tooltip-deviceinfo none-for-desktop">
                            {test.device=="all" &&
                            <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"desktop-and-phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">All devices</span>
                            </span>
                            }
                            {test.device=="mobile" &&
                            <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">Mobile only</span>
                            </span>
                            }
                            {test.device=="desktop" && 
                             <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"desktop"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">Desktop only</span>
                            </span>
                            }
                            {test.device === 'all' ?
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible on {test.device} devices. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                                :
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible only on {test.device} screens. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                            }
                        </div>

                    </div>
                    {total_tasks>1 &&
                    <div className="change-order-button-wrap">
                        <Button
                            type={"secondary"}
                            size="small"
                            onClick={() => {
                                if(setConfirmModal){
                                    setConfirmModal({ open: true, task_id: task.id });
                                }
                            }}

                            label="Delete"
                            iconLeft={<Icon value={"delete"} size="medium" />}
                        />
                        <Button
                            type={"secondary"}
                            size="small"
                            onClick={() => {
                                changeOrder();
                            }}
                            cssclass={"change-order-btn"}
                            label="Change order"
                            iconLeft={<Icon value={"sort-icon"} size="medium" />}
                        />
                    </div>
                    }

                </div>
                {syncLoading &&
                <div className="cardsorting-wrap-mid-data "> 

                    <div className={`task-prototype-test-sync-row d-flex ${test.device && test.device=="mobile" ? "mobile-preview-device":""}`}>
                        <div className={`task-prototype-test-sync-left`} >
                            <Skeleton  width={"100%"} height={120} />
                        </div>
                        <div className="task-prototype-test-sync-right">
                            <div className="prototype-test-task-figma-filename">
                                <Skeleton width={240} height={20} />
                                <Skeleton width={240} height={20} />
                            </div>
                            <div  className="prototype-test-task-btns-list">
                                <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton width={190} height={46}  borderRadius={56} styleProp={{display:"inline-flex", lineHeight:"25px", margin:"6px 10px 0px"}} /></div>
                                <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton  width={240} height={46}  borderRadius={56}  /></div>
                                <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton width={146} height={46}  borderRadius={56} styleProp={{display:"inline-flex", lineHeight:"25px", margin:"6px 10px 0px"}} /></div>

                            </div>

                        </div>

                    </div>
                    {showMessageTakingLong &&
                    <div className="info-message-wrapper mt-20">
                        <ToastMessage type="info" closable={false} message="This is taking longer than expected. Please wait while we upload your prototype." />
                    </div>
                    }
                    {showMockupMessage &&
                    <div className="info-message-wrapper mt-20">
                        <ToastMessage
                            type="info"
                            icon="no-mockup"
                            onClose={()=>{
                                setShowMockupMessage(false);
                            }}
                            closable={true}
                            message="Please disable the device mockup in your figma prototype to look at a better preview"
                        />
                    </div>
                    }
                </div>
                }
                {!syncLoading &&
                <div className="cardsorting-wrap-mid-data">
                    <div className="prototype-text-heading mb-32">
                        <div className="protolink-head-left">
                            <div className="prototype-icon-heading">
                                <Icon value={"figma_logo"} size={"medium"} />
                                <Text type={"subtitle-2"} fontWeight={"medium-font"}>Figma prototype</Text>
                            </div>
                            {objective=="closed"
                                ?
                                <Text type={"body-text-2"} fontWeight={"medium-font"}>Insert your Figma prototype link for this particular task</Text>
                                :
                                <Text type={"body-text-2"} fontWeight={"medium-font"}>Insert your Figma prototype link for this particular flow</Text>
                            }

                        </div>
                        <div className="protolink-head-right purple-color">
                            <a href="#" onClick={(e)=>{e.preventDefault(); openHelpModal()}}>Learn more about Figma prototype criteria</a>
                        </div>

                    </div>


                    <div className="formbox" id={"prototype_link_"+task.id}>
                        <Input type="text" label="Your figma prototype link" value={formValues.prototype_link}
                               onChange={(e)=>{
                                   setFormValues({...formValues, prototype_link:e.target.value});

                                   if(formErrors.prototype_link){
                                       validateFigmaLink(e.target.value)
                                   }
                                   updateTask({...task, prototype_link:e.target.value}, index)
                               }}
                               clearField={!syncLoading}
                               readOnly={syncLoading}
                               onClear={(e)=>{  updateTask({...task, prototype_link:''}, index); setFormValues({...formValues, prototype_link:''}); saveTask({...formValues, prototype_link:''});    }}
                               onBlur={(e)=>{
                                   /* saveTask();
                                    updateTask({...task, prototype_link:e.target.value}, index);
                                    if(formValues.prototype_link){
                                        validateFigmaLink(formValues.prototype_link);
                                    } else {
                                        setFormErrors({...formErrors, prototype_link:null})
                                    }*/
                                   updateTask({...task, prototype_link:e.target.value}, index);

                                   if(!validateFigmaLink(formValues.prototype_link)){

                                       saveTask();
                                   } else {
                                       saveTask({...formValues,prototype_link:""})
                                   }

                               }}
                               error={formErrors.prototype_link}
                        />
                    </div>
                    <div  className="formbox mb-0" id={"prototype_link_sync_"+task.id}>

                        <FigmaAuth
                            isLoading={syncLoading}
                            onClick={()=>{
                                if(!validateFigmaLink(formValues.prototype_link)){

                                    servicetype = "sync";

                                    validateToken();
                                    //openFigmaAuthWindow(responseFigma);
                                }
                            }}
                            state={syncBtnState()?"disabled":"enabled"}
                        />
                        {formErrors.prototype_link_sync &&
                        <span className="error red-text">{formErrors.prototype_link_sync}</span>
                        }
                    </div>

                </div>
                }
            </div>
            }
            {task.is_synced==1 &&
            <div className="card-sorting-test-wrap prefer-design-wrap">
                <div className="cardsorting-wrap-top  prototype-test-title d-flex justify-content-between align-items-center">
                    <div className="device-test-icon-wrap">
                        <div className="tooltip-deviceinfo  none-for-mobile">
                            {test.device=="all" &&
                             <span className="selected-divice-wrap d-flex align-items-center">
                             <Icon value={"desktop-and-phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                             <span className="none-for-desktop device-name">All devices</span>
                            </span>
                            }
                            {test.device=="mobile" &&
                             <span className="selected-divice-wrap d-flex align-items-center">
                             <Icon value={"phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                             <span className="none-for-desktop device-name">Mobile only</span>
                         </span>
                            }
                            {test.device=="desktop" &&
                             <span className="selected-divice-wrap d-flex align-items-center">
                             <Icon value={"desktop"} colorClass={'secondry-purple-200-svg'} size="medium" />
                             <span className="none-for-desktop device-name">Desktop only</span>
                            </span>
                            }
                            {test.device === 'all' ?
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible on {test.device} devices. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                                :
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible only on {test.device} screens. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                            }
                        </div>

                        <Text type={"subtitle-2"} fontWeight={"medium-font"}>{objective=="closed"?"Task":"Flow"} {index+1}</Text>

                        <div className="tooltip-deviceinfo  none-for-desktop">
                            {test.device=="all" &&
                             <span className="selected-divice-wrap d-flex align-items-center">
                             <Icon value={"desktop-and-phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                             <span className="none-for-desktop device-name">All devices</span>
                            </span>
                            }
                            {test.device=="mobile" &&
                             <span className="selected-divice-wrap d-flex align-items-center">
                             <Icon value={"phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                             <span className="none-for-desktop device-name">Mobile only</span>
                         </span>
                            }
                            {test.device=="desktop" &&
                             <span className="selected-divice-wrap d-flex align-items-center">
                             <Icon value={"desktop"} colorClass={'secondry-purple-200-svg'} size="medium" />
                             <span className="none-for-desktop device-name">Desktop only</span>
                            </span>
                            }
                            {test.device === 'all' ?
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible on {test.device} devices. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                                :
                                <Tooltip
                                    type={"bottom"}
                                    data={<>
                                        This test will be accessible only on {test.device} screens. If you would like to change
                                        your device preferences <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        opentestSettingModal()
                                    }}>click here</a>
                                    </>}
                                    className="tooltipChart"
                                />
                            }
                        </div>
                    </div>

                    {total_tasks>1 &&
                    <div className="change-order-button-wrap">
                        <Button
                            type={"secondary"}
                            size="small"
                            onClick={() => {
                                if(setConfirmModal){
                                    setConfirmModal({ open: true, task_id: task.id });
                                }
                            }}

                            label="Delete"
                            iconLeft={<Icon value={"delete"} size="medium" />}
                        />
                        <Button
                            type={"secondary"}
                            size="small"
                            onClick={() => {
                                changeOrder();
                            }}
                            cssclass={"change-order-btn"}
                            label="Change order"
                            iconLeft={<Icon value={"sort-icon"} size="medium" />}
                        />
                    </div>
                    }



                </div>
                <div className="cardsorting-wrap-mid-data ">

                    <div className={`task-prototype-test-sync-row d-flex ${test.device && test.device=="mobile" ? "mobile-preview-device":""}`}>
                        <div className={`task-prototype-test-sync-left`} >
                            {resyncLoading &&
                            <Skeleton  width={"100%"} height={120} />
                            }
                            {!resyncLoading &&
                            <div className="define-box-image-preview">
                                <div className="define-box-prew-inner">
                                    <img src={process.env.REACT_APP_IMG_URL+"/"+task.start_screen_thumb} />
                                </div>
                            </div>
                            }

                        </div>
                        <div className="task-prototype-test-sync-right">
                            <div className="prototype-test-task-figma-filename">
                                {resyncLoading &&
                                <>
                                    <Skeleton width={240} height={20} />
                                    <Skeleton width={240} height={20} />
                                </>
                                }

                                {!resyncLoading &&
                                <>
                                    <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"medium-font"}>{task.figma_filename}</Text>
                                    <Text type={"body-text-3"} fontWeight={"medium-font"}>Last synced <span className="color-purple purpletext">{moment.utc(task.last_synced).local().format("DD-MM-Y") }</span> at <span className="color-purple purpletext">{moment.utc(task.last_synced).local().format("HH:mm z") }</span></Text>
                                </>
                                }
                            </div>
                            <div  className="prototype-test-task-btns-list">
                                <Button
                                    label="Resync again"
                                    type={resyncLoading?"primary":"secondary"}
                                    size="medium"
                                    iconLeft={<Icon value="sync" size={"small"} />}
                                    onClick={()=>{

                                        
                                        if(dont_ask_again){
                                            resyncTask(false);
                                        } else {


                                            setResyncConfirmModal({...resyncConfirmModal, open:true})
                                        }

                                    }}
                                    microLoading={resyncLoading}
                                />
                                {!resyncLoading &&
                                <Button
                                    label="Preview prototype"
                                    type="secondary"
                                    size="medium"
                                    iconLeft={<Icon value="eye" size={"small"} />}
                                    onClick={()=>{
                                        setPreviewPrototypeModal({...previewPrototypeModal, open:true})
                                    }}
                                />
                                }

                                {resyncLoading &&
                                <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton  width={240} height={46}  borderRadius={56}  /></div>
                                }
                                {!resyncLoading &&
                                <Button
                                    label="Unsync"
                                    type={resyncLoading?"primary":"secondary"}
                                    size="medium"
                                    iconLeft={<Icon value="unsync" size={"small"} />}
                                    onClick={()=>{
                                        //unsyncTask();

                                        setUnsyncConfirmModal({...unsyncConfirmModal, open:true});
                                    }}
                                    microLoading={unsyncLoading}
                                />
                                }
                                {resyncLoading &&
                                <div  style={{margin:0, border:"none", display:"inline-flex", margin:"6px 10px 0px"}}><Skeleton width={146} height={46}  borderRadius={56} styleProp={{display:"inline-flex", lineHeight:"25px", margin:"6px 10px 0px"}} /></div>
                                }
                            </div>
                        </div>
                        {formErrors.resync_error &&
                        <div className="col-md-12">

                            <span className="error red-text">{formErrors.resync_error}</span>


                        </div>
                        }
                    </div>
                    {showMessageTakingLong &&
                    <div className="info-message-wrapper mt-20">
                        <ToastMessage type="info" closable={false} message="This is taking longer than expected. Please wait while we upload your prototype." />
                    </div>
                    }
                    {showMockupMessage &&
                    <div className="info-message-wrapper mt-20">
                        <ToastMessage
                            type="info"
                            icon="no-mockup"
                            onClose={()=>{
                                setShowMockupMessage(false);
                            }}
                            closable={true}
                            message="Please disable the device mockup in your figma prototype to look at a better preview"
                        />
                    </div>
                    }
                </div>
                <div className="separator">
                    <div className="cardsorting-wrap-mid-data prototype-task-description">

                        <Text type={"subtitle-2"} fontWeight={"medium-font"}>{objective=="closed"?"Task description":"Instructions"}</Text>
                        <Text type={"body-text-2"} cssClasses="mb-32" fontWeight={"medium-font"}>{objective=="closed"?"Provide participants with instructions to complete the task on the prototype, for example: How would you change your password?":"Provide participants with instructions for exploring the prototype. For instance, instruct them to freely navigate and observe the content and visual aspects of the design."}</Text>
                        <div className="formbox " id={"task_description_"+task.id}>
                            <Input type="text"
                                   rtl={language=="ar"?true:false}
                                   label={objective=="closed"?"Task description":"Instructions"}
                                   maxLength={300}
                                ///placeholder={language=="ar"?"ابحث في النموذج التفاعلي عن كيفية تقديم شكوى":"E.g. How would you change your password?" }
                                   value={formValues.description}
                                   onChange={(e)=>{ setStepperError(false); validateDescription(e.target.value);  updateTask({...task, description:e.target.value}, index); setFormValues({...formValues, description:e.target.value}) }}
                                   onClear={(e)=>{  updateTask({...task, description:''}, index); setFormValues({...formValues, description:''}); saveTask({...formValues, description:''}); }}
                                   onBlur={(e)=>{ if(e.target.value){ setStepperError(false);} validateDescription(e.target.value);  updateTask({...task, description:e.target.value}, index); saveTask(); }}
                                   error={formErrors.description}
                                   required={true}
                            />
                        </div>
                    </div>
                </div>
                {objective=="closed" &&
                <div className="separator">
                    <div className={`cardsorting-wrap-mid-data prototype-task-define-flow ${formErrors.goal_screen?"goal_screen_error":""} `}>
                        <div className="formbox" id={"goal_screen_"+task.id}>
                            <DefineFlow
                                language={language}
                                start_screen={{
                                    thumb: task.start_screen_thumb,
                                    file:task.start_screen_thumb,
                                    prototype_link:task.prototype_link,
                                    version:task.version,
                                    file_key:task.file_key,
                                    file_name: task.figma_filename,
                                    node_id:task.start_screen_node_id
                                }

                                }
                                goal_screen={task.goal_screen_thumb ? {
                                    thumb: task.goal_screen_thumb,
                                    file:task.goal_screen_thumb,
                                    node_id:task.goal_screen_node_id
                                }:null}
                                objective={objective}
                                goal_screen_loading={syncLoading}
                                resync_loading={resyncLoading}
                                updateGoalScreen={(node_id)=>{
                                    goal_node_id = node_id;
                                    servicetype = 'set_goal_screen';
                                    validateTokenAfterSync();
                                }}
                                device={test.device}
                                error={formErrors.goal_screen}
                            />
                            <span className="error red-text">
                        {formErrors.goal_screen}
                        </span>
                        </div>
                    </div>
                </div>
                }
                <div className="separator">
                    <div className={`prototype-task-followup-questions  ${formErrors.questions?"prototype-questions-error":""} `} id={"questions_"+task.id}>
                        <FollowupQuestions tempAddQuestion={tempAddQuestion} onUpdate={(question)=>{
                            setTempAddQuestion(question);
                        }} ref={followupRef} objective={objective} maxQuestions={objective=="closed"?1:3} followupQuestions={task.questions} isLoading={false}
                                           updateQuestions={(questions)=>{

                                               var taskLoc = {...task};

                                               taskLoc.questions = questions;

                                               if(questions.length>0){

                                                   if(formErrors.questions){
                                                       setStepperError(false);
                                                   }

                                                   setFormErrors({...formErrors, questions:null});


                                               }

                                               updateTask(taskLoc, index);
                                           }}
                                           test={test}
                                           task={task}
                                           setIsSaved={setIsSaved}
                        />

                        <span className="error red-text">
                        {formErrors.questions}
                    </span>
                    </div>
                </div>
                <PreviewPrototype
                    prototype_link={"https://embed.figma.com/proto/"+task.file_key+"/"+task.figma_filename+"?node-id="+task.start_screen_node_id+"&version-id="+task.version+"&starting-point-node-id="+task.start_screen_node_id+"&hide-ui=1&hotspot-hints=0&scaling=&scaling=scale-down-width&embed-host=userq&client-id="+process.env.REACT_APP_FIGMA_CLIENT_ID}
                    version={task.version}
                    open={previewPrototypeModal.open}
                    close={()=>{
                        setPreviewPrototypeModal({...previewPrototypeModal, open:false})
                    }}
                    device={test.device}
                />
                <ConfirmationModal
                    cssClass="unsync-confirmation-modal"
                    confirm_message="Are you sure you want to unsyc this prototype? Any instruction or question associated to it will be deleted and you will be able to sync a new Figma prototype."
                    confirm_btn_title="Yes, proceed"
                    cancel_btn_title={"No, cancel"}
                    confirm_title="Unsync Figma prototype"
                    confirm_title_class={"text-danger"}
                    openModal={unsyncConfirmModal.open}
                    close={() => {
                        setUnsyncConfirmModal({ ...unsyncConfirmModal, open: false });
                    }}
                    confirm={() => {
                        setUnsyncConfirmModal({ ...unsyncConfirmModal, open: false });

                        unsyncTask();
                    }}
                />
                <ConfirmationModal
                    cssClass="sync-confirmation-modal"
                    confirm_message="By syncing again your Figma prototype you will receive the most recent updates from the file."
                    confirm_btn_title="Yes, proceed"
                    cancel_btn_title={"No, cancel"}
                    confirm_title="Resync Figma prototype"
                    dont_ask_again={true}
                    openModal={resyncConfirmModal.open}
                    close={() => {
                        setResyncConfirmModal({ ...resyncConfirmModal, open: false });
                    }}
                    askconfirm={(dont_ask_again) => {

                        servicetype = 'resync';

                        setResyncConfirmModal({ ...resyncConfirmModal, open: false });

                        dont_ask_again_func = dont_ask_again;

                        validateTokenAfterSync();
                        //resyncTask(dont_ask_again);
                    }}
                />
            </div>
            }
        </div>
    );
});
export default Task;