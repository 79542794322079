import { handleApiErrorResponse } from "../lib/helpers";

// Fetch list of photos
export function fetchPhotosLibrary(token, type) {
    return fetch(`${process.env.REACT_APP_API_END_POINT}photos_library/list?category=${type}`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json",
        },
        redirect: "follow"
    })
    .then((res) => {
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;
    })
    .catch((error) => {
        handleApiErrorResponse(error);
    });
}

// Upload a new photo
export function uploadPhoto(file, token, onUploadProgress) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        formData.append('photo', file);

        // Set up the request
        xhr.open('POST', `${process.env.REACT_APP_API_END_POINT}photos_library/upload`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        // Track upload progress
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentage = Math.round((event.loaded / event.total) * 100);
                onUploadProgress(percentage); // Pass progress to the callback
            }
        };

        // Resolve or reject based on response
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.responseText));
            } else {
                reject(JSON.parse(xhr.responseText));
            }
        };

        xhr.onerror = () => {
            reject(new Error('Upload failed.'));
        };

        // Send form data
        xhr.send(formData);
    });
}

// Delete photo
export function deletePhoto(id, token) {
    return fetch(`${process.env.REACT_APP_API_END_POINT}photos_library/remove?photo_id=${id}`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    })
    .then((res) => {
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;
    })
    .catch((error) => {
        handleApiErrorResponse(error);
    });
}
