import React, { useEffect, useState, useRef } from "react";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
export const GuideLink = ({ methodology }) => {

  const [clicked, setClicked] = useState(false);
  const [guidelineUrl, setGuidelineUrl] = useState("");

  useEffect(() => {
    if (methodology == "prototype test") {
      setGuidelineUrl("https://userq.com/prototype-test-guide-how-to-create-a-prototype-test/");
    }
    if (methodology == "survey") {
      setGuidelineUrl("https://userq.com/survey-guide-how-to-create-a-survey/");
    }
    if (methodology == "5 second test") {
      setGuidelineUrl("https://userq.com/5-second-test-guide-how-to-create-a-5-second-test/");
    }
    if (methodology == "card sorting test") {
      setGuidelineUrl("https://userq.com/card-sorting-guide-how-to-create-a-card-sorting-test/");
    }
    if (methodology == "tree test") {
      setGuidelineUrl("https://userq.com/tree-testing-guide-how-to-create-a-tree-test/");
    }
    if (methodology == "preference test") {
      setGuidelineUrl("https://userq.com/preference-test-guide-how-to-create-a-preference-test/");
    }
    if (methodology == "first click test") {
      setGuidelineUrl("https://userq.com/first-click-test-guide-how-to-create-a-first-click-test/");
    }
  }, [methodology]);

  const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                  setClicked(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

  return (
    // <a href={guidelineUrl} target="_blank">
    <a href={guidelineUrl} target="_blank" className={`guide-link-container ${clicked ? "clicked-guideline" : ""}`} onClick={() => { setClicked(true) }} ref={wrapperRef}>
      <Text type={"body-text-2"} fontWeight={"medium-font"}>How to create a {methodology}</Text>
      {/* <Icon value="external-link" size="medium" hover={true}/> */}
      <div className="external-link-icon">
        <Icon
            value={"external-link"}
            colorClass={"gray-50-svg"}
            size={"medium"}
        />
      </div>
    </a>
    // </a>
  );
};