//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";
import { roundNumber } from "../../../lib/helpers";
import { Icon } from "../../themes/userq/Icon/Icon";
import Cookies from "js-cookie";

export default function ExistingTesterConcludeTestModal({
    openExistingTesterConcludeTestModal,
    closeExistingTesterConcludeTestModal,
    test,
    isAssignsCredits,
    cssClass,
    open,
}) {
    //console.log(test);
    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    const svgContainer1 = useRef(null);

    useEffect(() => {
        if (window.lottie && svgContainer1.current) {
            const animItem = window.lottie.loadAnimation({
                container: svgContainer1.current, // Reference to the container div
                renderer: "svg",
                loop: false,
                autoplay: true,
                path: `${process.env.REACT_APP_URL}animations/congratulations.json`,
            });

            return () => {
                if (animItem) {
                    animItem.destroy();
                }
            };
        }
    }, []);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeExistingTesterConcludeTestModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    return (
        <Modal
            show={openExistingTesterConcludeTestModal}
            centered
            className={`fade custom-modal-wrap new-tester-given-test congratulation-modal-wrap tester-congrats-modal ${cssClass}`}
        >
            <Modal.Body
                className={test.language == "ar" ? "arabic_wrapper" : ""}
                ref={wrapperRef}
            >
                <div className="lottie-hold lottie-position-absolute">
                    <div
                        className="animation-svg-hold"
                        id="svg"
                        ref={svgContainer1}
                        style={{ width: 300, height: 300 }}
                    ></div>
                </div>

                <div className="congrats-modal-text mt-0">
                    <div className="money-icon-wrap">
                        <Icon value={"money"} size={"extra-large"} />
                    </div>
                    {/*<Text type={"h1"}>*/}
                    {/*    {test.language=="en"?'Congratulation':'تهنئة'}*/}
                    {/*</Text>*/}
                    {!isAssignsCredits ? (
                        <div>
                            <Text type={"h1"}>
                                {test.language == "en"
                                    ? "Congratulations"
                                    : "تهانينا"}
                            </Text>

                            <Text type={"h2"}>
                                {test.language === "en" ? (
                                    "You've successfully completed the test"
                                ) : (
                                    <span className="w-100">
                                        لقد أكملت الاختبار بنجاح
                                    </span>
                                )}
                            </Text>

                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-color"}
                            >
                                {test.language === "en" ? (
                                    "Your response will be reviewed, and if it violates our guidelines, your score may be lowered, and we'll let you know by email."
                                ) : (
                                    <span className="w-100">
                                        ستتم مراجعة ردودك، وإذا كان هناك أي
                                        انتهاك لإرشاداتنا، فقد يتم خفض درجاتك.
                                        سيتم إبلاغك عن طريق البريد الإلكتروني.
                                    </span>
                                )}
                            </Text>
                        </div>
                    ) : (
                        <div>
                            <Text type={"h1"}>
                                {test.language == "en"
                                    ? "Congratulations"
                                    : "تهانينا"}
                            </Text>

                            <Text type={"h2"}>
                                {test.language === "en" ? (
                                    `$${test.recruitment_fees_for_tester.toFixed(
                                        2
                                    )} has been credited to your wallet`
                                ) : (
                                    <span className="w-100 ">
                                        {" "}
                                        تمت إضافة&nbsp;
                                        {test.recruitment_fees_for_tester.toFixed(
                                            2
                                        )}
                                        $&nbsp;إلى محفظتك
                                    </span>
                                )}
                            </Text>

                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-color"}
                            >
                                {test.language === "en" ? (
                                    "Your response will be reviewed, and if it violates our guidelines, your score may be lowered, and we'll let you know by email."
                                ) : (
                                    <span className="w-100 ">
                                        ستتم مراجعة ردودك، وإذا كان هناك أي
                                        انتهاك لإرشاداتنا، فقد يتم خفض درجاتك.
                                        سيتم إبلاغك عن طريق البريد الإلكتروني.
                                    </span>
                                )}
                            </Text>
                        </div>
                    )}
                    <div className="congrts-modal-wrap">
                        <Button
                            type={"primary"}
                            size={"large"}
                            label={
                                test.language === "en"
                                    ? "Your dashboard"
                                    : "لوحة القيادة الخاصة بك"
                            }
                            onClick={() => {
                                //closeExistingTesterConcludeTestModal(false);

                                Cookies.remove("id_open");
                                navigate("/dashboard");
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
