import {handleApiErrorResponse, isJsonString} from "../lib/helpers";

export function getProjects(token,signal){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects', {
        method: 'GET',
        signal: signal,
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}
export function createProject(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/create', {
        method: 'POST',
        body:  formValues,
        headers:{"Authorization":"Bearer "+token}
    })
    .then((res)=>{
        
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{        
        handleApiErrorResponse(res);
    });
}
export function updateProject(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/update', {
        method: 'POST',
        body:  formValues,
        headers:{"Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function deleteProject(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/remove', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getProject(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/fetch?project_id='+formValues.project_id, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function addCollaboratorService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/collaborator/add', {
        method: 'POST',
        body: JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function removeCollaboratorService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/collaborator/remove', {
        method: 'POST',
        body: JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateRoleOfCollabService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'projects/collaborator/role', {
        method: 'POST',
        body: JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
