import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { LoadingIcon } from "../Loader/loadingIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TestHeader } from "../TestHeader/TestHeader";
import { TesterHeader } from "../Header/TesterHeader";
import { SupportPanel } from "./elements/support_panel";
import { GuestHeader } from "../Header/GuestHeader";
import HelpModal from "../Modal/HelpModal";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";
import { useTestBuilderData } from "../../../researcher/tests/contexts/TestBuilderContext";


export  const LayoutResearcher =  ({wrapClass, researcher, isSharedLink, isLoading, skipCheck, activeMenu, resultHeaderClass, openSupportPanel,hideSupportPanel,isWorkspaceFrozen, openFrozenModal, ...props}) =>{

    const user = ReactSession.get("user");

    const navigate = useNavigate();

    const [show_support_panel, setShowSupportPanel] = useState(false);
    
    const {workspaceid} = useParams();

    const location = useLocation();

    const {frozenWorkspaceModal, setFrozenWorkspaceModal} = useTestBuilderData();

    useEffect(()=>{

        /*if(workspaceid){
            
            ReactSession.set("workspace_id", workspaceid);
        }*/

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        
        if(!props.skipProfileComplete && !isSharedLink) {

            if (user.role == "Researcher" && user.completed_step != 1) {

                navigate('/r/profile/complete');
            } else if (user.role == "Researcher" && user.signup_plan == "Team" && 
                location.pathname!="/r/choose-plan"
                && 
                location.pathname!="/r/support/contact"
                && 
                location.pathname!="/r/support"
            ) {
                navigate('/r/create-team-workspace');
            }
        }
    },[]);

    useEffect(()=>{
        setShowSupportPanel(openSupportPanel);
    },[openSupportPanel])
    useEffect(()=>{
        if(openFrozenModal){
            setFrozenWorkspaceModal({
                ...frozenWorkspaceModal,
                open: true,
            });
        } else {
            setFrozenWorkspaceModal({
                ...frozenWorkspaceModal,
                open: false,
            });
        }
    },[openFrozenModal]);
    return (
        <SkeletonTheme baseColor="#cccccc" highlightColor="#e0e0e0">
            {!isLoading &&
            <>
            {isSharedLink ?
            <>
            <GuestHeader isSharedLink={isSharedLink} resultHeaderClass={resultHeaderClass}/>
            </>
            :
            <>
            {props.profileHeader &&
            <TesterHeader researcher={props.profileResearcher} isAccount={false} />
            }
            {!props.profileHeader &&
            <Header openSupportPanel={()=>{  setShowSupportPanel(true)}}   collaborators={[]} user={user} resultHeaderClass={resultHeaderClass} workspaceName={props.workspaceName} />
            }
            </>
            }
            
            <div className={`page-data-wrapper ${wrapClass} ${resultHeaderClass?"result-body":""}`} onClick={(e)=>{ 
                if(isWorkspaceFrozen){
                    setFrozenWorkspaceModal({
                        ...frozenWorkspaceModal,
                        open: true,
                    });
                    e.stopPropagation();
                e.preventDefault();
                }
                
             }}>
                {props.children}
            </div>
            <HelpModal
                cssClass={"frozen-account-modal"}
                open={frozenWorkspaceModal.open}
                close={() => {
                    setFrozenWorkspaceModal({
                        ...frozenWorkspaceModal,
                        open: false,
                    });
                }}
                btnLoading={false}
                body={
                    <div className="frozen-modal-data">
                        <div className="frozen-icon-wrap mb-32">
                            <Icon
                                value={"Snowflake"}
                                colorClass={"gray-900-svg"}
                            ></Icon>
                        </div>
                        <div className="mb-32 text-center">
                            <Text type={"h3"} cssClasses={"mb-20"}>
                                Access to this content has been momentarily
                                revoked.
                            </Text>

                            <Text
                                cssClasses={"gray-text"}
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                            >
                                The workspace is not available due to pending payment. Please contact your workspace admin to resolve the issue.
                            </Text>
                        </div>

                        <div className="d-flex justify-content-center duplicate-test-modal-action">
                            <Button
                                label={"Close"}
                                type={"primary"}
                                size={"large"}
                                onClick={() => {
                                    setFrozenWorkspaceModal({
                                        ...frozenWorkspaceModal,
                                        open: false,
                                    });
                                }}
                            />
                        </div>
                    </div>
                }
            />
            <Footer />
            </>
            }
            {isLoading &&
                <div className="container inner-page-container">
                    <LoadingIcon />
                </div>
                
            }

            {!isSharedLink &&
                <SupportPanel researcher={researcher} showprop={show_support_panel} hideSupportPanel={() => {
                    setShowSupportPanel(false);

                    if (hideSupportPanel) {
                        hideSupportPanel(false);
                    }
                }}/>
            }
        </SkeletonTheme>
    )
}