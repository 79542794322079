import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
// import PhoneInput, {getCountryCallingCode} from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Icon} from "../Icon/Icon";


export const PhoneNumberInput = ({ value, onBlur, onCountryChange, onChange, error, error_class, label, readOnly }) => {

    // console.log(value)

    return (

        <div className={`input-field static-label multi-field-wrap phone-number-dropdown phone-number-field ${value != '' ? 'focused phone-number-available' : ''} ${error ? 'field-error' : ''} ${readOnly==true?'read-only-field-phone':''}`}>
            <label className="focusedlabel" htmlFor="phone">{label?label:"Phone number"}</label>

            <PhoneInput
                countrySelectProps={{ unicodeFlags: false }}
                international
                countryCodeEditable={false}
                country="ae"
                value={(value)?value:'971'}
                onBlur={onBlur}
                onChange={onChange} />


            {/*<PhoneInput*/}
            {/*    countrySelectProps={{ unicodeFlags: false }}*/}
            {/*    international*/}
            {/*    countryCallingCodeEditable={false}*/}
            {/*    defaultCountry="AE"*/}
            {/*    value={value}*/}
            {/*    onBlur={onBlur}*/}
            {/*    onCountryChange={(country)=>{*/}
            {/*        if(onCountryChange && country && country!=0){*/}
            {/*            onCountryChange(getCountryCallingCode(country));*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    onChange={onChange} />*/}



            {error != null && (
                <span className='caption'>
                    {error}
                </span>
            )}

        </div>

    )
}
PhoneNumberInput.propTypes = {
    value: PropTypes.string,
    error_class: PropTypes.string,
    onChange: PropTypes.func,

};
PhoneNumberInput.defaultProps = {
    onChange: undefined,
    error_class: 'input-error',
};
