import React, { useEffect, useState ,useRef} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { ReactSession } from "../../lib/secure_reactsession";
import { getResultData, validateResultData } from "../../services/test_result";

import {decryptId, encryptId, renderTestTitleCreate} from "../../lib/helpers";
import { Footer } from "../themes/userq/Footer/Footer";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { load } from 'recaptcha-v3';
import { Helmet } from "react-helmet";
import {GuestHeader} from "../themes/userq/Header/GuestHeader";
import ScreeningQuestionsModal from "../themes/userq/ScreeningQuestionsModal/screening_questions_modal";
import {getTestData} from "../../services/test";

let captcha = null;

export default function Test() {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const Ref = useRef(null);

    const [test, setTest] = useState("");
    const [result, setResult] = useState("");
    const [similarTest, setSimilarTest] = useState("");
    const [settings, setSettings] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isNda, setNda] = useState(false);
    const [testerNotMatchedInTargetGroup, setTesterNotMatchedInTargetGroup] = useState(false);
    const [testerLimitExceed, setTesterLimitExceed] = useState(false);
    const [errorType, setErrorType] = useState("general");
    const [openExitTestModal, setOpenExitTestModal] = useState(false);
    const [timeSpent, setTimeSpent] = useState(0);
    const [isBackButtonClicked, setBackbuttonPress] = useState(false);
    const [sessionAboutToExpireModal, setSessionAboutToExpireModal] = useState({open:false});
    const [sessionExpiredModal, setSessionExpiredModal] = useState({open:false});
    const [timeInSecondsRemaning, setTimeInSecondsRemaning] = useState(3600);

    const [questionType, setQuestionType] = useState("briefing");
    const [briefingQuestionArray, setBriefingQuestionArray] = useState([]);
    const [debriefingQuestionArray, setDebriefingQuestionArray] = useState([]);
    const [surveyQuestionArray, setSurveyQuestionArray] = useState([]);
    const [isActive, setActive] = useState("introduction");

    const [isAssignsCredits, setAssignsCredits] = useState(false);
    const [isPaidUser, setPaidUser] = useState(false);
    const [isRegisteredUser, setRegisteredUser] = useState(false);
    const [score, setScore] = useState(0);
    const [accountStatus, setAccountStatus] = useState("active");

    const [testGivenBy, setTestGivenBy] = useState(null);
    const [loginStatusChangeModal, setLoginStatusChangeModal] = useState({open:false});

    const [screeningQuestionsModal, setScreeningQuestionsModal] = useState({
        open: false
    });




    const [accordianData, setAccordionData] = useState([
        {
            id: 1,
            open: false,
            title: 'Why has my account been blocked?',
            info: "Your account has been blocked due to a tester score of 50% or lower or a violation of our <a class='faq-support-link' href='https://userq.com/terms-of-use/' target='_blank'>Terms of Use</a>."
        },
        {
            id: 2,
            open: false,
            title: 'What happens when my account is blocked?',
            info: 'You will be restricted from accessing any further tests on UserQ, your score will be lowered and you will not be able to request a payout from your wallet.'
        },
        {
            id: 3,
            open: false,
            title: 'How can I get my account unblocked?',
            info: "You can reach out to our <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile' target='_blank'>support team</a> for advice on how to move forward."
        }
    ]);

    const generateCaptcha = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                //({ ...formValues, captcha: captcha });

                callback(token);

            })
        })
    }

    const getTestResultApi = () => {
        let token = ReactSession.get("token");
        //if(!token){
        
        let guest_token = ReactSession.get("guest_token");

        if (!ReactSession.get("guest_token")) {
            const randomToken = Math.random().toString(36).substr(2, 15);
            ReactSession.set("guest_token", randomToken);

            guest_token = randomToken;
        }

        // }

        // check test given by logged User or guest
        if(token){
            setTestGivenBy('loggedInUser');
        }else{
            setTestGivenBy('guest');
        }

        setLoading(true);


            getTestData({ test_id:test_id, guest_token: guest_token }, token).then((response) => {

                setLoading(false);
                if (response.success) {

                    setTest(response.test);

                    if(response.test.target_group.is_screening_questions && response.test.target_group.is_screening_questions==1){
                        if(response.screeningattemptedTests.includes(response.test.id)){
                            // console.log('asd')
                            navigate("/t/" + encryptId(response.test.id, response.test));
                        }else{

                            // console.log('asd1')
                            openScreeningQuestions(response.test);
                            setTest(response.test);
                        }
                    }else{
                        // console.log('asd2')
                        navigate("/t/" + encryptId(response.test.id, response.test));
                    }
                };
            });




    };

    const openScreeningQuestions =(test)=>{
        setScreeningQuestionsModal({ ...screeningQuestionsModal, open: true });
    }



    useEffect(() => {
        //document.title = "Take test | "+process.env.REACT_APP_NAME;

        getTestResultApi();

        // location scroll top
        window.scrollTo(0, 0);


        // clearTimer(getDeadTime());
    }, []);





    return (
        <>
            <Helmet>
                <title>{location.pathname.substr(1, 1) === "t" ? "Take Test" : "Preview "+ (test && test.methodology ? renderTestTitleCreate(test.methodology):" test")}  | UserQ</title>
            </Helmet>


                <div
                    className={`tester-login-page-wrapper`}
                >
                    {isLoading &&
                    <div className="full-page-loader taking-test-full-page-loader">
                        <LoadingIcon />
                    </div>
                    }
                    {!isLoading && (
                        <>



                                                {screeningQuestionsModal.open && test &&
                                                    <ScreeningQuestionsModal
                                                        openModal={screeningQuestionsModal.open}
                                                        confirm={()=>{
                                                            setScreeningQuestionsModal({ ...screeningQuestionsModal, open: false });
                                                        }}
                                                        closeModal={(reloadTests)=>{
                                                            setScreeningQuestionsModal({ ...screeningQuestionsModal, open: false });
                                                            if(reloadTests){

                                                                let token = ReactSession.get("token");
                                                                if(!token){

                                                                    navigate('/tester/sign-up');
                                                                }else{
                                                                    navigate('/dashboard');
                                                                }

                                                            }else {
                                                                // getTestResultApi();
                                                                let token = ReactSession.get("token");
                                                                if(!token){

                                                                    navigate('/tester/sign-in');
                                                                }else{
                                                                    navigate('/dashboard');
                                                                }
                                                            }
                                                        }}
                                                        test={test}
                                                        location={location.pathname.substr(1, 1)}
                                                    />
                                                }




                        </>
                    )}






                </div>

        </>
    );
}