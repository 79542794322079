import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { encryptId } from "../../../../lib/helpers.js";
import {ReactSession} from "../../../../lib/secure_reactsession";
import {renameTestService} from "../../../../services/test";
import toast from "react-hot-toast";
import { ToastMessage } from '../../../themes/userq/ToastMessage/ToastMessage';
import { TestHeader } from '../../../themes/userq/TestHeader/TestHeader';
import { settings } from "../../../../data/settings.js";



export default function Header({test,isLoading,credits,isSaved}) {

    const [isEdit,setIsEdit] = useState(false);
    const [isSavedIcon,setIsSavedIcon] = useState({'status':'success','message':''});
    const [formValues, setFormValues] = useState({ "test_name": null });
    const [checkError, setCheckError] = useState(false);
    const [maxLength] = useState(45);
    const [formErrors, setFormErrors] = useState({ "test_name": null, "error_class": "" });

    const [testLink, setTestLink] = useState('');


    useEffect(() => {

       
        setIsSavedIcon({'status':(isSaved.status)?isSaved.status:'success','message':isSaved.message});

        let screening_question = ReactSession.get("screening_question_data");

       // console.log(screening_question)
        if(screening_question){
            var testLink = '';
            if(test.target_group && screening_question == 'yes'){

                testLink = process.env.REACT_APP_URL+"p/screening/" + test.id;

            }else{
                testLink = process.env.REACT_APP_URL+"p/" + encryptId(test.id, test);
            }

            // console.log(testLink)
            setTestLink(testLink);
            ReactSession.set("screening_question_data",false);
        }


    }, [isSaved]);

    useEffect(() => {

        if(test){

            var testLink = '';
            if(test.target_group && test.target_group.is_screening_questions && test.target_group.is_screening_questions==1){

                testLink = process.env.REACT_APP_URL+"p/screening/" + test.id;

            }else{
                testLink = process.env.REACT_APP_URL+"p/" + encryptId(test.id, test);
            }

            // console.log(testLink)
            setTestLink(testLink);
            ReactSession.set("screening_question_data",false);
        }


    }, [test]);

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    const submitHandler = (val) =>{

        if(!val){
            showError('Test name field is required');
            return false;
        }

        let token = ReactSession.get("token");

        // user cannot click on save is process is running
        if(isSavedIcon.status === 'loading'){
            //showError('Please try again later');
            return false;
        }

        setIsEdit(false);

        setIsSavedIcon({'status':'loading','message':''});

        renameTestService({ test_id: test.id, title: val }, token).then((response) => {


            //setFormValues({'test_name':val});

            if (!response.success) {
                showError(response.message);
                setIsSavedIcon({'status':'error','message':response.message});
                //setFormValues({'test_name':test.test_name});
                setCheckError(true);
            }else{
                setFormValues({'test_name':val});
                setIsSavedIcon({'status':'success','message':''});
                setCheckError(false);
            }

        });
    }

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };



    return (
        <TestHeader

            methodology={test.methodology}
            previewLink={testLink?testLink:(!isLoading)?process.env.REACT_APP_URL + "p/" + encryptId(test.id, test):''}
            // previewLink={(!isLoading)?process.env.REACT_APP_URL + "p/" + encryptId(test.id, test):''}
            isLoading={isLoading}
            credits={credits}
            testName={(test && test.test_name)}
            isErrorTestName={checkError}
            savedTestName={formValues.test_name}
            testLanguage={test && test.language}
            isSaved={isSavedIcon.status}
            isSavedMessage={isSavedIcon.message}
            workspace_project={test && test.workspace_project}
            submitHandler={submitHandler}
        />
    );
}
