import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { hasFlag } from "country-flag-icons";
import Flags from "country-flag-icons/react/3x2";
import { countries } from "../../../../data/countries";
import { useRef, useState } from "react";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import { Button } from "../../../themes/userq/Button/Button";
import { Modal } from "react-bootstrap";
import { roundAnlyticsNumber } from "../../../../lib/helpers";
import exportAsImage from "../../../../lib/exportAsImage";

export const CirclesGroupStatsBox = ({ title, data }) => {
    const ref = useRef();
    const modref = useRef();
    const tipref = useRef();
    const exportPngRef = useRef();

    const [tooltip, showTooltip] = useState({
        tooltipLeft: 0,
        tooltipTop: 0,
        tooltipData: "",
        open: false,
        show: false,
    });

    const handlePointerMove = (data) => {
        return (event) => {
            let tipPosition = "bottom";

            var tool_width =
                tipref && tipref.current ? tipref.current.clientWidth : 150;

            var tool_height =
                tipref && tipref.current
                    ? tipref.current.clientHeight + 12
                    : 106;

            var local_ref = otherModal.open ? modref : ref;

            let containerX =
                ("clientX" in event ? event.clientX : 0) -
                local_ref.current.getBoundingClientRect().left -
                tool_width / 2;
            let containerY =
                ("clientY" in event ? event.clientY : 0) -
                local_ref.current.getBoundingClientRect().top -
                tool_height -
                10;

            var window_width = window.innerWidth;

            var window_height = window.innerHeight;

            if (event.clientX + tool_width / 2 > window_width) {
                containerX =
                    ("clientX" in event ? event.clientX : 0) -
                    local_ref.current.getBoundingClientRect().left -
                    tool_width / 2 -
                    (event.clientX + tool_width / 2 - window_width);
            }
            if (event.clientX - tool_width / 2 < 0) {
                containerX =
                    ("clientX" in event ? event.clientX : 0) -
                    local_ref.current.getBoundingClientRect().left -
                    tool_width / 2 +
                    (tool_width / 2 - event.clientX);
            }

            showTooltip({
                tooltipLeft: containerX,
                tooltipTop: containerY,
                tooltipData: data,
                open: true,
                show: tooltip.open && tipref && tipref.current ? true : false,
                tipPostion: tipPosition,
            });
        };
    };

    const [otherModal, setOtherModal] = useState({ open: false });

    let nationalities_main = [];

    let nationalities_others = [];

    if (data.length > 12) {
        for (var i = 0; i < 11; i++) {
            nationalities_main.push(data[i]);
        }
        var others = 0;

        for (var j = i; j < data.length; j++) {
            others += data[j]["count"];

            nationalities_others.push(data[j]);
        }
        nationalities_main.push({
            nationality: "More",
            short_code: "other",
            count: others,
        });
    } else {
        nationalities_main = data;
    }
    const renderNationalities = (nationalities_arr, insideModal, local_ref) => {
        let nationalities = [];

        var sum = 0;

        data.forEach(function (item) {
            sum += item["count"];
        });

        nationalities_arr.forEach(function (item) {
            // Math.round((((item["count"]/sum)*100)* 100)/100)
            var percentage = roundAnlyticsNumber((item["count"] / sum) * 100);
            nationalities.push(
                <RadialChart
                    handlePointerMove={handlePointerMove}
                    showTooltip={showTooltip}
                    percentage={percentage}
                    key={"nationality" + item["short_code"]}
                    sum={sum}
                    item={item}
                    onClick={() => {
                        if (item["short_code"] == "other") {
                            setOtherModal({ ...otherModal, open: true });
                        }
                    }}
                />
            );
        });
        return (
            <div
                className="circle-stats-group"
                ref={local_ref}
                style={{ position: "relative" }}
            >
                {tooltip.open && (insideModal || !otherModal.open) ? (
                    <>
                        <Tooltip
                            ref={tipref}
                            type={tooltip.tipPosition}
                            key={Math.random()} // needed for bounds to update correctly
                            style={{
                                zIndex: 1,
                                display: tooltip.show ? "block" : "block",
                                whiteSpace: "nowrap",
                                width: "auto",
                                minWidth: "150px",
                                margin: 0,
                                position: "absolute",
                                left: tooltip.tooltipLeft + "px",
                                top: tooltip.tooltipTop + "px",
                            }}
                            data={tooltip.tooltipData}
                            className="tooltipChart"
                        />
                    </>
                ) : (
                    <></>
                )}
                {nationalities}
            </div>
        );
    };

    return (
        <div className="position-relative chart-download-with-heading">
            <div
                className="download-chart-png cursor-pointer"
                onClick={() => exportAsImage(exportPngRef.current, title)}
            >
                <Icon value={"download"} size={"small"} hover={"true"}></Icon>
                <div className="tooltip-wrapper top-tooltip">
                    <p className="body-text body-text-2 normal-font ">
                        Download
                    </p>
                </div>
            </div>

            <div className="mb-20 box-outer-heading">
                <Text type={"h4"} fontWeight={"medium-font"}>
                    {title}
                </Text>
            </div>

            <div ref={exportPngRef}>
                <Box size={"large"}>
                    {renderNationalities(nationalities_main, false, ref)}
                </Box>
                <Modal
                    show={otherModal.open}
                    centered
                    className="fade custom-modal-wrap  confirmation-modal-wrap nationality-modal-wrap"
                    onHide={() => {
                        setOtherModal({ ...otherModal, open: false });
                    }}
                >
                    <Modal.Body className="">
                        <Box size={"large"}>
                            {renderNationalities(
                                nationalities_others,
                                true,
                                modref
                            )}
                        </Box>
                        <Button
                            type={"primary"}
                            size={"medium"}
                            label={"close"}
                            onClick={() => {
                                setOtherModal({ ...otherModal, open: false });
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};
export const RadialChart = ({
    item,
    onClick,
    sum,
    percentage,
    handlePointerMove = undefined,
    showTooltip = undefined,
}) => {
    const [tooltip, setToolTip] = useState({});

    const renderFlagIcon = (code, nationality) => {
        const Component = Flags[code];

        return (
            <Component
                title={nationality}
                style={{ display: "inline-block", height: "1em", width: "1em" }}
            />
        );
    };
    return (
        <div
            onClick={onClick}
            className={`circle-stats-group percent ${
                item["short_code"] == "other" ? "othercircle" : ""
            } `}
            key={"nationality-box-" + item["nationality"]}
            onMouseEnter={() => {
                var tooltip_loc = Object.assign({}, tooltip);
                tooltip_loc[item["nationality"]] = true;
                setToolTip(tooltip_loc);
            }}
            onMouseLeave={() => {
                var tooltip_loc = Object.assign({}, tooltip);
                tooltip_loc[item["nationality"]] = false;
                setToolTip(tooltip_loc);
            }}
        >
            <div
                className="percent-inner"
                onPointerMove={
                    handlePointerMove
                        ? handlePointerMove(item["count"] + " out of " + sum)
                        : undefined
                }
                onPointerLeave={
                    showTooltip
                        ? () => {
                              showTooltip({
                                  ...tooltip,
                                  open: false,
                                  show: false,
                              });
                          }
                        : undefined
                }
            >
                {/*tooltip[item["nationality"]]  &&
                    <Tooltip
                        type={"bottom"}
                        key={item["nationality"]}
                        data={item["count"]+" out of "+sum}
                        className="tooltipChart nationalityTooltip"
                        style={{display:"block"}}
                    />
    */}
                <svg className="circular-flag-icon">
                    <circle cx="92" cy="92" r="85"></circle>
                    <circle
                        cx="92"
                        cy="92"
                        r="85"
                        style={{
                            "--circlepercent": (item["count"] / sum) * 100,
                        }}
                    ></circle>
                </svg>
                <div className="number">
                    <div>
                        <Text type={"h4"}>{percentage}%</Text>

                        {hasFlag(item["short_code"]) && (
                            <span className="country-flag-icon">
                                {renderFlagIcon(
                                    item["short_code"],
                                    item["nationality"]
                                )}
                            </span>
                        )}
                        {item["short_code"] == "other" && (
                            <span className="country-flag-icon">
                                <Icon value={"global"} size={"medium"} />
                            </span>
                        )}
                        {item["short_code"] == "unknown" && (
                            <span className="country-flag-icon">
                                <Icon value={"support"} size={"medium"} />
                            </span>
                        )}
                        <Text type={"body-text-3"} fontWeight="semi-bold-font">
                            {item["nationality"]}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
