import { Box } from "../../themes/userq/Box/Box"
import { Button } from "../../themes/userq/Button/Button";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text"

export const PayoutOverviewBox = ({type, amount, request_payout_enabled, onClick,loading}) => {
    
    const renderTitle = () =>{
        if(type=="balance"){
            return "Wallet balance";
        }
        else if(type=="inprogress"){
            return "Payout in progress";
        }
        else if(type=="deposited"){
            return "Deposited in your account";
        }
    }
    return <div className="wallet-overview-tile-hold"><Box size={"small"}>
        <div className="wallet-overview-tile">
            <Text type={"h4"} fontWeight={"medium-font"}>
                {renderTitle()}
            </Text>

            <div className={`${(parseInt(amount)===0)?'balance-row balance-row-balance':`balance-row balance-row-${type}`}`}>
                <div className="balance-row-left">
                {type=="balance" &&
                    <Icon colorClass={"gray-900-svg"} value={"credit"} size={"large"} />

                }
                {type=="inprogress" &&
                    <>
                        {(parseInt(amount)===0) ?
                            <Icon colorClass={"gray-900-svg"} value={"Arrow up left"} size={"large"} />
                            :
                            <Icon  colorClass={"warning-yellow-200-svg"} value={"Arrow up left"} size={"large"} />
                        }

                    </>

                }
                {type=="deposited" &&
                <>
                    {(parseInt(amount)===0) ?
                        <Icon colorClass={"gray-900-svg"} value={"Arrow down right"} size={"large"} />
                        :
                        <Icon colorClass={"success-green-200-svg"} value={"Arrow down right"} size={"large"} />
                    }

                </>
                }
                <Text type={"h1"} fontWeight={"medium-font"}>${amount}</Text>
               
                </div>
                {type=="balance" &&
                <div className="balance-row-right">
                <Button
                    type={"primary"}
                    size={"medium"}
                    state={request_payout_enabled?"active":"disabled"}
                    label={"Request payout"}
                    onClick={onClick}
                    microLoading={loading?loading:false}
                    />
                </div>
            }
            </div>
        </div>
    </Box> </div>
}