export const countries = [
    {
       "value":"Belarus",
       "label":"Belarus",
       "code":"BY"
    },
    {
       "value":"Cuba",
       "label":"Cuba",
       "code":"CU"
    },
    {
      "value":"Iran",
      "label":"Iran",
      "code":"IR"
   },
    {
       "value":"The Democratic Republic of the Korea",
       "label":"The Democratic Republic of the Korea",
       "code":"KP"
    },
    {
       "value":"Nigeria",
       "label":"Nigeria",
       "code":"NG"
    },    
    {
       "value":"Russian Federation",
       "label":"Russian Federation",
       "code":"RU"
    },
    {
       "value":"Sudan",
       "label":"Sudan",
       "code":"SD"
    },
    {
      "value":"South Sudan",
      "label":"South Sudan",
      "code":"SS"
   },
    {
       "value":"Syrian Arab Republic",
       "label":"Syrian Arab Republic",
       "code":"SY"
    },
    {
       "value":"Ukraine",
       "label":"Ukraine",
       "code":"UA"
    } 
];
