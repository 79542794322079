import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Icon} from "../../Icon/Icon";
import {Text} from "../../Text/Text";
import {Box} from "../../Box/Box";
import { Tooltip } from "../../Tooltip/Tooltip";
import { Link } from "react-router-dom";
import ReportTesterModal from "../../Modal/report_tester";

export const OverviewParticipant = ({language,userQPanel,sharedLink,test, totalReportApprovedByAdmin}) => {


    const [reportTesterModal, setReportTesterModal] = useState({
        open: false
    });

    var tooltipdata=<>
                    <div>{userQPanel+" participant"+(userQPanel>1?"s":"")+" passed screening criteria "}</div>
                    <div>{test && test.test_screening_failure_count +"  participant"+(test && test.test_screening_failure_count>1?"s":"")+" did not fit screening criteria"}</div>
                    </>;
    
    return (
        <>
        <div className="participants-overview-results">

            <Box title="Total number of participants">

                <div className="participants-overview-data-wrap d-flex">
                    <div className="participants-overview-left">

                        <Text
                            type={"h2"}
                            cssClasses={"participants-results-count"}
                        >
                            {userQPanel+sharedLink}
                        </Text>

                    </div>
                    <div className="participants-overview-right">

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"screen-tooltip-info-wrap"}
                        >
                            {userQPanel} From UserQ panel 
                            {test && test.target_group.is_screening_questions==1 &&
                            <span className="info-icon info-wrap position-relative screening-failed-info-tooltip">
                                <Icon value={"information"} size="small" />
                                <Tooltip 
                                        className={"tooltip-wrapper"}
                                        type={"bottom"}
                                        data={tooltipdata}
                                    />
                            </span>
                            }
                        </Text>
                        
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                        >
                            {sharedLink} From shared link
                        </Text>

                    </div>

                   
                </div>

                {test && test.reported_tester.status === "reviewed" && totalReportApprovedByAdmin > 0 &&
                        <div className="gray-box gray-bg reported-tester-count-overview w-100 d-flex align-items-center">
                            <span className="flag-icon">
                                <Icon value={"flag"} size="small" /> 
                            </span>

                            <Text
                                type={"caption"}
                                fontWeight={"medium-font"}
                                cssClasses={"screen-tooltip-info-wrap"}  
                            >
                                {totalReportApprovedByAdmin} Reported participants
                                <span className="info-icon info-wrap position-relative screening-failed-info-tooltip">
                                    <Icon value={"information"} size="small" />
                                    <div className="tooltip-wrapper bottom-tooltip">
                                        <Text type='body-text-2' cssClasses={'white-color'}>
                                            {totalReportApprovedByAdmin} participants have been reported and excluded from the test results.
                                        </Text>
                                        <Link className={'link-text'} onClick={() => {
                                            setReportTesterModal({
                                                open: true,
                                            });
                                        }}>
                                            View reported participants
                                        </Link>
                                    </div>
                                </span>
                            </Text>
                        </div>}


                        <div className={`participants-overview-footer ${test && test.reported_tester.status === "reviewed" && totalReportApprovedByAdmin > 0 ? 'mt-0' : ''}`}>

                    <Text
                        type={"body-text-2"}
                        fontWeight={"semi-bold-font"}
                        children={"Test language"}
                    />

                    <div className="participants-overview-footer-language d-flex">
                        <Icon value={language+"-language-icon"} size={"large"} />

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            children={language}
                        />
                    </div>



                </div>

            </Box>

        </div>


        <ReportTesterModal
            test={test}
            openModal={reportTesterModal.open}
            close={() => {
                setReportTesterModal({ ...reportTesterModal, open: false });
            }}
            confirm={(type, totalParticipantSelected) => {
                setReportTesterModal({ ...reportTesterModal, open: false });
            }}
        />

        </>
    );
};



OverviewParticipant.defaultProps = {
    userQPanel: PropTypes.bool,
    language: PropTypes.oneOf(["english","arabic"]),
    sharedLink:PropTypes.string
};
