import React, { useEffect, useState } from "react";
import { Text } from "../Text/Text";

export const QuestionCard = ({questiontype, onClick}) => {
    return (
            <div className="question-card-wrapper m-3" onClick={onClick}>
                <div className="question-card-inner">
                    <img className="question-card-image" src={process.env.REACT_APP_URL + "img/" + questiontype + ".svg"}/>
                    <Text type="body-text-2" fontWeight="medium">{questiontype}</Text>
                </div>
            </div>
            )
}