import React, { useEffect, useMemo, useState, useRef } from "react";
import { Group } from "@visx/group";
import { Tree, hierarchy } from "@visx/hierarchy";
import { LinkHorizontal } from "@visx/shape";
import { pointRadial } from 'd3-shape';
import getLinkComponent from './getLinkComponent';
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import { Text } from "../../../themes/userq/Text/Text";



const peach = "#fd9b93";
const pink = "#fe6e9e";
const blue = "#03c0dc";
const green = "#26deb0";
const plum = "#71248e";
const lightpurple = "#374469";
const white = "#ffffff";
export const background = "#272b4d";

const rawTree = {
	name: "T",
	children: [
		{
			name: "A",
			children: [
				{ name: "A1", percentage: 90 },
				{ name: "A2", percentage: 80 },
				{ name: "A3", percentage: 70 },
				{
					name: "C",
					children: [
						{
							name: "C1",
							percentage: 90,
						},
						{
							name: "D",
							children: [
								{
									name: "D1",
									percentage: 90,
								},
								{
									name: "D2",
									percentage: 90,
								},
								{
									name: "D3",
									percentage: 90,
								},
							],
						},
					],
				},
			],
		},
		{ name: "Z", percentage: 90 },
		{
			name: "B",
			children: [
				{ name: "B1", percentage: 90 },
				{ name: "B2", percentage: 90 },
				{ name: "B3", percentage: 90 },
			],
		},
	],
};

/** Handles rendering Root, Parent, and other Nodes. */
function Node({ node, radial, answer_path, handlePointerMove,handlePointerOut, test }) {
	const width = 120;
	const height = 30;
	const centerX = -width / 2;
	const centerY = -height / 2;
	const isRoot = node.depth === 0;
	const isParent = !!node.children;

	const renderTitle = (title) => {

		var title = title.trim();

		var result = title.replace(/^\s+|\s+$/gm,'');

		return result.length >13?result.substring(0,10)+"...":result;
	}

	const getStrokeColor = (id) => {
		var exist = false;

		answer_path.forEach(function (item) {
			if (item.id == id) {
				exist = true;
			}
		});
		if (exist) {
			return "rgba(55, 139, 16, 1)";
		} else {
			return "rgba(43, 43, 43, 1)";
		}
	};

	if (isParent) return <ParentNode test={test} radial={radial} node={node} answer_path={answer_path}  handlePointerOut={handlePointerOut} handlePointerMove={handlePointerMove} />;

	let top=0;
	let left=0;
	if (radial) {
	const [radialX, radialY] = pointRadial(node.x, node.y);
	top = radialY;
	left = radialX;
	}  else {
	top = node.x;
	left = node.y;
	}
	return (
		<Group top={top} left={left}>
			<rect
				height={height}
				width={width}
				y={centerY}
				x={centerX}
				fill={"white"}
				stroke={getStrokeColor(node.data.id)}
				strokeWidth={2}
				rx={10}
				onClick={() => {
					//alert(`clicked: ${JSON.stringify(node.data.value)}`);
				}}
                onMouseMove={ function(d){

                    handlePointerMove(d,node.data.title,node.data.participants, node.data);
                }}
                onMouseOut={ function(d){
                    handlePointerOut(d)
                }}
			/>
			<text
				dy=".33em"
				fontSize={"16"}
				className={`${test.language=='ar'?'arabic-font':''}`}
				fontFamily="inter"
				fontWeight="600"
				textAnchor="middle"
				fill={"rgba(43, 43, 43, 1)"}
				style={{ pointerEvents: "none" }}
			>
				{renderTitle(node.data.title)}
			</text>
		</Group>
	);
}

function ParentNode({ node, radial, answer_path,  handlePointerMove, handlePointerOut, test }) {
	const width = 120;
	const height = 30;
	const centerX = -width / 2;
	const centerY = -height / 2;

	const renderTitle = (title) => {

		var title = title.trim();

		var result = title.replace(/^\s+|\s+$/gm,'');

		return result.length >13?result.substring(0,10)+"...":result;
	}
	const getStrokeColor = (id) => {
		var exist = false;

		answer_path.forEach(function (item) {
			if (item.id == id) {
				exist = true;
			}
		});
		if (exist) {
			return "rgba(55, 139, 16, 1)";
		} else {
			return "rgba(151, 89, 203, 1)";
		}
	};
	let top=0;
	let left=0;
	if (radial) {
	const [radialX, radialY] = pointRadial(node.x, node.y);
	top = radialY;
	left = radialX;
	}  else {
	top = node.x;
	left = node.y;
	}

	return (
		<Group top={top} left={left}>
			<rect
				className={`${test.language=='ar'?'arabic-font':''}`}
				height={height}
				width={width}
				y={centerY}
				x={centerX}
				fill={"white"}
				stroke={getStrokeColor(node.data.id)}
				strokeWidth={2}
				onClick={() => {
					//alert(`clicked: ${JSON.stringify(node.data.value)}`);
				}}
                onMouseMove={ function(d){
                    handlePointerMove(d,node.data.title,node.data.participants, node.data);
                }}
                onMouseOut={ function(d){
                    handlePointerOut(d)
                }}
			/>
			<text
				dy=".33em"
				fontSize={16}
				className={`${test.language=='ar'?'arabic-font':''}`}
				fontFamily="inter"
				fontWeight="600"
				textAnchor="middle"
				style={{ pointerEvents: "none" }}
				fill={"rgba(43, 43, 43, 1)"}
			>
				{renderTitle(node.data.title)}
			</text>
		</Group>
	);
}

const defaultMargin = { top: 0, left: 0, right: 40, bottom: 0 };

export default function SankeyChart({
	depth,
	owidth,
	height,
	margin = defaultMargin,
	rawdata,
	answer_path,
	total_navigation,
	expandData,
	radial,
	test
}) {
	const data = hierarchy(rawdata);
	const [width, setWidth] = useState(owidth);
	const ref = useRef();
    const tipref = useRef();

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false , data
    });
    const handlePointerMove = (event, title, value,data) => {
        /*console.log(event)
        let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left + 30 ;
        let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top ;
        
		if(event.clientX + 360 + 30 > width){
			containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - 360 -60;
		}
		
		if(event.clientY + 224 +60 > window.innerHeight){
			containerY = ('clientY' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - 224 -30  ;
		}
		showTooltip({
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipData: title.length > 5 ? ( (parseInt(value) > 1 ? <>{title}<br/>{value} Participants</> : <>{title}<br/>{value} Participant</>) ) : ( (parseInt(value) > 1 ? <>{value} Participants</> : <>{value} Participant</>) ) ,
            open:true,
			data:data
        });*/

        let tipPosition = "bottom";
				
        var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 344;


        var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 250;

        
        

        let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
        let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;
        
        
        var window_width = window.innerWidth;

        var window_height = window.innerHeight;
    
        if(event.clientX + tool_width/2 > window_width){
            containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;
        
        }
        if(event.clientX - tool_width/2 < 0){
            containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
        }
        showTooltip({
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipData: title.length > 5 ? ( (parseInt(value) > 1 ? <>{title}<br/>{value} Participants</> : <>{title}<br/>{value} Participant</>) ) : ( (parseInt(value) > 1 ? <>{value} Participants</> : <>{value} Participant</>) ) ,
            open:true,
            show: tooltip.open && tipref && tipref.current ? true: false,
            tipPostion: tipPosition,
            data:data
        });
    }
    const handlePointerOut = (event)=>{
        showTooltip({...tooltip, open:false}) 
    }

	useEffect(() => {
		if(ref && ref.current){
			setWidth(ref.current.getBoundingClientRect().width);
			
			setXMax(
				ref.current.getBoundingClientRect().width - margin.left - margin.right
			);

			window.addEventListener('resize', function(){

				if(ref && ref.current){
					setWidth(ref.current.getBoundingClientRect().width);
				
					setXMax(
						ref.current.getBoundingClientRect().width - margin.left - margin.right
					);
				}
			});
		}


	}, [expandData, radial]);
	const yMax = height - margin.top - margin.bottom;
	const [xMax, setXMax] = useState(width - margin.left - margin.right);
	var strokeWidth = (perc) => {
		var max = 36;
		var min = 1;
		return (perc / 100) * (max-min) > 1 ? ((perc / 100) * (max-min)) + min : min;
	};
	var strokeColor = (link, answer_path2) => {
		var exist = false;

		answer_path2.forEach(function (item) {
			if (link.target && item.id == link.target.data.id) {
				exist = true;
			}
		});
		if (exist) {
			return "rgba(55, 139, 16, 1)";
		} else {
			return "rgba(156, 156, 156, 1)";
		}
	};
	const LinkComponent = getLinkComponent({"layout":radial ? 'polar':'cartesian', "linkType":  radial ? "line" : "diagonal", "orientation": "horizontal"});

	const innerWidth = radial ? xMax : xMax-82;
	const innerHeight = yMax;

	let origin =  { x: 0, y: 0 };
	let sizeWidth = 0;
	let sizeHeight = 0;

		if (radial) {
			origin = {
				x: innerWidth / 2,
				y: innerHeight / 2,
			};
			sizeWidth = 2 * Math.PI ;
			sizeHeight = Math.min(innerWidth-50, innerHeight-50) / 2 ;

			
		} else {
			
			origin = { x: 0, y: 0 };
			sizeWidth = innerHeight;
			sizeHeight = innerWidth;
		}
	return (
		<div ref={ref} style={{position:'relative'}}>
            {(tooltip.open)  ? (
				<>
					<Tooltip
						ref={tipref}
						type={tooltip.tipPosition}
						key={Math.random()} // needed for bounds to update correctly
						style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
						data={<>
                                <div className="chart-tooltip-top-tree">
                                    <div className="chart-tooltip-left-tree">
                                        <div className={` d-flex justify-content-between ${test.language=='ar'?'arabic-font':''}`}>
											<Text type={"body-text-2"} fontWeight={"semi-bold-font"}>{tooltip.data.title}</Text>
											
												<Text type={"caption"} fontWeight={"normal-font"}>{tooltip.data.participants} visit{tooltip.data.participants>1?'s':''}</Text>
											
										</div>
										<div className="tooltip-header-top tree-chart-tooltip-top-header">
                                        <Text type={"body-text-3"} fontWeight={"medium-font"}>Participants from here proceeded to:</Text>
										</div>
                                    </div>
                                    
                                </div>
                                <div  className="tree-chart-tooltip-table">
                                    <table>
                                        <tr>
                                            <td>Correct path</td>
                                            <td><span>{tooltip.data.correct_answer_count}</span> visit{tooltip.data.correct_answer_count>1?'s':''}</td>
                                            <td><span>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.correct_answer_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100): 0}%</span></td>
                                        </tr>
                                        <tr>
                                            <td>Incorrect path</td>
                                            <td><span>{tooltip.data.incorrect_answer_count}</span> visit{tooltip.data.incorrect_answer_count>1?'s':''}</td>
                                            <td><span>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.incorrect_answer_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100): 0}%</span></td>
                                        </tr>
                                        {tooltip.data.id!=-1 &&
                                        <tr>
                                            <td>Back</td>
                                            <td><span>{tooltip.data.back_count}</span> visit{tooltip.data.back_count>1?'s':''}</td>
                                            <td><span>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.back_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100) : 0}%</span></td>
                                        </tr>
                                        }
                                        <tr>
                                            <td>Selected answer</td>
                                            <td><span>{tooltip.data.nominated_answer_count}</span> visit{tooltip.data.nominated_answer_count>1?'s':''}</td>
                                            <td><span>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.nominated_answer_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100) : 0}%</span></td>
                                        </tr>
                                        <tr>
                                            <td>Skip Task</td>
                                            <td><span>{tooltip.data.skipped_count}</span> visit{tooltip.data.skipped_count>1?'s':''}</td>
                                            <td><span>{(tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count) >0 ? Math.round(tooltip.data.skipped_count / (tooltip.data.correct_answer_count+tooltip.data.incorrect_answer_count+tooltip.data.back_count+tooltip.data.nominated_answer_count+tooltip.data.skipped_count)*100) : 0}%</span></td>
                                        </tr>
                                    </table>
                                </div>
                        </>}
						className="tooltipChart"
					
					/>
				</>
				): (
					<></>
				)}
           
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
				<Tree root={data} size={[sizeWidth, sizeHeight]} separation={(a,b)=>{
					return 100;
				}} >
					{(tree) => (
						<>
							{//console.log(tree.links())
							 // - width / (depth + 2) / 2
							}
							<Group
							
								top={origin.y}
								left={origin.x+61}
							>
								{tree.links().map((link, i) => (
									<>
                                    
										<LinkComponent
											key={`link-${i}`}
											data={link}
											stroke={strokeColor(link, answer_path)}
											strokeWidth={strokeWidth(
												total_navigation > 0
													? (link.target.data.participants / total_navigation) * 100
													: 0
											)}
											fill="none"
										/>
									</>
								))}
								{tree.descendants().map((node, i) => (
									<>
										<Node
											test={test}
											key={`node-${i}`}
											node={node}
											radial={radial}
											answer_path={answer_path}
											handlePointerMove = {handlePointerMove}
											handlePointerOut={handlePointerOut}
										/>
									</>
								))}
							</Group>
						</>
					)}
				</Tree>
			</svg>
		</div>
	);
}
