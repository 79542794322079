import React, { useEffect } from "react";
import {useLayoutEffect, useRef, useState} from 'react';
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export const ChartSliderCombo = ({barData,min, max, step, onChange, min_val, max_val, onStartSliding, onStopSliding}) => {

   
    const chartAreaRef = useRef(null);

    const leftLabelRef = useRef(null);

    const rightLabelRef = useRef(null);

    const middleLabelRef = useRef(null);

    const [width, setWidth] = useState(0);

    const [height, setHeight] = useState(0);

    const [minVal, setMinVal] = useState(min_val);

    const [maxVal, setMaxVal] = useState(max_val);

    const [bar_data, setBarData] = useState(barData);

    const [minRangeVal, setMinRangeVal] = useState(min);

    const [maxRangeVal, setMaxRangeVal] = useState(max);

    const [labelPositions, setLabelPositions] = useState({"left":1,"right":1,"oneLeft":0,"labels":2});

    const [thumbPositions, setThumbPositions] = useState({"leftThumb":0, "rightThumb":0});

    let slideLeftStart = false;

    let slideRightStart = false;


    let prevPos = null;

    let icon_width = 0;

    useLayoutEffect(() => {
    
        setWidth(chartAreaRef.current.offsetWidth);
    
        setHeight(chartAreaRef.current.offsetHeight);

        //calcSliderLabels();
    
    }, []);

    useEffect(()=>{

        window.addEventListener('resize', function(barData,min,max, step, chartAreaRef) {

            if(chartAreaRef && chartAreaRef.current){
                setWidth(chartAreaRef.current.offsetWidth);
        
                setHeight(chartAreaRef.current.offsetHeight);

                setMinRangeVal(min);

                setMaxRangeVal(max);

                
                setBarData(barData);

                calcSliderLabels();
            }

        }, true);

        setMinRangeVal(min);

        setMaxRangeVal(max);
        
        setBarData(barData);
        calcSliderLabels();
        
    },[barData,min,max, step]);

    useEffect(()=>{

        setMinVal(min_val);

        setMaxVal(max_val);

        calcSliderLabels();
        
    },[min_val,max_val]);

    useEffect(()=>{
        calcSliderLabels();
        
        
    },[]);


    useEffect(()=>{
        calcSliderLabels();
        
        
    },[width]);

    const calcSliderLabels = ()=>{

       
        if(width){

           
            
            var left = ((min_val - minRangeVal) / (maxRangeVal-minRangeVal))*(width+icon_width);

            var right = ((max_val - minRangeVal) / (maxRangeVal-minRangeVal))*(width+icon_width);
            
        
            setThumbPositions({leftThumb:left-16, rightThumb:right-16});

            var middleLeft = left+(right-left)/2;



            var finalLeft, finalRight, finalMiddleLeft; 

            if(leftLabelRef && leftLabelRef.current){
                var leftLabelWidth = 60;

                finalLeft = left - leftLabelWidth/2;

                if(finalLeft < 0){
                    finalLeft = 0;
                }

                if(finalLeft +  leftLabelWidth  > width+icon_width){
                    finalLeft = width + icon_width- leftLabelWidth;
                }
            }
            if(rightLabelRef && rightLabelRef.current){
                
                var rightLabelWidth = 60;

                
                finalRight = right - rightLabelWidth/2;

                if(finalRight < 0){
                    finalRight = 0;
                }

                if(finalRight +  rightLabelWidth  > width+icon_width){
                    finalRight = width+icon_width - rightLabelWidth;
                }
                
                
            }
            if(middleLabelRef && middleLabelRef.current){
                var middleLabelWidth = 95;

                finalMiddleLeft = middleLeft - middleLabelWidth/2;

                if(finalMiddleLeft < 0){
                    finalMiddleLeft = 0;
                }

                if(finalMiddleLeft +  middleLabelWidth  >= width+icon_width){
                    finalMiddleLeft = width+icon_width - middleLabelWidth;
                }
            }
            var labels;
            
            if(max_val-min_val < 7*step){
                labels=1;
            } else {
                labels=2;
            }
            setLabelPositions({left:finalLeft, right:finalRight, oneLeft:finalMiddleLeft, labels:labels});
        }


    }

    const updateSlider = (value, type) => {
        
        if( (type=="min" && (parseFloat(maxVal)-parseFloat(value) >= 1) ) || (type=="max" && (parseFloat(value) - parseFloat(minRangeVal)  ) >= 1 ) ){

            if(type=="min" && (parseFloat(value) < parseFloat(maxVal)) ){
                setMinVal(value);

                if(onChange!=undefined){
                    onChange(value, maxVal);
                }
            }
            if(type=="max" && (parseFloat(value) > parseFloat(minVal)) ){
                setMaxVal(value);

                if(onChange!=undefined){
                    onChange(minVal, value);
                }
            }
           
        }
    }

    const renderChart = () => {

        
        var bars = [];

        var barWidth = width / bar_data.length;
        

        var bar_values = [];

        bar_data.forEach(function(item){
            bar_values.push(item.value);
        })
        var max_value = Math.max(...bar_values);

        

        var loop_index = 0;
        bar_data.forEach(function(item, index){
            
            if(height*(item.value/max_value) > 0){
                bars.push((
                    <div key={"bar"+index} className={`bar ${ (item.x +step > minVal && item.x  <= maxVal) ? '' : 'gray-bar'} `} style={
                        {width: (((2)/(maxRangeVal-minRangeVal))*100)+"%", borderRight:"1px solid #FFF", left: (((loop_index)/(maxRangeVal-minRangeVal))*100)+"%", height: Math.round(height*(item.value/max_value))+"px", top:(height - Math.round(height*(item.value/max_value)))+"px"}
                    }></div>
                ))
            } else {
                bars.push((
                    <div key={"bar"+index} className={`bar gray-bar `} style={
                        {width: (((2)/(maxRangeVal-minRangeVal))*100)+"%", borderRight:"1px solid #FFF", left: (((loop_index)/(maxRangeVal-minRangeVal))*100)+"%", height: "0px", bottom:"0px"}
                    }></div>
                ))
            }
            loop_index= loop_index+2;

           
        });
        return bars;
    }
    return (
        <div className="chartSliderCombo"
        
        >
            <div style={{padding:"0 0px"}}>
                <div className="chartArea" ref={chartAreaRef} style={{height:"200px"}}>
                    {renderChart()}
                </div>
            </div>
            {/*<div className="sliderArea"
                
            >
                <div 
                    className={"slider-thumb"} 
                    draggable={true}
                    style={{top:"-18px", left:thumbPositions.leftThumb+"px"}}
                    onDragStart={(e)=>{
                        return false;
                    }} 
                    onMouseDown={(e)=>{
                        slideLeftStart = true;
                        prevPos = e.clientX;
                    }}
                    onMouseMove={(e)=>{
                       
                        if(slideLeftStart){
                            if(prevPos <= e.clientX){
                                
                                prevPos = e.clientX;
                                  
                                if(min_val+step <= maxRangeVal && min_val+step <= max_val){
                                    
                                    updateSlider(min_val+step,"min");
                                }                          
                            }
            
                            if(prevPos >= e.clientX){
            
                                prevPos = e.clientX;
            
                                if(min_val-step >= minRangeVal && min_val-step <= max_val){
                                    updateSlider(min_val-step,"min");
                                }
                            }
                        }
                        if(slideRightStart){
            
                            if(prevPos < e.clientX){
            
                                prevPos = e.clientX;
                                  
                                if(maxVal+step <= maxRangeVal && maxVal+step >= minVal){
                                    updateSlider(maxVal+step,"max");
                                }                          
                            }
            
                            if(prevPos > e.clientX){
            
                                prevPos = e.clientX;
            
                                if(maxVal-step >= minRangeVal && maxVal-step >= minVal){
                                    updateSlider(maxVal-step,"min");
                                }
                            }
                        }
                    }}            
                    onMouseUp={(e)=>{
                        slideLeftStart = false;
                    }}>
                </div>
                <div 
                    className={"slider-thumb"} 
                    style={{top:"-18px", left:thumbPositions.rightThumb+"px"}}
                    onMouseDown={(e)=>{
                        slideRightStart = true;
                        prevPos = e.clientX;
                    }}
                    
                    onMouseUp={(e)=>{
                        slideRightStart = false;
                    }}
                >
                </div>
            </div>*/}
            <div className="sliderArea"
                 onMouseDown={(e)=>{

                    if(onStartSliding){
                        onStartSliding();
                    }
                }}
                onMouseUp={(e)=>{onStopSliding()}}
                onMouseLeave={(e)=>{onStopSliding()}}
            >
                <Slider
                    range={true}
                    min={minRangeVal}
                    max={maxRangeVal}
                    step={1}
                    value={[minVal, maxVal]}
                    onChange={(values)=>{
                        
                        onChange(values[0], values[1]);
                    }}
                   
                />

                <input
                    type="range"
                    min={minRangeVal}
                    max={maxRangeVal}
                    value={minVal}
                    step={1}
                    onChange={(e)=>{updateSlider(e.target.value,"min")}}
                    onMouseDown={(e)=>{

                        if(onStartSliding){
                            onStartSliding();
                        }
                    }}
                    onMouseUp={(e)=>{onStopSliding()}}
                    className="thumb thumb--zindex-3"
                />
                <input
                    type="range"
                    min={minRangeVal}
                    max={maxRangeVal}
                    step={1}
                    value={maxVal}
                    onChange={(e)=>{updateSlider(e.target.value,"max")}}
                    onMouseDown={(e)=>{
                        if(onStartSliding){
                            onStartSliding();
                        }
                    }}
                    onMouseUp={(e)=>{onStopSliding()}}
                    className="thumb thumb--zindex-4"
                />
                <div className="slider">
                    <div className="slider__track" />
                    <div className="slider__range" />
                    
                    <>
                    <div ref={leftLabelRef} style={{left:labelPositions.left+"px", display:labelPositions.labels==2?'block':'none'}} className="slider__left-value">
                        <Text type="body-text-1">{minVal} years</Text>
                    </div>
                    <div ref={rightLabelRef} style={{left:labelPositions.right+"px", display:labelPositions.labels==2?'block':'none'}} className="slider__right-value">
                        <Text type="body-text-1">{maxVal} years</Text>
                    </div>
                    </>
                    
                    
                    <div ref={middleLabelRef} style={{left:labelPositions.oneLeft+"px", display:labelPositions.labels==1?'block':'none'}} className="slider__middle-value">
                        <Text type="body-text-1">{minVal} - {maxVal} years</Text>
                    </div>
                    
                </div>
            </div>
        </div>        
    );
};
ChartSliderCombo.defaultProps = {
    barData: [{"label":"0-10", "value":0}, {"label":"11-20", "value":5}, {"label":"21-30", "value":10}, {"label":"31-40", "value":15}, {"label":"41-50", "value":20}, {"label":"51-60", "value":20}, {"label":"61-70", "value":15}, {"label":"71-80", "value":10}, {"label":"81-90", "value":5}, {"label":"91-100", "value":0}]
    
};