import React from "react";
import { Modal } from "react-bootstrap";

export default function CtaActionModal({
    open,
    message,
    title,
    close_action,
    primary_btn_title,
    secondary_btn_title,
    primary_action,
    secondary_action,
}) {
    
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  mid-width-modal cta-confirm-modal confirm-modal confirm-modal-wrapper"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            close_action();
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img
                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                alt="cross"
                            />
                        </span>
                    </button>
                    <h2 className="h2">{title}</h2>
                    <p>{message}</p>
                    <div className="confirm-buttons-div button-wrap d-flex">
                        {secondary_btn_title &&
                        <button
                            type="button"
                            className="button secondary-btn"
                            onClick={() => {
                                secondary_action();
                            }}
                            aria-label="Close"
                        >
                            {secondary_btn_title ? secondary_btn_title : 'CANCEL'}
                        </button>
                        }
                        {primary_btn_title &&
                        <button
                            type="button"
                            className="button primary-btn"
                            onClick={() => {
                                primary_action();
                            }}
                            aria-label="Close"
                        >
                            {primary_btn_title ? primary_btn_title : "OK"}
                        </button>   
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
