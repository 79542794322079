import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";

export default function SuccessDefaultModal({
    icon,
    message,
    btn,
    open,
    close,
    cssClass
}) {
    const closeModal = () => {
        close();
    };
    

    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap  ${cssClass}`}
        >
            
            <Modal.Body className="" ref={wrapperRef}>
            

                <div className="congrats-modal-text">

                    {icon}
                    {message}

                    <div className="congrts-modal-wrap">
                        {btn}
                    </div>

                </div> 
            </Modal.Body>
        </Modal>
    );
}
