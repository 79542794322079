import React, { useEffect, useRef, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import validator from "validator";
import { dettachPaymentMethodService, dismissNewAdminModalService, downgradeWorkspaceService, getWorkspaceBillingService, reactivateWorkspaceService, updateBillingCycleService, upgradeWorkspaceService } from "../../../../services/workspaces";
import {
    amountFormat,
    showToast,
    encryptClient
} from "../../../../lib/helpers.js";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon.js";
import { Helmet } from "react-helmet";
import { Tag } from "../../../themes/userq/Tag/Tag.js";
import { getPaymentMethodsService } from "../../../../services/payment_methods.js";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton.js";
import moment from "moment";
import { getInvoicesService } from "../../../../services/invoices.js";
import BillingInfoModal from "../modals/billing-info-modal.js";
import { countries } from "../../../../data/stripe_countries.js";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip.js";
import "./workspace-billing.css";
import ReviewPaymentModal from "../modals/review-payment-modal.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage.js";
import Skeleton from "react-loading-skeleton";
import DowngradeWorkspaceModal from "../modals/downgrade-workspace-modal.js";
import ConfirmDowngradeWorkspaceModal from "../modals/confirm-downgrade-workspace-modal.js";
import ReactivateWorkspaceModal from "../modals/reactivate-workspace-modal.js";
import { useTestBuilderData } from "../../tests/contexts/TestBuilderContext.js";
import ChangebillingcycleModal from "../modals/changebillingcycle-modal.js";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const  WorkspaceBiling = (props)=> {
    return (<Elements stripe={stripePromise}>
        <PaymentComponent {...props} />
    </Elements>);
};
const PaymentComponent = (props) => {
    ReactSession.setStoreType("localStorage");

    const stripe = useStripe();

    const { workspaceid } = useParams();

    const [formErrors, setFormErrors] = useState({
        card_no: null,
        card_name: null,
        expiration_date: null,
        cvv: null,
        error_class: null,
    });

    const [formValues, setFormValues] = useState({
        card_no: "",
        exp_date: "",
        cvv: "",
        card_name: "",
        payment_method_id: ""
    });

    const [changeBillingCycleModal, setChangeBillingCycleModal] = useState({open:false});

    const [pageLoading, setPageLoading] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const navigate = useNavigate();

    const [user, setUser] = useState(ReactSession.get("user"));

    const [afterCardAction, setAfterCardAction] = useState(null);

    const [workspace, setWorkspace] = useState({
        id:null,
        name: null,
        plan: null,
        maxSeats: 0,
        availableSeats: 0,
        billingCycle: null,
        planRenewalAmount: 0,
        nextPaymentDate: null,
        is_downgrade_scheduled: 0,
        status: null,
        paymentMethod: null,
        show_payment_method_saved_msg:0,
        is_new_admin_proposed:0,
        is_new_admin_popup:0,
        nextBillingCyclePlan: null,
        nextBillingCycleEffectiveDate: null
    });

    const [billingInfo, setBillingInfo] = useState({
        name: null,
        country: null,
        city: null,
        address: null,
        email: null,
    });

    const [workspaceAdmin, setWorkspaceAdmin] = useState(false);
    const [workspaceType, setWorkspaceType] = useState();

    const [billingModal, setBillingModal] = useState({
        open: false,
    });

    const [paymentMethodCount, setPaymentMethodCount] = useState(0);

    const [reviewPaymentModal, setReviewPaymentModal] = useState({
        open: false,
    });

    const [addNewCard, setAddNewCard] = useState(false);

    const [futurePaymentCard, setFuturePaymentCard] = useState({
        paymentMethodId : null,
        brand : null,
        last_4 : null,
        default : 0,
        id: null
    })

    const [changePaymentMethodLoading, setChangePaymentMethodLoading] = useState(false);

    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

    const [sameCardError, setSameCardError] = useState(false);

    const [reactivateWorkspaceModal, setReactivateWorkspaceModal] =
        useState({
            open: false,
        });

    const [downgradeWorkspaceModal, setDowngradeWorkspaceModal] = useState({
        open: false,
    });

    const [confirmDowngradeWorkspaceModal, setConfirmDowngradeWorkspaceModal] =
        useState({
            open: false,
        });

    const open = () => {
        setConfirmDowngradeWorkspaceModal((prevState) => ({
            ...prevState,
            open: true,
        }));
    };

    const close = () => {
        setConfirmDowngradeWorkspaceModal((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    const openModal = () => {
        setDowngradeWorkspaceModal((prevState) => ({
            ...prevState,
            open: true,
        }));
    };

    const closeModal = () => {
        setDowngradeWorkspaceModal((prevState) => ({
            ...prevState,
            open: false,
        }));
        
    };




    const scrollContainerRef = useRef(null);

    const [isScrolled, setIsScrolled] = useState(false);

    const [invoiceLoading, setInvoiceLoading] = useState(false);

    const [invoicePage, setInvoicePage] = useState(1);

    const [order, setOrder] = useState("");

    const [hasInvoices, setHasInvoices] = useState(true);

    const [firstLoading, setFirstLoading] = useState(true);

    const [invoices, setInvoices] = useState([]);

    const [teamMember, setTeamMember] = useState(null);

    const [downgradeLoading, setDowngradeloading] = useState(false);

    const [reactivateLoading, setReactivateloading] = useState(false);

    const [dettachLoading, setDettachloading] = useState(false);

    const {events,setFrozenWorkspaceModal} = useTestBuilderData();

    const [billingCycleLoading, setBillingCycleLoading] = useState(false);

    useEffect(() => {

        events.forEach(function(event){
            if(event.event_type === "frozen_workspace_active"){
                if(workspace && workspace.id === event.event_id){
                    
                    let workspaceLoc   = Object.assign({}, workspace);
                    workspaceLoc.status = "active";

                    setWorkspace(workspaceLoc);

                    setFrozenWorkspaceModal({open:false});
                }
            } else if(event.event_type === "workspace_admin_added"){

                if(workspace && workspace.id === event.event_id && user && user.user_id === event.user_id){
                    
                    getBillingInfo();
                    
                }

            } 
        })
    },[events]);

    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener("scroll", handleScroll);
            };
        }
    }, [invoices]);

    const fetchInvoices = () => {
        let token = ReactSession.get("token");

        if (firstLoading) {
            setPageLoading(true);
        } else {
            setInvoiceLoading(true);
        }

        getInvoicesService(invoicePage, order ? order : "desc", workspaceid, token).then(
            (response) => {
                if (firstLoading) {
                    setPageLoading(false);
                } else {
                    setInvoiceLoading(false);
                }

                setFirstLoading(false);

                if (response.success) {
                    setInvoices(invoices.concat(response.invoices));
                    if (response.more_records) {
                        setHasInvoices(true);
                        if (!order) {
                            // setOrder("desc");
                        }
                    } else {
                        setHasInvoices(false);
                    }
                    setInvoicePage(invoicePage + 1);
                } else {
                    showToast(response.message, "error");
                }
            }
        );
    };

    useEffect(() => {
        
        getBillingInfo();
        
    }, []);

    useEffect(() => {
        if (order) {
            let token = ReactSession.get("token");

            // setInvoiceLoading(true);

            getInvoicesService(1, order, workspaceid, token).then((response) => {
                //   setInvoiceLoading(false);

                if (response.success) {
                    setInvoices(response.invoices);
                    if (response.more_records) {
                        setHasInvoices(true);
                    } else {
                        setHasInvoices(false);
                    }
                    setInvoicePage(2);
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    }, [order]);

    const renderInvoices = () => {
        var invoiceRender = [];
        invoices.forEach(function (invoice) {
            var coupon = "--";
            if (invoice.type === "credits" && invoice.purchase) {
                coupon = invoice.purchase.promo_code
                    ? invoice.purchase.promo_code
                    : invoice.purchase &&
                        invoice.purchase.credits > invoice.purchase.amount
                        ? "Bulk purchase"
                        : "--";
            } else if (invoice.type === "test" && invoice.purchase) {
                coupon = invoice.purchase.coupon_code
                    ? invoice.purchase.coupon_code
                    : "--";
            } else if (invoice.type === "workspace" && invoice.purchase) {
                coupon = invoice.purchase.coupon_code
                    ? invoice.purchase.coupon_code
                    : "--";
            }

            var discount = 0;
            if (invoice.type === "credits" && invoice.purchase) {
                discount =
                    invoice.purchase.promo_code && invoice.purchase
                        ? invoice.purchase.credits - invoice.purchase.amount
                        : invoice.purchase &&
                            invoice.purchase.credits > invoice.purchase.amount
                            ? invoice.purchase.credits - invoice.purchase.amount
                            : 0;
            } else if (invoice.type === "test" && invoice.purchase) {
                discount = invoice.purchase.discount;
            } else if (invoice.type === "workspace" && invoice.purchase) {
                discount = invoice.purchase.coupon_discount;
            }

            var subTotal = 0;
            if (invoice.type === "credits" && invoice.purchase) {
                subTotal = invoice.purchase ? invoice.purchase.credits : 0;
            } else if (invoice.type === "test" && invoice.purchase) {
                subTotal =
                    invoice.purchase.total_fees - invoice.purchase.credits_used;
            } else if (invoice.type === "workspace" && invoice.purchase) {
                subTotal =
                    invoice.purchase.amount;
            }

            var total = 0;
            if (invoice.type === "credits" && invoice.purchase) {
                total = invoice.purchase ? invoice.purchase.amount : 0;
            } else if (invoice.type === "test" && invoice.purchase) {
                total = invoice.purchase.amount;
            } else {
                if(invoice.status === "Failed"){
                    total = invoice.invoice_total ;
                } else {
                    total = invoice.invoice_total / 100;
                }
            }

            invoiceRender.push(
                <tr key={"invoice" + invoice.id}>
                    <td>
                        {moment(invoice.date_of_invoice).format("DD-MM-YY")}
                    </td>
                    <td>{invoice.description}</td>
                    <td>{coupon}</td>
                    <td>${subTotal.toFixed(2)}</td>
                    <td>{+discount.toFixed(2) > 0 ? `-$${discount.toFixed(2)}`: `$${discount.toFixed(2)}`}</td>
                    <td>${total.toFixed(2)}</td>
                    <td>
                        {invoice.status === "paid" ||
                            invoice.status === "Paid" ? (
                            <span className="paid-status">
                                {invoice.status}
                            </span>
                        ) : (
                            <span className="failed-status">
                                {invoice.status}
                            </span>
                        )}
                    </td>
                    <td>{invoice.notes ? invoice.notes : "--"}</td>
                    <td>
                        {invoice.status && invoice.status.toLowerCase() === "paid" &&
                        <>
                            {invoice.purchase.billing_address ?
                            <Link to={"/inv/"+invoice.id} target="_blank">
                                <Icon size={"small"} value={"billing"} />
                            </Link>
                            :
                                <>{invoice.invoice_url ? (
                                    <a href={invoice.invoice_url} target="_blank">
                                        <Icon size={"small"} value={"billing"} />
                                    </a>
                                ) : (
                                    ""
                                )}</>
                                }
                        </>
                    }
                    </td>
                </tr>
            );
        });
        return invoiceRender;
    };


    const getBillingInfo = () => {
        setPageLoading(true);
        let token = ReactSession.get("token");

        getWorkspaceBillingService(token, workspaceid).then((response) => {
            // setPageLoading(false);
            if (response.success) {

                if (response.is_admin) {
                    setWorkspaceAdmin(true);
                }
                if(response.workspace_team_member){
                    setTeamMember(response.workspace_team_member);
                }
                if (response.workspace) {
                    let country = null;

                    //console.log(response.billing_address);
                    countries.forEach(function (item) {
                        if (item.value == response.workspace.billing_country) {
                            country = item.label;
                        }
                    });

                    setBillingInfo({
                        ...billingInfo,
                        name: response.workspace.billing_name
                            ? response.workspace.billing_name
                            : "",
                        country: country,
                        city: response.workspace.billing_city
                            ? response.workspace.billing_city
                            : "",
                        address: response.workspace.billing_address_line1
                            ? response.workspace.billing_address_line1
                            : "",
                        email: response.workspace.billing_email
                            ? response.workspace.billing_email
                            : "",
                    });

                    if (response.workspace.type === "Team") {
                        setWorkspaceType("Team");
                        setWorkspace({
                            ...workspace,
                            id: response.workspace.id,
                            name: response.workspace.title
                                ? response.workspace.title
                                : null,
                            plan: response.workspace.type
                                ? response.workspace.type
                                : null,
                            maxSeats: response.seats ? response.seats : 0,
                            availableSeats: response.seats
                                ? +response.seats -
                                +response.workspace.workspace_team_members
                                    .length
                                : 0,
                            billingCycle: response.workspace.plan
                                ? response.workspace.plan.billing_cycle
                                : null,
                            planRenewalAmount: response.workspace.amount_due ? response.workspace.amount_due : 0,
                            nextPaymentDate: response.workspace.next_billing_date ? moment(response.workspace.next_billing_date).format("DD/MM/YYYY") : null,
                            status: response.workspace.status
                                ? response.workspace.status
                                : null,
                            is_downgrade_scheduled: response.workspace.is_downgrade_scheduled,
                            paymentMethod: response.workspace.payment_method ? response.workspace.payment_method : null,
                            show_payment_method_saved_msg: response.workspace.show_payment_method_saved_msg ? response.workspace.show_payment_method_saved_msg : null,
                            is_new_admin_proposed : response.workspace.is_new_admin_proposed ? response.workspace.is_new_admin_proposed : 0,
                            is_new_admin_popup : response.workspace.is_new_admin_popup ? response.workspace.is_new_admin_popup : 0,
                            nextBillingCyclePlan: response.workspace.next_billing_cycle_plan ? response.workspace.next_billing_cycle_plan: null,
                            nextBillingCycleEffectiveDate: response.workspace.next_billing_cycle_effective_date ? moment(response.workspace.next_billing_cycle_effective_date).format("DD/MM/YYYY"): null
                        });
                    } else {
                        setWorkspaceType("Individual");
                        setWorkspace({
                            ...workspace,
                            name: response.workspace.title
                                ? response.workspace.title
                                : null,
                            plan: response.workspace.type
                                ? response.workspace.type
                                : null,
                            planRenewalAmount: response.workspace.amount_due ? response.workspace.amount_due : 0,
                            nextPaymentDate: response.workspace.next_billing_date ? moment(response.workspace.next_billing_date).format("DD/MM/YYYY") : null,
                            status: response.workspace.status
                                ? response.workspace.status
                                : null,
                            is_downgrade_scheduled: response.workspace.is_downgrade_scheduled,
                            paymentMethod: response.workspace.payment_method ? response.workspace.payment_method : null,
                            show_payment_method_saved_msg: response.workspace.show_payment_method_saved_msg ? response.workspace.show_payment_method_saved_msg : null,
                            nextBillingCyclePlan: response.workspace.next_billing_cycle_plan ? response.workspace.next_billing_cycle_plan: null,
                            nextBillingCycleEffectiveDate: response.workspace.next_billing_cycle_effective_date ? moment(response.workspace.next_billing_cycle_effective_date).format("DD/MM/YYYY"): null
                        });
                    }
                }

                if (response.payment_methods_count) {
                    setPaymentMethodCount(+response.payment_methods_count);
                }

                if(response.plan_payment_method) {
                    setFuturePaymentCard({
                        ...futurePaymentCard,
                        paymentMethodId : response.plan_payment_method.stripe_payment_method_id,
                        default: response.plan_payment_method.default,
                        last_4: response.plan_payment_method.last_4,
                        brand : response.plan_payment_method.brand,
                        id : response.plan_payment_method.id
                    })
                    if(response.workspace.is_new_admin_proposed === 1 && response.is_admin ){
                        getPaymentMethods(response.plan_payment_method.id, false, response.workspace.is_new_admin_popup === 1 ? true : false, response.workspace.id);
                    } else {
                        getPaymentMethods(response.plan_payment_method.id);
                    }
                    
                } else {

                    if(response.workspace.is_new_admin_proposed === 1 && response.is_admin){
                        getPaymentMethods(null, false,  response.workspace.is_new_admin_popup === 1 ? true : false, response.workspace.id);
                    } else {
                        getPaymentMethods();
                    }
                    
                }


                // setPageLoading(false);
                
            } else {
                if (response.message == "Workspace not found.") {
                    navigate("/404/");
                } else if (
                    response.message == "Workspace project not found."
                ) {
                    navigate("/404/");
                } else {
                   
                    setPageLoading(false);
                showToast(response.message, "error");
                }
                
            }
        });
    };


    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method) {
            render.push((
                <div key={"methods" + method.id}>
                    <div  className="payments-cards d-flex addedpayment-cards-wrap">

                        <div className="card-left-side">
                            {method.card_expire_status != 2 &&
                            
                            <RadioButton
                                id={method.stripe_payment_method_id}
                                type="radio"
                                isChecked={
                                    formValues.payment_method_id == method.id ? true : false
                                }
                                onChange={() => {
                                    if(!changePaymentMethodLoading){
                                        setFormValues({ ...formValues, payment_method_id: method.id, card_no: "", cvv: "", exp_date: "" });
                                        setSameCardError(false);
                                    }
                                }}
                                name="payment_method"
                                value={method.stripe_payment_method_id}
                                className="hidden radio_btn_input"
                                required="required"
                            />
                            }
                            <label htmlFor={method.stripe_payment_method_id} className="mt-0 d-flex">
                                <img src={process.env.REACT_APP_URL + "images/" + method.brand + "-icon.svg"} alt="img" />
                            </label>

                        </div>
                        <div className="card-right-side">
                            {method.card_expire_status == 2 
                            ?
                                <Tag title="Expired" type="danger-tag" />
                            :
                            <>
                            {method.default == 1 &&
                                <Tag title="Primary" />
                            }
                            </>
                            }
                            

                            <Text type={"body-text-3"}>**** {method.last_4}</Text>

                            {method.default == 0 &&
                                <></>
                            }
                        </div>
                    </div>
                    {((formValues.payment_method_id !== "" && formValues.payment_method_id === method.id && method.id !== futurePaymentCard.id) ? true : false) && (
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={
                                "gray-color d-flex align-items-center mt-20 crad-saved-for-future"
                            }
                            key={"methods-tick" + method.id}
                        >
                            <Icon
                                value={"check mark"}
                                size={"medium"}
                                colorClass={"success-green-200-svg"}
                            />
                            This card will be used for the future subscription payments
                        </Text>
                    )}

                    {((method.id === futurePaymentCard.id) ? true : false) && (
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={
                                "gray-color d-flex align-items-center mt-20 crad-saved-for-future"
                            }
                            key={"future-methods-tick" + method.id}
                        >
                            <Icon
                                value={"check mark"}
                                size={"medium"}
                                colorClass={"success-green-200-svg"}
                            />
                            This card is currently being used for your workspace subscription
                        </Text>
                    )}


                </div>
            ))
        })

        if (render.length > 0) {
            return (
                <>
                    <div className="cardlabel-box">
                        <Text type={"overline"} cssClasses={'saved-card'}>Saved cards</Text>
                        {render}
                    </div>
                </>

            );
        } else {
            return (
                <></>
                // <div className="no_payment_add_box">
                //     <Text type={"subtitle-2"} fontWeight={"medium-font"} cssClasses={'no_payment_add'}>
                //         <Icon value="attention" colorClass={'gray-700-svg attention-payment'} />
                //         No payment method added.
                //     </Text>
                // </div>
            );
        }

    }
    const getPaymentMethods = (paymentId = null, paymentLoad = false, isNewAdmin = false, workspaceId = null) => {
        let token = ReactSession.get("token");
        let paymentMethodModal = ReactSession.get("payment_method_modal");


        getPaymentMethodsService(token).then(response => {
            if(paymentLoad){
                setPaymentMethodLoading(false)
            } else {
                setPageLoading(false);
            }
            

            if (response.success) {

                var exist = false;
                response.payment_methods.forEach(function (method) {
                    // if (method.default == 1) {
                    //     exist = true;
                    //     setFormValue("payment_method_id", method.id);
                    // }

                    if(method.id == paymentId){
                        exist = true;
                        setFormValue("payment_method_id", method.id);
                    }
                })
                if (!exist) {
                    if (response.payment_methods.length > 0) {
                        setFormValue("payment_method_id", response.payment_methods[0].id);
                    }
                }
                setPaymentMethods(response.payment_methods);

                // check if user have not added any card then adding new card will be enable automatically
                if (response.payment_methods.length === 0) {
                    setAddNewCard(true);
                }

                if(!paymentLoad){
                    fetchInvoices();
                } else {
                    setPaymentMethodCount(response.payment_methods.length);
                }

                if(paymentMethodModal && paymentMethodModal.workspace_id === workspaceid && paymentMethodModal.open){
                    if(paymentMethodModal.status && paymentMethodModal.status === "reactivate"){
                        setReactivateWorkspaceModal({open:true});

                        setAfterCardAction("reactivate");

                    } else {
                        setReviewPaymentModal({
                            ...reviewPaymentModal,
                            open: true,
                        });
                    }
                    
                    ReactSession.set("payment_method_modal", null);

                    if(afterCardAction === 'reactivate') {
                        reactivateWorkspace()
                    }
                }

                if(isNewAdmin){
                        setReviewPaymentModal({
                            ...reviewPaymentModal,
                            open: true,
                        });

                        dismissNewAdminModalService(
                            { workspace_id: workspaceId },
                            ReactSession.get("token"),
                        ).then((response) => {});
                }

                

            } else {

                showToast(response.message, "error");

            }
        });
    }


    const resetPaymentMethods = () => {
        setSameCardError(false);
        var exist = false;
        paymentMethods.forEach(function (method) {
            // if (method.default == 1) {
            //     exist = true;
            //     // setFormValue("payment_method_id", method.id);
            //     setFormValues({
            //         ...formValues,
            //         card_no: "",
            //         exp_date: "",
            //         cvv: "",
            //         payment_method_id: method.id
            //     })
            // }

            if(method.id == futurePaymentCard.id){
                exist = true;
                setFormValues({
                    ...formValues,
                    card_no: "",
                    exp_date: "",
                    cvv: "",
                    payment_method_id: method.id
                })
            }
        })
        if (!exist) {
            if (paymentMethods.length > 0) {
                setFormValues({
                    ...formValues,
                    card_no: "",
                    exp_date: "",
                    cvv: "",
                    payment_method_id: paymentMethods[0].id
                })
            }
        }

        // check if user have not added any card then adding new card will be enable automatically
        if (paymentMethods.length === 0) {
            setAddNewCard(true);
            setFormValues({
                ...formValues,
                card_no: "",
                exp_date: "",
                cvv: "",
                payment_method_id: ""
            })
        } else {
            setAddNewCard(false);
        }
    }



    


    const validatePaymentForm = () => {
        var error = false;


        if (formValues.card_no == "") {
            error = true;

        } else if (!validator.isCreditCard(formValues.card_no)) {
            error = true;

        }

        if (formValues.exp_date == "") {
            error = true;

        }
        var new_exp_date = formValues.exp_date.replaceAll(" ", "");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {

            if (new Date().getTime() > new Date("20" + new_exp_date_arr[1] + "-" + new_exp_date_arr[0] + "-01").getTime() || isNaN(new Date("20" + new_exp_date_arr[1] + "-" + new_exp_date_arr[0] + "-01").getTime())
            ) {

                error = true;

            }

        } else if (new_exp_date.length > 0) {

            error = true;

        }
        if (formValues.cvv == "") {

            error = true;

        }
        return error;
    };


    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));

    };

    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const frozenPaymentMethodUpdate = () => {
        // let payment_method_modal = {
        //     open : true,
        //     workspace_id : workspaceid,
        //     status: "frozen"
        // };
        ReactSession.set("payment_method_modal", null);
        // navigate(`/wsp/${workspaceid}/workspace-billing`);
        setReviewPaymentModal({
            ...reviewPaymentModal,
            open: true,
        });
    }
    const confirmDowngrade = (reasons)=>{
        setDowngradeloading(true);

        var formValues = {reasons: reasons, workspace_id: workspaceid};

        let token = ReactSession.get("token");

        downgradeWorkspaceService(formValues, token).then((response) => {
            
            setDowngradeloading(false);
            
            setConfirmDowngradeWorkspaceModal({...confirmDowngradeWorkspaceModal, open:false});

            if (response.success) {

                setWorkspace({...workspace, 
                    is_downgrade_scheduled:1, 
                    planRenewalAmount: response.workspace.amount_due,
                    "next_billing_cycle_plan_id": null,
                    "nextBillingCycleEffectiveDate":  null,
                    "nextBillingCyclePlan": null
                });

                showToast(response.message, "success");

            } else {
                setPageLoading(false);
                showToast(response.message, "error");
            }
        });
    }
    const reactivateWorkspace = (reasons)=>{
        setReactivateloading(true);

        var formValues = {workspace_id: workspaceid};

        let token = ReactSession.get("token");

        reactivateWorkspaceService(formValues, token).then((response) => {
            
            setReactivateloading(false);

            setReactivateWorkspaceModal({...reactivateWorkspaceModal, open:false});

            if (response.success) {
                setWorkspace({...workspace, is_downgrade_scheduled:0});
                
                showToast(response.message, "success");

            } else {
                if(response.error_code && response.error_code === "no_payment_method"){
                    setReviewPaymentModal({
                        ...reviewPaymentModal,
                        open: true,
                    });
                } else {
                    showToast(response.message, "error");
                }
            }
        });
    }
    const updateBillingCycle = (billing_cycle)=>{
        
        setBillingCycleLoading (true);

        var formValues = {workspace_id: workspaceid, billing_cycle:billing_cycle};

        let token = ReactSession.get("token");

        updateBillingCycleService(formValues, token).then((response) => {
            
            setBillingCycleLoading(false);

           
            if (response.success) {
                setWorkspace({...workspace, 
                    "next_billing_cycle_plan_id": response.workspace.next_billing_cycle_plan_id,
                    "nextBillingCycleEffectiveDate":  response.workspace.next_billing_cycle_effective_date ? moment(response.workspace.next_billing_cycle_effective_date).format("DD/MM/YYYY"): null,
                    "nextBillingCyclePlan": response.workspace.next_billing_cycle_plan,
                    "planRenewalAmount": response.workspace.amount_due,
                });
                
                setChangeBillingCycleModal({...changeBillingCycleModal, open:false});
                //showToast(response.message, "success");

            } else {
                showToast(response.message, "error");
            }
        });
    }
    const dettachCardFromSubscription = ()=>{
        setDettachloading(true);

        var formValues = {workspace_id: workspaceid};

        let token = ReactSession.get("token");

        dettachPaymentMethodService(formValues, token).then((response) => {
            
            setDettachloading(false);

            
            if (response.success) {
                setWorkspace({...workspace, payment_method_id:null});

                setFuturePaymentCard({...futurePaymentCard, paymentMethodId:null, id:null});
                
                showToast(response.message, "success");

            } else {
                showToast(response.message, "error");
            }
        });
    }
    

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass="workspace-billing-hold"
        >
            <Helmet>
                <title>{"Workspace billing"} | UserQ</title>
            </Helmet>

            <div className="billing-workspace-wrapper">
                {pageLoading ? (
                    <>
                        <div className="workspace-page-loader">
                            <div
                                className={
                                    "page-loader accountloading-box creditloading-box"
                                }
                            >
                                <LoadingIcon />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {workspace && workspace.is_downgrade_scheduled==1 && teamMember && teamMember.role === "admin" &&
                        <div className="mb-40">
                            <ToastMessage
                                icon={"group"}
                                type={"info"}
                                message={
                                    <>
                                        <div className="d-flex align-items-center freez-toast-wrap justify-content-between">
                                            <span className="mr-3">
                                                Your Team plan is cancelled and will expire on {workspace.nextPaymentDate
                                                    ? workspace.nextPaymentDate
                                                    : "-"}.
                                                
                                            </span>
                                            <span>
                                                Changed your mind? <Link className="link-text" onClick={(e)=>{ e.preventDefault(); setReactivateWorkspaceModal({open:true}) }} to={"/"}>Re-activate your Team Plan</Link>
                                            </span>
                                            
                                        </div>
                                    </>
                                }
                                closable={false}
                            />
                        </div>
                        }
                        {workspace && workspace.status === "frozen" ? (
                            <div className="mb-40">
                                <ToastMessage
                                    type={
                                        teamMember &&
                                        teamMember.role == "member"
                                            ? "snowflake"
                                            : (workspace && workspace.show_payment_method_saved_msg==1 ? "info" : "error")
                                    }
                                    icon={
                                        teamMember &&
                                        teamMember.role == "member"
                                            ? "snowflake"
                                            : (workspace && workspace.show_payment_method_saved_msg==1 ? "time" : "error")
                                    }
                                    message={
                                        <>
                                            {teamMember && (
                                                <>
                                                    {teamMember.role ===
                                                    "admin" ? (
                                                        <>
                                                        {workspace && workspace.show_payment_method_saved_msg==1
                                                        ?
                                                        <>
                                                            Your payment method has been successfully saved. The subscription payment will be processed soon.
                                                        </>
                                                        :
                                                    
                                                        <div className="d-flex align-items-center freez-toast-wrap">
                                                            <span className="mr-3">
                                                                This
                                                                workspace is
                                                                frozen due
                                                                to
                                                                unsuccessful
                                                                transaction.
                                                                Please
                                                                update your
                                                                payment
                                                                method to
                                                                retain team
                                                                capabilities
                                                                or switch to
                                                                Pay-As-You-Go
                                                            </span>
                                                            <Button
                                                                type={
                                                                    "primary"
                                                                }
                                                                size={
                                                                    "small"
                                                                }
                                                                label={
                                                                    "Update payment method"
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();

                                                                    frozenPaymentMethodUpdate("frozen");
                                                                }}
                                                            />
                                                        </div>
                                                        }
                                                        </>
                                                    ) : (
                                                        <>
                                                            The workspace is not available due to pending payment. Please contact your workspace admin to resolve the issue.
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    }
                                    closable={false}
                                />
                            </div>
                        ) : (
                            <>
                                {workspace &&
                                    workspace.paymentMethod &&
                                    workspace.paymentMethod
                                        .card_expire_status === 1 &&
                                    teamMember &&
                                    teamMember.role == "admin" && (
                                        <div className="mb-40">
                                            <ToastMessage
                                                type={"warning"}
                                                message={
                                                    <>
                                                        {workspace
                                                            .paymentMethod
                                                            .expired ? (
                                                            <>
                                                                As the admin
                                                                of this
                                                                workspace,
                                                                it's
                                                                mandatory to
                                                                maintain a
                                                                valid
                                                                payment
                                                                method. The
                                                                credit card
                                                                connected to
                                                                this
                                                                workspace
                                                                has expired
                                                                on{" "}
                                                                {(function () {
                                                                    if (
                                                                        workspace
                                                                            .paymentMethod
                                                                            .card_expire_at
                                                                    ) {
                                                                        return moment(
                                                                            workspace
                                                                                .paymentMethod
                                                                                .card_expire_at
                                                                        ).format(
                                                                            "DD/MM/YY"
                                                                        );
                                                                    } else {
                                                                        return "-";
                                                                    }
                                                                })()}
                                                                . The next
                                                                billing date
                                                                is scheduled
                                                                for{" "}
                                                                {workspace.next_billing_date
                                                                    ? moment(
                                                                        workspace.next_billing_date
                                                                    ).format(
                                                                        "DD/MM/YY"
                                                                    )
                                                                    : "-"}
                                                                .<br/> Please add
                                                                or update
                                                                your payment
                                                                method to
                                                                ensure it's
                                                                in place
                                                                before the
                                                                next billing
                                                                cycle.
                                                            </>
                                                        ) : (
                                                            <>
                                                                As the admin
                                                                of this
                                                                workspace,
                                                                it's
                                                                mandatory to
                                                                maintain a
                                                                valid
                                                                payment
                                                                method. The
                                                                credit card
                                                                connected to
                                                                this
                                                                workspace
                                                                will expire
                                                                on{" "}
                                                                {(function () {
                                                                    if (
                                                                        workspace
                                                                            .paymentMethod
                                                                            .card_expire_at
                                                                    ) {
                                                                        return moment(
                                                                            workspace
                                                                                .paymentMethod
                                                                                .card_expire_at
                                                                        ).format(
                                                                            "DD/MM/YY"
                                                                        );
                                                                    } else {
                                                                        return "-";
                                                                    }
                                                                })()}
                                                                . The next
                                                                billing date
                                                                is scheduled
                                                                for{" "}
                                                                {workspace.next_billing_date
                                                                    ? moment(
                                                                        workspace.next_billing_date
                                                                    ).format(
                                                                        "DD/MM/YY"
                                                                    )
                                                                    : "-"}
                                                                .<br/> Please add
                                                                or update
                                                                your payment
                                                                method to
                                                                ensure it's
                                                                in place
                                                                before the
                                                                next billing
                                                                cycle.
                                                            </>
                                                        )}
                                                        <Button
                                                            type={"primary"}
                                                            size={"small"}
                                                            label={
                                                                "Update payment method"
                                                            }
                                                            onClick={(
                                                                e
                                                            ) => {
                                                                e.stopPropagation();

                                                                frozenPaymentMethodUpdate();
                                                            }}
                                                        />
                                                    </>
                                                }
                                                closable={false}
                                            />
                                        </div>
                                    )}
                            </>
                        )}


                        <div className="page-heading-wrap accounts-page--heading">
                            <Text type={"h1"}>Workspace billing</Text>
                        </div>

                        {workspaceType && (<div className="workspace-billing-wrapper">
                            {workspaceAdmin && (
                                <div className={`row ${
                                    workspace &&
                                    workspace.status === "frozen"
                                        ? "frozen-account"
                                        : ""
                                }`}
                                >
                                    <div className="col-md-6">
                                        <div className="billing-workspace-section-heading mb-20">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"semi-bold-font"}
                                            >
                                                Workspace information
                                            </Text>
                                        </div>

                                        <div className="gray-box p-32 workspace-billing-section">
                                            {workspaceType === "Team" && (
                                                <>
                                                    <div className="row mb-40">
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Workspace
                                                                    name
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.name
                                                                        ? workspace.name
                                                                        : "-"}
                                                                </Text>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Workspace
                                                                    plan
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.plan
                                                                        ? (workspace.plan === "Team" ? "Team plan" : workspace.plan)
                                                                        : "-"}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-40">
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Seats
                                                                </Text>

                                                                <div className="billing-info-withbtn d-flex align-items-end">
                                                                    <Text
                                                                        type={
                                                                            "body-text-2"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        cssClasses={
                                                                            "d-flex align-items-center info-text"
                                                                        }
                                                                    >
                                                                        {(+workspace.maxSeats - +workspace.availableSeats) +
                                                                            "/" +
                                                                            workspace.maxSeats}

                                                                        <span className="info-wrap">
                                                                            <Icon
                                                                                value={
                                                                                    "information"
                                                                                }
                                                                                colorClass={
                                                                                    "gray-700-svg"
                                                                                }
                                                                                hover={
                                                                                    true
                                                                                }
                                                                            />
                                                                            <Tooltip
                                                                                data={[
                                                                                    // (+workspace.maxSeats - +workspace.availableSeats) === 1 ? `You have 1 active seat.` : (`You have ${+workspace.maxSeats - +workspace.availableSeats} active seats.`),
                                                                                    // <br />,
                                                                                    // (workspace.availableSeats === 1) ? `1 seat is not being used at the moment.` : (`${workspace.availableSeats} seats are not being used at the moment.`),
    
                                                                                    (workspace.availableSeats === 1) ? `1 seat is currently available.` : (`${workspace.availableSeats} seats are currently available.`),

                                                                                ]}
                                                                            />
                                                                        </span>
                                                                    </Text>

                                                                    <Button
                                                                        type={
                                                                            "secondary"
                                                                        }
                                                                        size={
                                                                            "small"
                                                                        }
                                                                        label={
                                                                            "Edit team"
                                                                        }
                                                                        onClick={() => {
                                                                            navigate(
                                                                                "/wsp/" +
                                                                                workspaceid +
                                                                                "/team-&-settings"
                                                                            );
                                                                        }}
                                                                        cssclass={
                                                                            "ml-3"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Billing
                                                                    cycle
                                                                </Text>
                                                                <div className="billing-info-withbtn d-flex align-items-end">
                                                                    <div
                                                                        style={{
                                                                            minWidth:
                                                                                "75px",
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            type={
                                                                                "body-text-2"
                                                                            }
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                        >
                                                                            {workspace.billingCycle
                                                                                ? workspace.billingCycle
                                                                                        .charAt(
                                                                                            0
                                                                                        )
                                                                                        .toUpperCase() +
                                                                                    workspace.billingCycle
                                                                                        .slice(
                                                                                            1
                                                                                        )
                                                                                        .toLowerCase()
                                                                                : "-"}
                                                                        </Text>
                                                                    </div>
                                                                    <Button
                                                                        type={
                                                                            "secondary"
                                                                        }
                                                                        size={
                                                                            "small"
                                                                        }
                                                                        label={
                                                                            "Change"
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                !workspace.is_downgrade_scheduled &&
                                                                                workspace.is_new_admin_proposed !=
                                                                                    1
                                                                            ) {
                                                                                setChangeBillingCycleModal(
                                                                                    {
                                                                                        ...changeBillingCycleModal,
                                                                                        open: true,
                                                                                    }
                                                                                );
                                                                            }
                                                                            //updateBillingCycle();
                                                                        }}
                                                                        cssclass={
                                                                            "ml-3"
                                                                        }
                                                                        microLoading={
                                                                            billingCycleLoading
                                                                        }
                                                                        state={
                                                                            workspace.is_downgrade_scheduled ||
                                                                            workspace.is_new_admin_proposed ==
                                                                                1
                                                                                ? "disabled"
                                                                                : "active"
                                                                        }
                                                                    />
                                                                </div>
                                                                {!workspace.is_downgrade_scheduled && workspace.is_new_admin_proposed!=1 &&
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses={"gray-color mt-20"}
                                                                >
                                                                    {workspace.nextBillingCyclePlan
                                                                        ? 
                                                                    <>Your {workspace.nextBillingCyclePlan.billing_cycle} billing cycle will be effective from {workspace.nextBillingCycleEffectiveDate} </>
                                                                    : ""}
                                                                    
                                                                </Text>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-40">
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Plan renewal
                                                                    amount
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace && workspace.plan === "Individual"
                                                                    ?
                                                                    <>Free forever / $0.00</>
                                                                    :
                                                                    <>${amountFormat(workspace.planRenewalAmount, 2)}</>
                                                                    }
                                                                    
                                                                </Text>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Next payment
                                                                    date
                                                                </Text>

                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.nextPaymentDate
                                                                        ? workspace.nextPaymentDate
                                                                        : "-"}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {workspace.is_new_admin_proposed!=1 &&
                                                    <>
                                                    {workspace.is_downgrade_scheduled==1 
                                                    ?
                                                    <div className="downgrade-link">
                                                        <Text
                                                            type={"caption"}
                                                            fontWeight={"medium-font"}
                                                        >
                                                            
                                                        Your plan will be downgraded to Pay-As-You-Go on the {workspace.nextPaymentDate
                                                                        ? workspace.nextPaymentDate
                                                                        : "-"}. <Link
                                                                        to={"#"}
                                                                        className="link-text"
                                                                        onClick={(e)=>{ e.preventDefault(); setReactivateWorkspaceModal({open:true}) }}
                                                                    >
                                                                        Re-activate your Team Plan
                                                                    </Link>
                            
                                                        </Text>
                                                        
                                                    </div>
                                                    :
                                                    <div className="downgrade-link">
                                                        <Link
                                                            to={"#"}
                                                            className="link-text"
                                                            onClick={(
                                                                e
                                                            ) => {
                                                                e.stopPropagation();
                                                                openModal();
                                                            }}
                                                        >
                                                            Downgrade to
                                                            Pay-As-You-Go
                                                        </Link>
                                                    </div>
                                                    }
                                                    </>
                                                    }
                                                </>
                                            )}
                                            {workspaceType === "Individual" && (
                                                <>
                                                    <div className="row mb-40">
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Workspace
                                                                    name
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.name
                                                                        ? workspace.name
                                                                        : "-"}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Workspace
                                                                    plan
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.plan
                                                                        ? (workspace.plan === "Individual" ? "Pay-As-You-Go" : workspace.plan)
                                                                        : "-"}
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-40">
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Plan renewal
                                                                    amount
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.plan === "Individual"
                                                                    ?
                                                                    <>Free forever / $0.00</>
                                                                    :
                                                                    <>${amountFormat(workspace.planRenewalAmount, 2)}</>
                                                                    }
                                                                    
                                                                </Text>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="work-space-billing-info-repat">
                                                                <Text
                                                                    type={
                                                                        "caption"
                                                                    }
                                                                    cssClasses={
                                                                        "gray-color"
                                                                    }
                                                                >
                                                                    Next payment
                                                                    date
                                                                </Text>
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                >
                                                                    {workspace.plan === "Individual"
                                                                    ?
                                                                    <>NA</>
                                                                    :
                                                                    <>
                                                                    {workspace.nextPaymentDate
                                                                        ? workspace.nextPaymentDate
                                                                        : "NA"}
                                                                    </>
                                                                    }
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="switch-mamber-team d-flex align-items-center">
                                                        <Text
                                                            type={"subtitle-2"}
                                                        >
                                                            Do you want to
                                                            invite team
                                                            <br /> members to
                                                            this workspace?
                                                        </Text>

                                                        <Button
                                                            type="primary"
                                                            size={`medium`}
                                                            cssclass={
                                                                "white-bg-btn"
                                                            }
                                                            iconLeft={
                                                                <Icon
                                                                    value={
                                                                        "group"
                                                                    }
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                />
                                                            }
                                                            onClick={() => {
                                                                navigate(
                                                                    "/wsp/" +
                                                                    workspaceid +
                                                                    "/upgrade-workspace"
                                                                );
                                                            }}
                                                            label="Switch to Team plan"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="billing-workspace-section-heading mb-20">
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"semi-bold-font"}
                                            >
                                                Billing information
                                            </Text>
                                        </div>
                                        <div className="gray-box p-32 workspace-billing-section">

                                            {workspaceType !== "Individual" && (
                                                <div className="mb-40">
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={"medium-font"}
                                                        cssClasses={"mb-12"}
                                                    >
                                                        Plan payment method
                                                    </Text>

                                                    {
                                                        paymentMethodLoading ? 
                                                                <div className="d-flex change-payment-skel mt-0 mb-0 align-items-center">
                                                                    <Skeleton
                                                                        width={311}
                                                                        height={60}
                                                                        style={{
                                                                            borderRadius:
                                                                                "8px",
                                                                        }}
                                                                        className={
                                                                            "big-skel"
                                                                        }
                                                                    />

                                                                    <Skeleton
                                                                        width={86}
                                                                        height={35}
                                                                        style={{
                                                                            borderRadius:
                                                                                "100px",
                                                                        }}
                                                                        className={
                                                                            "ml-4 small-skel"
                                                                        }
                                                                    />
                                                                </div>
                                                                :
                                                            (
                                                                <>
                                                                    {(+paymentMethodCount > 0) && futurePaymentCard.paymentMethodId && (
                                                                        <>
                                                                            <div className="selected-payment-card mb-8 d-flex align-items-center">
                
                                                                                <div className="payments-cards d-flex mt-0 mb-0 addedpayment-cards-wrap">
                
                                                                                    <div className="card-left-side">
                                                                                        <label htmlFor={futurePaymentCard.paymentMethodId} className="mt-0 d-flex">
                                                                                            <img src={process.env.REACT_APP_URL + "images/" + futurePaymentCard.brand + "-icon.svg"} alt="img" />
                                                                                        </label>
                
                                                                                    </div>
                                                                                    <div className="card-right-side">
                                                                                        {futurePaymentCard.default == 1 &&
                                                                                            <Tag title="Primary" />
                                                                                        }
                
                                                                                        <Text type={"body-text-3"}>**** {futurePaymentCard.last_4}</Text>
                
                                                                                    </div>
                                                                                </div>
                                                                                {workspace.is_downgrade_scheduled===0 &&
                                                                                <Button
                                                                                    type={"secondary"}
                                                                                    size={"small"}
                                                                                    label={"Change"}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if(workspace.is_downgrade_scheduled==1){

                                                                                        } else {
                                                                                            setReviewPaymentModal({
                                                                                                ...reviewPaymentModal,
                                                                                                open: true,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    status={workspace.is_downgrade_scheduled==1 ? "disabled" :"active"}
                                                                                />
                                                                                }
                                                                                {workspace.is_downgrade_scheduled===1 &&
                                                                                <Button
                                                                                    type={"secondary"}
                                                                                    size={"small"}
                                                                                    label={"Unlink card"}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        
                                                                                        dettachCardFromSubscription();
                                                                                    }}
                                                                                    microLoading={dettachLoading}
                                                                                    status={workspace.is_downgrade_scheduled==1 ? "active" :"disabled"}
                                                                                />
                                                                                }
                                                                            </div>
                
                                                                            <div className="saved-payment-link">
                                                                                <Link
                                                                                    to={"/r/payment-methods"}
                                                                                    className="link-text"
                                                                                    target="_blank"
                                                                                >
                                                                                    {paymentMethodCount} saved payment methods
                                                                                </Link>
                                                                            </div>
                                                                        </>
                
                                                                    )}
                
                                                                    {(+paymentMethodCount === 0 || !futurePaymentCard.paymentMethodId) && (
                                                                        <div className="no-payment-card">
                                                                            <div className="nocard-data">
                
                                                                                <div className="no_payment_add_box">
                                                                                    <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={'no_payment_add gray-color d-flex align-items-center'}>
                                                                                        <Icon value="attention" colorClass={'gray-700-svg attention-payment mr-2'} />
                                                                                        No payment method added.
                                                                                    </Text>
                                                                                </div>
                                                                            </div>
                                                                            {workspace.is_downgrade_scheduled===0 &&
                                                                            <Button
                                                                                type={"secondary"}
                                                                                size={"small"}
                                                                                iconLeft={
                                                                                    <Icon value={'add'} size={'small'} colorClass={'gray-900-svg'}/>
                                                                                }
                                                                                label={"Add payment method"}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();

                                                                                    setAfterCardAction("reactivate");

                                                                                    if(workspace.is_downgrade_scheduled==1){

                                                                                    } else {
                                                                                        setReviewPaymentModal({
                                                                                            ...reviewPaymentModal,
                                                                                            open: true,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                status={workspace.is_downgrade_scheduled==1 ? "disabled" :"active"}
                                                                            />
                                                                            }
                                                                            {workspace.is_downgrade_scheduled===1 &&
                                                                            <Button
                                                                                type={"secondary"}
                                                                                size={"small"}
                                                                                iconLeft={
                                                                                    <Icon value={'add'} size={'small'} colorClass={'gray-900-svg'}/>
                                                                                }
                                                                                label={"Add payment method"}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                   
                                                                                        setReviewPaymentModal({
                                                                                            ...reviewPaymentModal,
                                                                                            open: true,
                                                                                        });
                                                                                    
                                                                                }}
                                                                            />
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                    }
                                                     
                                                    

                                                </div>
                                            )}


                                            <div className="workspace-billing-info-wrap">
                                                <div className="billing-info-heading d-flex align-items-center justify-content-between">
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "semi-bold-font"
                                                        }
                                                    >
                                                        Billing info
                                                    </Text>
                                                    <Button
                                                        type={"secondary"}
                                                        size={"small"}
                                                        label={"Edit"}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setBillingModal({
                                                                ...billingModal,
                                                                open: true,
                                                            });
                                                        }}
                                                    />
                                                </div>

                                                <div className="row mb-12">
                                                    <div className="col-md-6">
                                                        <div className="work-space-billing-info-repat">
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                Name/company
                                                                name
                                                            </Text>

                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {billingInfo.name
                                                                    ? billingInfo.name
                                                                    : "-"}
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="work-space-billing-info-repat">
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                Address
                                                            </Text>

                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {billingInfo.address
                                                                    ? billingInfo.address
                                                                    : "-"}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-12">
                                                    <div className="col-md-6">
                                                        <div className="work-space-billing-info-repat">
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                Email
                                                            </Text>

                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {billingInfo.email
                                                                    ? billingInfo.email
                                                                    : "-"}
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="work-space-billing-info-repat">
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                City
                                                            </Text>

                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {billingInfo.city
                                                                    ? billingInfo.city
                                                                    : "-"}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="work-space-billing-info-repat">
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                Country
                                                            </Text>

                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {billingInfo.country
                                                                    ? billingInfo.country
                                                                    : "-"}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!workspaceAdmin && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"semi-bold-font mb-20"}
                                        >
                                            Your workspace plan
                                        </Text>
                                        <div className="gray-box p-32 text-center no-access-box">
                                            <div className="no-access-inner">
                                                <Icon
                                                    value={"lock"}
                                                    size={"large"}
                                                    colorClass={"gray-700-svg"}
                                                />
                                                <Text
                                                    type={"body-text-2"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={"mt-20"}
                                                >
                                                    As a member of this
                                                    workspace, you don’t have
                                                    access to billing
                                                    <br />
                                                    information. Please contact
                                                    your workspace admin.
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="row mt-60">
                                <div className="col-md-12">
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"semi-bold-font"}
                                        cssClasses={"mb-32"}
                                    >
                                        Billing and Invoice history
                                    </Text>

                                    <div className="table-wrapper">
                                        {invoices.length > 0 && (
                                            <div
                                                ref={scrollContainerRef}
                                                className={`max-height-table  ${isScrolled ? "scrolled" : ""
                                                    }  ${invoices &&
                                                        invoices.length > 8
                                                        ? "responsive-table-scroll"
                                                        : ""
                                                    }`}
                                            >
                                                <table className="table invoicetable">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className={`td-sort-wrap cursor-pointer`}
                                                            >
                                                                <div
                                                                    onClick={() => {
                                                                        if (
                                                                            order ===
                                                                            "asc"
                                                                        ) {
                                                                            setOrder(
                                                                                "desc"
                                                                            );
                                                                        } else {
                                                                            setOrder(
                                                                                "asc"
                                                                            );
                                                                        }
                                                                    }}
                                                                    className={`${order
                                                                            ? order
                                                                            : ""
                                                                        }   col-sort justify-content-between`}
                                                                >
                                                                    Date
                                                                    <span>
                                                                        {order ==
                                                                            "asc" && (
                                                                                <Icon
                                                                                    value={
                                                                                        "sortup"
                                                                                    }
                                                                                />
                                                                            )}
                                                                        {order ==
                                                                            "desc" && (
                                                                                <Icon
                                                                                    value={
                                                                                        "sortdown"
                                                                                    }
                                                                                />
                                                                            )}
                                                                        {!order && (
                                                                            <Icon
                                                                                value={
                                                                                    "sort-icon"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th>Description</th>
                                                            <th>Coupon</th>
                                                            <th>Subtotal</th>
                                                            <th>
                                                                Discount
                                                            </th>
                                                            <th>Total</th>
                                                            <th>Status</th>
                                                            <th>Notes</th>
                                                            <th>Invoice</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {renderInvoices()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {!hasInvoices &&
                                            invoices.length == 0 && (
                                                <div className="nodata-wrap">
                                                    No bills available at this
                                                    moment.
                                                </div>
                                            )}
                                        {hasInvoices && !invoiceLoading && (
                                            <div className={"load-more-data"}>
                                                <Button
                                                    type="primary"
                                                    size={`large`}
                                                    label="Load more"
                                                    onClick={fetchInvoices}
                                                />
                                            </div>
                                        )}
                                        {hasInvoices && invoiceLoading && (
                                            <div className={"load-more-data"}>
                                                <Button
                                                    type="primary"
                                                    size={`large`}
                                                    microLoading={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>)}

                        <BillingInfoModal
                            open={billingModal.open}
                            close={(send = false, data = null) => {
                                setBillingModal({
                                    ...billingModal,
                                    open: false,
                                });
                                if (send && data) {
                                    setBillingInfo({
                                        ...billingInfo,
                                        name: data.name,
                                        country: data.country,
                                        city: data.city,
                                        address: data.address
                                    });
                                }
                            }}
                            workspaceId={workspaceid}
                            billingInfo={billingInfo}
                        />

                        <ReviewPaymentModal
                            open={reviewPaymentModal.open}
                            close={(send = false, data = null) => {
                                setReviewPaymentModal({
                                    ...reviewPaymentModal,
                                    open: false,
                                });

                                setWorkspace({...workspace, is_new_admin_popup :0});
                                if(send){
                                    

                                    // let data = null;
                                    // console.log("paymentMethodId comming on close", paymentMethodId, paymentMethods);


                                    // paymentMethods.forEach((method) => {
                                    //     if (method.id === paymentMethodId) {
                                    //         data = {
                                    //             paymentMethodId : method.stripe_payment_method_id,
                                    //             default: method.default,
                                    //             last_4: method.last_4,
                                    //             brand : method.brand,
                                    //             id : method.id
                                    //         }
                                    //     }
                                    // });

                                    setWorkspace({...workspace, show_payment_method_saved_msg:1, is_new_admin_popup :0});

                                    if(data){
                                        setPaymentMethodLoading(true);
                                        setFuturePaymentCard({
                                            ...futurePaymentCard,
                                            paymentMethodId : data.stripe_payment_method_id,
                                            default: data.default,
                                            last_4: data.last_4,
                                            brand : data.brand,
                                            id : data.id
                                        })
                                        getPaymentMethods(data.id, true);

                                        

                                    } else {
                                        setPaymentMethodLoading(true);
                                        getPaymentMethods(futurePaymentCard.id, true);
                                        
                                    }
                                        
                                    
                                }
                                

                            }}
                            stripe={stripe}
                            workspaceId={workspaceid}
                            renderPaymentMethods={renderPaymentMethods}
                            validatePaymentForm={validatePaymentForm}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            addNewCard={addNewCard}
                            setAddNewCard={setAddNewCard}
                            resetFormErrors={resetFormErrors}
                            resetPaymentMethods={resetPaymentMethods}
                            workspace={workspace}
                            paymentMethodCount={paymentMethodCount}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            futurePaymentCard={futurePaymentCard}
                            changePaymentMethodLoading={changePaymentMethodLoading}
                            setChangePaymentMethodLoading={setChangePaymentMethodLoading}
                            sameCardError={sameCardError}
                            setSameCardError={setSameCardError}
                            title={workspace.is_new_admin_popup ? "Congratulations, you’re now the new admin of this workspace!" : (paymentMethodCount
                                ? "Review your payment method"
                                : "Add a payment method")}
                        />
                        <DowngradeWorkspaceModal
                            openModal={downgradeWorkspaceModal.open}
                            closeModal={closeModal}
                            confirm={() => {
                                setDowngradeWorkspaceModal((prevState) => ({
                                    ...prevState,
                                    open: false,
                                }));
                                setConfirmDowngradeWorkspaceModal(
                                    (prevState) => ({
                                        ...prevState,
                                        open: true,
                                    })
                                );
                            }}

                            workspace={workspace}
                        />

                        <ConfirmDowngradeWorkspaceModal
                            open={confirmDowngradeWorkspaceModal.open}
                            workspace={workspace}
                            close={close}
                            btnLoading={downgradeLoading}
                            confirmDowngradeAction={confirmDowngrade}
                        />
                        <ReactivateWorkspaceModal 
                            open={reactivateWorkspaceModal.open}
                            workspace={workspace}
                            close={()=>{
                                setReactivateWorkspaceModal({open:false})
                            }}
                            btnLoading={reactivateLoading}
                            confirmUpgradeAction={reactivateWorkspace}
                        />
                        <ChangebillingcycleModal
                            open={changeBillingCycleModal.open}
                            close={()=>{
                                setChangeBillingCycleModal({...changeBillingCycleModal, open:false})
                            }}
                            workspace={workspace}
                            loading={billingCycleLoading}
                            updateBillingCycle={(billing_cycle)=>{ updateBillingCycle(billing_cycle)} }
                        />
                    </>
                )}
            </div>
        </LayoutResearcher>
    );
};
export default WorkspaceBiling;
