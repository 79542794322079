import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../Text/Text";
import { Button } from '../../../themes/userq/Button/Button';
import { Icon } from "../Icon/Icon";

export default function WelcomeMessageTesterModal({
  open,
  title,
  subtitle,
  btn,
  close,
  confirm,
  isWelcome,
  isProfileVerified
}) {
  const closeModal = () => {
    confirm();
  };

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        //console.log(event.target.nodeName);
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.target.nodeName === "DIV") {
            closeModal();
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    // If isWelcome is true, show the welcome step (isActiveStep = 1)
    // Otherwise, show the tester score step (isActiveStep = 2)
    setActiveStep(!isProfileVerified ? (isWelcome ? 1 : 3):(isWelcome ? 1 : 2));

    
  }, [open, isWelcome]);
  const [isActiveStep, setActiveStep] = useState(1);
  return (
    <Modal
      show={open}
      centered
      className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap welcom-userq-modal welcome_tester_userq_modal"
    >
        
        <Modal.Body className="" ref={wrapperRef}>
            {isWelcome 
            ? 
            <>
                {!isProfileVerified
                ?
                <>
                {isActiveStep === 1 ? (
                <>
                    <div className="congrats-modal-header-step-second">
                        <img src={process.env.REACT_APP_URL + 'img/tester_id_banner.svg'} />
                    </div>
                    <div className="congrats-modal-text">
                        <div className="tester-id-verified-modal-data">
                            <Text type={"h3"}>Verify your identity</Text>
                            <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text mt-20"}>We are encouraging all testers to verify their identity in order to ensure the security and privacy of our platform. We take the protection of our users' information very seriously, and we require all users to provide valid proof of identity before requesting a payout.</Text>
                                
                                <div className="tester-idv-doctype mt-20 mb-20 d-flex align-items-center flex-wrap justify-content-between">
                                    <div className="tester-doctype-repeat d-flex align-items-center">
                                        <Icon colorClass="secondary-purple-200-svg" value={"passport"} size="xl-large"/>
                                        <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Passport</Text>
                                    </div>
                                    <div className="tester-doctype-repeat d-flex align-items-center">
                                        <Icon colorClass="secondary-purple-200-svg" value={"id"} size="xl-large"/>
                                        <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>National ID</Text>
                                    </div>
                                    <div className="tester-doctype-repeat d-flex align-items-center">
                                        <Icon colorClass="secondary-purple-200-svg"  value={"id"} size="xl-large"/>
                                        <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Driving license</Text>
                                    </div>

                                </div>

                            <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text"}>To verify your identity, please provide a copy of your government-issued photo identification (Your national ID, driver's license or passport)</Text>
                        </div>

                        <div className={`congrts-modal-wrap ${isWelcome ? 'is_welcome_btn' : 'is_tester_score_btn'}`}>
                        
                            <>
                                <Button
                                    type={"ghost"}
                                    size={"small"}
                                    label={"Skip"}
                                    cssclass={"skip_btn"}
                                    onClick={() => {
                                        confirm();
                                    }}
                                />

                                <div className={"next-pre-btn d-flex"}>
                                    <>
                                        <Icon value={isActiveStep === 1 ? "dot-black" : "dot-grey"} hover={true} />
                                        <Icon value={isActiveStep === 2 ? "dot-black" : "dot-grey"} hover={true} />
                                        <Icon value={isActiveStep === 3 ? "dot-black" : "dot-grey"} hover={true} />
                                    </>  
                                    
                                </div>

                                <Button
                                    type={"primary"}
                                    size={"large"}
                                    iconRight={<Icon colorClass="gray-50-svg" value={"forward-arrow"} />}
                                    label="Next"
                                    onClick={() => {
                                        setActiveStep(isActiveStep + 1); // Go to the next step
                                    }}
                                />
                            </>
                        
                        </div>
                    </div>
                </>
                
                ) : isActiveStep === 2 ? (
                    <>
                    <div className="congrats-modal-header">
                        <Text type={"h1"}>Welcome to UserQ</Text>
                    </div>
                    <div className="congrats-modal-text">
                
                        <div className="welcome-userq-text">
                            <Text type={"body-text-2"} fontWeight={'medium-font'}>We'll show you tests that match your profile when they become available. Start taking tests and collect payments in your wallet.</Text>
                        </div>
                    

                        <div className={`congrts-modal-wrap ${isWelcome ? 'is_welcome_btn' : 'is_tester_score_btn'}`}>
                        
                            <>
                            <Button
                                type={"ghost"}
                                size={"small"}
                                label={ "Back"}
                                cssclass={"skip_btn"}
                                onClick={() => {
                                    setActiveStep(isActiveStep - 1); // Go back to the previous step
                                }}
                            />

                            <div className={"next-pre-btn d-flex"}>
                                
                                <Icon value={isActiveStep === 1 ? "dot-black" : "dot-grey"} hover={true} />
                                <Icon value={isActiveStep === 2 ? "dot-black" : "dot-grey"} hover={true} />
                                <Icon value={isActiveStep === 3 ? "dot-black" : "dot-grey"} hover={true} />
                                
                                
                            </div>

                            <Button
                                type={"primary"}
                                size={"large"}
                                iconRight={<Icon  colorClass="gray-50-svg" value={"forward-arrow"} />}
                                label={"Next"}
                                onClick={() => {
                                    setActiveStep(isActiveStep + 1); // Go to the next step
                                }}
                            />
                            </>
                        
                        </div>
                    </div>
                    </>
                    ) : (
                        <>
                        <div className="congrats-modal-header-step-second">
                            <img src={process.env.REACT_APP_URL + 'img/default-tester-score.png'} />
                        </div>
                        <div className="congrats-modal-text">
                            <>
                                <Text type={"h3"} cssClasses={"introducing-score-text"}>
                                    Introducing the tester score 
                                </Text>

                                <Text type={"body-text-2"} fontWeight={'medium-font'}>
                                    Your tester score is based on the quality of responses you give.<br /> The higher the score, the more tests you will receive.
                                </Text>
                                <div className='account-guidelines-link'>
                                    <Text type="body-text-3">
                                    <a className='faq-support-link'
                                        href={process.env.REACT_APP_URL + "t/tester-guidelines"}
                                        target="_blank">Read more about the tester guidelines</a>
                                    </Text>
                                </div>
                            </>
            

                            <div className={`congrts-modal-wrap is_tester_score_btn`}>
                            
                                <>
                                    <Button
                                        type={"primary"}
                                        size={"large"}
                                        label={"Go to dashboard"}
                                        onClick={() => {
                                            confirm();
                                        }}
                                    />
                                </>
                        
                            </div>
                        </div>
                        </>
                    )
                }
                </>
                :

                <>
                    {isActiveStep === 1 ? 
                    <>
                    <div className="congrats-modal-header">
                        <Text type={"h1"}>Welcome to UserQ</Text>
                    </div>
                    <div className="congrats-modal-text">
                      <div className="welcome-userq-text">
                            <Text type={"body-text-2"} fontWeight={'medium-font'}>We'll show you tests that match your profile when they become available. Start taking tests and collect payments in your wallet.</Text>
                        </div>

                        <div className={`congrts-modal-wrap is_welcome_btn`}>
                        
                            <>
                            <Button
                                type={"ghost"}
                                size={"small"}
                                label={isActiveStep === 1 ? "Skip" : "Back"}
                                cssclass={"skip_btn"}
                                onClick={() => {
                                    confirm();
                                }}
                            />

                            <div className={"next-pre-btn d-flex"}>
                                <Icon value={isActiveStep === 1 ? "dot-black" : "dot-grey"} hover={true} />
                                <Icon value={isActiveStep === 2 ? "dot-black" : "dot-grey"} hover={true} />
                                
                            </div>

                            <Button
                                type={"primary"}
                                size={"large"}
                                iconRight={<Icon  colorClass="gray-50-svg" value={"forward-arrow"} />}
                                label={"Next"}
                                onClick={() => {
                                    setActiveStep(isActiveStep + 1); // Go to the next step
                                }}
                            />
                            </>
                        
                        </div>
                    </div>
                    </>
                    : 
                    <>
                    <div className="congrats-modal-header-step-second">
                        <img src={process.env.REACT_APP_URL + 'img/default-tester-score.png'} />
                    </div>
                    <div className="congrats-modal-text">
                
                    <>
                        <Text type={"h3"} cssClasses={"introducing-score-text"}>
                            Introducing the tester score 
                        </Text>

                        <Text type={"body-text-2"} fontWeight={'medium-font'}>
                            Your tester score is based on the quality of responses you give.<br /> The higher the score, the more tests you will receive.
                        </Text>
                        <div className='account-guidelines-link'>
                            <Text type="body-text-3">
                            <a className='faq-support-link'
                                href={process.env.REACT_APP_URL + "t/tester-guidelines"}
                                target="_blank">Read more about the tester guidelines</a>
                            </Text>
                        </div>
                        </>`
                

                        <div className={`congrts-modal-wrap is_tester_score_btn`}>
                        
                            

                            <>
                                <Button
                                    type={"primary"}
                                    size={"large"}
                                    label={"Go to dashboard"}
                                    onClick={() => {
                                    confirm();
                                    }}
                                />
                            </>
                        </div>
                    </div>
                    </>
                    }
                </>
                }
            </>
            :
            <>
            <div className="congrats-modal-header-step-second">
                <img src={process.env.REACT_APP_URL + 'img/tester_id_banner.svg'} />
            </div>
            <div className="congrats-modal-text">
                <div className="tester-id-verified-modal-data">
                    <Text type={"h3"}>Verify your identity</Text>
                    <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text mt-20"}>We are encouraging all testers to verify their identity in order to ensure the security and privacy of our platform. We take the protection of our users' information very seriously, and we require all users to provide valid proof of identity before requesting a payout.</Text>
                        
                        <div className="tester-idv-doctype mt-20 mb-20 d-flex align-items-center flex-wrap justify-content-between">
                            <div className="tester-doctype-repeat d-flex align-items-center">
                                <Icon colorClass="secondary-purple-200-svg" value={"passport"} size="xl-large"/>
                                <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Passport</Text>
                            </div>
                            <div className="tester-doctype-repeat d-flex align-items-center">
                                <Icon colorClass="secondary-purple-200-svg" value={"id"} size="xl-large"/>
                                <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>National ID</Text>
                            </div>
                            <div className="tester-doctype-repeat d-flex align-items-center">
                                <Icon colorClass="secondary-purple-200-svg" value={"id"} size="xl-large"/>
                                <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Driving license</Text>
                            </div>

                        </div>

                    <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text"}>To verify your identity, please provide a copy of your government-issued photo identification (Your national ID, driver's license or passport)</Text>
                </div>

                <div className={`congrts-modal-wrap is_tester_score_btn`}>
                        
                            

                    <>
                        <Button
                            type={"primary"}
                            size={"large"}
                            label={"Go to dashboard"}
                            onClick={() => {
                            confirm();
                            }}
                        />
                    </>
                </div>
            </div>
            </>
            }
            
        </Modal.Body>
    </Modal>
  );
}
