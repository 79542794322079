import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Icon } from './../Icon/Icon';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "../Input/Input";

export const InputCalendar = React.forwardRef(({  readOnly,  required,  id, onChange, error, onClear, label, clearField, focussed, disabled, selected}, ref) => {

    const [calendarValue,SetCalendarValue]=useState(selected);
    useEffect(()=>{
        SetCalendarValue(selected)
    },[selected])
    var today = new Date();
    const ExampleCustomInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
        
        <Input  value={value} label={label} onClick={onClick} onKeyUp={onClick} clearField={false} required={required} ref={ref}/>
      ));
      // onChange={onChange}
    return (

        <div className={`calendor-input-field form-group input-field  input-with-right-icon mb-0 ${((selected != null && selected != '') || focussed == true) ? 'focused' : ''}  ${error ? 'field-error' : ''} ${readOnly == true ? 'read-only-field' : ''} ${disabled == true ? 'disabled-field' : ''}`}>
            <div className={`input-position-relative`}>
                <div className="date-timepickar-wrap">
                    <DatePicker
                        id={id}
                        // placeholderText={label}
                        selected={calendarValue ? moment(calendarValue).toDate() : null}
                        onChange={(date)=>{
                            onChange(date);
                            SetCalendarValue(date);
                        }}
                        onSelect={(date)=>{
                            onChange(date);
                            SetCalendarValue(date);
                        }}
                        onYearChange={(date)=>{
                            onChange(date);
                            SetCalendarValue(date);
                        }}
                        onMonthChange={(date)=>{
                            onChange(date);
                            SetCalendarValue(date);
                        }}
                        //   dateFormat="MMMM d, yyyy"
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        //   minDate={new Date("01-01-1934")}
                        //     maxDate={new Date("01-01-2004")}
                        minDate={new Date(new Date().setFullYear(today.getFullYear() - 90))}
                        maxDate={new Date(new Date().setFullYear(today.getFullYear() - 18))}
                        customInput={<ExampleCustomInput />}
                    />
                </div>
                
                    {/* <label className="control-label body-text body-text-2" htmlFor={id}>
                        {label}
                        {!required &&
                            (" (Optional)")
                        }
                    </label> */}


            </div>

            {error != null && (
                <span className="caption">
                    {error}
                </span>
            )}
        </div>

    )
});
InputCalendar.propTypes = {
    type: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    maxLength: PropTypes.number,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    autoFocus: PropTypes.func,
    error: PropTypes.string,
    label: PropTypes.string,
    clearField: PropTypes.bool,
    focussed: PropTypes.bool,
    selected: PropTypes.string,

};
InputCalendar.defaultProps = {
    required: false,
    clearField: true,
    onChange: undefined,
    onKeyDown: undefined,
    autoFocus: undefined,
    focussed: false,
    selected: ""

};
