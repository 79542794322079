import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "./../Icon/Icon";
import { Text } from "../Text/Text";
import { CheckBox } from "./../CheckBox/CheckBox";
import { RadioButton } from "./../RadioButton/RadioButton";
import { Chip } from "./../Chips/Chips";
import { Tag } from "./../Tag/Tag";
import { OptionsReadMore } from "../OptionsReadMore/OptionsReadMore";
import { DropdownComponent } from "./../Dropdown/Dropdown";
import { DropdownComponentDragAndDrop } from "../Dropdown/DropdownInsideDragandDrop";
import { ToggleButton } from "./../ToggleButton/ToggleButton";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import toast from "react-hot-toast";

import { settings } from "../../../../data/settings";

export const ScreeningQuestion = ({
    index,
    question,
    onCopy,
    onEdit,
    onDelete,
    saveScreening,
    type,
    language,
    hideDuplicateAction,
}) => {
    const dropdownOptions = [
        { label: "Good fit (continue)", value: "Good fit (continue)" },
        { label: "Not a fit (end test)", value: "Not a fit (end test)" },
    ];

    const [viewAll, setViewAll] = useState(
        question.questionType == "singlechoice" ? true : false
    );

    const showError = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={false}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                id: "error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    return (
        <>
            <div
                className={`lightgraybox questionbox-s newsingle-question added-question-card-wrap ${
                    question.questionType == "singlechoice" && type == "survey"
                        ? "singlesurvey-view"
                        : ""
                }`}
            >
                <div
                    className={`d-flex questionbox-top ${
                        question.is_optional == true ? "optional-q-top" : ""
                    } `}
                >
                    <div className="d-flex question-topleftbox">
                        {!hideDuplicateAction && (
                            <div className="questiondrag">
                                <Icon
                                    value="Move-object"
                                    size="medium"
                                    hover={true}
                                />
                            </div>
                        )}
                        {hideDuplicateAction && (
                            <div className="questiondrag">&nbsp;</div>
                        )}
                        <div className="question-middlebox">
                            {question.questionType == "free" ? (
                                <>
                                    <Text
                                        type="body-text-1"
                                        cssClasses={
                                            "none-for-mobile question-no-for-big-screen color-black"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Question {index + 1} - Free text
                                    </Text>
                                    <Text
                                        type="subtitle-2"
                                        cssClasses={
                                            "none-for-desktop question-no-for-small-screen"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Q {index + 1} - Free text
                                    </Text>
                                </>
                            ) : question.questionType == "singlechoice" ? (
                                <>
                                    <Text
                                        type="body-text-1"
                                        cssClasses={
                                            "none-for-mobile question-no-for-big-screen color-black"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Question {index + 1} - Single choice
                                    </Text>
                                    <Text
                                        type="subtitle-2"
                                        cssClasses={
                                            "none-for-desktop question-no-for-small-screen"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Q {index + 1} - Single choice
                                    </Text>
                                </>
                            ) : question.questionType == "multiplechoice" ? (
                                <>
                                    <Text
                                        type="body-text-1"
                                        cssClasses={
                                            "none-for-mobile question-no-for-big-screen color-black"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Question {index + 1} - Multi-choice
                                    </Text>
                                    <Text
                                        type="subtitle-2"
                                        cssClasses={
                                            "none-for-desktop question-no-for-small-screen"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Q {index + 1} - Multi-choice
                                    </Text>
                                </>
                            ) : question.questionType == "ratingscale" ? (
                                <>
                                    <Text
                                        type="body-text-1"
                                        cssClasses={
                                            "none-for-mobile question-no-for-big-screen color-black"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Question {index + 1} - Rating scale
                                    </Text>
                                    <Text
                                        type="subtitle-2"
                                        cssClasses={
                                            "none-for-desktop question-no-for-small-screen"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Q {index + 1} - Rating scale
                                    </Text>
                                </>
                            ) : question.questionType == "likertscale" ? (
                                <>
                                    <Text
                                        type="subtitle-2"
                                        fontWeight="semi-bold-font"
                                    >
                                        Question {index + 1} - Likert scale
                                    </Text>
                                    <Text
                                        type="body-text-1"
                                        cssClasses={
                                            "none-for-desktop question-no-for-small-screen color-black"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Q {index + 1} - Likert scale
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Text
                                        type="body-text-1"
                                        cssClasses={
                                            "none-for-mobile question-no-for-big-screen color-black"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Question {index + 1} - Ranking scale
                                    </Text>
                                    <Text
                                        type="subtitle-2"
                                        cssClasses={
                                            "none-for-desktop question-no-for-small-screen"
                                        }
                                        fontWeight="semi-bold-font"
                                    >
                                        Q {index + 1} - Ranking scale
                                    </Text>
                                </>
                            )}
                            {question.is_optional == true && (
                                <span className="viewpart-optional">
                                    (Optional)
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="d-flex question-topbuttonbox">
                        {!hideDuplicateAction && (
                            <div className="question-readmode-icon">
                                <span
                                    onClick={() => {
                                        if (onCopy) {
                                            onCopy(index);
                                        }
                                    }}
                                >
                                    <Icon
                                        value="duplicate"
                                        size="medium"
                                        hover={true}
                                    />
                                </span>
                            </div>
                        )}
                        <div className="question-readmode-icon">
                            <span
                                onClick={() => {
                                    if (onEdit) {
                                        onEdit(index);
                                    }
                                }}
                            >
                                <Icon value="edit" size="medium" hover={true} />
                            </span>
                        </div>
                        <div className="ans-close-row question-readmode-icon">
                            <span
                                onClick={() => {
                                    if (onDelete) {
                                        onDelete(index);
                                    }
                                }}
                            >
                                <Icon
                                    value="delete"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={`questiondescription-s d-flex ${
                        language == "ar" ? "arabic_wrapper" : ""
                    }`}
                >
                    <Text
                        type={"body-text-2"}
                        fontWeight={"medium-font"}
                        cssClasses={"survey-question-added-info"}
                    >
                        {question.question}
                    </Text>
                </div>

                {question.questionType == "singlechoice" &&
                    question.options.map((option, i) => (
                        <>
                            {viewAll ? (
                                <div
                                    className={`question-optionbox singlechoice-boxes ${
                                        question.is_logic == 1
                                            ? "logicq-view"
                                            : ""
                                    }`}
                                    key={index + "" + option + "-" + i}
                                >
                                    <div className="questionreadmode-left">
                                        <RadioButton
                                            isChecked={false}
                                            onChange={() => {}}
                                        />
                                        <div
                                            className={`option-value ${
                                                language == "ar"
                                                    ? "arabic_wrapper"
                                                    : ""
                                            }`}
                                        >
                                            {option}
                                        </div>
                                    </div>
                                    <div
                                        className={`logic-dropdown-readmode ${
                                            question.screeningOptions[i] ==
                                            "Not a fit (end test)"
                                                ? "dropdown-alert-text"
                                                : ""
                                        }`}
                                    >
                                        <DropdownComponentDragAndDrop
                                            id={
                                                "logic-dropdown-" +
                                                index +
                                                "-" +
                                                i
                                            }
                                            label="Screening"
                                            options={dropdownOptions}
                                            value={question.screeningOptions[i]}
                                            onChange={(item) => {
                                                var not_fit_count = 0;

                                                var fit_count = 0;

                                                question.screeningOptions.forEach(
                                                    function (item) {
                                                        if (
                                                            item ==
                                                            "Not a fit (end test)"
                                                        ) {
                                                            not_fit_count++;
                                                        }
                                                        if (
                                                            item ==
                                                            "Good fit (continue)"
                                                        ) {
                                                            fit_count++;
                                                        }
                                                    }
                                                );
                                                if (
                                                    item.value ==
                                                    "Not a fit (end test)"
                                                ) {
                                                    not_fit_count--;
                                                }
                                                if (
                                                    item.value ==
                                                    "Good fit (continue)"
                                                ) {
                                                    fit_count--;
                                                }
                                                if (not_fit_count == 0) {
                                                    showError(
                                                        "Please include a response that qualifies for the no fit logic."
                                                    );
                                                } else if (fit_count == 0) {
                                                    showError(
                                                        "Please include a response that qualifies for the good fit logic."
                                                    );
                                                } else {
                                                    var question_sub = {
                                                        ...question,
                                                    };

                                                    question_sub.screeningOptions[
                                                        i
                                                    ] = item.value;

                                                    saveScreening(
                                                        index,
                                                        question_sub
                                                    );
                                                }
                                            }}
                                            logicUpdated={false}
                                            error={null}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {i < 3 && (
                                        <div
                                            className={`question-optionbox singlechoice-boxes ${
                                                question.is_logic == 1
                                                    ? "logicq-view"
                                                    : ""
                                            }`}
                                            key={index + "" + option + "-" + i}
                                        >
                                            <div className="questionreadmode-left">
                                                <RadioButton
                                                    isChecked={false}
                                                    onChange={() => {}}
                                                />
                                                <div
                                                    className={`option-value ${
                                                        language == "ar"
                                                            ? "arabic_wrapper"
                                                            : ""
                                                    }`}
                                                >
                                                    {option}
                                                </div>
                                            </div>
                                            <div
                                                className={`logic-dropdown-readmode ${
                                                    question.screeningOptions[
                                                        i
                                                    ] == "Not a fit (end test)"
                                                        ? "dropdown-alert-text"
                                                        : ""
                                                }`}
                                            >
                                                <DropdownComponentDragAndDrop
                                                    id={
                                                        "logic-dropdown-" +
                                                        index +
                                                        "-" +
                                                        i
                                                    }
                                                    label="Screening"
                                                    options={dropdownOptions}
                                                    value={
                                                        question
                                                            .screeningOptions[i]
                                                    }
                                                    onChange={(item) => {
                                                        var question_sub = {
                                                            ...question,
                                                        };

                                                        question_sub.screeningOptions[
                                                            i
                                                        ] = item.value;

                                                        saveScreening(
                                                            index,
                                                            question_sub
                                                        );
                                                    }}
                                                    logicUpdated={false}
                                                    error={null}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ))}

                {question.questionType === "singlechoice" &&
                    !viewAll &&
                    question.options &&
                    question.options.length > 3 && (
                        <div className="question-optionbox multiplechoice-boxes">
                            <div
                                className="addadas"
                                onClick={() => {
                                    setViewAll(true);
                                }}
                            >
                                +{question.options.length - 3} more
                            </div>
                        </div>
                    )}
            </div>
        </>
    );
};

ScreeningQuestion.propTypes = {
    // question: PropTypes.string,
    // questionType: PropTypes.string,
};

ScreeningQuestion.defaultProps = {
    // question: "",
    // questionType:"freetext",
};
