import React from "react";
import { Link } from 'react-router-dom';

export default function ResearcherSupportNavigation(props) {

    return (
        <div className="accountnaviagtion-outer">
            <span className={`${props.activeMenu=='faqs' ? "active" :''}`}>
                <Link to={"/r/support/"}>
                    FAQs
                </Link>
            </span>
            <span className={`${props.activeMenu=='contact' ? "active" :''}`}>
                <Link to={"/r/support/contact"}>
                    Contact us
                </Link>
            </span>
        </div>
    )
}
