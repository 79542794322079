import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Text } from "../../../themes/userq/Text/Text";
import { Input } from '../../../themes/userq/Input/Input';
import { LoadingIcon } from './../../../loader/loadingIconNew';
import { Icon } from "../../../themes/userq/Icon/Icon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";
import { EmailInput } from "../../../themes/userq/Input/EmailInput";
import {
    Link, useNavigate
} from "react-router-dom";


export default function RemoveMemberModal({
    open,
    close,
    confirm,
    btnLoading,
    memberName
}) {
    const closeModal = () => {
        close();
    };

    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap delete-account-modal-wrap remove-invite-member-modal"
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <h3 className={`heading h3 text-danger`}>Remove member from workspace</h3>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                        </button>
                    </div>
                    <Text type="body-text-3" fontWeight="medium-font">Are you sure you want to remove <b className="remove-mamber-name">{memberName}</b> from the workspace?</Text>

                    <div className="confirm-buttons-wrap">
                        <Button type="secondary" size="large" label={"Yes, remove"} onClick={() => { confirm(); }} microLoading={btnLoading} />
                        <Button type="primary" size="large" label={'No, keep'} onClick={() => { closeModal(); }} />
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
}
