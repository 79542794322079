export const countries = [
    {
       "value":"Afghanistan",
       "label":"Afghanistan",
       "code":"AF"
    },
    {
       "value":"Albania",
       "label":"Albania",
       "code":"AL"
    },
    {
       "value":"Algeria",
       "label":"Algeria",
       "code":"DZ"
    },
    {
       "value":"American Samoa",
       "label":"American Samoa",
       "code":"AS"
    },
    {
       "value":"Andorra",
       "label":"Andorra",
       "code":"AD"
    },
    {
       "value":"Angola",
       "label":"Angola",
       "code":"AO"
    },
    {
       "value":"Anguilla",
       "label":"Anguilla",
       "code":"AI"
    },
    {
       "value":"Antarctica",
       "label":"Antarctica",
       "code":"AQ"
    },
    {
       "value":"Antigua and Barbuda",
       "label":"Antigua and Barbuda",
       "code":"AG"
    },
    {
       "value":"Argentina",
       "label":"Argentina",
       "code":"AR"
    },
    {
       "value":"Armenia",
       "label":"Armenia",
       "code":"AM"
    },
    {
       "value":"Aruba",
       "label":"Aruba",
       "code":"AW"
    },
    {
       "value":"Australia",
       "label":"Australia",
       "code":"AU"
    },
    {
       "value":"Austria",
       "label":"Austria",
       "code":"AT"
    },
    {
       "value":"Azerbaijan",
       "label":"Azerbaijan",
       "code":"AZ"
    },
    {
       "value":"Bahamas",
       "label":"Bahamas",
       "code":"BS"
    },
    {
       "value":"Bahrain",
       "label":"Bahrain",
       "code":"BH"
    },
    {
       "value":"Bangladesh",
       "label":"Bangladesh",
       "code":"BD"
    },
    {
       "value":"Barbados",
       "label":"Barbados",
       "code":"BB"
    },
    {
       "value":"Belarus",
       "label":"Belarus",
       "code":"BY"
    },
    {
       "value":"Belgium",
       "label":"Belgium",
       "code":"BE"
    },
    {
       "value":"Belize",
       "label":"Belize",
       "code":"BZ"
    },
    {
       "value":"Benin",
       "label":"Benin",
       "code":"BJ"
    },
    {
       "value":"Bermuda",
       "label":"Bermuda",
       "code":"BM"
    },
    {
       "value":"Bhutan",
       "label":"Bhutan",
       "code":"BT"
    },
    {
       "value":"Bolivia",
       "label":"Bolivia",
       "code":"BO"
    },
    {
       "value":"Bosnia and Herzegowina",
       "label":"Bosnia and Herzegowina",
       "code":"BA"
    },
    {
       "value":"Botswana",
       "label":"Botswana",
       "code":"BW"
    },
    {
       "value":"Bouvet Island",
       "label":"Bouvet Island",
       "code":"BV"
    },
    {
       "value":"Brazil",
       "label":"Brazil",
       "code":"BR"
    },
    {
       "value":"British Indian Ocean Territory",
       "label":"British Indian Ocean Territory",
       "code":"IO"
    },
    {
       "value":"Brunei Darussalam",
       "label":"Brunei Darussalam",
       "code":"BN"
    },
    {
       "value":"Bulgaria",
       "label":"Bulgaria",
       "code":"BG"
    },
    {
       "value":"Burkina Faso",
       "label":"Burkina Faso",
       "code":"BF"
    },
    {
       "value":"Burundi",
       "label":"Burundi",
       "code":"BI"
    },
    {
       "value":"Cambodia",
       "label":"Cambodia",
       "code":"KH"
    },
    {
       "value":"Cameroon",
       "label":"Cameroon",
       "code":"CM"
    },
    {
       "value":"Canada",
       "label":"Canada",
       "code":"CA"
    },
    {
       "value":"Cape Verde",
       "label":"Cape Verde",
       "code":"CV"
    },
    {
       "value":"Cayman Islands",
       "label":"Cayman Islands",
       "code":"KY"
    },
    {
       "value":"Central African Republic",
       "label":"Central African Republic",
       "code":"CF"
    },
    {
       "value":"Chad",
       "label":"Chad",
       "code":"TD"
    },
    {
       "value":"Chile",
       "label":"Chile",
       "code":"CL"
    },
    {
       "value":"China",
       "label":"China",
       "code":"CN"
    },
    {
       "value":"Christmas Island",
       "label":"Christmas Island",
       "code":"CX"
    },
    {
       "value":"Cocos (Keeling) Islands",
       "label":"Cocos (Keeling) Islands",
       "code":"CC"
    },
    {
       "value":"Colombia",
       "label":"Colombia",
       "code":"CO"
    },
    {
       "value":"Comoros",
       "label":"Comoros",
       "code":"KM"
    },
    {
       "value":"Republic of Congo",
       "label":"Republis of Congo",
       "code":"CG"
    },
    {
       "value":"The Democratic Republic of the Congo",
       "label":"The Democratic Republic of the Congo",
       "code":"CD"
    },
    {
       "value":"Cook Islands",
       "label":"Cook Islands",
       "code":"CK"
    },
    {
       "value":"Costa Rica",
       "label":"Costa Rica",
       "code":"CR"
    },
    {
       "value":"Cote d'Ivoire",
       "label":"Cote d'Ivoire",
       "code":"CI"
    },
    {
       "value":"Croatia (Hrvatska)",
       "label":"Croatia (Hrvatska)",
       "code":"HR"
    },
    {
       "value":"Cuba",
       "label":"Cuba",
       "code":"CU"
    },
    {
       "value":"Cyprus",
       "label":"Cyprus",
       "code":"CY"
    },
    {
       "value":"Czech Republic",
       "label":"Czech Republic",
       "code":"CZ"
    },
    {
       "value":"Denmark",
       "label":"Denmark",
       "code":"DK"
    },
    {
       "value":"Djibouti",
       "label":"Djibouti",
       "code":"DJ"
    },
    {
       "value":"Dominica",
       "label":"Dominica",
       "code":"DM"
    },
    {
       "value":"Dominican Republic",
       "label":"Dominican Republic",
       "code":"DO"
    },
    {
       "value":"East Timor",
       "label":"East Timor",
       "code":"TL"
    },
    {
       "value":"Ecuador",
       "label":"Ecuador",
       "code":"EC"
    },
    {
       "value":"Egypt",
       "label":"Egypt",
       "code":"EG"
    },
    {
       "value":"El Salvador",
       "label":"El Salvador",
       "code":"SV"
    },
    {
       "value":"Equatorial Guinea",
       "label":"Equatorial Guinea",
       "code":"GQ"
    },
    {
       "value":"Eritrea",
       "label":"Eritrea",
       "code":"ER"
    },
    {
       "value":"Estonia",
       "label":"Estonia",
       "code":"EE"
    },
    {
       "value":"Ethiopia",
       "label":"Ethiopia",
       "code":"ET"
    },
    {
       "value":"Falkland Islands (Malvinas)",
       "label":"Falkland Islands (Malvinas)",
       "code":"FK"
    },
    {
       "value":"Faroe Islands",
       "label":"Faroe Islands",
       "code":"FO"
    },
    {
       "value":"Fiji",
       "label":"Fiji",
       "code":"FJ"
    },
    {
       "value":"Finland",
       "label":"Finland",
       "code":"FI"
    },
    {
       "value":"France",
       "label":"France",
       "code":"FR"
    },
    {
       "value":"France Metropolitan",
       "label":"France Metropolitan",
       "code":"FR"
    },
    {
       "value":"French Guiana",
       "label":"French Guiana",
       "code":"GF"
    },
    {
       "value":"French Polynesia",
       "label":"French Polynesia",
       "code":"PF"
    },
    {
       "value":"French Southern Territories",
       "label":"French Southern Territories",
       "code":"TF"
    },
    {
       "value":"Gabon",
       "label":"Gabon",
       "code":"GA"
    },
    {
       "value":"Gambia",
       "label":"Gambia",
       "code":"GM"
    },
    {
       "value":"Georgia",
       "label":"Georgia",
       "code":"GE"
    },
    {
       "value":"Germany",
       "label":"Germany",
       "code":"DE"
    },
    {
       "value":"Ghana",
       "label":"Ghana",
       "code":"GH"
    },
    {
       "value":"Gibraltar",
       "label":"Gibraltar",
       "code":"GI"
    },
    {
       "value":"Greece",
       "label":"Greece",
       "code":"GR"
    },
    {
       "value":"Greenland",
       "label":"Greenland",
       "code":"GL"
    },
    {
       "value":"Grenada",
       "label":"Grenada",
       "code":"GD"
    },
    {
       "value":"Guadeloupe",
       "label":"Guadeloupe",
       "code":"GP"
    },
    {
       "value":"Guam",
       "label":"Guam",
       "code":"GU"
    },
    {
       "value":"Guatemala",
       "label":"Guatemala",
       "code":"GT"
    },
    {
       "value":"Guinea",
       "label":"Guinea",
       "code":"GN"
    },
    {
       "value":"Guinea-Bissau",
       "label":"Guinea-Bissau",
       "code":"GW"
    },
    {
       "value":"Guyana",
       "label":"Guyana",
       "code":"GY"
    },
    {
       "value":"Haiti",
       "label":"Haiti",
       "code":"HT"
    },
    {
       "value":"Heard and Mc Donald Islands",
       "label":"Heard and Mc Donald Islands",
       "code":"HM"
    },
    {
       "value":"Holy See (Vatican City State)",
       "label":"Holy See (Vatican City State)",
       "code":"VA"
    },
    {
       "value":"Honduras",
       "label":"Honduras",
       "code":"HN"
    },
    {
       "value":"Hong Kong",
       "label":"Hong Kong",
       "code":"HK"
    },
    {
       "value":"Hungary",
       "label":"Hungary",
       "code":"HU"
    },
    {
       "value":"Iceland",
       "label":"Iceland",
       "code":"IS"
    },
    {
       "value":"India",
       "label":"India",
       "code":"IN"
    },
    {
       "value":"Indonesia",
       "label":"Indonesia",
       "code":"ID"
    },
    {
       "value":"Iran",
       "label":"Iran",
       "code":"IR"
    },
    {
       "value":"Iraq",
       "label":"Iraq",
       "code":"IQ"
    },
    {
       "value":"Ireland",
       "label":"Ireland",
       "code":"IE"
    },
    {
       "value":"Israel",
       "label":"Israel",
       "code":"IL"
    },
    {
       "value":"Italy",
       "label":"Italy",
       "code":"IT"
    },
    {
       "value":"Jamaica",
       "label":"Jamaica",
       "code":"JM"
    },
    {
       "value":"Japan",
       "label":"Japan",
       "code":"JP"
    },
    {
       "value":"Jordan",
       "label":"Jordan",
       "code":"JO"
    },
    {
       "value":"Kazakhstan",
       "label":"Kazakhstan",
       "code":"KZ"
    },
    {
       "value":"Kenya",
       "label":"Kenya",
       "code":"KE"
    },
    {
       "value":"Kiribati",
       "label":"Kiribati",
       "code":"KI"
    },
    {
       "value":"Republic of Korea",
       "label":"Republic of Korea",
       "code":"KR"
    },
    {
       "value":"The Democratic Republic of the Korea",
       "label":"The Democratic Republic of the Korea",
       "code":"KP"
    },
    {
       "value":"Kuwait",
       "label":"Kuwait",
       "code":"KW"
    },
    {
       "value":"Kyrgyzstan",
       "label":"Kyrgyzstan",
       "code":"KG"
    },
    {
       "value":"Lao, People's Democratic Republic",
       "label":"Lao, People's Democratic Republic",
       "code":"LA"
    },
    {
       "value":"Latvia",
       "label":"Latvia",
       "code":"LV"
    },
    {
       "value":"Lebanon",
       "label":"Lebanon",
       "code":"LB"
    },
    {
       "value":"Lesotho",
       "label":"Lesotho",
       "code":"LS"
    },
    {
       "value":"Liberia",
       "label":"Liberia",
       "code":"LR"
    },
    {
       "value":"Libyan Arab Jamahiriya",
       "label":"Libyan Arab Jamahiriya",
       "code":"LY"
    },
    {
       "value":"Liechtenstein",
       "label":"Liechtenstein",
       "code":"LI"
    },
    {
       "value":"Lithuania",
       "label":"Lithuania",
       "code":"LT"
    },
    {
       "value":"Luxembourg",
       "label":"Luxembourg",
       "code":"LU"
    },
    {
       "value":"Macau",
       "label":"Macau",
       "code":"MO"
    },
    {
       "value":"Macedonia",
       "label":"Macedonia",
       "code":"MK"
    },
    {
       "value":"Madagascar",
       "label":"Madagascar",
       "code":"MG"
    },
    {
       "value":"Malawi",
       "label":"Malawi",
       "code":"KW"
    },
    {
       "value":"Malaysia",
       "label":"Malaysia",
       "code":"MY"
    },
    {
       "value":"Maldives",
       "label":"Maldives",
       "code":"MV"
       
    },
    {
       "value":"Mali",
       "label":"Mali",
       "code":"ML"
    },
    {
       "value":"Malta",
       "label":"Malta",
       "code":"MT"
    },
    {
       "value":"Marshall Islands",
       "label":"Marshall Islands",
       "code":"MH"
    },
    {
       "value":"Martinique",
       "label":"Martinique",
       "code":"MQ"
    },
    {
       "value":"Mauritania",
       "label":"Mauritania",
       "code":"MR"
    },
    {
       "value":"Mauritius",
       "label":"Mauritius",
       "code":"MU"
    },
    {
       "value":"Mayotte",
       "label":"Mayotte",
       "code":"YT"
    },
    {
       "value":"Mexico",
       "label":"Mexico",
       "code":"MX"
    },
    {
       "value":"Micronesia",
       "label":"Micronesia",
       "code":"FM"
    },
    {
       "value":"Moldova",
       "label":"Moldova",
       "code":"MD"
    },
    {
       "value":"Monaco",
       "label":"Monaco",
       "code":"MC"
    },
    {
       "value":"Mongolia",
       "label":"Mongolia",
       "code":"MN"
    },
    {
       "value":"Montserrat",
       "label":"Montserrat",
       "code":"MS"
    },
    {
       "value":"Morocco",
       "label":"Morocco",
       "code":"MA"
    },
    {
       "value":"Mozambique",
       "label":"Mozambique",
       "code":"MZ"
    },
    {
       "value":"Myanmar",
       "label":"Myanmar",
       "code":"MM"
    },
    {
       "value":"Namibia",
       "label":"Namibia",
       "code":"NA"
    },
    {
       "value":"Nauru",
       "label":"Nauru",
       "code":"NR"
    },
    {
       "value":"Nepal",
       "label":"Nepal",
       "code":"NP"
    },
    {
       "value":"Netherlands",
       "label":"Netherlands",
       "code":"NL"
    },
    {
       "value":"Netherlands Antilles",
       "label":"Netherlands Antilles",
       "code":"NL"
    },
    {
       "value":"New Caledonia",
       "label":"New Caledonia",
       "code":"NC"
    },
    {
       "value":"New Zealand",
       "label":"New Zealand",
       "code":"NZ"
    },
    {
       "value":"Nicaragua",
       "label":"Nicaragua",
       "code":"NI"
    },
    {
       "value":"Niger",
       "label":"Niger",
       "code":"NE"
    },
    {
       "value":"Nigeria",
       "label":"Nigeria",
       "code":"NG"
    },
    {
       "value":"Niue",
       "label":"Niue",
       "code":"NU"
    },
    {
       "value":"Norfolk Island",
       "label":"Norfolk Island",
       "code":"NF"
    },
    {
       "value":"Northern Mariana Islands",
       "label":"Northern Mariana Islands",
       "code":"MP"
    },
    {
       "value":"Norway",
       "label":"Norway",
       "code":"NO"
    },
    {
       "value":"Oman",
       "label":"Oman",
       "code":"OM"
    },
    {
       "value":"Pakistan",
       "label":"Pakistan",
       "code":"PK"
    },
    {
       "value":"Palau",
       "label":"Palau",
       "code":"PW"
    },
    {
      "value":"Palestine",
      "label":"Palestine",
      "code":"PS"
   },
    {
       "value":"Panama",
       "label":"Panama",
       "code":"PA"
    },
    {
       "value":"Papua New Guinea",
       "label":"Papua New Guinea",
       "code":"PG"
    },
    {
       "value":"Paraguay",
       "label":"Paraguay",
       "code":"PY"
    },
    {
       "value":"Peru",
       "label":"Peru",
       "code":"PE"
    },
    {
       "value":"Philippines",
       "label":"Philippines",
       "code":"PH"
    },
    {
       "value":"Pitcairn",
       "label":"Pitcairn",
       "code":"PN"
    },
    {
       "value":"Poland",
       "label":"Poland",
       "code":"PL"
    },
    {
       "value":"Portugal",
       "label":"Portugal",
       "code":"PT"
    },
    {
       "value":"Puerto Rico",
       "label":"Puerto Rico",
       "code":"PR"
    },
    {
       "value":"Qatar",
       "label":"Qatar",
       "code":"QA"
    },
    {
       "value":"Reunion",
       "label":"Reunion",
       "code":"RE"
    },
    {
       "value":"Romania",
       "label":"Romania",
       "code":"RO"
    },
    {
       "value":"Russian Federation",
       "label":"Russian Federation",
       "code":"RU"
    },
    {
       "value":"Rwanda",
       "label":"Rwanda",
       "code":"RW"
    },
    {
       "value":"Saint Kitts and Nevis",
       "label":"Saint Kitts and Nevis",
       "code":"KN"
    },
    {
       "value":"Saint Lucia",
       "label":"Saint Lucia",
       "code":"LC"
    },
    {
       "value":"Saint Vincent and the Grenadines",
       "label":"Saint Vincent and the Grenadines",
       "code":"VC"
    },
    {
       "value":"Samoa",
       "label":"Samoa",
       "code":"AS"
    },
    {
       "value":"San Marino",
       "label":"San Marino",
       "code":"SM"
    },
    {
       "value":"Sao Tome and Principe",
       "label":"Sao Tome and Principe",
       "code":"ST"
    },
    {
       "value":"Saudi Arabia",
       "label":"Saudi Arabia",
       "code":"SA"
    },
    {
       "value":"Senegal",
       "label":"Senegal",
       "code":"SN"
    },
    {
       "value":"Seychelles",
       "label":"Seychelles",
       "code":"SC"
    },
    {
       "value":"Sierra Leone",
       "label":"Sierra Leone",
       "code":"SL"
    },
    {
       "value":"Singapore",
       "label":"Singapore",
       "code":"SG"
    },
    {
       "value":"Slovakia (Slovak Republic)",
       "label":"Slovakia (Slovak Republic)",
       "code":"SK"
    },
    {
       "value":"Slovenia",
       "label":"Slovenia",
       "code":"SI"
    },
    {
       "value":"Solomon Islands",
       "label":"Solomon Islands",
       "code":"SB"
    },
    {
       "value":"Somalia",
       "label":"Somalia",
       "code":"SO"
    },
    {
       "value":"South Africa",
       "label":"South Africa",
       "code":"ZA"
    },
    {
       "value":"South Georgia and the South Sandwich Islands",
       "label":"South Georgia and the South Sandwich Islands",
       "code":"GS"
    },
    {
       "value":"Spain",
       "label":"Spain",
       "code":"ES"
    },
    {
       "value":"Sri Lanka",
       "label":"Sri Lanka",
       "code":"LK"
    },
    {
       "value":"St. Helena",
       "label":"St. Helena",
       "code":"SH"
    },
    {
       "value":"St. Pierre and Miquelon",
       "label":"St. Pierre and Miquelon",
       "code":"PM"
    },
    {
       "value":"Sudan",
       "label":"Sudan",
       "code":"SD"
    },
    {
      "value":"South Sudan",
      "label":"South Sudan",
      "code":"SS"
   },
    {
       "value":"Suriname",
       "label":"Suriname",
       "code":"SR"
    },
    {
       "value":"Svalbard and Jan Mayen Islands",
       "label":"Svalbard and Jan Mayen Islands",
       "code":"SJ"
    },
    {
       "value":"Swaziland",
       "label":"Swaziland",
       "code":"SZ"
    },
    {
       "value":"Sweden",
       "label":"Sweden",
       "code":"SE"
    },
    {
       "value":"Switzerland",
       "label":"Switzerland",
       "code":"CH"
    },
    {
       "value":"Syrian Arab Republic",
       "label":"Syrian Arab Republic",
       "code":"SY"
    },
    {
       "value":"Taiwan, Province of China",
       "label":"Taiwan, Province of China",
       "code":"TW"
    },
    {
       "value":"Tajikistan",
       "label":"Tajikistan",
       "code":"TJ"
    },
    {
       "value":"Tanzania",
       "label":"Tanzania",
       "code":"TZ"
    },
    {
       "value":"Thailand",
       "label":"Thailand",
       "code":"TH"
    },
    {
       "value":"Togo",
       "label":"Togo",
       "code":"TG"
    },
    {
       "value":"Tokelau",
       "label":"Tokelau",
       "code":"TK"
    },
    {
       "value":"Tonga",
       "label":"Tonga",
       "code":"TO"
    },
    {
       "value":"Trinidad and Tobago",
       "label":"Trinidad and Tobago",
       "code":"TT"
    },
    {
       "value":"Tunisia",
       "label":"Tunisia",
       "code":"TN"
    },
    {
       "value":"Turkey",
       "label":"Turkey",
       "code":"TR"
    },
    {
       "value":"Turkmenistan",
       "label":"Turkmenistan",
       "code":"TM"
    },
    {
       "value":"Turks and Caicos Islands",
       "label":"Turks and Caicos Islands",
       "code":"TC"
    },
    {
       "value":"Tuvalu",
       "label":"Tuvalu",
       "code":"TV"
    },
    {
       "value":"Uganda",
       "label":"Uganda",
       "code":"UG"
    },
    {
       "value":"Ukraine",
       "label":"Ukraine",
       "code":"UA"
    },
    {
       "value":"United Arab Emirates",
       "label":"United Arab Emirates",
       "code":"AE"
    },
    {
       "value":"United Kingdom",
       "label":"United Kingdom",
       "code":"GB"
    },
    {
       "value":"United States",
       "label":"United States",
       "code":"US"
    },
   //  {
   //     "value":"United States Minor Outlying Islands",
   //     "label":"United States Minor Outlying Islands",
   //     "code":"UM"
   //  },
    {
       "value":"Uruguay",
       "label":"Uruguay",
       "code":"UY"
    },
    {
       "value":"Uzbekistan",
       "label":"Uzbekistan",
       "code":"UZ"
    },
    {
       "value":"Vanuatu",
       "label":"Vanuatu",
       "code":"VU"
    },
    {
       "value":"Venezuela",
       "label":"Venezuela",
       "code":"VE"
    },
    {
       "value":"Vietnam",
       "label":"Vietnam",
       "code":"VN"
    },
    {
       "value":"Virgin Islands (British)",
       "label":"Virgin Islands (British)",
       "code":"VG"
    },
    {
       "value":"Virgin Islands (U.S.)",
       "label":"Virgin Islands (U.S.)",
       "code":"VI"
    },
    {
       "value":"Wallis and Futuna Islands",
       "label":"Wallis and Futuna Islands",
       "code":"WF"
    },
    {
       "value":"Western Sahara",
       "label":"Western Sahara",
       "code":"EH"
    },
    {
       "value":"Yemen",
       "label":"Yemen",
       "code":"YE"
    },
    {
       "value":"Yugoslavia",
       "label":"Yugoslavia",
       "code":"YU"
    },
    {
       "value":"Zambia",
       "label":"Zambia",
       "code":"ZM"
    },
    {
       "value":"Zimbabwe",
       "label":"Zimbabwe",
       "code":"ZW"

    }
 ];
