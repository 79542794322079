//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";

export default function WelcomeCongratsModal({ open, close, cssClass, body }) {
    //console.log(test);
    const navigate = useNavigate();

    const wrapperRef = useRef(null);
    const svgContainer1 = useRef(null);

    useEffect(() => {
        let animItem1;

        if (open && window.lottie) {
            if (svgContainer1.current) {
                svgContainer1.current.innerHTML = "";
            }

            animItem1 = window.lottie.loadAnimation({
                container: svgContainer1.current,
                renderer: "svg",
                loop: false,
                autoplay: true,
                path: `${process.env.REACT_APP_URL}animations/congratulations.json`,
            });

            return () => {
                if (animItem1) {
                    animItem1.destroy(); // Cleanup when modal is closed
                }
            };
        }
    }, [open]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        close();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap new-tester-given-test congratulation-modal-wrap ${cssClass}`}
        >
            <Modal.Body className={""} ref={wrapperRef}>
                {/* <img
                    className="congrts-img"
                    src={process.env.REACT_APP_URL + "img/congrts.svg"}
                    alt="cross"
                /> */}

                <div className="lottie-hold">
                    <div
                        className="animation-svg-hold"
                        id="svg"
                        ref={svgContainer1}
                        style={{ width: 300, height: 300 }}
                    ></div>
                </div>

                <div className="congrats-modal-text">{body}</div>
            </Modal.Body>
        </Modal>
    );
}
