import React, {useState} from "react";
import PropTypes from 'prop-types';
import { Icon } from "../Icon/Icon";

export const ToastMessage = ({ message, type, closable,link,linkvalue, onClose, icon, colorClass, cssClasses }) => {
    const [toastVisibility, setToastVisibility] =   useState(true);
    return (
        <>
        {toastVisibility==true?
        <div  className={`toast-message-wrap toast-${type} ${cssClasses} body-text body-text-2 medium-font`}>
            
            <div className="toast-left-icon">
            {type == "snowflake" &&
            <Icon colorClass={"gray-50-svg"} value={"snowflake"} size={"medium"} />
            }
            {type == "error" &&
            <Icon colorClass={"gray-50-svg"} value={"error"} size={"medium"} />
            }
            {type == "warning" &&
            <Icon colorClass={"gray-900-svg"} value={"warning"} size={"medium"} />
            }
            {type == "success" &&
            <Icon colorClass={"gray-50-svg"} value={"success"} size={"medium"} />
            
            }
            {type == "info" && !icon &&
            <Icon colorClass={"secondary-purple-200-svg"} value={"Hint"} size={"medium"} />
            
            }
            {type == "info" && icon && !colorClass &&
            <Icon colorClass={"secondary-purple-200-svg"} value={icon} size={"medium"} />
            
            }
            {type == "info" && icon && colorClass &&
            <Icon colorClass={colorClass} value={icon} size={"medium"} />
            
            }
            {type == "logic" &&
            <Icon colorClass={"gray-50-svg"} value={"Got updated"} size={"medium"} />
            }
            </div>
            
            <div className={`toast-data toast-${type}-data`}><span>{message}</span>
            {link &&
            <a href={link} className="toast-link" target="_blank">{linkvalue}</a>
            }
            </div>
            {closable && (
            <div className="toast-cross-icon" onClick={()=>{
                if(onClose){
                    onClose();
                }else{
                setToastVisibility(false)
                }
            }}>
                {(type == "warning" || type == "info") ?
                <Icon colorClass={"gray-900-svg"} value={"close"} size={"medium"} />
                :
                <Icon colorClass={"gray-900-svg toast-close-icon"} value={"close"} size={"medium"} />
                }
            </div>
            )}
        </div>
        :<></>}
        </>
    );
};

ToastMessage.propTypes = {
    message: PropTypes.any,
    type: PropTypes.string,
    closable: PropTypes.bool,
    link: PropTypes.string,
    linkvalue:PropTypes.string
};
ToastMessage.defaultProps = {
    message: "",
    type: "success",
    closable: true,
    link: "",
    linkvalue: "Learn More"

};