import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { TestResultsSidebarNavigation } from "../components/test_results_sidebar_navigation";
import { useState, useEffect, useRef } from "react";
import { getResearcherTestResultData } from "../../../../services/researcher_test_results";
import moment from "moment";
import { atom, useRecoilState } from "recoil";
import { LoadingIcon } from "../../../loader/loadingIcon";
import { TestResultsHeader } from "../../../themes/userq/TestResultsHeader/TestResultsHeader";
import {
    calculateTime,
    renderTestTitle,
    showToast,
} from "../../../../lib/helpers";
import { Helmet } from "react-helmet";
import { PieStatsBox } from "../components/pie_stats_box";
import { Text } from "../../../themes/userq/Text/Text";
import {
    getScreeningChartService,
    getScreeningResultService,
} from "../../../../services/screening_test_results";
import { DropdownComponent } from "../../../themes/userq/Dropdown/Dropdown";
import { Search } from "../../../themes/userq/Search/Search";
import { Icon } from "../../../themes/userq/Icon/Icon";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../themes/userq/Button/Button";
import { EmptySearchPlaceholder } from "../../../themes/userq/EmptySearchPlaceholder/EmptySearchPlaceholder";
import { NoParticipants } from "../components/no_participants";
import "./screening_results.css";

export default function ScreeningResults({ isSharedLink }) {
    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    let { test_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    let searchController = useRef();

    const [sectionLoading, setSectionLoading] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [chartLoading, setChartLoading] = useState(false);

    const [tableLoading, setTableLoading] = useState(false);

    const testState = atom({
        key: "testresult-" + test_id,
        default: "",
    });
    const [test, setTest] = useRecoilState(testState);

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const [chartData, setChartData] = useState({
        "Good fit": 0,
        "Not a fit": 0,
    });

    const [screeningResults, setScreeningResults] = useState([]);

    const [questions, setQuestions] = useState([]);

    const [loadMore, setLoadMore] = useState(true);

    const [noResults, setNoResults] = useState(false);

    const [searchLoading, setSearchLoading] = useState(false);

    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [meta, setMeta] = useState({
        q: "",
        load_more: true,
        page: 1,
        column: "",
        limit: 10,
        direction: "asc",
        screening_outcome: "All",
    });

    const projectState = atom({
        key: "testresultproject-" + test_id,
        default: null,
    });
    const [project, setProject] = useRecoilState(projectState);

    useEffect(() => {
        getTest();
    }, []);
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener("scroll", handleScroll);
            };
        }
    }, [questions]);
    const escapeRegExp = (str = "") =>
        str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
    const Highlight = ({ children = "" }) => {
        const patt = new RegExp(`(${escapeRegExp(meta.q)})`, "i");
        const parts = String(children).split(patt);
        if (meta.q) {
            return parts.map((part, index) =>
                patt.test(part) ? (
                    <mark className={"search-highlight"} key={index}>
                        {part}
                    </mark>
                ) : (
                    part
                )
            );
        } else {
            return children;
        }
    };
    const getTestafterEndTest = () => {
        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
		let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getResearcherTestResultData(
            { test_id: test_id, guest_result_token: guest_result_token, admin_result_token: admin_result_token},
            token
        ).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const getTest = () => {
        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = "";

            if (location.pathname.substr(1, 1) === "v") {
                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random()
                        .toString(36)
                        .substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = "";

            if (location.pathname.substr(1, 1) === "a") {
                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random()
                        .toString(36)
                        .substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData(
                {
                    test_id: test_id,
                    guest_result_token: guest_result_token,
                    admin_result_token: admin_result_token,
                },
                token
            ).then((response) => {
                setLoading(false);

                if (response.success) {
                    if (response.workspace_project_team_member) {
                        if (
                            response.workspace_project_team_member.status !=
                            "active"
                        ) {
                            navigate(
                                "/wsp/" +
                                    response.workspace_project_team_member
                                        .workspace_id +
                                    "/p/" +
                                    response.workspace_project_team_member
                                        .workspace_project_id +
                                    "/project-no-access"
                            );
                        }
                    }
                    if (response.test && response.test.workspace_project) {
                        setProject(response.test.workspace_project);
                    }
                    // check test is available for sharing
                    if (
                        location.pathname.substr(1, 1) === "v" &&
                        response.is_share_result
                    ) {
                        navigate(`/v/results/${test_id}/results-not-available`);
                    }

                    // check if guest user not validate there password
                    if (
                        location.pathname.substr(1, 1) === "v" &&
                        response.test.password &&
                        !response.test_password_verify
                    ) {
                        navigate(`/v/results/${test_id}/password`);
                    }
                    setTest(response.test);

                    getScreeningChart();
                } else {
                    if (response.message == "Project not found.") {
                        navigate("/404");
                    } else if (response.message == "Test Record Not found") {
                        navigate("/404");
                    } else {
                        if(location.pathname.substr(1, 1) === 'v'){

                            if(response.test_shared_results && !response.test_password_verify){
                                return navigate(`/v/results/${test_id}/password`);
                            }
                            else if(!response.test_shared_results){
                               return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        if(location.pathname.substr(1, 1) === 'a'){

                            if(!response.test_results_available){
                               return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        showToast(response.message, "error");
                    }
                }
            });
        } else {
            getScreeningChart();
        }
    };

    const getScreeningChart = () => {
        let token = ReactSession.get("token");

        let guest_result_token = "";

        if (location.pathname.substr(1, 1) === "v") {
            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";

        if (location.pathname.substr(1, 1) === "a") {
            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        setSectionLoading(true);

        setChartLoading(true);

        //setTableLoading(true);

        getScreeningChartService(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
            },
            token
        ).then((response) => {
            setChartLoading(false);

            if (response.success) {
                setChartData(response.chart);

                getScreeningResult({
                    ...meta,
                    q: "",
                    page: 1,
                    screening_outcome: "All",
                });
            } else {
                showToast(response.message, "error");

                setTableLoading(false);

                setSectionLoading(false);
            }
        });
    };
    const getScreeningResult = ({
        q,
        page,
        screening_outcome,
        column,
        direction,
        limit,
    }) => {
        let token = ReactSession.get("token");

        let guest_result_token = "";

        if (location.pathname.substr(1, 1) === "v") {
            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";

        if (location.pathname.substr(1, 1) === "a") {
            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        //setTableLoading(true);

        if (searchController.current) {
            searchController.current.abort();
        }
        const controller = new AbortController();

        searchController.current = controller;

        getScreeningResultService(
            {
                limit: limit,
                column: column,
                direction: direction,
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
                q: q,
                screening_outcome: screening_outcome,
                page: page,
            },
            token,
            searchController.current?.signal
        ).then((response) => {
            //setTableLoading(false);

            setSectionLoading(false);

            setSearchLoading(false);

            setLoadMoreLoading(false);

            if (response.success) {
                setQuestions(response.questions);

                setLoadMore(response.load_more);

                if (page == 1 || searchLoading) {
                    if (
                        !noResults &&
                        questions.length == 0 &&
                        response.screening_results.length == 0
                    ) {
                        setNoResults(true);
                    }
                    setScreeningResults(response.screening_results);
                } else {
                    var results = Object.assign([], screeningResults);

                    setScreeningResults([
                        ...results,
                        ...response.screening_results,
                    ]);
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    };

    const getLoadingRows = (rowCount) => {
        let rowArr = Array.apply(null, Array(rowCount)).map(function () {});

        let columnArr = Array.apply(null, Array(8)).map(function () {});

        return rowArr.map(function () {
            return (
                <tr>
                    {columnArr.map(function () {
                        return (
                            <td>
                                <Skeleton
                                    width={"80px"}
                                    height={30}
                                    style={{ borderRadius: "5px" }}
                                />
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };
    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <Helmet>
                <title>
                    Screening questions{" "}
                    {test && test.methodology
                        ? " - " + renderTestTitle(test.methodology)
                        : ""}{" "}
                    | UserQ
                </title>
            </Helmet>
            {test && (
                <div className="three-column-layout two-column-layout result">
                    <TestResultsHeader
                        project={project}
                        isSharedLink={isSharedLink}
                        test={test}
                        endedOn={
                            test.status === "completed"
                                ? moment.utc(test.ended_on).format("DD-MM-Y")
                                : ""
                        }
                        callbackTopbar={callbackTopbar}
                    />
                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={
                                    test.methodology === "Five Seconds Test"
                                        ? "5 second test"
                                        : test.methodology
                                }
                                selected_index={1}
                                test_id={
                                    location.pathname.substr(1, 1) === "v"
                                        ? test_id
                                        : test.id
                                }
                                screening={
                                    test &&
                                    test.screening_questions &&
                                    test.screening_questions.length > 0
                                        ? "Yes"
                                        : "No"
                                }
                            />
                        </div>
                        <div className="two-column-right-side">
                            {sectionLoading ? (
                                <div
                                    className={
                                        "page-loader accountloading-box result-sections-loader"
                                    }
                                >
                                    <LoadingIcon />
                                </div>
                            ) : (
                                <>
                                    {noResults ? (
                                        <NoParticipants small={false} />
                                    ) : (
                                        <>
                                            <div className="question-dropdown-container screen-question-wrap">
                                                <div className="dropdown-header">
                                                    <div
                                                        className={
                                                            "card-sorting-test-results-header"
                                                        }
                                                    >
                                                        <p className="questions-dropdown-result-head">
                                                            <span className="questions-dropdown-result-count">
                                                                {chartData[
                                                                    "Good fit"
                                                                ] +
                                                                    chartData[
                                                                        "Not a fit"
                                                                    ]}{" "}
                                                                user
                                                                {chartData[
                                                                    "Good fit"
                                                                ] +
                                                                    chartData[
                                                                        "Not a fit"
                                                                    ] >
                                                                1
                                                                    ? "s"
                                                                    : ""}
                                                            </span>
                                                        </p>

                                                        <Text
                                                            type={"body-text-1"}
                                                            fontWeight="medium-font"
                                                            cssClasses={
                                                                "black-color"
                                                            }
                                                        >
                                                            Screening overview
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="test-result-inner-data">
                                                {sectionLoading && (
                                                    <div
                                                        className={
                                                            "page-loader accountloading-box result-sections-loader"
                                                        }
                                                    >
                                                        <LoadingIcon />
                                                    </div>
                                                )}
                                                {!sectionLoading && (
                                                    <>
                                                        {noResults ? (
                                                            <NoParticipants
                                                                small={false}
                                                            />
                                                        ) : (
                                                            <div className="test-result-overview-data">
                                                                <div className="screening-overview-chart-wrap">
                                                                    <div className="mb-20">
                                                                        <Text
                                                                            type={
                                                                                "h4"
                                                                            }
                                                                        >
                                                                            Chart
                                                                        </Text>
                                                                    </div>

                                                                    <div className="row screening-chart">
                                                                        <div className="col-md-6">
                                                                            <PieStatsBox
                                                                                loading={
                                                                                    chartLoading
                                                                                }
                                                                                colorsSet={[
                                                                                    "#378B10",
                                                                                    "#FF0000",
                                                                                ]}
                                                                                data={
                                                                                    chartData
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`screening-buy-user-data-warap mt-60 mb-60`}
                                                                >
                                                                    <div className="screen-by-user-top-head d-flex justify-content-between align-items-center mb-20">
                                                                        <Text type="h4">
                                                                            By
                                                                            user
                                                                        </Text>
                                                                        <div className="screen-byuser-filter-wrap d-flex justify-content-end align-items-center">
                                                                            <DropdownComponent
                                                                                id={
                                                                                    "screening_outcome"
                                                                                }
                                                                                name="screening_outcome"
                                                                                title="Screening outcome"
                                                                                searchable={[
                                                                                    "Search for education level",
                                                                                    "No matching education level",
                                                                                ]}
                                                                                value={
                                                                                    meta.screening_outcome
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        label: "All",
                                                                                        value: "All",
                                                                                    },
                                                                                    {
                                                                                        label: "Good fit",
                                                                                        value: "Good fit",
                                                                                    },
                                                                                    {
                                                                                        label: "Not a fit",
                                                                                        value: "Not a fit",
                                                                                    },
                                                                                ]}
                                                                                onChange={(
                                                                                    item
                                                                                ) => {
                                                                                    //setScreeningResults([]);

                                                                                    if (
                                                                                        item
                                                                                    ) {
                                                                                        setMeta(
                                                                                            {
                                                                                                ...meta,
                                                                                                screening_outcome:
                                                                                                    item.value,
                                                                                                page: 1,
                                                                                            }
                                                                                        );

                                                                                        getScreeningResult(
                                                                                            {
                                                                                                ...meta,
                                                                                                screening_outcome:
                                                                                                    item.value,
                                                                                                page: 1,
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        setMeta(
                                                                                            {
                                                                                                ...meta,
                                                                                                screening_outcome:
                                                                                                    null,
                                                                                                page: 1,
                                                                                            }
                                                                                        );

                                                                                        getScreeningResult(
                                                                                            {
                                                                                                ...meta,
                                                                                                screening_outcome:
                                                                                                    item.value,
                                                                                                page: 1,
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                label={
                                                                                    "Screening outcome"
                                                                                }
                                                                                isClearable={
                                                                                    false
                                                                                }
                                                                            />
                                                                            <Search
                                                                                loading={
                                                                                    searchLoading
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                value={
                                                                                    meta.q
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    //setScreeningResults([]);

                                                                                    setSearchLoading(
                                                                                        true
                                                                                    );

                                                                                    setMeta(
                                                                                        {
                                                                                            ...meta,
                                                                                            q: e
                                                                                                .target
                                                                                                .value
                                                                                                ? e
                                                                                                      .target
                                                                                                      .value
                                                                                                : "",
                                                                                            page: 1,
                                                                                        }
                                                                                    );

                                                                                    getScreeningResult(
                                                                                        {
                                                                                            ...meta,
                                                                                            q: e
                                                                                                .target
                                                                                                .value
                                                                                                ? e
                                                                                                      .target
                                                                                                      .value
                                                                                                : "",
                                                                                            page: 1,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                onClear={() => {
                                                                                    setMeta(
                                                                                        {
                                                                                            ...meta,
                                                                                            q: "",
                                                                                            page: 1,
                                                                                        }
                                                                                    );
                                                                                    getScreeningResult(
                                                                                        {
                                                                                            ...meta,
                                                                                            q: "",
                                                                                            page: 1,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        ref={
                                                                            scrollContainerRef
                                                                        }
                                                                        className={`question-result-data  max-height-table  ${
                                                                            isScrolled
                                                                                ? "scrolled"
                                                                                : ""
                                                                        } ${
                                                                            screeningResults &&
                                                                            screeningResults.length >
                                                                                8
                                                                                ? "responsive-table-scroll"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <table className="table screen-user-table">
                                                                            <thead>
                                                                                {tableLoading &&
                                                                                meta.page ==
                                                                                    1 ? (
                                                                                    <>
                                                                                        {getLoadingRows(
                                                                                            1
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <tr>
                                                                                        <th>
                                                                                            Screening
                                                                                            users
                                                                                        </th>
                                                                                        {questions.map(
                                                                                            function (
                                                                                                question,
                                                                                                index
                                                                                            ) {
                                                                                                return (
                                                                                                    <th>
                                                                                                        <div className="screen-question-th d-flex align-items-center">
                                                                                                            Question{" "}
                                                                                                            {index +
                                                                                                                1}
                                                                                                            <span className="info-wrap">
                                                                                                                <Icon
                                                                                                                    value={
                                                                                                                        "information"
                                                                                                                    }
                                                                                                                    colorClass={
                                                                                                                        "gray-700-svg"
                                                                                                                    }
                                                                                                                    size={
                                                                                                                        "medium"
                                                                                                                    }
                                                                                                                />

                                                                                                                <div className="tooltip-wrapper bottom-tooltip">
                                                                                                                    <Text
                                                                                                                        type="body-text-2"
                                                                                                                        fontWeight="normal-font"
                                                                                                                    >
                                                                                                                        {
                                                                                                                            JSON.parse(
                                                                                                                                question.questions
                                                                                                                            )
                                                                                                                                .question
                                                                                                                        }
                                                                                                                    </Text>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                        <th>
                                                                                            Screening
                                                                                            outcome
                                                                                        </th>
                                                                                        <th>
                                                                                            Participant
                                                                                            No.
                                                                                        </th>
                                                                                        <th>
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    setMeta(
                                                                                                        {
                                                                                                            ...meta,
                                                                                                            page: 1,
                                                                                                            column: "time_spent",
                                                                                                            direction:
                                                                                                                meta.column ==
                                                                                                                    "time_spent" &&
                                                                                                                meta.direction ==
                                                                                                                    "asc"
                                                                                                                    ? "desc"
                                                                                                                    : "asc",
                                                                                                        }
                                                                                                    );

                                                                                                    //setScreeningResults([]);

                                                                                                    getScreeningResult(
                                                                                                        {
                                                                                                            ...meta,
                                                                                                            page: 1,
                                                                                                            column: "time_spent",
                                                                                                            direction:
                                                                                                                meta.column ==
                                                                                                                    "time_spent" &&
                                                                                                                meta.direction ==
                                                                                                                    "asc"
                                                                                                                    ? "desc"
                                                                                                                    : "asc",
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                                className={`${
                                                                                                    meta.column &&
                                                                                                    meta.column ==
                                                                                                        "time_spent"
                                                                                                        ? meta.column
                                                                                                        : ""
                                                                                                } col-sort justify-content-between`}
                                                                                            >
                                                                                                Time
                                                                                                spent
                                                                                                <span>
                                                                                                    {meta.column ==
                                                                                                        "time_spent" &&
                                                                                                        meta.direction ==
                                                                                                            "desc" && (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    {meta.column ==
                                                                                                        "time_spent" &&
                                                                                                        meta.direction ==
                                                                                                            "asc" && (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    {meta.column !=
                                                                                                        "time_spent" && (
                                                                                                        <Icon
                                                                                                            value={
                                                                                                                "sort-icon"
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </span>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th>
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    setMeta(
                                                                                                        {
                                                                                                            ...meta,
                                                                                                            page: 1,
                                                                                                            column: "created_at",
                                                                                                            direction:
                                                                                                                meta.direction ==
                                                                                                                "asc"
                                                                                                                    ? "desc"
                                                                                                                    : "asc",
                                                                                                        }
                                                                                                    );

                                                                                                    //setScreeningResults([]);

                                                                                                    getScreeningResult(
                                                                                                        {
                                                                                                            ...meta,
                                                                                                            page: 1,
                                                                                                            column: "created_at",
                                                                                                            direction:
                                                                                                                meta.direction ==
                                                                                                                "asc"
                                                                                                                    ? "desc"
                                                                                                                    : "asc",
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                                className={`${
                                                                                                    meta.column &&
                                                                                                    meta.column ==
                                                                                                        "created_at"
                                                                                                        ? meta.column
                                                                                                        : ""
                                                                                                } col-sort justify-content-between`}
                                                                                            >
                                                                                                Date
                                                                                                <span>
                                                                                                    {meta.column ==
                                                                                                        "created_at" &&
                                                                                                        meta.direction ==
                                                                                                            "desc" && (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    {meta.column ==
                                                                                                        "created_at" &&
                                                                                                        meta.direction ==
                                                                                                            "asc" && (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    {meta.column !=
                                                                                                        "created_at" && (
                                                                                                        <Icon
                                                                                                            value={
                                                                                                                "sort-icon"
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </span>
                                                                                            </div>
                                                                                        </th>
                                                                                    </tr>
                                                                                )}
                                                                            </thead>
                                                                            <tbody>
                                                                                {screeningResults.map(
                                                                                    function (
                                                                                        item,
                                                                                        key
                                                                                    ) {
                                                                                        return (
                                                                                            <tr
                                                                                                key={
                                                                                                    "answer" +
                                                                                                    key
                                                                                                }
                                                                                            >
                                                                                                <td>
                                                                                                    User
                                                                                                </td>
                                                                                                {item.questions.map(
                                                                                                    function (
                                                                                                        question
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <td>
                                                                                                                <>
                                                                                                                    <Highlight>
                                                                                                                        {
                                                                                                                            question
                                                                                                                        }
                                                                                                                    </Highlight>
                                                                                                                </>
                                                                                                            </td>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                                <td
                                                                                                    className={`${
                                                                                                        item.screening_passed
                                                                                                            ? "green-text"
                                                                                                            : "red-text"
                                                                                                    }`}
                                                                                                >
                                                                                                    {item.screening_passed
                                                                                                        ? "Good fit"
                                                                                                        : "Not a fit"}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        item.participant_id
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {calculateTime(
                                                                                                        item.time_spent
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span>
                                                                                                        {moment(
                                                                                                            item.created_at
                                                                                                        ).format(
                                                                                                            "DD/MM/Y"
                                                                                                        )}
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                )}

                                                                                {tableLoading && (
                                                                                    <>
                                                                                        {getLoadingRows(
                                                                                            meta.limit
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                {screeningResults &&
                                                                                    screeningResults.length ==
                                                                                        0 && (
                                                                                        <tr>
                                                                                            <td
                                                                                                colSpan={
                                                                                                    8
                                                                                                }
                                                                                                className="border-0"
                                                                                            >
                                                                                                <div className="text-center">
                                                                                                    <EmptySearchPlaceholder
                                                                                                        type="Clear filter"
                                                                                                        title="Clear filter"
                                                                                                        clearSearch={() => {
                                                                                                            setMeta(
                                                                                                                {
                                                                                                                    ...meta,
                                                                                                                    q: "",
                                                                                                                    screening_outcome:
                                                                                                                        "All",
                                                                                                                    page: 1,
                                                                                                                }
                                                                                                            );
                                                                                                            getScreeningResult(
                                                                                                                {
                                                                                                                    ...meta,
                                                                                                                    q: "",
                                                                                                                    screening_outcome:
                                                                                                                        "All",
                                                                                                                    page: 1,
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                        cssClass="by-user-placeholder"
                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                {loadMore && (
                                                                    <div
                                                                        className={
                                                                            "load-more-data"
                                                                        }
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            size={`large`}
                                                                            label="Load more"
                                                                            onClick={() => {
                                                                                let page =
                                                                                    ++meta.page;

                                                                                setMeta(
                                                                                    {
                                                                                        ...meta,
                                                                                        page: page,
                                                                                    }
                                                                                );

                                                                                getScreeningResult(
                                                                                    {
                                                                                        ...meta,
                                                                                        page: page,
                                                                                    }
                                                                                );

                                                                                setLoadMoreLoading(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            microLoading={
                                                                                loadMoreLoading
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!isSharedLink && (
                <a
                    onClick={(e) => {
                        e.preventDefault();

                        setShowSupportPanel(true);
                    }}
                    href={process.env.REACT_APP_URL + "r/support"}
                    target="_blank"
                    className="support-button"
                >
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z"
                            stroke="#9759CB"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Support
                </a>
            )}
        </LayoutResearcher>
    );
}
