import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { Icon } from "../Icon/Icon";
import {Button} from "../Button/Button";

let image_width = null;

export const PreviewNav = ({open, items, loading, close, withMinimizeIcon, rtl, index,answers,setAnswerData,isEnableVote})=>{

    const [scale, setScale] = useState(100);

    const scrollContainer = useRef();

    const imgRef = useRef();

    const [width, setWidth] = useState(null);

    

    const closeModal = () => {
        close();
    };
    const [activeIndex, setActiveIndex] = useState(index);

    

    const handleZoomIn = (event) => {
        setScale(prevScale => prevScale + 10);

        if(imgRef && imgRef.current){
          
            if(!image_width){
                image_width = imgRef.current.clientWidth;
                
            }
            setWidth(image_width*(scale+10)/100);
        }
        
    };
    
      const handleZoomOut = () => {
        setScale(prevScale => Math.max(10, prevScale - 10));

        if(imgRef && imgRef.current){

            if(!image_width){
                image_width = imgRef.current.clientWidth;
            }
            if(scale >10){
                setWidth(  image_width*(scale-10)/100);
            }
            
        }
        
      };

    useEffect(()=>{
        setScale(100);

        if(open){
            setActiveIndex(index);
        }
    },[index,open]);

    useEffect(()=>{
        setScale(100);

        
    },[activeIndex]);

    useEffect(()=>{
        if(open){
            setScale(100);
            setWidth(null);
    image_width = null;
        }
    },[open]);

    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap ${rtl?"arabic-wrapper":""}  confirmation-modal-wrap designs-preview-modal ${withMinimizeIcon?"preview-min-icon":""}`}
            onHide={() => {
                closeModal();
            }}
            style={{background:items[activeIndex].color}}
        >
            <Modal.Body className=""  style={{background:items[activeIndex].color}}>
                {loading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text" style={{background:items[activeIndex].background}}>
                        <div className="modal-header-top">
                            <h2 className={`heading h2`}>Preview</h2>

                            <button
                                type="button"
                                className="close black-bg"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <Icon value="Close" colorClass={'gray-50-svg'} size="medium" />
                                </span>
                            </button>
                        </div>
                        {items[activeIndex].type!="video" &&
                        <div className="zoom-controls-wrapper">
                            <div className="zoom-controls" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                <span className="zoom-in" onClick={handleZoomIn}>
                                <Icon value="add" colorClass={'gray-900-svg'} size="medium" />
                                </span>
                                <span className="zoom-out" onClick={handleZoomOut}>
                                <Icon value="Minimize" colorClass={'gray-900-svg'} size="medium" />
                                </span>
                                
                            </div>
                            <span className="zoom-percentage">{scale+"%"}</span>
                        </div>
                        }
                        
                            {items.length > 0 &&
                            <div className="pref-test-btns-wrap preview-modal-nav-arrows">
                                <div className={`btn prevPage ${activeIndex == 0 ? 'disabled-nav' : ''}`} 
                                    onClick={() => 
                                        { 
                                            if (activeIndex != 0) { 
                                                setActiveIndex(activeIndex - 1); 
                                            }
                                        }}>
                                       <Icon colorClass="gray-50-svg" value={'back arrow'}/>     
                                    
                                </div>
                                <div className={`btn nextPage ${activeIndex == items.length - 1 ? 'disabled-nav' : ''}`} 
                                
                                    onClick={() => 
                                        { 
                                            if (activeIndex < items.length - 1) { 
                                                setActiveIndex(activeIndex + 1); 
                                            } 
                                        }}>
                                             <Icon colorClass="gray-50-svg" value={'forward arrow'}/>  
                                   
                                </div>
                            </div>
                            }
                           <div  ref={scrollContainer} className="prefer-design-view-img-hold" style={{overflow:"auto"}} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); closeModal();}}>
                            {items[activeIndex].type=="video"
                                ?
                            <video controls>
                                <source src={items[activeIndex].design_file.includes("https") ? items[activeIndex].design_file : process.env.REACT_APP_IMG_URL + items[activeIndex].design_file} />
                            </video>
                                :
                            <div className={`zoom-container ${scale>100?"active-zoom":""}`} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); }}  >
                                <img ref={imgRef} style={{width:  width!=null?width+"px":'auto'}} src={items[activeIndex].design_file.includes("https") ? items[activeIndex].design_file : process.env.REACT_APP_IMG_URL + items[activeIndex].design_file} />
                            </div>
                            }
                        <div className="prefer-design-full-view">
                        {isEnableVote &&
                        <>
                            {answers && 'answer_id' in answers && items[activeIndex].id == answers.answer_id ?
                        

                                <Button
                                    type={"secondary"}
                                    size={"small"}
                                    cssclass={`${answers && 'answer_id' in answers && items[activeIndex].id == answers.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                    onClick={() => setAnswerData(items[activeIndex].id)}
                                    label={!rtl ?
                                        "You've voted for this option"
                                        : "حدد هذا الخيار"}
                                    iconRight={<Icon colorClass="gray-50-svg" value={'check mark'}/>}
                                />
                                :

                                <Button
                                    type={"secondary"}
                                    size={"small"}
                                    className={`${answers && 'answer_id' in answers && items[activeIndex].id == answers.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                    onClick={() => setAnswerData(items[activeIndex].id)}
                                    label={!rtl ?
                                        "I'd vote for this option"
                                        : "حدد هذا الخيار"}
                                    iconRight={<Icon size={'small'} value={'like'}/>}
                                />
                                
                            }
                        </>
                        }
                        </div>
                        </div> 



                        
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
    
}
