import { useNavigate, useParams, Link } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import React, { useEffect, useState, useRef } from "react";
import LayoutResearcherFullWidth from '../../layouts/layout_researcher_full_width';
import { Dropdown } from "reactjs-dropdown-component";
import { getTargetGroupOptions } from "../../../services/target_group.js";
import "react-multi-carousel/lib/styles.css";
import toast from 'react-hot-toast';
import MultiRangeSlider from "multi-range-slider-react";
import Select from 'react-select'
import { Modal } from 'react-bootstrap';
import {DropdownIndicator, nFormatter, normalize} from '../../../lib/helpers';
import { createTargetGroup } from '../../../services/target_group.js';
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import CtaActionModal from '../../dialog/cta_action_modal';

export default function AddTargetGroup() {

    let { project_id } = useParams();

    const [formLoading, setFormLoading] = useState(false);

    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [target_group_name, setTargetGroupName] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [errorMsg, setErrorMessage] = useState(null);

    const [modal, setModal] = useState({ modalHeading: null, modalMessage: null, modalOpen: false });

    const [cta_modal, setCTAModal] = useState({ open: false });

    const [formErrors, setFormErrors] = useState({ image: null, project_name: null, "error_class": null });

    const [educationLabelClass, setEducationLabelClass] = useState("inactive_label");

    const [incomeLabelClass, setIncomeLabelClass] = useState("inactive_label");

    const [hoursLabelClass, setHoursLabelClass] = useState("inactive_label");

    const [employmentLabelClass, setEmploymentLabelClass] = useState("inactive_label");

    const [industryLabelClass, setIndustryLabelClass] = useState("inactive_label");

    const [departmentLabelClass, setDepartmentLabelClass] = useState("inactive_label");

    const [nationalityLabelClass, setNationalityLabelClass] = useState("inactive_label");

    const [emirateLabelClass, setEmirateLabelClass] = useState("inactive_label");

    const [ksaLabelClass, setKsaLabelClass] = useState("inactive_label");

    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [deviceLabelClass, setDeviceLabelClass] = useState("inactive_label");

    const [appLabelClass, setAppLabelClass] = useState("inactive_label");

    const [formValues, setFormValues] = useState({
        "age_match": 1,
        "gender_match": 1,
        "gender": "",
        "min_age": 18,
        "max_age": 90,
        "male_perc": 50,
        "female_perc": 50,
        "nationality_match": 0,
        "nationalities": [],
        "residency_match": 0,
        "emirates_match": 0,
        "ksa_match": 0,
        "countries_match": 0,
        "countries": [],
        "emirates": [],
        "ksa": [],
        "countries": [],
        "education_match": 0,
        "education": null,
        "technology_match": 0,
        "hours_spend_online": null,
        "devices": [],
        "apps": [],
        "employment_match": 0,
        "employment": [],
        "industry": [],
        "department": [],
        "earnings_match": 0,
        "household_members_condition": "All",
        "people": 0,
        "term": "Yearly",
        "income_slab":[],
        "min_income": 0,
        "max_income": 1000000


    });
    const [options, setOptions] = useState({
        "min_age": 0,
        "max_age": 100,
        "min_gender": 0,
        "max_gender": 100,
        "nationalities": [],
        "group_nationalities": { "East Asian": [], "South Asian": [], "Arab Expats": [], "Emirati": [], "Saudi": [], "Europeans": [] },
        "emirates": [],
        "ksa": [],
        "countries": [],
        "education": [],
        "hours_spend_online": [],
        "devices": [],
        "apps": [],
        "employment": [],
        "industry": [],
        "department": [],
        "min_income": 0,
        "max_income": 1,
        "income_levels":[]
    });



    const getTargetGroupOptionsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTargetGroupOptions({}, token).then(response => {
            setLoading(false);

            if (response.success) {

                if (formValues.min_age < response.options.age_range.min) {
                    setFormValue("min_age", response.options.age_range.min);
                }
                if (formValues.max_age > response.options.age_range.max) {
                    setFormValue("max_age", response.options.age_range.max);
                }
                var other_countries = [];

                for(var  i=0; i<response.options.countries.length;i++){
                    if(response.options.countries[i].value!="United Arab Emirates" && response.options.countries[i].value!="Saudi Arabia"){
                        other_countries.push(response.options.countries[i]);
                    }
                }
                setOptions({
                    min_age: response.options.age_range.min,
                    max_age: response.options.age_range.max,
                    min_gender: 0,
                    max_gender: 100,
                    nationalities: response.options.nationalities,
                    group_nationalities: response.options.group_nationalities,
                    emirates: response.options.emirates,
                    ksa: response.options.ksa,
                    countries: other_countries,
                    education: response.options.education,
                    hours_spend_online: response.options.hours_spend_online,
                    devices: response.options.devices,
                    apps: response.options.apps,
                    employment: response.options.employment,
                    industry: response.options.industry,
                    department: response.options.department,
                    min_income: response.options.income.min.value,
                    max_income: response.options.income.max.value,
                    min_income_label: response.options.income.min.label,
                    max_income_label: response.options.income.max.label,
                    income_levels: response.options.income_levels
                });

            } else {
                toast.error(response.setErrorMessage)
            }
        });
    }
    useEffect(() => {



        document.title = process.env.REACT_APP_NAME + " - Target Group";

        setTargetGroupName(ReactSession.get("target_group_name"));

        getTargetGroupOptionsApi();

    }, []);


    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({ ...oldValues, [name]: value }));
        }
    };
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));



    };
    const toggleCheck = (name, value) => {
        if (name == "age_match") {
            if (value !== 1) {
                setFormValue("min_age", options.min_age);
                setFormValue("max_age", options.max_age);
            }

        }
        if (name == "gender_match") {
            if (value !== 1) {
                setFormValue("male_perc", 50);
                setFormValue("female_perc", 50);
                setFormValue("gender", "Any");
            }

        }
        if (name == "nationality_match") {

            if (value !== 1) {

                setFormValue("nationalities", []);

                setNationalityLabelClass("inactive_label");
            }
        }
        if (name == "residency_match") {

            if (value !== 1) {

                setFormValue("ksa", []);
                setFormValue("emirates", []);
                setFormValue("countries", []);

                setFormValue("ksa_match", 0);
                setFormValue("emirates_match", 0);
                setFormValue("countries_match", 0);

                setEmirateLabelClass("inactive_label");
                setKsaLabelClass("inactive_label");
                setCountryLabelClass("inactive_label");

            }
        }
        if (name == "emirates_match") {

            if (value !== 1) {

                setFormValue("emirates", []);
                setFormValue("emirates_match", 0);
                setEmirateLabelClass("inactive_label");
            }
        }
        if (name == "ksa_match") {

            if (value !== 1) {

                setFormValue("ksa", []);
                setFormValue("ksa_match", 0);

                setKsaLabelClass("inactive_label");

            }
        }
        if (name == "countries_match") {

            if (value !== 1) {

                setFormValue("countries", []);
                setFormValue("countries_match", 0);

                setCountryLabelClass("inactive_label");
            }
        }
        if (name == "education_match") {

            if (value !== 1) {

                setFormValue("education", []);
                setEducationLabelClass("inactive_label");
            }
        }
        if (name == "technology_match") {

            if (value !== 1) {

                setFormValue("hours_spend_online", null);

                setFormValue("devices", []);

                setFormValue("apps", []);

                setHoursLabelClass("inactive_label");

                setDeviceLabelClass("inactive_label");

                setAppLabelClass("inactive_label");

            }
        }
        if (name == "employment_match") {

            if (value !== 1) {

                setFormValue("employment", []);

                setFormValue("industry", []);

                setFormValue("department", []);

                setEmploymentLabelClass("inactive_label");

                setIndustryLabelClass("inactive_label");

                setDepartmentLabelClass("inactive_label");

            }
        }
        if (name == "earnings_match") {

            if (value !== 1) {

                setFormValue("household_members_condition", "All");

                setFormValue("people", 0);

                setFormValue("income", 0);

                setFormValue("term", "Yearly");

                setFormValue("income_slab", []);

                setIncomeLabelClass("inactive_label");

            }
        }
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    const onSubmit = async (event) => {

        event.preventDefault();

        saveTargetGroup();

        return false;
    }
    const saveTargetGroup = () => {

        resetFormErrors();


        if (!formLoading) {

            var error = false;


            if (!error) {
                setFormLoading(true);

                ReactSession.set("new_target_id", null);

                const token = ReactSession.get("token");

                var formData = Object.assign({}, formValues);

                formData["target_group_name"] = ReactSession.get("target_group_name");

                formData["project_id"] = project_id;

                formData['nationalities'] = normalize(formData['nationalities']);

                formData['countries'] = normalize(formData['countries']);

                formData['emirates'] = normalize(formData['emirates']);

                formData['ksa'] = normalize(formData['ksa']);

                formData['education'] = formData['education'] ? formData['education']['value'] : '';

                formData['devices'] = normalize(formData['devices']);

                formData['hours_spend_online'] = formData['hours_spend_online'] ? formData['hours_spend_online']['value'] : '';

                formData['employment'] = normalize(formData['employment']);

                formData['department'] = normalize(formData['department']);

                formData['industry'] = normalize(formData['industry']);

                formData['apps'] = normalize(formData['apps']);

                formData['gender'] = formData["gender_match"] == 1 ? formData["gender"] : "";


                formData['income_slab'] = normalize(formData['income_slab']);

                createTargetGroup(formData, token)
                    .then(response => {
                        setFormLoading(false);

                        if (response.success) {

                            

                            if (response.testers_count < 30) {

                                setCTAModal({open: true, 
                                    title:"Your target group has been created", 
                                    "message": <>Before you can start testing with this group, more testers may be needed. Either change the criteria or reach us at <Link to={'/researcher/support/contact?reason=Help with recruitment'}>support@userq.com</Link> for help.</>, 
                                    close_action:function(){
                                        if(ReactSession.get('is_add_new_target_group')) {
                                            ReactSession.set("new_target_id", response.new_target_group_id.toString());
                                        }
                                        setCTAModal({...cta_modal,open: false});
                                        navigate(-1);
                                    } })
                                
                            } else {
                                if(ReactSession.get('is_add_new_target_group')) {
                                    ReactSession.set("new_target_id", response.new_target_group_id.toString());
                                }
                                navigate(-1);
                                var id = toast((
                                    <div className='toastinner'>
                                        <div className="d-flex justify-content-center w-100">
                                            {/*Target group created,&nbsp; <strong className="font-weight-600">{response.testers_count} testers</strong> &nbsp;included*/}
                                            {response.message}
                                        </div>
                                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                                    </div>), {
                                    className: 'successtoast',
                                    position: 'bottom-center',
                                });
                            }
                            setSuccessMessage(response.message);


                        } else {
                            setErrorMessage(response.message);
                        }
                    })
            }
        }
    }
    const nationalityChange = (items) => {

        if (items.length > 0) {
            setNationalityLabelClass('active_label');
        } else {
            setNationalityLabelClass('inactive_label');
        }
        setFormValue("nationalities", items);

    }

    const getFastClass = (name) => {
        let nationalities = formValues.nationalities;

        let check = true;

        let fast_nationalities = options["group_nationalities"][name];

        fast_nationalities.forEach(function (fast_item) {
            let exist = false;
            nationalities.forEach(function (item) {
                if (item.label == fast_item) {
                    exist = true;
                }
            })
            if (!exist) {
                check = false;
            }
        })
        if (check) {
            return 'active';
        }
        return '';
    }
    const selectFast = (name) => {

        return (e) => {

            if (formValues.nationality_match != 1) {
                return;
            }

            let nationalities = [...formValues.nationalities];


            let fast_nationalities = options["group_nationalities"][name];


            let fast_selection = [];

            fast_nationalities.forEach(function (fast_item) {
                fast_selection.push({
                    value: fast_item,
                    label: fast_item
                });
            });


            if (getFastClass(name) != 'active') {

                fast_selection.forEach(function (fast_item) {
                    let exist = false;
                    nationalities.forEach(function (item) {
                        if (item.value == fast_item.value) {
                            exist = true;
                        }
                    })
                    if (!exist) {
                        nationalities.push(fast_item);
                    }
                });
            }
            if (getFastClass(name) == 'active') {
                fast_selection.forEach(function (fast_item) {

                    var exist = -1;
                    nationalities.forEach(function (item, index) {
                        if (item.value == fast_item.value) {
                            exist = index;
                        }
                    })
                    if (exist != -1) {
                        nationalities.splice(exist, 1);
                    }

                });
            }
            if (nationalities.length == 0) {
                setNationalityLabelClass("inactive_label");
            } else {
                setNationalityLabelClass("active_label");
            }
            setFormValue('nationalities', nationalities);

            return;
        }

    }
    const addPeople = () => {
        if (formValues.earnings_match !== 1) {
            return;
        }
        var people = Number(formValues.people);
        if (people < 10) {
            setFormValue('people', people + 1)
        }
    };
    const minusPeople = () => {

        if (formValues.earnings_match !== 1) {
            return;
        }
        var people = Number(formValues.people);
        if (people > 0) {

            setFormValue('people', people - 1)
        }
    };
    const changePeople = (e) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            setFormValue('people', Number(e.target.value));
        }
    };



    function showmore(divID) {
        //document.getElementById(divID).style.height = '600px';
        document.getElementById(divID).classList.add("expendit");
    }

    function showless(divID) {
        document.getElementById(divID).classList.remove("expendit");
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            var slides = document.getElementsByClassName("target-group-data-repeat");
            for (var i = 0; i < slides.length; i++) {
                var bbheight = 0;
                var ccheight = 0;
                var bb = slides.item(i).getElementsByClassName("targer-group-left-wrap")[0];
                var cc = slides.item(i).getElementsByClassName("target-group-info-repeat")[0];
                if (bb) {
                    bbheight = bb.offsetHeight;
                }
                if (cc) {
                    ccheight = cc.offsetHeight;
                }
                //console.log('>>'+bbheight+'<><>'+ccheight);
                if (bbheight < ccheight) {
                    cc.classList.add('lessshow');
                    cc.style.height = bbheight + 'px';
                } else {
                    cc.classList.add('normalshow');
                }
            }



        }, 3000);
        return () => clearTimeout(timer);

    }, []);




    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }
    return (
        <LayoutResearcherFullWidth
            isLoading={false}
            fixed_header_target={true}
            skipCheck={false}
            activeMenu={"dashboard"}
        >
            <form onSubmit={onSubmit}>
                <div className="page-back-option-wrap target_group_fixed_header">
                    <div className="container inner-page-container">
                        <div className="profile-info-section">
                            <div className="target-back-option-data">
                                <div className="backto-list-wrap">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(-1);
                                        }}
                                    >
                                        <img
                                            src={
                                                process.env.REACT_APP_URL + "images/back-arrow.svg"
                                            }
                                            alt="img"
                                        />{" "}
                                        Back
                                    </a>
                                </div>
                                <div className="profile-info-text">
                                    <div className="target-group-heading-top">
                                        <h1 className="h1">{target_group_name}</h1>
                                    </div>
                                    {/*
                                    <div className="target-group-btns-top">
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModal({
                                                    modalHeading: "Email Sent",
                                                    modalMessage:
                                                        "We just sent an email! Click on the link in the email to generate a new password.",
                                                    modalOpen: true,
                                                });
                                            }}
                                            className="btn form-btn secondary-btn"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn form-btn">
                                            Save
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container inner-page-container create-new-target-group-page-wrap">
                    <div className="target-group-data-wrapper">
                        <div className="target-group-data-repeat">
                            <div className="targer-group-left-wrap">
                                <h3>Demographics</h3>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="target-form-wrap">
                                            <label className="check-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={formValues.age_match}
                                                    onChange={(event) =>
                                                        toggleCheck(
                                                            "age_match",
                                                            event.currentTarget.checked ? 1 : 0
                                                        )
                                                    }
                                                />
                                                <span className="check-slider"></span>
                                                <p>Age</p>
                                            </label>
                                            {formValues.age_match == 1 && (
                                                <div className="target-group-col-data">
                                                    <MultiRangeSlider
                                                        disabled={
                                                            formValues.age_match == 1 ? true : false
                                                        }
                                                        min={options.min_age}
                                                        max={options.max_age}
                                                        step={1}
                                                        ruler={false}
                                                        label={false}
                                                        preventWheel={false}
                                                        minValue={formValues.min_age}
                                                        maxValue={formValues.max_age}
                                                        onInput={(e) => {
                                                            setFormValue("min_age", e.minValue);
                                                            setFormValue("max_age", e.maxValue);
                                                            setFormValue("age_match", 1);
                                                        }}
                                                    />

                                                    <div className="gender-range-data">
                                                        <span>
                                                            <b>{formValues.min_age}</b> years
                                                        </span>
                                                        <span>
                                                            <b>{formValues.max_age}</b> years
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="target-form-wrap">
                                            <label className="check-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={formValues.gender_match}
                                                    onChange={(event) =>
                                                        toggleCheck(
                                                            "gender_match",
                                                            event.currentTarget.checked ? 1 : 0
                                                        )
                                                    }
                                                />
                                                <span className="check-slider"></span>
                                                <p>Gender</p>
                                            </label>
                                            {formValues.gender_match == 1 && (
                                                <div className="target-group-col-data">
                                                    <div className="checkboxes radio_btn_class gender_btn  d-flex">
                                                        <Radiobtn name="gender" value="Male" id="male" label={"Male"} onChange={() => { setFormValue('gender', "Male"); }} checked={(formValues.gender == "Male") ? true : false} />
                                                        <Radiobtn name="gender" value="Female" id="female" label={"Female"} onChange={() => { setFormValue('gender', "Female"); }} checked={formValues.gender == "Female" ? true : false} />
                                                        <Radiobtn name="gender" value="Any" id="any" label={"Any"} onChange={() => { setFormValue('gender', "Any"); }} checked={(formValues.gender == "Any" || !formValues.gender) ? true : false} />
                                                    </div>
                                                    {/*<input
                              type="range"
                              min={0}
                              max={100}
                              step={1}
                              value={formValues.male_perc}
                              onChange={(e) => {
                                setFormValue("male_perc", e.target.value);
                                setFormValue(
                                  "female_perc",
                                  100 - e.target.value
                                );
                                setFormValue("gender_match", 1);
                              }} 
                            />
                            <div className="gender-range-data">
                              <span>
                                <b>{formValues.male_perc}%</b> Male
                              </span>
                              <span>
                                <b>{formValues.female_perc}%</b> Female
                              </span>
                            </div>*/}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="repaetinfobox1" className="target-group-info-repeat repaetinfobox1">
                                <h3>Demographics</h3>
                                <p>
                                    Choose the age range and gender split of your target
                                    audience.
                                </p>

                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox1");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox1");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>
                        <div className="target-group-data-repeat target-residency-data">
                            <div className="targer-group-left-wrap">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="check-switch">
                                            <input
                                                type="checkbox"
                                                checked={formValues.residency_match}
                                                onChange={(event) =>
                                                    toggleCheck(
                                                        "residency_match",
                                                        event.currentTarget.checked ? 1 : 0
                                                    )
                                                }
                                            />
                                            <span className="check-slider"></span>
                                            <p className="big-label-text">Residency</p>
                                        </label>
                                    </div>
                                    {formValues.residency_match == 1 && (
                                        <>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="form-group custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={
                                                                        formValues.residency_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    checked={formValues.emirates_match}
                                                                    onChange={(event) =>{
                                                                        toggleCheck(
                                                                            "emirates_match",
                                                                            event.currentTarget.checked ? 1 : 0
                                                                        );
                                                                        if(event.currentTarget.checked){
                                                                        setFormValue("emirates",[{value: "All emirates of residency", label: "All emirates of residency"}]);
                                                                        }
                                                                    }
                                                                    }
                                                                    className="custom-control-input"
                                                                    id="customCheck1"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck1"
                                                                >
                                                                    UAE residents
                                                                </label>
                                                            </div>
                                                            <div
                                                                className={`input-field mb-0 ${formValues.emirates_match != 1
                                                                        ? "read-only-field"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                <label className={`${emirateLabelClass}`}>
                                                                    Emirate of residency
                                                                </label>
                                                                <Select
                                                                    placeholder="Emirate of residency"
                                                                    value={formValues.emirates}
                                                                    options={options.emirates}
                                                                    onChange={(items) => {
                                                                        
                                                                        if(items.length==2 && items[items.length-1].value!="All emirates of residency"){
                                                                        items = items.filter(function( obj ) {
                                                                        return obj.value !== 'All emirates of residency';
                                                                        });
                                                                        }
                                                                        if(items.length>=2 && items[items.length-1].value=="All emirates of residency"){
                                                                            items = items.filter(function( obj ) {
                                                                                return obj.value == 'All emirates of residency';
                                                                            });
                                                                        }

                                                                        items.length > 0
                                                                            ? setEmirateLabelClass("active_label")
                                                                            : setEmirateLabelClass(
                                                                                "inactive_label"
                                                                            );
                                                                        setFormValue("emirates", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.emirates_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="form-group custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={
                                                                        formValues.residency_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    checked={formValues.ksa_match}
                                                                    onChange={(event) =>{
                                                                        toggleCheck(
                                                                            "ksa_match",
                                                                            event.currentTarget.checked ? 1 : 0
                                                                        );
                                                                        if(event.currentTarget.checked){
                                                                        setFormValue("ksa",[{value: "All provinces of residency", label: "All provinces of residency"}]);
                                                                        }
                                                                    }
                                                                    }
                                                                    className="custom-control-input"
                                                                    id="customCheck2"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck2"
                                                                >
                                                                    Saudi Arabia residents
                                                                </label>
                                                            </div>
                                                            <div
                                                                className={`input-field mb-0 ${formValues.ksa_match != 1
                                                                        ? "read-only-field"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                <label className={`${ksaLabelClass}`}>
                                                                    Region of residency
                                                                </label>
                                                                <Select
                                                                    placeholder="Region of residency"
                                                                    value={formValues.ksa}
                                                                    options={options.ksa}
                                                                    onChange={(items) => {

                                                                        if(items.length==2 && items[items.length-1].value!="All provinces of residency"){
                                                                            items = items.filter(function( obj ) {
                                                                            return obj.value !== 'All provinces of residency';
                                                                            });
                                                                        }
                                                                        if(items.length>=2 && items[items.length-1].value=="All provinces of residency"){
                                                                            items = items.filter(function( obj ) {
                                                                                return obj.value == 'All provinces of residency';
                                                                            });
                                                                        }

                                                                        items.length > 0
                                                                            ? setKsaLabelClass("active_label")
                                                                            : setKsaLabelClass("inactive_label");
                                                                        setFormValue("ksa", items);
                                                                        
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.ksa_match == 1 ? false : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="form-group custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={
                                                                        formValues.residency_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    checked={formValues.countries_match}
                                                                    onChange={(event) =>
                                                                        toggleCheck(
                                                                            "countries_match",
                                                                            event.currentTarget.checked ? 1 : 0
                                                                        )
                                                                    }
                                                                    className="custom-control-input"
                                                                    id="customCheck3"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="customCheck3"
                                                                >
                                                                    Other countries
                                                                </label>
                                                            </div>
                                                            <div
                                                                className={`input-field mb-0 ${formValues.countries_match != 1
                                                                        ? "read-only-field"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                <label className={`${countryLabelClass}`}>
                                                                    Select countries
                                                                </label>
                                                                <Select
                                                                    placeholder="Select countries"
                                                                    value={formValues.countries}
                                                                    options={options.countries}
                                                                    onChange={(items) => {
                                                                        items.length > 0
                                                                            ? setCountryLabelClass("active_label")
                                                                            : setCountryLabelClass(
                                                                                "inactive_label"
                                                                            );
                                                                        setFormValue("countries", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.countries_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div id="repaetinfobox2" className="target-group-info-repeat repaetinfobox2">
                                <h3>Residency</h3>
                                <p>
                                Choose participants from certain countries and regions.
                                </p>

                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox2");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox2");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>
                        <div className="target-group-data-repeat">
                            <div className="targer-group-left-wrap">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="target-form-wrap">
                                            <label className="check-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={formValues.nationality_match}
                                                    onChange={(event) =>
                                                        toggleCheck(
                                                            "nationality_match",
                                                            event.currentTarget.checked ? 1 : 0
                                                        )
                                                    }
                                                />
                                                <span className="check-slider"></span>
                                                <p className="big-label-text">Nationalities</p>
                                            </label>
                                            {formValues.nationality_match == 1 && (
                                                <div className="target-group-col-data">
                                                    <div className="profile-form-group">
                                                        <div className="input-field mb-0">
                                                            <div className="">
                                                                <label className={`${nationalityLabelClass}`}>
                                                                    Select nationalities...
                                                                </label>
                                                                <Select
                                                                    placeholder="Select nationalities..."
                                                                    className="userq"
                                                                    classNamePrefix="userq-select"
                                                                    value={formValues.nationalities}
                                                                    options={options.nationalities}
                                                                    onChange={nationalityChange}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.nationality_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {formValues.nationality_match == 1 && (
                                        <div className="col-lg-12">
                                            <div className="profile-form-group mobile-appslist-outer add-target-first-selection">
                                                <div className="input-field static-label mb-0">
                                                    <label>Quick selection</label>
                                                    <div className="mobile-apps-list-wrap">
                                                        <span
                                                            className={getFastClass("Emirati")}
                                                            onClick={selectFast("Emirati")}
                                                        >
                                                            Emirati
                                                        </span>
                                                        <span
                                                            className={getFastClass("Saudi")}
                                                            onClick={selectFast("Saudi")}
                                                        >
                                                            Saudi
                                                        </span>
                                                        <span
                                                            className={getFastClass("East Asian")}
                                                            onClick={selectFast("East Asian")}
                                                        >
                                                            East Asian
                                                        </span>
                                                        <span
                                                            className={getFastClass("South Asian")}
                                                            onClick={selectFast("South Asian")}
                                                        >
                                                            Asian Expats
                                                        </span>
                                                        <span
                                                            className={getFastClass("Arab Expats")}
                                                            onClick={selectFast("Arab Expats")}
                                                        >
                                                            Arab Expats
                                                        </span>
                                                        <span
                                                            className={getFastClass("Europeans")}
                                                            onClick={selectFast("Europeans")}
                                                        >
                                                            Europeans
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div id="repaetinfobox3" className="target-group-info-repeat repaetinfobox3">
                                <h3>Nationalities</h3>
                                <p>
                                    We have a diverse panel of participants. Choose the nationalities
                                    you would like to target.{" "}
                                </p>
                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox3");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox3");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>

                        <div className="target-group-data-repeat tg-highest-level-edu">
                            <div className="targer-group-left-wrap">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="check-switch">
                                            <input
                                                type="checkbox"
                                                checked={formValues.education_match}
                                                onChange={(event) =>
                                                    toggleCheck(
                                                        "education_match",
                                                        event.currentTarget.checked ? 1 : 0
                                                    )
                                                }
                                            />
                                            <span className="check-slider"></span>
                                            <p className="big-label-text">
                                                Highest level of education
                                            </p>
                                        </label>
                                    </div>
                                    {formValues.education_match == 1 && (
                                        <div className="col-lg-6">
                                            <div className="target-form-wrap">
                                                <div className="target-group-col-data">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label mb-0">
                                                            <label className={`${educationLabelClass}`}>
                                                                Select levels of education...
                                                            </label>
                                                            {/* <Select
                                                            isClearable
                                                            placeholder={"Select levels of education..."}
                                                            value={formValues.education}
                                                            options={options.education}
                                                            onChange={(item)=>{ if(item==null){ setEducationLabelClass("inactive_label"); } else { setEducationLabelClass("active_label"); } setFormValue("education",item)}}
                                                            isDisabled={formValues.education_match==1 ? false : true}
                                                        /> */}
                                                            <Dropdown
                                                                name="education_level"
                                                                title="Select levels of education..."
                                                                searchable={[
                                                                    "Search for education level",
                                                                    "No matching education level",
                                                                ]}
                                                                list={options.education}
                                                                onChange={(item) => {
                                                                    if (item == null) {
                                                                        setEducationLabelClass("inactive_label");
                                                                    } else {
                                                                        setEducationLabelClass("active_label");
                                                                    }
                                                                    setFormValue("education", item);
                                                                }}
                                                                styles={{
                                                                    headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                    headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div id="repaetinfobox4" className="target-group-info-repeat repaetinfobox4">
                                <h3>Education</h3>
                                <p>
                                    Select the minimum education level you require for your
                                    participants.{" "}
                                </p>

                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox4");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox4");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>
                        <div className="target-group-data-repeat tg-confidence-level-tec">
                            <div className="targer-group-left-wrap">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="check-switch">
                                            <input
                                                type="checkbox"
                                                checked={formValues.technology_match}
                                                onChange={(event) =>
                                                    toggleCheck(
                                                        "technology_match",
                                                        event.currentTarget.checked ? 1 : 0
                                                    )
                                                }
                                            />
                                            <span className="check-slider"></span>
                                            <p className="big-label-text">
                                                Confidence with technology
                                            </p>
                                        </label>
                                    </div>
                                    {formValues.technology_match == 1 && (
                                        <>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label mb-0">
                                                                <label className={`${hoursLabelClass}`}>
                                                                    Average time spent online per day
                                                                </label>
                                                                <Select
                                                                    isClearable
                                                                    placeholder={
                                                                        "Average time spent online per day"
                                                                    }
                                                                    value={formValues.hours_spend_online}
                                                                    options={options.hours_spend_online}
                                                                    onChange={(item) => {
                                                                        if (item == null) {
                                                                            setHoursLabelClass("inactive_label");
                                                                        } else {
                                                                            setHoursLabelClass("active_label");
                                                                        }
                                                                        setFormValue("hours_spend_online", item);
                                                                    }}
                                                                    isDisabled={
                                                                        formValues.technology_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label mb-0">
                                                                <label className={`${deviceLabelClass}`}>
                                                                    Select favorite device...
                                                                </label>
                                                                <Select
                                                                    placeholder={"Select favorite device..."}
                                                                    value={formValues.devices}
                                                                    options={options.devices}
                                                                    onChange={(items) => {
                                                                        items.length > 0
                                                                            ? setDeviceLabelClass("active_label")
                                                                            : setDeviceLabelClass("inactive_label");
                                                                        setFormValue("devices", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.technology_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label mb-0">
                                                                <label className={`${appLabelClass}`}>
                                                                    Apps used
                                                                </label>
                                                                <Select
                                                                    placeholder={"Apps used"}
                                                                    value={formValues.apps}
                                                                    options={options.apps}
                                                                    onChange={(items) => {
                                                                        items.length > 0
                                                                            ? setAppLabelClass("active_label")
                                                                            : setAppLabelClass("inactive_label");
                                                                        setFormValue("apps", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.technology_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div id="repaetinfobox5" className="target-group-info-repeat repaetinfobox5">
                                <h3>Technology</h3>
                                <p>Choose participants with different technical competencies. </p>

                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox5");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox5");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>
                        <div className="target-group-data-repeat tg-emp-status">
                            <div className="targer-group-left-wrap">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="check-switch">
                                            <input
                                                type="checkbox"
                                                checked={formValues.employment_match}
                                                onChange={(event) =>
                                                    toggleCheck(
                                                        "employment_match",
                                                        event.currentTarget.checked ? 1 : 0
                                                    )
                                                }
                                            />
                                            <span className="check-slider"></span>
                                            <p className="big-label-text">Employment status</p>
                                        </label>
                                    </div>
                                    {formValues.employment_match == 1 && (
                                        <>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label mb-0">
                                                                <label className={`${employmentLabelClass}`}>
                                                                    Select curent employment status....
                                                                </label>
                                                                <Select
                                                                    placeholder={
                                                                        "Select curent employment status..."
                                                                    }
                                                                    value={formValues.employment}
                                                                    options={options.employment}
                                                                    onChange={(items) => {
                                                                        setEmploymentLabelClass(
                                                                            items.length > 0
                                                                                ? "active_label"
                                                                                : "inactive_label"
                                                                        );
                                                                        setFormValue("employment", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.employment_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label mb-0">
                                                                <label className={`${industryLabelClass}`}>
                                                                    Select industry....
                                                                </label>
                                                                <Select
                                                                    placeholder={"Select industry...."}
                                                                    value={formValues.industry}
                                                                    options={options.industry}
                                                                    onChange={(items) => {
                                                                        setIndustryLabelClass(
                                                                            items.length > 0
                                                                                ? "active_label"
                                                                                : "inactive_label"
                                                                        );
                                                                        setFormValue("industry", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.employment_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label mb-0">
                                                                <label className={`${departmentLabelClass}`}>
                                                                    Select department....
                                                                </label>
                                                                <Select
                                                                    placeholder={"Select department...."}
                                                                    value={formValues.department}
                                                                    options={options.department}
                                                                    onChange={(items) => {
                                                                        setDepartmentLabelClass(
                                                                            items.length > 0
                                                                                ? "active_label"
                                                                                : "inactive_label"
                                                                        );
                                                                        setFormValue("department", items);
                                                                    }}
                                                                    isMulti={true}
                                                                    isDisabled={
                                                                        formValues.employment_match == 1
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div id="repaetinfobox6" className="target-group-info-repeat repaetinfobox6">
                                <h3>Employment</h3>
                                <p>
                                    Choose participants of different employment types and statuses.
                                </p>

                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox6");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox6");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>
                        <div className="target-group-data-repeat tg-earning-status">
                            <div className="targer-group-left-wrap border-0">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="check-switch">
                                            <input
                                                type="checkbox"
                                                checked={formValues.earnings_match}
                                                onChange={(event) =>
                                                    toggleCheck(
                                                        "earnings_match",
                                                        event.currentTarget.checked ? 1 : 0
                                                    )
                                                }
                                            />
                                            <span className="check-slider"></span>
                                            <p className="big-label-text">Household</p>
                                        </label>
                                    </div>
                                    {formValues.earnings_match == 1 && (
                                        <>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label">
                                                                <label>Number of members in household</label>
                                                                <div className="checkboxes radio_btn_class gender_btn  d-flex">
                                                                    <label htmlFor="All" className="mt-0">
                                                                        <input
                                                                            id="All"
                                                                            disabled={
                                                                                formValues.earnings_match == 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            type="radio"
                                                                            name="income"
                                                                            checked={
                                                                                formValues.household_members_condition ==
                                                                                    "All"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value="All"
                                                                            className="hidden radio_btn_input"
                                                                            onChange={(e) =>
                                                                                setFormValue(
                                                                                    "household_members_condition",
                                                                                    "All"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span>
                                                                            <img
                                                                                className="empty-fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/Polygon-blank.svg"
                                                                                }
                                                                            />
                                                                            <img
                                                                                className="fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/pol-fill.svg"
                                                                                }
                                                                            />
                                                                        </span>
                                                                        <p>All</p>
                                                                    </label>
                                                                    <label htmlFor="minimum" className="mt-0">
                                                                        <input
                                                                            id="minimum"
                                                                            disabled={
                                                                                formValues.earnings_match == 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            type="radio"
                                                                            name="income"
                                                                            checked={
                                                                                formValues.household_members_condition ==
                                                                                    "minimum"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value="minimum"
                                                                            className="hidden radio_btn_input"
                                                                            onChange={(e) =>
                                                                                setFormValue(
                                                                                    "household_members_condition",
                                                                                    "minimum"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span>
                                                                            <img
                                                                                className="empty-fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/Polygon-blank.svg"
                                                                                }
                                                                            />
                                                                            <img
                                                                                className="fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/pol-fill.svg"
                                                                                }
                                                                            />
                                                                        </span>
                                                                        <p>Minimum</p>
                                                                    </label>
                                                                    <label htmlFor="upto" className="mt-0">
                                                                        <input
                                                                            id="upto"
                                                                            disabled={
                                                                                formValues.earnings_match == 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            type="radio"
                                                                            name="income"
                                                                            checked={
                                                                                formValues.household_members_condition ==
                                                                                    "upto"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value="upto"
                                                                            className="hidden radio_btn_input"
                                                                            onChange={(e) =>
                                                                                setFormValue(
                                                                                    "household_members_condition",
                                                                                    "upto"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span>
                                                                            <img
                                                                                className="empty-fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/Polygon-blank.svg"
                                                                                }
                                                                            />
                                                                            <img
                                                                                className="fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/pol-fill.svg"
                                                                                }
                                                                            />
                                                                        </span>
                                                                        <p>Up to</p>
                                                                    </label>
                                                                </div>
                                                                <div className="qty-row-wrap">
                                                                    <button
                                                                        type="button"
                                                                        className="sub"
                                                                        onClick={minusPeople}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/less-icon.svg"
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </button>
                                                                    <input
                                                                        type="number"
                                                                        disabled={
                                                                            formValues.earnings_match == 1
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        value={formValues.people}
                                                                        onChange={changePeople}
                                                                        min="0"
                                                                        max="10"
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="add"
                                                                        onClick={addPeople}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/plus-icon.svg"
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="target-form-wrap">
                                                    <div className="target-group-col-data">
                                                        <div className="input-field static-label mb-0">
                                                            <label className={`${incomeLabelClass}`}>
                                                                Household income $ per month
                                                            </label>
                                                            <Select
                                                                isClearable
                                                                name="income_slab"
                                                                title="Household income $ per month"
                                                                placeholder="Household income $ per month"
                                                                options={options.income_levels}
                                                                onChange={(items) => {
                                                                    items.length > 0
                                                                            ? setIncomeLabelClass("active_label")
                                                                            : setIncomeLabelClass("inactive_label");
                                                                    setFormValue("income_slab", items);
                                                                    /*if (item == null) {
                                                                        setIncomeLabelClass("inactive_label");
                                                                        
                                                                        setFormValue("income_slab", null);
                                                                    } else {
                                                                        setIncomeLabelClass("active_label");
                                                                        setFormValue("income_slab", item.value);
                                                                    }*/
                                                                   
                                                                }}
                                                                isMulti={true}
                                                                value={formValues.income_slab}
                                                                /*value={
                                                                    formValues.income_slab
                                                                    ? {
                                                                        label: formValues.income_slab,
                                                                        value: formValues.income_slab,
                                                                        }
                                                                    : null
                                                                }*/
                                                                components={{DropdownIndicator}}
                                                            />
                                                        </div>
                                                        {/*
                                                        <div className="input-field static-label mb-0">
                                                            <label>Household income $ per </label>

                                                            <div className="checkboxes radio_btn_class gender_btn  d-flex">
                                                                <label htmlFor="Yearly" className="mt-0">
                                                                    <input
                                                                        id="Yearly"
                                                                        disabled={
                                                                            formValues.earnings_match == 1
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        type="radio"
                                                                        name="term"
                                                                        checked={
                                                                            formValues.term == "Yearly"
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value="Yearly"
                                                                        className="hidden radio_btn_input"
                                                                        onChange={(e) =>
                                                                            setFormValue("term", "Yearly")
                                                                        }
                                                                    />
                                                                    <span>
                                                                        <img
                                                                            className="empty-fill-icon"
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/Polygon-blank.svg"
                                                                            }
                                                                        />
                                                                        <img
                                                                            className="fill-icon"
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/pol-fill.svg"
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <p>Year</p>
                                                                </label>
                                                                <label htmlFor="Monthly" className="mt-0">
                                                                    <input
                                                                        id="Monthly"
                                                                        disabled={
                                                                            formValues.earnings_match == 1
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        type="radio"
                                                                        name="term"
                                                                        checked={
                                                                            formValues.term == "Monthly"
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value="Monthly"
                                                                        className="hidden radio_btn_input"
                                                                        onChange={(e) =>
                                                                            setFormValue("term", "Monthly")
                                                                        }
                                                                    />
                                                                    <span>
                                                                        <img
                                                                            className="empty-fill-icon"
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/Polygon-blank.svg"
                                                                            }
                                                                        />
                                                                        <img
                                                                            className="fill-icon"
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/pol-fill.svg"
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <p>Month</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <MultiRangeSlider
                                                            disabled={
                                                                formValues.earings_match == 1 ? true : false
                                                            }
                                                            min={options.min_income}
                                                            max={options.max_income}
                                                            step={1}
                                                            ruler={false}
                                                            label={false}
                                                            preventWheel={false}
                                                            minValue={formValues.min_income}
                                                            maxValue={formValues.max_income}
                                                            onInput={(e) => {
                                                                setFormValue("min_income", e.minValue);
                                                                setFormValue("max_income", e.maxValue);
                                                            }}
                                                        />
                                                        <div className="gender-range-data">
                                                            <span>
                                                                <b> {nFormatter(formValues.min_income)}</b>
                                                            </span>
                                                            <span>
                                                                <b>{nFormatter(formValues.max_income)}</b>
                                                            </span>
                                                        </div>
                                                        */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="target-group-btns-top mt-32 justify-content-end add-target-grp-btn">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setModal({
                                                modalHeading: "Email Sent",
                                                modalMessage:
                                                    "We just sent an email! Click on the link in the email to generate a new password.",
                                                modalOpen: true,
                                            });
                                        }}
                                        className="btn form-btn secondary-btn"
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn form-btn">
                                        Save
                                        {formLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                aria-hidden="true"
                                                style={{ marginLeft: "5px" }}
                                            ></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                            
                            <div id="repaetinfobox7" className="target-group-info-repeat repaetinfobox7">
                                <h3>Household</h3>
                                <p>
                                    Choose from participants with specific household numbers and income
                                    levels.
                                </p>
                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox7");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox7");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>

                        <div className="target-group-data-repeat">
                            <div className="targer-group-left-wrap  border-0">
                                {/*<div className="delete-res-profile">
                                    <span className="delete-account-btn">Delete Account <img src={process.env.REACT_APP_URL+"images/trash.svg"} alt="trash-icon" /></span>
                                </div>*/}
                            </div>
                            <div className="target-group-info-repeat pt-0">
                                <h3>Do you have additional requirements?</h3>
                                <p>
                                    Tell us your project audience and demographic requirements
                                    and we’ll source the right participants for you.
                                </p>
                                <Link to={'/researcher/support/contact?reason=Help with recruitment'} className="create-btn">
                                    SEND REQUEST{" "}
                                    <span>
                                        <img
                                            src={
                                                process.env.REACT_APP_URL + "images/arrow-right.png"
                                            }
                                            alt="img"
                                        />
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div
                            className="target-group-right-side"
                            style={{ display: "none" }}
                        >
                            <div className="target-group-info-repeat">
                                <h3>Demographics</h3>
                                <p>
                                    Choose the age range and gender split of your target
                                    audience.
                                </p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Nationalities</h3>
                                <p>
                                    We have a diverse panel of participants. Chosoe the nationalities
                                    you would like to target.{" "}
                                </p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Residency</h3>
                                <p>
                                Choose participants from certain countries and regions.
                                </p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Education</h3>
                                <p>
                                    Select the minimum education level you require for your
                                    participants.
                                </p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Technology</h3>
                                <p>Choose participants with different technical competencies.</p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Employment</h3>
                                <p>
                                    Choose participants of different employment types and statuses.
                                </p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Household</h3>
                                <p>
                                    Choose from participants with specific household numbers and income
                                    levels.
                                </p>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Do you have additional requirements?</h3>
                                <p>
                                    Tell us your project audience and demographic requirements
                                    and we’ll source the right participants for you.
                                </p>
                                <button className="create-btn">
                                    SEND REQUEST{" "}
                                    <span>
                                        <img
                                            src={
                                                process.env.REACT_APP_URL + "images/arrow-right.png"
                                            }
                                            alt="img"
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
            <Modal
                show={modal.modalOpen}
                centered
                className="fade custom-modal-wrap leave-page-modal-wrap"
            >
                <Modal.Body className="p-0">
                    <div className="modal-inner-text">
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                setModal({
                                    modalHeading: null,
                                    modalMessage: null,
                                    modalOpen: false,
                                });
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <img
                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                    alt="cross"
                                />
                            </span>
                        </button>
                        <div className="create-targetgroup-data-wrap">
                            <h2 className="h2">Leave page?</h2>
                            <p>
                                You haven’t saved the changes on your target group. Do you
                                want to leave this page without saving?
                            </p>
                        </div>
                        <div className="create-project-form-btn d-block">
                            <button
                                type="button"
                                className="btn form-btn w-100"
                                onClick={saveTargetGroup}
                            >
                                SAVE &amp; EXIT
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                                className="btn form-btn secondary-btn b-block w-100"
                            >
                                EXIT WITHOUT SAVING
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <CtaActionModal close_action={cta_modal.close_action} open={cta_modal.open} title={cta_modal.title} message={cta_modal.message} />
        </LayoutResearcherFullWidth>
    );
}