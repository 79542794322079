import React, { useEffect } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";

export default function EstimatedPanel({ panel_color, panel_size }) {

    function getApproxVal(number)
    {
        var roundTo = 10;
        // Smaller multiple
        
        if(number <100){
            roundTo = 10;
        } else {
            roundTo = 100;
        }
        var new_number  = Math.floor(number / roundTo) * roundTo;

        return number>=1000 ? "~"+(new_number >=  1000 ? (Math.round((new_number/1000)*100)/100)+"K" : number):number;
    } 
    return (
        <>
            {panel_color == "danger" &&
                <>
                    <div className="insufficient-responses-wrap d-flex align-items-center">
                        
                        <Icon value={'error'}  colorClass={'danger-200-svg'}/>
                        <Text type={'body-text-3'} fontWeight={'medium-font'} cssClasses={'red-text'}>Insufficient responses</Text>
                    </div>
                    <div className="estimate-error-wrap">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9V12.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 15.75H12.008V15.758H12V15.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Uh oh! Try again by reducing the number of participants or changing the selected criteria. For additional support, <a href="mailto:support@userq.com">get in touch with us.</a></span>
                    </div>
                </>
            }
            {panel_color != "danger" &&
                <div className={`estimate-progress-wrap estimated-panel-color-` + panel_color}>
                    <h1 className="heading h1">
                        {getApproxVal(panel_size)}
                    </h1>

                    <div className={`estimate-progress-bar estimated-panel-progress-` + panel_color}>
                        <div className="progress-width"></div>
                    </div>

                    <div className="estimate-responses-wrap">
                        <span>Slow responses</span>
                        <span>Fast responses</span>
                    </div>

                    <div className="responsesinfo-forsmallscreen">
                        <Text type={"body-text-3"} fontWeight={'medium-font'} cssClasses="gray-color">Responses</Text>
                    </div>
                </div>
            }
        </>
    );
}
