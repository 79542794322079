import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from "react";
import {LayoutResearcher} from "../../themes/userq/Layouts/layout_researcher";
import BillingNavigation from './billing-navigation';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import { showToast } from '../../../lib/helpers';
import moment from 'moment';
import { getInvoicesService } from '../../../services/invoices.js';
import {LoadingIcon} from "../../themes/userq/Loader/loadingIcon";
import {Text} from "../../themes/userq/Text/Text";
import {Button} from "../../themes/userq/Button/Button";
import { Icon } from "../../themes/userq/Icon/Icon.js";
import { Helmet } from 'react-helmet';

export default function Invoice() {

    const scrollContainerRef = useRef(null);

    const [isScrolled, setIsScrolled] = useState(false);

    const navigate = useNavigate();

    const user = ReactSession.get("user");

    const [isLoading, setLoading] = useState(false);

    const [invoiceLoading, setInvoiceLoading] = useState(false);

    const [invoicePage, setInvoicePage] = useState(1);

    const [order, setOrder] = useState('');

    const [hasInvoices, setHasInvoices] = useState(true);


    const [firstLoading, setFirstLoading] = useState(true);

    const [invoices, setInvoices] = useState([]);


    const [show_support_panel, setShowSupportPanel] = useState(false);

    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;
    
        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;
    
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
    
          // Remove the event listener when the component unmounts
          return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
          };
        }
      }, [invoices]);

    const fetchInvoices = () => {
        let token = ReactSession.get("token");

        if(firstLoading){
            setLoading(true);
        }else {
            setInvoiceLoading(true);
        }

        getInvoicesService(invoicePage,order?order:'desc', token).then(response => {


            if(firstLoading){
                setLoading(false);
            }else {
                setInvoiceLoading(false);
            }

            setFirstLoading(false);

            if (response.success) {

                setInvoices(invoices.concat(response.invoices));
                if (response.more_records) {
                    setHasInvoices(true);
                    if(!order) {
                       // setOrder("desc");
                    }
                } else {
                    setHasInvoices(false);
                }
                setInvoicePage(invoicePage + 1);

            } else {

                showToast(response.message,"error");
            }
        });
    }

    useEffect(() => {

        //document.title = process.env.REACT_APP_NAME + " - Invoice history";

        fetchInvoices();

    }, []);

    useEffect(()=>{

        if(order){
            let token = ReactSession.get("token");

           // setInvoiceLoading(true);

            getInvoicesService(1,order, token).then(response => {

             //   setInvoiceLoading(false);

                if (response.success) {

                    setInvoices(response.invoices);
                    if (response.more_records) {
                        setHasInvoices(true);
                    } else {
                        setHasInvoices(false);
                    }
                    setInvoicePage( 2);

                } else {

                    showToast(response.message,"error");
                }
            });
        }
    },[order])


    const renderInvoices = () => {
        var invoiceRender = [];
        invoices.forEach(function (invoice) {

            var coupon = '--';
            if(invoice.type === 'credits' && invoice.purchase){
                coupon =   invoice.purchase.promo_code?invoice.purchase.promo_code:(invoice.purchase && (invoice.purchase.credits > invoice.purchase.amount)  ? "Bulk purchase" : "--");
            }else if(invoice.type === 'test' && invoice.purchase){
                coupon = invoice.purchase.coupon_code?invoice.purchase.coupon_code:"--"
            }

            var discount  = 0;
            if(invoice.type === 'credits' && invoice.purchase){
                discount = ((invoice.purchase.promo_code && invoice.purchase)?(invoice.purchase.credits-invoice.purchase.amount): ((invoice.purchase && (invoice.purchase.credits > invoice.purchase.amount)  ? (invoice.purchase.credits - invoice.purchase.amount  ) : 0)));
            }else if(invoice.type === 'test' && invoice.purchase){
                discount = (invoice.purchase.discount);
            }

            var subTotal  = 0;
            if(invoice.type === 'credits' && invoice.purchase){
                subTotal = ((invoice.purchase)?(invoice.purchase.credits):0);
            }else if(invoice.type === 'test' && invoice.purchase){
                subTotal = (invoice.purchase.total_fees-invoice.purchase.credits_used);
            }

            var total  = 0;
            if(invoice.type === 'credits' && invoice.purchase){
                total = ((invoice.purchase)?(invoice.purchase.amount):0);
            }else if(invoice.type === 'test' && invoice.purchase){
                total = (invoice.purchase.amount);
            }else{
                total = invoice.invoice_total/100;
            }

            invoiceRender.push(
                <tr key={"invoice" + invoice.id}>
                    <td>{moment(invoice.date_of_invoice).format("DD-MM-YY")}</td>
                    <td>{invoice.description}</td>
                    <td>
                        {coupon}
                    </td>
                    <td>
                        ${(discount).toFixed(2)}
                    </td>
                    <td>
                        ${(subTotal).toFixed(2)}
                    </td>
                    <td>
                        ${(total).toFixed(2)}
                    </td>
                    <td>
                        {invoice.status === 'paid' || invoice.status === 'Paid'?
                            <span className="paid-status">{invoice.status}</span>
                            :
                            <span className="failed-status">{invoice.status}</span>
                        }

                    </td>
                    <td>
                        {invoice.notes?invoice.notes:'--'}
                    </td>
                    <td>
                        {invoice.invoice_url?
                            <a href={invoice.invoice_url} target="_blank"><Icon size={'small'} value={'billing'}/></a>
                            :
                            ''
                        }

                    </td>
                </tr>
            );
        })
        return invoiceRender;
    }

    return (
        <LayoutResearcher
            isLoading={false}
            wrapclassName="rs-inner-profile-wrapper"
            skipCheck={false}
            extendedFooter={false}
            activeMenu={"invoice"}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <Helmet>
                <title>Invoice history | UserQ</title>
            </Helmet>
            <div className="invoice-plan-page-wrapper">

                <div className="page-heading-wrap accounts-page--heading invoice-info-section">
                    <Text type={"h1"}>Billing</Text>
                </div>

                <BillingNavigation activeMenu="invoice" />


                {isLoading && (
                    <div className={'page-loader accountloading-box'}>
                        <LoadingIcon/>
                    </div>
                )}
                {!isLoading && (
                    <>
                        <div className="profile-form-repeat pb-48">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-wrapper">
                                        {invoices.length > 0 && (
                                            <div ref={scrollContainerRef}  className={`max-height-table  ${isScrolled ? 'scrolled' : ''}  ${invoices && invoices.length>8 ? "responsive-table-scroll":""}`}>
                                                <table className="table invoicetable">
                                                    <thead>
                                                    <tr >
                                                        <th className={`td-sort-wrap cursor-pointer`} >

                                                            <div onClick={() => {
                                                                if(order === 'asc'){
                                                                    setOrder('desc');
                                                                }else{
                                                                    setOrder('asc');
                                                                }
                                                            }} className={`${(order) ? order : ''}   col-sort justify-content-between`}>

                                                                Date
                                                                <span>

                                                                    {order=="asc" &&
                                                                    <Icon value={'sortup'}/>
                                                                    }
                                                                    {order=="desc" &&
                                                                    <Icon value={'sortdown'}/>
                                                                    }
                                                                    {!order &&
                                                                    <Icon value={'sort-icon'}/>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th>Description</th>
                                                        <th>Coupon</th>
                                                        <th>Discounted amount</th>
                                                        <th>Subtotal</th>
                                                        <th>Total</th>
                                                        <th>Status</th>
                                                        <th>Notes</th>
                                                        <th>Invoice</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>{renderInvoices()}</tbody>
                                                </table>
                                            </div>
                                        )}
                                        {!hasInvoices && invoices.length == 0 && (
                                            <div className="nodata-wrap">
                                                No bills available at this moment.
                                            </div>
                                        )}
                                        {hasInvoices && !invoiceLoading && (
                                            <div className={"load-more-data"}>
                                                <Button type="primary"
                                                        size={`large`}
                                                        label="Load more"
                                                        onClick={fetchInvoices}
                                                />
                                            </div>
                                        )}
                                        {hasInvoices && invoiceLoading && (
                                            <div className={"load-more-data"}>
                                                <Button type="primary"
                                                        size={`large`}
                                                        microLoading={true}

                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )}
            </div>
            <a onClick={(e)=>{
                e.preventDefault();

                setShowSupportPanel(true);

            }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
                Support
            </a>
        </LayoutResearcher>
    );
}