import {handleApiErrorResponse} from "../lib/helpers";
import toast from "react-hot-toast";
import { ToastMessage } from "../components/themes/userq/ToastMessage/ToastMessage";
import { settings } from "../data/settings";


export function createTargetGroup(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/create', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
var toast_target_group;
export function updateTargetGroupService(formValues, token, signal){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/update', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token},
        signal:signal
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }
        return data;

    }).catch((res)=>{
        if(res instanceof TypeError){
            if(toast_target_group){
                toast.dismiss(toast_target_group);
            }
            toast_target_group = toast(
                <ToastMessage type={"warning"} message={"We're calculating the panel size based on your requirements. Just a few moments..."} closable={true} onClose={() => { toast.dismiss(); }} />,
                  {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: settings.toast_duration,
                  }
              );
        } else {
            handleApiErrorResponse(res);
        }
    });
}
export function deleteTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/delete', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function renameTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT + "target_groups/rename", {
      method: "POST",
      body: JSON.stringify(formValues),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTargetGroupOptions(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/options', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getAgeDistribution(formValues, token, signal){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/age-distribution?step='+(formValues && formValues.step?formValues.step:'3')+'&gender='+(formValues && formValues.gender?formValues.gender:'')+'&language='+(formValues && formValues.language?formValues.language:'en'), {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token},
        signal: signal
    }  ).then((res)=>{
console.log(res)
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
       handleApiErrorResponse(res);
    });
}
export function getTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/fetch?target_group_id='+formValues.target_group_id, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTestTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/test/fetch?test_id='+formValues.test_id, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}