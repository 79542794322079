import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./downgrade-workspace-modal.css";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
import moment from "moment/moment";

export default function ReactivateWorkspaceModal({ btnLoading, confirmUpgradeAction, workspace,...props}) {
    const wrapperRef = useRef(null);
    

    const closeModal = () => {
        if (props.close) {
            props.close();
        }
    };

    const confirmUpgrade = () => {
        confirmUpgradeAction();

    }
    const getDaysDiff = () => {
        var diff = workspace.nextPaymentDate ? moment(workspace.nextPaymentDate,"DD/MM/YYYY").diff(moment(),"days") : 0;

        if(diff==1){
            return diff+" day";
        }
        return diff+" days";
    }
    return (
        <Modal
            show={props.open}
            onHide={props.close}
            centered
            size="lg"
            className="fade downgrad-workspace-modal"
        >
            <Modal.Body ref={wrapperRef}>
                <div className="downgrad-workspace-modal-data">
                    <div className="modal-header-top">
                        <Text type={"h3"}>Re-activate Team plan</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="downgrad-modal-inner-data">
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"gray-color"}
                        >
                            It appears you still have {getDaysDiff()} remaining in the current Team Plan billing cycle. If you wish to upgrade the workspace again, your next billing date will be {workspace && workspace.nextPaymentDate?workspace.nextPaymentDate:"-"}. 
                            
                        </Text>
                        <div className="downgrade-plan-reason mt-20">
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-color"}
                            >
                                Simply confirm the upgrade, and you're all set.
                            </Text>
                        </div>
                        <div className="confirm-buttons-wrap modal-button-wrap">
                            <Button
                                type="secondary"
                                size={`large`}
                                label={"Confirm upgrade"}
                                onClick={(e)=>{ confirmUpgrade()}}
                                microLoading={btnLoading}
                                cssclass={"confirm-upgrade"}
                            />

                            <Button
                                type="primary"
                                size={`large`}
                                label={"Cancel"}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
