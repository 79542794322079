import PropTypes from 'prop-types';
import React from "react";
import { Link } from 'react-router-dom';
import { Tooltip } from '../Tooltip/Tooltip';
export const Button = ({type, iconOnly, target, tag, href, id, size, state, label, iconLeft, iconRight,microLoading,tooltip,cssclass, ... props})=>{
    
    if(!tag){

    
    
        return (
            <button id={id} onClick={props.onClick}  className={` ${(type=="ghost" || type=="secondary") ?type+"-btn":"btn"} ${type}${size?"-"+size:""} ${state?"btn-"+state:""} ${(iconLeft || iconRight)?"btn-with-icon":""} ${iconLeft?"btn-with-icon-left":""} ${iconRight?"btn-with-icon-right":""} ${cssclass?cssclass:''} ${microLoading?"no-hover-state":""}` }>

                {microLoading ?
                    <div className="micro-loading">
                        <div className="three-balls">
                            <div className="ball ball1"></div>
                            <div className="ball ball2"></div>
                            <div className="ball ball3"></div>
                        </div>
                    </div>
                    :
                    <>
                    {iconLeft ? iconLeft : <></>} {!iconOnly?label:<></>} {iconRight ? iconRight : <></>}
                    </>
                }
                {iconOnly &&
                <Tooltip
                    data={label}
                    type="top"
                />
                
                }
                {tooltip &&
                <Tooltip
                    data={tooltip}
                    type="bottom"
                    style={{display:"block"}}
                />
                }
            </button>
        )
    } else if(tag=="link"){

        return (
            <Link to={href} className={`${(type=="ghost" || type=="secondary") ?type+"-btn":"btn"} ${type}${size?"-"+size:""} ${state?"btn-"+state:""} ${(iconLeft || iconRight)?"btn-with-icon":""} ${iconLeft?"btn-with-icon-left":""} ${iconRight?"btn-with-icon-right":""}`}>

                {microLoading ?
                    <div className="micro-loading">
                        <div className="three-balls">
                            <div className="ball ball1"></div>
                            <div className="ball ball2"></div>
                            <div className="ball ball3"></div>
                        </div>
                    </div>
                    :
                    <>
                    {iconLeft ? iconLeft : <></>} {label} {iconRight ? iconRight : <></>}
                    </>
                }

            </Link>
        )
    } else {
        
        return (
            <a id={id} href={href} target={target} onClick={props.onClick}  className={`${(type=="ghost" || type=="secondary") ?type+"-btn":"btn"} ${type}${size?"-"+size:""} ${state?"btn-"+state:""} ${(iconLeft || iconRight)?"btn-with-icon":""} ${iconLeft?"btn-with-icon-left":""} ${iconRight?"btn-with-icon-right":""}`}>

                {microLoading ?
                    <div className="micro-loading">
                        <div className="three-balls">
                            <div className="ball ball1"></div>
                            <div className="ball ball2"></div>
                            <div className="ball ball3"></div>
                        </div>
                    </div>
                    :
                    <>
                    {iconLeft ? iconLeft : <></>} {label} {iconRight ? iconRight : <></>}
                    </>
                }

            </a>
        )
    }
    
    
}
Button.propTypes = {
    /**
     * Can be primary, secondary or ghost
     */
    type: PropTypes.oneOf(["primary","secondary","ghost"]),
    /**
     * Can be small, medium or larget
     */
    size: PropTypes.oneOf(['small','medium','large']),
    /**
     * Can be active, disabled
     */
    state: PropTypes.oneOf(['','active','disabled']),
    microLoading: PropTypes.oneOf([true,false]),
    label:PropTypes.any.isRequired
}
Button.defaultProps = {
    type:"primary",
    size:"small",
    state:undefined,
    microLoading:undefined,
}