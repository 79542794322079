export default function CheckboxNormal({style, checked, value, bigLabel,  defaultValue, id,onChange,label,darkCheckbox,isSelectedlabelBold}){
    
    return (
        <div className={`form-group custom-control custom-checkbox mb-0 ${darkCheckbox}`} style={style}>
            <input
                type="checkbox" 
                id={id}
                checked={checked}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                className="custom-control-input"
            />
            <label htmlFor={id} className={` ${(isSelectedlabelBold && checked) ? 'bold-radio-label':''} custom-control-label` }>
                {label}
            </label>
        </div>
    )
}

