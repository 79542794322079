//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { maxLengthCheck, isFileImage, DropdownIndicator } from "../../../lib/helpers";
import { createProject } from "../../../services/project";
import { getCollaborators } from "../../../services/collaborators";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCurrentPlansService } from './../../../services/plans';
import { getProject, updateProject } from './../../../services/project';
import toast from "react-hot-toast";

export function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    // if(file.type.match('video.*'))
    //   return 'video';

    // if(file.type.match('audio.*'))
    //   return 'audio';



    return 'other';
}
export function matchExtensions(extns, file) {

    return extns.indexOf(file.name.split('.').pop()) != -1 ? true : false;
}

export default function ProjectSettingsModal(props) {
    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(true);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({ project_name: "" });

    const [formErrors, setFormErrors] = useState({
        image: null,
        project_name: null,
        email: null,
        error_class: null,
    });

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [image, setImage] = useState(null);

    const [previewUrl, setPreviewUrl] = useState("");

    const [collaborators, setCollaborators] = useState([]);

    const [collaboratorsApi, setCollaboratorsApi] = useState([]);

    const [collaboratorOptions, setCollaboratorOptions] = useState([]);

    const user = ReactSession.get("user");

    let emailSelect = useRef();

    const [emailLabelClass, setEmailLabelClass] = useState("inactive_label");

    const [email, setEmail] = useState(null);

    const [collaboratorRow, setCollaboratorRow] = useState({
        id: null,
        user_id: "",
        email: "",
        role: "Admin",
    });


    const getCollabortorsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCollaborators(token).then((response) => {
            setLoading(false);

            if (response.success) {

                setCollaboratorsApi(response.collaborators);

                var collab_options = [];

                response.collaborators.forEach(function (item_a) {
                    var exist = false;

                    collaborators.forEach(function (item_b) {
                        if (item_a.id == item_b.user_id) {
                            exist = true;
                        }
                    });
                    if (!exist && item_a.role && item_a.last_active) {
                        collab_options.push({ value: item_a.id, label: item_a.email });
                    }
                });

                setCollaboratorOptions(collab_options);

                window.setLabels();
            } else {
                setErrorMessage(response.setErrorMessage);
            }
        });
    };

    const getProjectApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProject({ project_id: props.project_id }, token).then((response) => {
            if (response.success) {
                window.setLabels();
                setFormValues(response.project);

                const collaboratorsFinal = [...collaborators];

                response.project.collaborators.forEach(function (item) {
                    /*collaboratorsFinal.push({
                      id: item.id,
                      user_id: item.user_id,
                      email: item.collaborator.email,
                      last_active: item.collaborator.last_active,
                      role: item.role,
                    });*/
                    collaboratorsFinal.push({
                        value: item.user_id,
                        label: item.collaborator.email
                    });
                });
                setCollaborators(collaboratorsFinal);
                setLoading(false);
                getCollabortorsApi();
            } else {
                setLoading(false);
                setErrorMessage(response.setErrorMessage);
            }
        });
    };

    useEffect(() => {
        getProjectApi();

    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        resetFormErrors();


        if (!formLoading) {
            var error = false;

            if (formValues.project_name == "") {
                showFormError("project_name", "Required field");
                error = true;
            }

            if (!error) {
                setFormLoading(true);

                const token = ReactSession.get("token");

                var data = new FormData();
                if (image) {
                    data.append("image", image);
                }
                data.append("project_name", formValues.project_name);

                data.append("collaborators", JSON.stringify(collaborators));

                data.append("project_id", props.project_id);

                updateProject(data, token).then((response) => {
                    setFormLoading(false);

                    if (response.success) {
                        props.closeModal(true);
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                    }
                });
            }
        }
        return false;
    };

    const closeModal = () => {
        setFormValues({ project_name: "" });

        setImage(null);

        resetFormErrors();

        setCollaborators([]);

        setEmail(null);

        setEmailLabelClass("inactive_label");

        removeImage();

        props.closeModal(false);
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);

    };
    const resetFormValues = () => {
        let formValuesLocal = Object.assign({}, formValues);

        for (var key in formErrors) {
            formValuesLocal[key] = "";
        }
        setFormValues(formValuesLocal);
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };
    //   const handleFile = (file) => {
    //     //you can carry out any file validations here...
    //     if (!isFileImage(file)) {
    //       showFormError("image", "Please select a valid image");
    //       return;
    //     }
    //     setImage(file);
    //     setPreviewUrl(URL.createObjectURL(file));
    //   };

    const handleFile = (file) => {

        resetFormErrors();
        //let's grab the image file
        var error = false;

        var fileType = getFileType(file);

        if (fileType == "image" && file.size / 1024 > 2048) {

            setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });

            error = true;
        }
        if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {


            setFormErrors({ ...formErrors, image: "Only image files are accepted with extensions  jpg, jpeg, gif and png.", "error_class": "input_error" });
            error = true;
        }

        if (fileType != "image") {

            setFormErrors({ ...formErrors, image: "Only image files are accepted.", "error_class": "input_error" });
            error = true;
        }

        if (!error) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }

    };

    const handleOnDragOver = (event) => {
        event.preventDefault();
    };
    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    };
    const removeImage = (e) => {
        setImage(null);
        setPreviewUrl(null);
        setFormValues({ ...formValues, image: null });
    };
    const handleFileInput = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.target.files[0];

        handleFile(imageFile);
    };

    const emailChange = (item) => {
        setEmail(item);
        setCollaboratorRow((oldValues) => ({
            ...oldValues,
            ["user_id"]: item.value,
            ["email"]: item.label,
        }));
        setEmailLabelClass("active_label");
    };
    const setCollabortorRole = (role) => {
        return () => {
            setCollaboratorRow((oldValues) => ({ ...oldValues, ["role"]: role }));
        };
    };
    const updateCollaboratorOptions = () => {
        var collab_options = [];

        collaboratorsApi.forEach(function (item_a) {
            var exist = false;

            collaborators.forEach(function (item_b) {
                if (item_a.id == item_b.user_id) {
                    exist = true;
                }
            });
            if (!exist) {
                collab_options.push({ value: item_a.id, label: item_a.email });
            }
        });
        setCollaboratorOptions(collab_options);
    };

    const addCollaborator = () => {
        return (e) => {
            showFormError("email", null);

            var error = false;

            if (collaboratorRow.email == "") {
                showFormError("email", "Please select email");

                error = true;
            } else {
                var exist = false;

                collaborators.forEach(function (item) {
                    if (item.user_id == collaboratorRow.user_id) {
                        exist = true;
                    }
                });
                if (exist) {
                    showFormError(
                        "email",
                        "Collaborator is already added for this email."
                    );

                    error = true;
                }
            }

            if (!error) {
                setEmailLabelClass("inactive_label");

                setEmail(null);

                const collaboratorsFinal = [...collaborators];

                collaboratorsFinal.push(collaboratorRow);

                setCollaborators(collaboratorsFinal);

                setCollaboratorRow({ user_id: "", email: "", role: "Admin" });
            }
        };
    };
    const updateCollabortorRole = (role, index) => {
        return () => {
            const collaboratorsFinal = [...collaborators];

            collaboratorsFinal[index]["role"] = role;

            setCollaborators(collaboratorsFinal);
        };
    };
    const removeCollaborator = (index) => {
        return () => {
            const collaboratorsFinal = [...collaborators];

            collaboratorsFinal.splice(index, 1);

            setCollaborators(collaboratorsFinal);
        };
    };
    const renderCollaborators = () => {
        var render = [];

        collaborators.forEach(function (item, index) {
            render.push(
                <div key={"collab" + item.id} className="repeat-added-option">
                    <div className="added-option-data">
                        <p className={"add-member-email-td"}>{item.email}</p>
                        <span className="project-permission-drop-down">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id={"dropdownMenuButton" + item.user_id}
                                    className="dropdown-toggle"
                                >
                                    {item.role}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        href="#"
                                        onClick={updateCollabortorRole("Admin", index)}
                                    >
                                        Admin
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        href="#"
                                        onClick={updateCollabortorRole("Editor", index)}
                                    >
                                        {" "}
                                        Editor
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>
                    <span
                        className="delete-colaborator-btn"
                        onClick={removeCollaborator(index)}
                    >
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="img"
                        />
                    </span>
                </div>
            );
        });

        return render;
    };
    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap  create-new-project-modal"
        >
            <Modal.Body className="modal-lg">
                <div className="modal-inner-text">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal();
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img
                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                alt="cross"
                            />
                        </span>
                    </button>
                    <form onSubmit={onSubmit}>
                        <div className="create-project-data-wrap">
                            <div className="project-modal-img">
                                {!previewUrl && (
                                    <div>
                                        {formValues.image && (
                                            <>
                                                <div className="uploaded-project-img">
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_IMG_URL + formValues.image
                                                        }
                                                        alt="img"
                                                    />
                                                </div>
                                                <img
                                                    className="remove-img"
                                                    onClick={removeImage}
                                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                    alt="img"
                                                />
                                            </>
                                        )}
                                        {!formValues.image && (
                                            <>
                                                <div
                                                    className="upload-project-img"
                                                    onDragOver={handleOnDragOver}
                                                    onDrop={handleOnDrop}
                                                >
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/project-img.png"
                                                        }
                                                        alt="img"
                                                    />
                                                    <p>
                                                        Drag and drop an image, or &nbsp;
                                                        <b>
                                                            Browse
                                                            <input type="file" onChange={handleFileInput} />
                                                        </b>
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                {previewUrl && (
                                    <>
                                        <div className="uploaded-project-img">
                                            <img src={previewUrl} alt="img" />
                                        </div>
                                        <img
                                            className="remove-img"
                                            onClick={removeImage}
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="img"
                                        />
                                    </>
                                )}
                                <div
                                    className="uploaded-project-img"
                                    style={{ display: "none" }}
                                >
                                    <img
                                        src={process.env.REACT_APP_URL + "images/project-img.png"}
                                        alt="img"
                                    />
                                </div>
                                {formErrors.image != null && (
                                    <span className={formErrors.error_class}>
                                        {formErrors.image}
                                    </span>
                                )}
                            </div>
                            <div className="create-project-modal-data">
                                <h2 className="h2">
                                    Project Settings
                                    {isLoading && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            aria-hidden="true"
                                            style={{ marginLeft: "5px" }}
                                        ></i>
                                    )}
                                </h2>

                                <div className="create-projectmodal-form-group">
                                    <div className="input form-group input-field">
                                        <input
                                            type="text"
                                            className={
                                                formErrors.project_name != null
                                                    ? "form-control " + formErrors.error_class
                                                    : "form-control"
                                            }
                                            id="project_name"
                                            maxLength="20"
                                            onInput={maxLengthCheck}
                                            onChange={set("project_name")}
                                            defaultValue={formValues.project_name}
                                        />
                                        <label className="control-label" htmlFor="project_name">
                                            Project name
                                        </label>
                                        <span
                                            className="clear"
                                            onClick={clearFormValue("project_name")}
                                        >
                                            <svg viewBox="0 0 24 24">
                                                <path
                                                    className="line"
                                                    d="M 2,2 C 3.925,3.925 8.149999999999999,8.15 12,12 C 15.85,15.85 20.075,20.075 22,22"
                                                ></path>
                                                <path className="long" d="M9 15L20 4"></path>
                                                <path className="arrow" d="M13 11V7"></path>
                                                <path className="arrow" d="M17 11H13"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="project-name-guide-text">
                                        Max characters:&nbsp;
                                        <span className="pink-color">
                                            {formValues.project_name.length > 0 && (
                                                <>{formValues.project_name.length}/</>
                                            )}
                                            20
                                        </span>
                                    </p>
                                    {formErrors.project_name != null && (
                                        <span className={formErrors.error_class}>
                                            {formErrors.project_name}
                                        </span>
                                    )}
                                </div>

                                <div className="add-project-colaborator collab-add-projects">
                                    <h3 className="pro-set-col-head">
                                        Project collaborators
                                        {/*{props.planType == "PAID" && (*/}
                                        <div className="added-collaborators-wrap">
                                            <div className="group-slide-left">
                                                <img
                                                    src={
                                                        process.env.REACT_APP_URL +
                                                        "images/user-profile-icon.png"
                                                    }
                                                    alt="img"
                                                />
                                                <span>
                                                    <b>{collaborators.length}</b> collaborators
                                                </span>
                                            </div>
                                        </div>
                                        {/*)}*/}
                                    </h3>

                                    <p>
                                        {collaboratorOptions.length == 0 ?
                                            <>
                                                Before adding collaborators to this project, you'll need to add them to your account first.
                                            </>
                                            :
                                            <>
                                                Add your team members to this project, so they can view, add, and edit tests.
                                            </>
                                        }
                                    </p>

                                    {/*{collaboratorOptions.length > 0 && (*/}
                                    {/*  <p>*/}
                                    {/*    Collaborators will be able to view, add and edit the tests*/}
                                    {/*    within a project.*/}
                                    {/*  </p>*/}
                                    {/*)}*/}
                                    {/*{collaboratorOptions.length == 0 &&*/}
                                    {/*  props.planType == "FREE" && (*/}
                                    {/*    <p>*/}
                                    {/*      Collaborators will be able to view, add and edit the*/}
                                    {/*      tests within a project.*/}
                                    {/*    </p>*/}
                                    {/*  )}*/}
                                    {/*{collaboratorOptions.length == 0 && props.planType == "PAID" && (*/}
                                    {/*  <>*/}
                                    {/*    <p>*/}
                                    {/*      You can add project collaborators within your account*/}
                                    {/*      members group. It looks like you are the only member of*/}
                                    {/*      this account.*/}
                                    {/*    </p>*/}
                                    {/*    <div className="no-collaborator-added">*/}
                                    {/*      <Link className="link linktype-1" to="/researcher/team">*/}
                                    {/*        Add ACCOUNT members*/}
                                    {/*      </Link>*/}
                                    {/*    </div>*/}
                                    {/*  </>*/}
                                    {/*)}*/}
                                    {collaboratorOptions.length == 0 &&
                                        <div className="no-collaborator-added">

                                            <Link className="link linktype-1" to="/researcher/team">
                                                Add account collaborators {" "} &nbsp;
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/fill-plus.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                            </Link>
                                        </div>
                                    }



                                    {/*{props.planType == "PAID" && (*/}
                                    {collaboratorOptions.length !== 0 && (
                                        <div className="add-plan-member-sec mt-32 create-modal-collab-sec">
                                            {/*{collaborators.length > 0 && (*/}
                                            {/*  <div className="added-options-wrapper mt-0">*/}
                                            {/*    {renderCollaborators()}*/}
                                            {/*  </div>*/}
                                            {/*)}*/}

                                            <div className="create-projectmodal-form-group added-collab-listing">
                                                <h3 className="link linktype-1">
                                                    {/*Add account members {" "}*/}
                                                </h3>
                                                <div className="project-colaborator-form-wrap d-block profile-form-group">
                                                    {/*{collaboratorOptions.length > 0 && (*/}
                                                    <>
                                                        {/*{openForm && (*/}
                                                        <>
                                                            <div className="input form-group input-field">
                                                                <div className="position-relative w-100">

                                                                    <div
                                                                        className={
                                                                            formErrors.email != null
                                                                                ? "input_error"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <Select
                                                                            value={collaborators}
                                                                            options={collaboratorOptions}
                                                                            onChange={(item) => {
                                                                                setCollaborators(item);
                                                                            }}
                                                                            ref={emailSelect}
                                                                            isMulti={true}
                                                                            placeholder="Select collaborators"
                                                                            components={{ DropdownIndicator }}
                                                                        />
                                                                    </div>
                                                                    {formErrors.email != null && (
                                                                        <span
                                                                            className={formErrors.error_class}
                                                                        >
                                                                            {formErrors.email}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {/*<span className="project-permission-drop-down">*/}
                                                                {/*  <Dropdown>*/}
                                                                {/*    <Dropdown.Toggle*/}
                                                                {/*      id="dropdownMenuButton"*/}
                                                                {/*      className="dropdown-toggle"*/}
                                                                {/*    >*/}
                                                                {/*      {collaboratorRow.role}*/}
                                                                {/*    </Dropdown.Toggle>*/}
                                                                {/*    <Dropdown.Menu>*/}
                                                                {/*      <Dropdown.Item*/}
                                                                {/*        href="#"*/}
                                                                {/*        onClick={setCollabortorRole(*/}
                                                                {/*          "Admin"*/}
                                                                {/*        )}*/}
                                                                {/*      >*/}
                                                                {/*        Admin*/}
                                                                {/*      </Dropdown.Item>*/}
                                                                {/*      <Dropdown.Item*/}
                                                                {/*        href="#"*/}
                                                                {/*        onClick={setCollabortorRole(*/}
                                                                {/*          "Editor"*/}
                                                                {/*        )}*/}
                                                                {/*      >*/}
                                                                {/*        {" "}*/}
                                                                {/*        Editor*/}
                                                                {/*      </Dropdown.Item>*/}
                                                                {/*    </Dropdown.Menu>*/}
                                                                {/*  </Dropdown>*/}
                                                                {/*</span>*/}
                                                            </div>
                                                            {/*<div className="last-active-wrap close-add-collaborator">*/}
                                                            {/*  &nbsp;*/}
                                                            {/*  <span*/}
                                                            {/*    className="delete-table-row"*/}
                                                            {/*    onClick={() => {*/}
                                                            {/*      setOpenForm(false);*/}
                                                            {/*      setEmail(null);*/}
                                                            {/*      emailSelect.current = null;*/}
                                                            {/*      setCollaboratorRow({*/}
                                                            {/*        email: "",*/}
                                                            {/*        role: "Admin",*/}
                                                            {/*      });*/}
                                                            {/*      showFormError("email", null);*/}
                                                            {/*    }}*/}
                                                            {/*  >*/}
                                                            {/*    <img*/}
                                                            {/*      src={*/}
                                                            {/*        process.env.REACT_APP_URL +*/}
                                                            {/*        "images/cross.svg"*/}
                                                            {/*      }*/}
                                                            {/*      alt="img"*/}
                                                            {/*    />*/}
                                                            {/*  </span>*/}
                                                            {/*</div>*/}
                                                        </>
                                                        {/*)}*/}
                                                        {/*<div*/}
                                                        {/*  className="create-btn add-new-card-btn add-new-member-btn mt-32"*/}
                                                        {/*  // onClick={addCollaborator()}*/}
                                                        {/*  onClick={*/}
                                                        {/*    openForm*/}
                                                        {/*      ? addCollaborator()*/}
                                                        {/*      : () => {*/}
                                                        {/*          setOpenForm(true);*/}
                                                        {/*        }*/}
                                                        {/*  }*/}
                                                        {/*>*/}
                                                        {/*  Add new member*/}
                                                        {/*  <span>*/}
                                                        {/*    <img*/}
                                                        {/*      src={*/}
                                                        {/*        process.env.REACT_APP_URL +*/}
                                                        {/*        "images/fill-plus.svg"*/}
                                                        {/*      }*/}
                                                        {/*      alt="img"*/}
                                                        {/*    />*/}
                                                        {/*  </span>*/}
                                                        {/*</div>*/}
                                                    </>
                                                    {/*)}*/}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/*props.planType == "FREE" && (
                    <div className="block-colaborator-section">
                      <div className="lock-img-sec">
                        <img
                          src={
                            process.env.REACT_APP_URL + "images/lock-icon.svg"
                          }
                        />
                      </div>

                      <div className="text-wrap">
                        <p>
                          You don’t have access to collaboration features.{" "}
                          <Link
                            to="/researcher/billing/plan/change"
                            className="updrate-plan-link"
                          >
                            Upgrade
                          </Link>{" "}
                          your plan to add collaborators.
                        </p>
                      </div>
                    </div>
                  )*/}
                                    <div className="create-project-form-btn">
                                        <button
                                            type="button"
                                            className="btn form-btn secondary-btn"
                                            onClick={() => {
                                                closeModal();
                                            }}
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn form-btn">
                                            Save
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}
