//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState, useEffect, useRef} from "react";
import {Text} from "../../themes/userq/Text/Text";
import {ShareLink} from "../../themes/userq/ShareLink/ShareLink";
import {Button} from "../../themes/userq/Button/Button";
import {Icon} from "../../themes/userq/Icon/Icon";
import {CheckBox} from "../../themes/userq/CheckBox/CheckBox";


export default function NewTesterConcludeTestModal({openNewTesterConcludeTestModal,closeNewTesterConcludeTestModal, test, isCompleteMyProfile,cssClass}) {

    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeNewTesterConcludeTestModal(false);
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openNewTesterConcludeTestModal}
            centered
            className={`fade custom-modal-wrap guest-user-given-test-modal ${cssClass}`}
        >
            <Modal.Body className= {(test.language=="ar") ? "arabic_wrapper" : ""} ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={"h3"} >
                            {test.language=="en"?"Want to get paid next time?":"هل ترغب بالحصول على مال في المرة القادمة؟"}
                        </Text>



                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeNewTesterConcludeTestModal(false);
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon size={'medium'} value={'close'} />
                            </span>
                        </button>
                    </div>
                    <div className="guest-user-modal-data">
                        <Text type={"body-text-3"} fontWeight={'medium-font'}>
                            {test.language === 'en'?
                                "Register with UserQ to participate in more tests like this and get paid for your insights."
                                :"سجل مع UserQ للمشاركة في المزيد من الاختبارات المماثلة واحصل على أموال مقابل رأيك."}
                        </Text>
                    </div>

                    <div className="confirm-buttons-wrap">

                        <Button
                            type="primary"
                            size="large"
                            label={test.language === 'en'?"Create your account":'أنشئ حسابك'}
                            onClick={(e)=>{
                                navigate("/tester/sign-up");
                            }} />

                    </div>

                </div>

            </Modal.Body>


            {/*<Modal.Body className="modal-lg p-0">*/}
            {/*    <div className={`modal-inner-text p-64 text-left ${test.language=="ar"? 'arabic_wrapper':''}` }>*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className="close"*/}
            {/*            onClick={() => {*/}
            {/*                closeNewTesterConcludeTestModal(false);*/}
            {/*            }}*/}
            {/*            aria-label="Close"*/}
            {/*        >*/}
            {/*            <span aria-hidden="true">*/}
            {/*            <img*/}
            {/*                src={process.env.REACT_APP_URL + "images/cross.svg"}*/}
            {/*                alt="cross"*/}
            {/*            />*/}
            {/*            </span>*/}
            {/*        </button>*/}

            {/*        <div>*/}
            {/*    <div>*/}
            {/*        <h2>{test.language=="en"?"Want to get paid next time?":"تريد الحصول على المال المرة القادمة؟"}</h2>*/}
            {/*        */}
            {/*        {test.language === 'en'?*/}
            {/*        <p className="w-100 text-left">Register with UserQ to fill out more tests like this and get paid for your insights.</p>*/}
            {/*        :<p className="w-100 text-right"> قم بالتسجبل في UserQ للقيام بالمزيد من الاختبارات المشابهة لهذا الإختبار واحصل على المال مقابل أراك &nbsp;</p>}*/}
            {/*        */}
            {/*    </div>*/}


            {/*</div>*/}

            {/*        <div className="button-wrap d-flex justify-content-between new-tester-conclude mt-32">*/}
            {/*            <Link to={'/tester/sign-in'} className="button primary-btn w-100 m-0">{test.language === 'en'?"Register":"سجل"}</Link>*/}
            {/*        </div>*/}


            {/*    </div>*/}
            {/*</Modal.Body>*/}
        </Modal>
    );
}