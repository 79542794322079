import React, {useEffect, useState} from "react";
export default function InputField({inputref, onBlur, min, inline_label, readOnly, type, required, value, rtl, defaultValue, maxLength,onClear,onInput,id,onChange,onKeyDown,onClick, error, error_class, label,infoLabel}){
    const [fieldType, setFieldType] =   useState((type ? type : "text"));
    return (
        <div className={`input form-group input-field ${readOnly?'read-only-field':''} mb-0 ${value!=''?'focused':''} ${rtl==true?'arabic_wrapper':''}`}>
            <div className={`position-relative w-100  ${(rtl==true)?'arabic_wrapper':''}`}>
                <input
                    type={fieldType}
                    id={id}
                    min={min}
                    className={`form-control ${(error!=null && error_class)?error_class:''} ${(rtl==true)?'arabic_wrapper':''}`}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    maxLength={maxLength}
                    onInput={onInput}
                    rtl={rtl?rtl:''}
                    readOnly={readOnly}
                    ref={inputref}
                    onClick={onClick}
                    onBlur={onBlur}
                />
                {type=="password" &&
                <i onClick={()=>{ setFieldType( fieldType=="password" ? "text": "password" )}} className="toggle-password"><img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="eye" /></i>
                }
                <label className="control-label" htmlFor={id}>
                    {label}
                    {required &&
                    <img src={process.env.REACT_APP_URL+"images/star-field.svg"} className="required-field-icon" alt="required-field"></img>
                    }
                </label>
                {inline_label &&
                <span className="aed-btn-wrap">{inline_label}</span>
                }
                {/* {readOnly &&
                <div className="data-field-icon"><img src={process.env.REACT_APP_URL+"images/fill-check.svg"} alt="icon" /></div>
                } */}
                {onClear && !type &&
                <span
                    className="clear"
                    onClick={onClear}
                >
                    <svg viewBox="0 0 24 24">
                        <path
                        className="line"
                        d="M 2,2 C 3.925,3.925 8.149999999999999,8.15 12,12 C 15.85,15.85 20.075,20.075 22,22"
                        ></path>
                        <path className="long" d="M9 15L20 4"></path>
                        <path className="arrow" d="M13 11V7"></path>
                        <path className="arrow" d="M17 11H13"></path>
                    </svg>
                </span>
                }
            </div>
            {!readOnly && !error && maxLength &&
            <p className="project-name-guide-text">
                Max characters:&nbsp;
                <span className="pink-color">
                {value && value.length > 0 && (
                    <>{value.length}/</>
                )}
                {maxLength}
                </span>
            </p>
            }

            {infoLabel &&
                <p className="field-guide-text">{infoLabel}</p>
            }
            {error != null && (
                <span className={error_class}>
                    {error}
                </span>
            )}
        </div>
    )
}
