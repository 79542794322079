import PropTypes from 'prop-types';
import { Icon } from '../Icon/Icon';
import { CircularNameIcon } from './CircularNameIcon';
import { Link } from 'react-router-dom';
export const CircularNameIconGroup = ({circularIconsArray, seatsAvailable, onClick, link,... props})=>{
    
    const renderCircularIcons = () => {

        var render = [];

        var till = circularIconsArray.length > 3 ? 3 :  circularIconsArray.length;

        for(var i=0; i < till; i++){
            render.push((
                <CircularNameIcon tooltip={circularIconsArray[i].tooltip} key={"circle-icon-"+i} size={"small"} style={{background:circularIconsArray[i].color}} label={circularIconsArray[i].label} />
            ));
        }
        if(circularIconsArray.length > 3){

            if(link){
                render.push((
                    <Link to={link} key={"circle-icon-"+(till+1)}><CircularNameIcon size={"small"} color={"gray-300"} label={<>+{circularIconsArray.length-3}</>}  /></Link>
                ));
            } else {
                render.push((
                    <CircularNameIcon key={"circle-icon-"+(till+1)} size={"small"} color={"gray-300"} label={<>+{circularIconsArray.length-3}</>}  />
                ));
            }
            
            
        }
        // if(circularIconsArray.length > 0 && seatsAvailable){
        //     render.push((
        //         <Link to={link} key={"circle-icon-"+(till+2)} ><CircularNameIcon  size={"small"} color={"gray-50"} label={<><Icon value={"add"} size="small" /> </>}  /></Link>
        //     ));
        // }
        return render;
    }
    
    return (
        <div className='circular-name-icon-wrap'>
            {renderCircularIcons()}
        </div>
    )
    
    
}
CircularNameIconGroup.propTypes = {
    circularIconsArray: PropTypes.array,
    onClick: PropTypes.func
}
CircularNameIconGroup.defaultProps = {
    circularIconsArray: [],
    onClick: undefined
}