import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import {DropdownIndicator} from "../../lib/helpers";

export default function MoveTestModal(props) {
    const [selectedProject, setSelectedProject] = useState({project_id:null,project_name:null});



  const closeModal = () => {
    props.close();
  };
  return (
    <Modal
      show={props.openModal}
      centered
      className="fade custom-modal-wrap  move-test-modal-text mid-width-modal"
    >
      <Modal.Body className="modal-lg p-0">
        <div className="align-left-modal p-64">
          <button
            type="button"
            className="close"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <img
                src={process.env.REACT_APP_URL + "images/cross.svg"}
                alt="cross"
              />
            </span>
          </button>
          <h2 className="h2">{props.movetest_title}</h2>
          <p>{props.movetest_message}</p>
          <div className="input form-group  input-field">
            {props.userProjects.length == 0 ? (
              <div
                onClick={() => {
                  closeModal();
                  props.openProjectModal();
                }}
                className="no-projects-found no-data"
              >
                No Projects Found. Click to create a project.
              </div>
            ) : (
              <Select
                placeholder="Select Project..."
                value={
                  selectedProject.project_id
                    ? {
                        value: selectedProject.project_id,
                        label: selectedProject.project_name,
                      }
                    : null
                }
                options={props.userProjects}
                onChange={(item) => {
                  setSelectedProject({
                    ...selectedProject,
                    project_id: item.value,
                    project_name: item.label,
                  });
                }}
                components={{DropdownIndicator}}
              />
            )}
          </div>
          <div className="button-wrap d-flex">
            <button
              type="button"
              className="button secondary-btn"
              onClick={() => {
                closeModal();
              }}
              aria-label="Close"
            >
              CANCEL
            </button>

            <button
              type="button"
              className="button primary-btn"
              onClick={() => {
                props.move(selectedProject.project_id);
              }}
              aria-label="Close"
            >
              {props.movetest_btn_title ? props.movetest_btn_title : "OK"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}