//import { ReactSession } from 'react-client-session';
import { ReactSession } from "../../../lib/secure_reactsession";
import React, {useEffect, useState} from "react";
import { Modal } from 'react-bootstrap';
import { maxLengthCheck } from '../../../lib/helpers';
import { createTargetGroup } from '../../../services/target_group';
import { useNavigate } from 'react-router-dom';

export default function NewTargetGroupModal(props) {

    const navigate = useNavigate();

    ReactSession.setStoreType("localStorage");

    

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({"target_group_name": ""});

    const [formErrors, setFormErrors] = useState({target_group_name:null, "error_class":null});

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [errorMsg, setErrorMessage] = useState(null);    
	
    
    const onSubmit =  async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        resetFormErrors();


        if(!formLoading){

            var error = false;

            if(formValues.target_group_name==''){       
                
                showFormError("target_group_name", "Required field");
                error = true;

            }
            
            if(!error){
                setFormLoading(true);

                const token = ReactSession.get("token");

                formValues.project_id = props.project_id;

                ReactSession.set("target_group_name",formValues.target_group_name);

                navigate("/researcher/target_group/add/"+props.project_id);
                
            }
        }
        return false;
    }

    const closeModal = () => {

        setFormValues({target_group_name:''})

                        resetFormErrors();

                        showFormError("target_group_name", null);

                        setFormErrors({target_group_name:null, error_class:"input_error"})


       
        props.closeModal(false);
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal['error_class'] = 'input_error';

        setFormErrors(formErrorsLocal);


    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }
    
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    }
    return (
        <Modal show={props.openModal} centered className="fade custom-modal-wrap  create-new-project-modal">
            <Modal.Body className="modal-lg">
                <div className="modal-inner-text">
                    <button type="button" className="close" onClick={()=>{ closeModal(); } } aria-label="Close">
                        <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                    </button>
                    <form onSubmit={onSubmit} >
                        <div className="create-targetgroup-data-wrap">
                            <h2 className="h2">Create target group</h2>
                            <p>Build a specific target audience to use in your tests.</p>
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {successMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap">
                                    <div className="pro-lft-wrap">
                                        {successMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearSuccessMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="create-projectmodal-form-group">
                                <div className="input form-group input-field">
                                    <input type="text" maxLength="20" onInput={maxLengthCheck} className={formErrors.target_group_name!=null ? "form-control "+formErrors.error_class : "form-control"} id="name" name='target_group_name' onChange={set('target_group_name') }/>
                                    <label className="control-label" htmlFor="name">Target group name</label> 
                                    <span className="clear" onClick={clearFormValue('target_group_name')}>
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M 2,2 C 3.925,3.925 8.149999999999999,8.15 12,12 C 15.85,15.85 20.075,20.075 22,22"></path>
                                            <path className="long" d="M9 15L20 4"></path>
                                            <path className="arrow" d="M13 11V7"></path>
                                            <path className="arrow" d="M17 11H13"></path>
                                        </svg>
                                    </span>
                                </div>
                                <p className="project-name-guide-text">Max characters:&nbsp; 
                                    <span className="pink-color">
                                        {formValues.target_group_name.length > 0 &&
                                            <>{formValues.target_group_name.length}/</>
                                        }
                                        20
                                    </span>
                                </p>
                                {(formErrors.target_group_name!=null) &&
                                    <span className={formErrors.error_class}>{formErrors.target_group_name}</span>
                                }
                            </div>
                            <div className="create-project-form-btn justify-content-center">
                                <button type="button" className="btn form-btn secondary-btn" onClick={()=>{ closeModal(false); } }>Cancel</button>
                                <button type="submit" className="btn form-btn" id="r_new_target_group_created">
                                    CREATE
                                    {formLoading &&
                                        <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}