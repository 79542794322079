import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { encryptClient} from "../../lib/helpers";
import { changePassword, checkPassword, setPassword as setPasswordService } from "../../services/user.js";
import { Text } from "../themes/userq/Text/Text";
import { Input } from './../themes/userq/Input/Input';
import { Button } from "../themes/userq/Button/Button";
import { showToast } from "../../lib/helpers";
import { Icon } from "../themes/userq/Icon/Icon";

export default function SetPasswordModal(props) {
    ReactSession.setStoreType("localStorage");

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal(false);
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);


    const [passwordFormErrors, setPasswordFormErrors] = useState({
        currentpassword: null,
        newpassword: null,
        confirmpassword: null,
        error_class: null,
    });

    const [passwordFormValues, setPasswordFormValues] = useState({
        currentpassword: "",
        newpassword: "",
        confirmpassword: "",
    });

    const [formLoading, setFormLoading] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState({error:false, length:0, letters:0, numbers:0, special:0});
    const [enableChangePassword, setEnableChangePassword]= useState(false);

    const setPassword = (name) => {
        return ({ target: { value } }) => {
            setPasswordFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            if(name=="newpassword"){
                var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;

                if (
                    value.length >= 6 &&
                    value.match(re)
                ){
                    setPasswordFormErrors((oldValues) => ({
                        ...oldValues,
                        newpassword: null,
                    }));
                }
                if(passwordFormValues.confirmpassword === value){
                    setPasswordFormErrors((oldValues) => ({
                        ...oldValues,
                        confirmpassword: null,
                    }));
                }
                validateNewPassword(value,false);
            }
            if(name=="confirmpassword"){
                if(passwordFormValues.newpassword === value){
                    setPasswordFormErrors((oldValues) => ({
                        ...oldValues,
                        confirmpassword: null,
                    }));
                }
            }
            if(name=="currentpassword"){
                    setPasswordFormErrors((oldValues) => ({
                        ...oldValues,
                        currentpassword: null,
                    }));
                
            }
        };
    };

    const validateForm = (name) => {
        
        if(name=="newpassword"){
            
            validateNewPassword(passwordFormValues.newpassword,true);



            if(passwordFormValues.confirmpassword != '' && passwordFormValues.confirmpassword === passwordFormValues.newpassword){
                setPasswordFormErrors((oldValues) => ({
                    ...oldValues,
                    confirmpassword: null,
                }));
            }

            if(passwordFormValues.currentpassword && passwordFormValues.newpassword &&  passwordFormValues.currentpassword==passwordFormValues.newpassword){

                setPasswordFormErrors((oldValues) => ({
                    ...oldValues,
                    newpassword: "New password cannot be the same as the old password",
                    password_error_class: "password_error",
                    error_class: "input_error",
                }));
            }
        }
        if(name=="confirmpassword"){
            if (passwordFormValues.confirmpassword.length == 0) {
                setPasswordFormErrors((oldValues) => ({
                    ...oldValues,
                    confirmpassword: "Required field",
                    error_class: "input_error",
                }));
            } else if (
                passwordFormValues.confirmpassword != passwordFormValues.newpassword
            ) {
                setPasswordFormErrors((oldValues) => ({
                    ...oldValues,
                    confirmpassword: "Password does not match",
                    error_class: "input_error",
                }));
            }else {
                setPasswordFormErrors((oldValues) => ({
                    ...oldValues,
                    confirmpassword: null,
                }));
            }
        }
        if(name=="currentpassword"){
            if (passwordFormValues.currentpassword.length == 0) {
                setPasswordFormErrors((oldValues) => ({
                    ...oldValues,
                    currentpassword: "Required field",
                    error_class: "input_error",
                }));
            }else{
                let body = Object.assign({}, passwordFormValues);

                body.currentpassword = encryptClient(body.currentpassword);
                body.newpassword = encryptClient(body.newpassword);
                body.confirmpassword = encryptClient(body.confirmpassword);

                body = JSON.stringify(body);

                const token = ReactSession.get("token");

                var newPasswordMatchWithOld = null;
                if(passwordFormValues.currentpassword && passwordFormValues.newpassword &&  passwordFormValues.currentpassword==passwordFormValues.newpassword){
                    newPasswordMatchWithOld = 'New password cannot be the same as the old password'
                }

                    checkPassword(body, token).then((response) => {
                    if (response.success) {
                        var form_errors = {
                            currentpassword: null,
                            newpassword: passwordFormErrors.newpassword,
                            confirmpassword: passwordFormErrors.confirmpassword,
                            error_class: null,
                        };
                        form_errors = {
                            ...form_errors,
                            currentpassword: null,
                            newpassword:newPasswordMatchWithOld,
                            password_error_class: "password_error",
                            error_class: "input_error",
                        };
                        setPasswordFormErrors(form_errors);
                        setEnableChangePassword(true);

                    } else {
                        if(response.message=="Incorrect password"){
                            var form_errors = {
                                currentpassword: null,
                                newpassword: passwordFormErrors.newpassword,
                                confirmpassword: passwordFormErrors.confirmpassword,
                                error_class: null,
                            };
                            form_errors = {
                                ...form_errors,
                                currentpassword: "Incorrect password",
                                newpassword:newPasswordMatchWithOld,
                                password_error_class: "password_error",
                                error_class: "input_error",
                            };
                            setPasswordFormErrors(form_errors);
                            setEnableChangePassword(false);
                        }
                    }
                });
            }
        }
        
    };

    const closeModal = () => {
        resetFormErrors();

        props.closeModal(null);
    };


    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, passwordFormErrors);

        for (var key in passwordFormErrors) {
            formErrorsLocal[key] = null;
        }
        setPasswordFormErrors(formErrorsLocal);
        setNewPasswordError({error:false, length:0, letters:0, numbers:0, special:0});
    };

    const resetForm = () => {
        let formLocal = Object.assign({}, passwordFormValues);

        for (var key in passwordFormValues) {
            formLocal[key] = '';
        }

        setPasswordFormValues(formLocal);
    };

    const validateNewPassword = (password, blur) =>{

        var new_password_error = Object.assign({},newPasswordError);
        
        if(password.length==0){
            setPasswordFormErrors((oldValues) => ({
                        ...oldValues,
                        newpassword: "Required field",
                        error_class: "input_error",
                    }));
            setNewPasswordError({error:false, length:0, letters:0, numbers:0, special:0});
        }else{
            setPasswordFormErrors((oldValues) => ({
                ...oldValues,
                newpassword: null,
            }));
        if(password.length>-1){
            
            new_password_error = {...new_password_error, error: true};

            
            if(password.length >= 6){

                new_password_error = {...new_password_error, length: 1};
                
            } else {
                
                if(blur){
                    new_password_error = {...new_password_error, length: 2};
                } else {
                    if(new_password_error.length==0){
                    new_password_error = {...new_password_error, length: 0};
                    }else{
                    new_password_error = {...new_password_error, length: 2};
                    }
                }
            }
            const specialChars =/[@$!%*#?&]/;

            if(specialChars.test(password)){
                new_password_error = {...new_password_error, special: 1};
            } else {

                if(blur){
                     new_password_error= {...new_password_error, special: 2};
                }  else {
                    if(new_password_error.special==0){
                    new_password_error = {...new_password_error, special: 0};
                    }else{
                        new_password_error = {...new_password_error, special: 2};
                    }
                }
            }
            const numbers =/[0-9]/;

            if(numbers.test(password)){
                new_password_error = {...new_password_error, numbers: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, numbers: 2};
                } else {
                    if(new_password_error.numbers==0){
                    new_password_error = {...new_password_error, numbers: 0};
                    }else{
                        new_password_error = {...new_password_error, numbers: 2};
                        }
                }
            }
            const letters =/[a-zA-Z]/;

            if(letters.test(password)){
                new_password_error = {...new_password_error, letters: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, letters: 2};
                } else {
                    if(new_password_error.letters==0){
                    new_password_error = {...new_password_error, letters: 0};
                    }else{
                        new_password_error = {...new_password_error, letters: 2};
                        }
                }
            }
            setNewPasswordError(new_password_error);

        } else {

            var new_password_error = Object.assign({},{error:false, length:0, letters:0, numbers:0, special:0});

            setNewPasswordError({...new_password_error, error: false});
        }
        }
    }

    
    const validateFullForm = () => {
        var error = false;

        // var form_errors = resetFormErrors();
        var form_errors = {
            newpassword: null,
            confirmpassword: null,
            error_class: null,
        };

        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;
        
        if(passwordFormValues.newpassword=="" && newPasswordError.error==false){
            form_errors = {
                ...form_errors,
                newpassword: "Required field",
                password_error_class: "password_error",
                error_class: "input_error",
            };
            error = true;

        }
        else if (!passwordFormValues.newpassword.match(re)) {
            // form_errors = {
            //     ...form_errors,
            //     newpassword: "Invalid Password",
            //     password_error_class: "password_error",
            //     error_class: "input_error",
            // };
            error = true;
        }else if(passwordFormValues.currentpassword==passwordFormValues.newpassword){

            form_errors = {
                ...form_errors,
                newpassword: "New password cannot be the same as the old password",
                password_error_class: "password_error",
                error_class: "input_error",
            };
            error = true;


        } else {
            form_errors = {
                ...form_errors,
                newpassword: null,
                error_class: "input_error",
                password_error_class: "none",
            };
        }
        if (passwordFormValues.confirmpassword.length == 0) {
            form_errors = {
                ...form_errors,
                confirmpassword: "Required field",
                confirmpassword_error_class: "password_error",
                error_class: "input_error",
            };
            error = true;
        } else if (
            passwordFormValues.confirmpassword != passwordFormValues.newpassword
        ) {
            form_errors = {
                ...form_errors,
                confirmpassword: "Password does not match",
                confirmpassword_error_class: "password_error",
                error_class: "input_error",
                confirmpassword_errormessage: "Password does not match",
            };

            error = true;
        }
        setPasswordFormErrors(form_errors);

        return error;
    };
    const onSubmitPassword = async (event) => {
        event.preventDefault();

        setPasswordFormErrors({
            newpassword: null,
            confirmpassword: null,
            error_class: null,
        });


        if (!formLoading) {
            var error = validateFullForm();

            if (!error) {
                setFormLoading(true);
                let body = Object.assign({}, passwordFormValues);

                body.newpassword = encryptClient(body.newpassword);

                body.confirmpassword = encryptClient(body.confirmpassword);

                body = JSON.stringify(body);



                const token = ReactSession.get("token");

                setPasswordService(body, token).then((response) => {
                    setFormLoading(false);

                    if (response.success) {
                        resetForm();
                        
                        props.closeModal(response.user);

                        showToast(response.message,"success");
                        setNewPasswordError({error:false, length:0, letters:0, numbers:0, special:0});

                    } else {
                        
                        showToast(response.message,"error");
                    }
                });
            }
        }
        return false;
    };

    return (
        <Modal
            show={props.openModal}
            centered
            className="fade custom-modal-wrap change-pass-modal"
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <h2 className={`heading h2`}>Set a password</h2>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                                resetForm();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                        </button>
                    </div>
                        
                    <div className="password-input-field newpasswordbox">
                        <Input
                            label="Create a password"
                            onChange={setPassword("newpassword")}
                            onClear={() => { }}
                            value={passwordFormValues.newpassword}
                            error={passwordFormErrors.newpassword}
                            error_class={passwordFormErrors.error_class}
                            id="newpassword"
                            clearField={false}
                            required={true}
                            passwordField={true}
                            newPasswordError={newPasswordError}
                            onBlur={()=>validateForm("newpassword")}
                            disabled={enableChangePassword?false:true}
                        />
                    </div>
                    <div className="password-input-field confirmationpasswordbox">
                        <Input
                            label="Confirm password"
                            onChange={setPassword("confirmpassword")}
                            onClear={() => { }}
                            value={passwordFormValues.confirmpassword}
                            error={passwordFormErrors.confirmpassword}
                            error_class={passwordFormErrors.error_class}
                            id="confirmpassword"
                            clearField={false}
                            required={true}
                            passwordField={true}
                            onBlur={()=>validateForm("confirmpassword")}
                            disabled={enableChangePassword?false:true}
                        />
                    </div>
                    <div className="change-password-button">
                        {(passwordFormValues.newpassword==""|| passwordFormValues.newpassword==null|| passwordFormValues.confirmpassword==""|| passwordFormValues.confirmpassword==null)?
                        <Button type="primary" size="large" label="Create" onClick={onSubmitPassword}  state="disabled"/>:
                        <Button type="primary" size="large" label="Create" onClick={onSubmitPassword}  microLoading={formLoading}/>}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
