import React, { useState, useEffect, useRef } from "react";
import { ReactSession } from "../../../../lib/secure_reactsession";
import toast from "react-hot-toast";
import { getFileType, matchExtensions, maxLengthCheck } from "../../../../lib/helpers";
import {addDesignService, addFiveSecondsDesignService} from "../../../../services/test";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Input } from "../../../themes/userq/Input/Input";
//import { navigate } from "@storybook/addon-links";
import AutosizeInput from "react-input-autosize";

import { Preview } from "../../../themes/userq/Preview/preview"
import {ProgressBar} from "../../../themes/userq/ProgressBar/progress_bar";
import { settings } from "../../../../data/settings";


export const ImageUpload = ({ design, index, test, setConfirmModal, onDesignAdded, onDesignUpdated, updateHeaderLoader,isPreview, taskErrors, setImageError }) => {

    const fileRef = useRef();

    const colorRef = useRef();

    const inputRef = useRef(null);

    const [fileType, setFileType] = useState("image");

    const [previewModal, setPreviewModal] = useState({open:false, img:null, loading:false});

    const [designFile, setDesignFile] = useState(null);

    const [previewUrl, setPreviewUrl] = useState(null);

    const [titleEditable, setTitleEditable] = useState(false);
    const [uploadPerc, setUploadPerc] = useState(0);

    let user = ReactSession.get("user");

    useEffect(() => {

        if (design) {
            //console.log(design)
            setFormValues({
                ...formValues,
                file_type: design.type,
                design_id: design.id,
                title: design.title ? design.title : '',
                color: design.color
            });

            setPreviewUrl(process.env.REACT_APP_IMG_URL + "/" + design.design_file);

            setFileType(design.type);
        }


    }, [design]);

    useEffect(() => {

        if (titleEditable) {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [titleEditable]);

    useEffect(()=>{
        var form_errors = {...formErrors};
        
        if(taskErrors.image  && !formErrors.design_file){

            form_errors = {...form_errors, design_file:taskErrors.image};

        }
        setFormErrors(form_errors);

    },[taskErrors]);


    const [formValues, setFormValues] = useState({
        title: "",
        file_type: "",
        color: "",
    });
    const [formErrors, setFormErrors] = useState({
        title: null,
        design_file: null,
        color: null,
        "error_class": "input_error"
    });
    const [formLoading, setFormLoading] = useState(false);

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const handleFile = (file) => {

        resetFormErrors();
        //let's grab the image file
        var error = false;

        var fileType = getFileType(file);

        if (fileType == "image" && file.size / 1024 > 2048) {

            setFormErrors({ ...formErrors, design_file: "Please select file <= 2MB.", "error_class": "input_error" });
            setImageError("Please select file <= 2MB.");
            error = true;
        }
        if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {


            setFormErrors({ ...formErrors, design_file: "Only image files are accepted with extensions jpg, jpeg, gif and png.", "error_class": "input_error" });
            setImageError("Only image files are accepted with extensions jpg, jpeg, gif and png.");
            error = true;
        }
        // if (fileType == "video" && file.size / 1024 > 4096) {
        //
        //     setFormErrors({ ...formErrors, design_file: "Please select file <= 4MB.", "error_class": "input_error" });
        //
        //     error = true;
        // }


        if (fileType != "image") {


            setFormErrors({ ...formErrors, design_file: "Only image files are accepted.", "error_class": "input_error" });
            setImageError("Only image files are accepted.");
            error = true;
        }

        if (!error) {

            if (fileType == "image") {
                setFileType("image");

                setFormValues({ ...formValues, file_type: "image" });
            }
            // if (fileType == "video") {
            //     setFileType("video");
            //
            //     setFormValues({ ...formValues, file_type: "video" });
            // }
            //setDesignFile(file);

            submitForm(file,fileType);

            //setPreviewUrl(URL.createObjectURL(file));
        }

    };

    const handleOnDragOver = (event) => {
        event.preventDefault();
    };
    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    };
    const removeImage = (e) => {
        setDesignFile(null);
        setPreviewUrl(null);
        fileRef.current.value = null;
    };
    const handleFileInput = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();

        handleFile(event.target.files[0]);
    };
    const updateDesign = (form_data = null) => {

        var error = false;

        var form_errors = resetFormErrors();


        if (error) {
            setFormErrors(form_errors);
        }
        if (!error) {
            let token = ReactSession.get("token");

            var form_data_local = form_data ? form_data : formValues;

            const formData = new FormData;
            formData.append("test_id", test.id);

            formData.append("design_id", form_data_local.design_id);

            //formData.append("design_file",designFile);

            //formData.append("file_type",formValues.file_type);

            formData.append("title", form_data_local.title);

            formData.append("color", form_data_local.color);

            setFormLoading(true);

            updateHeaderLoader({status:"loading", message:""})

            addFiveSecondsDesignService(
                formData,
                token
            ).then((response) => {

                setFormLoading(false);

                if (response.success) {

                    updateHeaderLoader({status:"success", message:""})

                    resetFormErrors();

                    setDesignFile(null);

                    setPreviewUrl(null);

                    setFormValues({ title: "", file_type: "" })

                    onDesignUpdated(response.design, index)

                } else {

                    updateHeaderLoader({status:"error", message:response.message})

                    /*toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );*/
                }
            });
        }
    }
    const submitForm = (file, file_type) => {

        var error = false;

        var form_errors = resetFormErrors();

        if (!file) {

            error = true;

            form_errors = {
                ...form_errors,
                design_file: "Please select file.",
                "error_class": "input_error"
            };
        }

        if (error) {
            setFormErrors(form_errors);
        }
        if (!error) {
            let token = ReactSession.get("token");

            const formData = new FormData;

            var title = (test.language == "ar") ?"اسم التصميم":"Design name";

            formData.append("test_id", test.id);

            formData.append("task_file", file);

            formData.append("file_type", file_type);

            formData.append("title", title);

            setFormLoading(true);
            setUploadPerc(0);

           // updateHeaderLoader({status:"loading", message:""})

            addFiveSecondsDesignService(
                formData,
                token,
                (percentage)=>{
                    setUploadPerc(percentage);
                }
            ).then((response) => {

                setFormLoading(false);

                if (response.success) {

                    resetFormErrors();

                    setDesignFile(null);

                    setPreviewUrl(null);

                    setFormValues({ title: "", file_type: "" })

                    removeImage();

                    onDesignAdded(response.design);

                   // updateHeaderLoader({status:"success", message:""})


                } else {

                    //updateHeaderLoader({status:"error", message:response.message})
                    toast(
                        <div className="toastinner">
                            <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: settings.toast_duration,
                        }
                    );
                }
            });
        }
    }
    return (
        <>
            {design &&
            <>
                <div className={`added-design-preview-thum ${taskErrors.image ? "file_error_pref":""}`}>
                    <div className="prefer-design-image-view-hold">
                        <div className="prefer-test-selected-design-view">


                            <div style={{ 'display': 'none' }}

                            >
                                {design.type == "video" &&
                                <div className="video-design-icon">
                                    <img src={process.env.REACT_APP_URL + "images/video-play-btn.svg"} alt="icon" />
                                </div>
                                }


                            </div>


                            <div className="prefer-test-thum-data-hold">
                                {/*<div className="prefer-design-drag-icon">*/}
                                {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*        <path d="M8 6.75C7.80109 6.75 7.61032 6.67098 7.46967 6.53033C7.32902 6.38968 7.25 6.19891 7.25 6C7.25 5.80109 7.32902 5.61032 7.46967 5.46967C7.61032 5.32902 7.80109 5.25 8 5.25C8.19891 5.25 8.38968 5.32902 8.53033 5.46967C8.67098 5.61032 8.75 5.80109 8.75 6C8.75 6.19891 8.67098 6.38968 8.53033 6.53033C8.38968 6.67098 8.19891 6.75 8 6.75ZM8 12.75C7.80109 12.75 7.61032 12.671 7.46967 12.5303C7.32902 12.3897 7.25 12.1989 7.25 12C7.25 11.8011 7.32902 11.6103 7.46967 11.4697C7.61032 11.329 7.80109 11.25 8 11.25C8.19891 11.25 8.38968 11.329 8.53033 11.4697C8.67098 11.6103 8.75 11.8011 8.75 12C8.75 12.1989 8.67098 12.3897 8.53033 12.5303C8.38968 12.671 8.19891 12.75 8 12.75ZM8 18.75C7.80109 18.75 7.61032 18.671 7.46967 18.5303C7.32902 18.3897 7.25 18.1989 7.25 18C7.25 17.8011 7.32902 17.6103 7.46967 17.4697C7.61032 17.329 7.80109 17.25 8 17.25C8.19891 17.25 8.38968 17.329 8.53033 17.4697C8.67098 17.6103 8.75 17.8011 8.75 18C8.75 18.1989 8.67098 18.3897 8.53033 18.5303C8.38968 18.671 8.19891 18.75 8 18.75Z" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />*/}
                                {/*        <path d="M16 6.75C15.8011 6.75 15.6103 6.67098 15.4697 6.53033C15.329 6.38968 15.25 6.19891 15.25 6C15.25 5.80109 15.329 5.61032 15.4697 5.46967C15.6103 5.32902 15.8011 5.25 16 5.25C16.1989 5.25 16.3897 5.32902 16.5303 5.46967C16.671 5.61032 16.75 5.80109 16.75 6C16.75 6.19891 16.671 6.38968 16.5303 6.53033C16.3897 6.67098 16.1989 6.75 16 6.75ZM16 12.75C15.8011 12.75 15.6103 12.671 15.4697 12.5303C15.329 12.3897 15.25 12.1989 15.25 12C15.25 11.8011 15.329 11.6103 15.4697 11.4697C15.6103 11.329 15.8011 11.25 16 11.25C16.1989 11.25 16.3897 11.329 16.5303 11.4697C16.671 11.6103 16.75 11.8011 16.75 12C16.75 12.1989 16.671 12.3897 16.5303 12.5303C16.3897 12.671 16.1989 12.75 16 12.75ZM16 18.75C15.8011 18.75 15.6103 18.671 15.4697 18.5303C15.329 18.3897 15.25 18.1989 15.25 18C15.25 17.8011 15.329 17.6103 15.4697 17.4697C15.6103 17.329 15.8011 17.25 16 17.25C16.1989 17.25 16.3897 17.329 16.5303 17.4697C16.671 17.6103 16.75 17.8011 16.75 18C16.75 18.1989 16.671 18.3897 16.5303 18.5303C16.3897 18.671 16.1989 18.75 16 18.75Z" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />*/}
                                {/*    </svg>*/}

                                {/*</div>*/}
                                <div className="prefer-designpreview-thum-data-wrap">
                                    <div className="added-design-img-thm" style={{ "backgroundColor": formValues.color}}>
                                        <img src={process.env.REACT_APP_IMG_URL + design.thumb} />

                                        <div className="design-preview-btn-wrap">
                                            <a href={process.env.REACT_APP_IMG_URL + design.design_file} target="_blank">
                                                <Button label={"Preview"} iconLeft={
                                                    <Icon value={"eye"} size={"medium"}  />
                                                }
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            setPreviewModal({...previewModal, open:true, background:formValues.color,file_type:design.type, img:process.env.REACT_APP_IMG_URL + design.design_file});
                                                        }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`prefer-design-action-option ${test.language == "ar" ?"arabic_wrapper":""}`}>

                                        <div className="prefe-test-name-wrap d-flex align-items-center ">
                                            {!titleEditable &&
                                            <>
                                                {!formValues.title &&
                                                <Text type="body-text-2" fontWeight={'medium-font'}>{test.language == "ar" ?"اسم التصميم":"Design name goes here"}</Text>
                                                }
                                                {formValues.title &&
                                                <Text type="body-text-2" fontWeight={'medium-font'}>{formValues.title}</Text>
                                                }

                                                <span className="edit-btn" onClick={() => { setTitleEditable(true) }}><Icon value={"edit"} size={"small"} /></span>
                                            </>
                                            }
                                            {titleEditable &&
                                            <div className={`image-edit-name-input ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
                                                <AutosizeInput
                                                    className={`auto-width-input ${test.language == "ar" ? "arabic_wrapper" : ""}`}
                                                    value={formValues.title ? formValues.title : ''}
                                                    placeholder={test.language == "ar" ?"اسم التصميم":'Design name'}
                                                    onChange={(e) => {
                                                        setFormValues({...formValues, title:e.target.value});
                                                    }}
                                                    maxLength={35}
                                                    onBlur={
                                                        () => {
                                                            setTitleEditable(false);
                                                            updateDesign();
                                                        }
                                                    }
                                                    onKeyDown={(e)=>{
                                                        if (e.key === 'Enter') {
                                                            setTitleEditable(false);
                                                            updateDesign();
                                                        }
                                                    }}
                                                    ref={inputRef}
                                                />
                                                <span className="edit-btn" onClick={() => {
                                                    setTitleEditable(false);
                                                    updateDesign(); }}>
															<Icon value={"check-mark"} size={"small"} />
														</span>

                                                {/*<Input type={"text"} value={formValues.title ? formValues.title : ''}
															onChange={(e) => {
																setFormValues({ ...formValues, title: e.target.value });
															}}
															label={"Design name"}
															onBlur={
																() => {
																	setTitleEditable(false);
																	updateDesign();
																}
															}
														/>*/}
                                            </div>
                                            }

                                        </div>
                                        <div className="prefer-test-preview-bottom-wrap">

                                            <div className="prefer-thum-bg-color-wrap d-flex">
                                                <span>Background color</span>
                                                <div className="prefer-bg-colorpiker" >


                                                    <div className="bg-color-box"
                                                         onClick={() => { if (colorRef && colorRef.current) { colorRef.current.click(); } }}
                                                         style={{ "backgroundColor": formValues.color, width: "50px", "height": "50px" }}>

                                                    </div>

                                                    <Input
                                                        ref={colorRef}
                                                        style={{ "display": "none" }}
                                                        type={"color"}
                                                        rtl={(test && test.language == "ar") ? true : false}
                                                        label={"Background color"} value={formValues.color}
                                                        onChange={(e) => { setTitleEditable(false); setFormValues({ ...formValues, color: e.target.value }); updateDesign({ ...formValues, color: e.target.value }); }}
                                                        error={formErrors.color}
                                                        error_class={formErrors.error_class}
                                                    />
                                                </div>
                                            </div>

                                            <Button
                                                type={"secondary"}
                                                onClick={() => {
                                                    setConfirmModal({ open: true, design_id: design.id });
                                                }}

                                                label="Delete"
                                                iconLeft={<Icon value={"delete"} size="medium" />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Preview withMinimizeIcon={true} open={previewModal.open} background={previewModal.background} loading={false} file_type={previewModal.file_type} img={previewModal.img} close={()=>{
                    //console.log(previewModal)

                    //setPreviewModal({...previewModal,open:false});
                    setPreviewModal({open:false, img:null, loading:false});
                }}/>
            </>
            }
            {!design &&
            <>
                <div className={`upload-image-inner-wrapper ${taskErrors.image ? "file_error_pref":""} `}>
                    <div className="upload-img-opton-wrap">
                        {!isPreview &&
                            <>
                            {!formLoading &&
                                <div
                                    class="upload-image-information-wrapper"
                                    onDragOver={handleOnDragOver}
                                    onDrop={handleOnDrop}
                                >
                                    <Text type={"subtitle-2"} fontWeight={"medium-fomt"}>Drag and drop to upload file</Text>
                                    <Text type={"body-text-3"} fontWeight={"medium-font"}>JPG, JPEG, PNG or GIF supported</Text>
                                    <Text type={"caption"} fontWeight={"medium-font"}>Max image dimensions are 16000x16000. Max image size is 2MB</Text>


                                    <button className="secondary-btn secondary-large position-relative up-img-btn btn-with-icon-left">
                                        {/*<Icon value="upload" size={"medium"} /> Upload*/}
                                        {/*<input type={"file"}*/}
                                        {/*       ref={fileRef}*/}
                                        {/*       accept="image/*, video/*"*/}
                                        {/*       onChange={handleFileInput} />*/}
                                        <label className="label upload-image-label">
                                            <span className="change-image-link">
                                                <Icon value="upload" size={"medium"} />
                                                <input
                                                    type="file"
                                                    ref={fileRef}
                                                    className="default-file-input"
                                                    onChange={handleFileInput}
                                                    onClick={(event) => {
                                                        event.target.value = null
                                                    }}
                                                    accept="image/*, video/*"
                                                    style={{display: "none"}}
                                                />
                                                <span className="browse-files-text">Upload</span>
                                            </span>
                                        </label>
                                    </button>




                                </div>
                            }
                                {formLoading &&
                                <div
                                    class="upload-image-information-wrapper"

                                >
                                    <ProgressBar percentage={uploadPerc} />

                                </div>
                                }
                            </>
                        }
                        {isPreview && previewUrl && (
                            <div className="project-modal-img design-uploaded-img project-setting-image-wrap w-100 design-edit-image-option" onDragOver={handleOnDragOver}
                                 onDrop={handleOnDrop}>
                                <div className="uploaded-project-img edit-design-img-wrap">
                                    <div className="prefer-upload-opton-wrap">

                                        {fileType == "image" &&
                                        <img src={previewUrl} alt="img" width="100%" />
                                        }
                                        {fileType == "video" &&
                                        <video src={previewUrl} width="100%" controls="1">
                                        </video>
                                        }
                                    </div>


                                </div>

                                <img
                                    className="remove-img"
                                    onClick={removeImage}
                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                    alt="img"
                                    style={{ width: "20px" }}
                                />
                            </div>
                        )}

                    </div>

                    {/* {formErrors.design_file &&
                    <ToastMessage type={"error"} message={formErrors.design_file} closable={false} />
                    } */}

                </div>
            </>
            }
        </>
    );
}