import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import "./accepted-workspace-modal.css";

export default function AcceptedWorkspaceModal({
  open,
  close,
  cssClass,
  body,
}) {
  const navigate = useNavigate();

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.target.nodeName == "DIV") {
            close();
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);
  return (
    <Modal
      show={open}
      centered
      className={`fade custom-modal-wrap ${cssClass}`}
    >
      <Modal.Body className={""} ref={wrapperRef}>
        <div className="accepet-modal-text">{body}</div>
      </Modal.Body>
    </Modal>
  );
}
