import React from "react";
import { Modal } from "react-bootstrap";

export default function InvitationModal({
    openModal,
    closeModal,
    confirmInvitation,
    formLoading,
    invitatioModalData
}) {
    return (
        <Modal
        show={openModal}
        centered
        className="fade custom-modal-wrap  mid-width-modal confirm-modal invitation-email confirm-modal-wrapper"
        >
        <Modal.Body className="modal-lg p-0">
            <div className="modal-inner-text p-64">
            <button
                type="button"
                className="close"
                onClick={() => {
                closeModal();
                }}
                aria-label="Close"
            >
                <span aria-hidden="true">
                <img
                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                    alt="cross"
                />
                </span>
            </button>
            <h2 className="h2">Confirm invitation</h2>
            {/*<p>We will send an invite on added emails to join workspace for their respective roles.</p>*/}
            {/*<p>Once they register, you will be charged on the next next bill for the additional seat.</p>*/}


                <p className={"text-left"} dangerouslySetInnerHTML={{ __html:invitatioModalData.msg }}></p>
                <div className="confirm-buttons-div button-wrap d-flex">
                <button
                    type="button"
                    className="button secondary-btn"
                    onClick={() => {
                    closeModal();
                    }}
                    aria-label="Close"
                >
                    CANCEL
                </button>
                    {invitatioModalData.confirmBtn &&
                        <button
                            type="button"
                            className="button primary-btn"
                            onClick={() => {
                            confirmInvitation();
                            }}
                            aria-label="Close"
                        >
                            CONFIRM
                            {formLoading && (
                                <i
                                className="fa fa-spinner fa-spin"
                                aria-hidden="true"
                                style={{ marginLeft: "5px" }}
                                ></i>
                            )}
                        </button>
                    }
                </div>
            
            </div>
        </Modal.Body>
        </Modal>
    );
}
