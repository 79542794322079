import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { Icon } from "../themes/userq/Icon/Icon";
import { ReactSession } from "../../lib/secure_reactsession";
import { getTestData } from "../../services/test";
import moment from "moment";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { Text } from "../themes/userq/Text/Text";
import { Tag } from "../themes/userq/Tag/Tag";
import { Question } from "../themes/userq/Question/Question";
import { Label } from "recharts";
import { settings } from "../../data/settings";
import { FilterButtons } from "../themes/userq/FilterButtons/FilterButtons";
import { emirates } from "../../data/emirates";
import { saregions } from "../../data/saregions";
import { DefineFlow } from "../researcher/tests/prototype_test/define_flow";
import { ScreeningQuestion } from "../themes/userq/Question/ScreeningQuestion";
import { useLocation } from "react-router-dom";

export default function TestSummaryModal(props) {

    ReactSession.setStoreType("localStorage");
    const wrapperRef = useRef(null);

    const location = useLocation();

    const [isLoading, setLoading] = useState(false);
    const [briefingQuestion, setBriefingQuestion] = useState([]);
    const [deBriefingQuestion, setDeBriefingQuestion] = useState([]);
    const [surveyQuestion, setSurveyQuestion] = useState([]);
    const [fiveSecondsQuestion, setFiveSecondsQuestion] = useState([]);
    const [preferenceTestQuestion, setPreferenceTestQuestion] = useState([]);
    const [screeningQuestions, setScreeningQuestions] = useState([]);
    const [filterOption, setFilterOption] = useState("Test");
    const [targetGroup, setTargetGroup] = useState({});
    const [noDemographics, setNoDemographics] = useState(true);

    const [test, setTest] = useState("");


    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const closeModal = () => {
        props.close();
    };

    useEffect(() => {
        if (props.test_id) {

            getTestApi();
        }
    }, [props.test_id]);

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'a'){

            admin_result_token = ReactSession.get("admin_result_token");
        }


        getTestData({ test_id: props.test_id,guest_result_token:guest_result_token,admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {

                if (response.test.target_group) {
                    setTargetGroup(response.test.target_group.project_target_group);

                    var target_group = response.test.target_group.project_target_group;

                    var no_demographics = true;

                    if(target_group){
                        if(target_group.min_age && target_group.min_age!=18){
                            no_demographics = false;
                        } else if(target_group.max_age && target_group.max_age!=90){
                            no_demographics = false;
                        } else if(target_group.gender && target_group.gender!="Any"){
                            no_demographics = false;
                        }else if(target_group.countries){
                            no_demographics = false;
                        } else if(target_group.nationalities){
                            no_demographics = false;
                        } else if(target_group.education){
                            no_demographics = false;
                        } else if(target_group.hours_spend_online){
                            no_demographics = false;
                        } else if(target_group.apps){
                            no_demographics = false;
                        } else if(target_group.devices){
                            no_demographics = false;
                        } else if(target_group.devices){
                            no_demographics = false;
                        } else if(target_group.employment){
                            no_demographics = false;
                        } else if(target_group.industry){
                            no_demographics = false;
                        } else if(target_group.department){
                            no_demographics = false;
                        }else if(target_group.household_members_condition){
                            no_demographics = false;
                        }else if(target_group.income_slab){
                            no_demographics = false;
                        }
                    }
                    setNoDemographics(no_demographics);
                }
                setTest(response.test);


                let briefingQuestions = [];
                let deBriefingQuestions = [];
                let surveyQuestions = [];
                let fiveSecondsQuestions = [];
                let preferenceQuestions = [];
                response.test.briefingQuestion.forEach((question) => {
                    briefingQuestions.push(JSON.parse(question));
                });
                response.test.debriefingQuestion.forEach((question) => {
                    deBriefingQuestions.push(JSON.parse(question));
                });
                response.test.surveyQuestion.forEach((question) => {
                    surveyQuestions.push(JSON.parse(question));
                });
                response.test.fiveSecondsTestQuestion.forEach((question) => {
                    fiveSecondsQuestions.push(JSON.parse(question));
                });

                response.test.preferenceTestQuestion.forEach((question) => {
                    preferenceQuestions.push(JSON.parse(question));
                });
                var screening_questions = [];

                response.test.screening_questions.forEach(function(item){
                    var que = JSON.parse(item.questions);

                    var options = [];

                    var option_ids = [];

                    var screen_options = [];

                    item.options.forEach(function(option){
                        options.push(option.option_value);
                        option_ids.push(option.id);
                        screen_options.push(option.screening);
                    });

                    screening_questions.push({
                        "id":item.id,
                        "questionType":item.question_type,
                        "question":que.question,
                        "options": options,
                        "screeningOptions":screen_options,
                        "options_ids":option_ids
                    });
                });

                setScreeningQuestions(screening_questions);

                console.log(response.test)


                setBriefingQuestion(briefingQuestions);
                setDeBriefingQuestion(deBriefingQuestions);
                setSurveyQuestion(surveyQuestions);
                setFiveSecondsQuestion(fiveSecondsQuestions);
                setPreferenceTestQuestion(preferenceQuestions);
                setFilterOption("Test");

            } else {
                showError(response.message);
            }
        });
    };
    const renderUAE = () => {
        var regions = [];

        targetGroup.countries.split(",").forEach(function (item) {

            emirates.forEach(function (emirate) {

                if (emirate.value == item) {
                    regions.push(item);
                }
            })
        })
        if (regions.length > 0) {
            return <div className="residents-summary-next-line">UAE residents from: {regions.join(", ")}</div>
        }
        return <></>
    }
    const renderKSA = () => {
        var regions = [];

        targetGroup.countries.split(",").forEach(function (item) {



            saregions.forEach(function (emirate) {

                if (emirate.value == item) {
                    regions.push(item);
                }
            })
        })
        if (regions.length > 0) {
            return <div className="residents-summary-next-line">KSA residents from: {regions.join(", ")}</div>
        }
        return <></>
    }
    const renderOtherCountries = () => {
        var regions = [];

        targetGroup.countries.split(",").forEach(function (item) {

            var exist = false;

            emirates.forEach(function (emirate) {

                if (emirate.value == item) {
                    exist = true;
                }
            })

            saregions.forEach(function (emirate) {

                if (emirate.value == item) {
                    exist = true;
                }
            })

            if (!exist) {
                regions.push(item)
            }
        })
        if (regions.length > 0) {
            return <div className="residents-summary-next-line">Residents from: {regions.join(", ")}</div>
        }
        return <></>
    }



    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                // console.log(event.target);
                // console.log(event.target.nodeName);
                // console.log("xyz");
                // console.log(ref.current);
                if (ref.current && !ref.current.contains(event.target)) {
                    // console.log("abc");
                    if(event.target.nodeName =="DIV"){
                        // console.log("abcd");
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }



    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={props.openModal}
            centered
            className="fade custom-modal-wrap  test-summary-modal-wrap"
        >
            <Modal.Body className={`${test.language === "ar" ? "arabic-summary" : ""}`} ref={wrapperRef}>



                <div className="test-summary-modal-data">

                    <div className="modal-header-top">
                        <Text type={"h3"}>Test summary</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                        </button>
                    </div>

                    {isLoading ?
                        <div className="modal-loader text-center">
                            <LoadingIcon />
                        </div>
                        :
                        <div className="summary-modal-data">
                            {!test || test.target_group && test.target_group.target_type == "target-group" &&
                            <div className="row summary-modal-data-repeat test-summary-filters">
                                <div className="col-md-12">
                                    <FilterButtons
                                        options={[
                                            "Test",

                                            `Participants  ${test.target_group && test.target_group.participants_selected ? "("+test.target_group.participants_selected+")":""}`,
                                        ]}
                                        selected={filterOption}
                                        onChange={(item) => {
                                            setFilterOption(item);
                                        }}
                                    />



                                </div>
                            </div>
                            }
                            {filterOption == "Test" &&
                            <>
                                <div className="row summary-modal-data-repeat">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Test name"} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {test.test_name} </Text>
                                        </div>
                                    </div>
                                </div>


                                <div className="row summary-modal-data-repeat">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Test language"} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'semi-bold-font'}> {test.language === "en" ? "English" : "Arabic"} </Text>
                                        </div>
                                    </div>
                                </div>



                                <div className="row summary-modal-data-repeat">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Methodology"} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'semi-bold-font'}> {test.methodology=="Five Seconds Test" ? "5 second test":test.methodology} </Text>
                                        </div>
                                    </div>
                                </div>

                                <div className="row summary-modal-data-repeat">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Welcome page"} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            <div className="row summary-walcome-page-data">

                                                <div className="col-md-6">
                                                    <div className="study-review-welcome-text">
                                                        <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>
                                                            {test.welcome_title}
                                                        </Text>

                                                        <div className="review-study-small-text">
                                                            <Text type={"body-text-2"} fontWeight={"normal-font"}>{test.welcome_description}</Text>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="uploaded-project-img study-review-image-wrap">
                                                        <img
                                                            src={
                                                                test.image
                                                                    ? process.env.REACT_APP_IMG_URL + test.image
                                                                    : process.env.REACT_APP_URL +
                                                                    "img/welcome-preview.svg"
                                                            }
                                                            alt="img"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>








                                <div className="row summary-modal-data-repeat">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={"medium-font"} children={"Introduction questions"} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            {briefingQuestion.length === 0 ?
                                                <p className={"info-not-available"}>You have not
                                                    added any briefing questions to your test </p>
                                                : ''
                                            }

                                            {briefingQuestion && briefingQuestion.map((question, index) => (
                                                <Question
                                                    index={index}
                                                    question={question}
                                                    key={"question-summary-"+index}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>


                                {test.methodology == "Tree Test" && (

                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={"medium-font"} children={"Tree testing"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className={`summary-right-sec-wrap tree-test-data-summary`}>

                                                {test.methodology === "Tree Test" &&
                                                test.tasks &&
                                                test.tasks.map((items, index) => {
                                                    return (

                                                        <div key={"tree-summary-task"+index} className="review-task-data-repeat tree-review-tasks">
                                                            <Text type={'body-text-3'} fontWeight={'semi-bold-font'}>Task {index + 1}</Text>
                                                            <Text type={'body-text-2'} fontWeight={"normal-font"}> {items.title}</Text>


                                                            <div className="the-correct-answer">
                                                                <Text type={'body-text-3'} fontWeight={'semi-bold-font'}>The correct answer</Text>
                                                            </div>

                                                            <div className="selected-answer-tree">
                                                                {items.correct_answer &&
                                                                items.correct_answer.map(
                                                                    (answer, index, rows) => {
                                                                        return (
                                                                            <span>
                                                                                                {index + 1 !== rows.length ?
                                                                                                    answer + " > "
                                                                                                    :
                                                                                                    answer
                                                                                                }
                                                                                            </span>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            {items.questions.length > 0 &&
                                                            <div className="prototype-summery-test-questions">
                                                                <Text cssClasses={'prototype-question-heading'} type={"subtitle-2"} fontWeight={"medium-font"}>
                                                                    Question
                                                                </Text>
                                                                {items.questions.map(function (question, index) {
                                                                    return (
                                                                        <Question
                                                                            index={index}
                                                                            question={question}
                                                                        />
                                                                    );
                                                                })

                                                                }
                                                            </div>
                                                            }
                                                        </div>

                                                    );
                                                })}

                                                {test.tasks.length === 0 &&
                                                <p className={"no-record"}>No tasks available</p>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                )}

                                {test.methodology == "Card Sorting" && (
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={"medium-font"}>
                                                    {test.card_sorting.card_sorting === "open" ? 'Open card sorting' : 'Closed card sorting'}
                                                </Text>
                                                <p className="category-summery-text body-text body-text-3 medium-font">
                                                    {test.card_sorting.card_sorting === "open" &&
                                                    " Participants create and name their own categories"}
                                                    {test.card_sorting.card_sorting === "close" &&
                                                    " Categories are predetermined by researchers"}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 summary-right">
                                            <div className={`summary-right-sec-wrap summary-cards-category-wrap`}>





                                                <div className="cardsort-cards-view">
                                                    <Text type={'subtitle-2'} cssClasses={'cardsort-task-title'} fontWeight={"medium-font"}>Task description</Text>

                                                    <Text type={'body-text-2'} cssClasses={'cardsort-task-title-data gray-color '} fontWeight={"medium-font"}>{test.card_sorting.title}</Text>

                                                    <div className={'mt-32 cardsort-cards-data'}>
                                                        <Text type={'body-text-3'} fontWeight={"semi-bold-font"}>Cards</Text>

                                                        <div className="active-tiles-wrap">
                                                            {test.card_sorting_card &&
                                                            test.card_sorting_card.map((items, index) => {
                                                                return (
                                                                    <Tag
                                                                        key={index}
                                                                        title={items.name}
                                                                        type="purple-tag"
                                                                    />
                                                                );
                                                            })}

                                                            {test.card_sorting_card.length === 0 &&
                                                            <p className={"no-record"}>No cards available</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {test.card_sorting.card_sorting === "close" && (
                                                    <>

                                                        <div
                                                            className="cardsort-cards-view">
                                                            <Text type={'body-text-3'} fontWeight={"semi-bold-font"}>Categories</Text>

                                                            {test.card_sorting_category &&
                                                            test.card_sorting_category.map(
                                                                (items, index) => {
                                                                    return (
                                                                        <Tag
                                                                            key={index}
                                                                            title={items.name}
                                                                            type="purple-tag"
                                                                        />
                                                                    );
                                                                }
                                                            )}

                                                            {test.card_sorting_category.length === 0 &&
                                                            <p className={"no-record"}>No category available</p>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {test.methodology == "Preference Test" && (

                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'medium-font'}>
                                                    Preference test
                                                </Text>
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className={`summary-right-sec-wrap summary-prefer-info-wrap`}>
                                                <Text type={"body-text-3"} fontWeight={'semi-bold-font'}>
                                                    Task
                                                </Text>
                                                <div className="task_title">{test.preference_test_setting.preference_test_title}</div>
                                                {test.preference_test_designs && (
                                                    <div className="preference-designs-repeat">
                                                        {test.preference_test_designs.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div key={"test-summary-design"+index} className="preference-test-design-wrap">
                                                                        <div
                                                                            className="prefer-design-image-view-hold">
                                                                            <div
                                                                                className="prefer-test-selected-design-view">
                                                                                <div className="added-design-img-thm">
                                                                                    <img
                                                                                        src={
                                                                                            process.env.REACT_APP_IMG_URL +
                                                                                            item.thumb
                                                                                        }
                                                                                    />
                                                                                </div>


                                                                            </div>

                                                                            <Text type={'body-text-2'} fontWeight={'medium-font'}>{item.title}</Text>

                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                )}

                                {test.methodology === "Preference Test" && (
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={"medium-font"} children={"Follow up questions"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className="summary-right-sec-wrap">
                                                {preferenceTestQuestion.length === 0 ?
                                                    <p className={"info-not-available"}>You have not
                                                        added any questions to your test </p>
                                                    : ''
                                                }

                                                {preferenceTestQuestion && preferenceTestQuestion.map((question, index) => (
                                                    <Question
                                                        index={index}
                                                        question={question}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {test.methodology === "Survey" && (
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={"medium-font"} children={"Survey questions"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className="summary-right-sec-wrap">
                                                {surveyQuestion.length === 0 ?
                                                    <p className={"info-not-available"}>You have not
                                                        added any questions to your test </p>
                                                    : ''
                                                }

                                                {surveyQuestion && surveyQuestion.map((question, index) => (
                                                    <Question
                                                        index={index}
                                                        question={question}
                                                        type="survey"
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {test.methodology == "Five Seconds Test" && (
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} children={"5 second test"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className="summary-right-sec-wrap">
                                                <Text type={"body-text-3"} fontWeight={"semibold-font"}>
                                                    Task
                                                </Text>
                                                <div className="tast-prefer-sub-text">
                                                    <Text type={'body-text-2'} fontWeight={'normal-font'}>{test.fiveSecondsTestTask.task}</Text>
                                                </div>

                                                {test.fiveSecondsTestTask.task_file ? (
                                                        <div className="preference-designs-repeat five_seconds_review_design fivesecond-summary">
                                                            <div className="preference-test-design-wrap">
                                                                <div
                                                                    className="prefer-design-image-view-hold">
                                                                    <div
                                                                        className="prefer-test-selected-design-view">

                                                                        <div className="added-design-img-thm">
                                                                            <img
                                                                                src={
                                                                                    process.env.REACT_APP_IMG_URL +
                                                                                    test.fiveSecondsTestTask.task_file
                                                                                }
                                                                            />
                                                                        </div>

                                                                        <Text type={'body-text-2'} fontWeight={'medium-font'}>{(test.fiveSecondsTestTask.design_title) ? test.fiveSecondsTestTask.design_title : 'Design 1'}</Text>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    <p className={"info-not-available"}>You have not
                                                        added any designs to your test </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {test.methodology === "Five Seconds Test" && (
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={"medium-font"} children={"Follow up questions"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className="summary-right-sec-wrap">
                                                {fiveSecondsQuestion.length === 0 ?
                                                    <p className={"info-not-available"}>You have not
                                                        added any questions to your test </p>
                                                    : ''
                                                }

                                                {fiveSecondsQuestion && fiveSecondsQuestion.map((question, index) => (
                                                    <Question
                                                        index={index}
                                                        question={question}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {test.methodology == "Prototype test" && (
                                    <div className="row summary-modal-data-repeat prototype-summary">
                                        <div className="col-md-6 summary-left prototype-summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Prototype test"} />
                                                <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                                    {test.prototype_test_setting.objective == "closed" ? "Task flow" : "Free flow"}
                                                </Text>

                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right prototype-summary-right">
                                            {test.prototype_test_tasks.map(function (task, index) {
                                                var objective = test.prototype_test_setting.objective;
                                                return (
                                                    <div className="proto-type-summery-repeat">
                                                        <div className="proto-type-summery-info-top">
                                                            <Text type={"subtitle-2"} fontWeight={"medium-font"}>
                                                                {objective == "closed" ? "Task " : " Flow "}{index + 1}
                                                            </Text>

                                                            <Text cssClasses={'light-dark-text'} type={"body-text-2"} fontWeight={"medium-font"}>
                                                                {task.figma_filename}
                                                            </Text>

                                                        </div>
                                                        {objective == "closed" &&
                                                        <DefineFlow
                                                            read_mode={true}
                                                            language={test.language}
                                                            start_screen={{
                                                                thumb: task.start_screen_thumb,
                                                                file: task.start_screen_thumb,
                                                                prototype_link: task.prototype_link,
                                                                version: task.version,
                                                                file_key: task.file_key,
                                                                file_name: task.figma_filename,
                                                                node_id: task.start_screen_node_id
                                                            }

                                                            }
                                                            goal_screen={task.goal_screen_thumb ? {
                                                                thumb: task.goal_screen_thumb,
                                                                file: task.goal_screen_thumb,
                                                                node_id: task.goal_screen_node_id
                                                            } : null}
                                                            objective={objective}
                                                            goal_screen_loading={false}
                                                            resync_loading={false}
                                                        />
                                                        }
                                                        {objective == "open" &&
                                                        <DefineFlow
                                                            read_mode={true}
                                                            language={test.language}
                                                            start_screen={{
                                                                thumb: task.start_screen_thumb,
                                                                file: task.start_screen_thumb,
                                                                prototype_link: task.prototype_link,
                                                                version: task.version,
                                                                file_key: task.file_key,
                                                                file_name: task.figma_filename,
                                                                node_id: task.start_screen_node_id
                                                            }

                                                            }
                                                            goal_screen={null}
                                                            objective={objective}
                                                            goal_screen_loading={false}
                                                            resync_loading={false}
                                                        />
                                                        }



                                                        {task.questions.length > 0 &&
                                                        <div className="prototype-summery-test-questions">
                                                            <Text cssClasses={'prototype-question-heading'} type={"subtitle-2"} fontWeight={"medium-font"}>
                                                                {objective == "closed" ? "Question " : "Question(s)"}
                                                            </Text>
                                                            {task.questions.map(function (question, index) {
                                                                return (
                                                                    <Question
                                                                        index={index}
                                                                        question={question}
                                                                    />
                                                                );
                                                            })

                                                            }
                                                        </div>
                                                        }
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                )}

                                {test.methodology == "First click" && (
                                    <div className="row summary-modal-data-repeat prototype-summary first-click">
                                        <div className="col-md-6 summary-left prototype-summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} children={"First click"} />

                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right prototype-summary-right">
                                            {test.firstclick_test_tasks.map(function (task, index) {
                                                return (
                                                    <div className="proto-type-summery-repeat">
                                                        <div className="proto-type-summery-info-top">
                                                            <Text type={"subtitle-2"} fontWeight={"medium-font"}>
                                                                Task {index + 1}
                                                            </Text>

                                                            <Text cssClasses={'light-dark-text'} type={"body-text-2"} fontWeight={"medium-font"}>
                                                                {task.description}
                                                            </Text>

                                                        </div>

                                                        <div className="preference-designs-repeat five_seconds_review_design fivesecond-summary">
                                                            <div className="preference-test-design-wrap">
                                                                <div
                                                                    className="prefer-design-image-view-hold">
                                                                    <div
                                                                        className="prefer-test-selected-design-view">

                                                                        <div className="added-design-img-thm">
                                                                            <img
                                                                                src={
                                                                                    process.env.REACT_APP_IMG_URL + task.thumb
                                                                                }
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>




                                                        {task.questions.length > 0 &&
                                                        <div className="prototype-summery-test-questions">
                                                            <Text cssClasses={'prototype-question-heading'} type={"subtitle-2"} fontWeight={"medium-font"}>
                                                                Question
                                                            </Text>
                                                            {task.questions.map(function (question, index) {
                                                                return (
                                                                    <Question
                                                                        index={index}
                                                                        question={question}
                                                                    />
                                                                );
                                                            })

                                                            }
                                                        </div>
                                                        }
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                )}



                                <div className="row summary-modal-data-repeat survey-summary-row">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={"medium-font"} children={"Conclusion questions"} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            {deBriefingQuestion.length === 0 ?
                                                <p className={"info-not-available"}>You have not
                                                    added any questions to your test </p>
                                                : ''
                                            }

                                            {deBriefingQuestion && deBriefingQuestion.map((question, index) => (
                                                <Question
                                                    index={index}
                                                    question={question}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>


                                <div className="row summary-modal-data-repeat">
                                    <div className="col-md-6 summary-left">
                                        <div className="summary-left-sec-wrap">
                                            <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Thank you"} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 summary-right">
                                        <div className="summary-right-sec-wrap">
                                            <div className="row summary-walcome-page-data">

                                                <div className="col-md-6">
                                                    <div className="study-review-welcome-text">
                                                        <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>
                                                            {test.thankyou_title}
                                                        </Text>

                                                        <div className="review-study-small-text">
                                                            <Text type={"body-text-2"} fontWeight={"normal-font"}>{test.thankyou_description}</Text>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="uploaded-project-img study-review-image-wrap">
                                                        <img
                                                            src={
                                                                test.conclusion_image
                                                                    ? process.env.REACT_APP_IMG_URL + test.conclusion_image
                                                                    : process.env.REACT_APP_URL +
                                                                    "img/welcome-preview.svg"
                                                            }
                                                            alt="img"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }


                            {filterOption ===  `Participants  ${test.target_group && test.target_group.participants_selected ? "("+test.target_group.participants_selected+")":""}` &&
                            <>
                                {test.target_group && test.target_group.is_demographics==1 &&
                                <>
                                    {!noDemographics &&
                                    <div className="test-summary-participants test-summary-screening-questions mb-32">
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Participants from UserQ panel"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {test.target_group.participants_selected}</Text>
                                                </div>
                                            </div>
                                        </div>
                                        {targetGroup.min_age &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Age Group"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.min_age} - {targetGroup.max_age} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.gender &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Gender"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.gender} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.countries &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Residency"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>
                                                        {renderUAE()}
                                                    </Text>
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>
                                                        {renderKSA()}
                                                    </Text>
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>
                                                        {renderOtherCountries()}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.nationalities &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Nationalities"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.nationalities.split(",").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.education &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Minimum level of education"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.education} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.hours_spend_online &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Average time spend online per day"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.hours_spend_online} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.apps &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"App used"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.apps.split(",").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.devices &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Favorite device"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.devices.split(",").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.employment &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Employment status"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.employment.split(",").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.industry &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Industry"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.industry.split("|").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.department &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Department"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {targetGroup.department.split(",").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.household_members_condition &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Members in household"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>  {targetGroup.household_members_condition == "All" ? "Exact" : targetGroup.household_members_condition} {targetGroup.people} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {targetGroup.income_slab &&
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Household income $ per month"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>  {targetGroup.income_slab.split("|").join(", ")} </Text>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    }
                                    {noDemographics && test.target_group && test.target_group.is_screening_questions==0 &&

                                    <div className="test-summary-participants test-summary-screening-questions mb-40">
                                        <div className="row summary-modal-data-repeat">
                                            <div className="col-md-6 summary-left">
                                                <div className="summary-left-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Participants from UserQ panel"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 summary-right">
                                                <div className="summary-right-sec-wrap">
                                                    <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {test.target_group.participants_selected}</Text>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="no-demographics-selected mt-32">
                                            <Text type={"body-text-2"} fontWeight={"normal-font"}>No demographic filters selected</Text>
                                        </div>
                                    </div>
                                    }
                                </>
                                }
                                {test.target_group && test.target_group.is_screening_questions==1 &&
                                <div className={`test-summary-participants test-summary-screening-questions screening_options_group summary-modal-data-repeat ${test.target_group && test.target_group.is_demographics==0 ? "only-screening-summary" : ""} `}>
                                    {noDemographics &&
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Participants from UserQ panel"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className="summary-right-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {test.target_group.participants_selected}</Text>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <Text cssClasses={"test-screening-questions-title mt-32"} type={"body-text-1"} fontWeight={"medium-font"}>Screening questions</Text>

                                    {screeningQuestions.map(function(question, index){
                                        return (
                                            <ScreeningQuestion
                                                index={index}
                                                question={question}
                                                language={test.language}
                                                read_mode={true}

                                            />
                                        );
                                    })}
                                </div>
                                }
                                {test.target_group && test.target_group.is_demographics==0 && test.target_group.is_screening_questions==0 &&
                                <div className="test-summary-participants test-summary-screening-questions mb-40">
                                    <div className="row summary-modal-data-repeat">
                                        <div className="col-md-6 summary-left">
                                            <div className="summary-left-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} children={"Participants from UserQ panel"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 summary-right">
                                            <div className="summary-right-sec-wrap">
                                                <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>   {test.target_group.participants_selected}</Text>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="no-demographics-selected mt-16">
                                        <Text type={"body-text-2"} fontWeight={"normal-font"}>No reruitment filters selected</Text>
                                    </div>
                                </div>
                                }
                            </>
                            }

                        </div>
                    }



                    <div className="summary-modal-close-btn-wrap">

                        <Button
                            type={"primary"}
                            size={"large"}
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        > Close
                        </Button>




                    </div>
                </div>


            </Modal.Body>
        </Modal>
    );
}
