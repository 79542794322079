import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { savePrototypeTesterInterctions } from "../../../../services/test_result";
import { removeItemFromArray, showToast } from "../../../../lib/helpers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import { PageProgressBar } from "../../../themes/userq/ProgressBar/page_progress_bar";
import { TextArea } from "../../../themes/userq/TextArea/TextArea";
import { Input } from "../../../themes/userq/Input/Input";
import { TesterQuestionOptions } from "../../../themes/userq/TesterQuestionOptions/TesterQuestionOptions";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton";
import { savePrototypeResultQuestion } from "../../../../services/test_result";
import RadiobtnRating from "./../../../layouts/elements/fields/radiobtnRating";
import ExitTestModal from "../../dialog/exit_test_modal";
import { validateResultData } from "../../../../services/test_result";
import { RatingScaleTester } from "../../../themes/userq/RatingScaleTester/RatingScaleTester";
import {TakeTestQuestionImage} from "../../../themes/userq/QuestionImage/TakeTestQuestionImage";

export default function PrototypeTestFreeFlow({ test, task, active_task, result, timeSpent, moveToNextTask, isPaidUser,onAlreadyTaken }) {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let user = ReactSession.get("user");

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [getStarted, setGetStarted] = useState(true);
    const [prototypeTask, setPrototypeTask] = useState(task);
    const travelledPath = useRef([]);
    const currentNode = useRef(null);
    const currentNodeclickMapData = useRef([]);
    const clickMapData = useRef([]);
    const [taskTimeSpent, setTaskTimeSpent] = useState(0);
    const [questionsSidebarOpen, setQuestionsSidebarOpen] = useState(false);
    const nodeTimeSpent = useRef(0);

    //followupquestions
    const [isActiveQuestion, setActiveQuestion] = useState(null);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [QuestionArrayList, setQuestionArrayList] = useState([]);
    const [formValues, setFormValues] = useState({
        type: null,
        question_id: null,
        answer: "",
        option_id: [],
        is_optional: 0,
    });
    const [formErrors, setFormErrors] = useState({ error: "", error_class: "" });
    const [questionLoading, setQuestionLoading] = useState(false);
    const [questionArray, setQuestionArray] = useState([]);
    const [questionTimeSpend, setQuestionTimeSpend] = useState(0);
    const [likertActive, setLikertActive] = useState(false);
    const [options, setOptions] = useState([]);
    const isActiveQuestionId = useRef(null);
    const isSkipped = useRef(0);
    const isOptional = useRef(false);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const skippedCount = useRef(0);
    const logicalySkipped = useRef(0);
    const isTyping = useRef(false);
    const [logicAdded, setLogicAdded] = useState(false);
    const nextButtonActive = useRef(false);
    const likertAttemptedSubQuestion = useRef(0);
    const otherOptionSelected = useRef(false);
    var questionType = "Survey";
    const questionsOpened = useRef(false);
    const [openExitTestModal, setOpenExitTestModal] = useState(false);

    // close exit test modal & rediect to home page if exits success
    const closeExitTestModal = (data) => {
        setOpenExitTestModal(false);
        if (data) {
            saveAbandonedTest(); // save abandoned test
            if (location.pathname.substr(1, 1) === 'p') {

                //navigate("/r/test/" + test.id + "/welcome");
                navigate("/");
            } else {
                if (!(ReactSession.get("token"))) {
                    navigate("/tester/sign-in");
                } else {
                    navigate("/");
                }

            }
        }
    };

    const saveAbandonedTest = () => {
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test && test.id);
        data.append("guest_token", ReactSession.get("guest_token"));
        data.append("validate", "abandoned");
        data.append("type", location.pathname.substr(1, 1));
        data.append("result_id", result && result.id);
        data.append("time_spent", timeSpent);

        setLoading(true);
        validateResultData(data, token).then((response) => {
            setLoading(false);

            if (response.success) {
                // console.log(window.history)
                navigate("/");
            } else {
                navigate("/");
            }
        });
    };

    useEffect(() => {
        setSidebarOpen(true);
        setGetStarted(true);
        setPrototypeTask(task);
        setLoading(false);
        travelledPath.current = [];
        currentNodeclickMapData.current = [];
        clickMapData.current = [];
        currentNode.current = null;

        setTaskTimeSpent(0);
        setQuestionTimeSpend(0);
        setQuestionsSidebarOpen(false);
        questionsOpened.current = false;
        setQuestionArray([]);
        loadQuestions();

    }, [active_task, task]);

    useEffect(() => {
        function tickTime() {
            nodeTimeSpent.current = nodeTimeSpent.current + 1;

            setTaskTimeSpent((prevSeconds) => prevSeconds + 1);
            setQuestionTimeSpend((prevSeconds) => prevSeconds + 1);
        }
        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    }, []);

    const saveTesterInteraction = (travelledPath) => {
        setLoading(true);

        clickMapData.current.push({ node: currentNode.current, data: currentNodeclickMapData.current, timespent: nodeTimeSpent.current });
        if (location.pathname.substr(1, 1) === "t") {
            let data = Object.assign({}, { path_travelled: travelledPath.toString() });
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;
            data["task_time_spent"] = taskTimeSpent;
            data["task_id"] = task.id;
            data["is_success"] = travelledPath[travelledPath.length - 1] == prototypeTask.goal_screen_node_id ? 1 : 0;
            data["click_map_data"] = clickMapData.current;
            savePrototypeTesterInterctions(data, ReactSession.get("token")).then(
                (response) => {
                    setLoading(false);
                    if (response.success) {
                        // moveToNextTask(active_task + 1);
                        console.log("interactions saved");
                    } else {
                        showToast(response.message, "error");
                    }
                }
            );
        } else {
            // moveToNextTask(active_task + 1);
            console.log("interactions saved");
        }
    };


    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
        var data = event.data;
        if (data.type == "MOUSE_PRESS_OR_RELEASE") {

            var clickednode = {
                // x: Math.round(data.data.nearestScrollingFrameMousePosition.x),
                // y: Math.round(data.data.nearestScrollingFrameMousePosition.y),
                x: Math.round(data.data.nearestScrollingFrameOffset ? data.data.nearestScrollingFrameOffset.x + data.data.nearestScrollingFrameMousePosition.x : data.data.targetNodeMousePosition.x),
                y: Math.round(data.data.nearestScrollingFrameOffset ? data.data.nearestScrollingFrameOffset.y + data.data.nearestScrollingFrameMousePosition.y : data.data.targetNodeMousePosition.y),
                value: 1
            }
            if (currentNodeclickMapData.current.length == 0) {
                currentNodeclickMapData.current.push(clickednode);
            } else {
                if (clickednode.x != currentNodeclickMapData.current[currentNodeclickMapData.current.length - 1].x) {
                    currentNodeclickMapData.current.push(clickednode);
                    // console.log(currentNodeclickMapData.current);
                }
            }

        }

        if (data.type == "PRESENTED_NODE_CHANGED" && data.data.presentedNodeId) {
            if (travelledPath.current.length == 0) {
                travelledPath.current.push(data.data.presentedNodeId);
                currentNode.current = data.data.presentedNodeId;
            } else {
                if (data.data.presentedNodeId != travelledPath.current[travelledPath.current.length - 1]) {
                    travelledPath.current.push(data.data.presentedNodeId);
                    clickMapData.current.push({ node: currentNode.current, data: currentNodeclickMapData.current, timespent: nodeTimeSpent.current });
                    currentNode.current = data.data.presentedNodeId;
                    currentNodeclickMapData.current = [];
                    nodeTimeSpent.current = 0;
                }
            }

        }
    }

    const loadQuestions = () => {
        let Questions = [];
        if (questionType === "Survey") {
            if (test && task.prototype_test_task_question.length) {
                setActiveQuestion(1);
                setTotalQuestion(test && task.prototype_test_task_question.length);

                test &&
                    task.prototype_test_task_question.forEach((items) => {
                        Questions.push({
                            data: items,
                            questionData: JSON.parse(items.questions),
                        });
                        if (JSON.parse(items.questions)["is_logic"] == 1) {
                            setLogicAdded(true);
                        }
                    });

                setQuestionArrayList(Questions);
                isOptional.current = Questions[0]['questionData']['is_optional'];
                const questionTypeData = Questions.filter(
                    (item, indexItem) => indexItem + 1 === 1
                )[0];
                if (questionTypeData.data.question_type === "singlechoice") {
                    setFormValues({ ...formValues, type: "single", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "multiplechoice") {
                    setFormValues({ ...formValues, type: "multiple", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "ratingscale") {
                    setFormValues({ ...formValues, type: "ratingscale", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "likertscale") {
                    setFormValues({ ...formValues, type: "likertscale", is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "rankingscale") {
                    let options = [];
                    questionTypeData.data.prototypetestoptions.forEach((option) => {
                        options.push(option.id);
                    });

                    setFormValues({ ...formValues, type: "rankingscale", question_id: questionTypeData.data.id, is_optional: questionTypeData.questionData.is_optional, answer: "", option_id: options, });
                    //nextButtonActive.current = true;
                } else {
                    setFormValues({ ...formValues, type: "free", is_optional: questionTypeData.questionData.is_optional });
                }
            } else {
                saveQuestionData([], true);
            }

        }
    }

    const handleSubmitQuestion = (e) => {
        e.preventDefault();
        setFormErrors({ error: "", error_class: "" });
        if (isActiveQuestionId.current == null) {
            isActiveQuestionId.current = QuestionArrayList[0]['data']['id']
        }
        if (isSkipped.current == 0) {
            if (formValues.is_optional == 1 && ((formValues.answer === "" && formValues.type !== "multiple" && formValues.type !== "rankingscale" && formValues.type !== "likertscale") || (formValues.type === "multiple" || formValues.type === "rankingscale") && formValues.option_id.length === 0) || (formValues.type === "likertscale" && formValues.option_id.length === 0)) {
                if (skippedCount.current < 2) {

                    if (formValues.is_optional == 0 && formValues.type == "likertscale") {
                        isSkipped.current = 0;
                    } else {
                        isSkipped.current = 1;
                        setFormValues({
                            ...formValues,
                            question_id: isActiveQuestionId.current,
                        });
                        skippedCount.current = skippedCount.current + 1;
                    }
                } else {
                    showToast("You can skip maximum of 2 questions.", "error");
                    return false;
                }
            }
        }

        if (isSkipped.current == 0) {
            if (formValues.answer === "" && formValues.type !== "multiple" && formValues.type !== "rankingscale" && formValues.type !== "likertscale") {
                setFormErrors({ error: "Required field", error_class: "input_error" });

                if ((formValues.type === "single") || (formValues.type === "ratingscale")) {
                    showToast("You need to select one answer before moving to the next question.", "error");
                }
                return false;
            }

            if ((formValues.type === "multiple" || formValues.type === "rankingscale") && formValues.option_id.length === 0) {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                showToast("You need to select one answer before moving to the next question.", "error");
                return false;
            }

            if ((formValues.type === "rankingscale") && !nextButtonActive.current) {
                return false;
            }
            if (formValues.type === "likertscale" && formValues.option_id.length === 0) {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                showToast("You need to answer before moving to the next question.", "error");
                return false;
            }
            else {
                if (formValues.option_id.length < formValues.totalSubQuestions) {
                    setFormErrors({ error: "Required field", error_class: "input_error" });
                    showToast("You need to answer for each subquestion.", "error");
                    return false;
                }
            }
            var other_selected = false;

            if((formValues.type === "multiple") ){
                formValues.option_id.forEach(function(option_id){

                    if(QuestionArrayList[isActiveQuestion-1].data.prototypetestoptions){
                        
                        QuestionArrayList[isActiveQuestion-1].data.prototypetestoptions.forEach(function(option){
                            if(option.id == option_id && ((test.language == "en" && option.option_value == "Other") || (option.option_value == "آخر" && test.language == "ar"))){
                                other_selected = true;
                            }
                        });
                    }
                });
            }
            if((formValues.type === "single") ){
                

                if(QuestionArrayList[isActiveQuestion-1].data.prototypetestoptions && formValues.answer){
                    
                    QuestionArrayList[isActiveQuestion-1].data.prototypetestoptions.forEach(function(option){
                        if(option.id == formValues.answer && ((test.language == "en" && option.option_value == "Other") || (option.option_value == "آخر" && test.language == "ar"))){
                            other_selected = true;
                        }
                    });
                }
                
            }
            if ((formValues.type === "multiple" || formValues.type === "single") && other_selected && formValues.other_option_value == "") {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                return false;
            }
        }

        // location scroll top
        if (isActiveQuestion < totalQuestion) {
            window.scrollTo(0, 0);
        }
        // check & activate next question
        formValues["question_time_spent"] = questionTimeSpend;
        formValues["is_skipped"] = isSkipped.current;
        if (isActiveQuestionId.current && logicalySkipped.current == "0") {
            formValues["question_id"] = isActiveQuestionId.current;
        }
        setQuestionTimeSpend(0);
        setQuestionArray([...questionArray, formValues]); // set data in array & make form value blank
        if (isActiveQuestion < totalQuestion) {
            var nextquestioncount = 1;
            if (formValues.type == "single") {
                const currentquestionData = QuestionArrayList.filter(
                    (item, indexItem) => indexItem + 1 === isActiveQuestion
                )[0];

                if (formValues.answer != "") {
                    const chosenoption = currentquestionData.data.prototypetestoptions.filter(
                        (item) => item.id == formValues.answer
                    )[0];



                    if (chosenoption.jump_to_question == "End Survey") {
                        saveQuestionData([...questionArray, formValues], false);
                        return false;
                    }
                    else if (chosenoption.jump_to_question != null) {
                        const jumpToQuestion = parseInt(chosenoption.jump_to_question);
                        nextquestioncount = jumpToQuestion - isActiveQuestionId.current;
                        logicalySkipped.current = 1;
                    }
                }
            }
            else {
                const currentquestionData = QuestionArrayList.filter(
                    (item, indexItem) => indexItem + 1 === isActiveQuestion
                )[0];

                if (currentquestionData.questionData['is_logic'] == 1) {
                    if (currentquestionData.data.jump_to_question == "End Survey") {
                        saveQuestionData([...questionArray, formValues], false);
                        return false;
                    }
                    else if (currentquestionData.data.jump_to_question != null) {
                        const jumpToQuestion = parseInt(currentquestionData.data.jump_to_question);
                        nextquestioncount = jumpToQuestion - isActiveQuestion;
                        logicalySkipped.current = 1;
                    }

                }
            }

            const questionTypeData = QuestionArrayList.filter(
                (item, indexItem) => indexItem + 1 === isActiveQuestion + nextquestioncount
            )[0];

            if (questionTypeData.data.question_type === "singlechoice") {
                var types = "single";
            } else if (questionTypeData.data.question_type === "multiplechoice") {
                var types = "multiple";
            } else if (questionTypeData.data.question_type === "ratingscale") {
                var types = "ratingscale";
            } else if (questionTypeData.data.question_type === "likertscale") {
                var types = "likertscale";
            } else if (questionTypeData.data.question_type === "rankingscale") {
                var types = "rankingscale";
            } else {
                var types = "free";
            }

            let options = [];
            if (types == "rankingscale") {

                isActiveQuestionId.current = questionTypeData.data.id;
                //nextButtonActive.current = true;

                questionTypeData.data.prototypetestoptions.forEach((option) => {
                    options.push(option.id);
                });

                setFormValues({
                    type: types,
                    question_id: questionTypeData.data.id,
                    is_optional: questionTypeData.questionData.is_optional,
                    answer: "",
                    option_id: options,
                });
            } else {
                setFormValues({
                    type: types,
                    question_id: null,
                    is_optional: questionTypeData.questionData.is_optional,
                    answer: "",
                    option_id: [],
                });
            }

            setActiveQuestion(isActiveQuestion + nextquestioncount);
            logicalySkipped.current = 0;
            otherOptionSelected.current = false;
            likertAttemptedSubQuestion.current = 0;
            setOptions([]);
            isSkipped.current = 0;
            if (types !== "rankingscale") {
                isActiveQuestionId.current = QuestionArrayList[isActiveQuestion]['data']['id'];
                nextButtonActive.current = false;
            } else {
                nextButtonActive.current = false;
            }
            isOptional.current = QuestionArrayList[isActiveQuestion]['questionData']['is_optional'];
        } else {
            saveQuestionData([...questionArray, formValues], false);
            saveTesterInteraction(travelledPath.current);
        }
    };

    const saveQuestionData = (formData, shouldNavigate) => {
        if (location.pathname.substr(1, 1) === "t") {
            let data = Object.assign({}, { questions: formData });
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["question_type"] = questionType;
            data["task_id"] = task.id;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;

            // cannot hit api multiple time if already ruuning api request
            if (questionLoading) {
                return false;
            }

            if (!shouldNavigate) {
                setQuestionLoading(true);
            }

            if (shouldNavigate) {
                // questionCallback(formData, questionType,false);
            }

            savePrototypeResultQuestion(data, ReactSession.get("token")).then(
                (response) => {
                    if (!shouldNavigate) {
                        setQuestionLoading(false);
                    }
                    if (response.success) {
                        setFormValues({
                            type: null,
                            question_id: null,
                            answer: "",
                            option_id: [],
                            is_optional: 0,
                        });
                        moveToNextTask(active_task + 1);

                    } else {
                        showToast(response.message, "error");

                    }
                }
            );
        } else {
            setFormValues({
                type: null,
                question_id: null,
                answer: "",
                option_id: [],
                is_optional: 0,
            });
            moveToNextTask(active_task + 1);
        }
    };
    const movetonextlikertsubquestion = (subquestionindex) => {
        setLikertActive(true);
        var nextquestionid = "subquestion-" + parseInt(subquestionindex + 1, 10);
        const item = document.getElementById(nextquestionid);
        item.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: '#CCCCCC',
            border: 'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: 'none',
            border: '1px dashed'
        });
    };



    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const runFunction = (e) => {

        if (
            e.target.type == 'text' || e.target.type == 'textarea'
        ) {
            // e.preventDefault();
            return;
        }
        else {
            // e.preventDefault();
            isTyping.current = false;
            return;
        }
    }
    const [showNav, setShowNav] = useState(false);
    const showRef = useRef(null);

    const toggleNav = () => {
        setShowNav(!showNav);
    };
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap tester-test-intro figma-mobile-prototype">
            <div className="prototype-test-wrap five-second-full-view-wrap prototype-test-fullscreen">
                {loading ?
                    <div className="loader_section full-page-loader prototype-loader">
                        <LoadingIcon />
                    </div>
                    :
                    <>
                        {sidebarOpen &&
                            <>

                                <div className="figma_prototype_sidebar prototypesidebar">
                                    <div className="sidebar-header">
                                        {test.language == "ar" ?
                                            <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text><Icon colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /></div>
                                            :
                                            <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Icon colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text></div>
                                        }
                                        <div className="contact-support-button">
                                            {user && user.role == "Tester" ?
                                                <div onClick={() => { navigate('/t/support/contact?reason=Problem with the platform'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                :
                                                <>
                                                    {user && user.role == "Researcher" ?
                                                        <div onClick={() => { navigate('/r/support/contact'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                        :
                                                        <a href={'https://userq.com/contact-us/'} target={"_blank"} className="contact-support-guestuser">
                                                            <div onClick={() => { }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                        </a>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="minimie-screen-icon" onClick={() => {
                                            setSidebarOpen(false);
                                            setGetStarted(false);
                                        }}>
                                            <Icon value="Minimize cards" size={"small"} hover={true} />
                                        </div>
                                    </div>
                                    <div className="figma_prototype_sidebar_body">
                                        <div className="figma-prototype-sidebar-body-inner">
                                            <Text type="h3" >{test.language == "ar" ? "التعليمات" : "Instructions"}</Text>
                                            <Text type="body-text-2" fontWeight="medium-font">{task.description}</Text>
                                            {getStarted ?
                                                <>{test.language == "ar" ?
                                                    <>{currentNode.current == null ?
                                                        <Button
                                                            type={"primary"}
                                                            size="large"
                                                            onClick={() => {
                                                                // setSidebarOpen(false);
                                                                // setGetStarted(false);
                                                            }}

                                                            label="جاري تحميل النموذج المبدئي"
                                                            microLoading={true}
                                                        />
                                                        :
                                                        <Button
                                                            type={"primary"}
                                                            size="large"
                                                            onClick={() => {
                                                                setSidebarOpen(false);
                                                                setGetStarted(false);
                                                                nodeTimeSpent.current = 0;
                                                            }}

                                                            label="لنبدأ"
                                                            iconRight={<Icon colorClass={"gray-50-svg"} value={"back-arrow"} size="medium" />}
                                                        />
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                        {currentNode.current == null ?
                                                            <div className="loading-prototype-button">
                                                                <Button
                                                                    type={"primary"}
                                                                    size="large"
                                                                    onClick={() => {
                                                                        // setSidebarOpen(false);
                                                                        // setGetStarted(false);
                                                                    }}

                                                                    label="Loading prototype"
                                                                    microLoading={true}
                                                                />
                                                            </div>
                                                            :
                                                            <Button
                                                                type={"primary"}
                                                                size="large"
                                                                onClick={() => {
                                                                    setSidebarOpen(false);
                                                                    setGetStarted(false);
                                                                    nodeTimeSpent.current = 0;
                                                                }}

                                                                label="Get started"
                                                                iconRight={<Icon colorClass={"gray-50-svg"} value={"forward-arrow"} size="medium" />}
                                                            />
                                                        }
                                                    </>
                                                }
                                                </>
                                                :
                                                <div className="free-flow-task-flow">
                                                    <Button
                                                        type={"secondary"}
                                                        size="large"
                                                        onClick={() => {
                                                            setSidebarOpen(false);
                                                        }}

                                                        label={test.language == "ar" ? "استمر في الاستكشاف" : "Keep exploring"}
                                                    />
                                                    {test.language == "ar" ?
                                                        <Button
                                                            type={"primary"}
                                                            size="large"
                                                            onClick={() => {
                                                                setSidebarOpen(false);
                                                                setQuestionsSidebarOpen(true);
                                                            }}

                                                            label="أنا جاهز للإجابة"
                                                            iconRight={<Icon colorClass={"gray-50-svg"} value={"back-arrow"} size="medium" />}
                                                        />
                                                        :
                                                        <Button
                                                            type={"primary"}
                                                            size="large"
                                                            onClick={() => {
                                                                setSidebarOpen(false);
                                                                setQuestionsSidebarOpen(true);
                                                            }}

                                                            label="I’m ready to answer"
                                                            iconRight={<Icon colorClass={"gray-50-svg"} value={"forward-arrow"} size="medium" />}
                                                        />
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className={`figma_prototype_design_screen_container ${(sidebarOpen || questionsSidebarOpen) ? "prototype-overlay-class" : ""}`}>
                            <div className="figma_prototype_design_screen">
                                <div id="heatmapContainer" className="prototype-iframe-overlay"></div>
                                <div id="prototype-iframe">
                                    <iframe
                                        id="figma_prototype_iframe"
                                        height="450"
                                        width="800"
                                        src={"https://embed.figma.com/proto/" + prototypeTask.file_key + "/" + prototypeTask.figma_filename + "?node-id=" + prototypeTask.start_screen_node_id + "&version-id=" + prototypeTask.version + "&starting-point-node-id=" + prototypeTask.start_screen_node_id + "&hide-ui=1&hotspot-hints=0&scaling=scale-down-width&disable-default-keyboard-nav=1&embed-host=userq&client-id="+process.env.REACT_APP_FIGMA_CLIENT_ID}
                                        allowfullscreen
                                    />
                                </div>
                            </div>
                            <div
                                className="open-nav-btn none-for-desktop"
                                onClick={toggleNav}
                            >
                                <span>
                                    <Icon
                                        value={"menu"}
                                        size={"medium"}
                                        color={"gray-50-svg"}
                                    />
                                </span>
                            </div>
                            {!(sidebarOpen || questionsSidebarOpen) &&
                                <>
                                    {showNav && (
                                    <div
                                        ref={showRef}
                                        className="figma_prototype_footer prototype-footer-mobile"
                                    >
                                        <div className="prototype-figma-footer-data none-for-desktop">
                                            <div
                                                className="close-nav"
                                                onClick={toggleNav}
                                            >
                                                <Icon
                                                    value={"close"}
                                                    size={"medium"}
                                                />
                                            </div>
                                            <Text type="caption">
                                                {test.language == "ar"
                                                    ? 'راجع التعليمات وتصفح النموذج الأولي. انقر فوق "أنا جاهز للإجابة" عندما تكون مستعدًا للإجابة على الأسئلة.'
                                                    : "Review the instructions and explore the prototype. Click “I’m ready to answer” when you are ready."}
                                            </Text>

                                            {test.language == "ar" ? (
                                                <Button
                                                    type={"secondary"}
                                                    size={"large"}
                                                    onClick={() => {
                                                        if (
                                                            currentNode.current !=
                                                            null
                                                        ) {
                                                            setSidebarOpen(
                                                                true
                                                            );
                                                            toggleNav();
                                                        }
                                                    }}
                                                    label="اقرأ التعليمات"
                                                    iconLeft={
                                                        <Icon
                                                            value={"eye"}
                                                            size="medium"
                                                        />
                                                    }
                                                    state={
                                                        currentNode.current ==
                                                        null
                                                            ? "disabled"
                                                            : ""
                                                    }
                                                />
                                            ) : (
                                                <Button
                                                    type={"secondary"}
                                                    size={"large"}
                                                    onClick={() => {
                                                        if (
                                                            currentNode.current !=
                                                            null
                                                        ) {
                                                            setSidebarOpen(
                                                                true
                                                            );
                                                            toggleNav();
                                                        }
                                                    }}
                                                    label="Read the instructions"
                                                    iconLeft={
                                                        <Icon
                                                            value={"eye"}
                                                            size="medium"
                                                        />
                                                    }
                                                    state={
                                                        currentNode.current ==
                                                        null
                                                            ? "disabled"
                                                            : ""
                                                    }
                                                />
                                            )}

                                            <Button
                                                type={"primary"}
                                                size={"large"}
                                                onClick={() => {
                                                    // console.log(travelledPath.current);
                                                    if (
                                                        currentNode.current !=
                                                        null
                                                    ) {
                                                        setQuestionsSidebarOpen(
                                                            true
                                                        );
                                                        toggleNav();
                                                        if (
                                                            questionsOpened.current ==
                                                            false
                                                        ) {
                                                            setQuestionTimeSpend(
                                                                0
                                                            );
                                                            questionsOpened.current = true;
                                                        }
                                                    }
                                                }}
                                                label={
                                                    test.language == "ar"
                                                        ? "أنا جاهز للإجابة"
                                                        : "I’m ready to answer"
                                                }
                                                state={
                                                    currentNode.current ==
                                                    null
                                                        ? "disabled"
                                                        : "active"
                                                }
                                            />
                                        </div>
                                    </div>
                                    )}
                                    <div className="figma_prototype_footer none-for-mobile">
                                        {test.language == "ar" ?
                                            <Button
                                                type={"secondary"}
                                                onClick={() => {
                                                    if (currentNode.current != null) {
                                                        setSidebarOpen(true);
                                                    }
                                                }}

                                                label="اقرأ التعليمات"
                                                iconLeft={<Icon value={"eye"} size="medium" />}
                                                state={currentNode.current == null ? "disabled" : ""}
                                            />
                                            :
                                            <Button
                                                type={"secondary"}
                                                onClick={() => {
                                                    if (currentNode.current != null) {
                                                        setSidebarOpen(true);
                                                    }
                                                }}

                                                label="Read the instructions"
                                                iconLeft={<Icon value={"eye"} size="medium" />}
                                                state={currentNode.current == null ? "disabled" : ""}
                                            />
                                        }
                                        <Text type="caption" >{test.language == "ar" ? 'راجع التعليمات وتصفح النموذج الأولي. انقر فوق "أنا جاهز للإجابة" عندما تكون مستعدًا للإجابة على الأسئلة.' : 'Review the instructions and explore the prototype. Click “I’m ready to answer” when you are ready.'}</Text>
                                        <Button
                                            type={"primary"}
                                            onClick={() => {
                                                // console.log(travelledPath.current);
                                                if (currentNode.current != null) {
                                                    setQuestionsSidebarOpen(true);
                                                    if (questionsOpened.current == false) {
                                                        setQuestionTimeSpend(0);
                                                        questionsOpened.current = true;
                                                    }
                                                }
                                            }}
                                            label={test.language == "ar" ? "أنا جاهز للإجابة" : "I’m ready to answer"}
                                            state={currentNode.current == null ? "disabled" : "active"}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        {questionsSidebarOpen &&
                            <div className={`figma_prototype_sidebar questions-sidebar ${formValues.type == "likertscale" ? "likert-question-wrap" : ""}`}>

                                <div className="prototype-take-test-top-hdr">
                                    <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Icon colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text></div>
                                    <div className="contact-support-button">
                                        {user && user.role == "Tester" ?
                                            <div onClick={() => { navigate('/t/support/contact?reason=Problem with the platform'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                            :
                                            <>
                                                {user && user.role == "Researcher" ?
                                                    <div onClick={() => { navigate('/r/support/contact'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                    :
                                                    <a href={'https://userq.com/contact-us/'} target={"_blank"} className="contact-support-guestuser">
                                                        <div onClick={() => { }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                    </a>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="minimie-screen-icon" onClick={() => {
                                        setQuestionsSidebarOpen(false);
                                    }}>

                                        <Icon colorClass="gray-900-svg" value="Minimize cards" size={"small"} hover={true} />
                                    </div>

                                </div>

                                <div className="figma_prototype_sidebar_body">
                                    <div className="taking-test-page-wrap" onClick={(e) => runFunction(e)}>
                                        <div className="container-1">
                                            <form onSubmit={(e) => handleSubmitQuestion(e)}>
                                                <div className="taking-test-page-height">
                                                    {QuestionArrayList &&
                                                        QuestionArrayList.map((items, index) => {
                                                            return (
                                                                <div className={`full ${items.questionData.questionType == "likertscale" ? "" : ""}`} key={index} >
                                                                    {isActiveQuestion === index + 1 && (
                                                                        <div className="full" key={index} >
                                                                            {items.questionData.questionType === "free" && (
                                                                                <div className="free-take-test-wrap medium-box-wrapper">
                                                                                    <div className="taking-test-progressbar">
                                                                                        <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                                    </div>

                                                                                    <div className="take-test-heading-wrap">
                                                                                        <Text type="h2">
                                                                                            {items.questionData.question}&nbsp;
                                                                                            {items.questionData.is_optional == true &&
                                                                                                <span className="optional-span">
                                                                                                    ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                                </span>
                                                                                            }
                                                                                        </Text>
                                                                                    </div>

                                                                                    <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                                    <div className="test-form-hold mt-40" onClick={() => {
                                                                                        isTyping.current = true;
                                                                                    }}
                                                                                    >
                                                                                        <TextArea
                                                                                            autosize={true}
                                                                                            value={formValues.answer == null
                                                                                                ? ""
                                                                                                : formValues.answer}
                                                                                            onChange={(e) => {
                                                                                                isActiveQuestionId.current = items.data.id;
                                                                                                if (e.target.value != " ") {
                                                                                                    setFormErrors(
                                                                                                        { error: "", error_class: "" }
                                                                                                    );
                                                                                                    setFormValues({
                                                                                                        ...formValues,
                                                                                                        question_id: items.data.id,
                                                                                                        answer: e.target.value,
                                                                                                    });
                                                                                                    if (!(e.target.value == "" || e.target.value == null)) {
                                                                                                        nextButtonActive.current = true;
                                                                                                    } else {
                                                                                                        nextButtonActive.current = false;
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            maxLength="300"
                                                                                            label={test.language == "en" ? "Type your answer here" : "جاوب هنا"}
                                                                                            language={test.language}
                                                                                            onClear={() => {
                                                                                                setFormValues({
                                                                                                    ...formValues,
                                                                                                    answer: "",
                                                                                                });
                                                                                            }}
                                                                                            wrapClass="tester-text-area"
                                                                                            error={formErrors.error}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            {items.questionData.questionType ===
                                                                                "singlechoice" && (
                                                                                    <div className="single-choice-take-test medium-box-wrapper">
                                                                                        <div className="taking-test-progressbar">
                                                                                            <PageProgressBar  language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                                        </div>

                                                                                        <div className="take-test-heading-wrap">
                                                                                            <Text type="h2">
                                                                                                {items.questionData.question}
                                                                                                &nbsp;
                                                                                                {items.questionData.is_optional == true &&
                                                                                                    <span className="optional-span">
                                                                                                        ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                                    </span>
                                                                                                }
                                                                                            </Text>
                                                                                        </div>

                                                                                        <div className="select-option-text-guide mt-24">
                                                                                            <Text type="body-text-1" fontWeight="medium-font">{test.language == "en" ? "Select 1 of the following" : "حدد خيار واحد"}</Text>
                                                                                        </div>

                                                                                        <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                                        <div className="test-form-hold mt-32">
                                                                                            <div className={`${test.language == "en" ? "singlechoice-option-wrap" : "singlechoice-option-wrap singlechoice-option-arabic-wrap"}`}>
                                                                                                {items.data.prototypetestoptions &&
                                                                                                    items.data.prototypetestoptions.map(
                                                                                                        (optionData) => {
                                                                                                            return (
                                                                                                                <div key={optionData.id}>
                                                                                                                    <TesterQuestionOptions
                                                                                                                        id={`option-select-${optionData.id}`}
                                                                                                                        type="singlechoice"
                                                                                                                        value={optionData.id}
                                                                                                                        optionImage={optionData.image && optionData.image.image_path}
                                                                                                                        label={optionData.option_value} isChecked={
                                                                                                                            formValues.answer ===
                                                                                                                                optionData.id
                                                                                                                                ? true
                                                                                                                                : false
                                                                                                                        }
                                                                                                                        onChange={(e) => {
                                                                                                                            isActiveQuestionId.current = items.data.id;
                                                                                                                            nextButtonActive.current = true;
                                                                                                                            if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                                                setFormValues({
                                                                                                                                    ...formValues,
                                                                                                                                    question_id: items.data.id,
                                                                                                                                    answer: parseInt(
                                                                                                                                        e.target.value
                                                                                                                                    ),
                                                                                                                                    other_option_value: "",
                                                                                                                                });
                                                                                                                                otherOptionSelected.current = true;
                                                                                                                            } else {
                                                                                                                                setFormValues({
                                                                                                                                    ...formValues,
                                                                                                                                    question_id: items.data.id,
                                                                                                                                    answer: parseInt(
                                                                                                                                        e.target.value
                                                                                                                                    ),
                                                                                                                                });
                                                                                                                                otherOptionSelected.current = false;
                                                                                                                                setFormErrors(
                                                                                                                                    { error: "", error_class: "" }
                                                                                                                                );
                                                                                                                            }
                                                                                                                            const item = document.getElementById("buttons");
                                                                                                                            item.scrollIntoView({
                                                                                                                                behavior: "smooth",
                                                                                                                                block: "center",
                                                                                                                            });
                                                                                                                        }} />

                                                                                                                    {formValues.answer === optionData.id && ((optionData.option_value == "Other" && test.language == "en") || (optionData.option_value == "آخر" && test.language == "ar")) &&
                                                                                                                        <div className="other-input-field">
                                                                                                                            <Input
                                                                                                                                label={test.language == "en" ? "Specify other" : "تحديد آخر"}
                                                                                                                                maxLength="100"
                                                                                                                                language={test.language}
                                                                                                                                value={formValues.other_option_value}
                                                                                                                                id="othervalue"
                                                                                                                                rtl={test.language == "en" ? false : true}
                                                                                                                                readOnly={false}
                                                                                                                                onChange={(e) => {
                                                                                                                                    if (e.target.value != " ") {
                                                                                                                                        setFormValues({
                                                                                                                                            ...formValues,
                                                                                                                                            other_option_value: e.target.value,
                                                                                                                                        });
                                                                                                                                        setFormErrors(
                                                                                                                                            { error: "", error_class: "" }
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                onClear={() => {
                                                                                                                                    setFormValues({
                                                                                                                                        ...formValues,
                                                                                                                                        other_option_value: "",
                                                                                                                                    });
                                                                                                                                }}
                                                                                                                                error={formErrors.error}
                                                                                                                                required={true}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            {items.questionData.questionType ===
                                                                                "multiplechoice" && (
                                                                                    <div className="single-choice-take-test medium-box-wrapper  multiple-choice-take-test">
                                                                                        <div className="taking-test-progressbar">
                                                                                            <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                                        </div>

                                                                                        <div className="take-test-heading-wrap">
                                                                                            <Text type="h2">
                                                                                                {items.questionData.question}&nbsp;
                                                                                                {items.questionData.is_optional == true &&
                                                                                                    <span className="optional-span">
                                                                                                        ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                                    </span>
                                                                                                }
                                                                                            </Text>
                                                                                        </div>
                                                                                        <div className="select-option-text-guide mt-24">
                                                                                            <Text type="body-text-1" fontWeight="medium-font">{test.language == "en" ? "Select 1 or more of the following" : "حدد خيار واحد أو أكثر من الخيارات أدناه"}</Text>
                                                                                        </div>

                                                                                        <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                                        <div className="test-form-hold mt-32">

                                                                                            <div className={`${test.language == "en" ? "singlechoice-option-wrap" : "singlechoice-option-wrap singlechoice-option-arabic-wrap"}`}>
                                                                                                {items.data.prototypetestoptions &&
                                                                                                    items.data.prototypetestoptions.map(
                                                                                                        (optionData) => {
                                                                                                            return (
                                                                                                                <div key={optionData.id}>
                                                                                                                    <TesterQuestionOptions
                                                                                                                        id={`custom-checks-${optionData.id}`}
                                                                                                                        type="multiplechoice"
                                                                                                                        value={optionData.id}
                                                                                                                        optionImage={optionData.image && optionData.image.image_path}
                                                                                                                        label={optionData.option_value}
                                                                                                                        isChecked={
                                                                                                                            formValues.option_id.includes(
                                                                                                                                optionData.id
                                                                                                                            )
                                                                                                                                ? true
                                                                                                                                : false
                                                                                                                        }
                                                                                                                        onChange={(e) => {
                                                                                                                            isActiveQuestionId.current = items.data.id;
                                                                                                                            if (e.currentTarget.checked) {

                                                                                                                                if ((test.language == "en" && optionData.option_value == "None of the above") || (optionData.option_value == "لا شيء مما بالأعلى" && test.language == "ar")){
                                                                                                                                    formValues.option_id =  [];
                                                                                                                                    formValues.option_id.push(
                                                                                                                                        parseInt(e.target.value)
                                                                                                                                    );
                                                                                                                                    setFormValues({
                                                                                                                                        ...formValues,
                                                                                                                                        question_id:
                                                                                                                                        items.data.id,
                                                                                                                                        option_id:
                                                                                                                                        formValues.option_id,
                                                                                                                                        is_none_above:parseInt(e.target.value),
                                                                                                                                        other_option_value: "",
                                                                                                                                    });
                                                                                                                                    otherOptionSelected.current=false;

                                                                                                                                }else {

                                                                                                                                    if(formValues.option_id.includes(formValues.is_none_above)){
                                                                                                                                        formValues.option_id = formValues.option_id.filter(item => item !== formValues.is_none_above)
                                                                                                                                    }

                                                                                                                                    formValues.option_id.push(
                                                                                                                                        parseInt(e.target.value)
                                                                                                                                    );
                                                                                                                                    if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                                                        setFormValues({
                                                                                                                                            ...formValues,
                                                                                                                                            question_id:
                                                                                                                                            items.data.id,
                                                                                                                                            option_id:
                                                                                                                                            formValues.option_id,
                                                                                                                                            other_option_value: "",
                                                                                                                                        });
                                                                                                                                        otherOptionSelected.current = true;
                                                                                                                                    } else {
                                                                                                                                        setFormValues({
                                                                                                                                            ...formValues,
                                                                                                                                            question_id:
                                                                                                                                            items.data.id,
                                                                                                                                            option_id:
                                                                                                                                            formValues.option_id,
                                                                                                                                        });
                                                                                                                                        otherOptionSelected.current = false;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            } else {
                                                                                                                                if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                                                    otherOptionSelected.current = false;
                                                                                                                                    setFormErrors(
                                                                                                                                        { error: "", error_class: "" }
                                                                                                                                    );
                                                                                                                                }
                                                                                                                                formValues.option_id =
                                                                                                                                    removeItemFromArray(
                                                                                                                                        formValues.option_id,
                                                                                                                                        optionData.id
                                                                                                                                    );
                                                                                                                                setFormValues({
                                                                                                                                    ...formValues,
                                                                                                                                    question_id:
                                                                                                                                        items.data.id,
                                                                                                                                    option_id:
                                                                                                                                        formValues.option_id,
                                                                                                                                });
                                                                                                                            }
                                                                                                                            if (formValues.option_id.length > 0) {
                                                                                                                                nextButtonActive.current = true;
                                                                                                                            } else {
                                                                                                                                nextButtonActive.current = false;
                                                                                                                            }
                                                                                                                        }} />

                                                                                                                    {formValues.option_id.includes(optionData.id) && ((optionData.option_value == "Other" && test.language == "en") || (optionData.option_value == "آخر" && test.language == "ar")) &&
                                                                                                                        <div className="other-input-field">
                                                                                                                            <Input
                                                                                                                                label={test.language == "en" ? "Specify other" : "تحديد آخر"}
                                                                                                                                maxLength="100"
                                                                                                                                language={test.language}
                                                                                                                                value={formValues.other_option_value}
                                                                                                                                id="othervalue"
                                                                                                                                rtl={test.language == "en" ? false : true}
                                                                                                                                readOnly={false}
                                                                                                                                onChange={(e) => {
                                                                                                                                    setFormValues({
                                                                                                                                        ...formValues,
                                                                                                                                        other_option_value: e.target.value,
                                                                                                                                    });
                                                                                                                                    setFormErrors(
                                                                                                                                        { error: "", error_class: "" }
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                                onClear={() => {
                                                                                                                                    setFormValues({
                                                                                                                                        ...formValues,
                                                                                                                                        other_option_value: "",
                                                                                                                                    });
                                                                                                                                }}
                                                                                                                                error={formErrors.error}
                                                                                                                                required={true}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            {/* Akshay: Added Rating Scale */}
                                                                            {(items.questionData.questionType === 'ratingscale') &&
                                                                                <div className="single-choice-take-test medium-box-wrapper rating-scale-take-test">
                                                                                    <div className="taking-test-progressbar">
                                                                                        <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                                    </div>

                                                                                    <div className="take-test-heading-wrap">
                                                                                        <Text type="h2">
                                                                                            {items.questionData.question}
                                                                                            &nbsp;
                                                                                            {items.questionData.is_optional == true &&
                                                                                                <span className="optional-span">
                                                                                                    ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                                </span>
                                                                                            }
                                                                                        </Text>
                                                                                    </div>

                                                                                    <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                                    <div className="test-form-hold mt-32 rating-options">
                                                                                        <div className={`${test.language == "en" ? "ratingscale-option-wrap" : "ratingscale-option-wrap ratingscale-option-arabic-wrap"}`}>

                                                                                            <RatingScaleTester
                                                                                                type={items.questionData.scale_type == "Emotions" ? "emoticons" : (items.questionData.scale_type == "Numeric" ? "numbers" : "stars")}
                                                                                                scale={items.questionData.scale_length == "3 points" ? 3 : (items.questionData.scale_length == "5 points" ? 5 : (items.questionData.scale_length == "7 points" ? 7 : 10))}
                                                                                                value={formValues.answer}
                                                                                                labels={items.questionData.labels}
                                                                                                onChange={(value) => {
                                                                                                    setFormValues({ ...formValues, question_id: items.data.id, answer: value })
                                                                                                    nextButtonActive.current = true;
                                                                                                }}
                                                                                            />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            <div className="likert-scale-taking-test-view">

                                                                                {items.questionData.questionType == "likertscale" && (
                                                                                    <>
                                                                                        <div className="taking-test-progressbar">
                                                                                            <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={test.language == "en" ? "Question" : "سؤال"} />
                                                                                        </div>

                                                                                        <div className="take-test-heading-wrap">
                                                                                            <Text type="h2">
                                                                                                {items.questionData.question}&nbsp;
                                                                                                {items.questionData.is_optional == true &&
                                                                                                    <span className="optional-span">
                                                                                                        ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                                    </span>
                                                                                                }
                                                                                            </Text>
                                                                                        </div>

                                                                                        <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                                        <div className="survey-rating-options-wrap likret-scale-top-row none-for-mobile ">
                                                                                            <div className="bd-question-radio-data w-100">
                                                                                                <div className="ans-list-repeat saved-ans-repeat w-100 mt-32">
                                                                                                    <div className="likert-option-left">
                                                                                                        <div className="ans-icon">&nbsp;</div>
                                                                                                        <div className="ans-data">&nbsp;</div>
                                                                                                    </div>
                                                                                                    <div className="likert-option-right">
                                                                                                        {items.data.prototypetestoptions.map((option, index) => (
                                                                                                            <label htmlFor="radio4" key={index}>
                                                                                                                <p>{option.option_value}</p>
                                                                                                            </label>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                <div className={`likert-innerview ${likertActive == true ? "likert-active-view" : ""}`}>
                                                                                    {items.questionData.questionType == "likertscale" && items.data.prototypetesttasksubquestions.map(
                                                                                        (subQuestion, subquestionindex) => (
                                                                                            <div className="ans-list-wrap likert-scale-option-add" key={subquestionindex} id={`subquestion-${subquestionindex}`}>
                                                                                                <div className="ans-list-repeat saved-ans-repeat">
                                                                                                    <div className="likert-option-left">
                                                                                                        <div className="ans-data">{subQuestion.subquestion}</div>
                                                                                                    </div>
                                                                                                    <div className="likert-option-right">
                                                                                                        <div className="survey-rating-options-wrap w-100">
                                                                                                            <div className="bd-question-radio-data w-100">
                                                                                                                <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio w-100">
                                                                                                                    {items.data.prototypetestoptions.map((option, index) => (
                                                                                                                        <>

                                                                                                                            <div className="likert-view-option-repeat" key={subquestionindex + '-' + index}>
                                                                                                                                <span
                                                                                                                                    onClick={(e) => {
                                                                                                                                        movetonextlikertsubquestion(subquestionindex);

                                                                                                                                    }
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <RadioButton
                                                                                                                                        id={`option-select-${subquestionindex + '-' + index}`}
                                                                                                                                        isSelectedlabelBold={true}
                                                                                                                                        name={`option-select-${subquestionindex}`}
                                                                                                                                        value={option.id}
                                                                                                                                        checked={
                                                                                                                                            formValues.option_id.forEach((subquestion) => {
                                                                                                                                                if ((subquestion.subquestionid === subQuestion.id) && (subquestion.optionid === option.id)) {
                                                                                                                                                    return true;
                                                                                                                                                }
                                                                                                                                            })

                                                                                                                                        }
                                                                                                                                        onChange={(e) => {
                                                                                                                                            isActiveQuestionId.current = items.data.id;
                                                                                                                                            likertAttemptedSubQuestion.current++;

                                                                                                                                            options.forEach((subquestion, index) => {
                                                                                                                                                if (subquestion.subquestionid === subQuestion.id) {
                                                                                                                                                    options.splice(index, 1);
                                                                                                                                                    likertAttemptedSubQuestion.current--;
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                            options.push({ subquestionid: subQuestion.id, optionid: option.id });

                                                                                                                                            var op = options.sort((a, b) => {
                                                                                                                                                if (a.subquestionid < b.subquestionid) {
                                                                                                                                                    return -1;
                                                                                                                                                }
                                                                                                                                            });

                                                                                                                                            setFormValues({
                                                                                                                                                ...formValues,
                                                                                                                                                question_id: items.data.id,
                                                                                                                                                option_id: op,
                                                                                                                                                totalSubQuestions: items.data.prototypetesttasksubquestions.length,
                                                                                                                                            });
                                                                                                                                            if (likertAttemptedSubQuestion.current >= items.data.prototypetesttasksubquestions.length) {
                                                                                                                                                nextButtonActive.current = true;
                                                                                                                                            } else {
                                                                                                                                                nextButtonActive.current = false;
                                                                                                                                            }

                                                                                                                                        }}
                                                                                                                                        label={option.option_value}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>

                                                                                                                        </>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>


                                                                            <div className="ranking-scale-taking-test-view medium-box-wrapper">

                                                                                {items.questionData.questionType ===
                                                                                    "rankingscale" && (
                                                                                        <>
                                                                                            <div className="taking-test-progressbar">
                                                                                                <PageProgressBar language={test.language} current_step={isActiveQuestion} total_steps={QuestionArrayList.length} variant={"take-test"} label={"Question"} />
                                                                                            </div>

                                                                                            <div className="take-test-heading-wrap">
                                                                                                <Text type="h2">
                                                                                                    {items.questionData.question}&nbsp;
                                                                                                    {items.questionData.is_optional == true &&
                                                                                                        <span className="optional-span">
                                                                                                            ({test.language == "en" ? "Optional" : "اختياري"})
                                                                                                        </span>
                                                                                                    }
                                                                                                </Text>
                                                                                            </div>

                                                                                            <div className="select-option-text-guide mt-24">
                                                                                                <Text type="body-text-1" fontWeight="medium-font">{test.language == "en" ? "Drag the items below to reorder them" : "ترتيب العناصر حسب الأهمية"}</Text>
                                                                                            </div>

                                                                                            <TakeTestQuestionImage type={'question'} url={items.questionData && items.questionData.image_url} />

                                                                                            <div className="test-form-hold">

                                                                                                <div className="ranking-data-wrap">
                                                                                                    <div className="rank-dragable-list-wrap" style={{ position: 'relative' }}>
                                                                                                        <DragDropContext
                                                                                                            onDragEnd={(param) => {
                                                                                                                setPlaceholderProps({});
                                                                                                                nextButtonActive.current = true;
                                                                                                                const srcI = param.source.index;
                                                                                                                const desI = param.destination.index;

                                                                                                                items.data.prototypetestoptions.splice(
                                                                                                                    desI,
                                                                                                                    0,
                                                                                                                    items.data.prototypetestoptions.splice(
                                                                                                                        srcI,
                                                                                                                        1
                                                                                                                    )[0]
                                                                                                                );


                                                                                                                let options = [];
                                                                                                                items.data.prototypetestoptions.forEach((option) => {
                                                                                                                    options.push(option.id);

                                                                                                                });
                                                                                                                isActiveQuestionId.current = items.data.id;
                                                                                                                setFormValues({
                                                                                                                    ...formValues,
                                                                                                                    question_id: items.data.id,
                                                                                                                    option_id: options,
                                                                                                                });
                                                                                                            }}
                                                                                                            onDragStart={handleDragStart}
                                                                                                            onDragUpdate={handleDragUpdate}
                                                                                                        >
                                                                                                            <Droppable droppableId="droppable-1">
                                                                                                                {(provided, snapshot) => (
                                                                                                                    <div className="w-100"
                                                                                                                        ref={
                                                                                                                            provided.innerRef
                                                                                                                        }
                                                                                                                        {...provided.droppableProps}
                                                                                                                    >
                                                                                                                        {items.data.prototypetestoptions.map(
                                                                                                                            (optionData, index) => (
                                                                                                                                <Draggable
                                                                                                                                    key={index}
                                                                                                                                    draggableId={
                                                                                                                                        "draggable-" +
                                                                                                                                        index
                                                                                                                                    }
                                                                                                                                    index={index}
                                                                                                                                >
                                                                                                                                    {(
                                                                                                                                        provided,
                                                                                                                                        snapshot
                                                                                                                                    ) => (

                                                                                                                                        <div
                                                                                                                                            className={`ranking-list-repeat saved-ans-repeat ${snapshot.isDragging ? 'draggable-element-start' : ''}`}
                                                                                                                                            ref={
                                                                                                                                                provided.innerRef
                                                                                                                                            }
                                                                                                                                            {...provided.draggableProps}
                                                                                                                                            {...provided.dragHandleProps}
                                                                                                                                        >
                                                                                                                                            <div className="ranking-count">
                                                                                                                                                <p >{('0' + (index + 1)).slice(-2)}</p>
                                                                                                                                            </div>

                                                                                                                                            <div
                                                                                                                                                className={`option-holder ${snapshot.isDragging ? 'draggable-inner-element-start' : ''}`}
                                                                                                                                                {...provided.dragHandleProps}
                                                                                                                                            >
                                                                                                                                                <Icon value="move object" />
                                                                                                                                                <Text display={"inline"} type={"body-text-2"}>{optionData.option_value}</Text>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </Draggable>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                        {provided.placeholder}
                                                                                                                        {!isEmpty(placeholderProps) && (
                                                                                                                            <div
                                                                                                                                className="placeholder"
                                                                                                                                style={{
                                                                                                                                    top: placeholderProps.clientY,
                                                                                                                                    left: placeholderProps.clientX,
                                                                                                                                    height: placeholderProps.clientHeight,
                                                                                                                                    width: placeholderProps.clientWidth,
                                                                                                                                    position: "absolute",
                                                                                                                                    borderColor: "#000000",
                                                                                                                                    background: placeholderProps.background,
                                                                                                                                    borderRadius: '10px',
                                                                                                                                    border: placeholderProps.border,
                                                                                                                                    margin: '0px 0px 10px'
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Droppable>
                                                                                                        </DragDropContext>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}

                                                </div>

                                                <div className={`taking-test-button-wrap proto-type-take-test-btn d-flex   ${isTyping.current == true ? 'keyboard-active-button' : ''} ${isOptional.current == true ? 'optional-button' : ''}`} id="buttons">
                                                    <Button type="secondary" size="large" label={test.language == "en" ? "Back to explore" : "العودة إلى التصفح"} iconLeft={<Icon colorClass={"gray-900-svg"} value={test.language == "ar" ? 'forward-arrow' : 'back-arrow'} />} onClick={() => {
                                                        setQuestionsSidebarOpen(false);
                                                        setFormValues({
                                                            ...formValues,
                                                            answer: "",
                                                            option_id: []
                                                        });
                                                    }} />
                                                    <Button type="primary" size="large" label={test.language == "en" ? "Next" : "التالي"} iconRight={<Icon colorClass={"gray-50-svg"} value={test.language == "ar" ? 'back-arrow' : 'forward-arrow'} />} state={nextButtonActive.current == true ? 'active' : 'disabled'} />
                                                    <div className="skipp-btn-option">
                                                        {isOptional.current == true &&
                                                            <Button type="ghost" size="medium" label={test.language == "en" ? "Skip" : "تخطي"} iconRight={<Icon colorClass={"gray-900-svg"} value={test.language == "ar" ? 'backward' : 'forward'} />} onClick={(e) => {
                                                                if (skippedCount.current < 2) {

                                                                    isSkipped.current = 1;
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        question_id: isActiveQuestionId.current,
                                                                    });
                                                                    handleSubmitQuestion(e);
                                                                    skippedCount.current = skippedCount.current + 1;
                                                                }
                                                            }}
                                                            />
                                                        }
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }

            </div>
            <ExitTestModal
                cssClass={(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode-modal' : ''}
                openExitTestModal={openExitTestModal}
                closeExitTestModal={closeExitTestModal}
                test={test}
                isPaidUser={isPaidUser}
            />
        </div>
    );
}