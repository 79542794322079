export const ImageCheckBoxes = ({options, selected, onChange})=>{

    return (
        <ul className="image-radio-btns-wrap">
            {options.map(function(option){
                return <ImageCheckBox onClick={()=>{
                    let selected_values = Object.assign([], selected);

                    var exist = false;

                    var newValues = [];

                    selected_values.forEach(function(value){
                        if(value==option.value){
                            exist= true;
                        } else {
                            newValues.push(value);
                        }
                    });
                console.log(newValues)
                    if(onChange){
                        if(!exist){
                            onChange([...selected_values,option.value]);
                        } else {
                            onChange(newValues);
                        }
                        
                    }
                }} selected={selected.includes(option.value)?true:false} key={"image-checkbox-"+option.value} value={option.value} image={option.image} />
            })}
        </ul>
    );
}
const ImageCheckBox = ({value, image, onClick, selected})=>{
    return (
    <li className={`image-checkbox-btns ${selected?"image-checkbox-btns-selected":""} `}  onClick={onClick}>
        <div className="img-checkbox-inner">
             <img src={process.env.REACT_APP_URL+"img/"+image.toLowerCase()} />
            <p>{value}</p> 
        </div>
    </li>);
}