import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
import { ReactSession } from '../../../lib/secure_reactsession';
import { showToast } from '../../../lib/helpers';
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import { getResearcherTestResultData, getResearcherTestResultOverviewData, getTestMethologyService } from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import {
    atom,
    useRecoilState,
} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import { TestResultsHeader } from '../../themes/userq/TestResultsHeader/TestResultsHeader';
import { PieStatsBox } from './components/pie_stats_box';
import { Button } from '../../themes/userq/Button/Button';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Text } from "../../themes/userq/Text/Text";
import TestSummaryModal from "../../dialog/test_summary_modal";
import { NoParticipants } from './components/no_participants';
import { ParticipantsNotAvailable } from './components/participants_not_available';
import { TreeTasksOverviewStatsBox } from './components/tree_tasks_overview_stats_box';
import { LinearAxisChartStatsBox } from './components/linear_axis_chart_stats_box';
import {renderTestTitle, roundNumber} from "../../../lib/helpers";
import { Box } from '../../themes/userq/Box/Box';
import { Helmet } from 'react-helmet';
import {Input} from "../../themes/userq/Input/Input";
import {saveTestResultGuestPasswordService} from "../../../services/test_result";



export default function TestResultOverview({isSharedLink}) {

    let { test_id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);

    const [test, setTest] = useState('');

    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isTyping, setTyping] = useState(false);
    const [isFormLoading, setFormLoading] = useState(false);
    const [formValues, setFormValues] = useState({'password':''});

    const [formErrors, setFormErrors] = useState({password:null, error_class:null});


    const getTest = () => {

            let token = ReactSession.get("token");

            setLoading(true);

            getTestMethologyService({ test_id: test_id }).then((response) => {
                setLoading(false);

                if (response.success) {

                    setTest(response.test);

                } else {

                    navigate("/dashboard");


                    showToast(response.message,'error')
                }
            });
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }


    const validateFullForm = (focus, key, value=null, inline=false) => {

        var error = false;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }

        if(focus){

            form_errors = resetFormErrors();
        }


        var firsterrorid = null;

        if(!key || key=="password"){

            var field_value = key && inline ? value : formValues.password;

            if (field_value == '' || field_value == null) {


                form_errors = {
                    ...form_errors,
                    password: "Required field",
                    error_class: "input_error"
                };

                error = true;
                firsterrorid = firsterrorid == null ? "password" : firsterrorid;
            }
        }
        if((inline && formErrors[key] ) || !inline){

            setFormErrors(form_errors);
        }
        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }

    const submitHandler = ()=>{

        setFormLoading(true);



        /*if (!ReactSession.get("guest_result_token")) {
            const randomToken = Math.random().toString(36).substr(2, 15);
            ReactSession.set("guest_result_token", randomToken);
        }*/

        const token = ReactSession.get("token");

        let data = Object.assign({}, '');
        data["test_id"] = test_id;
        data["password"] = formValues.password;
        //data["guest_result_token"] = ReactSession.get("guest_result_token");


        saveTestResultGuestPasswordService(data, token).then((response) => {

            setFormLoading(false);

            if(response){
                if (response.success) {

                    const randomToken = response.apikey;

                ReactSession.set("guest_result_token", randomToken);

                    navigate(`/v/results/${test_id}/overview`);

                } else {

                    showToast(response.message,'error')
                }
            }
        });
    }



    const callbackTopbar = (data) => {
        //getTest();
    }



    useEffect(() => {
        getTest();

    }, []);


    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >

            <Helmet>
                <title>Password {test && test.methodology ? " - "+renderTestTitle(test.methodology):""}  | UserQ</title>
            </Helmet>
            {test &&
            <div className="three-column-layout two-column-layout result">

                {sectionLoading &&
                <div className={'page-loader accountloading-box result-sections-loader'}>
                    <LoadingIcon />
                </div>
                }

                {!sectionLoading &&
                <div className="test-result-password">

                    <div className="test-result-password-inner text-center">

                            <Icon value="lock-purple" size="extra-large" />
                            <Text cssClasses={'password-protected mt-20'} type={'h4'}>Password protected</Text>
                            <Text type={'body-text-2'} cssClasses={'password-protected-invited mt-20 mb-20 gray-color'} fontWeight={'medium-font'}>
                                You are invited to see the result of <span className={'bold-font black-color'}>{test.test_name}</span> by <span className={'black-color bold-font'}>{test.user && test.user.email}</span>
                            </Text>
                            <Text type={'body-text-2'} cssClasses={'password-protected-enter gray-color'} fontWeight={'medium-font'}>
                                Please enter the password to proceed.
                            </Text>

                            <div className={'mt-32 mb-20'}>
                                <Input
                                    id="password"
                                    type="password"
                                    value={formValues.password}
                                    onChange={(e)=>{
                                        setFormValues({...formValues,password:e.target.value})
                                        // validateFullForm(false,'password')
                                        if(e.target.value){
                                            setTyping(true);
                                            setFormErrors({
                                                ...formErrors,
                                                password: "",
                                                error_class: "input_error"
                                            });
                                        }else{
                                            setTyping(false);
                                            setFormErrors({
                                                ...formErrors,
                                                password: "Required field",
                                                error_class: "input_error"
                                            });
                                        }
                                    }}
                                    autocomplete="password"
                                    required={true}
                                    label={"Password"}
                                    passwordVisible={passwordVisible}
                                    passwordInput={true}
                                    clearField={false}
                                    onBlur={()=>{validateFullForm(false,'password')}}
                                    onFocus={()=>{ setFormErrors({...formErrors, password:null}) }}
                                    passwordField={true}
                                    error={formErrors.password}
                                    error_class={formErrors.error_class}
                                />
                            </div>

                            <Button
                                type="primary" className="btn btn-block form-btn"
                                label={"Proceed"}
                                microLoading={isFormLoading}
                                onClick={(e)=>{
                                    (formValues.password)?submitHandler(true):validateFullForm(false,'password')
                                }}
                                state={(formValues.password)?"active":"disabled"}
                                size={"large"}
                            />

                    </div>

                </div>
                }

            </div>
            }

        </LayoutResearcher>
    )
}