import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
// import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
// import Stack from '@mui/material/Stack';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
// import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EventIcon from '@mui/icons-material/Event';



export const InputDatePicker = React.forwardRef(({ onChange, error, value, label, onBlur, readOnly }, ref) => {

    const [calendarValue, SetCalendarValue] = useState(value);
    useEffect(() => {
        SetCalendarValue(value)
    }, [value])
    var today = new Date();

    // const onKeyDown = (e) => {
    //     e.preventDefault();
    // };

    return (

        <div className={`date-picker-field ${error ? 'field-error' : ''} ${(calendarValue == null || calendarValue == "") ? 'inactive-field' : 'active-field'} ${readOnly==true?'read-only-field-date-picker':''}`}>
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="none-for-desktop diaplay-for-mobile dispaly-for-tablet">
                        <DatePicker
                            disableFuture
                            label={label}
                            openTo="day"
                            views={['year', 'month', 'day']}
                            minDate={new Date(new Date().setFullYear(today.getFullYear() - 90))}
                            maxDate={new Date(new Date().setFullYear(today.getFullYear() - 18))}
                            inputFormat="dd-MM-yyyy"
                            value={calendarValue}
                            onChange={(date) => {
                                console.log(date);
                                // if (date) {
                                console.log(date);
                                if (date != "Invalid Date" && !readOnly) {
                                    onChange(date);
                                    SetCalendarValue(date);
                                }
                                // }

                            }}
                            renderInput={(params) => <TextField {...params} onBlur={onBlur} error={false} color="secondary" focused ={false}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                                }}
                            />}
                            // components={{
                            //     OpenPickerIcon: CalendarTodayOutlinedIcon
                            // }}
                            defaultCalendarMonth={new Date(new Date().setFullYear(today.getFullYear() - 18))}
                        />
                    </div>
                    <div className="none-for-mobile none-for-tablet">
                        <DatePicker
                            disableFuture
                            label={label}
                            openTo="day"
                            views={['year', 'month', 'day']}
                            minDate={new Date(new Date().setFullYear(today.getFullYear() - 90))}
                            maxDate={new Date(new Date().setFullYear(today.getFullYear() - 18))}
                            inputFormat="dd-MM-yyyy"
                            value={calendarValue}
                            onChange={(date) => {
                                console.log(date);
                                // if (date) {
                                console.log(date);
                                if (date != "Invalid Date" && !readOnly) {
                                    onChange(date);
                                    SetCalendarValue(date);
                                }
                                // }

                            }}
                            renderInput={(params) => <TextField {...params} onBlur={onBlur} error={false} color="secondary" focused ={false}/>}
                            // components={{
                            //     OpenPickerIcon: CalendarTodayOutlinedIcon
                            // }}
                            defaultCalendarMonth={new Date(new Date().setFullYear(today.getFullYear() - 18))}
                        />
                    </div>
                </LocalizationProvider>
            </div>

            {error != null && (
                <span className="caption">
                    {error}
                </span>
            )}
        </div>

    )
});
InputDatePicker.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
    readOnly: PropTypes.bool,

};
InputDatePicker.defaultProps = {
    onChange: undefined,
    label: "Date of birth",
    value: "",
    readOnly: false

};
