import React, { useState, useEffect, useRef } from 'react';
import { ReactSession } from '../../lib/secure_reactsession';
import { Text } from '../themes/userq/Text/Text';
import { Icon } from '../themes/userq/Icon/Icon';
import { LayoutTester } from '../themes/userq/Layouts/layout_tester';

import { Accordian } from "../themes/userq/Accordion/Accordion";
import { getBlockedStatus } from '../../services/id_verification';
import { useNavigate } from 'react-router-dom';
import { LoadingIcon } from '../themes/userq/Loader/loadingIcon';
import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../data/settings';

export default function UnderAge() {

    const navigate = useNavigate();

    ReactSession.setStoreType("localStorage");

    const user = ReactSession.get("user");

    const [isLoading, setLoading] = useState(false);

    const [blockedData,setBlockedData] = useState({"days_left":null});


    const [accordianData, setAccordionData] = useState([
        {
            id: 1,
            open: false,
            title: 'Why is UserQ restricted for individuals under the age of 18?',
            info: "Your account has been blocked due to a tester score of 50% or lower or a violation of our <a class='faq-support-link' href='https://userq.com/terms-of-use/' target='_blank'>Terms of Use</a>."
        },
        {
            id: 2,
            open: false,
            title: 'When I can come back to use the platform?',
            info: 'You will be restricted from accessing any further tests on UserQ, your score will be lowered and you will not be able to request a payout from your wallet.'
        },
        {
            id: 3,
            open: false,
            title: 'Where can I find the terms of use and the privacy policy?',
            info: "You can reach out to our <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile' target='_blank'>support team</a> for advice on how to move forward."
        }
    ]);
    
    useEffect(() => {
        window.animate();
        document.title = "Under Age | "+process.env.REACT_APP_NAME;

        getBlockedStatusApi();

    }, []);
    const showError = (error) => {

        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const getBlockedStatusApi = () => {



        setLoading(true);

        const token = ReactSession.get("token");

        getBlockedStatus(token)
            .then(response => {
                setLoading(false);

                if (response.success) {
                   
                    if(response.account_status=="blocked"){
                        setBlockedData({...blockedData, days_left:response.days_left});
                    } else {
                       // navigate("/dashboard");
                    }
                   
                } else {
                    showError(response.message);
                }
            })


    }
    
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={true}
        >
            {isLoading &&
                    <div class="accountloading-box"> <LoadingIcon /></div>
            }
            {!isLoading &&
            <div className="dashboard-data-wrapper tester-dashboard disabled-account-dashboard">
                
                <div className="tester-disbled-account-body">
                    <div className="tester-disbled-account-notice">
                        <Icon colorClass={"danger-red-200"} value={"blocked"} size="large" />
                        <Text type={"h2"} children="Under Age" />
                        <Text type={"subtitle-1"}>
                            Based on the document provided, it appears that you are under 18 years old. Your account is blocked and will be deleted in {blockedData.days_left} day{blockedData.days_left>1?'s':''}.
                        </Text>
                        <Text type={"body-text-2"}>As per our <a className='faq-support-link disabled-support-link' target="_blank" href={process.env.REACT_APP_SITE_URL+"terms-of-use"}>Terms & Conditions</a>, minors are not allowed to create a tester profile on UserQ.com. If you are not a minor and believe this is an error, please <a className='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=ID Verification"} target="_blank">contact our support team</a>. Otherwise, your registered profile associated with {user && user.email} will be deleted within 30 days.</Text>
                    </div>
                    {/* 
                    <div className="tester-disbled-account-accordion">
                        
                        {accordianData.map((data, index) => (
                            <Accordian title={data.title} open={data.open}
                                onChange={() => {
                                    if (data.open == true) {
                                        accordianData[index].open = false;
                                    } else {
                                        accordianData[index].open = true;
                                    }
                                    setAccordionData([...accordianData]);
                                }}
                            >
                                <Text type="body-text-2" fontWeight="medium-font">
                                    <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                    </div>
                                </Text>
                            </Accordian>
                        ))}
                    </div>
                    */}
                </div>
            </div>
            }
        </LayoutTester>
    );
}