import React from 'react';
import { useState, useEffect } from "react";
import { LayoutTester } from '../themes/userq/Layouts/layout_tester';
import { Text } from '../themes/userq/Text/Text';
import { Icon } from '../themes/userq/Icon/Icon';
import SVG from 'react-inlinesvg';

export default function TesterGuidelines(props) {

    useEffect(() => {
        document.title = "Tester guidelines | "+process.env.REACT_APP_NAME;
    }, []);

    return (
        <LayoutTester
            isLoading={false}
            isAccount={true}
            skipCheck={false}
            activeMenu={""}
        >
            <div className='tester-guidelines-wrap'>
                <div className='section-1'>
                    <Text type="h1">Tester Guidelines</Text>
                    <Text type="body-text-2" fontWeight={"medium-font"}>At UserQ, we are committed to improving the usability and functionality of digital products in the MENA region. As a tester with UserQ, you play a vital role in helping us achieve this goal by providing high-quality responses and getting duly rewarded for your contributions.<br/>
                        To ensure that our data collection process is accurate and trust-worthy, we’ve adopted a tester scoring system that measures the quality of responses we receive on the platform.<br/>
                        Please read the following guidelines carefully to understand how the system works and how you can be a part of our top tier of testers that receive tests often.
                    </Text> 
                </div> 
                <div className='section-2'>
                    <Text type="h1">Tester Scoring System</Text>
                    <div className='section-2-intro'>
                        <Text type="body-text-2">Based on your performance on the platform, your tester score can range from 100% to 0%. The highest tester score is 100%. As a new tester on the platform, you are awarded a 100% score by default.
                            The test score is assessed by taking into consideration the number of tests you have taken, versus the number of tests you have been reported on for low quality or violations. Maintaining this high score will ensure you remain a part of our elite group of testers - which in turn gives you access to more tests.
                        </Text>
                        {/* <Icon value="guidelines-score-range" /> */}
                        <SVG
                                src={process.env.REACT_APP_URL+"img/"+"guidelines-score-range"+".svg"}
                                width={546}
                                height="auto"
                            />
                    </div>
                    <div className='review-process'>
                        <Text type="subtitle-1">
                            Responses review process
                        </Text>
                        <Text type="body-text-2">
                            We take the quality of data collected on our platform very seriously.  Every response you provide is manually reviewed by the researcher and our UserQ Quality Assurance team (QA team) to ensure that they are complete, accurate and do not violate our Terms of Use.
                            Bad quality responses could lead to your score being lowered and eventually reported for certain kinds of violations.
                        </Text>
                        <Text type="body-text-2">
                            In order to avoid this, we always advise our testers to keep these simple tips in mind when taking a test:
                        </Text>
                        <div className='review-process-instructions'>
                        <Text type="body-text-2">
                        <ul>
                            <li>Carefully read all instructions and questions before attempting to answer or complete a task</li>
                            <li>Take your time and avoid rushing through the test</li>
                            <li>Be clear and specific in your responses, provide as much detail as possible when asked to explain the reasoning behind your choice.</li>
                            <li>Be honest and objective in your feedback. Researchers are looking for honest opinions and insights, even if they are not always positive. </li>
                            <li>Avoid providing generic or unhelpful feedback. Try to provide specific, actionable suggestions for improvements where possible.</li>
                            <li>Do not share any confidential information or trade secrets from the product or website being tested.</li>
                            <li>Be respectful and professional in your responses on the platform. Any inappropriate behaviour or language will not be tolerated.</li>
                        </ul>
                        </Text>
                        </div>
                        <Text type="body-text-2">
                            Responses that are poor quality, contain profanity or breach our Terms of Use are reported by the researcher or our QA team. We internally review every report raised and if the claim is found to be valid, your score will be reduced. In some cases, based on our discretion, your account could also be suspended and your payout frozen.
                        </Text>
                    </div>
                    <div className="access-to-tests">
                        <Text type="subtitle-1">
                            Access to tests
                        </Text> 
                        <div className='access-to-tests-body'>
                            <Text type="body-text-2" fontWeight={"medium-font"}>
                            To ensure top quality responses on tests, our recruitment algorithm prioritises testers with high scores. Therefore, in order to increase your chances of being selected for future tests, it is important to maintain a high score.<br/>

If at any point your score falls below 50%, you will be blocked from taking any further tests and you will be required to get in touch with support and manual reviews may be required.
                            </Text> 
                            {/* <Icon value="guidelines-score" /> */}
                            <SVG
                                src={process.env.REACT_APP_URL+"img/"+"guidelines-score"+".svg"}
                                width={410}
                                height="auto"
                            />
                        </div>
                    </div>
                    <div className="profile-review">
                        <Text type="subtitle-1">
                            Profile review process
                        </Text>
                        <Text type="body-text-2" fontWeight={"medium-font"}>
                            We rely heavily on the demographic information that you provide in your profile to match you with the right testing opportunities. Any profile updates should be made with caution, as frequent changes to your profile information can be seen as suspicious and may result in a violation of our Terms & Conditions.
                            <br /><br />
                            It is also important to note that duplicate user accounts are strictly prohibited, as they can compromise the integrity of our demographic data and potentially impact the quality of our testing results.
                            <br /><br />
                            We take violations of our Terms & Conditions very seriously and monitor activity on our platform closely to maintain the accuracy and validity of our data. Any profile information that is found to be inaccurate or misleading may result in the suspension of your account and an immediate freeze on your payout.
                        </Text>
                    </div>
                </div>
                <div className='section-3'>
                    <Text type="h1">Payouts</Text>
                    <div className='section-3-intro'>
                        <div className='sec3-intro-left'>
                            <Text type="subtitle-2" fontWeight="semi-bold-font">A payout can be requested once you reach a balance of $30 in your wallet.</Text>
                            <Text type="body-text-2">Please note that you may need to provide a valid ID document when requesting a payout. To avoid any delays in the process, please make sure that the information provided in the document accurately matches your profile information. </Text>
                        </div>
                        <div className='sec3-intro-right'>
                            {/* <Icon value="guidelines-wallet" /> */}  
                            <SVG
                                src={process.env.REACT_APP_URL+"img/"+"guidelines-wallet"+".svg"}
                                width={400}
                                height="auto"
                            />
                        </div>
                    </div>
                    <Text type="body-text-2">
                        You may be asked to provide your ID in the following situations:
                    </Text>
                    <div className='payouts-instructions'>
                        <Text type="body-text-2">
                        <ul>
                            <li>When the bank account information you provided does not match your username and surname.</li>
                            <li>When your PayPal email address does not match your UserQ email address.</li>
                            <li>When we suspect that you have created multiple profiles, which is a violation of our Terms & Conditions and may result in your account being blocked.</li>
                        </ul>
                        </Text>
                        </div>
                    <Text type="body-text-2">
                        If you are unable to provide an ID document for our verification process, please note that we will not be able to process the payment on your behalf.<br />
                        These measures have been put in place to ensure that payouts are made to the correct individual and to prevent fraud/duplicate accounts, in line with our Terms & Conditions. <br />
                        It is your responsibility to ensure that all the information on your profile is up to date and accurate.
                    </Text>
                    <div>

                    </div>
                </div>

                <div className='orange-gradient-box'>
                    Remember that you are an important part of the research process taking place in the region and that your feedback can help improve products and services for everyone. Take pride in your work as a tester and do your best to provide high-quality feedback that will benefit both our researchers and the end-users.Thank you for being a part of our testing community!
                </div>
                <div className='guidelines-support-text'>
                    <Text type="body-text-2">
                        If you have any questions or concerns, please reach out to us at <a class='faq-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile"} target="_blank">support@userq.com</a>
                    </Text>
                </div>
            </div>
        </LayoutTester>
    )
}