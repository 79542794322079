import React, { useEffect } from "react";
import {useState} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export const RecruitmentSlider = ({onChange,value}) => {

   const range = [5,25,50,200,500,1000];

   const [sliderValue, setSliderValue] = useState(5);

    useEffect(()=>{
        calculateSliderValue();
    },[value]);
    const calculateSliderValue = ()=>{
        let slider_value = value;
        if(value <= 25){
            slider_value = value;
        }
        else if(value <= 50){
            slider_value = 25 + Math.ceil((20/25)*(value-25) );
        }
        else if(value <= 200){
            slider_value = 45 + Math.ceil((20/150)*(value-50));
        }
        else if(value <= 500){
            slider_value = 65 + Math.ceil((20/300)*(value-200));
        }else if(value <= 1000){
            slider_value = 85 + Math.ceil((20/500)*(value-500));
        }
        
        setSliderValue(slider_value);
    }
    const calculateValue = (slider_value)=>{
        let value = slider_value;
        if(slider_value <= 25){
            value = slider_value;
        }
        else if(slider_value <= 45){
            value = 25 + Math.ceil((25/20)*(slider_value-25));
        }
        else if(slider_value <= 65){
            value = 50 + Math.ceil((150/20)*(slider_value-45));
        }
        else if(slider_value <= 85){
            value = 200 + Math.ceil((300/20)*(slider_value-65));
        }else if(slider_value <= 105){
            value = 500 + Math.ceil((500/20)*(slider_value-85));
        }
        return value;
    }
    return (
        <div className="recruitmentSlider"
        
        >

                <Slider
                    range={false}
                    min={5}
                    max={105}
                    step={1}
                    value={sliderValue}
                    onChange={(value)=>{
                        if(onChange){
                            onChange(calculateValue(value));
                        }
                    }}
                    marks={{
                        5:"5",
                        25:"25",
                        45:"50",
                        65:"200",
                        85:"500",
                        105:"1000"
                      }}
                   
                />
                

                
        </div>        
    );
};
