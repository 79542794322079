import React from "react";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";

export const EmptySearchPlaceholder = ({clearSearch, cssClass, type}) => {
    return (
        <div className={`search-no-data-found ${cssClass}`}>
            <div className="search-no-data-inner">
                <Icon value={"Search"} size={"extra-large"} />
                <Text type="subtitle-2">No result found!</Text>
                <Text type="body-text-3">There is no result matching your search.</Text>
                
                <Button size="small" label={type ?  "Clear filter" : "Clear search"} onClick={clearSearch} />
            </div>
        </div>
    );
};