import { useState } from "react";
import { Button } from "../Button/Button";
import { Text } from "../Text/Text"
import { TextArea } from "../TextArea/TextArea";
import { Chip, Chips } from "../Chips/Chips";
import { useNavigate } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { sendFeedback } from "../../../../services/test";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { RatingScaleTester } from "../RatingScaleTester/RatingScaleTester";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings as globalsettings } from "../../../../data/settings";
import { toast } from "react-hot-toast";
import { Icon } from "../Icon/Icon";

export const NPS = ({onHide,role,openMenu,setPanelRate, section,setSection,...props})=>{

    const navigate = useNavigate();

    const [rate, setRate] = useState(null);

    const [toastError, setToastError] = useState(null);


    const [rateSelected, setRateSelected] = useState(false);


    const [opinion, setOpinion] = useState("");

    const [feedback, setFeedback] = useState(false);

    const tags= role=="tester"?["Interface & Navigation","Taking the test experience","Technical issues","Payouts & Rewards","Other"]:["Interface & Navigation", "Test creation functionalities","Technical issues","Pricing & Billing","Testers recruitment","Other"];


    const [formErrors, setFormErrors] = useState({opinion: null, error_class:null});

    const [isLoading, setLoading] = useState(false);

    const [selectedTags, setSelectedTags] = useState([]);

    var timeout_error;

    const checkBtnState = ()=>{
        
        if(!rate){
            return true;
        } else {

            if(rate <=3 && !opinion){
                return true;
            }
            if(rate <=3 && selectedTags.length==0){
                return true;
            }

        }
        return false;
    }
    const showError = (error) => {
       
        setToastError(error);

        if(timeout_error){
            clearTimeout(timeout_error);
        }
        timeout_error = setTimeout(() => {
            setToastError(null);
        }, globalsettings.toast_duration);
        
        
    };
    const renderPanelHeader = () =>{
        if(section){
            if(feedback){
                return <>
                    <div className='nps-back-btn align-items-center' onClick={()=>{setSection("menu")}}>
                        <Icon value="back-arrow" colorClass="gray-900-svg" size={"small"} /> Back
                    </div> 
                </>
            } else if(rateSelected){
                return <>
                    <div className='nps-back-btn align-items-center' onClick={()=>{setRateSelected(false); setFormErrors({...formErrors, opinion:null}); }}>
                        <Icon value="back-arrow" colorClass="gray-900-svg" size={"small"} /> Back
                    </div> 
                </>
            } else {
                return <>
                    <div className='nps-back-btn align-items-center' onClick={()=>{setSection("menu")}}>
                        <Icon value="back-arrow" colorClass="gray-900-svg" size={"small"} /> Back
                    </div> 
                </>
            }
        } else {
            return <></>
        }
        
        
       
    }
    const validateFullForm = (focus, key, value=null, inline=false) => {

        var error = false;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = {opinion:null, error_class:null};
        }

        
        var firsterrorid = null;

        if(!key){
            
            if (rate<=3 && selectedTags.length==0){
               
                error = true;

                firsterrorid = firsterrorid == null ? "tags-error" : firsterrorid;

                showError("Please select at least one reason");
            }
            
        }
        if(!key || key=="opinion"){

            
            var field_value = key && inline ? value : opinion;
            
            if (rate<=3 && (field_value == '' || field_value == null)){

                
                    form_errors = {
                        ...form_errors,
                        opinion: "This field is required",
                        error_class: "input_error"
                    };
                
                error = true;
                firsterrorid = firsterrorid == null ? "field-opinion" : firsterrorid;
            }
            
        }
        
        //alert(firsterrorid);

        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        if(focus){
            if (firsterrorid != null) {
                setTimeout(()=>{
                    document.getElementById(firsterrorid).scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                },50)
                
            }
        }

        return error;
    }
    const submitFeedback = ()=>{

        if(!isLoading){

            var error = validateFullForm(true);

            /*if(error){
                if(selectedTags.length==0){
                   
                }
            }*/
            
            if(!error){
                setLoading(true);
                
                const token = ReactSession.get("token");
                const data = new FormData();
                data.append("rating", rate);
                data.append("opinion", opinion);
                data.append("issues", selectedTags);

                sendFeedback(data, token).then((response) => {
                    setLoading(false);

                    if (response.success) {
                        if(setPanelRate){
                            setPanelRate(rate);
                        }
                        setFeedback(true);
                    } else {
                        showError(response.message);
                    }
                });
            }
        }
    }
    const renderSuccess = ()=>{
        if(rate<=2){
        return    <div className="mt-32 nps-feedback-thanks-info text-center"> 

                <Text type={"h3"} fontWeight={"normal-font"}>Thank you for sharing your feedback</Text>
                
                <Text type={"body-text-2"} cssClasses={"gray-text"} fontWeight={"medium-font"}>We're really sorry to hear you had such a lousy experience. Our team is all about giving awesome customer service, and we're all about making our users 100% happy. We'd love the chance to fix this mess and make things right for you.</Text>
                
                    <Button 
                        type={"primary"}
                        label={"Contact support"}
                        size={"large"}
                        onClick={()=>{
                            if(role=="tester"){
                                navigate("/t/support/contact");
                                onHide();
                            }else{
                                if(section){
                                    openMenu("contact-us")
                                    
                                } else {
                                    navigate("/r/support/contact");
                                }
                                //
                            }
                            
                        }}
                        cssclass={"w-100 mt-32"}
                    />
                
            </div>
        } else if(rate==3) {
            return    <div className="mt-32 nps-feedback-thanks-info text-center"> 
                <Text type={"h3"} fontWeight={"normal-font"}>Thank you for sharing your feedback</Text>
                <Text type={"body-text-2"}  cssClasses={"gray-text"} fontWeight={"medium-font"}>We really appreciate your feedback! At UserQ, we're all about using your suggestions to fine-tune our platform and make sure you have an even better experience down the road.</Text>
            </div>
        } else {
            return    <div className="mt-32 nps-feedback-thanks-info text-center"> 
                <Text type={"h3"} fontWeight={"normal-font"}>Thank you for sharing your feedback</Text>
                <Text type={"body-text-2"} cssClasses={"gray-text"} fontWeight={"medium-font"}>We really appreciate your feedback. 
We're committed to using your suggestions to improve our platform and give you a better experience down the road.</Text>
                    {role=="tester"?
                        <Button 
                            type={"primary"}
                            label={"Back to the dashboard"}
                            size={"large"}
                            onClick={()=>{
                                onHide()
                            }}
                            cssclass={"w-100 mt-32"}
                        />
                    :
                        <Button 
                            type={"primary"}
                            label={"Refer a friend"}
                            size={"large"}
                            onClick={()=>{
                                navigate("/r/invite-and-earn");
                                onHide()
                            }}
                            cssclass={"w-100 mt-32"}
                        />
                    }
                    
                
            </div>
        }
    }
    
    return (<div className="nps-section">
            {renderPanelHeader()}
        <div className="nps-feedback-heading mb-32 mt-16">
            <Text type={"subtitle-1"} cssClasses="purple-color" fontWeight={"medium-font"}>Share your feedback</Text>
        </div>
        {!feedback &&
        <div className="nps-feedback-info-text mb-32">
            <Text type={"h4"} cssClasses="mb-16">We would love to hear from you!</Text>
            {!rateSelected
            ?
            <Text type={"body-text-2"} cssClasses={"gray-text"} fontWeight={"medium-font"}>Your feedback makes UserQ the best it can be.<br/> How likely are you to recommend UserQ to a friend, colleague or peer?</Text>
            :
            (rate>3 ?
                <Text type={"body-text-2"} cssClasses={"gray-text"} fontWeight={"medium-font"}>We are really pleased to know you love UserQ. <br/>What is the most important reason you would recommend us?</Text>
                :
                <Text type={"body-text-2"} cssClasses={"gray-text"} fontWeight={"medium-font"}>Is there anything we can improve?</Text>
                )
            
            }
        </div>


        }
        {!rateSelected &&
        <div>
            <RatingScaleTester type={"emoticons"} scale={5} value={rate} onChange={(value)=>{setRate(value)}} />
        </div>
        }
        {rateSelected &&
        <div className="nps-selected-option mb-32">
            <RatingScaleIcon type={"emoticons"} scale={5} value={rate} />
        </div>
        }
        {!feedback &&
        <>
        
        {rateSelected && rate >=1 && rate<=3  &&
        <div className="nps-selected-chips mb-32">            
            <Chips>
                {tags.map(function(item){
                    return <Chip 
                        title={item} 
                        value={item} 
                        type={selectedTags.indexOf(item) !==-1? "selected":"default"}
                        onClick={(value)=>{
                            var selected_tags = Object.assign([], [...selectedTags]);

                            if(selectedTags.indexOf(value)==-1){
                                
                                selected_tags.push(value);
                            } else {
                                
                                selected_tags.splice(selectedTags.indexOf(value), 1);
                            }
                            setSelectedTags(selected_tags);
                        }}
                    />
                })}
            </Chips>   
        </div>
        }
        {rateSelected && rate &&
        <div className="nps-textarea">            
            <TextArea 
                id="field-opinion"
                autosize={true}
                label="Share your opinion"
                value={opinion}
                maxLength="300"
                onChange={(e)=>{ 
                    setOpinion(e.target.value);
                    validateFullForm(false, "opinion", e.target.value ? e.target.value:'',true)
                }}
                onBlur={()=>{validateFullForm(false,'opinion')}}
                onFocus={()=>{ setFormErrors({...formErrors, opinion:null}) }}
                error={formErrors.opinion}
                error_class={formErrors.error_class}
            />
        </div>
        }
        <div className="nps-feedback-btn mt-32">
        {rateSelected &&
        <Button 
            type={"primary"}
            label={!rate?"Submit":"Submit feedback"}
            size={"large"}
            microLoading={isLoading}
            state={checkBtnState()?"disabled":"active"}
            onClick={()=>{
                submitFeedback();
            }}
        />
        }
        {!rateSelected &&
        <Button 
            type={"primary"}
            label={"Submit"}
            size={"large"}
            microLoading={isLoading}
            state={!rate?"disabled":"active"}
            onClick={()=>{
                if(rate){
                    setRateSelected(true);
                }
            }}
        />
        }
        </div>
        </>
        }
        {feedback &&
        renderSuccess()
        }
        <div id="tags-error">
        {toastError &&
        <div className='mt-32 toast-error-sidebar'>
            <ToastMessage type={"error"} message={toastError} closable={false} onClose={() => { setToastError(null); }} />
        </div>
        }
        </div>
    </div>);
}