import React, { useEffect, useState ,useRef} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "../layouts/layout";
import { ReactSession } from "../../lib/secure_reactsession";
import { getResultData, validateResultData } from "../../services/test_result";

import Introduction from "./introduction";
import Password from "./password";
import ErrorPage from "./error_page";
import AlreadyTakenTest from "./already_taken_test";
import Question from "./question";
import ConclusionPage from "./conclusion_page";
import DebriefingPage from "./defriefing_page";
import toast from "react-hot-toast";
import ExitTestModal from "./dialog/exit_test_modal";
import Methodology from "./methodology/methodology";
import { decryptId, renderTestTitleCreate } from "../../lib/helpers";
import { TakingTestHeader } from "../themes/userq/Header/TakingTestHeader";
import { Footer } from "../themes/userq/Footer/Footer";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { Icon } from "../themes/userq/Icon/Icon";
import { TesterTestCard } from "../themes/userq/Test/TesterTestCard";
import { Text } from "../themes/userq/Text/Text";
import { Button } from "../themes/userq/Button/Button";
import { Accordian } from "../themes/userq/Accordion/Accordion";
import { load } from 'recaptcha-v3';
import { Helmet } from "react-helmet";
import {CheckBox} from "../themes/userq/CheckBox/CheckBox";
import HelpModal from "../themes/userq/Modal/HelpModal";

let captcha = null;

export default function Test() {
	let { test_id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const Ref = useRef(null);

	const [test, setTest] = useState("");
	const [result, setResult] = useState("");
	const [similarTest, setSimilarTest] = useState("");
	const [settings, setSettings] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isNda, setNda] = useState(false);
	const [testerNotMatchedInTargetGroup, setTesterNotMatchedInTargetGroup] = useState(false);
	const [testerLimitExceed, setTesterLimitExceed] = useState(false);
	const [errorType, setErrorType] = useState("general");
	const [openExitTestModal, setOpenExitTestModal] = useState(false);
	const [timeSpent, setTimeSpent] = useState(0);
	const [isBackButtonClicked, setBackbuttonPress] = useState(false);
	const [sessionAboutToExpireModal, setSessionAboutToExpireModal] = useState({open:false});
	const [sessionExpiredModal, setSessionExpiredModal] = useState({open:false});
	const [timeInSecondsRemaning, setTimeInSecondsRemaning] = useState(3600);

	const [questionType, setQuestionType] = useState("briefing");
	const [briefingQuestionArray, setBriefingQuestionArray] = useState([]);
	const [debriefingQuestionArray, setDebriefingQuestionArray] = useState([]);
	const [surveyQuestionArray, setSurveyQuestionArray] = useState([]);
	const [isActive, setActive] = useState("introduction");

	const [isAssignsCredits, setAssignsCredits] = useState(false);
	const [isPaidUser, setPaidUser] = useState(false);
	const [isRegisteredUser, setRegisteredUser] = useState(false);
	const [score, setScore] = useState(0);
	const [accountStatus, setAccountStatus] = useState("active");

	const [testGivenBy, setTestGivenBy] = useState(null);
	const [loginStatusChangeModal, setLoginStatusChangeModal] = useState({open:false});



	const aboutSessionToExpireDuration = 55*60*1000;

	var aboutSessionToExpireTimer;

	const sessionExpireDuration = 60*60*1000;

	var sessionExpireTimer;

	const [timer, setTimer] = useState('00:00');

	const [isShowSeesionTimeoutTimer, setShowSeesionTimeoutTimer] = useState(false);

	const [accordianData, setAccordionData] = useState([
		{
			id: 1,
			open: false,
			title: 'Why has my account been blocked?',
			info: "Your account has been blocked due to a tester score of 50% or lower or a violation of our <a class='faq-support-link' href='https://userq.com/terms-of-use/' target='_blank'>Terms of Use</a>."
		},
		{
			id: 2,
			open: false,
			title: 'What happens when my account is blocked?',
			info: 'You will be restricted from accessing any further tests on UserQ, your score will be lowered and you will not be able to request a payout from your wallet.'
		},
		{
			id: 3,
			open: false,
			title: 'How can I get my account unblocked?',
			info: "You can reach out to our <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile' target='_blank'>support team</a> for advice on how to move forward."
		}
	]);
    const generateCaptcha = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                //({ ...formValues, captcha: captcha });

                callback(token);

            })
        })
    }
	// is active type= introduction, password, 404Error, alreadyTakenTest, question

	// close exit test modal & rediect to home page if exits success
	const closeExitTestModal = (data) => {
		setOpenExitTestModal(false);
		if (data) {
			saveAbandonedTest(); // save abandoned test
			// if (location.pathname.substr(1, 1) === 'p') {
			//
			// 	//navigate("/r/test/" + test.id + "/welcome");
			// 	navigate("/");
			// } else {
			// 	if (!(ReactSession.get("token"))) {
			// 		navigate("/tester/sign-in");
			// 	} else {
			// 		navigate("/");
			// 	}
			//
			// }
		}
	};

	const getTestResultApi = () => {
		let token = ReactSession.get("token");
		//if(!token){
		if (!ReactSession.get("guest_token")) {
			const randomToken = Math.random().toString(36).substr(2, 15);
			ReactSession.set("guest_token", randomToken);
		}
		// }

		// check test given by logged User or guest
		if(token){
			setTestGivenBy('loggedInUser');
		}else{
			setTestGivenBy('guest');
		}

		setLoading(true);

		/*var regexBase64 =
			/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
		if (!regexBase64.test(test_id)) {
			setActive("404Error");
			setErrorType("404");
			return false;
		}*/

		generateCaptcha(function(captcha){
			getResultData(
				{
					test_id: test_id,
					type: location.pathname.substr(1, 1),
					guest_token: ReactSession.get("guest_token"),
					captcha: captcha
				},
				token
			).then((response) => {
				setLoading(false);
	
				if (response.success) {

					// check tester is givenn screening question or not if not then redirect to screening question
					if(response.roleTests === 'Tester'){

						if(location.pathname.substr(1, 1) == 't'){

							if(response.testerNotMatchedInTargetGroup==false && response.screeningpassed==false){
								// navigate("/dashboard?test="+response.test.id);
								navigate(`/t/screening/${response.test.id}`);
							}
						}


					}else{

						if(location.pathname.substr(1, 1) == 't') {
							if (response.screeningpassed == false) {

								navigate(`/t/screening/${response.test.id}`);
							}
						}
					}


					setTest(response.test);
					setResult(response.result);
					setSimilarTest(response.similar_test);
					setSettings(response.settings);
					setPaidUser(response.isUserPaid);
					setRegisteredUser(response.isUserRegistered);
					setScore(response.score);
					setAccountStatus(response.account_status);
					if(response.account_status=="silently_blocked"){
						setActive("404Error");
						return;
					}
					// save test id if test given by tester (After test given and sign up guest user will assign test automatically)
					if (!token) {
						ReactSession.set("test_result_id", response.result.id);
					}
	
					// user role tester not completed profile then redirect to Profile complete steps
					/*if(!response.testerCompletedProfile){
						navigate('/profile/step1');
					}*/
	
					setActive("introduction");
					if (location.pathname.substr(1, 1) === "p") {
						// check test is password protected or not
						if (response.test && response.test.password !== null) {
							//setActive("password");
						}
					} else {
						// check test is password protected or not
						if (response.result && !response.result.is_verify_password) {
							//setActive("password");
						} else if (response.result && response.result.is_test_completed) {
							setActive("alreadyTakenTest");
						}
					}
	
					// if user has not signed nda

					if (response.result && response.result.is_nda === 0) {

						setNda(true);


							ReactSession.set("isCheckNdaPopupNotOpened", 'true');

					}else{
						ReactSession.set("isCheckNdaPopupNotOpened", 'false');
					}
	
					setTesterNotMatchedInTargetGroup(response.testerNotMatchedInTargetGroup);
					setTesterLimitExceed(response.testerLimitExceed);
				} else {

					if(location.pathname.substr(1, 1) == 't'){

						if(response.screeningpassed==false){
							// navigate("/dashboard?test="+response.test.id);
							navigate(`/t/screening/${response.test_id}`);

							return;
						}
					}
					setActive("404Error");
				}
			});
		})

		
	};

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}

	const startTimer = (e) => {
		let { total, hours, minutes, seconds }
			= getTimeRemaining(e);
		if (total >= 0) {

			// update the timer
			// check if less than 10 then we need to
			// add '0' at the beginning of the variable
			setTimer(
				// (hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}

	const clearTimer = (e) => {

		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next
		setTimer('05:00');

		// If you try to remove this line the
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();

		// This is where you need to adjust if
		// you entend to add more time
		deadline.setSeconds(deadline.getSeconds() + 300);
		return deadline;
	}


	useEffect(() => {
		//document.title = "Take test | "+process.env.REACT_APP_NAME;

		getTestResultApi();

		// location scroll top
		window.scrollTo(0, 0);


		// show popup in 55 min spent on test
		aboutSessionToExpireTimer = setTimeout(() =>{
			setSessionAboutToExpireModal({open:true});
			clearTimer(getDeadTime());
			setShowSeesionTimeoutTimer(false);

			// close nda popup if open
			if(ReactSession.get("isCheckNdaPopupNotOpened")){
				setNda(false);
			}

		}, aboutSessionToExpireDuration);

		// show popup in 60 min spent on test
		sessionExpireTimer = setTimeout(() =>{
			setSessionAboutToExpireModal({open:false});

			setSessionExpiredModal({open:true});

			setShowSeesionTimeoutTimer(false);

			// close nda popup if open
			//if(isNda) {
				setNda(false);
			//}

		}, sessionExpireDuration);


		// set time taken by test
		function tickTime() {
			// var prevSeconds = prevSeconds + 1;
			setTimeSpent((prevSeconds) => prevSeconds + 1);
			setTimeInSecondsRemaning((prevSeconds) => prevSeconds - 1);
		}
		let timeData = setInterval(() => tickTime(), 1000);

		return () => clearInterval(timeData);


		// clearTimer(getDeadTime());
	}, [location.pathname.substr(3)]);

	const onClickReset = () => {
		 clearTimer(getDeadTime());
	}


	useEffect(() => {
		// show waring popup if user leave the meeting
		document.body.classList.add('taking-test-body');
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBackButtonEvent);
		window.addEventListener("beforeunload", onBackButtonEvent);

		//logic for showing popup warning on page refresh
		window.onbeforeunload = function () {
			setBackbuttonPress(true);
			return "Data will be lost if you leave the page, are you sure?";
		};
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
			window.removeEventListener("beforeunload", onBackButtonEvent);
		};
	}, ["location"]);

	// check while taking test user perform login or logout operation in new tab
	useEffect(()=>{

		let token = ReactSession.get("token");

		// user is logged in middle of test
		if(token && testGivenBy === 'guest'){
			// console.log('something went wrong guest')
			setLoginStatusChangeModal({open:true});

			setSessionAboutToExpireModal({open:false}); // close all below popup

			setSessionExpiredModal({open:false}); // close all below popup

			setNda(false); // close nda popup if open
		}

		// user is logged out middle of test
		if(!token && testGivenBy === 'loggedInUser'){
			// console.log('something went wrong logged out')

			setLoginStatusChangeModal({open:true});

			setSessionAboutToExpireModal({open:false}); // close all below popup

			setSessionExpiredModal({open:false}); // close all below popup

			setNda(false); // close nda popup if open
		}

	},[timeSpent]);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		if (!isBackButtonClicked) {
			if (window.confirm("Are you sure you want to leave the page without completing test?")) {
				if (location.pathname.substr(1, 1) === "t") {
					setBackbuttonPress(true);
					saveAbandonedTest();
				} else {
					navigate("/");
				}
			} else {
				window.history.pushState(null, null, window.location.pathname);
				setBackbuttonPress(false);
			}
		}
	};

	const saveAbandonedTest = () => {
		const token = ReactSession.get("token");
		const data = new FormData();
		data.append("test_id", test_id);
		data.append("guest_token", ReactSession.get("guest_token"));
		data.append("validate", "abandoned");
		data.append("type", location.pathname.substr(1, 1));
		data.append("result_id", result && result.id);
		data.append("time_spent", timeSpent);

		setLoading(true);
		validateResultData(data, token).then((response) => {
			setLoading(false);

			if (location.pathname.substr(1, 1) === 'p') {

				//navigate("/r/test/" + test.id + "/welcome");
				navigate("/");
			} else {
				if (!(ReactSession.get("token"))) {
					navigate("/tester/sign-in");
				} else {
					navigate("/");
				}

			}
		});
	};

	const passwordCallback = () => {
		setActive("introduction");

		// show nda popup after password validate
		if (result && result.is_nda === 0) {
			setNda(true);
		}
	};

	// introduction callback
	const introductionCallback = (checkNda) => {

		// if user has not signed nda
		if (result && result.is_nda === 0) {
			if (checkNda === false) {
				setNda(!isNda);


				return false;
			} else {
				if (test && test.methodology === "Surveyy") {
					setActive("tree");
				} else {
					setQuestionType("briefing");
					setActive("question");
				}
			}
		} else {
			if (test && test.methodology === "Surveyy") {
				setActive("tree");
			} else {
				setQuestionType("briefing");
				setActive("question");
			}
		}
	};

	// question callback
	const questionCallback = (formData, type, isAssignCredit) => {
		if (type === "briefing") {
			setQuestionType("debriefing"); // set question type debriefing and activate tree page
			setBriefingQuestionArray(formData);
			setActive("tree");
			setAssignsCredits(isAssignCredit);
		} else if (type === "debriefing") {
			setDebriefingQuestionArray(formData);
			setActive("conclusion");
			setAssignsCredits(isAssignCredit);
		} else if (type === "Survey") {
			setSurveyQuestionArray(formData);
			setActive("conclusion");
			setAssignsCredits(isAssignCredit);
		}
		else if (type === "Five Seconds Test Questions") {
			setActive("conclusion");
			setAssignsCredits(isAssignCredit);
		}
		else if (type === "Preference Test Followup Questions") {
			setActive("conclusion");
			setAssignsCredits(isAssignCredit);
		}
	};

	// tree callback function
	const methodologyCallback = () => {
		// if no debriefing question then save data and redirect to conclusion page
		if (test && test.debriefing_question.length === 0) {
			setActive("question");
		} else {
			setActive("debriefing");
		}
	};

	const debriefingCallback = () => {
		setActive("question");
	};

	// conclusion page callback
	const conclusionCallback = () => {
		// console.log('kjahdjksa')
		// console.log(briefingQuestionArray)
		// console.log(debriefingQuestionArray)
	};

	return (
		<>
			<Helmet>
				<title>{location.pathname.substr(1, 1) === "t" ? "Take Test" : "Preview "+ (test && test.methodology ? renderTestTitleCreate(test.methodology):" test")}  | UserQ</title>
			</Helmet>
			{isActive === "404Error" && <ErrorPage test={test} errorType={errorType} />}
			{isActive !== "404Error" && (
				<div
					className={`${test.language == "en"
							? isActive === "alreadyTakenTest" || isActive === "password"
								? "login-page-wrapper "
								: isActive === "debriefing"
									? "test-debriefingquestion-page-wrapper"
									: isActive === "conclusion"
										? "tester-login-page-wrapper test-thanks-page-wrapper"
										: " tester-login-page-wrapper"
							: isActive === "alreadyTakenTest" || isActive === "password"
								? "login-page-wrapper arabic_wrapper"
								: isActive === "debriefing"
									? "test-debriefingquestion-page-wrapper arabic_wrapper"
									: isActive === "conclusion"
										? "tester-login-page-wrapper test-thanks-page-wrapper arabic_wrapper"
										: " tester-login-page-wrapper arabic_wrapper"
						} ${location.pathname.substr(1, 1) === "p" ? ' tester-test-preview' : ''} ${document.querySelector('.figma_prototype_design_screen') !== null ? "figma-screens" : ""} ${document.querySelector('.first-click-image') !== null ? "first-click-design-screen" : ""}`}
				>
					{/* {isLoading && <div className="loader_page">Loading...</div>} */}
					{isLoading &&
						<div className="full-page-loader taking-test-full-page-loader">
							<LoadingIcon />
						</div>
					}
					{!isLoading && (
						<>
							{/* <div className="login-page-header tester-welcome-header">
								<div className="login-hdr-left none-for-mobile">
									<Link to={"/"} className="logo">
										<img
											src={process.env.REACT_APP_URL + "images/logo.svg"}
											alt="logo"
										/>
									</Link>
								</div>
								<div className="login-mid-sec">
								</div>
								<div className={`login-hdr-right taking-test-hdr-right`}>
									{test && isActive === "alreadyTakenTest" && (
										<Link className={"become-tester-btn"} to={"sign-in"}>Become a tester</Link>
									)}

									{test &&
										isActive !== "alreadyTakenTest" &&
										isActive !== "conclusion" && (
											<>
											<Link to={'/tester/support/contact?reason=Problem with the platform'} className="create-btn">
												{test.language=="en"?<>CONTACT SUPPORT</>:<>اتصل بالدعم</>}
												<span>
												<img
													src={process.env.REACT_APP_URL + "images/arrow-right.png"}
													alt="img"/>
											</span>
											</Link>
											<Link className={"exit-test"} to={"#"} onClick={() => setOpenExitTestModal(true)}>
												{test.language=="en"?<>Exit test</>:<>اخرج</>}
											</Link>
											</>
										)}
								</div>
							</div> */}

							{(process.env.REACT_APP_URL.includes("localhost")|| process.env.REACT_APP_URL.includes("apprevampui") || process.env.REACT_APP_URL.includes("appuat")) &&
								<div className="test-environment-header">This is a test environment</div>
							}

							{(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ?
								''
								:
								<>
									<TakingTestHeader test={test} exitText={() => {
										setOpenExitTestModal(true)
									}} />
								</>
							}

							{location.pathname.substr(1, 1) === "p" &&
								isActive !== "password" &&
								isActive !== "alreadyTakenTest" &&
								isActive !== "debriefing" && (
									<>
										<div className="profile-complete-progress-wrap notify-info d-flex justify-content-center">
											<div className="no-data-icon">
												<Icon colorClass={"gray-900-svg"} value={"attention"} size={"medium"} />
											</div>

											<Text type={"body-text-2"} fontWeight="medium-font">
												This is a preview. Your response will not be saved.
											</Text>
										</div>
									</>
								)}

							{accountStatus == "blocked" ?
								<div className="taking-test-page-height blocked-tester-taking-test-screen">
									<div className="dashboard-data-wrapper tester-dashboard disabled-account-dashboard">
										<div className="tester-disbled-account-body">
											<div className="tester-disbled-account-notice">
												<Icon value={"blocked-icon-red"} size="large" />
												{/* <Text type={"h2"} children="Important Notice"/> */}
												<Text type={"subtitle-1"} children="You cannot take a test while your profile is blocked." />
												<Text type={"body-text-2"}>Please get in touch with <a class='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile"} target="_blank">UserQ support.</a></Text>
											</div>
											<div className="tester-disbled-account-accordion">
												{accordianData.map((data, index) => (
													<Accordian title={data.title} open={data.open}
														onChange={() => {
															if (data.open == true) {
																accordianData[index].open = false;
															} else {
																accordianData[index].open = true;
															}
															setAccordionData([...accordianData]);
														}}
													>
														<Text type="body-text-2" fontWeight="medium-font">
															<div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
															</div>
														</Text>
													</Accordian>
												))}
											</div>
										</div>
										<a href={process.env.REACT_APP_URL + "t/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
											Support
										</a>
									</div>
								</div>
								:
								<>
									<div className={`card-sorting-test-mobile-view ${(test.methodology === 'Card Sorting' || (document.body.clientWidth < 768 && test.device === 'desktop')) ? 'hide-for-desktop' : 'd-none'} `}>
										<div className="cardsorting-mobile-inner-data">
											<div className="cardsorting-mobile-text">
												<Icon value={'desk-top-icon'} />
												<Text type={'subtitle-2'} fontWeight={'semi-bold-font'} children={test.language == "ar" ? "هذا الاختبار مُتاح على أجهزة الكومبيوتر فقط." : 'This test is available on desktop only.'} />
											</div>
											{test && test.language == "ar" ?
												<Button
													size={'large'}
													iconRight={<Icon colorClass={"gray-50-svg"} value={test && test.language == "ar" ? 'back-arrow' : 'forward-arrow'} />}
													label={test.language === 'en' ? "Back to dashboard" : "العودة إلى لوحة القيادة"}
													onClick={() => {
														let user = ReactSession.get("user");

														if(user && user.role === "Researcher" && test){
															navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
														} else {
															navigate('/dashboard');
														}
													}}
												/> :
												<Button
													size={'large'}
													iconLeft={<Icon colorClass={"gray-50-svg"} value={test && test.language == "ar" ? 'forward-arrow' : 'back-arrow'} />}
													label={test.language === 'en' ? "Back to dashboard" : "العودة إلى لوحة القيادة"}
													onClick={() => {
														let user = ReactSession.get("user");

														if(user && user.role === "Researcher" && test){
															navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
														} else {
															navigate('/dashboard');
														}
													}}
												/>
											}
										</div>

									</div>

									<div className={`device-desktop-view ${((document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) !== "p" || test.methodology !== 'Prototype test'))) ? 'hide-for-mobile' : 'd-none'} `}>
										<div className="cardsorting-mobile-inner-data">
											<div className="cardsorting-mobile-text">
												<Icon value={'mob-icon'} />
												<Text type={'subtitle-2'} fontWeight={'semi-bold-font'} children={test.language == "ar" ? "هذا الاختبار مُتاح على أجهزة الهاتف المحمول فقط." : 'This test is available on mobile only.'} />
											</div>
											{test && test.language == "ar" ?
												<Button
													size={'large'}
													iconRight={<Icon value={test && test.language == "ar" ? 'backward-arrow-white' : 'backward-arrow-white'} />}
													label={test.language === 'en' ? "Back to dashboard" : "العودة إلى لوحة القيادة"}
													onClick={() => {
														let user = ReactSession.get("user");

														if(user && user.role === "Researcher" && test){
															navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
														} else {
															navigate('/dashboard');
														}
													}}
												/> :
												<Button
													size={'large'}
													iconLeft={<Icon value={test && test.language == "ar" ? 'backward-arrow-white' : 'backward-arrow-white'} />}
													label={test.language === 'en' ? "Back to dashboard" : "العودة إلى لوحة القيادة"}
													onClick={() => {
														let user = ReactSession.get("user");

														if(user && user.role === "Researcher" && test){
															navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
														} else {
															navigate('/dashboard');
														}
													}}
												/>
											}
										</div>

									</div>
									{/*<div className="card-sorting-test hide-for-mobile">*/}

									{/*</div>*/}

									<div className={`
							${(test.methodology === 'Card Sorting') ? 'hide-for-mobile' : ''}
							 ${(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) !== "p" || test.methodology !== 'Prototype test')) ? 'hide-for-desktop' : ''} 
							 ${(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode' : ''} 
							 ${(document.body.clientWidth < 768 && test.device === 'desktop') ? 'hide-for-mobile' : ''}
							 fivetestmain-box fullheight-minusHF`}>

										{(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ?
											<TakingTestHeader test={test} exitText={() => {
												setOpenExitTestModal(true)
											}} />
											:
											''
										}

										<div className={`${(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test'))?'prototype-test-preview-for-desktop-mode-combined-data':''}`}>

											{isActive === "introduction" && (
												<Introduction
													introductionCallback={introductionCallback}
													timeSpent={timeSpent}
													test={test}
													result={result}
													isNda={isNda}
													testerNotMatchedInTargetGroup={testerNotMatchedInTargetGroup}
													testerLimitExceed={testerLimitExceed}
													isPaidUser={isPaidUser}
													isRegisteredUser={isRegisteredUser}
													score={score}
												/>
											)}

											{isActive === "password" && (
												<Password
													passwordCallback={passwordCallback}
													timeSpent={timeSpent}
													test={test}
													result={result}
												/>
											)}

											{isActive === "alreadyTakenTest" && <AlreadyTakenTest test={test} />}

											{isActive === "question" && (
												<Question
													test={test}
													result={result}
													timeSpent={timeSpent}
													questionCallback={questionCallback}
													questionType={questionType}
												/>
											)}
											{isActive === "tree" && (
												<Methodology
													test={test}
													result={result}
													timeSpent={timeSpent}
													methodologyCallback={methodologyCallback}
													questionCallback={questionCallback}
													isPaidUser={isPaidUser}
													onAlreadyTaken={()=>{
														setActive("alreadyTakenTest")
													}}
												/>
											)}

											{isActive === "debriefing" && (
												<DebriefingPage test={test} debriefingCallback={debriefingCallback} />
											)}

											{isActive === "conclusion" && (
												<ConclusionPage
													test={test}
													similarTest={similarTest}
													settings={settings}
													isAssignsCredits={isAssignsCredits}
													conclusionCallback={conclusionCallback}
												/>
											)}

											{(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ?
												<Footer />
												:
												''
											}
										</div>
									</div>
								</>
							}

							{isShowSeesionTimeoutTimer &&
							<div className={'seesion-timeout-time-take-test d-flex cursor-pointer'} onClick={()=>{
								setSessionAboutToExpireModal({open:true});
								setShowSeesionTimeoutTimer(false);
							}
							}>
								<Icon colorClass={"gray-50-svg"} value={'time'} size={'large'} />
								<Text type={'body-text-1'} cssClasses={'timer-text'} fontWeight={'medium-font'}>
									{timer}
								</Text>
							</div>
							}


							{(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ?
								''
								:
								<Footer />
							}

						</>
					)}



					<ExitTestModal
						cssClass={(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode-modal' : ''}
						openExitTestModal={openExitTestModal}
						closeExitTestModal={closeExitTestModal}
						test={test}
						isPaidUser={isPaidUser}
					/>

					<HelpModal
						open={sessionAboutToExpireModal.open}
						cssClass={"taking-test-session-about-to-expire-modal"}
						close={()=>{
							setSessionAboutToExpireModal({...sessionAboutToExpireModal, open:false});
							setShowSeesionTimeoutTimer(true);

							// check user has not signed NDA
							// console.log(ReactSession.get("isCheckNdaPopupNotOpened"))
							 if(ReactSession.get("isCheckNdaPopupNotOpened") === true){
								setNda(true);
							 }
						}}
						heading={"Session is about to expire"}
						body={
							<div className="taking-test-session-about-to-expire-modal-data">

								<div className={'timer-data d-flex align-items-center'}>
									<Icon value={'time'} size={'extralarge'} />
									<Text type={'body-text-1'} cssClasses={'color-black ml-16'} fontWeight={'medium-font'}>
										{timer}
									</Text>
								</div>
								<Text type={'body-text-2'} cssClasses={'gray-color mt-20'} fontWeight={'medium-font'}>
									Your session is going to time out soon. Please ensure to complete your test before the session expires.
								</Text>

							</div>

						}
					/>


					<HelpModal
						open={sessionExpiredModal.open}
						cssClass={"taking-test-session-expired-modal"}
						close={()=>{
							setSessionExpiredModal({...sessionExpiredModal, open:false});
							closeExitTestModal(true);
							if(ReactSession.get("token")){
								navigate('/dashboard');
							}else{
								navigate('/tester/sign-in');
							}
						}}
						heading={"Session expired"}
						body={
							<div className="taking-test-session-expired-modal-data">

								<Text type={'body-text-2'} cssClasses={'gray-color'} fontWeight={'medium-font'}>
									Your session has timed out. Any answers or actions made during this period will not be saved. Click "<b className={'color-black semi-bold-font'}>Back to Dashboard</b>" to explore new tests.
								</Text>

								<Button
									type="primary"
									size="large"
									cssclass={'w-100 mt-20'}
									label={"Back to dashboard"}
									onClick={() => {
										closeExitTestModal(true);
										if(ReactSession.get("token")){

											let user = ReactSession.get("user");

											if(user && user.role === "Researcher" && test){
												navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
											} else {
												navigate('/dashboard');
											}
										}else{
											navigate('/tester/sign-in');
										}

									}} />

							</div>

						}
					/>

					<HelpModal
						open={loginStatusChangeModal.open}
						cssClass={"taking-test-login-status-change-modal"}
						close={()=>{
							setLoginStatusChangeModal({...loginStatusChangeModal, open:false});
							// closeExitTestModal(true);
							if(ReactSession.get("token")){
								navigate('/dashboard');
							}else{
								navigate('/tester/sign-in');
							}
						}}
						heading={"Login Status Changed"}
						body={
							<div className="taking-test-login-status-change-modal-data">

								<Text type={'body-text-2'} cssClasses={'gray-color'} fontWeight={'medium-font'}>
									Oops! Your login status has changed while you were in the middle of this test. To ensure the integrity of your session, you'll need to start the test again.
								</Text>

								<div className="confirm-buttons-wrap">

									<Button
										type="primary"
										size="large"
										cssclass={'start-again'}
										label={'Start again'}
										onClick={() => { window.location.reload(); }} />


									<Button
										type="secondary"
										size="large"
										cssclass={'back-to-dashboard'}
										label={"Back to dashboard"}
										onClick={() => {
											 // closeExitTestModal(true);
											if(ReactSession.get("token")){
												let user = ReactSession.get("user");

												if(user && user.role === "Researcher" && test){
													navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
												} else {
													navigate('/dashboard');
												}
											}else{
												navigate('/tester/sign-in');
											}

										}} />



								</div>


							</div>

						}
					/>

				</div>
			)}
		</>
	);
}