import React, { useState, useEffect, useRef } from "react";
import { maxLengthCheck } from "../../../../lib/helpers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import Options from './options';
import InputField from './../../../layouts/elements/fields/input';
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import { Text } from "../Text/Text";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { Icon } from "../Icon/Icon";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import { FilterButtons } from "../FilterButtons/FilterButtons";
import { DropdownComponent } from "../Dropdown/Dropdown";
import { Tag } from "../Tag/Tag";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { InputPlaceholder } from "../Input/InputPlaceholder";
import ScreenQuestionOptions from "./screen_question_options";

export default function EditScreeningQuestionInput(props) {
    
    const [questionEditFormValues, setQuestionEditFormValues] = useState({
        question: props.questionDetails.question
    });

    const [questionEditFormErrors, setQuestionEditFormErrors] = useState({
        question: null,
        error_class: null,
    });

    const [allOptions, setAllOptions] = useState( [...props.questionDetails.options]);
    const [allScreeningOptions, setAllScreeningOptions] = useState([...props.questionDetails.screeningOptions]);
    
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});

    const [optionErrors, setOptionErrors] = useState([]);
    
    const wrapperRef = useRef(null);

    useEffect(()=>{
        setQuestionEditFormValues({question:props.questionDetails.question});

        setAllOptions( props.questionDetails.options);

        setAllScreeningOptions(props.questionDetails.screeningOptions);

    },[props.questionDetails]);

    useEffect(()=>{
        
        setQuestionEditFormErrors({...props.editQuestionErrors});

        setOptionErrors([...props.editQuestionErrors.options]);

},[props.editQuestionErrors])

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const setQuestion = (name) => {

        
        return ({ target: { value } }) => {
            
            props.onEditQuestion({...questionEditFormValues, question:value, options:allOptions, screeningOptions:allScreeningOptions});

            setQuestionEditFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            if (value.length > 0) {
                setQuestionEditFormErrors({ question: null, error_class: null });
            }
        };
    };

    const saveOptionHandler = (index, value) => {
        allOptions.splice(index, 1, value);
        setAllOptions([...allOptions]);

        props.onEditQuestion({question: questionEditFormValues.question, options:allOptions, screeningOptions: allScreeningOptions});
    };
    const saveScreeningOptionHandler = (index, value) => {
        allScreeningOptions.splice(index, 1, value);
        setAllScreeningOptions([...allScreeningOptions]);
        props.onEditQuestion({question:value, options:allOptions, screeningOptions:allScreeningOptions});
            
    };
    

    const closeOptionHandler = (index) => {
        const list = [...allOptions];
        list.splice(index, 1);
        setAllOptions(list);

        const listScreen = [...allScreeningOptions];
        listScreen.splice(index, 1);
        setAllScreeningOptions(listScreen);

        const option_errors = [...optionErrors];
        option_errors.splice(index, 1);
        setOptionErrors(option_errors);

        props.onEditQuestion({question: questionEditFormValues.question, options:list, screeningOptions: listScreen});
    };
    const editOptionHandler = (index, value) => {
        allOptions.splice(index, 1, value);
        setAllOptions([...allOptions]);

        props.onEditQuestion({question: questionEditFormValues.question, options:allOptions, screeningOptions: allScreeningOptions});
        
    };

    


    const showQuestionFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, questionEditFormErrors);

        for (var key in questionEditFormErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setQuestionEditFormErrors(formErrorsLocal);

    };
    const checkBtnstatus = () => {
        var error = false;

        if (questionEditFormValues.question === "") {
            
             error = true;
        } 
        let nulloptioncount = 0;
        allOptions.forEach((element) => {
            if (element !== "") {
                nulloptioncount = nulloptioncount + 1;
            }
            
        });
        var not_fit_count = 0;

        var fit_count = 0;

        const notEmptyOptions = [...allOptions];
        const notEmptyScreeningOptions = [...allScreeningOptions];
        for (var i = 0; i < notEmptyOptions.length; i++) {
            if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                notEmptyOptions.splice(i, 1);
                notEmptyScreeningOptions.splice(i,1);
                i--;
            }
        }
        notEmptyScreeningOptions.forEach(function(item){
            if(item=="Not a fit (end test)"){
                not_fit_count++;
            }
            if(item=="Good fit (continue)"){
                fit_count++;
            }
        })
        if (nulloptioncount < 2) {

            
            
                error=true;
            
        } else if(fit_count==0){
            
            error=true;
        } else if(not_fit_count==0){
           
            error=true;
        }
        

        return error;
    }
    const handleQuestionEditSubmit = () => {
        
        if (questionEditFormValues.question === "") {
            showQuestionFormError("question", "Required field");
            document.getElementById("question").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            // error = true;
        } else {
            let nulloptioncount = 0;

            var option_error = false;

            allOptions.forEach((item,index) => {
                
                optionErrors[index] = null;
                
            });
            allOptions.forEach((element) => {
                if (element !== "") {
                    nulloptioncount = nulloptioncount + 1;
                }
                
            });
            for(var i = 0; i < allOptions.length; i++) {
                if(!allOptions[i]){
                    optionErrors[i] = "Required field";
                    document.getElementById("question-option-"+i).scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    option_error = true;
                    break;
                }
            }
           
           
            setOptionErrors([...optionErrors]);
            
            const notEmptyOptions = [...allOptions];
            const notEmptyScreeningOptions = [...allScreeningOptions];
            for (var i = 0; i < notEmptyOptions.length; i++) {
                if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                    notEmptyOptions.splice(i, 1);
                    notEmptyScreeningOptions.splice(i,1);
                    i--;
                }
            }
            var not_fit_count = 0;

            var fit_count = 0;

            notEmptyScreeningOptions.forEach(function(item){
                if(item=="Not a fit (end test)"){
                    not_fit_count++;
                }
                if(item=="Good fit (continue)"){
                    fit_count++;
                }
            })
            if(!option_error){
                if (nulloptioncount !== 0) {
                    if (nulloptioncount < 2) {
                        //showError("Single choice questions require at least two options.");
                    } else if(fit_count==0){
                        showError(
                            "Please include a response that qualifies for the good fit logic."
                        );
                    } else if(not_fit_count==0){
                        showError(
                            "Please include a response that qualifies for the no fit logic."
                        );
                    } else {
                        props.editQuestions({
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            options: notEmptyOptions,
                            screeningOptions: notEmptyScreeningOptions

                        });
                        setQuestionEditFormValues({
                            question: ""
                        });
                        props.cancelEditQuestion();
                        
                        setAllOptions([]);

                        setAllScreeningOptions([]);
                    }
                }
                else {
                    showError("Single choice questions require at least two options.");
                }
            }
        }
    };

    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            // console.log(ref.current);
            // console.log("jsfhfsh");
            function handleClickOutside(event) {
                
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName !="svg"){
                        if(event.target.nodeName !="path"){
                            if (questionFormValues.question != "") {
                                if (!event.target.classList.contains('icon-medium')) {
                                    if (!event.target.classList.contains('toast-close-icon')) {
                                        handleQuestionEditSubmit();
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, questionFormValues, allOptions, allScreeningOptions]);
    }

    useOutsideAlerter(wrapperRef, questionEditFormValues);

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
            border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];



        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
            border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    return (
        <div ref={wrapperRef}>
           
            
            <div className={`survey-add-question-wrapper questioninputbox ${props.language == "ar" ? "arabic_wrapper" : ""}`}>
                <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${props.language == "ar" ? "arabic_wrapper" : ""} ${(props.questionDetails.questionType == "singlechoice" && props.islogic) ? 'addlogic-singlelogicbox-outer' : ''}`}>

                    <div className="bd-que-slide-left question-heading-left">
                        <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Question {props.questionno} - Single choice

                                </div>

                        </div>
                        
                        <div className="small-dropdown change-questiontype-dropdown">
                            
                            <div className={'cross-q m-cross-q mobile-view'} onClick={() => {
                                    props.cancelEditQuestion();
                                    props.onDelete();
                                    setQuestionEditFormValues({
                                        question: ""
                                    });
                                    props.onEditQuestion(null);
                                    
                                }}>
                                    <Icon value="delete" size="medium" hover={true} />
                                    
                                </div>

                        </div>
                    </div>
                    <div className={`bd-que-slide-arrow add-logic-survey-option none-for-mobile ${(props.questionDetails.questionType == "singlechoice" && props.islogic) ? 'addlogic-singlelogicbox' : ''}`}>
                        <div className="sqa-check-top singlechoice-addlogic">
                            <div className="form-group custom-control custom-checkbox d-flex">

                                <div className={'cross-q'} onClick={() => {
                                    props.cancelEditQuestion();
                                    props.onDelete();
                                    setQuestionEditFormValues({
                                        question: ""
                                    });
                                    props.onEditQuestion(null);
                                    
                                }}>
                                    <Icon value="delete" size="medium" hover={true} />
                                    
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className={`create-projectmodal-form-group d-flex ${props.language === "ar"?'arabic-add-logic-true':''} ${(props.methodology === 'Survey' && props.questionDetails.questionType !== "singlechoice") && questionEditFormValues.is_logic?'add-logic-true':''}`}>
                    <Input
                        required={true}
                        label="Your question"
                        onChange={setQuestion("question")}
                        onClear={() => {
                            setTimeout(() => {
                                setQuestionEditFormValues((oldValues) => ({
                                    ...oldValues,
                                    question: "",
                                }));
                                props.onEditQuestion({question:"", options:allOptions, screeningOptions:allScreeningOptions});
                            }, 0);
                        }}
                        value={questionEditFormValues.question}
                        maxLength="350"
                        error={questionEditFormErrors.question}
                        error_class={questionEditFormErrors.error_class}
                        id="question"
                        rtl={props.language == "ar" ? true : false}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleQuestionEditSubmit();
                            }
                        }}
                    />
                </div>
                <div className={`ans-list-wrap ${questionEditFormValues.is_logic == 1 ? "survey-logic-added" : ""} ${props.language == "ar" ? "arabic_wrapper" : ""}`}>


                    <p className="small-info-heading">
                        {(props.questionType == "rankingscale") ?
                            "Ranking options"
                            :
                            "Answers"
                        }
                    </p>
                    <ScreenQuestionOptions
                        allOptions={allOptions}
                        allScreeningOptions={allScreeningOptions}
                        close={(index) => closeOptionHandler(index)}
                        save={(index, value) => editOptionHandler(index, value)}
                        saveScreening={(index, value) => saveScreeningOptionHandler(index, value)}
                        language={props.language}
                        questionType={props.questionType}
                        addOption={(index) => {
                            if (allOptions.length < 5) {
                                allOptions.splice(index + 1, 0, "");
                                setAllOptions([...allOptions]);
                                
                                allScreeningOptions.splice(index + 1, 0, "Good fit (continue)");
                                setAllScreeningOptions([...allScreeningOptions]);

                                props.onEditQuestion({question: questionEditFormValues.question, options:allOptions, screeningOptions: allScreeningOptions});
                            }
                            else {
                                showError("You have reached the maximum number of responses available for this question.")

                            }
                        }}
                        questionno={props.questionno}
                        formErrors={optionErrors}
                    />
                </div>
                <div className="add-survey-answer-btn">

                    <Button
                        iconLeft={<Icon value="add" />}
                        label={props.questionDetails.questionType != "rankingscale"?"Add answer":"Add options"}
                        type="ghost"
                        onClick={() => {
                            if (allOptions.length < 5) {
                                    
                                allOptions.push("");
                                setAllOptions([...allOptions]);
                                
                                allScreeningOptions.push("Good fit (continue)");
                                setAllScreeningOptions([...allScreeningOptions]);
                                
                                
                            } else {
                                showError("You have reached the maximum number of responses available for this question.")

                            }
                        }}
                    />

                   
                </div>
                <div className="button-wrap d-flex justify-content-start">
                    <Button
                        label="Save"
                        onClick={handleQuestionEditSubmit}
                        state={checkBtnstatus() ? "disabled" : "active"}
                    />
                </div>
                <div className="create-test-preview-area">
                    <Text
                        type={'overline'}
                        children={'Preview'}
                    />
                    <div className="create-test-preview-area-inner">
                    {props.questionDetails.questionType == "rankingscale"?
                    <img src={process.env.REACT_APP_URL + "img/ranking-question-preview.svg"} alt="img" />
                    :
                        <img src={process.env.REACT_APP_URL + "img/multiple-question-preview.svg"} alt="img" />}
                    </div>
                </div>
            </div>
               
        </div>
    );
}
