import React, { useRef } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../../lib/secure_reactsession";
import { useState, useEffect } from "react";
import {
    getTestPreferenceTestTaskData
} from "../../../../../services/researcher_test_results";
import toast from "react-hot-toast";
import {calculateTime, showToast} from "../../../../../lib/helpers";
import moment from "moment";
import DeleteAnswer from "../../dialog/hide-participant";
import ReportAnswer from "../../dialog/report-answer";
import { Dropdown } from "react-bootstrap";
import { LoadingIcon } from "../../../../loader/loadingIcon";
import {Icon} from "../../../../themes/userq/Icon/Icon";
import {Button} from "../../../../themes/userq/Button/Button";
import ReportTesterModal from "../../../../themes/userq/Modal/report_tester";
import { useLocation } from "react-router-dom";

export default function TaskAttempts({isSharedLink, test_id, test }) {

    const location = useLocation();

    const scrollContainerRef = useRef(null);
    
    const [isScrolled, setIsScrolled] = useState(false);

    const [isTaskLoading, setTaskLoading] = useState(false);

    const [tests, setTests] = useState([]);

    const [isActive, setActive] = useState(0);

    const [page, setPage] = useState(1);

    const [has_records, setHasRecords] = useState(true);

    const [initial_loaded, setInitialLoaded] = useState(false);

    const [order, setOrder] = useState("time_spent");

    const [sortLoading, setSortLoading] = useState("time_spent");

    const [direction, setDirection] = useState("asc");

    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, answer_id: null });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null, type:null });


    const [reportTesterModal, setReportTesterModal] = useState({
        open: false,
        test_result_id:''
    });

    const getTaskAttempts = (page, order, direction) => {


        let token = ReactSession.get("token");

        setTaskLoading(true);

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
        let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        if(page==1){
            setHasRecords(true)
        }

        getTestPreferenceTestTaskData({ test_id: test_id, order: order, direction: direction, page: page, guest_result_token:guest_result_token, admin_result_token: admin_result_token }, token).then((response) => {
            
            setTaskLoading(false);

            setSortLoading(false);

            if (response.success) {

                if (page == 1) {
                    setTests(response.data);
                } else {
                    var testsLoc = [];
                    
                    testsLoc = Object.assign([], tests);
                    var newtests = testsLoc.concat(response.data);

                    setTests(newtests);
                }
                if (response.data.length < 10) {
                    setHasRecords(false);
                }
                if(response.has_records===0){
                    setHasRecords(false);
                }

                setInitialLoaded(true);

                setPage(page);

                setOrder(order);

                setDirection(direction);

            } else {
                showToast(response.message, "error");
            }
        });

    };



    useEffect(() => {

        getTaskAttempts(1, "result_id", "asc");
    }, []);
    const deleteAnswerModal = (id,hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true,  test_result_id: id,hidden_result:hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            //const newAnswerList = tests.filter(item => item.id !== openDeleteAnswerModal.answer_id)

            //console.log(newAnswerList)
            //setTests(newAnswerList);

            var testsLocal = [];
            
            for(var i =0; i < tests.length; i++){
                if(tests[i].test_result_id==openDeleteAnswerModal.test_result_id){
                    if(tests[i].result && tests[i].result.is_hidden===1){
                        
                        var test = Object.assign({},tests[i]);

                        test.result.is_hidden = 0;

                        testsLocal.push(test);

                        
                    }
                    else if(tests[i].result && tests[i].result.is_hidden===0){
                        
                        var test = Object.assign({},tests[i]);

                        test.result.is_hidden = 1;

                        testsLocal.push(test);
                    } else {
                        testsLocal.push(tests[i]);

                    }
                } else {
                    testsLocal.push(tests[i]);
                }
            }
            
            setTests(testsLocal);
        }
        setOpenDeleteAnswerModal({ active: false,  test_result_id: null,hidden_result:false  })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id, type:'methodology' });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
    // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    return (
        <div className="bd-que-ans-table-data-inner bd-que-buy-user-table tree-path-view-table prefer-analy-user-view-table" id="field1">
            <div ref={scrollContainerRef} className={`max-height-table  ${isScrolled ? 'scrolled' : ''}  ${tests && tests.length>8 ? "responsive-table-scroll":""}`}>
                <table className={"table"}>
                    <thead>
                        <tr>
                            <th>
                                <div className={`justify-content-between`}>
                                    Participant
                                </div>
                            </th>
                            <th>Preferred design</th>
                            <th>Design order</th>
                            <th
                                onClick={() => {
                                    setInitialLoaded(false);
                                    setSortLoading(true);
                                    getTaskAttempts(1, "time_spent", direction == "asc" ? "desc" : "asc");
                                }}
                            >
                                <div className={`${order=="time_spent" ? direction: '' } col-sort justify-content-between`}>
                                    Time taken

                                    {order == "time_spent" && direction == "asc" &&
                                         <Icon value={'sortup'} />
                                    }
                                    {order == "time_spent" && direction == "desc" &&
                                        <Icon value={'sortdown'} />
                                    }
                                    {order!="time_spent"  &&
                                        <Icon value={'sort-icon'} />
                                    }
                                </div>
                            </th>
                            <th
                                onClick={() => {
                                    setInitialLoaded(false);
                                    setSortLoading(true);
                                    getTaskAttempts(1, "created_at", direction == "asc" ? "desc" : "asc");
                                }}
                            >
                                <div  className={`${order=="created_at" ? direction: '' } col-sort justify-content-between`}>
                                    Date
                                    {order == "created_at" && direction == "asc" &&
                                        <Icon value={'sortup'} />
                                    }
                                    {order == "created_at" && direction == "desc" &&
                                        <Icon value={'sortdown'} />
                                    }
                                    {order!="created_at"  &&
                                        <Icon value={'sort-icon'} />
                                    }
                                </div>
                            </th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tests.map(function (item, index) {

                            return (
                                <tr key={"test" + item.id} className={` ${(item.result && item.result.is_reported==1)?'disabled-row-1':''}`}>
                                    <td>

                                        {item.result &&
                                         (item.result.user_type === 'Unknown')?item.result.user_type+" ":item.result.user_type
                                        }

                                        {item.result && item.result.result_id ?
                                            item.result.result_id
                                            :
                                            index + 1
                                        }
                                    </td>
                                    <td className={`${test.language=='ar'?'arabic-font':''}`}>
                                        {item.design.title}
                                    </td>
                                    <td className={`${test.language=='ar'?'arabic-font':''}`}>
                                        {item.sequence_designs ? item.sequence_designs : item.sequence }
                                    </td>
                                    <td><b>{calculateTime(item.time_spent)}</b></td>
                                    <td>
                                        <div className="bd-col-action-wrap d-flex">
                                            <span>{moment(item.created_at).format("DD/MM/YYYY")}</span>

                                        </div>
                                    </td>
                                    <td className="tableDropdown">
                                        {item.result && item.result.user_type !== 'Unknown' && (location.pathname.substr(1, 1) !== 'v') &&
                                            <span
                                                className="hamburger-menu ">

                                            <Dropdown className="dropdown">
                                                <Dropdown.Toggle
                                                    id={"dropdownMenuButton" + item.id}
                                                    className="dropdown-toggle"
                                                >
                                                    <span className="menu-item">
                                                    <Icon value={'Quick menu'} colorClass={'gray-900-svg hamburger-menu-img'}/>
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={'hamburger-items'}>
                                                    <div className="drop-down-link-hold byuser-table-link-hold">
                                                        {/*<Dropdown.Item onClick={() => deleteAnswerModal(item.test_result_id,(item.result && item.result.is_hidden)?true:false)}>*/}
                                                        {/*    <span>*/}
                                                        {/*        {(item.result && item.result.is_hidden)?'Show participant':'Hide participant'}*/}
                                                        {/*    </span>*/}
                                                        {/*    <Icon value="eye" size="medium" />*/}
                                                        {/*</Dropdown.Item>*/}
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setReportTesterModal({
                                                                    open: true,
                                                                    test_result_id:item.test_result_id
                                                                });
                                                                //reportAnswerModal(item.test_result_id)
                                                            }}>
                                                            <span>Report participant</span>
                                                            <Icon value="warning" size="medium"/>
                                                        </Dropdown.Item>
                                                    </div>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        {tests.length == 0 && !isTaskLoading &&
                            <tr>
                                <td colSpan={10} className="table-without-data">No data available</td>
                            </tr>
                        }

                    </tbody>
                </table>
            </div>
            {initial_loaded && !isTaskLoading && has_records &&
                <div className="load-more-data">
                    <Button type="primary"
                            size={`large`}
                            label="Load more"
                            onClick={() => {
                                getTaskAttempts(page + 1, order, direction);
                            }}
                    />
                </div>
            }
            {isTaskLoading  && !sortLoading &&
                <div className="load-more-data">
                    <Button type="primary"
                            size={`large`}
                            onClick={()=>{}}
                            microLoading={true}
                    />
                </div>
            }
            <DeleteAnswer
                test_id={test_id}
                openModal={openDeleteAnswerModal}
                closeModal={closeDeleteAnswerModal} />
            <ReportAnswer
                test_id={test_id}
                openModal={openReportAnswerModal}
                closeModal={closeReportAnswerModal} />

            <ReportTesterModal
                test={test}
                openModal={reportTesterModal.open}
                selectedTestResultId={reportTesterModal.test_result_id}
                close={() => {
                    setReportTesterModal({ ...reportTesterModal, open: false });
                }}
                confirm={(type,totalParticipantSelected) => {

                    // set reported data
                    // setReportedData({
                    //     'status':type,
                    //     'totalSelectedParticipant':totalParticipantSelected
                    // });
                    // isReportedTester(true);
                    setReportTesterModal({ ...reportTesterModal, open: false });
                    sessionStorage.setItem("is_reported_tester", "true");

                    // hide instruction model if user has submit request
                    // if(type !== 'draft'){
                    //     setConfirmedTesterReportModal(true);
                    // }
                }}
            />
        </div>
    );
}