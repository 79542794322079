import { Helmet } from "react-helmet";
import "./invoice_template.css";
import { Logo } from "../../../themes/userq/Logo/Logo";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../../themes/userq/Button/Button";
import html2canvas from "html2canvas";
import exportAsImage from "../../../../lib/exportAsImage";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon.js";
import { getInvoiceDataService, getInvoicePdfDataService } from "../../../../services/invoices.js";
import { showToast, amountFormat, capitalizeFirstLetter, handleApiErrorResponse } from "../../../../lib/helpers.js";
import { ReactSession } from "../../../../lib/secure_reactsession.js";
import { planets } from "@visx/mock-data";
import moment from "moment/moment.js";
import NoAccess from "../../../themes/userq/Noaccess/noaccess.js";
import { Footer } from "../../../themes/userq/Footer/Footer.js";

const loadScript = (src) =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve();
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (err) => reject(err);
        document.body.appendChild(script);
    });

export default function InvoiceTemplate() {
    //const src = process.env.REACT_APP_URL+'/js/jspdf.min.js';

    const navigate = useNavigate();

    const {invoice_id} = useParams();

    const src =
        "https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js";

    const [pdfEnabled, setPDFEnabled] = useState(false);
    const invoice_ref = useRef();

    const [pageLoading, setPageLoading] = useState(false);

    const [invoice, setInvoice] = useState(false);

    const [noAccess, setNoAccess] = useState(false);

    useEffect(() => {

        
        loadScript(src)
            .then(() => {
                setPDFEnabled(true);
            })
            .catch(console.error);

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`);
            if (scriptTag) document.body.removeChild(scriptTag);
        };
    }, []);

    useEffect(() => {

        if(invoice_id){
            fetchInvoice(invoice_id);
        }
        
    }, [invoice_id]);

    const downloadPDF = async () => {

        

        var elementHTML = document.querySelector("#invoice_output");

        if (invoice_ref && invoice_ref.current) {
            const canvas = await html2canvas(invoice_ref.current, {
                allowTaint: false,
                dpi: 300,
                letterRendering: true,
                logging: false,
                scale: 1,
            });

            // exportAsImage(invoice_ref.current,"sample.png");
            //return;
            window.jsPDF = window.jspdf.jsPDF;

            if (window.jsPDF) {
                const doc = new window.jsPDF();
                if (doc) {
                    doc.addImage(canvas, "PNG", 0, 0);

                    doc.save("receipt.pdf");
                }
            }
        }
    };
    const downloadPDFAPI = async () => {
        
        try {
            let token = ReactSession.get("token");
            // Fetch the PDF from the API
            const response = await fetch(process.env.REACT_APP_API_END_POINT + "invoices/pdf?invoice_id=" + invoice_id, {
              method: 'GET',
              headers: {
                'Authorization': 'Bearer '+token, // Add authorization if required
                'Content-Type':'application/json,application/pdf'
              },
            });
      
            const isJson = response.headers.get('content-type')?.includes('application/json');
            //const data = isJson ? response.json() : null;

            // Ensure the response is OK (status 200)
            if (!response.ok) {
                const error =  response.status;
                throw error;
            }
      
            /*if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }*/
            const contentType = response.headers.get('Content-Type');
    
    
            if (contentType && contentType.includes('application/json')) {
                return response.json().then(data => {
                    if(!data.success) {
                        if(data.message=="Access not allowed") {
                            setNoAccess(true);
                        }
                    }
                    //console.log('Received JSON:', data);
                    // Handle the JSON data (you can display it or process it further)
                });
            }
            if (contentType && contentType.includes('application/pdf')) {
                // Extract the Content-Disposition header to get the filename
                const contentDisposition = response.headers.get('Content-Disposition');
                let filename = 'downloaded-file.pdf'; // Default filename
        
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (filenameMatch != null && filenameMatch[1]) {
                        filename = filenameMatch[1].replace(/['"]/g, ''); // Remove any surrounding quotes
                    }
                }
        
                // Get the PDF data as a Blob
                const blob = await response.blob();
        
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        
                // Create a temporary link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); // Set the filename to the one extracted
        
                // Append link to the body, trigger the click, then remove it
                document.body.appendChild(link);
                link.click();
        
                // Clean up: revoke the object URL and remove the link element
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
          } catch (error) {

            handleApiErrorResponse(error); 
          }
        
    };
    const fetchInvoice = (invoice_id)=>{

        setPageLoading(true);

        let token = ReactSession.get("token");

        getInvoiceDataService({invoice_id:invoice_id},token).then((response) => {
            
            setPageLoading(false);  

            if (response.success) {

                setInvoice(response.invoice);
            } else {
                
                if(response.message == "Access not allowed" || response.message == "Invoice not found"){
                    setNoAccess(true);
                    //navigate("/404");
                } else {
                    showToast(response.message, "error");
                }
            }
        });
    }
    return (
        <div>
            <Helmet>
                <title>{"Invoice"} | UserQ</title>
            </Helmet>
            {pageLoading ? (
                <>
                    <div className="workspace-page-loader">
                        <div
                            className={
                                "page-loader accountloading-box creditloading-box"
                            }
                        >
                            <LoadingIcon />
                        </div>
                    </div>
                </>
            ) : (
                <>
                {noAccess
                    ?
                    <>
                    <div className="header researcher-header d-flex align-items-center header-with-logo-only">
                        <div className="container">
                            <div className="header-inner-data body-text body-text body-text-2 medium-font d-flex">
                                <div className="header-lhs-side">
                                    <div className="logo">
                                        <Logo theme={"white"} background={"black"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-data-wrapper invoice-no-data-wrapper">
                    <NoAccess />
                    </div>
                    <Footer />
                    </>
                    :
                    <>
                        {invoice &&
                        <div style={{  backgroundColor:"#ccc"}}>
                            <div
                                className="container p-0 "
                                style={{ width: 8.27 + "in"}}
                                
                            >
                                <div className="row m-0">
                                    <div className="col-md-12 p-0">
                                        <div style={{textAlign:"right"}} className="mb-20 mt-20">
                                        {/*<a href={process.env.REACT_APP_API_END_POINT + "invoices/pdf?invoice_id=" + invoice_id}>*/}
                                            <Button
                                                label={"Download as PDF"}
                                                type={"primary"}
                                                onClick={downloadPDFAPI}
                                            />
                                        {/*</a>*/}
                                        </div>
                                        <div className="grid " 
                                            id="invoice_output"
                                            ref={invoice_ref}
                                        >
                                            <div
                                                className="grid-body"
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <div
                                                    className="invoice-title invoice"
                                                    style={{
                                                        backgroundColor: "#000000",
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h2>Payment Receipt</h2>
                                                            <div className="mt-20">
                                                                {invoice.stripe_invoice && invoice.stripe_invoice.number &&
                                                                <div className="receipt-repeat d-flex align-items-center">
                                                                    <div className="receipt-left">
                                                                        <b>Invoice number</b>
                                                                    </div>
                                                                    <div className="receipt-left">
                                                                        <b>{invoice.stripe_invoice.number}</b>
                                                                    </div>
                                                                </div>
                                                                }
                                                                {invoice.stripe_invoice && invoice.stripe_invoice.receipt_number &&
                                                                <div className="receipt-repeat d-flex align-items-center">
                                                                    <div className="receipt-left">
                                                                        <b>Receipt number</b>
                                                                    </div>
                                                                    <div className="receipt-left">
                                                                        <b>{invoice.stripe_invoice.receipt_number}</b>
                                                                    </div>
                                                                </div>
                                                                }
                                                                <div className="receipt-repeat d-flex align-items-center">
                                                                    <div className="receipt-left">
                                                                        Date paid
                                                                    </div>
                                                                    <div className="receipt-left">
                                                                        {invoice.date_of_invoice ? moment(invoice.date_of_invoice,"YYYY-MM-DD").format("MMMM DD, YYYY") : "-"}
                                                                    </div>
                                                                </div>
                                                                {invoice.stripe_charge && invoice.stripe_charge.payment_method_details && invoice.stripe_charge.payment_method_details.card &&
                                                                <div className="receipt-repeat d-flex align-items-center">
                                                                    <div className="receipt-left">
                                                                        Payment method
                                                                    </div>
                                                                    <div className="receipt-left">
                                                                        {capitalizeFirstLetter(invoice.stripe_charge.payment_method_details.card.brand)} - {invoice.stripe_charge.payment_method_details.card.last4}
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text-right">
                                                                <Link to="/">
                                                                    <Logo theme={"white"} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row invoice mid-invoice">
                                                    <div className="col-md-6">
                                                        <h3 className="bill-heading-pdf">
                                                            Bill to
                                                        </h3>

                                                        <div className="billed-info-pdf">
                                                            {invoice.purchase.billing_address && invoice.purchase.billing_address.split("\r\n").map((address_item)=>{
                                                                if(address_item){
                                                                return <div className="billed-info-pdf-repeat">
                                                                    {address_item}
                                                                </div>
                                                                }
                                                                return <></>
                                                            })}
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <h3 className="bill-heading-pdf">
                                                            Bill from
                                                        </h3>

                                                        <div className="billed-info-pdf">
                                                            <div className="billed-info-pdf-repeat">
                                                                Digital of Things DMCC
                                                            </div>
                                                            <div className="billed-info-pdf-repeat">
                                                                2707, The Dome Tower, Cluster N,
                                                                Jumeirah Lakes Towers Dubai,
                                                                United Arab Emirates 0000 AE
                                                            </div>
                                                            <div className="billed-info-pdf-repeat">
                                                                +971 47707569
                                                            </div>
                                                            <div className="billed-info-pdf-repeat">
                                                                accounts@digitalofthings.com
                                                            </div>
                                                            <div className="billed-info-pdf-repeat">
                                                                www.digitalofthings.com
                                                            </div>
                                                            <div className="billed-info-pdf-repeat">
                                                                TRN 100599255500003
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="row pdf-table-wrap">
                                                            <div className="col-md-12">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr className="line">
                                                                            <td className="text-left">
                                                                                Description
                                                                            </td>
                                                                            <td className="text-center">
                                                                                Qty
                                                                            </td>
                                                                            <td className="text-center">
                                                                                Unit price
                                                                            </td>
                                                                            <td className="text-right">
                                                                                Amount
                                                                            </td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className="table-ptf-first-row">
                                                                            <td
                                                                                style={{
                                                                                    width: "420px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        width: "300px",
                                                                                    }}
                                                                                >
                                                                                    {invoice.description} on 
                                                                                    {invoice.stripe_invoice.period_end &&
                                                                                    <>
                                                                                        {" "}
                                                                                        {moment(invoice.stripe_invoice.period_end*1000).format("MMMM DD, YYYY")}
                                                                                        
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {invoice.purchase && invoice.purchase.credits ?
                                                                                invoice.purchase.credits
                                                                                :
                                                                                1
                                                                                }
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {invoice.purchase && (invoice.purchase.credits || invoice.purchase.total_fees) ?
                                                                                <>$1</>
                                                                                :
                                                                                <>${invoice.purchase && amountFormat(invoice.purchase.amount,2)}</>
                                                                                }
                                                                                
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {invoice.purchase && invoice.purchase.credits ?
                                                                                <>${amountFormat(invoice.purchase.credits,2)}</>
                                                                                :
                                                                                <>${invoice.purchase && amountFormat(invoice.purchase.amount,2)}</>
                                                                                }{" "}
                                                                            </td>
                                                                        </tr>

                                                                        <tr className="pdf-amount-row">
                                                                            <td colSpan="1"></td>
                                                                            <td
                                                                                colSpan="2"
                                                                                className="text-left border-td"
                                                                            >
                                                                                Subtotal
                                                                            </td>
                                                                            <td className="text-right border-td">
                                                                                {invoice.purchase && invoice.purchase.credits ?
                                                                                <>${amountFormat(invoice.purchase.credits,2)}</>
                                                                                :
                                                                                <>${invoice.purchase && amountFormat(invoice.purchase.amount,2)}</>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        {invoice.purchase && invoice.purchase.coupon_discount > 0 &&
                                                                        <tr className="pdf-amount-row">
                                                                            <td colSpan="1"></td>
                                                                            <td
                                                                                colSpan="2"
                                                                                className="text-left border-td"
                                                                            >
                                                                                {invoice.purchase.coupon_code}
                                                                            </td>
                                                                            <td className="text-right border-td">
                                                                                -${amountFormat(invoice.purchase.coupon_discount,2)}
                                                                            </td>
                                                                        </tr>
                                                                        }
                                                                        {invoice.purchase && invoice.purchase.promo_code &&
                                                                        <tr className="pdf-amount-row">
                                                                            <td colSpan="1"></td>
                                                                            <td
                                                                                colSpan="2"
                                                                                className="text-left border-td"
                                                                            >
                                                                                {invoice.purchase.promo_code}
                                                                            </td>
                                                                            <td className="text-right border-td">
                                                                                -${amountFormat(invoice.purchase.credits - invoice.purchase.amount,2)}
                                                                            </td>
                                                                        </tr>
                                                                        }
                                                                        <tr className="pdf-amount-row">
                                                                            <td colSpan="1"></td>
                                                                            <td
                                                                                colSpan="2"
                                                                                className="text-left border-td"
                                                                            >
                                                                                Total
                                                                            </td>
                                                                            <td className="text-right border-td">
                                                                                {invoice.purchase && invoice.purchase.credits ?
                                                                                <>${amountFormat(invoice.purchase.amount,2)}</>
                                                                                :
                                                                                <>
                                                                                {invoice.purchase && invoice.purchase.total_fees ?
                                                                                <>${amountFormat(invoice.purchase.amount,2)}</>
                                                                                :
                                                                                <>${amountFormat(invoice.purchase.total_amount,2)}</>
                                                                                }
                                                                                </>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                        <tr className="pdf-amount-row pdf-due-amount-row">
                                                                            <td colSpan="1"></td>
                                                                            <td
                                                                                colSpan="2"
                                                                                className="text-left border-td"
                                                                            >
                                                                                <b>
                                                                                    Amount paid
                                                                                </b>
                                                                            </td>
                                                                            <td className="text-right border-td">
                                                                                <b>
                                                                                    {invoice.purchase && invoice.purchase.credits ?
                                                                                    <>${amountFormat(invoice.purchase.amount,2)} USD</>
                                                                                    :
                                                                                    <>
                                                                                    {invoice.purchase && invoice.purchase.total_fees ?
                                                                                    <>${amountFormat(invoice.purchase.amount,2)}  USD</>
                                                                                    :
                                                                                    <>${amountFormat(invoice.purchase.total_amount,2)}  USD</>
                                                                                    }
                                                                                    </>
                                                                                    }
                                                                                </b>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className="pdf-bottom-row">
                                                                            <td
                                                                                colSpan="4"
                                                                                className="text-right"
                                                                            >
                                                                                {invoice.purchase && invoice.purchase.credits ?
                                                                                <>${amountFormat(invoice.purchase.amount,2)}</>
                                                                                :
                                                                                <>{invoice.purchase && invoice.purchase.total_fees ?
                                                                                    <>${amountFormat(invoice.purchase.amount,2)}</>
                                                                                    :
                                                                                    <>${amountFormat(invoice.purchase.total_amount,2)}</>
                                                                                    }</>
                                                                                }
                                                                                {" "}USD paid
                                                                                {" · "}{invoice.date_of_invoice ? moment(invoice.date_of_invoice,"YYYY-MM-DD").format("MMMM DD, YYYY") : "-"}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>

                                                                    <tfoot>
                                                                        <tr className="pdf-footer-row">
                                                                            <td colSpan="2">
                                                                                {invoice.stripe_invoice && invoice.stripe_invoice.receipt_number && 
                                                                                <>{invoice.stripe_invoice.receipt_number+" · "}</>
                                                                                }
                                                                                {invoice.purchase && invoice.purchase.credits ?
                                                                                <>${amountFormat(invoice.purchase.amount,2)}</>
                                                                                :
                                                                                <>
                                                                                {invoice.purchase && invoice.purchase.total_fees ?
                                                                                    <>${amountFormat(invoice.purchase.amount,2)}</>
                                                                                    :
                                                                                    <>${amountFormat(invoice.purchase.total_amount,2)}</>
                                                                                    }
                                                                                </>
                                                                                }
                                                                                {" "}USD paid
                                                                                {" · "}{invoice.date_of_invoice ? moment(invoice.date_of_invoice,"YYYY-MM-DD").format("MMMM DD, YYYY") : "-"}
                                                                            </td>
                                                                            <td
                                                                                colSpan="2"
                                                                                className="text-right"
                                                                            >
                                                                                Page 1 of 1
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </>
                }
                </>
                
            )
            }
        </div>
    );
}
