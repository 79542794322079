import Layout from "../layouts/layout";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getTesterHistory,getLoadMoreTesterHistory } from '../../services/test_result';
import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useNavigate,Link } from "react-router-dom";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import {LoadingIcon} from "../loader/loadingIcon";
import {roundNumber, showToast} from "../../lib/helpers";
import TesterAccountNavigation from "../profile/tester_account_navigation";
import {LayoutTester} from "../themes/userq/Layouts/layout_tester";
import {TesterTestHistory} from "../themes/userq/TesterTestHistory/TesterTestHistory";
import {Text} from "../themes/userq/Text/Text";
import {Button} from "../themes/userq/Button/Button";
import {Icon} from "../themes/userq/Icon/Icon";
import {getLoadMoreQuestionData} from "../../services/researcher_test_results";
import Skeleton from "react-loading-skeleton";
import { ScoreChip } from "../themes/userq/ScoreChip/ScoreChip";

export default function Tests() {

    let score = ReactSession.get("testerscore");
    const accountstatus=ReactSession.get("accountstatus");

    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const [history, setHistory] = useState([]);
    const [testhistory, setTestHistory] = useState([]);
    const [referralandpayouthistory, setReferralandpayouthistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [allCount, setAllCount] = useState([]);
    const navigate = useNavigate();
    const [testerscore, setTesterScore] = useState(null);

    const [metaData, setMetaData] = useState({  sort_status: "", sort_date: "", sort_reward: "", load_more: false, page: 1 });


    const getPlan = () => {
        let token = ReactSession.get("token");
        setIsLoading(true);
        getTesterHistory(token).then((response) => {
            setIsLoading(false);
            if (response.success) {
                //setHistory(response.totalhistory);
                //setTestHistory(response.testhistory);
               // setReferralandpayouthistory(response.referralandpayouthistory);
                setTesterScore(response.score);
                setHistory(response.testhistory);
                setAllCount(response.allCount);
                setMetaData({
                    sort_status: "",
                    sort_date: "",
                    sort_reward: "",
                    load_more: (response.result_count > 10) ? true : false,
                    page: 1
                });

            } else {

                let message = response.message;
                if(response.message=="Unauthenticated."){
                    message = "Please log out and log in again, if you’re not able to see the history";
                }
                showToast(message,"error");

            }
        });

    };

    useEffect(()=>{
        document.title = "Test history | "+process.env.REACT_APP_NAME;
        getPlan();
        },[]);

        const handleScroll = () => {
            // Check the scroll position or any other logic you need
            const scrolled = scrollContainerRef.current.scrollTop > 0;
        
            // Update state based on the scroll position
            setIsScrolled(scrolled);
          };
          useEffect(() => {
            // Attach scroll event listener to the referenced element
            const scrollContainer = scrollContainerRef.current;
        
            if (scrollContainer) {
              scrollContainer.addEventListener('scroll', handleScroll);
        
              // Remove the event listener when the component unmounts
              return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
              };
            }
        }, [history]);
    const sortData = (type) => {
        if (type === 'status') {
            if (metaData.sort_status === 'asc') {
                sortDataApi('status', 'desc');
            } else {
                sortDataApi('status', 'asc');
            }
        } else if (type === 'date') {
            if (metaData.sort_date === 'asc') {
                sortDataApi('date', 'desc');
            } else {
                sortDataApi('date', 'asc');
            }
        } else if (type === 'reward') {
            if (metaData.sort_reward === 'asc') {
                sortDataApi('reward', 'desc');
            } else {
                sortDataApi('reward', 'asc');
            }
        }


    }

    const sortDataApi = (sortColumn, orderBy) => {

        //setMetaData({ ...metaData, load_more: true });
        // setLoadMoreLoading(true);
        //setSortLoading(true);
        let token = ReactSession.get("token");
        getLoadMoreTesterHistory({
            page: 1,
            sort_column: sortColumn,
            order_by: orderBy
        }, token).then((response) => {
            //setLoadMoreLoading(false);
            //setSortLoading(false);

            if (response.success) {
                // setAnswerArray(answerArray.concat(response.data));
                //setAnswerArray(response.data);
                setHistory(response.testhistory);

                if (sortColumn === 'status') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_status: "desc", sort_date: "", sort_reward: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_status: "asc", sort_date: "", sort_reward: "", page: 1, load_more: response.more_records });
                    }
                }else if (sortColumn === 'date') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_status: "", sort_date: "desc", sort_reward: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_status: "", sort_date: "asc", sort_reward: "", page: 1, load_more: response.more_records });
                    }
                } else {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_status: "", sort_date: "", sort_reward: "desc", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_status: "", sort_date: "", sort_reward: "asc", page: 1, load_more: response.more_records });
                    }
                }
                // setMetaData({...metaData,page:metaData.page+1,load_more:response.more_records});

            } else {
                showToast(response.message,"error");
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);
        let token = ReactSession.get("token");

        console.log(metaData)

        getLoadMoreTesterHistory({
            page: metaData.page + 1,
            sort_column: metaData.sort_status?"status":metaData.sort_reward?"reward":"date",
            order_by: metaData.sort_status?metaData.sort_status:metaData.sort_reward?metaData.sort_reward:metaData.sort_date?metaData.sort_date:"desc"
            //sort_column:metaData.sort_time?"time":metaData.sort_date?"date":"date",
            //order_by:metaData.sort_time?metaData.sort_time:metaData.sort_date?metaData.sort_date:"asc",
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setHistory(history.concat(response.testhistory));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                showToast(response.message,"error");
            }
        });
    }

    return (
        <LayoutTester isLoading={false} skipCheck={false} isAccount={true} extendedFooter={false} activeMenu={"settings"}>
            <div className="credits-page-wrapper studies-hpage-wrapper page-min-height">
            {accountstatus=="blocked" && <div className="account-blocked-message">
                    <div><Icon value={"blocked-icon-red"} size="large"/></div>
                    <div>
                    <Text type={"subtitle-1"}>Your payout cannot be redeemed as your profile is currently blocked.</Text>
                    <Text type={"body-text-2"}>Please contact <a className='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile"} target="_blank">UserQ support</a>, to guide you through the next steps.</Text>
                    </div>
                </div>}
                <div className='page-heading-wrap accounts-page--heading'>
                    <Text type="h1">Account</Text>
                    <div className="accounts-score-chip">
                    {/* {isLoading?<Skeleton className="header-credit-skeleton" width={180} height={40}/>:<ScoreChip testerscore={testerscore}/>} */}
                    <ScoreChip testerscore={score}/>
                    <div className='account-guidelines-link'>
                        <Text type="body-text-3">
                        Read more about  <a className='faq-support-link' href={process.env.REACT_APP_URL+"t/tester-guidelines"} target="_blank">tester guidelines</a>
                        </Text>
                    </div>
                    </div>
                </div>
                <TesterAccountNavigation activeMenu={"tests"} />

                {/*<div className="profile-info-section d-block pb-64 pt-64">*/}
                {/*  <div className="profile-info-left w-100">*/}
                {/*    <div className="profile-info-text  mt-0">*/}
                {/*      <h1 className="page-main-heading">History</h1>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {isLoading ? (
                    <div className="accountloading-box"><LoadingIcon /></div>
                ) : (
                    <>
                        <div className="profile-form-wrap">
                            <div className="profile-form-repeat tester-test-history border-0">
                                <div className="row">
                                    <div className="col-md-4 col-lg-3">
                                        {/*<div className="form-info-text">*/}
                                        {/*    <h3>*/}
                                        {/*        {testhistory.length < 1*/}
                                        {/*            ? "You haven't taken any tests yet"*/}
                                        {/*            : testhistory.length > 1*/}
                                        {/*                ? testhistory.length + " tests taken"*/}
                                        {/*                : testhistory.length + " test taken"}{" "}*/}
                                        {/*    </h3>*/}
                                        {/*</div>*/}
                                        <TesterTestHistory
                                            abandoned={(allCount)?allCount.abondonedTest:0}
                                            completed={(allCount)?allCount.completedTest:0}
                                            reported={(allCount)?allCount.reportedTest:0}
                                        />
                                    </div>
                                    <div className="col-md-8 col-lg-9">
                                        <div className="table-wrapper study-history-table testhistory-box">
                                            {history.length > 0 && <Text type={"h2"}>Tests history</Text> }
                                            <div ref={scrollContainerRef}  className={`max-height-table  ${isScrolled ? 'scrolled' : ''}  ${history && history.length>8 ? "responsive-table-scroll":""}`}>
                                            <table className="table">
                                                    {history.length > 0 ? (
                                                        <thead>
                                                        <tr>
                                                            <th>Name
                                                            </th>
                                                            <th>
                                                                <div onClick={() => {
                                                                    sortData('status')
                                                                }} className={`${(metaData.sort_status) ? metaData.sort_status : ''}  col-sort justify-content-between`}>

                                                                Status
                                                                    <span>
                                                                        {metaData.sort_status=="asc" &&
                                                                        <Icon value={'sortup'}/>
                                                                        }
                                                                        {metaData.sort_status=="desc" &&
                                                                        <Icon value={'sortdown'}/>
                                                                        }
                                                                        {!metaData.sort_status &&
                                                                        <Icon value={'sort-icon'}/>
                                                                        }

                                                                    </span>
                                                            </div>
                                                            </th>
                                                            <th>
                                                                <div onClick={() => {
                                                                    sortData('date')
                                                                }} className={`${(metaData.sort_date) ? metaData.sort_date : ''}  col-sort justify-content-between`}>

                                                                    Date
                                                                    <span>
                                                                        {metaData.sort_date=="asc" &&
                                                                        <Icon value={'sortup'}/>
                                                                        }
                                                                        {metaData.sort_date=="desc" &&
                                                                        <Icon value={'sortdown'}/>
                                                                        }
                                                                        {!metaData.sort_date &&
                                                                        <Icon value={'sort-icon'}/>
                                                                        }

                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <div className="sdh-reward-wrap">
                                                                    <div onClick={() => {
                                                                        sortData('reward')
                                                                    }} className={`${(metaData.sort_reward) ? metaData.sort_reward : ''}  col-sort justify-content-between`}>

                                                                        Reward
                                                                        <span>
                                                                        {metaData.sort_reward=="asc" &&
                                                                        <Icon value={'sortup'}/>
                                                                        }
                                                                            {metaData.sort_reward=="desc" &&
                                                                            <Icon value={'sortdown'}/>
                                                                            }
                                                                            {!metaData.sort_reward &&
                                                                            <Icon value={'sort-icon'}/>
                                                                            }

                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <tbody>

                                                    {history.map((result, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>Test taken:{" "}
                                                                    <span className={(result.test_id?result.test && result.test.language === 'ar'?'arabic-font':'':'')}>{result.test_id?result.test && result.test.test_name:result.description}</span>
                                                                </td>
                                                                <td>
                                                                    <div className="active-tiles-wrap">
                                                                        {result.is_reported ===1 ?
                                                                                <span className={'reported'}>Reported</span>
                                                                            :
                                                                            <>
                                                                                {result.test_id ? result.is_test_completed === 1 ?
                                                                                    <span
                                                                                        className={'completed'}>Completed</span>
                                                                                    :

                                                                                    <span
                                                                                        className={'abandoned'}>Abandoned</span>
                                                                                    :
                                                                                    <span className={'abandoned'}>Abandoned</span>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    {format(
                                                                        new Date(result.created_at),
                                                                        "dd-MM-yy"
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div className="td-flex-wrap">
                                                                        {result.test_id ?result.is_test_completed === 1 ? (
                                                                            result.credit ? (
                                                                                <span className="green-color">
                                                                                    +$
                                                                                    {result.credit.credits.toFixed(2)}{" "}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="red-color">$0.00</span>
                                                                            )
                                                                        ) : (
                                                                            <span className="red-color">$0.00</span>
                                                                        ):<>{result.credits ? (
                                                                            result.transaction_type=="credit"?(
                                                                                <span className="green-color">
                                                                                    +$
                                                                                    {result.credits.toFixed(2)}{" "}
                                                                                </span>
                                                                            ):(
                                                                                <span className="red-color">
                                                                                    -$
                                                                                    {result.credits.toFixed(2)}{" "}
                                                                                </span>
                                                                            )
                                                                        ) : (
                                                                            <span className="red-color">$0.00</span>
                                                                        )
                                                                        }</>}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                    </tbody>
                                                </table>

                                                


                                            </div>
                                            {metaData.load_more && (
                                                    <div className={"load-more-data"}>
                                                        <Button type="primary"
                                                                size={`large`}
                                                                label="Load more"
                                                                microLoading={loadMoreLoading}
                                                                onClick={() => {
                                                                    loadMore()
                                                                }}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </LayoutTester>
    );
}
