
import Select, {components} from "react-select";
import React,{useState} from "react";
import PropTypes from 'prop-types';
import { DropdownIndicator } from "../../../../lib/helpers";
import { Icon } from '../Icon/Icon';
import { Chip, Chips } from "../Chips/Chips";
import {CheckBox} from "../CheckBox/CheckBox";

export const MultiSelectGroupDropdown = ({ id, isMulti, readOnly, error, onChange,onClick, onTouchStart, value, label,  active, options, menuIsOpen,isStorybook }) => {

    const { ValueContainer, Placeholder } = components;

    let selected_value = null;

    let option_array = null;

    if (isMulti) {

        selected_value = [];
        value.forEach(function (item) {
            selected_value.push({ "label": item, "value": item });
        })


        // check option array
        option_array = [];
        //console.log(options)
        options.forEach(function (items) {

            const checkValue = value.findIndex(item => item === items.value);
            if(checkValue !== -1){
                option_array.push({ ...items,color:'#cccccc', isDisabled: true});
            }else{
                option_array.push({ ...items,color:'#cccccc', isDisabled: false});
            }

        })

    } else {
        if (value) {
            selected_value = {
                label: value,
                value: value
            };
        }

        option_array = [];
        options.forEach(function (items) {

            option_array.push({ ...items,color:'', isDisabled: false})

        })
    }
    const customStyles = {
        placeholder: (base, state) => ({
            ...base,
            display:
                state.isFocused || state.isSelected || state.selectProps.inputValue
                    ? 'none'
                    : 'block',
        }),
    }

    const NewValueContainer = (props) => {
        const shouldFloatLabel = props.selectProps.menuIsOpen || props.hasValue;

        return (
            <components.ValueContainer {...props}>
                {shouldFloatLabel && <p>{label}</p>}
                {props.children}
            </components.ValueContainer>
        );
    };

    // const CustomValueContainer = ({ children, ...props }) => {
    //     return (
    //         <ValueContainer {...props}>
    //             <Placeholder {...props} isFocused={props.isFocused}>
    //                 {props.selectProps.placeholder}
    //             </Placeholder>
    //             {React.Children.map(children, child => {
    //                     // console.log(child)
    //                     return child && child.type !== Placeholder ? child : null
    //                 }
    //             )}
    //         </ValueContainer>
    //     );
    // }


    const InputOption = ({
                             getStyles,
                             Icon,
                             isDisabled,
                             isFocused,
                             isSelected,
                             children,
                             innerProps,
                             ...rest
                         }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        if (isFocused) bg = "#eee";
        if (isActive) bg = "#B2D4FF";

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: "inherit",
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };

        return (
            <components.Option
                {...rest}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                getStyles={getStyles}
                innerProps={props}
            >
                {/*<CheckBox*/}
                {/*    id={`terms_accept_toggles`}*/}
                {/*    checked={isSelected}*/}
                {/*    label={children}*/}
                {/*    // onChange={(e) =>*/}
                {/*    // {*/}
                {/*    //     //setFormValues({...formValues,"isShown":!formValues.isShown})*/}
                {/*    // }}*/}
                {/*/>*/}
                <span className={"checkbox-wrap checkbox-label"}>
                    <input className={'option-input-checked'} type="checkbox" checked={isSelected} /><span className={"checkmark"}></span></span>&nbsp;
                <span className={'group-checkbox-label'}>{children}</span>

            </components.Option>
        );
    };

    const groupStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    };

    const groupBadgeStyles = {
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#172B4D",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center"
    };

    const formatGroupLabel = data => (
        <div className={"option-label-heading"}>
            <span>{data.label}</span>
            {/*<span style={groupBadgeStyles}>{data.options.length}</span>*/}
        </div>
    );

    // const colourOptions = [
    //     { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    //     { value: "blue", label: "Blue", color: "#0052CC", disabled: true },
    //     { value: "purple", label: "Purple", color: "#5243AA" },
    //     { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    //     { value: "orange", label: "Orange", color: "#FF8B00" },
    //     { value: "yellow", label: "Yellow", color: "#FFC400" },
    //     { value: "greennn", label: "Green", color: "#36B37E" },
    //     { value: "forest", label: "Forest", color: "#00875A" },
    //     { value: "slate", label: "Slate", color: "#253858" },
    //     { value: "silver", label: "Silver", color: "#666666" }
    // ];
    // const flavourOptions = [
    //     { value: "vanilla", label: "Vanilla", rating: "safe" },
    //     { value: "chocolate", label: "Chocolate", rating: "good" },
    //     { value: "strawberry", label: "Strawberry", rating: "wild" },
    //     { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" }
    // ];
    // const groupedOptions = [
    //     {
    //         label: "Colours",
    //         options: colourOptions
    //     },
    //     {
    //         label: "Flavours",
    //         options: flavourOptions
    //     }
    // ];

    const multiValueContainer = ({ selectProps, data }) => {
        console.log(selectProps)
        console.log('dasdas')
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex(selected => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
        const val = `${label}${labelSuffix}`;

        console.log(val)
        return val;
    };

    // const CustomValueContainer = ({childrens,...props}) => {
    //     const length = props.getValue().length;
    //     const children = props.children;
    //     let displayChips = React.Children.toArray(children).slice(0, 1)
    //
    //     return length ? (
    //         <components.ValueContainer {...props}>
    //             <>
    //                 {console.log(displayChips)}
    //                 {length} selected
    //                 {children.slice(-1)}
    //             </>
    //         </components.ValueContainer>
    //     ) : (
    //         <components.ValueContainer {...props}>{children}</components.ValueContainer>
    //     );
    // }

    const CustomValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
            const val = (i= Number) => values[i].props.children;
            const { length } = values;
            switch (length) {
                case 1:
                    values = `${val(0)} `;
                    break;
                default:

                    const otherCount = length - 1;
                    values = `${val(0).length >28?val(0).substring(0,27)+"...":val(0)}, +${otherCount} more`;
                    break;
            }
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    return (
        <div onClick={onClick} onTouchStart={onTouchStart} className={` dropdown-container form-group input-field mb-0 ${readOnly?"read-only-dropdown":""} ${error?"field-error":""} ${((value != '' && value != null) || active == true) ? 'focused' : ''} ${(active == true) ? 'active' : ''}`} id={id} >
            <div className={`input-position-relative  dropdown-field country-dropdown multiselect-dropdown multiselectgroup-dropdown`}>
                {/*{console.log(menuIsOpens)}*/}


                {/*{menuIsOpens?'true':'false'}*/}
                {isStorybook ?

                    <>
                        <span className={""}>
                            <Select
                                isMulti={true}
                                isClearable={false}
                                isSearchable={false}
                                isDisabled={false}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                defaultMenuIsOpen={false}
                                name="countries"
                                options={options}
                                onChange={onChange}
                                placeholder={label}
                                value={selected_value}
                                // components={{ DropdownIndicator }}
                                components={{
                                    Option: InputOption,
                                    // multiValueContainer:multiValueContainer,
                                    ValueContainer: CustomValueContainer,DropdownIndicator
                                }}
                                styles={{
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        display: state.selectProps.inputValue ? "none" : "block",
                                    })
                                }}
                                formatGroupLabel={formatGroupLabel}
                                // classNamePrefix="select"
                                // components={{ ValueContainer: NewValueContainer,DropdownIndicator }}
                            />
                        </span>
                    </>
                    :
                    <>

                        <span className={"none-for-mobile "}>
                            <Select
                                isMulti={true}
                                isClearable={false}
                                isSearchable={false}
                                isDisabled={false}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                menuIsOpen={(menuIsOpen)?true:false}
                                defaultMenuIsOpen={false}
                                name="countries"
                                options={options}
                                onChange={onChange}
                                placeholder={label}
                                value={selected_value}
                                // components={{ DropdownIndicator }}
                                components={{
                                    Option: InputOption,
                                    // multiValueContainer:multiValueContainer,
                                    ValueContainer: CustomValueContainer,DropdownIndicator
                                }}
                                styles={{
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        display: state.selectProps.inputValue ? "none" : "block",
                                    })
                                }}
                                formatGroupLabel={formatGroupLabel}
                                // classNamePrefix="select"
                                // components={{ ValueContainer: NewValueContainer,DropdownIndicator }}
                            />
                        </span>

                                <span className={"none-for-desktop"}>
                            <Select
                                isMulti={true}
                                isClearable={false}
                                isSearchable={false}
                                isDisabled={false}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                defaultMenuIsOpen={false}
                                name="countries"
                                options={options}
                                onChange={onChange}
                                placeholder={label}
                                value={selected_value}
                                // components={{ DropdownIndicator }}
                                components={{
                                    Option: InputOption,
                                    // multiValueContainer:multiValueContainer,
                                    ValueContainer: CustomValueContainer,DropdownIndicator
                                }}
                                styles={{
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        display: state.selectProps.inputValue ? "none" : "block",
                                    })
                                }}
                                formatGroupLabel={formatGroupLabel}
                                // classNamePrefix="select"
                                // components={{ ValueContainer: NewValueContainer,DropdownIndicator }}
                            />
                        </span>

                    </>
                }

                {/* <span class="input-icon multiselect-search-icon"><Icon value="search" size="medium"/></span> */}
                 <label className="control-label body-text body-text-2" htmlFor={id}>

                    {label}
                </label>


            </div>
            {error != null && (
                <span className="caption">
                    {error}
                </span>
            )}
        </div>
    );
}

// MultiSelectDropdown.propTypes = {
//     id: PropTypes.string,
//     onChange: PropTypes.func,
//     label: PropTypes.string,
//     inactive: PropTypes.bool,
//     focussed: PropTypes.bool,
//     open: PropTypes.bool,
//     isMulti: PropTypes.bool,
//     menuIsOpen: PropTypes.bool,
//     type: PropTypes.string,
//
// };
MultiSelectGroupDropdown.defaultProps = {
    onChange: undefined,
    inactive: false,
    focussed: false,
    open: false,
    type: "country",
    isMulti:true,
    menuIsOpen:false,
};
