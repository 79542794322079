export const nationalities_other = [
	{
		"code": "AF",
		"label": "Afghan / Afghani",
		"value": "Afghan / Afghani"
	},
	{
		"code": "AL",
		"label": "Albanian",
		"value": "Albanian"
	},
	{
		"code": "DZ",
		"label": "Algerian",
		"value": "Algerian"
	},
	{
		"code": "US",
		"label": "American",
		"value": "American"
	},
	{
		"code": "AO",
		"label": "Angolan",
		"value": "Angolan"
	},
	{
		"code": "AR",
		"label": "Argentine / Argentinian",
		"value": "Argentine / Argentinian"
	},
	{
		"code": "AU",
		"label": "Australian",
		"value": "Australian"
	},
	{
		"code": "AT",
		"label": "Austrian",
		"value": "Austrian"
	},
	{
		"code": "BD",
		"label": "Bangladeshi",
		"value": "Bangladeshi"
	},
	{
		"code": "BH",
		"label": "Bahraini",
		"value": "Bahraini"
	},
	{
		"code": "BY",
		"label": "Belarusian",
		"value": "Belarusian"
	},
	{
		"code": "BE",
		"label": "Belgian",
		"value": "Belgian"
	},
	{
		"code": "BT",
		"label": "Bhutanese",
		"value": "Bhutanese"
	},
	{
		"code": "BO",
		"label": "Bolivian",
		"value": "Bolivian"
	},
	{
		"code": "BW",
		"label": "Botswanan",
		"value": "Botswanan"
	},
	{
		"code": "BR",
		"label": "Brazilian",
		"value": "Brazilian"
	},
	{
		"code": "GB",
		"label": "British",
		"value": "British"
	},
	{
		"code": "BG",
		"label": "Bulgarian",
		"value": "Bulgarian"
	},
	{
		"code": "MM",
		"label": "Burmese",
		"value": "Burmese"
	},
	{
		"code": "KH",
		"label": "Cambodian",
		"value": "Cambodian"
	},
	{
		"code": "CA",
		"label": "Canadian",
		"value": "Canadian"
	},
	{
		"code": "CL",
		"label": "Chilean",
		"value": "Chilean"
	},
	{
		"code": "CN",
		"label": "Chinese",
		"value": "Chinese"
	},
	{
		"code": "CO",
		"label": "Colombian",
		"value": "Colombian"
	},
	{
		"code": "CD",
		"label": "Congolese",
		"value": "Congolese"
	},
	{
		"code": "HR",
		"label": "Croatian",
		"value": "Croatian"
	},
	{
		"code": "CU",
		"label": "Cuban",
		"value": "Cuban"
	},
	{
		"code": "CY",
		"label": "Cypriot",
		"value": "Cypriot"
	},
	{
		"code": "CZ",
		"label": "Czech",
		"value": "Czech"
	},
	{
		"code": "DK",
		"label": "Danish",
		"value": "Danish"
	},
	{
		"code": "NL",
		"label": "Dutch",
		"value": "Dutch"
	},
	{
		"code": "EC",
		"label": "Ecuadorian",
		"value": "Ecuadorian"
	},
	{
		"code": "EG",
		"label": "Egyptian",
		"value": "Egyptian"
	},
	{
		"code": "EE",
		"label": "Estonian",
		"value": "Estonian"
	},
	{
		"code": "ET",
		"label": "Ethiopian",
		"value": "Ethiopian"
	},
	{
		"code": "AE",
		"label": "Emirati",
		"value": "Emirati"
	},
	{
		"code": "FJ",
		"label": "Fijian",
		"value": "Fijian"
	},
	{
		"code": "PH",
		"label": "Filipino",
		"value": "Filipino"
	},
	{
		"code": "FI",
		"label": "Finnish",
		"value": "Finnish"
	},
	{
		"code": "FR",
		"label": "French",
		"value": "French"
	},
	{
		"code": "GE",
		"label": "Georgian",
		"value": "Georgian"
	},
	{
		"code": "DE",
		"label": "German",
		"value": "German"
	},
	{
		"code": "GH",
		"label": "Ghanaian",
		"value": "Ghanaian"
	},
	{
		"code": "GR",
		"label": "Greek",
		"value": "Greek"
	},
	{
		"code": "GT",
		"label": "Guatemalan",
		"value": "Guatemalan"
	},
	{
		"code": "HK",
		"label": "Hong Kongese",
		"value": "Hong Kongese"
	},
	{
		"code": "HU",
		"label": "Hungarian",
		"value": "Hungarian"
	},
	{
		"code": "IS",
		"label": "Icelandic",
		"value": "Icelandic"
	},
	{
		"code": "IN",
		"label": "Indian",
		"value": "Indian"
	},
	{
		"code": "ID",
		"label": "Indonesian",
		"value": "Indonesian"
	},
	{
		"code": "IR",
		"label": "Iranian",
		"value": "Iranian"
	},
	{
		"code": "IQ",
		"label": "Iraqi",
		"value": "Iraqi"
	},
	{
		"code": "IE",
		"label": "Irish",
		"value": "Irish"
	},
	{
		"code": "IL",
		"label": "Israeli",
		"value": "Israeli"
	},
	{
		"code": "IT",
		"label": "Italian",
		"value": "Italian"
	},
	{
		"code": "CI",
		"label": "Ivorian",
		"value": "Ivorian"
	},
	{
		"code": "JM",
		"label": "Jamaican",
		"value": "Jamaican"
	},
	{
		"code": "JP",
		"label": "Japanese",
		"value": "Japanese"
	},
	{
		"code": "JO",
		"label": "Jordanian",
		"value": "Jordanian"
	},
	{
		"code": "KZ",
		"label": "Kazakh / Kazakhstani",
		"value": "Kazakh / Kazakhstani"
	},
	{
		"code": "KE",
		"label": "Kenyan",
		"value": "Kenyan"
	},
	{
		"code": "KW",
		"label": "Kuwaiti",
		"value": "Kuwaiti"
	},
	{
		"code": "LA",
		"label": "Laotian / Lao",
		"value": "Laotian / Lao"
	},
	{
		"code": "LV",
		"label": "Latvian",
		"value": "Latvian"
	},
	{
		"code": "LB",
		"label": "Lebanese",
		"value": "Lebanese"
	},
	{
		"code": "LY",
		"label": "Libyan",
		"value": "Libyan"
	},
	{
		"code": "LT",
		"label": "Lithuanian",
		"value": "Lithuanian"
	},
	{
		"code": "MG",
		"label": "Malagasy",
		"value": "Malagasy"
	},
	{
		"code": "MY",
		"label": "Malaysian",
		"value": "Malaysian"
	},
	{
		"code": "MV",
		"label": "Maldivian",
		"value": "Maldivian"
	},
	{
		"code": "MX",
		"label": "Mexican",
		"value": "Mexican"
	},
	{
		"code": "MN",
		"label": "Mongolian",
		"value": "Mongolian"
	},
	{
		"code": "MA",
		"label": "Moroccan",
		"value": "Moroccan"
	},
	{
		"code": "MZ",
		"label": "Mozambican",
		"value": "Mozambican"
	},
	{
		"code": "NA",
		"label": "Namibian",
		"value": "Namibian"
	},
	{
		"code": "NP",
		"label": "Nepalese / Nepali",
		"value": "Nepalese / Nepali"
	},
	{
		"code": "NZ",
		"label": "New Zealand",
		"value": "New Zealand"
	},
	{
		"code": "NG",
		"label": "Nigerian",
		"value": "Nigerian"
	},
	{
		"code": "KP",
		"label": "North Korean",
		"value": "North Korean"
	},
	{
		"code": "NO",
		"label": "Norwegian",
		"value": "Norwegian"
	},
	{
		"code": "PK",
		"label": "Pakistani",
		"value": "Pakistani"
	},
	{
		"code": "PS",
		"label": "Palestinian",
		"value": "Palestinian"
	},
	{
		"code": "PY",
		"label": "Paraguayan",
		"value": "Paraguayan"
	},
	{
		"code": "PE",
		"label": "Peruvian",
		"value": "Peruvian"
	},
	{
		"code": "PL",
		"label": "Polish",
		"value": "Polish"
	},
	{
		"code": "PT",
		"label": "Portuguese",
		"value": "Portuguese"
	},
	{
		"code": "RO",
		"label": "Romanian",
		"value": "Romanian"
	},
	{
		"code": "RU",
		"label": "Russian",
		"value": "Russian"
	},
	{
		"code": "SA",
		"label": "Saudi Arabian",
		"value": "Saudi Arabian"
	},
	{
		"code": "RS",
		"label": "Serbian",
		"value": "Serbian"
	},
	{
		"code": "SG",
		"label": "Singaporean",
		"value": "Singaporean"
	},
	{
		"code": "SK",
		"label": "Slovak / Slovakian",
		"value": "Slovak / Slovakian"
	},
	{
		"code": "SI",
		"label": "Slovenian / Slovene",
		"value": "Slovenian / Slovene"
	},
	{
		"code": "SO",
		"label": "Somali / Somalian",
		"value": "Somali / Somalian"
	},
	{
		"code": "ZA",
		"label": "South African",
		"value": "South African"
	},
	{
		"code": "KR",
		"label": "South Korean",
		"value": "South Korean"
	},
	{
		"code": "ES",
		"label": "Spanish",
		"value": "Spanish"
	},
	{
		"code": "LK",
		"label": "Sri Lankan",
		"value": "Sri Lankan"
	},
	{
		"code": "SD",
		"label": "Sudanese",
		"value": "Sudanese"
	},
	{
		"code": "SE",
		"label": "Swedish",
		"value": "Swedish"
	},
	{
		"code": "CH",
		"label": "Swiss",
		"value": "Swiss"
	},
	{
		"code": "SY",
		"label": "Syrian",
		"value": "Syrian"
	},
	{
		"code": "TW",
		"label": "Taiwanese",
		"value": "Taiwanese"
	},
	{
		"code": "TZ",
		"label": "Tanzanian",
		"value": "Tanzanian"
	},
	{
		"code": "TH",
		"label": "Thai",
		"value": "Thai"
	},
	{
		"code": "TN",
		"label": "Tunisian",
		"value": "Tunisian"
	},
	{
		"code": "TR",
		"label": "Turkish",
		"value": "Turkish"
	},
	{
		"code": "UG",
		"label": "Ugandan",
		"value": "Ugandan"
	},
	{
		"code": "UA",
		"label": "Ukrainian",
		"value": "Ukrainian"
	},
	{
		"code": "UY",
		"label": "Uruguayan",
		"value": "Uruguayan"
	},
	{
		"code": "VE",
		"label": "Venezuelan",
		"value": "Venezuelan"
	},
	{
		"code": "VN",
		"label": "Vietnamese",
		"value": "Vietnamese"
	},
	{
		"code": "YE",
		"label": "Yemeni / Yemenite",
		"value": "Yemeni / Yemenite"
	},
	{
		"code": "ZM",
		"label": "Zambian",
		"value": "Zambian"
	},
	{
		"code": "QA",
		"label": "Qatari",
		"value": "Qatari"
	},
	{
		"code": "OM",
		"label": "Omani",
		"value": "Omani"
	},
	{
		"code": "AM",
		"label": "Armenian",
		"value": "Armenian"
	},
	{
		"code": "AZ",
		"label": "Azerbaijani",
		"value": "Azerbaijani"
	},
	{
		"code": "BJ",
		"label": "Beninese",
		"value": "Beninese"
	},
	{
		"code": "BA",
		"label": "Bosnian",
		"value": "Bosnian"
	},
	{
		"code": "BN",
		"label": "Bruneian",
		"value": "Bruneian"
	},
	{
		"code": "BF",
		"label": "Burkinabé",
		"value": "Burkinabé"
	},
	{
		"code": "CM",
		"label": "Cameroonian",
		"value": "Cameroonian"
	},
	{
		"code": "CV",
		"label": "Cape Verdean",
		"value": "Cape Verdean"
	},
	{
		"code": "CF",
		"label": "Central African",
		"value": "Central African"
	},
	{
		"code": "TD",
		"label": "Chadian",
		"value": "Chadian"
	},
	{
		"code": "KM",
		"label": "Comorian",
		"value": "Comorian"
	},
	{
		"code": "CR",
		"label": "Costa Rican",
		"value": "Costa Rican"
	},
	{
		"code": "DJ",
		"label": "Djiboutian",
		"value": "Djiboutian"
	},
	{
		"code": "DO",
		"label": "Dominican",
		"value": "Dominican"
	},
	{
		"code": "TL",
		"label": "East Timorese",
		"value": "East Timorese"
	},
	{
		"code": "GQ",
		"label": "Equatorial Guinean",
		"value": "Equatorial Guinean"
	},
	{
		"code": "ER",
		"label": "Eritrean",
		"value": "Eritrean"
	},
	{
		"code": "GA",
		"label": "Gabonese",
		"value": "Gabonese"
	},
	{
		"code": "GM",
		"label": "Gambian",
		"value": "Gambian"
	},
	{
		"code": "GD",
		"label": "Grenadian",
		"value": "Grenadian"
	},
	{
		"code": "GN",
		"label": "Guinean",
		"value": "Guinean"
	},
	{
		"code": "GY",
		"label": "Guyanese",
		"value": "Guyanese"
	},
	{
		"code": "HT",
		"label": "Haitian",
		"value": "Haitian"
	},
	{
		"code": "HN",
		"label": "Honduran",
		"value": "Honduran"
	},
	{
		"code": "KG",
		"label": "Kyrgyz",
		"value": "Kyrgyz"
	},
	{
		"code": "LR",
		"label": "Liberian",
		"value": "Liberian"
	},
	{
		"code": "LU",
		"label": "Luxembourger",
		"value": "Luxembourger"
	},
	{
		"code": "ML",
		"label": "Malian",
		"value": "Malian"
	},
	{
		"code": "MR",
		"label": "Mauritanian",
		"value": "Mauritanian"
	},
	{
		"code": "FM",
		"label": "Micronesian",
		"value": "Micronesian"
	},
	{
		"code": "MD",
		"label": "Moldovan",
		"value": "Moldovan"
	},
	{
		"code": "MC",
		"label": "Monégasque",
		"value": "Monégasque"
	},
	{
		"code": "NI",
		"label": "Nicaraguan",
		"value": "Nicaraguan"
	},
	{
		"code": "NE",
		"label": "Nigerien",
		"value": "Nigerien"
	},
	{
		"code": "PA",
		"label": "Panamanian",
		"value": "Panamanian"
	},
	{
		"code": "PG",
		"label": "Papua New Guinean",
		"value": "Papua New Guinean"
	},
	{
		"code": "RW",
		"label": "Rwandan",
		"value": "Rwandan"
	},
	{
		"code": "LC",
		"label": "Saint Lucian",
		"value": "Saint Lucian"
	},
	{
		"code": "SV",
		"label": "Salvadoran",
		"value": "Salvadoran"
	},
	{
		"code": "WS",
		"label": "Samoan",
		"value": "Samoan"
	},
	{
		"code": "SN",
		"label": "Senegalese",
		"value": "Senegalese"
	},
	{
		"code": "SC",
		"label": "Seychellois",
		"value": "Seychellois"
	},
	{
		"code": "SL",
		"label": "Sierra Leonean",
		"value": "Sierra Leonean"
	},
	{
		"code": "SB",
		"label": "Solomon Islander",
		"value": "Solomon Islander"
	},
	{
		"code": "SR",
		"label": "Surinamese",
		"value": "Surinamese"
	},
	{
		"code": "TG",
		"label": "Togolese",
		"value": "Togolese"
	},
	{
		"code": "TT",
		"label": "Trinidadian / Tobagonian",
		"value": "Trinidadian / Tobagonian"
	},
	{
		"code": "UZ",
		"label": "Uzbek",
		"value": "Uzbek"
	}
];
export const nationalities_other_old = [
    {
        "value": "Afghan \/ Afghani",
        "label": "Afghan \/ Afghani",
        "code":"AF"
    },
    {
        "value": "American",
        "label": "American",
        "code":"US"
    },
    {
        "value": "Angolan",
        "label": "Angolan",
        "code":"AO"
    },
    {
        "value": "Argentine \/ Argentinian",
        "label": "Argentine \/ Argentinian",
        "code":"AR"
    },
    {
        "value": "Bolivian",
        "label": "Bolivian",
        "code":"BO"
    },
    {
        "value": "Botswanan",
        "label": "Botswanan",
        "code":"BW"

    },
    {
        "value": "Brazilian",
        "label": "Brazilian",
        "code":"BR"
    },
    {
        "value": "Burmese",
        "label": "Burmese",
        "code":"MM"
    },
    {
        "value": "Cambodian",
        "label": "Cambodian",
        "code":"KH"

    },
    {
        "value": "Canadian",
        "label": "Canadian",
        "code":"CA"

    },
    {
        "value": "Chilean",
        "label": "Chilean",
        "code":"CL"
    },
    {
        "value": "Colombian",
        "label": "Colombian",
        "code":"CO"
    },
    {
        "value": "Congolese",
        "label": "Congolese",
        "code":"CD"
    },
    {
        "value": "Cuban",
        "label": "Cuban",
        "code":"CU"
    },
    {
        "value": "Ecuadorian",
        "label": "Ecuadorian",
        "code":"EC"

    },
    {
        "value": "Ethiopian",
        "label": "Ethiopian",
        "code":"ET"
    },
    {
        "value": "Georgian",
        "label": "Georgian",
        "code":"GE"
    },
    {
        "value": "Ghanaian",
        "label": "Ghanaian",
        "code":"GH"
    },
    {
        "value": "Guatemalan",
        "label": "Guatemalan",
        "code":"GT"
    },
    {
        "value": "Iranian",
        "label": "Iranian",
        "code":"IR"
    },
    {
        "value": "Iraqi",
        "label": "Iraqi",
        "code":"IQ"
    },
    {
        "value": "Israeli",
        "label": "Israeli",
        "code":"IL"
    },
    {
        "value": "Ivorian",
        "label": "Ivorian",
        "code":"CI"
    },
    {
        "value": "Jamaican",
        "label": "Jamaican",
        "code":"JM"

    },
    {
        "value": "Jordanian",
        "label": "Jordanian",
        "code":"JO"
    },
    {
        "value": "Kazakh \/ Kazakhstani",
        "label": "Kazakh \/ Kazakhstani",
        "code":"KZ"
    },
    {
        "value": "Kenyan",
        "label": "Kenyan",
        "code":"KE"
    },
    {
        "value": "Laotian \/ Lao",
        "label": "Laotian \/ Lao",
        "code":"LA"
    },
    {
        "value": "Libyan",
        "label": "Libyan",
        "code":"LY"
    },
    {
        "value": "Malagasy",
        "label": "Malagasy",
        "code":"MG"

    },
    {
        "value": "Mexican",
        "label": "Mexican",
        "code":"MX"
    },
    {
        "value": "Mongolian",
        "label": "Mongolian",
        "code":"MN"
    },
    {
        "value": "Mozambican",
        "label": "Mozambican",
        "code":"MZ"
    },
    {
        "value": "Namibian",
        "label": "Namibian",
        "code":"NA"
    },
    {
        "value": "Nigerian",
        "label": "Nigerian",
        "code":"NG"
    },
    {
        "value": "Palestinian",
        "label": "Palestinian",
        "code":"PS"

    },
    {
        "value": "Paraguayan",
        "label": "Paraguayan",
        "code":"PY"
    },
    {
        "value": "Peruvian",
        "label": "Peruvian",
        "code":"PE"
    },
    {
        "value": "Somali \/ Somalian",
        "label": "Somali \/ Somalian",
        "code":"SO"
    },
    {
        "value": "South African",
        "label": "South African",
        "code":"ZA"
    },
    {
        "value": "Sudanese",
        "label": "Sudanese",
        "code":"SD"

    },
    {
        "value": "Tanzanian",
        "label": "Tanzanian",
        "code":"TZ"
    },
    {
        "value": "Turkish",
        "label": "Turkish",
        "code":"TR"
    },
    {
        "value": "Ugandan",
        "label": "Ugandan",
        "code":"UG"
    },
    {
        "value": "Uruguayan",
        "label": "Uruguayan",
        "code":"UY"
    },
    {
        "value": "Venezuelan",
        "label": "Venezuelan",
        "code":"VE"
    },
    {
        "value": "Zambian",
        "label": "Zambian",
        "code":"ZM"
    }];