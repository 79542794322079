import React, { useState, useEffect } from 'react';
import { preventClick } from '../../../lib/helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { FormattedMessage } from 'react-intl';
import { LoadingIcon } from '../../loader/loadingIconNew';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Text } from '../../themes/userq/Text/Text';
import { Logo } from '../../themes/userq/Logo/Logo';
import Cookies from 'js-cookie';

export default function Verify() {

    let { code } = useParams();

    const navigate = useNavigate();

    const [isEmailVerified, setEmailVerified] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Verify | "+process.env.REACT_APP_NAME;
        verifyEmail();
    },[]);

    const verifyEmail = () => {
        
        setLoading(true);

        setErrorMessage(null);

        var verification_code = code;

        
        
        fetch(process.env.REACT_APP_API_END_POINT+'verify_code', {
            method: 'POST',
            body: JSON.stringify({code: verification_code, 'role': 'Researcher'}),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);

            if(response.success){
                
                setEmailVerified(true);

                
                ReactSession.set(
                  "successVerifiedMessage",
                  ""
                );
                /*<FormattedMessage id="Success! Your account has now been verified. Login to get started with UserQ!" /> */

                var date = new Date();
                Cookies.set("expires_at", date.getTime()+30*24*60*60*1000);

                ReactSession.set("token", response.token);
                ReactSession.set("user", response.user);

                // navigate("/researcher/sign-in");
                navigate('/dashboard');

            } else {
                
                setErrorMessage(response.message);
            }
        });
        
    }
    return (
        <div className="login-page-wrapper verifingdata-header">
            {isLoading &&
                <div  className={'dashboard-filter-loader verifyresarcherbox'} style={{height:"100vh"}}>
                    <LoadingIcon />
                    <div>
                    <Text type={"subtitle-1"} fontWeight={"medium-font"}>We are verifying your email...</Text>
                    </div>
                </div>
            }
            {!isLoading &&
            <>
            <div className="researcher-registerheader login-page-header">
                <div className="container">
                    <div className="login-hdr-left">
                        <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                            
                            <Logo theme={"black"} background={"white"} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="verifingdata-wrap">
                <div className="container">
                    <div className="login-left-side">
                        {isLoading &&
                            <div  className={'dashboard-filter-loader verifyresarcherbox'} >
                                <LoadingIcon />
                                <div>
                                <Text type={"subtitle-1"} fontWeight={"medium-font"}>We are verifying your email...</Text>
                                </div>
                            </div>
                        }
                        {!isLoading &&
                            <div>
                                {isEmailVerified &&
                                    <div>
                                        <h1 className="form-heading">Successfully Verified!</h1>
                                        <div className="login-form-inner-data">
                                            <div className="reset-btn-wrap">
                                                <Link to="/sign-in"  className="btn btn-block form-btn">CONTINUE TO DASHBOARD</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!isEmailVerified &&
                                    <div>
                                        <div className={`no-participants-data no-questions-data no-questions-added`}>
                                            <div className="no-page-data-wrap" style={{marginTop:"80px"}}>
                                                <div className="no-data-icon">
                                                    <Icon value={"exclamation-gray"} size={"large"} />
                                                </div>
                                                <Text type={"subtitle-1"} fontWeight="medium-font">
                                                    It looks like this page doesn’t exist.
                                                </Text>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    )
}