import React from "react";
import { Modal } from "react-bootstrap";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";

export default function AIModal({ close, showModal, setSurveyCreatorMode, type }) {
    const handleClose = () => {
        close();
    };

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            centered
            className="fade custom-modal-wrap change-pass-modal"
        >
            <Modal.Body>
                <div className="modal-inner-text">
                    <div className="modal-header-top d-flex justify-content-between align-items-center">
                        <Text type={"h3"}>{type=="generate"? "Generate With AI" : "Regenerate" }</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={handleClose} // Close button logic
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="data-wrap">
                        <Text
                            type={"body-text-2"}
                            fontWeight={"medium-font"}
                            cssClasses={"gray-text"}
                        >
                            {type=="generate"
                            ?
                            "By proceeding with AI-generated questions, your manually added questions will be permanently replaced and cannot be recovered."
                            :
                            "Are you sure you want to regenerate the survey? This action will overwrite your current survey and any changes you've made. If you only need to make adjustments, you can edit the questions directly."
                            }
                        </Text>
                    </div>

                    <div className="modal-btn ai-modal-btns mt-32">
                        {type == "generate"?
                        <>
                        <Button
                            type="secondary"
                            size="large"
                            label="Cancel"
                            onClick={()=>{
                                close();
                            }}
                        />
                        <Button
                            type="primary"
                            size="large"
                            label="Proceed with AI"
                            onClick={()=>{
                                setSurveyCreatorMode("AI");
                                close();
                            }}
                        />
                        </>
                        :
                        <Button
                            iconLeft={
                                <Icon
                                    value={"Ai"}
                                    size={"medium"}
                                    colorClass={"gray-50-svg"}
                                    cssClasses="tag-reverse"
                                />
                            }
                            type="primary"
                            size="large"
                            label="Generate new survey"
                            cssclass={"navy-btn"}
                            onClick={()=>{
                                setSurveyCreatorMode("AI");
                                close();
                            }}
                        />
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
