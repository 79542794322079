import PropTypes from 'prop-types';
import React from "react";
import { Text } from './../Text/Text';
export const Box = ({size,title,children,onClick, cssClasses})=>{
    
    
    return (
        <div className={`box-${size} ${cssClasses}`} onClick={onClick}>
            <div className={`box-title`}><Text type={'body-text-2'} fontWeight={'semi-bold-font'}><>{title}</></Text></div>
            <div className={`box-content`}><>{children}</></div>
        </div>
    )
    
    
}
Box.propTypes = {
    /**
     * Can be small, medium or larget
     */
    children: PropTypes.any,
    size: PropTypes.oneOf(['small','medium','large']),
    title : PropTypes.string,
}
Box.defaultProps = {
    size:"small",
    title:"",
}