//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Modal } from "react-bootstrap";
import React, {useState, useEffect, useRef} from "react";
import toast from "react-hot-toast";
import {changeTestStatus} from "../../../../services/test";
import {atom, useRecoilState,useResetRecoilState} from 'recoil';
import { LoadingIcon } from "../../../loader/loadingIconNew";
import { Button } from "../Button/Button";
import {ToastMessage} from "../ToastMessage/ToastMessage";
import {settings} from "../../../../data/settings";
import {Icon} from "../Icon/Icon";
import {Text} from "../Text/Text";
export default function EndTestModal({openModal,closeModal,test}) {

    const wrapperRef = useRef(null);

    const creditsState = atom({
        key: 'credits',
        default: 0
    });

    //const [credits, setCredits] = useRecoilState(creditsState);
    const reset = useResetRecoilState(creditsState);

    const [unpublishedLoading, setUnpublishedLoading] = useState(false);

    const showSuccessToast = (message) => {

        toast(
            <ToastMessage type={"success"} message={message} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showErrorToast = (error) => {

        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const publishHandler = (e)=>{

        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);
        data.append("status", 'completed');

        setUnpublishedLoading(true);
        changeTestStatus(data, token).then((response) => {
            setUnpublishedLoading(false);

            if (response.success) {
                closeModal(true);


                if(response.refundedCredits > 0){
                    showSuccessToast(`Your test has ended. ${response.refundedCredits} credits have been refunded to your wallet.`)
                    reset();
                }else{
                    showSuccessToast('Your test has ended successfully.')
                }
                //ReactSession.set("credits", response.credits_balance);
                //setCredits(response.credits_balance);
            } else {

                showErrorToast(response.message);
            }
        });
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap confirmation-modal-wrap endTest-modal-wrap"
        >
            <Modal.Body className="" ref={wrapperRef}>
                {unpublishedLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <Text type={'h3'} cssClasses={'red-text'}>Are you sure you want to end this {test.methodology=="Survey"?"survey":"test"}?</Text>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal(false);
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                            </button>
                        </div>
                        <Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={'gray-text'}>By ending this {test.methodology=="Survey"?"survey":"test"}, you’ll stop collecting results. But don’t worry, any
                            unspent credits will be refunded to your account.</Text>

                        <div className="confirm-buttons-wrap">

                            <Button type="secondary" size="large" label={'Cancel'} onClick={() => { closeModal(false); }} />
                            <Button type="primary" size="large" label={test.methodology=="Survey"?"End survey":"End test"} onClick={(e)=>publishHandler(e)} />
                        </div>


                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}