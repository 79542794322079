export const settings = {"publishing_credits":10, 
"card_sorting_credits":6,
"preference_test_credits":2,
"tree_test_credits":6,
"survey_credits":6, 
"publishing_credits_card_sorting":10,
"publishing_credits_preference_test":10,
"publishing_credits_tree_test":10,
"publishing_credits_survey":10,
"publishing_credits_five_seconds_test":10,
"toast_duration":5000};
