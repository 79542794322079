import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Text } from "../../themes/userq/Text/Text";

export default function AccountUnblockedModal({
    open,
    title,
    subtitle,
    message,
    btn,
    close,
}) {
    const closeModal = () => {
        close();
    };
    

    const navigate = useNavigate();
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap account-unblocked-modal new-tester-given-test"
            onHide={() => {
                closeModal();
            }}
        >
            
            <Modal.Body className="">
            <img className="congrts-img"
                                    src={process.env.REACT_APP_URL + "img/congrts.svg"}
                                    alt="cross"
                                />

                <div className="congrats-modal-text">

                    <Text type={"h1"}>{title}</Text>
                    <Text type={"h2"}>{subtitle}</Text>
                    <Text type={"body-text-2"}>We are happy to inform you that the issue has been successfully resolved, and your account has been reinstated. To prevent any potential future blocks, we kindly request that you review our <a class='faq-support-link' href={process.env.REACT_APP_URL+"t/tester-guidelines"} target="_blank">guidelines</a>. Thank you for your understanding.</Text>
                    
                    
                    <div className="congrts-modal-wrap">
                        {btn}
                    </div>

                </div> 
            </Modal.Body>
        </Modal>
    );
}
