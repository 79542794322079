import React from "react";
import { useState, useEffect } from "react";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";

export const RatingScaleTester = ({ type, scale, value, onChange, labels }) => {
    const [iconValues, setIconValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        if (scale == 3) {
            setIconValues([1, 2, 3]);
        }
        else if (scale == 5) {
            setIconValues([1, 2, 3, 4, 5]);
        }
        else if (scale == 7) {
            setIconValues([1, 2, 3, 4, 5, 6, 7]);
        }
        else {
            setIconValues([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        }
    }, [scale]);

    return (
        <>
            <div className="tester-rating-scale-holder d-flex">
                {iconValues.map((value, index) => {
                    return (
                        <>
                            {type == "stars" ?
                                <RatingScaleIcon type={"star"} scale={scale} value={value} onClick={() => {
                                    onChange(value);
                                    setSelectedValue(value)
                                }} selected={index < selectedValue ? true : false} />
                                :
                                <RatingScaleIcon type={type} scale={scale} value={value} onClick={() => {
                                    onChange(value);
                                    setSelectedValue(value)
                                }} selected={value == selectedValue ? true : false} />
                            }

                        </>
                    )
                })}
            </div>
            
            {labels && (
                <>
                {!(labels[0] == "" && labels[1] == "" && labels[2] == "") &&
                <div className="rating-scale-labels d-flex justify-content-between">
                    {labels.map((value, index) => {
                        return (
                            <div className="scale-label" id={"label-" + index}>{value}</div>
                        )
                    })}
                </div>
                }
                </>
            )
            }
        </>
    );
};