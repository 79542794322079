import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import moment from 'moment';
import { HamburgerMenu } from './../HamburgerMenu/HamburgerMenu';
import { Icon } from './../Icon/Icon';
import { Button } from "../Button/Button";
import ReportTesterModal from "../Modal/report_tester";
import { EmptySearchPlaceholder } from "../EmptySearchPlaceholder/EmptySearchPlaceholder";
import { useLocation } from "react-router-dom";

export const ByUserTableLikert = ({ answerArray, methodology, tableClass, hideParticipantModal, reportAnswerModal, test, loadMore, loadMoreLoading, subquestionIndex, searchValue, totalSubQuestions, moreDataAvailable, totalResponses, clearSearch }) => {

    
    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [metaData, setMetaData] = useState({ questionType: "", totalResultCount: 0, userType: "", aggregate_total_voters: 0, sort_time: "", sort_result_id: "asc", sort_date: "", sort_vote: "", load_more: "", test_question_id: "", page: 1 });
    const location = useLocation();

    if (searchValue != "") {
        const newanswerArray = answerArray.filter(items => items.option[subquestionIndex].toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        answerArray = newanswerArray
    }

    // highlight text if matches
    const escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    );

    const [reportTesterModal, setReportTesterModal] = useState({
        open: false,
        test_result_id: ''
    });

    const Highlight = ({ children = '' }) => {
        const patt = new RegExp(`(${escapeRegExp(searchValue)})`, 'i');
        const parts = String(children).split(patt);

        if (searchValue) {
            return parts.map((part, index) => (
                patt.test(part) ? <mark className={'search-highlight'} key={index}>{part}</mark> : part
            ));
        } else {
            return children;
        }
    };

    const calculateTime = (time) => {
        var d = Math.floor(time / (3600 * 24));
        var h = Math.floor(time % (3600 * 24) / 3600);
        var m = Math.floor(time % 3600 / 60);
        var s = Math.floor(time % 60);

        if (m > 0) {
            return (`${m}min ${s}sec`);

        } else if (s > 0) {
            return (` ${s}sec`);
        } else {
            return ('-');
        }
    }

    const sortData = (sortBy) => {
        if (sortBy == "date") {
            if (metaData.sort_date === 'asc') {
                const newArray = answerArray.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1);
                answerArray = newArray;
                setMetaData({ ...metaData, sort_date: "desc" });
            } else {
                const newArray = answerArray.sort((a, b) => (a.updated_at > b.updated_at) ? 1 : -1);
                answerArray = newArray;
                setMetaData({ ...metaData, sort_date: "asc" });
            }
        }
        if (sortBy == "time") {
            if (metaData.sort_time === 'asc') {
                const newArray = answerArray.sort((a, b) => (a.time_spent < b.time_spent) ? 1 : -1);
                answerArray = newArray;
                setMetaData({ ...metaData, sort_time: "desc" });
            } else {
                const newArray = answerArray.sort((a, b) => (a.time_spent > b.time_spent) ? 1 : -1);
                answerArray = newArray;
                setMetaData({ ...metaData, sort_time: "asc" });

            }
        }
        if (sortBy == "result_id") {
            if (metaData.sort_result_id === 'asc') {
                const newArray = answerArray.sort((a, b) => (a.result_id < b.result_id) ? 1 : -1);
                answerArray = newArray;
                setMetaData({ ...metaData, sort_result_id: "desc" });
            } else {
                const newArray = answerArray.sort((a, b) => (a.result_id > b.result_id) ? 1 : -1);
                answerArray = newArray;
                setMetaData({ ...metaData, sort_result_id: "asc" });

            }
        }
    }
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;
    
        // Update state based on the scroll position
        setIsScrolled(scrolled);
      };
      useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;
    
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
    
          // Remove the event listener when the component unmounts
          return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
          };
        }
      }, []);
    return (
        <>
            <div ref={scrollContainerRef} className={`max-height-table  ${isScrolled ? 'scrolled' : ''} ${tableClass?tableClass:''} ${answerArray && answerArray.length>8 ? "responsive-table-scroll":""}`}>
                <table className="table byuser-table byuser-table-likert">
                    <thead>
                        <tr>
                            <th className="bd-thcol-1">
                                <div className={`justify-content-between`}>
                                Participant
                            </div></th>
                            <th className="bd-thcol-2">Answer</th>
                            {methodology && methodology=="preference-test" &&
                            <th className="bd-thcol-3">Preferred design</th>
                            }
                            <th className={`${!methodology?'bd-thcol-3':'bd-thcol-4'}`}>
                                <div onClick={() => sortData('time')} className={`${(metaData.sort_time) ? metaData.sort_time : ''} col-sort justify-content-between`}>
                                    Time spent <span>
                                        {/* {metaData.sort_time ?
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-applied.svg"
                                        } alt="icon" width={20} />
                                        :
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-icon.svg"
                                        } alt="icon" width={20} />
                                    } */}

                                        {metaData.sort_time == "asc" &&
                                            <Icon value={'sortup'} />
                                        }
                                        {metaData.sort_time == "desc" &&
                                            <Icon value={'sortdown'} />
                                        }
                                        {!metaData.sort_time &&
                                            <Icon value={'sort-icon'} />
                                        }


                                    </span>
                                </div>
                            </th>
                            <th className={`${!methodology?'bd-thcol-4':'bd-thcol-5'}`}>
                                <div onClick={() => sortData('date')} className={`${(metaData.sort_date) ? metaData.sort_date : ''}  col-sort justify-content-between`}>
                                    Date <span>
                                        {/* {metaData.sort_date ?
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-applied.svg"
                                        } alt="icon" width={20} />
                                        :
                                        <img src={
                                            process.env.REACT_APP_URL + "img/sort-icon.svg"
                                        } alt="icon" width={20} />
                                    } */}

                                        {metaData.sort_date == "asc" &&
                                            <Icon value={'sortup'} />
                                        }
                                        {metaData.sort_date == "desc" &&
                                            <Icon value={'sortdown'} />
                                        }
                                        {!metaData.sort_date &&
                                            <Icon value={'sort-icon'} />
                                        }

                                    </span>
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {answerArray && answerArray.map((result, index) => {

                            return (
                                <tr id={`hide-row-${result.id}`} className={` ${(result.result && result.result.is_reported) ? 'disabled-row-1' : ''}`}>
                                    <td className="bd-q-pat-col">

                                        {result.result &&
                                        (result.result.user_type === 'Unknown')?result.result.user_type+" ":result.result.user_type
                                        }

                                        {result.result && result.result.result_id ?
                                            result.result.result_id
                                            :
                                            index + 1
                                        }

                                    </td>
                                    <td className="bd-q-ans-col">
                                        <div className={`bd-qu-td-ans ${test.language == "ar" ? "arabic-font" : ""}`}>

                                            {result.type === 'likertscale' &&

                                                <p key={index} className={`${test.language == "ar" ? "arabic-font" : ""}`}>
                                                    <Highlight>{result.option[subquestionIndex]}</Highlight>
                                                </p>


                                            }
                                        </div>
                                    </td>
                                    {methodology && methodology=="preference-test" &&
                                    <td className="bd-preferred-design-col">{result.preferred_design?result.preferred_design:"-"}</td>
                                    }
                                    <td className="bd-time-col">{calculateTime(result.time_spent / totalSubQuestions)}</td>
                                    <td className="bd-q-date-col">
                                        <div className="bd-col-action-wrap d-flex">
                                            <span>{moment(result.created_at).format('DD/MM/Y')}</span>

                                        </div>
                                    </td>
                                    <td className="tableDropdown">
                                        {result.result && result.result.user_type !== 'Unknown' && (location.pathname.substr(1, 1) !== 'v') &&
                                            <span
                                                className="hamburger-menu ">

                                                <Dropdown className="dropdown">
                                                    <Dropdown.Toggle
                                                        id={"dropdownMenuButton" + result.id}
                                                        className="dropdown-toggle"
                                                    >
                                                        <span className="menu-item">
                                                            <Icon value={'Quick menu'} colorClass={'gray-900-svg hamburger-menu-img'}/>
                                                        </span> 
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className={'hamburger-items'}>
                                                        <div className="drop-down-link-hold byuser-table-link-hold">
                                                            {/*<Dropdown.Item onClick={() => hideParticipantModal(result.result.id, (result.result && result.result.is_hidden) ? true : false)}>*/}
                                                            {/*    <span>{(result.result && result.result.is_hidden) ? 'Show participant' : 'Hide participant'}</span>*/}
                                                            {/*    <Icon value="eye" size="medium" />*/}
                                                            {/*</Dropdown.Item>*/}
                                                            <Dropdown.Item onClick={() => {
                                                                // open report tester modal
                                                                setReportTesterModal({
                                                                    open: true,
                                                                    test_result_id: result.result.id
                                                                });
                                                                //reportAnswerModal(result.result.id)
                                                            }}>
                                                                <span>Report participant</span>
                                                                <Icon value="warning" size="medium" />
                                                            </Dropdown.Item>
                                                        </div>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        {answerArray && answerArray.length == 0 && (
                            <tr>
                                <td colSpan={5} className="border-0">
                                    <div className="text-center">
                                        <EmptySearchPlaceholder clearSearch={clearSearch} cssClass="by-user-placeholder likert-byuser-placeholder" />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {answerArray && answerArray.length != 0 &&
                <>
                    {moreDataAvailable && !loadMoreLoading && (
                        <div className={"load-more-data"}>
                            <Button type="primary"
                                size={`large`}
                                label="Load more"
                                onClick={() => loadMore()}
                            />
                        </div>
                    )}
                    {moreDataAvailable && loadMoreLoading && (
                        <div className={"load-more-data"}>
                            <Button type="primary"
                                size={`large`}
                                onClick={() => { }}
                                microLoading={true}
                            />
                        </div>
                    )}
                </>}
            <ReportTesterModal
                test={test}
                openModal={reportTesterModal.open}
                selectedTestResultId={reportTesterModal.test_result_id}
                close={() => {
                    setReportTesterModal({ ...reportTesterModal, open: false });
                }}
                confirm={(type, totalParticipantSelected) => {

                    // set reported data
                    // setReportedData({
                    //     'status':type,
                    //     'totalSelectedParticipant':totalParticipantSelected
                    // });
                    // isReportedTester(true);
                    setReportTesterModal({ ...reportTesterModal, open: false });
                    sessionStorage.setItem("is_reported_tester", "true");

                    // hide instruction model if user has submit request
                    // if(type !== 'draft'){
                    //     setConfirmedTesterReportModal(true);
                    // }
                }}
            />
        </>
    )


}

