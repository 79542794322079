import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Text } from "../Text/Text";
import { HamburgerMenu } from "../HamburgerMenu/HamburgerMenu";
import PropTypes from "prop-types";
import { CircularNameIcon } from "../CircularNameIcon/CircularNameIcon";
import "./project_card.css";
import moment from "moment";
import { Icon } from "../Icon/Icon";
import Skeleton from "react-loading-skeleton";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Tooltip } from "../Tooltip/Tooltip";
import { containsArabic } from "../../../../lib/helpers";
export const ProjectCard = ({
    hideTeamMembers,
    project,
    onRemove,
    pinProject,
    unpinProject,
    pinLoading,
    openProjectSettings,
    isLoading,
    key,
}) => {
    const ref = useRef();

    const [hamburgerMenu, sethamburgerMenu] = useState([
        {
            name: "Unpin project",
            itemClass: "",
            isLink: false,
        },
        {
            name: "Settings",
            itemClass: "",
            onClick: () => {
                openProjectSettings(project);
            },
            isLink: false,
        },
        {
            name: "Delete project",
            itemClass: "red-text",
            onClick: () => {
                onRemove(project.id, project.tests_count, teamMembers.length);
            },
            isLink: false,
        },
    ]);

    //team Member

    const [teamMembers, setTeamMembers] = useState([]);

    const [projectCreator, setProjectCreator] = useState([]);

    const [backgroundColor, setBackgroundColor] = useState(null);

    const tooltipText =
        projectCreator && projectCreator.status === "active"
            ? projectCreator.name
            : "Unknown user. This user is no longer part of this workspace";

    /*const teamMembers = [
        { name: "Jane Smith", status: "accepted", color: "#793EAB" },
        { email: "kohn@gmail.com", role: "Admin", status: "accepted" },
        { name: "Tob Johnson", color: "#793EAB" },
        { name: "John Doe", status: "accepted", color: "#D7A56A" },
        { name: "Jane Smith", status: "notAccepted" },
        { name: "Bob Johnson", status: "accepted" },
        { name: "Kamal", status: "notAccepted" },

        { email: "fohn@gmail.com", role: "Admin", status: "accepted" },
        { email: "fohn@gmail.com", role: "Admin", status: "accepted" },
        { email: "fohn@gmail.com", role: "Admin", status: "accepted" },
        { email: "fohn@gmail.com", role: "Admin", status: "accepted" },
    ];*/

    const maxVisibleMembers = 5;

    useEffect(() => {
        if (isLoading) {
            return;
        }
        let team_members = [];

        var allowed_delete = false;

        let user = ReactSession.get("user");

        if (user && user.user_id === project.user_id) {
            allowed_delete = true;
        }
        project.workspace_project_team_members.forEach(function (member) {
            if (member.user_id === project.user_id) {
                setBackgroundColor(member.workspace_team_member && member.workspace_team_member.color);
                setProjectCreator({
                    name: member.user
                        ? member.user.first_name + " " + member.user.last_name
                        : "",
                    color: member.workspace_team_member && member.workspace_team_member.color,
                    status: member.status,
                    workspace_status: member.workspace_team_member && member.workspace_team_member.status,
                });
            }

            if (
                user &&
                user.user_id === member.user_id &&
                member.workspace_team_member &&
                member.workspace_team_member.role === "admin"
            ) {
                allowed_delete = true;
            }
            // if(member.user_id != project.user_id){
            if (member.workspace_team_member && member.status == "active") {
                team_members.push({
                    name: member.user
                        ? member.user.first_name + " " + member.user.last_name
                        : "",
                    color: member.workspace_team_member.color,
                    status: member.status,
                });
            }
            //}
        });

        setTeamMembers(team_members);

        if (allowed_delete) {
            sethamburgerMenu([
                {
                    name: project.pinned_at ? "Unpin project" : "Pin project",
                    itemClass: "",
                    onClick: () => {
                        if (project.pinned_at) {
                            unpinProject(project.id);
                        } else {
                            pinProject(project.id);
                        }
                    },
                    isLink: false,
                },
                {
                    name: "Settings",
                    itemClass: "",
                    onClick: () => {
                        openProjectSettings(project);
                    },
                    isLink: false,
                },
                {
                    name: "Delete project",
                    itemClass: "red-text",
                    onClick: () => {
                        onRemove(
                            project.id,
                            project.tests_count,
                            team_members.length
                        );
                    },
                    isLink: false,
                },
            ]);
        } else {
            sethamburgerMenu([
                {
                    name: project.pinned_at ? "Unpin project" : "Pin project",
                    itemClass: "",
                    onClick: () => {
                        if (project.pinned_at) {
                            unpinProject(project.id);
                        } else {
                            pinProject(project.id);
                        }
                    },
                    isLink: false,
                },
                {
                    name: "Settings",
                    itemClass: "",
                    onClick: () => {
                        openProjectSettings(project);
                    },
                    isLink: false,
                },
            ]);
        }
    }, [project]);

    return (
        <div
            className={`project-card-wrap p-20 ${
                isLoading ? "project-card-loading" : ""
            }`}
            key={key}
        >
            <div className={`project-card-data`}>
                {isLoading ? (
                    <>
                        <div className={"project-card-top-data"}>
                            <div className="p-card-top-hold  d-flex align-items-center justify-content-between">
                                <div className="project-card-head-left">
                                    <Skeleton
                                        width={200}
                                        height={24}
                                        style={{
                                            borderRadius: "100px",
                                        }}
                                    />
                                </div>
                                <div className="project-card-head-right d-flex align-items-center">
                                    <Skeleton
                                        width={24}
                                        height={24}
                                        style={{
                                            borderRadius: "100px",
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="project-creaded-data d-flex align-items-center mt-12">
                                <Skeleton
                                    width={24}
                                    height={24}
                                    style={{
                                        borderRadius: "100px",
                                    }}
                                />

                                <Skeleton
                                    width={150}
                                    height={24}
                                    style={{
                                        borderRadius: "100px",
                                        marginLeft: "10px",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="p-card-footer d-flex justify-content-between">
                            <div className="p-card-lft-footer">
                                <Text type={"caption"}>
                                    <Skeleton
                                        width={60}
                                        height={20}
                                        style={{
                                            borderRadius: "100px",
                                            marginLeft: "0px",
                                        }}
                                    />
                                </Text>
                                <Text type={"h1"}>
                                    <Skeleton
                                        width={32}
                                        height={32}
                                        style={{
                                            borderRadius: "100px",
                                            marginLeft: "0px",
                                        }}
                                    />
                                </Text>
                            </div>
                            {!hideTeamMembers && (
                                <div className="p-card-right-footer">
                                    <Text type={"caption"}>
                                        {" "}
                                        <Skeleton
                                            width={100}
                                            height={20}
                                            style={{
                                                borderRadius: "100px",
                                                marginLeft: "0px",
                                            }}
                                        />
                                    </Text>
                                    <div className="circular-name-icon-wrap d-flex align-items-center skelton-circular-load">
                                        <Skeleton
                                            width={24}
                                            height={24}
                                            style={{
                                                borderRadius: "100px",
                                                marginLeft: "0px",
                                            }}
                                        />
                                        <Skeleton
                                            width={24}
                                            height={24}
                                            style={{
                                                borderRadius: "100px",
                                                marginLeft: "0px",
                                            }}
                                        />
                                        <Skeleton
                                            width={24}
                                            height={24}
                                            style={{
                                                borderRadius: "100px",
                                                marginLeft: "0px",
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={"project-card-top-data"}>
                            <div className="p-card-top-hold  d-flex align-items-center justify-content-between">
                                <div
                                    className="project-card-head-left"
                                    ref={ref}
                                >
                                    <Link
                                        to={
                                            "/wsp/" +
                                            project.workspace_id +
                                            "/p/" +
                                            project.id
                                        }
                                        className="card-top-link"
                                    >
                                        <Text
                                            type={`subtitle-2`}
                                            cssClasses={`${
                                                containsArabic(project.title)
                                                    ? "arabic-font"
                                                    : ""
                                            } `}
                                        >
                                            {project.title}
                                        </Text>
                                    </Link>
                                </div>
                                <div className="project-card-head-right d-flex align-items-center">
                                    {pinLoading == project.id ? (
                                        <Skeleton
                                            width={24}
                                            height={24}
                                            style={{
                                                borderRadius: "100px",
                                            }}
                                        />
                                    ) : (
                                        <>
                                            {project.pinned_at && (
                                                <Icon
                                                    value="pin"
                                                    size="medium"
                                                    colorClass="gray-900-svg"
                                                />
                                            )}
                                        </>
                                    )}
                                    <HamburgerMenu items={hamburgerMenu} />
                                </div>
                            </div>

                            <Link
                                to={
                                    "/wsp/" +
                                    project.workspace_id +
                                    "/p/" +
                                    project.id
                                }
                                className="card-top-link"
                            >
                                <div
                                    className={`project-creaded-data d-flex align-items-center mt-12 ${
                                        tooltipText && tooltipText.length < 20
                                            ? "small-tooltip"
                                            : "big-tooltip"
                                    }`}
                                >
                                    {projectCreator.status == "active" && (
                                        <CircularNameIcon
                                            style={
                                                projectCreator &&
                                                projectCreator.status ==
                                                    "active"
                                                    ? { backgroundColor }
                                                    : {
                                                          backgroundColor:
                                                              "#9C9C9C",
                                                      }
                                            }
                                            tooltip={tooltipText}
                                            label={
                                                projectCreator &&
                                                projectCreator.status ==
                                                    "active" ? (
                                                    projectCreator.name &&
                                                    projectCreator.name.length >
                                                        0 ? (
                                                        projectCreator.name
                                                            .toUpperCase()
                                                            .charAt(0)
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    <>
                                                        <Icon
                                                            value={
                                                                "unknown-user"
                                                            }
                                                            size={"small"}
                                                            colorClass={
                                                                "gray-200-svg"
                                                            }
                                                        />
                                                    </>
                                                )
                                            }
                                        />
                                    )}
                                    {projectCreator.status ==
                                        "project_left" && (
                                        <>
                                            {projectCreator.workspace_status ==
                                            "workspace_removed" ? (
                                                <CircularNameIcon
                                                    style={{
                                                        backgroundColor:
                                                            "#9C9C9C",
                                                    }}
                                                    tooltip={
                                                        "Unknown user. This user is not longer part of this workspace"
                                                    }
                                                    label={
                                                        <>
                                                            <Icon
                                                                value={
                                                                    "unknown-user"
                                                                }
                                                                size={"small"}
                                                                colorClass={
                                                                    "gray-200-svg"
                                                                }
                                                            />
                                                        </>
                                                    }
                                                />
                                            ) : (
                                                <CircularNameIcon
                                                    style={
                                                        projectCreator &&
                                                        projectCreator.status ==
                                                            "active"
                                                            ? {
                                                                  backgroundColor,
                                                              }
                                                            : {
                                                                  backgroundColor:
                                                                      "#FFFFFF",
                                                                  color: "#000000",
                                                                  border: "2px dashed #000000",
                                                              }
                                                    }
                                                    tooltip={
                                                        projectCreator &&
                                                        projectCreator.status ==
                                                            "active"
                                                            ? projectCreator.name
                                                            : projectCreator.name +
                                                              ". This user is not longer part of this project"
                                                    }
                                                    label={
                                                        projectCreator &&
                                                        (projectCreator.status ==
                                                            "active" ||
                                                            projectCreator.status ==
                                                                "project_left") ? (
                                                            projectCreator.name
                                                                .toUpperCase()
                                                                .charAt(0)
                                                        ) : (
                                                            <>
                                                                <Icon
                                                                    value={
                                                                        "unknown-user"
                                                                    }
                                                                    size={
                                                                        "small"
                                                                    }
                                                                    colorClass={
                                                                        "gray-200-svg"
                                                                    }
                                                                />
                                                            </>
                                                        )
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    {projectCreator.status ==
                                        "workspace_removed" && (
                                        <CircularNameIcon
                                            style={
                                                projectCreator &&
                                                projectCreator.status ==
                                                    "active"
                                                    ? { backgroundColor }
                                                    : {
                                                          backgroundColor:
                                                              "#9C9C9C",
                                                      }
                                            }
                                            tooltip={
                                                projectCreator &&
                                                projectCreator.status ==
                                                    "active"
                                                    ? projectCreator.name
                                                    : "Unknown user. This user is not longer part of this workspace"
                                            }
                                            label={
                                                projectCreator &&
                                                projectCreator.status ==
                                                    "active" ? (
                                                    projectCreator.name
                                                        .toUpperCase()
                                                        .charAt(0)
                                                ) : (
                                                    <>
                                                        <Icon
                                                            value={
                                                                "unknown-user"
                                                            }
                                                            size={"small"}
                                                            colorClass={
                                                                "gray-200-svg"
                                                            }
                                                        />
                                                    </>
                                                )
                                            }
                                        />
                                    )}

                                    <Text type="caption">
                                        Created on:{" "}
                                        {project.created_at
                                            ? moment(project.created_at).format(
                                                  "DD/MM/YY"
                                              )
                                            : "-"}{" "}
                                    </Text>
                                </div>
                            </Link>
                        </div>

                        <Link
                            to={
                                "/wsp/" +
                                project.workspace_id +
                                "/p/" +
                                project.id
                            }
                            className="card-top-link"
                        >
                            <div className="p-card-footer d-flex justify-content-between">
                                <div className="p-card-lft-footer">
                                    <Text
                                        type={"caption"}
                                        cssClasses={"d-flex align-items-center"}
                                    >
                                        Tests{" "}
                                        {project.live_tests_count > 0 && (
                                            <span className="info-wrap test-tooltip-hold">
                                                <span className="live-test-indi"></span>
                                                <Tooltip
                                                    data={
                                                        project.live_tests_count +
                                                        " Live test" +
                                                        (project.live_tests_count >
                                                        1
                                                            ? "s"
                                                            : "")
                                                    }
                                                />
                                            </span>
                                        )}
                                    </Text>
                                    <Text type={"h1"}>
                                        {project.tests_count}
                                    </Text>
                                </div>
                                {!hideTeamMembers && (
                                    <div className="p-card-right-footer">
                                        <Text type={"caption"}>
                                            Team members
                                        </Text>
                                        <div className="circular-name-icon-wrap d-flex align-items-center">
                                            {teamMembers
                                                .slice(0, maxVisibleMembers)
                                                .map((user, index) => {
                                                    let backgroundColor;
                                                    backgroundColor =
                                                        user.color;
                                                    const label = user.name
                                                        ? user.name &&
                                                          user.name.length > 0
                                                            ? user.name
                                                                  .charAt(0)
                                                                  .toUpperCase()
                                                            : ""
                                                        : user.email &&
                                                          user.email.length > 0
                                                        ? user.email
                                                              .charAt(0)
                                                              .toUpperCase()
                                                        : "";
                                                    return (
                                                        <CircularNameIcon
                                                            tooltip={user.name}
                                                            style={{
                                                                backgroundColor,
                                                            }}
                                                            key={index}
                                                            label={label}
                                                        />
                                                    );
                                                })}

                                            {teamMembers.length >
                                                maxVisibleMembers && (
                                                <CircularNameIcon
                                                    style={{
                                                        backgroundColor:
                                                            "#676767",
                                                    }}
                                                    key={maxVisibleMembers}
                                                    label={`+${
                                                        teamMembers.length -
                                                        maxVisibleMembers
                                                    }`}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};
