import LinearAxisChart from "./components/linear_axis_chart";
import {Sankey} from "react-sankey-chart";
import SankeyChart from "./components/sankey_chart";
import SankeyChartD3 from "./components/sankey_chart_d3";
import SankeyChartVis from "./components/sankey_chart_vis";

export default function TestResultTreeTestSample(){
    return <>
    <LinearAxisChart />
    {/*<Sankey graph={
                [
                    {
                        level: 1,    
                        nodes: [

                            {
                                'id': '1',    
                                "nodeLabel": "A",   
                                "color": 'red',
                                "edges": [
                                    {
                                        "nodeId": "4",
                                        "edgeWeight": 40
                                    }
                                    , 
                                    {
                                        "nodeId": "5",
                                        "edgeWeight": 10
                                    },
                                    {
                                        "nodeId": "6",
                                        "edgeWeight": 10
                                    },
                                    {
                                        "nodeId": "7",
                                        "edgeWeight": 10
                                    }
                                ],
                            },
                            {
                                'id': '2',    
                                "nodeLabel": "Z",   
                                "color": 'red',           // will be used in the edges gradient color 
                                "edges": [
                                ],
                            },
                            {
                                'id': '3',    
                                "nodeLabel": "B",   
                                "color": 'red',           // will be used in the edges gradient color 
                                "edges": [
                                ],
                            },
                        ]
                    },
                    {
                        level: 2,
                        nodes: [

                            {
                                'id': '4',
                                "nodeLabel": "A1",
                                "color": 'blue',
                                "edges": [
                                ],
                            },
                            {
                                'id': '5',
                                "nodeLabel": "A2",
                                "color": 'green',
                                "edges": [
                                ],
                            },
                            {
                                'id': '6',
                                "nodeLabel": "A3",
                                "color": 'green',
                                "edges": [
                                ],
                            },
                            {
                                'id': '7',
                                "nodeLabel": "C",
                                "color": 'green',
                                "edges": [
                                ],
                            },
                        ]
                    }
                ]
                    
            } height={400} width={500} />
        <SankeyChartD3 height={400} width={500} /> */}
        <SankeyChart height={400} width={800} />
    </>
}