import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Icon } from './../Icon/Icon';
import { Button } from './../Button/Button';
import { Text } from "../Text/Text";
import { CheckBox } from './../CheckBox/CheckBox';
import { RadioButton } from './../RadioButton/RadioButton';
import { Chip } from './../Chips/Chips';
import { Tag } from './../Tag/Tag';
import { Input } from './../Input/Input';
import toast from "react-hot-toast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Options from "./options";
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { FilterButtons } from "../FilterButtons/FilterButtons";
import { DropdownComponent } from "../Dropdown/Dropdown";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { InputPlaceholder } from "../Input/InputPlaceholder";
import { GuideLink } from "../GuideLink/GuideLink";
import ScreenQuestionOptions from "./screen_question_options";

export const ScreeningQuestionInput = ({ questionType, cancelQuestion, addQuestions, language, questionno, addQuestionErrors, onAddQuestion}) => {

    const [questionFormValues, setQuestionFormValues] = useState({
        question: ""
    });
    const [questionFormErrors, setQuestionFormErrors] = useState({
        question: null,
        error_class: null,
    });
    const [optionErrors, setOptionErrors] = useState([null,null]);
    const [allOptions, setAllOptions] = useState(["",""]);
    const [allScreeningOptions, setAllScreeningOptions] = useState(["Good fit (continue)","Not a fit (end test)"]);
    const wrapperRef = useRef(null);


    useEffect(()=>{
        
            setQuestionFormErrors({...addQuestionErrors});
            console.log(addQuestionErrors)

            setOptionErrors([...addQuestionErrors.options]);
    
    },[addQuestionErrors])

    

    const setQuestion = (name) => {
        return ({ target: { value } }) => {
            onAddQuestion({...questionFormValues, question:value, options: allOptions, screeningOptions:allScreeningOptions });
            setQuestionFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            if (value.length > 0) {
                setQuestionFormErrors({ question: null, error_class: null });
            }
        };
    };
    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    }

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const closeOptionHandler = (index) => {
        const list = [...allOptions];
        list.splice(index, 1);
        setAllOptions(list);

        const listScreen = [...allScreeningOptions];
        listScreen.splice(index, 1);
        setAllScreeningOptions(listScreen);

        const option_errors = [...optionErrors];
        option_errors.splice(index, 1);
        setOptionErrors(option_errors);

        onAddQuestion({question: questionFormValues.question, options:list, screeningOptions: listScreen});
    };
    
    const saveOptionHandler = (index, value) => {
        allOptions.splice(index, 1, value);
        setAllOptions([...allOptions]);

        onAddQuestion({question:questionFormValues.question, options:allOptions, screeningOptions:allScreeningOptions});
    };
    const saveScreeningOptionHandler = (index, value) => {
        allScreeningOptions.splice(index, 1, value);
        setAllScreeningOptions([...allScreeningOptions]);

        onAddQuestion({question:questionFormValues.question, options:allOptions, screeningOptions:allScreeningOptions});
    };
    
    const showQuestionFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, questionFormErrors);
        for (var key in questionFormErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;
        formErrorsLocal["error_class"] = "input_error";
        setQuestionFormErrors(formErrorsLocal);
    };
    const checkBtnstatus = () => {
        var error = false;

        if (questionFormValues.question === "") {
            
             error = true;
        } 
        let nulloptioncount = 0;
        allOptions.forEach((element) => {
            if (element !== "") {
                nulloptioncount = nulloptioncount + 1;
            }
            
        });
        var not_fit_count = 0;

        var fit_count = 0;

        const notEmptyOptions = [...allOptions];
        const notEmptyScreeningOptions = [...allScreeningOptions];
        for (var i = 0; i < notEmptyOptions.length; i++) {
            if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                notEmptyOptions.splice(i, 1);
                notEmptyScreeningOptions.splice(i,1);
                i--;
            }
        }
        notEmptyScreeningOptions.forEach(function(item){
            if(item=="Not a fit (end test)"){
                not_fit_count++;
            }
            if(item=="Good fit (continue)"){
                fit_count++;
            }
        })
        if (nulloptioncount < 2) {

            
            
                error=true;
            
        } else if(fit_count==0){
            
            error=true;
        } else if(not_fit_count==0){
           
            error=true;
        }
        

        return error;
    }
    const handleQuestionSubmit = (showToast) => {
        
        if (questionFormValues.question === "") {
            showQuestionFormError("question", "Required field");
            document.getElementById("question").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        
        } else {


            let nulloptioncount = 0;

            let option_error = false;

            allOptions.forEach((item,index) => {
                
                optionErrors[index] = null;
                
            });
            
            allOptions.forEach((element,index) => {
                if (element !== "") {
                    nulloptioncount = nulloptioncount + 1;
                }
            });
            for(var i = 0; i < allOptions.length; i++) {
                if(!allOptions[i]){
                    option_error = true;
                    optionErrors[i] = "Required field";
                    document.getElementById("question-option-"+i).scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    break;
                }
            }
           
           
            setOptionErrors([...optionErrors]);

            const notEmptyOptions = [...allOptions];
            const notEmptyScreeningOptions = [...allScreeningOptions];
            for (var i = 0; i < notEmptyOptions.length; i++) {
                if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                    notEmptyOptions.splice(i, 1);
                    notEmptyScreeningOptions.splice(i,1);
                    i--;
                }
            }
            var not_fit_count = 0;

            var fit_count = 0;

            notEmptyScreeningOptions.forEach(function(item){
                if(item=="Not a fit (end test)"){
                    not_fit_count++;
                }
                if(item=="Good fit (continue)"){
                    fit_count++;
                }
            })
            
            if(!option_error){
                if ( nulloptioncount !== 0) {
                    // if (allOptions.length < 2) {
                    if (nulloptioncount < 2) {
                        if(showToast){
                            showError(
                                "Single choice questions require at least two options."
                            );
                        } 
                    } else if(fit_count==0){
                        showError(
                            "Please include a response that qualifies for the good fit logic."
                        );
                    } else if(not_fit_count==0){
                        showError(
                            "Please include a response that qualifies for the no fit logic."
                        );
                    } else {
                        if (addQuestions) {
                            addQuestions({
                                questionType: questionType,
                                question: questionFormValues.question,
                                options: notEmptyOptions,
                                screeningOptions: notEmptyScreeningOptions
                            });
                            setQuestionFormValues({
                                question: ""
                            });
                            setAllOptions([]);

                            setAllScreeningOptions([]);
                        }

                    }
                } else {
                    if(showToast){
                        showError("Your answer is empty - please enter option.");
                    }
                }
            }
        }
    };

    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName != "svg") {
                        if (event.target.nodeName != "path") {
                            if (questionFormValues.question !== "") {
                                if (!event.target.classList.contains('icon-medium')) {
                                    if (!event.target.classList.contains('toast-close-icon')) {
                                        handleQuestionSubmit(false);
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, questionFormValues, allOptions, allScreeningOptions]);
    }

    useOutsideAlerter(wrapperRef, questionFormValues);
    
    return (
        <div ref={wrapperRef}>
            <>
                <div className={`survey-add-question-wrapper questioninputbox ${language == "ar" ? "arabic_wrapper" : ""}`}>
                    <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${language == "ar" ? "arabic_wrapper" : ""}   ${(questionType == "singlechoice" ) ? 'addlogic-singlelogicbox-outer' : ''}`}>
                        <div className="bd-que-slide-left question-heading-left">
                            <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Screening question {questionno} - Single choice
                                </div>
                                
                            </div>
                            <div className={`bd-que-slide-arrow add-logic-survey-option ${(questionType == "singlechoice" ) ? 'addlogic-singlelogicbox' : ''}`}>
                                <div className="sqa-check-top">
                                    <div className="sqa-check-top singlechoice-addlogic">
                                        <div className="form-group custom-control d-flex">
                                            <div className={'cross-q'} onClick={() => {
                                                if (cancelQuestion) {
                                                    cancelQuestion();
                                                    setQuestionFormValues({
                                                        question: ""
                                                    });
                                                    setAllOptions([]);
                                                }
                                            }}>
                                                <Icon value="delete" size="medium" hover={true} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`create-projectmodal-form-group d-flex ${language === "ar" ? 'arabic-add-logic-true' : ''} `}>

                        <Input
                            label="Your question"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                setTimeout(() => {
                                    setQuestionFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }, 0);
                            }}
                            required={true}
                            value={questionFormValues.question}
                            maxLength={"350"}
                            error={questionFormErrors.question}
                            error_class={questionFormErrors.error_class}
                            id="question"
                            rtl={language == "ar" ? true : false}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleQuestionSubmit(true);
                                }
                            }}

                        />
                    </div>
                    <div className={`ans-list-wrap ${questionFormValues.is_logic == 1 ? "survey-logic-added" : ""} ${language == "ar" ? "arabic_wrapper" : ""}`}>
                        <p className="small-info-heading">
                            Answers
                        </p>
                        <ScreenQuestionOptions
                            allOptions={allOptions}
                            allScreeningOptions={allScreeningOptions}
                            close={(index) => closeOptionHandler(index)}
                            save={(index, value) => saveOptionHandler(index, value)}
                            saveScreening={(index, value) => saveScreeningOptionHandler(index, value)}
                            language={language}
                            questionType={questionType}
                            addOption={(index) => {
                                if (allOptions.length < 5) {
                                    allOptions.splice(index + 1, 0, "");
                                    setAllOptions([...allOptions]);
                                    
                                    allScreeningOptions.splice(index + 1, 0, "Good fit (continue)");
                                    setAllScreeningOptions([...allScreeningOptions]);
                                    onAddQuestion({question:questionFormValues.question, options:allOptions, screeningOptions:allScreeningOptions});
                                }
                                else {
                                    showError("You have reached the maximum number of responses available for this question.")

                                }
                            }}
                            questionno={questionno}
                            formErrors={optionErrors}
                        />
                    </div>
                    
                    <div className=" add-survey-answer-btn">
                        <Button
                            iconLeft={< Icon value="add" />}
                            label={"Add answer"}
                            type="ghost"
                            onClick={() => {
                                if (allOptions.length < 5) {
                                    
                                    allOptions.push("");
                                    setAllOptions([...allOptions]);
                                    
                                    allScreeningOptions.push("Good fit (continue)");
                                    setAllScreeningOptions([...allScreeningOptions]);
                                    
                                    
                                } else {
                                    showError("You have reached the maximum number of responses available for this question.");
                                }
                            }}
                        />
                    </div>
                    <div className="button-wrap d-flex justify-content-start">
                        <Button
                            label="Save"
                            onClick={()=>handleQuestionSubmit(true)}
                            state={checkBtnstatus() ? "disabled" : "active"}
                        />
                    </div>
                </div>


            </>
        </div>
    )
}

ScreeningQuestionInput.propTypes = {
    // question: PropTypes.string,
    // questionType: PropTypes.string,

};

ScreeningQuestionInput.defaultProps = {
    // question: "",
    // questionType:"freetext",

};