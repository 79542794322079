import React from "react";
import "./TaskNotCompleted.css";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";

const TaskNotCompleted = ({to}) => {
    return (
        <div className="task-not-completed-wrap">
            <div className="task-not-completed-wrap-inner">
                <Icon
                    value={"error"}
                    size={"large"}
                    colorClass={"danger-200-svg"}
                />
                <div className="mt-12 mb-32">
                    <Text type={"subtitle-1"} fontWeight={"medium-font"}>
                        Some key information is missing. Please review and complete any empty fields.
                    </Text>
                </div>
                <Button
                    tag={"link"}
                    href={to}
                    type={"primary"}
                    size={"large"}
                    label={"Back to editing"}
                />
            </div>
        </div>
    );
};

export default TaskNotCompleted;
