import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { Icon } from "../themes/userq/Icon/Icon";
import { ReactSession } from "../../lib/secure_reactsession";
import { getAddLogicTestResultData } from "../../services/test";
import moment from "moment";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { Text } from "../themes/userq/Text/Text";
import { Tag } from "../themes/userq/Tag/Tag";
import { Question } from "../themes/userq/Question/Question";
import { Label } from "recharts";
import { settings } from "../../data/settings";
import { FilterButtons } from "../themes/userq/FilterButtons/FilterButtons";
import { emirates } from "../../data/emirates";
import { saregions } from "../../data/saregions";
import { DefineFlow } from "../researcher/tests/prototype_test/define_flow";
import { ScreeningQuestion } from "../themes/userq/Question/ScreeningQuestion";
import { useLocation } from "react-router-dom";

export default function AddLogicTestResultModal(props) {

    ReactSession.setStoreType("localStorage");
    const wrapperRef = useRef(null);

    const location = useLocation();

    const [isLoading, setLoading] = useState(false);

    const [surveyQuestion, setSurveyQuestion] = useState([]);

    const [activeQuestion, setActiveQuestion] = useState({'question':'','index':''});
    const [jumpTo, setJumpTo] = useState('');
    const [totalUsersGivenTestCount, setTotalUsersGivenTestCount] = useState(0);
    const [totalUserSkipGivenAnswerCount, setTotalUserSkipGivenAnswerCount] = useState(0);
    const [nextQuestion, setNextQuestion] = useState({'question':'','index':''});
    const [multiNextQuestion, setMultiNextQuestion] = useState([]);
    const [userArrives, setUserArrive] = useState([]);
    const [questionLabel, setQuestionLabel] = useState([]);




    const [test, setTest] = useState("");


    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const closeModal = () => {
        props.close();
    };

    useEffect(() => {
        if (props.test_id) {

            getTestApi();
        }
    }, [props.openModal]);

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        let guest_result_token = '';

        if(location.pathname.substr(1, 1) === 'v'){

          // create guest_result_token
          if (!ReactSession.get("guest_result_token")) {
            const randomToken = Math.random().toString(36).substr(2, 15);
            ReactSession.set("guest_result_token", randomToken);
          }

          guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';

        if(location.pathname.substr(1, 1) === 'a'){

          // create guest_result_token
          if (!ReactSession.get("admin_result_token")) {
            const randomToken = Math.random().toString(36).substr(2, 15);
            ReactSession.set("admin_result_token", randomToken);
          }

          admin_result_token = ReactSession.get("admin_result_token");
        }


        getAddLogicTestResultData({ test_id: props.test_id,question_id:props.question_id,guest_result_token:guest_result_token,admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {


                if (response.test.target_group) {

                    setTest(response.test);


                    let surveyQuestions = [];

                    var currentQuestion = '';
                    var currentQuestionIndex = '';
                    var jumpToNextQuestion = '';

                    var nextQuestionData = '';
                    var nextQuestion = '';
                    var nextQuestionIndex = '';


                    let singleLogicNextData = [];
                    let singleQuestArray = [];

                    response.test.surveyQuestion.forEach((question,index) => {

                        var questionDatas = JSON.parse(question);

                        // if(questionDatas.id === props.question_id){
                        if(props.question_index === index){

                            currentQuestion = questionDatas;
                            currentQuestionIndex = index;

                            if(questionDatas.questionType !== 'singlechoice'){
                                setMultiNextQuestion([]);
                                jumpToNextQuestion =  questionDatas.jump_to_question != "End Survey" ? (questionDatas.jump_to_question == index + 2 ? "Next question" : "Question " + questionDatas.jump_to_question) : "End survey";

                                // next question calculation
                                if(jumpToNextQuestion !== 'End Survey'){

                                    // check question is next
                                    if(questionDatas.jump_to_question === index + 2){

                                        nextQuestionData = questionDatas.jump_to_question;
                                    }else{
                                        nextQuestionData = questionDatas.jump_to_question;
                                    }

                                    // console.log("nextQuestionData")
                                    // console.log(response.test.surveyQuestion[parseInt(nextQuestionData)-1])

                                    if(nextQuestionData !== 'End Survey'){

                                        nextQuestion = JSON.parse(response.test.surveyQuestion[parseInt(nextQuestionData)-1]);
                                        nextQuestionIndex = parseInt(nextQuestionData)-1;

                                        console.log(nextQuestion)
                                    }



                                }
                            } else {
                              const uniqueSingleNextQuestion = questionDatas.jumpTo.filter((element, index) => {
                                return questionDatas.jumpTo.indexOf(element) === index;
                              });

                              singleLogicNextData = uniqueSingleNextQuestion.map((item) => (item !== "End Survey" ? (item === index + 2 ? "Next question" : "Question " + item) : "End survey"));

                              uniqueSingleNextQuestion.map((item) => {
                                if (item !== "End Survey") {
                                  let questJump = item;
                                  let parsedJumpQuest = JSON.parse(response.test.surveyQuestion[parseInt(questJump) - 1]);
                                  let parsedJumpQuestIndex = parseInt(questJump) - 1;

                                  singleQuestArray.push({ 'question': parsedJumpQuest, 'index': parsedJumpQuestIndex });
                                } else {
                                  singleQuestArray.push({ 'question': '', 'index': '' });
                                }
                              })
                              setQuestionLabel(singleLogicNextData);
                            }
                        }

                        surveyQuestions.push(questionDatas);
                    });


                    console.log(currentQuestion)
                    setActiveQuestion({'question':currentQuestion,'index':currentQuestionIndex})


                    if(!singleLogicNextData.length){
                        setJumpTo(jumpToNextQuestion);
                        setMultiNextQuestion([]);

                        if(jumpToNextQuestion !== 'End Survey' && nextQuestion){

                            setNextQuestion({'question':nextQuestion,'index':nextQuestionIndex})
                        }else{

                            setNextQuestion({'question':'','index':''})
                        }
                    } else {
                        setMultiNextQuestion(singleQuestArray);
                    }

                    


                    setUserArrive(response.userArrive);

                    setSurveyQuestion(surveyQuestions);

                    if(response.totalUserGivenAnswerCount){

                        setTotalUsersGivenTestCount(response.totalUserGivenAnswerCount);
                    }else{
                      
                        setTotalUsersGivenTestCount(0);
                    }

                    if(response.totalUserSkipGivenAnswerCount) {
                        setTotalUserSkipGivenAnswerCount(response.totalUserSkipGivenAnswerCount);
                    }else{
                        setTotalUserSkipGivenAnswerCount(0);
                    }

                } else {
                    showError(response.message);
                }
            }
        });
    };



    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                // console.log(event.target);
                // console.log(event.target.nodeName);
                // console.log("xyz");
                // console.log(ref.current);
                if (ref.current && !ref.current.contains(event.target)) {
                    // console.log("abc");
                    if(event.target.nodeName =="DIV"){
                        // console.log("abcd");
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
      <Modal
        show={props.openModal}
        centered
        className="fade custom-modal-wrap  add-logic-modal-wrap"
      >
        <Modal.Body className="" ref={wrapperRef}>
          <div className="add-logic-modal-data">
            <div className="modal-header-top">
              <Text type={"h2"}>Logic applied</Text>

              <button
                type="button"
                className="close"
                onClick={() => {
                  closeModal();
                }}
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Icon value="Close" size="large" hover={true} />
                </span>
              </button>
            </div>

            {isLoading ? (
              <div className="modal-loader text-center">
                <LoadingIcon />
              </div>
            ) : (
              <div className="summary-modal-data">
                {/*jumpTo*/}

                <div className={"tester-count-hold"}>
                  {totalUsersGivenTestCount ? (
                    <div className={"total-users"}>
                      <Icon
                        value={"group"}
                        colorClass={"purple-200-svg"}
                        size={"medium"}
                      />
                      <Text
                        type={"body-text-2"}
                        fontWeight={"medium-font"}
                        cssClasses={"total-users-text"}
                      >
                        {totalUsersGivenTestCount}{" "}
                        {totalUsersGivenTestCount === 1 ? "user" : "users"} went
                        through this logic
                      </Text>
                    </div>
                  ) : (
                    ""
                  )}

                  {totalUserSkipGivenAnswerCount ? (
                    <div className={"total-users-skip"}>
                      <Icon
                        colorClass={"gray-700-svg skip-qiestion-icon"}
                        value={"forward"}
                        size={"medium"}
                      />
                      <Text
                        type={"body-text-2"}
                        fontWeight={"medium-font"}
                        cssClasses={"total-users-text"}
                      >
                        {totalUserSkipGivenAnswerCount}{" "}
                        {totalUserSkipGivenAnswerCount === 1 ? "user" : "users"}{" "}
                        skipped the question
                      </Text>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {userArrives && userArrives.length > 0 && (
                  <div className={"users-navigate-from"}>
                    <Text
                      type={"body-text-2"}
                      fontWeight={"medium-font"}
                      cssClasses={"navigate-text"}
                    >
                      Users reached this question after navigating from
                    </Text>

                    {userArrives.map((item, index) => (
                      <Text
                        type={"body-text-2"}
                        fontWeight={"medium-font"}
                        cssClasses={"navigate-text-question"}
                      >
                        Question {item.currentIndex}
                        <span className="autosave-icon info-wrap position-relative">
                          <Icon
                            value={"information"}
                            colorClass={"gray-900-svg"}
                            size="medium"
                          />
                          <div className="tooltip-wrapper top-tooltip">
                            <Text
                              type="body-text-2"
                              id="tooltip"
                              fontWeight="normal-font"
                            >
                              <>
                                <span className={"participant"}>
                                  {item.answer_count}{" "}
                                  {item.answer_count === 1
                                    ? "Participant"
                                    : "Participants"}
                                </span>
                                <span className={"question"}>
                                  Question {item.currentIndex} -{" "}
                                  {item.currentQuestion}
                                </span>
                              </>
                            </Text>
                          </div>
                        </span>
                      </Text>
                    ))}
                  </div>
                )}

                {test.methodology === "Survey" && !multiNextQuestion.length && (
                  <div className="row summary-modal-data-repeat">
                    <div className={"jump-to-question-data"}>
                      <Text
                        type={"body-text-2"}
                        fontWeight={"medium-font"}
                        cssClasses={"jump-to-question-left"}
                      >
                        The logic of this question set to lead the users to
                      </Text>
                      <Text
                        type={"body-text-2"}
                        fontWeight={"semi-bold-font"}
                        cssClasses={"jump-to-question-right"}
                      >
                        {jumpTo}
                      </Text>
                    </div>

                    <div className="col-md-12 current-question">
                      <div className="summary-right-sec-wrap">
                        <Question
                          index={activeQuestion.index}
                          question={activeQuestion.question}
                          type="survey"
                        />
                      </div>
                    </div>

                    {/*nextQuestion*/}

                    {nextQuestion &&
                      nextQuestion.question &&
                      !multiNextQuestion.length && (
                        <div className="col-md-12 next-question">
                          <Text
                            type={"body-text-2"}
                            fontWeight={"semi-bold-font"}
                            cssClasses={"nextquestion-text"}
                          >
                            Next question
                          </Text>
                          <div className="summary-right-sec-wrap">
                            <Question
                              index={nextQuestion.index}
                              question={nextQuestion.question}
                              type="survey"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                )}

                {test.methodology === "Survey" && multiNextQuestion.length > 0 && (
                  <div className="row summary-modal-data-repeat">
                    <div className="col-md-12 current-question">
                      <div className="summary-right-sec-wrap">
                        <Question
                          index={activeQuestion.index}
                          question={activeQuestion.question}
                          type="survey"
                        />
                      </div>
                    </div>

                    {/*nextQuestion*/}

                    {multiNextQuestion.length &&
                      multiNextQuestion.map((item, idx) => {
                        if (item.question)
                          return (
                            <div className="col-md-12 next-question">
                              <Text
                                type={"body-text-2"}
                                fontWeight={"semi-bold-font"}
                                cssClasses={"nextquestion-text"}
                              >
                                {questionLabel[idx]}
                              </Text>
                              <div className="summary-right-sec-wrap">
                                <Question
                                  index={item.index}
                                  question={item.question}
                                  type="survey"
                                />
                              </div>
                            </div>
                          );
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
}
