import Select, { components } from "react-select";
import React, { useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { DropdownIndicator } from "../../../../lib/helpers";
import { Icon } from './../Icon/Icon';
import { Chip } from "../Chips/Chips";

const CustomValueContainer = ({ children, ...props }) => {
    const { ValueContainer, Placeholder } = components;

    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
}



export const SearchDropdownMultiple = ({ id, isMulti, type, onChange, value, label, active, menuIsOpen, options, error, error_class }) => {

    
    const [input, setInput] = useState("");

    let selected_value = null;


    useEffect(()=>{

    },[value])

    
    var modified_options = [];


    if (isMulti) {
        selected_value = [];
        
        value.forEach(function (item) {
            selected_value.push({ "label": item, "value": item });
        })
        
    } else {
        if (value) {
            selected_value = {
                label: value,
                value: value
            };
        }
    }
    modified_options = options.map(function(option){

        const checkValue = selected_value.findIndex(item => item.value === option.value);

        if(checkValue !== -1){
                    
            return {
                value: option["value"],
                label: option["value"],
                isDisabled: true
            };

            
        } else{
            
            return {
                value: option["value"],
                label: option["value"],
                isDisabled: false
            };
            
        }
    });

    return (
        <div className={` dropdown-container form-group input-field mb-0 ${((value != '' && value != null) || active == true) ? 'focused country-with-value' : ''} ${(active == true) ? 'active' : ''} ${error?"field-error":""} ` } id={id} >
            <div className={`input-position-relative  dropdown-field country-dropdown`}>
                {menuIsOpen ?
                    <Select
                        isMulti={true}
                        isClearable={false}
                        isSearchable={true}
                        isDisabled={false}
                        hideSelectedOptions={false}
                        name="countries"
                        options={modified_options}
                        onChange={onChange}
                        placeholder={label}
                        value={selected_value}
                        menuIsOpen
                        components={{ ValueContainer: CustomValueContainer, DropdownIndicator }}
                        backspaceRemovesValue={false}
                    />
                    :
                    <Select
                        key="countrydropdown"
                        isMulti={true}
                        isDisabled={false}
                        hideSelectedOptions={false}
                        name="countries"
                        options={modified_options}
                        onChange={(items)=>{ setInput(""); onChange(items)}}
                        placeholder={label}
                        value={selected_value}
                        components={{
                            ValueContainer: CustomValueContainer, DropdownIndicator
                        }}
                        styles={{
                            placeholder: (provided, state) => ({
                                ...provided,
                                display: state.selectProps.inputValue ? "none" : "block",
                            })
                        }}
                        inputValue={input}
                        onInputChange={(value, action) => {
                            if (action.action === "input-change") { setInput(value); }
                        }}
                        backspaceRemovesValue={false}
                    />}
                <span className="input-icon"><Icon value="search" size="medium" hover={true}/></span>
                {/* <label className="control-label body-text body-text-2" htmlFor={id}>

                    {label}
                </label> */}
                {input != "" && (
                    <span className="input-right-icon" onClick={() => { setInput("") }}>
                        <Icon value="close" size="medium" hover={true}/>
                    </span>
                )}
                {error &&
                <span className={error_class}>{error}
                </span>
            }
            </div>
            
        
            <div className="dropdown-chips">{selected_value.map((option, i) => (<Chip key={"itemchip" + option.value} title={option.value}  type="icon-right" onRemove={() => {
                let new_selected = [];

                selected_value.forEach(function (item) {
                    if (item.value != option.value) {
                        new_selected.push(item);
                    }
                })
                if (onChange) {
                    onChange(new_selected)
                }
            }} />))}</div>
            
        </div>
    );
}

SearchDropdownMultiple.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    inactive: PropTypes.bool,
    focussed: PropTypes.bool,
    open: PropTypes.bool,
    isMulti: PropTypes.bool,
    menuIsOpen: PropTypes.bool

};
SearchDropdownMultiple.defaultProps = {
    onChange: undefined,
    inactive: false,
    focussed: false,
    open: false,
    isMulti: true,
    menuIsOpen: false
};