import { scaleSqrt } from "d3";
import { useEffect, useState } from "react";
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { PageProgressBar } from "../../../themes/userq/ProgressBar/page_progress_bar";
import { Text } from "../../../themes/userq/Text/Text";


export default function Task({ activeTaskIndex, totalTasks, test, tree, path, navPath, skipTask, task, next, answer, nextTask, fullTree, unsetAnswer, setAnswer, formLoading }) {

    const [closed, setClosed] = useState('');

    const [node, setNode] = useState(0);

    const [nodeIndex, setNodeIndex] = useState(0);

    const [animating, setAnimating] = useState(false);

    const [height, setHeight] = useState(0);

    var nodeHeight = 65;

    var animationTimeout = 205;

    useEffect(() => {
       setTimeout(()=>{
        setHeight((path.length+tree.length)*nodeHeight);
       },animationTimeout);
        

    },[path]);

    const renderNodes = (index) => {
        
        var nodes = [];

        var answer_at_same_level = false;

        tree.forEach(item => {

            if (answer == item.id) {
                answer_at_same_level = true;
            }
        });

        tree.forEach(item => {
            var pathLocal = [...path];
            pathLocal.push(item);

            if (!answer_at_same_level || item.id == answer) {
                nodes.push((
                    <div key={"node" + item.id}  className={`test-selected-mt-rpt slider ${node != item.id ? closed : ""} `}
                        onClick={
                            (e) => {
                                console.log(item+"as")
                                setHeight(pathLocal.length*nodeHeight);

                                setTimeout(()=>{
                                    
                                    e.preventDefault();
                                    
                                    if (!animating && answer != item.id) {
    
                                        setAnimating(true);
    
                                        //item.title+
    
                                        var new_nav_path = [...navPath, "forward", item.id];
    
                                        if (item.children.length > 0) {
    
                                            setNode(item.id);
    
                                            setNodeIndex(index);
    
                                            setTimeout(function () {
    
                                                setAnimating(false);
    
                                                next(item.children, pathLocal, new_nav_path);
    
                                            }, 300)
    
                                        } else if (item.children.length == 0) {
    
                                            setNode(item.id);
    
                                            setTimeout(() => {
                                                setAnimating(false);
                                                
                                                setAnswer(task.id, item.id, new_nav_path);
    
                                                
                                            }, 300);
    
    
                                        }
    
    
                                    }
                                },animationTimeout)
                               
                            }
                        }>
                        <a href="#" onClick={(e) => { e.preventDefault();  }} className={`sl-mt-link-wrp ${answer == item.id ? 'test-answer-selected' : ''}`}>
                            <span className="task-tree-cat-data">{item.title}</span>
                            {item.children.length == 0 &&
                                <span className={`${answer == item.id ? 'selected-correct-answer' : 'select-this-answer-1'}`}>
                                    {answer == item.id ? <><img src={process.env.REACT_APP_URL + "/img/green-fill-tick.svg"} /> <p className="selected-answer">{test.language == "en" ? "I'd find it here" : "سأجدها هنا"}</p></> : ''}
                                </span>
                            }
                        </a>
                    </div>
                ))
            }
        });
        return nodes;
    }
    const checkAnswer = () => {
        var answer_at_same_level = false;

        tree.forEach(item => {

            if (answer == item.id) {
                answer_at_same_level = true;
            }
        });
        return answer_at_same_level;
    }
    const navigateTree = (path, index) => {
        if (index == path.length) {
            return (
                <>

                    {
                        renderNodes(index)
                    }
                </>
            )
        } else {

            return (
                <>
                    <div key={"node" + path[index].id} className={`test-selected-mt-rpt slider ${index > nodeIndex && node != path[index].id ? closed : ""} `}
                        onClick={(e) => {
                            console.log(path)
                            e.preventDefault();

                            

                            if (path[index].id == -1 && (index == path.length - 1 && !checkAnswer())) {
                                return;
                            } else {
                                setHeight((index+1)*nodeHeight);
                            }

                            setTimeout(()=>{


                                if (path[index].id == -1 && (index == path.length - 1 && !checkAnswer())) {
                                    return;
                                }
    
                                // going back
    
                                var answer_selected = false;
    
                                tree.forEach(function (item) {
                                    if (item.id == answer) {
                                        answer_selected = true;
                                    }
                                })
                                if (answer_selected && index == path.length - 1) {
                                    setAnimating(true);
    
                                    setNode(path[index].id);
    
                                    setNodeIndex(index);
    
                                    setAnimating(false);
    
                                    setTimeout(function () {
                                        var pathLocal = [...path];
                                        var new_path = [...path];
                                        var back_path = [];
    
                                        var from = pathLocal.length - 1;
    
    
                                        var till = 0;
    
                                        till = index;
    
                                        if (till < 0) {
                                            till = 0;
                                        }
    
                                        /*for(var i=from;i>=till;i--){
                                            back_path.push("back");
                                            //pathLocal[i].title+
                                            back_path.push(pathLocal[i].id);
                                        }*/
                                        back_path.push("back");
                                        back_path.push(pathLocal[till].id);
    
                                        var new_nav_path = [];
    
                                        if (navPath[navPath.length - 2] == "back" && navPath[navPath.length - 1] == -1 && back_path[0] == "back" && back_path[1] == -1) {
                                            new_nav_path = navPath;
                                        } else {
                                            new_nav_path = navPath.concat(back_path);
                                        }
    
                                        //new_path.splice(index);
    
                                        var new_tree;
                                        if (index == 0) {
                                            new_tree = fullTree;
    
                                           var tree_loc = [{ "id": -1, "title": test.home_name, children: fullTree }];
    
    
                                            new_path = [tree_loc[0]];
    
                                        } else {
                                            new_tree = pathLocal[index]["children"];
                                        }
    
                                        next(new_tree, new_path, new_nav_path);
    
                                        unsetAnswer(task.id);
    
                                        
                                    }, 300);
    
                                }
                                else if (!animating) {
                                    setAnimating(true);
    
                                    setNode(path[index].id);
    
                                    setNodeIndex(index);
    
                                    setTimeout(function () {
                                        var pathLocal = [...path];
                                        var new_path = [...path];
                                        var back_path = [];
    
                                        var from = pathLocal.length - 2;
    
    
                                        var till = 0;
    
                                        till = index;
    
                                        if (index == pathLocal.length - 1) {
                                            till = index - 1;
                                        }
    
                                        if (till < 0) {
                                            till = 0;
                                        }
    
                                        /*for(var i=from;i>=till;i--){
                                            back_path.push("back");
                                            //pathLocal[i].title+
                                            back_path.push(pathLocal[i].id);
                                        }*/
                                        back_path.push("back");
                                        back_path.push(pathLocal[till].id);
    
                                        var new_nav_path = [];
    
                                        if (navPath[navPath.length - 2] == "back" && navPath[navPath.length - 1] == -1 && back_path[0] == "back" && back_path[1] == -1) {
                                            new_nav_path = navPath;
                                        } else {
                                            new_nav_path = navPath.concat(back_path);
                                        }
                                        if (index == pathLocal.length - 1) {
                                            new_path.splice(index);
                                        } else {
                                            new_path.splice(index + 1);
                                        }
    
    
                                        var new_tree;
                                        if (index == 0) {
                                            new_tree = fullTree;
    
                                            //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:fullTree}];
    
                                            var tree_loc = [{ "id": -1, "title": test.home_name, children: fullTree }];
    
    
                                            new_path = [tree_loc[0]];
    
                                        } else {
    
                                            if (index == pathLocal.length - 1) {
                                                new_tree = pathLocal[index - 1]["children"];
                                            } else {
                                                new_tree = pathLocal[index]["children"];
                                            }
    
                                        }
    
                                        unsetAnswer(task.id);
                                        next(new_tree, new_path, new_nav_path)
    
                                        setAnimating(false);
    
                                    }, 300)
                                }
                                

                            },animationTimeout)

                            

                            



                        }}
                    >
                        <a href="#" onClick={(e) => { e.preventDefault() }} className={`sl-mt-link-wrp`}>
                            <span className="task-tree-cat-data">{path[index].title}</span>
                            <span className="back-tree-icon" >
                                
                                {index == path.length - 1 && !checkAnswer() &&

                                    <img src={process.env.REACT_APP_URL + "img/chevron.svg"} />

                                }

                                {!(index == path.length - 1 && !checkAnswer()) &&
                                    <></>
                                }
                            </span>
                        </a>
                    </div>
                    <div className={`sel-chile-cat-wrap `}>

                        {navigateTree(path, index + 1)}
                    </div>
                </>
            )
        }
    }
    return (
        <div className="tester-page-wrapper tester-question-selection-wrap test-selected-main-cat-wrap tree-test-preview-hold">

            <div className="container inner-page-container">

                <div className="tester-screens-hold">
                    <div className="tester-form-steps-wrapper">
                        <div className="tester-form-steps-inner">
                            <div className="taking-test-page-height">
                                <div className="tree-test-preview-wrap">
                                    <div className="tree-test-top-fixed">
                                        <div className="tester-page-wrapper task-step-progress tester-question-selection-wrap test-selected-main-cat-wrap">
                                            

                                                <div className="taking-test-progressbar">
                                                    <PageProgressBar language={test.language} label={'Task'} variant={'take-test'} current_step={activeTaskIndex + 1} total_steps={totalTasks} taskbar={test.language == "en" ? "Task" : "مهمة"} />

                                                </div>
                                                <div className="take-test-heading-wrap">
                                                    <Text type={"h2"} fontWeight="semi-bold-font" className={`h2 ${test.language == "ar" ? 'arabic-font' : ''} `}>
                                                        {task.title}
                                                    </Text>
                                                </div>
                                            
                                        </div>

                                    </div>
                                    <div style={{height:height}} className="test-form-hold mt-40 animation-tree-wrapper">
                                        {/*navPath.map(function(item){
                                            if(item=="back"){
                                                return <>&nbsp;&nbsp;&lt;&nbsp;&nbsp;</>;
                                            }
                                            else if(item=="forward"){
                                                return <>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</>;
                                            } else {
                                                return item;
                                            }
                                        })*/}
                                        {navigateTree(path, 0)}
                                    </div>
                                </div>


                            </div>
                            
                            <div className="taking-test-button-wrap d-flex justify-content-center  mb-40 optional-button">
                               
                                <Button
                                    type="primary"
                                    size={"large"}
                                    onClick={() => { nextTask(task.id) }}
                                    label={test.language == "en" ? "Next" : "التالي"}
                                    microLoading={formLoading}
                                    iconRight={<Icon colorClass={"gray-50-svg"} value={test.language=="ar"?'back-arrow':'forward-arrow'} />}
                                />

                                <Button
                                    type="ghost"
                                    
                                    size={"medium"}
                                    onClick={() => { skipTask(task.id, navPath) }}
                                    label={test.language == "en" ? "Skip" : "تخطي"}
                                    iconRight={<Icon colorClass={"gray-900-svg"} value={test.language=="ar"?'backward':'forward'} />}
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}