import { useNavigate } from 'react-router-dom';
import React, {useEffect,useState} from "react";
import LayoutResearcher from '../../layouts/layout_researcher.js';
import { getPlansService, subscribeToFreePlanService } from '../../../services/plans';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import toast from 'react-hot-toast';
import CommonPlans from './common-plans.js';
import {atom, useResetRecoilState} from 'recoil';
import {LoadingIcon} from "../../loader/loadingIcon";

export default function Plans() {

    const navigate  = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [plans, setPlans] = useState([])
    const [term, setTerm] = useState(ReactSession.get("term") ? ReactSession.get("term") :"Monthly" );


    const activePlanState = atom({
        "key":"active_plan",
        "default":null
    })
    const reset = useResetRecoilState(activePlanState);

    
    const getPlans = () => {
		let token = ReactSession.get("token");

        setLoading(true);

        getPlansService(token).then(response=> {
            setLoading(false);

            if(response.success){

                var plan_id = ReactSession.get("plan_id");
                var exist = false;
                var selected_plan;
                response.plans.forEach(function(plan){
                    if(parseInt(plan.id) == parseInt(plan_id)){
                        exist = true;
                        selected_plan = plan;
                    }
                })
                if(exist){
                    
                    if(ReactSession.get(term)){
                        setTerm("yearly")
                    }
                    var planService = selectPlan(selected_plan);

                    planService();
                }

                setPlans(response.plans);               

            } else {
				

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
			}
        });
	}
    useEffect(() => {

        document.title = process.env.REACT_APP_NAME+" - Plans";

        getPlans();

    }, []);

    const selectPlan = (plan) => {

        return (e)=> {
            
            if(e){
                e.preventDefault();
            }
            
            
            if(plan.plan_category=="FREE"){
                
                setLoading(true);
    
    
                var token = ReactSession.get('token');
    
                subscribeToFreePlanService(token).then(response=> {
                    setLoading(false);
    
                    if(response.success){
    

                        reset();

                        ReactSession.set("show_subscribed",1);    
                        
                        ReactSession.set("show_pro_subscribed",0);    
                        
                        
                        ReactSession.set("plan",plan);     
    
                        navigate("/dashboard");
        
                    } else {
                        
    
                        var id = toast((
                            <div className='toastinner'>
                                {response.message}
                                <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                            </div>), {
                            className: 'errortoast',
                            position: 'bottom-center'
                        });
                        
                    }
                });
                
            } else {
                ReactSession.set("selected_plan",plan);

                ReactSession.set("term",term);

                navigate("/researcher/billing/subscribe");
            }
        }   
        
    }

    
    return (
        <LayoutResearcher skipCheck={true} extendedFooter={false}>   
            <div className="dashboard-data-wrapper">
                {isLoading &&
                    <LoadingIcon/>
                }
                {!isLoading && 
                <>
                    <CommonPlans plans={plans} term={term} selectPlan={(plan)=>selectPlan(plan)} setTerm={(term)=>setTerm(term)} />
                    
                </>
                }
            </div>
        </LayoutResearcher>
    )
}