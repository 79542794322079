import React from 'react';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../lib/secure_reactsession';
import { Navigate } from "react-router-dom";

export default function Home() {
  ReactSession.setStoreType("localStorage");
  let token = ReactSession.get("token");
  let user = ReactSession.get("user");
  console.log(user);
  if(user && token && token!=null){
    
    let wsp_id = null;

        let session_workspace = ReactSession.get("workspace");

        if(session_workspace){
          wsp_id = session_workspace.workspace_id;
        }

    if(wsp_id && user.role=="Researcher"){
      return (<Navigate to={"/wsp/"+wsp_id} />);
    }
    return (<Navigate to={"/dashboard"} />);
    
} else {
    return (<Navigate to="/researcher/sign-in" />);
}
  
  
}
