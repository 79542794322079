import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams,useLocation} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import InputField from '../layouts/elements/fields/input';
import { validateResultData } from "../../services/test_result";
import toast from "react-hot-toast";

export default function Password({test,result,passwordCallback,timeSpent}) {

    console.log(test);

    const location = useLocation();

    const [formErrors, setFormErrors] = useState({ password: null, error_class: "input_error" });

    const [formValues, setFormValues] = useState({  "password": "" })
    const [passwordLoading, setPasswordLoading] = useState(false);

    const submitHandler = (e)=>{
        e.preventDefault();

        setFormErrors({ password: null, error_class: "input_error" });
        if(!formValues.password){
            setFormErrors({ password: "Required field", error_class: "input_error" });
            return false;
        }

        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);
        data.append("guest_token", ReactSession.get("guest_token"));
        data.append("validate", 'password');
        data.append("password", formValues.password);
        data.append("type", location.pathname.substr(1 ,1));
        data.append("result_id", result && result.id);
        data.append("time_spent", timeSpent);

        setPasswordLoading(true);
        validateResultData(data, token).then((response) => {
            setPasswordLoading(false);

            if (response.success) {
                passwordCallback(true);
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });


    }

    return (
        <div className="login-data-wrap">
            <div className="container">
                <div className="login-left-side">
                    <h1 className="form-heading">{test.language=="ar"?"هذا الاختبار يتطلب كلمة مرور":"THIS TEST needs a PASSWORD"}</h1>
                    <p className="form-sub-head">{test.language=="ar"?"أدخل كلمة المرور الخاصة بك أدناه للبدء":"Enter your password below to get started"}</p>
                    <div className="login-form-inner-data">

                        <form onSubmit={submitHandler}>
                            <InputField error={formErrors.password} error_class={formErrors.error_class} onChange={(e)=>setFormValues({"password":e.target.value})} value={formValues.password} type="password" label={test.language=="ar"?"كلمة المرور":"Password"} required={true} id="password" />

                            <button type="submit"
                                    className="btn btn-block form-btn login-page-btn testsign-sign-in-button">
                                {test.language=="ar"?"لنبدأ":"Let’s go"}
                                {passwordLoading && (
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        aria-hidden="true"
                                        style={{ marginLeft: "5px" }}
                                    ></i>
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
