
import { Link, useNavigate } from "react-router-dom";
//import { ReactSession } from 'react-client-session';
import { ReactSession } from "../../../lib/secure_reactsession.js";
//import LayoutResearcher from '../../layouts/layout_researcher.js';
import {LayoutResearcher} from "../../themes/userq/Layouts/layout_researcher";
import React, { useEffect, useState, useRef } from "react";
import ResearcherProfileNavigation from '../../profile/researcher_profile_navigation.js';
import { Dropdown } from 'react-bootstrap';
import { getCollaborators, addTeamMember, verifyEmailExits, deleteTeamMember, updateTeamMember } from '../../../services/collaborators';
import { checkIfEmailUnique } from '../../../services/user.js';
import toast from 'react-hot-toast';
import moment from "moment";
import InvitationModal from './../../dialog/invitation_modal';
import ConfirmationModal from './../../dialog/team_modal';
import {FormattedMessage} from "react-intl";
import {LoadingIcon} from "../../loader/loadingIcon";
import AutosizeInput from 'react-input-autosize';

export default function ResearcherTeam() {



    ReactSession.setStoreType("localStorage");

    let user = ReactSession.get("user");

    const navigate = useNavigate();

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [collaborators, setCollaborators] = useState([]);
    const [unsavedcollaborators, setUnsavedCollaborators] = useState([]);
    const [currentUnsaved, setCurrentUnsaved] = useState("");
    const [loggedInUser, setLoggedInUser] = useState("");

    const [emailLabelClass, setEmailLabelClass] = useState("inactive_label");

    const [isLoading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(true);

    const [memberLoading, setMemberLoading] = useState(false);

    const [plan_id, setPlanId] = useState(null);

    const [collaboratorRow, setCollaboratorRow] = useState({ email: "", role: "Admin" });

    const [isRole, setIsRole] = useState("Admin");
    const [isActiveInvite, setIsActiveInvite] = useState(false);
    const [sendInvitationLoading, setSendInvitationLoading] = useState(false);

    const setCollabortorRole = (role) => {
        return () => {
            setCollaboratorRow(oldValues => ({ ...oldValues, ['role']: role }));
        }
    }
    const [formErrors, setFormErrors] = useState({ email: null, "error_class": null });

    const [email, setEmail] = useState(null);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [openInvitationModal, setOpenInvitationModal] = useState({ active: false, msg: '', confirmBtn: true });
    const [openConfirmationModal, setOpenConfirmationModal] = useState({ active: false, type: 'delete', role: '', title: '', msg: '', index: 0 });

    const emailAdd = (e) => {
        setCollaboratorRow(oldValues => ({ ...oldValues, ['email']: e.target.value }));
        setEmailLabelClass('active_label')
        setFormErrors({ email: null, error_class: null });
    }
    const updateUnsavedCollaboratorsEmail = (value, index) => {
        unsavedcollaborators[index].email = value;
        setUnsavedCollaborators([...unsavedcollaborators]);

    }
    const updateUnsavedCollaboratorsRole = (value, index) => {
        unsavedcollaborators[index].role = value;
        setUnsavedCollaborators([...unsavedcollaborators]);
        //console.log(unsavedcollaborators);

    }
    useEffect(() => {

        document.title = process.env.REACT_APP_NAME + " - Team";

        getCollabortorsApi();

    }, []);

    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };

    const closeInvitationModal = () => {


        setOpenInvitationModal({ ...openInvitationModal, active: false });


    };

    const closeConfirmationModal = () => {

        setOpenConfirmationModal({ ...openConfirmationModal, active: false });
    }

    const getCollabortorsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCollaborators(token).then(response => {

            setLoading(false);

            if (response.success) {

                setLoggedInUser(response.user);

                if (response.collaborators) {


                    setPlanId(response.plan_id);

                    const collaboratorsFinal = [];

                    response.collaborators.forEach(function (item) {
                        collaboratorsFinal.push({
                            "id": item.id,
                            "user_id": item.user_id,
                            "email": item.email,
                            "last_active": item.last_active,
                            "created_at": item.created_at,
                            'role': item.role
                        })
                    })
                    setCollaborators(collaboratorsFinal);

                    window.setLabels();

                }
            } else {

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });

            }
        });
    }



    // const addCollaborator = () => {

    //     var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    //     return (e) => {

    //         e.preventDefault();

    //         showFormError("email",null);

    //         var error = false;

    //         if(collaboratorRow.email==''){

    //             showFormError('email','Please add email');

    //             error = true;

    //         }else if (!collaboratorRow.email.match(validRegex)) {
    //             showFormError("email", "Please add a valid email");

    //             error = true;
    //         } else {
    //           collaborators.forEach(function (item) {
    //             if (item.email == collaboratorRow.email) {
    //               showFormError("email", "Duplicate email.");

    //               error = true;
    //             }
    //           });

    //           if (!error) {
    //             setMemberLoading(true);
    //             checkIfEmailUnique(
    //               collaboratorRow.email,
    //               ReactSession.get("token")
    //             ).then((response) => {
    //               setMemberLoading(false);
    //               if (response.success) {
    //                 if (!response.unique) {
    //                   // showFormError("email", "Email already exist");
    //                   if (response.role == "Tester") {
    //                     showFormError(
    //                       "email",
    //                       "User is already active as tester"
    //                     );
    //                   } else if (response.role == "Researcher") {
    //                     showFormError(
    //                       "email",
    //                       "User is already active in other account"
    //                     );
    //                   } else if (response.role == "Collaborator") {
    //                     showFormError(
    //                       "email",
    //                       "User is already active as collaborator"
    //                     );
    //                   } else {
    //                     showFormError("email", "Email already exist");
    //                   }

    //                   error = true;
    //                 }
    //               } else {
    //                 error = true;
    //               }
    //               if (!error) {
    //                 const collaboratorsFinal = [...collaborators];

    //                 collaboratorsFinal.push(collaboratorRow);

    //                 setCollaborators(collaboratorsFinal);

    //                 setCollaboratorRow({ email: "", role: "Admin" });

    //                 window.setLabels();
    //               }
    //             });
    //           }
    //         }
    //     }
    // }

    const [formLoading, setFormLoading] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);


    // set new role for new unsaved email
    const updateNewCollabortorRole = (role) => {

        const newCollaborator = unsavedcollaborators;

        if (newCollaborator.length > 0) {
            for (var i = 0; i < newCollaborator.length; i++) {
                newCollaborator[i].role = role;
            }
        }

        setIsRole(role);
        setUnsavedCollaborators(newCollaborator);

    }



    const [formValues, setFormValues] = useState({ "email": "" });

    const clearErrorMessage = () => {
        setErrorMessage(null);
    }

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal['error_class'] = 'input_error';

        setFormErrors(formErrorsLocal);


    }


    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }

    const onSubmit = async () => {



        setErrorMessage(null);

        resetFormErrors();

        if (!formLoading) {

            var error = false;

            if (!error) {
                setFormLoading(true);

                const token = ReactSession.get("token");

                var data = new FormData();

                collaborators.forEach(item => {
                    if (item.role) {
                        data.append(
                            "collaborators[]", JSON.stringify(item)
                        );
                    }
                });

                unsavedcollaborators.forEach(item => {
                    data.append(
                        "collaborators[]", JSON.stringify(item)
                    );
                });

                data.append("role", isRole);

                addTeamMember(data, token)
                    .then(response => {
                        setFormLoading(false);

                        if (response.success) {

                            setFormSubmitted(false);

                            var id = toast((
                                <div className='toastinner'>
                                    {response.message}
                                    <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                                </div>), {
                                className: 'successtoast',
                                position: 'bottom-center'
                            });
                            closeInvitationModal();
                            setUnsavedCollaborators([]);

                            getCollabortorsApi();

                        } else {
                            var id = toast((
                                <div className='toastinner'>
                                    {response.message}
                                    <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });
                        }
                    })
            }
        }
        return false;
    }

    const onConfirm = () => {


        if (openConfirmationModal.type === 'delete') {
            const collaboratorsFinal = [...collaborators];

            setFormLoading(true);

            const token = ReactSession.get("token");

            var data = new FormData();
            data.append(
                "id", collaboratorsFinal[openConfirmationModal.index].id
            );

            deleteTeamMember(data, token)
                .then(response => {
                    setFormLoading(false);

                    if (response.success) {

                        setFormSubmitted(false);

                        collaboratorsFinal.splice(openConfirmationModal.index, 1);

                        setCollaborators(collaboratorsFinal);

                        setOpenConfirmationModal({ ...openConfirmationModal, active: false });


                    }
                    var id = toast((
                        <div className='toastinner'>
                            {response.message}
                            <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                        </div>), {
                        className: (response.success) ? 'successtoast' : 'errortoast',
                        position: 'bottom-center'
                    });

                })

        } else {
            const collaboratorsFinal = [...collaborators];

            setFormLoading(true);

            const token = ReactSession.get("token");

            var data = new FormData();
            data.append(
                "id", collaboratorsFinal[openConfirmationModal.index].id
            );
            data.append(
                "permission", openConfirmationModal.role
            );


            updateTeamMember(data, token)
                .then(response => {
                    setFormLoading(false);

                    if (response.success) {

                        setFormSubmitted(false);

                        collaboratorsFinal[openConfirmationModal.index]["role"] = openConfirmationModal.role;

                        setCollaborators(collaboratorsFinal);

                        setOpenConfirmationModal({ ...openConfirmationModal, active: false });


                    }
                    var id = toast((
                        <div className='toastinner'>
                            {response.message}
                            <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                        </div>), {
                        className: (response.success) ? 'successtoast' : 'errortoast',
                        position: 'bottom-center'
                    });

                })



        }


    }

    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    }

    const getDaysPassed = (lastactive) => {
        var yearsdiff = -moment.utc(lastactive).local().diff(moment(), "years");
        var monthsdiff = -moment.utc(lastactive).local().diff(moment(), "months");
        var daysdiff = -moment.utc(lastactive).local().diff(moment(), "days");

        var hoursdiff = -moment.utc(lastactive).local().diff(moment(), "hours");

        var minutesdiff = -moment.utc(lastactive).local().diff(moment(), "minutes");

        var secondsdiff = -moment.utc(lastactive).local().diff(moment(), "seconds");

        if (yearsdiff > 0) {
            return (
                <span className="days-passed-text">
                    {yearsdiff} year{yearsdiff == 1 ? "" : "s"} ago
                </span>
            );
        } else if (monthsdiff < 12 && monthsdiff > 0) {
            return (
                <span className="days-passed-text">
                    {monthsdiff} month{monthsdiff == 1 ? "" : "s"} ago
                </span>
            );
        } else if (daysdiff < 30 && daysdiff > 0) {
            return (
                <span className="days-passed-text">
                    {daysdiff} day{daysdiff == 1 ? "" : "s"} ago
                </span>
            );
        } else if (hoursdiff < 24 && hoursdiff > 0) {
            return (
                <span className="days-passed-text">
                    {hoursdiff} hour{hoursdiff == 1 ? "" : "s"} ago
                </span>
            );
        } else if (minutesdiff < 60 && minutesdiff > 0) {
            return (
                <span className="days-passed-text">
                    {minutesdiff} minute{minutesdiff == 1 ? "" : "s"} ago
                </span>
            );
        } else if (secondsdiff < 60 && secondsdiff >= 0) {
            return (
                <span className="days-passed-text">
                    {secondsdiff} second{secondsdiff <= 1 ? "" : "s"} ago
                </span>
            );
        } else {
            return <>--</>;
        }
    };



    const renderCollaborators = () => {

        var render = [];

        collaborators.forEach(function (item, index) {

            render.push(
                <tr key={"collab" + item.email}>
                    <td>
                        <div className={"add-member-email-td"}>{item.email}</div>
                    </td>
                    <td>
                        {(item.role == "Admin" || item.role == "Editor") ?
                            <div className="dropdown">
                                {(user.permission != "Editor" && loggedInUser.email !== item.email) ? (
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id={"dropdownMenuButton" + item}
                                            className="dropdown-toggle"
                                        >
                                            {item.role}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                href="#"
                                                onClick={()=>{
                                                        setOpenConfirmationModal({
                                                            "active":true,
                                                            "type":"update",
                                                            "title":"Are you sure?",
                                                            "msg":'Confirm if you want to update this collaborator role.',
                                                            "index":index,
                                                            "role":"Admin"
                                                        });

                                                    //updateCollabortorRole("Admin", index)
                                                }}
                                            >
                                                Admin
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                href="#"
                                                onClick={() => {
                                                    setOpenConfirmationModal({
                                                        "active":true,
                                                        "type":"update",
                                                        "title":"Are you sure?",
                                                        "msg":'Confirm if you want to change this collaborator role.',
                                                        "index":index,
                                                        "role":"Editor"
                                                    });

                                                    //updateCollabortorRole("Editor", index)
                                                }}
                                            >
                                                {" "}
                                                Editor
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    item.role
                                )}
                            </div> : <div>Owner</div>}
                    </td>
                    <td>
                        {item.last_active ? getDaysPassed(item.created_at) : "Invited"}
                        {/* {item.created_at ? getDaysPassed(item.created_at) : "-"} */}
                    </td>
                    {(item.role == "Admin" || item.role == "Editor") &&
                        <td>
                            {user.permission != "Editor" && loggedInUser.email !== item.email && (
                                <>
                                    <div className="delete-collab-row">

                                        <span
                                            className="delete-table-row"
                                            onClick={() => {
                                                setOpenConfirmationModal({
                                                    "active": true,
                                                    "type": "delete",
                                                    "title": "Are you sure",
                                                    "msg": 'Confirm if you want to delete this collaborator?',
                                                    "index": index,
                                                    "role": ''
                                                });
                                                //removeCollaborator(index)
                                            }}
                                        >
                                            <img
                                                src={process.env.REACT_APP_URL + "images/trash-black.svg"}
                                                alt="img"
                                            />
                                        </span>
                                    </div>
                                </>
                            )}
                        </td>}
                </tr>
            );

        })

        return render;

    }
    const renderUnsavedCollaborators = () => {

        var render = [];

        unsavedcollaborators.forEach(function (item, index) {

            render.push(
                <div className="create-projectmodal-form-group">
                    <div className="project-colaborator-form-wrap position-relative">
                        <div className="input form-group  input-field">
                            <div className="position-relative w-100">
                                <input
                                    value={item.email}
                                    type="text"
                                    onChange={(e) => updateUnsavedCollaboratorsEmail(e.target.value, index)}
                                    className={
                                        formErrors.email != null
                                            ? "form-control " +
                                            formErrors.error_class
                                            : "form-control"
                                    }
                                    id={`${"email" + index}`}
                                />
                                <label htmlFor={`${"email" + index}`}>
                                    Enter Email
                                </label>
                                {formErrors.email != null && (
                                    <span
                                        className={formErrors.error_class}
                                    >
                                        {formErrors.email}
                                    </span>
                                )}
                            </div>
                            <span className="project-permission-drop-down">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                        {item.role}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            href="#"
                                            onClick={() => updateUnsavedCollaboratorsRole(
                                                "Admin", index
                                            )}
                                        >
                                            Admin
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#"
                                            onClick={() => updateUnsavedCollaboratorsRole(
                                                "Editor", index
                                            )}
                                        >
                                            {" "}
                                            Editor
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </div>
                        <div className="last-active-wrap close-add-collaborator">
                            &nbsp;
                            <span
                                className="delete-table-row"
                                onClick={() => {
                                    const collaboratorsFinal = [...unsavedcollaborators];
                                    collaboratorsFinal.splice(index, 1);
                                    setUnsavedCollaborators(collaboratorsFinal);
                                }}
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "images/cross.svg"
                                    }
                                    alt="img"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            );

        })

        return render;

    }

    const handleBlurInput = (e)=>{
        e.preventDefault();
        tmpSaveEmail();
    }

    const tmpSaveEmail = ()=>{
        var value = currentUnsaved;

        if (value) {
            //setUnsavedCollaborators([...unsavedcollaborators,currentUnsaved]);
            //setUnsavedCollaborators([...unsavedcollaborators,currentUnsaved]);

            const emailValidator = /\S+@\S+\.\S+/.test(value);

            // check email is alreadt is exits
            const checkEmailExits = unsavedcollaborators.findIndex(item => item.email === value);

            if (checkEmailExits === -1) {

                const totalCount = collaborators.length + unsavedcollaborators.length;

                if (totalCount === 10) {
                    toast(
                        <div className="toastinner">
                            <FormattedMessage id="You can add upto a maximum of 10 team members." />
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: 3000,
                        }
                    );
                    return false;
                }
                setUnsavedCollaborators([...unsavedcollaborators, {
                    "id": null,
                    "user_id": null,
                    "email": value,
                    "last_active": null,
                    "role": 'Admin',
                    "is_valid": emailValidator

                }]);
                setCurrentUnsaved("");
            } else {
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="Email with the same name already exists." />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 3000,
                    }
                );
            }

            // check is user email all valid or not
            checkAllValidEmail([...unsavedcollaborators, {
                "id": null,
                "user_id": null,
                "email": value,
                "last_active": null,
                "role": 'Admin',
                "is_valid": emailValidator

            }]);



        }
    }

    const handleKeyDown = (e) => {
        if (["Enter", "Tab", ","].includes(e.key)) {
            e.preventDefault();

            tmpSaveEmail();

        }
    }
    const handleChange = (e) => {
        setCurrentUnsaved(e.target.value);
    }
    const handlePaste = () => {
    }
    const handleDelete = (index) => {
        const collaboratorsFinal = [...unsavedcollaborators];
        collaboratorsFinal.splice(index, 1);
        setUnsavedCollaborators(collaboratorsFinal);

        // check is user email all valid or not
        checkAllValidEmail(collaboratorsFinal);


    }

    // check all new enter are valid email or not
    const checkAllValidEmail = (checkEmailAllValid) => {

        if (checkEmailAllValid.length === 0) {
            setIsActiveInvite(false)
        } else {
            setIsActiveInvite(true)
        }


        let totalValidEmailErrorCount = 0;
        if (checkEmailAllValid.length > 0) {
            for (var i = 0; i < checkEmailAllValid.length; i++) {
                if (checkEmailAllValid[i].is_valid === false) {
                    totalValidEmailErrorCount = totalValidEmailErrorCount + 1;
                }
            }
        }

        // check user is exits
        if (totalValidEmailErrorCount > 0) {
            setIsActiveInvite(false)
        }
    }

    // check all email valid are not send invitation
    const sendInvitation = () => {

        if (unsavedcollaborators.length === 0) {
            toast((
                <div className='toastinner'>
                    Please enter atleast one email for sending invitaion.
                    <a onClick={() => { toast.dismiss(); }}>&times;</a>
                </div>), {
                className: 'errortoast',
                position: 'bottom-center'
            });
        } else if (!isActiveInvite) {
            toast((
                <div className='toastinner'>
                    Please remove all invalid email before sending invitation
                    <a onClick={() => { toast.dismiss(); }}>&times;</a>
                </div>), {
                className: 'errortoast',
                position: 'bottom-center'
            });
        } else {

            const token = ReactSession.get("token");

            var data = new FormData();

            unsavedcollaborators.forEach(item => {
                data.append(
                    "collaborators[]", JSON.stringify(item)
                );
            });

            data.append(
                "role", isRole
            );

            setSendInvitationLoading(true);

            verifyEmailExits(data, token)
                .then(response => {
                    setSendInvitationLoading(false);

                    if (response.success) {

                        setOpenInvitationModal({ active: true, 'msg': response.message, confirmBtn: response.confirmBtn });

                        //setFormSubmitted(false);

                        // closeInvitationModal();
                        //setUnsavedCollaborators([]);

                        //getCollabortorsApi();

                    } else {
                        toast((
                            <div className='toastinner'>
                                {response.message}
                                <a onClick={() => { toast.dismiss(); }}>&times;</a>
                            </div>), {
                            className: 'errortoast',
                            position: 'bottom-center'
                        });
                    }
                })
        }
    }

    return (
        <LayoutResearcher
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"team"}
        >

            <div className={"team-page-wrapper"}>
                <div className="page-min-height">
                    <ResearcherProfileNavigation isLoading={false} activeMenu="team" />

                    <div className="profile-info-section">
                        <div className="profile-info-left w-100">
                            <div className="profile-info-text">
                                <h1 className="page-main-heading">Team</h1>
                                <p>
                                    Manage your team here – add or remove collaborators, and review
                                    people's roles.{" "}
                                </p>
                            </div>
                        </div>
                    </div>

                    {isLoading ? (
                        <LoadingIcon/>

                    ) : (
                        <>
                            <div className="profile-form-wrap">
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Account collaborators</h3>
                                                <p>
                                                    Account collaborators can be added as editors or admins. Admins will be able to purchase credits and view billing informations.
                                                    Editor have full access to projects, studies and panel features but will not be able to add collaborators or view billing informations.
                                                    {user.permission != "Editor" &&
                                                    <span className="font-weight-600 gray-color">{" "}You can add up to 10 collaborators.</span>
                                                    }
                                                </p>
                                                {!isLoading && plan_id == 2 && (
                                                    <div className="group-slide-left added-team-member-info">
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_URL +
                                                                "images/user-profile-icon.png"
                                                            }
                                                            alt="img"
                                                        />
                                                        <span>
                                                            <b>
                                                                {plan_id == 2}
                                                                {collaborators.length}
                                                            </b>{" "}
                                                            collaborators
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {isLoading && (
                                            <div className="col-md-8">
                                                <div className={"loader"}>Loading...</div>
                                            </div>
                                        )}
                                        {!isLoading && (
                                            <div className="col-md-8">
                                                <form onSubmit={onSubmit}>
                                                    {errorMsg && (
                                                        <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                                            <div className="pro-lft-wrap">{errorMsg}</div>
                                                            <div className="pro-right-wrap">
                                                                <div className="delete-progress-row">
                                                                    <img
                                                                        onClick={clearErrorMessage}
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/cross.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {successMsg && (
                                                        <div className="profile-complete-progress-wrap login-message-wrap">
                                                            <div className="pro-lft-wrap">{successMsg}</div>
                                                            <div className="pro-right-wrap">
                                                                <div className="delete-progress-row">
                                                                    <img
                                                                        onClick={clearSuccessMessage}
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/cross.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {user.permission != "Editor" &&
                                                        <>


                                                            <div className="collab-section-hold">
                                                                <div className="add-collab-member-wrap">
                                                                    <label>Add new collaborators</label>
                                                                    <div className="collab-items-wrap">

                                                                        {unsavedcollaborators.map((item, index) => (
                                                                            <div className={`tag-item ${item.is_valid ? '' : 'team-email-chips-error'}`} key={index}>
                                                                                {item.email}
                                                                                <button
                                                                                    type="button"
                                                                                    className="button"
                                                                                    onClick={() => handleDelete(index)}
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                            </div>
                                                                        ))}


                                                                        {/*<input*/}
                                                                        {/*    className={"input "}*/}
                                                                        {/*    value={currentUnsaved}*/}
                                                                        {/*    placeholder={`${(unsavedcollaborators.length===0)?'Email address, comma separated':''}`}*/}
                                                                        {/*    onKeyDown={(e) => handleKeyDown(e)}*/}
                                                                        {/*    onBlur={(e)=>handleBlurInput(e)}*/}
                                                                        {/*    onChange={(e) => handleChange(e)}*/}
                                                                        {/*//onPaste={handlePaste}*/}
                                                                        {/*/>*/}
                                                                        <AutosizeInput
                                                                            name="form-field-name"
                                                                            className={"auto-width-input"}
                                                                            value={currentUnsaved}
                                                                            placeholder={`${(unsavedcollaborators.length===0)?'Email address, comma separated':''}`}
                                                                            onChange={(e) => handleChange(e)}
                                                                            onKeyDown={(e) => handleKeyDown(e)}
                                                                            onBlur={(e)=>handleBlurInput(e)}
                                                                        />
                                                                    </div>

                                                                    {/*<div className="dropdown">*/}
                                                                    {/*    <div className="dropdown">*/}
                                                                    {/*        <button type="button" id="dropdownMenuButton[object Object]" aria-expanded="false" className="dropdown-toggle dropdown-toggle btn btn-primary">Editor</button>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                    <div className="dropdown">
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                id={"dropdownMenuButton-item"}
                                                                                className="dropdown-toggle"
                                                                            >
                                                                                {isRole}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    href="#"
                                                                                    onClick={() => updateNewCollabortorRole("Admin")}
                                                                                >
                                                                                    Admin
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                    href="#"
                                                                                    onClick={() => updateNewCollabortorRole("Editor")}
                                                                                >
                                                                                    {" "}
                                                                                    Editor
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>


                                                                <div className="send-invition-btn-wrap">
                                                                    <button type="button" onClick={() => sendInvitation()}
                                                                        className={`button button-primary form-btn ${(!isActiveInvite) ? 'disabled-button' : ''} send-invite-button`}>

                                                                        {sendInvitationLoading &&
                                                                            <i
                                                                                className="fa fa-spinner fa-spin"
                                                                                aria-hidden="true"
                                                                                style={{ marginLeft: "5px" }}
                                                                            ></i>
                                                                        }
                                                                        SEND INVITE
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {plan_id != 1 &&
                                                        !isLoading &&
                                                        collaborators.length > 0 && (
                                                            <div className="project-setting-table-wrap team-page-table-wrap">
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Email</th>
                                                                            <th>Role</th>
                                                                            <th>Active since</th>
                                                                            <th>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>{renderCollaborators()}</tbody>
                                                                    {/*<tbody>{renderUnsavedCollaborators()}</tbody>*/}
                                                                </table>
                                                            </div>
                                                        )}

                                                    <div className="add-plan-member-sec">


                                                        {plan_id != 2 && !isLoading && (
                                                            <div className="block-colaborator-section">
                                                                <div className="lock-img-sec">
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/lock-icon.svg"
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="text-wrap">
                                                                    {/*<p>*/}
                                                                    {/*    You don’t have access to team features on a*/}
                                                                    {/*    free plan.{" "}*/}
                                                                    {/*    <Link*/}
                                                                    {/*        to="/researcher/billing/plan/change"*/}
                                                                    {/*        className="updrate-plan-link"*/}
                                                                    {/*    >*/}
                                                                    {/*        Upgrade*/}
                                                                    {/*    </Link>{" "}*/}
                                                                    {/*    your plan to add team members.*/}
                                                                    {/*</p>*/}
                                                                    {/*<button*/}
                                                                    {/*    onClick={(e) => {*/}
                                                                    {/*        e.preventDefault();*/}
                                                                    {/*        navigate("/researcher/billing/plans");*/}
                                                                    {/*    }}*/}
                                                                    {/*    type="button"*/}
                                                                    {/*    className="btn form-btn"*/}
                                                                    {/*>*/}
                                                                    {/*    Upgrade now*/}
                                                                    {/*</button>*/}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {/*              {user.permission != "Editor" && (*/}
                                                        {/*                  <>*/}
                                                        {/*                      {plan_id == 2 && !isLoading && (*/}
                                                        {/*                          <div className="add-new-memberbtn-option mb-0">*/}
                                                        {/*                              <div*/}
                                                        {/*                                  className="create-btn add-new-card-btn add-new-member-btn"*/}
                                                        {/*                                  onClick={*/}
                                                        {/*                                      ()=>{*/}
                                                        {/*                                          setUnsavedCollaborators([...unsavedcollaborators,*/}
                                                        {/*                                              {"id" : null,*/}
                                                        {/*                                                  "user_id" : null,*/}
                                                        {/*                                                  "email": null,*/}
                                                        {/*                                                  "last_active" : null,*/}
                                                        {/*                                                  "role": 'Admin'}]);*/}
                                                        {/*                                      }*/}
                                                        {/*                                  }*/}
                                                        {/*                              >*/}
                                                        {/*                                  Add new member*/}
                                                        {/*                                  <span>*/}
                                                        {/*  <img*/}
                                                        {/*      src={*/}
                                                        {/*          process.env.REACT_APP_URL +*/}
                                                        {/*          "images/plus-white.svg"*/}
                                                        {/*      }*/}
                                                        {/*  />*/}
                                                        {/*</span>*/}
                                                        {/*                                  {memberLoading && (*/}
                                                        {/*                                      <i*/}
                                                        {/*                                          className="fa fa-spinner fa-spin"*/}
                                                        {/*                                          aria-hidden="true"*/}
                                                        {/*                                          style={{ marginLeft: "5px" }}*/}
                                                        {/*                                      ></i>*/}
                                                        {/*                                  )}*/}
                                                        {/*                              </div>*/}
                                                        {/*                          </div>*/}
                                                        {/*                      )}*/}
                                                        {/*                  </>*/}
                                                        {/*              )}*/}

                                                        {plan_id == 2 && !isLoading && (
                                                            <div className="dashboard-top-right team-btns-wrap">
                                                                {formSubmitted && (
                                                                    <button className="create-btn changes-saved-btn">
                                                                        <span>
                                                                            <img
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/fill-check.svg"
                                                                                }
                                                                                alt="img"
                                                                            />
                                                                        </span>
                                                                        Changes saved
                                                                    </button>
                                                                )}
                                                                {user.permission != "Editor" && (
                                                                    <>
                                                                        {!formSubmitted && (
                                                                            <>
                                                                                {/*<button*/}
                                                                                {/*    type="button"*/}
                                                                                {/*    className="btn form-btn secondary-btn"*/}
                                                                                {/*    onClick={() => {*/}
                                                                                {/*        // navigate(-1);*/}
                                                                                {/*        setUnsavedCollaborators([]);*/}
                                                                                {/*    }}*/}
                                                                                {/*>*/}
                                                                                {/*    Cancel*/}
                                                                                {/*</button>*/}
                                                                                {/*                                  <button*/}
                                                                                {/*                                      type="button"*/}
                                                                                {/*                                      className="btn form-btn"*/}
                                                                                {/*                                      onClick={()=>{setOpenInvitationModal(true);}}*/}
                                                                                {/*                                  >*/}
                                                                                {/*                                      Save*/}
                                                                                {/*                                      /!* {formLoading && (*/}
                                                                                {/*  <i*/}
                                                                                {/*    className="fa fa-spinner fa-spin"*/}
                                                                                {/*    aria-hidden="true"*/}
                                                                                {/*    style={{ marginLeft: "5px" }}*/}
                                                                                {/*  ></i>*/}
                                                                                {/*)} *!/*/}
                                                                                {/*                                  </button>*/}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {openInvitationModal && (
                <InvitationModal
                    openModal={openInvitationModal.active}
                    closeModal={closeInvitationModal}
                    invitatioModalData={openInvitationModal}
                    confirmInvitation={onSubmit}
                    formLoading={formLoading}
                />

            )}

            <ConfirmationModal
                openModal={openConfirmationModal.active}
                closeModal={closeConfirmationModal}
                onConfirm={onConfirm}
                modalData={openConfirmationModal}
                formLoading={formLoading}
            />
        </LayoutResearcher>
    );
}