import PropTypes from 'prop-types';
import { CheckBox } from '../CheckBox/CheckBox';
import { Icon } from '../Icon/Icon';
import { RadioButton } from '../RadioButton/RadioButton';
import { Text } from '../Text/Text';
import {TakeTestQuestionImage} from "../QuestionImage/TakeTestQuestionImage";
import React from "react";

export const TesterQuestionOptions = ({ id, type, label, isChecked, value, surveyoptions, surveysubquestions, onChange,optionImage }) => {

    return (<>
        {type == "singlechoice" &&

            <div className={`option-holder ${optionImage ? 'mb-20 test-answer-with-image' : ''}`}>

                <RadioButton id={id} label={<Text display={"inline"} type={"body-text-2"}>{label}</Text>} isChecked={isChecked} value={value} onChange={onChange} />

                <TakeTestQuestionImage type={'option'} url={optionImage} />
            </div>
        }
        {type == "multiplechoice" &&
            <div className={`option-holder ${optionImage ? 'mb-20 test-answer-with-image' : ''}`}>
                <CheckBox id={id} label={<Text display={"inline"} type={"body-text-2"}>{label}</Text>} checked={isChecked} value={value} onChange={onChange} />

                <TakeTestQuestionImage type={'option'} url={optionImage} />
            </div>
        }
        {type == "likertscale" &&
            <div className='likert-scale-taking-test-view'>
                <div className="survey-rating-options-wrap likret-scale-top-row none-for-mobile ">
                    <div className="bd-question-radio-data w-100">
                        <div className="ans-list-repeat saved-ans-repeat w-100 mt-32">
                            <div className="likert-option-left">
                                <div className="ans-icon">&nbsp;</div>
                                <div className="ans-data">&nbsp;</div>
                            </div>
                            <div className="likert-option-right">
                                {surveyoptions.map((option, index) => (
                                    <label htmlFor="radio4" key={index}>
                                        <p>{option.option_value}</p>
                                    </label>
                                ))}
                            </div>
                        </div>


                    </div>
                </div>
                <div className={`likert-innerview`}>
                    {surveysubquestions.map(
                        (subQuestion, subquestionindex) => (
                            <div className="ans-list-wrap likert-scale-option-add" key={subquestionindex} id={`subquestion-${subquestionindex}`}>
                                <div className="ans-list-repeat saved-ans-repeat">
                                    <div className="likert-option-left">
                                        <div className="ans-data">{subQuestion.subquestion}</div>
                                    </div>
                                    <div className="likert-option-right">
                                        <div className="survey-rating-options-wrap w-100">
                                            <div className="bd-question-radio-data w-100">
                                                <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio w-100">
                                                    {surveyoptions.map((option, index) => (
                                                        <>
                                                            <div className="likert-view-option-repeat" key={subquestionindex + '-' + index}>
                                                                <a
                                                                    href={`#subquestion-${subquestionindex + 1}`}
                                                                    onClick={() => {

                                                                    }
                                                                    }
                                                                >
                                                                    <RadioButton
                                                                        id={`option-select-${subquestionindex + '-' + index}`}
                                                                        isSelectedlabelBold={true}
                                                                        name={`option-select-${subquestionindex}`}
                                                                        value={option.id}
                                                                        checked={false
                                                                        }
                                                                        onChange={(e) => {
                                                                        }}
                                                                        label={option.option_value}
                                                                    />
                                                                </a>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        }
        {type == "rankingscale" &&
            <div className='ranking-data-wrap ranking-list-repeat saved-ans-repeat'>
                <div className='ranking-count'><p>01</p></div>
                <div className='rank-dragable-list-wrap'>
                        <div className='option-holder'>
                            <Icon value="move" />
                            <Text display={"inline"} type={"body-text-2"}>{label}</Text>
                        </div>
                </div>
            </div>
        }
    </>
    )
}
TesterQuestionOptions.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.node,
    type: PropTypes.string,
    id: PropTypes.string

};
TesterQuestionOptions.defaultProps = {
    isChecked: true,
    type: "singlechoice",
    onChange: undefined

};