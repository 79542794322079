import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";

export default function FigmaLogin(){

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        
        document.title = process.env.REACT_APP_NAME + " - Prototype test | Figma login";

        console.log(window.opener)

        const bc = new BroadcastChannel("figma_channel");

        bc.postMessage("code="+searchParams.get("code")+"&state="+searchParams.get("state"));

        bc.close();

        //window.opener.postMessage("code="+searchParams.get("state")+"&state="+searchParams.get("code"),'*')

       // window.opener.onSuccess(searchParams.get("state"),searchParams.get("code"));

    },[])
    return <></>;
}
