import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Text } from "../../../themes/userq/Text/Text";
import { Input } from '../../../themes/userq/Input/Input';
import { useNavigate } from "react-router-dom";
import { LoadingIcon } from './../../../loader/loadingIconNew';
import { Icon } from "../../../themes/userq/Icon/Icon";
import { createIndividualWorkspceService } from "../../../../services/workspaces";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";


export default function IndividualWorkspaceModal(props) {
    ReactSession.setStoreType("localStorage");
    const wrapperRef = useRef(null);
    const [formLoading, setFormLoading] = useState(false);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        workspaceName: ""
    });
    const [formErrors, setFormErrors] = useState({
        workspaceName: null
    });

    useEffect(() => {
        console.log("prororprp", props);
        let nameWorkspace = (props.firstName + "'s Workspace" + (+(props.workspaceCount + 1) > 1 ? ' ' + (props.workspaceCount + 1) : '' )).slice(0, 30);
        setFormValues({
            ...formValues,
            workspaceName: nameWorkspace,
        });

    }, [props.openModal])

    const closeModal = () => {
        setFormValues({ workspaceName: "" });
        props.closeModal(false);
    };

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };

    const onSubmit = async (event) => {
        event.preventDefault();


        if (!formLoading) {
            let error = false;
            if (formValues.workspaceName === "" || formValues.workspaceName === undefined || formValues.workspaceName === null) {
                showFormError("workspaceName", "A workspace name is required");
                error = true;
            } else {
                const token = ReactSession.get("token");
                setFormLoading(true);
                let formData = {};
                formData.title = formValues.workspaceName;
                createIndividualWorkspceService(formData, token).then((response) => {
                    setFormLoading(false);
                    if (response.success) {
                        if (response.workspace.id) {
                            let user = ReactSession.get("user");
                        user.signup_plan = null;
                        ReactSession.set("user", user);
                            navigate(`/wsp/${response.workspace.id}`);
                        }
                    } else {
                        showToast(response.message, "error");
                    }
                });
            }
        }
        return false;
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const checkCreateBtnState = () => {

        if (formValues.workspaceName === "" || formValues.workspaceName === undefined || formValues.workspaceName === null) {
            return "disabled";
        } else {
            return "active";
        }
    };


    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap test-setting-modal create-workspace-modal"
        >
            <Modal.Body ref={wrapperRef}>
                {formLoading ?
                    <div className="modal-loader test-setting-modal-loader">
                        <div className="creating-workspace-loader-inner text-center">
                        <img
                            src={
                            process.env.REACT_APP_URL + "/img/icons/Individual-Loader.gif"
                            }
                            alt="loader-img"
                        />
                        <Text type={"body-text-1"} fontWeight={"medium-font"}>
                            Creating Individual workspace...
                        </Text>
                        </div>
                    </div>
        
                    :
                    <div className={`modal-inner-text ${props.read_mode ? "test-setting-read-mode" : ""}`}>
                        <div className="modal-header-top">
                            <Text type="h3">Give your workspace a name</Text>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">

                                    <Icon value="Close" size="medium" hover={true} />
                                </span>
                            </button>
                        </div>


                        <div className="test-setting-middle-data">

                            <Input
                                label="Workspace name"
                                onChange={set("workspaceName")}
                                onClear={() => {
                                    setTimeout(() => {
                                        setFormValues((oldValues) => ({
                                            ...oldValues,
                                            workspaceName: "",
                                        }));
                                    }, 1000);
                                }}
                                value={formValues.workspaceName}
                                maxLength="30"
                                error={formErrors.workspaceName}
                                error_class={formErrors.error_class}
                                id="workspaceName"
                                clearField={false}
                                required={true}
                            />

                        </div>

                        <Button type="primary" cssclass={"mt-0"} size="large" label={"Create"} microLoading={formLoading} onClick={onSubmit} state={checkCreateBtnState()} />
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}