import React, { useEffect, useState } from "react";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Helmet } from "react-helmet";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
import "./workspace-no-access.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import {
    getWorkspacesListService,
    getWorkspaceService,
    acceptWorkInviteDashboardService,
} from "../../../../services/workspaces";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon.js";
import { showToast } from "../../../../lib/helpers.js";

const WorkspaceNoAccess = () => {
    let { workspaceid } = useParams();

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const [workspaces, setWorkspaces] = useState([]);

    const [workspace, setWorkspace] = useState(null);

    const [workspacesListLoading, setWorkspacesListLoading] = useState(false);

    const user = ReactSession.get("user");

    const navigate = useNavigate();

    useEffect(() => {
        getWorkspaceApi();
    }, []);
    const getWorkspaceApi = () => {
        let token = ReactSession.get("token");

        setWorkspacesListLoading(true);

        getWorkspaceService(token, workspaceid)
            .then((response) => {
                if (response.success) {
                    setWorkspace(response.workspace);

                    if (
                        response.workspace_team_member &&
                        response.workspace_team_member.status ===
                            "invitation_sent"
                    ) {
                        acceptInvitation();
                    } else if (
                        response.workspace_team_member &&
                        response.workspace_team_member.status ===
                            "invitation_accepted"
                    ) {
                        navigate(`/wsp/${workspaceid}`);
                    } else {
                        getWorkspacesListApi();
                    }
                } else {
                    if (response.message == "Workspace not found.") {
                        navigate("/404/");
                    }
                    setWorkspacesListLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);
                setWorkspacesListLoading(false);
            });
    };
    const acceptInvitation = () => {
        let token = ReactSession.get("token");

        let formData = {};

        formData.workspace_id = workspaceid;

        acceptWorkInviteDashboardService(formData, token)
            .then((response) => {
                if (response.success) {
                    navigate(`/wsp/${workspaceid}`);
                } else {
                    setWorkspacesListLoading(false);
                }
            })
            .catch((error) => {
                setWorkspacesListLoading(false);

                showToast(error, "error");
            });
    };
    const getWorkspacesListApi = () => {
        let token = ReactSession.get("token");

        setWorkspacesListLoading(true);

        getWorkspacesListService(token, 1)
            .then((response) => {
                setWorkspacesListLoading(false);

                if (response.success) {
                    setWorkspaces(response.workspaces);
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);
                setWorkspacesListLoading(false);
            });
    };

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass={"no-access-page-hold"}
        >
            <Helmet>
                <title>Access denied | UserQ</title>
            </Helmet>

            {workspacesListLoading ? (
                <>
                    <div className="workspace-page-loader">
                        <div
                            className={
                                "page-loader accountloading-box creditloading-box"
                            }
                        >
                            <LoadingIcon />
                        </div>
                    </div>
                </>
            ) : (
                <div className="no-access-page-wrap">
                    <div className="no-access-inner-wrap  mb-32">
                        <div className="tester-disbled-account-notice mb-60">
                            <Icon
                                colorClass={"danger-red-200"}
                                value={"blocked"}
                                size="large"
                            />

                            <Text type={"subtitle-1"}>
                                It seems that you no longer have access to{" "}
                                {workspace && workspace.title}.
                            </Text>
                            <Text type={"body-text-2"}>
                                Please get in touch with your team or{" "}
                                <a
                                    className="faq-support-link disabled-support-link"
                                    href={
                                        process.env.REACT_APP_URL +
                                        "r/support/contact"
                                    }
                                    target="_blank"
                                >
                                    UserQ support
                                </a>{" "}
                                if you are unsure why this has occurred.
                            </Text>
                        </div>

                        {workspaces.length == 0 ? (
                            <div className="back-dash-btn">
                                <Button
                                    type="primary"
                                    label="Create workspace"
                                    size={"large"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(
                                            "/r/choose-plan?from=noaccess"
                                        );
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="access-available-workspaces d-flex justify-content-between">
                                <div className="access-available-workspaces-left">
                                    <Text type={"h3"}>
                                        Available workspaces{" "}
                                    </Text>
                                    <Text
                                        type={"body-text-3"}
                                        cssClasses={"gray-color"}
                                    >
                                        You are signed in as{" "}
                                        {user && user.email}
                                    </Text>
                                </div>

                                <div className="access-available-workspaces-right">
                                    <div className="added-workspaces-for-user">
                                        {workspaces.map((workspace, index) => {
                                            return (
                                                <Link
                                                    key={"workspace" + index}
                                                    to={"/wsp/" + workspace.id}
                                                >
                                                    <div className="workspace-list-repeat d-flex align-items-center">
                                                        <span className="span-workspace-owner-icon">
                                                            <Icon
                                                                value={
                                                                    workspace.type ==
                                                                    "Individual"
                                                                        ? "user"
                                                                        : "group"
                                                                }
                                                                colorClass={
                                                                    "gray-50-svg"
                                                                }
                                                            />
                                                        </span>
                                                        <div className="workspace-type-info">
                                                            <Text
                                                                type={
                                                                    "body-text-3"
                                                                }
                                                                cssClasses={
                                                                    "black-color"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {
                                                                    workspace.title
                                                                }
                                                            </Text>
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                            >
                                                                {workspace.type}{" "}
                                                                workspace
                                                            </Text>
                                                        </div>
                                                        <span className="workspace-arrow">
                                                            <Icon
                                                                value={
                                                                    "chevron"
                                                                }
                                                                size={"small"}
                                                                colorClass={
                                                                    "gray-700-svg"
                                                                }
                                                                hover={true}
                                                            />
                                                        </span>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </LayoutResearcher>
    );
};
export default WorkspaceNoAccess;
