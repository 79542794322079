import React, { useEffect, useState, useRef } from "react";
import FirstClickTestIntroduction from "./firstclick_test_introduction";
import FirstClickTestTaskFlow from "./firstclick_test_task_flow";
import TaskNotCompleted from "../../elements/TaskNotCompleted";

export default function FirstClickTest({
    test,
    result,
    questionCallback,
    callback,
    questionType,
    timeSpent,
    isPaidUser
}) {
    const [isActiveTask, setActiveTask] = useState(0);
    const [initialScreen, setInitialScreen] = useState(true);
    const [taskScreen, setTaskScreen] = useState(false);

    const taskHasError = ()=>{
        var error = false;

        if(test.firstclick_test_tasks.length == 0){
            error = true;
        } else {
            var task  = test.firstclick_test_tasks[isActiveTask];

            if(!task.description){
                error = true;
            } else  if(!task.design_file){
                error = true;
            }
        }
        return error;
    }
    return (
        <>
            {initialScreen &&
                <FirstClickTestIntroduction goToFirstTask={
                    () => {
                        setInitialScreen(false);
                        setTaskScreen(true);
                    }
                }
                    testlanguage={test.language}
                    test={test}
                />
            }
            {taskScreen &&
                <>
                    {taskHasError() ?
                        <TaskNotCompleted to={"/r/test/"+test.id+"/first-click"}/>
                        :


                        <FirstClickTestTaskFlow goToFirstQuestion={
                            () => {
                                setTaskScreen(false);
                            }
                        }
                            test={test}
                            task={test.firstclick_test_tasks[isActiveTask]}
                            active_task={isActiveTask}
                            result={result}
                            timeSpent={timeSpent}
                            moveToNextTask={(task) => {
                                console.log(task);
                                console.log(test.firstclick_test_tasks.length);
                                if (task < test.firstclick_test_tasks.length) {
                                    setActiveTask(task);
                                } else {
                                    callback();
                                }
                            }}
                            isPaidUser={isPaidUser}
                        />
                    }
                </>
            }
        </>
    );
}
