import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../themes/userq/Button/Button";
import { Text } from "../../themes/userq/Text/Text";
import { Input } from './../../themes/userq/Input/Input';
//import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { createTest } from "../../../services/test";
import { LoadingIcon } from './../../loader/loadingIconNew';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import { ArabicNumbers } from 'react-native-arabic-numbers';
import { settings } from "../../../data/settings";
import {Icon} from "../../themes/userq/Icon/Icon";
import {DropdownComponent} from "../../themes/userq/Dropdown/Dropdown";
import { countries as payoutCountries } from "../../../data/countries.js";
import {updatetesterbankdetails} from "../../../services/user";
import toast from "react-hot-toast";
import {showToast} from "../../../lib/helpers";
import { ReactSession } from "../../../lib/secure_reactsession";
import {PhoneNumberInput} from "../../themes/userq/PhoneNumberInput/PhoneNumberInput";
import {isValidPhoneNumber} from "react-phone-number-input";
import { Link as UserQLink} from '../../themes/userq/Link/Link.js';

import { countries as sanctioned_countries } from "../../../data/countries_sanctioned.js";

export default function AddPaypalAccountModal(props) {
    const [formLoading, setFormLoading] = useState(false);

    const [bankFormValues, setBankFormValues] = useState({ name:'', email:'',phone:'',confirmEmail:'',type:'paypal'});
    const [bankFormErrors, setBankFormErrors] = useState({});
    const [isError, setError] = useState(false);

    const [displaySanctionedMessage, setDisplaySanctionedMessage] = useState(false);

    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);


    const closeModal = (status) => {
        //setFormValues({ test_name: "" });
        // if status true then pass formvalue
        if(status){
            props.closeModal(status,bankFormValues);
        }else{
            props.closeModal(false,null);
        }

    };

    useEffect(()=>{

        if(props.bankFormValuesData){

            var sanctioned_country = false;

            sanctioned_countries.forEach(function(country){
                if(props.bankFormValuesData.beneficiary_resident_country==country.value){
                    sanctioned_country = true;
                }
            });
            setDisplaySanctionedMessage(sanctioned_country);

            setBankFormValues({
                name:props.bankFormValuesData.name,
                email:props.bankFormValuesData.email,
                phone:props.bankFormValuesData.phone,
                confirmEmail:props.bankFormValuesData.email,
                type:'paypal'
            })
        }
        resetFormErrors()


    },[props.bankFormValuesData,props.openModal])

    useEffect(()=>{

        var error = false;


        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;



        if(bankFormValues.name==''){

            error = true;

        }
        if(bankFormValues.email==''){

            error = true;

        } else if(bankFormValues.email != null && !bankFormValues.email.match(mailformat)) {



            error = true;
        }
        var field_value = bankFormValues.phone;

        if(!bankFormValues.phone){
            error = true;
        }else if(field_value=== null || (field_value && !isValidPhoneNumber('+'+field_value.toString()))){

            error = true;
        }



        //}
        if(bankFormValues.confirmEmail==''){

            error = true;

        }else if(bankFormValues.confirmEmail != null && !bankFormValues.confirmEmail.match(mailformat)) {

            error = true;
        }
        if(bankFormValues.email && bankFormValues.confirmEmail &&bankFormValues.email !== bankFormValues.confirmEmail){

            error = true;

        }

        if(error){
            setError(true);
        }else{
            setError(false);
        }



    },[bankFormValues])


    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, bankFormErrors);

        for(var key in bankFormErrors){
            formErrorsLocal[key] = null;
        }
        setBankFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }

    const validateBankDetails = () => {

        var error = false;

        var form_errors = resetFormErrors();

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;



        if(bankFormValues.name==''){

            form_errors  = {
                ...form_errors,
                name: "Required!",
                error_class: "input_error"
            };
            error = true;

        }
        if(bankFormValues.email==''){

            form_errors  = {
                ...form_errors,
                email: "Required!",
                error_class: "input_error"
            };
            error = true;

        } else if(bankFormValues.email != null && !bankFormValues.email.match(mailformat)) {


            form_errors = {
                ...form_errors,
                email: "Please enter a valid email",
                error_class: "input_error"
            };

            error = true;
        }


        var field_value = bankFormValues.phone;

        if(field_value=== null || (field_value && !isValidPhoneNumber('+'+field_value.toString()))){

            // form_errors = {
            //     ...form_errors,
            //     phone_number: "Phone number is not valid",
            //     error_class: "input_error"
            // };
            // error = true;
            form_errors  = {
                ...form_errors,
                phone: "Phone number is not valid",
                error_class: "input_error"
            };
            error = true;
        }



        setBankFormErrors(form_errors);

        if(error){
            setError(true);
        }

        return error;

    }

    const validatePhoneForm = () =>{
        var field_value = bankFormValues.phone;

        if(!field_value){

            setBankFormErrors({
                ...bankFormErrors,
                phone: "Phone number is not valid",
                error_class: "input_error"
            });

        }else if(field_value=== null || (field_value && !isValidPhoneNumber('+'+field_value.toString()))){

            setBankFormErrors({
                ...bankFormErrors,
                phone: "Phone number is not valid",
                error_class: "input_error"
            });
        }
    }

    const updatebankdetails = () => {
        var error = validateBankDetails();

        //console.log(bankFormValues)
        //return false


        if(!error && !isError){
            setFormLoading(true);
            const token = ReactSession.get("token");
            var data = new FormData();
            data.append("bankdetails", JSON.stringify(bankFormValues));
            data.append("selectedbankcountry", bankFormValues.country);
            data.append("type", bankFormValues.type);
            // data.append("account_no_type", bankFormValues.iban);
            updatetesterbankdetails(data, token).then((response) => {

                setFormLoading(false);
                if (response.success) {
                    //gettesterbank();
                    //setIsBankAccountEditable(false);

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'select_payout',
                        'method' : 'paypal', //not both, depending on selection
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });

                    closeModal(true);

                    showToast(response.message,"success");


                } else {

                    showToast(response.message,"error");

                }
            });
        }
    };


    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap add-bank-account-modal "
        >
            <Modal.Body ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h3">{props.type} your Paypal account</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal(false);
                            }}
                            aria-label="Close"
                        >
                                <span aria-hidden="true">
                                    <Icon value="Close" size="large" hover={true} />
                                </span>
                        </button>
                    </div>
                    <div className="tester_profole_guide_info mb-20">
                        <Text type="body-text-2" fontWeight="medium-font" cssClasses="text-black">Make sure that your PayPal email address matches the email address associated with your UserQ account. PayPal accounts that do not match will not be accepted. If you have any question <UserQLink target={'_blank'} url={process.env.REACT_APP_URL+"t/support/contact?reason=Help with payout"}>contact our support team</UserQLink>.</Text>
                    </div>

                    <div className="add-bank-account-modal-middle-data">
                        {displaySanctionedMessage &&
                        <div className="formbox sanctioned-info">
                            <div className=' form-group input-field  input-with-right-icon mb-0  '>
                                <ToastMessage closable={false} type={"warning"} message={<>Please be aware that, in compliance with both local and international regulations, we may not be able to process payouts to certain countries. If you need more information, please refer to our <UserQLink target="_blank" url="https://userq.com/terms-of-use">Terms of use</UserQLink>.</>} />

                            </div>
                        </div>
                        }

                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={bankFormValues.email}

                                label={"Paypal email address"}
                                error_class={
                                    bankFormErrors.email != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.email}
                                clearField={false}
                                required={true}
                                readOnly={true}
                            />
                        </div>


                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={bankFormValues.name ? bankFormValues.name:''}
                                onBlur={(e)=>{
                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"name":"Required!", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"name":null, error_class:"input_error"});
                                    }
                                }}
                                onChange={(e)=>{
                                    setBankFormValues({...bankFormValues, name:e.target.value});

                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"name":"Required!", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"name":null, error_class:"input_error"});
                                    }
                                }}
                                label={"PayPal Username"}
                                error_class={
                                    bankFormErrors.name != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.name}
                                clearField={false}
                                required={true}
                            />
                        </div>

                        <div className={"mb-20"}>
                            <PhoneNumberInput
                                label={"PayPal phone number"}
                                value={bankFormValues.phone ? bankFormValues.phone:''}
                                onBlur={()=>{
                                    validatePhoneForm()
                                }}
                                onCountryChange={()=>{
                                    // setFormErrors({
                                    //     ...formErrors,
                                    //     phone_number: null,
                                    //     error_class: "input_error"
                                    // });
                                    // setProfileChanged(true);
                                    setBankFormErrors({...bankFormErrors,"phone":null, error_class:"input_error"});
                                }}
                                onChange={(value) => {
                                    setBankFormErrors({...bankFormErrors,"phone":null, error_class:"input_error"});
                                    setBankFormValues({...bankFormValues, phone:value});

                                }}
                                error_class={
                                    bankFormErrors.phone != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.phone}

                            />

                        </div>


                    </div>

                    <Button
                        type="primary"
                        size="large"
                        label="Save your Paypal information"
                        microLoading={formLoading}
                        onClick={updatebankdetails}
                        state={!isError?'active':'disabled'}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}