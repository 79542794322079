import React from "react";

const Grid = ({ children, columns }) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                gridGap: 20,
                maxWidth: "100%",
                margin: "0 auto 32px",
            }}
        >
            {children}
        </div>
    );
};

export default Grid;
