import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//import { preventClick } from '../../../lib/helpers';
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Text } from '../../themes/userq/Text/Text';
import { Button } from '../../themes/userq/Button/Button';
import { load } from 'recaptcha-v3'
import GoogleAuth from '../login-with-google';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage';
import { settings } from '../../../data/settings';
import { toast } from 'react-hot-toast';
import { Footer } from '../../themes/userq/Footer/Footer';

let verify_token = null;

export default function ResearcherRegistrationSuccess() {

    const navigate = useNavigate();

    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);

    const [timer, setTimer] = useState(60000);

    const [sentEmail, setSentEmail] = useState(true);

    useEffect(() => {
        
        document.title = "Registration Success | "+process.env.REACT_APP_NAME;

        countDown();

    }, []);
    const countDown = ()=>{
        setTimeout(()=>{
        
            setTimer(timer-1000);
                
        },1000);

        
    }
    useEffect(() => {
        
        if(timer>0){
            countDown();
        }


    

    }, [timer]);

    const generateCaptchaVerify = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3,{renderParameters:{badge:"bottomleft"}}).then((recaptcha) => {
            
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;      
                
                callback(token);
            })
        })
    }
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const timeFormat = (time)=>{
        var ms = time;
        var min = Math.floor((ms/1000/60) << 0);
        var sec = Math.floor((ms/1000) % 60);
        return min + ':' + (sec < 10 ? "0"+sec : sec);

    }
    const resendVerifyEmail = () => {
        
        generateCaptchaVerify(function(token){
            
            verify_token = token;

            setLoading(true);

            
            fetch(process.env.REACT_APP_API_END_POINT+'check_if_email_verified?verify_token='+verify_token+'&email='+encodeURIComponent(ReactSession.get("register_email"))+"&role=Researcher", {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);

                if(response.success){
                    setTimer(60000);
                    if(response.data.verified){ 
                        navigate("/researcher/sign-in");
                    } else {
                        showSuccess(response.message); 
                    }
                } else {
                    
                    
                    if(response.data && response.data.account && response.data.used_with_social){
                        
                        /*setPageHeading(response.message);

                        setSubHeading(response.data.sub_heading);

                        setSocial(true);

                        setAccountLinked(true);*/
                    }
                    else if(response.data &&  !response.data.account){
                        
                        /*ReactSession.set("register_email", formValues.email);
                        if(searchParams.get('refID')){
                            navigate({
                                "pathname":"/researcher/sign-up",
                                "search" : '?refID='+searchParams.get('refID')
                            });
                        }else{
                            navigate("/researcher/sign-up");
                        }*/
                    } else {
                        
                        showError(response.message);
                    }
                    
                }
            });
        })
        
        
    }
   
    return (
        <div className="researcher-verify-email-wrapper">
           
            <div className="researcher-verify-box-outer">
                <div className="container">
                    <div className="researcher-verify-box">
                        <Icon value={"email"} colorClass={'secondry-purple-200-svg'} size={"extralarge email-auto-margin"} />

                        <Text type={"h3"} fontWeight={"semi-bold-font"}>
                        Please verify your email
                        </Text>
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                        You’re almost there! We sent you an email to
                        </Text>
                        <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>
                            {ReactSession.get("register_email")}
                        </Text>
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                        Just click on the link in that email to complete your signup.<br/>
                            If you don’t see it, you may need to <span>check your spam</span> folder.
                        </Text>
                        <div className="full stillcantsee-box mt-32">
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                        Still can’t see it?
                        </Text>
                        </div>
                        <Button 
                            type={"primary"}
                            size={"large"}
                            microLoading={isLoading}
                            label={"Resend email "+(timer > 0 ? timeFormat(timer):'')}
                            onClick={()=>{ if(timer==0){resendVerifyEmail() } }}
                            state={( (timer > 0 && sentEmail))?"disabled":"active"}
                                        
                        />
                        
                        <div className="login-form-inner-data researcher-verify-bottomlink">
                            
        <div className="researcher-verify-bottom-left"> Back to <Link to="/researcher/sign-in" className="link linktype-2 back-to-sign-inbtn"> Sign in</Link></div>
                           <div className="researcher-verify-bottom-right">     Need help? <a href='https://userq.com/contact-us/' className="link linktype-2 back-to-sign-inbtn">Contact support</a></div>
                        </div>
                    </div>
                </div>
            </div>
                <Footer />
        </div>
    )
}