import React from 'react';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../lib/secure_reactsession';
import { Navigate } from "react-router-dom";
import SwipeRefreshView from 'react-swipe-refresh';

export default function Testing() {

    return (
        <>
            <SwipeRefreshView
                disableChromeDefaultRefresh
            >
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello12 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello452 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
     <p>Hello785 </p>
            </SwipeRefreshView>
            </>
    );


}
