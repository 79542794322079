import React from "react";
import { Footer } from "./themes/userq/Footer/Footer";
import { Logo } from "./themes/userq/Logo/Logo";
import "./page_unauthorized.css";
import { Text } from "./themes/userq/Text/Text";
import { Button } from "./themes/userq/Button/Button";
import { Icon } from "./themes/userq/Icon/Icon";
import { Helmet } from "react-helmet";

const PageUnauthorized = () => {
    return (
        <>
            <Helmet>
                <title>
                    Unauthorized | UserQ
                </title>
            </Helmet>
            <div className="header researcher-header d-flex align-items-center header-with-logo-only">
                <div className="container">
                    <div className="header-inner-data body-text body-text body-text-2 medium-font d-flex">
                        <div className="header-lhs-side">
                            <div className="logo">
                                <Logo theme={"white"} background={"black"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="session-loged-out-data">
                <div className="session-log-out-inner-data">
                    <Text type={"h2"}>You’ve been logged out</Text>
                    <Text
                        type={"body-text-2"}
                        fontWeight={"medium-font"}
                        cssClasses={"gray-color mt-20 mb-20"}
                    >
                        You have been logged out of UserQ as your account was
                        accessed on another device. To avoid this in the future,
                        please log in with one device or browser at a time.
                    </Text>

                    <Button 
                        tag="link"
                        href="/researcher/sign-in" 
                        label={"Sign in"} 
                        size={"large"} 
                        type={"primary"} 
                    />

                    <div className="switch-mamber-team d-flex align-items-center position-static w-100 mt-40 mb-40">
                        <Text type={"body-text-1"} fontWeight={"medium-font"}>
                            Would you like to collaborate with team members in
                            the same workspace?
                        </Text>

                        <Button
                            type="primary"
                            tag="href"
                            href="https://userq.com/pricing"
                            size={`medium`}
                            cssclass={"white-bg-btn"}
                            iconLeft={<Icon value={"group"} size={"medium"} />}
                            label="Switch to Team plan"
                        />
                    </div>

                    <Button
                        type="ghost"
                        tag="href"
                        href="https://userq.com"
                        size={`medium`}
                        iconLeft={<Icon value={"back arrow"} size={"medium"} />}
                        label="Back to home page"
                    />
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PageUnauthorized;
