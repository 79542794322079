export default function redirectToStep({current_step, saved_step, test_id, navigate, test}) {
    if(current_step > saved_step+1){
        if(saved_step==0){
            navigate("/r/test/"+test_id+"/welcome")
        }
        if(saved_step==1){
            if(test.methodology=="Tree Test"){
                navigate("/r/test/"+test_id+"/tree-test")
            }
            if(test.methodology=="Preference Test"){
                navigate("/r/test/"+test_id+"/preference-test")
            }
            
        }
        if(saved_step==2){
            navigate("/r/test/"+test_id+"/thank-you")
        }
        if(saved_step==3){
            navigate("/r/test/"+test_id+"/recruit")
        }
        if(saved_step==4){
            navigate("/r/test/"+test_id+"/review")
        }
        if(saved_step==5){
            navigate("/r/test/"+test_id+"/publish")
        }
    }
} 
