import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { encryptId } from "../../../../lib/helpers";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { changeTestStatus } from "../../../../services/test";
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import { LoadingIcon } from "../../../loader/loadingIconNew";
import { Button } from "../Button/Button";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { Icon } from "../Icon/Icon";
import { Tag } from "../Tag/Tag";
import { CheckBox } from "../CheckBox/CheckBox";
import { Text } from "../Text/Text";
import { checkScreeningResult, getScreeningResult, saveScreeningResult } from "../../../../services/test_result";
import { TesterQuestionOptions } from "../TesterQuestionOptions/TesterQuestionOptions";
import {GuestHeader} from "../Header/GuestHeader";
import { Footer } from "../Footer/Footer";

import moment from "moment";
let start_time = moment();

export default function ScreeningQuestionsModal({ openModal, closeModal, test,location }) {

    // const wrapperRef = useRef(null);
    const navigate = useNavigate();
    const [timeSpentDisplay, setTimeSpentDisplay] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isActiveQuestion, setActiveQuestion] = useState(null);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [QuestionArrayList, setQuestionArrayList] = useState([]);
    const [formValues, setFormValues] = useState({ type: null, question_id: null, answer: '', option_id: [] });
    const [formErrors, setFormErrors] = useState({ error: '', error_class: '' });
    const nextButtonActive = useRef(false);
    const goodfit = useRef(null);
    const [screeningFailed, setScreeningFailed] = useState(false);

    const [checkGoodFit, setCheckGoodFit] = useState(false);

    const [isTester, setTester] = useState(false);

    useEffect(()=>{
        start_time = moment();
    },[]);
    useEffect(() => {
        /*setInterval(() => {
            setTimeSpentDisplay(moment().diff(start_time,"seconds"));
        }, 1000)*/
    },[])

    const showSuccessToast = (message) => {

        toast(
            <ToastMessage type={"success"} cssClasses={test.language=="ar"?"arabic_wrapper arabic_toast":""} message={message} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                id:"success",
                className: "successtoast",
                position: "bottom-center",
                duration: 5000,
            }
        );
    };

    const showErrorToast = (error) => {

        toast(
            <ToastMessage type={"error"} cssClasses={test.language=="ar"?"arabic_wrapper arabic_toast":""} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: 5000,
            }
        );
    };

    const checkScreeningResults = (test) => {

        const token = ReactSession.get("token");

        const userData = ReactSession.get("user");

        // check and set tester true
        if(token && userData && userData.role === 'Tester'){
            setTester(true);
        }

        const data = new FormData();
        data.append("test_id", test.id);

        if (!ReactSession.get("guest_token")) {
            const randomToken = Math.random().toString(36).substr(2, 15);
            ReactSession.set("guest_token", randomToken);
        }

        // setModalLoading(true);
        setLoading(true);
        getScreeningResult({
            test_id: test.id,
            guest_token:ReactSession.get("guest_token")
        }, token).then((response) => {
            // setModalLoading(false);
            setLoading(false);
            //console.log(response);
            if (response.success) {
                if (response.screeningResult && location === 't') {
                    if (response.screeningResult.screening_completed == 1 && response.screeningResult.screening_passed == 1) {
                        navigate("/t/" + encryptId(test.id, test));
                    } else if (response.screeningResult.screening_completed == 1 && response.screeningResult.screening_passed == 0) {
                        closeModal(false);
                        // setScreeningFailed(true);
                    }
                    else {
                        start_time = moment();
                        setActiveQuestion(response.screeningResult.jump_to_question);
                        let Questions = [];
                        setTotalQuestion(response.screeningQuestions.length);

                        response.screeningQuestions.forEach((items) => {
                            Questions.push({ 'data': items, 'questionData': JSON.parse(items.questions) });
                        });

                        setQuestionArrayList(Questions);
                        const questionTypeData = Questions.filter((item, indexItem) => indexItem + 1 === 1)[0];
                        if (questionTypeData.data.question_type === 'singlechoice') {
                            setFormValues({ ...formValues, type: 'single', is_optional: questionTypeData.questionData.is_optional });
                        }
                    }
                } else {
                    let Questions = [];
                    setTotalQuestion(response.screeningQuestions.length);

                    response.screeningQuestions.forEach((items) => {
                        Questions.push({ 'data': items, 'questionData': JSON.parse(items.questions) });
                    });

                    setQuestionArrayList(Questions);
                    const questionTypeData = Questions.filter((item, indexItem) => indexItem + 1 === 1)[0];
                    if (questionTypeData.data.question_type === 'singlechoice') {
                        setFormValues({ ...formValues, type: 'single', is_optional: questionTypeData.questionData.is_optional });
                    }
                }
            } else {
                showErrorToast(response.message);
            }
        });
    }

    const saveScreeningResults = () => {

        if(location === 't'){

            const token = ReactSession.get("token");
            const data = new FormData();
            data.append("test_id", test.id);
            data.append("guest_token", ReactSession.get("guest_token"));
            setModalLoading(true);
            saveScreeningResult(data, token).then((response) => {
                setModalLoading(false);
                console.log(response);
                if (response.success) {
                    // closeModal(true);
                    if (response.screeningResult) {

                    //} else {
                        start_time = moment();
                        setActiveQuestion(1);
                    }
                } else {
                    showErrorToast(response.message);
                }
            });

        }else{

            start_time = moment();
            setActiveQuestion(1);
        }

    }

    const saveAnswer = () => {


        if(location === 't') {
            const token = ReactSession.get("token");
            const data = new FormData();
            data.append("test_id", test.id);
            data.append("guest_token", ReactSession.get("guest_token"));
            data.append("question_id", formValues.question_id);
            data.append("option_id", formValues.answer);
            data.append("time_spent", moment().diff(start_time, "seconds"));

            //return;
            setModalLoading(true);

            saveScreeningResult(data, token).then((response) => {
                setModalLoading(false);
                
                if (response.success) {
                    start_time = moment();
                    
                    if (isActiveQuestion < totalQuestion) {
                        if(response.good_fit){
                            setActiveQuestion(isActiveQuestion + 1);
                        } else {
                            setActiveQuestion(isActiveQuestion + 1);
                            setScreeningFailed(true);
                            start_time = moment();    
                        }
                    } else {
                        if(response.good_fit){
                            navigate("/t/" + encryptId(test.id, test));
                            showSuccessToast(test.language == "ar" ? "لقد تجاوزت الأسئلة بنجاح ويبدو أنك مؤهل بشكل جيد لهذا الاختبار، الآن يمكنك المتابعة." : "You are a good fit for this test and can continue to the test.");
                        } else {
                            setActiveQuestion(isActiveQuestion + 1);
                            setScreeningFailed(true);
                            start_time = moment();    
                        }
                    }

                    
                } else {
                    showErrorToast(response.message);
                }
            });
            /*if (goodfit.current == true) {
                if (isActiveQuestion < totalQuestion) {
                    data.append("jump_to_question", isActiveQuestion + 1);
                    saveScreeningResult(data, token).then((response) => {
                        setModalLoading(false);
                        console.log(response);
                        if (response.success) {
                            start_time = moment();
                            setActiveQuestion(isActiveQuestion + 1);
                        } else {
                            showErrorToast(response.message);
                        }
                    });
                    setFormValues({
                        ...formValues,
                        answer: '',
                    });
                } else {
                    data.append("screening_passed", true);
                    saveScreeningResult(data, token).then((response) => {
                        setModalLoading(false);
                        console.log(response);
                        if (response.success) {
                            navigate("/t/" + encryptId(test.id, test));
                            showSuccessToast(test.language == "ar" ? "لقد تجاوزت الأسئلة بنجاح ويبدو أنك مؤهل بشكل جيد لهذا الاختبار، الآن يمكنك المتابعة." : "You are a good fit for this test and can continue to the test.");
                        } else {
                            showErrorToast(response.message);
                        }
                    });
                }

            } else {
                data.append("screening_failed", true);
                saveScreeningResult(data, token).then((response) => {
                    setModalLoading(false);
                    console.log(response);
                    if (response.success) {
                        setActiveQuestion(isActiveQuestion + 1);
                        setScreeningFailed(true);
                        start_time = moment();
                    } else {
                        showErrorToast(response.message);
                    }
                });
            }*/
        }else{

            const token = ReactSession.get("token");
            const data = new FormData();
            data.append("test_id", test.id);
            data.append("question_id", formValues.question_id);
            data.append("option_id", formValues.answer);
            
            //return;
            setModalLoading(true);

            checkScreeningResult(data, token).then((response) => {
                
                setModalLoading(false);
                
                if (response.success) {
                    start_time = moment();
                    
                    if (isActiveQuestion < totalQuestion) {
                        if(response.good_fit){
                            setActiveQuestion(isActiveQuestion + 1);
                        } else {
                            setActiveQuestion(isActiveQuestion + 1);
                            setScreeningFailed(true);
                            start_time = moment();    
                        }
                    } else {
                        if(response.good_fit){
                            navigate("/p/" + encryptId(test.id, test));
                            showSuccessToast(test.language == "ar" ? "لقد تجاوزت الأسئلة بنجاح ويبدو أنك مؤهل بشكل جيد لهذا الاختبار، الآن يمكنك المتابعة." : "You are a good fit for this test and can continue to the test.");
                        } else {
                            setActiveQuestion(isActiveQuestion + 1);
                            setScreeningFailed(true);
                            start_time = moment();    
                        }
                    }

                    
                } else {
                    showErrorToast(response.message);
                }
            });

           /* if (isActiveQuestion < totalQuestion) {

                if(goodfit.current == true){

                }else{
                    setCheckGoodFit(true);
                }

                start_time = moment();
                setActiveQuestion(isActiveQuestion + 1);

            }else{

                // console.log(checkGoodFit);
                if(goodfit.current == true && !checkGoodFit){

                    // console.log('no 12')
                    navigate("/p/" + encryptId(test.id, test));
                }else{

                    setScreeningFailed(true);
                    // if(ReactSession.get('preview_test_link')){
                    //     navigate(`/r/test/${test.id}/${ReactSession.get('preview_test_link')}`);
                    //     ReactSession.set('preview_test_link','');
                    // }else {
                    //     navigate(`/r/test/${test.id}/recruit`);
                    // }
                    // console.log('yes 12')
                }


                // showSuccessToast(test.language == "ar" ? "لقد تجاوزت الأسئلة بنجاح ويبدو أنك مؤهل بشكل جيد لهذا الاختبار، الآن يمكنك المتابعة." : "You are a good fit for this test and can continue to the test.");
            }*/
        }
    }


    useEffect(() => {

        checkScreeningResults(test);

    }, [test]);



    return (
        <>
            {isLoading?

                <>
                    <div className="full-page-loader taking-test-full-page-loader">
                        <LoadingIcon />
                    </div>
                </>
                :
                <>
                    <GuestHeader />

                    <div className="login-data-wrap screening-question-innerwrap">

                        <div className="modal-dialog modal-dialog-centered screening-questions-modal-wrap">
                            <div className="modal-content">
                                <div className={`modal-body ${(test && test.language == "ar") ? "arabic_wrapper" : ""}`}>

                                    {modalLoading ?
                                        <div className="modal-loader text-center">
                                            <LoadingIcon />
                                        </div>
                                        :
                                        <div className="modal-inner-text">
                                            <div className="modal-header-top">
                                                {test.language=="ar"?
                                                    <Text type={'h3'}>{screeningFailed?"آسف، لا يمكنك المشاركة في هذا الاختبار.":"أسئلة ما قبل الاختبار"}</Text>:
                                                    <Text type={'h3'}>{screeningFailed?"Sorry, you cannot participate in this test":"Pre-test questions"}</Text>
                                                }
                                            </div>
                                            {screeningFailed?
                                                <div className="screening-modal-body screening-failed-modal">
                                                    <Text type="body-text-2" fontWeight="medium-font" cssClasses="grey-text">{test.language=="ar"?"شكرًا على إجاباتك. للأسف، انت لا تستوفي المعايير المحددة لهذا لاختبار.":"Thank you for your answers. Unfortunately, you do not meet the criteria for this particular test this time."}</Text>
                                                    <Text type="body-text-2" fontWeight="medium-font" cssClasses="grey-text">
                                                        {isTester ?
                                                            <>
                                                                {test.language=="ar"?"يرجى متابعة بريدك الإلكتروني لتلقي دعوات من UserQ للمشاركة في اختبارات أخرى.":"Keep an eye out for UserQ emails inviting you to participate in other tests."}
                                                            </>
                                                            :
                                                            <>
                                                                {location === 'p'
                                                                    ?
                                                                    <>
                                                                        {test.language=="ar"?"يرجى متابعة بريدك الإلكتروني لتلقي دعوات من UserQ للمشاركة في اختبارات أخرى.":"Keep an eye out for UserQ emails inviting you to participate in other tests."}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {test.language=="ar"?"هل ترغب في الحصول على أجر في المرة القادمة؟ أنشئ حساب مع USERQ للمشاركة في المزيد من هذه الاختبارات و احصل على أجر مقابل رأيك. ":"Want to get paid next time? Register with UserQ to participate in more tests like this and get paid for your insights."}
                                                                    </>
                                                                }

                                                            </>
                                                        }

                                                    </Text>

                                                    {location === 'p' ?

                                                        <Button type="primary" size="large" label={test.language=="ar"?"إغلاق":'Close'} onClick={()=>{
                                                            if(ReactSession.get('preview_test_link')){
                                                                navigate(`/r/test/${test.id}/${ReactSession.get('preview_test_link')}`);
                                                                ReactSession.set('preview_test_link','');
                                                            }else {
                                                                navigate(`/r/results/${test.id}/overview`);
                                                            }
                                                        }}  />
                                                        :
                                                        <Button type="primary" size="large" label={isTester ?<>{test.language=="ar"?"إغلاق":'Close'}</>:<>{test.language=="ar"?"أنشئ حساب":'Create an account'}</>} onClick={()=>closeModal(true)}  />
                                                    }
                                                </div>:
                                                <div className="screening-modal-body">
                                                    <Tag
                                                        title={test.test_name}
                                                        type="gold-tag"
                                                        cssClasses={(test.language === 'ar') ? 'arabic-font' : ''}
                                                    />
                                                    {isActiveQuestion ?
                                                        <div className="screening-questions-body">
                                                            {QuestionArrayList && QuestionArrayList.map((items, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {(isActiveQuestion === index + 1) &&
                                                                        <div key={index}>
                                                                            <div className="single-choice-take-test medium-box-wrapper mb-32">

                                                                                <div className="take-test-heading-wrap">
                                                                                    <Text type="subtitle-2" fontWeight="medium-font">
                                                                                        {items.questionData.question}
                                                                                    </Text>
                                                                                </div>

                                                                                <div className="select-option-text-guide mt-12">
                                                                                    <Text type="body-text-3" fontWeight="medium-font">{test.language == "en" ? "Select 1 of the following" : "حدد خيار واحد"}</Text>
                                                                                </div>
                                                                                <div className="test-form-hold mt-32">
                                                                                    <div className={`${test.language == "en" ? "singlechoice-option-wrap" : "singlechoice-option-wrap singlechoice-option-arabic-wrap"}`}>
                                                                                        {items.data.options &&
                                                                                        items.data.options.map(
                                                                                            (optionData) => {
                                                                                                return (
                                                                                                    <div key={optionData.id}>
                                                                                                        <TesterQuestionOptions
                                                                                                            id={`option-select-${optionData.id}`}
                                                                                                            type="singlechoice"
                                                                                                            value={optionData.id}
                                                                                                            label={optionData.option_value} isChecked={
                                                                                                            formValues.answer ===
                                                                                                            optionData.id
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                            onChange={(e) => {
                                                                                                                // isActiveQuestionId.current = items.data.id;
                                                                                                                //console.log(optionData);
                                                                                                                nextButtonActive.current = true;

                                                                                                                setFormValues({
                                                                                                                    ...formValues,
                                                                                                                    question_id: items.data.id,
                                                                                                                    answer: parseInt(
                                                                                                                        e.target.value
                                                                                                                    ),
                                                                                                                });
                                                                                                                setFormErrors(
                                                                                                                    { error: "", error_class: "" }
                                                                                                                );

                                                                                                                if (optionData.screening == "Good fit (continue)") {
                                                                                                                    goodfit.current = true;
                                                                                                                } else {
                                                                                                                    goodfit.current = false;
                                                                                                                }

                                                                                                                // const item = document.getElementById("buttons");
                                                                                                                // item.scrollIntoView({
                                                                                                                //     behavior: "smooth",
                                                                                                                //     block: "center",
                                                                                                                // });
                                                                                                            }} />


                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        }

                                                                    </div>
                                                                );
                                                            })}
                                                            {test.language=="ar"?
                                                                (formValues.answer != '' ?
                                                                        <Button type="primary" size="large" label={"التالي"} onClick={saveAnswer} iconRight={<Icon colorClass="gray-50-svg" value={'back-arrow'} />} />
                                                                        :
                                                                        <Button type="primary" size="large" label={"التالي"} state="disabled" onClick={() => { }} iconRight={<Icon colorClass="gray-50-svg" value={'back-arrow'} />} />
                                                                ):
                                                                (formValues.answer != '' ?
                                                                        <Button type="primary" size="large" label={'Next'} onClick={saveAnswer} iconRight={<Icon colorClass="gray-50-svg" value={'forward-arrow'} />} />
                                                                        :
                                                                        <Button type="primary" size="large" label={'Next'} state="disabled" onClick={() => { showErrorToast('You need to select one answer before moving to the next question.') }} iconRight={<Icon colorClass="gray-50-svg" value={'forward-arrow'} />} />
                                                                )}
                                                        </div>
                                                        :
                                                        <div className="screening-checkbox-body">

                                                            <Text type="body-text-2" fontWeight="medium-font" cssClasses="grey-text">
                                                                {isTester ?
                                                                    <>
                                                                        {test.language=="ar"?
                                                                            "قبل الاستمرار في الاختبار، هناك بعض الأسئلة التي نحتاج إلى طرحها لمعرفة ما إذا كنت مؤهلاً للمتابعة. سيتمكن فقط المشاركون الذين يستوفون المعايير من متابعة الاختبار واستلام المدفوعات مقابل إجاباتهم.":
                                                                            "Before continuing with the test, there are a few questions we need to ask to see if you are eligible. Only participants who fit the criteria will be able to continue with the test and receive payment for their answers."}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {test.language=="ar"?
                                                                            "قبل الاستمرار في الاختبار، هناك بعض الأسئلة التي نحتاج إلى طرحها لمعرفة ما إذا كنت مؤهلاً للمتابعة. سيتمكن فقط المشاركون الذين يستوفون المعايير من متابعة الاختبار.":
                                                                            "Before continuing with the test, there are a few questions we need to ask to see if you are eligible. Only participants who fit the criteria will be able to continue with the test."}
                                                                    </>

                                                                }
                                                            </Text>
                                                            <CheckBox
                                                                label={test.language=="ar"?"اتفهم ذلك":"I understand"}
                                                                checked={checked}
                                                                onChange={(event) => { setChecked(event.currentTarget.checked) }}

                                                            />
                                                            {test.language=="ar"?
                                                                <div className="confirm-buttons-wrap">
                                                                    {checked ?
                                                                        <Button type="primary" size="large" label={'متابعة'} onClick={saveScreeningResults} iconRight={<Icon colorClass="gray-50-svg" value={'back-arrow'} />} />
                                                                        :
                                                                        <Button type="primary" size="large" label={'متابعة'} state="disabled" onClick={() => { showErrorToast('.يرجى قراءة المعلومات وتحديد خانة "اتفهم ذلك" للمتابعة') }} iconRight={<Icon colorClass="gray-50-svg" value={'back-arrow'} />} />
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="confirm-buttons-wrap">
                                                                    {checked ?
                                                                        <Button type="primary" size="large" label={'Continue'} onClick={saveScreeningResults} iconRight={<Icon size={"medium"} colorClass={"gray-50-svg"} value={'forward-arrow'} />} />
                                                                        :
                                                                        <Button type="primary" size="large" label={'Continue'} state="disabled" onClick={() => { showErrorToast('Please read the note and check the “I understand” box to continue') }} iconRight={<Icon colorClass="gray-50-svg" value={'forward-arrow'} />} />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>


                    <Footer />
                </>
            }


        </>
    );
}