import { Icon } from "../Icon/Icon";
import Flags from "country-flag-icons/react/3x2";

export const CountryChips = ({ title, value, type, onRemove, countrydetails }) => {

    let Component = "ru";

    if(typeof(countrydetails)!="undefined"){
        
        Component = countrydetails.length > 0 ? Flags[countrydetails[0]["code"]] : ""
    }


    return (
        <>
        
        <div className={`chip chip-${type ? type : "default"} country-chips`} >
            {type && type == "icon-left" &&
                <span className="chip-option-icon-left" onClick={onRemove}>
                    <Icon value={"close"} colorClass={'gray-50-svg'} size={"small"} />
                </span>
            }
            {type && type == "selected" &&
                <span className="chip-option-icon-left" onClick={onRemove}>
                    <Icon value={"check-mark"} size={"small"} />
                </span>
            }
            <span className="chips-flag">
            {Component=="" ? <></> : ( typeof Component== undefined ? countrydetails[0]["code"] : <Component
                title={countrydetails.length > 0 ? countrydetails[0]["code"] : ""}
                style={{ display: "inline-block", height: "1em", width: "1em" }}
            />)}
            </span>
            <span className="chip-name" >{title}</span>
            {type && type == "icon-right" &&
                <span className="chip-option-icon-right" onClick={onRemove}>
                    <Icon value={"close"} colorClass={'gray-50-svg'} size={"small"} />
                </span>
            }
        </div>
        </>
    );
};