import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from '../../lib/secure_reactsession';
import { useSearchParams, useNavigate } from "react-router-dom";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box"
import { Text } from '../themes/userq/Text/Text';
import { Input } from '../themes/userq/Input/Input';
import { DropdownComponent } from '../themes/userq/Dropdown/Dropdown';
import { CheckBox } from '../themes/userq/CheckBox/CheckBox';
import { Button } from '../themes/userq/Button/Button';
import { Icon } from '../themes/userq/Icon/Icon';
import { PhoneNumberInput } from '../themes/userq/PhoneNumberInput/PhoneNumberInput';
import { getProfile, completeResearcherProfile } from "../../services/user.js";
import { isValidPhoneNumber } from 'react-phone-number-input'
import { LoadingIcon } from '../themes/userq/Loader/loadingIcon';
import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../data/settings';
import { ResearcherSlider } from './researcher_slider.js';
import { getWorkspaces } from '../../services/workspaces.js';

let captcha = null;




export default function CompleteResearcherProfile() {

    ReactSession.setStoreType("localStorage");

    const [startPosition, setStartPosition] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    let jobRolesSelect = useRef();

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];

    const teamSizeOptions = [{ "label": "1", "value": "1" }, { "label": "2-5", "value": "2-5" }, { "label": "6-10", "value": "6-10" }, { "label": "11-20", "value": "11-20" }, { "label": "21-50", "value": "21-50" }, { "label": "51-100", "value": "51-100" }, { "label": "100+", "value": "100+" }];

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({ captcha: '', phone_number: "", whatsapp_phone_number: "", whatsapp_checkbox: true, first_name: '', last_name: '', company_name: '', job_role: '', job_role_other: '', team_size: '', newsletter: true, role: 'Tester' });

    const [formErrors, setFormErrors] = useState({ phone_number: null, whatsapp_phone_number: null, first_name: null, last_name: null, password: null, company_name: null, job_role: null, job_role_other: null, job_role_other: null, team_size: null, terms: null });


    useEffect(() => {
        window.animate();
        document.title = "Complete Profile | "+process.env.REACT_APP_NAME;

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response => {
            setLoading(false);

            if (response.success) {

                if (response.data.user.completed_step >= 1) {

                    var user = ReactSession.get("user");

                    //user["completed_step"] = response.data.user.completed_step;

                    user["welcome_message_shown"] = response.data.user.welcome_message_shown;

                    ReactSession.set("user", user);
                    // navigate('/dashboard');

                    // if(!response.data.user.signup_plan){
                    //     getWorkspaces(user.id, token).then(resp => {
                    //         if (response.success){
                    //             if(resp.data.workspace.length){
                    //                 navigate(`/wsp/${resp.data.workspace[0].id}`);
                    //             }
                    //         }
                    //     });
                    // } else if(response.data.workspace_id) {
                    //     navigate(`/wsp/${response.data.workspace_id}`);
                    // }
                }
                setFormValues({
                    ...formValues,
                    first_name: response.data.user.first_name ? response.data.user.first_name : '',
                    last_name: response.data.user.last_name ? response.data.user.last_name : ''
                })

            }
        });


    }, []);
    const showError = (error) => {

        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {

        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const completeProfile = () => {

        setFormLoading(true);


        let body = formValues;


        if (body["job_role"] == "Other...") {
            body["job_role"] = body["job_role_other"];
        }
        let token = ReactSession.get("token");

        completeResearcherProfile(body, token).then(response => {
            setFormLoading(false);

            if (response.success) {

                console.log("resess", response);

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'sign_up',
                    'usertype': 'researcher',
                    'method': response.user.signup_type,
                    'userID': response.user.user_id
                });

                var user = ReactSession.get("user");

                user["completed_step"] = response.user.completed_step;

                user["default_workspace_id"] = response.user.default_workspace_id;

                user["welcome_message_shown"] = response.user.welcome_message_shown;

                user["first_name"] = response.user.first_name;

                user["last_name"] = response.user.last_name;

                user["signup_plan"] = response.user.signup_plan;

                ReactSession.set("user", user);


                
                if(response.user.signup_plan === "Team" && response.user.completed_step >=1){
                    console.log("navigation");
                    navigate('/r/create-team-workspace');
                } else if(response.workspace_id) {
                    navigate(`/wsp/${response.workspace_id}`);
                } else {
                    navigate(`/dashboard`)
                }
               

            } else {
                showError(response.message);
            }
        });

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value, inline) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([], formErrors);

        if (key) {
            form_errors[key] = null;
        }

        if (focus) {

            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        if (!key || key == "first_name") {

            var field_value = key && inline ? value : formValues.first_name;

            if (field_value == '') {

                form_errors = {
                    ...form_errors,
                    first_name: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "first-name" : firsterrorid;
            }
        }
        if (!key || key == "last_name") {

            var field_value = key && inline ? value : formValues.last_name;

            if (field_value == '') {

                form_errors = {
                    ...form_errors,
                    last_name: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "last-name" : firsterrorid;
            }
        }
        if (!key || key == "phone_number") {

            var field_value = key && inline ? value : formValues.phone_number;

            if (!field_value || !isValidPhoneNumber('+'+field_value.toString())) {

                form_errors = {
                    ...form_errors,
                    phone_number: "Phone number is not valid",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "phone-number" : firsterrorid;
            }
        }
        if (!formValues.whatsapp_checkbox  && formValues.whatsapp_phone_number) {
            if (!key || key == "whatsapp_phone_number") {

                var field_value = key && inline ? value : formValues.whatsapp_phone_number;

                if (!field_value || !isValidPhoneNumber('+'+field_value.toString())) {

                    form_errors = {
                        ...form_errors,
                        whatsapp_phone_number: "WhatsApp number is not valid",
                        error_class: "input_error"
                    };
                    error = true;
                    firsterrorid = firsterrorid == null ? "whatsapp-phone-number" : firsterrorid;
                }
            }
        }
        if (!key || key == "company_name") {

            var field_value = key && inline ? value : formValues.company_name;

            if (field_value == '') {
                form_errors = {
                    ...form_errors,
                    company_name: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "company_name" : firsterrorid;
            }
        }
        if (!key || key == "job_role") {

            var field_value = key ? value : formValues.job_role;


            if (field_value == '') {

                form_errors = {
                    ...form_errors,
                    job_role: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "job_role" : firsterrorid;
            }
        }
        if (!key || key == "job_role_other") {

            var field_value = key && inline ? value : formValues.job_role_other;

            if (formValues.job_role == 'Other...' && field_value == '') {

                form_errors = {
                    ...form_errors,
                    job_role_other: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "job_role_other" : firsterrorid;
            }
        }

        if ((inline && formErrors[key]) || !inline) {

            setFormErrors(form_errors);
        }

        if (focus) {
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const checkBtnState = () => {

        var error = false;


        if (formValues.first_name == '') {

            error = true;
        }
        if (formValues.last_name == '') {

            error = true;
        }
        if (formValues.company_name == '') {
            error = true;
        }
        if (formValues.job_role == '') {

            error = true;

        }
        if (formValues.job_role == 'Other...' && formValues.job_role_other == '') {

            error = true;

        }
        

        if (!formValues.phone_number || !isValidPhoneNumber('+'+formValues.phone_number.toString())) {


            error = true;

        }

        if (formValues.team_size == '') {

            error = true;

        }

        if (!formValues.whatsapp_checkbox  && formValues.whatsapp_phone_number) {

            if (!formValues.whatsapp_phone_number || !isValidPhoneNumber('+'+formValues.whatsapp_phone_number.toString())) {

                error = true;
            }

        }
        return error;
    }
    const onSubmit = async (event) => {

        event.preventDefault();

        if (!isLoading) {

            var error = validateFullForm(true, null);

            if (!error) {
                completeProfile();
            }
        }

        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z\s]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues(oldValues => ({ ...oldValues, [name]: value }));
                }
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value : '', true)
        }
    };
    const clearFormValue = (name) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
    };

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };

    return (
        <LayoutResearcher
            skipProfileComplete={true}
            profileHeader={true}
            profileResearcher={true}
        >
            <div className='container'>
                {isLoading &&
                    <div class="accountloading-box"> <LoadingIcon /></div>
                }
                {!isLoading &&
                    <form method="POST" onSubmit={onSubmit}>
                        <div className='register-page-min-hegiht'>
                            <div className='row'>
                                <div className='col-md-6 graytext researcher-profilebox-right-outerbox'>
                                    <Text type={"h3"} fontWeight={"semi-bold-font"}>Tell us about yourself</Text>
                                    <Text type={"body-text-2"} fontWeight={""}>We need to know some information about yourself</Text>


                                    <div className="login-form-inner-data researcher-profilebox mt-40">


                                        <div className="formbox">
                                            <Input
                                                type="text"
                                                id="first-name"
                                                name="first_name"
                                                value={formValues.first_name}
                                                onChange={set("first_name")}
                                                label={"First name"}
                                                onClear={() => { clearFormValue("first_name") }}
                                                error={formErrors.first_name}
                                                error_class={formErrors.error_class}
                                                required={true}
                                                onBlur={() => {
                                                    validateFullForm(false, "first_name")
                                                }}
                                                maxLength={"16"}
                                                hideMaxChars={true}
                                            />
                                        </div>
                                        <div className="formbox">
                                            <Input
                                                type="text"
                                                id="last-name"
                                                name="last_name"
                                                value={formValues.last_name}
                                                onChange={set("last_name")}
                                                label={"Last name"}
                                                onClear={() => { clearFormValue("last_name") }}
                                                error={formErrors.last_name}
                                                error_class={formErrors.error_class}
                                                required={true}
                                                onBlur={() => {
                                                    validateFullForm(false, "last_name")
                                                }}
                                                maxLength="45"
                                                hideMaxChars={true}
                                            />
                                        </div>
                                        <div className="formbox " id='phone-number' style={{ position: "relative" }}>
                                            <PhoneNumberInput
                                                value={formValues.phone_number}
                                                onBlur={() => {
                                                    validateFullForm(false, "phone_number")
                                                }}
                                                onCountryChange={() => {
                                                    setFormErrors({
                                                        ...formErrors,
                                                        phone_number: null,
                                                        error_class: "input_error"
                                                    });
                                                }}
                                                onChange={(value) => {
                                                    setFormErrors({ ...formErrors, phone_number: null })
                                                    setFormValues(oldValues => ({ ...oldValues, phone_number: value }));

                                                    validateFullForm(false, "phone_number", value ? value : '', true);
                                                }}
                                                error={formErrors.phone_number}
                                                error_class={formErrors.error_class}

                                            />
                                            <div className="whatsupplabel">
                                                <CheckBox
                                                    checked={formValues.whatsapp_checkbox}
                                                    onChange={(event) => { toggleCheck('whatsapp_checkbox', event.currentTarget.checked) }}
                                                    id="whatsapp_number"
                                                    label={"I use WhatsApp on this number"}
                                                />
                                            </div>
                                        </div>
                                        {!formValues.whatsapp_checkbox &&
                                            <div className="formbox " id="whatsapp-phone-number" style={{ position: "relative" }}>
                                                <PhoneNumberInput
                                                    label={"WhatsApp number"}
                                                    value={formValues.whatsapp_phone_number}
                                                    onBlur={() => {
                                                        validateFullForm(false, "whatsapp_phone_number")
                                                    }}
                                                    onCountryChange={() => {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            whatsapp_phone_number: null,
                                                            error_class: "input_error"
                                                        });
                                                    }}
                                                    onChange={(value) => {
                                                        setFormErrors({ ...formErrors, whatsapp_phone_number: null })
                                                        setFormValues(oldValues => ({ ...oldValues, whatsapp_phone_number: value }));

                                                        validateFullForm(false, "whatsapp_phone_number", value ? value : '', true);

                                                    }}
                                                    error={formErrors.whatsapp_phone_number}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>
                                        }
                                        <div className="formbox ">
                                            <Input
                                                type="text"
                                                id="company_name"
                                                name="company_name"
                                                value={formValues.company_name}
                                                onChange={set("company_name")}
                                                label={"Company name"}
                                                onClear={() => { clearFormValue("company_name") }}
                                                error={formErrors.company_name}
                                                error_class={formErrors.error_class}
                                                required={true}
                                                onBlur={() => {
                                                    validateFullForm(false, "company_name")
                                                }}
                                            />
                                        </div>
                                        <div className="formbox">
                                            <div className='profile-form-group'>
                                                <DropdownComponent
                                                    options={jobRoleOptions}
                                                    value={formValues.job_role}
                                                    onChange={(item) => {


                                                        setFormValues({ ...formValues, job_role: item ? item.value : '' })

                                                        validateFullForm(false, "job_role", item ? item.value : '');

                                                    }}
                                                    isClearable={true}
                                                    label="Job role"
                                                    error={formErrors.job_role}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>

                                        </div>
                                        {formValues.job_role == "Other..." && (
                                            <div className="formbox">
                                                <Input
                                                    type="text"
                                                    id="job-role-other"
                                                    name="job_role_other"
                                                    value={formValues.job_role_other}
                                                    onChange={set("job_role_other")}
                                                    label={"Other job role"}
                                                    maxLength="45"
                                                    error={formErrors.job_role_other}
                                                    error_class={formErrors.error_class}
                                                    onClear={() => { clearFormValue("job_role_other") }}
                                                    required={true}
                                                    onBlur={() => {
                                                        validateFullForm(false, "job_role_other")
                                                    }}
                                                />
                                            </div>
                                        )}

<div className="formbox">
                                            <div className='profile-form-group'>
                                                <DropdownComponent
                                                    options={teamSizeOptions} 
                                                    value={formValues.team_size}
                                                    onChange={(item) => {


                                                        setFormValues({ ...formValues, team_size: item ? item.value : '' })

                                                        validateFullForm(false, "team_size", item ? item.value : '');

                                                    }}
                                                    isClearable={true}
                                                    label="Research & design team size"
                                                    error={formErrors.team_size}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>

                                        </div>
                                        

                                        <div className="formbox">
                                            <div className='profile-form-group'>
                                                <CheckBox
                                                    checked={formValues.newsletter}
                                                    onChange={(event) => { toggleCheck('newsletter', event.currentTarget.checked) }}
                                                    id="newsletterCheckBox"
                                                    label={"I'd like to receive updates about UserQ"}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className='sign-up-btn-wrap sign-up-single-btn'>

                                        <span className='empty-span'>&nbsp;</span>
                                        <Button
                                            type='primary'
                                            size='large'
                                            id='r_registration'
                                            label="Next"
                                            iconRight={
                                                <Icon
                                                    value="forward-arrow"
                                                    size="large"
                                                    colorClass={'gray-50-svg'}
                                                />
                                            }
                                            microLoading={formLoading}
                                            state={checkBtnState() ? "disabled" : "active"}
                                            cssclass="w-100 tester-signin-step1"
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6 graytext  hide-for-tablet'>
                                    <Text type={"h3"} fontWeight={"semi-bold-font"}>Some useful tips</Text>
                                    <Text type={"body-text-2"} fontWeight={""}>To help you get started with your researcher account</Text>
                                    <div className="reasearcher-left-carousel mt-40">
                                        <ResearcherSlider />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>
                }
            </div>
        </LayoutResearcher>
    );
}