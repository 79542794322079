import { Link, useNavigate,useLocation } from "react-router-dom";
import { ReactSession } from "../../lib/secure_reactsession";
import React, {useEffect, useRef, useState} from "react";
import {LayoutResearcher} from "../themes/userq/Layouts/layout_researcher";
import { closeFeedback, getTestDashboard,getLoadMoreTestDashboard,getTestCountDashboard } from "../../services/test.js";
import { testDefaultCardsData } from "../../data/test-default-card";
import { Defaultcard } from "../themes/userq/Test/DefaultCard";
import { CardTemplate } from "../themes/userq/Test/Card";
import { Button } from "../themes/userq/Button/Button";
import { Icon } from "../themes/userq/Icon/Icon";
import { Text } from "../themes/userq/Text/Text";
import moment from "moment";
import { LoadingIcon }  from "../themes/userq/Loader/loadingIcon";
import { Dropdown } from "react-bootstrap";
import ConfirmationModal from "../themes/userq/Modal/ConfirmationModal";
import {ToastMessage} from "../themes/userq/ToastMessage/ToastMessage";
import {encryptId, roundNumber} from "../../lib/helpers";
import TestFrame from "../researcher/projects/project frames/test_frame";
import { updateWelcomeMessage, updateNewFeatureService } from "../../services/user.js";
import toast from "react-hot-toast";
import CopyLinkModal from "../dialog/copy_link_modal";
import TestSummaryModal from "../dialog/test_summary_modal";
import {changeTestStatus, deleteTest, duplicateTest} from "../../services/test";
import TestSettingsModal from "./modals/test_settings_modal";
import ChooseMethodologyModal from "./modals/choose_methodologies_model";
import Skeleton from "react-loading-skeleton";
import { settings } from "../../data/settings";
import {
    atom,
    useRecoilState,
    useResetRecoilState
} from 'recoil';
import WelcomeMessageModal from "../themes/userq/WelcomeMessageModal/welcome_message_modal";
import NewFeatureModal from "../themes/userq/Modal/NewFeatureModal";
import { Tag } from "../themes/userq/Tag/Tag";
import HelpModal from "../themes/userq/Modal/HelpModal";
import { Box } from "../themes/userq/Box/Box";
import { RadioButton } from "../themes/userq/RadioButton/RadioButton";
import NpsModal from "../themes/userq/NPS/npsModal";
import {Search} from "../themes/userq/Search/Search";
import {Link as UQLink} from "../themes/userq/Link/Link";

export default function ResearcherDashboard() {
    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();
    const location = useLocation();

    const dataFetchedRef = useRef(false);
    const dataFetchedSearchRef = useRef(false);

    const searchRef = useRef(null);

    const [isPageRefresh, setPageRefresh] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isLoadMoreTestLoading, setLoadMoreTestLoading] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [confirmationBtnLoading, setConfirmationBtnLoading] = useState(false);
    const [duplicateBtnLoading, setDuplicateBtnLoading] = useState(false);
    const [isTestAvailable, setIsTestAvailable] = useState(true);
    const [isApplyFilter, setIsApplyFilter] = useState(false);
    const [toggleMethodology, setToggleMethodology] = useState(false);
    const [tests, setTests] = useState([]);
    const [openNewTestModal, setOpenNewTestModal] = useState(false);
    const [openChooseMethodologiesModal, setOpenChooseMethodologiesModal] = useState(false);
    const [openNewFeatureModal, setOpenNewFeatureModal] = useState({open:false});
    const [modalMethodology, setModalMethodology] = useState("");
    const [totalTests, setTotalTests] = useState(1);
    const [isTestCount, setTestCount] = useState(0);
    const [filterTestCount, setFilterTestCount] = useState(0);
    const [totalTestsCount, setTotalTestsCount] = useState({
        'Card_Sorting_count':1,
        'Preference_Test_count':1,
        'Tree_Test_test':1,
        'Survey_count':1,
        'Five_Seconds_Test_count':1,
        'First_Click_count':1,
        'prototype_test_count':1
    });
    const [statusTestsCount, setStatusTestsCount] = useState({
        'all':0,
        'draft':0,
        'published':0,
        'completed':0
    });

    const creditsState = atom({
        key: 'credits',
        default: 0
    });

    const [search, setSearch] = useState("");
    const [isCheckSearch, setCheckSearch] = useState(false);
    // const [isCountChangeLoadingState, setCheckSearch] = useState(true);

    const reset = useResetRecoilState(creditsState);
    //const [credits, setCredits] = useRecoilState(creditsState);


    //const resetCredits = useResetRecoilState(creditsState);

    const [welcomeMessageModal, setWelcomeMessageModal] = useState({
        open: false
    });

    const [enableSearch, setEnableSearch] = useState(false);
     const toggleSearch = () => {
        setEnableSearch(prevState => !prevState);
      };

    const [confirmModal, setConfirmModal] = useState({
        open: false
    });

    const [copyLinkModal, setCopyLinkModal] = useState({
        open: false
    });
    const [testSummaryModal, setTestSummaryModal] = useState({
        open: false
    });
    const [duplicateModal, setDuplicateModal] = useState({
        open: false
    });
    const [npsModal, setNpsModal] = useState({
        open: false
    });

    let methodologies= {"Prototype test":"Prototype test","Card Sorting":"Card sorting","Preference Test":"Preference test","Tree Test":"Tree test","Survey":"Survey", "Five Seconds Test":"5 second test", "First click":"First click"}


    const [formValues, setFormValues] = useState({
        status: "",
        methodology: "",
        order: "desc",
        loading:false
    });

    const [publishing_credits, setPublishingCredits] = useState(0);

    const [days_left, setDaysLeft] = useState(0);

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const deleteTestApi = (test_id) => {
        let token = ReactSession.get("token");
        setConfirmationBtnLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        deleteTest(data, token)
            .then((response) => {
                setConfirmationBtnLoading(false);
                setConfirmModal({ ...confirmModal, open: false });

                if (response.success) {
                    showSuccessToast(response.message);
                    // searchData("");
                    getTests(false);
                }else{
                    showErrorToast(response.message);
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const endTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setConfirmationBtnLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        data.append("status", "completed");
        changeTestStatus(data, token)
            .then((response) => {
                setConfirmationBtnLoading(false);
                setConfirmModal({ ...confirmModal, open: false });

                if (response.success) {

                    if(response.refundedCredits > 0){
                        showSuccessToast(`Your test has ended. ${response.refundedCredits} credits have been refunded to your wallet.`)
                        reset();
                    }else{
                        showSuccessToast('Your test has ended successfully.')
                    }


                    ReactSession.set("credits", response.credits_balance);
                    //setCredits(response.credits_balance);
                    //navigate('/r/credits');
                    // searchData("");
                    getTests(false);
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const duplicateTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setIsFilterLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);

        data.append("duplicate_preference",duplicateModal.duplicate_preference);

        duplicateTest(data, token)
            .then((response) => {
                setIsFilterLoading(false);
                setConfirmModal({ ...confirmModal, open: false });

                setDuplicateModal({...duplicateModal, open: false});

                if (response.success) {
                    // searchData("");
                    getTests(false);
                    document.getElementById("test-filters").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const getTests = (loading) => {
        let token = ReactSession.get("token");


        ReactSession.set("enableLoadingData","yes");


        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();

        }

        //abort search controller
        if (dataFetchedSearchRef.current) {
            dataFetchedSearchRef.current.abort();

        }

        const controller = new AbortController();

        dataFetchedRef.current = controller;

        // if(loading){
        //     setLoading(true);
        // }else {
            setIsFilterLoading(true);
        //}
        // setLoading(true);
        setLoadMoreTestLoading(false);




        getTestDashboard({...formValues,'search':search},token,dataFetchedRef.current?.signal).then((response) => {



            if (response.success) {
                setTests(response.tests);

                // check after filter data records exists
                if(response.tests.length === 0 && !loading){
                    setIsTestAvailable(false)
                }
                setPublishingCredits(response.publishing_credits);

                setDaysLeft(response.days_left);

                // set filter test count
                setFilterTestCount(response.tests.length);

                setStatusTestsCount(response.status_count);

                // check more record exists then load more data
                if(response.status_count.more_records){
                    loadMoreTestsData();
                }else{
                    ReactSession.set("enableLoadingData","no");
                }

                // set test count
                setTestCount(response.is_test_available);

                setNpsModal({...npsModal, open:response.is_nps==1?true:false});

                setCheckSearch(true);

                // set total test count calculateTotalCount(formValues.methodology,val);
                if(response.totalTestCount) {
                    // setTotalTests(response.totalTestCount + 1);
                    setTotalTestsCount(response.totalTestCount);
                }



                if(response.new_feature_available) {
                    // setTotalTests(response.totalTestCount + 1);
                    setOpenNewFeatureModal({...openNewFeatureModal, open:true});
                }

            } else {
                setTests([]);
                showErrorToast(response.message);
            }

           // if(loading){
               // setLoading(false);
                //setIsApplyFilter(true);
            //}else {
                setIsFilterLoading(false);
                setLoading(false)
                setPageRefresh(false)
           // }
        });
    };

    const loadMoreTestsData= ()=>{

        let token = ReactSession.get("token");

        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();

        }

        const controller = new AbortController();

        dataFetchedRef.current = controller;


        setLoadMoreTestLoading(true);
        // console.log(tests)
        // console.log(formValues)

        getLoadMoreTestDashboard(formValues,token,dataFetchedRef.current?.signal).then((response) => {

            setLoadMoreTestLoading(false);

            if (response.success) {

                 // setTests(response.tests);

                //var totalTest = tests.concat(response.tests);
                var totalTest = response.tests;

                setCheckSearch(true);

                renderTests(totalTest);
                 setTests(totalTest);

                ReactSession.set("enableLoadingData","no");

            } else {
                // setTests([]);
                showErrorToast(response.message);
            }
        });
    }

    const searchData = (val)=>{


        let users = ReactSession.get("user");
        if(val !== users.email){



            if(!isLoading && !isLoadMoreTestLoading){


                setSearch(val);

                 calculateTotalCount(formValues.methodology,val);
            }else {


                setSearch(val);
                getTests(false);
            }

        }


    }

    // useEffect(()=>{
    //
    //     if(!isLoading && !isLoadMoreTestLoading && search){
    //
    //         calculateTotalCount(formValues.methodology,search);
    //     }
    //
    // },[search,isLoading,isLoadMoreTestLoading]);
    const showErrorToast = (error) => {

        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccessToast = (message) => {

        toast(
            <ToastMessage type={"success"} message={message} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };


    useEffect(() => {
        document.title =  "Your dashboard | "+process.env.REACT_APP_NAME;

        let user = ReactSession.get('user');

        let toast_message = user.welcome_message_shown==1 ? false : true;
        
        if(toast_message){
            
            /*setWelcomeMessageModal({...welcomeMessageModal, open:true});

            let user = ReactSession.get("user");

            user.welcome_message_shown = 1;
            ReactSession.set("user", user);*/
            /*updateWelcomeMessage(ReactSession.get("token"))
            .then(response=>{
            });*/
            
        } 


        getTests(true);


    }, []);

    useEffect(()=>{

        // set time taken by test
        function tickTime() {

            if(isCheckSearch){

                setCheckSearch(false);

                if(!isLoading && !isLoadMoreTestLoading && search) {

                    calculateTotalCount(formValues.methodology, search);
                }
            }

        }
        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    },[search,tests,isCheckSearch,isLoading,isLoadMoreTestLoading])

    useEffect(() => {
        // show waring popup if user leave the meeting
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener("popstate", onBackButtonEvent);
        window.addEventListener("beforeunload", onBackButtonEvent);

        //logic for showing popup warning on page refresh
        // window.onbeforeunload = function () {
        //   //  setBackbuttonPress(true);
        //     return "Data will be lost if you leave the page, are you sure?";
        // };
        return () => {
            window.removeEventListener("popstate", onBackButtonEvent);
            window.removeEventListener("beforeunload", onBackButtonEvent);
        };
    }, ["location"]);



    const onBackButtonEvent = (e) => {
        e.preventDefault();

        if(ReactSession.get("dashboardFilter")){
            ReactSession.set("dashboardFilterActive", ReactSession.get("dashboardFilter"));
        }
        return false;

    };
    const updateNewFeature = ()=>{
        updateNewFeatureService(ReactSession.get("token"))
            .then(response=>{
            });
    }

    const renderTests = (testsData) => {
        var render = [];

        if(!isFilterLoading && !isLoading) {



            let filteredUsers = testsData.filter((data) => {

                if(formValues.status && formValues.methodology){

                    return data.status===formValues.status && data.methodology === formValues.methodology;
                }else if(formValues.status && !formValues.methodology){

                    return data.status===formValues.status;
                }else if(!formValues.status && formValues.methodology){

                    return data.methodology === formValues.methodology;
                }else{

                    return  data;
                }


            }).sort((a,b)=>{
                if(formValues.order === 'asc'){
                    return  (new Date(a.activity_date) - new Date(b.activity_date));
                }else{
                    return (new Date(b.activity_date) - new Date(a.activity_date));
                }
            });




                var checkSearchTestExistsCount = 0;
                filteredUsers.forEach(function (test) {

                    var rgxp = new RegExp(search.toLowerCase(), "g");
                    var searchTitle = test.test_name.toLowerCase();
                    if (searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {

                        var status = test.status;
                        if (status === 'published') {
                            var status = 'live';
                        }

                        var averageTimeSpendMinute = 0;
                        var averageTimeSpendSeconds = 0;
                        if (test.test_results_count !== 0) {
                            var averageTimeSpendMinute = Math.floor(test.total_time_spent / (test.test_results_count * 60));
                            var averageTimeSpendSeconds = ((test.total_time_spent / test.test_results_count) % 60).toFixed(0);
                        }


                        var repeat = (
                            <CardTemplate
                                key={test.id}
                                testName={test.test_name}
                                status={status}
                                methodology={test.methodology}
                                isDuplicate={test.is_duplicate}
                                isReportedTester={(test.reported_tester.status === 'to_review' ? true : false)}
                                countReportedTester={test.reported_tester.totalSelectedParticipant}
                                language={(test.language === 'en') ? 'English' : 'Arabic'}
                                createdOn={test.published_date}
                                timeLengthLabel={test.time_length_label}
                                endedOn={test.ended_on}
                                percentageComplete={Math.round((test.saved_step * 100) / 5)}
                                participants={test.test_results_count}
                                participantsTotal={(test.participants) ? test.participants : ''}
                                averageTimeSpendMinute={averageTimeSpendMinute + 'm'}
                                averageTimeSpendSeconds={averageTimeSpendSeconds + 's'}
                                navigateLink={(test.status === "draft") ? "/r/test/" + test.id + "/welcome" : "/r/results/" + test.id + "/overview"}
                                endingInDay={test.close_condition == "specific-date" ||
                                test.close_condition == "specific-date-after-no-participants"
                                    ? getDaysLeft(test, true)
                                    : "--"}
                                endingInText={test.close_condition == "specific-date" ||
                                test.close_condition == "specific-date-after-no-participants"
                                    ? getDaysLeft(test, false)
                                    : ""}
                                duplicate={() => {

                                    if (test && test.target_group && test.target_group.target_type != "test-links") {
                                        setDuplicateModal({
                                            ...duplicateModal,
                                            open: true,
                                            test_id: test.id,
                                            duplicate_preference: null
                                        });
                                    } else {
                                        duplicateTestApi(test.id);
                                    }

                                }}
                                deleteTest={() => {
                                    setConfirmModal({
                                        open: true,
                                        confirm_btn_title: "Delete",
                                        confirm_title: "Delete test",
                                        confirm_message: " Are you sure you want to delete this test?",
                                        test_id: test.id,
                                        confirmation_for: "testdelete",
                                        confirm_title_class: 'text-danger'
                                    });
                                }}
                                openCopyLink={() => {
                                    setCopyLinkModal({
                                        open: true,
                                        test_id: test.id,
                                        password: test.password,
                                        test: test,
                                        link: process.env.REACT_APP_URL + "t/" + encryptId(test.id, test),
                                        resultLink: process.env.REACT_APP_URL + "v/results/" + encryptId(test.id, test) + "/overview",
                                    });
                                }}
                                endTest={() => {
                                    setConfirmModal({
                                        open: true,
                                        confirm_btn_title: test.methodology == "Survey" ? "End Survey" : "End Test",
                                        confirm_title: test.methodology == "Survey" ? "End this survey?" : "End this test?",
                                        confirm_message:
                                            test.methodology == "Survey" ? " By ending this survey, you’ll stop collecting results. But don’t worry, any unspent credits will be refunded to your account." : " By ending this test, you’ll stop collecting results. But don’t worry, any unspent credits will be refunded to your account.",
                                        test_id: test.id,
                                        confirmation_for: "endtest",
                                        confirm_title_class: 'text-danger'
                                    });
                                }}
                                editTest={() => {
                                    if (test.status === "draft") {
                                        navigate(`/r/test/${test.id}/welcome`);
                                    } else {
                                        navigate(`/r/results/${test.id}/overview`);
                                    }
                                }}
                                testSummary={() => {
                                    setTestSummaryModal({
                                        open: true,
                                        test_id: test.id
                                    });
                                    // window.open(`/researcher/tests/${test.id}/review`, '_blank', 'noopener,noreferrer');
                                    //navigate();
                                }}
                            />
                        );

                        render.push(repeat);

                        checkSearchTestExistsCount++;

                    }

                });

            // check after search no test available
            if(search && checkSearchTestExistsCount === 0 && !isLoadMoreTestLoading){
                render.push(
                    <div className={`dashboard-search-no-data-found w-100`}>
                        <div className={`search-no-data-found`}>
                            <div className="search-no-data-inner">
                                <Icon value={"Search"} size={"extra-large"} />
                                <Text type="subtitle-2">
                                    {formValues.status?
                                        `No result found in ${formValues.status === 'published'?'live':formValues.status}!`
                                        :
                                        'No result found in all tests!'
                                    }

                                </Text>
                                <Text type="body-text-3">There is no result matching your search.</Text>

                                {formValues.status ?
                                    <Button
                                        size="small"
                                        iconLeft={<Icon size={'small'} value={'search'} colorClass={'gray-50-svg'}/>}
                                        label={"Search in all tests"}
                                        onClick={() => {
                                            setFormValueData('')
                                            //console.log('clear search')
                                        }}
                                    />
                                    :
                                    <Button
                                        size="small"
                                        label={"Clear search"}
                                        onClick={() => {
                                            searchData("");
                                    }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                );
            }

        }else{
            [1,2,3,4,5,6,7,8].forEach(function (index) {
                var repeat = (
                    <CardTemplate
                        isLoading={isFilterLoading}
                        key={index}
                    />
                );

                render.push(repeat);
            });
        }

        if(!isFilterLoading && !isLoading) {
            //filterTestCount
            return (
                <>
                {render}

                </>
            );
        }else {
            return render;
        }
    }

    const getDaysLeft = (test,endingInText) => {

        var daysdiff = moment.utc(test.closed_on).local().diff(moment(), "days");

        var hoursdiff = moment.utc(test.closed_on).local().diff(moment(), "hours");

        var minutesdiff = moment
            .utc(test.closed_on)
            .local()
            .diff(moment(), "minutes");

        if (daysdiff > 0) {
            if(endingInText){
                return daysdiff;
            }else{
                return 'days'
            }

        } else if (hoursdiff < 24 && hoursdiff > 0) {
            if(endingInText){
                return hoursdiff;
            }else{
                return 'hour'
            }

        } else if (minutesdiff < 60 && minutesdiff >= 0) {
            if(endingInText){
                return minutesdiff;
            }else{
                return 'minute'
            }

        } else {
            if(endingInText){
                return '--';
            }else{
                return ''
            }
        }
    };

    const setFormValueData = (status)=>{

        if(!isLoading && !isLoadMoreTestLoading){

            setFormValues({...formValues,status:status,loading:false})
        }else{

            setFormValues({...formValues,status:status,loading:true})
        }


    }

    useEffect(()=>{

        //console.log(formValues)
        if (formValues.loading) {

            //ReactSession.set("dashboardFilter", formValues); // set value in session
            setIsApplyFilter(true);
            getTests(false);
        }

    },[formValues])

    const closeNewTestModal = (reload) => {
        setOpenNewTestModal(false);
    };
    const closeChooseMethodologiesModal = (reload) => {
        setOpenChooseMethodologiesModal(false);
    };


    const calculateTotalCount = (methodology,searchKeyword)=>{

        // calculation count
        var allCount = 0;
        var draftCount = 0;
        var publishedCount = 0;
        var completedCount = 0;
        let TestCountData = tests.filter((data) => {

            // search parameter
            var rgxp = new RegExp(searchKeyword.toLowerCase(), "g");
            var searchTitle = data.test_name.toLowerCase();

            // draft && complted & published count
            if(formValues.status && methodology){

                if(data.status==='draft' && data.methodology === methodology){


                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        draftCount++;
                    }
                }

                if(data.status==='published' && data.methodology === methodology){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        publishedCount++;
                    }
                }

                if(data.status==='completed' && data.methodology === methodology){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        completedCount++;
                    }
                }
            }else if(methodology && !formValues.status){

                if(data.status==='draft' && data.methodology === methodology){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        draftCount++;
                    }
                }

                if(data.status==='published' && data.methodology === methodology){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        publishedCount++;
                    }
                }

                if(data.status==='completed' && data.methodology === methodology){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        completedCount++;
                    }
                }
            }else if(!methodology && formValues.status){
                if(data.status==='draft'){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        draftCount++;
                    }
                }

                if(data.status==='published'){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        publishedCount++;
                    }
                }

                if(data.status==='completed'){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        completedCount++;
                    }
                }
            }else{
                if(data.status==='draft'){
                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        draftCount++;
                    }
                }

                if(data.status==='published'){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        publishedCount++;
                    }
                }

                if(data.status==='completed'){

                    if(searchTitle.match(rgxp) && searchTitle.match(rgxp).length > 0) {
                        completedCount++;
                    }
                }
            }
        });

        if(!isLoading && !isLoadMoreTestLoading && ReactSession.get("enableLoadingData") === 'no') {
            setStatusTestsCount({
                'all': draftCount+publishedCount+completedCount,
                'draft': draftCount,
                'published': publishedCount,
                'completed': completedCount
            });
        }
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {

                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){

                        if(!search){
                            setEnableSearch(false)
                        }
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref,search]);
    }

    useOutsideAlerter(searchRef);


    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel} 
            hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
           <div className="das-hdsrch-hold">
            <div className="page-heading-wrap page-heading-wrap d-flex  align-items-center justify-content-between dashboard-page-heading">
                <div className="test-publish-fee-header-left align-items-center d-flex">
                    <h1 className="heading h1">Your Dashboard</h1>
                    {!isFilterLoading && !isLoading &&
                    <>
                    {publishing_credits==0 && days_left>0  &&
                    <Text type={"body-text-3"} fontWeight={"medium-font"}> No publishing fee</Text>
                    }
                    
                    {days_left>0 &&
                    <Tag type="purple-tag" iconLeft={"time"} colorClass="secondary-purple-200-svg path"  title={days_left+" day"+(days_left>1?"s":"")+" left"} />
                    }
                    {publishing_credits>0 && days_left>0 &&
                    <Tag type="pink-tag" iconLeft={"credit"} title={"Publishing fee: "+publishing_credits+" credits"} />
                    }
                    </>
                    }
                </div>
                
                {isPageRefresh ? (
                    
                        <div className="page-head-right d-flex dashbaord-header-right-with-search">
                            <div className={'search-functionlity-data'}>
                            <span className={`dashboard-search-icon skeleton-search`}>
                                <Skeleton width={40} height={40} style={{ borderRadius: '5px' }} />
                            </span>
                            </div>
                            <Skeleton width={230} height={60} style={{ borderRadius: '50px' }} />
                        </div>
                        
                    ) :
                    
                    ((isTestCount !== 0)) ?
                        <div className="page-head-right d-flex dashbaord-header-right-with-search">
                             <div className="search-icon-mobile">
                             

                             <span
  className={`dashboard-search-icon ${!enableSearch ? 'disabled-search' : 'active'}`}
  onClick={toggleSearch}
>
  <Icon value={'search'} size={'medium'} colorClass={'gray-600-svg'} />
</span>
                                </div>    


                            <div className={'search-functionlity-data search-for-desktop'}>


                                {!enableSearch &&
                                    <span className={`dashboard-search-icon ${(!isLoading)?'disabled-search':''}`}
                                      onClick={() => (!isLoading) ? setEnableSearch(true) : setEnableSearch(false)}>
                                        <Icon value={'search'} size={'medium'} colorClass={'gray-600-svg'} hover={'true'}/>
                                    </span>
                                }

                                    <span className={`dashboard-search-data-wrap ${enableSearch?'active-search-1':''}`} ref={searchRef}>
                                         <Search
                                             size={'small'}
                                             type="text"
                                             placeholder="Search for study"
                                             value={search}
                                             onChange={(event) => {

                                                 setFormValues({...formValues, loading: (!isLoading && !isLoadMoreTestLoading)?false:true})

                                                 // if(event.target.value){
                                                 //     setSearch(event.target.value);
                                                 // }else{
                                                     searchData(event.target.value);
                                                 // }

                                             }}
                                             onClear={(event) => {
                                                 searchData("");
                                             }}
                                             onBlur={()=>{
                                                 if(!search){
                                                     setEnableSearch(false)
                                                 }
                                             }}
                                             loading={false}
                                         />

                                    </span>


                            </div>
                            <Button
                                size={'large'}
                                cssclass={'create-test-btn'}
                                iconLeft={<Icon value={'add'} colorClass={'gray-50-svg add-icon add-icon-dashboard'} />}
                                label={'Create new test'}
                                onClick={() => {
                                    setOpenChooseMethodologiesModal(true);
                                }}
                            />
                        </div>
                        :
                        ''
                        


                }


                
            </div>

            <div className="search-field-form-mobile">

            <div className={'search-functionlity-data'}>

                                    <span className={`dashboard-search-data-wrap ${enableSearch?'active-search-1':''}`} ref={searchRef}>
                                         <Search
                                             size={'small'}
                                             type="text"
                                             placeholder="Search"
                                             value={search}
                                             onChange={(event) => {

                                                 setFormValues({...formValues, loading: (!isLoading && !isLoadMoreTestLoading)?false:true})

                                                 // if(event.target.value){
                                                 //     setSearch(event.target.value);
                                                 // }else{
                                                     searchData(event.target.value);
                                                 // }

                                             }}
                                             onClear={(event) => {
                                                 searchData("");
                                             }}
                                             onBlur={()=>{
                                                 if(!search){
                                                     setEnableSearch(false)
                                                 }
                                             }}
                                             loading={false}
                                         />

                                    </span>


                            </div>
                            </div>
                    </div>        

            <div className="dashboard-sub-head-wrap">
                <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }} href={process.env.REACT_APP_URL + "t/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                    Support
                </a>
                {/*{!isLoading && (tests && tests.length > 0 || isApplyFilter) &&*/}
                
                
                 
                {isPageRefresh ? (
                <>
                <div id="test-filters" className={'dashboard-filter-wrap'}>
                    <div className="dashboard-filter-left desktop-view">
                    <div className="testnaviagtionlist desktop-view">
                        <span className={'filter-skelton'}><Skeleton width={121} height={46} style={{borderRadius:'5px'}} /></span>
                        <span className={'filter-skelton'}><Skeleton width={121} height={46} style={{borderRadius:'5px'}} /></span>
                        <span className={'filter-skelton'}><Skeleton width={121} height={46} style={{borderRadius:'5px'}} /></span>
                        <span className={'filter-skelton'}><Skeleton width={121} height={46} style={{borderRadius:'5px'}} /></span>
                    </div>
                    </div>
                    <div className="dashboard-filter-right">
                    <div className={'dropdown mobile-view dropdown'}>
                        <div className="filter-menu-wrap">
                        <span className={`filter-menu-btn`}>
                            <Skeleton width={160} height={46} style={{borderRadius:'5px'}} />
                        </span>
                        </div>
                    </div>
                    <div className="filter-menu-wrap">
                        <span className={`filter-menu-btn`}>
                        <Skeleton width={160} height={46} style={{borderRadius:'5px'}} />
                        </span>
                    </div>
                    <div className="dashboard-sorting-wrap">
                        <span className="filter-sort-btn">
                        <Skeleton width={160} height={46} style={{borderRadius:'5px'}} />
                        </span>
                    </div>
                    </div>
                </div>
                 </>
                ) :
                    <>
                        {!isLoading && ((isTestCount !== 0)) &&
                        <div className="dashboard-filter-wrap" id="test-filters">
                            <div className="dashboard-filter-left desktop-view">


                                <div className="testnaviagtionlist desktop-view">
                                    <span
                                        className={(formValues.status=== '')?'active':''}
                                        onClick={()=>{
                                            setFormValueData('')
                                        }}
                                    >
                                        All tests <em className="tests-counter">({statusTestsCount.all})</em>
                                    </span>
                                    <span
                                        className={(formValues.status=== 'draft')?'active':''}
                                        onClick={()=>{

                                            setFormValueData('draft')

                                        }}
                                    >
                                        Draft <em className="tests-counter">({statusTestsCount.draft})</em>
                                    </span>
                                    <span
                                        className={(formValues.status=== 'published')?'active':''}
                                        onClick={()=>{
                                            setFormValueData('published')
                                        }}
                                    >
                                        Live <em className="tests-counter">({statusTestsCount.published})</em>
                                    </span>
                                    <span
                                        className={(formValues.status=== 'completed')?'active':''}
                                        onClick={()=>{
                                            setFormValueData('completed')
                                        }}
                                    >
                                        Completed <em className="tests-counter">({statusTestsCount.completed})</em>
                                    </span>
                                </div>
                            </div>

                            <div className="dashboard-filter-right">
                                <Dropdown className="dropdown mobile-view">
                                    <Dropdown.Toggle className="dropdown-toggle">
                                    <span className={`filter-menu-btn`}>
                                        {(formValues.status === '') ?
                                            'All tests'
                                            :
                                            (formValues.status === 'published')
                                                ?
                                                'live'
                                                :
                                                formValues.status
                                        }
                                        {(formValues.status === '') ?
                                            <em className="tests-counter">({statusTestsCount.all})</em>
                                            :
                                            <em className="tests-counter">({statusTestsCount[formValues.status]})</em>
                                        }

                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M26 11L16 21L6 11" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className={`hamburger-items`}>

                                            <Dropdown.Item  className={`active-item ${(formValues.status === '')?'active':''}`} onClick={
                                                ()=>{
                                                    setFormValueData('')
                                                }
                                            }
                                            >
                                                All tests <em className="tests-counter">({statusTestsCount.all})</em>
                                            </Dropdown.Item>
                                            <Dropdown.Item  className={`active-item ${(formValues.status=== 'draft')?'active':''}`} onClick={
                                                ()=>{
                                                    setFormValueData('draft')
                                                }
                                            }
                                            >
                                                Draft <em className="tests-counter">({statusTestsCount.draft})</em>
                                            </Dropdown.Item>

                                            <Dropdown.Item  className={`active-item ${(formValues.status=== 'published')?'active':''}`}  onClick={
                                                ()=>{
                                                    setFormValueData('published')
                                                }
                                            }
                                            >
                                                Live <em className="tests-counter">({statusTestsCount.published})</em>
                                            </Dropdown.Item>

                                            <Dropdown.Item className={`active-item ${(formValues.status=== 'completed')?'active':''}`} onClick={
                                                ()=>{
                                                    setFormValueData('completed')
                                                }
                                            }
                                            >
                                                Completed <em className="tests-counter">({statusTestsCount.completed})</em>
                                            </Dropdown.Item>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="filter-menu-wrap">

                                    <Dropdown className="dropdown">
                                        <Dropdown.Toggle className="dropdown-toggle">
                                    <span className={`filter-menu-btn ${(toggleMethodology)?'active':''} ${formValues.methodology!==''?"selected-filter":""}`}>
                                        {(formValues.methodology === '') ?
                                            'All methodologies'
                                            :
                                            methodologies[formValues.methodology]
                                        }
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M26 11L16 21L6 11" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className={`hamburger-items`}>

                                                <Dropdown.Item  className={`active-item ${(formValues.methodology === '')?'active':''}`} onClick={
                                                    ()=>{
                                                        calculateTotalCount('',search);
                                                        setFormValues({...formValues, methodology: '', loading: (!isLoading && !isLoadMoreTestLoading)?false:true})
                                                    }}
                                                >
                                                    All methodologies
                                                </Dropdown.Item>

                                                <Dropdown.Item className={`active-item ${(formValues.methodology === 'Prototype test')?'active':''}`}  onClick={
                                                    ()=>{

                                                        calculateTotalCount('Prototype test',search);
                                                        setFormValues({
                                                            ...formValues,
                                                            methodology: 'Prototype test',
                                                            loading: (!isLoading && !isLoadMoreTestLoading)?false:true
                                                        })
                                                    }}
                                                >
                                                    Prototype test
                                                </Dropdown.Item>

                                                <Dropdown.Item className={`active-item ${(formValues.methodology === 'Card Sorting')?'active':''}`} onClick={
                                                    ()=>{

                                                        calculateTotalCount('Card Sorting',search);
                                                        setFormValues({
                                                            ...formValues,
                                                            methodology: 'Card Sorting',
                                                            loading: (!isLoading && !isLoadMoreTestLoading)?false:true
                                                        })

                                                    }}
                                                >
                                                    Card sorting
                                                </Dropdown.Item>

                                                <Dropdown.Item className={`active-item ${(formValues.methodology === 'Preference Test')?'active':''}`}  onClick={
                                                    ()=>{

                                                        calculateTotalCount('Preference Test',search);
                                                        setFormValues({
                                                            ...formValues,
                                                            methodology: 'Preference Test',
                                                            loading: (!isLoading && !isLoadMoreTestLoading)?false:true
                                                        })

                                                    }}
                                                >
                                                    Preference test
                                                </Dropdown.Item>

                                                <Dropdown.Item  className={`active-item ${(formValues.methodology === 'Tree Test')?'active':''}`} onClick={
                                                    ()=>{
                                                        calculateTotalCount('Tree Test',search);
                                                        setFormValues({...formValues,methodology:'Tree Test',loading:(!isLoading && !isLoadMoreTestLoading)?false:true})

                                                    }}
                                                >
                                                    Tree test
                                                </Dropdown.Item>

                                                <Dropdown.Item className={`active-item ${(formValues.methodology === 'Survey')?'active':''}`} onClick={
                                                    ()=>{

                                                        calculateTotalCount('Survey',search);
                                                        setFormValues({...formValues,methodology:'Survey',loading:(!isLoading && !isLoadMoreTestLoading)?false:true})
                                                    }}
                                                >
                                                    Survey
                                                </Dropdown.Item>

                                                <Dropdown.Item className={`active-item  ${(formValues.methodology === 'Five Seconds Test')?'active':''}`}  onClick={
                                                    ()=>{

                                                        calculateTotalCount('Five Seconds Test',search);
                                                        setFormValues({
                                                            ...formValues,
                                                            methodology: 'Five Seconds Test',
                                                            loading: (!isLoading && !isLoadMoreTestLoading)?false:true
                                                        })
                                                    }}
                                                >
                                                    5 second test
                                                </Dropdown.Item>

                                                <Dropdown.Item className={`active-item  ${(formValues.methodology === 'First click')?'active':''}`}  onClick={
                                                    ()=>{

                                                        calculateTotalCount('First click',search);
                                                        setFormValues({
                                                            ...formValues,
                                                            methodology: 'First click',
                                                            loading: (!isLoading && !isLoadMoreTestLoading)?false:true
                                                        })
                                                    }}
                                                >
                                                    First click
                                                </Dropdown.Item>


                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>



                                </div>
                                <div className="dashboard-sorting-wrap">
                            <span className="filter-sort-btn" onClick={
                                ()=>{
                                    if(!isLoading && !isLoadMoreTestLoading){
                                        if (formValues.order === 'desc') {
                                            setFormValues({...formValues, order: 'asc', loading: false})
                                        } else {
                                            setFormValues({...formValues, order: 'desc', loading: false})
                                        }
                                    }

                                }}>
                                {formValues.order === 'desc' ?
                                    <>
                                        <span className="desktop-view">Newest to oldest</span>
                                        <Icon value={'sort-order-up'} colorClass={'gray-900-svg dashboard-sort'} />
                                    </>
                                    :
                                    <>
                                        <span className="desktop-view">Oldest to newest</span>
                                        <Icon value={'sort-order'} colorClass={'gray-900-svg dashboard-sort'} />
                                    </>
                                }

                            </span>
                                </div>
                            </div>
                        </div>
                        }

                    </>
                }


                {/*{!isLoading && !isFilterLoading && ((tests && tests.length === 0 && isTestAvailable) || isTestCount === 0) &&*/}
                {!isLoading && !isFilterLoading && isTestCount === 0 && (
                    <>
                     <div className="publish-fees-required-wrap d-flex align-items-center flex-wrap">
                      <Text type={'h3'} children={'Get started by choosing a test type'} />
                        {publishing_credits > 0 && (
                        <Tag type="pink-tag" iconLeft={"credit"} title={"Publishing fee: " + roundNumber(publishing_credits,2) + " credits"} />
                        )}
                    </div>
                     <Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={'gray-color mt-16 pricing-info-text'}>
                    Share the test with your own participants and no recruitment fees apply. <UQLink className={'link-text'} target="_blank" url={"https://userq.com/pricing/"}> Learn more about pricing here.</UQLink>
                    </Text>
                    </>
                )}

                
            </div> 

            {isLoading ?
                <>
                    {/*<div className={'dashboard-filter-loader'}>*/}
                    {/*    <LoadingIcon/>*/}
                    {/*</div>*/}
                    <div className="all-test-listing-wrapper">
                        {renderTests([])}
                    </div>
                </>
                :
                <>
                    {isFilterLoading ?
                        <>
                            {/*<div className={'dashboard-filter-loader'}>*/}
                            {/*    <LoadingIcon/>*/}
                            {/*</div>*/}
                            <div className="all-test-listing-wrapper">
                                {renderTests([])}

                            </div>
                        </>
                        :
                        <>
                            {tests && tests.length > 0 ?
                                <>
                                <div className={"cards-min-height"} >
                                    <div className="all-test-listing-wrapper">
                                        {renderTests(tests)}


                                        {isLoadMoreTestLoading && [1,2,3,4,5,6,7,8].map(function (index) {
                                            return (
                                                <CardTemplate
                                                    isLoading={true}
                                                    key={index}
                                                />
                                            );
                                        })
                                        }
                                    </div>
                                </div>
                                {/*<div className={"w-100 total-test-count-filter"}>
                                    <Text type={'body-text-3'} fontWeight={"medium-font"}>
                                        {filterTestCount} {filterTestCount ===1 ?'Test':'Tests'}
                                    </Text>
                                </div>*/}
                                </>
                                :
                                <>
                                    {/*{!isTestAvailable ?*/}
                                    {(isTestCount === 0) ?
                                        <div className="creat-test-cards-wrapper">
                                            {testDefaultCardsData.map((items,index) => {
                                                return (
                                                    <Defaultcard
                                                        key={index}
                                                        title={items.title}
                                                        description={items.description}
                                                        image={items.image}
                                                        isComingSoon={items.isComingSoon}
                                                        onClick={() => {
                                                            setOpenNewTestModal(true);
                                                            setModalMethodology(items.methodology);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                        :
                                        <div className={'no-test-data-available'}>
                                            No test available
                                        </div>

                                    }
                                </>

                            }
                        </>
                    }

                </>
            }
            {openNewTestModal && (
                <TestSettingsModal
                    openModal={openNewTestModal}
                    closeModal={closeNewTestModal}
                    selectedMethodology={modalMethodology}
                    totalTests={totalTests}
                />
            )}

            {openChooseMethodologiesModal && (
                <ChooseMethodologyModal
                    publishing_credits={publishing_credits}
                    days_left={days_left}
                    openModal={openChooseMethodologiesModal}
                    closeModal={closeChooseMethodologiesModal}
                    enterTestDetails={(methodology)=>{
                        setOpenNewTestModal(true);
                        closeChooseMethodologiesModal();
                        setModalMethodology(methodology);
                        if(methodology === 'Preference Test'){
                            setTotalTests(totalTestsCount.Preference_Test_count+1)
                        }else if(methodology === 'Card Sorting'){
                            setTotalTests(totalTestsCount.Card_Sorting_count+1)
                        }else if(methodology === 'Tree Test'){
                            setTotalTests(totalTestsCount.Tree_Test_test+1)
                        }else if(methodology === 'Survey'){
                            setTotalTests(totalTestsCount.Survey_count+1)
                        }else if(methodology === 'Five Seconds Test'){
                            setTotalTests(totalTestsCount.Five_Seconds_Test_count+1)
                        }else if(methodology === 'First click'){
                            setTotalTests(totalTestsCount.First_Click_count+1)
                        }else if(methodology === 'Prototype test'){
                            setTotalTests(totalTestsCount.prototype_test_count+1)
                        }
                    }

                    }
                />
            )}


            <TestSummaryModal
                test_id={testSummaryModal.test_id}
                openModal={testSummaryModal.open}
                close={() => {
                    setTestSummaryModal({ ...testSummaryModal, open: false });
                }}
            />
            <CopyLinkModal
                link={copyLinkModal.link}
                resultLink={copyLinkModal.resultLink}
                password={copyLinkModal.password}
                test_id={copyLinkModal.test_id}
                test={copyLinkModal.test}
                openModal={copyLinkModal.open}
                close={() => {
                    setCopyLinkModal({ ...copyLinkModal, open: false });
                }}
            />
            <ConfirmationModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title={confirmModal.confirm_btn_title}
                confirm_title={confirmModal.confirm_title}
                confirm_title_class={confirmModal.confirm_title_class}
                openModal={confirmModal.open}
                btnLoading={confirmationBtnLoading}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    // setConfirmModal({ ...confirmModal, open: false });
                    if (confirmModal.confirmation_for === "testdelete") {
                        deleteTestApi(confirmModal.test_id);
                    } else if (confirmModal.confirmation_for === "endtest") {
                        endTestApi(confirmModal.test_id);
                    }

                }}
            />
            <WelcomeMessageModal
                open={welcomeMessageModal.open}
                close={()=>{setWelcomeMessageModal({...welcomeMessageModal, open:false})
                    let user = ReactSession.get("user");

                    user.welcome_message_shown = 1;
                    ReactSession.set("user", user);
                    updateWelcomeMessage(ReactSession.get("token"))
                        .then(response=>{
                        });}}
                body={
                    <>
                        <div className="welcome-userq-text mt-20 good-togo-wrap">
                            <div className="mb-12">
                                <Text type={'h4'}>You’re good to go!</Text>
                            </div>
                            <div className="good-to-go-data-hold">

                                <div className="good-to-go-data-repeat d-flex mt-24">
                                    <Icon value={'green-check-border'} size={'medium'}/>
                                    <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={'color-black'}>You can start running tests straight away</Text>
                                </div>

                                <div className="good-to-go-data-repeat d-flex mt-24">
                                    <Icon value={'green-check-border'} size={'medium'}/>
                                    <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={'color-black'}>Free to publish for the first 30 days</Text>
                                </div>

                                <div className="good-to-go-data-repeat d-flex mt-24">
                                    <Icon value={'green-check-border'} size={'medium'}/>
                                    <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={'color-black'}>Share with your own participants or recruit from our panel</Text>
                                </div>

                            </div>
                        </div>

                    </>
                }
                btn={
                    <Button
                        type={"primary"}
                        size={"large"}
                        label={"Let’s get started"}
                        onClick={()=>{

                            setWelcomeMessageModal({...welcomeMessageModal, open:false})
                            let user = ReactSession.get("user");

                            user.welcome_message_shown = 1;
                            ReactSession.set("user", user);
                            updateWelcomeMessage(ReactSession.get("token"))
                                .then(response=>{
                                });
                        }}


                    />
                }
            />
            <NewFeatureModal 
                openModal={openNewFeatureModal.open}
                title={"New feature alert"}
                body={<>
                    <div className="new-feature-body-left">
                        <img src={process.env.REACT_APP_URL+"img/prototype-test-black.svg"} />
                    </div>
                    <div className="new-feature-body-right">
                        <Text type={"subtitle-2"} fontWeight={"semi-bold-font"}>Prototype testing</Text>
                        <Text type={"body-text-3"} fontWeight={"medium-font"}>
                            Track and analyse user behaviour in your prototype through clicks and navigation patterns.  <a href="https://userq.com/testing-tools/prototype-test/" target="_blank">Learn more</a>
                        </Text>
                        
                    </div>
                    </>}
                btn={
                    <Button 
                        type={"primary"}
                        size={"large"}
                        label="Create a prototype test"
                        iconRight={
                            <Icon value="forward-arrow" colorClass={'gray-50-svg forword-arrow'} size="medium" />
                        }
                        onClick={()=>{

                            setOpenNewFeatureModal({...openNewFeatureModal, open:false})

                           

                            setModalMethodology("Prototype test");

                            setOpenNewTestModal(true);

                            updateNewFeature();
                        
                        }}
                    />
                }
                close={()=>{
                    setOpenNewFeatureModal({...openNewFeatureModal, open:false})

                    updateNewFeature();
                }}
            />
            <HelpModal 
                cssClass={"duplicate-test-modal"}
				open={duplicateModal.open}
				close={()=>{
					setDuplicateModal({...duplicateModal, open:false});
				}}
				heading={"Duplicate test"}
                btnLoading={duplicateBtnLoading}
				body={
				       <div className="prototype-criteria-modal-data">
                            <div className="mb-20">
                                <Text cssClasses={"gray-text"} type={"body-text-3"} fontWeight={"medium-font"}>Choose
                                    whether you would like to keep your recruitment selections when duplicating this
                                    test</Text>
                            </div>
                            <div className="duplicate-test-options">
                                <div className="mb-20">
                                    <Box
                                        size={"large"}
                                        cssClasses={`${duplicateModal.duplicate_preference == "clear" ? "active-box" : ""}`}
                                    >
                                        <RadioButton
                                            isChecked={duplicateModal.duplicate_preference == "clear" ? true : false}
                                            label={"Clear recruitment selections"}
                                            sublabel={"This will clear all recruitment and screening criteria for the duplicated test"}
                                            onChange={() => {
                                                setDuplicateModal({...duplicateModal, duplicate_preference: "clear"})
                                            }}
                                        />
                                    </Box>
                                </div>
                                <div className="mb-20">
                                    <Box
                                        size={"large"}
                                        cssClasses={`${duplicateModal.duplicate_preference == "keep" ? "active-box" : ""}`}
                                    >
                                        <RadioButton
                                            isChecked={duplicateModal.duplicate_preference == "keep" ? true : false}
                                            label={"Keep recruitment selections"}
                                            sublabel={"This will keep all recruitment and screening criteria for the duplicated test"}
                                            onChange={() => {
                                                setDuplicateModal({...duplicateModal, duplicate_preference: "keep"})
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center duplicate-test-modal-action">
                                <Button
                                    label={"Duplicate test"}
                                    type={"primary"}
                                    size={"large"}
                                    onClick={() => {
                                        setDuplicateModal({...duplicateModal, open: false});
                                        duplicateTestApi(duplicateModal.test_id);
                                    }}
                                    state={!duplicateModal.duplicate_preference ? "disabled" : "active"}
                                />
                            </div>

                        </div>
				}
			/>
            <NpsModal openModal={npsModal.open} 
                close={()=>{
                    setNpsModal({...npsModal, open:false});

                    let token = ReactSession.get("token");

                    closeFeedback({},token).then((response) => {

                        if(response.success){

                        }else {

                        }

                    });
                }}
            />
            {/**/}

        </LayoutResearcher>
    );
}