import { Text } from "../../../themes/userq/Text/Text";
import React from "react";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";

export default function FiveSecondsTestIntroduction({ goToFirstTask, testlanguage }) {
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap five-sec-welcome-page-wrap">
            <div className="container inner-page-container">
              <div className="taking-test-page-height">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="selected-test-info-img">
                                <img src={process.env.REACT_APP_URL + "img/fivesec-default-placeholder.svg"} />
                            </div>
                        </div>


                        <div className="selected-page-left-side">
                            <Text type={"h1"}>
                                {testlanguage == "en" ? "5 second test" : "اختبار الخمس ثواني"}
                            </Text>

                            <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                {testlanguage == "en" ? <>We'll be showing you a design on the following screen. You will have 5 seconds to explore it and understand what it's about. After 5 seconds, the design will disappear and you will be asked to answer some questions related to it.</>:
                                    "سنعرض لك تصميمًا على الشاشة التالية. سيكون لديك 5 ثوانٍ لاستكشافه وفهمه. بعد 5 ثوان سيختفي التصميم وسيُطلب منك الإجابة على بعض الأسئلة المتعلقة به."}

                            </Text>

                            <div className={'remember-text'}>
                                <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                    {testlanguage == "en" ? "Remember, there’s no right or wrong answer here - just do what comes naturally."
                                        : "تذكر أنه لا توجد إجابة صحيحة أو خاطئة هنا - فقط لاحظ كما تفعل بشكل طبيعي."}
                                </Text>
                            </div>

                            <div className="button-wrapper none-for-mobile">
                                <Button
                                    size={'large'}
                                    iconRight={<Icon colorClass={"gray-50-svg"} value={testlanguage=="ar"?'back-arrow':'forward-arrow'} />}
                                    label={testlanguage == "en" ? "Let’s start" : "لنبدأ"}
                                    onClick={() => {
                                        goToFirstTask();
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    <Button
                        size={'large'}
                        iconRight={<Icon colorClass={"gray-50-svg"} value={testlanguage=="ar"?'back-arrow':'forward-arrow'} />}
                        label={testlanguage == "en" ? "Let’s start" : "لنبدأ"}
                        onClick={() => {
                            goToFirstTask();
                        }}
                    />
                </div>


            </div>
        </div>
    );
}