import TextareaAutosize from 'react-textarea-autosize';
export default function TextAreaField({autosize, value, rtl, defaultValue, maxLength,onClear,onInput,id,onChange,onKeyDown, error, error_class, label, required, language, readOnly}){
    
    return (
      <div
        className={`input form-group textarea-form-group input-field mb-0 ${
          language === "ar" ? "arabic_wrapper" : ""
        } ${value != "" ? "focused" : ""} ${readOnly ? "read-only-field" : ""}`}
      >
        <div className="position-relative w-100">
          {autosize && (
            <TextareaAutosize
              id={id}
              className={`form-control ${
                error != null && error_class ? error_class : ""
              }`}
              value={value}
              defaultValue={defaultValue}
              onKeyDown={onKeyDown}
              onChange={onChange}
              maxLength={maxLength}
              onInput={onInput}
              rtl={rtl}
              readOnly={readOnly}
            />
          )}
          {!autosize && (
            <textarea
              id={id}
              className={`form-control ${
                error != null && error_class ? error_class : ""
              } `}
              value={value}
              defaultValue={defaultValue}
              onKeyDown={onKeyDown}
              onChange={onChange}
              maxLength={maxLength}
              onInput={onInput}
              rtl={rtl}
            >
              {value}
            </textarea>
          )}
          <label className="control-label" htmlFor={id}>
            {label}
            {required && (
              <img
                src={process.env.REACT_APP_URL + "images/star-field.svg"}
                className="required-field-icon"
                alt="required-field"
              ></img>
            )}
          </label>
          {/* {readOnly && (
            <div className="data-field-icon">
              <img
                src={process.env.REACT_APP_URL + "images/fill-check.svg"}
                alt="icon"
              />
            </div>
          )} */}
          {onClear && (
            <span className="clear" onClick={onClear}>
              <svg viewBox="0 0 24 24">
                <path
                  className="line"
                  d="M 2,2 C 3.925,3.925 8.149999999999999,8.15 12,12 C 15.85,15.85 20.075,20.075 22,22"
                ></path>
                <path className="long" d="M9 15L20 4"></path>
                <path className="arrow" d="M13 11V7"></path>
                <path className="arrow" d="M17 11H13"></path>
              </svg>
            </span>
          )}
        </div>

        {!readOnly && maxLength && (
          <p className="project-name-guide-text">
            Max characters:&nbsp;
            <span className="pink-color">
              {value && value.length > 0 && <>{value.length}/</>}
              {maxLength}
            </span>
          </p>
        )}
        {error != null && <span className={error_class}>{error}</span>}
      </div>
    );
}
