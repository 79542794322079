import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../Loader/loadingIcon";
import { Text } from './../Text/Text';
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";

export default function DeleteAccountModal({
                                               openModal,
                                               confirm_title,
                                               confirm_btn_title,
                                               cancel_btn_title,
                                               close,
                                               confirm,
                                               btnLoading,
                                               confirm_title_class,
                                           }) {
    const closeModal = () => {
        close();
    };

    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap delete-account-modal-wrap"
        >
            <Modal.Body className="" ref={wrapperRef}>
                {/* {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon />
                    </div>
                    : */}
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <h3 className={`heading h3 ${confirm_title_class ? confirm_title_class : ''}`}>{confirm_title}</h3>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                                <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                                </span>
                        </button>
                    </div>
                    <Text type="body-text-3" fontWeight="medium-font">Are you sure you want to delete your account? Once you delete your account, all tests and unused credits will be removed and you will not be able to create a new account using this email for the next 90 days. <span className='text-danger' >This action cannot be undone.</span></Text>

                    <div className="confirm-buttons-wrap">
                        <Button type="secondary" size="large" label={confirm_btn_title ? confirm_btn_title : "OK"} onClick={() => { confirm(); }} microLoading={btnLoading}/>
                        <Button type="primary" size="large" label={cancel_btn_title ? cancel_btn_title : 'Cancel'} onClick={() => { closeModal(); }} />
                    </div>

                </div>
                {/* } */}
            </Modal.Body>
        </Modal>
    );
}
