import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Text } from "../Text/Text";
import { roundNumber } from "../../../../lib/helpers";

export const ScoreChip = ({ testerscore }) => {

    return (
            <span className={`scorechip ${testerscore>80 ? "green-chip":""} ${60<testerscore && testerscore<=80 ? "purple-chip":""} ${50<testerscore && testerscore<=60 ? "yellow-chip":""} ${testerscore<=50 && testerscore!=null  ? "red-chip":""} ${testerscore==null?"white-chip":""}`}>
                <Text type="body-text-2" fontWeight="medium-font">Your score is</Text>
                <Text type="h4" fontWeight="medium-font">{roundNumber(testerscore, 0)}%</Text>
            </span>
        
    )
}

ScoreChip.propTypes = {
    testerscore: PropTypes.number,

};
ScoreChip.defaultProps = {
    testerscore: null,

};