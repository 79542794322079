import React from "react";
import {Icon} from "../../../themes/userq/Icon/Icon";
import {Text} from "../../../themes/userq/Text/Text";
import {Button} from "../../../themes/userq/Button/Button";

export default function CardSortingIntroduction({goToFirstTask,testlanguage}){
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap card-sorting-introduction">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="selected-test-info-img">
                               
                                    <img src={process.env.REACT_APP_URL+"img/cardsorting-placeholder.svg"}/>
                                
                            </div>
                        </div>


                        <div className={`selected-page-left-side ${testlanguage=="ar"?"arabic_wrapper":""}`}>
                            <Text type={"h1"}>
                                {testlanguage=="en"?"Card sorting":"فرز البطاقات"}
                            </Text>

                            <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                {testlanguage=="en"?
                                    "You'll be asked to look at a list of cards, and drag them into groups that you think would be best. We'll also ask you to give each group an appropriate name."
                                    :"سيُطلب منك إلقاء نظرة على قائمة البطاقات وفرزها في المجموعات التي تعتقد أنها تناسبها. سنطلب منك أيضًا إعطاء كل مجموعة اسمًا مناسبًا لها."}
                            </Text>

                            <div className={'remember-text'}>
                                <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                    {testlanguage=="en"?"Remember, there’s no right or wrong answer here - just do what comes naturally."
                                        :"تذكر أنه لا توجد إجابة صحيحة أو خاطئة هنا - فقط لاحظ كما تفعل بشكل طبيعي."}
                                </Text>
                            </div>

                            <div className="button-wrapper none-for-mobile">
                                <Button
                                    size={'large'}
                                    iconRight={<Icon colorClass={"gray-50-svg"} value={testlanguage=="ar"?'back-arrow':'forward-arrow'}/>}
                                    label={testlanguage == "en" ? "Let’s start" : "لنبدأ"}
                                    onClick={() => {
                                        goToFirstTask();
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                </div>

                <div className="button-wrapper none-for-desktop taking-test-button">
                    <Button
                        size={'large'}
                        iconRight={<Icon colorClass={"gray-50-svg"} value={testlanguage=="ar"?'back-arrow':'forward-arrow'}/>}
                        label={testlanguage=="en"?"Let’s start":"لنبدأ"}
                        onClick={() => {
                            goToFirstTask();
                        }}
                    />
                </div>
            </div>
        </div>
    );
}