import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../themes/userq/Text/Text";
import { Button } from '../themes/userq/Button/Button';
import { Icon } from "../themes/userq/Icon/Icon";
import { Box } from "../themes/userq/Box/Box";

export default function IdVerifyPushModal({
  open,
 close,
  confirm,
  isLoading
 
}) {
  
  return (
    <Modal
      show={open}
      centered
      className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap welcom-userq-modal welcome_tester_userq_modal"
      close={close}
      id="idVerificationModal"
    >
        
        <Modal.Body className="" >
             <>
                    <div className="congrats-modal-header-step-second">
                        <img src={process.env.REACT_APP_URL + 'img/tester_id_banner.svg'} />
                    </div>
                    <div className="congrats-modal-text">
                        <div className="tester-id-verified-modal-data">
                            <Text type={"h3"}>Verify your identity</Text>
                            <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text mt-20"}>We are encouraging all testers to verify their identity in order to ensure the security and privacy of our platform. We take the protection of our users' information very seriously, and we require all users to provide valid proof of identity before requesting a payout.</Text>
                             <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text mt-20"}>By verifying your identity, you will:</Text>
                                
                                <div className="tester-idv-doctype mt-20 mb-20 row align-items-center flex-wrap justify-content-between">
                                    <div className="col-md-4 p-2 box-popup">
                                    <Box size={'small'}>
                                        <div className="tester-doctype-repeat">
                                        <Icon colorClass="secondary-purple-200-svg" value={"multiple-task"} size="large" cssClasses={'justify-content-center'}/>
                                        <Text type={"body-text-3"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Get access to more tests</Text>
                                    </div>
                                    </Box>
                                    </div>
                                   <div className="col-md-4 p-2 box-popup">
                                    <Box size={'small'}>
                                        <div className="tester-doctype-repeat">
                                        <Icon colorClass="secondary-purple-200-svg" value={"money"} size="large" cssClasses={'justify-content-center'}/>
                                        <Text type={"body-text-3"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Be able to request payouts</Text>
                                    </div>
                                    </Box>
                                    </div>
                                    <div className="col-md-4 p-2 box-popup">
                                    <Box size={'small'}>
                                        <div className="tester-doctype-repeat">
                                        <Icon colorClass="secondary-purple-200-svg" value={"chevron-up"} size="large" cssClasses={'justify-content-center'}/>
                                        <Text type={"body-text-3"} fontWeight={"medium-font"} cssClasses={"color-black mb-0"}>Become a priority in future studies</Text>
                                    </div>
                                    </Box>
                                    </div>

                                </div>

                            <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses={"gray-text"}>To verify your identity, please provide a copy of your government-issued photo identification (Your national ID, driver's license or passport). The ID verification process may take 3-5 mins</Text>
                        </div>
                       <div className="d-flex justify-content-center mt-32">
                            <Button
                            size={"large"}
                            type="primary"
                            label="Verify ID"
                            iconLeft={<Icon colorClass="gray-50-svg" value="id" />}
                            onClick={confirm}
                            microLoading={isLoading}
                            />
                       </div>
                       <div className="d-flex justify-content-center mt-4">
                            <Button
                            type={"ghost"}
                            label={"I'll do it later"}
                            size={"small"}
                            onClick={close}
                            />
                       </div>
                       
                    </div>
                </>
        </Modal.Body>
    </Modal>
  );
}
