import { Text } from "../Text/Text";
import { ProgressBar } from "./progress_bar";
export const PageProgressBar = ({current_step, total_steps, label, variant,language})=>{
    var percentage = Math.round((current_step / total_steps)*100);
    return (
        <div className="page-progress-bar register-progress-bar"> 
           <div className="proress-step-count">
                {label?<Text type={"overline"} fontWeight={"medium-font"}>{label}&nbsp;</Text>:""}

               {language === 'ar'?
                   <>
                       <Text type={"overline"} fontWeight={"medium-font"}>{total_steps}</Text>
                       <Text cssClasses={`${variant=="take-test"?"":"gray-font-text"}`} type={"overline"} fontWeight={"medium-font"}>/{current_step}</Text>
                   </>
                   :
                   <>
                       <Text type={"overline"} fontWeight={"medium-font"}>{current_step}</Text>
                       <Text cssClasses={`${variant=="take-test"?"":"gray-font-text"}`} type={"overline"} fontWeight={"medium-font"}>/{total_steps}</Text>
                   </>
               }

            </div>
            <ProgressBar percentage={percentage} />
        </div>
    );
}