import React from "react";
export const Logo = ({theme, background}) => {
    return (
      <div className={`background-${background}`}>
        {theme=="black" &&
        <svg width="98" height="40" viewBox="0 0 98 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6161 32.396C17.3911 32.396 21.2521 28.832 21.2521 23.486V9.36199H16.8301V23.486C16.8301 26.456 14.7511 28.436 11.6161 28.436C8.44806 28.436 6.33606 26.456 6.33606 23.486V9.36199H1.91406V23.486C1.91406 28.832 5.80806 32.396 11.6161 32.396Z" fill="black"/>
        <path d="M31.4271 32.462C36.0141 32.462 39.0831 30.284 39.0831 27.017C39.0831 24.014 37.3341 22.76 31.8231 21.605C29.4801 21.077 28.7211 20.648 28.7211 19.955C28.7211 18.998 29.8101 18.338 31.4271 18.338C32.8791 18.338 34.1992 19.097 34.6941 20.219L38.3241 19.328C37.3671 16.754 34.5951 15.038 31.4271 15.038C27.4341 15.038 24.7611 17.084 24.7611 20.153C24.7611 22.892 26.2792 23.981 31.0641 25.07C34.1331 25.664 35.1231 26.225 35.1231 27.017C35.1231 28.304 33.6381 29.162 31.4271 29.162C29.2161 29.162 27.6321 28.04 27.5001 26.39H23.7711C23.9031 30.02 26.9721 32.462 31.4271 32.462Z" fill="black"/>
        <path d="M49.7298 32.462C53.7558 32.462 57.1878 30.02 58.2768 26.39H54.2508C53.4918 28.04 51.6768 29.162 49.7298 29.162C47.1558 29.162 45.1758 27.446 44.8128 24.839H58.3428L58.6728 24.509V23.75C58.6728 18.536 55.1088 15.038 49.7298 15.038C44.3508 15.038 40.7868 18.536 40.7868 23.75C40.7868 28.964 44.3508 32.462 49.7298 32.462ZM45.0768 21.539C45.8358 19.493 47.5188 18.338 49.7298 18.338C51.9408 18.338 53.6238 19.493 54.3828 21.539H45.0768Z" fill="black"/>
        <path d="M61.0405 32H65.0005V22.232C65.0005 20.021 66.7495 18.536 69.3895 18.536H70.7425V15.5C70.2805 15.302 69.5875 15.17 68.9935 15.17C67.4755 15.17 65.8585 16.094 65.0005 17.447V15.5H61.0405V32Z" fill="black"/>
        <path d="M84.3109 32.462C86.2249 32.462 88.5019 31.901 90.0529 31.076C91.2079 31.901 92.7589 32.462 93.9469 32.462C94.4749 32.462 95.4319 32.264 96.3229 32V28.634C94.7719 28.634 93.6169 28.502 93.4189 28.271C95.0359 26.258 96.0919 23.222 96.0919 20.648C96.0919 13.586 91.3729 8.89999 84.3109 8.89999C77.2489 8.89999 72.5299 13.586 72.5299 20.648C72.5299 27.743 77.2489 32.462 84.3109 32.462ZM84.3109 28.502C79.8889 28.502 76.9519 25.367 76.9519 20.648C76.9519 15.962 79.8889 12.86 84.3109 12.86C88.7329 12.86 91.6699 15.962 91.6699 20.648C91.6699 25.367 88.7329 28.502 84.3109 28.502Z" fill="#FF6DB1"/>
        </svg>
        
        }
        {theme=="white" &&
        <svg width="98" height="40" viewBox="0 0 98 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6161 32.396C17.3911 32.396 21.2521 28.832 21.2521 23.486V9.36199H16.8301V23.486C16.8301 26.456 14.7511 28.436 11.6161 28.436C8.44806 28.436 6.33606 26.456 6.33606 23.486V9.36199H1.91406V23.486C1.91406 28.832 5.80806 32.396 11.6161 32.396Z" fill="white"/>
        <path d="M31.4271 32.462C36.0141 32.462 39.0831 30.284 39.0831 27.017C39.0831 24.014 37.3341 22.76 31.8231 21.605C29.4801 21.077 28.7211 20.648 28.7211 19.955C28.7211 18.998 29.8101 18.338 31.4271 18.338C32.8791 18.338 34.1992 19.097 34.6941 20.219L38.3241 19.328C37.3671 16.754 34.5951 15.038 31.4271 15.038C27.4341 15.038 24.7611 17.084 24.7611 20.153C24.7611 22.892 26.2792 23.981 31.0641 25.07C34.1331 25.664 35.1231 26.225 35.1231 27.017C35.1231 28.304 33.6381 29.162 31.4271 29.162C29.2161 29.162 27.6321 28.04 27.5001 26.39H23.7711C23.9031 30.02 26.9721 32.462 31.4271 32.462Z" fill="white"/>
        <path d="M49.7298 32.462C53.7558 32.462 57.1878 30.02 58.2768 26.39H54.2508C53.4918 28.04 51.6768 29.162 49.7298 29.162C47.1558 29.162 45.1758 27.446 44.8128 24.839H58.3428L58.6728 24.509V23.75C58.6728 18.536 55.1088 15.038 49.7298 15.038C44.3508 15.038 40.7868 18.536 40.7868 23.75C40.7868 28.964 44.3508 32.462 49.7298 32.462ZM45.0768 21.539C45.8358 19.493 47.5188 18.338 49.7298 18.338C51.9408 18.338 53.6238 19.493 54.3828 21.539H45.0768Z" fill="white"/>
        <path d="M61.0405 32H65.0005V22.232C65.0005 20.021 66.7495 18.536 69.3895 18.536H70.7425V15.5C70.2805 15.302 69.5875 15.17 68.9935 15.17C67.4755 15.17 65.8585 16.094 65.0005 17.447V15.5H61.0405V32Z" fill="white"/>
        <path d="M84.3109 32.462C86.2249 32.462 88.5019 31.901 90.0529 31.076C91.2079 31.901 92.7589 32.462 93.9469 32.462C94.4749 32.462 95.4319 32.264 96.3229 32V28.634C94.7719 28.634 93.6169 28.502 93.4189 28.271C95.0359 26.258 96.0919 23.222 96.0919 20.648C96.0919 13.586 91.3729 8.89999 84.3109 8.89999C77.2489 8.89999 72.5299 13.586 72.5299 20.648C72.5299 27.743 77.2489 32.462 84.3109 32.462ZM84.3109 28.502C79.8889 28.502 76.9519 25.367 76.9519 20.648C76.9519 15.962 79.8889 12.86 84.3109 12.86C88.7329 12.86 91.6699 15.962 91.6699 20.648C91.6699 25.367 88.7329 28.502 84.3109 28.502Z" fill="#FF6DB1"/>
        </svg>
        

        }
        {theme=="icon" &&
        <svg width="49" height="42" viewBox="0 0 49 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6161 34.396C17.3911 34.396 21.2521 30.832 21.2521 25.486V11.362H16.8301V25.486C16.8301 28.456 14.7511 30.436 11.6161 30.436C8.44806 30.436 6.33606 28.456 6.33606 25.486V11.362H1.91406V25.486C1.91406 30.832 5.80806 34.396 11.6161 34.396Z" fill="black"/>
        <path d="M35.5521 34.462C37.4661 34.462 39.7431 33.901 41.2941 33.076C42.4491 33.901 44.0001 34.462 45.1881 34.462C45.7161 34.462 46.6731 34.264 47.5641 34V30.634C46.0131 30.634 44.8581 30.502 44.6601 30.271C46.2771 28.258 47.3331 25.222 47.3331 22.648C47.3331 15.586 42.6141 10.9 35.5521 10.9C28.4901 10.9 23.7711 15.586 23.7711 22.648C23.7711 29.743 28.4901 34.462 35.5521 34.462ZM35.5521 30.502C31.1301 30.502 28.1931 27.367 28.1931 22.648C28.1931 17.962 31.1301 14.86 35.5521 14.86C39.9741 14.86 42.9111 17.962 42.9111 22.648C42.9111 27.367 39.9741 30.502 35.5521 30.502Z" fill="#FF6DB1"/>
        </svg>
        }

    </div>
    );
};