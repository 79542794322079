import React from "react";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";

export const ResearcherSlider = React.memo(() => {
    return (
        <InformationTilesBox
            tiles={[
                {
                    Icon: "group",
                    colorClass: "secondary-purple-200",
                    title: "Easily recruit participants",
                    subtitle:
                        "Choose from a variety of demographics to find the ideal participants for your study.",
                },
                {
                    Icon: "user-question",
                    colorClass: "pink-200-svg",
                    title: "Screening questions to reach your audience",
                    subtitle:
                        "Add up to 3 screening questions when recruiting from the UserQ panel to reach the right audience.",
                },
                {
                    Icon: "credit",
                    colorClass: "tertiary-gold-200",
                    title: "You pay only for the good response",
                    subtitle:
                        "By reporting participants who didn’t comply with the quality guidelines, you will receive a refund of the credits you spent.",
                },

                {
                    Icon: "share",
                    colorClass: "success-green-200",
                    title: "Share the insights with your team",
                    subtitle:
                        "Download or share a live link to your results instantly.",
                },
                {
                    Icon: "support",
                    colorClass: "secondary-purple-200",
                    title: "UserQ team is here to support you",
                    subtitle:
                        "Access the live chat from your dashboard to receive real-time support from the team.",
                },
            ]}
        />
    );
});
