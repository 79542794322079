import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { NPS } from "./nps";

export default function NpsModal({
    openModal,
    close,
    role
}) {
    const closeModal = () => {
        close();
    };

    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap nps-modal"
            onHide={() => {
                closeModal();
            }}
        >
            <Modal.Body className="nps-modal-body">
                <NPS role={role} onHide={() => {closeModal();}}/>
            </Modal.Body>
        </Modal>
    );
}
