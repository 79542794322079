import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import "./ImageLibraryModal.css";
import { FilterButtons } from "../../../themes/userq/FilterButtons/FilterButtons";
import { fetchPhotosLibrary,uploadPhoto,deletePhoto} from "../../../../../src/services/photoLibrary";
import { LoadingIcon } from "../../../loader/loadingIcon";
import { ProgressBar } from "../../../themes/userq/ProgressBar/progress_bar";

export default function ImageLibraryModal({ open, close, cssClass, body,onSelectPhoto }) {
    
    const navigate = useNavigate();
    const [filterOption, setFilterOption] = useState("All");
    const [images, setImages] = useState([]);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [uploadedImages, setUploadedImages] = useState([]); 
    const [isLoading, setIsLoading] = useState(true);
    const [uploadPerc, setUploadPerc] = useState(0);
    const [isuploading,setIsuploading] = useState(false);
    const [isphotoSelected,setIsPhotoSelected] = useState(false);
    
    

    useEffect(() => {
    
        setIsLoading(true); 

        fetchImages();
        
    }, [filterOption]);

    const fetchImages = async () => {

        var token = ReactSession.get("token");

        try {
            if (filterOption.toLowerCase() !== "uploads") {
                const result = await fetchPhotosLibrary(token, 'all');
                if (result.success && Array.isArray(result.photos)) {
                    const formattedImages = result.photos.map((photo) => ({
                        id: photo.id,
                        category: photo.category,
                        src: photo.image_path,
                        isLocal: false,
                    }));
                    setImages(formattedImages);
                } else {
                    console.error("Failed to fetch images:", result.message);
                }
            } else if (filterOption.toLowerCase() === "uploads") {
                const result = await fetchPhotosLibrary(token, 'uploads');
                if (result.success && Array.isArray(result.photos)) {
                    const formattedUploads = result.photos.map((photo) => ({
                        id: photo.id,
                        category: "uploads",
                        src: photo.image_path,
                        isLocal: false,
                    }));
                    setUploadedImages(formattedUploads);
                } else {
                    console.error("Failed to fetch uploads:", result.message);
                }
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setIsLoading(false); // Stop loading after fetch is done
        }
    };

    const selectPhoto = () => {
        if(filterOption.toLowerCase() !== "uploads"){
            var selectedImage;
            selectedImage = images.find((image) => image.id === selectedImageId);
        }else{
            selectedImage = uploadedImages.find((image) => image.id === selectedImageId);  
        }
        if (selectedImage) {
        const imageSrc = selectedImage.isLocal
            ? selectedImage.src
            : `${process.env.REACT_APP_IMG_URL}${selectedImage.src}`;
        
        // Call the parent's callback to update the image source
        onSelectPhoto(imageSrc,selectedImageId);
        }
    };
    const wrapperRef = useRef(null);

    const filterImages = (category) => {
        if (category.toLowerCase() === "all") {
            return images.sort((a, b) => b.id - a.id);
        } else if (category.toLowerCase() === "uploads") {
            return uploadedImages; // Return only uploaded images for the "Uploads" tab
        } else {
            return images.filter((image) => image.category === category.toLowerCase());
        }
    };

    const filteredImages = filterImages(filterOption);

    const handleFileChange = async (event) => { 
        const file = event.target.files[0];
        
        var token = ReactSession.get("token");

        if (file) {
            setIsuploading(true);
            try {
                await uploadPhoto(file, token, (progress) => {
                    setUploadPerc(progress);  // Set the progress in real-time
                });

                // After a successful upload, refresh the uploaded images
                const result = await fetchPhotosLibrary(token, 'uploads');
                if (result.success && Array.isArray(result.photos)) {
                    const formattedUploads = result.photos.map((photo) => ({
                        id: photo.id,
                        category: "uploads",
                        src: photo.image_path,
                        isLocal: false,
                    }));
                    setUploadedImages(formattedUploads);
                }
            } catch (error) {
                console.error('Error uploading the image:', error);
            } finally {
                setIsuploading(false);
                setUploadPerc(0);  // Reset the progress
                event.target.value = '';  // Clear the input
            }
        }
    
};



    const handleUploadClick = () => {
        document.getElementById("file-upload-input").click();

    };
   const handleImgClick = (id) => {
    
        setSelectedImageId(id); 
        setIsPhotoSelected(true);
        
    };  
const handleDeleteImage = async (id) => {
    setIsLoading(true); // Start loading before deleting

    try {
        const token = ReactSession.get('token');
        const result = await deletePhoto(id, token);

        if (result.success) {
            if (filterOption.toLowerCase() === "uploads") {
                // Update uploadedImages when in "Uploads" tab
                setUploadedImages((prevImages) => prevImages.filter((image) => image.id !== id));
            } else {
                // Update images when in "All" or other filter tabs
                setImages((prevImages) => prevImages.filter((image) => image.id !== id));
            }
        } else {
            console.error("Failed to delete image:", result.message);
        }
    } catch (error) {
        console.error("Error deleting image:", error);
    } finally {
        setIsLoading(false); // Stop loading after fetch is done
    }
};


    const renderImageGallery = ({ images, isLoading }) => {
        return (
            <>
                {isLoading ? (
                    // Display the loading wrapper when isLoading is true
                <div className="welcome-loader-inner fetch-loader">
                        
                        <LoadingIcon />
                        
                    </div>
                ) : (
                    // Display the image library when isLoading is false
                    <div className="img-lib-scroll-wrap">
                        <div className="image-gallery">
                            {images.length > 0 ? (
                                images.map((image) => (
                                    <div
                                        className={`image_lib_hold ${
                                            selectedImageId === image.id ? "active" : ""
                                        }`}
                                        onClick={() => handleImgClick(image.id)}
                                    >
                                        <img
                                            src={
                                                image.isLocal
                                                    ? image.src
                                                    : `${process.env.REACT_APP_IMG_URL}${image.src}`
                                            }
                                            alt={image.category}
                                        />
                                        {image.category === "uploads" && (
                                            <div
                                                className="delete-img "
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteImage(image.id);
                                                }}
                                            >
                                                <Icon value={"delete"} hover={true} size={"small"} />
                                            </div>
                                        )}
                                    </div>
                                ))
                                
                            ) : (
                                <div className="no-uploaded-library-data text-center">
                                    {!isuploading &&
                                    (
                                        <div className="no-img-content">
                                    <Icon value={"attention"} size={"large"} />

                                    <Text
                                        type={"body-text-1"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-color mt-20 mb-20"}
                                    >
                                        You haven’t uploaded any image yet! Create your own library
                                        by uploading images to UserQ.
                                    </Text>

                                    <Text
                                        type="body-text-3"
                                        fontWeight="medium-font"
                                        cssClasses="gray-color"
                                    >
                                        Recommended size 1000 * 1400px
                                    </Text>
                                    </div>
                                    )}
                                </div>
                            )}
                            {isuploading &&
                            (
                                <>
                            <div className="image_lib_hold ">
                                <ProgressBar percentage={uploadPerc}/>
                            </div>
                                </>
                            )
                            }
                            
                        </div>
                    </div>
                )}
            </>
        );
    };


    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName.toLowerCase() === "div") {
                        close();
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={open}
            className={`fade custom-modal-wrap image-library-modal ${cssClass}`}
        >
            <Modal.Body ref={wrapperRef}>
                <input
                    type="file"
                    id="file-upload-input"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    name ="photo"
                />
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h3">Photo library</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={close}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>
                    <Text
                        type="body-text-2"
                        fontWeight="medium-font"
                        cssClasses="gray-color"
                    >
                        Select from our library or upload your own photo
                        (Recommended size 1000px * 1400px)
                    </Text>
                    <div className="image-filter-wrap mt-32 mb-32 text-center">
                        <FilterButtons
                            options={[
                                "All",
                                "Light",
                                "Dark",
                                "Basic",
                                "Uploads",
                            ]}
                            selected={filterOption}
                            onChange={(item) => setFilterOption(item)}
                        />
                    </div>
                    {renderImageGallery({images:filteredImages, isLoading:isLoading})}
                    <div className="lib-modal-button-wrap d-flex align-items-center flex-wrap mt-32 justify-content-center">
                        {filteredImages.length > 0 ? (
                            <>
                                <Button
                                    type="primary"
                                    size="large"
                                    label="Select photo"
                                    onClick={selectPhoto}
                                    state={isphotoSelected?'active':'disabled'}
                                />
                                <Button
                                    type="secondary"
                                    size="large"
                                    iconLeft={
                                        <Icon
                                            value="upload"
                                            colorClass="gray-900-svg"
                                        />
                                    }
                                    label="Upload photo"
                                    onClick={handleUploadClick}
                                />
                            </>
                        ) : (
                            <Button
                                type="secondary"
                                size="large"
                                iconLeft={
                                    <Icon
                                        value="upload"
                                        colorClass="gray-900-svg"
                                    />
                                }
                                label="Upload photo"
                                onClick={handleUploadClick}
                            />
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
