import React, { useState, useEffect } from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import { preventClick } from '../../lib/helpers';
import { Modal } from 'react-bootstrap';
import InputField from '../layouts/elements/fields/input';
import toast from 'react-hot-toast';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession';
// import { FullPageLoadingIcon } from './../loader/full_page_loader_lcon_New';
import { FullPageLoadingIcon } from '../loader/full_page_loader_lcon_New';
import { showToast } from '../../lib/helpers';

export default function CancelPayout() {

    let { payoutid } = useParams();

    const [formValues, setFormValues] = useState({payoutid: payoutid});
    const [isLoading, setLoading] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        cancelPayout()
        document.title = process.env.REACT_APP_NAME+" - Cancel Payout";
    }, []);

    const cancelPayout = () => {
        
        setLoading(true);

        var body = formValues;

        
        fetch(process.env.REACT_APP_API_END_POINT+'cancel-payout', {
            method: 'POST',
            body: JSON.stringify(formValues),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);
            if(response.success){
              showToast(response.message,"success");
            } else {
              showToast(response.message,"error");
            }
        });
    }
    
    return (
        <div>
            {(isLoading==true) &&
            <FullPageLoadingIcon/> 
            }  
        </div>
    )
}