import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { ReactSession } from "../../../lib/secure_reactsession";
import { getTestData } from "../../../services/test";
import { saveTest } from "../../../services/test";
import { encryptId, renderTestTitleCreate } from "../../../lib/helpers";
import toast from "react-hot-toast";
import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { LayoutResearcher } from './../../themes/userq/Layouts/layout_researcher';
import { Text } from './../../themes/userq/Text/Text';
import { Input } from './../../themes/userq/Input/Input';
import { TextArea } from './../../themes/userq/TextArea/TextArea';
import { Icon } from './../../themes/userq/Icon/Icon';
import { Button } from './../../themes/userq/Button/Button';
import Header from './components/header';
import { LoadingIcon } from './../../loader/loadingIconNew';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import { settings } from "../../../data/settings";
import { Helmet } from "react-helmet";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";
import ImageLibraryModal from "../../themes/userq/ImageLibraryModal/ImageLibraryModal.js";


export function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    return 'other';
}

export function matchExtensions(extns, file) {

    return extns.indexOf(file.name.split('.').pop()) != -1 ? true : false;
}

export default function Study() {

    ReactSession.setStoreType("localStorage");
    let { test_id } = useParams();
    const navigate = useNavigate();

    const { updateTestLength, getTestLength, allowEditAccess } = useTestBuilderData();

    const [test, setTest] = useState("");
    const [lastSaved, setLastSaved] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errorMsg, setErrorMessage] = useState(null);
    const [image, setImage] = useState(null);
     const [imageId, setImageId] = useState(null);
    const [language, setLanguage] = useState("en");
    const [previewUrl, setPreviewUrl] = useState("");
    const [testImage, setTestImage] = useState(null);
    const [imageDeleted, setImageDeleted] = useState(false);
    const lang = useRef("en");
    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });
    const [stepperError, setStepperError] = useState(false);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWelcomeImage, setIsWelcomeImage] = useState(true);
    const handleImageLibraryModalClose = () => setIsModalOpen(false);
    const [previewTitle,setPreviewTitle] = useState('');
    const [previewText,setpreviewText] = useState('');
    const handleTitleChange = (e) =>{
        var value = e.target.value
        setPreviewTitle(value);
    }
    const handleTextChange = (e) =>{
        var value = e.target.value
        setpreviewText(value);
    }
    const handleRemoveImage = () =>{
    setIsWelcomeImage(false);
    removeImage();
        }
    const [selectedImageSrc, setSelectedImageSrc] = useState(`${process.env.REACT_APP_URL}img/welcome-img.png`);

    const handleSelectPhoto = (imageSrc,imageID) => {
        setSelectedImageSrc(imageSrc); 
        setIsModalOpen(false); 
        setImageId(imageID);
         setIsWelcomeImage(true);
          setTestImage(null);
    };

    const [formValues, setFormValues] = useState({
        test_name: "",
        message: "",
    });

    const [formErrors, setFormErrors] = useState({
        image: null,
        test_name: null,
        message: null,
        error_class: null,
    });

    const [searchParams] = useSearchParams()

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    // const handleFile = (file) => {

    //     resetFormErrors();
    //     //let's grab the image file
    //     var error = false;

    //     var fileType = getFileType(file);

    //     if (fileType == "image" && file.size / 1024 > 2048) {
    //         setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });
    //         error = true;
    //     }
    //     if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {
    //         setFormErrors({ ...formErrors, image: "Only image files are accepted with extensions  jpg, jpeg, gif and png.", "error_class": "input_error" });
    //         error = true;
    //     }
    //     if (fileType != "image") {
    //         setFormErrors({ ...formErrors, image: "Only image files are accepted.", "error_class": "input_error" });
    //         error = true;
    //     }
    //     if (!error) {
    //         setImage(file);
    //         setPreviewUrl(URL.createObjectURL(file));
    //     }

    // };

    // const handleOnDragOver = (event) => {
    //     event.preventDefault();
    // };
    // const handleOnDrop = (event) => {
    //     //prevent the browser from opening the image
    //     event.preventDefault();
    //     event.stopPropagation();
    //     //let's grab the image file
    //     let imageFile = event.dataTransfer.files[0];
    //     handleFile(imageFile);
    // };
    const removeImage = (e) => {
        setImageId(null);
        setPreviewUrl(null);
        setTestImage(null);
        setImageDeleted(true);
        // console.log('removed');
    };
    // const handleFileInput = (event) => {
    //     //prevent the browser from opening the image
    //     event.preventDefault();
    //     event.stopPropagation();
    //     //let's grab the image file
    //     let imageFile = event.target.files[0];
    //     handleFile(imageFile);
    // };
    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            setStepperError(false);
        };
    };

    const getTest = () => {
        let token = ReactSession.get("token");

        
        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if(response.test.workspace_project){
                    if(response.workspace_project_team_member){
                        if(response.workspace_project_team_member.status!="active"){
                            navigate("/wsp/"+response.test.workspace_project.workspace_id+"/p/"+response.test.workspace_project.id);
                        }
                    }
                }
                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/wsp/"+ response.test.workspace_id+"/p/" + response.test.workspace_project_id);
                    }
                }
                allowEditAccess({test_id:test_id, workspace_id: response.test.workspace_id, workspace_project_id: response.test.workspace_project_id});
                
                getTestLength(test_id);

                ReactSession.set("methodology", response.test.methodology);

                redirectToStep({
                    test_id: test_id,
                    current_step: 1,
                    saved_step: response.test.saved_step,
                    navigate: navigate,
                    test: test,
                });

                setTest(response.test);
                lang.current = response.test.language;
                // console.log(response.test)
                setLastSaved(moment(response.test.updated_at));
                const {
                    welcome_title,
                    welcome_description,
                    language,
                    image,
                    is_intro_img_removed
                } = response.test;

                setFormValues((oldValues) => ({
                    ...oldValues,
                    test_name: welcome_title,
                    message: welcome_description,
                }));
                setLanguage(language);

                setTestImage(image);
                setPreviewTitle(welcome_title);
                setpreviewText(welcome_description);

                 if(is_intro_img_removed == 1 && image == null){
                     setIsWelcomeImage(false);
                 }

                if (searchParams.get("error") == "true") {
                    validateFullForm(welcome_title,welcome_description,false);
                }
            } else {
                if(response.message == "Project not found.") {
                    navigate("/404");
                } else {
                    showError(response.setErrorMessage);
                }
            }
        });
    };

    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Welcome";
        getTest();
        if (searchParams.get("error") == "true") {
            setStepperError(true);
        }
        ReactSession.set("new_target_id", null);

        updateTestLength(null);
        
    }, []);

    const saveData = () => {
        const token = ReactSession.get("token");
        var data = new FormData();
        setIsSaved({ 'status': 'loading', 'message': '' });
        data.append("test_id", test_id);
        data.append("encrypted_test_id", encryptId(test_id));
        data.append("title", formValues.test_name);
        data.append("description", formValues.message);
        if (imageId) {
            data.append("image_id", imageId);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }

        saveTest(data, token).then((response) => {
            if (response.success) {
                setIsSaved({ 'status': 'success', 'message': '' });
                // setLastSaved(moment.now());
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
                // console.log(response);
            }
        });
    };

    useEffect(() => {
        //  This will run every 30 seconds
        if (test.status == "draft") {
            saveData();
        }
    }, [
        imageId,
        imageDeleted,
        testImage
    ]);

    const validateFullForm = (test_name,message,showToast) => {
        var error = false;
        var form_errors = resetFormErrors();
        var firsterrorid = null;

        if (test_name == '') {

            form_errors = {
                ...form_errors,
                test_name: "A page title is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "title" : firsterrorid;

            setFormErrors(form_errors);
            if(showToast){
            showError("The welcome page cannot be empty! Please enter a title and a description.");
            }
        }
        if(message == ''){
            form_errors = {
                ...form_errors,
                message: "A welcome message is required",
                error_class: "input_error"
            };
            
            firsterrorid = firsterrorid == null ? "message" : firsterrorid;

            setFormErrors(form_errors);

            if(!error && showToast){
                showError("The welcome page cannot be empty! Please enter a title and a description.");
            }
            error = true;
        }
        if(error){
            localStorage.setItem("stepper_error", "The welcome page cannot be empty! Please enter a title and a description.");
            setStepperError(true);
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
        
        
        return error;
        
    }


    const introductionsubmitHandler = (saveUsingNavigation) => {
        var error=false
        if (!saveUsingNavigation) {
        error = validateFullForm(formValues.test_name,formValues.message,true);
        }
        if (!error) {
        const token = ReactSession.get("token");
        setFormLoading(true);
        setIsSaved({ 'status': 'loading', 'message': '' });
        var data = new FormData();

        data.append("test_id", test_id);
        data.append("encrypted_test_id", encryptId(test_id));
        data.append("title", formValues.test_name);
        data.append("description", formValues.message);

         // Append image_id instead of the actual image file
        if (imageId) {
            data.append("image_id", imageId);  // Send image_id
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        if (!saveUsingNavigation) {
            data.append("saved_step", 1);
        }

        saveTest(data, token).then((response) => {
            setFormLoading(false);
            if (response.success) {
                setIsSaved({ 'status': 'success', 'message': '' });
                if (!saveUsingNavigation) {
                   
                    // console.log(ReactSession.get("user") )
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step1',
                        'type': test.methodology,
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });


                    if (test.methodology == "Tree Test") {
                        navigate("/r/test/" + test_id + "/tree-test/");
                    } else if (test.methodology == "Survey") {
                        navigate("/r/test/" + test_id + "/survey/");
                    } else if (test.methodology == "Preference Test") {
                        navigate("/r/test/" + test_id + "/preference-test/");
                    } else if (test.methodology == "Card Sorting") {
                        navigate("/r/test/" + test_id + "/card-sorting-test/");
                    } else if (test.methodology == "Five Seconds Test") {
                        navigate("/r/test/" + test_id + "/five-seconds-test/");
                    } else if (test.methodology == "Prototype test") {
                        navigate("/r/test/" + test_id + "/prototype-test/");
                    } else if (test.methodology == "First click") {
                        navigate("/r/test/" + test_id + "/first-click/");
                    }
                }
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
                if (response.message == "The image must be an image.") {
                    setImage(null);
                    setPreviewUrl("");
                    setFormErrors({ ...formErrors, image: "The image must be an image.", "error_class": "input_error" });
                } else {
                    showError(response.message);
                }
            }
        });
    }
    };

    return (
        <LayoutResearcher wrapClass={"create-test-data-wrapper"} openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}>
            <Helmet>
                <title>Create {test && test.methodology ? renderTestTitleCreate(test.methodology):"test"}  - step 1  | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header
                    test={test}
                    isLoading={isLoading}
                    isSaved={isSaved}
                />
                <div className="three-column-layout-body">
                    <div className="three-column-layout-left">
                        <Stepper
                            test={test}
                            completed_step={0}
                            stepCallback={() => {
                                introductionsubmitHandler(true);
                            }}
                            error={stepperError}
                        />
                    </div>
                    {isLoading &&
                        <div className="test-section-loader"><LoadingIcon /></div>
                    }
                    {!isLoading && (
                        <>
                            <div className="three-column-layout-center">
                                <div className="test-welcome-page-wrap">
                                    <div className={`test-welcome-inner-data-hold ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
                                        <div className="test-welcome-page-left">
                                            <div className="test-welcome-top-head">
                                                <Text type='subtitle-1' fontWeight='medium-font' children="Welcome page" />
                                                <Text type='body-text-2' fontWeight='medium-font' children="To help participants, personalise your welcome page with a title, image and a custom message." />
                                            </div>
                                            <div className="test-welcome-form-wrap">
                                                <Input
                                                    label="Page title"
                                                    onChange={set("test_name")}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                test_name: "",
                                                            }));
                                                        }, 1000);
                                                    }}
                                                    value={formValues.test_name}
                                                    maxLength="45"
                                                    error={formErrors.test_name}
                                                    error_class={formErrors.error_class}
                                                    id="title"
                                                    clearField={false}
                                                    required={true}
                                                    onBlur={() => saveData()}
                                                    rtl={test.language == "ar" ? true : false}
                                                    onInput={handleTitleChange}
                                                />
                                                <TextArea
                                                    id={"message"}
                                                    autosize={true}
                                                    value={formValues.message}
                                                    onChange={set("message")}
                                                    maxLength="300"
                                                    label="Welcome message"
                                                    language={language}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                message: "",
                                                            }));
                                                        }, 1000);
                                                    }}
                                                    onBlur={() => saveData()}
                                                    labelfont="english-font"
                                                    wrapClass="tester-text-area"
                                                    error={formErrors.message}
                                                    error_class={formErrors.error_class}
                                                    onInput={handleTextChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="test-welcome-right">
                                            <div className="test-welcome-image-wrap">
                                               
                                        {isWelcomeImage ? ( 
                                        <>
                                                <div
                                                    className={`project-modal-img`}
                                                >

                                                    {!previewUrl &&
                                                        (testImage ? (
                                                            <>
                                                                <div className="uploaded-project-img">
                                                                    <img
                                                                        src={process.env.REACT_APP_IMG_URL+testImage}
                                                                        alt="img"
                                                                    />
                                                                </div>
                                                                
                                                            </>
                                                        ) : (

                                                            <div
                                                                className="upload-project-img"
                                                                
                                                            >
                                                                <img
                                                                    className="upload-image-placeholder"
                                                                    src={selectedImageSrc}
                                                                    alt="img"
                                                                />


                                                            </div>
                                                        ))}
                                                    {previewUrl && (
                                                        <>
                                                            <div className="uploaded-project-img">
                                                                <img src={previewUrl} alt="img" />
                                                            </div>
                                        
                                                        </>
                                                    )}

                                                    {formErrors.image != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.image}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="change-welcome-image">
                                                  
                                                    <div className="row justify-content-between px-3">
                                                        <Button
                                            type={"ghost"}
                                            label={"Change Photo"}
                                            size={"small"}
                                            
                                            onClick={()=>{
                                                setIsModalOpen({...isModalOpen, open: true});
                                            }}
                                        />

                                         <Button
                                            type={"ghost"}
                                            label={"Remove Photo"}
                                            size={"small"}
                                            iconLeft={
                                                <Icon value="delete" colorClass="gray-900-svg" size={"small"} />
                                            }
                                            onClick={handleRemoveImage}
                                        />
                                                    </div>
                                                </div>
                                                 </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`project-modal-img`}
                                                >

                                                            <div
                                                                className="no-img-bg d-flex justify-content-center align-items-center"
                                                            
                                                            >
                                                                <div className="pb-2 px-5">
                                                                <img src={
                                                                        process.env.REACT_APP_URL +
                                                                        "img/no-img.png" 
                                                                    }  alt="" className="d-block mx-auto"/>
                                             <Text type={'body-text-3'} cssClasses={'medium-font mt-2 text-center'}>No photo will be shown on the welcome page</Text>
                                             </div>
                                                            </div>
                                                             </div>
                                                             <div className="change-welcome-image">
                                            
                                                   
                                                    <div className="row justify-content-between px-3">
                                                        <Button
                                            type={"ghost"}
                                            label={"Add Photo"}
                                            size={"small"}
                                            
                                             onClick={()=>{
                                                setIsModalOpen({...isModalOpen, open: true});
                                            }}
                                        />

                                         <Button
                                            type={"ghost"}
                                            label={"Remove Photo"}
                                            size={"small"}
                                            iconLeft={
                                                <Icon value="delete" colorClass="gray-900-svg" size={"small"} />
                                            }
                                            state={'disabled'}
                                        />
                                                    </div>
                                                   
                                                </div>
                                               
                                                </>
                                        )
                                                 }
                                            </div>
                                           

                                        </div>
                                    </div>
                                    {/* <div>
                                        <CountryDropdown 
                                        onChange={(e)=>{
                                            var countries=[];
                                            e.forEach(element => {
                                                countries.push(element.value);
                                                setSelectedCounties([...countries]);
                                                
                                            });
                                        }} 
                                        value={selectedCountries} 
                                        isMulti={true}
                                        onRemove={(i)=>{
                                            const list = [...selectedCountries];
                                            list.splice(i, 1);
                                            setSelectedCounties(list);
                                        }}
                                        type="nationality"
                                        />
                                        <MultiSelectDropdown
                                        onChange={(e)=>{
                                            var countries=[];
                                            e.forEach(element => {
                                                countries.push(element.value);
                                                setSelectedCounties([...countries]);
                                                
                                            });
                                        }} 
                                        value={selectedCountries} 
                                        isMulti={true}
                                        onRemove={(i)=>{
                                            const list = [...selectedCountries];
                                            list.splice(i, 1);
                                            setSelectedCounties(list);
                                        }}
                                        options={countries_other}
                                        />
                                    </div> */}
                                    <div className="btns-wrap-bottom none-for-mobile">
                                        <Button
                                            type={"ghost"}
                                            label={"Back to dashboard"}
                                            size={"large"}
                                            iconLeft={
                                                <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                            onClick={() => (navigate("/dashboard"))}
                                        />


                                        <Button
                                            onClick={() => introductionsubmitHandler(false)}
                                            iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                            label="Next"
                                            size={"large"}
                                            state={(formValues.test_name==""||formValues.message=="")?"disabled":""}
                                        />
                                    </div>

                                    <div className="btns-wrap-bottom none-for-desktop">
                                        <Button
                                            type={"ghost"}
                                            label={"Back"}
                                            size={"large"}
                                            iconLeft={
                                                <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                        />


                                        <Button
                                            onClick={() => introductionsubmitHandler(false)}
                                            iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                            label="Next"
                                            size={"large"}
                                            state={(formValues.test_name==""||formValues.message=="")?"disabled":""}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="three-column-layout-right">
                                <div className="welcome-test-preview-area">
                                    <Text
                                        type={'overline'}
                                        children={'Preview'}
                                    />
                                    {/* <div className="welcome-test-preview-area-inner">
                                        {test.language == "ar" ?
                                            <img src={
                                                process.env.REACT_APP_URL + "img/welcomepreviewarabic.svg"}
                                                alt="img" />
                                            :
                                            <img src={
                                                process.env.REACT_APP_URL + "img/welcomepreview.svg"}
                                                alt="img" />}
                                    </div> */}
                                   
                                    {/* Dynamic image preview */}
                                    
                                    <div className="welcome-test-preview-area-inner">
                                       <div className="test-preview-container d-flex">
                                        <div className="col-8 p-0 pr-2 text-left">
                                            {/* {test.language == "ar" ?
                                               <img src={process.env.REACT_APP_URL+"img/staticpreviewarabic.png"} alt="img"/>:
                                                <img src={process.env.REACT_APP_URL+"img/staticpreview.png"} alt="img" />
                                            } */}
                                             <Text type='body-text-2' fontWeight='semibold-font' children={previewTitle}  cssClasses={'one-line mb-2'}/>
                                              <Text type='caption' children={previewText} cssClasses={'four-line pb-1 '} />   
                                               <Button
                                                type={"primary"}
                                                label={"Get Started"}
                                                size={"medium"}
                                                cssclass={"mt-2"} />
                                        </div>
                                         <div className="col-4 p-0 left-preview-img">
                                         {isWelcomeImage ? ( 
                                            <>
                                             {!previewUrl &&
                                                (testImage ? (
                                                    <img src={process.env.REACT_APP_IMG_URL+testImage} alt="img"/>           
                                                        ):(
                                                             <img className="upload-image-placeholder" src={selectedImageSrc} alt="img" />
                                                            ))}
                                                        {previewUrl && (
                                                                <img src={previewUrl} alt="img" />
                                                    )}
                                        </>
                                                       ):(

                                                    <>
                                                      <div></div>
                                                    </>
                                               )}
                                           </div>
                                       </div>
                                    </div>
                                
                                </div>

                                <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'>
                                    <Icon
                                        value={"support"}
                                        colorClass={"gray-50-svg"}
                                        size={"medium"}
                                    />
                                    Support
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
            
            {isModalOpen &&
            <ImageLibraryModal 
                open={isModalOpen} 
                close={handleImageLibraryModalClose} 
                cssClass="" 
                onSelectPhoto={handleSelectPhoto}
            />
            }
        </LayoutResearcher>
    );
}
