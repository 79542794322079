import React,{useState} from "react";
import {  Link,useNavigate } from "react-router-dom";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
import { HamburgerMenu } from "../HamburgerMenu/HamburgerMenu";
import PropTypes from 'prop-types';
import Skeleton from "react-loading-skeleton";
import {Button} from "../Button/Button";

export const TesterTestCard = ({
                                 test,
                                   testName,
                                   language,
                                   youWillGet,
                                   duration,
                                   testUrl,
                                   isLoading
                             }) => {

    const navigate = useNavigate();


    return (
        <div className={`tester-test-cards test-list-repeat-wrap `}>
            <div className={`test-list-data ${(!isLoading)?'':'loading-cards'}`} onClick={testUrl}>
                <div className={"all-test"}>
                    {isLoading ?
                        <>
                            <div
                                className="test-list-head d-flex align-items-center justify-content-between position-relative">
                                <h4 className="heading h4">
                                    <Skeleton width={200} height={20} style={{borderRadius:'30px'}} />
                                </h4>

                            </div>
                            <div className="test-list-mid-data position-relative">
                                <div className={"card-tag-wrap d-flex justify-content-between align-items-center"}>
                                    <div className="tag-wrap">
                                        <Skeleton width={66} height={23} style={{borderRadius:'5px',marginLeft:'8px'}}/>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div
                                className="test-list-head d-flex align-items-center justify-content-between position-relative">
                                <Text type={"body-text-1"} fontWeight={"semibold-font"} cssClasses={(language === 'Arabic')?'arabic-font':''}>
                                    {testName}
                                </Text>

                            </div>
                            <div className="test-list-mid-data position-relative">
                                <div className={"card-tag-wrap d-flex justify-content-between align-items-center"}>
                                    <div className="tag-wrap">
                                        <span className={`tag ${(language === 'Arabic')?'arabic-font':''}`}>
                                            {language === 'Arabic'?'عربى':language}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div className="test-footer-data d-flex justify-content-between align-items-end position-relative">
                    <div className="test-footer-lhs">

                        {isLoading ?
                            <>
                                <div className="test-time-data d-flex">
                                    <div className="test-time-data-repeat participant-data">
                                        <Skeleton width={53} height={18} style={{borderRadius:'15px'}} />


                                        <Skeleton width={58} height={26} style={{borderRadius:'30px'}} />

                                    </div>
                                    <div className="test-time-data-repeat">
                                        <Skeleton width={50} height={18} style={{borderRadius:'15px'}} />

                                        <Skeleton width={87} height={26} style={{borderRadius:'30px'}} />
                                    </div>


                                    <div className="test-time-data-repeat">
                                        <Skeleton width={113} height={33} style={{borderRadius:'50px',position:'relative',top:'30px'}} />
                                    </div>

                                </div>
                            </>
                            :
                            <>
                                <div className="test-time-data d-flex">
                                    <div className="test-time-data-repeat participant-data">
                                        <Text type='caption' children={'You’ll get'}/>

                                        {/*<Text type='subtitle-2' fontWeight={'semi-bold-font'}
                                              children={`$${youWillGet}`}/>*/}
                                        <Text type='subtitle-2' fontWeight={'semi-bold-font'}
                                              children={`$${test && test.tester_reward?test.tester_reward.toFixed(2):youWillGet}`}/>

                                    </div>
                                    <div className="test-time-data-repeat">
                                        <Text type='caption' children={'Duration'}/>
                                        <Text type='subtitle-2' fontWeight={'semi-bold-font'} children={test && test.time_label?test.time_label:duration}/>
                                    </div>


                                    <div className="test-time-data-repeat">
                                        <Button
                                            size={'small'}
                                            iconRight={<Icon size={"medium"} colorClass={"gray-50-svg"} value={'forward-arrow'}/>}
                                            label={'Take test'}
                                            onClick={testUrl}
                                        />
                                    </div>

                                </div>
                            </>
                        }



                    </div>

                </div>
            </div>
        </div>
    );
};

TesterTestCard.propTypes = {
    testName: PropTypes.string,
    youWillGet:PropTypes.any,
    testUrl:PropTypes.any,
    duration:PropTypes.string,
    language: PropTypes.oneOf(["English","Arabic"])
};