import { ReactSession } from "../../../../lib/secure_reactsession";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { reportIssueAnswer } from "../../../../services/researcher_test_results";
import InputField from "../../../layouts/elements/fields/input";
import { LoadingIcon } from "../../../loader/loadingIconNew";
import { Button } from "../../../themes/userq/Button/Button";
import { Input } from "../../../themes/userq/Input/Input";
import { showToast } from "../../../../lib/helpers";


export default function ReportAnswer({ openModal, closeModal, test_id }) {

    const [isReportAnswerLoading, setReportAnswerLoading] = useState(false);
    const [formValues, setFormValues] = useState({ title: '' });
    const [formErrors, setFormErrors] = useState({ error_msg: '', error_class: '' });

    useEffect(function () {
        setFormValues({ title: '' })
    }, [openModal])
    const reportIssueHandler = () => {

        setFormErrors({ error_msg: '', error_class: '' });

        if (formValues.title === '') {
            setFormErrors({ 'error_msg': 'Required field', 'error_class': "input_error" });
            return false;
        }
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test_id);

        if (openModal.type == "methodology") {
            data.append("result_id", openModal.answer_id);
        } else {
            data.append("answer_id", openModal.answer_id);
        }
        data.append("comment", formValues.title);

        setReportAnswerLoading(true);
        reportIssueAnswer(data, token).then((response) => {
            setReportAnswerLoading(false);

            if (response.success) {
                closeModal(true);
            }

            // showToast(response.message,(response.success) ? "success" : "error");

        });
    }
    return (
        <Modal
            show={openModal.active}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap"
            onHide={() => {
				closeModal();
			}}
        >
            <Modal.Body className="">
                {isReportAnswerLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon />
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <h2 className={`heading h2`}>Report the tester</h2>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal(false);
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img
                                        src={process.env.REACT_APP_URL + "img/close.svg"}
                                        alt="cross"
                                    />
                                </span>
                            </button>
                        </div>
                        <div className="report-answer-body">
                            <p className="subtitle-2 medium-font">Let us know what the issue is:</p>
                            <Input
                                label="What would you like to report?"
                                onChange={(e) => {
                                    setFormValues({ title: e.target.value });
                                    setFormErrors((oldValues) => ({
                                        ...oldValues,
                                        error_msg: null,
                                    }));
                                }}
                                onClear={() => {
                                    setFormValues((oldValues) => ({
                                        ...oldValues,
                                        title: "",
                                    }));
                                }}
                                value={formValues.title}
                                error={formErrors.error_msg}
                                error_class={formErrors.error_class}
                                id="What would you like to report?"
                                clearField={true}
                                required={true}
                            />
                        </div>
                        <div className="confirm-buttons-wrap">
                            <Button type="secondary" size="large" label={'Cancel'} onClick={() => { closeModal(false); }} />
                            <Button type="primary" size="large" label={"Report tester"} onClick={(e) => reportIssueHandler()} />
                        </div>
                    </div>}
            </Modal.Body>
        </Modal>
    );
}