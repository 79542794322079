export default function Checkbox({ checked, value, bigLabel,  defaultValue, id,onChange,label, className, beforeLabel,isDisabled}){
    
    return (
        <label className={'check-switch '+className} htmlFor={id}>
           {beforeLabel  &&
            <p className={`${beforeLabel?'monthly-plan-text':''}  ${bigLabel?'big-':''}label-text`}>
                {!checked  &&
                    <b>
                        {beforeLabel}
                    </b>
                }
                {checked &&
                    <>
                        {beforeLabel}
                    </>
                }
                
            </p>
            }
            <input
                type="checkbox" 
                id={id}
                checked={checked}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={(isDisabled)?true:false}
            />
            <span className="check-slider"></span>
            <p className={`${beforeLabel?'yearly-plan-text':''} ${bigLabel?'big-':''}label-text`}>
                {checked && beforeLabel &&
                    
                    <b>{label}</b>
                    
                }
                {(!checked || !beforeLabel) &&
                    <>
                        {label}
                    </>
                }

                {isDisabled}
            </p>
        </label>
    )
}
