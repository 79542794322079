import {handleApiErrorResponse} from "../lib/helpers";
import axios from 'axios';


export function addUpdateQuestionImageService(formValues,progressCallback, token) {
    return   axios.post(process.env.REACT_APP_API_END_POINT + "test/question/image/save",formValues, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const {loaded, total} = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          progressCallback(percent)
        }
    }).then((res)=>{
    
        
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.data : null;
    
        if (res.statusText!="OK") {
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          return Promise.reject(error);
        }
        return data;
    
    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function deleteQuestionImageService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/question/image/delete", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}