import React, {useEffect, useState, useRef} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import {Text} from "../Text/Text";
import {CheckBox} from "../CheckBox/CheckBox";
import {ReactSession} from "../../../../lib/secure_reactsession";
import {Search} from "../Search/Search";
import {Icon} from "../Icon/Icon";
import {
    getLoadMoreReportTesterParticipant,
    getReportTesterParticipant
} from "../../../../services/test_result";
import {showToast} from "../../../../lib/helpers";
import {violations} from "../../../../data/violations";
import {MultiSelectGroupDropdown} from "../../../themes/userq/MultiSelectGroupDropdown/MultiSelectGroupDropdown";
import {Input} from "../Input/Input";
import ConfirmationModal from "./ConfirmationModal";
import ReportTesterConfirmation from "./report_tester_confirmation";
import {ReportTesterParticipantTestResult,ReportTesterParticipantDeletedTestResult} from "../../../../services/researcher_test_results";
import {format} from "date-fns";
import { Link } from "../Link/Link";

var total_participants_reported = null;

export default function ReportTesterModal({
                                              openModal,
                                              close,
                                              confirm,
                                              btnLoading,
                                              test,
                                              selectedTestResultId
                                          }) {
    const closeModal = () => {

        if(isDraft){


            //confirm('draft',totalParticipantSelected);

            confirm('draft',total_participants_reported);

        }else{

            close();
        }

    };
    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    let reportTestersTableClass = null;

    const [formValues, setFormValues] = useState({  isShown: false });
    const [search, setSearch] = useState("");

    const wrapperRef = useRef(null);
    const wrapperRefCloseModal = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [draftBtnLoading, setDraftBtnLoading] = useState(false);
    const [allCount, setAllCount] = useState([]);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [metaData, setMetaData] = useState({ sort_time: "",sort_participant: "",load_more: false, page: 1 });

    const [totalParticipantSelected, setTotalParticipantSelected] = useState(0);



    const [clearSelectionModal, setClearSelectionModal] = useState({
        open: false
    });
    const [reportTesterConfirmationModal, setReportTesterConfirmationModal] = useState({
        open: false
    });
    const [clearSelectionBtnLoading, setClearSelectionBtnLoading] = useState(false);

    const [isError, setError] = useState(false);
    const [isDraft, setDraft] = useState(true);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);

    const [testReportData, setTestReportData] = useState("");
    const [testReportTesterData, setTestReportTesterData] = useState([]);


    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isDropdownOpenOption, setDropdownOpenOption] = useState(false);
    const [isDropdownOpenOptionMobile, setDropdownOpenOptionMobile] = useState(false);


    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {

        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {

            scrollContainer.addEventListener('scroll', handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [data]);

    // ger report tester participants data
    const getReportTesterParticipantData = ()=>{
        let token = ReactSession.get("token");
        setIsLoading(true);
        getReportTesterParticipant({test_id:test.id},token).then((response) => {
            setIsLoading(false);
            if (response.success) {

                //console.log(response)
                //console.log(selectedTestResultId)

                var dataArray = [];
                var checkedSelectedDataCountIncrement = 0;
                response.data.forEach((items)=>{

                    // checked if any value while reported from breifing and defiring anser
                    var checkedSelectedData = false;
                    if(selectedTestResultId){

                        // if user already selcted
                        if((items.test_reports_testers)){
                            checkedSelectedData = true;

                            // setSearch(items.tester_type);
                            if(selectedTestResultId === items.id){
                                setSearch(items.tester_type);
                            }
                        }else if(selectedTestResultId === items.id){
                            checkedSelectedData=true;
                            checkedSelectedDataCountIncrement++;

                            setSearch(items.tester_type);
                        }




                    }else{
                        checkedSelectedData = (items.test_reports_testers)?true:false;
                    }

                    dataArray.push({
                        ...items,
                        'ischecked':checkedSelectedData,
                        //'ischecked':(items.test_reports_testers)?true:false,
                        'violation':(items.test_reports_testers && items.test_reports_testers.violations)?items.test_reports_testers.violations.split(","):[],
                        'additional_notes':(items.test_reports_testers)?items.test_reports_testers.notes:'',
                        'violation_error':'',
                        'additional_notes_error':'',
                        'violationDropdownOpen':false

                    })
                });


                // check error
                checkedError(dataArray);

                var dataSort = dataArray.sort((a, b) => (a.result_id - b.result_id))
                setData(dataSort);


                // setData(dataArray);
                setAllCount(response.resultcount);
                total_participants_reported = response.testReportTesterCount+checkedSelectedDataCountIncrement;
                setTotalParticipantSelected(response.testReportTesterCount+checkedSelectedDataCountIncrement)
                setDraft(response.isDraft)
                setTestReportData(response.testReport)
                setTestReportTesterData(response.testReportTester)
                setMetaData({
                    sort_time: "",
                    sort_participant: "asc",
                    load_more: false,
                    page: 1
                });

                //sortData("sort_participant")

            } else {

                showToast(response.message,"error");

            }
        });
    }

    useEffect(() => {

        if(openModal){
            getReportTesterParticipantData();
            setSearch("");
        }



    },[openModal]);


    const user = ReactSession.get("user");

    const calculateTime = (time) => {
        var d = Math.floor(time / (3600 * 24));
        var h = Math.floor(time % (3600 * 24) / 3600);
        var m = Math.floor(time % 3600 / 60);
        var s = Math.floor(time % 60);

        if (m > 0) {
            return (`${m}min ${s}sec`);

        } else if (s > 0) {
            return (` ${s}sec`);
        } else {
            return ('-');
        }
    }

    const setFormData =  (id,Val,type)=>{

        var resultData = data.find(x => x.id === id);

        if(resultData){

            // checked type
            if(type === 'checkbox'){

                resultData.ischecked = Val;

                // increment count of selctedted count participant
                if(Val){
                    setTotalParticipantSelected(totalParticipantSelected+1);
                }else{
                    setTotalParticipantSelected(totalParticipantSelected-1);

                    // empty value is check box is unchecked
                    resultData.additional_notes = "";
                    resultData.violation_error = "";
                    resultData.violation = [];
                    resultData.additional_notes_error = "";
                }

            }else if (type === 'input'){

                resultData.additional_notes = Val;

                // if(Val){
                //     resultData.additional_notes_error = "";
                // }else{
                //
                //     // checked if checkbox is checked
                //     if(resultData.ischecked)
                //      resultData.additional_notes_error = "Required field";
                // }


            }else if(type === 'select'){

                var groupData=[];


                // automatically select checkbox if any voilation selected
                if(!resultData.ischecked){
                    resultData.ischecked = true;  // set true
                    setTotalParticipantSelected(totalParticipantSelected+1);
                }

                if(Val.length === 0){
                    groupData = [];

                    // checked if checkbox is checked
                    if(resultData.ischecked)
                        resultData.violation_error = "Required field";
                }else{
                    Val.forEach(element => {
                        groupData.push(element.value);
                        //setSelectedData([...groupData]);

                    });

                    resultData.violation_error = "";
                }

                resultData.violation = groupData;

                setDropdownOpen(true);
                // setDropdownOpenOption(true)


            }

            const list = [...data];
            const question = resultData;

            var index = data.findIndex(x => x.id === id);

            list.splice(index,1,question);

            // check error
            checkedError(list);

            setData(list)

        }
    }

    // checked error in the form
    const checkedError = (dataArray) => {

        var error = false;
        dataArray.forEach((items)=>{

            // checked if checkbox is checked
            if(items.ischecked){

                // if(items.additional_notes === ""){
                //     error = true;
                // }

                if(items.violation.length === 0){
                    error = true;
                }
            }
        })

        setError(error);
    }

    const violationsHtml = (violationData) =>{
        var render_html = [];

        var violationDataArray = violationData.split(",");
        violationDataArray.map((items, index) => {
            render_html.push(
                <li key={"listitem"+index}>
                    {items}
                </li>
            );
        });
        return (
            <ul className={"violation-list"}>{render_html}</ul>
        );
    }

    // repoted tester details
    const renderedReportedTestedHtml = ()=>{
        var render_html = [];

        if(testReportTesterData.length>8){
            reportTestersTableClass = "responsive-table-scroll";
        } else {
            reportTestersTableClass = null;
        }

        testReportTesterData.map((items, index) => {

            var userType = '';
            if(items.test_result){

                if(items.test_result.user_type){
                    if(items.test_result.user_type === 'Unknown'){
                        userType =items.test_result.user_type+" ";
                    }else{
                        userType =items.test_result.user_type;
                    }
                }


            }

            render_html.push(
                <tr>
                    <td className={'participant-id d-flex'}>

                        <div className={"participant-id-data-wrap"}>
                            <Icon value={'warning'} /> {" "}
                            {userType?userType:'UQ'}

                            {items.test_result && items.test_result.result_id ?
                                items.test_result.result_id
                                :
                                index + 1
                            }
                        </div>

                    </td>
                    <td>
                        {violationsHtml(items.violations)}
                    </td>
                    <td>
                        {items.notes?items.notes:'-'}
                    </td>
                    <td>
                        <div className={items.status}>
                            {items.status === 'Pending'?'Pending UQ approval':items.status}
                        </div>
                    </td>
                    <td>
                        {items.status === 'Pending' && '-' }
                        {items.status === 'Approved' && <span className="text-success">+ {items.credits_refunded} Credits</span> }
                        {items.status === 'Declined' && <span className="text-danger">0 Credits</span> }
                    </td>


                </tr>
            )
        })


        return (
            <>{render_html}</>
        );
    }

    const renderedHtml = ()=>{

        var render_html = [];


        var searchVal = search.charAt(0).toUpperCase() + search.slice(1);

        var showNoParticipant=true;


        data.map((items, index) => {

            var tester_Val = items.tester_type;
            var rgxp = new RegExp(searchVal, "g");
            if(tester_Val.match(rgxp) && tester_Val.match(rgxp).length > 0) {
                //console.log(tester_Val.match(rgxp))
                showNoParticipant=false;
                render_html.push(
                    <tr className={`${items.ischecked ? 'row-checked' : ''}`}>
                        <td>

                            <div className={'participant-data-checkbox'}>
                                <CheckBox
                                    id={`terms_accept_toggle-${index}`}
                                    checked={items.ischecked}
                                    onChange={(e) => setFormData(items.id, !items.ischecked, 'checkbox')}
                                    label={items.tester_type}
                                />
                            </div>


                            {/*<span className={'participant-data-name'}>*/}
                            {/*    {items.user_type}*/}
                            {/*    {" "}*/}

                            {/*    {items.result_id ?*/}
                            {/*        items.result_id*/}
                            {/*        :*/}
                            {/*        index + 1*/}
                            {/*    }*/}

                            {/*</span>*/}

                        </td>
                        <td>
                            <div className={'time-spent d-flex align-items-center none-for-mobile'}>
                                <Icon hover={true} value={"time"}/>
                                <span className={'time-spent-data-wrap'}>{calculateTime(items.time_spent)}</span>
                            </div>
                            <div className={'time-spent d-flex align-items-center none-for-desktop'}>
                                <Icon hover={true} value={"time"}/>
                                <span
                                    className={'time-spent-data-wrap'}>Time spent: <span>{calculateTime(items.time_spent)}</span></span>
                            </div>
                        </td>
                        <td>
                            <MultiSelectGroupDropdown
                                onChange={(e) => setFormData(items.id, e, 'select')}
                                // onTouchStart={() => {
                                //     console.log('mobile')
                                //     console.log(isDropdownOpenOption)
                                //     console.log(isDropdownOpenOptionMobile)
                                //     if (isDropdownOpenOption && isDropdownOpenOptionMobile) {
                                //         //setDropdownOpenOption(false);
                                //         setDropdownOpenOption(false);
                                //         setDropdownOpenOptionMobile(false);
                                //     } else {
                                //
                                //         var resultData = data.find(x => x.id === items.id);
                                //         resultData.violationDropdownOpen = true;
                                //         const list = [...data];
                                //         const question = resultData;
                                //
                                //         var index = data.findIndex(x => x.id === items.id);
                                //
                                //         list.splice(index, 1, question);
                                //         setData(list)
                                //
                                //         setDropdownOpenOption(true);
                                //         setDropdownOpenOptionMobile(true);
                                //
                                //          console.log('open drop 1')
                                //     }
                                // }}
                                onClick={() => {
                                    //console.log('90')
                                    if (isDropdownOpenOption) {
                                        //setDropdownOpenOption(false);
                                    } else {

                                        var resultData = data.find(x => x.id === items.id);
                                        resultData.violationDropdownOpen = true;
                                        const list = [...data];
                                        const question = resultData;

                                        var index = data.findIndex(x => x.id === items.id);

                                        list.splice(index, 1, question);
                                        setData(list)

                                        setDropdownOpenOption(true);

                                        //console.log('open drop')
                                    }

                                }}
                                value={items.violation}
                                isMulti={true}
                                options={violations}
                                label={"Select violations"}
                                error={items.violation_error}
                                menuIsOpen={items.violationDropdownOpen}
                                // menuIsOpen={isDropdownOpenOption}
                            />
                        </td>
                        <td>

                            <div className={`none-for-mobile ${items.ischecked?'':'read-only-field'}`}>
                                <Input
                                    label={"Give us details about why this user should be reported"}
                                    value={(items.additional_notes)?items.additional_notes:""}
                                    readOnly={(items.ischecked)?false:true}
                                    onBlur={(e) => {
                                        setFormData(items.id, e.target.value, 'input')
                                    }}
                                    onChange={(e) => {
                                        // checked checkedbox is checed and violation is selected
                                        if(items.ischecked){
                                            setFormData(items.id, e.target.value, 'input')
                                        }else{
                                            setFormData(items.id, '', 'input')
                                        }

                                    }}
                                    onClear={() => {
                                        setFormData(items.id, "", 'input')
                                    }}
                                    error={items.additional_notes_error}
                                    required={true}
                                    maxLength={300}
                                />
                            </div>

                            <div className={`none-for-desktop ${items.ischecked?'':'read-only-field'}`}>
                                <Input
                                    label={"Additional notes"}
                                    value={(items.additional_notes)?items.additional_notes:""}
                                    readOnly={(items.ischecked)?false:true}
                                    onBlur={(e) => {
                                        setFormData(items.id, e.target.value, 'input')
                                    }}
                                    onChange={(e) => {
                                        // checked checkedbox is checed and violation is selected
                                        if(items.ischecked){
                                            setFormData(items.id, e.target.value, 'input')
                                        }else{
                                            setFormData(items.id, '', 'input')
                                        }
                                    }}
                                    onClear={() => {
                                        setFormData(items.id, "", 'input')
                                    }}
                                    error={items.additional_notes_error}
                                    required={true}
                                    maxLength={300}
                                />
                            </div>

                        </td>


                    </tr>
                )
            }


        })

        if(showNoParticipant){
            render_html.push(
                <div className="search-no-data-found">
                    <div className="search-no-data-inner">
                        <Icon value={"Search"} size={"extra-large"}/>
                        <Text type="subtitle-2">No result found!</Text>
                        <Text type="body-text-3">There is no result matching your search.</Text>
                        <Button size="small" label="Clear search" onClick={()=>{setSearch("")}} />
                    </div>
                </div>
            );
        }
        if(render_html.length>8){
            reportTestersTableClass = "responsive-table-scroll";
        } else {
            reportTestersTableClass = null;
        }

        return (
            <>{render_html}</>
        );
    }

    const sortData = (type) => {
        if (type === 'time') {


            if (metaData.sort_time === 'asc') {

                var dataSort = data.sort((a, b) => (b.time_spent - a.time_spent))

                setData(dataSort);

                //sortDataApi('time', 'desc');

                setMetaData({
                    ...metaData,
                    sort_participant: '',
                    sort_time: 'desc',
                    load_more: false,
                    page: 1
                });
            } else {

                var dataSort = data.sort((a, b) => (a.time_spent - b.time_spent))

                setData(dataSort);
                //sortDataApi('time', 'asc');

                setMetaData({
                    ...metaData,
                    sort_participant:'',
                    sort_time: 'asc',
                    load_more: false,
                    page: 1
                });
            }


        }else if(type === 'participant'){

            if (metaData.sort_participant === 'asc') {

                var dataSort = data.sort((a, b) => (b.result_id - a.result_id))
                setData(dataSort);

                //sortDataApi('time', 'desc');

                setMetaData({
                    ...metaData,
                    sort_time: '',
                    sort_participant: 'desc',
                    load_more: false,
                    page: 1
                });
            } else {

                var dataSort = data.sort((a, b) => (a.result_id - b.result_id))

                setData(dataSort);
                //sortDataApi('time', 'asc');

                setMetaData({
                    ...metaData,
                    sort_time: '',
                    sort_participant: 'asc',
                    load_more: false,
                    page: 1
                });
            }
        }

    }

    const sortDataApi = (sortColumn, orderBy) => {

        let token = ReactSession.get("token");
        getLoadMoreReportTesterParticipant({
            page: 1,
            sort_column: sortColumn,
            order_by: orderBy,
            test_id:test.id
        }, token).then((response) => {
            //setSortLoading(false);

            if (response.success) {

                setData(response.data);
                setMetaData({
                    ...metaData,
                    sort_time: orderBy,
                    load_more: response.more_records,
                    page: 1
                });

            } else {
                showToast(response.message,"error");
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);
        let token = ReactSession.get("token");

        getLoadMoreReportTesterParticipant({
            page: metaData.page + 1,
            sort_column: metaData.sort_time?"time":"",
            order_by: metaData.sort_time?metaData.sort_time:"desc",
            test_id:test.id
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setData(data.concat(response.data));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                showToast(response.message,"error");
            }
        });
    }




    // search
    const searchData = (val)=>{

        // var search = val.charAt(0).toUpperCase() + val.slice(1);
        //
        // var searchArrayData = [];
        // data.map((items)=> {
        //     // if(items.tester_type === search){
        //     //
        //     // }
        //
        //     var tester_Val = items.tester_type;
        //     var rgxp = new RegExp(search, "g");
        //     //console.log(tester_Val.match(rgxp))
        //
        //     if(tester_Val.match(rgxp) && tester_Val.match(rgxp).length > 0){
        //         searchArrayData.push({...items})
        //     }
        // });


        //alert(xxx.match(rgxp).length);


        //console.log(searchArrayData)
        //setFilterData(searchArrayData);
        setSearch(val);

    }

    // clear selection handler
    const clearSelectionHandler = ()=>{

        const token = ReactSession.get("token");
        let datas = Object.assign({}, '');

        datas["test_id"] = test.id;

        if(clearSelectionBtnLoading){
            return false;
        }

        setClearSelectionBtnLoading(true);
        ReportTesterParticipantDeletedTestResult(datas, token).then((response) => {
            setClearSelectionBtnLoading(false);



            if (response.success) {

                if(totalParticipantSelected === 1){

                    showToast("Participant has been deselected from report","success");
                }else{

                    showToast("Participants has been deselected from report","success");
                }

                var selectedData = [];

                data.forEach((items)=>{
                    selectedData.push({
                        ...items,
                        'ischecked':false,
                        'violation':[],
                        'additional_notes':'',
                        'violation_error':'',
                        'additional_notes_error':'',
                    })
                })

                setData(selectedData);

                setClearSelectionModal({ ...clearSelectionModal, open: false });
                setTotalParticipantSelected(0);  // set total participant selected 0

            }else{

                showToast(response.message,(response.success ? "success" : "error"));
            }





        });



    }

    const saveDataHandler = (type)=>{

        /// show error message notification
        if(isError && type !== 'draft'){
            showToast("Please fill all the value of  selected participants.","error");
            return false;
        }

        var getSelectedData = data.filter(x=>x.ischecked === true);

        var selectedData = [];
        getSelectedData.forEach((items)=>{
            selectedData.push({
                test_result_id:items.id,
                user_id:items.user_id,
                violation:items.violation,
                notes:items.additional_notes,
            });
        });


        const token = ReactSession.get("token");
        let datas = Object.assign({}, '');

        datas["test_id"] = test.id;
        datas["status"] = type;
        datas["datas"] = selectedData;


        if(draftBtnLoading && type === 'draft'){
            return false;
        }

        if (saveBtnLoading && type !== 'draft'){
            return false;
        }

        if(type !== 'draft')
            setSaveBtnLoading(true)

        if(type === 'draft')
            setDraftBtnLoading(true);
        ReportTesterParticipantTestResult(datas, token).then((response) => {
            setDraftBtnLoading(false);
            setSaveBtnLoading(false)

            if (response.success) {

                // open report tester modal
                //status
                // totalselected participant

                total_participants_reported = totalParticipantSelected;

                confirm(type,totalParticipantSelected);



            }

            showToast(response.message,(response.success ? "success" : "error"));



        });
    }

    function useOutsideAlerter(ref, data) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            // console.log("abcd");
            //   console.log(ref.current);
            //   console.log("jsfhfsh");

            function handleClickOutside(event) {
                // console.log(event.target);
                // console.log(event.target.nodeName); //
                //
                //console.log(isDropdownOpenOption)
                //console.log(event.target.classList)

                // console.log('close')
                if(isDropdownOpenOptionMobile){

                    if(!event.target.classList.contains('css-319lph-ValueContainer')){
                        if(!event.target.classList.contains('css-g1d714-ValueContainer')){
                            if(isDropdownOpenOption){
                                //console.log('adas')
                                // saveDataHandler('draft');
                                setDropdownOpenOption(false);
                                setDropdownOpenOptionMobile(false);

                                // set false to all dropdown value
                                const list = [...data];
                                const listArray = [];
                                list.forEach((items)=>{
                                    listArray.push({...items,violationDropdownOpen:false})
                                })

                                setData(listArray)
                            }
                        }
                    }
                }else{

                    if(!event.target.classList.contains('css-319lph-ValueContainer')) {
                        if (!event.target.classList.contains('css-g1d714-ValueContainer')) {
                            if (isDropdownOpenOption) {
                                //console.log('adas')
                                // saveDataHandler('draft');
                                setDropdownOpenOption(false);

                                // set false to all dropdown value
                                const list = [...data];
                                const listArray = [];
                                list.forEach((items) => {
                                    listArray.push({...items, violationDropdownOpen: false})
                                })

                                setData(listArray)
                            }
                        }
                    }
                }

                //return false;


                // console.log(ref.current);
                // if (ref.current && !ref.current.contains(event.target)) {
                //     console.log(ref.current.contains(event.target));
                //     if (event.target.nodeName != "svg") {
                //         if (event.target.nodeName != "path") {
                //             // if (questionFormValues.question !== "") {
                //                 if (!event.target.classList.contains('icon-medium')) {
                //                     if (!event.target.classList.contains('toast-close-icon')) {
                //                        // handleQuestionSubmit();
                //                         console.log('adasdsa');
                //                     }
                //                 }
                //             // }
                //         }
                //     }
                // }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, data,isDropdownOpenOption]);
    }

    useOutsideAlerter(wrapperRef, data);

    function useOutsideClickClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                /*console.log(event.target);
                console.log(event.target.nodeName);
                console.log(event.target.classList);
                console.log("xyz");
                console.log(ref.current);*/
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV" && event.target.classList.contains('report-tester-modal')){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideClickClose(wrapperRefCloseModal);





    return (
        <>
            <Modal
                show={openModal}
                centered
                className={`fade custom-modal-wrap report-testers-modal  confirmation-modal-wrap report-tester-modal`}
                ref={wrapperRef}
            >
                <Modal.Body className="" ref={wrapperRefCloseModal}>
                    {isLoading ?
                        <div className="modal-loader text-center">
                            <LoadingIcon/>
                        </div>
                        :
                        <div className="modal-inner-text">
                            <div className="modal-header-top">
                                <Text type={'h3'}>{isDraft ?'Report participants':'Reported participants'}</Text>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                    aria-label="Close"
                                >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                                </button>
                            </div>

                            {isDraft ?
                                <>
                                    <div className={"report-tester-modal-data-wrap"}>

                                        <div className={"report-tester-search-modal-data-wrap"}>
                                            <Search
                                                type="text"
                                                size={'small'}
                                                placeholder="Search"
                                                value={search}
                                                onChange={(event) => {
                                                    searchData(event.target.value);
                                                }}

                                                onClear={(event) => {
                                                    searchData("");
                                                }}
                                            />

                                            <div className="guide-line-link">
                                                <Link target={"_blank"} url={process.env.REACT_APP_URL+"r/reporting-guidelines"} cssClasses={'small-link'}>Read more about the reporting guidelines</Link>
                                            </div>
                                        </div>
                                        <div ref={scrollContainerRef} className={`report-tester-participant  max-height-table  ${isScrolled ? 'scrolled' : ''} responsive-table-scroll`}>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th className="bd-thcol-1">

                                                        <div onClick={() => {
                                                            sortData('participant')
                                                        }}
                                                             className={`${(metaData.sort_participant) ? metaData.sort_participant : ''} col-sort justify-content-between`}>
                                                            Participants
                                                            {metaData.sort_participant == "asc" &&
                                                            <Icon value={'sortup'}/>
                                                            }
                                                            {metaData.sort_participant == "desc" &&
                                                            <Icon value={'sortdown'}/>
                                                            }
                                                            {!metaData.sort_participant &&
                                                            <Icon value={'sort-icon'}/>
                                                            }
                                                        </div>
                                                    </th>
                                                    <th className="bd-thcol-2">
                                                        <div onClick={() => {
                                                            sortData('time')
                                                        }}
                                                             className={`${(metaData.sort_time) ? metaData.sort_time : ''} col-sort justify-content-between`}>
                                                            Time spent
                                                            {metaData.sort_time == "asc" &&
                                                            <Icon value={'sortup'}/>
                                                            }
                                                            {metaData.sort_time == "desc" &&
                                                            <Icon value={'sortdown'}/>
                                                            }
                                                            {!metaData.sort_time &&
                                                            <Icon value={'sort-icon'}/>
                                                            }
                                                        </div>
                                                    </th>
                                                    <th className="bd-thcol-3">
                                                        Violations
                                                    </th>
                                                    <th className="bd-thcol-4">
                                                        Additional notes
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderedHtml()}


                                                </tbody>
                                            </table>

                                            {/*{metaData.load_more && (*/}
                                            {/*    <div className={"load-more-data"}>*/}
                                            {/*        <Button type="primary"*/}
                                            {/*                size={`large`}*/}
                                            {/*                label="Load more"*/}
                                            {/*                onClick={()=>{loadMore()}}*/}
                                            {/*                microLoading={loadMoreLoading}*/}
                                            {/*        />*/}
                                            {/*    </div>*/}
                                            {/*)}*/}

                                        </div>


                                    </div>


                                    <div className="confirm-buttons d-flex">
                                        <div className="confirm-buttons-wrap-left">
                                            <div
                                                onClick={(e) => {
                                                    // if count then clear selection popup shown
                                                    if (totalParticipantSelected)
                                                        setClearSelectionModal({
                                                            open: true
                                                        });
                                                }}
                                                className={`clear-selection-btn-wrap ${(totalParticipantSelected) ? 'active' : 'disabled'}`}>
                                                <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                    <Icon
                                                        value={"Refresh"} size={"small"}/> Clear
                                                    selection</Text>
                                            </div>
                                        </div>
                                        <div className="confirm-buttons-wrap">


                                            <Button type="secondary" size="large" microLoading={draftBtnLoading}
                                                    label={`Save draft`}
                                                    state={(totalParticipantSelected) ? '' : 'disabled'}
                                                    onClick={() => {
                                                        if (totalParticipantSelected)
                                                            saveDataHandler('draft');
                                                    }}/>
                                            <Button type="primary" size="large" microLoading={saveBtnLoading}
                                                    state={(totalParticipantSelected && !isError) ? '' : 'disabled'}
                                                    label={`Report ${(totalParticipantSelected === 1) ? totalParticipantSelected + ' participant' : totalParticipantSelected + ' participants'}`}
                                                    onClick={() => {
                                                        // if count then clear selection popup shown
                                                        if (totalParticipantSelected){
                                                            if(isError){
                                                                showToast("Please fill all the value of  selected participants.","error");
                                                                return false;
                                                            }else{
                                                                setReportTesterConfirmationModal({
                                                                    open: true
                                                                });
                                                            }
                                                        }

                                                    }}/>


                                        </div>
                                    </div>
                                </>
                                :
                                <div className={"report-tester-modal-data-wrap report-tester-modal-list"}>

                                    <div className={"submitted_date d-flex"}>
                                        <Icon value={'calendar'} />
                                        {testReportData &&
                                        <Text type={"body-text-3"} fontWeight={"medium-font"}>
                                            Submitted on {" "}
                                            {format(
                                                new Date(testReportData.created_at),
                                                "dd/MM/yy"
                                            )}
                                        </Text>
                                        }
                                    </div>


                                    <Text type={"body-text-2"} cssClasses={`reported-description-data ${(testReportData && testReportData.status === 'to_review')?'':'reported-description-data-reviewed'}`} fontWeight={"semi-bold-font"}>
                                        {testReportData && testReportData.status === 'to_review' ?
                                            <>
                                                You’ve successfully reported {totalParticipantSelected} participants. Our UQ team will review your request, and once approved, credits will be refunded to your wallet.
                                            </>
                                            :
                                            <>&nbsp;</>
                                        }
                                    </Text>



                                    <div ref={scrollContainerRef} className={`report-tester-submitted-participant  max-height-table  ${isScrolled ? 'scrolled' : ''} ${reportTestersTableClass?reportTestersTableClass:''}`}>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="bd-thcol-1">Participants</th>
                                                <th className="bd-thcol-2">
                                                    Violations
                                                </th>
                                                <th className="bd-thcol-3">
                                                    Additional notes
                                                </th>
                                                <th className="bd-thcol-4">
                                                    Status
                                                </th>
                                                <th className="bd-thcol-5">
                                                    Refunded credits
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderedReportedTestedHtml()}


                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            }

                        </div>
                    }
                </Modal.Body>
            </Modal>

            <ConfirmationModal
                confirm_message={"Are you sure you want to clear selection?"}
                confirm_btn_title={"Submit"}
                confirm_title={"Clear selection"}
                confirm_title_class={'text-danger'}
                openModal={clearSelectionModal.open}
                btnLoading={clearSelectionBtnLoading}
                cssClass={'add-overlay-background'}
                close={() => {
                    setClearSelectionModal({ ...clearSelectionModal, open: false });
                }}
                confirm={() => {
                    clearSelectionHandler();
                }}
            />

            <ReportTesterConfirmation
                confirm_btn_title={"Submit"}
                confirm_title={"Submit request"}
                total_test={totalParticipantSelected}
                openModal={reportTesterConfirmationModal.open}
                btnLoading={clearSelectionBtnLoading}
                cssClass={'add-overlay-background'}
                close={() => {
                    setReportTesterConfirmationModal({ ...reportTesterConfirmationModal, open: false });
                }}
                confirm={() => {
                    //clearSelectionHandler();
                    setReportTesterConfirmationModal({ ...reportTesterConfirmationModal, open: false });
                    saveDataHandler('to_review');
                }}
            />
        </>
    );
}
