import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Logo } from './../themes/userq/Logo/Logo';
import { Text } from './../themes/userq/Text/Text';
import { Input } from './../themes/userq/Input/Input';
import { showToast } from '../../lib/helpers';
import { Button } from './../themes/userq/Button/Button';
import { Footer } from './../themes/userq/Footer/Footer';
import { Icon } from '../themes/userq/Icon/Icon';

export default function ChangeEmail() {

    let { code } = useParams();

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({ email: '', confirm_email: '', code: code });

    const [formErrors, setFormErrors] = useState({ email: null, confirm_email: null, passwordErrorStyle: { color: '#000' }, terms: null, error_class: null });

    const [modal, setModal] = useState({ modalHeading: null, modalMessage: null, modalOpen: false });

    useEffect(() => {
        setTimeout(function () {
            window.animate();
        }, 1000);
        document.title = process.env.REACT_APP_NAME + " - Change Email";
    }, []);

    const confirmEmail = () => {

        setLoading(true);

        fetch(process.env.REACT_APP_API_END_POINT + 'confirm-email', {
            method: 'POST',
            body: JSON.stringify(formValues),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(response => {
                setLoading(false);
                if (response.success) {
                    // setFormValues({ email: '', confirm_email: '', code: code });
                    setModal({ modalHeading: "Email Sent", modalMessage: "An email is sent to your new email address. Please go and verify.", modalOpen: true });

                } else {
                    showToast(response.message,"error");
                }
            });
    }


    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = () => {


        var form_errors = resetFormErrors();

        var error = false;

        // var mailformat=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        //checking if email field is not blank

        if (formValues.email == '') {
            setLoading(false);
            setFormErrors({ email: 'Required', confirm_email: null, "error_class": "input_error" });
            error = true;

        }

        //checking if email is in correct format


        else if (!formValues.email.match(mailformat)) {
            setLoading(false);
            setFormErrors({ email: 'Please enter a valid email', confirm_email: null, "error_class": "input_error" });
            error = true;

        }

        //checking if email and confirm email are both same

        else if (formValues.email != formValues.confirm_email) {
            setLoading(false);
            setFormErrors({ email: null, confirm_email: 'Emails must be the same', "error_class": "input_error" });
            error = true;

        } else {

            error = false;
        }


        return error;
    }
    const onSubmit = async (event) => {
        event.preventDefault();


        if (!isLoading) {

            var error = validateFullForm();

            if (!error) {
                confirmEmail();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            if(name=="email"){
                setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
                if(value===formValues.confirm_email){
                    setFormErrors((oldValues) => ({ ...oldValues, confirm_email: null }));
                }
            }else{
                if(value===formValues.email){
                    setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
                }
            }
        }
    };

    return (

        <div className="change-email-page-wrapper">
            <div className="change-email-header">
                <Link to={"/"} >
                    <Logo theme={"white"} background={"black"} />
                </Link>
            </div>
            <div className="change-email-body">
                <div className="change-email-body-top">
                    <Text type="h1">Update your email address</Text>
                    <Text type="body-text-2" fontWeight="medium-font">Enter the new email address you want to use to access UserQ.</Text>
                </div>
                <div className="change-email-form">
                    <div className="change-email-form-field">
                        <Input
                            label="Email address"
                            onChange={set("email")}
                            onClear={() => {
                                setFormValues((oldValues) => ({
                                    ...oldValues,
                                    email: "",
                                }));
                            }}
                            value={formValues.email}
                            error={formErrors.email}
                            error_class={formErrors.error_class}
                            id="email"
                            clearField={true}
                            required={true}
                        />

                    </div>
                    <div className="change-email-form-field">
                        <Input
                            label="Confirm email"
                            onChange={set("confirm_email")}
                            onClear={() => {
                                setFormValues((oldValues) => ({
                                    ...oldValues,
                                    confirm_email: "",
                                }));
                            }}
                            value={formValues.confirm_email}
                            error={formErrors.confirm_email}
                            error_class={formErrors.error_class}
                            id="confirm_email"
                            clearField={true}
                            required={true}
                        />
                    </div>
                    <div className="change-email-button">
                        {(formValues.email=="" || formValues.confirm_email=="")?
                            <Button type="primary" size="large" label="Update email" state="disabled" />
                            :
                            <Button type="primary" size="large" label="Update email" state="active" onClick={onSubmit} microLoading={isLoading}/>
                        }
                    </div>

                    <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap change-email-modal">
                        <Modal.Body>
                            <div className="modal-inner-text">

                                <div className="modal-header-top">
                                    <Text type="h3">{modal.modalHeading}</Text>
                                    <button type="button" className="close" onClick={() => { setModal({ modalHeading: null, modalMessage: null, modalOpen: false }); }} aria-label="Close">
                                        <Icon value={'Close'} size={'medium'} hover={'true'}></Icon>
                                    </button>
                                </div>

                                <Text type="body-text-3" fontWeight="medium-font" cssClasses={'gray-color'}>{modal.modalMessage}</Text>

                                <div className="confirm-buttons-wrap">
                                    <Link to="/researcher/sign-in" className=""><Button type="primary" size="large" label="Back to dashboard" /></Link>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

            </div>
            <Footer />
        </div>

    )
}