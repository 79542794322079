//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import { Button } from "../../themes/userq/Button/Button";


export default function ExitTestModal({ openExitTestModal, closeExitTestModal, test,isPaidUser,cssClass }) {

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeExitTestModal(false);
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openExitTestModal}
            centered
            className={`fade custom-modal-wrap test-exit-modal ${cssClass}`}
        >
            <Modal.Body className={(test.language == "ar") ? "arabic_wrapper" : ""} ref={wrapperRef}>
                <div className="modal-inner-text">

                    <div className="modal-header-top">
                        <Text type={"h3"}>
                            {(test.language =="en")?'Exit test':'إنهاء الاختبار'}
                        </Text>



                        <button
                            type="button"
                            className="close p-0"
                            onClick={() => {
                                closeExitTestModal(false);
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value={'close'} size={'medium'} hover={true} />
                            </span>
                        </button>
                    </div>

                    <div className={`modal-middle-content`}>
                        {isPaidUser && test.target_group && test.target_group.target_type !=="test-links"
                            ?
                            <Text type={"body-text-3"} fontWeight={"medium-font"}>
                                {test.language == "en" ? "Remember – if you end the test at this stage, you won’t get paid for your insights. Return to the test to make sure you get rewarded for your time."
                                    : "تذكر - إذا أنهيت الاختبار في هذه المرحلة ، فلن تحصل على أموال مقابل أفكارك. عد إلى الاختبار للتأكد من حصولك على مكافأة مقابل وقتك."}
                            </Text>
                            :
                            <Text type={"body-text-3"} fontWeight={"medium-font"}>
                                {test.language == "en" ? "Are you sure you want to exit the test?"
                                    : "هل أنت متأكد أنك تريد الخروج من الاختبار؟"}
                            </Text>
                        }

                    </div>

                    <div className="confirm-buttons-wrap">


                        <Button
                            type="secondary"
                            size="large"
                            label={test.language == "en" ? "Cancel" : "إلغاء"}
                            onClick={() => { closeExitTestModal(false); }} />

                        <Button
                            type="primary"
                            size="large"
                            label={test.language == "en" ? "Exit test" : "إنهاء الاختبار"}
                            onClick={() => {
                                closeExitTestModal(true);
                            }} />

                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
}