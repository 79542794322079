export const testDefaultCardsData = [
    {
        title: "Prototype",
        description:
            "Understand how users navigate your digital product, measure how easy it is for them to complete specific tasks.",
        image: "firstclick-placeholder.svg",
        isComingSoon: true,
        methodology: "Prototype test",
        link: "https://app.userq.com/p/82dc0d",
    },
    {
        title: "Card sorting",
        description:
            "Find out how users think content should be organised and labelled to create a validated information architecture.",
        image: "cardsorting-placeholder.svg",
        isComingSoon: false,
        methodology: "Card Sorting",
        link: "https://app.userq.com/p/e26cfd",
    },
    {
        title: "Preference test",
        description:
            "Measure impact by having users evaluate different designs to understand which ones perform better.",
        image: "preferencetest-placeholder.svg",
        isComingSoon: false,
        methodology: "Preference Test",
        link: "https://app.userq.com/p/09b244",
    },
    {
        title: "Tree test",
        description:
            "Pinpoint exactly where your users are getting lost within your existing “tree” of information & hierarchical structure.",
        image: "treetest-placeholder.svg",
        isComingSoon: false,
        methodology: "Tree Test",
        link: "https://app.userq.com/p/c0d484",
    },
    {
        title: "Survey",
        description:
            "Get your target customers to answer your burning questions and acquire precise insights and data points.",
        image: "survey-placeholder.svg",
        isComingSoon: false,
        methodology: "Survey",
        isAIActive: true,
        link: "https://app.userq.com/p/35787e",
    },
    {
        title: "5 second test",
        description:
            "Discover the impact and comprehension of your product on users with a 5 Seconds Test.",
        image: "fivesec-placeholder.svg",
        isComingSoon: false,
        methodology: "Five Seconds Test",
        link: "https://app.userq.com/p/59850f",
    },
    {
        title: "First click",
        description:
            "Check where users first click on an interface to complete their task.",
        image: "firstclick-placeholder.svg",
        isComingSoon: true,
        methodology: "First click",
        link: "https://app.userq.com/p/346261",
    },
];