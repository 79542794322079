import React, { useState, useEffect } from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import { preventClick, showToast } from '../../lib/helpers';
import { Modal } from 'react-bootstrap';
import InputField from '../layouts/elements/fields/input';
import toast from 'react-hot-toast';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession';
// import { FullPageLoadingIcon } from './../loader/full_page_loader_lcon_New';
import { FullPageLoadingIcon } from '../loader/full_page_loader_lcon_New';
import { Logo } from '../themes/userq/Logo/Logo';
import { Icon } from '../themes/userq/Icon/Icon';
import { Text } from '../themes/userq/Text/Text';

export default function ConfirmEmail() {

    let { code } = useParams();

    const [formValues, setFormValues] = useState({email:'', confirm_email:'', code: code});
    const [isLoading, setLoading] = useState(false);

    const [isEmailVerified, setEmailVerified] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        changeAndVerifyEmail()
        document.title = process.env.REACT_APP_NAME+" - Confirm Email";
    }, []);

    const changeAndVerifyEmail = () => {
        
        setLoading(true);

        var body = formValues;

        
        fetch(process.env.REACT_APP_API_END_POINT+'verify-email', {
            method: 'POST',
            body: JSON.stringify(formValues),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);
            if(response.success){
                setEmailVerified(true);
                ReactSession.set("token",null);
                ReactSession.set("user",null);
                if(response.user_role=="Researcher"){
                  navigate('/researcher/sign-in');
                }else if(response.user_role=="Tester"){
                  navigate('/tester/sign-in');
                }else{
                  navigate('/researcher/sign-in');
                }
            } else {
               //showToast(response.message,"error");
            }
        });
    }
    
    return (
        <div>
            {isLoading==true
            ?
            <FullPageLoadingIcon/> 
            :
            <div className="login-page-wrapper">
            
            {!isLoading &&
            <>
            <div className="researcher-registerheader login-page-header">
                <div className="container">
                    <div className="login-hdr-left">
                        <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                            
                            <Logo theme={"black"} background={"white"} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        
                        <div>
                            
                            {!isEmailVerified &&
                                <div>
                                    <div className={`no-participants-data no-questions-data no-questions-added`}>
                                        <div className="no-page-data-wrap" style={{marginTop:"80px"}}>
                                            <div className="no-data-icon">
                                                <Icon value={"exclamation-gray"} size={"large"} />
                                            </div>
                                            <Text type={"subtitle-1"} fontWeight="medium-font">
                                                It looks like this page doesn’t exist.
                                            </Text>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
            </>
            }
        </div>
            }  
        </div>
    )
}