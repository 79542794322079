import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession.js';
import React, {useState, useEffect} from "react";
import ResearcherDashboard from './researcher.js';
import TesterDashboard from './tester.js';
import ChooseWorkspaces from './choose-workspaces.js';

export default function Dashboard() {

    ReactSession.setStoreType("localStorage");
    const navigate = useNavigate();
    const [user,setUser] = useState({"role":""});

    
      
    useEffect(() => {
      document.title =  "Your dashboard | "+process.env.REACT_APP_NAME;
        let user = ReactSession.get("user");
        setUser(user);
    }, []);
     
    const loginUserDashboard = () => {
        if(user.role=="Tester"){
            return <TesterDashboard />
        } else if (user.role == "Researcher") {
          return <ChooseWorkspaces />;
        } else if (user.role == "Collaborator") {
          return <ResearcherDashboard />;
        } else {
          return <></>;
        }
    }
    return (
        <>
            {loginUserDashboard()}
        </>
        
    )
}