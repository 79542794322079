import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { Button } from "../Button/Button";
import { CircularNameIconGroup } from "../CircularNameIcon/CircularNameIconGroup";
import { Icon } from "../Icon/Icon";
import { Logo } from "../Logo/Logo";
import { getCreditSummaryService } from "../../../../services/credits";
import { logoutService } from "../../../../services/user";
import { roundNumber, showToast } from "../../../../lib/helpers";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import Skeleton from "react-loading-skeleton";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Dropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import { Text } from "../Text/Text";
import { CircularNameIcon } from "../CircularNameIcon/CircularNameIcon";
import { useTestBuilderData } from "../../../researcher/tests/contexts/TestBuilderContext";
import {
    getWorkspaceService,
    getWorkspacesListService,
    acceptWorkInviteDashboardService,
    declineWorkInviteDashboardService,
    dismissInviteTooltipService,
    setDisplayWorkspaceFeatureService,
    dismissNewAdminModalService,
} from "../../../../services/workspaces";
import Wizard from "../../../researcher/workspace/wizard/wizard";
import ReviewPaymentModal from "../../../researcher/workspace/modals/review-payment-modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { RadioButton } from "../RadioButton/RadioButton";
import { Tag } from "../Tag/Tag";
import { getPaymentMethodsService } from "../../../../services/payment_methods";
import validator from "validator";
import { ToastMessage } from "../ToastMessage/ToastMessage";

import moment from "moment";
import { LoadingIcon } from "../../../loader/loadingIcon";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const Header = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentComponent {...props} />
        </Elements>
    );
};

const PaymentComponent = ({
    collaborators,
    user,
    resultHeaderClass,
    openSupportPanel,
    workspaceName,
    ...props
}) => {
    const stripe = useStripe();

    const {
        header_workspaces_list_reload,
        setHeaderWorkspacesListReload,
        events,
    } = useTestBuilderData();

    const [isOpen, setIsOpen] = useState(false);

    const [slideOpen, setSlideOpen] = useState(false);

    const dropdownRef = useRef(null);

    const [currentInvitedWorkspace, setCurrentInvitedWorkspace] =
        useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [creditsLoading, setCreditsLoading] = useState(false);
    const [workspaceLoading, setWorkspaceLoading] = useState(false);
    const [workspacesListLoading, setWorkspacesListLoading] = useState(false);
    const [workspace, setWorkspace] = useState(
        props.workspace ? props.workspace : null
    );
    const [seats, setSeats] = useState(0);
    const [workspaces, setWorkspaces] = useState(
        props.workspaces ? props.workspaces : []
    );
    const [allWorkspaces, setAllWorkspaces] = useState([]);
    const [menuClass, setMenuClass] = useState("");
    const [members, setMembers] = useState([]);
    let { workspaceid } = useParams();
    const creditsState = atom({
        key: "credits",
        default: 0,
    });
    const [credits, setCredits] = useRecoilState(creditsState);
    const resetCredits = useResetRecoilState(creditsState);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const tooltipRef = useRef(null);
    const dropdownCompleteRef = useRef(null);

    const [invitedWorkspaceIds, setInvitedWorkspaceIds] = useState([]);

    const [invitationLoading, setInvitationLoading] = useState(false);
    const [declineInviteLoading, setDeclineInvitationLoading] = useState(false);

    const [hideWorkspace, setHideWorkspace] = useState(false);

    const [wizardOptions, setWizardOptions] = useState({
        open: false,
        currentStep: 0,
    });

    const [formErrors, setFormErrors] = useState({
        card_no: null,
        card_name: null,
        expiration_date: null,
        cvv: null,
        error_class: null,
    });

    const [formValues, setFormValues] = useState({
        card_no: "",
        exp_date: "",
        cvv: "",
        card_name: "",
        payment_method_id: "",
    });

    const [pageLoading, setPageLoading] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [reviewPaymentModal, setReviewPaymentModal] = useState({
        open: false,
    });

    const [paymentMethodCount, setPaymentMethodCount] = useState(0);

    const [addNewCard, setAddNewCard] = useState(false);

    const [futurePaymentCard, setFuturePaymentCard] = useState({
        paymentMethodId: null,
        brand: null,
        last_4: null,
        default: 0,
        id: null,
    });

    const [changePaymentMethodLoading, setChangePaymentMethodLoading] =
        useState(false);

    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

    const [sameCardError, setSameCardError] = useState(false);

    const [showNewAdminPayment, setShowNewAdminPayment] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);

    const [showNewAdminPaymentPopup, setShowNewAdminPaymentPopup] =
        useState(false);

    const [loggingOutLoader, setLoggingOutLoader] = useState(false);

    const toggleMenu = () => {
        setSlideOpen(!slideOpen);
    };

    useEffect(() => {
        if (slideOpen) {
            document.body.classList.add("open-m-menu");
        } else {
            document.body.classList.remove("open-m-menu");
        }

        return () => {
            document.body.classList.remove("open-m-menu");
        };
    }, [slideOpen]);

    useEffect(() => {
        if (wizardOptions.open) {
            document.body.classList.add("wirard-active-body");
        } else {
            document.body.classList.remove("wirard-active-body");
        }
    }, [wizardOptions.open]);

    useEffect(() => {
        events.forEach(function (event) {
            if (event.event_type === "new_invitation") {
                getWorkspacesListApi();
            } else if (event.event_type === "delete_workspace") {
                if (workspace && workspace.id === event.event_id) {
                    navigate("/wsp/" + event.event_id + "/noaccess");
                } else {
                    let allWorkspacesLoc = [];

                    let workspacesLoc = [];

                    allWorkspaces.forEach(function (workspace_item) {
                        if (workspace_item.id !== event.event_id) {
                            allWorkspacesLoc.push(workspace_item);
                        }
                    });
                    workspaces.forEach(function (workspace_item) {
                        if (workspace_item.id !== event.event_id) {
                            workspacesLoc.push(workspace_item);
                        }
                    });
                    setAllWorkspaces(allWorkspacesLoc);

                    setWorkspaces(workspacesLoc);
                }
            } else if (
                event.event_type === "workspace_admin_added" &&
                workspace &&
                workspace.id === event.event_id
            ) {
                getWorkspaceApi();
            }
        });
    }, [events]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target)
            ) {
                setTooltipVisible(false);
            }
            // if (
            //     dropdownCompleteRef.current &&
            //     !dropdownCompleteRef.current.contains(event.target)
            // ) {
            //     if(!wizardOptions.open){
            //         setIsOpen(false);
            //     }

            // }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownCompleteRef.current &&
                !dropdownCompleteRef.current.contains(event.target)
            ) {
                if (!wizardOptions.open) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wizardOptions.open]);

    const logout = (e) => {
        e.preventDefault();

        let token = ReactSession.get("token");

        document.body.classList.remove("open-m-menu");

        setLoggingOutLoader(true);

        logoutService(token).then((response) => {
            if (response.success) {
                setLoggingOutLoader(false);

                let user = ReactSession.get("user");
                if (!user) {
                    navigate("/tester/sign-in");
                }

                resetCredits();
                Cookies.remove("expires_at", { path: "" });
                ReactSession.get("workspace", null);
                ReactSession.set("token", null);
                ReactSession.set("prototype_download_failed_messages", null);
                ReactSession.set("user", null);
                ReactSession.set("plan", null);
                ReactSession.set("show_subscribed", null);
                ReactSession.set("username", null);
                ReactSession.set("new_target_id", null);
                localStorage.clear();
                if (user.role == "Tester") {
                    navigate("/tester/sign-in");
                } else {
                    navigate("/researcher/sign-in");
                }
            }
        });
    };
    const goToHome = () => {
        let user = ReactSession.get("user");
        if (user.role == "Tester") {
            return process.env.REACT_APP_TESTER_URL;
        } else {
            return process.env.REACT_APP_RESEARCHER_URL;
        }
    };
    const fetchCreditsSummary = (stoploading = null) => {
        console.log(stoploading);
        let token = ReactSession.get("token");
        setCreditsLoading(true);
        setWorkspaceLoading(true);
        if (!token) {
            setCreditsLoading(false);
            ReactSession.set("credits", 0);
            setCredits(0);
        } else {
            let session_workspace_id = null;

            let session_workspace = ReactSession.get("workspace");

            if (session_workspace) {
                session_workspace_id = session_workspace.workspace_id;
            }

            let workspace_id = workspaceid ? workspaceid : session_workspace_id;
            //setWorkspaceLoading(true);
            if (workspace_id) {
                getCreditSummaryService(
                    { workspace_id: workspace_id },
                    token
                ).then((response) => {
                    //setCreditsLoading(false);
                    if (stoploading) {
                        setWorkspaceLoading(false);
                        setCreditsLoading(false);
                    } else {
                        getWorkspacesListApi();
                    }
                    if (response.success) {
                        ReactSession.set(
                            "credits",
                            response.summary.credits_balance
                        );
                        setCredits(response.summary.credits_balance);
                    }
                });
            }
        }
    };
    useEffect(() => {
        let user = ReactSession.get("user");
        if (!user) {
            navigate("/tester/sign-in");
        }
        //fetchCreditsSummary(true);
    }, [credits]);
    useEffect(() => {
        /*let session_workspace_id = null;

        let session_workspace = ReactSession.get("workspace");

        if (session_workspace) {
            session_workspace_id = session_workspace.workspace_id;
        }

        let workspace_id = workspaceid ? workspaceid : session_workspace_id;

        allWorkspaces.forEach((workspace_item) => {
            if (parseInt(workspace_id) === parseInt(workspace_item.id)) {
                setWorkspace(workspace_item);

                ReactSession.set("workspace", {
                    workspace_id: workspace_item.id,
                    type: workspace_item.type,
                });
            }
        });*/
        //fetchCreditsSummary(false);

        const regex = /wsp\/[^/]+\/noaccess/i;
        const regex_dashboard = /^\/dashboard\/?$/;

        var no_access = false;

        const searchParams = new URLSearchParams(window.location.search);

        const from = searchParams.get("from");

        if (
            regex.test(window.location.pathname) ||
            regex_dashboard.test(window.location.pathname) ||
            (searchParams.has("from") &&
                from !== null &&
                from === "noaccess") ||
            window.location.pathname === `/r/delete-workspace`
        ) {
            no_access = true;
        }

        if (!no_access) {
            if (!props.from) {
                fetchCreditsSummary(false);
            }
            //getWorkspacesListApi();
        } else {
            setHideWorkspace(true);
        }

        if (
            window.location.pathname === `/wsp/${workspaceid}` ||
            window.location.pathname === `/wsp/${workspaceid}/credits` ||
            window.location.pathname === `/wsp/${workspaceid}/team-&-settings`
        ) {
            setShowNewAdminPayment(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        let user = ReactSession.get("user");

        // console.log("what is my user_id", user);
        let invitedWorkspaceLocal = [...invitedWorkspaceIds];

        workspaces.forEach((workspace_item) => {
            if (workspace_item.id !== workspaceid) {
                let invited = workspace_item.workspace_team_members
                    .filter((item) => {
                        return (
                            user &&
                            item.user_id == user.user_id &&
                            item.status === "invitation_sent"
                        );
                    })
                    .map((ele) => ele.workspace_id);

                if (!invitedWorkspaceLocal.includes(...invited)) {
                    invitedWorkspaceLocal.push(...invited);
                }

                for (const [
                    index,
                    teamMember,
                ] of workspace_item.workspace_team_members.entries()) {
                    if (
                        user &&
                        teamMember.user_id == user.user_id &&
                        teamMember.status === "invitation_sent" &&
                        teamMember.invite_tooltip === 0
                    ) {
                        setTooltipVisible(true);
                        dismissTooltip();
                        break;
                    }
                }
            }
        });

        setInvitedWorkspaceIds(invitedWorkspaceLocal);
        // console.log("setInvitedWorkspaceIds", invitedWorkspaceLocal);
    }, [workspaces]);

    useEffect(() => {
        //setIsOpen(!isOpen);

        let workspacesLocal = [];

        allWorkspaces.forEach((workspace_item) => {
            if (workspace && workspace.id != parseInt(workspace_item.id)) {
                workspacesLocal.push(workspace_item);
            }
        });
        if (workspace) {
            let user = ReactSession.get("user");
            let membersLocal = [];
            let loggedInMember = {};
            if (workspace.workspace_team_members) {
                workspace.workspace_team_members.forEach(function (member) {
                    if (user && member.user_id != user.user_id) {
                        membersLocal.push({
                            label:
                                member.user && member.user != null
                                    ? member.user.first_name != null
                                        ? member.user.first_name
                                              .toUpperCase()
                                              .charAt(0)
                                        : member.user.email
                                              .toUpperCase()
                                              .charAt(0)
                                    : member.email && member.email.length > 0
                                    ? member.email.toUpperCase().charAt(0)
                                    : "",
                            color:
                                member.status == "invitation_sent"
                                    ? "#9C9C9C"
                                    : member.color,
                            tooltip:
                                member.user && member.user != null
                                    ? member.user.first_name +
                                      " " +
                                      member.user.last_name
                                    : member.email,
                        });
                    } else {
                        loggedInMember = {
                            label:
                                member.user && member.user != null
                                    ? member.user.first_name != null
                                        ? member.user.first_name
                                              .toUpperCase()
                                              .charAt(0)
                                        : member.user.email
                                              .toUpperCase()
                                              .charAt(0)
                                    : member.email && member.email.length > 0
                                    ? member.email.toUpperCase().charAt(0)
                                    : "",
                            color:
                                member.status == "invitation_sent"
                                    ? "#9C9C9C"
                                    : member.color,
                            tooltip:
                                member.user && member.user != null
                                    ? member.user.first_name +
                                      " " +
                                      member.user.last_name
                                    : member.email,
                        };
                    }
                });
                membersLocal = [loggedInMember, ...membersLocal];

                setMembers(membersLocal);
            }
        }

        setWorkspaces(workspacesLocal);
    }, [workspace]);

    /*useEffect(() => {
        const regex = /wsp\/[^/]+\/noaccess/i;

        var no_access = false;

        const searchParams = new URLSearchParams(window.location.search);

        const from = searchParams.get("from");

        if (
            regex.test(window.location.pathname) ||
            (searchParams.has("from") &&
                from !== null &&
                from === "noaccess") ||
            window.location.pathname === `/r/delete-workspace`
        ) {
            no_access = true;
        }

        if (!no_access) {
            fetchCreditsSummary(false);
            //getWorkspacesListApi();
        } else {
            setHideWorkspace(true);
        }

        if (
            window.location.pathname === `/wsp/${workspaceid}` ||
            window.location.pathname === `/wsp/${workspaceid}/credits` ||
            window.location.pathname === `/wsp/${workspaceid}/team-&-settings`
        ) {
            setShowNewAdminPayment(true);
        }
    }, []);*/
    useEffect(() => {
        if (header_workspaces_list_reload) {
            setInvitedWorkspaceIds([]);
            setHeaderWorkspacesListReload(false);
            const regex = /wsp\/[^/]+\/noaccess/i;

            const regex_dashboard = /^\/dashboard\/?$/;

            var no_access = false;

            const searchParams = new URLSearchParams(window.location.search);

            const from = searchParams.get("from");

            if (
                regex.test(window.location.pathname) ||
                regex_dashboard.test(window.location.pathname) ||
                (searchParams.has("from") &&
                    from !== null &&
                    from === "noaccess") ||
                window.location.pathname === `/r/delete-workspace`
            ) {
                no_access = true;
            }

            if (!no_access) {
                getWorkspacesListApi();
            } else {
                console.log("this is no access", from, searchParams);
                setHideWorkspace(true);
            }
        }
    }, [header_workspaces_list_reload]);
    const getWorkspaceApi = () => {
        let token = ReactSession.get("token");

        setWorkspaceLoading(true);

        let session_workspace_id = null;

        let session_workspace = ReactSession.get("workspace");

        if (session_workspace) {
            session_workspace_id = session_workspace.workspace_id;
        }

        let workspace_id = workspaceid ? workspaceid : session_workspace_id;

        getWorkspaceService(token, workspace_id)
            .then((response) => {
                setWorkspaceLoading(false);

                setCreditsLoading(false);

                if (response.success) {
                    console.log(response.workspace);
                    ReactSession.set("workspace", {
                        workspace_id: response.workspace.id,
                        type: response.workspace.type,
                        title: response.workspace.title,
                    });

                    setWorkspace(response.workspace);

                    setSeats(response.seats);

                    if (response.is_admin) {
                        setIsAdmin(true);
                    }

                    if (
                        response.workspace_team_member &&
                        response.workspace_team_member
                            .workspace_features_displayed === 1
                    ) {
                        if (
                            window.location.pathname === `/wsp/${workspaceid}`
                        ) {
                            setWizardOptions({
                                ...wizardOptions,
                                open: true,
                                currentStep: 0,
                            });
                        }
                    }

                    if (
                        response.workspace &&
                        response.workspace.is_new_admin_proposed === 1 &&
                        response.is_admin &&
                        !hideWorkspace &&
                        window.location.pathname !==
                            `/wsp/${workspaceid}/workspace-billing`
                    ) {
                        if (response.workspace.is_new_admin_popup === 1) {
                            setShowNewAdminPaymentPopup(true);
                        }

                        if (response.workspace.payment_method) {
                            setFuturePaymentCard({
                                ...futurePaymentCard,
                                paymentMethodId:
                                    response.workspace.payment_method
                                        .stripe_payment_method_id,
                                default:
                                    response.workspace.payment_method.default,
                                last_4: response.workspace.payment_method
                                    .last_4,
                                brand: response.workspace.payment_method.brand,
                                id: response.workspace.payment_method.id,
                            });
                            getPaymentMethods(
                                response.workspace.payment_method.id,
                                false,
                                response.workspace.is_new_admin_popup === 1
                                    ? true
                                    : false,
                                response.workspace.id
                            );
                        } else {
                            getPaymentMethods(
                                null,
                                false,
                                response.workspace.is_new_admin_popup === 1
                                    ? true
                                    : false,
                                response.workspace.id
                            );
                        }
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);
                setWorkspaceLoading(false);
            });
    };

    const getWorkspacesListApi = () => {
        let token = ReactSession.get("token");

        setWorkspaceLoading(true);

        getWorkspacesListService(token)
            .then((response) => {
                setWorkspaceLoading(false);

                if (response.success) {
                    console.log(response.workspaces);
                    setAllWorkspaces(response.workspaces);

                    getWorkspaceApi();
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);
                setWorkspaceLoading(false);
            });
    };

    const dismissTooltip = () => {
        let token = ReactSession.get("token");
        // setPageLoading(true);
        dismissInviteTooltipService(token)
            .then((response) => {
                // console.log("tooltip dismissed");
            })
            .catch((error) => {
                // setPageLoading(false);
            });
    };

    useEffect(() => {
        if (tooltipVisible) {
            const timeout = setTimeout(() => {
                setTooltipVisible(false);
            }, 30000);

            return () => clearTimeout(timeout);
        }
    }, [tooltipVisible]);

    const acceptInvite = (workspaceId, redirect = false) => {
        if (!invitationLoading && !declineInviteLoading) {
            setCurrentInvitedWorkspace(workspaceId);
            let token = ReactSession.get("token");
            let formData = {};
            formData.workspace_id = workspaceId;
            if (redirect) {
                // setWorkspaceLoading(true);
            } else {
                setInvitationLoading(true);
            }

            acceptWorkInviteDashboardService(formData, token)
                .then((response) => {
                    if (redirect) {
                    } else {
                        setInvitationLoading(false);
                    }

                    if (response.success) {
                        showToast(response.message, "success");
                        let invitedWorkspaceIdsLocal = invitedWorkspaceIds;

                        const index =
                            invitedWorkspaceIdsLocal.indexOf(workspaceId);
                        if (index > -1) {
                            invitedWorkspaceIdsLocal.splice(index, 1);
                        }

                        setInvitedWorkspaceIds([...invitedWorkspaceIdsLocal]);
                        let workspacesLocal = [...allWorkspaces];
                        let workSpacesLocalDup = [];

                        workspacesLocal.forEach((workspace_item) => {
                            if (workspace_item.id === workspaceId) {
                                let workSpacesLocalCopyMember =
                                    workspace_item.workspace_team_members.map(
                                        (item) => {
                                            if (
                                                item.user_id == user.user_id &&
                                                item.status ===
                                                    "invitation_sent"
                                            ) {
                                                return {
                                                    ...item,
                                                    status: "invitation_accepted",
                                                    invite_tooltip: 1,
                                                };
                                            } else {
                                                return {
                                                    ...item,
                                                    invite_tooltip: 1,
                                                };
                                            }
                                        }
                                    );
                                workSpacesLocalDup.push({
                                    ...workspace_item,
                                    workspace_team_members:
                                        workSpacesLocalCopyMember,
                                });
                            } else {
                                let workSpacesLocalCopyMember =
                                    workspace_item.workspace_team_members.map(
                                        (item) => {
                                            return {
                                                ...item,
                                                invite_tooltip: 1,
                                            };
                                        }
                                    );
                                workSpacesLocalDup.push({
                                    ...workspace_item,
                                    workspace_team_members:
                                        workSpacesLocalCopyMember,
                                });
                                // workSpacesLocalDup.push(workspace_item);
                            }
                        });
                        setAllWorkspaces(workSpacesLocalDup);
                        if (redirect) {
                            toggleDropdown();
                            navigate(`/wsp/${workspaceId}`);
                        }
                    } else {
                        if (
                            response.error_code &&
                            response.error_code.includes(
                                "You no longer have access to the workspace"
                            )
                        ) {
                            if (redirect) {
                                toggleDropdown();
                                navigate(`/wsp/${workspaceId}`);
                            } else {
                                showToast(response.message, "error");
                                updateWorkspaces(workspaceId);
                            }
                        } else {
                            showToast(response.message, "error");
                        }
                    }
                })
                .catch((error) => {
                    if (redirect) {
                    } else {
                        setInvitationLoading(false);
                    }
                    showToast(error, "error");
                });
        }
    };

    const declineInvite = (workspaceId) => {
        if (!declineInviteLoading && !invitationLoading) {
            setCurrentInvitedWorkspace(workspaceId);
            let token = ReactSession.get("token");
            let formData = {};
            formData.workspace_id = workspaceId;
            setDeclineInvitationLoading(true);

            declineWorkInviteDashboardService(formData, token)
                .then((response) => {
                    setDeclineInvitationLoading(false);
                    if (response.success) {
                        showToast(response.message, "success");
                        let invitedWorkspaceIdsLocal = invitedWorkspaceIds;

                        const index =
                            invitedWorkspaceIdsLocal.indexOf(workspaceId);
                        if (index > -1) {
                            invitedWorkspaceIdsLocal.splice(index, 1);
                        }
                        setInvitedWorkspaceIds([...invitedWorkspaceIdsLocal]);

                        let allWorkSpacesLocalDup = [];
                        let workSpacesLocalDup = [];

                        allWorkspaces.forEach((workspace_item) => {
                            if (workspace_item.id !== workspaceId) {
                                let workSpacesLocalCopyMember =
                                    workspace_item.workspace_team_members.map(
                                        (item) => {
                                            return {
                                                ...item,
                                                invite_tooltip: 1,
                                            };
                                        }
                                    );
                                allWorkSpacesLocalDup.push({
                                    ...workspace_item,
                                    workspace_team_members:
                                        workSpacesLocalCopyMember,
                                });
                                // allWorkSpacesLocalDup.push(workspace_item);
                            }
                        });
                        workspaces.forEach((workspace_item) => {
                            if (workspace_item.id !== workspaceId) {
                                let workSpacesLocalCopyMember =
                                    workspace_item.workspace_team_members.map(
                                        (item) => {
                                            return {
                                                ...item,
                                                invite_tooltip: 1,
                                            };
                                        }
                                    );
                                workSpacesLocalDup.push({
                                    ...workspace_item,
                                    workspace_team_members:
                                        workSpacesLocalCopyMember,
                                });
                                // workSpacesLocalDup.push(workspace_item);
                            }
                        });
                        setAllWorkspaces(allWorkSpacesLocalDup);
                        setWorkspaces(workSpacesLocalDup);
                    } else {
                        showToast(response.message, "error");
                        if (
                            response.error_code &&
                            response.error_code.includes(
                                "You no longer have access to the workspace"
                            )
                        ) {
                            updateWorkspaces(workspaceId);
                        }
                    }
                })
                .catch((error) => {
                    setDeclineInvitationLoading(false);
                    showToast(error, "error");
                });
        }
    };

    useEffect(() => {
        if (workspaceName) {
            setWorkspace({ ...workspace, title: workspaceName });
        }
    }, [workspaceName]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (dropdownRef.current) {
            dropdownRef.current.scrollTop = 0; // Scroll to top
        }
    };

    const updateWorkspaces = (workspaceId) => {
        let invitedWorkspaceIdsLocal = invitedWorkspaceIds;

        const index = invitedWorkspaceIdsLocal.indexOf(workspaceId);
        if (index > -1) {
            invitedWorkspaceIdsLocal.splice(index, 1);
        }
        setInvitedWorkspaceIds([...invitedWorkspaceIdsLocal]);

        let allWorkSpacesLocalDup = [];
        let workSpacesLocalDup = [];

        allWorkspaces.forEach((workspace_item) => {
            if (workspace_item.id !== workspaceId) {
                let workSpacesLocalCopyMember =
                    workspace_item.workspace_team_members.map((item) => {
                        return {
                            ...item,
                            invite_tooltip: 1,
                        };
                    });
                allWorkSpacesLocalDup.push({
                    ...workspace_item,
                    workspace_team_members: workSpacesLocalCopyMember,
                });
                // allWorkSpacesLocalDup.push(workspace_item);
            }
        });
        workspaces.forEach((workspace_item) => {
            if (workspace_item.id !== workspaceId) {
                let workSpacesLocalCopyMember =
                    workspace_item.workspace_team_members.map((item) => {
                        return {
                            ...item,
                            invite_tooltip: 1,
                        };
                    });
                workSpacesLocalDup.push({
                    ...workspace_item,
                    workspace_team_members: workSpacesLocalCopyMember,
                });
                // workSpacesLocalDup.push(workspace_item);
            }
        });
        setAllWorkspaces(allWorkSpacesLocalDup);
        setWorkspaces(workSpacesLocalDup);
    };

    useEffect(() => {
        if (wizardOptions.open) {
            if ([1, 2].includes(wizardOptions.currentStep)) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }
    }, [wizardOptions]);

    const dismissWizard = () => {
        if (wizardOptions.open) {
            setDisplayWorkspaceFeatureService(
                { workspace_id: workspaceid },
                ReactSession.get("token")
            ).then((response) => {});

            setWizardOptions({
                ...wizardOptions,
                open: false,
                currentStep: 0,
            });
        }
    };

    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method) {
            render.push(
                <>
                    <div
                        key={"methods" + method.id}
                        className="payments-cards d-flex addedpayment-cards-wrap"
                    >
                        <div className="card-left-side">
                            {method.card_expire_status != 2 && (
                                <RadioButton
                                    id={method.stripe_payment_method_id}
                                    type="radio"
                                    isChecked={
                                        formValues.payment_method_id ==
                                        method.id
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        if (!changePaymentMethodLoading) {
                                            setFormValues({
                                                ...formValues,
                                                payment_method_id: method.id,
                                                card_no: "",
                                                cvv: "",
                                                exp_date: "",
                                            });
                                            setSameCardError(false);
                                        }
                                    }}
                                    name="payment_method"
                                    value={method.stripe_payment_method_id}
                                    className="hidden radio_btn_input"
                                    required="required"
                                />
                            )}
                            <label
                                htmlFor={method.stripe_payment_method_id}
                                className="mt-0 d-flex"
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "images/" +
                                        method.brand +
                                        "-icon.svg"
                                    }
                                    alt="img"
                                />
                            </label>
                        </div>
                        <div className="card-right-side">
                            {method.card_expire_status == 2 ? (
                                <Tag title="Expired" type="danger-tag" />
                            ) : (
                                <>
                                    {method.default == 1 && (
                                        <Tag title="Primary" />
                                    )}
                                </>
                            )}

                            <Text type={"body-text-3"}>
                                **** {method.last_4}
                            </Text>

                            {method.default == 0 && <></>}
                        </div>
                    </div>
                    {(formValues.payment_method_id !== "" &&
                    formValues.payment_method_id === method.id &&
                    method.id !== futurePaymentCard.id
                        ? true
                        : false) && (
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={
                                "gray-color d-flex align-items-center mt-20 crad-saved-for-future"
                            }
                            key={"methods-tick" + method.id}
                        >
                            <Icon
                                value={"check mark"}
                                size={"medium"}
                                colorClass={"success-green-200-svg"}
                            />
                            This card will be used for the future subscription
                            payments
                        </Text>
                    )}

                    {(method.id === futurePaymentCard.id ? true : false) && (
                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={
                                "gray-color d-flex align-items-center mt-20 crad-saved-for-future"
                            }
                            key={"future-methods-tick" + method.id}
                        >
                            <Icon
                                value={"check mark"}
                                size={"medium"}
                                colorClass={"success-green-200-svg"}
                            />
                            This card is currently being used for your workspace
                            subscription
                        </Text>
                    )}
                </>
            );
        });

        if (render.length > 0) {
            return (
                <>
                    <div className="cardlabel-box">
                        <Text type={"overline"} cssClasses={"saved-card"}>
                            Saved cards
                        </Text>
                        {render}
                    </div>
                </>
            );
        } else {
            return (
                <></>
                // <div className="no_payment_add_box">
                //     <Text type={"subtitle-2"} fontWeight={"medium-font"} cssClasses={'no_payment_add'}>
                //         <Icon value="attention" colorClass={'gray-700-svg attention-payment'} />
                //         No payment method added.
                //     </Text>
                // </div>
            );
        }
    };
    const getPaymentMethods = (
        paymentId = null,
        paymentLoad = false,
        isNewAdmin = false,
        workspaceId = null
    ) => {
        let token = ReactSession.get("token");

        getPaymentMethodsService(token).then((response) => {
            if (paymentLoad) {
                setPaymentMethodLoading(false);
            } else {
                setPageLoading(false);
            }

            if (response.success) {
                var exist = false;
                response.payment_methods.forEach(function (method) {
                    // if (method.default == 1) {
                    //     exist = true;
                    //     setFormValue("payment_method_id", method.id);
                    // }

                    if (method.id == paymentId) {
                        exist = true;
                        setFormValue("payment_method_id", method.id);
                    }
                });
                if (!exist) {
                    if (response.payment_methods.length > 0) {
                        setFormValue(
                            "payment_method_id",
                            response.payment_methods[0].id
                        );
                    }
                }
                setPaymentMethods(response.payment_methods);

                if (response.payment_methods.length === 0) {
                    setAddNewCard(true);
                }

                setPaymentMethodCount(response.payment_methods.length);

                if (isNewAdmin) {
                    setReviewPaymentModal({
                        ...reviewPaymentModal,
                        open: true,
                    });

                    dismissNewAdminModalService(
                        { workspace_id: workspaceId },
                        ReactSession.get("token")
                    ).then((response) => {});
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const resetPaymentMethods = () => {
        setSameCardError(false);
        var exist = false;
        paymentMethods.forEach(function (method) {
            // if (method.default == 1) {
            //     exist = true;
            //     // setFormValue("payment_method_id", method.id);
            //     setFormValues({
            //         ...formValues,
            //         card_no: "",
            //         exp_date: "",
            //         cvv: "",
            //         payment_method_id: method.id
            //     })
            // }

            if (method.id == futurePaymentCard.id) {
                exist = true;
                setFormValues({
                    ...formValues,
                    card_no: "",
                    exp_date: "",
                    cvv: "",
                    payment_method_id: method.id,
                });
            }
        });
        if (!exist) {
            if (paymentMethods.length > 0) {
                setFormValues({
                    ...formValues,
                    card_no: "",
                    exp_date: "",
                    cvv: "",
                    payment_method_id: paymentMethods[0].id,
                });
            }
        }

        // check if user have not added any card then adding new card will be enable automatically
        if (paymentMethods.length === 0) {
            setAddNewCard(true);
            setFormValues({
                ...formValues,
                card_no: "",
                exp_date: "",
                cvv: "",
                payment_method_id: "",
            });
        } else {
            setAddNewCard(false);
        }
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const validatePaymentForm = () => {
        var error = false;

        if (formValues.card_no == "") {
            error = true;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            error = true;
        }

        if (formValues.exp_date == "") {
            error = true;
        }
        var new_exp_date = formValues.exp_date.replaceAll(" ", "");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {
            if (
                new Date().getTime() >
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime() ||
                isNaN(
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime()
                )
            ) {
                error = true;
            }
        } else if (new_exp_date.length > 0) {
            error = true;
        }
        if (formValues.cvv == "") {
            error = true;
        }
        return error;
    };

    const frozenPaymentMethodUpdate = () => {
        // let payment_method_modal = {
        //     open : true,
        //     workspace_id : workspaceid,
        //     status: "frozen"
        // };
        ReactSession.set("payment_method_modal", null);
        // navigate(`/wsp/${workspaceid}/workspace-billing`);
        setReviewPaymentModal({
            ...reviewPaymentModal,
            open: true,
        });
    };

    return (
        <>
            {(process.env.REACT_APP_URL.includes("localhost") ||
                process.env.REACT_APP_URL.includes("apprevampui") ||
                process.env.REACT_APP_URL.includes("appuat") ||
                process.env.REACT_APP_URL.includes("appdevelopment")) && (
                <div
                    className={`test-environment-header ${
                        wizardOptions.open ? "d-none" : ""
                    }`}
                >
                    This is a test environment
                </div>
            )}

            <div
                className={`header researcher-header workspace-main-header ${
                    resultHeaderClass ? resultHeaderClass : ""
                } ${wizardOptions.open ? "wizard-active" : ""} 
                ${
                    wizardOptions.open && wizardOptions.currentStep === 0
                        ? "wizard-active-1"
                        : ""
                }
                ${
                    wizardOptions.open && wizardOptions.currentStep === 1
                        ? "wizard-active-2"
                        : ""
                }
                ${
                    wizardOptions.open && wizardOptions.currentStep === 2
                        ? "wizard-active-3"
                        : ""
                }
                ${
                    wizardOptions.open && wizardOptions.currentStep === 3
                        ? "wizard-active-4"
                        : ""
                }`}
            >
                <div className="container">
                    <div className="header-inner-data body-text body-text body-text-2 medium-font none-for-mobile">
                        <div className="header-lhs-side">
                            <div
                                className="logo"
                                onClick={() => {
                                    console.log(process.env.REACT_APP_URL);
                                }}
                            >
                                <Link
                                    to={(function (e) {
                                        const regex_dashboard =
                                            /^\/dashboard\/?$/;

                                        if (
                                            regex_dashboard.test(
                                                window.location.pathname
                                            )
                                        ) {
                                            return "/dashboard";
                                        } else if (workspaceid) {
                                            return "/wsp/" + workspaceid;
                                        } else {
                                            let session_workspace_id = null;

                                            let session_workspace =
                                                ReactSession.get("workspace");

                                            if (session_workspace) {
                                                session_workspace_id =
                                                    session_workspace.workspace_id;

                                                return (
                                                    "/wsp/" +
                                                    session_workspace_id
                                                );
                                            } else {
                                                return "/";
                                            }
                                        }
                                    })()}
                                >
                                    <Logo
                                        theme={"white"}
                                        background={"black"}
                                    />
                                </Link>
                            </div>
                            {!hideWorkspace && (
                                <>
                                    <div className="hdr-workspace-data">
                                        <div
                                            className={`workspace-dropdown added-workspace-dropdown ${
                                                wizardOptions.open &&
                                                wizardOptions.currentStep === 0
                                                    ? "wizard-step-one active-wborder"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                if (
                                                    wizardOptions.open &&
                                                    wizardOptions.currentStep ===
                                                        0
                                                ) {
                                                    dismissWizard();
                                                }
                                            }}
                                        >
                                            <Dropdown
                                                className={
                                                    "dropdown " +
                                                    (workspaceLoading
                                                        ? "pointer-event-none"
                                                        : "")
                                                }
                                                show={isOpen}
                                                ref={dropdownCompleteRef}
                                                onClick={toggleDropdown}
                                            >
                                                <Dropdown.Toggle className="dropdown-toggle">
                                                    {workspaceLoading ? (
                                                        <div className="header-skelton-wrap d-flex align-items-center">
                                                            <Skeleton
                                                                width={40}
                                                                height={40}
                                                                style={{
                                                                    borderRadius:
                                                                        "5px",
                                                                }}
                                                                className={
                                                                    "header-credit-skeleton"
                                                                }
                                                            />
                                                            {(function () {
                                                                let session_workspace_type =
                                                                    null;

                                                                let session_workspace =
                                                                    ReactSession.get(
                                                                        "workspace"
                                                                    );

                                                                if (
                                                                    session_workspace
                                                                ) {
                                                                    session_workspace_type =
                                                                        session_workspace.type;
                                                                }
                                                                if (
                                                                    session_workspace_type ==
                                                                    "Team"
                                                                ) {
                                                                    return (
                                                                        <Skeleton
                                                                            width={
                                                                                77
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    "5px",
                                                                            }}
                                                                            className={
                                                                                "header-credit-skeleton"
                                                                            }
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <Skeleton
                                                                            width={
                                                                                152
                                                                            }
                                                                            height={
                                                                                24
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    "5px",
                                                                            }}
                                                                            className={
                                                                                "header-credit-skeleton"
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            })()}

                                                            <Skeleton
                                                                width={18}
                                                                height={18}
                                                                style={{
                                                                    borderRadius:
                                                                        "5px",
                                                                    top: "-0.5px",
                                                                    marginRight:
                                                                        "0px",
                                                                }}
                                                                className={
                                                                    "header-credit-skeleton"
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {workspace && (
                                                                <>
                                                                    <span className="span-workspace-owner-icon">
                                                                        <Icon
                                                                            value={
                                                                                workspace.type ==
                                                                                "Individual"
                                                                                    ? "user"
                                                                                    : "group"
                                                                            }
                                                                            colorClass={
                                                                                "gray-900-svg"
                                                                            }
                                                                        />
                                                                    </span>

                                                                    <span
                                                                        className={`workspace-menu-btn`}
                                                                    >
                                                                        <Text
                                                                            type={
                                                                                "body-text-2"
                                                                            }
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                            cssClasses={
                                                                                "workspace-name-dropdown"
                                                                            }
                                                                        >
                                                                            {
                                                                                workspace.title
                                                                            }
                                                                        </Text>
                                                                        <Icon
                                                                            colorClass="gray-50-svg chevron-icon"
                                                                            size={
                                                                                "medium"
                                                                            }
                                                                            value={
                                                                                "chevron"
                                                                            }
                                                                        />
                                                                    </span>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div
                                                        className={`hamburger-items`}
                                                    >
                                                        <div
                                                            className="scroll-menu"
                                                            ref={dropdownRef}
                                                        >
                                                            <div className="workspace-owner-info-dropdown">
                                                                {workspaceLoading ? (
                                                                    <Skeleton
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {workspace && (
                                                                            <span className="span-workspace-owner-icon">
                                                                                <Icon
                                                                                    value={
                                                                                        workspace.type ==
                                                                                        "Individual"
                                                                                            ? "user"
                                                                                            : "group"
                                                                                    }
                                                                                    colorClass={
                                                                                        "gray-900-svg"
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <div className="workspace-type-info text-center">
                                                                    {workspaceLoading ? (
                                                                        <Skeleton
                                                                            width={
                                                                                120
                                                                            }
                                                                            height={
                                                                                30
                                                                            }
                                                                            style={{
                                                                                borderRadius:
                                                                                    "5px",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {workspace && (
                                                                                <>
                                                                                    <Text
                                                                                        type={
                                                                                            "body-text-3"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "text-white text-center"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            workspace.title
                                                                                        }
                                                                                    </Text>
                                                                                    <Text
                                                                                        type={
                                                                                            "caption"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "header-info-type"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "medium-font"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            workspace.type
                                                                                        }{" "}
                                                                                        workspace{" "}
                                                                                    </Text>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {workspaceLoading ? (
                                                                    <Skeleton
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {workspace &&
                                                                            workspace.type ===
                                                                                "Individual" && (
                                                                                <Link
                                                                                    to={
                                                                                        "/wsp/" +
                                                                                        workspace.id +
                                                                                        "/upgrade-workspace"
                                                                                    }
                                                                                    className={`${
                                                                                        wizardOptions.open &&
                                                                                        wizardOptions.currentStep ===
                                                                                            1
                                                                                            ? "wizard-step-two active-wborder"
                                                                                            : ""
                                                                                    }`}
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        if (
                                                                                            wizardOptions.open &&
                                                                                            wizardOptions.currentStep ===
                                                                                                1
                                                                                        ) {
                                                                                            dismissWizard();
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <span className="upgrade-to-team-plan">
                                                                                        Upgrade
                                                                                        to
                                                                                        Team
                                                                                        plan
                                                                                    </span>
                                                                                </Link>
                                                                            )}
                                                                    </>
                                                                )}
                                                            </div>

                                                            {workspaceLoading ? (
                                                                <div className="workspace-menu-list">
                                                                    <Skeleton
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />

                                                                    <Skeleton
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />

                                                                    <Skeleton
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {workspace && (
                                                                        <div className="workspace-menu-list">
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    navigate(
                                                                                        `/wsp/${
                                                                                            workspace.id
                                                                                        }/${
                                                                                            workspace.type ===
                                                                                            "Individual"
                                                                                                ? "settings"
                                                                                                : "team-&-settings"
                                                                                        }`
                                                                                    );
                                                                                }}
                                                                                className={`${
                                                                                    location.pathname.includes(
                                                                                        `/wsp/${
                                                                                            workspace.id
                                                                                        }/${
                                                                                            workspace.type ===
                                                                                            "Individual"
                                                                                                ? "settings"
                                                                                                : "team-&-settings"
                                                                                        }`
                                                                                    )
                                                                                        ? "active-menu"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                <Icon
                                                                                    colorClass="gray-600-svg billing-icon"
                                                                                    size={
                                                                                        "medium"
                                                                                    }
                                                                                    value={
                                                                                        workspace.type ==
                                                                                        "Individual"
                                                                                            ? "user"
                                                                                            : "group"
                                                                                    }
                                                                                />
                                                                                {workspace.type ==
                                                                                "Individual"
                                                                                    ? "Settings"
                                                                                    : "Team & Settings"}
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    navigate(
                                                                                        "/wsp/" +
                                                                                            workspace.id +
                                                                                            "/workspace-billing"
                                                                                    );
                                                                                }}
                                                                                className={`${
                                                                                    location.pathname.includes(
                                                                                        "/wsp/" +
                                                                                            workspace.id +
                                                                                            "/workspace-billing"
                                                                                    )
                                                                                        ? "active-menu"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                <Icon
                                                                                    colorClass="gray-600-svg billing-icon"
                                                                                    size={
                                                                                        "medium"
                                                                                    }
                                                                                    value={
                                                                                        "billing"
                                                                                    }
                                                                                />
                                                                                Workspace
                                                                                billing
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item
                                                                                onClick={() => {
                                                                                    navigate(
                                                                                        "/wsp/" +
                                                                                            workspace.id +
                                                                                            "/credits"
                                                                                    );
                                                                                }}
                                                                                className={`${
                                                                                    location.pathname.includes(
                                                                                        "/wsp/" +
                                                                                            workspace.id +
                                                                                            "/credits"
                                                                                    )
                                                                                        ? "active-menu"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                <Icon
                                                                                    colorClass="gray-600-svg credit-icon"
                                                                                    size={
                                                                                        "medium"
                                                                                    }
                                                                                    value={
                                                                                        "credit"
                                                                                    }
                                                                                />
                                                                                Credits
                                                                                wallet
                                                                            </Dropdown.Item>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}

                                                            {workspacesListLoading ? (
                                                                <>
                                                                    <Skeleton
                                                                        key={
                                                                            "skeleton-loading"
                                                                        }
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />

                                                                    <Skeleton
                                                                        key={
                                                                            "skeleton-loading"
                                                                        }
                                                                        width={
                                                                            120
                                                                        }
                                                                        height={
                                                                            30
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                "5px",
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {workspaces.length >
                                                                        0 && (
                                                                        <div
                                                                            className={
                                                                                "added-workspaces-for-user" +
                                                                                (invitationLoading ||
                                                                                declineInviteLoading
                                                                                    ? " disabled-workspace-list"
                                                                                    : "")
                                                                            }
                                                                        >
                                                                            <Text
                                                                                type={
                                                                                    "caption"
                                                                                }
                                                                                cssClasses={
                                                                                    "workspaces-list-heading"
                                                                                }
                                                                            >
                                                                                Workspaces
                                                                            </Text>

                                                                            {workspaces.map(
                                                                                function (
                                                                                    workspace_item,
                                                                                    index
                                                                                ) {
                                                                                    if (
                                                                                        workspace &&
                                                                                        workspace.id !==
                                                                                            workspace_item.id
                                                                                    ) {
                                                                                        if (
                                                                                            !invitedWorkspaceIds.includes(
                                                                                                workspace_item.id
                                                                                            )
                                                                                        ) {
                                                                                            return (
                                                                                                <Link
                                                                                                    key={
                                                                                                        "workspace-link-" +
                                                                                                        index
                                                                                                    }
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        e.preventDefault();

                                                                                                        if (
                                                                                                            invitationLoading ||
                                                                                                            declineInviteLoading
                                                                                                        ) {
                                                                                                            e.stopPropagation();
                                                                                                            e.preventDefault();
                                                                                                        } else {
                                                                                                            navigate(
                                                                                                                `/wsp/${workspace_item.id}`
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="workspace-list-repeat d-flex align-items-center">
                                                                                                        <span className="span-workspace-owner-icon">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    workspace_item.type ==
                                                                                                                    "Individual"
                                                                                                                        ? "user"
                                                                                                                        : "group"
                                                                                                                }
                                                                                                                colorClass={
                                                                                                                    "gray-900-svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                        <div className="workspace-type-info">
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "body-text-3"
                                                                                                                }
                                                                                                                cssClasses={
                                                                                                                    "text-white text-center"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    workspace_item.title
                                                                                                                }
                                                                                                            </Text>
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "caption"
                                                                                                                }
                                                                                                                cssClasses={
                                                                                                                    "header-info-type"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                {workspace_item.type +
                                                                                                                    " workspace"}
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                        <span className="workspace-arrow">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "chevron"
                                                                                                                }
                                                                                                                size={
                                                                                                                    "small"
                                                                                                                }
                                                                                                                colorClass={
                                                                                                                    "gray-50-svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <div
                                                                                                    className={`invite-workspace-notifivation`}
                                                                                                >
                                                                                                    <div
                                                                                                        className="workspace-list-repeat d-flex align-items-center cursor-pointer"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.stopPropagation();
                                                                                                            acceptInvite(
                                                                                                                workspace_item.id,
                                                                                                                true
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="span-workspace-owner-icon">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    workspace_item.type ==
                                                                                                                    "Individual"
                                                                                                                        ? "user"
                                                                                                                        : "group"
                                                                                                                }
                                                                                                                colorClass={
                                                                                                                    "gray-900-svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                        <div className="workspace-type-info">
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "body-text-3"
                                                                                                                }
                                                                                                                cssClasses={
                                                                                                                    "text-white text-center"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    workspace_item.title
                                                                                                                }
                                                                                                            </Text>
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "caption"
                                                                                                                }
                                                                                                                cssClasses={
                                                                                                                    "header-info-type"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                {workspace_item.type +
                                                                                                                    " workspace"}
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                        <span className="workspace-arrow">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "chevron"
                                                                                                                }
                                                                                                                size={
                                                                                                                    "small"
                                                                                                                }
                                                                                                                colorClass={
                                                                                                                    "gray-50-svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div className="notification-wrap d-flex align-items-center justify-content-between">
                                                                                                        <Button
                                                                                                            cssclass={
                                                                                                                "white-bg-btn" +
                                                                                                                (declineInviteLoading
                                                                                                                    ? " btn-disabled"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            type={
                                                                                                                "primary"
                                                                                                            }
                                                                                                            iconLeft={
                                                                                                                <Icon
                                                                                                                    value={
                                                                                                                        "notification"
                                                                                                                    }
                                                                                                                    size={
                                                                                                                        "small"
                                                                                                                    }
                                                                                                                    colorClass={
                                                                                                                        "gray-900-svg"
                                                                                                                    }
                                                                                                                />
                                                                                                            }
                                                                                                            size={
                                                                                                                "small"
                                                                                                            }
                                                                                                            label={
                                                                                                                "Accept"
                                                                                                            }
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.stopPropagation();
                                                                                                                acceptInvite(
                                                                                                                    workspace_item.id
                                                                                                                );
                                                                                                            }}
                                                                                                            microLoading={
                                                                                                                currentInvitedWorkspace ===
                                                                                                                    workspace_item.id &&
                                                                                                                invitationLoading
                                                                                                            }
                                                                                                        />

                                                                                                        <Button
                                                                                                            cssclass={
                                                                                                                "black-bg-btn" +
                                                                                                                (invitationLoading
                                                                                                                    ? " btn-disabled"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            type={
                                                                                                                "secondary"
                                                                                                            }
                                                                                                            iconLeft={
                                                                                                                <Icon
                                                                                                                    value={
                                                                                                                        "close"
                                                                                                                    }
                                                                                                                    size={
                                                                                                                        "small"
                                                                                                                    }
                                                                                                                    colorClass={
                                                                                                                        "gray-50-svg"
                                                                                                                    }
                                                                                                                />
                                                                                                            }
                                                                                                            size={
                                                                                                                "small"
                                                                                                            }
                                                                                                            label={
                                                                                                                "Decline"
                                                                                                            }
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.stopPropagation();
                                                                                                                declineInvite(
                                                                                                                    workspace_item.id
                                                                                                                );
                                                                                                            }}
                                                                                                            microLoading={
                                                                                                                currentInvitedWorkspace ===
                                                                                                                    workspace_item.id &&
                                                                                                                declineInviteLoading
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`add-work-space-link ${
                                                                workspaces.length >
                                                                4
                                                                    ? "more-workspaces"
                                                                    : ""
                                                            }`}
                                                            onClick={(e) => {
                                                                if (
                                                                    wizardOptions.open &&
                                                                    wizardOptions.currentStep ===
                                                                        2
                                                                ) {
                                                                    dismissWizard();
                                                                }
                                                            }}
                                                        >
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    navigate(
                                                                        "/r/choose-plan"
                                                                    );
                                                                }}
                                                                className={`${
                                                                    location.pathname.includes(
                                                                        ""
                                                                    )
                                                                        ? ""
                                                                        : ""
                                                                } ${
                                                                    wizardOptions.open &&
                                                                    wizardOptions.currentStep ===
                                                                        2
                                                                        ? "wizard-step-three active-wborder"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <Icon
                                                                    colorClass="gray-600-svg add-icon"
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    value={
                                                                        "add"
                                                                    }
                                                                />
                                                                Add workspace
                                                            </Dropdown.Item>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                        <div ref={tooltipRef}>
                                            {tooltipVisible && (
                                                <div className="added-workspace-tooltip">
                                                    <div className="tooltip-wrapper top-tooltip">
                                                        <p className="body-text body-text-2 normal-font d-flex align-items-center">
                                                            <Icon
                                                                value={"group"}
                                                                colorClass={
                                                                    "gray-50-svg"
                                                                }
                                                            />
                                                            You’ve been added to
                                                            a workspace!
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {workspaceLoading &&
                                            (function () {
                                                let session_workspace_type =
                                                    null;

                                                let session_workspace =
                                                    ReactSession.get(
                                                        "workspace"
                                                    );

                                                if (session_workspace) {
                                                    session_workspace_type =
                                                        session_workspace.type;
                                                }
                                                if (
                                                    session_workspace_type ==
                                                    "Team"
                                                ) {
                                                    return (
                                                        <div className="circular-name-icon-wrap header-circular-skelton d-flex align-items-center">
                                                            <Skeleton
                                                                width={32}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "100px",
                                                                }}
                                                                className={
                                                                    "header-credit-skeleton"
                                                                }
                                                            />

                                                            <Skeleton
                                                                width={32}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "100px",
                                                                }}
                                                                className={
                                                                    "header-credit-skeleton"
                                                                }
                                                            />

                                                            <Skeleton
                                                                width={32}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "100px",
                                                                }}
                                                                className={
                                                                    "header-credit-skeleton"
                                                                }
                                                            />

                                                            <Skeleton
                                                                width={32}
                                                                height={32}
                                                                style={{
                                                                    borderRadius:
                                                                        "100px",
                                                                }}
                                                                className={
                                                                    "header-credit-skeleton"
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })()}

                                        {workspace && !workspaceLoading && (
                                            <Link
                                                to={
                                                    workspace.type=="Individual"
                                                    ?
                                                    "/wsp/" +
                                                    workspace.id +
                                                    "/settings"
                                                    :
                                                    "/wsp/" +
                                                    workspace.id +
                                                    "/team-&-settings"
                                                }
                                            >
                                                <CircularNameIconGroup
                                                    size={"medium"}
                                                    count={2}
                                                    seatsAvailable={
                                                        seats -
                                                            members.length -
                                                            1 >
                                                        0
                                                            ? true
                                                            : false
                                                    }
                                                    circularIconsArray={members}
                                                />
                                            </Link>
                                        )}
                                    </div>
                                    <span
                                        className="header-link credits-link-header credits-count"
                                        onClick={() => {
                                            navigate(
                                                "/wsp/" +
                                                    workspace.id +
                                                    "/credits"
                                            );
                                        }}
                                    >
                                        {!creditsLoading && (
                                            <>
                                                <Icon
                                                    colorClass="gray-50-svg credit-icon"
                                                    size={"large"}
                                                    value={"credit"}
                                                />
                                                {roundNumber(credits, 2)}{" "}
                                                Credits
                                            </>
                                        )}
                                    </span>

                                    {creditsLoading && (
                                        <div className="header-skelton-wrap d-flex align-items-center">
                                            <Skeleton
                                                className="header-credit-skeleton"
                                                width={32}
                                                height={32}
                                            />

                                            <Skeleton
                                                className="header-credit-skeleton"
                                                width={70}
                                                height={24}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="header-rhs-side">
                            <div
                                className={`header-credits-wrap ${
                                    wizardOptions.open &&
                                    wizardOptions.currentStep === 3
                                        ? "wizard-step-four active-wborder"
                                        : ""
                                }`}
                                onClick={(e) => {
                                    if (
                                        wizardOptions.open &&
                                        wizardOptions.currentStep === 3
                                    ) {
                                        dismissWizard();
                                    }
                                }}
                            >
                                <Dropdown className="dropdown">
                                    <Dropdown.Toggle className="dropdown-toggle">
                                        <span
                                            className={`user-profile-btn d-flex align-items-center`}
                                        >
                                            {/*<div className="login-user-thum">
                                                <CircularNameIcon
                                                    label={
                                                        user
                                                            ? user.first_name
                                                                  .charAt(0)
                                                                  .toUpperCase()
                                                            : ""
                                                    }
                                                />
                                            </div>*/}

                                            <Text
                                                type={"body-text-2"}
                                                cssClasses={"login-user-name"}
                                                fontWeight={"medium-font"}
                                            >
                                                {user && user.first_name}
                                            </Text>
                                            <div className="profile-dropdown-icon">
                                                <Icon
                                                    value={"chevron"}
                                                    size={"small"}
                                                    colorClass={"gray-50-svg"}
                                                />
                                            </div>
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className={`hamburger-items`}>
                                            <div className="login-user-name-info">
                                                <span
                                                    className={`user-profile-btn d-flex align-items-center`}
                                                >
                                                    <div className="login-user-thum">
                                                        <CircularNameIcon
                                                            label={
                                                                user
                                                                    ? user.first_name
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase()
                                                                    : ""
                                                            }
                                                        />
                                                    </div>

                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "login-user-name"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {user &&
                                                            user.first_name +
                                                                " " +
                                                                user.last_name}
                                                    </Text>
                                                </span>
                                            </div>
                                            <div className="workspace-menu-list">
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        navigate("/r/account");
                                                    }}
                                                    className={`${
                                                        location.pathname.includes(
                                                            "/r/account"
                                                        )
                                                            ? "active-menu"
                                                            : ""
                                                    }`}
                                                >
                                                    <Icon
                                                        colorClass="gray-600-svg profile-icon"
                                                        size={"medium"}
                                                        value={"profile"}
                                                    />
                                                    Account
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    onClick={() => {
                                                        navigate(
                                                            "/r/payment-methods"
                                                        );
                                                    }}
                                                    className={`${
                                                        location.pathname.includes(
                                                            "/r/payment-methods"
                                                        )
                                                            ? "active-menu"
                                                            : ""
                                                    }`}
                                                >
                                                    <Icon
                                                        colorClass="gray-600-svg profile-icon"
                                                        size={"medium"}
                                                        value={"card"}
                                                    />
                                                    Payment methods
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    onClick={() => {
                                                        navigate("/r/settings");
                                                    }}
                                                    className={`${
                                                        location.pathname.includes(
                                                            "/r/settings"
                                                        )
                                                            ? "active-menu"
                                                            : ""
                                                    }`}
                                                >
                                                    <Icon
                                                        colorClass="gray-600-svg notification-icon"
                                                        size={"medium"}
                                                        value={"notification"}
                                                    />
                                                    Notifications settings
                                                </Dropdown.Item>
                                            </div>

                                            <div className="menu-mid-links">
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        navigate(
                                                            "/r/invite-and-earn"
                                                        );
                                                    }}
                                                    className={`${
                                                        location.pathname.includes(
                                                            "/r/invite-and-earn"
                                                        )
                                                            ? "active-menu"
                                                            : ""
                                                    }`}
                                                >
                                                    <Icon
                                                        colorClass="gray-600-svg support-icon"
                                                        size={"medium"}
                                                        value={"add user"}
                                                    />
                                                    Refer & Earn
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    onClick={() => {
                                                        openSupportPanel();
                                                        //navigate("/r/support");
                                                    }}
                                                >
                                                    <Icon
                                                        colorClass="gray-600-svg support-icon"
                                                        size={"medium"}
                                                        value={"support"}
                                                    />
                                                    Support
                                                </Dropdown.Item>
                                            </div>

                                            <div className="log-out-btn">
                                                <Dropdown.Item onClick={logout}>
                                                    <Icon
                                                        colorClass="gray-600-svg exit-icon"
                                                        size={"medium"}
                                                        value={"exit"}
                                                    />
                                                    Log out
                                                </Dropdown.Item>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                    <div className="header-for-mobile none-for-desktop">
                        <div className="mobile-header-top">
                            <div className="logo">
                                <Link to={"/"}>
                                    <Logo
                                        theme={"white"}
                                        background={"black"}
                                    />
                                </Link>
                            </div>
                            <div className="mobile-menu-wrap">
                                <span
                                    className="open-m-menu"
                                    onClick={() => {
                                        setMenuClass("open-menu");
                                        document.body.classList.add(
                                            "open-m-menu"
                                        );
                                    }}
                                >
                                    <Icon
                                        colorClass="gray-50-svg menu-icon"
                                        size={"medium"}
                                        value={"menu"}
                                    />
                                </span>
                            </div>
                        </div>

                        <div className={`m-nagivation-wrap ${menuClass}`}>
                            <div className="mobile-header-top">
                                <div className="logo">
                                    <Link to={"/"}>
                                        <img
                                            src={
                                                process.env.REACT_APP_URL +
                                                "img/UQ.svg"
                                            }
                                            alt="UserQ"
                                        />
                                    </Link>
                                </div>
                                <div className="mobile-menu-wrap">
                                    <span
                                        className="close-m-menu"
                                        onClick={() => {
                                            setMenuClass("");
                                            document.body.classList.remove(
                                                "open-m-menu"
                                            );
                                        }}
                                    >
                                        <Icon
                                            colorClass="gray-50-svg close-icon"
                                            size={"large"}
                                            value={"close"}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="header-login-userinfo d-flex align-items-center">
                                <div className="login-user-thum">
                                    <CircularNameIcon
                                        label={
                                            user
                                                ? user.first_name
                                                      .charAt(0)
                                                      .toUpperCase()
                                                : ""
                                        }
                                    />
                                </div>
                                {user && user.first_name + " " + user.last_name}
                            </div>
                            <div className="topnavigationlist navigation-listing">
                                <ul>
                                    <li
                                        className={`${
                                            location.pathname.includes(
                                                "/dashboard"
                                            )
                                                ? "active-menu"
                                                : ""
                                        }`}
                                    >
                                        <Link to={"/"}>
                                            <Icon
                                                colorClass="gray-600-svg dashboard-icon"
                                                size={"medium"}
                                                value={"dashboard"}
                                            />
                                            <span> Dashboard </span>
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            location.pathname.includes(
                                                "/r/account"
                                            )
                                                ? "active-menu"
                                                : ""
                                        }`}
                                    >
                                        <Link to={"/r/account"}>
                                            <Icon
                                                colorClass="gray-600-svg profile-icon"
                                                size={"medium"}
                                                value={"profile"}
                                            />
                                            <span> Account </span>
                                        </Link>
                                    </li>

                                    <li
                                        className={`${
                                            location.pathname.includes(
                                                "/r/payment-methods"
                                            )
                                                ? "active-menu"
                                                : ""
                                        }`}
                                    >
                                        <Link to={"/r/payment-methods"}>
                                            <Icon
                                                colorClass="gray-600-svg profile-icon"
                                                size={"medium"}
                                                value={"card"}
                                            />
                                            <span> Payment methods</span>
                                        </Link>
                                    </li>

                                    <li
                                        className={`${
                                            location.pathname.includes(
                                                "/r/setting"
                                            )
                                                ? "active-menu"
                                                : ""
                                        }`}
                                    >
                                        <Link to={"/r/settings"}>
                                            <Icon
                                                colorClass="gray-600-svg notification-icon"
                                                size={"medium"}
                                                value={"notification"}
                                            />
                                            <span> Notifications settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="mobilemenubtn-box">
                                <Button
                                    onClick={() => {
                                        navigate("/r/invite-and-earn");
                                    }}
                                    type={"secondary"}
                                    size={"large"}
                                    label={"Refer & Earn"}
                                />
                            </div>
                            <div className="supportnavigationbox navigation-listing">
                                <ul>
                                    <li>
                                        <Link
                                            onClick={(e) => {
                                                setMenuClass("");
                                                document.body.classList.remove(
                                                    "open-m-menu"
                                                );
                                                e.preventDefault();
                                                openSupportPanel();
                                                //navigate("/r/support");
                                            }}
                                            to={"/r/support"}
                                        >
                                            <Icon
                                                colorClass="gray-600-svg support-icon"
                                                size={"medium"}
                                                value={"support"}
                                            />
                                            <span> Support </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="bottomnavigationlist navigation-listing">
                                <ul>
                                    <li>
                                        <a href="#" onClick={logout}>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-workspace-for-mobile">
                    <div className="mobile-header-left">
                        <div className="workspace-dropdown">
                            <div
                                className={
                                    "dropdown " +
                                    (workspaceLoading
                                        ? "pointer-event-none"
                                        : "")
                                }
                            >
                                <div
                                // onClick={() => {
                                //     document.body.classList.toggle(
                                //         "open-m-menu"
                                //     );
                                // }}
                                >
                                    <div
                                        className={`btn dropdown-toggle d-flex align-items-center ${
                                            slideOpen ? "menu-open-active" : ""
                                        }`}
                                        onClick={toggleMenu}
                                    >
                                        {workspaceLoading ? (
                                            <Skeleton
                                                width={120}
                                                height={30}
                                                style={{ borderRadius: "5px" }}
                                            />
                                        ) : (
                                            <>
                                                {workspace && (
                                                    <>
                                                        <span className="span-workspace-owner-icon">
                                                            <Icon
                                                                value={
                                                                    workspace.type ==
                                                                    "Individual"
                                                                        ? "user"
                                                                        : "group"
                                                                }
                                                                colorClass={
                                                                    "gray-900-svg"
                                                                }
                                                            />
                                                        </span>

                                                        <span
                                                            className={`workspace-menu-btn`}
                                                        >
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                                cssClasses={
                                                                    "workspace-name-dropdown"
                                                                }
                                                            >
                                                                {
                                                                    workspace.title
                                                                }
                                                            </Text>
                                                            <Icon
                                                                colorClass="gray-50-svg chevron-icon"
                                                                size={"medium"}
                                                                value={
                                                                    "chevron"
                                                                }
                                                            />
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div
                                        className={`hamburger-items mobile-workspace-slider ${
                                            slideOpen ? "menu-open-active" : ""
                                        } ${
                                            workspaces.length < 1
                                                ? "no-workspace-added"
                                                : ""
                                        }`}
                                    >
                                        <div
                                            className="close-menu"
                                            onClick={() => {
                                                document.body.classList.remove(
                                                    "open-m-menu"
                                                );
                                            }}
                                        >
                                            <div
                                                className="dropdown-toggle"
                                                onClick={toggleMenu}
                                            >
                                                <Icon
                                                    value={"close"}
                                                    size={"large"}
                                                    colorClass={"gray-600-svg"}
                                                />
                                            </div>
                                        </div>

                                        <div className="scroll-menu">
                                            <div className="workspace-owner-info-dropdown">
                                                {workspaceLoading ? (
                                                    <Skeleton
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        {workspace && (
                                                            <span className="span-workspace-owner-icon">
                                                                <Icon
                                                                    value={
                                                                        workspace.type ==
                                                                        "Individual"
                                                                            ? "user"
                                                                            : "group"
                                                                    }
                                                                    colorClass={
                                                                        "gray-900-svg"
                                                                    }
                                                                />
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                                <div className="workspace-type-info text-center">
                                                    {workspaceLoading ? (
                                                        <Skeleton
                                                            width={120}
                                                            height={30}
                                                            style={{
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {workspace && (
                                                                <>
                                                                    <Text
                                                                        type={
                                                                            "body-text-3"
                                                                        }
                                                                        cssClasses={
                                                                            "text-white text-center"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            workspace.title
                                                                        }
                                                                    </Text>
                                                                    <Text
                                                                        type={
                                                                            "caption"
                                                                        }
                                                                        cssClasses={
                                                                            "header-info-type"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            workspace.type
                                                                        }{" "}
                                                                        workspace{" "}
                                                                    </Text>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {workspaceLoading ? (
                                                    <Skeleton
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        {workspace &&
                                                            workspace.type ===
                                                                "Individual" && (
                                                                <Link
                                                                    to={
                                                                        "/wsp/" +
                                                                        workspace.id +
                                                                        "/upgrade-workspace"
                                                                    }
                                                                >
                                                                    <span className="upgrade-to-team-plan">
                                                                        Upgrade
                                                                        to Team
                                                                        plan
                                                                    </span>
                                                                </Link>
                                                            )}
                                                    </>
                                                )}
                                            </div>

                                            {workspaceLoading ? (
                                                <div className="workspace-menu-list">
                                                    <Skeleton
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />

                                                    <Skeleton
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />

                                                    <Skeleton
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    {workspace && (
                                                        <div className="workspace-menu-list">
                                                            <div className="topnavigationlist navigation-listing">
                                                                <ul>
                                                                    <li
                                                                        className={
                                                                            location.pathname.includes(
                                                                                workspace.type ===
                                                                                    "Individual"
                                                                                    ? "settings"
                                                                                    : "team-&-settings"
                                                                            )
                                                                                ? "active-menu"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <Link
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                navigate(
                                                                                    `/wsp/${
                                                                                        workspace.id
                                                                                    }/${
                                                                                        workspace.type ===
                                                                                        "Individual"
                                                                                            ? "settings"
                                                                                            : "team-&-settings"
                                                                                    }`
                                                                                );
                                                                                event.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                colorClass="gray-600-svg billing-icon"
                                                                                size={
                                                                                    "medium"
                                                                                }
                                                                                value={
                                                                                    workspace.type ==
                                                                                    "Individual"
                                                                                        ? "user"
                                                                                        : "group"
                                                                                }
                                                                            />
                                                                            {workspace.type ==
                                                                            "Individual"
                                                                                ? "Settings"
                                                                                : "Team & Settings"}{" "}
                                                                        </Link>
                                                                    </li>

                                                                    <li
                                                                        className={`${
                                                                            location.pathname.includes(
                                                                                "/wsp/" +
                                                                                    workspace.id +
                                                                                    "/workspace-billing"
                                                                            )
                                                                                ? "active-menu"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <Link
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                navigate(
                                                                                    "/wsp/" +
                                                                                        workspace.id +
                                                                                        "/workspace-billing"
                                                                                );
                                                                                event.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                colorClass="gray-600-svg billing-icon"
                                                                                size={
                                                                                    "medium"
                                                                                }
                                                                                value={
                                                                                    "billing"
                                                                                }
                                                                            />
                                                                            Workspace
                                                                            billing
                                                                        </Link>
                                                                    </li>

                                                                    <li
                                                                        className={`${
                                                                            location.pathname.includes(
                                                                                "/wsp/" +
                                                                                    workspace.id +
                                                                                    "/credits"
                                                                            )
                                                                                ? "active-menu"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <Link
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                navigate(
                                                                                    "/wsp/" +
                                                                                        workspace.id +
                                                                                        "/credits"
                                                                                );
                                                                                event.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                colorClass="gray-600-svg credit-icon"
                                                                                size={
                                                                                    "medium"
                                                                                }
                                                                                value={
                                                                                    "credit"
                                                                                }
                                                                            />
                                                                            Credits
                                                                            wallet
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {workspacesListLoading ? (
                                                <>
                                                    <Skeleton
                                                        key={"skeleton-loading"}
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />

                                                    <Skeleton
                                                        key={"skeleton-loading"}
                                                        width={120}
                                                        height={30}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {workspaces.length > 0 && (
                                                        <div
                                                            className={
                                                                "added-workspaces-for-user" +
                                                                (invitationLoading ||
                                                                declineInviteLoading
                                                                    ? " disabled-workspace-list"
                                                                    : "")
                                                            }
                                                        >
                                                            <Text
                                                                type={"caption"}
                                                                cssClasses={
                                                                    "workspaces-list-heading"
                                                                }
                                                            >
                                                                Workspaces
                                                            </Text>

                                                            <div
                                                                className={`mobile-workspace-listing-dropdown`}
                                                            >
                                                                {workspaces.map(
                                                                    function (
                                                                        workspace_item,
                                                                        index
                                                                    ) {
                                                                        if (
                                                                            workspace &&
                                                                            workspace.id !==
                                                                                workspace_item.id
                                                                        ) {
                                                                            if (
                                                                                !invitedWorkspaceIds.includes(
                                                                                    workspace_item.id
                                                                                )
                                                                            ) {
                                                                                return (
                                                                                    <Link
                                                                                        key={
                                                                                            "workspace-link-" +
                                                                                            index
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.preventDefault();

                                                                                            if (
                                                                                                invitationLoading ||
                                                                                                declineInviteLoading
                                                                                            ) {
                                                                                                e.stopPropagation();
                                                                                                e.preventDefault();
                                                                                            } else {
                                                                                                toggleMenu();
                                                                                                navigate(
                                                                                                    `/wsp/${workspace_item.id}`
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <div className="workspace-list-repeat d-flex align-items-center">
                                                                                            <span className="span-workspace-owner-icon">
                                                                                                <Icon
                                                                                                    value={
                                                                                                        workspace_item.type ==
                                                                                                        "Individual"
                                                                                                            ? "user"
                                                                                                            : "group"
                                                                                                    }
                                                                                                    colorClass={
                                                                                                        "gray-900-svg"
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                            <div className="workspace-type-info">
                                                                                                <Text
                                                                                                    type={
                                                                                                        "body-text-3"
                                                                                                    }
                                                                                                    cssClasses={
                                                                                                        "text-white text-center"
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        "medium-font"
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        workspace_item.title
                                                                                                    }
                                                                                                </Text>
                                                                                                <Text
                                                                                                    type={
                                                                                                        "caption"
                                                                                                    }
                                                                                                    cssClasses={
                                                                                                        "header-info-type"
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        "medium-font"
                                                                                                    }
                                                                                                >
                                                                                                    {workspace_item.type +
                                                                                                        " workspace"}
                                                                                                </Text>
                                                                                            </div>
                                                                                            <span className="workspace-arrow">
                                                                                                <Icon
                                                                                                    value={
                                                                                                        "chevron"
                                                                                                    }
                                                                                                    size={
                                                                                                        "small"
                                                                                                    }
                                                                                                    colorClass={
                                                                                                        "gray-50-svg"
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    </Link>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <div
                                                                                        className={`invite-workspace-notifivation`}
                                                                                    >
                                                                                        <div
                                                                                            className="workspace-list-repeat d-flex align-items-center"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.stopPropagation();
                                                                                                acceptInvite(
                                                                                                    workspace_item.id,
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <span className="span-workspace-owner-icon">
                                                                                                <Icon
                                                                                                    value={
                                                                                                        workspace_item.type ==
                                                                                                        "Individual"
                                                                                                            ? "user"
                                                                                                            : "group"
                                                                                                    }
                                                                                                    colorClass={
                                                                                                        "gray-900-svg"
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                            <div className="workspace-type-info">
                                                                                                <Text
                                                                                                    type={
                                                                                                        "body-text-3"
                                                                                                    }
                                                                                                    cssClasses={
                                                                                                        "text-white text-center"
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        "medium-font"
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        workspace_item.title
                                                                                                    }
                                                                                                </Text>
                                                                                                <Text
                                                                                                    type={
                                                                                                        "caption"
                                                                                                    }
                                                                                                    cssClasses={
                                                                                                        "header-info-type"
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        "medium-font"
                                                                                                    }
                                                                                                >
                                                                                                    {workspace_item.type +
                                                                                                        " workspace"}
                                                                                                </Text>
                                                                                            </div>
                                                                                            <span className="workspace-arrow">
                                                                                                <Icon
                                                                                                    value={
                                                                                                        "chevron"
                                                                                                    }
                                                                                                    size={
                                                                                                        "small"
                                                                                                    }
                                                                                                    colorClass={
                                                                                                        "gray-50-svg"
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="notification-wrap d-flex align-items-center justify-content-between">
                                                                                            <Button
                                                                                                cssclass={
                                                                                                    "white-bg-btn" +
                                                                                                    (declineInviteLoading
                                                                                                        ? " btn-disabled"
                                                                                                        : "")
                                                                                                }
                                                                                                type={
                                                                                                    "primary"
                                                                                                }
                                                                                                iconLeft={
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "notification"
                                                                                                        }
                                                                                                        size={
                                                                                                            "small"
                                                                                                        }
                                                                                                        colorClass={
                                                                                                            "gray-900-svg"
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                                size={
                                                                                                    "small"
                                                                                                }
                                                                                                label={
                                                                                                    "Accept"
                                                                                                }
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    e.stopPropagation();
                                                                                                    acceptInvite(
                                                                                                        workspace_item.id
                                                                                                    );
                                                                                                }}
                                                                                                microLoading={
                                                                                                    currentInvitedWorkspace ===
                                                                                                        workspace_item.id &&
                                                                                                    invitationLoading
                                                                                                }
                                                                                            />

                                                                                            <Button
                                                                                                cssclass={
                                                                                                    "black-bg-btn" +
                                                                                                    (invitationLoading
                                                                                                        ? " btn-disabled"
                                                                                                        : "")
                                                                                                }
                                                                                                type={
                                                                                                    "secondary"
                                                                                                }
                                                                                                iconLeft={
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "close"
                                                                                                        }
                                                                                                        size={
                                                                                                            "small"
                                                                                                        }
                                                                                                        colorClass={
                                                                                                            "gray-50-svg"
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                                size={
                                                                                                    "small"
                                                                                                }
                                                                                                label={
                                                                                                    "Decline"
                                                                                                }
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    e.stopPropagation();
                                                                                                    declineInvite(
                                                                                                        workspace_item.id
                                                                                                    );
                                                                                                }}
                                                                                                microLoading={
                                                                                                    currentInvitedWorkspace ===
                                                                                                        workspace_item.id &&
                                                                                                    declineInviteLoading
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div
                                            className={`add-workspace-link ${
                                                workspaces.length > 3
                                                    ? " more-workspaces"
                                                    : ""
                                            }`}
                                        >
                                            <Dropdown.Item
                                                onClick={() => {
                                                    navigate("/r/choose-plan");
                                                }}
                                                className={`${
                                                    location.pathname.includes(
                                                        ""
                                                    )
                                                        ? ""
                                                        : ""
                                                }`}
                                            >
                                                <Icon
                                                    colorClass="gray-600-svg add-icon"
                                                    size={"medium"}
                                                    value={"add"}
                                                />
                                                Add workspace
                                            </Dropdown.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ref={tooltipRef}>
                        {tooltipVisible && (
                            <div className="added-workspace-tooltip">
                                <div className="tooltip-wrapper top-tooltip">
                                    <p className="body-text body-text-2 normal-font d-flex align-items-center">
                                        <Icon
                                            value={"group"}
                                            colorClass={"gray-50-svg"}
                                        />
                                        You’ve been added to a workspace!
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="workspace-mobile-hdr-right">
                        {workspace && (
                            <CircularNameIconGroup
                                size={"medium"}
                                count={2}
                                seatsAvailable={
                                    seats - members.length - 1 > 0
                                        ? true
                                        : false
                                }
                                circularIconsArray={members}
                                link={
                                    
                                    workspace.type=="Individual"
                                                    ?
                                                    "/wsp/" +
                                                    workspace.id +
                                                    "/settings"
                                                    :
                                                    "/wsp/" +
                                                    workspace.id +
                                                    "/team-&-settings"
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            {loggingOutLoader && (
                <div className="header-logout-loader">
                    <LoadingIcon />
                </div>
            )}
            <Wizard
                wizardOptions={wizardOptions}
                setWizardOptions={setWizardOptions}
                dismissWizard={dismissWizard}
            />

            <ReviewPaymentModal
                open={!hideWorkspace && reviewPaymentModal.open}
                close={(send = false, data = null) => {
                    setReviewPaymentModal({
                        ...reviewPaymentModal,
                        open: false,
                    });
                    setShowNewAdminPaymentPopup(false);
                    if (send) {
                        setShowNewAdminPayment(false);
                        if (data) {
                            setPaymentMethodLoading(true);
                            setFuturePaymentCard({
                                ...futurePaymentCard,
                                paymentMethodId: data.stripe_payment_method_id,
                                default: data.default,
                                last_4: data.last_4,
                                brand: data.brand,
                                id: data.id,
                            });
                            getPaymentMethods(data.id, true);
                        } else {
                            setPaymentMethodLoading(true);
                            getPaymentMethods(futurePaymentCard.id, true);
                        }
                    }
                }}
                stripe={stripe}
                workspaceId={workspaceid}
                renderPaymentMethods={renderPaymentMethods}
                validatePaymentForm={validatePaymentForm}
                formValues={formValues}
                setFormValues={setFormValues}
                addNewCard={addNewCard}
                setAddNewCard={setAddNewCard}
                resetFormErrors={resetFormErrors}
                resetPaymentMethods={resetPaymentMethods}
                workspace={workspace}
                paymentMethodCount={paymentMethodCount}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                futurePaymentCard={futurePaymentCard}
                changePaymentMethodLoading={changePaymentMethodLoading}
                setChangePaymentMethodLoading={setChangePaymentMethodLoading}
                sameCardError={sameCardError}
                setSameCardError={setSameCardError}
                title={
                    "Congratulations, you’re now the new admin of this workspace!"
                }
            />
            {showNewAdminPayment &&
                workspace &&
                workspace.type === "Team" &&
                workspace.is_new_admin_proposed == 1 &&
                //!showNewAdminPaymentPopup &&
                !workspace.payment_method_id &&
                isAdmin && (
                    <div
                        className="mt-40 mb-40 page-data-wrapper"
                        style={{ minHeight: "auto" }}
                    >
                        <ToastMessage
                            type={"warning"}
                            message={
                                <>
                                    <div className="d-flex align-items-center freez-toast-wrap justify-content-between">
                                        <span className="pr-5">
                                            As the admin of this workspace, it's
                                            mandatory to maintain a valid
                                            payment method. The next billing
                                            date is scheduled for{" "}
                                            {workspace.next_billing_date
                                                ? moment(
                                                      workspace.next_billing_date
                                                  ).format("DD/MM/YY")
                                                : "-"}
                                            .<br /> Please add or update your
                                            payment method to ensure it's in
                                            place before the next billing cycle.
                                        </span>

                                        <Button
                                            type={"primary"}
                                            size={"small"}
                                            label={"Update payment method"}
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                frozenPaymentMethodUpdate(
                                                    "expired"
                                                );
                                            }}
                                        />
                                    </div>
                                </>
                            }
                            closable={false}
                        />
                    </div>
                )}
        </>
    );
};