import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
export const CheckBox = ({checked, value, id,onChange, label})=>{
    
    const [checkedState, setChecked] = useState(checked);

    useEffect(()=>{
        setChecked(checked);
    },[checked])

    return (
        
            
        <label htmlFor={id} className={`checkbox-wrap checkbox-label` }>
            <input
                type="checkbox" 
                id={id}
                checked={checkedState}
                value={value}
                onChange={(e)=>{

                    if(e.target.checked){
                        setChecked(true);
                    } else {
                        setChecked(false);
                    }
                    if(onChange){
                        onChange(e)
                    }
                }}
                className="checkbox-input"
            />
            <span className="checkmark"></span>
            <p className='radio-btn-label'>
                {label}
            </p>
        </label>
    )
}
CheckBox.propTypes = {
    checked:PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.node,
    value: PropTypes.string,
    id:PropTypes.string
    
};
CheckBox.defaultProps = {
    checked: true,
    onChange: undefined
    
};