import HeaderResearcher from "./elements/header_researcher"
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from "../../lib/secure_reactsession";
import { getActivePlanService } from "../../services/user.js";
import { atom, useRecoilState } from 'recoil';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function LayoutResearcher(props) {

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    ReactSession.setStoreType("localStorage");

    const activePlanState = atom({
        "key": "active_plan",
        "default": null
    })

    const [activePlan, setActivePlan] = useRecoilState(activePlanState);

    useEffect(() => {

        const plan = ReactSession.get("plan");

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        if (user && user.role == "Researcher" && !props.skipCheck) {
            if (!activePlan) {
                setLoading(false);

                setActivePlan({id:2});

                /*getActivePlanService(token).then(response => {
                    setLoading(false);

                    if (response.success) {

                        setActivePlan(response.plan);
                        if (response.plan.id == null) {
                            navigate('/researcher/billing/plans');
                        }

                    }
                });*/
            } else {
                if (activePlan.id == null) {
                    navigate('/researcher/billing/plans');
                }
            }


        }
    }, []);
    const getFooterYear = () => {
        const d = new Date(); let year = d.getFullYear(); return <>{year}</>;
    }
    return (
        <SkeletonTheme baseColor="#dae5f1" highlightColor="#ebf4ff">
            <div className={`inner-page-wrapper${(isLoading || props.isLoading) ? '-1' : ''} ${props.wrapClass} `}>
                {(isLoading || props.isLoading) &&
                    <div className="container inner-page-container">
                        <div className="loader_page">Loading...</div>
                    </div>
                }
                {(!isLoading && !props.isLoading) &&
                    <>
                        <HeaderResearcher username={props.username} activeMenu={props.activeMenu} />
                        <div className={`${(props.extended404Footer === false) ? '' : 'container inner-page-container'}`}>
                            <>{props.children}</>
                        </div>
                        {props.extendedFooter &&
                            <>
                                <div className="full home-marquee-banner section">
                                    <div className="home-marque-banner-box">
                                        <div className="scrolling-box">Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                        <div className="scrolling-box" aria-hidden="true"> Privacy is important</div>
                                    </div>
                                </div>

                                <div className="full homeafooterbox section researcher-homeafooterbox">
                                    <div className="container inner-page-container">
                                        <div className="footerboxhome minicontainer-outer">
                                            <div className="minicontainer">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 homefooterbox-headingbox">
                                                        <div className="h1">At User<span>Q</span>, we take your<br /> data privacy and security<br /> seriously</div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-12 homefooterbox-infobox">
                                                        <div className="row align-items-center">
                                                            <div className="col-xs-12 col-sm-12 col-md-5 homefooterbox-left">
                                                                <div className="full homefooterbox-info">
                                                                    <p>GDPR is a European Union regulation, designed to improve the data security and privacy of European citizens. Basically, companies in Europe have to make sure your data is safe, and that you can access and control it.</p>
                                                                    <p>Our Privacy Policy was prepared in accordance with the GDPR.</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-md-7 homefooterbox-right">
                                                                <div className="full homefooterbox-imgbox"> <img src={process.env.REACT_APP_URL + "images/Frame-257.png"} alt="UserQ" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {(props.extended404Footer !== false) &&
                            <footer className="researcher-footer">
                                <div className="container inner-page-container">
                                    <div className="footerbottom-bar">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                                                <div className="footer-left-side-data">
                                                    <span>&copy; {getFooterYear()} USERQ. All rights reserved.
                                                        {/*<a href={process.env.REACT_APP_SITE_URL + "terms-of-use/"}>Terms of use</a>*/}
                                                    </span>
                                                    {/*<div className="footer-polify-data">*/}
                                                    {/*    <span className="cookie-link">*/}
                                                    {/*        &nbsp;|&nbsp;*/}
                                                    {/*    </span>*/}
                                                    {/*    <a href="https://userq.com/privacy-policy/">Privacy Policy</a>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 footerbottom-bar-right">
                                                <div className="footerbottom-bar-right-inner">
                                                    <span className={"terms-of-use-txt-link-dsk"}><a href="https://userq.com/terms-of-use/">Terms of use</a></span>
                                                    <span className={"privacy-policy-txt-link-dsk"}><a href="https://userq.com/privacy-policy/">Privacy policy</a></span>
                                                    <span className="cookie-link"><a href="https://userq.com/privacy-policy/#cookies-policy">Cookie policy <img
                                                        src={process.env.REACT_APP_URL + "images/cookie-icon.svg"}
                                                        alt="img" /></a></span>
                                                    <span className="proudly-saying-text">
                                                        Proudly created by <a
                                                            href="https://www.digitalofthings.com/" target="_blank">Digital Of Things</a>                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        }
                    </>
                }
            </div>
        </SkeletonTheme>
    )
}