import { useRef, useState } from "react";
import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import BarChart from "./bar_chart_v2";
import { roundAnlyticsNumber } from "../../../../lib/helpers";
import exportAsImage from "../../../../lib/exportAsImage";

export const  HorizontalBarChartStatsBox = ({title, data}) =>{

	const ref = useRef();
	const tipref = useRef();

	const exportPngRef = useRef();

	const [tooltip, showTooltip] = useState({
		tooltipLeft:0,
		tooltipTop:0,
		tooltipData:"",
		open: false,
		show:false
	});

	const handlePointerMove = (key) => {


		return (
			(event) => {


				let tipPosition = "bottom";

				var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 150;


				var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 106;




				let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
				let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


				var window_width = window.innerWidth;

				var window_height = window.innerHeight;

				if(event.clientX + tool_width/2 > window_width){
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

				}
				if(event.clientX - tool_width/2 < 0){
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
				}

				showTooltip({
					tooltipLeft: containerX,
					tooltipTop: containerY,
					tooltipData: data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant',
					open:true,
					show: tooltip.open && tipref && tipref.current ? true: false,
					tipPostion: tipPosition
				});
			});
	}
	var colors = {};
	const color_unknown = "rgba(156, 156, 156, 1)";

	const colorsPallete = [
		"rgba(255, 109, 177, 1)",
		"rgba(151, 89, 203, 1)",
		"rgba(242, 198, 144, 1)",
		"rgba(105, 140, 231, 1)",
		"rgba(49, 240, 229, 1)",
		"rgba(55, 139, 16, 1)",
		"rgba(227, 47, 131, 1)",
		"rgba(121, 62, 171, 1)",
		"rgba(215, 165, 106, 1)",
		"rgba(54, 104, 231, 1)",
		"rgba(17, 191, 180, 1)",
		"rgba(22, 65, 3, 1)",
		"rgba(255, 155, 202, 1)",
		"rgba(191, 142, 232, 1)",
		"rgba(191, 142, 232, 1)",
		"rgba(158, 180, 237, 1)",
		"rgba(144, 242, 236, 1)",
		"rgba(191, 240, 168, 1)"
	];
	var color_index = 0;
	for(var key in data){

		if(key=="Unknown"){
			colors["Unknown"] = color_unknown;
		} else {
			colors[key] = colorsPallete[color_index];
			color_index++;
		}
	}
	const renderHorizontalBarChart = () => {

		var lChartData = [];

		var index = 0;

		var color_index = 0;

		var max = 0;

		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;

		for (var key in data) {

			sum += data[key];

		}
		var bars = [];

		for (var key in data) {

			//Math.round(((data[key] / sum) * 100) * 100) / 100
			var percentage = max > 0 ? roundAnlyticsNumber((data[key] / sum) * 100,true) : 0;
			var percentage_label = sum > 0 ?  roundAnlyticsNumber((data[key] / sum) * 100,true) : 0;

			var color = colors[key];

			lChartData.push({
				label: key,
				value: data[key],
				color: color,
				//textcolor: color.text,
				percentage_label: percentage + "%"
			});

			index++;

			bars.push((
				<div className="horiz-chart-data-repeat" key={"bar"+key}

				>
					<div className={`horizonatal-chart-left-side `} >
						<div className="horiz-chart-top-info d-flex align-items-end">
							<h3  style={{ color: color }} className={`h3 heading ${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</h3>
							<Text type={'caption'} fontWeight={'medium-font'} cssClasses={'color-black ml-2'}>{data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}</Text>

						</div>

						<div className="horiz-chart-hold-bg">
							<div className={`horiz-chart-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
								 style={{ width: percentage + "%", backgroundColor: color  }}
								// 			onPointerMove={handlePointerMove(key) }
								// onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
							>
								<div
									className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
									style={{ backgroundColor: color }}
								>

								</div>
							</div>

						</div>

					</div>

					<div className="horizonatal-chart-right-side">
						<div className="horiz-chart-text-wrap d-flex align-items-center">
							<Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={'color-black'}>{key}</Text>

							{key=="Unknown" &&
							<span className="info-icon info-wrap position-relative ml-2 cursor-pointer">
                        <Icon value={"information"} colorClass={'gray-700-svg'} size="medium" />
						<Tooltip
							className={"tooltip-wrapper"}
							type={"bottom"}
							data="If you directly shared the link with others, you might see unknown users outside your target demographic group."
						/>
						</span>
							}
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg" ref={ref} style={{position:'relative'}}><div className="edu-graph-inner-hold" >

				{bars}</div>

				{tooltip.open ? (
					<>
						<Tooltip
							ref={tipref}
							type={tooltip.tipPosition}
							key={Math.random()} // needed for bounds to update correctly
							style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
							data={tooltip.tooltipData}
							className="tooltipChart"

						/>
					</>
				): (
					<></>
				)}
			</div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}

	return<div className="position-relative chart-download-with-heading">
		<div className="mb-20 box-outer-heading">
			<Text type={"h4"} fontWeight={"medium-font"}>{title}</Text>
		</div>
		<div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title)}>
			<Icon value={'download'} size={'small'} hover={'true'}></Icon>
			<div className="tooltip-wrapper top-tooltip">
				<p className="body-text body-text-2 normal-font ">Download</p>
			</div>
		</div>

		<div ref={exportPngRef}>
			<Box  size={"large"}>
				{renderHorizontalBarChart()}

			</Box></div></div>;
}