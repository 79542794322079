import { useNavigate, useParams, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import LayoutResearcher from "../../layouts/layout_researcher";
import {maxLengthCheck, isFileImage, DropdownIndicator} from "../../../lib/helpers";
import { updateProject, getProject } from "../../../services/project.js";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import { getCollaborators } from "../../../services/collaborators";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";

export default function ProjectSettings() {
  let { project_id } = useParams();

  ReactSession.setStoreType("localStorage");

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(true);

  const [collabLoading, setCollabLoading] = useState(false);

  const [project, setProject] = useState({ project_name: "" });

  const [formLoading, setFormLoading] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formValues, setFormValues] = useState({ project_name: "", image: "" });

  const [formErrors, setFormErrors] = useState({
    image: null,
    project_name: null,
    error_class: null,
  });

  const [successMsg, setSuccessMessage] = useState(
    ReactSession.get("successVerifiedMessage")
  );

  const [errorMsg, setErrorMessage] = useState(null);
    const [planType, setPlanType] = useState("FREE");

  const [image, setImage] = useState(null);

  const [previewUrl, setPreviewUrl] = useState("");

  const user = ReactSession.get("user");

  let emailSelect = useRef();

  const [collaborators, setCollaborators] = useState([]);

  const [collaboratorsApi, setCollaboratorsApi] = useState([]);

  const [collaboratorOptions, setCollaboratorOptions] = useState([]);

  const [emailLabelClass, setEmailLabelClass] = useState("inactive_label");

  const [email, setEmail] = useState(null);

  const [collaboratorRow, setCollaboratorRow] = useState({
    id: null,
    user_id: "",
    email: "",
    role: "Admin",
  });

  const getPlan = () => {
    let token = ReactSession.get("token");

    setLoading(true);

    getCurrentPlansService(token).then((response) => {
      setLoading(false);

      if (response.success) {
        setPlanType(response.plan.type);
      } else {
        //   setErrorMessage(response.setErrorMessage);
      }
    });
  };

  const getCollabortorsApi = () => {
    let token = ReactSession.get("token");

    setLoading(true);

    getCollaborators(token).then((response) => {
      setLoading(false);

      if (response.success) {

        setCollaboratorsApi(response.collaborators);

        var collab_options = [];

        response.collaborators.forEach(function (item_a) {
          var exist = false;

          collaborators.forEach(function (item_b) {
            if (item_a.id == item_b.user_id) {
              exist = true;
            }
          });
          if (!exist) {
            collab_options.push({ value: item_a.id, label: item_a.email });
          }
        });
        setCollaboratorOptions(collab_options);

        window.setLabels();
      } else {
        setErrorMessage(response.setErrorMessage);
      }
    });
  };

  const getProjectApi = () => {
    let token = ReactSession.get("token");

    setLoading(true);

    getProject({ project_id: project_id }, token).then((response) => {
      

      if (response.success) {
        window.setLabels();

        setFormValues(response.project);

        const collaboratorsFinal = [...collaborators];

        response.project.collaborators.forEach(function (item) {
          collaboratorsFinal.push({
            id: item.id,
            user_id: item.user_id,
            email: item.collaborator.email,
            last_active: item.collaborator.last_active,
            role: item.role,
          });
        });
        setCollaborators(collaboratorsFinal);
        setLoading(false);
        getCollabortorsApi();
      } else {
        setLoading(false);
        setErrorMessage(response.setErrorMessage);
      }
    });
  };
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME + " - Project Settings";

    getProjectApi();
    getPlan();
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();

    setErrorMessage(null);

    resetFormErrors();


    if (!formLoading) {
      var error = false;

      if (formValues.project_name == "") {
        showFormError("project_name", "Required field");
        error = true;
      }

      if (!error) {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var data = new FormData();
        data.append("image", image);
        data.append("project_name", formValues.project_name);

        data.append("collaborators", JSON.stringify(collaborators));

        data.append("project_id", project_id);

        updateProject(data, token).then((response) => {
          setFormLoading(false);

          if (response.success) {
            setFormSubmitted(true);
            toast(
            <div className="toastinner">
                {response.message}
                <a
                onClick={() => {
                    toast.dismiss();
                }}
                >
                &times;
                </a>
            </div>,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: 3000,
            }
            );
            // setSuccessMessage(response.message);
          } else {
                toast(
                <div className="toastinner">
                    {response.message}
                    <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                    >
                    &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: 3000,
                }
                );
            // setErrorMessage(response.message);
          }
        });
      }
    }
    return false;
  };

  const set = (name) => {
    return ({ target: { value } }) => {
      setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };
  const clearFormValue = (name) => {
    return ({ target: { value } }) => {
      setTimeout(function () {
        setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
      }, 1000);
    };
  };
  const showFormError = (name, value) => {
    let formErrorsLocal = Object.assign({}, formErrors);

    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }
    formErrorsLocal[name] = value;

    formErrorsLocal["error_class"] = "input_error";

    setFormErrors(formErrorsLocal);

  };
  const resetFormValues = () => {
    let formValuesLocal = Object.assign({}, formValues);

    for (var key in formErrors) {
      formValuesLocal[key] = "";
    }
    setFormValues(formValuesLocal);
  };
  const resetFormErrors = () => {
    let formErrorsLocal = Object.assign({}, formErrors);

    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }
    setFormErrors(formErrorsLocal);
  };
  const handleFile = (file) => {
    //you can carry out any file validations here...
    if (!isFileImage(file)) {
      showFormError("image", "Please select a valid image");
      return;
    }
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleOnDragOver = (event) => {
    event.preventDefault();
  };
  const handleOnDrop = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];

    handleFile(imageFile);
  };
  const removeImage = (e) => {
    setImage(null);
    setPreviewUrl(null);
  };
  const handleFileInput = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.target.files[0];

    handleFile(imageFile);
  };
  const clearErrorMessage = () => {
    setErrorMessage(null);
  };
  const clearSuccessMessage = () => {
    setSuccessMessage(null);
  };
  const emailChange = (item) => {
    setEmail(item);
    setCollaboratorRow((oldValues) => ({
      ...oldValues,
      ["user_id"]: item.value,
      ["email"]: item.label,
    }));
    setEmailLabelClass("active_label");
  };
  const setCollabortorRole = (role) => {
    return () => {
      setCollaboratorRow((oldValues) => ({ ...oldValues, ["role"]: role }));
    };
  };
  const updateCollaboratorOptions = () => {
    var collab_options = [];

    collaboratorsApi.forEach(function (item_a) {
      var exist = false;

      collaborators.forEach(function (item_b) {
        if (item_a.id == item_b.user_id) {
          exist = true;
        }
      });
      if (!exist) {
        collab_options.push({ value: item_a.id, label: item_a.email });
      }
    });
    setCollaboratorOptions(collab_options);
  };
  const addCollaborator = () => {
    return (e) => {
      showFormError("email", null);

      var error = false;

      if (collaboratorRow.email == "") {
        showFormError("email", "Please select email");

        error = true;
      } else {
        var exist = false;

        collaborators.forEach(function (item) {
          if (item.user_id == collaboratorRow.user_id) {
            exist = true;
          }
        });
        if (exist) {
          showFormError(
            "email",
            "Collaborator is already added for this email."
          );

          error = true;
        }
      }

      if (!error) {
        setEmailLabelClass("inactive_label");

        setEmail(null);

        const collaboratorsFinal = [...collaborators];

        collaboratorsFinal.push(collaboratorRow);

        setCollaborators(collaboratorsFinal);

        setCollaboratorRow({ user_id: "", email: "", role: "Admin" });
      }
    };
  };
  const updateCollabortorRole = (role, index) => {
    return () => {
      const collaboratorsFinal = [...collaborators];

      collaboratorsFinal[index]["role"] = role;

      setCollaborators(collaboratorsFinal);
    };
  };
  const removeCollaborator = (index) => {
    return () => {
      const collaboratorsFinal = [...collaborators];

      collaboratorsFinal.splice(index, 1);

      setCollaborators(collaboratorsFinal);
    };
  };

  const renderCollaborators = () => {
    var render = [];

    collaborators.forEach(function (item, index) {
      render.push(
        <tr key={"collab" + item.id}>
          <td>
            <div className={"add-member-email-td"}>{item.email}</div>
          </td>
          <td>
            <Dropdown>
              <Dropdown.Toggle
                id={"dropdownMenuButton" + item.user_id}
                className="dropdown-toggle"
              >
                {item.role}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={updateCollabortorRole("Admin", index)}
                >
                  Admin
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={updateCollabortorRole("Editor", index)}
                >
                  {" "}
                  Editor
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={updateCollabortorRole("Viewer", index)}
                >
                  Viewer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <td>
            <div className="last-active-wrap">
              {item.last_active ? moment(item.last_active).fromNow() : "N/A"}
              <span
                className="delete-table-row"
                onClick={removeCollaborator(index)}
              >
                <img
                  src={process.env.REACT_APP_URL + "images/cross.svg"}
                  alt="img"
                />
              </span>
            </div>
          </td>
        </tr>
      );
    });

    return render;
  };
  return (
    <LayoutResearcher
      isLoading={false}
      skipCheck={false}
      activeMenu={"dashboard"}
    >
      <div className="dashboard-data-wrapper">
        <div className="dashboard-back-list-option">
          <div className="backto-list-wrap">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <img
                src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
                alt="img"
              />{" "}
              Back
            </a>
          </div>
        </div>

        <form onSubmit={onSubmit}>
          <div className="dashboard-top-wrapper">
            <div className="dash-top-left">
              <h1 className="page-main-sm-heading dash-flex-heading">
                Project Settings
                {isLoading && (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    style={{ marginLeft: "5px" }}
                  ></i>
                )}
                {formSubmitted && (
                  <sub className="project-saved-wrap">
                    saved {moment().format("DD-MM, HH:mm")}
                  </sub>
                )}
              </h1>
            </div>

            <div className="dashboard-top-right">
              {formSubmitted && (
                <button className="create-btn changes-saved-btn">
                  <span>
                    <img
                      src={process.env.REACT_APP_URL + "images/fill-check.svg"}
                      alt="img"
                    />
                  </span>
                  Changes saved
                </button>
              )}
              {!formSubmitted && (
                <>
                  <button
                    type="button"
                    className="btn form-btn secondary-btn"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </button>

                  <button type="submit" className="btn form-btn">
                    Save
                    {formLoading && (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
          {isLoading && (
            <div className="project-listing-loader loader_section">
              Loading...
            </div>
          )}
          {!isLoading && (
            <div className="dashboard-inner-data-wrapper project-detail-dash-wrap project-settings-page-wrapper">
              <div className="project-setting-datawrap">
                <div className="row">
                  <div className="col-lg-3">
                    <div
                      className="project-modal-img project-setting-image-wrap"
                      onDragOver={handleOnDragOver}
                      onDrop={handleOnDrop}
                    >
                      <div className="uploaded-project-img">
                        {!previewUrl && (
                          <>
                            {formValues.image && (
                              <>
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_URL +
                                    formValues.image
                                  }
                                  alt="img"
                                />
                              </>
                            )}
                            {!formValues.image && (
                              <>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/project-img.png"
                                  }
                                  alt="img"
                                />
                              </>
                            )}
                            <div className="change-img-option">
                              <div>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/upload-img.svg"
                                  }
                                  alt="img"
                                />
                                <p>Change image</p>
                              </div>
                            </div>
                          </>
                        )}
                        {previewUrl && (
                          <>
                            <img src={previewUrl} alt="img" />
                            <div className="change-img-option">
                              <div>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/upload-img.svg"
                                  }
                                  alt="img"
                                />
                                <p>Change image</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="upload-project-img">
                        <p>
                          Drag and drop an image, or &nbsp;
                          <b>
                            Browse
                            <input type="file" onChange={handleFileInput} />
                          </b>
                        </p>
                      </div>
                      {formErrors.image != null && (
                        <span className={formErrors.error_class}>
                          {formErrors.image}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="project-setting-right-side">
                      <div className="create-project-modal-data">
                        <h3 className="h3">Project Name</h3>

                        <div className="create-projectmodal-form-group">
                          <div className="input form-group input-field">
                            <input
                              type="text"
                              className={
                                formErrors.project_name != null
                                  ? "form-control " + formErrors.error_class
                                  : "form-control"
                              }
                              id="project_name"
                              maxLength="20"
                              onInput={maxLengthCheck}
                              onChange={set("project_name")}
                              defaultValue={formValues.project_name}
                            />
                            <label
                              className="control-label"
                              htmlFor="project_name"
                            >
                              Project name
                            </label>
                            <span
                              className="clear"
                              onClick={clearFormValue("project_name")}
                            >
                              <svg viewBox="0 0 24 24">
                                <path
                                  className="line"
                                  d="M 2,2 C 3.925,3.925 8.149999999999999,8.15 12,12 C 15.85,15.85 20.075,20.075 22,22"
                                ></path>
                                <path className="long" d="M9 15L20 4"></path>
                                <path className="arrow" d="M13 11V7"></path>
                                <path className="arrow" d="M17 11H13"></path>
                              </svg>
                            </span>
                          </div>
                          <p className="project-name-guide-text">
                            Max characters:
                            <span className="pink-color">
                              {formValues.project_name.length > 0 && (
                                <>{formValues.project_name.length}/</>
                              )}
                              20
                            </span>
                          </p>
                          {formErrors.project_name != null && (
                            <span className={formErrors.error_class}>
                              {formErrors.project_name}
                            </span>
                          )}
                        </div>

                        <div className="add-project-colaborator collab-add-projects">
                          <h3 className="pro-set-col-head">
                            Project collaborators
                            {collabLoading && (
                              <i
                                className="fa fa-spinner fa-spin"
                                aria-hidden="true"
                                style={{ marginLeft: "5px" }}
                              ></i>
                            )}
                            {planType == "PAID" && (
                              <div className="added-collaborators-wrap">
                                <div className="group-slide-left">
                                  <img
                                    src={
                                      process.env.REACT_APP_URL +
                                      "images/user-profile-icon.png"
                                    }
                                    alt="img"
                                  />
                                  <span>
                                    <b>{collaborators.length}</b> members
                                  </span>
                                </div>
                              </div>
                            )}
                          </h3>
                          {collaboratorOptions.length > 0 && (
                            <p>
                              Collaborators will be able to view, add and edit
                              the tests within a project.
                            </p>
                          )}
                          {collaboratorOptions.length == 0 &&
                            planType == "FREE" && (
                              <p>
                                Collaborators will be able to view, add and edit
                                the tests within a project.
                              </p>
                            )}

                          {planType == "PAID" &&
                            collaboratorOptions.length == 0 && (
                              <>
                                <p>
                                  You can add project collaborators within your
                                  account members group. It looks like you are
                                  the only member of this account.
                                </p>
                                <div className="no-collaborator-added">
                                  <Link
                                    className="link linktype-1"
                                    to="/researcher/team"
                                  >
                                    Add ACCOUNT members
                                  </Link>
                                </div>
                              </>
                            )}

                          {planType == "PAID" && collaborators.length > 0 && (
                            <div className="project-setting-table-wrap mt-32">
                              <table>
                                <thead>
                                  <tr>
                                    <th>User email</th>
                                    <th>Role</th>
                                    <th>Last active</th>
                                  </tr>
                                </thead>
                                <tbody>{renderCollaborators()}</tbody>
                              </table>
                            </div>
                          )}

                          {planType == "PAID" && (
                            <div className="add-plan-member-sec">
                              <div className="create-projectmodal-form-group mb-32">
                                <div className="project-colaborator-form-wrap d-block">
                                  {collaboratorOptions.length > 0 && (
                                    <>
                                      {openForm && (
                                        <>
                                          <div className="input form-group input-field">
                                            <div className="position-relative w-100">
                                              <label
                                                htmlFor="email"
                                                className={emailLabelClass}
                                              >
                                                Enter email...
                                              </label>
                                              <div
                                                className={
                                                  formErrors.email != null
                                                    ? "input_error"
                                                    : ""
                                                }
                                              >
                                                <Select
                                                  value={email}
                                                  options={collaboratorOptions}
                                                  onChange={emailChange}
                                                  ref={emailSelect}
                                                  placeholder="Enter Email..."
                                                  components={{DropdownIndicator}}
                                                />
                                              </div>
                                              {formErrors.email != null && (
                                                <span
                                                  className={
                                                    formErrors.error_class
                                                  }
                                                >
                                                  {formErrors.email}
                                                </span>
                                              )}
                                            </div>
                                            <span className="project-permission-drop-down">
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  id="dropdownMenuButton"
                                                  className="dropdown-toggle"
                                                >
                                                  {collaboratorRow.role}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    href="#"
                                                    onClick={setCollabortorRole(
                                                      "Admin"
                                                    )}
                                                  >
                                                    Admin
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    href="#"
                                                    onClick={setCollabortorRole(
                                                      "Editor"
                                                    )}
                                                  >
                                                    {" "}
                                                    Editor
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </span>
                                          </div>
                                          <div className="last-active-wrap close-add-collaborator">
                                            &nbsp;
                                            <span
                                              className="delete-table-row"
                                              onClick={() => {
                                                setOpenForm(false);
                                                setEmail(null);
                                                emailSelect.current = null;
                                                setCollaboratorRow({
                                                  email: "",
                                                  role: "Admin",
                                                });
                                                showFormError("email", null);
                                              }}
                                            >
                                              <img
                                                src={
                                                  process.env.REACT_APP_URL +
                                                  "images/cross.svg"
                                                }
                                                alt="img"
                                              />
                                            </span>
                                          </div>
                                        </>
                                      )}

                                      <div
                                        className="create-btn add-new-card-btn add-new-member-btn mt-32"
                                        onClick={
                                          openForm
                                            ? addCollaborator()
                                            : () => {
                                                setOpenForm(true);
                                              }
                                        }
                                      >
                                        Add new member
                                        <span>
                                          <img
                                            src={
                                              process.env.REACT_APP_URL +
                                              "images/fill-plus.svg"
                                            }
                                            alt="img"
                                          />
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {planType == "FREE" && (
                      <div className="block-colaborator-section">
                        <div className="lock-img-sec">
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/lock-icon.svg"
                            }
                          />
                        </div>

                        <div className="text-wrap">
                          <p>
                            You don’t have access to collaboration features.
                            Upgrade your plan to add collaborators.
                          </p>
                          <button type="button" className="btn form-btn">
                            Upgrade now
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </LayoutResearcher>
  );
}
