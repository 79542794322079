import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { Dropdown } from 'reactjs-dropdown-component';
import { useSearchParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'
import { PhoneNumberUtil } from 'google-libphonenumber';
import ReCAPTCHA from "react-google-recaptcha";
import { load } from 'recaptcha-v3'
import { Text } from '../../themes/userq/Text/Text';
import { CheckBox } from '../../themes/userq/CheckBox/CheckBox';
import { Button } from '../../themes/userq/Button/Button';
import { Input } from '../../themes/userq/Input/Input';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../../data/settings';
import { Logo } from '../../themes/userq/Logo/Logo';
import { Footer } from '../../themes/userq/Footer/Footer';
import GoogleAuth from '../login-with-google';
import { LoadingIcon } from '../../loader/loadingIconNew';
import { Icon } from '../../themes/userq/Icon/Icon';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login';
import Cookies from 'js-cookie';

import { encryptClient, preventClick } from '../../../lib/helpers';
import { Helmet } from 'react-helmet';

let captcha = null;


export default function Register() {

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const [social, setSocial] = useState("All");

    const navigate = useNavigate();

    let jobRolesSelect = useRef();

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];

    const [jobRoleLabelClass, setJobRoleLabelClass] = useState("inactive_label");

    const [errorMsg, setErrorMessage] = useState(null);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', captcha: '', dialcode_phone: null, phone_number: "", first_name: '', last_name: '', email: ReactSession.get('register_email'), password: '', company_name: '', job_role: '', job_role_other: '', newsletter: true, terms: false, over18: false, role: 'Tester' });

    const [formErrors, setFormErrors] = useState({ phone_no: null, first_name: null, last_name: null, password: null, company_name: null, job_role: null, job_role_other: null, job_role_other: null, passwordErrorStyle: { color: '#000' }, terms: null, over18:null });

    const [modal, setModal] = useState({ modalHeading: null, modalMessage: null, modalOpen: false });

    const [socialLoading, setSocialLoading] = useState(false);

    const emailAlreadySet = ReactSession.get('register_email') ? true : false;

    const [newPasswordError, setNewPasswordError] = useState({error:false, length:0, letters:0, numbers:0, special:0});

    useEffect(() => {
        window.animate();
        document.title = "Tester Sign Up - Make money online | UserQ";


        //console.log();

        if (searchParams.get("plan_id")) {
            ReactSession.set("term", searchParams.get("term").toUpperCase());

            ReactSession.set("plan_id", searchParams.get("plan_id"));
        } else {
            ReactSession.set("term", null);

            ReactSession.set("plan_id", null);
        }
        if (!ReactSession.get('register_email')) {
            //navigate("/researcher/sign-in");
        }
        regenerateCaptcha();

    }, []);
    const regenerateCaptcha = () => {
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                setFormValues({ ...formValues, captcha: captcha });

            })
        })
    }
    const generateCaptcha = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                setFormValues({ ...formValues, captcha: captcha });

                callback(token);

            })
        })
    }
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const responseApple = (response) => {
        //setSocialLoading(true);

        setErrorMessage(null);

        if('authorization' in response){

            setSocialLoading(true);

            generateCaptcha(function(token){
                response['captcha'] = token;
                response['referralCode'] = searchParams.get('refID');
                response['test_result_id'] = ReactSession.get("test_result_id");


                fetch(process.env.REACT_APP_API_END_POINT+'social-login/apple/Tester', {
                    method: 'POST',
                    body: JSON.stringify(response),
                    headers:{"Content-Type":"application/json"}
                })
                .then(res => res.json())
                .then(response => {
                    setSocialLoading(false);
                    if(response.success){
                        ReactSession.set("token", response.token);
                        ReactSession.set("user", response.user);

                        var date = new Date();
                        Cookies.set("expires_at", date.getTime()+30*24*60*60*1000);

                        Cookies.set("last_login_method_tester","Apple", { expires: 365*24*60*60 });

                        if(response.user.role === "Tester"){
                            
                            if(response.tester && response.tester.completed_step === 5){

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event' : 'sign_in',
                                    'usertype': 'tester',
                                    'method': 'apple',
                                    'userID' : response.user.user_id
                                });
                                
                                var user =  ReactSession.get("user");

                                user["completed_step"] = response.tester.completed_step;

                                ReactSession.set("user", user);

                                navigate('/dashboard');
                            } else {
                                navigate('/tester/profile/step1');
                            }
                        }else{
                            
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event' : 'sign_in',
                                'usertype': 'tester',
                                'method': 'apple',
                                'userID' : response.user.user_id
                            });

                            navigate('/dashboard');
                        }

                    } else {
                        
                        showError(response.message);
                    }
                });
            })
            
            
        }
    }
   
    const responseFacebook = (response) => {
        
        if(response){
          //  setLoading(true);
            setSocialLoading(true);

            generateCaptcha(function(token){
                setErrorMessage(null);
                
                response['captcha'] = token;
                response['referralCode'] = searchParams.get('refID');
                response['test_result_id'] = ReactSession.get("test_result_id");

                fetch(process.env.REACT_APP_API_END_POINT+'social-login/facebook/Tester', {
                    method: 'POST',
                    body: JSON.stringify(response),
                    headers:{"Content-Type":"application/json"}
                })
                .then(res => res.json())
                .then(response => {
                    setSocialLoading(false);
                    if(response.success){
                        ReactSession.set("token", response.token);
                        ReactSession.set("user", response.user);

                        var date = new Date();
                        Cookies.set("expires_at", date.getTime()+30*24*60*60*1000);

                        Cookies.set("last_login_method_tester","Facebook", { expires: 365*24*60*60 });

                        if(response.user.role === "Tester"){

                            

                            if(response.tester && response.tester.completed_step === 5){

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event' : 'sign_in',
                                    'usertype': 'tester',
                                    'method': 'facebook',
                                    'userID' : response.user.user_id
                                });
                                var user =  ReactSession.get("user");

                                user["completed_step"] = response.researcher.completed_step;

                                ReactSession.set("user", user);

                                navigate('/dashboard');
                            }else{
                                navigate('/tester/profile/step1');
                            }
                        } else {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event' : 'sign_in',
                                'usertype': 'tester',
                                'method': 'facebook',
                                'userID' : response.user.user_id
                            });
                            navigate('/dashboard');
                        }

                    } else {
                        
                        showError(response.message);
                    }
                });
            })
                
        }
    }

    const registerUser = () => {

        setLoading(true);

        generateCaptcha(function (token) {

            formValues.captcha = token;

            setErrorMessage(null);

            let body = formValues;

            body['role'] = 'Tester';
            body['referralCode'] = searchParams.get('refID');

            /*if (body["job_role"] == "Other...") {
                body["job_role"] = body["job_role_other"];
            }*/
            body["fingerprint"] = window.fingerprintvisitorid;
            fetch(process.env.REACT_APP_API_END_POINT + 'register', {
                method: 'POST',
                body: JSON.stringify(formValues),
                headers: { "Content-Type": "application/json" }
            })
                .then(res => res.json())
                .then(response => {
                    setLoading(false);
                    if (response.success) {
                        ReactSession.set("register_email", formValues.email);
                        navigate("/tester/registration/success");

                    } else {
                        showError(response.message);
                    }
                });

        })

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value='',inline=false ) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = resetFormErrors();
        }

        var firsterrorid = null;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
       
        if(!key || key=="email"){
            
            var field_value = key && inline ? value : formValues.email;

            if (field_value == '') {


                form_errors = {
                    ...form_errors,
                    email: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
            else if (field_value!=null && !field_value.match(mailformat)) {

                form_errors = {
                    ...form_errors,
                    email: "Please enter a valid email",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
        }
        if(!key || key=="password"){

            var field_value = key && inline ? value : formValues.password;

            if(!inline){
                validateNewPassword(field_value,true);
            } else {
                validateNewPassword(field_value,false);
            }

            if (field_value.length < 6 || !field_value.match(re)) {

                /*form_errors = {
                    ...form_errors,
                    //password: "Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)",
                    error_class: "input_error",
                    password_error_class: "password_error",
                };*/
                error = true;
                firsterrorid = firsterrorid == null ? "pass_log_id" : firsterrorid;

                if(!field_value){
                    form_errors = {
                        ...form_errors,
                        password: "Required field",
                        error_class: "input_error",
                        password_error_class: "password_error",
                    };    
                }
            }
        }
        if(!key){
            if (!formValues.terms) {

                if(focus){
                    showError(<FormattedMessage id="You must agree to our Terms and Conditions and Privacy Policy" />);
                }
                error = true;

            }
            if (!formValues.over18) {

                if(focus){
                    showError(<FormattedMessage id="You must be over 18 years old" />);
                }
                error = true;

            }
            
        }
        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        

        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const validateBtnState = () => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if (formValues.email == '') {
            error = true;
        }
        else if (formValues.email!=null && !formValues.email.match(mailformat)) {

            error = true;
        }
        if (formValues.password.length < 6 || !formValues.password.match(re)) {
   
            error = true;            
        }        
        if (!formValues.terms) {

            error = true;
        }
        if (!formValues.over18) {

            error = true;
        }
        return error;
    }
    const onSubmit = async (event) => {
        event.preventDefault();

        //if (formValues.terms) {
            setErrorMessage(null);

            if (!isLoading) {

                var error = validateFullForm(true);

                if (!error) {
                    registerUser();
                }
            }
        //}
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues(oldValues => ({ ...oldValues, [name]: value }));
                }
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            }

            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value ? value: '',true)
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {

            setTimeout(function () {
                setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
            }, 1000)

        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const validateForm = (name) => {


        return (event) => {

            setFormValues(oldValues => ({ ...oldValues, [name]: event.target.value }));


            var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

            if (formValues.password.length < 6 || !formValues.password.match(re)) {
                setFormErrors(oldValues => ({ ...oldValues, ['password']: 'Invalid password' }));
            } else {
                setFormErrors(oldValues => ({ ...oldValues, ['password']: '' }));
            }
        }

    }
    
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const jobRoleChange = (item) => {
        jobRolesSelect.current.selectSingleItem({ value: item.value });
        setFormValue("job_role", item.value);
        setJobRoleLabelClass('active_label');
        setFormErrors((oldValues) => ({ ...oldValues, job_role: null }));
        window.animate();
    }
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    const validateNewPassword = (password, blur) =>{

        var new_password_error = Object.assign({},newPasswordError);

        if(password.length>0){
            
            new_password_error = {...new_password_error, error: true};

            
            if(password.length >= 6){

                new_password_error = {...new_password_error, length: 1};
                
            } else {
                
                if(blur){
                    new_password_error = {...new_password_error, length: 2};
                } else {
                    if(new_password_error.length==0){
                        new_password_error = {...new_password_error, length: 0};
                    } else {
                        new_password_error = {...new_password_error, length: 2};
                    }
                }
            }
            const specialChars =/[@$!%*#?&]/;

            if(specialChars.test(password)){
                new_password_error = {...new_password_error, special: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, special: 2};
                }  else {
                    if(new_password_error.special==0){
                        new_password_error = {...new_password_error, special: 0};
                    } else {
                        new_password_error = {...new_password_error, special: 2};
                    }
                }
            }
            const numbers =/[0-9]/;

            if(numbers.test(password)){
                new_password_error = {...new_password_error, numbers: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, numbers: 2};
                } else {
                    if(new_password_error.numbers==0){
                        new_password_error = {...new_password_error, numbers: 0};
                    } else {
                        new_password_error = {...new_password_error, numbers: 2};
                    }
                }
            }
            const letters =/[a-zA-Z]/;

            if(letters.test(password)){
                new_password_error = {...new_password_error, letters: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, letters: 2};
                } else {
                    if(new_password_error.letters==0){
                        new_password_error = {...new_password_error, letters: 0};
                    } else {
                        new_password_error = {...new_password_error, letters: 2};
                    }
                }
            }
            setNewPasswordError(new_password_error);

        } else {

            var new_password_error = Object.assign({},{error:false, length:0, letters:0, numbers:0, special:0});

            setNewPasswordError({...new_password_error, error: false});
        }
    }
    return (
        <div className="login-page-wrapper">
            <Helmet>
                <title>Tester Sign Up - Make money online | UserQ</title>
                <meta
                    name="description"
                    content="Want to make money online in UAE or other MENA countries? As a tester, your opinions will shape brands’ digital experiences – and you’ll be paid. Sign up."
                />
            </Helmet>
            {socialLoading &&
            <div className={'dashboard-filter-loader'}  style={{height:"100vh"}}>
                <LoadingIcon/>
            </div>
            }
            {!socialLoading &&
            <>
            <div className="researcher-registerheader login-page-header">
                <div className="container">
                    <div className='row align-items-center'>
                        <div className="col-xs-6 col-sm-6 login-hdr-left">
                            <a href={process.env.REACT_APP_TESTER_URL} className="logo">
                                
                                <Logo theme={"black"} background={"white"} />
                            </a>
                        </div>
                        <div className="col-xs-6 col-sm-6 login-hdr-right text-right">      
                                <Button 
                                    type={"primary"}
                                    size={"medium"}
                                    label={"I'm a researcher"}
                                    iconRight={
                                        <Icon colorClass="gray-50-svg" value={"forward-arrow"} size={"small"} />
                                    }
                                    tag="link"
                                    href="/researcher/sign-in"
                                />

                            </div>
                        
                    </div>
                </div>
            </div>
            <div className={`login-data-wrap researcher-register-wrap ${social!="All"?"single-social-box":""}`}>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className="col-md-6">
                        <Text type={"h1"} cssClasses={"h3 mb-20"} fontWeight={"semi-bold-font"}>Create a <span className="themeclr">tester</span> account</Text>
                            
                            <Text type={"body-text-2"} fontWeight={"medium-font"}>Create an account and get paid to take tests</Text>  
                            
                            <div className="login-form-inner-data mt-40">
                                {errorMsg && (
                                    <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                        <div className="pro-lft-wrap">{errorMsg}</div>
                                        <div className="pro-right-wrap">
                                            <div className="delete-progress-row">
                                                <img
                                                    onClick={clearErrorMessage}
                                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="formbox">
                                        <Input 
                                            type="text"
                                            id="email"
                                            //name="email"
                                            value={formValues.email}
                                            onChange={set("email")}
                                            label={"Email address"}
                                            onBlur={()=>{validateFullForm(false, "email")}}
                                            onClear={()=>{setFormValues({...formValues,email:''})}}
                                            onFocus={()=>{ setFormErrors({...formErrors, email:null}) }}
                                            required={true}
                                            error={formErrors.email}
                                        />
                                        
                                        </div>    
                                    <div className="formbox">
                                            <Input 
                                                passwordField={true}
                                                type={"password"}
                                                id="pass_log_id"
                                                name="password"
                                                value={formValues.password}
                                                onChange={set("password")}
                                                onBlur={()=>{validateFullForm(false,"password")}}
                                                onFocus={()=>{ setFormErrors({...formErrors, password:null}) }}
                                                required={true}
                                                label={"Create password"}
                                                clearField={false}
                                                newPasswordError={newPasswordError}
                                                error={formErrors.password}
                                                // passwordSuggestion={"Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)"}
                                            />
                                            
                                            
                                        
                                        {formErrors.password_error_class != 'none' &&
                                            <p
                                                className={
                                                    formErrors.password_error_class != null
                                                        ? "password-guide-text " +
                                                        formErrors.password_error_class
                                                        : "password-guide-text "
                                                }
                                                style={formErrors.passwordErrorStyle}
                                            >
                                                
                                            </p>
                                        }
                                        {/* {(formErrors.password==null || formErrors.password=='') &&
                                            <span className="caption">
                                                Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)
                                            </span>
                                        } */}
                                    </div>
                                    <div className="account-term-and-condition-wrap">
                                        <div className="form-group resarcher-termcond custom-checkbox tester-termcond">
                                            <CheckBox checked={formValues.terms}
                                                onChange={(event) => { toggleCheck('terms', event.currentTarget.checked) }}
                                                id="termsCheckBox"
                                                label={
                                                    <>
                                                     I agree to the{" "}
                                                
                                                    <a
                                                        href={
                                                            process.env.REACT_APP_SITE_URL +
                                                            "terms-of-use"
                                                        }
                                                        target="_blank"
                                                    >
                                                        Terms of Use
                                                    </a>
                                                {" "}
                                                and have read the{" "}
                                               
                                                    <a
                                                        href={
                                                            process.env.REACT_APP_SITE_URL + "privacy-policy"
                                                        }
                                                        target="_blank"
                                                    >
                                                        Privacy Policy
                                                    </a>
                                               </>
                                                }
                                            />
                                            <CheckBox checked={formValues.over18}
                                                onChange={(event) => { toggleCheck('over18', event.currentTarget.checked) }}
                                                id="over18CheckBox"
                                                label={
                                                    <>
                                                     I confirm that I’m over 18 years old
                                                </>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="full createaccount-btnbox">
                                    <Button 
                                        label={"Create your account"}
                                        state={!validateBtnState()?"active":"disabled"}
                                        microLoading={isLoading}
                                        type={"primary"}
                                        size={"large"}
                                    />
                                    </div>
                                    
                                    <div className="full ordivider">
                                        <span>Or</span>
                                    </div>                                          
        
                                    <div className="full createaccount-googlebtn">
                                       <div className={`full tester-social-loginbox ${social!="All"?"single-social-box-2":""}`}>
                                        {(social=="All") &&
                                        <GoogleAuth role="tester" fromSignup={true} shortBtn={true} setLoading={(loading)=>{setSocialLoading(loading);}} setErrorMessage={(message)=>{showError(message);}}/>
                                        }
                                        {(social=="All") &&
                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FB_ID}
                                            fields="name,email,picture"
                                            status={true}
                                            autoLoad={false}
                                            callback={responseFacebook}
                                            render={renderProps => (
                                                <span onClick={renderProps.onClick}><a href="#" id='t_facebook_sign_in' onClick={preventClick}><img width={24} height={24} src={process.env.REACT_APP_URL+"img/fb-sociallogin.svg"} alt="icon"/></a></span>
                                            )}
                                        />
                                        }
                                        {(social=="All") &&
                                        <AppleLogin  
                                            clientId={"com.userq.app.apple"} 
                                            redirectURI={process.env.REACT_APP_URL+"tester/sign-up"}   
                                            responseType={"code"} 
                                            responseMode={"query"}  
                                            usePopup={true} 
                                            callback={responseApple}
                                            scope="name email"
                                            render={renderProps => (
                                                <span onClick={renderProps.onClick} disabled={renderProps.disabled}><a href="#" id='t_apple_sign_in' onClick={preventClick}><img width={24} height={24} src={process.env.REACT_APP_URL+"img/applelogin-tester.svg"} alt="icon"/></a></span>
                                            )}
                                        />
                                        }
                                        
                                        </div>
                                    </div>
                                    <div className="login-bottom-link form-group resarcher-btn-register">
                                        Already have an account? <Link to="/tester/sign-in">Sign in</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-6  login-right-side text-right'>
                            <img src={process.env.REACT_APP_URL+"/img/tester-signin-collage.png"} />
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
            <Footer />
        </div>
                     
    );
}