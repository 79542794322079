import React from 'react';
import { Navigate } from "react-router-dom";
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession';

export default function GuestRoute(props){
    ReactSession.setStoreType("localStorage");
  let ptoken = ReactSession.get("token");
  let user = ReactSession.get("user");

  console.log(user);
    if(user && ptoken!="" && ptoken!="null" && ptoken!=null){
        
        return (<Navigate to="/" />);
        
    } else {
        return props.element;
    }
    
}
