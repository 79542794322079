import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import {Text} from "../Text/Text";
import {ReactSession} from "../../../../lib/secure_reactsession";
import {Icon} from "../Icon/Icon";

export default function DownloadXlsxModal({
                                              openModal,
                                              confirm_message,
                                              confirm_title,
                                              confirm_btn_title,
                                              cancel_btn_title,
                                              close,
                                              confirm,
                                              btnLoading,
                                              confirm_title_class,
                                              btn_reverse
                                          }) {
    const closeModal = () => {
        close();
    };


    const user = ReactSession.get("user");
    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className={`fade custom-modal-wrap download-xlsx-report  confirmation-modal-wrap`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <Text type={'h3'}>Download result report</Text>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                            </button>
                        </div>

                        <div className={"download-modal-data-wrap"}>

                            <Text type={"body-text-3"} fontWeight={"medium-font"} children={'Your result report in .xls format will be sent to your email address:'} />

                            <Text type={"body-text-1"} cssClasses={"email-user"} fontWeight={"medium-font"} children={user?user.email:""} />

                            <Text type={"body-text-3"} fontWeight={"medium-font"} children={"It may take a couple of minutes to generate your download. You will receive it as soon as it’s ready."} />

                        </div>

                        <div className="confirm-buttons-wrap">


                            <Button type="secondary" size="large" label={'Cancel'} onClick={() => { closeModal(); }} />
                            <Button type="primary" size="large" label={"Confirm download"} onClick={() => { confirm(); }} />



                        </div>

                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
