import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Icon } from '../Icon/Icon';
import { Text } from '../Text/Text';
export const ToggleButton = ({isChecked, value, labelAlignment, label,  onChange, name, id, tooltip,isDisabled,...props})=>{
    
    const [buttonChecked, setButtonChecked] = useState(isChecked);

    useEffect(()=>{
        setButtonChecked(isChecked);
    },[isChecked])
    return (
        <span className={`toggle-btn-wrap toggle-custom-class ${label?'toggle-with-label':''} ${isDisabled?'disabled-toggle':''} `}>
            <label className="switch" htmlFor={id}>
                <input id={id} name={name} type={"checkbox"} checked={buttonChecked} onChange={(e)=>{
                        setButtonChecked(!buttonChecked);
                        if(onChange){
                            onChange(e);
                        }
                    } 
                } />
                <span className="slider round"></span>
                {label &&
                <p className={`toggle_btn_label ${labelAlignment=="left"?"toggle-btn-label-left": "toggle-btn-label-right"}`}>{label}</p>
                }
                
            </label>
            {tooltip &&
            <span className="info-wrap">
                <Icon value="information" size="medium"/>
                <div className="tooltip-wrapper bottom-tooltip">
                    <Text type={"body-text-2"} fontWeight={"normal-font"}>{tooltip}</Text>
                </div>
            </span>
            }
        </span>
    )
    
    
}
ToggleButton.propTypes = {
    onChange: PropTypes.func,
    labelAlignment: PropTypes.string,
    isChecked:PropTypes.bool,
    name:PropTypes.string,
    id:PropTypes.string,
    label:PropTypes.node
};
ToggleButton.defaultProps = {
    onChange: undefined,
    labelAlignment:"right",
    isChecked:false    
};