import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { ReactSession } from '../../lib/secure_reactsession';
import { useSearchParams, useNavigate } from "react-router-dom";
import {InformationTilesBox} from "../themes/userq/InformationTilesBox/information_tiles_box"
import { Text } from '../themes/userq/Text/Text';
import { Input } from '../themes/userq/Input/Input';
import { DropdownComponent } from '../themes/userq/Dropdown/Dropdown';
import { CheckBox } from '../themes/userq/CheckBox/CheckBox';
import { Button } from '../themes/userq/Button/Button';
import { Icon } from '../themes/userq/Icon/Icon';
import { PhoneNumberInput } from '../themes/userq/PhoneNumberInput/PhoneNumberInput';
import { getProfile, updateStep1, updateStep2, updateStep3, updateWelcomeMessage } from "../../services/user.js";
import { isValidPhoneNumber } from 'react-phone-number-input'
import { LoadingIcon } from '../themes/userq/Loader/loadingIcon';
import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../data/settings';
import { PageProgressBar } from '../themes/userq/ProgressBar/page_progress_bar';
import { RadioButton } from '../themes/userq/RadioButton/RadioButton';
import { CountryDropdownSingle } from '../themes/userq/CountryDropdown/CountryDropdownSingle';
import {InputCalendar} from '../themes/userq/InputCalendar/InputCalendar';
import { LayoutTester } from '../themes/userq/Layouts/layout_tester';
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";

import moment from "moment";
import { getAge, removeItemFromArray } from "../../lib/helpers";
import { IncDecCounter } from '../themes/userq/IncDecCounter/IncDecCounter';
import { Chip, Chips } from '../themes/userq/Chips/Chips';
import { TesterSlider } from './tester_slider';
import { SignUpInformationalCard } from '../themes/userq/SignUpInformationalCard/sign_up_informational_card';


export default function Step1() {

    const [startPosition, setStartPosition] = useState(0);

    ReactSession.setStoreType("localStorage");

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    
    const hoursOptions = [{"label": "1-3 hours","value": "1-3 hours" }, {"label":"4-6 hours","value":"4-6 hours"}, {"label":"more than 6 hours","value":"more than 6 hours"}];
    
    const gadgetsOptions = [
      { label: "Desktop", value: "Desktop" },
      { label: "Smartphone", value: "Smartphone" },
      { label: "Tablet", value: "Tablet" },
    ];
    
    const appsOptions = ["Shopping", "Hobbies", "Social media", "News", "Gaming" , "Chat", "Collaboration", "Banking & finance", "Health", "Transportation", "Travel", "Office", "Mail", "Food delivery","Content creation","Learning","Maintenance","Streaming","Messaging","Fitness"];

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({ hours_spend_online:"",  apps: [],  tech_gadgets:"", role:"Tester"});

    const [formErrors, setFormErrors] = useState({  hours_spend_online:null,  apps: null,  tech_gadgets:null, error_class:null });

    
    const employmentOptions = [
		{ label: "Student", value: "Student" },
		{ label: "Self employed", value: "Self employed" },
		{ label: "Unemployed", value: "Unemployed" },
		{ label: "Private employee", value: "Private employee" },
		{ label: "Public employee", value: "Public employee" },
		{ label: "Homemaker", value: "Homemaker" },
		{ label: "Retired", value: "Retired" },
	];

	const industryOptions = [
    {
      label: "Accountancy, banking and finance",
      value: "Accountancy, banking and finance",
    },
    {
      label: "Business, consulting and management",
      value: "Business, consulting and management",
    },
    {
      label: "Charity and voluntary work",
      value: "Charity and voluntary work",
    },
    { label: "Creative arts and design", value: "Creative arts and design" },
    { label: "Digital", value: "Digital" },
    { label: "Energy and utilities", value: "Energy and utilities" },
    {
      label: "Engineering and manufacturing",
      value: "Engineering and manufacturing",
    },
    {
      label: "Environment and agriculture",
      value: "Environment and agriculture",
    },
    { label: "Healthcare", value: "Healthcare" },
    {
      label: "Hospitality and events management",
      value: "Hospitality and events management",
    },
    { label: "Information technology", value: "Information technology" },
    { label: "Law", value: "Law" },
    {
      label: "Law enforcement and security",
      value: "Law enforcement and security",
    },
    {
      label: "Leisure, sport and tourism",
      value: "Leisure, sport and tourism",
    },
    {
      label: "Marketing, advertising and PR",
      value: "Marketing, advertising and PR",
    },
    { label: "Media and internet", value: "Media and internet" },
    { label: "Property and construction", value: "Property and construction" },
    {
      label: "Public services and administration",
      value: "Public services and administration",
    },
    { label: "Recruitment and HR", value: "Recruitment and HR" },
    { label: "Retail", value: "Retail" },
    { label: "Sales", value: "Sales" },
    {
      label: "Science and pharmaceuticals",
      value: "Science and pharmaceuticals",
    },
    { label: "Social care", value: "Social care" },
    {
      label: "Teacher training and education",
      value: "Teacher training and education",
    },
    {
      label: "Transport and logistics",
      value: "Transport and logistics",
    },
    { label: "Other (please specify)", value: "Other (please specify)" },
  ];

	const departmentOptions = [
		{ label: "Finance", value: "Finance" },
		{ label: "HR", value: "HR" },
		{ label: "Admin", value: "Admin" },
		{ label: "Design & research", value: "Design & research" },
		{ label: "IT & tech", value: "IT & tech" },
		{ label: "Branding & marketing", value: "Branding & marketing" },
		{ label: "Sales", value: "Sales" },
		{ label: "Purchase", value: "Purchase" },
		{ label: "Legal", value: "Legal" },
		{ label: "Other", value: "Other" },
	];
    
	const incomelevelOptions = [
    { label: "$0 - No income", value:"$0 - No income"},
    { label:"$1 - $250", value:"$1 - $250"},
    { label: "$251 - $500", value: "$251 - $500"},
    { label: "$501 - $1,500", value: "$501 - $1,500" },
		{ label: "$1,501 - $2,500", value: "$1,501 - $2,500" },
		{ label: "$2,501 - $5,000", value: "$2,501 - $5,000" },
		{ label: "$5,001 - $7,500", value: "$5,001 - $7,500" },
		{ label: "$7,501 - $10,000", value: "$7,501 - $10,000" },
		{ label: "$10,001 - $12,500", value: "$10,001 - $12,500" },
		{ label: "$12,501 - $15,000", value: "$12,501 - $15,000" },
		{ label: "Over $15,000", value: "Over $15,000" },
	];

    useEffect(() => {
        window.animate();
        document.title = "Step 3 | "+process.env.REACT_APP_NAME;

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response=> {
            setLoading(false);

            if(response.success){

                if(response.data.user.completed_step<2){
                    navigate("/tester/profile/step2")
                }
                
                setFormValues({...formValues,
                    hours_spend_online: response.data.user.hours_spend_online && response.data.user.hours_spend_online != 0? response.data.user.hours_spend_online : '',
                    tech_gadgets: response.data.user.tech_gadgets ? response.data.user.tech_gadgets : '',
                    apps: response.data.user.apps ? response.data.user.apps.split(",") : [],
                    
                })

            }
        });


    }, []);
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    
    const completeProfile = () => {
                
    
    
        setFormLoading(true);

        const token = ReactSession.get("token");
        


        updateStep3(formValues, token)
        .then(response=>{
            setFormLoading(false);
        
            if(response.success){

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event' : 'sign_up',
                    'usertype': 'tester',
                    'method': response.user.signup_type,
                    'userID' : response.user.user_id
                });
                
                let user = ReactSession.get("user");

                user.completed_step = response.user.completed_step;

                user.welcome_message_shown = 0;

                user.first_name =response.user.first_name;

                user.last_name =response.user.first_name;

                ReactSession.set("user",user);

                
               navigate("/dashboard");

            } else {
                showError(response.message);
            }
        })
            

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value, inline) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = resetFormErrors();
        }

        
        var firsterrorid = null;

        if(!key || key=="hours_spend_online"){

            var field_value = key  ? value : formValues.hours_spend_online;
            
            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    hours_spend_online: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "hours_spend_online" : firsterrorid;
            }
        }
        if(!key || key=="tech_gadgets"){

            var field_value = key  ? value : formValues.tech_gadgets;
            
            if (!field_value) {

                form_errors = {
                    ...form_errors,
                    tech_gadgets: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "tech_gadgets" : firsterrorid;
            }
        }
        if(!key || key=="apps"){

            var field_value = key && inline ? value : formValues.apps;
            
            if (field_value.length==0) {

                form_errors = {
                    ...form_errors,
                    apps: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "apps" : firsterrorid;
            }
        }
        
        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        
        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const checkBtnState = () => {

        var error = false;


        if (!formValues.hours_spend_online) {
            error = true;
        }
        if (!formValues.tech_gadgets) {
            error = true;
        }
    
    
        if (formValues.apps.length==0) {
    
            error = true;
        }
        return error;
    }
    const onSubmit = async (event) => {
        
        event.preventDefault();
        
        if (!isLoading) {

            var error = validateFullForm(true,null);

            if (!error) {
                completeProfile();
            }
        }
        
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues(oldValues => ({ ...oldValues, [name]: value }));
                }
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            }
            ///
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false, name, value? value:'',true)
        }
    };
    const clearFormValue = (name) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
    };
    
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    return (
        <LayoutTester
            skipProfileComplete={true}
            profileHeader={true}
        >
            <div className='container'>
                {isLoading &&
                    <div class="accountloading-box"> <LoadingIcon /></div>
                }
                {!isLoading &&
                 <form method="POST" onSubmit={onSubmit}>
                  <div className='register-page-min-hegiht'> 
                <div className='row tester_sign_up_step_row'>
                    
                    <div className='col-md-6 graytext researcher-profilebox-right-outerbox tester-profile-step3box mb-20'>
                        <PageProgressBar
                            current_step={3}
                            total_steps={3}    
                        />
                        <div className="mt-32">
                        <Text type={"h3"} fontWeight={"semi-bold-font"}>Let's talk tech!</Text>
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>Tell us about your technology behaviors</Text>
                        </div>
                
                        <div className="login-form-inner-data researcher-profilebox mt-40">

                            
                            
                           
                                
                                
                                <div className="formbox" id="hours_spend_online">
                                    <div className='profile-form-group'>
                                        <DropdownComponent 
                                            options={hoursOptions}
                                            value={formValues.hours_spend_online}
                                            onChange={(item)=>{
                                                
                                                if(item){
                                                    setFormValues({...formValues,hours_spend_online:item.value})

                                                    
                                                } else {
                                                    setFormValues({...formValues,hours_spend_online:''})
                                                }
                                                validateFullForm(false,"hours_spend_online",item?item.value:'');
                                            
                                            }}
                                            isClearable={true}
                                            label="Daily hours spent online"
                                            error={formErrors.hours_spend_online}
                                            error_class={formErrors.error_class}
                                        />
                                    </div>
                                    
                                </div>
                                <div className="formbox" id="tech_gadgets">
                                    <div className='profile-form-group'>
                                        <DropdownComponent 
                                            options={gadgetsOptions}
                                            value={formValues.tech_gadgets}
                                            onChange={(item)=>{
                                                
                                                if(item){
                                                    setFormValues({...formValues,tech_gadgets:item.value})

                                                    
                                                } else {
                                                    setFormValues({...formValues,tech_gadgets:''})
                                                }
                                                validateFullForm(false,"tech_gadgets",item?item.value:'');
                                            }}
                                            isClearable={true}
                                            label="Most used device"
                                            error={formErrors.tech_gadgets}
                                            error_class={formErrors.error_class}
                                        />
                                    </div>
                                    
                                </div>
                                <div className="formbox tester-apps-select" id='apps'>
                                    <label><Text cssClasses={"color-black"} type={"body-text-2"} fontWeight="semi-bold-font">Which mobile apps do you use in your daily routine?</Text></label>
                                    
                                    <Chips>
                                        {appsOptions.map(function (app) {
                                            return (<Chip
                                                key={"app-" + app}
                                                title={app}
                                                value={app}
                                                type={formValues.apps.includes(app) ? "selected" : "default"}
                                                onClick={() => {
                                                    let localApps = Object.assign([], formValues.apps);

                                                    var exist = false;

                                                    var newApps = [];

                                                    localApps.forEach(function (app_item) {
                                                        if (app_item == app) {
                                                            exist = true;
                                                        } else {
                                                            newApps.push(app_item);
                                                        }
                                                    });

                                                    if (exist) {

                                                        setFormValues({ ...formValues, apps: newApps });

                                                        

                                                    } else {

                                                        localApps.push(app);

                                                        
                                                        setFormValues({ ...formValues, apps: localApps });

                                                        

                                                    }
                                                    validateFullForm(false,"apps",localApps, true);
                                                }}
                                            />);
                                        })}
                                    </Chips>
                                    {formErrors.apps &&
                                    <span className={formErrors.error_class}>{formErrors.apps}
                                    </span>
                                    }
                                </div>
                                
                                
                              
                           
                        </div>

                        <div className='sign-up-btn-wrap tester_signup_btns mt-32'>
                            <Button 
                                            type='secondary'
                                            size='large'
                                            id='r_registration' 
                                            label="Back"
                                            iconLeft={
                                                <Icon 
                                                    colorClass="gray-900-svg"
                                                    value="back-arrow"
                                                    size="large"
                                                />
                                            }
                                            tag={"link"}
                                            state={""}
                                            href="/tester/profile/step2"
                                        />

                                    <Button 
                                                                            type='primary'
                                                                            size='large'
                                                                            id='r_registration' 
                                                                            label="Next"
                                                                            iconRight={
                                                                                <Icon 
                                                                                    colorClass="gray-50-svg"
                                                                                    value="forward-arrow"
                                                                                    size="large"
                                                                                />
                                                                            }
                                                                            microLoading={formLoading}
                                                                            state={checkBtnState()?"disabled":"active"}
                                                                        />
                   </div>

                    </div>  

                    <div className='col-md-6 graytext none-for-mobile'>
                        <SignUpInformationalCard icon={<Icon colorClass={"tertiary-gold-200"} value={"credit"} />} title="Request a payout when you reach $30" info="Take between 15/20 short tests to redeem your cash reward"/>
                    </div>

                </div>
                </div>
                </form>
                }
            </div>
        </LayoutTester>
    );
}