import {handleApiErrorResponse} from "../lib/helpers";

export function getScreeningChartService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/screening/chart?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}
export function getScreeningResultService(formValues, token, signal) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/screening/table/results?test_id="+
        formValues.test_id+
        (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:"")
        +"&q="+formValues.q
        +"&page="+formValues.page
        +"&screening_outcome="+formValues.screening_outcome
        +"&column="+(formValues.column?formValues.column:"")
        +"&direction="+(formValues.direction?formValues.direction:"asc")
        +"&limit="+(formValues.limit?formValues.limit:"10"),
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
            signal:signal
        }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}
