import { Link, useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
export default function Progress({ completed_step, test, stepCallback }) {
    return (
        <>
            <div className="steps-count-wrap">
                <span
                    id="r_create_step_1_intro"
                    className={` ${completed_step >= 1 ? "step-complete" : ""} ${completed_step == 0 ? "step-inprogress" : ""
                        } `}
                >
                    <img
                        className="complete-step-icon"
                        src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                        alt="icon"
                    />

                    <svg className="empty-step-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-1" d="M12 1.1547L21.3923 6.57735V17.4226L12 22.8453L2.6077 17.4226L2.6077 6.57735L12 1.1547Z" stroke="#595972" stroke-width="2" />
                        <path className="path-2" d="M13.3253 7.27273V16H11.7443V8.81108H11.6932L9.65199 10.1151V8.66619L11.821 7.27273H13.3253Z" fill="#595972" />
                    </svg>


                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/r/test/" + test.id + "/welcome"}
                    >
                        Introduction
                    </Link>
                </span>
                <span
                    id="r_create_step_2_build"
                    className={` ${completed_step >= 2 ? "step-complete" : ""} ${completed_step == 1 ? "step-inprogress" : ""
                        } `}
                >
                    <img
                        className="complete-step-icon"
                        src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                        alt="icon"
                    />

                    <svg className="empty-step-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-1" d="M12 1.1547L21.3923 6.57735V17.4226L12 22.8453L2.6077 17.4226L2.6077 6.57735L12 1.1547Z" stroke="#595972" stroke-width="2" />
                        <path className="path-2" d="M8.97159 16V15.4162L11.8224 12.2116C12.1946 11.7912 12.4943 11.4318 12.7216 11.1335C12.9489 10.8352 13.1136 10.5597 13.2159 10.3068C13.321 10.054 13.3736 9.79119 13.3736 9.51847C13.3736 9.18608 13.2955 8.89631 13.1392 8.64915C12.9858 8.39915 12.7741 8.20455 12.5043 8.06534C12.2344 7.92614 11.9304 7.85653 11.5923 7.85653C11.2344 7.85653 10.9205 7.93324 10.6506 8.08665C10.3835 8.24006 10.1761 8.45028 10.0284 8.71733C9.88068 8.98437 9.80682 9.29119 9.80682 9.63778H9.04403C9.04403 9.15483 9.15483 8.72727 9.37642 8.35511C9.60085 7.98011 9.90625 7.68608 10.2926 7.47301C10.6818 7.25994 11.1222 7.15341 11.6136 7.15341C12.0994 7.15341 12.5313 7.25852 12.9091 7.46875C13.2869 7.67614 13.5838 7.95881 13.7997 8.31676C14.0156 8.67472 14.1236 9.07528 14.1236 9.51847C14.1236 9.83949 14.0668 10.1506 13.9531 10.4517C13.8423 10.7528 13.6506 11.0881 13.3778 11.4574C13.1051 11.8267 12.7273 12.2784 12.2443 12.8125L10.0795 15.2287V15.2841H14.3963V16H8.97159Z" fill="#595972" />
                    </svg>
                    {!test ?
                        ((ReactSession.get("methodology")) === 'Survey')
                            ?
                            <Link onClick={(e) => { e.preventDefault() }} to={"/"}>
                                Survey builder
                            </Link>
                            :
                            <Link onClick={(e) => { e.preventDefault() }} to={"/"}>
                                Test builder
                            </Link>
                        :
                        <></>
                    }
                    {test.methodology == "Tree Test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/researcher/tests/" + test.id + "/tree-test"}>
                            Test builder
                        </Link>
                    }
                    {test.methodology == "Preference Test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/researcher/tests/" + test.id + "/preference-test"}>
                            Test builder
                        </Link>
                    }
                    {test.methodology == "Card Sorting" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/researcher/tests/" + test.id + "/card-sorting-test"}>
                            Test builder
                        </Link>
                    }
                    {test.methodology == "Survey" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/researcher/tests/" + test.id + "/survey"}>
                            Survey builder
                        </Link>
                    }

                    {test.methodology == "Five Seconds Test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/researcher/tests/" + test.id + "/five-seconds-test"}>
                            Test builder
                        </Link>
                    }
                </span>
                <span
                    id="r_create_step_3_summary"
                    className={` ${completed_step >= 3 ? "step-complete" : ""} ${completed_step == 2 ? "step-inprogress" : ""
                        } `}
                >
                    <img
                        className="complete-step-icon"
                        src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                        alt="icon"
                    />

                    <svg className="empty-step-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-1" d="M12 1.1547L21.3923 6.57735V17.4226L12 22.8453L2.6077 17.4226L2.6077 6.57735L12 1.1547Z" stroke="#595972" stroke-width="2" />
                        <path className="path-2" d="M11.8054 16.1193C11.2685 16.1193 10.7884 16.0227 10.3651 15.8295C9.94176 15.6335 9.60653 15.3622 9.35938 15.0156C9.11222 14.669 8.98153 14.2699 8.96733 13.8182H9.77273C9.78693 14.1364 9.88494 14.4148 10.0668 14.6534C10.2486 14.8892 10.4901 15.0739 10.7912 15.2074C11.0923 15.3381 11.4276 15.4034 11.7969 15.4034C12.2003 15.4034 12.5554 15.3281 12.8622 15.1776C13.1719 15.027 13.4134 14.821 13.5866 14.5597C13.7628 14.2955 13.8509 13.9972 13.8509 13.6648C13.8509 13.3125 13.7614 13.0014 13.5824 12.7315C13.4034 12.4616 13.1463 12.2514 12.8111 12.1009C12.4787 11.9503 12.081 11.875 11.6179 11.875H11.1065V11.1591H11.6179C11.9957 11.1591 12.3295 11.0909 12.6193 10.9545C12.9119 10.8153 13.142 10.6207 13.3097 10.3707C13.4773 10.1207 13.5611 9.8267 13.5611 9.48864C13.5611 9.16761 13.4886 8.88494 13.3438 8.64062C13.1989 8.39631 12.9943 8.20455 12.7301 8.06534C12.4688 7.92614 12.1634 7.85653 11.8139 7.85653C11.4759 7.85653 11.1662 7.92187 10.8849 8.05256C10.6037 8.18324 10.3764 8.3679 10.2031 8.60653C10.0327 8.84517 9.94176 9.12784 9.9304 9.45455H9.15909C9.17045 9 9.29545 8.60085 9.53409 8.2571C9.77557 7.91051 10.0952 7.64062 10.4929 7.44744C10.8935 7.25142 11.3366 7.15341 11.8224 7.15341C12.3366 7.15341 12.7812 7.25852 13.1562 7.46875C13.5312 7.67898 13.821 7.95881 14.0256 8.30824C14.233 8.65483 14.3366 9.03693 14.3366 9.45455C14.3366 9.9517 14.2017 10.3778 13.9318 10.733C13.6619 11.0852 13.2983 11.3295 12.8409 11.4659V11.5213C13.3977 11.6321 13.8352 11.8793 14.1534 12.2628C14.4744 12.6435 14.6349 13.1108 14.6349 13.6648C14.6349 14.1307 14.5128 14.5497 14.2685 14.9219C14.0241 15.2912 13.6889 15.5838 13.2628 15.7997C12.8395 16.0128 12.3537 16.1193 11.8054 16.1193Z" fill="#595972" />
                    </svg>
                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/researcher/tests/" + test.id + "/conclusions"}
                    >
                        Conclusion
                    </Link>
                </span>
                <span
                    id="r_create_step_4_target group"
                    className={` ${completed_step >= 4 ? "step-complete" : ""} ${completed_step == 3 ? "step-inprogress" : ""
                        } `}
                >
                    <img
                        className="complete-step-icon"
                        src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                        alt="icon"
                    />

                    <svg className="empty-step-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-1" d="M12 1.1547L21.3923 6.57735V17.4226L12 22.8453L2.6077 17.4226L2.6077 6.57735L12 1.1547Z" stroke="#595972" stroke-width="2" />
                        <path className="path-2" d="M8.76278 14.125V13.4773L12.6534 7.27273H13.1307V8.35938H12.777L9.65341 13.3537V13.4091H14.7543V14.125H8.76278ZM12.8366 16V13.9205V13.6222V7.27273H13.5952V16H12.8366Z" fill="#595972" />
                    </svg>

                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/researcher/tests/" + test.id + "/target-group"}
                    >
                        Recruitment
                    </Link>
                </span>
                <span
                    id="r_create_step_5_review"
                    className={` ${completed_step >= 5 ? "step-complete" : ""} ${completed_step == 4 ? "step-inprogress" : ""
                        } `}
                >
                    <img
                        className="complete-step-icon"
                        src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                        alt="icon"
                    />

                    <svg className="empty-step-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-1" d="M12 1.1547L21.3923 6.57735V17.4226L12 22.8453L2.6077 17.4226L2.6077 6.57735L12 1.1547Z" stroke="#595972" stroke-width="2" />
                        <path className="path-2" d="M11.5241 16.1193C11.0384 16.1193 10.6037 16.0213 10.2202 15.8253C9.83949 15.6264 9.53551 15.3537 9.30824 15.0071C9.08381 14.6605 8.96449 14.2642 8.95028 13.8182H9.72159C9.74716 14.2812 9.92898 14.6619 10.267 14.9602C10.6051 15.2557 11.0241 15.4034 11.5241 15.4034C11.9134 15.4034 12.2599 15.3111 12.5639 15.1264C12.8679 14.9389 13.1051 14.6832 13.2756 14.3594C13.4489 14.0327 13.5355 13.6648 13.5355 13.2557C13.5327 12.8295 13.4403 12.4545 13.2585 12.1307C13.0767 11.804 12.8295 11.5483 12.517 11.3636C12.2074 11.179 11.8594 11.0866 11.473 11.0866C11.1804 11.0838 10.8935 11.1321 10.6122 11.2315C10.331 11.331 10.0966 11.4588 9.90909 11.6151L9.11222 11.5128L9.63636 7.27273H13.9489V7.98864H10.3054L9.95597 10.9077H9.99858C10.1918 10.7457 10.4261 10.6136 10.7017 10.5114C10.9801 10.4091 11.2741 10.358 11.5838 10.358C12.098 10.358 12.5597 10.4815 12.9688 10.7287C13.3807 10.9759 13.706 11.3168 13.9446 11.7514C14.1861 12.1832 14.3068 12.6804 14.3068 13.2429C14.3068 13.794 14.1861 14.2869 13.9446 14.7216C13.706 15.1534 13.3764 15.4943 12.956 15.7443C12.5384 15.9943 12.0611 16.1193 11.5241 16.1193Z" fill="#595972" />
                    </svg>

                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/researcher/tests/" + test.id + "/review"}
                    >
                        Test summary
                    </Link>
                </span>
                <span
                    id="r_create_step_6_publish"
                    className={` ${completed_step >= 6 ? "step-complete" : ""} ${completed_step == 5 ? "step-inprogress" : ""
                        } `}
                >
                    <img
                        className="complete-step-icon"
                        src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                        alt="icon"
                    />

                    <svg className="empty-step-icon"
                        width="24"
                        height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-1" d="M12 1.1547L21.3923 6.57735V17.4226L12 22.8453L2.6077 17.4226L2.6077 6.57735L12 1.1547Z" stroke="#595972" stroke-width="2" />
                        <path className="path-2" d="M11.7202 16.1193C11.3594 16.1165 11.0071 16.044 10.6634 15.902C10.3196 15.7571 10.0099 15.5256 9.73438 15.2074C9.45881 14.8864 9.24006 14.4631 9.07812 13.9375C8.91619 13.4119 8.83523 12.7656 8.83523 11.9986C8.83523 11.2884 8.89773 10.6378 9.02273 10.0469C9.14773 9.45597 9.33523 8.9446 9.58523 8.51278C9.83523 8.08097 10.1477 7.74716 10.5227 7.51136C10.8977 7.27273 11.3366 7.15341 11.8395 7.15341C12.3111 7.15341 12.7273 7.25142 13.0881 7.44744C13.4489 7.64062 13.7415 7.91051 13.9659 8.2571C14.1903 8.60369 14.331 9.00284 14.3878 9.45455H13.6037C13.5241 8.99432 13.3352 8.61506 13.0369 8.31676C12.7386 8.01847 12.3395 7.86932 11.8395 7.86932C11.1236 7.86932 10.5696 8.2017 10.1776 8.86648C9.78551 9.52841 9.58807 10.4688 9.58523 11.6875H9.64062C9.77983 11.429 9.95881 11.206 10.1776 11.0185C10.3991 10.8281 10.6506 10.6804 10.9318 10.5753C11.2131 10.4673 11.5085 10.4134 11.8182 10.4134C12.304 10.4134 12.7486 10.5369 13.152 10.7841C13.5554 11.0284 13.8778 11.3651 14.1193 11.794C14.3608 12.2202 14.4815 12.7074 14.4815 13.2557C14.4815 13.7756 14.3665 14.2543 14.1364 14.6918C13.9091 15.1293 13.5881 15.4773 13.1733 15.7358C12.7614 15.9943 12.277 16.1222 11.7202 16.1193ZM11.7202 15.4034C12.1009 15.4034 12.4403 15.3068 12.7386 15.1136C13.0398 14.9205 13.277 14.6619 13.4503 14.3381C13.6236 14.0114 13.7102 13.6506 13.7102 13.2557C13.7102 12.8608 13.6236 12.5028 13.4503 12.1818C13.277 11.8608 13.0398 11.6051 12.7386 11.4148C12.4403 11.2244 12.1009 11.1293 11.7202 11.1293C11.4304 11.1293 11.1605 11.1889 10.9105 11.3082C10.6605 11.4247 10.4432 11.5852 10.2585 11.7898C10.0767 11.9915 9.9375 12.223 9.84091 12.4844C9.74716 12.7457 9.71165 13.0199 9.73438 13.3068C9.76562 13.7074 9.86932 14.0668 10.0455 14.3849C10.2244 14.7003 10.4574 14.9489 10.7443 15.1307C11.0341 15.3125 11.3594 15.4034 11.7202 15.4034Z" fill="#595972" />
                    </svg>


                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/researcher/tests/" + test.id + "/publish"}
                    >
                        Publish
                    </Link>
                </span>
            </div>
        </>
    );
}
