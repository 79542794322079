import { ReactSession } from "../../../../lib/secure_reactsession";
import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";

export default function DoneWithTaskModal(props) {
    ReactSession.setStoreType("localStorage");


    const [formLoading, setFormLoading] = useState(false);

    const closeModal = () => {
        props.closeModal(false);
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={props.openModal}
            centered
            className={`fade custom-modal-wrap done-with-task-modal prototype-done-with-task ${props.cssClass}`}
        >
            <Modal.Body className={props.test.language == "ar" ?"arabic-done-with-task-modal":""} ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={'h3'}>{props.test.language == "ar" ? "هل أنهيت المهمة؟" : "Would you like to end the task?"}</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon colorClass={"gray-900-svg"} value="close" size="medium" hover={true} />
                            </span>
                        </button>
                    </div>
                    <div className="done-with-task-modal-body">
                        <div className="done-with-task-buttons d-flex align-items-center">
                            {props.test.language == "ar" ?
                                <Button
                                    type={"primary"}
                                    size="large"
                                    onClick={() => {
                                        props.submit();
                                    }}

                                    label="نعم، أنهيت المهمة"
                                />
                                :
                                <Button
                                    type={"primary"}
                                    size="large"
                                    onClick={() => {
                                        props.submit();
                                    }}
                                    label="Yes, I’ve completed it"
                                />
                            }

                            {props.test.language == "ar" ?
                                <Button
                                    type={"secondary"}
                                    size="large"
                                    onClick={() => {
                                        props.submit();
                                    }}

                                    label="لا، استمر في الاستكشاف"
                                />
                                :
                                <Button
                                    type={"secondary"}
                                    size="large"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                    label="No, keep exploring"
                                />
                            }

                               
                            
                        </div>
                         
                       <div className="giveup-link-wrap d-flex align-items-center mt-20">
                            <div className="link-text" onClick={() => { props.exit() }} style={{ display: "inline-flex"}}

    >
                                <div className="cursor-pointer">
                                    {props.test.language == "ar" ?
                                        <>
                                            <span>تخلى عن هذه المهمة</span>
                                        </>
                                        :
                                        <>
                                            <span>I give up on this task</span>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
