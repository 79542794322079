import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import LayoutResearcher from '../../layouts/layout_researcher.js';
import Frame from './frame/frame.js';
import Progress from './progress/progress.js';
import Topbar from './topbar/topbar.js';  
import StudyTargetModal from "./targetmodal/study-target-modal.js";
import React, { useState } from 'react';


export default function StudyTargerGroup() { 
ReactSession.setStoreType("localStorage");
const [openModal, setOpenModal] = useState(false);
const [openStudyTargetModal, setOpenStudyTargetModal] = useState(false);
const closeStudyTargetModal = () => {
    StudyTargetModal (false);
  };
return (
<LayoutResearcher isLoading={false} wrapClass="" skipCheck={true} extendedFooter={false}  activeMenu={"credits"}>
    <div className='study-introduction-page-wrapper stydy-pages-wrapper stydy-target-group-wrap'>
        <div className="page-back-option-wrap">
            <Topbar/> 
        </div>
        <div className="study-steps-progress-wrap">
            <Progress/>
        </div> 
        <div className='target-group-data-wrapper study-target-group-wrap'>
            <div className='targer-group-left-wrap'>
                <div className='target-group-data-repeat study-target-repeat'>
                    <div className="target-form-wrap">
                        <h3>Your testers</h3>
                        <div className='sdudy-target-grop-selection-wrap'>
                            <div className="checkboxes radio_btn_class gender_btn  d-flex language-checkbox-wrap">
                                <label htmlFor="select-1" className="mt-0">
                                    <input type="radio" name="len" id="select-1" value="select-1" className="hidden radio_btn_input" />
                                    <span>
                                    <img className="empty-fill-icon" src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"} />
                                    <img className="fill-icon" src={process.env.REACT_APP_URL + "images/pol-fill.svg"} />
                                    </span>
                                    <p>Select or create a target group from the UserQ panel</p>
                                </label>
                                <label htmlFor="select-2" className="mt-0">
                                    <input type="radio" name="len" id="select-2" value="select-2" className="hidden radio_btn_input" />
                                    <span>
                                    <img className="empty-fill-icon" src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"} />
                                    <img className="fill-icon" src={process.env.REACT_APP_URL + "images/pol-fill.svg"} />
                                    </span>
                                    <p>Select a number of random participants from the UserQ panel</p>
                                </label>
                                <label htmlFor="select-3" className="mt-0">
                                    <input type="radio" name="len" id="select-3" value="select-3" className="hidden radio_btn_input" />
                                    <span>
                                    <img className="empty-fill-icon" src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"} />
                                    <img className="fill-icon" src={process.env.REACT_APP_URL + "images/pol-fill.svg"} />
                                    </span>
                                    <p className="d-flex radio-ph-flex">Share the test link with your own panel <a href='javascript:void();' className='no-credit-req'>No credits required</a></p>
                                </label>
                            </div>
                        </div>
                        <div className='study-tester-selected-view'>
                            <div className='study-selectd-tester-left-side'>
                                <h3>Target group name</h3>
                                <div className='selected-tester-data-count'>
                                    <span><b>3 460</b> testers</span> 
                                    <span><b>1 240</b> English speakers</span>
                                </div>
                            </div>
                            <div className='study-selectd-tester-right-side'>
                                <span><a href='#'>View details <img className="fill-icon" src={process.env.REACT_APP_URL + "images/eye.svg"} /></a></span>
                                <span><a href='#'>Unlink target group <img className="fill-icon" src={process.env.REACT_APP_URL + "images/link.svg"} /></a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='target-group-data-repeat study-target-repeat border-0 pb-0'>
                    <div className='select-tg-group-wrp pb-0 border-0'>
                        <div className="target-form-wrap">
                            <h3>Select target group</h3>
                            <div className='study-target-group-option-wrap'>
                                <div className="input form-group  input-field">
                                    <select>
                                        <option>Select...</option>
                                    </select>
                                </div>
                                <span>or</span>
                                <div className="new-targroup-btn">
                                    <button  onClick={() => {
                setOpenStudyTargetModal(true);
              }} type="button" className="button secondary-btn small-button">Create NEW </button>
                                </div> 
                            </div>
                            <div className='tg-pratic-top mt-64'>
                                <h3>How many participants do you need?</h3>
                                {/* <span className='free-pln-text-guide'>Upgrade TO add pre test questions  <img src={process.env.REACT_APP_URL + "images/lock-icon.svg"} alt="img"/></span> */}
                            </div>
                            <div className="target-group-col-data">
                                <input type="range"/>
                                <div className="range-data-info-count">
                                    <span><b>30</b></span>
                                    <span><b>10</b></span>
                                </div>
                            </div>
                            <div className="target-group-col-data">
                                <h3>How many participants would you like from this target group?</h3>
                                <div className='study-target-aud-progress'>
                                    <span className='inner-progress' style={{width: "10%"}}></span>
                                </div>
                                <div className="range-data-info-count">
                                    <span><b>10</b></span>
                                    <span><b>400</b></span>
                                </div>
                            </div>
                            <div className='target-group-pt-selected'>
                                <div className='pt-text-repeat'>
                                    <p>Participants selected</p>
                                    <h2 className='h2'>30</h2>
                                </div>
                                <div className='pt-text-repeat'>
                                    <p>Total cost</p>
                                    <h2 className='h2'>350 Credits</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='target-group-data-repeat study-target-repeat pt-0'>
                    <div className='border-0 pt-0 w-100'>
                        <div className='button-wrap d-flex justify-content-end mt-64'>
                            <button type="submit" className="button primary-btn">Next</button>
                        </div>
                    </div>
                </div>
            </div> 
            <div className='study-target-right-side'>
                <div className='target-group-info-repeat'>
                    <h3>Your testers</h3>
                    <p>Who are you looking to test with? Choose your desired target audience here.</p>
                </div>
                <div className='target-group-info-repeat'>
                    <h3>Choose your target group</h3>
                    <p>Select a premade target group or create a new one with specific requirements.</p>
                </div>
                <div className='target-group-info-repeat'>
                    <h3>Do you need help defining your target group? </h3>
                    <p>Tell us who the ideal candidates for your tests are, and we’ll find the testers for you.</p>
                    <button type="button" className='button secondary-btn small-button ml-0 mt-32'>Send request</button>
                </div>
            </div>
        </div>
    </div>
    <StudyTargetModal openModal={openStudyTargetModal} closeModal={closeStudyTargetModal} />
</LayoutResearcher>
)
}