import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
export default function SessionAboutToExpireModal({
    open,
    onTimeout,
    close,
}) {
    const closeModal = () => {
        close();
    };
    const [timeLeft, setTimeLeft] = useState(120);

    const [worker, setWorker] = useState(null);


    /*useEffect(()=>{

        if(open){
            setTimeLeft(60);
        }
        const intervalId = setInterval(() => {
            console.log("tick"+(new Date()).getTime());
            if(timeLeft==0){
                onTimeout();
            }
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        if(!open){
            clearInterval(intervalId)
        }

        return () => clearInterval(intervalId);

    }, [open]);*/

    useEffect(() => {
        if(window.location.href.indexOf(":6006")===-1){
            const newWorker = new Worker(`${process.env.REACT_APP_URL}js/intervalWorker.js`);

            setWorker(newWorker);
        
            newWorker.onmessage = (e) => {
                
                if(e.data == "timer"){
                    setTimeLeft(prevTime => prevTime - 1);
                }
            };
            
            return () => {
                newWorker.postMessage({  turn:"off" });
                setTimeLeft(120);
                newWorker.terminate();
            };
        }
    },[]);

    useEffect(() => {

        if(worker && timeLeft==0){
            worker.postMessage({ turn:"off" });
            setTimeLeft(120);
            onTimeout();
        }
    },[timeLeft]);
    useEffect(() => {
        
        if(open){
            
            if(worker){
                setTimeLeft(120);

                worker.postMessage({ uq_interval: 1000, message:"timer", turn:"on" });
            }
        } else {
            
       
            if(worker && !open){

                setTimeLeft(120);

                worker.postMessage({ turn:"off" });
               
            }
        
        }
    
        
        
    }, [open, worker]);
    

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap add-overlay-background"
            onHide={() => {
                closeModal();
            }}
        >
            <Modal.Body className="">
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h2">Session is about to expire</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="used-accound-mid-data">
                        <div className={'timer-data d-flex align-items-center'}>
                            <Icon value={'time'} size={'extralarge'} />
                            <Text type={'body-text-1'} cssClasses={'color-black ml-16'} fontWeight={'medium-font'}>
                                 {minutes < 10 ? '0' : ''}{minutes}:{seconds < 10 ? '0' : ''}{seconds}
                            </Text>
                        </div>
                        <Text
                            type={"body-text-2"}
                            cssClasses={
                                "gray-text mb-32 d-flex align-items-center mt-20"
                            }
                            fontWeight={"medium-font"}
                        >
                            Your session is about to expire. To continue editing, please click the button below.
                        </Text>

                        

                        <div className="modal-btn">
                            <Button
                                type="primary"
                                size={`large`}
                                label={"Continue editing"}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
