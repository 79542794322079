import React, { useEffect, useState, useRef } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";

export default function FiveSecondsTestTask({ test, goToFirstQuestion }) {
    const [imageScreen, setImageScreen] = useState(false);
    const [remainingSeconds, setRemainingSeconds] = useState(5);

    useEffect(() => {
        if (imageScreen && test.five_seconds_test_task.task_file) {
            setTimeout(function () {
                if(remainingSeconds>1){
                setRemainingSeconds(remainingSeconds - 1);
                }
                else {
                    goToFirstQuestion();
                }

            }, 1000);

        }
    }, [remainingSeconds, imageScreen]);
    

    return (

        <div className="tester-page-wrapper tester-welcome-page-wrap tester-test-intro">
            <div className="container inner-page-container">
                <div className="five-seconds-task-wrapper">
                    {!imageScreen &&
                        <>
                            <div className="five-sec-inner-text-data">
                                <div className="five-second-task">
                                    <Text type={"subtitle-1"} fontWeight={'medium-font'} cssClasses={`${test.language == "ar" ? "cairo-font" : ""}`}>
                                        {test.five_seconds_test_task.task}
                                    </Text>
                                </div>
                                <div className="five-second-show-design-button">
                                    <div className="button-wrapper">
                                        <Button type={"primary"} size="large" label={test.language == "en" ? "Show the design" : "اعرض التصميم"} onClick={() => {
                                            setImageScreen(true)
                                        }} />
                                    </div>
                                    <div className="subheading">
                                        <Icon value="time" size="medium" hover={false} />
                                        <Text type={"body-text-3"} fontWeight={'medium-font'} cssClasses={`${test.language == "ar" ? "cairo-font" : ""}`}>{test.language == "en" ? "The design will be shown for 5 seconds after you click the button." : "سيظهر التصميم لمدة 5 ثوانٍ بعد النقر فوق الزر."}</Text>
                                    </div>
                                </div>
                            </div> 
                        </>
                    }
                    {imageScreen &&
                        <>
                            <div className="five-sec-timer-design-wrap five-second-full-view-wrap">
                                <div className="five-seconds-task-design small-screen"> 
                                    <div className="five-second-image-hold position-relative " style={{ "backgroundColor": test.five_seconds_test_task.background}}

>
                                    <img src={process.env.REACT_APP_IMG_URL + test.five_seconds_test_task.task_file} />
                                    <div className="five-seconds-timer">
                                    <Icon value="time" size="medium" hover={false} />
                                    <Text type={"h4"} fontWeight={'medium-font'} cssClasses={`${test.language == "ar" ? "arabic-font" : ""}`}>{('0' + remainingSeconds).slice(-2)}</Text>
                                    </div>
                                </div>
                                </div>
                               
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

