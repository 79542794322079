import React from 'react';

export function FullPageLoadingIcon() {

    return (
        <div className="loader_section full-page-loader">
            <img
                src={process.env.REACT_APP_URL + "images/loading-icon.gif"}
                alt="logo"
            />
        </div>
    );


}
