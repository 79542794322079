import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import InputField from "../layouts/elements/fields/input";

export default function RenameModal({
  heading,
  open,
  close,
  title,
  confirm,
  language,
  isLoading,
}) {
  const [formValues, setFormValues] = useState({
    title: true,
    accepted_terms: false,
  });

  const [formErrors, setFormErrors] = useState({
    title: null,
    error_class: "input_error",
  });

  const checkForErrors = () => {
    resetFormErrors();

    if (formValues.title == "") {
      setFormErrors({
        ...formErrors,
        title: "Required!",
        error_class: "input_error",
      });
    } else {
      confirm(formValues.title);
    }
  };
  const resetFormErrors = () => {
    let formErrorsLocal = Object.assign({}, formErrors);
    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }
    setFormErrors(formErrorsLocal);
  };
  useEffect(() => {
    formValues.title = title;

    setFormValues(formValues);
  }, [title]);

  useEffect(() => {
    resetFormErrors();
    formValues.title = title;
  }, [open]);

  return (
    <Modal
      show={open}
      centered
      className="fade custom-modal-wrap rename-modal-wrap"
    >
      <Modal.Body className="p-0">
        <div className="modal-inner-text p-64">
          <button
            type="button"
            className="close"
            onClick={() => {
              resetFormErrors();

              close();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <img
                src={process.env.REACT_APP_URL + "images/cross.svg"}
                alt="cross"
              />
            </span>
          </button>
          <div className="modal-data-wrap publish-study-modal-data">
            <h2>{heading}</h2>
            <div className="create-projectmodal-form-group">
              <InputField
                label="Name"
                maxLength={40}
                onChange={(e) => {
                  setFormValues({ ...formValues, title: e.target.value });
                  setFormErrors({ ...formErrors, title: null });
                }}
                error={formErrors.title}
                error_class={formErrors.error_class}
                rtl={language == "arabic" ? true : false}
                value={formValues.title}
              />
            </div>

            <div className="create-project-form-btn modal-flex-button btn-50-wrap">
              <button
                className="button  secondary-btn"
                onClick={() => {
                  resetFormErrors();
                  close();
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className="button  primary-btn"
                onClick={() => {
                  checkForErrors();
                }}
                type="button"
              >
                Save
                {isLoading && (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    style={{ marginLeft: "5px" }}
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
