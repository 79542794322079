import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Icon} from "../../Icon/Icon";
import {Text} from "../../Text/Text";
import {QuestionsDropdown} from "../../QuestionsDropdown/QuestionsDropdown";
import {Box} from "../../Box/Box";

export const OverviewTestDetail = ({methodology,IntroductionCount,MainQuestionCount,ConclusionCount,items}) => {


    return (
        <div className="overview-results-test-details">

            <Box title="Test details">


            <div className="overview-results-test-details-data-wrap">


                {methodology ?
                    <>
                        <div className="items d-flex">

                            <Text
                                type={"h1"}
                                children={(IntroductionCount)}
                            />


                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(IntroductionCount) === 1)?'Introduction question':'Introduction questions'}
                            />
                        </div>



                        <div className="items d-flex">

                            <Text
                                type={"h1"}
                                children={MainQuestionCount}
                            />


                            {methodology === 'Preference test' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Design' : 'Designs'}
                            />
                            }

                            {methodology === '5 second test' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Follow up question' : 'Follow up questions'}
                            />
                            }

                            {methodology === 'Tree test' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Task' : 'Tasks'}
                            />
                            }

                            {methodology === 'Survey' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Main question' : 'Main questions'}
                            />
                            }

                            {methodology === 'Card Sorting' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Card' : 'Cards'}
                            />
                            }

                            {methodology === 'Prototype test' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Task' : 'Tasks'}
                            />
                            }

                            {methodology === 'First click' &&
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(MainQuestionCount) === 1) ? 'Task' : 'Tasks'}
                            />
                            }
                        </div>


                        <div className="items d-flex">

                            <Text
                                type={"h1"}
                                children={(ConclusionCount)}
                            />


                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                children={(parseInt(ConclusionCount) === 1)?'Conclusion question':'Conclusion questions'}
                            />
                        </div>
                    </>
                    :
                    items && items.map((item)=>{
                        return (
                            <div key={"item"+item.title} className="items d-flex">

                                <Text
                                    type={"h1"}
                                    children={item.count}
                                />


                                <Text
                                    type={"body-text-3"}
                                    fontWeight={"medium-font"}
                                    children={item.title}
                                />
                            </div>
                        );
                    })

                }



            </div>
            </Box>

        </div>
    );
};



OverviewTestDetail.propTypes = {
    //items: PropTypes.array,

    methodology: PropTypes.oneOf(['','Card Sorting','Preference test','Tree test','Survey','5 second test','Prototype test','First click']),
    // IntroductionCount:PropTypes.string,
    // MainCount:PropTypes.string,
    // thirdValue:PropTypes.string
}
OverviewTestDetail.defaultProps = {
    //items: [],
    methodology:'Tree test',
    IntroductionCount:5,
    MainQuestionCount:10,
    ConclusionCount:15
}
