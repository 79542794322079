import React, { useEffect, useImperativeHandle, useState, useRef } from "react";
import { showError } from "../../../../lib/helpers";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { saveFirstclickTestTaskService, deleteFirstclickDesignService, getPrototypeTestTaskQuestionsService } from "../../../../services/test";
import { Button } from "../../../themes/userq/Button/Button";
import FigmaAuth, { openFigmaAuthWindow } from "../../../themes/userq/FigmaAuth/figma_auth";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Input } from "../../../themes/userq/Input/Input";
import { Text } from "../../../themes/userq/Text/Text";
import FollowupQuestions from "./followup_questions";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import ConfirmationModal from "../../../themes/userq/Modal/ConfirmationModal";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import { ImageUpload } from "./image_upload_design";
import { GuideLink } from "../../../themes/userq/GuideLink/GuideLink";


const Task = React.forwardRef(({language, updateTaskAndDontAsk, opentestSettingModal, dont_ask_again, openHelpModal, changeOrder, total_tasks,taskErrors, index, task, test, test_id, objective, updateTask, setIsSaved, setConfirmModal, setStepperError},ref)=>{
    
    const [formValues, setFormValues] = useState({description: task.description  ? task.description :'',image: task.image  ? task.image :'' });

    const [formErrors, setFormErrors] = useState(taskErrors);

    const [syncLoading, setSyncLoading] = useState(false);

    const [unsyncLoading, setUnsyncLoading] = useState(false);

    const [resyncLoading, setResyncLoading] = useState(false);

    const [previewPrototypeModal, setPreviewPrototypeModal] =useState({open:false});
     //task.questions
    const [followupQuestions, setFollowupQuestions] = useState([]);

    const [followupLoading, setFollowupLoading] = useState(true);

    const [unsyncConfirmModal, setUnsyncConfirmModal] =useState({open:false});

    const [resyncConfirmModal, setResyncConfirmModal] =useState({open:false});

    const [deleteDesignConfirmModal, setDeleteDesignConfirmModal] =useState({open:false});

    const [tempAddQuestion, setTempAddQuestion] = useState({
        question: "",
        is_optional: false,
        is_logic: false,
        jump_to: "End Survey",
        options:[]
    });

    const followupRef = useRef(null);

    useImperativeHandle(ref, () => { return{
        followupRef: followupRef.current
    }},[tempAddQuestion]);

    useEffect(()=>{
        //getFollowupQuestions();

        
        setFormValues({...formValues,  description: task.description  ? task.description :''})

        
        setFollowupQuestions(task.questions);
        
    },[task]);

    useEffect(()=>{
        var form_errors = {...formErrors};
        
        if(taskErrors.description){

            form_errors = {...form_errors, description:taskErrors.description};

        } else {
            form_errors = {...form_errors, description:null};
        }
        
        setFormErrors(form_errors);

    },[taskErrors]);
    

    const getFollowupQuestions = ()=>{

        setFollowupLoading(true); 
        
        var data={task_id: task.id, test_id:test_id};

        
        getPrototypeTestTaskQuestionsService(data, ReactSession.get("token")).then(
			(response) => {
				setFollowupLoading(false);

				if (response.success) {

                    //setFollowupQuestions(response.followupQuestions)

                    setFollowupQuestions([]);

				} else {
                    
				    showError(response.message);
				}
			}
		);

    }
    const syncBtnState = ()=>{
        var reg = /^(?:https:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/?([^\?]+)?(.*))?$/

        var error = false;

        if(!reg.test(formValues.prototype_link)){
            
            error = true;
        } else {

            let paramString = formValues.prototype_link.split('?')[1];

            const queryParams = new URLSearchParams(paramString);
           

            
           /*if(!queryParams.get("node-id")){

            
                error = true;
            } 
            else*/ if(!queryParams.get("starting-point-node-id")){
                error = true;
            
            } 

            
        }
        return error;
    }
    
    const validateDescription = (description=null)=>{

        setFormErrors({...formErrors, description:null});

        var description_test = description ? description : formValues.description;

        
        var error = false;

        if(!description_test){
            setFormErrors({...formErrors, description:"A task description is required"});
            
            error = true;
        }
        return error;
    }
    const deleteDesign = (design_id) => {
		let token = ReactSession.get("token");

		setIsSaved({status:"loading",message:""})

		deleteFirstclickDesignService({ id: design_id, test_id: test_id }, token).then(
			(response) => {
				
                if (response.success) {
					setIsSaved({status:"",message:""})

                    var taskLoc = {...task};

                    taskLoc.design_file = '';

                    taskLoc.thumb = '';

                    taskLoc.color = '';

                    if(formErrors)
                    updateTask(taskLoc, index);

				} else {
                    setIsSaved({status:"error",message:response.message});
                    
					showError(response.message)
				}
			}
		);
	};
    const saveTask = (form_data=null)=>{

        var data={description:form_data ? form_data.description : formValues.description, task_id: task.id, test_id:test_id};

        if(data.description){
            setStepperError(false);
        }

        setIsSaved({status:"loading",message:""})

        saveFirstclickTestTaskService(data, ReactSession.get("token")).then(
			(response) => {
				setSyncLoading(false);				

				if (response.success) {

                    setIsSaved({status:"",message:""})
				} else {
                    setIsSaved({status:"error",message:response.message})
				    showError(response.message);
				}
			}
		);

    }
    
    return (
        <>
        <div className="card-sorting-test-wrap prefer-design-wrap first-click-design-loop">
            <div className="cardsorting-wrap-top  prototype-test-title d-flex justify-content-between align-items-center">
                <div className="device-test-icon-wrap">


                    <div className="tooltip-deviceinfo none-for-mobile">
                        {test.device=="all" &&
                        <span className="selected-divice-wrap d-flex align-items-center">
                            <Icon value={"desktop-and-phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                            <span className="none-for-desktop device-name">All devices</span>
                        </span>
                        }
                        {test.device=="mobile" && 
                        <span className="selected-divice-wrap d-flex align-items-center">
                            <Icon value={"phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                            <span className="none-for-desktop device-name">Mobile only</span>
                        </span>
                        }
                        {test.device=="desktop" &&
                            <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"desktop"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">Desktop only</span>
                            </span>
                        }
                        {test.device === 'all' ?
                            <Tooltip
                                type={"bottom"}
                                data={<>
                                    This test will be accessible on {test.device} devices. If you would like to change
                                    your device preferences <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    opentestSettingModal()
                                }}>click here</a>
                                </>}
                                className="tooltipChart"
                            />
                            :
                            <Tooltip
                                type={"bottom"}
                                data={<>
                                    This test will be accessible only on {test.device} screens. If you would like to change
                                    your device preferences <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    opentestSettingModal()
                                }}>click here</a>
                                </>}
                                className="tooltipChart"
                            />
                        }
                    </div> 
                   
                    <Text type={"subtitle-2"} fontWeight={"medium-font"}>Task {index+1}</Text>

                    <div className="tooltip-deviceinfo none-for-desktop">
                        {test.device=="all" &&
                        <span className="selected-divice-wrap d-flex align-items-center">
                            <Icon value={"desktop-and-phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                            <span className="none-for-desktop device-name">All devices</span>
                        </span>
                        }
                        {test.device=="mobile" && 
                        <span className="selected-divice-wrap d-flex align-items-center">
                            <Icon value={"phone"} colorClass={'secondry-purple-200-svg'} size="medium" />
                            <span className="none-for-desktop device-name">Mobile only</span>
                        </span>
                        }
                        {test.device=="desktop" &&
                            <span className="selected-divice-wrap d-flex align-items-center">
                                <Icon value={"desktop"} colorClass={'secondry-purple-200-svg'} size="medium" />
                                <span className="none-for-desktop device-name">Desktop only</span>
                            </span>
                        }
                        {test.device === 'all' ?
                            <Tooltip
                                type={"bottom"}
                                data={<>
                                    This test will be accessible on {test.device} devices. If you would like to change
                                    your device preferences <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    opentestSettingModal()
                                }}>click here</a>
                                </>}
                                className="tooltipChart"
                            />
                            :
                            <Tooltip
                                type={"bottom"}
                                data={<>
                                    This test will be accessible only on {test.device} screens. If you would like to change
                                    your device preferences <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    opentestSettingModal()
                                }}>click here</a>
                                </>}
                                className="tooltipChart"
                            />
                        }
                    </div> 
                </div>

                {total_tasks>1 &&
                <div className="change-order-button-wrap">
                <Button
                    type={"secondary"}
                    size="small"
                    onClick={() => {
                        if(setConfirmModal){
                            setConfirmModal({ open: true, task_id: task.id });
                        }
                    }}

                    label="Delete"
                    iconLeft={<Icon value={"delete"} size="medium" />}
                />
                <Button
                    type={"secondary"}
                    size="small"
                    onClick={() => {
                        changeOrder();
                    }}
                    cssclass={"change-order-btn"}
                    label="Change order"
                    iconLeft={<Icon value={"sort-icon"} size="medium" />}
                />
                </div>
                }
                

                
            </div>
            <div className="separator">
                <div className="cardsorting-wrap-mid-data prototype-task-description">
                    
                    <Text type={"subtitle-2"} fontWeight={"medium-font"}>Task description</Text>
                    <Text type={"body-text-2"} cssClasses="mb-32" fontWeight={"medium-font"}>Give your users a task that explains the action to be performed over the design shown. E.g. Where would you click to change your password?</Text>
                    <div className="formbox " id={"task_description_"+task.id}>
                        <Input type="text" 
                            rtl={language=="ar"?true:false}
                            label="Task description"
                            maxLength={300}
                            value={formValues.description} 
                            onChange={(e)=>{ setStepperError(false); setFormErrors({...formErrors, description:null}); updateTask({...task, description:e.target.value}, index); setFormValues({...formValues, description:e.target.value}) }} 
                            onClear={(e)=>{updateTask({...task, description:''}, index); setFormValues({...formValues, description:''}); saveTask({...formValues, description:''}); }} 
                            onBlur={(e)=>{  if(e.target.value){ setStepperError(false);}   updateTask({...task, description:e.target.value}, index); saveTask(); }}
                            error={formErrors.description}
                            required={true}
                        />
                    </div>
                </div>
                <div class="create-test-preview-area">
                    <Text
                        type={'overline'}
                        children={'Preview'}
                    />
                    <div className="create-test-preview-area-inner">
                        <img src={
                            process.env.REACT_APP_URL + "img/first-click-preview.svg"}
                            alt="img" />
                    </div>
                    <GuideLink methodology={"first click test"}/>
                </div>
            </div>
            <div className="separator"  id={"design_file_error_"+task.id}>
                <div className="cardsorting-wrap-mid-data prototype-task-define-flow ">
                    <Text type={"subtitle-2"} fontWeight={"medium-font"}>Design</Text>
                    <Text type={"body-text-2"} cssClasses="mb-32" fontWeight={"medium-font"}>Upload the image of your design. This can be a either a sketch, screenshot of a webpage or a wireframe.</Text>
                    <div className="five-second-test-img">
                        <ImageUpload 
                            design={task} 
                            taskErrors={taskErrors}
                            test={test} 
                            updateHeaderLoader={setIsSaved}
                            onDesignUpdated={(design)=>{
                                var taskLoc = {...task};

                                taskLoc.title = design.title;

                                taskLoc.design_file = design.design_file;

                                taskLoc.thumb = design.thumb;

                                taskLoc.color = design.color;

                                
                                setStepperError(false);

                                updateTask(taskLoc, index);

                            }}
                            setConfirmModal={setDeleteDesignConfirmModal}
                        />
                    </div>
                </div>
            </div>
            <div className="separator">
                <div className="prototype-task-followup-questions " id={"questions_"+task.id}>
                    <FollowupQuestions ref={followupRef} tempAddQuestion={tempAddQuestion} onUpdate={(question)=>{
                        setTempAddQuestion(question);
                    }}  objective={"closed"} maxQuestions={1} followupQuestions={task.questions?task.questions:[]} isLoading={false} 
                        updateQuestions={(questions)=>{

                            var taskLoc = {...task};

                            taskLoc.questions = questions;

                            updateTask(taskLoc, index);
                        }} 
                        test={test} 
                        task={task} 
                        setIsSaved={setIsSaved} 
                    />
                </div>
            </div>
        </div>
        <ConfirmationModal
            confirm_message="Are you sure you want to delete this design?"
            confirm_btn_title="Delete"
            confirm_title="Delete Design"
            confirm_title_class={"text-danger"}
            openModal={deleteDesignConfirmModal.open}
            close={() => {
                setDeleteDesignConfirmModal({ ...deleteDesignConfirmModal, open: false });
            }}
            confirm={() => {
                setDeleteDesignConfirmModal({ ...deleteDesignConfirmModal, open: false });

                deleteDesign(deleteDesignConfirmModal.design_id);
            }}
        />
        </>
    );
});
export default Task;