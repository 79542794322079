import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Input } from "../../../themes/userq/Input/Input";
import "./delete-workspace-modal.css";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";

export default function DeleteWorkspaceModal({
    open,
    close,
    workspaceCount,
    type,
    confirm,
    fullName,
}) {
    const wrapperRef = useRef(null);
    const [formValues, setFormValues] = useState({ signature: "" });
    const [errorMessage, setErrorMessage] = useState("");

    const closeModal = (send = false) => {
        close(send, send ? { ...formValues } : null);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (open) {
            setFormValues({ signature: "" });
            setErrorMessage("");
        }
    }, [open]);

    const handleSignatureChange = (e) => {
        const { value } = e.target;
        setFormValues({ signature: value });
        if (!value) {
            setErrorMessage("");
        }
    };

    const validateSignature = () => {
        if (
            !formValues.signature ||
            formValues.signature.toLowerCase() !== fullName.toLowerCase()
        ) {
            setErrorMessage(
                "Please enter your full name to delete the workspace"
            );
            return false;
        }
        setErrorMessage("");
        return true;
    };

    const handleDelete = () => {
        if (validateSignature()) {
            confirm(formValues.signature.toLowerCase());
        }
    };

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap delete-workspace-modal"
        >
            <Modal.Body ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h3" cssClasses="red-text">
                            Delete workspace
                        </Text>
                        <button
                            type="button"
                            className="close"
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover />
                            </span>
                        </button>
                    </div>

                    {type === "team" && workspaceCount > 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                fontWeight="semibold-font"
                                cssClasses="black-color mb-32"
                            >
                                You are the admin of this workspace.
                            </Text>
                            <Text
                                type="body-text-2"
                                cssClasses="gray-color"
                                fontWeight="medium-font"
                            >
                                By deleting this workspace:
                                <ul style={{ margin: 0, paddingLeft: "25px" }}>
                                    <li>
                                        All tests and unused credits will be
                                        removed.
                                    </li>
                                    <li>
                                        Team members will lose access to the
                                        workspace.
                                    </li>
                                    <li>
                                        You will not be billed again for the
                                        current workspace.
                                    </li>
                                </ul>
                            </Text>
                            <Text
                                type="body-text-2"
                                cssClasses="gray-color mt-32"
                                fontWeight="medium-font"
                            >
                                If you want to leave the workspace, but not
                                delete it, you can{" "}
                                <Link
                                    onClick={closeModal}
                                    className="link-text"
                                >
                                    re-assign
                                </Link>{" "}
                                ownership instead.
                            </Text>
                            <div className="red-bg-wrap mt-20 mb-20">
                                <Text
                                    type="body-text-2"
                                    cssClasses="gray-color"
                                    fontWeight="medium-font"
                                >
                                    By continuing, you acknowledge that all
                                    tests in the workspace will be deleted and
                                    that all unused credits will be lost. This
                                    action cannot be undone.
                                </Text>
                            </div>
                        </div>
                    )}

                    {type === "individual" && workspaceCount > 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                cssClasses="gray-color"
                                fontWeight="medium-font"
                            >
                                By deleting this workspace, all tests along with
                                any unused credits will be deleted.
                            </Text>
                            <div className="red-bg-wrap mt-20 mb-20">
                                <Text
                                    type="body-text-2"
                                    cssClasses="gray-color"
                                    fontWeight="medium-font"
                                >
                                    By continuing, you acknowledge that all
                                    tests in the workspace will be deleted and
                                    that all unused credits will be lost. This
                                    action cannot be undone.
                                </Text>
                            </div>
                        </div>
                    )}

                    {workspaceCount > 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                cssClasses="gray-color"
                                fontWeight="medium-font"
                            >
                                Kindly confirm by signing your full name:
                            </Text>
                            <div className="add-name-input mt-16">
                                <Input
                                    cssClasses="w-100"
                                    type="text"
                                    label="Your full name"
                                    value={formValues.signature}
                                    onChange={handleSignatureChange}
                                    onClear={() =>
                                        setFormValues({ signature: "" })
                                    }
                                />
                            </div>

                            {errorMessage && (
                                <div className="mt-12">
                                    <ToastMessage
                                        type="error"
                                        message={errorMessage}
                                        closable={false}
                                    />
                                </div>
                            )}

                            <div className="confirm-buttons-wrap">
                                <Button
                                    type="secondary"
                                    size="large"
                                    iconLeft={
                                        <Icon
                                            value="delete"
                                            colorClass="gray-900-svg"
                                        />
                                    }
                                    label="Delete workspace"
                                    onClick={handleDelete}
                                    state={
                                        fullName && formValues.signature
                                            ? formValues.signature.toLowerCase() ===
                                              fullName.toLowerCase()
                                                ? ""
                                                : "disabled"
                                            : "disabled"
                                    }
                                />
                                <Button
                                    type="primary"
                                    size="large"
                                    label="Close"
                                    onClick={closeModal}
                                />
                            </div>
                        </div>
                    )}

                    {workspaceCount === 1 && (
                        <div>
                            <Text
                                type="body-text-2"
                                cssClasses="gray-color"
                                fontWeight="medium-font"
                            >
                                You cannot delete this workspace as it is
                                currently the only one active for your account.
                            </Text>
                            <Text
                                type="body-text-2"
                                cssClasses="gray-color mt-32"
                                fontWeight="medium-font"
                            >
                                If you wish to no longer be a part of UserQ,
                                please delete your account instead or contact{" "}
                                <Link
                                    to="/r/support/contact"
                                    target="_blank"
                                    className="link-text"
                                >
                                    UserQ support
                                </Link>{" "}
                                for more information.
                            </Text>
                            <div className="confirm-buttons-wrap">
                                <Button
                                    type="primary"
                                    size="large"
                                    label="Close"
                                    onClick={closeModal}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}
