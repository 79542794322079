import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import { CheckBox } from "../CheckBox/CheckBox";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";

export default function NewFeatureModal({
    openModal,
    title,
    body,
    btn,
    close,
    cssClass
}) {
    const closeModal = () => {
        close();
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);


    return (
        <Modal
            show={openModal}
            centered
            className={`fade custom-modal-wrap  confirmation-modal-wrap new-feature-modal  ${cssClass}`}
        >
            <Modal.Body className="" ref={wrapperRef}>
            
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                        <span aria-hidden="true">
                            <Icon value="Close" size="large" hover={true} />
                        </span>
                        </button>
                    </div>
                    <div className="new-feature-icon-row">

                        <img src={process.env.REACT_APP_URL+"img/new_feature.gif"} width="150" height="150" />
                    </div>
                    <div className="new-feature-title-row">

                        <Text type="h2" fontWeight={"medium-font"}>{title}</Text>
                    </div>
                    <div className="new-feature-body-row">

                        {body}
                    </div>
                    <div className="new-feature-btn-row">

                        {btn}
                    </div>
                </div>
                 
            </Modal.Body>
        </Modal>
    );
}
