import { Dropdown } from "react-bootstrap";
import { Icon } from "../Icon/Icon";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { getFileType, matchExtensions, maxLengthCheck } from "../../../../lib/helpers";
import { Preview } from "../Preview/preview";
import { addUpdateQuestionImageService, deleteQuestionImageService } from "../../../../services/question_images";
import { ReactSession } from "../../../../lib/secure_reactsession";
import ConfirmationModal from "../Modal/ConfirmationModal";
import "./QuestionImage.css";

export  const QuestionImage = ({questionSection, questionSubSection, url, id, onImageUpload, editable=true})=>{

    const [uploadPerc, setUploadPerc] = useState(0);

    const [image_url, setImageURL] = useState(url?process.env.REACT_APP_IMG_URL+"/"+url:null);

    const [image_id, setImageId] = useState(null);

    const [confirmModal, setConfirmModal] = useState({open:false});

    useEffect(()=>{

        setImageURL(url?process.env.REACT_APP_IMG_URL+"/"+url:null);

    },[url]);

    useEffect(()=>{

        setImageId(id);

    },[id]);

    
    const showError = (error) => {
		toast(
			<ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: settings.toast_duration,
			}
		);
	};
    const onDelete = ()=>{
        setConfirmModal({open:true});
    }
    const onUpdate = (file, image_id)=>{
        var error = false;

		
		if (!file) {

			error = true;

            showError("Please select file.");

			
		}

	    if (!error) {
            
			let token = ReactSession.get("token");

			const formData = new FormData;

			formData.append("id",image_id?image_id:"");

			formData.append("image_path", file);
            
            setUploadPerc(0);

			addUpdateQuestionImageService(
				formData,
				(percentage)=>{
					setUploadPerc(percentage);
				},
                token
			).then((response) => {
			
				if (response && response.success) {

					setImageURL(process.env.REACT_APP_IMG_URL+"/"+response.design_file);

                    setImageId(response.id);

                    if(onImageUpload){
                        onImageUpload(response.id, response.design_file);

                        
                    }
					

				} else {

					showError(response.message);
				}
			});
		}
    }
    const deleteImage = () => {
		let token = ReactSession.get("token");

        
		deleteQuestionImageService({ id: image_id, questionSection: questionSection?questionSection:null,questionSubSection: questionSubSection?questionSubSection:null, }, token).then(
			(response) => {
				if (response.success) {
                    setImageURL(null);

                    setImageId(null);

                    if(onImageUpload){
                        onImageUpload(null, null);

                        
                    }
				} else {
					showError(response.message);
				}
			}
		);
	};
    return (<>
        <Image editable={editable} url={image_url} onDelete={onDelete}  onUpdate={onUpdate} />
        <ConfirmationModal
            confirm_message="Are you sure you want to delete this image?"
            confirm_btn_title="Delete"
            confirm_title="Delete Image"
            confirm_title_class={"text-danger"}
            openModal={confirmModal.open}
            close={() => {
                setConfirmModal({ ...confirmModal, open: false });
            }}
            confirm={() => {
                setConfirmModal({ ...confirmModal, open: false });

                deleteImage();
            }}
        />
    </>);

}
const Image = ({url, image_id,onDelete, onUpdate, editable})=>{

    const fileRef = useRef();

    const [previewUrl, setPreviewUrl] = useState(null);

    const [previewModal, setPreviewModal] = useState({open:false});

    const [image_url, setImageURL] = useState(url?url:null);

    useEffect(()=>{

        setImageURL(url?url:null);

    },[url]);

    useEffect(()=>{
        setPreviewUrl(url);
    },[url]);

    const showError = (error) => {
		toast(
			<ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: settings.toast_duration,
			}
		);
	};

    const handleOnDragOver = (event) => {
		event.preventDefault();
	};
	const handleOnDrop = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();
		//let's grab the image file
		let imageFile = event.dataTransfer.files[0];

		handleFile(imageFile);
	};
	const removeImage = (e) => {
		//setImageURL(null);
		//setPreviewUrl(null);
		fileRef.current.value = null;
	};
	const handleFileInput = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();

		handleFile(event.target.files[0]);
	};
    const handleFile = (file) => {

		//let's grab the image file
		var error = false;

		var fileType = getFileType(file);

		if (fileType == "image" && file.size / 1024 > 2048) {

			showError("Please select file <= 2MB.");

			error = true;
		}
		if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {

            showError("Only image files are accepted with extensions mp4, jpg, jpeg, gif and png.");

			error = true;
		}
		if (fileType != "image" && fileType != "video") {

            showError("Only video and image files are accepted.");

			error = true;
		}

		if (!error) {

			
            onUpdate(file,image_id);
			
            setPreviewUrl(URL.createObjectURL(file));
		}

	};
    
    
    return <div className={`question-image-wrap ${editable?"cursor-pointer":""} `}>
            {editable &&
            <input
                type="file"
                ref={fileRef}
                className="default-file-input"
                onChange={handleFileInput}
                onClick={(event) => {
                    event.target.value = null
                }}
                accept="image/*"
                style={{display: "none"}}
            />
            }
        {image_url 
        ?
        <>
          <img src={previewUrl?previewUrl:url} className="question-input-img-thum"/>
          {editable &&
          <div className="question-setting-action">
            <div className="hamburger-menu">
             <Dropdown>
                <Dropdown.Toggle
                    id="dropdownMenuButton"
                    className="dropdown-toggle"
                >
                    <Icon value={"settings"} size={"medium"} colorClass={'gray-50-svg'}/>
                   
                </Dropdown.Toggle>

                <Dropdown.Menu className="hamburger-items">
                    <div className="drop-down-link-hold">
                        <Dropdown.Item
                            key={"image-preview"}
                            onClick={()=>{
                                setPreviewModal({...previewModal, open: true});
                            }}
                        >
                            <Icon value={"eye"} size={"medium"} /> Preview

                        </Dropdown.Item>
                        {editable &&
                        <>
                        <Dropdown.Item
                            key={"image-change"}
                            onClick={()=>{
                                if(fileRef && fileRef.current){
                                    fileRef.current.click();
                                }
                            }}
                        >
                            <Icon value={"edit"} size={"medium"} /> Change image

                        </Dropdown.Item>
                        <Dropdown.Item
                            key={"image-delete"}
                            onClick={()=>{
                                //removeImage();
                                if(onDelete){
                                    
                                    onDelete();
                                }
                            }}
                            className="red-text"
                            
                        >
                            <Icon value={"delete"} size={"medium"} colorClass={'danger-200-svg'} /> Delete image

                        </Dropdown.Item>
                        </>
                        }
                    </div>
                </Dropdown.Menu>

            </Dropdown>
            </div>
            
          </div>

            }
        </>
        :
        <div
            class="question-input-img-preview"
            onDragOver={handleOnDragOver}
            onDrop={handleOnDrop}
            onClick={()=>{
                if(fileRef && fileRef.current){
                    fileRef.current.click();
                }
            }}
        >
            {previewUrl 
            ?
            <img src={previewUrl} className="question-input-img-thum"/>
            :
            <Icon value={"image"} size="large" colorClass={'gray-600-svg'} />
            }
            
           
        </div>
        }
        <Preview 
            open={previewModal.open} 
            img={url}
            close={()=>{
                setPreviewModal({...previewModal, open: false});
            }}
        />
    </div>

}