import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Icon} from "../../Icon/Icon";
import {Text} from "../../Text/Text";
import {Box} from "../../Box/Box";
import RechartArea from "./components/rechart_area";

export const OverviewEngagement = ({liveFrom,avgTime,graph,methodology}) => {


    return (
        <div className="engagement-overview-results">

            <Box title="Engagement per time" size={"medium"}>

                <div className="engagement-overview-results-data-wrap d-flex">
                    <div className="engagement-overview-results-left">

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"live_from"}
                        >
                            Live from
                        </Text>

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"live_from_data"}
                        >
                            {liveFrom}
                        </Text>

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"avg_time"}
                        >
                            Avg. engagement time
                        </Text>

                        <Text
                            type={"body-text-3"}
                            fontWeight={"medium-font"}
                            cssClasses={"avg_time_data"}
                        >
                            {avgTime}
                        </Text>

                    </div>
                    <div className="participants-overview-right">

                        <RechartArea areaClosedChartData={graph}
                                     testmethodology={methodology}/>

                    </div>
                </div>




            </Box>

        </div>
    );
};



OverviewEngagement.defaultProps = {
    liveFrom:PropTypes.string,
    avgTime:PropTypes.string,
    methodology:PropTypes.string,
    graph:PropTypes.array,
};
