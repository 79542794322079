import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { saveCardSortingResultService } from "../../../../services/test_result";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { FormattedMessage } from "react-intl";
import CardSortingIntroduction from "./card_sorting_introduction";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RenameModal from "./rename_model";
import InputField from "../../../layouts/elements/fields/input";
import ConfirmationModal from "../../../themes/userq/Modal/ConfirmationModal";
import LayoutResearcherFullWidth from "../../../layouts/layout_researcher_full_width";
import Masonry from "react-masonry-css";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
import { showToast, shuffle } from "../../../../lib/helpers";
import TaskNotCompleted from "../../elements/TaskNotCompleted";
import "./card_sorting.css";

export default function CardSorting({ test, result, callback, timeSpent }) {
    const [initialScreen, setInitialScreen] = useState(true);
    const location = useLocation();
    const inputref = useRef(null);

    const [infoText, setInfoText] = useState(false);
    const [minimizeData, setMinimizeData] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [isEnableNextButton, setEnableNextButton] = useState(true);
    const [cardSorting, setCardSorting] = useState(null);

    const [cardData, setCardData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);

    // check all cards move in single category
    const [cardDataResearcher, setCardDataResearcher] = useState([]);
    const [isAllCardsInSingleCategory, setAllCardsInSingleCategory] =
        useState(false);

    const [onDraggingCreateNewCategory, setOnDraggingCreateNewCategory] =
        useState(false);
    const [
        onDraggingCreateNewCategoryCardData,
        setOnDraggingCreateNewCategoryCardData,
    ] = useState(null);

    const [columns, setColumns] = useState([]);
    const [editCategoryArray, setEditCategoryArray] = useState([]);
    const [tmpCategoryHtml, setTmpCategoryHtml] = useState(false);
    const [onTouchMobileCreateTmpHtml, setOnTouchMobileCreateTmpHtml] =
        useState(false);
    const [databaseCategoryArray, setDatabaseCategoryArray] = useState([]);

    const [isTyping, setIsTyping] = useState(false);
    const [isDragingStartItem, setIsDragingStartItem] = useState(false);
    const [formErrors, setFormErrors] = useState({
        title: null,
        error_class: "",
    });

    const [deleteCategoryModal, setDeleteCategoryModal] = useState({
        open: false,
    });

    const [cardSortingTimeSpent, setCardSortingTimeSpent] = useState(0);

    const [isAddNewCategoryLoading, setIsAddNewCategoryLoading] =
        useState(false);
    const [isDeleteCategoryLoading, setIsDeleteCategoryLoading] =
        useState(false);
    const [moveItemsCategoryToTmpCategory, setMoveItemsCategoryToTmpCategory] =
        useState({ id: "", editCategoryId: "" });

    useEffect(() => {
        setCardSorting(test.card_sorting);

        if (test.card_sorting && test.card_sorting.is_randomize) {
            var shuffleCard = shuffle(test.card_sorting_card);
            setCardData(shuffleCard);
        } else {
            setCardData(test.card_sorting_card);
        }

        setDatabaseCategoryArray(test.card_sorting_category);

        /// check all cards move in single category
        setCardDataResearcher(test.card_sorting_card);

        //setEditCategoryArray([]);

        const columnsFromBackend = {
            ["1"]: {
                name: "",
                type: "",
                items: test.card_sorting_card,
            },
        };

        const blankUniqueIdArray = [];
        if (test.card_sorting_category.length > 0) {
            for (
                var totalCategory = 1;
                totalCategory <= test.card_sorting_category.length;
                totalCategory++
            ) {
                var newUniqueId =
                    Date.now() +
                    parseInt(test.card_sorting_category[totalCategory - 1].id);
                blankUniqueIdArray.push(newUniqueId);
                Object.assign(columnsFromBackend, {
                    [totalCategory + 1]: {
                        id: test.card_sorting_category[totalCategory - 1].id,
                        name:
                            test.card_sorting_category[totalCategory - 1]
                                .name || "-",
                        type: "category",
                        uniqueId: newUniqueId,
                        items: [],
                    },
                });
            }
        }

        setColumns(columnsFromBackend);

        // location scroll top
        window.scrollTo(0, 0);

        // set data
        const editCategoryDataTmpArray = [];
        if (test.card_sorting_category.length > 0) {
            for (var i = 0; i < test.card_sorting_category.length; i++) {
                editCategoryDataTmpArray.push({
                    id: test.card_sorting_category[i].id,
                    name: test.card_sorting_category[i].name || "-",
                    uniqueId: blankUniqueIdArray[i],
                    isEditing: false,
                    error_msg: "",
                    error_class: "",
                    categoryRef: false,
                    isResearcherCategory: true,
                });
            }
        }

        setEditCategoryArray(editCategoryDataTmpArray);

        function tickTime() {
            setCardSortingTimeSpent((prevSeconds) => prevSeconds + 1);
        }

        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    }, [test]);

    const saveCardSorting = () => {
        var p = location.pathname.substr(1, 1);

        // if (p === "p") {
        //     callback();
        // } else {

        if (
            cardSorting.is_required_categories === 1 &&
            Object.entries(columns)[0][1].items.length > 0
        ) {
            showToast(
                "You have to sort all the cards into the respective categories before proceeding.",
                "error"
            );
        } else if (
            cardSorting.is_required_categories === 0 &&
            Object.entries(columns)[0][1].items.length === cardData.length
        ) {
            showToast(
                "You need to sort at least one card into the respective category before proceeding.",
                "error"
            );
        } else if (!isEnableNextButton) {
            showToast(
                "One or more items in the grouping require fixing before proceeding further.",
                "error"
            );
        } else {
            //// check all cards move in single category
            //cardDataResearcher
            //console.log(columns)

            var iteration = 1;
            for (const [key, value] of Object.entries(columns)) {
                if (iteration !== 1) {
                    if (
                        Object.entries(columns)[iteration - 1][1].items
                            .length === cardDataResearcher.length
                    ) {
                        showToast(
                            "You need to categorize the cards into a minimum of two categories",
                            "error"
                        );

                        return false;
                    }
                }

                iteration++;
            }

            // check any empty category name
            // check category name exits is already category name & edit category array
            if (editCategoryArray.length > 0) {
                for (var i = 0; i < editCategoryArray.length; i++) {
                    if (editCategoryArray[i].name === "") {
                        showToast("Category title cannot be blank.", "error");

                        return false;
                    }
                }
            }

            if (p === "p") {
                callback();
            } else {
                setFormLoading(true);

                saveCardSortingResultService(
                    {
                        test_id: test.id,
                        result_id: result.id,
                        guest_token: ReactSession.get("guest_token"),
                        time_spent: timeSpent,
                        card_sorting_time_spent: cardSortingTimeSpent,
                        data: columns,
                        editCategory: editCategoryArray,
                    },
                    ReactSession.get("token")
                ).then((response) => {
                    setFormLoading(false);

                    if (response.success) {
                        callback();
                    } else {
                        showToast(response.message, "error");
                    }
                });
            }
        }

        //}
    };

    const onDragEnd = (result, columns, setColumns) => {
        setIsDragingStartItem(false);

        console.log("yes");
        console.log(result);

        //console.log(result)
        if (!result.destination) {
            if (onTouchMobileCreateTmpHtml) {
                createNewCategoryTmpToPermanent();

                setOnTouchMobileCreateTmpHtml(false);
            }

            // apply some condition
            setOnDraggingCreateNewCategory(false);
            setOnDraggingCreateNewCategoryCardData(null);
            setTmpCategoryHtml(false); // hide tmp catgeory html

            //console.log('das1245')

            return;
        }

        //console.log('noa')
        // apply some condition
        setOnDraggingCreateNewCategory(false);
        setOnDraggingCreateNewCategoryCardData(null);
        setTmpCategoryHtml(false); // hide tmp catgeory html

        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            console.log("das");
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
        } else {
            console.log("das no");
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
        }
    };

    // add new category
    const addCategory = (event) => {
        event.preventDefault();
        event.stopPropagation();

        /*Object.assign(columns,{
            [Object.keys(columns).length+1]:{
                id:  "",
                name: 'Category name',
                type:"category",
                items: []
            }})*/

        //setRenameData({isEditing:false,id:'',title:''})
        if (isAddNewCategoryLoading) {
            return false;
        }

        setIsAddNewCategoryLoading(true);

        const yourCardItems = Object.entries(columns)[0][1].items;

        var uniqueIdData = Date.now() + Math.floor(Math.random() * 100000 + 1);

        const columnsFromBackend = {
            ["1"]: {
                name: "",
                type: "",
                items: yourCardItems,
            },
            ["2"]: {
                id: "",
                name: "",
                type: "category",
                uniqueId: uniqueIdData,
                items: [],
            },
        };

        const editCategoryData = [
            {
                id: 2,
                name: "",
                uniqueId: uniqueIdData,
                isEditing: true,
                error_msg: "",
                error_class: "",
                categoryRef: true,
                isResearcherCategory: false,
            },
        ];
        if (editCategoryArray.length > 0) {
            for (var i = 0; i < editCategoryArray.length; i++) {
                editCategoryArray[i].id = editCategoryArray[i].id + 1;
                editCategoryArray[i].error_msg =
                    editCategoryArray[i].name === ""
                        ? "Required field"
                        : editCategoryArray[i].error_msg;
                editCategoryArray[i].error_class =
                    editCategoryArray[i].name === ""
                        ? "input_error"
                        : editCategoryArray[i].error_class;
                editCategoryArray[i].categoryRef = false;
                editCategoryData.push(editCategoryArray[i]);
            }
        }

        setEditCategoryArray(editCategoryData);

        var iteration = 1;
        for (const [key, value] of Object.entries(columns)) {
            if (iteration !== 1) {
                Object.assign(columnsFromBackend, {
                    [iteration + 1]: columns[key],
                });
            }

            iteration++;
        }

        setColumns(columnsFromBackend);

        // set button type enable or not
        setEnableNextButton(false);

        //setIsTyping(false);
        setIsAddNewCategoryLoading(false);
    };

    const deleteCard = (cardId, cardName, columnId, uniqueId) => {
        //setRenameData({isEditing:false,id:'',title:''})
        //setIsTyping(false);
        // apply some condition
        setOnDraggingCreateNewCategory(false);
        setOnDraggingCreateNewCategoryCardData(null);
        setTmpCategoryHtml(false); // hide tmp catgeory html

        const columnKeys = Object.keys(columns);

        const CategoryItems = Object.entries(columns)[columnId][1];

        if (CategoryItems) {
            const newCategoryItems = CategoryItems.items.filter(
                (item) => item.id !== cardId
            );

            // delete items from category
            Object.assign(columns, {
                [columnKeys[columnId]]: {
                    id: CategoryItems.id,
                    name: CategoryItems.name,
                    uniqueId: CategoryItems.uniqueId,
                    type: CategoryItems.type,
                    items: newCategoryItems,
                },
            });

            // add items in your card
            const yourCardItems = Object.entries(columns)[0][1].items;
            yourCardItems.push({ id: cardId, name: cardName });

            setColumns(columns);
        }
    };

    /// new edit category function
    const editCategorys = (uniqueId) => {
        //console.log(uniqueId)

        const oldEditCategoryArray = editCategoryArray;

        //console.log(editCategoryArray)
        // return false;
        const editCategory = editCategoryArray.find(function (item) {
            return item.uniqueId === parseInt(uniqueId);
        });
        const IndexEditCategory = editCategoryArray.findIndex(function (item) {
            return item.uniqueId === parseInt(uniqueId);
        });

        oldEditCategoryArray[IndexEditCategory] = {
            ...oldEditCategoryArray[IndexEditCategory],
            isEditing: true,
        };
        console.log(oldEditCategoryArray);
        setEditCategoryArray(oldEditCategoryArray);
    };

    // delete category confirmDeleteCategory
    const deleteCategory = (columnId, editCategoryId, uniqueId) => {
        //console.log(uniqueId)
        //setRenameData({isEditing:false,id:'',title:''})
        //setIsTyping(false);
        if (isDeleteCategoryLoading) {
            // console.log('dasd')
            return false;
        }

        setIsDeleteCategoryLoading(true);
        const columnKeys = Object.keys(columns);

        const CategoryItems = Object.entries(columns)[columnId][1].items;

        // show confirmation model if any cards assigned to this category
        if (CategoryItems.length > 0) {
            setDeleteCategoryModal({
                open: true,
                id: columnId,
                editCategoryid: editCategoryId,
                unique_id: uniqueId,
            });
        } else {
            // delete category
            delete columns[columnKeys[columnId]];

            const oldEditCategoryArray = editCategoryArray;
            const IndexEditCategory = editCategoryArray.findIndex(function (
                item
            ) {
                return item.uniqueId === parseInt(uniqueId);
            });

            oldEditCategoryArray.splice(IndexEditCategory, 1);
            setEditCategoryArray(oldEditCategoryArray);
            checkAllErrors(oldEditCategoryArray);

            // console.log(columns)
            //console.log(columnKeys[columnId])
            setTimeout(function () {
                //console.log('hrllo')
                setIsDeleteCategoryLoading(false);
            }, 1500);
        }

        //console.log(columns)
        //console.log(editCategoryArray)
    };

    const confirmDeleteCategory = (columnId, editCategoryid, uniqueId) => {
        const columnKeys = Object.keys(columns);
        const CategoryItems = Object.entries(columns)[columnId][1].items;
        if (CategoryItems) {
            // add items in your card
            const yourCardItems = Object.entries(columns)[0][1].items;
            CategoryItems.forEach(function (items) {
                yourCardItems.push({ id: items.id, name: items.name });
            });
        }

        delete columns[columnKeys[columnId]];

        const oldEditCategoryArray = editCategoryArray;
        const IndexEditCategory = editCategoryArray.findIndex(function (item) {
            return item.uniqueId === parseInt(uniqueId);
        });

        oldEditCategoryArray.splice(IndexEditCategory, 1);
        setEditCategoryArray(oldEditCategoryArray);

        checkAllErrors(oldEditCategoryArray);
        setTimeout(function () {
            //console.log('hrllo')
            setIsDeleteCategoryLoading(false);
        }, 1500);
    };

    // edit category editCategory
    const editCategory = (columnId, name, uniqueId) => {
        //console.log('asdsa')

        /*const oldEditCategoryArray = editCategoryArray;

        console.log(editCategoryArray)
        return false;
        const editCategory = editCategoryArray.find(function(item) {return item.id === parseInt(columnId)});
        const IndexEditCategory = editCategoryArray.findIndex(function(item) {return item.id === parseInt(columnId)});

        oldEditCategoryArray[IndexEditCategory] = {...oldEditCategoryArray[IndexEditCategory],"isEditing":true};
        //console.log(oldEditCategoryArray)
        setEditCategoryArray(oldEditCategoryArray);*/

        const oldEditCategoryArray = editCategoryArray;

        //console.log(editCategoryArray)
        // return false;
        const editCategory = editCategoryArray.find(function (item) {
            return item.uniqueId === parseInt(uniqueId);
        });
        const IndexEditCategory = editCategoryArray.findIndex(function (item) {
            return item.uniqueId === parseInt(uniqueId);
        });

        oldEditCategoryArray[IndexEditCategory] = {
            ...oldEditCategoryArray[IndexEditCategory],
            isEditing: true,
        };
        //console.log(oldEditCategoryArray)
        setEditCategoryArray(oldEditCategoryArray);

        // setRenameData({isEditing:true,id:columnId,title:name})
        //setRenameData({open:true,isLoading:false,id:columnId,title:name})
    };

    const renameCategory = (e, columnId, index, uniqueId) => {
        // console.log(uniqueId)
        // return false;
        e.preventDefault();
        //console.log(e.type)
        //console.log('yes')
        if (e.keyCode === 13 || e.type === "click") {
            const oldEditCategoryArray = editCategoryArray;
            const editCategory = editCategoryArray.find(function (item) {
                return item.uniqueId === parseInt(uniqueId);
            });
            const IndexEditCategory = editCategoryArray.findIndex(function (
                item
            ) {
                return item.uniqueId === parseInt(uniqueId);
            });

            //oldEditCategoryArray[IndexEditCategory] = {...oldEditCategoryArray[IndexEditCategory],'name':e.target.value};
            //console.log(oldEditCategoryArray);
            //setEditCategoryArray(oldEditCategoryArray);

            // console.log(editCategory)
            // console.log(columnId)
            // console.log(IndexEditCategory)

            oldEditCategoryArray[IndexEditCategory] = {
                ...oldEditCategoryArray[IndexEditCategory],
                error_msg: "",
                error_class: "",
            };

            if (editCategory.name === "") {
                oldEditCategoryArray[IndexEditCategory] = {
                    ...oldEditCategoryArray[IndexEditCategory],
                    error_msg: "Required field",
                    error_class: "input_error",
                };
                setEditCategoryArray(oldEditCategoryArray);
                checkAllErrors(oldEditCategoryArray);
                //setFormErrors({"title":"Required field","error_class":"input_error"});
            } else {
                //console.log('dasd')
                const columnKeys = Object.keys(columns);

                // check category name exits is already category name & edit category array
                if (editCategoryArray.length > 0) {
                    for (var i = 0; i < editCategoryArray.length; i++) {
                        if (
                            editCategoryArray[i].name === editCategory.name &&
                            editCategoryArray[i].uniqueId !==
                                editCategory.uniqueId
                        ) {
                            showToast(
                                " A category with the same name already exists.",
                                "error"
                            );

                            checkAllErrors(editCategoryArray);

                            return false;
                        }
                    }
                }

                const checkAlreadyNameFromDatabase = databaseCategoryArray.find(
                    function (item) {
                        return item.name === editCategory.name;
                    }
                );
                if (checkAlreadyNameFromDatabase) {
                    showToast(
                        " A category with the same name already exists.",
                        "error"
                    );

                    const checkAlreadyNameFromDatabaseIndex =
                        editCategoryArray.findIndex(function (item) {
                            return item.name === editCategory.name;
                        });
                    //console.log(checkAlreadyNameFromDatabaseIndex)
                    if (checkAlreadyNameFromDatabaseIndex !== -1) {
                        const oldCategoryArray = editCategoryArray;
                        oldCategoryArray[
                            checkAlreadyNameFromDatabaseIndex
                        ].isEditing = true;
                        oldCategoryArray[
                            checkAlreadyNameFromDatabaseIndex
                        ].error_msg = "Category name already exists.";
                        oldCategoryArray[
                            checkAlreadyNameFromDatabaseIndex
                        ].error_class = "input_error";
                        setEditCategoryArray(oldCategoryArray);

                        checkAllErrors(oldCategoryArray);
                    }

                    return false;
                }

                // check category name exits is already category name & edit category array
                /*var iteration=1;
                for (const [key, value] of Object.entries(columns)) {

                    if(iteration !== 1){

                        if(Object.entries(columns)[iteration-1][1].name === editCategory.name){
                            toast((
                                <div className='toastinner'>
                                    A category with the same name already exists
                                    <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });


                            return false;
                        }
                    }

                    iteration++;

                }*/

                //console.log(columns)
                //console.log(columnId)
                //console.log(index)
                //console.log(Object.entries(columns)[index])

                const CategoryItems = Object.entries(columns)[index][1];

                if (CategoryItems) {
                    Object.assign(columns, {
                        [columnKeys[index]]: {
                            id: CategoryItems.id,
                            name: editCategory.name,
                            type: CategoryItems.type,
                            uniqueId: CategoryItems.uniqueId,
                            items: CategoryItems.items,
                        },
                    });
                }

                oldEditCategoryArray[IndexEditCategory] = {
                    ...oldEditCategoryArray[IndexEditCategory],
                    isEditing: false,
                    error_msg: "",
                    error_class: "",
                };
                //console.log(oldEditCategoryArray)
                setEditCategoryArray(oldEditCategoryArray);

                checkAllErrors(oldEditCategoryArray);

                //setRenameData({isEditing:false,id:'',title:''})
                //setIsTyping(false);
            }
        }
    };

    // create category if no exists
    const onDraggingCreateCategory = (item, index, columnId) => {
        // console.log(e)
        //console.log(item)
        if (
            cardSorting.card_sorting === "open" ||
            (cardSorting.card_sorting === "close" &&
                cardSorting.is_add_new_category === 1)
        ) {
            // create category if no category is exists
            setOnDraggingCreateNewCategory(true); // on dragging create new catgory
            setOnDraggingCreateNewCategoryCardData(item); // on dragging create new catgory

            // move items from category to tmp category
            if (index) {
                setMoveItemsCategoryToTmpCategory({
                    id: index,
                    editCategoryId: columnId,
                });
            }
        }
        //  if(Object.keys(columns).length === 1) {
        //      const yourCardItems = Object.entries(columns)[0][1].items;
        //
        //      const columnsFromBackend = {
        //          ['1']: {
        //              name: "",
        //              type: "",
        //              items: yourCardItems
        //          },
        //          ['2']: {
        //              id: "",
        //              name: '',
        //              type: "category",
        //              items: []
        //          }
        //      };
        //
        //      setColumns(columnsFromBackend);
        //
        //      // enable edit category data
        //      const editCategoryData = [{'id':2,'name':'','isEditing':true,'error_msg':'','error_class':'','categoryRef':true}];
        //      setEditCategoryArray(editCategoryData);
        //      setEnableNextButton(false);
        // }
    };

    useEffect(() => {
        if (inputref.current) {
            inputref.current.focus();
            setIsTyping(true);
        }
    }, [editCategoryArray]);

    const runFunction = (e) => {
        /*if (
            e.target.type !== 'text'
        ) {
        setIsTyping(false)
            e.preventDefault();
            return;
        }*/

        if (e.target.type !== "text") {
            setIsTyping(false);
        }

        if (
            e.target.classList.contains("create-btns") ||
            e.target.classList.contains("save-category-input") ||
            e.target.classList.contains("fa fa-check save-category-input") ||
            e.target.type === "text"
        ) {
            e.preventDefault();
            return;
        }

        /* const columnKeys = Object.keys(columns);

         // check category name exits is already category name & edit category array
         if(editCategoryArray.length > 0){
             for(var i=0; i<editCategoryArray.length; i++){
                 if(editCategoryArray[i].name === editCategory.name && editCategoryArray[i].id !== editCategory.id){
                     toast((
                         <div className='toastinner'>
                             A category with the same name already exists.
                             <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                         </div>), {
                         className: 'errortoast',
                         position: 'bottom-center'
                     });

                     return false;

                 }
             }
         }


         // check category name exits is already category name & edit category array
         var iteration=1;
         for (const [key, value] of Object.entries(columns)) {

             if(iteration !== 1){

                 if(Object.entries(columns)[iteration-1][1].name === editCategory.name){
                     toast((
                         <div className='toastinner'>
                             A category with the same name already exists
                             <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                         </div>), {
                         className: 'errortoast',
                         position: 'bottom-center'
                     });


                     return false;
                 }
             }

             iteration++;

         }
 */

        //console.log(databaseCategoryArray)
        // disabled isediting false
        const newCategoryArray = [];
        console.log(editCategoryArray);
        if (editCategoryArray.length > 0) {
            editCategoryArray.forEach((items) => {
                var nameNew = items.name;
                var newId = items.id - 1;
                if (items.name === "") {
                    //nameNew = 'Category name '+newId;
                    newCategoryArray.push({
                        ...items,
                        name: "",
                        uniqueId: items.uniqueId,
                        isEditing: true,
                        error_msg: "Required field",
                        error_class: "input_error",
                    });
                } else {
                    //
                    // const checkAlreadyName = newCategoryArray.find(function (item) { return item.name === nameNew });
                    // //console.log(newCategoryArray)
                    // if (checkAlreadyName) {
                    //     newCategoryArray.push({
                    //         ...items,
                    //         'name': nameNew,
                    //         'uniqueId': items.uniqueId,
                    //         'isEditing': true,
                    //         'error_msg': 'Category name already exists',
                    //         'error_class': 'input_error'
                    //     })
                    // } else {
                    //     newCategoryArray.push({
                    //         ...items,
                    //         'name': nameNew,
                    //         'uniqueId': items.uniqueId,
                    //         'isEditing': false,
                    //         'error_msg': '',
                    //         'error_class': ''
                    //     })
                    // }

                    // check name exists from database
                    // const checkAlreadyNameFromDatabase = databaseCategoryArray.find(function (item) { return item.name === nameNew });
                    // if (checkAlreadyNameFromDatabase) {
                    //     const checkAlreadyNameFromDatabaseIndex = newCategoryArray.findIndex(function (item) { return item.name === nameNew });
                    //     newCategoryArray[checkAlreadyNameFromDatabaseIndex].isEditing = true;
                    //     newCategoryArray[checkAlreadyNameFromDatabaseIndex].error_msg = 'Category name already exists.';
                    //     newCategoryArray[checkAlreadyNameFromDatabaseIndex].error_class = 'input_error';
                    // }

                    newCategoryArray.push({
                        ...items,
                        name: nameNew,
                        uniqueId: items.uniqueId,
                        isEditing: false,
                        error_msg: "",
                        error_class: "",
                    });
                }
            });
        }

        const newCategoryArrayTmp = [];
        console.log(editCategoryArray);
        if (newCategoryArray.length > 0) {
            newCategoryArray.forEach((items) => {
                if (items.name === "") {
                    //nameNew = 'Category name '+newId;
                    newCategoryArrayTmp.push({
                        ...items,
                        name: "",
                        uniqueId: items.uniqueId,
                        isEditing: true,
                        error_msg: "Required field",
                        error_class: "input_error",
                    });
                } else {
                    const checkAlreadyName = editCategoryArray.find(function (
                        item
                    ) {
                        return (
                            item.name === items.name &&
                            item.uniqueId !== items.uniqueId
                        );
                    });
                    //console.log(newCategoryArray)
                    if (
                        checkAlreadyName &&
                        items.isResearcherCategory === false
                    ) {
                        newCategoryArrayTmp.push({
                            ...items,
                            name: items.name,
                            uniqueId: items.uniqueId,
                            isEditing: true,
                            error_msg: "Category name already exists",
                            error_class: "input_error",
                        });
                    } else {
                        newCategoryArrayTmp.push({
                            ...items,
                            name: items.name,
                            uniqueId: items.uniqueId,
                            isEditing: false,
                            error_msg: "",
                            error_class: "",
                        });
                    }

                    // check name exists from database
                    //  const checkAlreadyNameFromDatabase = databaseCategoryArray.find(function (item) { return item.name === items.name && item.uniqueId !== items.uniqueId });
                    //  if (checkAlreadyNameFromDatabase && !checkAlreadyNameFromDatabase.id) {
                    //      const checkAlreadyNameFromDatabaseIndex = newCategoryArrayTmp.findIndex(function (item) { return item.name === items.name && item.uniqueId !== items.uniqueId });
                    //     if(checkAlreadyNameFromDatabaseIndex !== -1 && !checkAlreadyNameFromDatabaseIndex.isResearcherCategory) {
                    //         newCategoryArrayTmp[checkAlreadyNameFromDatabaseIndex].isEditing = true;
                    //         newCategoryArrayTmp[checkAlreadyNameFromDatabaseIndex].error_msg = 'Category name already exists2.';
                    //         newCategoryArrayTmp[checkAlreadyNameFromDatabaseIndex].error_class = 'input_error';
                    //     }
                    //  }
                }
            });
        }

        console.log(newCategoryArrayTmp);

        var iteration = 1;
        //console.log(columns)
        //console.log(newCategoryArray)
        /*for (const [key, value] of Object.entries(columns)) {


            if(iteration !== 1){

                const editCategory = newCategoryArray.find(function(item) {return item.id === iteration});

                //console.log(editCategory)
                if(editCategory){

                    Object.assign(columns,{
                        [iteration]:{
                            ...columns[key],
                            'name':editCategory.name
                        }
                    })
                }

            }

            iteration++;

        }*/

        setEditCategoryArray(newCategoryArrayTmp);

        checkAllErrors(newCategoryArrayTmp);

        // console.log(columns)
        //setIsTyping(false);
    };

    const checkAllErrors = (newCategoryArray) => {
        // set enable button open
        setEnableNextButton(true);

        if (newCategoryArray.length > 0) {
            newCategoryArray.forEach((items) => {
                // check if any edit category field left
                if (items.isEditing) {
                    setEnableNextButton(false);
                    return false;
                }
            });
        }
    };

    const renderedHtml = () => {
        var render_html = [];
        {
            Object.entries(columns).map(([columnId, column], index) => {
                {
                    index === 0 &&
                        render_html.push(
                            <Droppable droppableId={columnId} key={columnId}>
                                {(provided, snapshot) => {
                                    return (
                                        <div className="cards-top-bar-fixed">
                                            <div
                                                className="card-sorting-view-wrap"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                key={columnId}
                                            >
                                                <div className="card-count-top-wrap">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "semibold-font"
                                                        }
                                                    >
                                                        {test.language === "en"
                                                            ? "Your cards"
                                                            : "بطاقاتك"}
                                                    </Text>
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "semibold-font"
                                                        }
                                                    >
                                                        {" "}
                                                        {
                                                            column.items.length
                                                        }{" "}
                                                        {test.language === "en"
                                                            ? "left"
                                                            : "متبقي"}
                                                    </Text>
                                                </div>

                                                <div
                                                    className={`
                                        ${
                                            snapshot.isDraggingOver
                                                ? "your-card-dragging"
                                                : ""
                                        }
                                          all-cards-view-wrap  
                                        `}
                                                >
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {column.items.length ===
                                                        0 ? (
                                                            <>
                                                                {test.language ===
                                                                "en"
                                                                    ? "No cards left"
                                                                    : " لم يتبقى أي بطاقات"}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Text>

                                                    <div className="cards-view-inner-wrap">
                                                        <div className="cardsort-view-data-repeat">
                                                            {column.items.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            draggableId={`test-${item.id}`}
                                                                            index={
                                                                                index
                                                                            }
                                                                        >
                                                                            {(
                                                                                provided,
                                                                                snapshot
                                                                            ) => {
                                                                                return (
                                                                                    <span
                                                                                        className={
                                                                                            snapshot.isDragging
                                                                                                ? "dragging-card-items draggable-item"
                                                                                                : "draggable-item"
                                                                                        }
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        onMouseDown={() =>
                                                                                            onDraggingCreateCategory(
                                                                                                item,
                                                                                                "",
                                                                                                ""
                                                                                            )
                                                                                        }
                                                                                        onTouchStart={(
                                                                                            e
                                                                                        ) => {
                                                                                            if (
                                                                                                cardSorting.card_sorting ===
                                                                                                    "open" ||
                                                                                                (cardSorting.card_sorting ===
                                                                                                    "close" &&
                                                                                                    cardSorting.is_add_new_category ===
                                                                                                        1)
                                                                                            ) {
                                                                                                setOnDraggingCreateNewCategory(
                                                                                                    true
                                                                                                ); // on dragging create new catgory
                                                                                                setOnDraggingCreateNewCategoryCardData(
                                                                                                    item
                                                                                                ); // on dragging create new catgory
                                                                                                setOnTouchMobileCreateTmpHtml(
                                                                                                    true
                                                                                                );
                                                                                                onMouseOverCreateTmpCategory(
                                                                                                    e,
                                                                                                    item
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        // onTouchEnd={onMouseMoveCreateCategory}
                                                                                    >
                                                                                        <Icon
                                                                                            colorClass={
                                                                                                "gray-700-svg"
                                                                                            }
                                                                                            value={
                                                                                                "move-object"
                                                                                            }
                                                                                            size={
                                                                                                "medium"
                                                                                            }
                                                                                        />
                                                                                        <Text
                                                                                            type={
                                                                                                "body-text-3"
                                                                                            }
                                                                                            fontWeight={
                                                                                                "medium-font"
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </Text>
                                                                                        <i
                                                                                            className="fa fa-times-circle"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                    </span>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                }
                                                            )}
                                                            {
                                                                provided.placeholder
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            </Droppable>
                        );
                }
            });
        }
        return <>{render_html}</>;
    };

    const renderedCategoryCommonHtml = (columnId, column, index) => {
        return (
            <Droppable droppableId={columnId} key={columnId}>
                {(provided, snapshot) => {
                    return (
                        <div
                            key={columnId}
                            className={`
                                          card-category-data-hold`}
                                          onMouseEnter={(e)=>{
                                            setTmpCategoryHtml(false);
                                            e.preventDefault();
                                          }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <form
                                action="post"
                                onSubmit={(e) =>
                                    renameCategory(
                                        e,
                                        columnId,
                                        index,
                                        column.uniqueId
                                    )
                                }
                            >
                                <div className="card-cat-top-wrp card-category-name-input">
                                    <h3
                                        className={`${
                                            column.id === "" &&
                                            "heading-with-icons"
                                        }`}
                                    >
                                        {editCategoryArray &&
                                        editCategoryArray.find(function (item) {
                                            return (
                                                item.uniqueId ===
                                                parseInt(column.uniqueId)
                                            );
                                        }) &&
                                        editCategoryArray.find(function (item) {
                                            return (
                                                item.uniqueId ===
                                                parseInt(column.uniqueId)
                                            );
                                        }).isEditing === true ? (
                                            <>
                                                <InputField
                                                    onInput={(e) => {
                                                        if (
                                                            e.target.value
                                                                .length > 0
                                                        ) {
                                                            setFormErrors({
                                                                ...formErrors,
                                                                title: null,
                                                                error_class: "",
                                                            });
                                                        }
                                                    }}
                                                    error_class={
                                                        editCategoryArray.find(
                                                            function (item) {
                                                                return (
                                                                    item.uniqueId ===
                                                                    parseInt(
                                                                        column.uniqueId
                                                                    )
                                                                );
                                                            }
                                                        ).error_class
                                                    }
                                                    error={
                                                        editCategoryArray.find(
                                                            function (item) {
                                                                return (
                                                                    item.uniqueId ===
                                                                    parseInt(
                                                                        column.uniqueId
                                                                    )
                                                                );
                                                            }
                                                        ).error_msg
                                                    }
                                                    label={
                                                        test.language == "en"
                                                            ? "Category name"
                                                            : "اسم الفئة"
                                                    }
                                                    value={
                                                        editCategoryArray.find(
                                                            function (item) {
                                                                return (
                                                                    item.uniqueId ===
                                                                    parseInt(
                                                                        column.uniqueId
                                                                    )
                                                                );
                                                            }
                                                        ).name
                                                    }
                                                    onChange={(e) => {
                                                        const oldEditCategoryArray =
                                                            editCategoryArray;
                                                        const IndexEditCategory =
                                                            editCategoryArray.findIndex(
                                                                function (
                                                                    item
                                                                ) {
                                                                    return (
                                                                        item.uniqueId ===
                                                                        parseInt(
                                                                            column.uniqueId
                                                                        )
                                                                    );
                                                                }
                                                            );
                                                        oldEditCategoryArray[
                                                            IndexEditCategory
                                                        ] = {
                                                            ...oldEditCategoryArray[
                                                                IndexEditCategory
                                                            ],
                                                            name: e.target
                                                                .value,
                                                        };
                                                        //console.log(oldEditCategoryArray);categoryRef
                                                        setEditCategoryArray(
                                                            oldEditCategoryArray
                                                        );
                                                    }}
                                                    //onKeyDown={(e)=>renameCategory(e,columnId,index)}
                                                    onClick={() => {
                                                        setIsTyping(true);
                                                    }}
                                                    inputref={
                                                        editCategoryArray.find(
                                                            function (item) {
                                                                return (
                                                                    item.uniqueId ===
                                                                    parseInt(
                                                                        column.uniqueId
                                                                    )
                                                                );
                                                            }
                                                        ).categoryRef
                                                            ? inputref
                                                            : undefined
                                                    }
                                                    maxLength={100}
                                                />
                                            </>
                                        ) : (
                                            <strong>
                                                {
                                                    editCategoryArray.find(
                                                        function (item) {
                                                            return (
                                                                item.uniqueId ===
                                                                parseInt(
                                                                    column.uniqueId
                                                                )
                                                            );
                                                        }
                                                    ).name
                                                }
                                            </strong>
                                        )}

                                        <span className="pink-color">
                                            {column.items.length > 0 && (
                                                <> ({column.items.length}) </>
                                            )}
                                        </span>
                                    </h3>

                                    {column.id === "" && (
                                        <div className="card-test-action-option">
                                            {editCategoryArray &&
                                            editCategoryArray.find(function (
                                                item
                                            ) {
                                                return (
                                                    item.uniqueId ===
                                                    parseInt(column.uniqueId)
                                                );
                                            }) &&
                                            editCategoryArray.find(function (
                                                item
                                            ) {
                                                return (
                                                    item.uniqueId ===
                                                    parseInt(column.uniqueId)
                                                );
                                            }).isEditing === true ? (
                                                <button
                                                    type="submit"
                                                    onClick={(e) =>
                                                        renameCategory(
                                                            e,
                                                            columnId,
                                                            index,
                                                            column.uniqueId
                                                        )
                                                    }
                                                    className="save-category-input hover-inline-svg inline-svg-24"
                                                >
                                                    <svg
                                                        className="save-category-input"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 40 40"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M8.33325 23.3333L17.4999 31.25L34.1666 10"
                                                            stroke="black"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                            ) : (
                                                <span
                                                    className="save-category-input hover-inline-svg inline-svg-24"
                                                    onClick={() =>
                                                        editCategorys(
                                                            column.uniqueId
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        className="save-category-input"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 40 40"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M28.1033 7.47832L32.5 11.875M28.1033 7.47832L30.915 4.66498C31.5011 4.07886 32.2961 3.74957 33.125 3.74957C33.9539 3.74957 34.7489 4.07886 35.335 4.66498C35.9211 5.25111 36.2504 6.04607 36.2504 6.87498C36.2504 7.7039 35.9211 8.49886 35.335 9.08499L11.3867 33.0333C10.5055 33.9139 9.41894 34.5612 8.225 34.9167L3.75 36.25L5.08333 31.775C5.4388 30.581 6.08606 29.4944 6.96667 28.6133L28.105 7.47832H28.1033Z"
                                                            stroke="black"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            )}
                                            <span
                                                className="save-category-input hover-inline-svg inline-svg-24"
                                                onClick={() =>
                                                    deleteCategory(
                                                        index,
                                                        columnId,
                                                        column.uniqueId
                                                    )
                                                }
                                            >
                                                {/*<img  src={process.env.REACT_APP_URL + "img/delete.svg"} />*/}
                                                <svg
                                                    className="save-category-input"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M24.5667 15L23.99 30M16.01 30L15.4333 15M32.0467 9.65C32.6167 9.73667 33.1833 9.82833 33.75 9.92667M32.0467 9.65167L30.2667 32.7883C30.194 33.7304 29.7684 34.6103 29.075 35.2521C28.3816 35.8939 27.4715 36.2503 26.5267 36.25H13.4733C12.5285 36.2503 11.6184 35.8939 10.925 35.2521C10.2316 34.6103 9.80598 33.7304 9.73333 32.7883L7.95333 9.65M32.0467 9.65C30.1231 9.35919 28.1896 9.13849 26.25 8.98833M6.25 9.925C6.81667 9.82667 7.38333 9.735 7.95333 9.65M7.95333 9.65C9.87689 9.3592 11.8104 9.1385 13.75 8.98833M26.25 8.98833V7.46167C26.25 5.495 24.7333 3.855 22.7667 3.79333C20.9227 3.7344 19.0773 3.7344 17.2333 3.79333C15.2667 3.855 13.75 5.49667 13.75 7.46167V8.98833M26.25 8.98833C22.0895 8.6668 17.9105 8.6668 13.75 8.98833"
                                                        stroke="black"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </form>

                            {/*${minimizeData ? 'd-none' : ''}*/}
                            <div className={`card-cat-card-data-hold `}>
                                <div
                                    className="active-tiles-wrap ondragAddDesign"
                                    id={column.uniqueId}
                                >
                                    {!minimizeData &&
                                        column.items.map(
                                            (item, columnIndex) => {
                                                return (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={`test-${item.id}`}
                                                        index={columnIndex}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot
                                                        ) => {
                                                            return (
                                                                <span
                                                                    id={"2"}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={
                                                                        snapshot.isDragging
                                                                            ? "dragging-card-items draggable-item"
                                                                            : "draggable-item"
                                                                    }
                                                                    style={
                                                                        snapshot.isDragging
                                                                            ? {
                                                                                  userSelect:
                                                                                      "none",
                                                                                  // borderColor: snapshot.isDragging
                                                                                  //     ? "transparent"
                                                                                  //     : "#595972",
                                                                                 ...provided.draggableProps.style,
                                                                              }
                                                                            : {
                                                                                  userSelect:
                                                                                      "none",
                                                                                      ...provided.draggableProps.style
                                                                              }
                                                                    }
                                                                    onMouseDown={() =>
                                                                        onDraggingCreateCategory(
                                                                            item,
                                                                            index,
                                                                            columnId
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        color={
                                                                            "gray-700-svg"
                                                                        }
                                                                        value={
                                                                            "Move object"
                                                                        }
                                                                        size={
                                                                            "medium"
                                                                        }
                                                                    />
                                                                    <Text
                                                                        type={
                                                                            "body-text-3"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Text>
                                                                    <em
                                                                        className={
                                                                            "delete-single-card hover-inline-svg inline-svg-24"
                                                                        }
                                                                        onClick={() =>
                                                                            deleteCard(
                                                                                item.id,
                                                                                item.name,
                                                                                index,
                                                                                column.uniqueId
                                                                            )
                                                                        }
                                                                    >
                                                                        {/*<Icon value={'close'} hover={true} size={"medium"} />*/}
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M6 18L18 6M6 6L18 18"
                                                                                stroke="#676767"
                                                                                stroke-width="1.5"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                            />
                                                                        </svg>
                                                                        {/*<i className="fa fa-times-circle" onClick={()=>deleteCard(item.id,item.name,index,column.uniqueId)} aria-hidden="true"></i>*/}
                                                                    </em>
                                                                </span>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            }
                                        )}
                                    {!minimizeData && provided.placeholder}
                                </div>

                                {snapshot.isDraggingOver ||
                                    (column.items.length === 0 && (
                                        <>
                                            <div
                                                className={`no-cat-added-in-card ${
                                                    minimizeData ? "d-none" : ""
                                                }`}
                                            >
                                                <Icon
                                                    colorClass={"gray-700-svg"}
                                                    value={"attention"}
                                                    size={"medium"}
                                                />
                                                <Text
                                                    type={"body-text-3"}
                                                    fontWeight={"medium-font"}
                                                    cssClasses={`${
                                                        snapshot.isDraggingOver
                                                            ? "yes"
                                                            : "no"
                                                    }`}
                                                >
                                                    {test.language == "en"
                                                        ? "No cards added to this category"
                                                        : "لا توجد بطاقات مضافة لهذه الفئة"}
                                                </Text>
                                            </div>
                                        </>
                                    ))}
                            </div>
                        </div>
                    );
                }}
            </Droppable>
        );
    };

    const breakpointColumnsObj = {
        default: 3,
        3000: 5,
        1920: 4,
        1599: 3,
        700: 2,
        500: 1,
    };

    const renderedCategoryHtml = () => {
        var render_html_left = [];
        var render_html_center = [];
        var render_html_right = [];
        var checkColumn = 0;
        {
            Object.entries(columns).map(([columnId, columnss], index) => {
                if (index !== 0) {
                    render_html_left.push(
                        renderedCategoryCommonHtml(columnId, columnss, index)
                    );
                }
            });
        }
        return (
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid card-sorting-selected-test-cat"
                columnClassName="my-masonry-grid_column card-sort-test-view-lhs"
                onMouseUp={onMouseMoveCreateCategory}
                onMouseOver={onMouseOverCreateTmpCategory}
            >
                {render_html_left}

                {/*create tmp div */}
                <div
                    className={`card-category-data-hold tmp-cat-hover tmp-category ${
                        !tmpCategoryHtml ? "d-none" : ""
                    } `}
                >
                    <div className="card-cat-top-wrp tmp-cat-hover">
                        <h3 className={"tmp-cat-hover"}>
                            <b className={"tmp-cat-hover"}>
                                Drop to create category
                            </b>
                        </h3>
                    </div>
                    <div
                        className="card-cat-card-data-hold tmp-cat-hover tmp-cat-style"
                        style={{ height: "30px" }}
                    >
                        <div className="active-tiles-wrap tmp-cat-hover"></div>
                    </div>
                </div>
            </Masonry>
        );
    };

    const onMouseOverCreateTmpCategory = (e, item = null) => {
        console.log(e.target.classList)

        if (
            (e.target.classList.contains("my-masonry-grid") ||
            e.target.classList.contains("my-masonry-grid_column") ||
            e.target.classList.contains("draggable-item")) && (!e.target.classList.contains("card-category-data-hold"))
        ) {
            //console.log(onDraggingCreateNewCategoryCardData)
            if (item || onDraggingCreateNewCategoryCardData) {
                // console.log('sss')
                if (item) {
                    //  console.log('yes')
                    setOnDraggingCreateNewCategoryCardData(item);
                }

                // show tmp category html
                setTmpCategoryHtml(true);
            } 
        }
    };

    const onMouseMoveCreateCategory = (e) => {
        if (
            e.target.classList.contains("tmp-cat-hover") ||
            e.target.classList.contains("dragging-card-items")
        ) {
            //console.log(onDraggingCreateNewCategoryCardData)
            if (onDraggingCreateNewCategoryCardData) {
                createNewCategoryTmpToPermanent();
            }
        }
    };

    const createNewCategoryTmpToPermanent = () => {
        //const [moveItemsCategoryToTmpCategory, setMoveItemsCategoryToTmpCategory] = useState({id:'',editCategoryId:''});

        console.log(moveItemsCategoryToTmpCategory);
        if (moveItemsCategoryToTmpCategory.id) {
            const checkCategoryItems =
                Object.entries(columns)[moveItemsCategoryToTmpCategory.id];
            // const checkCategoryItems = Object.entries(columns)[moveItemsCategoryToTmpCategory.id][1];
            if (checkCategoryItems) {
                var yourCardItems =
                    Object.entries(columns)[
                        moveItemsCategoryToTmpCategory.id
                    ][1].items;
            } else {
                var yourCardItems = Object.entries(columns)[0][1].items;
            }
        } else {
            var yourCardItems = Object.entries(columns)[0][1].items;
        }

        const yourCardItemsNew = yourCardItems.filter(
            (item) => item.id !== onDraggingCreateNewCategoryCardData.id
        );

        const newCategoryCard = [onDraggingCreateNewCategoryCardData];

        var editCategoryData = editCategoryArray;

        var getEditCategoryLength = editCategoryArray.length;
        if (getEditCategoryLength === 0) {
            var getEditCategoryId = 2 + databaseCategoryArray.length;
        } else {
            //console.log(editCategoryArray)

            if (databaseCategoryArray.length === 0) {
                var getEditCategoryId =
                    editCategoryArray[editCategoryArray.length - 1].id + 1;
            } else {
                if (
                    editCategoryArray[editCategoryArray.length - 1].id <
                    editCategoryArray.length + databaseCategoryArray.length
                ) {
                    var getEditCategoryId =
                        editCategoryArray[editCategoryArray.length - 1].id +
                        1 +
                        databaseCategoryArray.length;
                } else {
                    var getEditCategoryId =
                        editCategoryArray[editCategoryArray.length - 1].id + 1;
                }
            }

            // var getEditCategoryId = 4;
            // console.log()
        }

        //console.log(getEditCategoryId)
        var uniqueIdData = Date.now();
        editCategoryData.push({
            id: getEditCategoryId,
            name: "",
            uniqueId: uniqueIdData,
            isEditing: true,
            error_msg: "",
            error_class: "",
            categoryRef: true,
            isResearcherCategory: false,
        });
        //console.log(editCategoryData)
        setEditCategoryArray(editCategoryData);

        var columnKeys = Object.keys(columns);
        if (moveItemsCategoryToTmpCategory.id) {
            const CategoryItemss =
                Object.entries(columns)[moveItemsCategoryToTmpCategory.id];
            if (CategoryItemss) {
                const CategoryItems =
                    Object.entries(columns)[
                        moveItemsCategoryToTmpCategory.id
                    ][1];

                console.log(columns);
                console.log(columnKeys[moveItemsCategoryToTmpCategory.id]);
                var newCard = Object.entries(columns)[0][1].items;

                var newCardItems = newCard.filter(
                    (item) => item.id !== onDraggingCreateNewCategoryCardData.id
                );
                console.log(newCardItems);
                console.log(onDraggingCreateNewCategoryCardData);
                Object.assign(columns, {
                    [columnKeys[moveItemsCategoryToTmpCategory.id]]: {
                        id: CategoryItems.id,
                        name: CategoryItems.name,
                        uniqueId: CategoryItems.uniqueId,
                        type: CategoryItems.type,
                        items: yourCardItemsNew,
                    },
                });

                Object.assign(columns, {
                    ["1"]: {
                        name: "",
                        type: "",
                        items: newCardItems,
                    },
                });
            } else {
                console.log("dasdasd");
                Object.assign(columns, {
                    ["1"]: {
                        name: "",
                        type: "",
                        items: yourCardItemsNew,
                    },
                });
            }
        } else {
            Object.assign(columns, {
                ["1"]: {
                    name: "",
                    type: "",
                    items: yourCardItemsNew,
                },
            });
        }

        Object.assign(columns, {
            [parseInt(columnKeys.pop()) + 1]: {
                id: "",
                uniqueId: uniqueIdData,
                name: "",
                type: "category",
                items: newCategoryCard,
            },
        });

        setEnableNextButton(false);
        //onDragEnd([], columns, setColumns);

        // hide tmp category html
        setTmpCategoryHtml(false);

        // apply some condition
        setOnDraggingCreateNewCategory(false);
        setOnDraggingCreateNewCategoryCardData(null);
        setMoveItemsCategoryToTmpCategory({ id: "", editCategoryId: "" });
        const elemt = document.getElementsByClassName("dragging-card-items");
        if (elemt) {
            console.log(elemt.length);

            elemt.removeAttr("style"); // uncomment this line shivam
        }
    };
    const taskHasError = ()=>{
        var error = false;

        if(!cardSorting){
            error = true;
        }
        else if(!cardSorting.title){
            error = true;
        } else if(test.card_sorting_card.length < 2){
            error = true;
        } else if(cardSorting.card_sorting === 'close' && test.card_sorting_category.length<2){
            error = true;
        }


        return error;
    }
    return (
        <>
            {initialScreen && (
                <CardSortingIntroduction
                    goToFirstTask={() => {
                        setInitialScreen(false);
                        // location scroll top
                        window.scrollTo(0, 0);
                    }}
                    testlanguage={test.language}
                />
            )}
            {!initialScreen && (
                <>
                {taskHasError()?
                <TaskNotCompleted to={"/r/test/"+test.id+"/card-sorting-test"}/>
                :
                <>
                    <div
                        onClick={(e) => runFunction(e)}
                        className={`tester-page-wrapper card-sorting-take-test-wrap ${
                            isTyping ? "input-type-active" : ""
                        } ${isDragingStartItem ? "card-dragging-start" : ""}`}
                    >
                        <DragDropContext
                            onDragEnd={(result) =>
                                onDragEnd(result, columns, setColumns)
                            }
                            onDragStart={(e) => {
                                setIsDragingStartItem(true);
                                //console.log(e);
                            }}
                            onDragUpdate={(e) => {
                                if (e.destination) {
                                    var cusid_ele =
                                        document.getElementsByClassName(
                                            "ondragAddDesign"
                                        )[
                                            parseInt(
                                                e.destination.droppableId
                                            ) - 2
                                        ];

                                    if (cusid_ele) {
                                        if (cusid_ele.lastChild) {
                                            // cusid_ele.lastChild.className = "addBorderWhileDragging";
                                        }
                                    }
                                }
                                // onMouseMoveCreateCategory(e);
                            }}
                        >
                            <div className="container inner-page-container">
                                <div
                                    className={"card-sorting-tests-header-wrap"}
                                >
                                    <Text type={"h4"}>{cardSorting.title}</Text>

                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"medium-font"}
                                    >
                                        {test.language == "en"
                                            ? "Drag the cards into categories and give it an appropriate name."
                                            : "اسحب البطاقات إلى الفئات وأعطها اسماً مناسباً"}
                                    </Text>
                                </div>

                                <div className="card-sorting-test-view-data-wrap">
                                    {renderedHtml()}

                                    <div className="card-sorting-cat-view-wrap">
                                        <div
                                            className={`cardtake-test-top d-flex align-items-center ${
                                                cardSorting.card_sorting ===
                                                    "open" ||
                                                (cardSorting.card_sorting ===
                                                    "close" &&
                                                    cardSorting.is_add_new_category ===
                                                        1)
                                                    ? "justify-content-between"
                                                    : "justify-content-end"
                                            }`}
                                        >
                                            {(cardSorting.card_sorting ===
                                                "open" ||
                                                (cardSorting.card_sorting ===
                                                    "close" &&
                                                    cardSorting.is_add_new_category ===
                                                        1)) && (
                                                <>
                                                    {/*<button onClick={addCategory} className="create-btn">{test.language=="en"?"Add category":"أضف فئة"} <span className="save-category-input"><img className="save-category-input" src={process.env.REACT_APP_URL + "images/plus-white.svg"}/></span> </button>*/}
                                                    <button
                                                        onClick={addCategory}
                                                        className="btn primary-small create-btns  btn-with-icon btn-with-icon-left "
                                                    >
                                                        <Icon
                                                            colorClass={
                                                                "gray-50-svg"
                                                            }
                                                            size={"small"}
                                                            value="add"
                                                        />{" "}
                                                        {test.language == "en"
                                                            ? "Add category"
                                                            : "أضف فئة"}
                                                    </button>
                                                </>
                                            )}

                                            <div className="minimize-box-wrap">
                                                
                                                {!minimizeData && (
                                                    <>
                                                        <Button
                                                            size={"small"}
                                                            type={"secondary"}
                                                            iconLeft={
                                                                <Icon
                                                                    size={
                                                                        "small"
                                                                    }
                                                                    value={
                                                                        "Minimize cards"
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                test.language ===
                                                                "en"
                                                                    ? "Minimize categories"
                                                                    : "تصغير الفئات"
                                                            }
                                                            onClick={() =>
                                                                setMinimizeData(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                                {minimizeData && (
                                                    <>
                                                        <Button
                                                            size={"small"}
                                                            type={"secondary"}
                                                            iconLeft={
                                                                <Icon
                                                                    size={
                                                                        "small"
                                                                    }
                                                                    value={
                                                                        "maximize"
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                test.language ===
                                                                "en"
                                                                    ? "Expand categories"
                                                                    : "قم بتوسيع الفئات"
                                                            }
                                                            onClick={() =>
                                                                setMinimizeData(
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                                <div className="top-next-btn">
                                                    <Button
                                                        size={"small"}
                                                        iconRight={
                                                            <Icon
                                                                colorClass={
                                                                    "gray-50-svg"
                                                                }
                                                                value={
                                                                    test.language ==
                                                                    "ar"
                                                                        ? "back-arrow"
                                                                        : "forward-arrow"
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            test.language ==
                                                            "en"
                                                                ? "Next"
                                                                : "التالي"
                                                        }
                                                        microLoading={
                                                            formLoading
                                                        }
                                                        state={
                                                            cardSorting.is_required_categories ===
                                                                1 &&
                                                            Object.entries(
                                                                columns
                                                            )[0][1].items
                                                                .length > 0
                                                                ? "disabled"
                                                                : cardSorting.is_required_categories ===
                                                                      0 &&
                                                                  Object.entries(
                                                                      columns
                                                                  )[0][1].items
                                                                      .length ===
                                                                      cardData.length
                                                                ? "disabled"
                                                                : !isEnableNextButton
                                                                ? "disabled"
                                                                : "active"
                                                        }
                                                        onClick={
                                                            saveCardSorting
                                                        }
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        {renderedCategoryHtml()}

                                        <div
                                            className={`button-wrap d-flex justify-content-center mt-40 card-sorting-test-buttons ${
                                                isTyping
                                                    ? "keyboard-active-button"
                                                    : ""
                                            } ${
                                                isDragingStartItem
                                                    ? "hide-next-btn-for-mobile-when-dragging"
                                                    : ""
                                            }`}
                                        >
                                            <Button
                                                size={"large"}
                                                iconRight={
                                                    <Icon
                                                        colorClass={
                                                            "gray-50-svg"
                                                        }
                                                        value={
                                                            test.language ==
                                                            "ar"
                                                                ? "back-arrow"
                                                                : "forward-arrow"
                                                        }
                                                    />
                                                }
                                                label={
                                                    test.language == "en"
                                                        ? "Next"
                                                        : "التالي"
                                                }
                                                microLoading={formLoading}
                                                state={
                                                    cardSorting.is_required_categories ===
                                                        1 &&
                                                    Object.entries(
                                                        columns
                                                    )[0][1].items.length > 0
                                                        ? "disabled"
                                                        : cardSorting.is_required_categories ===
                                                              0 &&
                                                          Object.entries(
                                                              columns
                                                          )[0][1].items
                                                              .length ===
                                                              cardData.length
                                                        ? "disabled"
                                                        : !isEnableNextButton
                                                        ? "disabled"
                                                        : "active"
                                                }
                                                onClick={saveCardSorting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DragDropContext>
                    </div>

                    <ConfirmationModal
                        confirm_message="Are you sure you want to delete this category? This will remove all the cards that you've added."
                        confirm_btn_title="YES"
                        confirm_title="Delete Category"
                        confirm_title_class="text-danger"
                        openModal={deleteCategoryModal.open}
                        close={() => {
                            setDeleteCategoryModal({
                                ...deleteCategoryModal,
                                open: false,
                            });
                            setIsDeleteCategoryLoading(false);
                        }}
                        confirm={() => {
                            setDeleteCategoryModal({
                                ...deleteCategoryModal,
                                open: false,
                            });
                            confirmDeleteCategory(
                                deleteCategoryModal.id,
                                deleteCategoryModal.editCategoryid,
                                deleteCategoryModal.unique_id
                            );
                        }}
                    />
                </>
                }
                </>
            )}
        </>
    );
}