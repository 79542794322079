import React from "react";
import { Icon } from "../Icon/Icon";
export const RatingScaleIcon = ({ type, scale, value, selected, onClick }) => {
    const renderStarIcons = () => {
        let icons = [];
        for (let i = 0; i < value; i++) {
            icons.push(<Icon value={"star-gold"} size={"xl-large"} />);
        }
        return icons;
    }
    return (
        <div className="rating-scale-icon">
            {type == "emoticons" &&
                <div className={`emoticon-icon ${selected ? "selected-icon" : ""}`} onClick={onClick}>

                    <Icon value={"emoticon_" + scale + "_" + value} size={"xl-large"} />

                </div>
            }
            {type == "numbers" &&
                <div className={`number-icon ${selected ? "selected-icon" : ""}`} onClick={onClick}>
                    {value}
                </div>
            }

            {/* used in results */}
            {type == "stars" &&
                <div className={`star-icon ${selected ? "selected-icon" : ""}`} onClick={onClick}>
                    {renderStarIcons()}
                </div>
            }

            {/* used in take test */}
            {type == "star" &&
                <div className={`star-icon ${selected ? "gold-star" : "grey-star"}`} onClick={onClick}>
                    {selected?
                    <Icon value={"star-gold"} size={"xl-large"} />
                    :
                    <Icon value={"star-grey"} size={"xl-large"} />
                    }
                </div>
            }
        </div>
    );
};