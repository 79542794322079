export const stripeCountries = [
    {
        "value":"AE",
        "label":"United Arab Emirates"
    },
    {
        "value":"SA",
        "label":"Saudi Arabia"
    },
    {
        "value":"OM",
        "label":"Oman"
    },

];
