export const ibans = [
    {
        "code":"AL",
        "length":28
    },
    {
        "code":"AD",
        "length":24
    },
    {
        "code":"AT",
        "length":20
    },
    {
        "code":"AZ",
        "length":28
    },
    {
        "code":"BH",
        "length":22
    },
    {
        "code":"BY",
        "length":28
    },
    {
        "code":"BE",
        "length":16
    },
    {
        "code":"BA",
        "length":20
    },
    {
        "code":"BR",
        "length":29
    },
    {
        "code":"BG",
        "length":22
    },
    {
        "code":"BI",
        "length":27
    },
    {
        "code":"CR",
        "length":22
    },
    {
        "code":"HR",
        "length":21
    },
    {
        "code":"CY",
        "length":28
    },
    {
        "code":"CZ",
        "length":24
    },
    {
        "code":"DK",
        "length":18
    },
    {
        "code":"DJ",
        "length":27
    },
    {
        "code":"DO",
        "length":28
    },
    {
        "code":"EG",
        "length":29
    },
    {
        "code":"SV",
        "length":28
    },
    {
        "code":"EE",
        "length":20
    },
    {
        "code":"FO",
        "length":18
    },
    {
        "code":"FI",
        "length":18
    },
    {
        "code":"FR",
        "length":27
    },
    {
        "code":"GE",
        "length":22
    },
    {
        "code":"DE",
        "length":22
    },
    {
        "code":"GI",
        "length":23
    },
    {
        "code":"GR",
        "length":27
    },
    {
        "code":"GL",
        "length":18
    },
    {
        "code":"GT",
        "length":28
    },
    {
        "code":"VA",
        "length":22
    },
    {
        "code":"HU",
        "length":28
    },
    {
        "code":"IS",
        "length":26
    },
    {
        "code":"IQ",
        "length":23
    },
    {
        "code":"IE",
        "length":22
    },
    {
        "code":"IL",
        "length":23
    },
    {
        "code":"IT",
        "length":27
    },
    {
        "code":"JO",
        "length":30
    },
    {
        "code":"KZ",
        "length":20
    },
    {
        "code":"XK",
        "length":20
    },
    {
        "code":"KW",
        "length":30
    },
    {
        "code":"LV",
        "length":21
    },
    {
        "code":"LB",
        "length":28
    },
    {
        "code":"LY",
        "length":25
    },
    {
        "code":"LI",
        "length":21
    },
    {
        "code":"LT",
        "length":20
    },
    {
        "code":"LU",
        "length":20
    },
    {
        "code":"MT",
        "length":31
    },
    {
        "code":"MR",
        "length":27
    },
    {
        "code":"MU",
        "length":30
    },
    {
        "code":"MD",
        "length":24
    },
    {
        "code":"MC",
        "length":27
    },
    {
        "code":"ME",
        "length":22
    },
    {
        "code":"NL",
        "length":18
    },
    {
        "code":"MK",
        "length":19
    },
    {
        "code":"NO",
        "length":15
    },
    {
        "code":"PK",
        "length":24
    },
    {
        "code":"PS",
        "length":29
    },
    {
        "code":"PL",
        "length":28
    },
    {
        "code":"PT",
        "length":25
    },
    {
        "code":"QA",
        "length":29
    },
    {
        "code":"RO",
        "length":24
    },
    {
        "code":"RU",
        "length":33
    },
    {
        "code":"LC",
        "length":32
    },
    {
        "code":"SM",
        "length":27
    },
    {
        "code":"ST",
        "length":25
    },
    {
        "code":"SA",
        "length":24
    },
    {
        "code":"RS",
        "length":22
    },
    {
        "code":"SC",
        "length":31
    },
    {
        "code":"SK",
        "length":24
    },
    {
        "code":"SI",
        "length":19
    },
    {
        "code":"SO",
        "length":23
    },
    {
        "code":"ES",
        "length":24
    },
    {
        "code":"SD",
        "length":18
    },
    {
        "code":"SE",
        "length":24
    },
    {
        "code":"CH",
        "length":21
    },
    {
        "code":"TL",
        "length":23
    },
    {
        "code":"TN",
        "length":24
    },
    {
        "code":"TR",
        "length":26
    },
    {
        "code":"UA",
        "length":29
    },
    {
        "code":"AE",
        "length":23
    },
    {
        "code":"GB",
        "length":22
    },
    {
        "code":"VG",
        "length":24
    },
    {
        "code":"DZ",
        "length":26
    },
    {
        "code":"AO",
        "length":25
    },
    {
        "code":"BJ",
        "length":28
    },
    {
        "code":"BF",
        "length":28
    },
    {
        "code":"CM",
        "length":27
    },
    {
        "code":"CV",
        "length":25
    },
    {
        "code":"CF",
        "length":27
    },
    {
        "code":"TD",
        "length":27
    },
    {
        "code":"KM",
        "length":27
    },
    {
        "code":"CG",
        "length":27
    },
    {
        "code":"GQ",
        "length":27
    },
    {
        "code":"GA",
        "length":27
    },
    {
        "code":"GW",
        "length":28
    },
    {
        "code":"HN",
        "length":28
    },
    {
        "code":"IR",
        "length":26
    },
    {
        "code":"CI",
        "length":28
    },
    {
        "code":"MG",
        "length":27
    },
    {
        "code":"ML",
        "length":28
    },
    {
        "code":"MN",
        "length":20
    },
    {
        "code":"MA",
        "length":28
    },
    {
        "code":"MZ",
        "length":25
    },
    {
        "code":"NI",
        "length":32
    },
    {
        "code":"NE",
        "length":28
    },
    {
        "code":"SN",
        "length":28
    },
    {
        "code":"TG",
        "length":28
    }


];