import React, { useCallback } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestCardSortingRCategoryesultData,
    getTestCardSortingCardsResultData,
    getTestCardSortingMatrixResultData,
    getTestCardSortingSimilarityMatrixResultData
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import { getCurrentPlansService } from "../../../services/plans";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { atom, useRecoilState } from 'recoil';

import AnyChart from 'anychart-react'
import { LoadingIcon } from "../../loader/loadingIcon";
import { Dropdown } from "react-bootstrap";
import exportAsImage from "../../../lib/exportAsImage";
import {downloadBlob, showToast} from "../../../lib/helpers";
import { Text } from "../../themes/userq/Text/Text";
import { Tooltip } from "../../themes/userq/Tooltip/Tooltip";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Footer } from '../../themes/userq/Footer/Footer';
import { Helmet } from 'react-helmet';

export default function TestResultCardSortingChart({isSharedLink}) {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const ref = useRef();
    const tipref = useRef();

    const exportSimilarityRef = useRef();
    const exportResultGridSvgRef = useRef();


    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);

    const [categoryArray, setCategoryArray] = useState([]);
    const [cardArray, setCardArray] = useState([]);
    const [matrixArray, setMatrixArray] = useState([]);
    const [matrixCategoryNameArray, setMatrixCategoryNameArray] = useState([]);
    const [similarityMatrixArray, setSimilarityMatrixArray] = useState([]);
    const [metaData, setMetaData] = useState({ active: 'matrix', 'sorting_type': 'open', cardSort: "", categorySort: "" });
    const [expandData, setExpandData] = useState(false);
    const [expandSimilarityData, setExpandSimilarityData] = useState(false);

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false,
        show:false
    });

    const handlePointerMove = (y,x,heatPercentage) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 260;


                var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 800;




                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
                let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if(event.clientX + tool_width/2 > window_width){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

                }
                if(event.clientX - tool_width/2 < 0){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
                }

                showTooltip({
                    tooltipLeft: containerX,
                    tooltipTop: containerY,
                    tooltipData: <><p><b>{y}</b> in <b>{x}</b> </p> {heatPercentage}% of participants</>,
                    open:true,
                    show: tooltip.open && tipref && tipref.current ? true: false,
                    tipPostion: tipPosition
                });
            });
    }

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
		let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getResearcherTestResultData({ test_id: test_id, guest_result_token: guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                showToast(response.message, "error");
            }
        })

    }

    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';
            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';
            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token: admin_result_token }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    if(response.workspace_project_team_member){
                        if(response.workspace_project_team_member.status!='active'){
                            navigate("/wsp/"+response.workspace_project_team_member.workspace_id+"/p/"+response.workspace_project_team_member.workspace_project_id+"/project-no-access")
                        }
                    }
                    // check if guest user not validate there password
                    if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

                        navigate(`/v/results/${test_id}/password`);
                    }

                    setTest(response.test);
                    getCardSortingMatrix();


                } else {

                    if(response.message == "Project not found.") {
                        navigate("/404");
                    } else if(response.message == "Test Record Not found") {
                        navigate("/404");
                    } else {

                        if(location.pathname.substr(1, 1) === 'v'){

                            if(response.test_shared_results && !response.test_password_verify){
                                return navigate(`/v/results/${test_id}/password`);
                            }
                            else if(!response.test_shared_results){
                                return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        if(location.pathname.substr(1, 1) === 'a'){

                            if(!response.test_results_available){
                                return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        showToast(response.message, "error");
                    }
                }
            });
        } else {
            getCardSortingMatrix();
        }
    };




    const getCardSortingMatrix = () => {
        let token = ReactSession.get("token");

        setDataLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            admin_result_token = ReactSession.get("admin_result_token");
        }


        getTestCardSortingMatrixResultData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setDataLoading(false);

            if (response.success) {

                // setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                //setCategoryArray(response.category);

                const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                // response.matrix.forEach((items)=>{
                //
                //     var fill = '';
                //     if(response.matrixRangeColor.minMatrixData === items.heat){
                //         fill = '#ECE8F7';
                //     }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                //         fill = '#EED9EF';
                //     }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                //         fill = '#F2BCDE';
                //     }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                //         fill = '#F7A0CE';
                //     }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                //         fill = '#FB83BD';
                //     }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                //         fill = '#FF66AD';
                //     }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                //         fill = '#96FFFC';
                //     }else{
                //         fill = '#FF66AD';
                //     }
                //
                //
                //     //console.log(response.MatrixDataArrayTotal)
                //
                //    // var heatpercentage =  Math.round(((items.heat)*100)/response.MatrixDataArrayTotal)
                //
                //     var totalheat = 0;
                //     response.matrix.forEach((entry) => {
                //         if (items.y == entry.y) {
                //             totalheat = totalheat + entry.heat
                //         }
                //     })
                //
                //     var heatpercentage = 0;
                //
                //     if(response.totalCountTestResult) {
                //         var heatpercentage = Math.round(((items.heat) * 100) / response.totalCountTestResult);
                //     }
                //
                //    // var folderIcon = 'http://localhost:3000/images/back-arrow.svg';
                //
                //     if((items.isCustom) == '0'){
                //         var folderIcon = '#2F2E55';
                //     }else{
                //         var folderIcon = '#FF66AD';
                //     }
                //
                //     var folderCardIcon = '#2F2E55';
                //
                //     //console.log(items.isCustom)
                //
                //
                //     // matrixData.push({
                //     //     x: items.x.toString().length>12?(test.language=="ar"?"</b>..."+items.x.toString().substring(0, 11)+'<b class="asdas" style="color: '+folderIcon+'">':'<b class="asdas" style="color: '+folderIcon+'">'+items.x.toString().substring(0, 11) + "..</b>"):'<b class="asdas" style="color: '+folderIcon+'">'+items.x+'</b>',
                //     //     // y: items.y,
                //     //     y: items.y.toString().length>6?(test.language=="ar"?"</b>..."+items.y.toString().substring(0, 5)+'<b>':'<b>'+items.y.toString().substring(0, 5) + "...</b>"):'<b>'+items.y+'</b>',
                //     //
                //     //     //x:'<b class="asdas" style="color: '+folderIcon+'">'+items.x+'</b>',
                //     //     //y:'<b style="color: '+folderCardIcon+'">'+items.y+'</b>',
                //     //     heat:items.heat,
                //     //     fill:fill,
                //     //     participants: items.y+" in "+items.x+" "+heatpercentage+"% of participants",
                //     //     //participants: '<b>'+items.y+"</b> in <b>"+items.x+"</b><br/> "+heatpercentage+"% of participants",
                //     // })
                // })

                // console.log(response.matrix)

                setMatrixArray(response.matrix);
                setMatrixCategoryNameArray(response.categoryNameArray);

            } else {
                showToast(response.message, "error");
            }
        });
    };





    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - card sorting result grid";
        getTest();


    }, []);







    const downloadSVG = useCallback(() => {
        //const svg = document.getElementsByClassName('anychart-ui-support');
        //anychart-credits

        // document.getElementsByClassName('anychart-credits').remove();
        //document.querySelector("div").remove();

        //const svg = document.getElementById('Aerachart1').getElementsByTagName('div')[0].innerHTML;
        //const svg = document.getElementById('Aerachart1').getElementsByTagName('div')[0].innerHTML;
        // const svg = exportResultGridRef.current.getElementsByTagName('div')[0].innerHTML;

        // var svg = '<svg xmlns="http://www.w3.org/2000/svg" border="0" data-ac-wrapper-id="237" width="100%" height="100%" class="anychart-ui-support" ac-id="ac_stage_e09343_18" role="presentation" style="display: block;">';
        // //svg += document.getElementById('Aerachart1').getElementsByTagName('div')[0].children[0].innerHTML;
        // svg += svg += exportSimilarityRef.current.getElementsByTagName('div')[0].innerHTML;
        // svg += '</svg>';

        var htmlContent = exportResultGridSvgRef.current.getElementsByTagName('div')[0].innerHTML;
        htmlContent = htmlContent.replace(/(<(?:div)[^<]*) (class="card-sorting-matrix-y-axis") (style="[^"]*")([^>]*>)/gi,'<div class="card-sorting-matrix-y-axis" style="width: 160px;padding-right: 10px;font-weight: 500;font-size: 14px;line-height: 25px;color: rgb(0, 0, 0)">');


        var svg = '<svg><foreignObject><body xmlns="http://www.w3.org/1999/xhtml">';
        svg += htmlContent;
        svg += '</body></foreignObject></svg>';

        //abc = abc.replace(/(<(?:table)[^<]*)(style="[^"]*")([^>]*>)/gi,'<table style="font-size:medium;">');
        //abc.querySelector('#demo-id')
        //console.log(htmlContent);
        //return false;
        //console.log(document.getElementsByClassName('anychart-ui-support')[0]);
        //console.log(document.getElementById('Aerachart1').getElementsByTagName('div')[0].innerHTML);
        const blob = new Blob([svg], { type: "image/svg+xml" });

        downloadBlob(blob, `resultgrid.svg`);
    }, []);

    const downloadPng = () => {

        var htmlContent = exportSimilarityRef.current;
        //var htmlContent = exportResultGridSvgRef.current.getElementsByTagName('div')[0].innerHTML;

        //htmlContent = htmlContent.replace(/(<(?:div)[^<]*) (class="card-sorting-matrix-y-axis") (style="[^"]*")([^>]*>)/gi,'<div class="card-sorting-matrix-y-axis" style="width: 160px;padding-right: 10px;font-weight: 500;font-size: 14px;line-height: 25px;color: rgb(0, 0, 0)">');
       // console.log(htmlContent);
        //const blob = new Blob([htmlContent], { type: "image/png" });
        exportAsImage(htmlContent, "result-grid")
    };


    // const showTooltip = (y,x,heatPercentage)=>{
    //
    //     return <>{y} in {x} <br/> {heatPercentage}% of participants</>
    // }

    return (
        <>
            <Helmet>
                <title>Results grid - Analyse card sorting  | UserQ</title>
            </Helmet>
            {isLoading &&
                <div className="page-loader full-page-loader mh-100vh">
                    <LoadingIcon />
                </div>
            }
            {!isLoading && test &&
                <div className="chart-new-tab-data matrix-gird-wrapper"> 



                    <div className={`container matrix-full-view hide-for-mobile`}>
                        <div className="matricx-chart-data-hold">



                            {sectionLoading &&
                                {/* <div className={'page-loader accountloading-box result-sections-loader'}>
                                    <LoadingIcon />
                                </div> */}
                            }
                            {!sectionLoading && 
                                <div className="card-result-matrix-grid">



                                    <div className='matricx-chart-top-head d-flex justify-content-between'>
                                        <div className='matricx-top-left'>
                                            
                                            <span className='subtitle-1 medium-font'>Results Grid</span>
                                            <p className='body-text body-text-1 medium-font'>Results grid shows the number of times each card was sorted into your pre-set categories in a closed card sort</p>


                                          


                                        </div>

                                         <div className='matricx-top-right'>

                                        <span className="small-dropdown hamburger-menu">
                                            <Dropdown class={"dropdown"}>
                                                <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle secondary-btn secondary-medium  btn-with-icon btn-with-icon-left">
                                                    <Icon size={'small'} value={'download'} />
                                                    Download
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='hamburger-items'>
                                                    <Dropdown.Item href="#"
                                                        onClick={downloadPng}>
                                                        Download PNG

                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => downloadSVG(exportResultGridSvgRef.current)}>
                                                        Download  SVG

                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                    </div>


                                        
                                    </div>

                                    <div className="matricx-table-wrap card-grid-data-hold">



                                        {dataLoading &&
                                            <div className={'page-loader accountloading-box result-sections-loader'}> 
                                                <LoadingIcon />
                                            </div>
                                        }

                                        {!dataLoading &&
                                            <div className={`bd-que-ans-table-data-inner position-relative ${(test.language !== 'en') ? 'cairo-font' : ''}`}  ref={exportResultGridSvgRef}>
                                                  {tooltip.open ? (
                                                        <>
                                                            <Tooltip
                                                                ref={tipref}
                                                                type={tooltip.tipPosition}
                                                                key={Math.random()} // needed for bounds to update correctly
                                                                style={{ zIndex:4, display: tooltip.show? "block" : 'block', whiteSpace:"normal", width:"260px", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                                                //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"normal",minHeight:'15px', margin:0,left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                                                data={tooltip.tooltipData}
                                                                className="tooltipChart"

                                                            />
                                                        </>
                                                    ): (
                                                        <></>
                                                    )}

                                                <div className="matrix-grid-table-responsive" ref={ref} style={{position:'relative'}}>
                                                   
                                                    <table  style={{padding:'5px'}}>
                                                        <thead>
                                                            <tr>
                                                                <th className="likert-chart-corner card-sorting-matrix"></th>
                                                                {matrixCategoryNameArray.map((items, i) => (
                                                                    <th className="likert-options card-sorting-matrix-x-axis">
                                                                        <div className="card-sorting-matrix-category-td"
                                                                             style={{
                                                                                 fontWeight: 300,
                                                                                 fontSize: '10px',
                                                                                 lineHeight: '14px',
                                                                                 color: '#000000',
                                                                                 maxWidth: '150px',
                                                                                 display: 'flex',
                                                                                 alignItems: 'center'
                                                                             }}>
                                                                            {items.is_custom === 0 &&
                                                                                <>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none"
                                                                                    style={{
                                                                                        marginRight:'5px',
                                                                                        display: 'flex'
                                                                                    }}
                                                                                    >
                                                                                        <path d="M1.25 9.75V9C1.25 8.40326 1.48705 7.83097 1.90901 7.40901C2.33097 6.98705 2.90326 6.75 3.5 6.75H18.5C19.0967 6.75 19.669 6.98705 20.091 7.40901C20.5129 7.83097 20.75 8.40326 20.75 9V9.75M12.06 3.31L9.94 1.19C9.80073 1.05055 9.63534 0.939918 9.45328 0.864421C9.27123 0.788923 9.07609 0.750042 8.879 0.75H3.5C2.90326 0.75 2.33097 0.987053 1.90901 1.40901C1.48705 1.83097 1.25 2.40326 1.25 3V15C1.25 15.5967 1.48705 16.169 1.90901 16.591C2.33097 17.0129 2.90326 17.25 3.5 17.25H18.5C19.0967 17.25 19.669 17.0129 20.091 16.591C20.5129 16.169 20.75 15.5967 20.75 15V6C20.75 5.40326 20.5129 4.83097 20.091 4.40901C19.669 3.98705 19.0967 3.75 18.5 3.75H13.121C12.7233 3.74965 12.342 3.59138 12.061 3.31H12.06Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg>
                                                                                </>
                                                                            }
                                                                            {items.is_custom === 1 &&
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none"
                                                                                     style={{
                                                                                         marginRight:'5px',
                                                                                         display: 'flex'
                                                                                     }}
                                                                                >
                                                                                    <path d="M11 7.5V13.5M14 10.5H8M12.06 3.31L9.94 1.19C9.80073 1.05055 9.63534 0.939918 9.45328 0.864421C9.27123 0.788923 9.07609 0.750042 8.879 0.75H3.5C2.90326 0.75 2.33097 0.987053 1.90901 1.40901C1.48705 1.83097 1.25 2.40326 1.25 3V15C1.25 15.5967 1.48705 16.169 1.90901 16.591C2.33097 17.0129 2.90326 17.25 3.5 17.25H18.5C19.0967 17.25 19.669 17.0129 20.091 16.591C20.5129 16.169 20.75 15.5967 20.75 15V6C20.75 5.40326 20.5129 4.83097 20.091 4.40901C19.669 3.98705 19.0967 3.75 18.5 3.75H13.121C12.7233 3.74965 12.342 3.59138 12.061 3.31H12.06Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg>
                                                                            }



                                                                            <span className={'matrix-cat-name'} style={{
                                                                                marginLeft:'2px',
                                                                                width:'81px'
                                                                            }}>{items.name}</span>
                                                                        </div>
                                                                    </th>
                                                                ))}

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {matrixArray.map((items, i) => (
                                                                <tr>
                                                                    {items.map((item, index) => (
                                                                        <>
                                                                            {index === 0 &&
                                                                                <td className="matrix-y-axis-y-td">
                                                                                    <div className='card-sorting-matrix-y-axis'
                                                                                         style={{
                                                                                             width:'120px',
                                                                                             paddingRight: '10px',
                                                                                             fontWeight: 500,
                                                                                             fontSize: '14px',
                                                                                             lineHeight: '25px',
                                                                                             color: '#000',
                                                                                             whiteSpace: 'nowrap',
                                                                                             overflow: 'hidden',
                                                                                             textOverflow: 'ellipsis'
                                                                                         }}
                                                                                    >
                                                                                        {item.y}
                                                                                    </div>
                                                                                </td>
                                                                            }

                                                                            <td>
                                                                                <div className="card-sorting-matrix-box"
                                                                                     style={{
                                                                                         backgroundColor: item.fill,
                                                                                         height: '40px',
                                                                                         display: 'flex',
                                                                                         alignItems: 'center',
                                                                                         justifyContent: 'center',
                                                                                         padding: '10px',
                                                                                         position: 'relative',
                                                                                         borderRadius: '2px',
                                                                                         width:'114px'
                                                                                     }}
                                                                                     onPointerMove={handlePointerMove(item.y,item.x,item.heatPercentage) }
                                                                                     onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
                                                                                >

                                                                                    <span className='card-matrix-count' style={{ color: item.labelColorCode }}>
                                                                                        {item.heat}
                                                                                    </span>
                                                                                    {/*<Tooltip*/}
                                                                                    {/*    data={showTooltip(item.y,item.x,item.heatPercentage)}*/}
                                                                                    {/*    type={"bottom"}*/}
                                                                                    {/*/>*/}
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </div>

                                               <div className='grid-png-download-table' style={{padding:'5px', opacity:'0', position:'fixed'}}>
                                                <table ref={exportSimilarityRef} style={{padding:'5px'}}>
                                                        <thead> 
                                                            <tr>
                                                                <th className="likert-chart-corner card-sorting-matrix"></th>
                                                                {matrixCategoryNameArray.map((items, i) => (
                                                                    <th className="likert-options card-sorting-matrix-x-axis">
                                                                        <div className="card-sorting-matrix-category-td"
                                                                             style={{
                                                                                 fontWeight: 300,
                                                                                 fontSize: '10px',
                                                                                 lineHeight: '14px',
                                                                                 color: '#000000',
                                                                                 maxWidth: '150px',
                                                                                 display: 'flex',
                                                                                 alignItems: 'center'
                                                                             }}>
                                                                            {items.is_custom === 0 &&
                                                                                <>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none"
                                                                                    style={{
                                                                                        marginRight:'5px',
                                                                                        display: 'flex'
                                                                                    }}
                                                                                    >
                                                                                        <path d="M1.25 9.75V9C1.25 8.40326 1.48705 7.83097 1.90901 7.40901C2.33097 6.98705 2.90326 6.75 3.5 6.75H18.5C19.0967 6.75 19.669 6.98705 20.091 7.40901C20.5129 7.83097 20.75 8.40326 20.75 9V9.75M12.06 3.31L9.94 1.19C9.80073 1.05055 9.63534 0.939918 9.45328 0.864421C9.27123 0.788923 9.07609 0.750042 8.879 0.75H3.5C2.90326 0.75 2.33097 0.987053 1.90901 1.40901C1.48705 1.83097 1.25 2.40326 1.25 3V15C1.25 15.5967 1.48705 16.169 1.90901 16.591C2.33097 17.0129 2.90326 17.25 3.5 17.25H18.5C19.0967 17.25 19.669 17.0129 20.091 16.591C20.5129 16.169 20.75 15.5967 20.75 15V6C20.75 5.40326 20.5129 4.83097 20.091 4.40901C19.669 3.98705 19.0967 3.75 18.5 3.75H13.121C12.7233 3.74965 12.342 3.59138 12.061 3.31H12.06Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg>
                                                                                </>
                                                                            }
                                                                            {items.is_custom === 1 &&
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none"
                                                                                     style={{
                                                                                         marginRight:'5px',
                                                                                         display: 'flex'
                                                                                     }}
                                                                                >
                                                                                    <path d="M11 7.5V13.5M14 10.5H8M12.06 3.31L9.94 1.19C9.80073 1.05055 9.63534 0.939918 9.45328 0.864421C9.27123 0.788923 9.07609 0.750042 8.879 0.75H3.5C2.90326 0.75 2.33097 0.987053 1.90901 1.40901C1.48705 1.83097 1.25 2.40326 1.25 3V15C1.25 15.5967 1.48705 16.169 1.90901 16.591C2.33097 17.0129 2.90326 17.25 3.5 17.25H18.5C19.0967 17.25 19.669 17.0129 20.091 16.591C20.5129 16.169 20.75 15.5967 20.75 15V6C20.75 5.40326 20.5129 4.83097 20.091 4.40901C19.669 3.98705 19.0967 3.75 18.5 3.75H13.121C12.7233 3.74965 12.342 3.59138 12.061 3.31H12.06Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg>
                                                                            }



                                                                            <span className={'matrix-cat-name'} style={{
                                                                                marginLeft:'2px',
                                                                                width:'81px'
                                                                            }}>{items.name}</span>
                                                                        </div>
                                                                    </th>
                                                                ))}

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {matrixArray.map((items, i) => (
                                                                <tr>
                                                                    {items.map((item, index) => (
                                                                        <>
                                                                            {index === 0 &&
                                                                                <td className="matrix-y-axis-y-td">
                                                                                    <div className='card-sorting-matrix-y-axis'
                                                                                         style={{
                                                                                             width:'120px',
                                                                                             paddingRight: '10px',
                                                                                             fontWeight: 500,
                                                                                             fontSize: '14px',
                                                                                             lineHeight: '25px',
                                                                                             color: '#000',
                                                                                             whiteSpace: 'nowrap',
                                                                                             overflow: 'hidden',
                                                                                             textOverflow: 'ellipsis'
                                                                                         }}
                                                                                    >
                                                                                        {item.y}
                                                                                    </div>
                                                                                </td>
                                                                            }

                                                                            <td>
                                                                                <div className="card-sorting-matrix-box"
                                                                                     style={{
                                                                                         backgroundColor: item.fill,
                                                                                         height: '40px',
                                                                                         display: 'flex',
                                                                                         alignItems: 'center',
                                                                                         justifyContent: 'center',
                                                                                         padding: '10px',
                                                                                         position: 'relative',
                                                                                         borderRadius: '2px',
                                                                                         width:'114px'
                                                                                     }}
                                                                                     onPointerMove={handlePointerMove(item.y,item.x,item.heatPercentage) }
                                                                                     onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
                                                                                >

                                                                                    <span className='card-matrix-count' style={{ color: item.labelColorCode }}>
                                                                                        {item.heat}
                                                                                    </span>
                                                                                    {/*<Tooltip*/}
                                                                                    {/*    data={showTooltip(item.y,item.x,item.heatPercentage)}*/}
                                                                                    {/*    type={"bottom"}*/}
                                                                                    {/*/>*/}
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </div>

                                            </div>
                                        }

                                    </div>



                                </div>
                            }




                        </div>
                    </div>

                    <div className="participants-result-responsive-section hide-for-desktop">
                        <div className="mobile-charts-not-available">
                            <div className="no-data-icon">
                                <Icon colorClass={"gray-900-svg"} value={"attention"} size={"large"} />
                            </div>
                            <p>
                            This page is not available on mobile. Please log in from a desktop device to access it.
                            </p>
                        </div>

                    </div>

                    <Footer />

                </div>
            }
        </>
    )
}