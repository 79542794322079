import React from "react";
import PropTypes from 'prop-types';
import { Text } from "../Text/Text";
 
export const Tooltip = React.forwardRef(({data,type, style,className, richText},ref) => {
    return (
        <div className={`${className} tooltip-wrapper ${type}-tooltip `} style={style} ref={ref}>
            {richText
            ?
            data
            :
            <Text type="body-text-2" fontWeight="normal-font" id="tooltip" children={data}/> 
        }
            
        </div>
    );
});

Tooltip.propTypes = {
    data: PropTypes.any,
    type: PropTypes.string,

};
Tooltip.defaultProps = {
    data: "",
    type: "bottom",

};