import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import Select from "react-select";
import {
    ClearIndicator,
    ClearIndicatorV2,
    DropdownIndicator,
    DropdownIndicatorV2,
    DropdownIndicatorV3
} from "../../../../lib/helpers";
import { hasFlag } from "country-flag-icons";
import { countries } from "../../../../data/countries";
import { countries as bank_countries } from "../../../../data/bank_countries";
import { nationalities_other } from "../../../../data/nationalities_other";

import {components} from "react-select";

import Flags from "country-flag-icons/react/3x2";
import { Icon } from "../Icon/Icon";

let search_value = "";

const ValueContainer = ({children, ...props})=>{
    const { getValue, hasValue } = props;

    
    
    const nbValues = getValue().length;

    var placeholder = false;

    var values = props.getValue()

    let value = '';
    if(values.length ==1){
        if(values[0].value==''){
            placeholder = true;
            
        } else {
            value = values[0].value;
        }
    }
    
    var exist = false;

    let Component = <></>;

    let title = '';


    countries.forEach(function(country){
        
        if(country.value==value){
            exist = hasFlag(country["code"])
            Component = Flags[country["code"]];
            title = country["code"];
        }
    })
    countries.forEach(function(country){
        
        if(country.code==value){
            exist = hasFlag(country["code"])
            Component = Flags[country["code"]];
            title = country["code"];
        }
    })
    nationalities_other.forEach(function(country){
        if(country.value==value){
            exist = hasFlag(country["code"])
            Component = Flags[country["code"]];
            title = country["code"];
        }
    })
    
    if (!hasValue) {
        return (
        <components.ValueContainer {...props}>
            {children}
        </components.ValueContainer>
        );
    }
    return (
        <components.ValueContainer {...props}>
            <div className="flag-value-container">
            {exist && !search_value &&
            <Component
                title={title}
                style={{ display: "inline-block", height: "auto", width: "1em" }}
            />}
            {children}
            </div>
        </components.ValueContainer>
    );
  
  };
export const AutoCompleteSingleSelect = ({ id, error, type, isMulti, isSearchable, isClearable, onChange, dropDownRef, value, label,options, inactive, active, open, menuPlacement, isSanctionedCountry,bankCountries }) => {

    const wrapperRef = useRef(null);
    
    let selected_value = null;

    const [input, setInput] = useState("");

    const [select_active, setSelectActive] = useState(false);

    const [menuIsOpen, setMenuOpen] = useState(false);

    if(isMulti){
        selected_value = [];
        value.forEach(function(item){
            selected_value.push({"label":item,"value":item});
        })
    } else {
        if(value){
            selected_value ={
                label: value,
                value: value
            };
        } else {
            selected_value ={
                label: '',
                value: ''
            };
        } 
    }
    
   
    var options_updated = [];
    if (type == "nationality") {
        options_updated = nationalities_other
            .map((country) => {
                const exist = hasFlag(country["code"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["code"]];

                if(selected_value.value === country.value){
                    
                    return {
                        value: country["value"],
                        label: (
                            <><span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };

                    
                } else{
                    
                    return {
                        value: country["value"],
                        label: (
                            <><span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                    
                }
                
            })
            .filter(Boolean);

    } else if(type=="country") {
        
        var countries_local = bankCountries ? bank_countries : countries;
        options_updated = countries_local
            .map((country) => {
                const exist = hasFlag(country["code"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["code"]];

                if(selected_value.value === country.value){
                    return {
                        value: country["value"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };
                } else {
                    return {
                        value: country["value"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                }
            })
            .filter(Boolean);

    }  else if(type=="billing_country") {
        
        options_updated = options
            .map((country) => {
                const exist = hasFlag(country["value"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["value"]];

                if(selected_value.value === country.value){
                    return {
                        value: country["label"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["value"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["label"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };
                } else {
                    return {
                        value: country["label"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["value"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["label"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                }
            })
            .filter(Boolean);
            // console.log(options_updated)
    } else {
        options_updated = options
            .map((option) => {
                
                if(selected_value.value === option.value){
                    return {
                        value: option["value"],
                        label: (
                            <> 
                                <span>{`${option["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };
                } else {
                    return {
                        value: option["value"],
                        label: (
                            <> 
                                <span>{`${option["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                }
            })
            .filter(Boolean);
    }


    

    
    return (

        <div className={` dropdown-container search-dropdown-component form-group input-field mb-0  ${isSanctionedCountry?"field-warning":""} ${error?"field-error":""} ${((value!='' && value!=null) ||     (search_value!="") ||   (!search_value && menuIsOpen)  )?'focused':''} ${(active==true)?'active':''}`} id={id} ref={wrapperRef}>
        
            <div className={`input-position-relative dropdown-field singleselect-dropdown`}
            
            >{menuIsOpen?
                <Select
                    isClearable={isClearable}
                    isSearchable={type?true:isSearchable}
                    name=""
                    title=""
                    placeholder=""
                    options={options_updated}
                    onChange={(item)=>{search_value=''; setInput("");  onChange(item); }}
                    ref={dropDownRef}
                    isMulti={isMulti}
                    value={selected_value}
                    inputValue={input}
                    onInputChange={(value, action) => {
                        if (action.action === "input-change") { search_value=value; setInput(value); }
                    }}
                    onMenuOpen={()=>{
                        setMenuOpen(true)
                    }}
                    onMenuClose={()=>{
                        search_value="";
                        setInput("")
                        setMenuOpen(false)
                    }}
                    menuPlacement={menuPlacement}
                   // components={!type ? { ValueContainer,ClearIndicator,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator } : { ValueContainer, ClearIndicator:ClearIndicatorV2,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator }}
                    components={!type ? { ClearIndicator:ClearIndicatorV2(input),DropdownIndicator: DropdownIndicatorV2(input)  } : { ValueContainer, ClearIndicator:ClearIndicatorV2(input),DropdownIndicator: DropdownIndicatorV2(input) }}
                    backspaceRemovesValue={true}
                />:
                <Select
                    isClearable={isClearable}
                    isSearchable={type?true:isSearchable}
                    name=""
                    title=""
                    placeholder=""
                    options={options_updated}
                    onChange={(item)=>{ search_value=''; setInput(""); onChange(item); }}
                    ref={dropDownRef}
                    value={selected_value}
                    isMulti={isMulti}
                    inputValue={input}
                    onInputChange={(value, action) => {
                        if (action.action === "input-change") { search_value=value; setInput(value); }
                    }}
                    onMenuOpen={()=>{
                        setMenuOpen(true)
                    }}
                    onMenuClose={()=>{
                        setMenuOpen(false)
                    }}
                    menuPlacement={menuPlacement}
                    components={isClearable?(!type ? { ClearIndicator:ClearIndicatorV2(input),DropdownIndicator: DropdownIndicatorV2(input) } : { ValueContainer, ClearIndicator:ClearIndicatorV2(input),DropdownIndicator: DropdownIndicatorV2(input) }):(!type ? { ValueContainer,ClearIndicator,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator } : { ValueContainer, ClearIndicator:ClearIndicatorV2,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator })}
                    // components={!type ? { ClearIndicator:ClearIndicatorV2(input),DropdownIndicator: DropdownIndicatorV2(input) } : { ValueContainer, ClearIndicator:ClearIndicatorV2(input),DropdownIndicator: DropdownIndicatorV2(input) }}
                    backspaceRemovesValue={true}
                />
                }
                <label className="control-label body-text body-text-2 dropdown-label" htmlFor={id}>
                    {label}
                </label>

            </div>
            {/*(type || isSearchable) &&
            <span className="input-icon"><Icon value="search" size="medium" hover={true}/></span>
            */}
            {error != null && (
                <span className="caption">
                    {error}
                </span>
            )}
        </div>
    )
}
