import {handleApiErrorResponse} from "../lib/helpers";

export function addNewPaymentMethodService(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'payment-methods/add', {
        method: 'POST',
        body:JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getPaymentMethodsService(token){
    return fetch(process.env.REACT_APP_API_END_POINT+'payment-methods/get', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function deletePaymentMethodService(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'payment-methods/remove', {
        method: 'POST',
        body:JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function setDefaultCard(formValues,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'payment-methods/set-default', {
        method: 'POST',
        body:formValues,
        headers:{"Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}