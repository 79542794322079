import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import "./custom-modal.css";


export default function HelperModal({
    open,
    heading,
    body,
    close,
    cssClass,
    btnLoading,
}) {
    const navigate = useNavigate();

    const closeModal = () => {
        if(!btnLoading){
            close();
        }
        
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        if(!btnLoading){
                            closeModal();
                        }
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, btnLoading]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap  confirmation-modal-wrap help-modal-wrap  ${cssClass}`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={"h3"}>{heading}</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>
                    <>{body}</>
                </div>
            </Modal.Body>
        </Modal>
    );
}
