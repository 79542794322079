import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Icon } from '../../Icon/Icon';
import { Text } from '../../Text/Text';
import { Link } from 'react-router-dom';
import { NPS } from '../../NPS/nps';
import { SquareTile } from '../../SquareTile/SquareTile';
import { Guidelines } from '../../Support/guidelines';
import ContactUsForm from '../../../../researcher/support/elements/contact_us_form';
import { RatingScaleIcon } from '../../RatingScaleIcon/RatingScaleIcon';
import { getProfile, getUserRatingApi } from '../../../../../services/user';
import { showError } from '../../../../../lib/helpers';
import { ReactSession } from '../../../../../lib/secure_reactsession';
import ChannelService from '../../Chat/channel_service';

export const SupportPanel = ({showprop, researcher, hideSupportPanel, ...props}) => {

    const [show, setShow] = useState(false); 

    const handleClose = () => {setShow(false); hideSupportPanel(); }
    
    const [section, setSection] = useState("menu");

    const [rate, setRate] = useState(null);

    const [userProfile, setUserProfile] = useState(null);

    const [isLoading, setLoading] = useState(false)

    useEffect(() =>{

        

        ChannelService.loadScript();

        getProfileService();
        
        
        

    },[]);

    useEffect(() =>{
        if(researcher){
            setUserProfile({...userProfile, first_name:researcher.first_name, last_name:researcher.last_name, email:researcher.email, phone_number: researcher.phone_number, company_name:researcher.company_name, job_role:researcher.job_role})
        }
    },[researcher]);

    useEffect(()=>{
        setSection("menu");
        setShow(showprop);
        getUserRating();

        //
       

        if(showprop){
            const user = ReactSession.get("user");
             if(userProfile){
                ChannelService.boot({
                    "pluginKey": "09d21bff-c9f0-41c0-bde0-ce0509a815ed",
                    "memberId": userProfile ? userProfile.user_id : null,
                    customLauncherSelector:"#LiveChatTile",
                    "hideChannelButtonOnBoot": true,
                    "profile": { 
                      "name": userProfile ? userProfile.first_name+" "+ userProfile.last_name : null,
                      email : userProfile ? userProfile.email : null,
                      mobileNumber: userProfile ? userProfile.phone_number : null,
                      "company":  userProfile ? userProfile.company_name : null,
                      "role": userProfile ? userProfile.job_role : null,
                  }});
            } else if(user){
                
                ChannelService.boot({
                    "pluginKey": "09d21bff-c9f0-41c0-bde0-ce0509a815ed",
                    "memberId": user ? user.user_id : null,
                    customLauncherSelector:"#LiveChatTile",
                    "hideChannelButtonOnBoot": true,
                    "profile": { 
                      "name": user ? user.first_name+" "+ user.last_name : null,
                      email : user ? user.email : null,
                      mobileNumber: user ? user.phone_number : null
                  }});
            } else {
                ChannelService.boot({
                    "pluginKey": "09d21bff-c9f0-41c0-bde0-ce0509a815ed",
                });
            }
        }

    },[showprop]);
    const getProfileService = ()=>{

        const token = ReactSession.get("token");

        getProfile(token).then(response => {
            

            if (response.success) {
                setUserProfile(response.data.user);

                /*let profile = response.data.user;

                ChannelService.boot({
                    "pluginKey": "09d21bff-c9f0-41c0-bde0-ce0509a815ed",
                    "memberId": profile ? profile.user_id : null,
                    customLauncherSelector:"#LiveChatTile",
                    "hideChannelButtonOnBoot": true,
                    "profile": { 
                      "name": profile ? profile.first_name+" "+ profile.last_name : null,
                      email : profile ? profile.email : null,
                      mobileNumber: profile ? profile.phone_number : null,
                      "company":  profile ? profile.company : null,
                      "role": profile ? profile.job_role : null,
                }});*/
                
            }
        });
    }
    const getUserRating = () => {
        
        let token = ReactSession.get("token");

        
        setLoading(true);

        getUserRatingApi(token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (response.rating) {
                    setRate(response.rating);
                }

            
            } else {
                showError(response.message);
            }
        });
    };

    const renderSection = ()=>{

        if(section=="menu"){
            return <>
                <ul className='nps-slide-nav-wrap'>
                <li>
                        <div className='d-flex align-items-center justify-content-between nps-link-hold' onClick={()=>{
                            setSection("guidelines");
                            }}>
                            <span className='d-flex nps-nav-left'>
                                <Icon value={"book"} hover={true} /> 
                                <Text type={"body-text-1"} fontWeight={"medium-font"}>Guides</Text>
                            </span>
                            <Icon value={"arrow-right"} hover={true} />
                        </div>
                    </li>

                    <li>
                        <a href={"/r/support"} target='_blank' className='d-flex align-items-center justify-content-between nps-link-hold'>
                        <span className='d-flex nps-nav-left'> 
                            <Icon value={"support"} hover={true}/> 
                            <Text type={"body-text-1"} fontWeight={"medium-font"}>FAQs</Text>
                        </span>
                        <Icon value={"external-link"} hover={true}/>
                    </a>
                    </li>

                    <li>
                        <a href='https://forms.monday.com/forms/d3ed651c9ba8088f339aeb4c5428dc86?r=use1' target="_blank" className='d-flex align-items-center justify-content-between nps-link-hold'>
                            <span className='d-flex nps-nav-left'>
                                <Icon value={"bookmark"} hover={true} /> 
                                <Text type={"body-text-1"} fontWeight={"medium-font"}>Feature request</Text>
                            </span>
                            <Icon value={"external-link"} hover={true}/>
                        </a>
                    </li>

                </ul>

                <div className='squaretile-wrapper d-flex'>  
                    <SquareTile 
                        onClick={()=>{
                        setSection("contact-us");
                    }} 
                    title="Contact us" 
                    icon="pink-email" 
                    size="ex-large"  
                    cssclasses="pink-hover-bg"
                    />
                    <SquareTile onClick={()=>{
                        handleClose();
                    }} id="LiveChatTile" title="Live chat" icon="Live-chat" size="ex-large" tag="Coming soon" cssclasses="purple-hover-bg"/>
                </div>
        
                <div className='share-feedback-btn feedback-shared' onClick={()=>{
                setSection("NPS");
                }}>
                    
                    {rate 
                    ?
                    <>
                    <Text type={"body-text-1"} fontWeight={"medium-font"}>Your feedback</Text>
                    <RatingScaleIcon type={"emoticons"} scale={5} value={rate} />
                    </>
                    :
                    <>
                    <Text type={"body-text-1"} fontWeight={"medium-font"}>Share your feedback</Text>
                    <Icon value={"share-emoji"} />
                    </>
                }
                </div>
            </>
        } else if(section=="guidelines"){
            return <>
                    {renderPanelHeader()}
                    <Guidelines onHide={handleClose} />
            </>;
        } else if(section=="NPS"){
            return <>
                
                <NPS section="sidebar" setSection={setSection} onHide={handleClose} openMenu={()=>{setSection("contact-us")}} setPanelRate={setRate} />
            </>;
        }  else if(section=="contact-us"){
            return <>
                {renderPanelHeader()}
                <div className='mt-16 nps-contact-form'>
                    <div className='mb-32'>
                        <Text type={"subtitle-1"} cssClasses="purple-color" fontWeight={"medium-font"}>Contact us</Text>
                    </div>

                    <ContactUsForm section={"sidebar"} />
                </div>
            </>;
        }
    }
        
        
    
    const renderPanelHeader = () =>{
        return<>
            <div className='nps-back-btn align-items-center' onClick={()=>{setSection("menu")}}>
                <Icon value={"back-arrow"} hover={true} size={"small"}/> Back
            </div> 
            </>
       
    }

    return <Offcanvas  show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header className='mb-32 d-flex justify-content-between align-items-center'>

                <Offcanvas.Title className='heading h2'>Support center</Offcanvas.Title>

                <span className='close-sidebar' onClick={handleClose} aria-label="Close">
                    <Icon value="Close" hover="true" /> 
                </span>

            </Offcanvas.Header>

        <Offcanvas.Body>
            <div className="support-panel" id='support-panel' style={{position:"relative"}}>
                {renderSection()}
            </div>
        </Offcanvas.Body>
    </Offcanvas>
}