import React, { forwardRef } from "react";
import "./Item.css";

const Item = forwardRef(
    ({ id, withOpacity, isDragging, style, children, ...props }, ref) => {
        const inlineStyles = {
            opacity: withOpacity ? "0.1" : "1",
            transformOrigin: "50% 50%",
            width: "100%",
            borderRadius: "10px",
            cursor: isDragging ? "grabbing" : "grab",
            backgroundColor: "#ffffff",
            justifyContent: "center",
            alignItems: "center",
            zIndex: isDragging ? 1000 : "auto",
            position: "relative",
            ...style,
        };

        return (
            <div
                ref={ref}
                className={`prefer-design-wrapper ${
                    props.class_name ? props.class_name : ""
                } ${isDragging ? "draggable-inner-element-start" : ""}`}
                style={inlineStyles}
                {...props}
            >
                {children}
            </div>
        );
    }
);

export default Item;
