import React, { useState, useEffect, useRef } from "react";
import { ReactSession } from "../../../../lib/secure_reactsession";
import toast from "react-hot-toast";
import { getFileType, matchExtensions, maxLengthCheck } from "../../../../lib/helpers";
import { addFirstclickDesignService, addImportFirstclickDesignService } from "../../../../services/test";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Input } from "../../../themes/userq/Input/Input";
//import { navigate } from "@storybook/addon-links";
import AutosizeInput from "react-input-autosize";
import { ProgressBar } from "../../../themes/userq/ProgressBar/progress_bar";
import { settings } from "../../../../data/settings";

import { Preview } from "../../../themes/userq/Preview/preview"
import { ArabicNumbers } from 'react-native-arabic-numbers';


export const ImageUpload = ({ design, test, taskErrors, setConfirmModal, onDesignUpdated, updateHeaderLoader }) => {

	const fileRef = useRef();

	const colorRef = useRef();

	const inputRef = useRef(null);

	const [fileType, setFileType] = useState("image");

	const [previewModal, setPreviewModal] = useState({open:false, img:null, loading:false});

	const [designFile, setDesignFile] = useState(null);

	const [previewUrl, setPreviewUrl] = useState(null);

	const [titleEditable, setTitleEditable] = useState(false);

	const [uploadPerc, setUploadPerc] = useState(0);

	let user = ReactSession.get("user");

	useEffect(() => {

		if (design) {
			setFormValues({
				...formValues,
				//file_type: design.type,
				design_id: design.id,
				title: design.title ? design.title : '',
				color: design.color
			});
			if(design.design_file){
				setPreviewUrl(process.env.REACT_APP_IMG_URL + "/" + design.design_file);

				setFileType(design.type);
			} else {
				setPreviewUrl(null);

				setFileType("image");
			}
		}


	}, [design]);

	useEffect(()=>{
        var form_errors = {...formErrors};
        
        if(taskErrors.design_file){

            form_errors = {...form_errors, design_file:taskErrors.design_file};
 
        } else {
            form_errors = {...form_errors, design_file:null};
        }
        setFormErrors(form_errors);

    },[taskErrors]);

	useEffect(() => {

        if (titleEditable) {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [titleEditable]);


	const [formValues, setFormValues] = useState({
		title: "",
		file_type: "",
		color: "rgb(255,255,255)",
	});
	const [formErrors, setFormErrors] = useState({
		title: null,
		design_file: null,
		color: null,
		"error_class": "input_error"
	});
	const [formLoading, setFormLoading] = useState(false);

	const showError = (error) => {
		toast(
			<ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: settings.toast_duration,
			}
		);
	};
	const resetFormErrors = () => {

		let formErrorsLocal = Object.assign({}, formErrors);

		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		setFormErrors(formErrorsLocal);

		return formErrorsLocal;
	};
	const handleFile = (file) => {

		resetFormErrors();
		//let's grab the image file
		var error = false;

		var fileType = getFileType(file);

		if (fileType == "image" && file.size / 1024 > 2048) {

			setFormErrors({ ...formErrors, design_file: "Please select file <= 2MB.", "error_class": "input_error" });

			error = true;
		}
		if (!matchExtensions(["jpg", "jpeg", "gif", "png", "MP4", "JPG", "JPEG", "GIF", "PNG"], file)) {


			setFormErrors({ ...formErrors, design_file: "Only image files are accepted with extensions jpg, jpeg, gif and png.", "error_class": "input_error" });
			error = true;
		}
		if (fileType == "video" && file.size / 1024 > 4096) {

			setFormErrors({ ...formErrors, design_file: "Please select file <= 4MB.", "error_class": "input_error" });

			error = true;
		}

		// && fileType != "video"
		if (fileType != "image") {


			setFormErrors({ ...formErrors, design_file: "Only image files are accepted.", "error_class": "input_error" });
			error = true;
		}

		if (!error) {

			if (fileType == "image") {
				setFileType("image");

				setFormValues({ ...formValues, file_type: "image" });
			}
			if (fileType == "video") {
				setFileType("video");

				setFormValues({ ...formValues, file_type: "video" });
			}
			//setDesignFile(file);

			submitForm(file,fileType);

			//setPreviewUrl(URL.createObjectURL(file));
		}

	};

	const handleOnDragOver = (event) => {
		event.preventDefault();
	};
	const handleOnDrop = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();
		//let's grab the image file
		let imageFile = event.dataTransfer.files[0];

		handleFile(imageFile);
	};
	const removeImage = (e) => {
		setDesignFile(null);
		setPreviewUrl(null);
		fileRef.current.value = null;
	};
	const handleFileInput = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();

		handleFile(event.target.files[0]);
	};
	const updateDesign = (form_data = null) => {

		if(!updateHeaderLoader){
			return;
		}

		var error = false;

		var form_errors = resetFormErrors();

		var form_data_local = form_data ? form_data : formValues;

		if(!form_data_local.title){

			error = true;

			
		}

		if (error) {
			setFormErrors(form_errors);
		}
		if (!error) {
			let token = ReactSession.get("token");

			var form_data_local = form_data ? form_data : formValues;

			const formData = new FormData;
			formData.append("test_id", test.id);

			formData.append("design_id", form_data_local.design_id);

			formData.append("title", form_data_local.title);

			formData.append("color", form_data_local.color);

			setFormLoading(true);

			updateHeaderLoader({status:"loading", message:""})

			addFirstclickDesignService(
				formData,
				token
			).then((response) => {

				setFormLoading(false);

				if (response.success) {

					updateHeaderLoader({status:"success", message:""})

					resetFormErrors();

					setDesignFile(null);

					setPreviewUrl(null);

					setFormValues({ title: "", file_type: "" })

					onDesignUpdated(response.design);

				} else {

					updateHeaderLoader({status:"error", message:response.message})

				}
			});
		}
	}
	const submitForm = (file, file_type) => {

		var error = false;

		var form_errors = resetFormErrors();

		if (!file) {

			error = true;

			form_errors = {
				...form_errors,
				design_file: "Please select file.",
				"error_class": "input_error"
			};
		}

		if (error) {
			setFormErrors(form_errors);
		}
		if (!error) {
			let token = ReactSession.get("token");

			
			const formData = new FormData;

			formData.append("test_id", test.id);

			formData.append("design_file", file);

			formData.append("file_type", file_type);

			formData.append("design_id", design.id);

            if(test.language=="ar"){
            	formData.append("title", "اسم التصميم");
            }else{
				formData.append("title", "design name");
            }

			setFormLoading(true);

			setUploadPerc(0);

			addImportFirstclickDesignService(
				formData,
				token,
				(percentage)=>{
					setUploadPerc(percentage);
				}
			).then((response) => {

				

				setFormLoading(false);

				if (response.success) {

					resetFormErrors();

					setDesignFile(null);

					setPreviewUrl(null);

					setFormValues({ title: "", file_type: "" })

					removeImage();

					onDesignUpdated(response.design);

					//onDesignAdded(response.design);


				} else {

					toast(
						<div className="toastinner">
							<ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
                            duration: settings.toast_duration,
						}
					);
				}
			});
		}
	}
	return (
		<div id={"design_file_"+design.id}>
			{design.design_file &&
				<>
					<div className={`added-design-preview-thum ${formErrors.design_file ? "file_error_pref":""}`}>
						<div className="prefer-design-image-view-hold">
							<div className="prefer-test-selected-design-view">


								<div style={{ 'display': 'none' }}

								>
									{design.type == "video" &&
										<div className="video-design-icon">
											<img src={process.env.REACT_APP_URL + "images/video-play-btn.svg"} alt="icon" />
										</div>
									}


								</div>


								<div className="prefer-test-thum-data-hold">
									<div className="prefer-designpreview-thum-data-wrap">
										<div className="added-design-img-thm" style={{ "backgroundColor": formValues.color}}>
											<img src={design.design_file.includes("https") ? design.design_file : process.env.REACT_APP_IMG_URL + design.design_file} />

											<div className="design-preview-btn-wrap">
												<a href={design.design_file.includes("https") ? design.design_file : process.env.REACT_APP_IMG_URL + design.design_file} target="_blank">
													<Button label={"Preview"} iconLeft={
														<Icon value={"eye"} size={"medium"}  />
													} 
														onClick={(e)=>{
															e.preventDefault();
															setPreviewModal({...previewModal, background:formValues.color, open:true, file_type:design.type, img: design.design_file.includes("https") ? design.design_file : process.env.REACT_APP_IMG_URL + design.design_file});
														}}
													/>
												</a>
											</div>
										</div>
										<div className={`prefer-design-action-option ${test.language == "ar" ?"arabic_wrapper":""}`}>

											<div className="prefe-test-name-wrap d-flex align-items-center ">
												{!titleEditable &&
													<>
														{!formValues.title &&
															<Text type="body-text-2" fontWeight={'medium-font'}>{test.language == "ar" ?"اسم التصميم":"Design name goes here"}</Text>
														}
														{formValues.title &&
															<Text type="body-text-2" fontWeight={'medium-font'}>{formValues.title}</Text>
														}

														<span className="edit-btn" onClick={() => { setTitleEditable(true) }}><Icon value={"edit"} size={"small"} /></span>
													</>
												}
												{titleEditable &&
													<div className={`image-edit-name-input ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
														<AutosizeInput
															className={`auto-width-input ${test.language == "ar" ? "arabic_wrapper" : ""}`}
															value={formValues.title!=null ? formValues.title : ''}
															placeholder={test.language == "ar" ?"اسم التصميم":'Design name'}
															onChange={(e) => {
																setFormValues({...formValues, title:e.target.value});
															}}
															maxLength={50}
															onBlur={
																() => {
																	if(!formValues.title){
																		setFormValues({...formValues, title:design.title})
																	}
																	setTitleEditable(false);
																	
																	updateDesign();
																}
															}
															onKeyDown={(e)=>{
																if (e.key === 'Enter') {
																	if(!formValues.title){
																		setFormValues({...formValues, title:design.title})
																	}
																	setTitleEditable(false);
																	updateDesign();
																}
															}}
															ref={inputRef}
														/>
														<span className="edit-btn" onClick={() => { 
															if(!formValues.title){
																setFormValues({...formValues, title:design.title})
															}
															setTitleEditable(false);
															updateDesign(); }}>
															<Icon value={"check-mark"} size={"small"} />
														</span>
														
														{/*<Input type={"text"} value={formValues.title ? formValues.title : ''}
															onChange={(e) => {
																setFormValues({ ...formValues, title: e.target.value });
															}}
															label={"Design name"}
															onBlur={
																() => {
																	setTitleEditable(false);
																	updateDesign();
																}
															}
														/>*/}
													</div>
												}

											</div>
											<div className="prefer-test-preview-bottom-wrap">

												<div className="prefer-thum-bg-color-wrap d-flex">
													<span>Background color</span>
													<div className="prefer-bg-colorpiker" >

														<div className="bg-color-box"
															 onClick={() => { if (colorRef && colorRef.current) { colorRef.current.click(); } }}
															 style={{ "backgroundColor": formValues.color, width: "50px", "height": "50px" }}>

														</div>

														<Input
															ref={colorRef}
															style={{ "display": "none" }}
															type={"color"}
															rtl={(test && test.language == "ar") ? true : false}
															label={"Background color"} value={formValues.color}
															onChange={(e) => { setTitleEditable(false); setFormValues({ ...formValues, color: e.target.value }); updateDesign({ ...formValues, color: e.target.value }); }}
															error={formErrors.color}
															error_class={formErrors.error_class}
														/>
													</div>
												</div>

												<Button
													type={"secondary"}
													onClick={() => {
														if(setConfirmModal){
														setConfirmModal({ open: true, design_id: design.id });
														}
													}}

													label="Delete"
													iconLeft={<Icon value={"delete"} size="medium" />}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Preview open={previewModal.open} withMinimizeIcon={true} background={previewModal.background} loading={false} file_type={previewModal.file_type} img={previewModal.img} close={()=>{
						setPreviewModal({...previewModal,open:false});
					}}/>
				</>
			}
			{!design.design_file &&
				<>
					<div className={`upload-image-inner-wrapper ${formErrors.design_file ? "file_error_pref":""} `}>
						<div className="upload-img-opton-wrap">
							{!previewUrl &&
								<>
								{!formLoading &&
								<div
									class="upload-image-information-wrapper"
									onDragOver={handleOnDragOver}
									onDrop={handleOnDrop}
								>
									<Text type={"subtitle-2"} fontWeight={"medium-font"}>Drag and drop to upload file</Text>
									<Text type={"body-text-3"} fontWeight={"medium-font"}>JPG, JPEG, PNG or GIF</Text>
									<Text type={"caption"} fontWeight={"medium-font"}>Max file size is 2MB for images</Text>


									<button className="secondary-btn secondary-large position-relative up-img-btn btn-with-icon-left">
										<label className="label upload-image-label">
                                            <span className="change-image-link">
                                                <Icon value="upload" size={"medium"} />
                                                <input
													type="file"
													ref={fileRef}
													className="default-file-input"
													onChange={handleFileInput}
													onClick={(event) => {
														event.target.value = null
													}}
													accept="image/*, video/*"
													style={{display: "none"}}
												/>
                                                <span className="browse-files-text">Upload</span>
                                            </span>
										</label>
									</button>

								</div>
								}
								{formLoading &&
								<div
									class="upload-image-information-wrapper"
									
								>
									<ProgressBar percentage={uploadPerc} />

								</div>
								}
								
								</>
							}
							{previewUrl && (
								<div className="project-modal-img design-uploaded-img project-setting-image-wrap w-100 design-edit-image-option" onDragOver={handleOnDragOver}
									onDrop={handleOnDrop}>
									<div className="uploaded-project-img edit-design-img-wrap">
										<div className="prefer-upload-opton-wrap">

											{fileType == "image" &&
												<img src={previewUrl} alt="img" width="100%" />
											}
											{fileType == "video" &&
												<video src={previewUrl} width="100%" controls="1">
												</video>
											}
										</div>


									</div>

									<img
										className="remove-img"
										onClick={removeImage}
										src={process.env.REACT_APP_URL + "images/cross.svg"}
										alt="img"
										style={{ width: "20px" }}
									/>
								</div>
							)}

						</div>
						
					</div>
				</>
			}
			<div >
			{formErrors.design_file &&
			<div  className={`img-upload-design-error`}>
				{formErrors.design_file}
			</div>
			}
			</div>
		</div>
	);
}