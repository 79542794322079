import Select, { components } from "react-select";
import React, { useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { hasFlag } from "country-flag-icons";
// import { countries } from "countries-list";
import Flags from "country-flag-icons/react/3x2";
import { DropdownIndicator } from "../../../../lib/helpers";
import { Icon } from './../Icon/Icon';
import { Chip } from "../Chips/Chips";
import { countries_other } from "../../../../data/countries_other";
import { CountryChips } from './../CountryChips/CountryChips';
import { nationalities_other } from "../../../../data/nationalities_other";

const CustomValueContainer = ({ children, ...props }) => {
    const { ValueContainer, Placeholder } = components;

    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
}



export const CountryDropdown = ({ id, isMulti, type, onChange, value, label, active, menuIsOpen, categories }) => {

    
    const [input, setInput] = useState("");

    let selected_value = null;


    useEffect(()=>{

    },[value])

    
    
    if (isMulti) {
        selected_value = [];
        if (type == "nationality") {
            value.forEach(function (item) {
                var exist = false;
                nationalities_other.forEach(function (nationality) {
                    if (nationality.value == item) {
                        exist = true;
                    }
                })
                if (exist) {
                    selected_value.push({ "label": item, "value": item });
                } else {
                    selected_value.push({ "label": item, "value": item });
                }

            })
            

        } else {
            value.forEach(function (item) {
                selected_value.push({ "label": item, "value": item });
            })
        }
    } else {
        if (value) {
            selected_value = {
                label: value,
                value: value
            };
        }
    }
    var options = null;
    if (type == "nationality") {
        options = nationalities_other
            .map((country) => {
                const exist = hasFlag(country["code"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["code"]];

                const checkValue = selected_value.findIndex(item => item.value === country.value);
                if(checkValue !== -1){
                    
                    return {
                        value: country["value"],
                        label: (
                            <><span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };

                    
                } else{
                    
                    return {
                        value: country["value"],
                        label: (
                            <><span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                    
                }
                
            })
            .filter(Boolean);

    } else {
        options = countries_other
            .map((country) => {
                const exist = hasFlag(country["code"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["code"]];

                const checkValue = selected_value.findIndex(item => item.value === country.value);
                if(checkValue !== -1){
                    return {
                        value: country["value"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };
                } else {
                    return {
                        value: country["value"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                }
            })
            .filter(Boolean);

    }

    return (
        <div className={` dropdown-container form-group input-field mb-0 ${((value != '' && value != null) || active == true) ? 'focused country-with-value' : ''} ${(active == true) ? 'active' : ''}`} id={id} >
            <div className={`input-position-relative  dropdown-field country-dropdown`}>
                {menuIsOpen ?
                    <Select
                        isMulti={true}
                        isClearable={false}
                        isSearchable={true}
                        isDisabled={false}
                        hideSelectedOptions={false}
                        name="countries"
                        options={options}
                        onChange={onChange}
                        placeholder={type=="nationality" ? "Search nationalities" : "Search"}
                        value={selected_value}
                        menuIsOpen
                        components={{ ValueContainer: CustomValueContainer, DropdownIndicator }}
                        backspaceRemovesValue={false}
                    // styles={customStyles}
                    // classNamePrefix="select"
                    // components={{ ValueContainer: NewValueContainer,DropdownIndicator }}
                    />
                    :
                    <Select
                        key="countrydropdown"
                        isMulti={true}
                        // isClearable={true}
                        // isSearchable={true}
                        isDisabled={false}
                        hideSelectedOptions={false}
                        name="countries"
                        options={options}
                        onChange={(items)=>{ setInput(""); onChange(items)}}
                        placeholder={type=="nationality" ? "Search nationalities" : "Search"}
                        value={selected_value}
                        components={{
                            ValueContainer: CustomValueContainer, DropdownIndicator
                        }}
                        styles={{
                            placeholder: (provided, state) => ({
                                ...provided,
                                display: state.selectProps.inputValue ? "none" : "block",
                            })
                        }}
                        inputValue={input}
                        onInputChange={(value, action) => {
                            if (action.action === "input-change") { setInput(value); }
                        }}
                        backspaceRemovesValue={false}
                        closeMenuOnSelect={type=="nationality"?false:true}
                    />}
                <span className="input-icon"><Icon value="search" size="medium" hover={true}/></span>
                {/* <label className="control-label body-text body-text-2" htmlFor={id}>

                    {label}
                </label> */}
                {input != "" && (
                    <span className="input-right-icon" onClick={() => { setInput("") }}>
                        <Icon value="close" size="medium" hover={true}/>
                    </span>
                )}

            </div>

            {type == "nationality" ?
                <>
                    {categories}
                    <div className="dropdown-chips chips-with-flag">{selected_value.map((nationality, i) => (
                        
                            <>
                            {nationality.value=="Pakistan"?nationalities_other.code:""}
                            <CountryChips key={"nationalitychip" + nationality.value} title={nationality.value} countrydetails={nationalities_other.filter(x => x.label === nationality.value)} type="icon-right"
                                onRemove={() => {
                                    let new_selected = [];

                                    selected_value.forEach(function (item) {
                                        if (item.value != nationality.value) {
                                            new_selected.push(item);
                                        }
                                    })
                                    if (onChange) {
                                        onChange(new_selected)
                                    }
                                    
                                }} 
                            />
                            </>
                        
                        ))}
                    </div>
                </>
                :
                <div className="dropdown-chips">{selected_value.map((country, i) => (<CountryChips key={"countrychip" + country.value} title={country.value} countrydetails={countries_other.filter(x => x.label === country.value)} type="icon-right" onRemove={() => {
                    let new_selected = [];

                    selected_value.forEach(function (item) {
                        if (item.value != country.value) {
                            new_selected.push(item);
                        }
                    })
                    if (onChange) {
                        onChange(new_selected)
                    }
                }} />))}</div>
            }
        </div>
    );
}

CountryDropdown.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    inactive: PropTypes.bool,
    focussed: PropTypes.bool,
    open: PropTypes.bool,
    isMulti: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
    type: PropTypes.string,

};
CountryDropdown.defaultProps = {
    onChange: undefined,
    inactive: false,
    focussed: false,
    open: false,
    type: "country",
    isMulti: true,
    menuIsOpen: false
};