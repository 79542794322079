import React, { useState, useReducer, useEffect } from "react";
import { Accordian } from './../Accordion/Accordion';
import { ByUserTableLikert } from "./ByUserTableLikert";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { getLoadMoreQuestionData } from "../../../../services/researcher_test_results";
import { showToast } from "../../../../lib/helpers";
import { Search } from './../Search/Search';
import { Text } from './../Text/Text';

export const LikertScaleByUser = ({ test_id, test, data, answerArray, likertQuestion, hideParticipantModal, reportAnswerModal, metaData, loadMore, loadMoreLoading, tableClass, methodology }) => {

    const [accordians, setAccordians] = useState({});
    const intialSearchArray = [];
    for (let i = 0; i < likertQuestion.subQuestions.length; i++) {
        intialSearchArray.push({ id: i, searchValue: "" });
    }
     
    const reducer = (state, action) => {
        switch (action.type) {
            case "CHANGE":
                return state.map((searchArray) => {
                    if (searchArray.id === action.id) {
                        return { ...searchArray, searchValue: action.value };
                    } else {
                        return searchArray;
                    }
                });
            default:
                return state;
        }
    };

    const [searchArray, dispatch] = useReducer(reducer, intialSearchArray);

    const handleSearch = (searchArray, value) => {
        dispatch({ type: "CHANGE", id: searchArray.id, value: value });
    };


    var accordion = {};
    useEffect(()=>{
        for (let i = 0; i < likertQuestion.subQuestions.length; i++) {
            if(i===0){
                accordion[likertQuestion.subQuestions[i]] = true;
            }else{
            accordion[likertQuestion.subQuestions[i]] = false;
            }
        }
        setAccordians({...accordion});
    },[likertQuestion])

    return (
        <div className="likert-scale-chart">
            {likertQuestion.subQuestions && likertQuestion.subQuestions.map((element, index) => (
                <div key={"byuser-" + index} className="likert-scale-chart-accordion">
                    <Accordian
                        title={<p className={`${test.language == "ar" ? "arabic-font" : ""}`}>{element}</p>}
                        open={accordians[element] ? true : false}
                        onChange={() => {
                            let new_accordians = Object.assign({}, {...accordians});
                            for (var key in accordians) {
                                if (key == element) {
                                    if (accordians[key]) {
                                        new_accordians[key] = false;
                                    } else {
                                        new_accordians[key] = true;
                                    }
                                }

                            }
                            setAccordians({...new_accordians});
                        }}
                    >
                        <div className='likert-byuser-table-header'>
                            <div className='free-question-table-header'>
                                <div className='free-question-table-header-left'>
                                    <Text type="body-text-3" fontWeight="medium-font">{metaData.totalResultCount}
                                        {metaData.totalResultCount == 1 ? ' answer' : ' answers'}
                                    </Text>
                                </div>
                                <div className='free-question-table-header-right'>
                                    <Search
                                        size={"small"}
                                        placeholder="Search in answers"
                                        onChange={(e) => handleSearch(searchArray[index], e.target.value)}
                                        onClear={() => {
                                            setTimeout(() => {
                                                handleSearch(searchArray[index], "")
                                            }, 0)
                                        }}
                                        loading={false}
                                        value={searchArray[index]?searchArray[index].searchValue:""}
                                    />
                                </div>
                            </div>

                        </div>
                        <ByUserTableLikert methodology={methodology} tableClass={tableClass} answerArray={answerArray} test={test} subquestionIndex={index} searchValue={searchArray[index]?searchArray[index].searchValue:""} totalSubQuestions={likertQuestion.subQuestions.length} reportAnswerModal={reportAnswerModal} hideParticipantModal={hideParticipantModal} moreDataAvailable={metaData.load_more} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{handleSearch(searchArray[index], "")}}/>
                    </Accordian>
                </div>
            ))}

        </div>
    )
}