import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import Layout from "../../layouts/layout";
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
import Accordian from '../../Accordian';
import InputField from "../../layouts/elements/fields/input";
import IntlTelInput from "react-intl-tel-input";
import {Dropdown} from "reactjs-dropdown-component";
import TextAreaField from "../../layouts/elements/fields/textarea";
import {getProfile, researcherTesterSupportContact} from "../../../services/user";
import {PhoneNumberUtil} from "google-libphonenumber";
import { LoadingIcon } from './../../loader/loadingIcon';
import {LayoutTester} from "../../themes/userq/Layouts/layout_tester";
import TesterSupportNavigation from './tester_support_navigation';
import { Text } from '../../themes/userq/Text/Text';
import { TextArea } from '../../themes/userq/TextArea/TextArea';
import { Button } from '../../themes/userq/Button/Button';
import { CheckBox } from '../../themes/userq/CheckBox/CheckBox';
import { showToast } from '../../../lib/helpers';
import { DropdownComponent } from '../../themes/userq/Dropdown/Dropdown';
import { Link } from '../../themes/userq/Link/Link';

export default function TesterSupportContact(props) {

    const [formErrors, setFormErrors] = useState({ name: null, error_class: "input_error",email: null,phone_no: null, reason: null,message: null, terms:null });

    const [formValues, setFormValues] = useState({  "name": "","email": "", "phone_number":"","reason":'','message':'','terms':false})

    const [reasonLabelClass, setReasonLabelClass] = useState('inactive_label');

    const [isLoading, setLoading] = useState(false);
    const [profile_loaded, setProfileLoaded] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    let reasonSelect = useRef();
    const navigate = useNavigate();

    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };


    // const useQuery= () => {
    //     return new URLSearchParams(useLocation().search);
    // }

    const [searchParams] = useSearchParams()

    const reasonOptions = [
        {"label":"Suggest an improvement","value":"Suggest an improvement"},
        {"label":"Problem with the platform","value":"Problem with the platform"},
        {"label":"Help with payout","value":"Help with payout"},
        {"label":"Blocked profile","value":"Blocked profile"},
        {"label":"Other","value":"Other"}
    ];

    const ReasonChange = (item) => {
        setFormValues({...formValues,"reason":item.value});
        setFormErrors((oldValues) => ({ ...oldValues, reason: null }));
        setReasonLabelClass('active_label')
        window.setLabels()
    }

    const getProfileResponse = () =>{
        let token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response=> {
            setLoading(false);


            if(response.success){

                // setUserProfile(response.data.user)
                if(response.data.user){

                    if(searchParams.get("reason")){
                        let reasonParam = searchParams.get("reason");
                        setFormValues({...formValues,'name':response.data.user.first_name+' '+response.data.user.last_name,'email':response.data.user.email,"phone_number":
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number
                            : "",
                    "dialcode_phone":
                        response.data.user.dialcode_phone == null
                            ? "us"
                            : response.data.user.dialcode_phone,
                    "country_phone": response.data.user.country_phone,'reason':reasonParam});

                        reasonSelect.current.selectSingleItem({ value: reasonParam });
                        setReasonLabelClass('active_label')
                        window.setLabels()
                    }else{
                        setFormValues({...formValues,'name':response.data.user.first_name+' '+response.data.user.last_name,'email':response.data.user.email,"phone_number":
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number
                            : "",
                    "dialcode_phone":
                        response.data.user.dialcode_phone == null
                            ? "us"
                            : response.data.user.dialcode_phone,
                    "country_phone": response.data.user.country_phone});

                    }


                }
                setProfileLoaded(true);
            }
        });
    }



    useEffect(() => {
        document.title = "Contact us | "+process.env.REACT_APP_NAME;

        var userData = ReactSession.get("user");
        if(!userData){
            navigate('/tester/sign-in');
            return false;
        }

        if(ReactSession.get("token")) {
            getProfileResponse();
        }else{
            if(searchParams.get("reason")){
                let reasonParam = searchParams.get("reason");
                setFormValues({...formValues,'reason':reasonParam});

                reasonSelect.current.selectSingleItem({ value: reasonParam });
                setReasonLabelClass('active_label')
                window.setLabels()

            }
        }




    }, []);

    const resetFormErrors = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const checkUser = () =>{
        if(ReactSession.get("token")){
            return  false;
        }
        return true;
    }

    const handleSubmit = () => {

        var error = false;

        var form_errors = resetFormErrors();

        if (formValues.reason == "") {
            form_errors = {
                ...form_errors,
                reason: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        if (formValues.message == "") {
            form_errors = {
                ...form_errors,
                message: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        setFormErrors(form_errors);

        if(!error){
            //console.log(formValues)
            setFormLoading(true);

            const token = ReactSession.get("token");

            var formData = { ...formValues };
            researcherTesterSupportContact(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    setFormValues({ ...formValues,'message':'',"reason":"", terms:false})
                    showToast(response.message,"success");
                }else{
                    showToast(response.message,"error");
                }
            });
        }

    }

    const validateBtnState = () => {

        var error = false;

        if (formValues.reason == '' || formValues.reason == null) {
            error = true;
        }
        else if (formValues.message==null || formValues.message == '') {

            error = true;
        }        
        if (!formValues.terms) {

            error = true;
        }
        return error;
    }





    return (
        <LayoutTester
            isLoading={false}
            isAccount={true}
            skipCheck={false}
            activeMenu={""}
        >
            <div className='page-heading-wrap accounts-page--heading'>
                <Text type="h1">Support</Text>
            </div>
            <TesterSupportNavigation activeMenu="contact" />
            {isLoading &&
            <div className="accountloading-box"> <LoadingIcon /></div>
            }
            {!isLoading &&
            <div className='contact-section'>
                <Text type="body-text-2" fontWeight="medium-font">Use the contact form below to get in touch with our support team. We will review and respond to your inquiry within 2 working days by sending a reply to your registered email address: <b>{formValues.email}</b>. </Text>
                <DropdownComponent
                    name="reason"
                    label="Reason of enquiry"
                    onChange={ReasonChange}
                    options={reasonOptions}
                    value={formValues.reason}
                    isClearable={false}
                    error={formErrors.reason}
                />
                <TextArea
                    autosize={true}
                    value={formValues.message}
                    onClear={() => {
                        setTimeout(() => {
                            setFormValues((oldValues) => ({
                                ...oldValues,
                                message: "",
                            }));
                        }, 1000);
                    }}
                    error={formErrors.message}
                    error_class={formErrors.error_class}
                    onChange={(e) => {
                        setFormValues((oldValues) => ({
                            ...oldValues,
                            "message": e.target.value
                        }))
                        setFormErrors({ ...setFormErrors, "message": '' });

                    }}
                    maxLength={350}
                    type="text"
                    label={"Your message"}
                    wrapClass="tester-text-area"
                />
                <CheckBox checked={formValues.terms}
                    onChange={(event) => { toggleCheck('terms', event.currentTarget.checked) }}
                    id="termsCheckBox"
                    label={
                        <div className='terms-checkbox-label'>
                            I agree to the{" "}
                            <Link url={process.env.REACT_APP_SITE_URL +"terms-of-use"} target="_blank">Terms of Use</Link>
                            {" "}
                            and have read the{" "}
                            <Link url={process.env.REACT_APP_SITE_URL +"privacy-policy"} target="_blank">Privacy Policy</Link>
                        </div>
                    }
                />
                <Button
                    label={"Submit"}
                    state={!validateBtnState()?"enabled":"disabled"}
                    microLoading={formLoading}
                    type={"primary"}
                    size={"large"}
                    onClick={()=>handleSubmit()}
                />
            </div>
            }

            {/* <div className="analyze-results-page-wrapper FAQ-support-page-wrap">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <div className="profile-info-section">
                            <div className="target-back-option-data">
                                <div className="backto-list-wrap">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1)
                                    }}>
                                        <img src={process.env.REACT_APP_URL+"images/back-arrow.svg"} alt="img"/> Back</a>
                                </div>
                                <div className="profile-info-text">
                                    <div className="target-group-heading-top studyint-top-flex-wrap w-100">
                                        <h1 className="h1"><span>Tester</span> support</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inner-page-container">
                    <div className="sp5-content-wrap analyze-overflow-wrap">
                        <div className="sidebar sp5-left-side-bar">
                            <div className="sp5-side-bar-innerdata">
                                <div className="sp5-side-nav-wrap border-0">
                                    <ul>
                                        <li><Link to={'/tester/support/'}>Frequently asked questions</Link></li>
                                        <li><Link to={'/tester/support/contact'} className="active">Contact us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        {isLoading &&
						<div className="page-min-height contactus-page-loader">
							<LoadingIcon />
						</div>
                        }
                        {!isLoading && (
                        <div className="sp5-right-side-wrap">
                            <div className="sp5-overview-data faq-data-wrapper">

                                <p className="faqsub-title">﻿We will review and respond to your inquiry within 2 working days.</p>

                                <div className="create-contact-support row">

                                    <div className={'form-group col-md-6'}>
                                        <InputField
                                            label={"Name"}
                                            error={formErrors.name}
                                            error_class={formErrors.error_class}
                                            onChange={(e)=>{
                                                setFormValues({...formValues,"name":e.target.value})
                                                setFormErrors({...setFormErrors,"name":''});
                                            }}
                                            value={formValues.name}
                                            type="text"
                                        />
                                    </div>

                                    <div className={'form-group col-md-6'}>
                                        <InputField
                                            label={"Email"}
                                            error={formErrors.email}
                                            error_class={formErrors.error_class}
                                            onChange={(e)=>{
                                                setFormValues({...formValues,"email":e.target.value})
                                                setFormErrors({...setFormErrors,"email":''});
                                            }}
                                            value={formValues.email}
                                            type="text"
                                        />
                                    </div>

                                    <div className={'form-group col-md-6'}>
                                        <div className="input-field static-label multi-field-wrap" id="phone_number">
                                            <label htmlFor="phone">Your phone number</label>
                                            {profile_loaded && (
                                                <IntlTelInput
                                                    containerClassName="intl-tel-input"
                                                    onPhoneNumberChange={onPhoneNumberChange(
                                                        "phone_number"
                                                    )}
                                                    defaultValue={formValues.phone_number}
                                                    value={formValues.phone_number}
                                                    defaultCountry={formValues.country_phone}
                                                    onSelectFlag={onFlagChange("phone_number")}
                                                    inputClassName={
                                                        formErrors.phone_no != null
                                                            ? "form-control " + formErrors.error_class
                                                            : "form-control "
                                                    }
                                                    format={"true"}
                                                    preferredCountries={["ae", "sa"]}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            )}
                                            {formErrors.phone_no != null && (
                                                <span className={formErrors.error_class}>
                                                                    {formErrors.phone_no}
                                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <div className="profile-form-group">
                                            <div className="input-field static-label" id='job_role'>
                                                <label htmlFor="reason" className={reasonLabelClass}>
                                                    Reason for enquiry
                                                </label>
                                                <div
                                                    className={
                                                        formErrors.reason != null
                                                            ? formErrors.error_class
                                                            : ""
                                                    }
                                                >
                                                    <Dropdown
                                                        name="job_role"
                                                        title="Reason of enquiry"
                                                        list={reasonOptions}
                                                        onChange={ReasonChange}
                                                        ref={reasonSelect}
                                                        styles={{
                                                            headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                            headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                        }}
                                                    />
                                                </div>
                                                {formErrors.reason != null && (
                                                    <span className={formErrors.error_class}>
                                                      {formErrors.reason}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-12">
                                        <TextAreaField
                                            error={formErrors.message}
                                            error_class={formErrors.error_class}
                                            onChange={(e) => {
                                                setFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    "message": e.target.value
                                                }))
                                                setFormErrors({...setFormErrors,"message":''});

                                            }}
                                            value={formValues.message}
                                            maxLength={350}
                                            autosize={true}
                                            type="text"
                                            label={"Your message"} />
                                    </div>

                                    <div className={"form-group col-md-12 d-flex justify-content-end"}>

                                        <button type="submit" onClick={()=>handleSubmit()} className="button primary-btn ml-0 mr-0 mt-64">
                                            Submit
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>

                                    </div>

                                </div>

                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div> */}
        </LayoutTester>
    )
}