import { Modal } from "react-bootstrap";
import React, { useState,useEffect } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import Radiobtn from "../../../layouts/elements/fields/radiobtn";
import toast from 'react-hot-toast';
import {Link} from "react-router-dom";
import CheckboxNormal from "../../../layouts/elements/fields/checkbox_normal";
import { FormattedMessage } from "react-intl";
import {
  atom,
  useRecoilState,
  useResetRecoilState
} from 'recoil';
import {roundNumber} from "../../../../lib/helpers";

export default function PublishModal({ open, close, test, confirm, testmethodology }) {
	let user = ReactSession.get("user");
	const [formValues, setFormValues] = useState({"checked_all_test":false,"accepted_terms":false})
	//const [credits, setCredits] = useState((ReactSession.get("credits"))?ReactSession.get("credits"):0)

  const creditsState = atom({
    key: 'credits',
    default: 0
});
  const [credits, setCredits] = useRecoilState(creditsState);

    
  const resetCredits = useResetRecoilState(creditsState);

	  const showError = (error) => {
      toast(
        <div className="toastinner">
          {error}
          <a
            onClick={() => {
              toast.dismiss();
            }}
          >
            &times;
          </a>
        </div>,
        {
          className: "errortoast",
          position: "bottom-center",
          duration: 3000,
        }
      );
    };

	useEffect(()=>{
        if(!open){
            setFormValues({"checked_all_test":false,"accepted_terms":false});
        }
    },[open]);
	
	return (
    <Modal
      show={open}
      centered
      className="fade custom-modal-wrap publish-modal-wrap"
    >
      <Modal.Body className="p-0">
        <div className="modal-inner-text p-64">
          <button
            type="button"
            className="close"
            onClick={() => {
              close();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <img
                src={process.env.REACT_APP_URL + "images/cross.svg"}
                alt="cross"
              />
            </span>
          </button>
          <div className="modal-data-wrap publish-study-modal-data">
            {testmethodology=="Survey"?(
            <h2>Publish your survey</h2>
            )
            :
            (
            <h2>Publish your study</h2>
            )
            }
            <p className="modal-full-width-p w-100">
              Ready for your test to go live? So are we!
            </p>
            <div className="selected-plan-wrapper">
              <div className="selected-plan-inner-data">
                <div className="selected-plan-top">
                  <div className="sel-pln-tp-left">
                    <p>Credits needed for this study </p>
                  </div>
                </div>
                <div className="selected-plan-mid-wrap">
                  <div className="selected-plan-info-repeat w-100">
                    <div className="d-flex align-items-center">
                      <h2>
                        <img
                          src={process.env.REACT_APP_URL + "images/coins.svg"}
                        />{" "}
                        {test && roundNumber(test.credits_required,2)}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="selected-plan-bottom-wrap">
                  <div className="selected-plan-bottleft w-100">
                    {parseInt(test && test.credits_required) <=
                      parseInt(credits) && (
                        <>
                        <div className="available-credits-text w-100">
                        <p className="publish-modal-available-credits"> Available Credits: {roundNumber(credits,2)}</p>
                        </div>
                        <p>
                          Non-refundable credits will be taken from your account when you publish this test. If you then close the test before all UserQ participants have completed it, any unspent credits will be refunded to your account.


                        </p>
                        </>
                    )}
                    {parseInt(test && test.credits_required) >
                      parseInt(credits) && (
                      <>
                        <div className="publish-credits-error-wrap d-block">
                          <div className="pub-cred-error-text w-100">
                            <p className="input_error">
                              You only have{" "}
                              <span className="red-color">
                                {roundNumber(credits,2)} credits{" "}
                              </span>{" "}
                              available
                              <br />
                              Please buy more to publish your test.
                              <br />
                            </p>
                          </div>
                          <div className="pub-cred-error-btn">
                            {user.permission != "Editor" ? (
                              <Link to={'/researcher/credits/buy-credits?return=test'}>
                                <button
                                  type="button"
                                  className="button primary-btn small-button"
                                >
                                  Buy credits
                                </button>
                              </Link>
                            ) : (
                              <button
                                type="button"
                                className="button primary-btn small-button"
                                onClick={() => {
                                  showError(
                                    "You don't have permission to buy credits. Contact your admin to get more credits"
                                  );
                                }}
                              >
                                Buy credits
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="full-width-radio publish-modal-radio-wrap">
              <div className="checkboxes radio_btn_class gender_btn  d-flex language-checkbox-wrap">
                <CheckboxNormal
                    onChange={() => {
                      setFormValues({ ...formValues, checked_all_test: !formValues.checked_all_test });
                    }}
                    id={"checked_all_test"}
                    name={"checked_all_test"}
                    checked={formValues.checked_all_test === true ? true:false}
                    label={
                      "I understand that I cannot modify or edit this test once published."
                    }
                />

                <CheckboxNormal
                    onChange={() => {
                      setFormValues({ ...formValues, accepted_terms: !formValues.accepted_terms });
                    }}
                    id={"accepted_terms"}
                    name={"accepted_terms"}
                    checked={formValues.accepted_terms === true ?true:false}
                    label={
                      <div>
                          I confirm that I have read the  {" "}

                          <b><a
                              target={"_blank"}
                              href={
                                  process.env.REACT_APP_SITE_URL + "privacy-policy"
                              }
                          >
                              Privacy policy {" "}
                          </a></b>

                          and {" "}

                        <b><a
                            target={"_blank"}
                            href={
                              process.env.REACT_APP_SITE_URL + "terms-of-service"
                            }
                        >
                            Terms of Use. {" "}
                        </a></b>
                      </div>
                    }
                />
                {/*<Radiobtn*/}
                {/*  id="checked_all_test"*/}
                {/*  name="checked_all_test"*/}
                {/*  onChange={() => {*/}
                {/*    setFormValues({ ...formValues, checked_all_test: true });*/}
                {/*  }}*/}
                {/*  checked={formValues.checked_all_test}*/}
                {/*  label={*/}
                {/*    "Make sure you've double checked your test, as well as considered any privacy issues that might arise with your testers."*/}
                {/*  }*/}
                {/*/>*/}
                {/*<Radiobtn*/}
                {/*  id="accepted_terms"*/}
                {/*  name="accepted_terms"*/}
                {/*  onChange={() => {*/}
                {/*    setFormValues({ ...formValues, accepted_terms: true });*/}
                {/*  }}*/}
                {/*  checked={formValues.accepted_terms}*/}
                {/*  label={*/}
                {/*    <>*/}
                {/*      Please tick to confirm that you have read the{" "}*/}
                {/*      <a*/}
                {/*        target={"_blank"}*/}
                {/*        href={*/}
                {/*          process.env.REACT_APP_SITE_URL + "terms-of-service"*/}
                {/*        }*/}
                {/*      >*/}
                {/*        Terms of Service*/}
                {/*      </a>{" "}*/}
                {/*      of using the UserQ platform before publishing your test.*/}
                {/*    </>*/}
                {/*  }*/}
                {/*/>*/}
              </div>
            </div>
            <div className="create-project-form-btn modal-flex-button btn-50-wrap mt-64">
              <button
                className="button  secondary-btn"
                onClick={() => {
                  close();
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className={`button  primary-btn  ${
                  !(formValues.checked_all_test && formValues.accepted_terms)
                    ? "disabled-button"
                    : ( parseInt(test && test.credits_required) > parseInt(credits))?
                      "disabled-button"
                      : ""
                }`}
                onClick={() => {
                  if (!formValues.accepted_terms) {
                    toast(
                      <div className="toastinner">
                        <><FormattedMessage id="Please accept Privacy policy & Terms of use." /></>
                        <a
                          onClick={() => {
                            toast.dismiss();
                          }}
                        >
                          &times;
                        </a>
                      </div>,
                      {
                        className: "errortoast",
                        position: "bottom-center",
                      }
                    );
                  }else if(!formValues.checked_all_test){

                      toast(
                          <div className="toastinner">
                              <><FormattedMessage id="Please acknowledge that you understand the tests can't be edited once published." /></>
                              <a
                                  onClick={() => {
                                      toast.dismiss();
                                  }}
                              >
                                  &times;
                              </a>
                          </div>,
                          {
                              className: "errortoast",
                              position: "bottom-center",
                          }
                      );

                    }else if( parseInt(test && test.credits_required) > parseInt(credits)){
                      toast(
                          <div className="toastinner">
                              <><div>You only have{" "}
                                  <span className="red-color">
                                {credits} credits{" "}
                                  </span>{" "}
                                  available
                                  Please buy more to publish your test.</div></>
                              <a
                                  onClick={() => {
                                      toast.dismiss();
                                  }}
                              >
                                  &times;
                              </a>
                          </div>,
                          {
                              className: "errortoast",
                              position: "bottom-center",
                          }
                      );
                  } else {
                    confirm();
                  }
                }}
                type="button"
              >
                {" "}
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
