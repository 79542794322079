import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useEffect, useState, useRef } from "react";
import ResearcherProfileNavigation from "../../profile/researcher_profile_navigation.js";
import {
    updateSettings,
    updateAccountsSettings,
    getSettings,
} from "../../../services/user.js";
//import LayoutResearcher from '../../layouts/layout_researcher.js';
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import Checkbox from "../../layouts/elements/fields/checkbox.js";
import { LoadingIcon } from "../../loader/loadingIcon.js";
import { LoadingIcon as LoadingIconNew } from "../../loader/loadingIconNew.js";
import Layout from "../../layouts/layout.js";
import TesterProfileNavigation from "../../profile/tester_profile_navigation.js";
import { Text } from "./../../themes/userq/Text/Text";
import ResearcherAccountNavigation from "./../../profile/researcher_account_navigation";
import TesterAccountNavigation from "./../../profile/tester_account_navigation";
import { ToggleButton } from "./../../themes/userq/ToggleButton/ToggleButton";
import { Link } from "../../themes/userq/Link/Link.js";
import { showToast } from "../../../lib/helpers.js";
import { LayoutTester } from "../../themes/userq/Layouts/layout_tester";
import Skeleton from "react-loading-skeleton";
import { ScoreChip } from "../../themes/userq/ScoreChip/ScoreChip.js";
import { Icon } from "../../themes/userq/Icon/Icon.js";
import { QuestionImage } from "../../themes/userq/QuestionImage/QuestionImage.js";

export default function ResearcherSettings() {
    ReactSession.setStoreType("localStorage");

    let user = ReactSession.get("user");
    let score = ReactSession.get("testerscore");
    const accountstatus = ReactSession.get("accountstatus");

    const [formLoading, setFormLoading] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        newsletter: 1,
        accountnotifications: 1,
    });
    const [testerscore, setTesterScore] = useState(null);
    const [show_support_panel, setShowSupportPanel] = useState(false);

    useEffect(() => {
        document.title =
            "Notification settings | " + process.env.REACT_APP_NAME;

        let token = ReactSession.get("token");

        setLoading(true);

        getSettings(token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTesterScore(response.data.score);
                setFormValues({
                    newsletter: response.data.settings.newsletter,
                    accountnotifications:
                        response.data.settings.accountnotifications,
                });
                window.setLabels();
            } else {
                let message = response.message;
                if (response.message == "Unauthenticated.") {
                    message =
                        "If you are unable to see your profile, kindly logout and log in again";
                }

                showToast(message, "error");
            }
        });
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!formLoading) {
            setFormLoading(true);

            const token = ReactSession.get("token");

            let body = Object.assign({}, formValues);

            updateSettings(body, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    // showToast(response.message,"success");
                } else {
                    showToast(response.message, "error");
                }
            });
        }
        return false;
    };
    const updateSettingsForm = (newsletter) => {
        if (!formLoading) {
            setFormLoading(true);

            const token = ReactSession.get("token");

            let body = Object.assign({}, { newsletter: newsletter });

            updateSettings(body, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    // showToast(response.message,"success");
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    };
    const updateAccountnotificationForm = (accountnotifications) => {
        if (!formLoading) {
            setFormLoading(true);

            const token = ReactSession.get("token");

            let body = Object.assign(
                {},
                { accountnotifications: accountnotifications }
            );

            updateAccountsSettings(body, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    // showToast(response.message,"success");
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    };
    const renderSettings = () => {
        return (
            <div className="billing-plan-page-wrapper setting-page-wrapper tester-setting-page">
                {user.role == "Researcher" && (
                    <ResearcherProfileNavigation
                        isLoading={false}
                        activeMenu="settings"
                    />
                )}
                {user.role == "Tester" && (
                    <>
                        {accountstatus == "blocked" && (
                            <div className="account-blocked-message">
                                <div>
                                    <Icon
                                        value={"blocked-icon-red"}
                                        size="large"
                                    />
                                </div>
                                <div>
                                    <Text
                                        type={"subtitle-1"}
                                        children="Your payout cannot be redeemed as your profile is currently blocked."
                                    />
                                    <Text type={"body-text-2"}>
                                        Please contact{" "}
                                        <a
                                            className="faq-support-link disabled-support-link"
                                            href={
                                                process.env.REACT_APP_URL +
                                                "t/support/contact?reason=Blocked profile"
                                            }
                                            target="_blank"
                                        >
                                            UserQ support
                                        </a>
                                        , to guide you through the next steps.
                                    </Text>
                                </div>
                            </div>
                        )}
                        <div className="page-heading-wrap accounts-page--heading">
                            <Text type="h1">Account</Text>
                            <div className="accounts-score-chip">
                                {/* {isLoading?<Skeleton className="header-credit-skeleton" width={180} height={40}/>:<ScoreChip testerscore={testerscore}/>} */}
                                <ScoreChip testerscore={score} />
                                <div className="account-guidelines-link">
                                    <Text type="body-text-3">
                                        Read more about{" "}
                                        <a
                                            className="faq-support-link"
                                            href={
                                                process.env.REACT_APP_URL +
                                                "t/tester-guidelines"
                                            }
                                            target="_blank"
                                        >
                                            tester guidelines
                                        </a>
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <TesterAccountNavigation activeMenu="settings" />
                    </>
                )}

                {/*<div className="profile-info-section d-block pb-64 pt-64">*/}
                {/*    <div className="profile-info-left w-100">*/}
                {/*        <div className="profile-info-text  mt-0">*/}
                {/*            <h1 className="page-main-heading">Settings</h1>*/}
                {/*            <p>*/}
                {/*                Choose the emails and notifications you want to receive from*/}
                {/*                us at your registered email.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {isLoading && (
                    <div className="project-listing-loader loader_section">
                        <div className="accountloading-box">
                            {" "}
                            <LoadingIcon />
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <div className="settings-data-wrap">
                        <div className="settings-subtitle">
                            <Text type="subtitle-2" fontWeight="semi-bold-font">
                                Email notifications
                            </Text>
                            <Text type="body-text-2" fontWeight="medium-font">
                                Choose the emails and notifications you want to
                                receive from us at your registered email.
                            </Text>
                        </div>
                        <div className="profile-form-wrap">
                            <form onSubmit={onSubmit}>
                                <div className="marketing-section-wrap">
                                    <div className="form-info-text">
                                        <Text
                                            type="body-text-1"
                                            fontWeight="medium-font"
                                        >
                                            Marketing notifications
                                        </Text>
                                        <Text
                                            type="body-text-3"
                                            fontWeight="medium-font"
                                        >
                                            Email notifications about UserQ
                                            updates, new features, offers and
                                            latest trends.
                                        </Text>
                                    </div>
                                    <div className="settings-toggle-wrap">
                                        <ToggleButton
                                            id={"newsletter-switch"}
                                            value={"1"}
                                            isChecked={
                                                formValues.newsletter == 1
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setFormValues({
                                                    ...formValues,
                                                    newsletter:
                                                        formValues.newsletter ==
                                                        1
                                                            ? 0
                                                            : 1,
                                                });
                                                updateSettingsForm(
                                                    formValues.newsletter == 1
                                                        ? 0
                                                        : 1
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="marketing-section-wrap account-section-wrap">
                                    <div className="form-info-text">
                                        <Text
                                            type="body-text-1"
                                            fontWeight="medium-font"
                                        >
                                            Account related notifications
                                        </Text>
                                        <Text
                                            type="body-text-3"
                                            fontWeight="medium-font"
                                        >
                                            Based on your account activity we
                                            will send you emails to guide your
                                            next steps.{" "}
                                        </Text>
                                    </div>
                                    <div className="settings-toggle-wrap">
                                        <ToggleButton
                                            id={"account-switch"}
                                            value={"1"}
                                            isChecked={
                                                formValues.accountnotifications ==
                                                1
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setFormValues({
                                                    ...formValues,
                                                    accountnotifications:
                                                        formValues.accountnotifications ==
                                                        1
                                                            ? 0
                                                            : 1,
                                                });
                                                updateAccountnotificationForm(
                                                    formValues.accountnotifications ==
                                                        1
                                                        ? 0
                                                        : 1
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="system-notification-section-wrap">
                                    <Text
                                        type="body-text-1"
                                        fontWeight="medium-font"
                                    >
                                        System notifications
                                    </Text>
                                    <Text
                                        type="body-text-3"
                                        fontWeight="medium-font"
                                    >
                                        <span>
                                            You are receiving these
                                            notifications because you agreed to
                                            our{" "}
                                            <Link
                                                url={
                                                    process.env
                                                        .REACT_APP_SITE_URL +
                                                    "privacy-policy"
                                                }
                                                target="_blank"
                                            >
                                                privacy policy
                                            </Link>{" "}
                                            and{" "}
                                            <Link
                                                url={
                                                    process.env
                                                        .REACT_APP_SITE_URL +
                                                    "terms-of-use"
                                                }
                                                target="_blank"
                                            >
                                                terms of use
                                            </Link>
                                            . <br />
                                            If you no longer wish to receive
                                            these notifications please delete
                                            your account.
                                        </span>
                                    </Text>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {/*{!isLoading && <>*/}
                {/*    <div className="profile-form-wrap">*/}
                {/*        <form onSubmit={onSubmit}>*/}
                {/*            <div className="profile-form-repeat">*/}
                {/*                <div className="row">*/}
                {/*                    <div className="col-md-4">*/}
                {/*                        <div className="form-info-text">*/}
                {/*                            <h3>Marketing notifications</h3>*/}
                {/*                            <span>Get notified on topics of interest.</span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-md-8">*/}
                {/*                        <div className="row">*/}
                {/*                            <div className="col-md-6">*/}
                {/*                                <div className="setting-checkwrap newsletter-spinner-wrap">*/}
                {/*                                    <Checkbox id={"newsletter-switch"} className={"check-switch newsletter-spinner-checkbox"} onChange={(event) => { setFormValues({ ...formValues, newsletter: formValues.newsletter == 1 ? 0 : 1 }); updateSettingsForm(formValues.newsletter == 1 ? 0 : 1); }} value={"1"} checked={formValues.newsletter == 1 ? true : false} label={"Subscribe to our awesome newsletter on all things UX"} />*/}
                {/*                                    <span className='newsletter-spinner-span'>*/}
                {/*                                        {formLoading && (*/}
                {/*                                            <i*/}
                {/*                                                className="fa fa-spinner fa-spin"*/}
                {/*                                                aria-hidden="true"*/}
                {/*                                                style={{ marginLeft: "5px" }}*/}
                {/*                                            ></i>*/}
                {/*                                        )}*/}
                {/*                                    </span>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="profile-form-repeat">*/}
                {/*                <div className="row">*/}
                {/*                    <div className="col-md-4">*/}
                {/*                        <div className="form-info-text">*/}
                {/*                            <h3>In platform notifications</h3>*/}
                {/*                            <span>Check status alerts in real time when logged in and running live tests.</span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-md-8">*/}
                {/*                        <div className="row">*/}
                {/*                            <div className="col-md-6">*/}
                {/*                                <div className="setting-checkwrap coming_soon_heading">*/}
                {/*                                    Coming Soon*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="profile-form-repeat">*/}
                {/*                <div className="row">*/}
                {/*                    <div className="col-md-4">*/}
                {/*                        <div className="form-info-text">*/}
                {/*                            <h3>System notifications</h3>*/}
                {/*                            <span>you are receiving these notifications because you agreed to our <a href={*/}
                {/*                                process.env.REACT_APP_SITE_URL + "privacy-policy"*/}
                {/*                            }*/}
                {/*                                target="_blank">privacy policy</a> and <a href={*/}
                {/*                                    process.env.REACT_APP_SITE_URL +*/}
                {/*                                    "terms-of-service"*/}
                {/*                                }*/}
                {/*                                    target="_blank"> terms of use</a>. If you no longer wish to receive these notifications please delete your account..</span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-md-8">*/}
                {/*                        <div className="row">*/}
                {/*                            <div className="col-md-6">*/}
                {/*                                <div className="setting-checkwrap">*/}

                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            /!*<div className="col-md-12">*/}
                {/*                <div className="researcher-profile-form-btn   justify-content-end">*/}
                {/*                    <div className="inner-form-btn res-profle-btn-right">*/}
                {/*                        <button type="submit" className="btn form-btn">*/}
                {/*                            Save settings*/}
                {/*                            {formLoading && (*/}
                {/*                                <i*/}
                {/*                                    className="fa fa-spinner fa-spin"*/}
                {/*                                    aria-hidden="true"*/}
                {/*                                    style={{ marginLeft: "5px" }}*/}
                {/*                                ></i>*/}
                {/*                            )}*/}
                {/*                        </button>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*!/*/}
                {/*        </form>*/}
                {/*    </div>*/}
                {/*</>*/}
                {/*}*/}
            </div>
        );
    };

    const renderSettingsResearcher = () => {
        return (
            <div className="billing-plan-page-wrapper setting-page-wrapper">
                {user.role == "Researcher" && (
                    <>
                        <div className="page-heading-wrap accounts-page--heading">
                            <Text type="h1">Notification settings</Text>
                        </div>
                        {/* <ResearcherAccountNavigation activeMenu="settings" /> */}
                    </>
                )}
                {user.role == "Tester" && (
                    <TesterProfileNavigation
                        isLoading={false}
                        activeMenu="settings"
                    />
                )}

                {isLoading && (
                    <div className="project-listing-loader loader_section">
                        <div className="accountloading-box">
                            {" "}
                            <LoadingIconNew />
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <div className="settings-data-wrap">
                        <div className="settings-subtitle">
                            <Text type="subtitle-2" fontWeight="semi-bold-font">
                                Email notifications
                            </Text>
                            <Text type="body-text-2" fontWeight="medium-font">
                                Choose the emails and notifications you want to
                                receive from us at your registered email.
                            </Text>
                        </div>
                        <div className="profile-form-wrap">
                            <form onSubmit={onSubmit}>
                                <div className="marketing-section-wrap">
                                    <div className="form-info-text">
                                        <Text
                                            type="body-text-1"
                                            fontWeight="medium-font"
                                        >
                                            Marketing notifications
                                        </Text>
                                        <Text
                                            type="body-text-3"
                                            fontWeight="medium-font"
                                        >
                                            Email notifications about UserQ
                                            updates, new features, offers and
                                            latest trends.
                                        </Text>
                                    </div>
                                    <div className="settings-toggle-wrap">
                                        <ToggleButton
                                            id={"newsletter-switch"}
                                            value={"1"}
                                            isChecked={
                                                formValues.newsletter == 1
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setFormValues({
                                                    ...formValues,
                                                    newsletter:
                                                        formValues.newsletter ==
                                                        1
                                                            ? 0
                                                            : 1,
                                                });
                                                updateSettingsForm(
                                                    formValues.newsletter == 1
                                                        ? 0
                                                        : 1
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="marketing-section-wrap account-section-wrap">
                                    <div className="form-info-text">
                                        <Text
                                            type="body-text-1"
                                            fontWeight="medium-font"
                                        >
                                            Account related notifications
                                        </Text>
                                        <Text
                                            type="body-text-3"
                                            fontWeight="medium-font"
                                        >
                                            Based on your account activity we
                                            will send you emails to guide your
                                            next steps.{" "}
                                        </Text>
                                    </div>
                                    <div className="settings-toggle-wrap">
                                        <ToggleButton
                                            id={"account-switch"}
                                            value={"1"}
                                            isChecked={
                                                formValues.accountnotifications ==
                                                1
                                                    ? true
                                                    : false
                                            }
                                            onChange={(event) => {
                                                setFormValues({
                                                    ...formValues,
                                                    accountnotifications:
                                                        formValues.accountnotifications ==
                                                        1
                                                            ? 0
                                                            : 1,
                                                });
                                                updateAccountnotificationForm(
                                                    formValues.accountnotifications ==
                                                        1
                                                        ? 0
                                                        : 1
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="system-notification-section-wrap">
                                    <Text
                                        type="body-text-1"
                                        fontWeight="medium-font"
                                    >
                                        System notifications
                                    </Text>
                                    <Text
                                        type="body-text-3"
                                        fontWeight="medium-font"
                                    >
                                        <span>
                                            You are receiving these
                                            notifications because you agreed to
                                            our{" "}
                                            <Link
                                                url={
                                                    process.env
                                                        .REACT_APP_SITE_URL +
                                                    "privacy-policy"
                                                }
                                                target="_blank"
                                            >
                                                privacy policy
                                            </Link>{" "}
                                            and{" "}
                                            <Link
                                                url={
                                                    process.env
                                                        .REACT_APP_SITE_URL +
                                                    "terms-of-use"
                                                }
                                                target="_blank"
                                            >
                                                terms of use
                                            </Link>
                                            . <br />
                                            If you no longer wish to receive
                                            these notifications please delete
                                            your account.
                                        </span>
                                    </Text>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return user && user.role == "Researcher" ? (
        <LayoutResearcher
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"settings"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            {renderSettingsResearcher()}
            <a
                onClick={(e) => {
                    e.preventDefault();

                    setShowSupportPanel(true);
                }}
                href={process.env.REACT_APP_URL + "r/support"}
                target="_blank"
                className="support-button"
            >
                <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z"
                        stroke="#9759CB"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                Support
            </a>
        </LayoutResearcher>
    ) : (
        <LayoutTester
            isLoading={false}
            isAccount={true}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"settings"}
        >
            {renderSettings()}
        </LayoutTester>
    );
}