import { ReactSession } from "../../../../lib/secure_reactsession";
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { hideResult } from "../../../../services/researcher_test_results";
import { LoadingIcon } from "../../../loader/loadingIconNew";
import { Button } from "../../../themes/userq/Button/Button";
import { showToast } from "../../../../lib/helpers";

export default function HideParticipant({ openModal, closeModal, test_id }) {

    const [hideParticipantLoading, setHideParticipantLoading] = useState(false);

    const hideParticipantHandler = () => {
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test_id);
        data.append("test_result_id", openModal.test_result_id);

        setHideParticipantLoading(true);
        hideResult(data, token).then((response) => {
            setHideParticipantLoading(false);

            if (response.success) {
                closeModal(true);
            }
            // showToast(response.message,(response.success) ? "success" : "error");
        });
    }
    return (
        <Modal
            show={openModal.active}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap"
        >
            <Modal.Body className="">
                {hideParticipantLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon />
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <h2 className={`heading h2`}>{(openModal.hidden_result) ? 'Show participant' : 'Hide participant'}</h2>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal(false);
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img
                                        src={process.env.REACT_APP_URL + "img/close.svg"}
                                        alt="cross"
                                    />
                                </span>
                            </button>
                        </div>

                        <p className="subtitle-2 medium-font">Are you sure you want to {(openModal.hidden_result) ? 'show' : 'hide'} this participant?</p>

                        <div className="confirm-buttons-wrap">
                            <Button type="secondary" size="large" label={'Cancel'} onClick={() => { closeModal(false); }} />
                            <Button type="primary" size="large" label={"Confirm"} onClick={(e) => hideParticipantHandler()} />
                        </div>
                    </div>}
            </Modal.Body>
        </Modal>
    );
}