import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "./../Text/Text";
import { Icon } from "./../Icon/Icon";
import { Tag } from "./../Tag/Tag";
import { loadMoreFirstClickResultData } from "../../../../services/researcher_test_results";
export const PrototypeDropdown = ({
    questionArray,
    changeActiveQuestionProp,
    dropdownOpen,
    resultCount,
    test,
    taskSetting,
}) => {
    const wrapperRef = useRef(null);

    const [isListOpen, setListOpen] = useState(false);
    const [isActive, setActive] = useState(1);
    const [metaData, setMetaData] = useState({
        questionType: "",
        totalResultCount: 0,
        userType: "",
        aggregate_total_voters: 0,
        sort_time: "",
        sort_date: "",
        sort_vote: "",
        load_more: "",
        test_question_id: "",
        page: 1,
    });

    const changeActiveQuestion = (index, data) => {
        setActive(index);
        const dataAnswer = data.filter(
            (item, indexItem) => indexItem + 1 === index
        )[0];

        setMetaData({
            questionType: "",
            totalResultCount: 0,
            userType: "",
            aggregate_total_voters: 0,
            sort_time: "",
            sort_date: "",
            sort_vote: "",
            load_more: false,
            test_question_id: "",
            page: 1,
        });

        // setMetaData({
        //     'questionType': '',
        //     'totalResultCount': 0,
        //     'userType': (dataAnswer.question_type === '') ? "by-user" : "chart",
        //     'aggregate_total_voters': dataAnswer.aggregate_total_voters,
        //     sort_time: "",
        //     sort_date: "",
        //     sort_vote: "",
        //     load_more: (dataAnswer.result_count > 10) ? true : false,
        //     test_question_id: dataAnswer.id,
        //     page: 1
        // });
    };
    const showPreQuestion = (type) => {
        if (type === "increase") {
            // decrease active question length
            if (isActive !== 1) {
                changeActiveQuestion(isActive - 1, questionArray);
                changeActiveQuestionProp(isActive - 1);
            }
        } else {
            // increase active question length
            if (questionArray.length !== isActive) {
                changeActiveQuestion(isActive + 1, questionArray);
                changeActiveQuestionProp(isActive + 1);
            }
        }
    };

    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            //   console.log(ref.current);
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setListOpen(false);
                    dropdownOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        changeActiveQuestion(1, questionArray);
    }, []);

    return (
        <div
            className="question-dropdown-container result-question-dropdown prototype-question-dropdown"
            ref={wrapperRef}
        >
            <div
                className="dropdown-header"
                onClick={(event) => {
                    // console.log(event.target);
                    // console.log(event.target.classList.contains('dropdown-toggle-disabled'));
                    if (
                        !event.target.classList.contains(
                            "dropdown-toggle-disabled"
                        )
                    ) {
                        if (questionArray.length > 1) {
                            setListOpen(!isListOpen);
                            dropdownOpen(!isListOpen);
                        }
                    }
                }}
            >
                <div className="dropdown-header-left">
                    <div className="dropdown-header-top">
                        <p className="text-small-info text-uppercase">
                            <span className="active-question">{isActive}</span>
                            <span className="text-lowercase">of</span>{" "}
                            {questionArray.length} -{" "}
                            {test.methodology === "First click"
                                ? "First click"
                                : "Prototype"}
                            <span className="questions-dropdown-result-count">
                                ({resultCount}{" "}
                                {resultCount > 1 ? " answers" : " answer"})
                            </span>
                        </p>
                    </div>
                    <div className="dropdown-header-bottom">
                        <div className="dropdown-header-selected-question">
                            <Text
                                type="body-text-1"
                                fontWeight="medium-font"
                                cssClasses={
                                    test.language == "ar" ? "arabic-font" : ""
                                }
                                dir="auto"
                            >
                                {questionArray &&
                                    questionArray.map((items, index) => {
                                        return (
                                            <>
                                                {isActive === index + 1 && (
                                                    <div>
                                                        {/*{items.figma_filename}*/}
                                                        {taskSetting ===
                                                        "closed"
                                                            ? "Task " +
                                                              (index + 1)
                                                            : "Flow " +
                                                              (index + 1)}

                                                        {items.description &&
                                                            " - " +
                                                                items.description}
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                            </Text>
                        </div>
                        <div className="dropdown-header-arrow-buttons">
                            <div className="bd-que-slide-arrow">
                                <a
                                    onClick={() => {
                                        showPreQuestion("increase");
                                        if (isListOpen) {
                                            setListOpen(false);
                                            dropdownOpen(false);
                                        }
                                    }}
                                    className={`${
                                        isActive === 1 ? "disabled-link" : ""
                                    } dropdown-toggle-disabled`}
                                >
                                    <Icon
                                        value="back-arrow"
                                        size="medium"
                                        colorClass={
                                            "gray-50-svg dropdown-toggle-disabled"
                                        }
                                    />
                                </a>
                                <a
                                    onClick={() => {
                                        showPreQuestion("decrease");
                                        if (isListOpen) {
                                            setListOpen(false);
                                            dropdownOpen(false);
                                        }
                                    }}
                                    className={`${
                                        questionArray.length === isActive
                                            ? "disabled-link"
                                            : ""
                                    } dropdown-toggle-disabled`}
                                >
                                    <Icon
                                        value="forward-arrow"
                                        size="medium"
                                        colorClass={
                                            "gray-50-svg dropdown-toggle-disabled"
                                        }
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`dropdown-header-right ${
                        questionArray.length > 1 ? "" : "disabled-arrow"
                    }`}
                >
                    <div className="dropdown-header-chevron">
                        {isListOpen ? (
                            <Icon value="chevron-up" size="medium" />
                        ) : (
                            <Icon value="chevron" size="medium" />
                        )}
                    </div>
                </div>
            </div>

            <div className="dropdown-header-arrow-buttons none-for-desktop mobile-nav-arrows">
                <div className="bd-que-slide-arrow">
                    <a
                        onClick={() => {
                            showPreQuestion("increase");
                            if (isListOpen) {
                                setListOpen(false);
                                dropdownOpen(false);
                            }
                        }}
                        className={`${
                            isActive === 1 ? "disabled-link" : ""
                        } dropdown-toggle-disabled`}
                    >
                        <Icon
                            value="back-arrow"
                            size="medium"
                            colorClass={"gray-50-svg dropdown-toggle-disabled"}
                        />
                    </a>
                    <a
                        onClick={() => {
                            showPreQuestion("decrease");
                            if (isListOpen) {
                                setListOpen(false);
                                dropdownOpen(false);
                            }
                        }}
                        className={`${
                            questionArray.length === isActive
                                ? "disabled-link"
                                : ""
                        } dropdown-toggle-disabled`}
                    >
                        <Icon
                            value="forward-arrow"
                            size="medium"
                            colorClass={"gray-50-svg dropdown-toggle-disabled"}
                        />
                    </a>
                </div>
            </div>

            {isListOpen && (
                <div className="dropdown-list-hold-wrap">
                    <div className="dropdown-list-container">
                        {questionArray &&
                            questionArray.map((item, index) => (
                                <span
                                    className="dropdown-list-item"
                                    key={item.id}
                                    onClick={() => {
                                        setListOpen(false);
                                        dropdownOpen(false);
                                        changeActiveQuestion(
                                            index + 1,
                                            questionArray
                                        );
                                        changeActiveQuestionProp(index + 1);
                                    }}
                                >
                                    {isActive == index + 1 ? (
                                        <div className="dropdown-active-question dropdown-question-item flex-wrap">
                                            {test.language === "ar" ? (
                                                <>
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses={"mb-1"}
                                                    >
                                                        {taskSetting ===
                                                        "closed"
                                                            ? "Task " +
                                                              (index + 1)
                                                            : "Flow " +
                                                              (index + 1)}{" "}
                                                    </Text>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <Text
                                                type="body-text-1"
                                                fontWeight="medium-font"
                                                cssClasses={
                                                    test.language == "ar"
                                                        ? "arabic-font"
                                                        : ""
                                                }
                                            >
                                                <span className="none-for-arabic">
                                                    {taskSetting === "closed"
                                                        ? "Task " + (index + 1)
                                                        : "Flow " +
                                                          (index + 1)}{" "}
                                                    -
                                                </span>

                                                {item.description &&
                                                    item.description}
                                            </Text>
                                        </div>
                                    ) : (
                                        <div className="dropdown-question-item flex-wrap">
                                            {test.language === "ar" ? (
                                                <>
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses={"mb-1"}
                                                    >
                                                        {taskSetting ===
                                                        "closed"
                                                            ? "Task " +
                                                              (index + 1)
                                                            : "Flow " +
                                                              (index + 1)}{" "}
                                                    </Text>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <Text
                                                type="body-text-1"
                                                fontWeight="medium-font"
                                                cssClasses={
                                                    test.language == "ar"
                                                        ? "arabic-font"
                                                        : ""
                                                }
                                            >
                                                <span className="none-for-arabic">
                                                    {" "}
                                                    {taskSetting === "closed"
                                                        ? "Task " + (index + 1)
                                                        : "Flow " +
                                                          (index + 1)}{" "}
                                                    -
                                                </span>

                                                {item.description &&
                                                    item.description}
                                            </Text>
                                        </div>
                                    )}
                                </span>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};
