export const violations = [
    {
        label: "Feedback violations",
        options: [
            { value: "Poor/incoherent responses", label: "Poor/incoherent responses" },
            { value: "Off topic/guessed responses", label: "Off topic/guessed responses" },
            { value: "Identical responses", label: "Identical responses" },
        ]
    },
    {
        label: "Engagement violations",
        options: [
            { value: "Rushed test completion", label: "Rushed test completion" },
            { value: "Skipped/given up on majority of tasks", label: "Skipped/given up on majority of tasks" },
        ]
    },
    {
        label: "Behaviour violations",
        options: [
            { value: "Profanity", label: "Profanity" },
            { value: "Derogatory remarks", label: "Derogatory remarks" },
        ]
    }
];

const feedbackViolations = [
    { value: "Poor/incoherent responses", label: "Poor/incoherent responses" },
    { value: "Off topic/guessed responses", label: "Off topic/guessed responses" },
    { value: "Identical responses", label: "Identical responses" },
];

const engagementViolations = [
    { value: "Rushed test completion", label: "Rushed test completion" },
    { value: "Skipped/given up on majority of tasks", label: "Skipped/given up on majority of tasks" },
];
const behaviourViolations = [
    { value: "Profanity", label: "Profanity" },
    { value: "Derogatory remarks", label: "Derogatory remarks" },
];