import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
import { Box } from "../../../themes/userq/Box/Box";
import { RadioButton } from "../../../themes/userq/RadioButton/RadioButton";
import "./changebillingcycle-modal.css";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { amountFormat, capitalizeFirstLetter, showToast } from "../../../../lib/helpers";
import { comparePlanService } from "../../../../services/workspaces";

const ChangebillingcycleModal = ({ open, close, workspace, updateBillingCycle, loading }) => {

    const [plans, setPlans] = useState([]);

    const [pageLoading, setPageLoading] = useState(false);

    const [formValues, setFormValues] = useState({billing_cycle:null});

    const closeModal = () => {
        if (close) {
            close();
        }
    };
    useEffect(()=>{
        comparePlanApi();
    },[]);
    useEffect(()=>{
        if(workspace){
            if(workspace.nextBillingCyclePlan) { 
                setFormValues({...formValues, billing_cycle:workspace.nextBillingCyclePlan.billing_cycle});
            } else {
                setFormValues({...formValues, billing_cycle:workspace.billingCycle});
            }
        }
    },[open, workspace]);
    const comparePlanApi = () => {
        
        const token = ReactSession.get("token");
        setPageLoading(true);

        comparePlanService(token).then((response) => {
            if (response.success) {

                setPlans(response.plans);

                setPageLoading(false);

                /*
                response.plans.forEach((plan) => {
                    if (plan.type === "Individual") {
                        setIndividualPlan(plan);
                    } else if (
                        plan.type === "Team" &&
                        plan.billing_cycle === "yearly"
                    ) {
                        setTeamPlanYearly(plan);
                        setActiveTeamPlan(plan);
                    } else if (
                        plan.type === "Team" &&
                        plan.billing_cycle === "monthly"
                    ) {
                        setTeamPlanMonthly(plan);
                    }
                });*/
            } else {
                setPageLoading(false);
                showToast(response.message, "error");
            }
        });
    };

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap change-billing-cycle-modal"
        >
            <Modal.Body>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h2">Change billing cycle</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="billing-change-modal-data">
                        {plans.map((plan, index)=>{
                            {console.log(workspace)}
                            if(plan.type === "Team"){
                                return (
                                    <Box key={"plan"+plan.id} onClick={()=>{ if(!loading){ setFormValues({...formValues, billing_cycle:plan.billing_cycle})}} } size={"large"} cssClasses={` cursor-pointer ${formValues.billing_cycle==plan.billing_cycle ? 'active-box' :''} ${index>0?"mt-32 mb-32":""} `}>
                                        <div className="current-cycle-wrap">
                                            {workspace.billingCycle==plan.billing_cycle  ?
                                            <Text
                                                type={"caption"}
                                                cssClasses={"d-flex green-color"}
                                            >
                                                {" "}
                                                <Icon
                                                    value={"check mark"}
                                                    size={"small"}
                                                    colorClass={"success-green-200"}
                                                />{" "}
                                                Current billing cycle
                                            </Text>
                                            :
                                            <Text type={"caption"} cssClasses={"d-flex"}>
                                                The new billing cycle will start on the next
                                                billing date
                                            </Text>
                                            }
                                        </div>
                                        <div className="billing-cycle-radio-wrap d-flex justify-content-between">
                                            <RadioButton isChecked={formValues.billing_cycle==plan.billing_cycle ? true : false} label={capitalizeFirstLetter(plan.billing_cycle)} value={plan.billing_cycle} name={"billing_cycle"} />
                                            <div className="billing-cycle-change-price">
                                                <Text
                                                    type={"subtitle-2"}
                                                    fontWeight={"semibold-font"}
                                                >
                                                    ${plan.billing_cycle=="yearly"?amountFormat(plan.amount/12,2):amountFormat(plan.amount,2)}{" "}
                                                    <sub className="caption">
                                                        / per month
                                                    </sub>
                                                </Text>
                                                <Text
                                                    type={"caption"}
                                                    cssClasses={
                                                        "gray-color d-block text-right"
                                                    }
                                                >
                                                    billed {plan.billing_cycle.toLowerCase()}
                                                </Text>
                                            </div>
                                        </div>

                                        <div className="next-bill-data">
                                            <Text type={"caption"}>
                                                Next billing date: {workspace.nextPaymentDate
                                                    ? workspace.nextPaymentDate
                                                    : "-"}
                                            </Text>
                                        </div>
                                    </Box>
                                );
                            }
                        })}
                        <div className="modal-btn">
                            <Button
                                type="primary"
                                size="large"
                                label="Confirm changes"
                                cssclass={"w-100"}
                                onClick={()=>{ if(!loading && !((formValues.billing_cycle==workspace.billingCycle && !workspace.nextBillingCyclePlan) || (workspace.nextBillingCyclePlan && workspace.nextBillingCyclePlan.billing_cycle==formValues.billing_cycle))){ updateBillingCycle(formValues.billing_cycle)} } }
                                state={(formValues.billing_cycle==workspace.billingCycle && !workspace.nextBillingCyclePlan) || (workspace.nextBillingCyclePlan && workspace.nextBillingCyclePlan.billing_cycle==formValues.billing_cycle)?"disabled":"active"}
                                microLoading={loading}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ChangebillingcycleModal;
