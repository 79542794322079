import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import Select from "react-select";
import { ClearIndicator, ClearIndicatorV2, DropdownIndicator, DropdownIndicatorV3 } from "../../../../lib/helpers";
import { hasFlag } from "country-flag-icons";
import { countries } from "../../../../data/countries";
import { nationalities_other } from "../../../../data/nationalities_other";
import { Icon } from "../Icon/Icon";

import {components} from "react-select";

import Flags from "country-flag-icons/react/3x2";

const ValueContainer = ({children, ...props})=>{
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;

    var placeholder = false;

    var values = props.getValue()

    let value = '';
    if(values.length ==1){
        if(values[0].value==''){
            placeholder = true;
            
        } else {
            value = values[0].value;
        }
    }
    
    var exist = false;

    let Component = <></>;

    let title = '';

    countries.forEach(function(country){
        
        if(country.value==value){
            exist = hasFlag(country["code"])
            Component = Flags[country["code"]];
            title = country["code"];
        }
    })
    nationalities_other.forEach(function(country){
        if(country.value==value){
            exist = hasFlag(country["code"])
            Component = Flags[country["code"]];
            title = country["code"];
        }
    })
    
    if (!hasValue) {
        return (
        <components.ValueContainer {...props}>
            <div>{children} </div>
        </components.ValueContainer>
        );
    }
    return (
        <components.ValueContainer {...props}>
            <div  onClick={(e)=>{e.preventDefault(); e.stopPropagation();}} className="flag-value-container">
            {exist  &&
            <Component
                title={title}
                style={{ display: "inline-block", height: "1em", width: "1em" }}
            />}
            {/*(value=="Numeric" || value=="Stars" || value=="Emotions") &&
            <Icon value={value==='Stars'?'star':value} size={"small"}/>
            */}
            {children}
            </div>
        </components.ValueContainer>
    );
  
  };
export const DropdownComponent = ({ id, readOnly, error, type, isMulti, isClearable, onChange, dropDownRef, value, label,options, inactive, active, open, menuPlacement }) => {

    const wrapperRef = useRef(null);
    
    let selected_value = null;

    const [menuIsOpen, setMenuOpen] = useState(open);

    if(isMulti){
        selected_value = [];
        value.forEach(function(item){
            selected_value.push({"label":item,"value":item});
        })
    } else {
        if(value){
            /*selected_value ={
                label: value,
                value: value
            };*/
           // console.log(value)
            options.forEach(function(option){
                //console.log(option)
                if(value && ((typeof(value)=="string" && typeof(option.label)=="string" && option.label.toLowerCase() === value.toLowerCase()))  ){
                    selected_value ={
                        label: option.label,
                        value: option.label
                    };
                } else if(value && option.value===value){
                    selected_value ={
                        label: option.label,
                        value: option.value
                    };
                }
            });
            //console.log(selected_value);
            
        } else {
            selected_value ={
                label: '',
                value: ''
            };
        } 
    }
    //console.log(value)
    var options_updated = options;
    
    if (type == "nationality") {
        options_updated = nationalities_other
            .map((country) => {
                const exist = hasFlag(country["code"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["code"]];

                if(selected_value.value === country.value){
                    
                    return {
                        value: country["value"],
                        label: (
                            <><span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };

                    
                } else{
                    
                    return {
                        value: country["value"],
                        label: (
                            <><span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                    
                }
                
            })
            .filter(Boolean);

    } else if(type=="country") {
        options_updated = countries
            .map((country) => {
                const exist = hasFlag(country["code"]);
                if (!exist) {
                    return undefined;
                }
                const Component = Flags[country["code"]];

                if(selected_value.value === country.value){
                    return {
                        value: country["value"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: true
                    };
                } else {
                    return {
                        value: country["value"],
                        label: (
                            <>  <span className="country-flag-icon">
                                <Component
                                    title={country["code"]}
                                    style={{ display: "inline-block", height: "1em", width: "1em" }}
                                />
                            </span>
                                &nbsp;
                                <span>{`${country["value"]}`}</span>
                            </>
                        ),
                        isDisabled: false
                    };
                }
            })
            .filter(Boolean);

    }


    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            //   console.log(ref.current);
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMenuOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    useEffect(()=>{

        setMenuOpen(open);

    },[open])
    return (

        <div className={` dropdown-container form-group input-field mb-0 ${readOnly?"read-only-dropdown":""} ${error?"field-error":""} ${((value!='' && value!=null) || active==true)?'focused':''} ${(active==true)?'active':''}`} id={id} ref={wrapperRef}>
            
            <div className={`input-position-relative dropdown-field singleselect-dropdown`}
            onClick={(e)=>{
                e.preventDefault();

                e.stopPropagation();
               // setMenuOpen(!menuIsOpen)
            }}
            >
                
                {readOnly?

                
                <Select
                    menuIsOpen={false}
                    isClearable={isClearable}
                    isSearchable={false}
                    name=""
                    title=""
                    placeholder=""
                    options={options_updated}
                    onChange={onChange}
                    ref={dropDownRef}
                    isMulti={isMulti}
                    value={selected_value}
                    menuPlacement={menuPlacement}
                    components={!type ? { ValueContainer, ClearIndicator,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator } : { ValueContainer, ClearIndicator:ClearIndicatorV2,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator }}
                />:
                <Select
                    isClearable={isClearable}
                    isSearchable={false}
                    name=""
                    title=""
                    placeholder=""
                    options={options_updated}
                    onChange={onChange}
                    ref={dropDownRef}
                    value={selected_value}
                    menuPlacement={menuPlacement}
                    components={!type ? { ValueContainer,ClearIndicator,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator } : { ValueContainer, ClearIndicator:ClearIndicatorV2,DropdownIndicator:isClearable?DropdownIndicatorV3:DropdownIndicator }}
                />
                }
                <label className="control-label body-text body-text-2 dropdown-label" htmlFor={id}>
                    {label}
                </label>

            </div>
            {error != null && error != "Not applicable" && (
                <span className="caption">
                    {error}
                </span>
            )}
        </div>
    )
}
DropdownComponent.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    menuPlacement: PropTypes.string,
    inactive: PropTypes.bool,
    focussed: PropTypes.bool,
    open: PropTypes.bool,


};
DropdownComponent.defaultProps = {
    onChange: undefined,
    inactive: false,
    focussed: false,
    open:false,
    menuPlacement:"bottom"

};
