import { useD3 } from '../../../../hooks/useD3';
import React, {useState,useRef, useEffect} from 'react';
import * as d3 from 'd3';
import { Tooltip } from '../../../themes/userq/Tooltip/Tooltip';
var simulation;
function BubbleChart({ data=[] , width, height}) {
    
    const [cheight, setHeight] = useState(height);
    const tooltipref = useRef();
    const tipref = useRef();
    const [tooltip, showTooltip] = useState({
            tooltipLeft:0,
            tooltipTop:0,
            tooltipData:"",
            open: false
        });
    

    useEffect(()=>{
        setHeight(height);
        
    }, [height, width])
    const handlePointerMove = (event) => {

        let tipPosition = "bottom";
				
        var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 150;

        var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight+12+10 : 86;
        

        let containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width/2 ;
        let containerY = ('clientY' in event ? event.clientY : 0)  - tooltipref.current.getBoundingClientRect().top - tool_height ;
        
        var window_width = window.innerWidth;

        var window_height = window.innerHeight;
    
        if(event.clientX + tool_width/2 > window_width){
            containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;
        
        }
        if(event.clientX - tool_width/2 < 0){
            containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX)  ;
        }
       
        showTooltip({
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipData: <>{event.target.__data__.label}<br/>{(parseInt(event.target.__data__.labelValue) > 1 ? event.target.__data__.labelValue+" Participants" : event.target.__data__.labelValue+" Participant")}</>,
            open:true
        });
        //event.preventDefault();
        //event.target.__data__.data.label+" "+(parseInt(event.target.__data__.value) > 1 ? event.target.__data__.value+" Participants" : event.target.__data__.value+" Participant"),
    }
    const ref = useD3(
    (svg) => {
      
    
        

        var diameter = ref.current.getBoundingClientRect().width;
        
        //height = ref.current.getBoundingClientRect().width;

        
        var svg = svg.select(".plot-area");

        svg.selectAll('.node_app').remove();



        
        
        var max = 1;

        data.children.forEach(function(item){
            
            if(max < item.value){
                max = item.value;
            }
            
        });

       var node = svg
                    .selectAll("g")
                    .data(data.children)
                    .enter()
                    .append("g")
                    .attr("class", "node node_app").attr("transform", function(d) {
                        return "translate(0,0)";
                    })
                    .on("mousemove", function(d){
                        handlePointerMove(d);
                    })
                    .on("mouseleave", function(d){
                        d.preventDefault();
                        showTooltip({...tooltip, open:false}) 
                    }).call(d3.drag() // call specific function when circle is dragged
                    .on("start", dragstarted)
                    .on("drag", dragged)
                    .on("end", dragended));

        node.append("circle")
                    .attr("r", function(d){ 
                        if(d.value==max){
                            return 69;
                        }
                       
                        return 50;
                     })
                     
                    .attr("cx", 0)
                    .attr("cy", 0)
                    .style("fill", function(d){

                        if(d.value==max){
                            return "rgba(167, 37, 97, 1)";
                        }
                       
                        return "rgba(255, 109, 177, 1)";
                    })
                    /*.attr("stroke", "black")
                    .style("stroke-width", 1)*/;

            node.append("text")
                    .attr("dy", ".2em")
                    .style("text-anchor", "middle")
                    .text(function(d) {
                        
                        return d.label.length > 13 ? d.label.substring(0, 10)+"..." : d.label;
                    })
                    .attr("font-family", "Inter")
                    .attr("font-size", function(d){
                        if(d.value==max){
                            return 20;
                        }
                        return 15;
                    })
                    .attr("font-weight", 600)
                    .attr("fill", "rgb(255,255,255)");
    
                node.append("text")
                    .attr("dy", "1.7em")
                    .style("text-anchor", "middle")
                    .text(function(d) {
                        return d.labelValue;
                    })
                    .attr("font-family",  "Inter", "sans-serif")
                    .attr("font-size", function(d){
                        if(d.value==max){
                            return 20;
                        }
                        return 15;
                    })
                    .attr("font-weight", 600)
                    .attr("fill", "rgb(255,255,255)");
       
                    simulation = d3.forceSimulation()
                        .force("center", d3.forceCenter().x(width / 2).y(height / 2)) // Attraction to the center of the svg area
                        .force("charge", d3.forceManyBody().strength(0)) // Nodes are attracted one each other of value is > 0
                        .force("collide", d3.forceCollide().strength(.9).radius(function(d){ return (69) }).iterations(1)) // Force that avoids circle overlapping
                    simulation
                    .nodes(data.children)
                    .on("tick", function(d){
                        /*node
                            .attr("cx", function(d){ return d.x = Math.max(73, Math.min(width - 73, d.x)); })
                            .attr("cy", function(d){ return d.y = Math.max(73, Math.min(height - 73, d.y)); })*/

                            node.attr('transform', function(d){ d.x = Math.max(73, Math.min(width - 24, d.x)); d.y = Math.max(73, Math.min(height - 73, d.y)); return `translate(${d.x},${d.y})` } );

                           /* node
                            .attr("cx", function(d){ return d.x = Math.max(73, Math.min(width - 73, d.x)); })
                            .attr("cy", function(d){ return d.y = Math.max(73, Math.min(height - 73, d.y)); })*/
                        
                    });
                    function dragstarted(event, d) {
                        showTooltip({...tooltip, open:false}) 
                        if (!event.active) simulation.alphaTarget(.03).restart();
                        d.fx = d.x;
                        d.fy = d.y;
                      }
                      function dragged(event, d) {
                       // showTooltip({...tooltip, open:false}) 
                        d.fx = event.x;
                       d.fy = event.y;
                      }
                      function dragended(event, d) {
                        //showTooltip({...tooltip, open:false}) 
                        if (!event.active) simulation.alphaTarget(.03);
                        d.fx = null;
                        d.fy = null;
                      }
                    
                    
  
        },
        [data.length, width, height]
    );

     return (
        <div ref={tooltipref} style={{position:"relative"}}>
            {tooltip.open ? (
            <>
                <Tooltip
                    ref={tipref}
                    type={tooltip.tipPosition}
                    key={Math.random()} // needed for bounds to update correctly
                    style={{ zIndex:9999, display:"block", whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                    data={tooltip.tooltipData}
                    className="tooltipChart"
                
                />
            </>
            ): (
                <></>
            )}
        <svg
        ref={ref}
        style={{
            height: cheight,
            width: "100%",
            marginRight: "0px",
            marginLeft: "0px",
        }}
        >
        <g className="plot-area" />
        </svg>
        </div>
    );
}

export default BubbleChart;