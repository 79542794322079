import {handleApiErrorResponse} from "../lib/helpers";

export function getInvoicesService(page,order,workspaceId,token){
    return fetch(process.env.REACT_APP_API_END_POINT+'researcher/invoices?page='+page+"&order="+order+"&workspace_id="+workspaceId, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getInvoiceDataService(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "researcher/invoices/get",
    {
      method: "POST",
      body: JSON.stringify(formValues),
      headers: { "Content-Type":"application/json","Accept":"application/json",Authorization: "Bearer " + token },
    }
  ).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || res.status;
          return Promise.reject(error);
      }

      return data;

  }).catch((res)=>{
      handleApiErrorResponse(res);
  });
}
export function getInvoicePdfDataService(formValues, token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "researcher/invoices/pdf",
      {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { Authorization: "Bearer " + token },
      }
    ).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'receipt.pdf'); // set desired file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((res)=>{
        handleApiErrorResponse(res);
    });
  }