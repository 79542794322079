import { useNavigate } from 'react-router-dom';
import React, {useEffect,useState} from "react";
import LayoutResearcher from '../../layouts/layout_researcher.js';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import toast from 'react-hot-toast';
import moment from 'moment';
import Select from 'react-select';
import { Dropdown } from 'react-bootstrap';
import { checkIfEmail, normalize } from '../../../lib/helpers.js';
import { checkIfEmailUnique } from '../../../services/user.js';
import { getProRateService, subscribeToPlanService } from '../../../services/plans.js';
import { countries } from '../../../data/stripe_countries.js';
import Cleave from 'cleave.js/react';
import validator from 'validator';
import {atom, useResetRecoilState} from 'recoil';
import { FormattedMessage } from 'react-intl';
import {DropdownIndicator} from "../../../lib/helpers";


export default function Subscribe() {

    const navigate  = useNavigate();

    const activePlanState = atom({
      "key":"active_plan",
      "default":null
  })
  const reset = useResetRecoilState(activePlanState);
  
    const [formLoading, setFormLoading] = useState(false);

    const [memberLoading, setMemberLoading] = useState(false);

    const [priceLoading, setPriceLoading] = useState(false);

    const [invoicePrice, setInvoicePrice] = useState(0);

    const [plan, setPlan] = useState([]);

    const [term, setTerm] = useState("Monthly");

    const [collaborators, setCollaborators] = useState([]);

    const [collaboratorRow, setCollaboratorRow] = useState({ email:"", role:"Admin"});

    const [formValues, setFormValues] = useState({"name": "", "country":"","city":"","address":"","card_no":"","card_name":"","exp_date":"","cvv":"","future_use":1});

    const [formErrors, setFormErrors] = useState({email:null,"name": null, "country":null,"city":null,"address":null,"card_no":null,"card_name":null,"expiration_date":null,"cvv":null,  "error_class":null});

    const user = ReactSession.get("user");

    useEffect(() => {

      document.title = process.env.REACT_APP_NAME+" - Subscribe";

      let plan = ReactSession.get("selected_plan");

      if(!plan){
         navigate(-1);

      
      }
      
      setPlan(plan);

      let term = ReactSession.get("term");

      if(!term){
         navigate(-1);
      }
      setTerm(term);
         
       getProRate(plan.id, term, collaborators.length+1);

    }, []);

    const showErrorToast = (error) => {
        toast(
          <div className="toastinner">
            {error}
            <a
              onClick={() => {
                toast.dismiss();
              }}
            >
              &times;
            </a>
          </div>,
          {
            className: "errortoast",
            position: "bottom-center",
            duration: 2000,
          }
        );
      };

   const showFormError = (name, value) => {
      let formErrorsLocal = Object.assign({}, formErrors);
      
      for(var key in formErrors){
          formErrorsLocal[key] = null;
      }
      formErrorsLocal[name] = value;

      formErrorsLocal['error_class'] = 'input_error';

      setFormErrors(formErrorsLocal);


  }
  const validateCreditCard = (value) => {
    
    if (!validator.isCreditCard(value)) {        
    //   showFormError('card_no','Enter valid Credit Card Number!')
      setFormErrors({...formErrors,card_no:'Enter valid Credit Card Number!'});
    } else {
        setFormErrors({...formErrors,card_no:null});
    }
  }
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }

   const toggleCheck = (name, value) => {

       if(name=='term'){
            setTerm(value==1 ? "Yearly" : "Monthly");
            getProRate(plan.id, value==1 ? "Yearly" : "Monthly", collaborators.length+1)
       } else {
           setFormValue("future_use",value)
       }
      
   };
   const  addCollaborator = () => {
       
        return (e) => {
            showFormError("email",null);

            var error = false;

            if(collaboratorRow.email=='' || !checkIfEmail(collaboratorRow.email)){

                showFormError('email','Please enter a valid email');

                error = true;

            } else {

                var exist = false;

                collaborators.forEach(function(item){
                    if(item.email==collaboratorRow.email){
                        showFormError('email','Duplicate email.');

                        error = true;
                    }
                })

                if(!error){
                    setMemberLoading(true);
                    checkIfEmailUnique(collaboratorRow.email, ReactSession.get( "token")).then(response => {
                        setMemberLoading(false);
                        if(response.success){
    
                            if(!response.unique){
                                if (response.role == "Tester") {
                                  showFormError(
                                    "email",
                                    "User is already active as tester"
                                  );
                                } else if (response.role == "Researcher") {
                                  showFormError(
                                    "email",
                                    "User is already active in other account"
                                  );
                                } else if (response.role == "Collaborator") {
                                  showFormError(
                                    "email",
                                    "User is already active as collaborator"
                                  );
                                }
                                else{
                                showFormError("email", "Email already exist");
                                }
        
                                error = true;
                            }
                        } else {
                            error = true;
                        }
                        if(!error)
                        {
                            
                            const collaboratorsFinal = [...collaborators];
        
                            collaboratorsFinal.push(collaboratorRow);
        
                            setCollaborators(collaboratorsFinal);
        
                            setCollaboratorRow({email:"","role":"Admin"});

                            getProRate(plan.id, term, collaboratorsFinal.length+1)
    
                            window.setLabels();
                        }
                    });
                }

                
                
               

            }
        }
    }
   const setEmail = () => {
      return (e) => {
          if(e.key=="Enter"){
              e.preventDefault();
          }
          setCollaboratorRow(oldValues => ({...oldValues, ['email']: e.target.value }));
          setFormErrors({...formErrors,email:null});
      }
   }
   const setCollaboratorRole = (role) => {
      return () => {
          setCollaboratorRow(oldValues => ({...oldValues, ['role']: role }));
      }
   }
   const updateCollabortorRole = (role, index) => {

      return () => {
          const collaboratorsFinal = [...collaborators];

          collaboratorsFinal[index]["role"] = role;

          setCollaborators(collaboratorsFinal);
      }
  }
  const setFormValue = (name, value) => {
    setFormValues(oldValues => ({...oldValues, [name]: value }));
    setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
   
    
};
  
  const removeCollaborator = (index) => {

      return () => {

          const collaboratorsFinal = [...collaborators];
         
          collaboratorsFinal.splice(index,1);

          setCollaborators(collaboratorsFinal);


          getProRate(plan.id, term, collaboratorsFinal.length+1)
          
      }
  }
  const set = name => {
    return ({ target: { value } }) => {
        setFormValues(oldValues => ({...oldValues, [name]: value }));
        setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
    }
  };
  const renderCollaborators = () => {
        
      var render = [];

      collaborators.forEach(function(item,index){

         render.push(
            <tr  key={"collab"+item.id} >
                  <td><div className="add-member-email-td">{item.email}</div></td>
                  <td>
                     <Dropdown>
                        <Dropdown.Toggle id={"dropdownMenuButton"+item.user_id} className="dropdown-toggle">
                              {item.role}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={updateCollabortorRole('Admin', index)}>Admin</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={updateCollabortorRole('Editor', index)}> Editor</Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </td>
                  <td>
                     <div className="last-active-wrap">
                        &nbsp;
                        <span className="delete-table-row" onClick={removeCollaborator(index)}><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="img" /></span>
                     </div>
                  </td>
            </tr>
         );

      })
      
      return render;
      
   }
   const onSubmit =  (event) => {

      event.preventDefault();

      subscribe(null);

      return false;
    }

    const validateFullForm = () => {
        var error = false;
    
        var form_errors = resetFormErrors();

        if(formValues.name==''){
            form_errors = {
                ...form_errors,
                name: "Required field",
                error_class: "input_error",
              };

            error=true;
        } 
        if (formValues.country==''){
            form_errors = {
                ...form_errors,
                country: "Required field",
                error_class: "input_error",
              };
            error=true;
        }
        if (formValues.city==''){
            form_errors = {
                ...form_errors,
                city: "Required field",
                error_class: "input_error",
              };
            error=true;
            
        }
        if (formValues.address==''){
            form_errors = {
                ...form_errors,
                address: "Required field",
                error_class: "input_error",
              };
            error=true;
            
        }
        if (formValues.card_no==''){
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
              };
            error=true;
            
        }
        if (!validator.isCreditCard(formValues.card_no)) {
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
              };
            error=true;

        }
        if (formValues.card_name==''){
            form_errors = {
                ...form_errors,
                card_name: "Required field",
                error_class: "input_error",
              };
            error=true;
            
        }
        if (formValues.exp_date==''){
            form_errors = {
                ...form_errors,
                exp_date: "Required field",
                error_class: "input_error",
              };
            error=true;
            
        }
        if (formValues.cvv==''){
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
              };
            error=true;
            
        }
   
        setFormErrors(form_errors);
    
        return error;
      };
    
    const subscribe = (action) => {
        reset();
        resetFormErrors();

        
        if(!formLoading){

            var error = validateFullForm();

            // var error = false;

            // resetFormErrors();
            // if(formValues.name==''){
            //     showFormError('name','Required field');

            //     error=true;
            // } else if (formValues.country==''){
            //     showFormError('country','Required field');
            //     error=true;
            // }else if (formValues.city==''){
            //     showFormError('city','Required field');
            //     error=true;
                
            // }else if (formValues.address==''){
            //     showFormError('address','Required field');
            //     error=true;
                
            // }else if (formValues.card_no==''){
            //     showFormError('card_no','Required field');
            //     error=true;
                
            // } else if (!validator.isCreditCard(formValues.card_no)) {
        
            //     showFormError('card_no','Enter valid Credit Card Number!');
            //     error=true;

            // } else if (formValues.card_name==''){
            //     showFormError('card_name','Required field');
            //     error=true;
                
            // }else if (formValues.exp_date==''){
            //     showFormError('exp_date','Required field');
            //     error=true;
                
            // }else if (formValues.cvv==''){
            //     showFormError('cvv','Required field');
            //     error=true;
                
            // }

            
            if(!error){
                setFormLoading(true);

                const token = ReactSession.get("token");

                var formData =  Object.assign({}, formValues);

                formData["plan_id"] = plan.id;

                formData["term"] = term;

                formData["country"] = formData['country']['value'];

                formData["collaborators"] = collaborators;
                
                subscribeToPlanService(formData,token)
                .then(response=>{
                    
                    setFormLoading(false);

                    if(response.success){

                        ReactSession.set("show_subscribed",1); 

                        ReactSession.set("show_pro_subscribed",1);

                        ReactSession.set("plan",plan);     
                        ReactSession.set("user",response.user);     
    
                        navigate("/dashboard");
                        
                    } else {
                        var id = toast((
                                <div className='toastinner'>
                                    {response.message}
                                    <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                                </div>), {
                            className: 'errortoast',
                            position: 'bottom-center'
                        });
                    }
                })
                resetFormErrors();
            }
            else{
                showErrorToast(
                    <FormattedMessage id="Please fill required fields" />
                  );
            }
        }
    }
    function getProRate(plan_id, term, qty){
        var formData = {};

        formData["qty"] = qty;


        formData["plan_id"] = plan_id;

        formData["term"] = term.toLowerCase();

        setPriceLoading(true);

        getProRateService(formData,ReactSession.get("token"))
        .then(response=>{
            setPriceLoading(false);
            
            if(response.success){
               
                setInvoicePrice(response.prorate);

            } else {
                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                    </div>), {
                className: 'errortoast',
                position: 'bottom-center'
            });
            }
            
        })
    }
    return (
      <LayoutResearcher skipCheck={true} extendedFooter={false}>
        <div className="plan-and-subscribe-page-wrapper">
          <div className="profile-info-section">
            <div className="profile-info-left w-100">
              <div className="profile-info-text mt-0">
                <h1 className="h1">Pay and subscribe</h1>
                <p>
                  With our Pro plan, you unlock all of UserQ's powerful
                  features. We just need a few details from you.
                </p>
              </div>
            </div>
          </div>
          <div className="profile-form-wrap">
            <div className="selected-plan-wrapper">
              <div className="selected-plan-inner-data">
                <div className="selected-plan-top">
                  <div className="sel-pln-tp-left">
                    <p>
                      <span>
                        PRO PLAN - $
                        {term == "Monthly"
                          ? plan.price_monthly + " per month per user"
                          : plan.price_yearly + " per year per user"}{" "}
                      </span>{" "}
                      {term == "Yearly"
                        ? "Billed annually (save %" +
                          (
                            ((plan.price_monthly * 12 - plan.price_yearly) /
                              (plan.price_monthly * 12)) *
                            100
                          ).toFixed(2) +
                          ")"
                        : ""}
                    </p>
                  </div>
                  <div className="sel-pln-tp-right">
                    <label className="check-switch plan-change-switch">
                      <p className="monthly-plan-text">Monthly</p>
                      <input
                        type="checkbox"
                        checked={term == "Yearly" ? true : false}
                        onChange={(event) =>
                          toggleCheck(
                            "term",
                            event.currentTarget.checked ? 1 : 0
                          )
                        }
                      />
                      <span className="check-slider"></span>
                      <p className="yearly-plan-text">
                        <b>Yearly</b>
                      </p>
                    </label>
                  </div>
                </div>
                <div className="selected-plan-mid-wrap">
                  <div className="selected-plan-info-repeat">
                    <p>Next billing date</p>
                    <h2>
                      {term == "Yearly"
                        ? moment().add(365, "days").format("DD-MM-YY")
                        : moment().add(1, "M").format("01-MM-YY")}
                    </h2>
                  </div>
                  <div className="selected-plan-info-repeat">
                    <p>Active users</p>
                    <h2>{collaborators.length + 1}</h2>
                  </div>
                  <div className="selected-plan-info-repeat">
                    <p>{term == "Yearly" ? "Yearly" : "Monthly"} total</p>
                    <h2>
                      $
                      {term == "Monthly"
                        ? plan.price_monthly * (collaborators.length + 1)
                        : plan.price_yearly * (collaborators.length + 1)}
                    </h2>
                  </div>
                </div>
                {/*
                            <div className="selected-plan-bottom-wrap">
                                <div className="selected-plan-bottleft">
                                <p>Pro plan renewal <span>14/06/22 - 6 months left</span></p>
                                </div>
                                <div className="selected-plan-bottright">
                                <button type="submit" className="btn form-btn" data-toggle="modal" data-target="#change-plan">CHANGE PLAN</button>
                                </div>
                            </div>
                            */}
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="profile-form-repeat border-top">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-info-text">
                      <h3>Add members</h3>
                      <p>
                        Account members can be added as editors or admins.
                        Editors have full access to projects, studies and panel
                        features, but won't be able to add seats or see your
                        billing information. Admins have that same project
                        access, but can also purchase credits and manage any
                        billing information.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {collaborators.length > 0 && (
                      <div className="project-setting-table-wrap">
                        <table>
                          <thead>
                            <tr>
                              <th>User email</th>
                              <th>Role</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>{renderCollaborators()}</tbody>
                        </table>
                      </div>
                    )}
                    <div className="add-plan-member-sec">
                      <div className="create-projectmodal-form-group">
                        <div className="project-colaborator-form-wrap">
                          <div className={`input form-group  input-field`}>
                            <div className={`position-relative w-100 `}>
                              <input
                                type="email"
                                className={`form-control ${
                                  formErrors.email != null
                                    ? formErrors.error_class
                                    : ""
                                }`}
                                value={collaboratorRow.email}
                                id="email"
                                name="email"
                                onChange={setEmail()}
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                              />
                              <label className="control-label" htmlFor="email">
                                Enter email...
                              </label>
                              {formErrors.email != null && (
                                <span className={formErrors.error_class}>
                                  {formErrors.email}
                                </span>
                              )}
                            </div>
                            <span className="project-permission-drop-down">
                              <div className="dropdown">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id={"dropdownMenuButton"}
                                    className="dropdown-toggle"
                                  >
                                    {collaboratorRow.role}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={setCollaboratorRole("Admin")}
                                    >
                                      Admin
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={setCollaboratorRole("Editor")}
                                    >
                                      {" "}
                                      Editor
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="add-new-memberbtn-option mb-0">
                      <div
                        className="create-btn add-new-card-btn add-new-member-btn"
                        onClick={addCollaborator()}
                      >
                        Add new member
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              "images/plus-white.svg"
                            }
                          />
                        </span>
                        {memberLoading && (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                            style={{ marginLeft: "5px" }}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-form-repeat">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-info-text">
                      <h3>Billing information</h3>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field">
                            <input
                              type="text"
                              className={`form-control ${
                                formErrors.name != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                              value={formValues.name}
                              onChange={set("name")}
                              id="name"
                            />
                            <label htmlFor="name">Name/Company name</label>
                            <span
                              className="clear"
                              onClick={clearFormValue("name")}
                            >
                              <svg viewBox="0 0 24 24">
                                <path className="line" d="M2 2L22 22" />
                                <path className="long" d="M9 15L20 4" />
                                <path className="arrow" d="M13 11V7" />
                                <path className="arrow" d="M17 11H13" />
                              </svg>
                            </span>
                            {formErrors.name != null && (
                              <span className={formErrors.error_class}>
                                {formErrors.name}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group ">
                          <div className="input input-field form-group read-only-field">
                            <input
                              type="text"
                              className="form-control"
                              value={user.email}
                              readOnly
                              id="email"
                            />
                            <label htmlFor="email">Email</label>
                            <div className="data-field-icon">
                              <img
                                src={
                                  process.env.REACT_APP_URL +
                                  "images/fill-check.svg"
                                }
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input-field mb-0">
                            <div
                              className={`${
                                formErrors.country != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                            >
                              <Select
                                placeholder="Country"
                                options={countries}
                                onChange={(country) => {
                                  setFormValue("country", country);
                                }}
                                components={{DropdownIndicator}}
                              />
                            </div>
                            {formErrors.country != null && (
                              <span className={formErrors.error_class}>
                                {formErrors.country}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field">
                            <input
                              type="text"
                              className={`form-control ${
                                formErrors.city != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                              value={formValues.city}
                              onChange={set("city")}
                              id="city"
                            />
                            <label htmlFor="city">City</label>
                            <span
                              className="clear"
                              onClick={clearFormValue("city")}
                            >
                              <svg viewBox="0 0 24 24">
                                <path className="line" d="M2 2L22 22" />
                                <path className="long" d="M9 15L20 4" />
                                <path className="arrow" d="M13 11V7" />
                                <path className="arrow" d="M17 11H13" />
                              </svg>
                            </span>
                            {formErrors.city != null && (
                              <span className={formErrors.error_class}>
                                {formErrors.city}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field mb-0">
                            <input
                              type="text"
                              id="address "
                              className={`form-control ${
                                formErrors.address != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                              value={formValues.address}
                              onChange={set("address")}
                            />
                            <label htmlFor="address ">Address </label>
                            <span className="clear">
                              <svg
                                viewBox="0 0 24 24"
                                onClick={clearFormValue("address")}
                              >
                                <path className="line" d="M2 2L22 22" />
                                <path className="long" d="M9 15L20 4" />
                                <path className="arrow" d="M13 11V7" />
                                <path className="arrow" d="M17 11H13" />
                              </svg>
                            </span>
                            {formErrors.address != null && (
                              <span className={formErrors.error_class}>
                                {formErrors.address}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-form-repeat">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-info-text">
                      <h3>Your payment method</h3>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field">
                            <Cleave
                              id="card-number"
                              options={{ creditCard: true }}
                              onChange={set("card_no")}
                              className={`form-control ${
                                formErrors.card_no != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                              value={formValues.card_no}
                              onKeyUp={(e) => {
                                validateCreditCard(e.target.value);
                              }}
                            />
                            <label htmlFor="card-number">Card number</label>
                            <span
                              className="clear"
                              onClick={clearFormValue("card_no")}
                            >
                              <svg viewBox="0 0 24 24">
                                <path className="line" d="M2 2L22 22" />
                                <path className="long" d="M9 15L20 4" />
                                <path className="arrow" d="M13 11V7" />
                                <path className="arrow" d="M17 11H13" />
                              </svg>
                            </span>
                            {formErrors.card_no != null && (
                              <span className={formErrors.error_class}>
                                {formErrors.card_no}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field">
                            <input
                              type="text"
                              id="cardholder-name"
                              className={`form-control ${
                                formErrors.card_name != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                              value={formValues.card_name}
                              onChange={set("card_name")}
                            />
                            <label htmlFor="cardholder-name">
                              Cardholder’s name
                            </label>
                            <span
                              className="clear"
                              onClick={clearFormValue("card_name")}
                            >
                              <svg viewBox="0 0 24 24">
                                <path className="line" d="M2 2L22 22" />
                                <path className="long" d="M9 15L20 4" />
                                <path className="arrow" d="M13 11V7" />
                                <path className="arrow" d="M17 11H13" />
                              </svg>
                              
                            </span>
                            {formErrors.card_name != null && (
                                <span className={formErrors.error_class}>
                                  {formErrors.card_name}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field">
                            <Cleave
                              id="expiration-date"
                              options={{
                                date: true,
                                datePattern: ["m", "y"],
                              }}
                              onChange={set("exp_date")}
                              className={`form-control ${
                                formErrors.exp_date != null
                                  ? formErrors.error_class
                                  : ""
                              } `}
                              value={formValues.exp_date}
                            />
                            <label htmlFor="expiration-date">
                              Expiration date mm/yy
                            </label>
                            <span
                              className="clear"
                              onClick={clearFormValue("exp_date")}
                            >
                              <svg viewBox="0 0 24 24">
                                <path className="line" d="M2 2L22 22" />
                                <path className="long" d="M9 15L20 4" />
                                <path className="arrow" d="M13 11V7" />
                                <path className="arrow" d="M17 11H13" />
                              </svg>
                            </span>
                            {formErrors.exp_date != null && (
                              <span className={formErrors.error_class}>
                                {formErrors.exp_date}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile-form-group">
                          <div className="input form-group input-field">
                            <div className="position-relative w-100">
                              <Cleave
                                id="cvv"
                                options={{
                                  blocks: [3],
                                  numericOnly: true,
                                }}
                                onChange={set("cvv")}
                                className={`form-control ${
                                  formErrors.exp_date != null
                                    ? formErrors.error_class
                                    : ""
                                } `}
                                value={formValues.cvv}
                              />
                              <label className="control-label" htmlFor="cvv">
                                CVV/CVC
                              </label>
                              <span
                                className="clear"
                                onClick={clearFormValue("cvv")}
                              >
                                <svg viewBox="0 0 24 24">
                                  <path className="line" d="M2 2L22 22" />
                                  <path className="long" d="M9 15L20 4" />
                                  <path className="arrow" d="M13 11V7" />
                                  <path className="arrow" d="M17 11H13" />
                                </svg>
                              </span>
                              {formErrors.cvv != null && (
                                <span className={formErrors.error_class}>
                                  {formErrors.cvv}
                                </span>
                              )}
                            </div>
                            <div className="d-flex justify-content-end cvv-holder">
                              <span tooltip="CVV is the last three digits on the back of your credit card.">
                                What is cvv/cvc?{" "}
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/info-icon.svg"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom-control custom-checkbox mb-0">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            checked={formValues.future_use == 1 ? true : false}
                            onChange={(event) =>
                              toggleCheck(
                                "future_use",
                                event.currentTarget.checked ? 1 : 0
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          >
                            Save for future use
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-form-repeat border-0 mb-0">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-info-text">
                      <h3>Summary</h3>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="plan-summery-test">
                          {term == "Monthly" && (
                            <div className="sumery-text-repeat mt-0">
                              <span>
                                PRO PLAN - {collaborators.length + 1} seat
                                {collaborators.length > 0 ? "s" : ""} - Due
                                today for {moment().format("DD/MM/YY")} -{" "}
                                {moment().endOf("month").format("DD/MM/YY")}
                              </span>

                              <b>
                                {collaborators.length + 1} x $
                                {priceLoading && (
                                  <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                  ></i>
                                )}
                                {!priceLoading &&
                                  (
                                    Math.round(
                                      (invoicePrice /
                                        (collaborators.length + 1) /
                                        100) *
                                        100
                                    ) / 100
                                  ).toFixed(2)}
                              </b>
                            </div>
                          )}
                          <div
                            className={`sumery-text-repeat mt-24 ${
                              term == "Monthly" ? "gray-text" : ""
                            } `}
                          >
                            <span>
                              PRO PLAN - {collaborators.length + 1} seat
                              {collaborators.length > 0 ? "s" : ""} -{" "}
                              {term == "Monthly"
                                ? "Monthly plan"
                                : "Annual plan"}{" "}
                              -{" "}
                              {term == "Yearly"
                                ? moment().format("DD/MM/YY") +
                                  " - " +
                                  moment().add(365, "days").format("DD/MM/YY")
                                : "Starting " + moment().format("01/MM/YY")}
                            </span>
                            <b>
                              {collaborators.length + 1} x $
                              {term == "Monthly"
                                ? plan.price_monthly
                                : plan.price_yearly}
                            </b>
                          </div>
                          <div className="sumery-text-repeat">
                            <div className="total-biling-head">Total (USD)</div>
                            <div className="billing-total-ammount">
                              <h3>
                                $
                                {priceLoading && (
                                  <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                  ></i>
                                )}
                                {!priceLoading &&
                                  (invoicePrice / 100).toFixed(2)}
                                {term == "Yearly" && (
                                  <span className="perDur"> / Year</span>
                                )}
                              </h3>
                              {term == "Monthly" && <p>Due now</p>}
                              {term == "Yearly" && (
                                <p>
                                  Next payment to be made on{" "}
                                  {moment().add(365, "days").format("DD/MM/YY")}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-form-btn res-profle-btn-right justify-content-end plan-subscribe-btn">
                <button type="submit" className="btn form-btn">
                  PAY AND SUBSCRIBE
                  {formLoading && (
                    <i
                      className="fa fa-spinner fa-spin"
                      aria-hidden="true"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </LayoutResearcher>
    );
}