import { Text } from "../../../themes/userq/Text/Text";
import PieChart from "./pie_chart_v2";
import { theme } from "../../../themes/userq/theme";
import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button } from "../../../themes/userq/Button/Button";

export const ParticipantsNotAvailable = ({small}) =>{

    const slides = [{"title":"Sharing research findings","subtitle":"87% of researchers share their research results in live presentations"},{"title":"Sharing research findings","subtitle":"71% of publishers say content that looks well-organized on mobile devices boosts positive user experience feedback."},{"title":"Sharing research findings","subtitle":"3.42 seconds is the time needed for users to judge a web site’s credibility merely on the basis of its aesthetic appeal"}]
	

    return (
        <>
            <div class="participants-detailed-analycs-wrapper mb-60">
                <div className="no-page-data-wrap">
                    <div className="no-data-icon">
                        <Icon value={"attention"} colorClass={'gray-700-svg'} size={"large"} />
                    </div>

                    <Text type={"subtitle-1"} fontWeight="medium-font">Participants details are not available as the test was shared with users outside of the UserQ panel. </Text>
                </div>


                {!small && false &&
                <Box size={"large"}>
                    <div className="detailed-analycs-data">

                        <div className="detailed-analy-img">
                            <Icon value={"analytics-mac"} size={"original"} />
                        </div>
                        <div className="detailed-analy-text">
                            <Text type={"h4"}>
                                Looking for detailed analytics?
                            </Text>
                            <Text type={"body-text-2"} fontWeight="medium-font">
                                You can always recruit our participants to improve the performance of your tests and get valuable feedback.
                            </Text>
                            <Text type={"body-text-2"} fontWeight="medium-font">

                                You With more than 6000+ tester in MENA you can get results from our panel in minutes!. Choose the demographics or pick random testers from our extensive pool.
                            </Text>

                            <div className="recruit-tile-bottom mt-32">
                                <div className="recruit-bottom-left">
                                    <Button
                                        type={"primary"}
                                        label={"I want to use UserQ panel"}

                                        size={"large"}
                                    />
                                </div>
                                <div className="recruit-bottom-right"><Icon value={"credit"} size={"medium"} /> 6 credits per participant</div>
                            </div>

                        </div>
                    </div>
                </Box>
                }
            </div>
            {false && !small &&
            <div className="parti-overflow-top-graph-wrap shared-target-group-participants">
                <h3 className={`h3 font-inter mb-32`}>
                    There are no demographic details available,
                    as the test was shared with participants outside of the UserQ panel.
                </h3>

                <div className="participants-notification mb-32">
                    <div className="profile-complete-progress-wrap notify-info w-100">
                        <div className="pro-lft-wrap">
                            <strong>

                                Looking for detailed analytics like the one below?
                                Try our <a className={"target-group-participants-notify pink-color"} href="https://userq.com/panel/" target={"_blank"}>UserQ Panel</a> with thousands of participants and data points.
                            </strong>
                        </div>
                    </div>
                </div>

                <div className="shared-target-group-participants-img">
                    <img src={process.env.REACT_APP_URL + "images/test-links-participants-test-result.png"} className={"w-100"} />
                </div>

            </div>
            }
        </>
    );
}