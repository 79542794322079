//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import {Link, useNavigate} from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import {nFormatter} from "../../../../lib/helpers";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";


export default function StudyTargetModal({target_group,target_group_option_api,openModal,closeModal}) {
    const [targetId, setTargetId] = useState(null)

    useEffect(()=>{
        setTargetId(target_group && target_group.id);
    });
    const emirates = [];
    const other_countries = [];
    const ksa = [];
    if(target_group_option_api && target_group){

        target_group_option_api && target_group_option_api.emirates.forEach(function(item){
            target_group && target_group.countries && target_group.countries.split(",").forEach(function(item_country){
                if(item_country === item.value){
                    emirates.push(item);
                }
            });
        });



        target_group_option_api && target_group_option_api.countries.forEach(function(item){
            target_group && target_group.countries && target_group.countries.split(",").forEach(function(item_country){
                if(item_country === item.value){
                    other_countries.push(item);
                }
            });
        });



        target_group_option_api && target_group_option_api.ksa.forEach(function(item){
            target_group && target_group.countries && target_group.countries.split(",").forEach(function(item_country){
                if(item_country === item.value){
                    ksa.push(item);
                }
            });
        });


    }

    return (
<Modal
    show={openModal}
    centered
    size="lg"
    className="fade custom-modal-wrap"
    >
    <Modal.Body className="modal-lg p-0">
        <div className="modal-inner-text modal-ws">
            <button
                type="button"
                className="close"
                onClick={() => {
            closeModal(false);
            }}
            aria-label="Close"
            >
            <span aria-hidden="true">
            <img
            src={process.env.REACT_APP_URL + "images/cross.svg"}
            alt="cross"
            />
            </span>
            </button>
            <div className="modal-data-wrap">
                <h2>{ target_group && target_group.target_group_name } </h2>
                <div className="modal-scroll">
                    <div className="study-targetmodal-prev">
                        <p>Projects help you to organise and keep track of your tests</p>

                        {(target_group && (target_group.min_age != null || target_group.gender)) &&
                        <div className="study-modal-date-repeat">
                            <h3>Demographics</h3>
                            <div className="study-tgm-selected-count">
                                {(target_group && target_group.min_age != null) &&
                                <>
                                    <div className="tgm-count-data-wrp">
                                        <p>Age</p>
                                        <div className="tgm-selected-count">
                                            <span><b>{(target_group && target_group.min_age != null) ? target_group.min_age : ''} - {(target_group && target_group.max_age != null) ? target_group.max_age : ''} years old</b></span>
                                        </div>
                                    </div>
                                </>
                                }

                                {(target_group && target_group.gender) &&
                                <>
                                    <div className="tgm-count-data-wrp">
                                        <p>Gender</p>
                                        <div className="tgm-selected-count">
                                            <span>
                                                <b>
                                                    {target_group && (target_group.gender === 'Male') && 'Males only'}
                                                    {target_group && (target_group.gender === 'Female') && 'Females only'}
                                                    {target_group && (target_group.gender === 'Any') && 'Males & Females'}
                                                </b></span>
                                            {/*<span><b>{target_group && target_group.gender_female_perc} %</b> female</span>*/}
                                        </div>
                                    </div>
                                </>
                                }

                            {(!target_group ) &&
                                <>
                                    <div className="tgm-count-data-wrp">
                                        <p>Gender</p>
                                        <div className="tgm-selected-count">
                                            <span>
                                                <b>
                                                    Males & Females
                                                </b></span>
                                        </div>
                                    </div>
                                </>
                                }

                            </div>
                        </div>
                        }



                        {(target_group && target_group.nationalities) &&
                            <div className="study-modal-date-repeat">
                                <h3>Nationalitites</h3>
                                <div className="active-tiles-wrap">
                                    {
                                        target_group && target_group.nationalities.split(",").map(items=>{
                                            return (items && <span>{items}</span>);
                                        })
                                    }

                                </div>
                            </div>
                        }

                        {emirates.length > 0 &&
                        <div className="study-modal-date-repeat">
                            <h3>UAE RESIDENTS - Emirate of residency</h3>
                            <div className="active-tiles-wrap">
                                {(emirates.map(items => {
                                        return (<span>{items && items.value}</span>);
                                    })
                                )}
                            </div>
                        </div>
                        }

                        {ksa.length > 0 &&
                        <div className="study-modal-date-repeat">
                            <h3>KSA RESIDENTS - City of residency</h3>
                            <div className="active-tiles-wrap">
                                {(ksa.map(items => {
                                        return (<span>{items && items.value}</span>)
                                    })
                                )}
                            </div>
                        </div>
                        }
                        {other_countries.length>0 &&
                        <div className="study-modal-date-repeat">
                            <h3>Other countries</h3>
                            <div className="active-tiles-wrap">
                                {(other_countries.map(items => {
                                        return (<span>{items && items.value}</span>)
                                    })
                                )}
                            </div>
                        </div>
                        }

                        {target_group && target_group.education &&
                        <div className="study-modal-date-repeat">
                            <h3>Highest level of education</h3>
                            <div className="active-tiles-wrap">
                                <span>{target_group && target_group.education}</span>
                            </div>
                        </div>
                        }

                        {target_group && (target_group.hours_spend_online || target_group.devices || target_group.apps ) &&
                        <div className="study-modal-date-repeat">
                            <h3>Confidence with technology</h3>
                            <div className="study-tgm-selected-count">
                                {target_group && target_group.hours_spend_online &&
                                <div className="tgm-count-data-wrp">
                                    <p>Hours spent online per day</p>
                                    <div className="tgm-selected-count">
                                        <span>{target_group && target_group.hours_spend_online}</span>
                                    </div>
                                </div>
                                }

                                { target_group && target_group.devices &&
                                <div className="tgm-count-data-wrp">
                                    <p>Select favorite device...</p>
                                    {(target_group && target_group.devices) &&
                                    <div className="active-tiles-wrap">
                                        {
                                            target_group && target_group.devices.split(",").map(items=>{
                                                return (<span>{items}</span>);
                                            })
                                        }

                                    </div>
                                    }
                                </div>
                                }
                            </div>

                            {(target_group && target_group.apps) &&
                            <div className="tgm-inner-items-repeat">
                                <p className="sub-head-p">Apps used</p>
                                <div className="active-tiles-wrap">
                                    {
                                        target_group && target_group.apps.split(",").map(items => {
                                            return (<span>{items}</span>);
                                        })
                                    }

                                </div>
                            </div>
                            }
                        </div>
                        }

                        <div className="study-modal-date-repeat">

                            {target_group && (target_group.employment || target_group.industry || target_group.department) &&
                            <h3>Employment Status</h3>
                            }

                            {(target_group && target_group.employment) &&
                            <div className="tgm-inner-items-repeat">
                                <p className="sub-head-p">Current employment status</p>
                                <div className="active-tiles-wrap">
                                    {
                                        target_group && target_group.employment.split(",").map(items => {
                                            return (<span>{items}</span>);
                                        })
                                    }
                                </div>
                            </div>
                            }

                            {(target_group && target_group.industry) &&
                            <div className="tgm-inner-items-repeat">
                                <p className="sub-head-p">Industry</p>
                                <div className="active-tiles-wrap">
                                    {
                                        target_group && target_group.industry.split("|").map(items=>{
                                            return (<span>{items}</span>);
                                        })
                                    }
                                </div>
                            </div>
                            }

                            {(target_group && target_group.department) &&
                            <div className="tgm-inner-items-repeat">
                                <p className="sub-head-p">Department</p>
                                <div className="active-tiles-wrap">
                                    {
                                        target_group && target_group.department.split(",").map(items => {
                                            return (<span>{items}</span>);
                                        })
                                    }
                                </div>

                            </div>
                            }

                            {target_group && (target_group.earnings_match === 1) &&
                            <div className="study-modal-date-repeat">
                                <h3>Household</h3>
                                <div className="study-tgm-selected-count">
                                    {target_group && (target_group.household_members_condition || target_group.people) &&
                                    <div className="tgm-count-data-wrp">
                                        <p>Number of members in household</p>
                                        <div className="tgm-selected-count">
                                            <span
                                                className="text-capitalize">{target_group && target_group.household_members_condition} &nbsp;&nbsp;
                                                <b>{target_group && target_group.people}</b></span>
                                        </div>
                                    </div>
                                    }

                                    {target_group && (target_group.income_slab) &&
                                    <div className="tgm-count-data-wrp">
                                        <p>Household income $ per month</p>
                                        <div className="active-tiles-wrap">
                                            {
                                                target_group && target_group.income_slab.split("|").map(items=>{
                                                    return (<span>{items}</span>);
                                                })
                                            }
                                        </div>
                                        {/*<div className="tgm-selected-count">*/}
                                        {/*    <span><b>{target_group && target_group.income_slab}</b>  <span*/}
                                        {/*        className="text-lowercase"></span></span>*/}
                                        {/*</div>*/}
                                    </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="create-project-form-btn modal-flex-button btn-50-wrap edit-target-group-disabled-btn">
                    <button
                        className="button  secondary-btn"
                        onClick={() => {
                    closeModal();
                    }}
                    type="button"
                    >
                    Cancel
                    </button>
                    {target_group && target_group.total_active_tests !== 0 ?

                        <button
                            className="button  primary-btn disabled-button"
                            type="button"
                        >
                            <span className="call-made-icon">
                            <img
                                src={process.env.REACT_APP_URL + "images/lock-icon.svg"}
                                alt="img"
                            />
                            </span>
                            EDIT TARGET GROUP
                        </button>

                        :
                        <Link to={'/researcher/target_group/' + targetId} target="_blank"
                              className="button primary-btn">
                            EDIT TARGET GROUP
                        </Link>
                    }
                </div>
            </div>
        </div>
    </Modal.Body>
</Modal>
);
}