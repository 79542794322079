import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
export default function SessionEndedModal({
    open,
    refresh,
    refreshLoading,
    close,
}) {
    const closeModal = () => {
        close();
    };

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap add-overlay-background"
            onHide={() => {
                closeModal();
            }}
        >
            <Modal.Body className="">
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h2">You have been logged out</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="used-accound-mid-data">
                        <Text
                            type={"body-text-2"}
                            cssClasses={
                                "gray-text mb-32 d-flex align-items-center"
                            }
                            fontWeight={"medium-font"}
                        >
                            You’ve been logged out due to inactivity.
                        </Text>

                        

                        <div className="modal-btn">
                            <Button
                                microLoading={refreshLoading}
                                type="primary"
                                size={`large`}
                                label={"Refresh"}
                                iconLeft={<Icon value={"refresh"} size={"small"} colorClass={"gray-50-svg"} />}
                                onClick={() => {
                                    if(!refreshLoading){
                                        refresh();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
