import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import "./downgrade-workspace-modal.css";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Link } from "react-router-dom";
import { Button } from "../../../themes/userq/Button/Button";
import moment from "moment";
import ConfirmDowngradeWorkspaceModal from "./confirm-downgrade-workspace-modal";

export default function DowngradeWorkspaceModal({workspace,...props}) {
    const wrapperRef = useRef(null);
    const closeModal = () => {
        if (props.closeModal) {
            props.closeModal();
        }
    };

    return (
        <>
            <Modal
                show={props.openModal}
                onHide={props.closeModal}
                centered
                size="lg"
                className="fade downgrad-workspace-modal"
            >
                <Modal.Body ref={wrapperRef}>
                    <div className="downgrad-workspace-modal-data">
                        <div className="modal-header-top">
                            <Text type={"h3"}>Downgrade to Pay-As-You-Go</Text>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <Icon
                                        value="Close"
                                        size="medium"
                                        hover={true}
                                    />
                                </span>
                            </button>
                        </div>

                        <div className="downgrad-modal-inner-data">
                            <Text
                                type={"body-text-1"}
                                fontWeight={"medium-font"}
                            >
                                Your workspace will be downgraded to
                                Pay-As-You-Go on {workspace && workspace.nextPaymentDate?workspace.nextPaymentDate:"-"}
                            </Text>
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-color mt-20"}
                            >
                                If you change your mind before this date, you
                                can re-activate to Team plan from your workspace
                                billing page.
                            </Text>

                            <div className="help-box-wrap gray-box p-20 mt-32 mb-32">
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                    cssClasses={"mb-8"}
                                >
                                    Do you need help?
                                </Text>
                                <Text
                                    type={"body-text-3"}
                                    cssClasses={"gray-color"}
                                    fontWeight={"medium-font"}
                                >
                                    If you require additional information or
                                    assistance, please contact our
                                    <br />
                                    <Link to={"/r/support/contact"} target="_blank" className="link-text">
                                        support team.
                                    </Link>
                                </Text>
                            </div>
                            <div className="confirm-buttons-wrap modal-button-wrap">
                                <Button
                                    type="primary"
                                    size={`large`}
                                    label={"Continue"}
                                    onClick={() => {
                                        props.confirm();
                                    }}
                                />

                                <Button
                                    type="secondary"
                                    size={`large`}
                                    label={"Cancel"}
                                    onClick={() => {
                                        closeModal();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ConfirmDowngradeWorkspaceModal />
        </>
    );
}
