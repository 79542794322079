import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import {
    amountFormat,
    showToast,
    encryptClient,
} from "../../../../lib/helpers.js";
import { CreditCardForm } from "../../tests/components/credit_card_form.js";
import "./review-payment-modal.css";
import {
    setupWorkspaceIntent,
    updateWorkspacePaymentMethodService,
} from "../../../../services/workspaces.js";
import validator from "validator";
import { ReactSession } from "../../../../lib/secure_reactsession";

import { addNewPaymentMethodService } from "../../../../services/payment_methods.js";
import moment from "moment";

export default function ReviewPaymentModal({
    open,
    close,
    workspaceId,
    renderPaymentMethods,
    validatePaymentForm,
    formValues,
    setFormValues,
    addNewCard,
    setAddNewCard,
    resetFormErrors,
    resetPaymentMethods,
    workspace,
    paymentMethodCount,
    formErrors,
    setFormErrors,
    stripe,
    // paymentMethods,
    // setPaymentMethods,
    // setPaymentMethodCount,
    futurePaymentCard,
    changePaymentMethodLoading,
    setChangePaymentMethodLoading,
    sameCardError,
    setSameCardError,
    title
}) {
    ReactSession.setStoreType("localStorage");

    

    

    const closeModal = (send = false, data = null) => {
        if(!changePaymentMethodLoading){
            if(send){
                // console.log("in close modal onn", paymentMethodId, paymentMethods);
                // let data = null;
                // paymentMethods.forEach((method) => {
                //     if (method.id === paymentMethodId) {
                //         data = {
                //             paymentMethodId : method.stripe_payment_method_id,
                //             default: method.default,
                //             last_4: method.last_4,
                //             brand : method.brand,
                //             id : method.id
                //         }
                //     }
                // });
                // if(data){
                    // console.log("in close modal yes data", paymentMethodId);
                    close(true, data);
                // } else {
                //     // console.log("in close modal no data", paymentMethodId);
                //     close(false);
                // }
            } else {
                // console.log("in close modal off", paymentMethodId);
                close(false);
            }
        }
        
        
        
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, changePaymentMethodLoading]);
    }

    useOutsideAlerter(wrapperRef);

    const checkSaveBtnState = () => {
        if (formValues.payment_method_id == "") {
            var error = (validatePaymentForm() || sameCardError);

            if (error) {
                return "disabled";
            } else {
                return "active";
            }
        // } 
        // else if(formValues.payment_method_id && futurePaymentCard && formValues.payment_method_id === futurePaymentCard.id) {
        //     return "disabled";
        } else {
            return "active";
        }
        
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };

    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        var new_exp_date = formValues.exp_date.replaceAll(" ", "");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {
            if (
                new Date().getTime() >
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime() ||
                isNaN(
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime()
                )
            ) {
                form_errors = {
                    ...form_errors,
                    exp_date: "Expiry cannot be past date",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "expiration-date" : firsterrorid;
            }
        } else if (new_exp_date.length > 0) {
            form_errors = {
                ...form_errors,
                exp_date: "Invalid CVV/CVC",
                error_class: "input_error",
            };
            error = true;
            firsterrorid =
                firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }

        setFormErrors(form_errors);

        // console.log(firsterrorid)
        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };

    const changePaymentMethodCard = () => {
        resetFormErrors();

        if (!changePaymentMethodLoading) {
            var error = false;

            var card_error = false;

            if (formValues.payment_method_id == "") {
                card_error = validatePaymentMethodForm();

                error = card_error;
            }

            if (
                !error &&
                futurePaymentCard &&
                formValues.payment_method_id !== futurePaymentCard.id
            ) {
                setUpIntent(formValues.payment_method_id, workspaceId);
            } else if (
                futurePaymentCard &&
                formValues.payment_method_id === futurePaymentCard.id
            ) {
                closeModal();
            }
        }
    };

    const addNewPaymentMethod = () => {
        resetFormErrors();

        if (!changePaymentMethodLoading) {
            var error = false;

            //  resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setChangePaymentMethodLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token).then((response) => {

                    if (response.success) {
                        // setAddNewCard(false);
                        // setPaymentMethods([...paymentMethods, response.method]);
                        // setPaymentMethodCount(paymentMethodCount + 1);
                        setUpIntent(response.method.id, workspaceId);

                        window.setLabels();
                    } else {
                        if(response.error_code && response.error_code === "same_card_twice"){
                            setSameCardError(true);
                        }
                        setChangePaymentMethodLoading(false);
                        showToast(response.message, "error");
                    }
                });
            }
        }
    };

    const setUpIntent = (paymentMethodId = null, workspaceId = null) => {
        if (paymentMethodId && workspaceId) {
            let token = ReactSession.get("token");
            setChangePaymentMethodLoading(true);
            let formData = {};
            formData.payment_method_id = paymentMethodId;
            formData.workspace_id = workspaceId; // Corrected typo here
            setupWorkspaceIntent(formData, token)
                .then((response) => {
                    // setChangePaymentMethodLoading(false);
                    if (response.success) {
                        // console.log("setup intent log...", response);
                        if (response.setup_intent) {
                            if (response.setup_intent.status === "succeeded") {
                                updatePaymentMethod(
                                    paymentMethodId,
                                    workspaceId,
                                    response.setup_intent.id
                                );
                            } else if (
                                response.setup_intent.status ===
                                "requires_action"
                            ) {
                                console.log(stripe)
                                if (response.setup_intent.client_secret) {
                                    // console.log("inside cliient secret", stripe);
                                    stripe
                                        .confirmCardSetup(
                                            response.setup_intent.client_secret
                                        )
                                        .then((result)=>{handleStripeJsResult(result, response.setup_intent.id, paymentMethodId)});
                                }
                            }
                        }
                    } else {
                        setChangePaymentMethodLoading(false);
                        showToast(response.message, "error");
                    }
                })
                .catch((error) => {
                    setChangePaymentMethodLoading(false);
                });
        }
    };

    const updatePaymentMethod = (
        paymentMethodId = null,
        workspaceId = null,
        stripeIntentId = null
    ) => {
        if (paymentMethodId && workspaceId) {
            let token = ReactSession.get("token");
            setChangePaymentMethodLoading(true);
            let formData = {};
            formData.payment_method_id = paymentMethodId;
            formData.workspace_id = workspaceId;
            formData.stripe_intent_id = stripeIntentId;
            updateWorkspacePaymentMethodService(formData, token)
                .then((response) => {
                    setChangePaymentMethodLoading(false);
                    if (response.success) {
                        showToast(response.message, "success");

                        if(response.method){
                            closeModal(true, response.method);
                        } else {
                            closeModal(true);
                        }

                        
                    } else {
                        showToast(response.message, "error");
                    }
                })
                .catch((error) => {
                    setChangePaymentMethodLoading(false);
                });
        }
    };

    function handleStripeJsResult(result, stripeIntentId = null, paymentMethodId = null) {
        if (result.error) {
            // Show error in payment form
            if (result.error.code == "card_declined") {
                showToast(result.error.message, "error");
            }  else if (result.error.code) {
                showToast(result.error.message, "error");
            } else {
                showToast(
                    "Some error while processing card. Please try again.",
                    "error"
                );
            }
            
        } else {
            setChangePaymentMethodLoading(true);
            updatePaymentMethod(
                paymentMethodId,
                workspaceId,
                stripeIntentId
            );
        }
    }

    const changePaymentMethod = () => {
        if(addNewCard && !formValues.payment_method_id){
            if(!sameCardError){
                addNewPaymentMethod();
            }
            
            // console.log("add new method", formValues);
        } else {
            changePaymentMethodCard();
            // console.log("change method", formValues);
        }
        
    };

    useEffect(() => {
        // also check if there is any card
        if (open) {
            resetFormErrors();
            resetPaymentMethods();
        }
    }, [open]);

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap payment-info-modal"
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className={`modal-inner-text `}>
                    <div className="modal-header-top">
                        <Text type="h3">
                            {title}
                        </Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <>
                        <div className="payment-modal-data">
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                                cssClasses={"gray-text"}
                            >
                                As the admin of this workspace, it's mandatory
                                to maintain a valid payment method. Please add a
                                payment method now to ensure it's in place
                                before the next billing cycle.
                            </Text>

                            <div className="modal-bill-amount-wrap d-flex align-items-center">
                                <div className="bill-amount-box-repeat">
                                    <div className="bill-amount-box-inner">
                                        <Text
                                            type={"body-text-3"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"gray-color mb-8"}
                                        >
                                            Billing amount
                                        </Text>
                                        <Text type={"h3"}>
                                            
                                            {workspace && (workspace.planRenewalAmount || workspace.amount_due) ? "$" + amountFormat(
                                                (workspace.planRenewalAmount || workspace.amount_due),
                                                2
                                            ) : "-"}
                                        </Text>
                                    </div>
                                </div>
                                <div className="bill-amount-box-repeat">
                                    <div className="bill-amount-box-inner">
                                        <Text
                                            type={"body-text-3"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"gray-color mb-8"}
                                        >
                                            Next billing date
                                        </Text>
                                        <Text type={"h3"}>
                                            {workspace && (workspace.nextPaymentDate || workspace.next_billing_date)
                                                ? (workspace.nextPaymentDate || moment(workspace.next_billing_date).format("DD/MM/YYYY"))
                                                : "-"}
                                        </Text>
                                    </div>
                                </div>
                            </div>

                            <>
                                <div className="payment-form-repeat">
                                    <div className="row">
                                        <div className="col-md-12 addcard-outer">
                                            {renderPaymentMethods()}
                                            <>
                                                {!addNewCard && (
                                                    <div className="addnewbutton-box">
                                                        <Button
                                                            size={"small"}
                                                            type={"secondary"}
                                                            iconLeft={
                                                                <Icon
                                                                    value={
                                                                        "add"
                                                                    }
                                                                    colorClass={
                                                                        "gray-50-svg add-icon"
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                "Add new card"
                                                            }
                                                            cssClasses={"w-100"}
                                                            onClick={() => {
                                                                setAddNewCard(
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                            {addNewCard == true && (
                                                <>
                                                    <CreditCardForm
                                                        isValid={
                                                            !validatePaymentForm() &&
                                                            formValues.payment_method_id ==
                                                                ""
                                                                ? true
                                                                : false
                                                        }
                                                        formValues={formValues}
                                                        setFormValues={(
                                                            formValues
                                                        ) => {
                                                            setFormValues(
                                                                formValues
                                                            );
                                                            setSameCardError(false);
                                                        }}
                                                        isSavedForFuture={false}
                                                        isInValid={sameCardError ? true:false}
                                                    />

                                                    {(!validatePaymentForm() &&
                                                    formValues.payment_method_id ==
                                                        ""
                                                        ? true
                                                        : false) && (
                                                        <Text
                                                            type={"body-text-3"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                            cssClasses={
                                                                "gray-color d-flex align-items-center mt-20 mb-20 crad-saved-for-future"
                                                            }
                                                        >
                                                            <Icon
                                                                value={
                                                                    "check mark"
                                                                }
                                                                size={"medium"}
                                                                colorClass={
                                                                    "success-green-200-svg"
                                                                }
                                                            />
                                                            This card will be
                                                            used for the future
                                                            subscription
                                                            payments
                                                        </Text>
                                                    )}

                                                    <div className="card-secure-text">

                                                        <Icon
                                                            value={"lock"}
                                                            size={"medium"}
                                                            colorClass={
                                                                "gray-700-svg"
                                                            }
                                                        />

                                                        <span>
                                                            This is a secure 128-bit SSL encrypted payment
                                                        </span>
                                                    </div>
                                                </>
                                            )}

                                            {
                                                <div
                                                    className={
                                                        "addcardbuttonbox"
                                                    }
                                                >
                                                    <div className="full addcardbuttonbox-inner">
                                                        <div className="flex-buttons-wrap justify-content-end">
                                                            <Button
                                                                type="primary"
                                                                size={"large"}
                                                                cssclass={
                                                                    "w-100 ml-0 mr-0"
                                                                }
                                                                onClick={() => {
                                                                    changePaymentMethod();
                                                                }}
                                                                microLoading={
                                                                    changePaymentMethodLoading
                                                                }
                                                                label={"Save"}
                                                                state={checkSaveBtnState()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </>
                </div>
            </Modal.Body>
        </Modal>
    );
}
