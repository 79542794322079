import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/layout";
import { stripeCountries } from "../../data/stripe-countries";
import SelectElement from "../layouts/elements/fields/select_element";
import InputField from "../layouts/elements/fields/input";
import { useNavigate, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getTesterCreditSummaryService } from "../../services/credits";
import {
    updatetesterbankdetails,
    gettesterbankdetails,
    requesttesterpayout,
    getLoadMoreWalletHistory,
    deletetesterbankdetails,
    getProfile
} from "../../services/user";
import { bankdetailsCountries } from "../../data/countries_bankdetails";
import TextAreaField from './../layouts/elements/fields/textarea';
import { LoadingIcon } from "../loader/loadingIcon";
import { DropdownIndicator, roundNumber, showToast } from "../../lib/helpers";
import { countries as payoutCountries } from "../../data/countries.js";
import Select from "react-select";
import toast from "react-hot-toast";
import TesterProfileNavigation from "../profile/tester_profile_navigation";
import Radiobtn from "../layouts/elements/fields/radiobtn.js";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { PayoutOverviewBox } from "./components/payout_overview_box";
import { Text } from "../themes/userq/Text/Text";
import TesterAccountNavigation from "../profile/tester_account_navigation";
import { Icon } from "../themes/userq/Icon/Icon";
import { format } from "date-fns";
import { Button } from "../themes/userq/Button/Button";
import { getLoadMoreTesterHistory } from "../../services/test_result";
import AddBankAccountModal from "./components/add-bank-aacount";
import AddPaypalAccountModal from "./components/add-paypal-account";
import ConfirmationModal from "../themes/userq/Modal/ConfirmationModal";
import { useResetRecoilState, atom } from "recoil";
import { Link as UserQLink} from '../themes/userq/Link/Link.js';
import Skeleton from "react-loading-skeleton";
import { ScoreChip } from "../themes/userq/ScoreChip/ScoreChip";
import {VerifyId} from "../idVerification/VerifyId";
import DocumentNotMatch from "../themes/userq/Modal/DocumentNotMatch";
import {Box} from "../themes/userq/Box/Box";
import {ExpiryId} from "../idVerification/ExpiryId";
import { countries as sanctioned_countries } from "../../data/countries_sanctioned.js";

import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage';

export default function Wallet() {

    const verifyIdRef = useRef(null);

    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const [bankFormValues, setBankFormValues] = useState({ country: null, name: '', nickname: '', beneficiary_bank: '', branch: '', city: '', beneficiary_resident_country: null, account_no: '', account_no_type: "iban", type: 'bank' });
    const [paypalFormValues, setPaypalFormValues] = useState({ name: '', phone: '', email: '', type: 'paypal' });
    const [bankFormErrors, setBankFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");
    const [beneficiaryLabelClass, setBeneficiaryLabelClass] = useState("inactive_label");
    const [isBankAccountEditable, setIsBankAccountEditable] = useState(false);
    const [tempSelectedCountry, setTempSelectedCountry] = useState(null);
    const [tempBankFormValues, setTempBankFormValues] = useState({});
    const [minimumThresholdsForPayouts, setMinimumThresholdsForPayouts] = useState(0);
    const [payoutHistory, setPayoutHistory] = useState([]);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);
    const [openAddPaypalModal, setOpenAddPaypalModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState({
        open: false
    });
    const [confirmationBtnLoading, setConfirmationBtnLoading] = useState(false);

    let countrySelect = useRef(0);

    let beneficiaryCountrySelect = useRef(0);

    const [metaData, setMetaData] = useState({ sort_status: "", sort_date: "", load_more: false, page: 1 });

    const navigate = useNavigate();

    const [credits, setCredits] = useState(null);
    const [requestedPayout, setRequestedPayout] = useState(null);
    const [depositedPayout, setDepositedPayout] = useState(null);
    let user = ReactSession.get("user");

    const [isProfileExpiredAt, setIsProfileExpiredAt] = useState('');

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const reset = useResetRecoilState(creditsState);
    const [testerscore, setTesterScore] = useState(null);
    const [userBlocked, setUserBlocked] = useState(false);

    const accountstatus=ReactSession.get("accountstatus");
    let score = ReactSession.get("testerscore");

    const [isProfileVerified, setIsProfileVerified] = useState('');
    const [isProfileVerifiedAt, setIsProfileVerifiedAt] = useState('');
    const [isProfileAttempts, setIsProfileAttempts] = useState(0);

    const [documentNotMatch, setDocumentNotMatch] = useState({ open: false });

    const [displaySanctionedMessage, setDisplaySanctionedMessage] = useState(false);

    useEffect(() => {

        document.title = "Your wallet | "+process.env.REACT_APP_NAME;

        let userData = ReactSession.get('user');
        // check profile is verified or not
        //setIsProfileVerified(userData.profile_verification_status);
        //setIsProfileVerifiedAt(userData.profile_verified_at);



        fetchCreditsSummary();
        gettesterbank();
        getProfieData();
    }, []);

    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;
    
        // Update state based on the scroll position
        setIsScrolled(scrolled);
      };
      useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;
    
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
    
          // Remove the event listener when the component unmounts
          return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
          };
        }
    }, [payoutHistory]);
    const getProfieData = ()=>{

        let token = ReactSession.get("token");
        setLoading(true);

        getProfile(token).then((response) => {
            setLoading(false);


            // check document not matched then open madal
            if(ReactSession.get("document_not_matched_model")){

                setDocumentNotMatch({
                    open: true,

                });
                ReactSession.set("document_not_matched_model",false);
            }

            if (response.success) {

                // check profile is verified or not
                setIsProfileVerified(response.data.user.profile_verification_status);
                setIsProfileVerifiedAt(response.data.user.verified_at);

                setIsProfileExpiredAt(response.data.user.document_expiry_date_left);
                setIsProfileAttempts(response.data.user.expired_document_attempt);
            }
        });
    }

    const sortData = (type) => {
        if (type === 'status') {
            if (metaData.sort_status === 'asc') {
                sortDataApi('status', 'desc');
            } else {
                sortDataApi('status', 'asc');
            }
        } else if (type === 'date') {
            if (metaData.sort_date === 'asc') {
                sortDataApi('date', 'desc');
            } else {
                sortDataApi('date', 'asc');
            }
        }


    }

    const sortDataApi = (sortColumn, orderBy) => {


        let token = ReactSession.get("token");
        getLoadMoreWalletHistory({
            page: 1,
            sort_column: sortColumn,
            order_by: orderBy
        }, token).then((response) => {

            if (response.success) {

                setPayoutHistory(response.payoutHistory);

                if (sortColumn === 'status') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_status: "desc", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_status: "asc", sort_date: "", page: 1, load_more: response.more_records });
                    }
                } else if (sortColumn === 'date') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_status: "", sort_date: "desc", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_status: "", sort_date: "asc", page: 1, load_more: response.more_records });
                    }
                }
                // setMetaData({...metaData,page:metaData.page+1,load_more:response.more_records});

            } else {
                showToast(response.message, "error");
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);
        let token = ReactSession.get("token");
        getLoadMoreWalletHistory({
            page: metaData.page + 1,
            sort_column: metaData.sort_status ? "status" : "date",
            order_by: metaData.sort_status ? metaData.sort_status : "desc"
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setPayoutHistory(payoutHistory.concat(response.payoutHistory));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                showToast(response.message, "error");
            }
        });
    }

    const fetchCreditsSummary = () => {

        if (!credits) {
            let token = ReactSession.get("token");

            setIsLoading(true);

            getTesterCreditSummaryService(token).then((response) => {
                setIsLoading(false);
                if (response.success) {
                    ReactSession.set("credits", response.summary.credits_balance);
                    setTesterScore(response.score);
                    if(response.account_status=="blocked"){
                        setUserBlocked(true);
                    }
                    setCredits(response.summary.credits_balance);
                    setRequestedPayout(response.summary.requestedpayoutamount);
                    setDepositedPayout(response.summary.depositedpayoutamount);
                }
            });
        }
    };

    const requestpayout = () => {

        // check user id is verified
        if(isProfileVerified !== 'verified'){
            verifyIdVerificationHandler();
            return false;
        }

        if(isProfileExpiredAt !== "" && isProfileExpiredAt ===0){
            showToast("Please update your expired ID document before requesting a new payout.", "error");
            return false;
        }

        if(((credits >= minimumThresholdsForPayouts) || (requestedPayout + depositedPayout >= minimumThresholdsForPayouts))
            && (credits >= minimumThresholdsForPayouts)){

        }else{
            showToast("You can request payout after earning $30", "error");
            return false;
        }
        if(!checkBankDetailsEntered()){
            showToast("Add your bank/paypal account to request payout", "error");
            return false;
        }
        // (((credits >= minimumThresholdsForPayouts) || (requestedPayout + depositedPayout >= minimumThresholdsForPayouts))
        //     && (credits >= minimumThresholdsForPayouts))
        //     ? (checkBankDetailsEntered()) ? requestpayout : false : false

        setLoading(true);

        const token = ReactSession.get("token");
        var data = new FormData();
        data.append("bankdetails", JSON.stringify(bankFormValues));
        data.append("selectedbankcountry", selectedCountry);
        data.append("amount", credits);
        requesttesterpayout(data, token).then((response) => {
            setCredits(response.balance);
            setRequestedPayout(response.requestedpayoutamount);
            setDepositedPayout(response.depositedpayoutamount);
            reset();

            setLoading(false);
            if (response.success) {

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event' : 'request_payout',
                    'method' : response.type,
                    'value' : response.requestedpayoutamount,
                    'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                });
                gettesterbank();
                showToast('Your payout request has been submitted successfully! Your payment should be processed within 10/15 business days. If there is an issue, we will contact you via email.', "success");


            } else {

                showToast(response.message, "error");
            }
        });
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, bankFormErrors);

        for (var key in bankFormErrors) {
            formErrorsLocal[key] = null;
        }
        setBankFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }

    const validateBankDetails = () => {

        var error = false;

        var form_errors = resetFormErrors();

        var firsterrorid = null;

        if (!bankFormValues.country) {

            form_errors = {
                ...form_errors,
                country: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "country" : firsterrorid;

        }
        if (bankFormValues.name == '') {

            form_errors = {
                ...form_errors,
                name: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "name" : firsterrorid;

        }
        if (bankFormValues.beneficiary_bank == '') {

            form_errors = {
                ...form_errors,
                beneficiary_bank: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "beneficiary_bank" : firsterrorid;

        }
        if (bankFormValues.branch == '') {

            form_errors = {
                ...form_errors,
                branch: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "branch" : firsterrorid;

        }
        if (bankFormValues.city == '') {

            form_errors = {
                ...form_errors,
                city: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "city" : firsterrorid;

        }
        if (!bankFormValues.beneficiary_resident_country) {

            form_errors = {
                ...form_errors,
                beneficiary_resident_country: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "beneficiary_resident_country" : firsterrorid;

        }
        if (!bankFormValues.account_no) {

            form_errors = {
                ...form_errors,
                account_no: "Required!",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "account_no" : firsterrorid;

        }
        setBankFormErrors(form_errors);

        /*if(firsterrorid!=null){
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
        }*/

        return error;

    }

    const updatebankdetails = () => {
        var error = validateBankDetails();
        if (!error) {
            setLoading(true);
            const token = ReactSession.get("token");
            var data = new FormData();
            data.append("bankdetails", JSON.stringify(bankFormValues));
            data.append("selectedbankcountry", bankFormValues.country);
            updatetesterbankdetails(data, token).then((response) => {

                setLoading(false);
                if (response.success) {
                    gettesterbank();
                    setIsBankAccountEditable(false);

                    toast(
                        <div className="toastinner">
                            Your Bank account details have been updated successfully
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "successtoast",
                            position: "bottom-center",
                            duration: 5000,
                        }
                    );

                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: 5000,
                        }
                    );
                    // console.log(response);
                }
            });
        }
    };

    const gettesterbank = () => {
        const token = ReactSession.get("token");
        gettesterbankdetails(token).then((response) => {
            if (response.success) {

                setPayoutHistory(response.payoutHistory);

                // setIsProfileExpiredAt(response.document_expiry_date_left);
                // setIsProfileAttempts(response.expired_document_attempt);

                setMetaData({
                    sort_status: "",
                    sort_date: "",
                    sort_reward: "",
                    load_more: (response.result_count > 10) ? true : false,
                    page: 1
                });

                setMinimumThresholdsForPayouts(parseInt(response.minimum_thresholds_for_payouts));

                var sanctioned_country = false;

                    sanctioned_countries.forEach(function(country){
                        if(response.country==country.value){
                            sanctioned_country = true;
                        }
                    });
                    setDisplaySanctionedMessage(sanctioned_country);
                    
                if (response.bankdetails != null) {




                    if (response.bankdetails.type && response.bankdetails.type === 'paypal') {

                        setPaypalFormValues({
                            name: response.bankdetails.name ? response.bankdetails.name : null,
                            phone: response.bankdetails.phone ? response.bankdetails.phone : null,
                            email: response.email ? response.email : null,
                            beneficiary_resident_country: response.country ? response.country : null,
                            type: 'paypal'
                        });
                        setBankFormValues({
                            ...bankFormErrors,
                            type: 'paypal'
                        })
                    } else {
                        
                        setBankFormValues({
                            country: response.bankdetails.country ? response.bankdetails.country : null,
                            // name: response.bankdetails.name ? response.bankdetails.name : '',
                            name: user.first_name+' '+user.last_name,
                            nickname: response.bankdetails.nickname ? response.bankdetails.nickname : '',
                            beneficiary_bank: response.bankdetails.beneficiary_bank ? response.bankdetails.beneficiary_bank : '',
                            branch: response.bankdetails.branch ? response.bankdetails.branch : '',
                            city: response.bankdetails.city ? response.bankdetails.city : '',
                            beneficiary_resident_country: response.country ? response.country : null,
                            // beneficiary_resident_country: response.bankdetails.beneficiary_resident_country ? response.bankdetails.beneficiary_resident_country : null,
                            account_no_type: response.bankdetails.account_no_type ? response.bankdetails.account_no_type : 'iban',
                            account_no: response.bankdetails.account_no ? response.bankdetails.account_no : '',
                            type: 'bank'
                        });
                        
                    }


                }else{
                    setBankFormValues({
                        ...bankFormValues,
                        name: user.first_name+' '+user.last_name,
                        beneficiary_resident_country: response.country ? response.country : null
                    });

                    setPaypalFormValues({
                        ...paypalFormValues,
                        email: response.email ? response.email : null,
                        beneficiary_resident_country: response.country ? response.country : null,
                    });
                }
            } else {
                showToast(response.message, "error");
                // console.log(response);
            }
        });
    };






    // const countryChange = (item) => {
    //     setBankFormValues({...bankFormValues, country:item.value});
    //     setCountryLabelClass("active_label");
    //     setBankFormErrors({...bankFormErrors,country:null});
    //
    // };
    // const beneficiaryCountryChange = (item) => {
    //     setBeneficiaryLabelClass("active_label");
    //     setBankFormErrors({...bankFormErrors,beneficiary_resident_country:null});
    //
    //     if(item.value=="United Arab Emirates"){
    //         setBankFormValues({...bankFormValues, account_no_type:"iban", beneficiary_resident_country:item.value});
    //     } else {
    //         setBankFormValues({...bankFormValues, beneficiary_resident_country:item.value});
    //
    //     }
    //
    // };
    const checkBankDetailsEntered = () => {

        if (bankFormValues.type === 'paypal') {
            if (paypalFormValues.name && paypalFormValues.email && paypalFormValues.phone) {

                return true;
            }
        } else {
            if (bankFormValues.country && bankFormValues.name && bankFormValues.beneficiary_bank && bankFormValues.branch && bankFormValues.city && bankFormValues.beneficiary_resident_country && bankFormValues.account_no) {

                return true;
            }
        }

        return false;
    }

    const deleteBankAccountApi = (accountType) => {
        setConfirmationBtnLoading(true);
        const token = ReactSession.get("token");
        var data = new FormData();
        deletetesterbankdetails(data, token).then((response) => {

            setConfirmationBtnLoading(false);
            if (response.success) {

                setConfirmModal({ open: false });


                // console.log(accountType)
                if (accountType === 'paypalAccountdelete') {

                    setPaypalFormValues({...paypalFormValues, name: '', phone: '', type: 'paypal' })

                    showToast(" Your paypal account details have been deleted successfully", "success");
                } else {

                    setBankFormValues({...bankFormValues, country: null, nickname: '', beneficiary_bank: '', branch: '', city: '', account_no: '', account_no_type: "iban", type: 'bank' })

                    showToast(" Your bank account details have been deleted successfully", "success");
                }


            } else {

                showToast(response.message, "error");

            }
        });
    }

    const confirmHandlerAsas = ()=>{

    }
    //  verify id veryfication handler
    const verifyIdVerificationHandler = ()=>{


        // showToast(<>ID verification is required for payout. Verify your identity</>,"error")
        if(isProfileVerified === 'id_not_matched_profile' || isProfileVerified === 'unverified' || (isProfileVerified === 'expired_document'  && isProfileExpiredAt === "") || isProfileVerified === 'invalid_document'){
            verifyIdRef.current.handleToastMessage(isProfileVerified,isProfileVerifiedAt);
        }


    }
    return (
        <LayoutTester isLoading={false} skipCheck={false} isAccount={true} extendedFooter={false} activeMenu={"wallet"}>
            <div className="credits-page-wrapper wallet-page-wrapper page-min-height">
                {accountstatus=="blocked" && <div className="account-blocked-message">
                    <div><Icon colorClass={"danger-red-200"} value={"blocked"} size="large"/></div>
                    <div>
                        <Text type={"subtitle-1"} children="Your payout cannot be redeemed as your profile is currently blocked."/>
                        <Text type={"body-text-2"}>Please contact <a class='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile"} target="_blank">UserQ support</a>, to guide you through the next steps.</Text>
                    </div>
                </div>}
                <div className='page-heading-wrap accounts-page--heading'>
                    <Text type="h1">Account</Text>
                    <div className="accounts-score-chip">
                        {/* {isLoading?<Skeleton className="header-credit-skeleton" width={180} height={40}/>:<ScoreChip testerscore={testerscore}/>} */}
                        <ScoreChip testerscore={score}/>
                        <div className='account-guidelines-link'>
                            <Text type="body-text-3">
                                Read more about  <a class='faq-support-link' href={process.env.REACT_APP_URL+"t/tester-guidelines"} target="_blank">tester guidelines</a>
                            </Text>
                        </div>
                    </div>
                </div>
                <TesterAccountNavigation activeMenu={"wallet"} />

                <div className="profile-info-section d-block pb-64 pt-64 wallet-page-data">
                    <div className="data-container">
                        {/*<div className="profile-info-left w-100">*/}
                        {/*    <div className="profile-info-text  mt-0">*/}
                        {/*        <h1 className="page-main-heading">Wallet</h1>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {isLoading ?
                            <div className="accountloading-box"><LoadingIcon /></div>
                            :
                            <>
                                <div className="wallet-top-wrap mt-40">

                                    {(isProfileVerified === 'id_not_matched_profile' || isProfileVerified === 'unverified' || (isProfileVerified === 'expired_document'  && isProfileExpiredAt === "") || isProfileVerified === 'invalid_document') &&
                                        <div className="idverify-message mb-32">
                                            <VerifyId ref={verifyIdRef} isType={'banner'} isProfileVerifiedAt={isProfileVerifiedAt} isProfileVerified={isProfileVerified} confirmHandler={(status)=>{
                                                if(status){
                                                    navigate("/dashboard");
                                                }else{
                                                    navigate("/t/profile");
                                                }
                                            }} />
                                        </div>
                                    }

                                    {((isProfileVerified === 'verified' || isProfileVerified === 'expired_document') && isProfileExpiredAt !== "" && isProfileExpiredAt >=0) &&
                                    <div className={`idexpiry-message ${displaySanctionedMessage?"mb-20":"mb-32"} `}>
                                        <ExpiryId profileExpiredDate={isProfileExpiredAt} isProfileAttempts={isProfileAttempts} isProfileVerifiedAt={isProfileVerifiedAt} confirmHandler={()=>{
                                            navigate("/t/profile");
                                        }}/>
                                    </div>
                                    } 
                                    {displaySanctionedMessage &&
                                        <div className="formbox sanctioned-info mb-32">
                                            <div className=' input-field  input-with-right-icon mb-0  '>
                                                <ToastMessage closable={false} type={"warning"} message={<>In compliance with both local and international regulations, we may not be able to process payouts to certain countries. Please refer to our <UserQLink target="_blank" url="https://userq.com/terms-of-use">Terms of use</UserQLink>.</>} />
                                                
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            {userBlocked?
                                                <PayoutOverviewBox
                                                    amount={roundNumber(credits, 2)}
                                                    type="balance"
                                                    request_payout_enabled={
                                                        false
                                                    }
                                                    onClick={()=>{}}
                                                    loading={loading}

                                                />:
                                                <PayoutOverviewBox
                                                    amount={roundNumber(credits, 2)}
                                                    type="balance"
                                                    request_payout_enabled={
                                                        (((credits >= minimumThresholdsForPayouts) || (requestedPayout + depositedPayout >= minimumThresholdsForPayouts))
                                                            && (credits >= minimumThresholdsForPayouts))
                                                            ? (checkBankDetailsEntered()) ? true : false : false
                                                    }
                                                    onClick={requestpayout}
                                                    loading={loading}

                                                />
                                            }

                                        </div>
                                        <div className="col-lg-4 col-md-6">

                                            <PayoutOverviewBox
                                                amount={roundNumber(requestedPayout, 2)}
                                                type="inprogress"
                                            />
                                        </div>

                                        <div className="col-lg-4 col-md-6">

                                            <PayoutOverviewBox
                                                amount={roundNumber(depositedPayout, 2)}
                                                type="deposited"
                                            />
                                        </div>

                                    </div>



                                </div>

                                <div className={'wallet-bank-detail-wrap'}>

                                    <div className={"add-bank-detail-wrap"}>

                                        <Text type={'subtitle-2'} fontWeight={'semi-bold-font'}>
                                            Bank account details
                                        </Text>

                                        <div className="add-bank-detail-sub-info mt-32 mb-32">
                                            <Text type={"body-text-2"} fontWeight={"medium-font"}>
                                                Fill in your bank account details to redeem the credits in your wallet. Transfer may take up to 10/15 days for processing. Please make sure that your bank account details match the ID document used to verify your identity.
                                            </Text>
                                            <div className="payout-info-text mt-20">
                                                {/* <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"payout-work"}>
                                                    <a href={process.env.REACT_APP_SITE_URL + "faq/?role=tester"} target={"_blank"}>
                                                        How does payout work?
                                                    </a>
                                                </Text> */}
                                                <UserQLink url={process.env.REACT_APP_SITE_URL + "faq/?role=tester&section=payouts"} target="_blank">How do payouts work?</UserQLink>
                                            </div>
                                        </div>
                                        <div className="add-bank-detail-button mb-32 none-for-mobile">
                                            {checkBankDetailsEntered() ?
                                                <div className={'edit-payments-button d-flex'}>
                                                    {bankFormValues.type === 'paypal' ?
                                                        <>
                                                            <Button
                                                                size={'large'}
                                                                type={"secondary"}
                                                                iconLeft={<Icon value={'edit'} />}
                                                                label={'Edit Paypal account'}
                                                                onClick={() => {
                                                                    if(!userBlocked){
                                                                        setOpenAddPaypalModal(true);
                                                                    }
                                                                }}
                                                                state={userBlocked?"disabled":""}
                                                            />

                                                            {/* <span className={'delete-bank-aacounts'} onClick={() => {
                                                                setConfirmModal({
                                                                    open: true,
                                                                    confirm_btn_title: "Yes, remove",
                                                                    cancel_btn_title: "No, keep",
                                                                    confirm_title: "Remove Paypal account",
                                                                    confirm_message: "Are you sure you want to remove the registered Paypal account information?",
                                                                    confirmation_for: "paypalAccountdelete",
                                                                    confirm_title_class: 'text-danger'
                                                                });
                                                            }}>
                                                                <Icon value={'delete'} hover={true} />
                                                            </span> */}
                                                            <span className={'delete-bank-aacounts'}>
                                                            <Button
                                                                size={'large'}
                                                                type={"secondary"}
                                                                iconLeft={<Icon value={'delete'} />}
                                                                label={'Remove'}
                                                                onClick={() => {
                                                                    setConfirmModal({
                                                                        open: true,
                                                                        confirm_btn_title: "Yes, remove",
                                                                        cancel_btn_title: "No, keep",
                                                                        confirm_title: "Remove Paypal account",
                                                                        confirm_message: "Are you sure you want to remove the registered Paypal account information?",
                                                                        confirmation_for: "paypalAccountdelete",
                                                                        confirm_title_class: 'text-danger'
                                                                    });
                                                                }}
                                                            />
                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            <Button
                                                                size={'large'}
                                                                type={"secondary"}
                                                                iconLeft={<Icon value={'edit'} />}
                                                                label={'Edit your bank account'}
                                                                onClick={() => {
                                                                    if(!userBlocked){
                                                                        setOpenAddBankAccountModal(true);
                                                                    }
                                                                }}
                                                                state={userBlocked?"disabled":""}
                                                            />

                                                            {/* <span className={'delete-bank-aacounts'} onClick={() => {
                                                                setConfirmModal({
                                                                    open: true,
                                                                    confirm_btn_title: "Yes, remove",
                                                                    cancel_btn_title: "No, keep",
                                                                    confirm_title: "Remove bank account",
                                                                    confirm_message: "Are you sure you want to remove the registered bank account information?",
                                                                    confirmation_for: "bankAccountdelete",
                                                                    confirm_title_class: 'text-danger'
                                                                });
                                                            }}>
                                                                <Icon value={'delete'} hover={true} />
                                                            </span> */}
                                                            <span className={'delete-bank-aacounts'}>
                                                            <Button
                                                                size={'large'}
                                                                type={"secondary"}
                                                                iconLeft={<Icon value={'delete'} />}
                                                                label={'Remove'}
                                                                onClick={() => {
                                                                    setConfirmModal({
                                                                        open: true,
                                                                        confirm_btn_title: "Yes, remove",
                                                                        cancel_btn_title: "No, keep",
                                                                        confirm_title: "Remove bank account",
                                                                        confirm_message: "Are you sure you want to remove the registered bank account information?",
                                                                        confirmation_for: "bankAccountdelete",
                                                                        confirm_title_class: 'text-danger'
                                                                    });
                                                                }}
                                                            />
                                                            </span>
                                                        </>
                                                    }

                                                </div>
                                                :
                                                <div className={'add-payments-button d-flex paypal-button-wrap'}>
                                                    <Button
                                                        size={'large'}
                                                        iconLeft={<Icon colorClass="gray-50-svg" value={'Add'} size="medium" />}
                                                        label={'Add your bank account'}
                                                        onClick={() => {
                                                            // check status
                                                            if(isProfileVerified === 'verified'){
                                                                if(!userBlocked){
                                                                    setOpenAddBankAccountModal(true);
                                                                }
                                                            }else{
                                                                verifyIdVerificationHandler();
                                                            }


                                                        }}
                                                        state={(userBlocked || isProfileVerified !== 'verified')?"disabled":""}
                                                    />

                                                    <Text type={"body-text-2"} fontWeight={"medium-font"}>
                                                        Or
                                                    </Text>

                                                    <Button
                                                        size={'large'}
                                                        type={"secondary"}
                                                        iconLeft={<Icon size={"large"} value={'paypal-big'} />}
                                                        label={''}
                                                        onClick={() => {
                                                            if(isProfileVerified === 'verified'){
                                                                if(!userBlocked){
                                                                    setOpenAddPaypalModal(true);
                                                                }
                                                            }else{
                                                                verifyIdVerificationHandler();
                                                            }
                                                        }}
                                                        state={(userBlocked || isProfileVerified !== 'verified')?"disabled":""}
                                                        cssclass={(userBlocked || isProfileVerified !== 'verified')?"blocked-paypal-button":"active-paypal-button"}
                                                    />
                                                </div>
                                            }
                                        </div>


                                        <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"payout-requesting-issue none-for-mobile"}>
                                            Are you having issue requesting your payout?{" "}
                                            <Link
                                                to={'/t/support/contact?reason=Help with payout'}>
                                                Get in touch with us
                                            </Link>
                                        </Text>


                                    </div>


                                    {checkBankDetailsEntered() &&
                                    <>
                                        {bankFormValues.type === 'paypal' ?
                                            <div className={'bank-account-details-data-view mt-0 mb-32'}>

                                                <div className={"row"}>
                                                    <div className={'col-md-12 paypal-image mb-32'}>
                                                        <img src={process.env.REACT_APP_URL + "img/PayPal.svg"} />
                                                    </div>

                                                </div>

                                                <div className={"row"}>
                                                    <div className={'col-md-12 paypal-added-detail'}>

                                                        <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                            Paypal account
                                                        </Text>
                                                        <Text type={'body-text-2'} cssClasses={'paypal-email'}  fontWeight={'medium-font'}>
                                                            {paypalFormValues.email ? paypalFormValues.email : "-"}
                                                        </Text>

                                                        <Text type={'body-text-2'} cssClasses={'paypal-username'} fontWeight={'medium-font'}>
                                                            {paypalFormValues.name ? paypalFormValues.name : "-"}
                                                        </Text>

                                                        <Text type={'body-text-2'} cssClasses={'paypal-phone'} fontWeight={'medium-font'}>
                                                            {paypalFormValues.phone ? paypalFormValues.phone : "-"}
                                                        </Text>
                                                    </div>


                                                </div>


                                            </div>
                                            :
                                            <div className={'bank-account-details-data-view mt-0 mb-32'}>

                                                <div className="sec-for-desktop">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        Country
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.country ? bankFormValues.country : "-"}
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        Beneficiary bank
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.beneficiary_bank ? bankFormValues.beneficiary_bank : "-"}
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        Account holder name
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.name ? bankFormValues.name : "-"}
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            <div className={"row"}>
                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        Beneficiary resident country
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.beneficiary_resident_country ? bankFormValues.beneficiary_resident_country : "-"}
                                                                    </Text>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">

                                                            <div className={"row"}>






                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        City
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.city ? bankFormValues.city : "-"}
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            <div className={"row"}>


                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        Branch
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.branch ? bankFormValues.branch : "-"}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                            <div className={"row"}>


                                                                <div className={'col-md-12'}>

                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                                                        IBAN
                                                                    </Text>
                                                                    <Text type={'body-text-2'} fontWeight={'medium-font'}>
                                                                        {bankFormValues.account_no ? bankFormValues.account_no : "-"}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>






                                                    </div>
                                                </div>







                                            </div>

                                        }
                                    </>
                                    }





                                    <div className="add-bank-detail-button none-for-desktop w-100">
                                        {checkBankDetailsEntered() ?
                                            <div className={'edit-payments-button d-flex'}>
                                                {bankFormValues.type === 'paypal' ?
                                                    <>
                                                        <Button
                                                            size={'large'}
                                                            type={"secondary"}
                                                            iconLeft={<Icon value={'edit'} />}
                                                            label={'Edit Paypal account'}
                                                            onClick={() => {
                                                                if(!userBlocked){
                                                                    setOpenAddPaypalModal(true);
                                                                }
                                                            }}
                                                            state={userBlocked?"disabled":""}
                                                        />

                                                        {/* <span className={'delete-bank-aacounts'} onClick={() => {
                                                                setConfirmModal({
                                                                    open: true,
                                                                    confirm_btn_title: "Yes, remove",
                                                                    cancel_btn_title: "No, keep",
                                                                    confirm_title: "Remove Paypal account",
                                                                    confirm_message: "Are you sure you want to remove the registered Paypal account information?",
                                                                    confirmation_for: "paypalAccountdelete",
                                                                    confirm_title_class: 'text-danger'
                                                                });
                                                            }}>
                                                                <Icon value={'delete'} hover={true} />
                                                            </span> */}
                                                        <span className={'delete-bank-aacounts'}>
                                                            <Button
                                                                size={'large'}
                                                                type={"secondary"}
                                                                iconLeft={<Icon colorClass={"gray-900-svg"} size="medium" value={'delete'} />}
                                                                label={'Remove'}
                                                                onClick={() => {
                                                                    setConfirmModal({
                                                                        open: true,
                                                                        confirm_btn_title: "Yes, remove",
                                                                        cancel_btn_title: "No, keep",
                                                                        confirm_title: "Remove Paypal account",
                                                                        confirm_message: "Are you sure you want to remove the registered Paypal account information?",
                                                                        confirmation_for: "paypalAccountdelete",
                                                                        confirm_title_class: 'text-danger'
                                                                    });
                                                                }}
                                                            />
                                                            </span>
                                                    </>
                                                    :
                                                    <>
                                                        <Button
                                                            size={'large'}
                                                            type={"secondary"}
                                                            iconLeft={<Icon value={'edit'} />}
                                                            label={'Edit your bank account'}
                                                            onClick={() => {
                                                                if(!userBlocked){
                                                                    setOpenAddBankAccountModal(true);
                                                                }
                                                            }}
                                                            state={userBlocked?"disabled":""}
                                                        />

                                                        {/* <span className={'delete-bank-aacounts'} onClick={() => {
                                                                setConfirmModal({
                                                                    open: true,
                                                                    confirm_btn_title: "Yes, remove",
                                                                    cancel_btn_title: "No, keep",
                                                                    confirm_title: "Remove bank account",
                                                                    confirm_message: "Are you sure you want to remove the registered bank account information?",
                                                                    confirmation_for: "bankAccountdelete",
                                                                    confirm_title_class: 'text-danger'
                                                                });
                                                            }}>
                                                                <Icon value={'delete'} hover={true} />
                                                            </span> */}
                                                        <span className={'delete-bank-aacounts'}>
                                                            <Button
                                                                size={'large'}
                                                                type={"secondary"}
                                                                iconLeft={<Icon colorClass={"black-900-svg"} size="medium" value={'delete'} />}
                                                                label={'Remove'}
                                                                onClick={() => {
                                                                    setConfirmModal({
                                                                        open: true,
                                                                        confirm_btn_title: "Yes, remove",
                                                                        cancel_btn_title: "No, keep",
                                                                        confirm_title: "Remove bank account",
                                                                        confirm_message: "Are you sure you want to remove the registered bank account information?",
                                                                        confirmation_for: "bankAccountdelete",
                                                                        confirm_title_class: 'text-danger'
                                                                    });
                                                                }}
                                                            />
                                                            </span>
                                                    </>
                                                }

                                            </div>
                                            :
                                            <div className={'add-payments-button d-flex paypal-button-wrap'}>
                                                <Button
                                                    size={'large'}
                                                    iconLeft={<Icon colorClass="gray-50-svg" value={'Add'} size="medium" />}
                                                    label={'Add your bank account'}
                                                    onClick={() => {
                                                        if(isProfileVerified === 'verified'){
                                                            if(!userBlocked){
                                                                setOpenAddBankAccountModal(true);
                                                            }
                                                        }else{
                                                            verifyIdVerificationHandler()
                                                        }
                                                    }}
                                                    state={(userBlocked || isProfileVerified !== 'verified')?"disabled":""}
                                                />

                                                <Text type={"body-text-2"} fontWeight={"medium-font"}>
                                                    Or
                                                </Text>

                                                <Button
                                                    size={'large'}
                                                    type={"secondary"}
                                                    iconLeft={<Icon size={"large"} value={'paypal-big'} />}
                                                    label={''}
                                                    onClick={() => {
                                                        if(isProfileVerified === 'verified'){
                                                            if(!userBlocked){
                                                                setOpenAddPaypalModal(true);
                                                            }
                                                        }else{
                                                            verifyIdVerificationHandler();

                                                        }
                                                    }}
                                                    state={(userBlocked || isProfileVerified !== 'verified')?"disabled":""}
                                                    cssclass={(userBlocked || isProfileVerified !== 'verified')?"blocked-paypal-button":"active-paypal-button"}
                                                />
                                            </div>
                                        }
                                    </div>



                                    <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"payout-requesting-issue mt-32 none-for-desktop"}>
                                        Are you having issue requesting your payout?{" "}
                                        <Link
                                            to={'/t/support/contact?reason=Help with payout'}>
                                            Get in touch with us
                                        </Link>
                                    </Text>

                                    {(isProfileVerified === 'id_not_matched_profile' || isProfileVerified === 'unverified' || (isProfileVerified === 'expired_document'  && isProfileExpiredAt === "") || isProfileVerified === 'invalid_document') &&
                                    <div className={'wallet-unverified-section'}>
                                        <Box cssClasses="signofinformational-wrap gray-box p-32 mb-20">
                                            <Text type={"subtitle-2"} fontWeight={"semi-bold-font"}>Why it’s important to verify my identity?</Text>

                                            <div className='sign-identity-repeat mb-20 mt-32'>
                                                <div className='sign-identity-top-head d-flex align-items-center'>
                                                    <Icon value="security" size="medium"/>
                                                    <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"semi-bold-font"}>Security</Text>
                                                </div>
                                                <Text type={"body-text-3"} cssClasses="gray-color" fontWeight={"medium-font"}>Verifying your identity helps to prevent fraud and unauthorized access to your account.</Text>
                                            </div>

                                            <div className='sign-identity-repeat mb-20'>
                                                <div className='sign-identity-top-head d-flex align-items-center'>
                                                    <Icon value="key" size="medium"/>
                                                    <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"semi-bold-font"}>Access to tests</Text>
                                                </div>
                                                <Text type={"body-text-3"} cssClasses="gray-color" fontWeight={"medium-font"}>Some tests might be available only for verified users. Get access to all tests by verifying your identity.</Text>
                                            </div>

                                            <div className='sign-identity-repeat'>
                                                <div className='sign-identity-top-head d-flex align-items-center'>
                                                    <Icon value="black_dollar" size="medium"/>
                                                    <Text type={"body-text-2"} cssClasses="color-black" fontWeight={"semi-bold-font"}>Payouts</Text>
                                                </div>
                                                <Text type={"body-text-3"} cssClasses="gray-color" fontWeight={"medium-font"}>Only verified accounts are eligible to request a payout. If you have not yet verified your identity, please do so before requesting a payout.</Text>
                                            </div>

                                            <div className="idverify-message mt-32">
                                                <VerifyId isType={'button'} isProfileVerifiedAt={isProfileVerifiedAt} isProfileVerified={isProfileVerified} confirmHandler={()=>{
                                                    navigate("/t/profile");
                                                }} />
                                            </div>

                                        </Box>
                                    </div>

                                    }



                                </div>


                                {/*Payout histoty table*/}
                                <div className="table-wrapper study-history-table wallet-payout-history-table mt-32">

                                    {payoutHistory.length > 0 && (<Text type={"h4"}>Payouts history</Text>)}


                                    <div ref={scrollContainerRef}  className={`max-height-table  ${isScrolled ? 'scrolled' : ''}  ${payoutHistory && payoutHistory.length>8 ? "responsive-table-scroll":""}`}>
                                            
                                        <table className="table">
                                            {payoutHistory.length > 0 ? (
                                                <thead>
                                                <tr>
                                                    <th>
                                                        Amount
                                                    </th>
                                                    <th className="big-column">
                                                        <div className="table-center-div">
                                                            <div onClick={() => {
                                                                sortData('status')
                                                            }} className={`${(metaData.sort_status) ? metaData.sort_status : ''}  col-sort justify-content-between`}>

                                                                Status
                                                                <span>
                                                                        {metaData.sort_status == "asc" &&
                                                                        <Icon value={'sortup'} />
                                                                        }
                                                                    {metaData.sort_status == "desc" &&
                                                                    <Icon value={'sortdown'} />
                                                                    }
                                                                    {!metaData.sort_status &&
                                                                    <Icon value={'sort-icon'} />
                                                                    }
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div onClick={() => {
                                                            sortData('date')
                                                        }} className={`${(metaData.sort_date) ? metaData.sort_date : ''}  col-sort justify-content-between`}>

                                                            Date
                                                            <span>
                                                                    {metaData.sort_date == "asc" &&
                                                                    <Icon value={'sortup'} />
                                                                    }
                                                                {metaData.sort_date == "desc" &&
                                                                <Icon value={'sortdown'} />
                                                                }
                                                                {!metaData.sort_date &&
                                                                <Icon value={'sort-icon'} />
                                                                }
                                                                </span>
                                                        </div>
                                                    </th>
                                                </tr>
                                                </thead>
                                            ) : (
                                                ""
                                            )}
                                            <tbody>

                                            {payoutHistory.map((result, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>${roundNumber(result.amount, 2)}</td>
                                                        <td className="big-column">
                                                            <div className="table-center-div">
                                                                <div className="active-tiles-wrap">
                                                                    {result.status === 'deposited' &&
                                                                    <span className={'completed'}>Completed</span>
                                                                    }
                                                                    {result.status === 'in-progress' &&    <span
                                                                        className={'in-progress'}>In-progress</span>
                                                                    }
                                                                    {result.status === 'cancelled' &&    <span
                                                                        className={'cancelled'}>Cancelled</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {format(
                                                                new Date(result.created_at),
                                                                "dd/MM/yy"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            </tbody>
                                        </table>

                                        {metaData.load_more && (
                                            <div className={"load-more-data"}>
                                                <Button type="primary"
                                                        size={`large`}
                                                        label="Load more"
                                                        microLoading={loadMoreLoading}
                                                        onClick={() => {
                                                            loadMore()
                                                        }}
                                                />
                                            </div>
                                        )}


                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {/*{isLoading ?*/}
                {/*    <></>*/}
                {/*    :*/}
                {/*    <>*/}
                {/*        {((credits >= minimumThresholdsForPayouts) || (requestedPayout+depositedPayout>=minimumThresholdsForPayouts)) &&*/}
                {/*            <>*/}
                {/*                <div className="profile-form-wrap ">*/}

                {/*                    {!isBankAccountEditable &&*/}
                {/*                            <>*/}
                {/*                    {checkBankDetailsEntered() ?*/}
                {/*                        <>*/}
                {/*                            {credits>=minimumThresholdsForPayouts?*/}
                {/*                            <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">*/}
                {/*                                <button type="submit" className="btn form-btn" onClick={requestpayout} id="t_wallet_request_payout">*/}
                {/*                                    Request Payout*/}
                {/*                                    {loading && (*/}
                {/*                                        <i*/}
                {/*                                            className="fa fa-spinner fa-spin"*/}
                {/*                                            aria-hidden="true"*/}
                {/*                                            style={{ marginLeft: "5px" }}*/}
                {/*                                        ></i>*/}
                {/*                                    )}*/}
                {/*                                </button>*/}
                {/*                            </div>*/}
                {/*                            :*/}
                {/*                            <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">*/}
                {/*                                <button type="submit" className="btn form-btn disabled-button" id="t_wallet_request_payout">*/}
                {/*                                    Request Payout*/}
                {/*                                    {loading && (*/}
                {/*                                        <i*/}
                {/*                                            className="fa fa-spinner fa-spin"*/}
                {/*                                            aria-hidden="true"*/}
                {/*                                            style={{ marginLeft: "5px" }}*/}
                {/*                                        ></i>*/}
                {/*                                    )}*/}
                {/*                                </button>*/}
                {/*                            </div>*/}
                {/*                            }*/}

                {/*                        </>*/}
                {/*                        :*/}
                {/*                        <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">*/}
                {/*                                <button type="submit" className="btn form-btn disabled-button" id="t_wallet_request_payout">*/}
                {/*                                    Request Payout*/}
                {/*                                    {loading && (*/}
                {/*                                        <i*/}
                {/*                                            className="fa fa-spinner fa-spin"*/}
                {/*                                            aria-hidden="true"*/}
                {/*                                            style={{ marginLeft: "5px" }}*/}
                {/*                                        ></i>*/}
                {/*                                    )}*/}
                {/*                                </button>*/}
                {/*                        </div>*/}
                {/*                        }*/}
                {/*                        </>*/}
                {/*                        }*/}
                {/*                        {isBankAccountEditable &&*/}
                {/*                            <div className="button-wrap d-flex justify-content-end">*/}
                {/*                                <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">*/}
                {/*                                    <button type="button" className="btn secondary-btn form-btn" onClick={()=>{*/}
                {/*                                        setIsBankAccountEditable(false);*/}
                {/*                                        setSelectedCountry(tempSelectedCountry);*/}
                {/*                                        setBankFormValues(tempBankFormValues);*/}
                {/*                                        setCountryLabelClass("inactive_label");*/}
                {/*                                        resetFormErrors();*/}
                {/*                                        }}>*/}
                {/*                                        Cancel*/}
                {/*                                    </button>*/}


                {/*                                    <button type="submit" className="btn primary-btn form-btn" onClick={updatebankdetails}>*/}
                {/*                                        Save*/}
                {/*                                        {loading && (*/}
                {/*                                            <i*/}
                {/*                                                className="fa fa-spinner fa-spin"*/}
                {/*                                                aria-hidden="true"*/}
                {/*                                                style={{ marginLeft: "5px" }}*/}
                {/*                                            ></i>*/}
                {/*                                        )}*/}
                {/*                                    </button>*/}

                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        }*/}
                {/*                </div>*/}
                {/*                <div className="profile-form-group">*/}
                {/*                </div>*/}
                {/*            </>*/}
                {/*        }*/}
                {/*    </>*/}
                {/*}*/}
            </div>
            <AddBankAccountModal
                openModal={openAddBankAccountModal}
                type={(checkBankDetailsEntered()?'Edit':'Add')}
                closeModal={(status, bankFormData) => {
                    if (status) {
                        setBankFormValues({
                            country: bankFormData.country,
                            name: user.first_name+' '+user.last_name,
                            nickname: bankFormData.nickname,
                            beneficiary_bank: bankFormData.beneficiary_bank,
                            branch: bankFormData.branch,
                            city: bankFormData.city,
                            beneficiary_resident_country: bankFormData.beneficiary_resident_country,
                            account_no: bankFormData.account_no,
                            account_no_type: bankFormData.account_no_type,
                            type: 'bank'
                        })
                    }
                    setOpenAddBankAccountModal(false);
                }}
                bankFormValuesData={bankFormValues}
            />
            <AddPaypalAccountModal
                openModal={openAddPaypalModal}
                type={(checkBankDetailsEntered()?'Edit':'Add')}
                closeModal={(status, bankFormData) => {
                    if (status) {
                        setPaypalFormValues({
                            name: bankFormData.name,
                            phone: bankFormData.phone,
                            email: bankFormData.email,
                            type: 'paypal'
                        })
                        setBankFormValues({
                            ...bankFormValues,
                            type: 'paypal'
                        })
                    }
                    setOpenAddPaypalModal(false);
                }}
                bankFormValuesData={paypalFormValues}
            />
            <ConfirmationModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title={confirmModal.confirm_btn_title}
                cancel_btn_title={confirmModal.cancel_btn_title}
                confirm_title={confirmModal.confirm_title}
                confirm_title_class={confirmModal.confirm_title_class}
                btn_reverse={true}
                openModal={confirmModal.open}
                btnLoading={confirmationBtnLoading}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    // setConfirmModal({ ...confirmModal, open: false });
                    if (confirmModal.confirmation_for === "bankAccountdelete") {
                        deleteBankAccountApi("bankAccountdelete");
                    }
                    else if (confirmModal.confirmation_for === "paypalAccountdelete") {
                        deleteBankAccountApi("paypalAccountdelete");
                    }

                }}
            />
            <DocumentNotMatch
                confirm_title={"We need to update your profile to match your ID"}
                openModal={documentNotMatch.open}
                close={() => {
                    setDocumentNotMatch({ open: false });
                }}
                confirmHandler={(status)=>{
                    if(status){
                        navigate("/dashboard");
                    }else{
                        navigate("/t/profile");
                    }
                }}
            />
        </LayoutTester>
    );
}
