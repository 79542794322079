import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";
import { EmailInput } from "../../../themes/userq/Input/EmailInput";
import {
    Link
} from "react-router-dom";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { addWorkspaceTeamMemberService } from "../../../../services/workspaces";
import "./add-members-modal.css";


let colorsUsedInModal = [];

let colorsPalleteInModal = [
    "rgba(227, 47, 131, 1)",
    "rgba(121, 62, 171, 1)",
    "rgba(215, 165, 106, 1)",
    "rgba(55, 139, 16, 1)",
    "rgba(33, 81, 245, 1)",
    "rgba(255, 109, 177, 1)",
    "rgba(151, 89, 203, 1)",
    "rgba(5, 43, 174, 1)",
    "rgba(22, 65, 3, 1)",
    "rgba(255, 184, 0, 1)",
    "rgba(6, 58, 236, 1)",
    "rgba(167, 37, 97, 1)",
    "rgba(92, 32, 141, 1)",
    "rgba(188, 113, 1, 1)"
];

export default function AddMembersModal({
    open,
    close,
    availableSeats,
    maxSeats,
    colorsUsed,
    workspaceid,
    oldMembers
}) {

    const [formLoading, setFormLoading] = useState(false);
    const closeModal = (send=false) => {
        resetModal();
        close(send);
    };


    const [seatData, setSeatData] = useState([]);



    const wrapperRef = useRef(null);



    const [formErrors, setFormErrors] = useState({
        email: null,
        error_class: null,
    });

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const handleAddMore = () => {
        if (seatData.length < availableSeats) {

            colorsPalleteInModal = colorsPalleteInModal.filter(function (el) {
                return colorsUsedInModal.indexOf(el) < 0;
            });

            const randomIndex = Math.floor(
                Math.random() * colorsPalleteInModal.length,
            );

            // Access the element at the random index
            //let color = colorsPalleteInModal[randomIndex];

            let color = colorsPalleteInModal[seatData.length];

            //colorsUsedInModal.push(color);

            setSeatData([
                ...seatData,
                { email: "", color: color, readOnly: false },
            ]);
        }
    };

    const resetModal = () => {
        setTimeout(() => {
            setSeatData([]);
            colorsUsedInModal = [];
            setFormErrors({
                email: null,
                error_class: null,
            });
        }, 300);
    }

    const addAdditionalDefaultMembers = (members, membersCount) => {
        for (var i = 0; i < membersCount; i++) {
            colorsPalleteInModal = colorsPalleteInModal.filter(function (el) {
                return colorsUsedInModal.indexOf(el) < 0;
            });

            var randomIndex = Math.floor(Math.random() * colorsPalleteInModal.length);

            // Access the element at the random index
            //var color = colorsPalleteInModal[randomIndex];

            var color = colorsPalleteInModal[i];

            //colorsUsedInModal.push(color);

            // console.log(color)
            members.push({ email: "", color: color, readOnly: false });
            // console.log("show me colorsPallete1", colorsPallete);
        }

        setSeatData(members);
    };

    useEffect(() => {

        colorsUsedInModal = [...colorsUsed];

        colorsPalleteInModal = [
            "rgba(227, 47, 131, 1)",
            "rgba(121, 62, 171, 1)",
            "rgba(215, 165, 106, 1)",
            "rgba(55, 139, 16, 1)",
            "rgba(33, 81, 245, 1)",
            "rgba(255, 109, 177, 1)",
            "rgba(151, 89, 203, 1)",
            "rgba(5, 43, 174, 1)",
            "rgba(22, 65, 3, 1)",
            "rgba(255, 184, 0, 1)",
            "rgba(6, 58, 236, 1)",
            "rgba(167, 37, 97, 1)",
            "rgba(92, 32, 141, 1)",
            "rgba(188, 113, 1, 1)"
        ];

        if (availableSeats > 0 && open) {
            addAdditionalDefaultMembers([], 1);
        }

    }, [open])


    const handleEmailChange = (index, value) => {
        setSeatData((prevSeatData) => {
            const updatedSeatData = [...prevSeatData];
            updatedSeatData[index].email = value;
            return updatedSeatData;
        });
    };

    const checkSendBtnState = (showError = false) => {
        if (seatData.length === 0) {
            return "disabled";
        } else {


            let error = validateFullFormOnly(showError);

            if (!error) {
                let errorVal = false;
                for (const [index, teamMember] of seatData.entries()) {

                    if (
                        !(
                            teamMember.email === "" ||
                            teamMember.email === null ||
                            teamMember.email === undefined
                        )
                    ) {
                        errorVal = false;
                        break;
                    } else {
                        errorVal = true;
                    }
                }

                if (errorVal) {
                    error = true;
                } else {
                    error = false;
                }
            }




            if (error) {
                return "disabled";
            } else {
                return "active";
            }
        }


    };


    const validateFullForm = (
        focus,
        key,
        value = null,
        inline = false,
        index,
    ) => {
        var error = false;
        var mailformat =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-0]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        var form_errors = { ...formErrors };
        if (key) {
            form_errors[key] = null;
        }

        // if (focus) {
        //     form_errors = resetFormErrors();
        // }

        if (key) {
            var field_value =
                key && inline
                    ? value
                    : seatData.length && seatData[index].email
                        ? seatData[index].email
                        : "";

            // if (
            //   field_value === "" ||
            //   field_value === null ||
            //   field_value === undefined
            // ) {
            //   form_errors = {
            //     ...form_errors,
            //     [key]: "Required field",
            //     error_class: "input_error",
            //   };
            //   error = true;
            // } else
            if (
                !(
                    field_value === "" ||
                    field_value === null ||
                    field_value === undefined
                )
            ) {
                if (!field_value.match(mailformat)) {
                    form_errors = {
                        ...form_errors,
                        [key]: "Please enter a valid email address",
                        error_class: "input_error",
                    };
                    error = true;
                } else {
                    // Check for duplicate emails
                    let isDuplicate =
                        seatData.some(
                            (data, i) =>
                                i !== index && data.email === field_value,
                        );

                    let isDuplicateOld =
                        oldMembers.some(
                            (data, i) => data.email === field_value,
                        );
                    if (isDuplicate || isDuplicateOld) {
                        form_errors = {
                            ...form_errors,
                            [key]: "Email already exists",
                            error_class: "input_error",
                        };
                        error = true;
                    }
                }
            }
        }

        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }

        // setFormErrors(form_errors);
        return error;
    };

    const validateFullFormOnly = (showError = false) => {
        var error = false;
        var mailformat =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-0]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


        var form_errors = { ...formErrors };

        form_errors['email'] = null;

        if(showError){
            form_errors = resetFormErrors();
        }

        let firsterrorid = null;



        if (seatData.length > 0 && seatData.length <= availableSeats) {
            for (const [index, teamMember] of seatData.entries()) {

                if (
                    !(
                        teamMember.email === "" ||
                        teamMember.email === null ||
                        teamMember.email === undefined
                    )
                ) {
                    if (!teamMember.email.match(mailformat)) {
                        error = true;
                        if(showError){
                            form_errors = {
                                ...form_errors,
                                [`email_${index}`]: "Please enter a valid email address",
                                error_class: "input_error",
                            };

                            firsterrorid = firsterrorid == null ? `email_${index}` : firsterrorid;
                        } else {
                            break;
                        }
                        
                    } else {
                        // Check for duplicate emails
                        let isDuplicate =
                            seatData.some((data, i) => {
                                return (
                                    i !== index &&
                                    data.email === teamMember.email
                                );
                            });
                        let isDuplicateOld =
                            oldMembers.some(
                                (data, i) => data.email === teamMember.email,
                            );
                        if (isDuplicate || isDuplicateOld) {
                            // console.log("errror here 2");
                            error = true;
                            if(showError){
                                form_errors = {
                                    ...form_errors,
                                    [`email_${index}`]: "Email already exists",
                                    error_class: "input_error",
                                };
                                firsterrorid = firsterrorid == null ? `email_${index}` : firsterrorid;
                            } else {
                                break;
                            }
                            
                        }
                    }
                } else {
                }
            }

        } else {
            error = true;
        }

        if (showError) {
            setFormErrors(form_errors);
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }


        return error;
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (let key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const sendInvitations = () => {
        if (checkSendBtnState(true) === "active" && !formLoading) {
            setFormLoading(true);
            // console.log("active");
            // setWorksapceLoader(true);

            const token = ReactSession.get("token");
            let formData = {};

            formData.members = seatData.filter((item) => {
                return !(
                    item.email === null ||
                    item.email === "" ||
                    item.email === undefined
                );
            });
            formData.workspace_id = workspaceid;

            // console.log("formdatea", formData);

            addWorkspaceTeamMemberService(formData, token).then((response) => {
                setFormLoading(false);
                if (response.success) {
                    closeModal(true);
                    showToast(response.message, "success");
                } else {
                    showToast(response.message, "error");
                }
            });
        } else {
            // console.log("not active");
            // showToast("");
        }
    };

    const countEmail = () => {
        let count = 0;
        for (const [index, teamMember] of seatData.entries()) {
            if (
                !(
                    teamMember.email === "" ||
                    teamMember.email === null ||
                    teamMember.email === undefined
                )
            ) {
                count++;
            }
        }

        return availableSeats - count;
    }

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap add-member-modal"
        >

            <Modal.Body className="" ref={wrapperRef}>
                <div className={`modal-inner-text `}>
                    <div className="modal-header-top">
                        <Text type="h3">Add members to workspace</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                        </button>
                    </div>

                    <div className="add-member-modal-data">

                        <div className="add-more-member">
                            <div className="add-workspace-member-hold">
                                {seatData.map((item, index) => (
                                    <div
                                        key={index}
                                        className="workspace-member-repeat mb-16 d-flex align-items-center"
                                    >
                                        <div className="workspace-email-wrap">
                                            <EmailInput
                                                label={`${`Email Address ${(maxSeats - availableSeats) + (index + 1)}`}`}
                                                type="text"
                                                value={
                                                    seatData.length &&
                                                        seatData[index].email
                                                        ? seatData[index]
                                                            .email
                                                        : ""
                                                }
                                                id={`email_${index}`}
                                                required={true}
                                                leftIcon={true}
                                                color={
                                                    seatData[index].color
                                                }
                                                readOnly={
                                                    seatData.length &&
                                                        seatData[index].readOnly
                                                        ? seatData[index]
                                                            .readOnly
                                                        : ""
                                                }
                                                onClear={() => {
                                                    handleEmailChange(
                                                        index,
                                                        "",
                                                    );
                                                    setFormErrors({
                                                        ...formErrors,
                                                        [`email_${index}`]:
                                                            null,
                                                    });
                                                }}
                                                onBlur={() =>
                                                    validateFullForm(
                                                        false,
                                                        `email_${index}`,
                                                        seatData[index],
                                                        false,
                                                        index,
                                                    )
                                                }
                                                onChange={(e) => {
                                                    const inputValue =
                                                        e.target.value;

                                                    const re = /^\S*$/;
                                                    if( !re.test(e.target.value)){
                                                        e.preventDefault();
                                                    } else {
                                                        handleEmailChange(
                                                            index,
                                                            inputValue,
                                                        );
                                                        validateFullForm(
                                                            false,
                                                            `email_${index}`,
                                                            inputValue,
                                                            true,
                                                            index,
                                                        );
                                                    }
                                                }}
                                                onKeyDown={(e)=>{
                                                    if (e.key === ' ') {
                                                        e.preventDefault();
                                                        return;
                                                    }
                                                }}
                                                error={
                                                    formErrors[
                                                    `email_${index}`
                                                    ]
                                                }
                                                error_class={
                                                    formErrors.error_class
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="add-member-btn-hold d-flex align-items-center justify-content-between  mt-20">
                                {seatData.length < availableSeats && (
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Button
                                            id="r_email_sign_in"
                                            type="secondary"
                                            label="Add member"
                                            onClick={handleAddMore}
                                            size={"medium"}
                                            iconLeft={
                                                <Icon
                                                    value="add"
                                                    size="small"
                                                    colorClass={"gray-900-svg"}
                                                />
                                            }
                                        />
                                        
                                    </div>
                                )}

                                {+countEmail() !== 0  && (
                                    <><span>&nbsp;</span>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="seats-available-count d-flex align-items-center">
                                                <Icon
                                                    value={"group"}
                                                    size={"small"}
                                                    colorClass={"gray-900-svg"}
                                                />
                                                <Text type={"caption"}>{`${+countEmail() === 1
                                                    ? "1 seat available"
                                                    : `${countEmail()} seats available`
                                                    }`}</Text>
                                            </div>
                                        </div>
                                    </>
                                    )
                                }
                            </div>
                            {seatData.length === availableSeats && +countEmail() === 0 &&  (
                                <>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>&nbsp;</span>
                                        <div className="seats-available-count d-flex align-items-center">
                                            <Icon
                                                value={"group"}
                                                size={"small"}
                                                colorClass={"gray-900-svg"}
                                            />
                                            <Text
                                                type={"caption"}
                                            >{`0 seats available`}</Text>
                                        </div>
                                    </div>
                                    <ToastMessage
                                        type={"info"}
                                        closable={false}
                                        icon={"profile"}
                                        message={
                                            <>
                                                To add more members, please
                                                contact our
                                                <Link to={"/r/support/contact?reason=Team collaboration"} target="_blank">support team</Link>
                                            </>
                                        }
                                    />
                                </>
                            )}

                        </div>
                        <div className="add-member-modal-btn d-flex align-items-center">

                            <Button
                                type="primary"

                                label={"Send invitation"}
                                size={"large"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    sendInvitations();
                                }}
                                state={checkSendBtnState()}
                                microLoading={formLoading}
                            />
                            <Button
                                type="secondary"

                                label={"Cancel"}
                                size={"large"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
