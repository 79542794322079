import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout";
import { useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getProfile } from "../../services/user";
import {getTesterCreditSummaryService} from "../../services/credits";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {FullPageLoadingIcon} from "../loader/full_page_loader_lcon";
import { roundNumber } from "../../lib/helpers";

export default function EarnAndInvite() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);

    const [emailValues, setEmailValues] = useState({subject:'',body:''});

    const [user, setUser] = useState(null);
    const [credits, setCredits] = useState(null);

    const getProfileData = () => {
        let token = ReactSession.get("token");

        setIsLoading(true);

        getProfile(token).then((response) => {
            setIsLoading(false);
            if (response.success) {
                setUser(response.data.user);

                // set email data
                setEmailValues({
                    "subject":"UserQ - Join MENA's First UI/UX Research Platform",
                    "body":

                            "Hey, %0D%0A %0D%0AWant to make some extra cash?" +
                            " Become a tester with UserQ and get paid to help brands" +
                            " in the MENA region shape their websites, apps and other digital products. %0D%0A %0D%0A"+
                            process.env.REACT_APP_URL + "tester/login?refID=" + response.data.user.referral_code+
                            "%0D%0A %0D%0ARegards,%0D%0A" + response.data.user.first_name+ " " +response.data.user.last_name

                });
            }
        });
    };

    const fetchCreditsSummary = () => {
        setIsLoading(true);
        if(!credits){
            let token = ReactSession.get("token");

            getTesterCreditSummaryService(token).then((response) => {
                setIsLoading(false);
                if (response.success) {
                    setCredits(response.summary.credits_balance);
                }
            });
        }
    };


    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Earn & invite";
        //document.querySelector("body").classList.add("earn-invite-page");
        //getProfileData();

        //fetchCreditsSummary(); // get total credits of testers
        navigate('/404');
    }, []);

    return (
            <Layout isLoading={false} skipCheck={false} wrapClass={"earn-invite-page"} extendedFooter={false}>
                <div className="credits-page-wrapper wallet-page-wrapper page-min-height">
                    {isLoading &&
                    <FullPageLoadingIcon />
                    }
                    {!isLoading && user &&
                    <div className="profile-info-section profile-earn-invite d-block pb-64 pt-64">
                        <div className="full link-type-back-earn-invite">
                            <a href="#" onClick={(e)=>{e.preventDefault(); navigate(-1) }} className="link link-type-back">Back</a>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-12 col-md-6 profile-info-left">
                                <div className="profile-info-text">
                                    <h1 className="page-main-heading">
                                        {" "}
                                        Invite friends & <br /> earn {" "}
                                        {user &&
                                            "$"+roundNumber(user.config_credit_amount,2)
                                        }
                                    </h1>


                                    <div className="earn-invite-info">
                                        <p>
                                            Invite your friends to UserQ. When they register and take their first test, {" "}
                                            {user &&
                                                "$"+user.config_credit_amount
                                            } {" "}
                                            will be credited in your wallet.
                                            Visit our{" "}
                                            <a href="https://userq.com/terms-of-use/" target="_blank">Terms of use</a>

                                            {" "}for more info.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 profile-info-right">
                                <div className="profile-info-right-inner">
                                    <div className="profile-info-balancebox">
                                        <h3>Total earnings</h3>
                                        <div className="earn-pricebox">
                                            {/*user.total_earning_referral_user*/}
                                                <>
                                                    <span>$</span>{roundNumber(user.total_earning_referral_user,2)}
                                                </>

                                        </div>
                                        <div className="earn-pricebox-information">
                                            {/*<div className="earnprice-infobox earnprice-infobox-1">*/}
                                            {/*    Sent 0*/}
                                            {/*</div>*/}
                                            {/*<div className="earnprice-infobox earnprice-infobox-2">*/}
                                            {/*    Registered {user.total_register_referral_user}*/}
                                            {/*</div>*/}
                                            {/*<div className="earnprice-infobox earnprice-infobox-3">*/}
                                            {/*    Total earnings ${user.total_earning_referral_user}*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
            
                                    <div className="profile-info-personal-link">
                                        <h3>Your personal link</h3>
                                        <p>
                                            Copy the link or share it with your friends across your
                                            social networks
                                        </p>


                                        <div className="personal-link-box">
                                            <a className="copylink">
                                                {process.env.REACT_APP_URL + "tester/login?refID=" + user.referral_code}
                                            </a>
                                            {/*<a className="copylinkbtn">*/}
                                            {/*    Copy link{" "}*/}
                                            {/*    <span>*/}
                                            {/*        <img*/}
                                            {/*            src={*/}
                                            {/*                process.env.REACT_APP_URL + "images/copylink.svg"*/}
                                            {/*            }*/}
                                            {/*            />*/}
                                            {/*    </span>*/}
                                            {/*</a>*/}
                                            <div className={"copy-share-test-wrap"}>

                                                <CopyToClipboard
                                                    text={process.env.REACT_APP_URL + "tester/login?refID=" + user.referral_code}
                                                    onCopy={() => {
                                                        setLinkCopied(true);
                                                        setTimeout(function () {
                                                            setLinkCopied(false);
                                                        }, 2000)
                                                    }}>
                                                    <span>Copy link  <img
                                                        src={process.env.REACT_APP_URL + "images/copylink.svg"}/></span>
                                                </CopyToClipboard>
                                                <span
                                                    className={`${linkCopied ? 'fade-in' : 'fade-out'} `}>Copied!</span>
                                            </div>
                                        </div>

                                    </div>
            
                                    <div className="profile-info-personal-link mt-4">
                                        <a
                                            className="link linktype-1"
                                            href={`mailto:?subject=${emailValues.subject}&body=${emailValues.body}`}
                                            >
                                            Email your friends
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Layout>
            );
}
