import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Text } from "../../../themes/userq/Text/Text";
import { Input } from "../../../themes/userq/Input/Input";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import "./add-members-modal.css";
import { countries } from "../../../../data/stripe_countries";
import { AutoCompleteSingleSelect } from "../../../themes/userq/Dropdown/AutoCompleteSingleSelect";
import "./accepted-workspace-modal.css";
import { updateWorkspaceBillingService } from "../../../../services/workspaces";
import {
    showToast,
} from "../../../../lib/helpers.js";

export default function BillingInfoModal({
    open,
    close,
    workspaceId,
    billingInfo,
}) {
    const [billingLoading, setBillingLoading] = useState(false);

    const [isBillingEditable, setBillingEditable] = useState(true);

    const [formValues, setFormValues] = useState({
        name: "",
        country: "",
        city: "",
        address: "",
    });

    const [formErrors, setFormErrors] = useState({
        email: null,
        name: null,
        country: null,
        city: null,
        address: null,
        error_class: null,
    });

    const nameMaxLength = 40;

    const addressMaxLength = 100;

    const closeModal = (send = false) => {
        if(send){
            close(true, {...formValues});
        } else {
            close(false, null);
        }
        
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const set = (name) => {
        return ({ target: { value } }) => {
            setBillingEditable(true);
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const validateBillingInfoForm = () => {
        console.log("what is validatation 123");
        var error = false;

        var form_errors = formErrors;

        if (formValues.name == "") {
            form_errors = {
                ...form_errors,
                name: "Required field",
                error_class: "input_error",
            };
            error = true;
        }
        if (!formValues.country) {
            form_errors = {
                ...form_errors,
                country: "Required field",
                error_class: "input_error",
            };
            error = true;
        }

        if (formValues.city == "") {
            form_errors = {
                ...form_errors,
                city: "Required field",
                error_class: "input_error",
            };
            error = true;
        }
        if (formValues.address == "") {
            form_errors = {
                ...form_errors,
                address: "Required field",
                error_class: "input_error",
            };
            error = true;
        }

        setFormErrors(form_errors);

        return error;
    };

    const validateFormDisabled = () => {
        var error = false;


        if (formValues.name == "") {
            error = true;
        }
        if (!formValues.country) {
            error = true;
        }

        if (formValues.city == "") {
            error = true;
        }
        if (formValues.address == "") {
            error = true;
        }

        return error;
    };

    const updateBillingInfo = () => {
        resetFormErrors();

        if (!billingLoading) {
            var error = false;

            error = validateBillingInfoForm();

            if (!error) {
                setBillingLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["billing_name"] = formValues.name;

                formData["billing_address_line1"] = formValues.address;

                countries.forEach(function (item) {
                    if (item.label == formValues.country) {
                        formData["billing_country"] = item.value;
                    }
                });

                formData["billing_city"] = formValues.city;

                // setTimeout(() => {
                //     setBillingLoading(false);
                //     closeModal(true);
                // }, 3000);

                // here I need response from api to send the updated billing info to parent

                // console.log(formValues)

                updateWorkspaceBillingService(formData, token, workspaceId)
                    .then(response => {

                        setBillingLoading(false);

                        if (response.success) {
                            showToast(response.message, "success");
                            closeModal(true);
                        } else {
                            showToast(response.message, "error");
                        }
                    })
            }
        }
    };

    useEffect(() => {
        setBillingEditable(false);
        resetFormErrors();
        setFormValues((oldValues) => ({
            ...oldValues,
            address: billingInfo.address
                ? billingInfo.address.slice(0, 30)
                : "",
            city: billingInfo.city ? billingInfo.city : "",
            country: billingInfo.country ? billingInfo.country : "",
            name: billingInfo.name ? billingInfo.name.slice(0, 30) : "",
        }));
    }, [open]);

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap billing-info-modal"
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className={`modal-inner-text `}>
                    <div className="modal-header-top">
                        <Text type="h3">Billing Info</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <>
                        <div className="billing-info-form">
                            <div className="row">
                                <div className="col-md-6 formbox">
                                    <Input
                                        label="Name/Company name"
                                        onChange={set("name")}
                                        //onClear={clearFormValue('name')}
                                        value={formValues.name}
                                        error={formErrors.name}
                                        error_class={formErrors.error_class}
                                        id="name"
                                        clearField={false}
                                        required={true}
                                        maxLength={nameMaxLength}
                                    />
                                </div>
                                <div className="col-md-6 formbox">
                                    <Input
                                        label="Email address"
                                        onChange={() => {}}
                                        onClear={() => {}}
                                        value={billingInfo.email}
                                        id="email"
                                        clearField={false}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-6 formbox">
                                    <div className="profile-form-group">
                                        <AutoCompleteSingleSelect
                                            type={"billing_country"}
                                            isClearable
                                            id={"contry"}
                                            label="Country"
                                            options={countries}
                                            value={formValues.country}
                                            error={formErrors.country}
                                            onChange={(country) => {
                                                if (country) {
                                                    setBillingEditable(true);
                                                    setFormValue(
                                                        "country",
                                                        country.value
                                                    );
                                                } else {
                                                    setBillingEditable(true);
                                                    setFormValue(
                                                        "country",
                                                        null
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 formbox">
                                    <Input
                                        label="City"
                                        onChange={set("city")}
                                        //onClear={clearFormValue('name')}
                                        value={formValues.city}
                                        error={formErrors.city}
                                        error_class={formErrors.error_class}
                                        id="city"
                                        clearField={false}
                                        required={true}
                                    />
                                </div>
                                <div className="col-md-12 formbox mb-0">
                                    <Input
                                        label="Address"
                                        onChange={set("address")}
                                        //onClear={clearFormValue('name')}
                                        value={formValues.address}
                                        error={formErrors.address}
                                        error_class={formErrors.error_class}
                                        id="address"
                                        clearField={false}
                                        required={true}
                                        maxLength={addressMaxLength}
                                    />
                                </div>
                                <div className="col-md-12 formbuttombox mb-0 mt-32">
                                    <div className="flex-buttons-wrap">
                                        <Button
                                            type="primary"
                                            size={`large`}
                                            microLoading={billingLoading}
                                            label="Save"
                                            onClick={() => {
                                                if (isBillingEditable) {
                                                    updateBillingInfo();
                                                }
                                            }}
                                            state={
                                                (isBillingEditable && !validateFormDisabled())
                                                    ? "active"
                                                    : "disabled"
                                            }
                                        />

                                        <Button
                                            type="secondary"
                                            size={`large`}
                                            label="Cancel"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                closeModal();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </Modal.Body>
        </Modal>
    );
}
