import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../themes/userq/Button/Button";
import { Text } from "../../themes/userq/Text/Text";
import { Input } from './../../themes/userq/Input/Input';
//import { ReactSession } from "../../../lib/secure_reactsession";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { createTest } from "../../../services/test";
import { LoadingIcon } from './../../loader/loadingIconNew';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import { ArabicNumbers } from 'react-native-arabic-numbers';
import { settings } from "../../../data/settings";
import {Icon} from "../../themes/userq/Icon/Icon";
import {DropdownComponent} from "../../themes/userq/Dropdown/Dropdown";
import { countries as payoutCountries } from "../../../data/bank_countries.js";
import { ibans as ibanList } from "../../../data/iban_details";
import {updatetesterbankdetails} from "../../../services/user";
import toast from "react-hot-toast";
import {showToast} from "../../../lib/helpers";
import {isValidPhoneNumber} from "react-phone-number-input";
import { Link as UserQLink} from '../../themes/userq/Link/Link.js';

import {AutoCompleteSingleSelect} from "../../themes/userq/Dropdown/AutoCompleteSingleSelect";

import { countries as sanctioned_countries } from "../../../data/countries_sanctioned.js";

export default function AddBankAccountModal(props) {
    const [formLoading, setFormLoading] = useState(false);

    const [bankFormValues, setBankFormValues] = useState({country:null, name:'', nickname:'',beneficiary_bank:'',branch:'',city:'',beneficiary_resident_country:null,account_no:'', account_no_type:"iban",type:'bank'});
    const [bankFormErrors, setBankFormErrors] = useState({});
    const [isError, setError] = useState(false);



    const [displaySanctionedMessage, setDisplaySanctionedMessage] = useState(false);

    const navigate = useNavigate();

    let user = ReactSession.get("user");


    const closeModal = (status) => {
        //setFormValues({ test_name: "" });
        // if status true then pass formvalue
        if(status){
            props.closeModal(status,bankFormValues);
        }else{
            props.closeModal(false,null);
        }

    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    useEffect(()=>{

        var error = false;

        console.log(bankFormValues)

        if(!bankFormValues.country){

            error = true;

        }
        if(bankFormValues.name==''){

            // error = true;

        }
        if(bankFormValues.beneficiary_bank==''){

            error = true;

        }
        if(bankFormValues.branch==''){

            error = true;

        }
        if(bankFormValues.city==''){

            error = true;

        }
        if(!bankFormValues.beneficiary_resident_country){

            error = true;

        }
        if(!bankFormValues.account_no){

            error = true;

        }

        // check account no
        var checkAccountValid = false;
        if(bankFormValues.account_no) {
            checkAccountValid = checkAccountNo(bankFormValues.country, bankFormValues.account_no);
        }

        //if()
        if(error){

            setError(true);
        }else{

            if(checkAccountValid){

                setError(true);
            }else{

                setError(false);
            }

        }



    },[bankFormValues])


    useEffect(()=>{

        if(props.bankFormValuesData){

            var sanctioned_country = false;

            sanctioned_countries.forEach(function(country){
                if(props.bankFormValuesData.beneficiary_resident_country==country.value){
                    sanctioned_country = true;
                }
            });
            setDisplaySanctionedMessage(sanctioned_country);

            setBankFormValues({
                country:props.bankFormValuesData.country,
                name:props.bankFormValuesData.name,
                nickname:props.bankFormValuesData.nickname,
                beneficiary_bank:props.bankFormValuesData.beneficiary_bank,
                branch:props.bankFormValuesData.branch,
                city:props.bankFormValuesData.city,
                beneficiary_resident_country:props.bankFormValuesData.beneficiary_resident_country,
                account_no:props.bankFormValuesData.account_no,
                account_no_type:props.bankFormValuesData.account_no_type,
                type:'bank'
            })
        }

        resetFormErrors();
    },[props.bankFormValuesData,props.openModal])
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, bankFormErrors);

        for(var key in bankFormErrors){
            formErrorsLocal[key] = null;
        }
        setBankFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }

    const validateBankDetails = () => {

        var error = false;

        var form_errors = resetFormErrors();


        if(!bankFormValues.country){

            form_errors  = {
                ...form_errors,
                country: "Required field",
                error_class: "input_error"
            };
            error = true;

        }
        if(bankFormValues.name==''){

            form_errors  = {
                ...form_errors,
                name: "Required field",
                error_class: "input_error"
            };
            error = true;

        }
        if(bankFormValues.beneficiary_bank==''){

            form_errors  = {
                ...form_errors,
                beneficiary_bank: "Required field",
                error_class: "input_error"
            };
            error = true;

        }
        if(bankFormValues.branch==''){

            form_errors  = {
                ...form_errors,
                branch: "Required field",
                error_class: "input_error"
            };
            error = true;

        }
        if(bankFormValues.city==''){

            form_errors  = {
                ...form_errors,
                city: "Required field",
                error_class: "input_error"
            };
            error = true;

        }
        if(!bankFormValues.beneficiary_resident_country){

            form_errors  = {
                ...form_errors,
                beneficiary_resident_country: "Required field",
                error_class: "input_error"
            };
            error = true;

        }

        // check account no
        var checkAccountValid = checkAccountNo(bankFormValues.country,bankFormValues.account_no);
        if(!bankFormValues.account_no){

            form_errors  = {
                ...form_errors,
                account_no: "Required field",
                error_class: "input_error"
            };
            error = true;

        }else if(checkAccountValid){
            form_errors  = {
                ...form_errors,
                account_no: "Please enter a valid IBAN",
                error_class: "input_error"
            };
            error = true;

        }


        setBankFormErrors(form_errors);

        return error;

    }

    const updatebankdetails = () => {
        var error = validateBankDetails();
        if(!error){
            setFormLoading(true);
            const token = ReactSession.get("token");
            var data = new FormData();
            data.append("bankdetails", JSON.stringify(bankFormValues));
            data.append("selectedbankcountry", bankFormValues.country);
            data.append("type", bankFormValues.type);
            // data.append("account_no_type", bankFormValues.iban);
            updatetesterbankdetails(data, token).then((response) => {

                setFormLoading(false);
                if (response.success) {
                    //gettesterbank();
                    //setIsBankAccountEditable(false);

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'select_payout',
                        'method' : 'bank', //not both, depending on selection
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });

                    closeModal(true);

                    showToast(response.message,"success");

                } else {

                    showToast(response.message,"error");

                }
            });
        }
    };

    const onlyLettersAndNumbers = (str)=> {
        return /^[A-Za-z0-9]*$/.test(str);
    }
    const checkFirstTwoLettersAlphabet= (str)=> {
        return /^[A-Za-z]*$/.test(str);
    }

    const checkAccountNo = (country,val)=>{
// if(bankFormValues.cou)
        var error = false;
        // console.log(val)
        //console.log(country)
        if(!country){
            error = true;
            // setBankFormErrors({...bankFormErrors,"account_no":"Please enter a valid IBAN1", error_class:"input_error"});
        }else if(country){


            if(val) {
                var regExp = /[a-zA-Z]/g;
                var IbanVal = val;

                /// check value first two character is alphabet
                //console.log(IbanVal.slice(0, 2))
                if (checkFirstTwoLettersAlphabet(IbanVal.slice(0, 2))) {

                    // console.log('231312')
                    // check iban number not contains any special character
                    if (onlyLettersAndNumbers(IbanVal)) {

                        console.log('asdas')

                        // check country code is exists in our record ibanList
                        var checkPayoutCountryCode = payoutCountries.find((items) => items.value === country)
                        var checkCountryCode = ibanList.find((items) => items.code === checkPayoutCountryCode.code)
                        //console.log(checkCountryCode)
                        if (checkCountryCode) {

                            //console.log('12')
                            // check country matched
                            if (checkCountryCode.code === IbanVal.slice(0, 2)) {

                                //console.log('45')
                                if (!val.match(/^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|NL\d{2}[A-Z]{4}\d{10}|RO\d{2}[A-Z]{4}\d[A-Z]\d{14}|LV\d{2}[A-Z]{4}\d{13}|FR\d{19}[A-Z]\d{5}|LI\d{17}[A-Z]{2}|MD\d{2}[A-Z]{2}\d{18}|(?:BG|GB|IE)\d{2}[A-Z]{4}\d{14}|BH\d{2}[A-Z]{4}\d{10}[A-Z]{2}\d{2}|GE\d{2}[A-Z]{2}\d{16}|GI\d{2}[A-Z]{4}\d{15}|BR\d{25}[A-Z]\d|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|PS\d{2}[A-Z]{4}\d{21}|QA\d{2}[A-Z]{4}\d{14}[A-Z]{7}|(?:AZ|DO|GT)\d{2}[A-Z]{4}\d{20}|(?:BJ|ML|SN|CI)\d{2}[A-Z]\d{23}|(?:PK|VG)\d{2}[A-Z]{4}\d{16}|(?:KW|JO)\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{12}[A-Z]{7}\d{3}[A-Z]|NO\d{13}|(?:BE|BI)\d{14}|(?:DK|FI|GL|FO)\d{16}|(?:MK|SI)\d{17}|(?:BA|LT|AT|EE|KZ|LU|XK)\d{18}|(?:HR|CH|CR)\d{19}|(?:DE|ME|RS)\d{20}|(?:AE|IL|TL)\d{21}|(?:AD|CZ|ES|SA|DZ|SK|SE|TN)\d{22}|(?:PT|AO|CV|MZ)\d{23}|(?:IS|IR|TR)\d{24}|(?:MR|MC|BF|CM|GR|MG)\d{25}|(?:EG)\d{27}|(?:AL|DO|LB|PL|CY|HU)\d{26})$/i)) {
                                    //console.log('12');
                                    error = true;
                                    // setBankFormErrors({
                                    //     ...bankFormErrors,
                                    //     "account_no": "Please enter a valid IBAN2",
                                    //     error_class: "input_error"
                                    // });
                                } else {
                                    // console.log('123');
                                    // setBankFormErrors({
                                    //     ...bankFormErrors,
                                    //     "account_no": "3",
                                    //     error_class: "input_error"
                                    // });
                                }

                            } else {
                                error = true;
                                // setBankFormErrors({
                                //     ...bankFormErrors,
                                //     "account_no": "Please enter a valid IBAN4.",
                                //     error_class: "input_error"
                                // });
                            }

                        } else {
                            //setBankFormErrors({...bankFormErrors, "account_no": '5', error_class: "input_error"});
                        }

                    } else {
                        error = true;
                        // setBankFormErrors({
                        //     ...bankFormErrors,
                        //     "account_no": "Please enter a valid IBAN6.",
                        //     error_class: "input_error"
                        // });
                    }
                } else {
                    error = true;
                    // setBankFormErrors({
                    //     ...bankFormErrors,
                    //     "account_no": "Please enter a valid IBAN7",
                    //     error_class: "input_error"
                    // });
                }
            }else{
                error = true;
                //  setBankFormErrors({...bankFormErrors,"account_no":'Required field', error_class:"input_error"});
            }

        }else{
            //setBankFormErrors({...bankFormErrors,"account_no":null, error_class:"input_error"});
        }

        console.log(error)
        if(error){

            if(country) {
                setBankFormErrors({
                    ...bankFormErrors,
                    "account_no": "Please enter a valid IBAN",
                    error_class: "input_error"
                });
            }
        }else{
            setBankFormErrors({...bankFormErrors,"account_no":null, error_class:"input_error"});
        }
        return error;
    }


    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap add-bank-account-modal "
        >
            <Modal.Body ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h3">{props.type} your bank account</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal(false);
                            }}
                            aria-label="Close"
                        >
                                <span aria-hidden="true">
                                    <Icon value="Close" size="large" hover={true} />
                                </span>
                        </button>
                    </div>

                    <div className="tester_profole_guide_info mb-20">
                        <Text type="body-text-2" fontWeight="medium-font" cssClasses="text-black">
                            Make sure that the name and surname on your bank account match the ID document provided for account verification. Accounts with a different holder name will not be accepted. If you have any question <UserQLink target={'_blank'} url={process.env.REACT_APP_URL+"t/support/contact?reason=Help with payout"}>contact our support team</UserQLink>.
                        </Text>
                    </div>
                    <div className="add-bank-account-modal-middle-data">

                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={user.first_name ? user.first_name:''}
                                label={"Account holder first name"}
                                clearField={false}
                                required={true}
                                readOnly={true}
                            />
                        </div>

                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={user.last_name ? user.last_name:''}
                                label={"Account holder last name"}
                                clearField={false}
                                required={true}
                                readOnly={true}
                            />
                        </div>


                        {bankFormValues.beneficiary_resident_country ?
                            <>
                                <div className={"mb-20"}>
                                    <Input
                                        type="text"
                                        value={bankFormValues.beneficiary_resident_country}
                                        label={"Beneficiary resident country"}
                                        clearField={false}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                                {displaySanctionedMessage &&
                                <div className="formbox  sanctioned-info">
                                    <div className=' form-group input-field  input-with-right-icon mb-0  '>
                                        <ToastMessage closable={false} type={"warning"} message={<>Please be aware that, in compliance with both local and international regulations, we may not be able to process payouts to certain countries. If you need more information, please refer to our <UserQLink target="_blank" url="https://userq.com/terms-of-use">Terms of use</UserQLink>.</>} />

                                    </div>
                                </div>
                                }
                            </>
                            :
                            <>
                                <div className={"mb-20"}>
                                    <AutoCompleteSingleSelect
                                        isClearable={true}
                                        type={"country"}
                                        bankCountries={true}
                                        options={payoutCountries}
                                        value={bankFormValues.beneficiary_resident_country}
                                        isSanctionedCountry={displaySanctionedMessage?true:false}
                                        onChange={
                                            (item)=>{

                                                if(item){
                                                    setBankFormValues({...bankFormValues,beneficiary_resident_country:item.value});

                                                    setBankFormErrors({...bankFormErrors,beneficiary_resident_country:null});
                                                } else {
                                                    setDisplaySanctionedMessage(false);

                                                    setBankFormValues({...bankFormValues,beneficiary_resident_country:null});

                                                    setBankFormErrors({...bankFormErrors,beneficiary_resident_country:"Required field"});
                                                }
                                                var sanctioned_country = false;

                                                sanctioned_countries.forEach(function(country){
                                                    if(item.value==country.value){
                                                        sanctioned_country = true;
                                                    }
                                                });
                                                setDisplaySanctionedMessage(sanctioned_country);

                                            }
                                        }
                                        label="Beneficiary resident country"
                                        menuPlacement="top"
                                        error={bankFormErrors.beneficiary_resident_country}
                                        error_class={ bankFormErrors.beneficiary_resident_country != null
                                            ? bankFormErrors.error_class
                                            : ""
                                        }
                                    />
                                </div>
                                {displaySanctionedMessage &&
                                <div className="formbox">
                                    <div className=' form-group input-field  input-with-right-icon mb-0  '>
                                        <ToastMessage closable={false} type={"warning"} message={<>Please be aware that, in compliance with both local and international regulations, we may not be able to process payouts to certain countries. If you need more information, please refer to our <UserQLink url="https://userq.com/terms-of-use">Terms of use</UserQLink>.</>} />

                                    </div>
                                </div>
                                }
                            </>
                        }





                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                onBlur={(e)=>{
                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"beneficiary_bank":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"beneficiary_bank":null, error_class:"input_error"});
                                    }
                                }}
                                value={bankFormValues.beneficiary_bank ? bankFormValues.beneficiary_bank:''}
                                onChange={(e)=>{
                                    setBankFormValues({...bankFormValues, beneficiary_bank:e.target.value});

                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"beneficiary_bank":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"beneficiary_bank":null, error_class:"input_error"});
                                    }
                                }}
                                label={"Beneficiary bank"}
                                error_class={
                                    bankFormErrors.beneficiary_bank != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.beneficiary_bank}
                                clearField={false}
                                required={true}
                            />
                        </div>

                        <div className={"mb-20"}>
                            <AutoCompleteSingleSelect
                                type={"country"}
                                isClearable={true}
                                options={payoutCountries}
                                bankCountries={true}
                                value={bankFormValues.country}
                                onChange={
                                    (item)=>{

                                        if(item){

                                            setBankFormValues({...bankFormValues,country:item.value})

                                            if(bankFormValues.account_no){

                                                checkAccountNo(item.value,bankFormValues.account_no);
                                            }
                                            setBankFormErrors({...bankFormErrors,country:null});

                                        } else {
                                            setBankFormValues({...bankFormValues,country:null})

                                            //checkAccountNo(null,bankFormValues.account_no);

                                            setBankFormErrors({...bankFormErrors,country:"Required field"});


                                        }




                                    }
                                }
                                label="Bank country"
                                error={bankFormErrors.country}
                                error_class={ bankFormErrors.country != null
                                    ? bankFormErrors.error_class
                                    : ""
                                }
                            />
                        </div>

                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={bankFormValues.account_no ? bankFormValues.account_no.toUpperCase():''}
                                onBlur={(e)=>{
                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"account_no":"Required field", error_class:"input_error"});
                                    }
                                    // else if(!e.target.value.match(/^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|NL\d{2}[A-Z]{4}\d{10}|RO\d{2}[A-Z]{4}\d[A-Z]\d{14}|LV\d{2}[A-Z]{4}\d{13}|FR\d{19}[A-Z]\d{5}|LI\d{17}[A-Z]{2}|MD\d{2}[A-Z]{2}\d{18}|(?:BG|GB|IE)\d{2}[A-Z]{4}\d{14}|BH\d{2}[A-Z]{4}\d{10}[A-Z]{2}\d{2}|GE\d{2}[A-Z]{2}\d{16}|GI\d{2}[A-Z]{4}\d{15}|BR\d{25}[A-Z]\d|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|PS\d{2}[A-Z]{4}\d{21}|QA\d{2}[A-Z]{4}\d{14}[A-Z]{7}|(?:AZ|DO|GT)\d{2}[A-Z]{4}\d{20}|(?:BJ|ML|SN|CI)\d{2}[A-Z]\d{23}|(?:PK|VG)\d{2}[A-Z]{4}\d{16}|(?:KW|JO)\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{12}[A-Z]{7}\d{3}[A-Z]|NO\d{13}|(?:BE|BI)\d{14}|(?:DK|FI|GL|FO)\d{16}|(?:MK|SI)\d{17}|(?:BA|LT|AT|EE|KZ|LU|XK)\d{18}|(?:HR|CH|CR)\d{19}|(?:DE|ME|RS)\d{20}|(?:AE|IL|TL)\d{21}|(?:AD|CZ|ES|SA|DZ|SK|SE|TN)\d{22}|(?:PT|AO|CV|MZ)\d{23}|(?:IS|IR|TR)\d{24}|(?:MR|MC|BF|CM|GR|MG)\d{25}|(?:AL|DO|LB|PL|CY|HU)\d{26})$/i)){
                                    //     setBankFormErrors({...bankFormErrors,"account_no":"Please enter a valid IBAN", error_class:"input_error"});
                                    // }
                                    else {
                                        checkAccountNo(bankFormValues.country,e.target.value);

                                    }
                                }}
                                onChange={(e)=>{
                                    setBankFormValues({...bankFormValues, account_no:e.target.value.toUpperCase()});

                                    //
                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"account_no":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"account_no":null, error_class:"input_error"});
                                    }
                                }}
                                label={"IBAN"}
                                error_class={
                                    bankFormErrors.account_no != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.account_no}
                                clearField={false}
                                required={true}
                            />
                        </div>


                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={bankFormValues.branch ? bankFormValues.branch:''}
                                onBlur={(e)=>{
                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"branch":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"branch":null, error_class:"input_error"});
                                    }
                                }}
                                onChange={(e)=>{
                                    setBankFormValues({...bankFormValues, branch:e.target.value});

                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"branch":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"branch":null, error_class:"input_error"});
                                    }
                                }}
                                label={"Branch"}
                                error_class={
                                    bankFormErrors.branch != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.branch}
                                clearField={false}
                                required={true}
                            />
                        </div>

                        <div className={"mb-20"}>
                            <Input
                                type="text"
                                value={bankFormValues.city ? bankFormValues.city:''}
                                onBlur={(e)=>{
                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"city":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"city":null, error_class:"input_error"});
                                    }
                                }}
                                onChange={(e)=>{
                                    setBankFormValues({...bankFormValues, city:e.target.value});

                                    if(!e.target.value){
                                        setBankFormErrors({...bankFormErrors,"city":"Required field", error_class:"input_error"});
                                    } else {
                                        setBankFormErrors({...bankFormErrors,"city":null, error_class:"input_error"});
                                    }
                                }}
                                label={"City"}
                                error_class={
                                    bankFormErrors.city != null
                                        ? bankFormErrors.error_class
                                        : ""
                                }
                                error={bankFormErrors.city}
                                clearField={false}
                                required={true}
                            />
                        </div>





                        {/*<div className={"mb-20"}>*/}
                        {/*    <Input*/}
                        {/*        type="text"*/}
                        {/*        value={bankFormValues.name ? bankFormValues.name:''}*/}
                        {/*        onBlur={(e)=>{*/}
                        {/*            if(!e.target.value){*/}
                        {/*                setBankFormErrors({...bankFormErrors,"name":"Required field", error_class:"input_error"});*/}
                        {/*            } else {*/}
                        {/*                setBankFormErrors({...bankFormErrors,"name":null, error_class:"input_error"});*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        onChange={(e)=>{*/}
                        {/*            setBankFormValues({...bankFormValues, name:e.target.value});*/}

                        {/*            if(!e.target.value){*/}
                        {/*                setBankFormErrors({...bankFormErrors,"name":"Required field", error_class:"input_error"});*/}
                        {/*            } else {*/}
                        {/*                setBankFormErrors({...bankFormErrors,"name":null, error_class:"input_error"});*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        label={"Account holder name"}*/}
                        {/*        error_class={*/}
                        {/*            bankFormErrors.name != null*/}
                        {/*                ? bankFormErrors.error_class*/}
                        {/*                : ""*/}
                        {/*        }*/}
                        {/*        error={bankFormErrors.name}*/}
                        {/*        clearField={false}*/}
                        {/*        required={true}*/}
                        {/*    />*/}
                        {/*</div>*/}






                    </div>

                    <Button
                        type="primary"
                        size="large"
                        label="Save your bank information"
                        microLoading={formLoading}
                        onClick={updatebankdetails}
                        state={!isError?'active':'disabled'}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}