import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession.js';
import React, {useEffect, useState} from "react";
import ProfileTester from './profile_tester.js';
import ProfileResearcher from './profile_researcher.js';

export default function Step1() {

    ReactSession.setStoreType("localStorage");
    const navigate = useNavigate();
    const [user,setUser] = useState({"role":""});

      
    useEffect(() => {

        let user = ReactSession.get("user");
        setUser(user);
    }, []);
    
    const loginUserProfile = () => {
        if(user.role=="Tester"){
            return <ProfileTester />
        } else {{
            // return <ProfileResearcher />
        }}
    }    
    return (
        <>
            {loginUserProfile()}
        </>
    )
}