import React from "react";
import { Text } from './../Text/Text';
import { Icon } from '../Icon/Icon';
import { Button } from "../Button/Button";


export const SignUpIdentityTile = ({title, isLoading, onClick, disabled})=>{
    return (
        <div className="identity_tile_wrap">
            <div className='sidentity_tile_inner d-flex justify-content-between align-items-center'>
                <Text  type={'subtitle-2'} fontWeight={'medium-font'}>{title}</Text>

                <Button state={disabled?"disabled":"active"} onClick={onClick} microLoading={isLoading} cssclass="none-for-mobile" type="primary" size="medium" iconLeft={<Icon  colorClass="gray-50-svg" value="id" size="small" />} label="Verify your ID"/>
                <Button state={disabled?"disabled":"active"} onClick={onClick} microLoading={isLoading} cssclass="none-for-desktop" type="primary" size="medium" iconLeft={<Icon  colorClass="gray-50-svg" value="id"  size="small" />} label="Verify"/>
            </div>

            <div className='sidentity_tile_inner-footer d-flex justify-content-between align-items-center'>

                <div className={'left-content'}>
                    <Icon colorClass="gray-900-svg" value={'passport'} size={'small'} />

                    <Text  type={'caption'} fontWeight={'medium-font'}>Please have your ID document ready</Text>
                </div>

                <div className={'right-content'}>
                    <Icon colorClass="gray-900-svg" value={'time'} size={'small'} />
                    <Text  type={'caption'} fontWeight={'medium-font'}>Estimated 3 to 5 mins</Text>
                </div>
            </div>
        </div>
    )
}