import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Text } from "../themes/userq/Text/Text";
import { Icon } from "../themes/userq/Icon/Icon";
import { Button } from "../themes/userq/Button/Button";

export default function DebriefingPage({ debriefingCallback, test }) {
    return (
        <div className={`tester-page-wrapper tester-defiefing-question-step `}>
            <div className="container inner-page-container">
                <div className="taking-test-page-height">

                    <div className="defefing-question-step-data">
                        <div className="defefing-question-step-inner-data">

                            <Text type={'h1'}>
                                {test.language == "en" ? 'Almost there!' : 'اوشكت على الوصول!'}
                            </Text>

                            <Text type={'h2'} cssClasses={'mt-32 mb-32'}>
                                {test.language == "en" ? 'Just a few more questions to go' : 'فقط عدد قليل من الأسئلة للذهاب'}
                            </Text>

                            <div className="button-wrapper d-flex justify-content-center">
                                <Button
                                    size={'large'}
                                    iconRight={<Icon size={"large"} colorClass={"gray-50-svg m-0"} value={test.language=="ar"?'back-arrow':'forward-arrow'} />}
                                    label={test.language == "en" ? "Next" : "التالي"}
                                    onClick={() => debriefingCallback()}
                                />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}
