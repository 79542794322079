import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Icon } from './../Icon/Icon';
import { CircularNameIcon } from "../CircularNameIcon/CircularNameIcon";


export const EmailInput=React.forwardRef(({type, readOnly, isValid, style, name,  required, value, defaultValue, maxLength, hideMaxChars,id, onChange, error, onClear, label, clearField, focussed, disabled, onKeyDown,onKeyUp, onBlur, autoFocus, onInput, rtl, passwordField, onClick, passwordSuggestion, newPasswordError, autocomplete, leftIcon, color},ref)=>{

    // console.log("value", value)
    const [fieldType, setFieldType] =   useState((type ? type : "text"));
    const [passwordVisible, setPasswordVisibility] = useState(false);
    const [elemValue, setElemValue] = useState(value);
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    useEffect(()=>{
        setElemValue(value)
    },[value])
    
    var password_error = false;

    if(newPasswordError && newPasswordError.error){
        if(newPasswordError.length>1 || newPasswordError.special>1 || newPasswordError.letters>1 || newPasswordError.numbers>1){
            password_error = true;
        }
    }
    return (
        
            <div  className={` form-group input-field emailinput-wrap ${(clearField==true || isValid )?'input-with-right-icon':''} mb-0 ${( value!='' || focussed==true)?'focused':''}  ${(error || password_error)?'field-error':''} ${readOnly==true?'read-only-field':''} ${disabled==true?'disabled-field':''}`}>
                <div className={`input-position-relative  ${(rtl==true)?'arabic_wrapper':''}`}>
                    <input
                        ref={ref}
                        name={name}
                        type={passwordField?(passwordVisible ? "text" : "password"):fieldType}
                        id={id}
                        className={`form-control body-text body-text-2 ${(rtl==true)?'arabic_wrapper':''}`}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={(e)=>
                            { 
                                setElemValue(e.target.value);

                                if(onChange){
                                    onChange(e);
                                }
                                
                            }
                        }
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        onBlur={onBlur}
                        onInput={onInput}
                        autoFocus={autoFocus}
                        rtl={rtl?rtl:''}
                        readOnly={readOnly}
                        autoComplete={autocomplete?autocomplete:"new-password"}
                        onFocus={onClick}

                    />

                    

                    {
                        value ?   <div className="user-icon"><CircularNameIcon
                        style={{background: color}}
                        label={
                            value
                              ? value.charAt(0).toUpperCase()
                              : value.charAt(0).toUpperCase()
                          }
                    /></div> : 
                        <span className="input-left-icon user-icon" >
                            <Icon value="profile" colorClass={'gray-600-svg'}  size="medium" />
                        </span>
                    }

                  

                 


                    {clearField==true && value!="" && !readOnly && (
                    <span className="input-right-icon" onClick={()=>{ if(onClear){onClear();} }}>
                        <Icon value="close" size="medium" hover={true}/>
                    </span>
                    )}

                    {readOnly==true && !isValid && !disabled && (
                        /*onClick={()=>{ if(onClear){onClear();} }} */
                    <span className="input-right-icon" >
                        <Icon value="lock" size="medium" />
                    </span>
                    )}

                    {passwordField==true && passwordVisible==true && (
                    <span className="input-right-icon password-svg" onClick={togglePassword}>
                        <Icon value="hide" size="medium" hover={true}/>
                    </span>
                    )}
                    {passwordField==true && passwordVisible==false && (
                    <span className="input-right-icon password-svg" onClick={togglePassword}>
                        <Icon value="eye" size="medium" hover={true}/>
                    </span>
                    )}
                    {isValid=="Yes" && (
                    <span className="input-right-icon input-right-without-pointer-cursor">
                        <Icon value="check-mark" colorClass={'success-200-svg check-mark'} size="medium"/>
                    </span>
                    )}
                    {isValid=="No" && (
                    <span className="input-right-icon input-right-without-pointer-cursor">
                        <Icon value="attention" colorClass={'danger-200-svg attention'} size="medium"/>
                    </span>
                    )}
                    {onClick && (
                    <span className="input-right-icon" onClick={onClick}>
                        <Icon value="calendar" size="medium" hover={true}/>
                    </span>
                    )}
                    
                    <label className="control-label body-text body-text-2" htmlFor={id}>
                        {label}
                        {!required && rtl==false &&
                        (" (Optional)")
                        }
                        {!required && rtl==true &&
                        (" (اختياري)")
                        }
                    </label>
                    
                    
                </div>
                {!error && maxLength && !disabled &&
                <>
                {!hideMaxChars &&
                <p className="caption">
                    <span className="max-characters">
                        <>{value?value.length:0}/</>

                        {maxLength}
                    </span>
                </p>
}
                </>
                }

                {error != null && (
                    <span className="caption">
                        {error}
                    </span>
                )}
                {(error == null || error == '') && type=="password" && passwordSuggestion && (
                    <span className="caption">
                         {passwordSuggestion}
                    </span>
                )}
                {!error && newPasswordError && newPasswordError.error &&
                <div>
                    <ul className="password-validition-ul">
                        <li className={`${newPasswordError.length==1?'green-color-txt':''} ${newPasswordError.length==2?'red-color-txt':''}`}>Minimum 6 characters</li>
                        <li className={`${newPasswordError.letters==1?'green-color-txt':''} ${newPasswordError.letters==2?'red-color-txt':''}`}>Letters</li>
                        <li className={`${newPasswordError.numbers==1?'green-color-txt':''} ${newPasswordError.numbers==2?'red-color-txt':''}`}>Numbers</li>
                        <li className={`${newPasswordError.special==1?'green-color-txt':''} ${newPasswordError.special==2?'red-color-txt':''}`}>Special characters</li>
                    </ul>
                </div>
                }
            </div>
        
    )
}); 
EmailInput.propTypes = {
    type:PropTypes.string,
    required:PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    maxLength: PropTypes.any,
    id:PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    autoFocus: PropTypes.func,
    error: PropTypes.string,
    label: PropTypes.string,
    clearField:PropTypes.bool,
    focussed:PropTypes.bool,
    disabled:PropTypes.bool,
    passwordSuggestion: PropTypes.string,
    
};

EmailInput.defaultProps = {
    required:false,
    clearField:true,
    onChange: undefined,
    onKeyDown: undefined,
    autoFocus: undefined,
    focussed: false,
    disabled:false,
    passwordSuggestion:""
    
};
