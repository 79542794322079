import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Text } from "../Text/Text";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "../Icon/Icon";
import { ShareLink } from "../ShareLink/ShareLink";
import { Button } from '../../../themes/userq/Button/Button';
import {
FacebookShareButton,
        FacebookIcon,
        TwitterShareButton,
        TwitterIcon,
        WhatsappShareButton,
        WhatsappIcon
        } from "react-share";

export default function WelcomeMessageModal( {
open,
        title,
        subtitle,
        btn,
        close,
        body
        }) {
    const closeModal = () => {
        close();
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);


    const navigate = useNavigate();
    return (
            <Modal
                show={open}
                centered
                className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap welcom-userq-modal researcher-welcome-modal"
                >
            
                <Modal.Body className="" ref={wrapperRef}>
                    <div className="congrats-modal-header">
                        <Text type={"h1"}>Welcome to UserQ</Text>
                    </div>
            
            
                    <div className="congrats-modal-text">
                        
                        {body}
                        <div className="congrts-modal-wrap">
                         {btn}
                        </div>
                        
                    </div> 
                </Modal.Body>
            </Modal>
            );
}
