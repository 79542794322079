import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import {Icon} from "../themes/userq/Icon/Icon";
import {Text} from "../themes/userq/Text/Text";
import {Button} from "../themes/userq/Button/Button";

export default function AlreadyTakenTest({test}) {

    const navigate = useNavigate();
    return (
        <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap">
            <div className="container">
                <div className={`no-participants-data no-questions-data no-questions-added`}>
                    <div className="no-page-data-wrap" style={{marginTop:"80px"}}>
                        <div className="no-data-icon">
                            <Icon colorClass={"gray-900-svg"} value={"Attention"} size={"large"} />
                        </div>

                        <Text type={"subtitle-1"} fontWeight="medium-font">
                            It looks like you’ve already taken this test!

                        </Text>

                        <Text type={"body-text-1"} fontWeight={'medium-font'}>
                            {(ReactSession.get("token")) ?
                                <>You can only take each test once – head back to the UserQ dashboard to see if<br/> another one is available. </>
                                :
                                <>Share your opinion and make money. Create your profile on UserQ.</>
                            }
                            </Text>

                    </div>

                    <div className={"fotter-wrap text-center"}>
                        {(ReactSession.get("token")) &&
                        <Button
                            type={"primary"}
                            size={"large"}
                            iconRight={<Icon size={"medium"} colorClass="gray-50-svg" value={"forward-arrow"} />}
                            label={"Back to dashboard"}
                            onClick={() => {
                                let user = ReactSession.get("user");

                                if(user && user.role === "Researcher" && test){
                                    navigate('/wsp/'+test.workspace_id+"/p/"+test.workspace_project_id);
                                } else {
                                    navigate('/dashboard');
                                }
                                
                            }}
                        />
                        }

                        {!(ReactSession.get("token")) &&
                        <Button
                            type={"primary"}
                            size={"large"}
                            iconRight={<Icon size={"medium"} colorClass="gray-50-svg" value={"forward-arrow"} />}
                            label={"Get started"}
                            onClick={() => {
                                navigate("/tester/sign-up")
                            }}
                        />
                        }
                    </div>
                </div>
                {/*<div className="login-left-side">*/}
                {/*    <h1 className="form-heading">It looks like you’ve already taken this test!</h1>*/}
                {/*    {(ReactSession.get("token")) &&*/}
                {/*    <p className="form-sub-head">You can only take each test once – head back to the UserQ dashboard to*/}
                {/*        see if<br/> another one is available. </p>*/}
                {/*    }*/}

                {/*    {!(ReactSession.get("token")) &&*/}
                {/*    <p>Share your opinion and make money. Create your profile on UserQ.</p>*/}
                {/*    }*/}
                {/*    <div className="login-form-inner-data">*/}
                {/*        {(ReactSession.get("token")) &&*/}
                {/*        <Link to={"/dashboard"} className="btn btn-block form-btn login-page-btn mt-0">*/}
                {/*            Back to dashboard*/}
                {/*        </Link>*/}
                {/*        }*/}
                {/*        {!(ReactSession.get("token")) &&*/}
                {/*        <Link to={"/tester/sign-in"} className="btn btn-block form-btn login-page-btn mt-0">*/}
                {/*            Get Started*/}
                {/*        </Link>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/*<div className="full home-marquee-banner section">*/}
            {/*    <div className="home-marque-banner-box">*/}
            {/*        <div className="scrolling-box">Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*        <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        );
}
