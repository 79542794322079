import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout";
import { useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getProfile, updateReferalLinkWorkspaceService } from "../../services/user";
import { getTesterCreditSummaryService } from "../../services/credits";
import { researcherEarnInviteInvitation } from "../../services/user";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FullPageLoadingIcon } from "../loader/full_page_loader_lcon";
import { roundNumber, showToast } from "../../lib/helpers";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { Text } from "../themes/userq/Text/Text";
import { Icon } from "../themes/userq/Icon/Icon";
import { Input } from "../themes/userq/Input/Input";
import { Button } from "../themes/userq/Button/Button";
import { ShareLink } from "../themes/userq/ShareLink/ShareLink";
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { LoadingIcon } from "../themes/userq/Loader/loadingIcon";
import { getWorkspacesListService } from "../../services/workspaces";
import { DropdownComponent } from "../themes/userq/Dropdown/Dropdown";
import Skeleton from "react-loading-skeleton";

export default function EarnAndInvite() {
    const navigate = useNavigate();
    const WorkspaceList = [
        { label: "Workspace One", value: "Workspace One" },
        { label: "Workspace Two", value: "Workspace Two" },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);

    const [emailValues, setEmailValues] = useState({ subject: "", body: "" });

    const [formValue, setFormValue] = useState({
        email: "",
        error: "",
        error_class: "",
    });

    const [user, setUser] = useState(null);
    const [credits, setCredits] = useState(null);
    const [show_support_panel, setShowSupportPanel] = useState(false);

    const [workspaces, setWorkspaces] = useState([]);

    const [workspace, setWorkspace] = useState(null);

    const [workspaceLoading, setWorkspaceLoading] = useState(false);

    const getWorkspacesListApi = () => {
        let token = ReactSession.get("token");

        setIsLoading(true);

        getWorkspacesListService(token, 1)
            .then((response) => {
                setIsLoading(false);

                if (response.success) {
                    setWorkspaces(response.workspaces);
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);
                setIsLoading(false);
            });
    };

    const getProfileData = () => {
        let token = ReactSession.get("token");

        setIsLoading(true);

        getProfile(token).then((response) => {
            //setIsLoading(false);
            if (response.success) {
                if (response.data.user.role !== "Researcher") {
                    navigate("/404");
                }
                setUser(response.data.user);

                // set email data
                setEmailValues({
                    subject:
                        "UserQ - Join MENA's First UI/UX Research Platform",
                    body:
                        "Hey, %0D%0A %0D%0AWant to make some extra cash?" +
                        " Become a researcher with UserQ and get paid to help brands" +
                        " in the MENA's region shape their websites, apps and other digital products. %0D%0A %0D%0A" +
                        process.env.REACT_APP_URL +
                        "researcher/sign-up?refID=" +
                        response.data.user.referral_code +
                        "\n \nRegards,%0D%0A" +
                        response.data.user.first_name +
                        " " +
                        response.data.user.last_name,
                });
                setWorkspace(response.data.user.referral_workspace_id ? response.data.user.referral_workspace_id : null);
                getWorkspacesListApi();
            } else {
                setIsLoading(false);
            }
        });
    };

    const fetchCreditsSummary = () => {
        setIsLoading(true);
        if (!credits) {
            let token = ReactSession.get("token");

            getTesterCreditSummaryService(token).then((response) => {
                setIsLoading(false);
                if (response.success) {
                    setCredits(response.summary.credits_balance);
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    };

    const sendInvitationRequest = () => {
        setBtnLoading(true);
        if (!btnLoading) {
            let token = ReactSession.get("token");

            var formData = { ...formValue };

            researcherEarnInviteInvitation(formData, token).then((response) => {
                setBtnLoading(false);
                if (response.success) {
                    setFormValue({ email: "", error: "", error_class: "" });
                    showToast(response.message, "success");
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    };
    const updateReferralWorkspaceRequest = (workspace_id) => {
        setWorkspaceLoading(true);
        if (!workspaceLoading) {
            let token = ReactSession.get("token");

            var formData = { workspace_id: workspace_id };

            updateReferalLinkWorkspaceService(formData, token).then((response) => {
                setWorkspaceLoading(false);
                if (response.success) {
                    setWorkspace(workspace_id)
                    
                } else {
                    showToast(response.message, "error");
                }
            });
        }
    };

    useEffect(() => {
        document.title = "Invite & Earn | " + process.env.REACT_APP_NAME;
        getProfileData();

        fetchCreditsSummary(); // get total credits of testers
    }, []);

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={"invite-and-earn"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <div className="earn-page-wrapper">
                {isLoading && (
                    <div
                        className={
                            "page-loader  accountloading-box earn-page-loader"
                        }
                    >
                        <LoadingIcon />
                    </div>
                )}
                {!isLoading && user && (
                    <div className="profile-earn-invite">
                        <div className="row align-items-center">
                            <div className="col-md-6 invite-earn-left">
                                <div className="invite-earn-data-text">
                                    <Text type={"h1"}>
                                        Invite friends & earn{" "}
                                        {user &&
                                            roundNumber(
                                                user.config_credit_amount,
                                                2
                                            )}{" "}
                                        credits!
                                    </Text>

                                    <div
                                        className={
                                            "earn-invite-left-info-icons"
                                        }
                                    >
                                        <Icon
                                            value="earn-invite-profile"
                                            size="small"
                                        />
                                        <Icon
                                            value="earn-invite-share"
                                            size="small"
                                        />
                                        <Icon
                                            value="earn-invite-credit"
                                            size="small"
                                        />
                                    </div>

                                    <div className="earn-invite-info">
                                        <Text
                                            type={"body-text-1"}
                                            fontWeight={"medium-font"}
                                        >
                                            Invite researchers to register with
                                            UserQ. With their first purchase,
                                            you will receive{" "}
                                            {user && user.config_credit_amount}{" "}
                                            credits into your account to use on
                                            tests and recruitment. Visit our{" "}
                                            <a
                                                href="https://userq.com/terms-of-use/"
                                                target="_blank"
                                            >
                                                Terms of use
                                            </a>{" "}
                                            for more info.
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 invite-earn-right">
                                <div className="invite-earn-form-right">
                                    <div className="invite-earn-balancebox">
                                        <Text type={"h2"}>Total earnings:</Text>
                                        <div className="earn-pricebox">
                                            {/*user.total_earning_referral_user*/}
                                            <Text type={"h1"}>
                                                {roundNumber(
                                                    user.total_earning_referral_user,
                                                    2
                                                )}
                                            </Text>
                                            <Text
                                                type={"body-text-2"}
                                                fontWeight={"medium-font"}
                                            >
                                                credits
                                            </Text>
                                        </div>
                                    </div>

                                    <div className="refer-earn-workspace-wrap mb-32">
                                        <Text
                                            type={"body-text-1"}
                                            fontWeight={"medium-font"}
                                        >
                                            Choose workspace
                                        </Text>
                                        <Text
                                            type={"body-text-3"}
                                            cssClasses={"gray-color mt-8 mb-8"}
                                        >
                                            Choose the workspace you want to add
                                            the credits to
                                        </Text>

                                        <DropdownComponent
                                            id={"Workspace"}
                                            label="Workspace"
                                            options={workspaces.map(function(workspace){
                                                return {
                                                    "label": workspace.title,
                                                    "value": workspace.id
                                                }
                                            })}
                                            value={workspace?workspace:null}
                                            onChange={(item) => {
                                                if(item.value!=workspace) {
                                                    updateReferralWorkspaceRequest(item.value)
                                                }
                                            }}
                                            isClearable={false}
                                            
                                        />
                                    </div>

                                    <div className="profile-info-personal-link">
                                        <Text
                                            type={"body-text-1"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"mb-20"}
                                        >
                                            Invite your friends
                                        </Text>

                                        <div className="d-flex align-items-center invite-input-hold mb-32">
                                            <Input
                                                label="Email address"
                                                onChange={(e) => {
                                                    //console.log(e.target.value)
                                                    //if(e.target.value=='' || !e.target.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                                                    if (e.target.value == "") {
                                                        // setFormValue({email:e.target.value,error: 'Please enter a valid email', "error_class": "input_error"});
                                                        setFormValue({
                                                            email: e.target
                                                                .value,
                                                            error: "",
                                                            error_class: "",
                                                        });
                                                    } else {
                                                        setFormValue({
                                                            ...formValue,
                                                            email: e.target
                                                                .value,
                                                            error: "",
                                                            error_class: "",
                                                        });
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (
                                                        e.target.value == "" ||
                                                        !e.target.value.match(
                                                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                                                        )
                                                    ) {
                                                        
                                                        // setFormValue({email:e.target.value,error: 'Please enter a valid email', "error_class": "input_error"});
                                                        setFormValue({
                                                            email: e.target
                                                                .value,
                                                            error: "Please enter a valid email",
                                                            error_class:
                                                                "input_error",
                                                        });
                                                    } else {
                                                        setFormValue({
                                                            ...formValue,
                                                            email: e.target
                                                                .value,
                                                            error: "",
                                                            error_class: "",
                                                        });
                                                    }
                                                }}
                                                error={formValue.error}
                                                error_class={
                                                    formValue.error_class
                                                }
                                                required
                                                value={formValue.email}
                                                clearField={false}
                                            />

                                            <Button
                                                label="Send"
                                                type={"primary"}
                                                size={"large"}
                                                state={
                                                    workspace &&
                                                    formValue.email
                                                        ? "active"
                                                        : "disabled"
                                                }
                                                microLoading={btnLoading}
                                                onClick={() => {
                                                    setFormValue({
                                                        ...formValue,
                                                        error: "",
                                                        error_class: "",
                                                    });
                                                    if (
                                                        formValue.email == "" ||
                                                        !formValue.email.match(
                                                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                                                        )
                                                        
                                                    ) {
                                                        setFormValue({
                                                            ...formValue,
                                                            error: "Please enter a valid email",
                                                            error_class:
                                                                "input_error",
                                                        });
                                                    } else {

                                                        if(!workspace){
                                                            showToast("No workspace selected. Please select workspace first.","error");
                                                        } else{
                                                            sendInvitationRequest();
                                                        }
                                                        //window.location = `mailto:?subject=${emailValues.subject}&body=${emailValues.body}&to=${formValue.email}`
                                                    }
                                                }}
                                            />
                                        </div>
                                        {workspaceLoading &&
                                        <div className="share-pr-link-to-refer share-link-loader">
                                            <Text
                                                type={"body-text-1"}
                                                fontWeight={"medium-font"}
                                                cssClasses={"mb-16 gray-color"}
                                            >
                                                Or share your personal link
                                            </Text>

                                            <div className="d-flex align-items-center ">
                                                <div className="personal-link-box">
                                                    <Skeleton
                                                        height={55}
                                                        style={{
                                                            borderRadius:
                                                                "10px",
                                                        }}
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        "share-social-links text-left d-flex align-items-center refer-social-link"
                                                    }
                                                >
                                                    <button>
                                                        <Skeleton
                                                            width={35}
                                                            height={35}
                                                            style={{
                                                                borderRadius:
                                                                    "100px",
                                                            }}
                                                        />
                                                    </button>

                                                    <button>
                                                        <Skeleton
                                                            width={35}
                                                            height={35}
                                                            style={{
                                                                borderRadius:
                                                                    "100px",
                                                            }}
                                                        />
                                                    </button>

                                                    <button>
                                                        <Skeleton
                                                            width={35}
                                                            height={35}
                                                            style={{
                                                                borderRadius:
                                                                    "100px",
                                                            }}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {workspace && !workspaceLoading &&
                                        <div className="share-pr-link-to-refer">
                                            <Text
                                                type={"body-text-1"}
                                                fontWeight={"medium-font"}
                                            >
                                                Or share your personal link
                                            </Text>

                                            <div className="d-flex align-items-center ">
                                                <div className="personal-link-box">
                                                    <ShareLink
                                                        link={
                                                            process.env
                                                                .REACT_APP_URL +
                                                            "researcher/sign-up?refID=" +
                                                            user.referral_code
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        "share-social-links text-left d-flex align-items-center refer-social-link"
                                                    }
                                                >
                                                    <FacebookShareButton
                                                        url={
                                                            process.env
                                                                .REACT_APP_URL +
                                                            "researcher/sign-up?refID=" +
                                                            user.referral_code
                                                        }
                                                        quote={
                                                            "Hi, click on my personal link to register to UserQ.com. UserQ is MENA's first pay-as-you-go remote user testing platform."
                                                        }
                                                        className="facebook__some-network__share-button"
                                                    >
                                                        <FacebookIcon
                                                            size={32}
                                                            round
                                                        />
                                                    </FacebookShareButton>

                                                    <TwitterShareButton
                                                        url={
                                                            process.env
                                                                .REACT_APP_URL +
                                                            "researcher/sign-up?refID=" +
                                                            user.referral_code
                                                        }
                                                        title={
                                                            "Hi, click on my personal link to register to UserQ.com. UserQ is MENA's first pay-as-you-go remote user testing platform."
                                                        }
                                                        className="twitter__some-network__share-button"
                                                    >
                                                        <Icon
                                                            value={"twitter"}
                                                            size={"medium"}
                                                        />
                                                    </TwitterShareButton>

                                                    <WhatsappShareButton
                                                        url={
                                                            process.env
                                                                .REACT_APP_URL +
                                                            "researcher/sign-up?refID=" +
                                                            user.referral_code
                                                        }
                                                        title={
                                                            "Hi, click on my personal link to register to UserQ.com. UserQ is MENA's first pay-as-you-go remote user testing platform."
                                                        }
                                                        //separator=":: "
                                                        className="whatsapp__some-network__share-button"
                                                    >
                                                        <WhatsappIcon
                                                            size={32}
                                                            round
                                                        />
                                                    </WhatsappShareButton>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <a
                onClick={(e) => {
                    e.preventDefault();

                    setShowSupportPanel(true);
                }}
                href={process.env.REACT_APP_URL + "r/support"}
                target="_blank"
                className="support-button"
            >
                <Icon
                    value={"support"}
                    colorClass={"gray-50-svg"}
                    size={"medium"}
                />
                Support
            </a>
        </LayoutResearcher>
    );
}
