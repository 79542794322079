import { ReactSession } from "../../../../lib/secure_reactsession";
import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";

export default function ConfirmYourClickModal(props) {
    ReactSession.setStoreType("localStorage");


    const [formLoading, setFormLoading] = useState(false);

    const closeModal = () => {
        props.closeModal(false);
    };

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        props.deny()
                        // closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={props.openModal}
            centered
            className="fade custom-modal-wrap done-with-task-modal confirm-click-modal"
        >
            <Modal.Body className={props.test.language == "ar" ?"arabic-done-with-task-modal":""} ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <h2 className={`heading h2`}>{props.test.language == "ar" ? "تأكيد مكان النقرة" : "Confirm your click"}</h2>
                        {/* <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <img
                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                    alt="cross"

                                />
                            </span>
                        </button> */}
                    </div>
                    <div className="done-with-task-modal-body">
                        <Text type="body-text-2" fontWeight="medium-font" >{props.test.language == "ar" ? "هل تريد تأكيد مكان النقرة؟" : "Do you want to confirm your click?"} </Text>
                        <div className="done-with-task-modal buttons confirm-click-modal-buttons">
                            {props.test.language == "ar" ?
                                <Button
                                    type={"primary"}
                                    size="large"
                                    onClick={() => {
                                        props.confirm();
                                    }}

                                    label="نعم، متأكد"
                                    iconRight={<Icon colorClass={"gray-50-svg"} value="success" size="medium" />}
                                />
                                :
                                <Button
                                    type={"primary"}
                                    size="large"
                                    onClick={() => {
                                        props.confirm();
                                    }}

                                    label="Yes, confirm"
                                    iconLeft={<Icon colorClass={"gray-50-svg"} value="success" size="medium" />}
                                />
                            }
                            <div className="exit-button" onClick={() => { props.deny() }} style={{ display: "inline-flex"}}
   
>
                                <div className="cursor-pointer">
                                {props.test.language == "ar" ?
                                    <>
                                        <span>لا، لقد قمت بالنقر عن غير قصد </span>
                                        <Icon  value="close x" size="medium" />
                                    </>
                                    :
                                    <>
                                        <Icon  value="close x" size="medium" />
                                        <span>No, It was by accident </span>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
