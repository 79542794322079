import React, { useState, useMemo, useRef, useEffect } from "react";
import AreaClosed from "@visx/shape/lib/shapes/AreaClosed";
import { LinePath } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { scaleLinear, coerceNumber } from "@visx/scale";
import { Axis, Orientation } from "@visx/axis";
import { GridRows, GridColumns } from "@visx/grid";
import {
	AnimatedAxis,
	AnimatedGridRows,
	AnimatedGridColumns,
} from "@visx/react-spring";
import {Tooltip} from "../../../themes/userq/Tooltip/Tooltip";

export const backgroundColor = "rgb(233, 215, 237)";
const axisColor = "rgba(204, 204, 204, 1)";
const tickLabelColor = "rgb(0, 0, 0)";
export const labelColor = "#340098";
const gridColor = "rgba(191, 240, 168, 1)";
const strokeColor="rgba(204, 204, 204, 1)";
const margin = {
	top: 0,
	right: 10,
	bottom: 0,
	left: 5,
};

const tickLabelProps = () => ({
	fill: tickLabelColor,
	fontSize: 12,
	fontFamily: "sans-serif",
	textAnchor: "middle",
});

const getMinMax = (vals) => {
	const numericVals = vals.map(coerceNumber);
	return [Math.min(...numericVals), Math.max(...numericVals)];
};

export default function LinearAxisChart({
	id,
	width: outerWidth = 100,
	height: outerHeight = 80,
	percentage,
	expandData
}) {
	const ref = useRef();

    const tipref = useRef();

    

	// use non-animated components if prefers-reduced-motion is set
	const prefersReducedMotionQuery =
		typeof window === "undefined"
			? false
			: window.matchMedia("(prefers-reduced-motion: reduce)");
	const prefersReducedMotion =
		!prefersReducedMotionQuery || !!prefersReducedMotionQuery.matches;
	const [useAnimatedComponents, setUseAnimatedComponents] = useState(
		!prefersReducedMotion
	);

	const data = [
		{ x: 0, y: 0 },
		{ x: percentage, y: 0 },
	];

	const data_line = [
		{ x: percentage, y: 0 },
		{ x: percentage, y: 100 },
	];

	// in svg, margin is subtracted from total width/height
	const [width, setWidth] = useState(outerWidth - margin.left - margin.right);
	const height = outerHeight - margin.top - margin.bottom;
	const [animationTrajectory, setAnimationTrajectory] = useState("center");

	const AxisComponent = useAnimatedComponents ? AnimatedAxis : Axis;
	const GridRowsComponent = useAnimatedComponents ? AnimatedGridRows : GridRows;
	const GridColumnsComponent = useAnimatedComponents
		? AnimatedGridColumns
		: GridColumns;

	const handlePointerMove = (event) => {
		let tipPosition = "bottom";
				
        var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 190;


        var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight+10 : 85;
        

        let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
        let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;
        
        var window_width = window.innerWidth;

        var window_height = window.innerHeight;
    
        if(event.clientX + tool_width/2 + 10> window_width){
            containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) -10;
        
        }
        if(event.clientX - tool_width/2 < 0){
            containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
        }

		showTooltip({
			tooltipLeft: containerX,
			tooltipTop: containerY,
			tooltipData: percentage+"%",
			open: true,
            tipPostion: tipPosition
			
		});
	};
    useEffect( ()=>{
		if(ref && ref.current){
        setWidth(ref.current.getBoundingClientRect().width);

		window.addEventListener('resize', function(){
			if(ref && ref.current){
			setWidth(ref.current.getBoundingClientRect().width);
			}
		});
	}

    },[percentage,expandData]);



	const axes = useMemo(() => {
		// toggle between two value ranges to demo animation
		const linearValues = [0, 20, 40, 60, 80, 100];

        

		return [
			{
				scale: scaleLinear({
					domain: getMinMax(linearValues),
					range: [0, width-margin.left-margin.right],
				}),
				values: linearValues,
				tickFormat: (v) => `${v}`,
				label: "linear",
			},
		];
	}, [width]);

	const scalePadding = 40;
	const scaleHeight = height / axes.length - scalePadding;

	const yScale = scaleLinear({
		domain: [100, 0],
		range: [scaleHeight, 0],
	});
	const [tooltip, showTooltip] = useState({
		tooltipLeft: 0,
		tooltipTop: 0,
		tooltipData: "",
		open: false,
	});
	return (
		<div id={id} ref={ref} style={{ position: "relative", width:"100%" }} onPointerMove={percentage<=10?handlePointerMove:()=>{}} onPointerLeave={() => {
            showTooltip({ ...tooltip, open: false });
        }}
		onMouseOut={() => {
            showTooltip({ ...tooltip, open: false });
        }}
		>
			{tooltip.open ? (
				<>
					<Tooltip
						ref={tipref}
						type={tooltip.tipPosition}
						key={Math.random()} // needed for bounds to update correctly
						style={{ zIndex:2, display:"block", whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
						data={tooltip.tooltipData}
						className="tooltipChart"
					
					/>
				</>
            ): (
                <></>
            )}
			<svg width={width} height={outerHeight}>
				<g transform={`translate(${margin.left},${margin.top})`}>
					{axes.map(({ scale, values, label, tickFormat }, i) => (
						<g
							key={`scale-${i}`}
							transform={`translate(0, ${i * (scaleHeight + scalePadding)})`}
							
						>
							<AreaClosed
								data={data.map((x) => [scale(x.x), yScale(x.y)])}
								yScale={yScale}
								curve={curveMonotoneX}
								fill={gridColor}
								onPointerMove={handlePointerMove}
								onClick={(event)=>{
									var ele = handlePointerMove(event);
										ele();
								}}
								onPointerLeave={() => {
									showTooltip({ ...tooltip, open: false });
								}}
								onMouseOut={() => {
									showTooltip({ ...tooltip, open: false });
								}}
							/>
                            <GridRowsComponent
								// force remount when this changes to see the animation difference
								key={`gridrows-${animationTrajectory}`}
								scale={yScale}
								stroke={strokeColor}
								width={width-margin.left-margin.right}
								numTicks={1}
								animationTrajectory={animationTrajectory}
							/>
							<GridColumnsComponent
								// force remount when this changes to see the animation difference
								key={`gridcolumns-${animationTrajectory}`}
								scale={scale}
								stroke={strokeColor}
								height={scaleHeight}
								numTicks={6}
								animationTrajectory={animationTrajectory}
							/>
							
							<AxisComponent
								// force remount when this changes to see the animation difference
								key={`axis-${animationTrajectory}`}
								orientation={Orientation.bottom}
								top={scaleHeight}
								scale={scale}
								tickFormat={tickFormat}
								stroke={axisColor}
								tickStroke={axisColor}
								tickLabelProps={tickLabelProps}
								tickValues={values}
								numTicks={undefined}
								labelProps={{
									x: width,
									y: 0,
									fill: labelColor,
									fontSize: 18,
									strokeWidth: 0,
									stroke: "#fff",
									paintOrder: "stroke",
									fontFamily: "sans-serif",
									textAnchor: "start",
								}}
								animationTrajectory={animationTrajectory}
							/>
							<LinePath
								data={data_line}
								x={(d) => scale(d.x)}
								y={(d) => yScale(d.y)}
								stroke="rgba(55, 139, 16, 1)"
								strokeWidth={1}
								shapeRendering="geometricPrecision"
								//onPointerMove={handlePointerMove}
								/*onPointerLeave={() => {
									showTooltip({ ...tooltip, open: false });
								}}*/
							/>
						</g>
					))}
				</g>
			</svg>
		</div>
	);
}
