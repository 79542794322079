import { useState } from "react";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { MultiSelecteDropdownList } from "./MultiSelecteDropdownList";

export const  RatingMultiSelect = ({scale, type, onChange, value}) =>{
    
    
    let options = [];

    for (let i = 0; i < scale;i++) {
        options.push({
            value: i+1,
            label: type == "emoticons" ? <RatingScaleIcon scale={scale} type={type} value={i+1} /> : (type == "stars" ? <RatingScaleIcon scale={scale} type={"stars"} value={i+1} /> : i+1)
        });
    }
    
    

    return <MultiSelecteDropdownList value={value} onChange={onChange} label={"Filter by rating"} options={options} type={type} />;
}