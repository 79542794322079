import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Logo } from "../Logo/Logo";
import { getCreditSummaryService } from "../../../../services/credits";
import { logoutService } from "../../../../services/user";
import {
    atom,
    useRecoilState,
    useResetRecoilState
} from 'recoil';
import Skeleton from "react-loading-skeleton";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Dropdown } from "react-bootstrap";
import {roundNumber} from "../../../../lib/helpers";
import {Text} from "../Text/Text";
import { LoadingIcon } from "../../../loader/loadingIcon";


export const GuestHeader = ({from_researcher,isSharedLink,resultHeaderClass}) => {

    const navigate = useNavigate();

    
    const [menuClass, setMenuClass] = useState('');

    
    useEffect(() => {
        
    }, []);


    return (
        <>
        {(process.env.REACT_APP_URL.includes("localhost")|| process.env.REACT_APP_URL.includes("apprevampui") || process.env.REACT_APP_URL.includes("appuat")) &&
            <div className="test-environment-header">This is a test environment</div>
        }
        <div className={`header researcher-header tester-header ${resultHeaderClass?resultHeaderClass:""}`}>
            <div className="container">
                <div className="header-inner-data body-text body-text body-text-2 medium-font none-for-mobile">
                    <div className="header-lhs-side">
                        <div className="logo">
                            <Link to={"/"} >
                                <Logo theme={"white"} background={"black"} />
                            </Link>
                        </div>

                        {isSharedLink &&
                            <div className={'guest-result-viewer-text'}>
                                <Text type={'body-text-2'} cssClasses={'white-text'}
                                      fontWeight={'medium-font'}>Viewer</Text>
                            </div>
                        }

                    </div>
                </div>
                <div className="header-for-mobile none-for-desktop">
                    <div className="mobile-header-top">
                        <div className="logo">
                            <Link to={"/"} >
                                <Logo theme={"white"} background={"black"} />
                            </Link>
                        </div>

                        {isSharedLink &&
                            <div className={'guest-result-viewer-text'}>
                                <Text type={'body-text-2'} cssClasses={'white-text'}
                                      fontWeight={'medium-font'}>Viewer</Text>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};