import { useEffect, useRef, useState} from "react";
import { Text } from "../../../themes/userq/Text/Text";
import PieChart from "./pie_chart_v2";
import { theme } from "../../../themes/userq/theme";
import { Box } from "../../../themes/userq/Box/Box";
import PieChartSingleChoice from './pie_chart_singlechoice_v2';
import exportAsImage from "../../../../lib/exportAsImage";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { roundAnlyticsNumber } from "../../../../lib/helpers";

export const PieStatsBoxSingleChoice = ({title, data,sortedchartData, test, totalAnswers}) =>{


    var total_legends = 0;

    sortedchartData.forEach((item)=>{
        if(item[1] > 0){
            total_legends++;
        }
    });
    console.log(sortedchartData);

    const exportPngRef = useRef();

    const colors = [
        "#FF6DB1",
        "#9759CB",
        "#F2C690",
        "#698CE7",
        "#31F0E5",
        "#378B10",
        "#E32F83",
        "#793EAB",
        "#D7A56A",
        "#3668E7",
        "#11BFB4",
        "#164103",
        "#FF8AC1",
        "#AC7AD5",
        "#FFE2C0",
        "#9EB4ED",
        "#90F2EC",
        "#BFF0A8",
        "#A72561",
        "#5C208D",
        "#C38D4D",
        "#0D3BB0",
        "#0A877F",
        "#A72561",
        "#5C208D",
        "#C38D4D",
        "#06236C",
        "#045A54",
    ];
    const [total, setTotal] = useState(0);
    useEffect(()=>{

        var total = 0;

        for (var key in data) {

            total += data[key];
        }
        setTotal(total);

    },[data]);


    return <div className={`chart-hold-wrap position-relative single-choice-chart ${total_legends > 3 ? "two-row-legends-box-wrap" : ""}`}
    >
        <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Single choice")}>
            <Icon value={'download'} size={'small'} hover={'true'}></Icon>
            <div className="tooltip-wrapper top-tooltip">
                <p className="body-text body-text-2 normal-font ">Download</p>
            </div>
        </div>
        <div className="donwload-chard-ref" ref={exportPngRef}><Box size={"medium"} cssClasses={total_legends>3?"two-row-legends-box single-choice-pie-chart":"single-choice-pie-chart"}>
            <div  className="pie-chart-box">
                <div  className="pie-chart-area single-choice-pie-chart">
                    <PieChartSingleChoice colors={colors} data={data} width={240} height={240} animate={false} test={test} totalAnswers={totalAnswers}/>
                </div>
                <div className={`chart-info-wrap ${total_legends>3?"two-row-legends":""}`}>
                    {sortedchartData.map((element, index) => (
                        (element[1] > 0) && (
                            <div key={"legend"+index} className="chart-info-repeat">
                                <span style={{ display: "inline-block", backgroundColor: `${colors[index]}` }}></span><p className={`${test.language=="ar"?"arabic-font":""}`}><Text type="body-text-2" fontWeight="medium-font" cssClasses={test.language=="ar"?"arabic-font":""}>{element[0]}                
                                <span style={{  "color": colors[index]}}>({total>0?roundAnlyticsNumber((element[1]/total)*100,2):''}%)</span>
                                </Text>
                                </p>
                            </div>
                        )
                    ))}
                </div>
            </div>

        </Box></div></div>;
}