import { Document, Page } from "react-pdf";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import { useNavigate, useParams } from "react-router-dom";
import { getInvoiceDataService } from "../../../services/invoices.js";
import toast from "react-hot-toast";

export default function ViewInvoice() {
  let { invoice_id } = useParams();

  const [pdfFile, setPdfFile] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(true);

  const getInvoice = () => {
    let token = ReactSession.get("token");

    setInvoiceLoading(true);

    var data = new FormData();
    data.append("invoice_id", invoice_id);

    getInvoiceDataService(data, token).then((response) => {
      setInvoiceLoading(false);

      if (response.success) {
        
      }
    });
  };
  const saveFile = () => {
    saveAs(pdfFile, "example.pdf");
  };

  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <>
      <Document file={pdfFile}>
        <Page pageNumber={1} />
      </Document>
      {/* <button onClick={saveFile}>download</button> */}
    </>
  );
}
