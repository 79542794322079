import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Text } from "../../themes/userq/Text/Text";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Button } from "../../themes/userq/Button/Button";
import "./AccountInUseModal.css";
export default function AccountInUseModal({
    open,
    title,
    subtitle,
    message,
    btn,
    close,
}) {
    const closeModal = () => {
        close();
    };

    const { workspaceid } = useParams();

    const navigate = useNavigate();
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap account-used-modal-wrap"
            onHide={() => {
                closeModal();
            }}
        >
            <Modal.Body className="">
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type="h2">This account is currently in use</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon
                                    value="Close"
                                    size="medium"
                                    hover={true}
                                />
                            </span>
                        </button>
                    </div>

                    <div className="used-accound-mid-data">
                        <Text
                            type={"body-text-2"}
                            cssClasses={"gray-text"}
                            fontWeight={"medium-font"}
                        >
                            This account is currently in use on another browser
                            or device. To sign in here, please access the active
                            account and log out manually.
                        </Text>

                        <div className="switch-mamber-team d-flex align-items-center position-static w-100 mt-32 mb-32">
                            <Text type={"subtitle-2"}>
                                Would you like to collaborate with team members
                                in the same workspace?
                            </Text>

                            <Button
                                type="primary"
                                size={`medium`}
                                cssclass={"white-bg-btn"}
                                iconLeft={
                                    <Icon value={"group"} size={"medium"} />
                                }
                                onClick={() => {
                                    navigate(
                                        "/wsp/" +
                                            workspaceid +
                                            "/upgrade-workspace"
                                    );
                                }}
                                label="Switch to Team plan"
                            />
                        </div>

                        <div className="account-inuse-modal-btn text-center">
                            <Button
                                type="primary"
                                size={`large`}
                                label={"Done"}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
