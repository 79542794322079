export const saregions = [
    {value: "Al-Bahah State (Al-Bahah)", label: "Al-Bahah State (Al-Bahah)"},
    {value: "Al Jawf State (Sakakah)", label: "Al Jawf State (Sakakah)"},
    {value: "Al-Qassim State (Buraidah)", label: "Al-Qassim State (Buraidah)"},
    {value: "'Asir State (Abha)", label: "'Asir State (Abha)"},
    {value: "Eastern Province (Dammam)", label: "Eastern Province (Dammam)"},
    {value: "Ha'il Region (Ha'il)", label: "Ha'il Region (Ha'il)"},
    {value: "Jizan State (Jizan)", label: "Jizan State (Jizan)"},
    {value: "Madinah State (Medina)", label: "Madinah State (Medina)"},
    {value: "Makkah State (Mecca)", label: "Makkah State (Mecca)"},
    {value: "Najran State (Najran)", label: "Najran State (Najran)"},
    {value: "Northern Borders Region ('Ar'ar)", label: "Northern Borders Region ('Ar'ar)"},
    {value: "Riyadh State (Riyadh)", label: "Riyadh State (Riyadh)"},
    {value: "Tabuk State (Tabuk)", label: "Tabuk State (Tabuk)"},
];