import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import Layout from "../layouts/layout";
import LayoutResearcher from "../layouts/layout_researcher";
import Header from "../layouts/elements/header";
import { GuestHeader } from "../themes/userq/Header/GuestHeader";
import { Footer } from "../themes/userq/Footer/Footer";
import { TestHeader } from "../themes/userq/TestHeader/TestHeader";
import { Logo } from "../themes/userq/Logo/Logo";
import { Text } from "../themes/userq/Text/Text";

export default function UnderMaintenace({errorType}) {
    const [counter,setCounter] = useState(10);
    const navigate = useNavigate();


    useEffect(() => {
        clearCacheData();

    }, []);
    useEffect(() => {
        document.title = "UserQ - Under maintenance";

        
    }, []);
    const clearCacheData = () => {
        var date = new Date;
    
        fetch("/meta.json?"+date.getTime())
        .then((response) => response.json())
        .then((meta) => {
            if(meta.maintenance_mode!=1){
                navigate("/");
            } 
        });
    
    };
    const errorHtml = () =>{
        return (
            <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap maintenance-wrap">
                <div className="container">
                    <div className="row align-items-center">

                    <div className={`col-md-6 text-right mb-20 mainten-img`}>
                            <img src={process.env.REACT_APP_URL+"img/maintenance.png"} />
                            
                        </div>

                        <div className="col-md-6">
                            <Text type={"h1"} cssClasses="mb-20"  fontWeight={"medium-font"}>
                            We will be back soon!
                            </Text>
                            <Text type={"subtitle-2"} fontWeight={"medium-font"}>Sorry for the inconvenience but we are making UserQ even better for you! You can always <a className="link-text" href="https://userq.com/contact-us">contact us</a>, otherwise we’ll be back online shortly.</Text>

                        </div>

                    </div>
                </div>

            </div>
        );
    }
    
    return (
        <>
            

            

                <div className="login-page-wrapper 404-err0r-wrap under_maintenance_wrap">
                    <div className="error-inner-404">
                        <div className="login-page-header">
                            <div className="researcher-registerheader login-page-header">
                                <div className="container">
                                    <div className='row align-items-center'>
                                        <div className="col-xs-6 col-sm-6 login-hdr-left">
                                            <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                                                
                                                <Logo theme={"black"} background={"white"} />
                                                
                                            </a>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        { errorHtml()}
                        <Footer />
                    </div>
                </div>
            
            
            </>
       );
}
