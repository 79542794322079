import React, { useState, useEffect, useRef } from "react";
import "./wizard.css";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";

const Wizard = ({ wizardOptions, setWizardOptions, dismissWizard }) => {
    const totalSlides = 4;

    const wrapperRef = useRef(null);

    const nextSlide = () => {
        if (wizardOptions.currentStep < totalSlides - 1) {
            setWizardOptions({
                ...wizardOptions,
                currentStep: wizardOptions.currentStep + 1,
            });
        }
    };

    const previousSlide = () => {
        if (wizardOptions.currentStep > 0) {
            setWizardOptions({
                ...wizardOptions,
                currentStep: wizardOptions.currentStep - 1,
            });
        }
    };

    const skipAll = () => {
        dismissWizard();
    };

    const progressBarWidth =
        ((wizardOptions.currentStep + 1) / totalSlides) * 100;

    // Define your slide content here
    const slides = [
        <div className="wizard-items-repeat">
            <div className="wizard-image-area mb-20">
                <img
                    src={process.env.REACT_APP_URL + "img/workspace-menu.png"}
                    alt="img"
                />
            </div>
            <div className="wizard-content-area">
                <Text type={"subtitle-2"} fontWeight={"semi-bold-font"}>
                    Manage your workspace and create new ones
                </Text>
                <Text
                    type={"body-text-3"}
                    fontWeight={"medium-font"}
                    cssClasses={"gray-color mt-12"}
                >
                    Click on the menu above to select between different
                    workspaces, manage your team, or access the workspace
                    invoice history and billing information.
                </Text>
            </div>
        </div>,
        <div className="wizard-items-repeat">
            <div className="wizard-image-area mb-20">
                <img
                    src={process.env.REACT_APP_URL + "img/setup-team.png"}
                    alt="img"
                />
            </div>

            <Text type={"subtitle-2"} fontWeight={"semibold-font"}>
                Invite your team
            </Text>
            <Text
                type={"body-text-3"}
                fontWeight={"medium-font"}
                cssClasses={"gray-color mt-12"}
            >
                With a Team Plan subscription you have now the possibility to
                invite your colleagues to work together.
            </Text>
        </div>,
        <div className="wizard-items-repeat">
            <div className="wizard-image-area mb-20">
                <img
                    src={process.env.REACT_APP_URL + "img/compare-plan.png"}
                    alt="img"
                />
            </div>

            <Text type={"subtitle-2"} fontWeight={"semibold-font"}>
                Add workspaces
            </Text>
            <Text
                type={"body-text-3"}
                fontWeight={"medium-font"}
                cssClasses={"gray-color mt-12"}
            >
                Create as many workspaces as you need and choose between
                Pay-As-You-Go and Team plans.
            </Text>
        </div>,
        <div className="wizard-items-repeat">
            <div className="wizard-image-area mb-20">
                <img
                    src={process.env.REACT_APP_URL + "img/account-menu.png"}
                    alt="img"
                />
            </div>

            <Text type={"subtitle-2"} fontWeight={"semibold-font"}>
                Your account details, settings, and payment methods – all
                conveniently located here.
            </Text>
            <Text
                type={"body-text-3"}
                fontWeight={"medium-font"}
                cssClasses={"gray-color mt-12"}
            >
                Even while navigating through different workspaces, your
                personal information and settings will always remain easily
                accessible.
            </Text>
        </div>,
    ];

    if (!wizardOptions.open) {
        // return null;
    }

    const disableScroll = () => {
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = function () {
            window.scrollTo(scrollLeft, scrollTop);
        };
    };

    const enableScroll = () => {
        window.onscroll = function () {};
    };

    //   useEffect(() => {
    //     if (wizardOptions.open) {
    //       disableScroll();
    //     } else {
    //       enableScroll();
    //     }

    //     const handleWindowWheel = (event) => {
    //       if (wizardOptions.open){
    //         event.preventDefault();
    //       }
    //     };

    //     window.addEventListener('wheel', handleWindowWheel, { passive: false });

    //     return () => {
    //       window.removeEventListener('wheel', handleWindowWheel);
    //     };
    //   }, [wizardOptions.open]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        // closeModal();
                        // console.log("click outside");
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <>
            {wizardOptions.open && (
                <div
                    className={`wizard-wrapper wizard-slide-${
                        wizardOptions.currentStep + 1
                    }`}
                >
                    <div className="page-data-wrapper wizard-data-holder">
                        <div className="wizard-container" ref={wrapperRef}>
                            <div className="wizaed-heading-top modal-header-top d-flex align-items-center justify-content-between mb-32 none-for-desktop">
                                <Text type={"h4"}>New feature</Text>

                                <button
                                    type="button"
                                    className="close"
                                    onClick={skipAll}
                                >
                                    <span aria-hidden="true">
                                        <Icon
                                            value="Close"
                                            size="medium"
                                            hover={true}
                                        />
                                    </span>
                                </button>
                            </div>

                            {slides[wizardOptions.currentStep]}

                            <div className="wizard-button-wrap d-flex align-items-center justify-content-between mt-20 mb-20">
                                <Button
                                    label={"Previous"}
                                    size={"medium"}
                                    iconLeft={
                                        <Icon
                                            value={"back arrow"}
                                            size={"small"}
                                        />
                                    }
                                    type={"secondary"}
                                    onClick={previousSlide}
                                    state={
                                        wizardOptions.currentStep === 0
                                            ? "disabled"
                                            : ""
                                    }
                                />

                                <Button
                                    label={
                                        wizardOptions.currentStep ===
                                        totalSlides - 1
                                            ? "Done"
                                            : "Next"
                                    }
                                    size={"medium"}
                                    iconRight={
                                        wizardOptions.currentStep !==
                                            totalSlides - 1 && (
                                            <Icon
                                                value={"Forward arrow"}
                                                colorClass={"gray-50-svg"}
                                                size={"small"}
                                            />
                                        )
                                    }
                                    type={"primary"}
                                    onClick={
                                        wizardOptions.currentStep ===
                                        totalSlides - 1
                                            ? skipAll
                                            : nextSlide
                                    }
                                />
                            </div>

                            <div
                                className="progress-bar-container mb-20"
                                style={{ background: "#E9E9E9" }}
                            >
                                <div
                                    className="progress-bar-inner"
                                    style={{
                                        background: "#FF6DB1",
                                        width: `${progressBarWidth}%`,
                                    }}
                                ></div>
                            </div>
                            <div className="slides-count d-flex align-items-center justify-content-between">
                                <span className="slide-count-text">
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                    >
                                        {wizardOptions.currentStep + 1}/
                                        <span className="gray-color">
                                            {totalSlides} steps
                                        </span>
                                    </Text>
                                </span>
                                <span
                                    className="skipp-all cursor-pointer"
                                    onClick={skipAll}
                                >
                                    <Text type={"caption"}> Skip all</Text>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Wizard;