import React from "react";
import {
    AreaChart,
    Area,
    YAxis,
    XAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];


export default function RechartArea({areaClosedChartData, testmethodology}) {

    /*const  data = [
       {
           name: "Jan 01",
           "Testers": 25
       },
       {
           name: "Feb 08",
           "Testers": 10
       },
       {
           name: "Mar 08",
           "Testers": 50
       },
       {
           name: "Apr 15",
           "Testers": 30
       },
       {
           name: "May 16",
           "Testers": 60
       }
   ];*/

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="tooltipChart">
                    {/*{console.log(payload)}*/}
                    {`${payload[0].value}`} {testmethodology=="Survey"?payload[0].value<2?<>participant</>:<>participants</>:payload[0].value<2?<>participant</>:<>participants</>}
                </div>
            );
        }

        return null;
    };
    return (
        <>
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={450}
                height={200}
                data={areaClosedChartData}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#F2C690" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#F2C690" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#F2C690" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#F2C690" stopOpacity={0}/>
                    </linearGradient>
                </defs>

                {/*<CartesianGrid strokeDasharray="3 3" />*/}
                <XAxis dataKey="name" tick={{fontSize:'12px'}}/>
                <YAxis tick={false} axisLine={false} />

                {/*<CartesianGrid strokeDasharray="0 0" />*/}
                <Tooltip
                    contentStyle={{ backgroundColor: "#000", color: "#fff" }}
                    itemStyle={{ color: "#fff" }}
                    cursor={true}
                    content={<CustomTooltip />}
                />
                <Area type="monotone" dataKey="Testers" stroke="#F2C690" fill="url(#colorUv)" fillOpacity={1} />
            </AreaChart>
        </ResponsiveContainer>

        {/*<AreaChart*/}
        {/*    width={450}*/}
        {/*    height={200}*/}
        {/*    data={areaClosedChartData}*/}
        {/*    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>*/}
        {/*    <defs>*/}
        {/*        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">*/}
        {/*            <stop offset="5%" stopColor="#F2C690" stopOpacity={0.8}/>*/}
        {/*            <stop offset="95%" stopColor="#F2C690" stopOpacity={0}/>*/}
        {/*        </linearGradient>*/}
        {/*        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">*/}
        {/*            <stop offset="5%" stopColor="#F2C690" stopOpacity={0.8}/>*/}
        {/*            <stop offset="95%" stopColor="#F2C690" stopOpacity={0}/>*/}
        {/*        </linearGradient>*/}
        {/*    </defs>*/}
        {/*    <XAxis dataKey="name" tick={{fontSize:'12px'}} />*/}
            {/*<YAxis tick={false} axisLine={false}/>*/}

            {/*<Tooltip*/}
            {/*    contentStyle={{ backgroundColor: "#000", color: "#fff" }}*/}
            {/*    itemStyle={{ color: "#fff" }}*/}
            {/*    cursor={true}*/}
            {/*    content={'dasdsad asdsa'}*/}
            {/*/>*/}

            {/*<CartesianGrid strokeDasharray="0 0" />*/}
            {/*<Tooltip*/}
            {/*    contentStyle={{ backgroundColor: "#000", color: "#fff" }}*/}
            {/*    itemStyle={{ color: "#fff" }}*/}
            {/*    cursor={true}*/}
            {/*    content={<CustomTooltip />}*/}
            {/*/>*/}

            {/*<Legend />*/}
            {/*<Area*/}
            {/*    type="monotone"*/}
            {/*    dataKey="Testers"*/}
            {/*    stroke="#F2C690"*/}
            {/*    fillOpacity={1}*/}
            {/*    fill="url(#colorUv)"/>*/}
            {/*<Area
                    type="monotone"
                    dataKey="Procuct B"
                    stroke="black"
                    fillOpacity={1}
                    fill="url(#colorPv)"/>*/}
        {/*</AreaChart>*/}

        </>
    );

}