import { shuffle } from "../../../../lib/helpers";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
//import { Preview } from "../../../themes/userq/Preview/preview";
import { PreviewNav } from "../../../themes/userq/Preview/preview_nav";

export default function Task({ test, preference_test_setting, preference_test_designs, skipTask, next, answer, nextTask, setAnswer, formLoading, setSelectedDesign }) {

    const [designs, setDesigns] = useState([]);

    const [sequence, setSequence] = useState([]);

    const [previewModal, setPreviewModal] = useState({open:false, activeIndex:0, items:[]});

    const [help, setHelp] = useState(false);

    const [fullView, setFullView] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {

        var designs_local = [];

        if (preference_test_setting.randomize_order) {
            designs_local = shuffle(preference_test_designs);
        } else {
            designs_local = preference_test_designs;
        }
        setDesigns(designs_local);

        var sequence_local = [];

        designs_local.forEach(function (item) {
            sequence_local.push(item.id);
        })

        setSequence(sequence_local);

    }, [preference_test_designs]);

    const renderDesigns = () => {
        var designs_render = [];

        var designs_loop = [];


        if (designs.length > 0) {
            if (preference_test_setting.show_fullscreen == 1) {
                designs_loop.push(designs[activeIndex]);
            } else {
                designs_loop = Object.assign([], designs);
            }
        }

        designs_loop.forEach(function (item, loopindex) {

            designs_render.push(
                <div key={"item" + item.id} className="preference-test-designs-repeat">
                    <div className="added-design-preview-thum"
                    >


                        <div className="prefer-design-image-view-hold" onClick={() => {
                                    /*if (preference_test_setting.show_fullscreen != 1) {
                                        setActiveIndex(loopindex);
                                    }

                                    setFullView(true)*/

                                    //setPreviewModal({...previewModal, open:true, activeIndex:loopindex, img: process.env.REACT_APP_IMG_URL + item.design_file, file_type:item.type,background:item.color});
                                    
                                    setPreviewModal({...previewModal, open:true, answers:answer, activeIndex: preference_test_setting.show_fullscreen != 1 ? loopindex : activeIndex});

                                }}>
                            <div className="test-design-name-and-option-wrap" style={{ position: "relative" }} >
                                <Text type={"body-text-1"} fontWeight={"medium-font"} cssClasses={`h3 prefer-design-name ${(test.language == "ar") ? "" : ""}`}>

                                    {preference_test_setting.show_design_titles == 1 &&
                                        <>{item.title}
                                        </>}
                                    &nbsp;
                                </Text>
                                <div className="design-max-screen-icon" >
                                    <Icon colorClass="gray-900-svg" value="maximize" size={"small"} hover={true} />
                                </div>


                            </div>
                            <div className="prefer-test-selected-design-view">
                                <div className="prefer-test-thum-data-hold">

                                    <div className="added-design-img-thm" style={{ "backgroundColor": item.color }}>
                                        {item.type == "video" &&
                                            <div className="video-design-icon">
                                                <img src={process.env.REACT_APP_URL + "images/video-play-btn.svg"} alt="icon" />
                                            </div>
                                        }
                                        {preference_test_setting.show_fullscreen != 1 &&
                                            <img src={process.env.REACT_APP_IMG_URL + item.thumb} />
                                        }
                                        {preference_test_setting.show_fullscreen == 1 &&
                                            <>
                                                {item.type == "image" &&
                                                    <img src={process.env.REACT_APP_IMG_URL + item.design_file} />
                                                }
                                                {item.type == "video" &&
                                                    <video width="320" height="240" controls>
                                                        <source src={process.env.REACT_APP_IMG_URL + item.design_file} type="video/mp4" />
                                                    </video>
                                                }
                                            </>
                                        }


                                    </div>
                                </div>
                            </div>

                            <div className="test-design-name-and-option-wrap">
                                {'answer_id' in answer && item.id == answer.answer_id ?

                                    <Button
                                        type={"secondary"}
                                        size={"small"}
                                        cssclass={`${'answer_id' in answer && item.id == answer.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setAnswer(item.id, sequence);
                                            setFullView(false);
                                            setSelectedDesign(item);
                                        }}
                                        label={test.language == "en" ?
                                            "You've voted for this option"
                                            : "حدد هذا الخيار"}
                                        iconRight={<Icon colorClass={"gray-50-svg"} value={'check mark'} />}
                                    />

                                    :
                                    <Button
                                        type={"secondary"}
                                        size={"small"}
                                        className={`${'answer_id' in answer && item.id == answer.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setAnswer(item.id, sequence);
                                            setFullView(false);
                                            setSelectedDesign(item);
                                            document.getElementById('next-screen-btns').scrollIntoView({
                                                behavior: "smooth",
                                                block: "center",
                                            });
                                        }}
                                        label={test.language == "en" ?
                                            "I'd vote for this option"
                                            : "حدد هذا الخيار"}
                                        iconRight={<Icon size={'small'} value={'like'} />}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>

            );
        });
        if (preference_test_setting.show_fullscreen == 1) {
            return (
                <div className="prefer-design-slider-hold single-design-wrap">
                    <div className="pref-test-btns-wrap">
                        <div className={`btn prevPage ${activeIndex == 0 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex != 0) { setActiveIndex(activeIndex - 1); } }}>
                            <img src={process.env.REACT_APP_URL + "img/backward-arrow-white.svg"} alt="icon" />
                        </div>

                        <div className={`btn nextPage ${activeIndex == designs.length - 1 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex < designs.length - 1) { setActiveIndex(activeIndex + 1); } }}>
                            <img src={process.env.REACT_APP_URL + "img/forward-arrow-white.svg"} alt="icon" />
                        </div>

                    </div>
                    {designs_render}
                </div>
            )
        } else {
            return (
                <div
                    className="preference-designs-inner-hold"
                    style={{ "width": "100%" }}
                >
                    {designs_render}
                </div>
            );
        }
    };
    const renderFullView = () => {
        var designs_render = [];

        var designs_loop = [];

        if (designs.length > 0) {
            designs_loop.push(designs[activeIndex]);
        }
        designs_loop.forEach(function (item, i) {
            designs_render.push(
                <div className="preference-test-design-wrap preference-taking-task-wrap"

                >


                    <div className="prefer-design-image-view-hold">
                        
                        <div className="prefer-test-selected-design-view"
                            style={{ backgroundColor: item.color ? item.color : "" }}
                        >


                            <>
                                {item.type == "image" &&
                                    <img src={process.env.REACT_APP_IMG_URL + item.design_file} />
                                }
                                {item.type == "video" &&
                                    <video width="320" height="240" controls>
                                        <source src={process.env.REACT_APP_IMG_URL + item.design_file} type="video/mp4" />
                                    </video>
                                }
                            </>


                            <div className="design-max-screen-icon" onClick={() => { 
                                    /*setActiveIndex(i); setFullView(true)*/
                                    //setPreviewModal({...previewModal, open:true, img: process.env.REACT_APP_IMG_URL + item.design_file, file_type:item.type,background:item.color}); 
                                    
                                    setPreviewModal({...previewModal, open:true,answers:answer, activeIndex:activeIndex});
                                }}>
                                
                                
                                <Icon colorClass="gray-900-svg" value="minimize" size={"small"} hover={true} />
                            </div>
                        </div>
                        <div className="test-design-name-and-option-wrap">
                            <h3 className={`h3 prefer-design-name ${(test.language == "ar") ? "" : "arabic_wrapper"}`}>
                                {/* {preference_test_setting.show_fullscreen != 1 &&
                                    <span>
                                        {i.toString().length == 1 ? "0" + (i + 1) : (i + 1)}
                                    </span>
                                }
                                {preference_test_setting.show_fullscreen == 1 &&
                                    <span>
                                        {activeIndex.toString().length == 1 ? "0" + (activeIndex + 1) : (activeIndex + 1)}
                                    </span>
                                }*/}
                                {/*item.title*/}
                                {preference_test_setting.show_design_titles == 1 &&
                                    <>{item.title}
                                    </>}
                                &nbsp;
                            </h3>
                            {'answer_id' in answer && item.id == answer.answer_id ?

                                <Button
                                    type={"secondary"}
                                    size={"small"}
                                    cssclass={`${'answer_id' in answer && item.id == answer.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();;
                                        setAnswer(item.id, sequence);
                                        setFullView(false);
                                        
                                    }}
                                    label={test.language == "en" ?
                                        "You've voted for this option"
                                        : "حدد هذا الخيار"}
                                    iconRight={<Icon colorClass="gray-50-svg" value={'check mark'} />}
                                />

                                :
                                <Button
                                    type={"secondary"}
                                    size={"small"}
                                    className={`${'answer_id' in answer && item.id == answer.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();;
                                        setAnswer(item.id, sequence);
                                        setFullView(false);
                                        
                                    }}
                                    label={test.language == "en" ?
                                        "I'd vote for this option"
                                        : "حدد هذا الخيار"}
                                    iconRight={<Icon size={'small'} value={'like'} />}
                                />
                            }
                            {/*<button className={`secondary-btn secondary-small  btn-with-icon  btn-with-icon-right ${'answer_id' in answer && item.id == answer.answer_id ? 'selected-btn' : 'select-this-option'}`}
                                onClick={() => {
                                    setAnswer(item.id, sequence)
                                    setFullView(false)
                                }}
                            >
                                {test.language == "en" ?
                                    "Select this option"
                                    : "حدد هذا الخيار"}
                            </button>*/}
                        </div>
                    </div>
                </div>

            );
        });

        return (
            <div className="design-fixed-full-view">
                <div className="prefer-design-slider-hold">
                    <div onClick={() => { setFullView(false) }} className="close-design-modal"><img src={process.env.REACT_APP_URL + "images/close-menu.svg"} alt="icon" /></div>
                    <div className="pref-test-btns-wrap">

                        <div className={`btn prevPage ${activeIndex == 0 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex != 0) { setActiveIndex(activeIndex - 1); } }}>
                            <img src={process.env.REACT_APP_URL + "img/backward-arrow-white.svg"} alt="icon" />
                        </div>
                        <div className={`btn nextPage ${activeIndex == designs.length - 1 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex < designs.length - 1) { setActiveIndex(activeIndex + 1); } }}>
                            <img src={process.env.REACT_APP_URL + "img/forward-arrow-white.svg"} alt="icon" />
                        </div>
                    </div>
                    {designs_render}
                </div>
            </div>
        );

    };
    return (
        <div className="tester-page-wrapper tester-question-selection-wrap test-selected-main-cat-wrap prefer-test-tacking-wrapper prefer-taking-test-data-hold">
            <div className="container inner-page-container">

                <div className="tester-screens-hold">
                    <div className="tester-form-steps-wrapper">

                        <div className="tester-form-steps-inner">
                            <div className="taking-test-page-height">
                                <div className="preference-taking-test-designs-wrapper">
                                    <div className="prefer-taking-test-head">
                                        <Text type={"h2"} fontWeight={'semi-bold-font'} cssClasses={`${test.language == "ar" ? "arabic-font" : ""}`}>{preference_test_setting.preference_test_title}</Text>
                                        <Text type={"body-text-1"} fontWeight={'medium-font'} cssClasses={`${test.language == "ar" ? "arabic-font" : ""}`}>{test.language == "ar" ?"تأكد من مراجعة جميع الخيارات قبل اتخاذ القرار":"Make sure to review all options before making a choice"}</Text>
                                    </div>
                                    {/*<div className="cards-info-help-wrap">

                                        <div  onMouseOut={() => { setHelp(false); }}  style={{ display: help ? 'flex' : 'none' }} className="profile-complete-progress-wrap card-sorting-info">
                                            <div className="pro-lft-wrap">
                                                {test.language=="en"?
                                                <>All you have to do is choose the design you like best by clicking the ‘<b className="font-weight-600">SELECT THIS OPTION</b>’.</>
                                                :<>كل ما عليك فعله هو اختيار التصميم الذي اعجبك بالضغط على <b>حدد هذا الخيار</b></>}
                                            </div>
                                            <div className="pro-right-wrap">

                                                <div className="delete-progress-row" onClick={() => { setHelp(false) }}>
                                                    <img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img" />
                                                </div>
                                            </div>
                                        </div>

                                        <span className="open-card-info-btn" onClick={() => { setHelp(true); }} onMouseOver={() => { setHelp(true) }}>
                                            <img src={process.env.REACT_APP_URL + "images/help.svg"} alt="icon" />

                                        </span>
                                    </div>*/}

                                    <div className="prefer-taking-test-designs-hold mt-40">

                                        {!fullView &&

                                            <><div className="prefer-taking-test-multiple-designs"> {renderDesigns()}</div></>
                                        }

                                        {fullView &&
                                            <><div className="prefer-taking-test-full-design"> {renderFullView()}</div></>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div id="next-screen-btns" className="button-wrap d-flex justify-content-center mb-40 prefer-text-btn">
                                {/*<button type="button" onClick={() => { skipTask() }} className="button secondary-btn">
                                            Skip Task
                                        </button>*/}

                                <Button
                                    type="primary"
                                    size={"large"}
                                    onClick={() => { nextTask() }}
                                    label={test.language == "en" ? "Next" : "التالي"}
                                    microLoading={formLoading}
                                    state={`${"answer_id" in answer ? 'active' : 'disabled'}`}
                                    iconRight={<Icon colorClass={"gray-50-svg"} value={test.language=="ar"?'back-arrow':'forward-arrow'} />}
                                />

                            </div>

                        </div>
                        {/*<div className="button-wrap d-flex justify-content-end mt-64">
                                    <button type="button" onClick={() => { skipTask() }} className="button secondary-btn">
                                        Skip Task
                                    </button>
                                    
                                    <button type="button" onClick={() => { nextTask() }} className={`button primary-btn ${"answer_id" in answer ?'':'disabled-button'} `}>
                                        Next
                                        {formLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ "marginLeft": "5px" }}></i>
                                        }
                                    </button>

                                </div>*/}
                    </div>
                </div>
                
                {designs.length > 0 &&
                <PreviewNav rtl={test && test.language=="ar"?true:false} open={previewModal.open} withMinimizeIcon={true}  background={previewModal.background} loading={false} file_type={previewModal.file_type} img={previewModal.img} close={()=>{
                    setPreviewModal({...previewModal,open:false});
                }}
                    items={designs}
                    index={previewModal.activeIndex}
                    answers={previewModal.answers}
                    isEnableVote={true}
                    setAnswerData={(taskId)=>{

                        
                            setAnswer(taskId, sequence);
                            setFullView(false);

                            designs.forEach((item)=>{
                                if(item.id === taskId) {
                                    setSelectedDesign(item);
                                }
                            })
                            setPreviewModal({...previewModal,open:false}); // close popup
                        }
                    }
                />
                }
            </div>
        </div>
    );
}