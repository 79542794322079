import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import { CheckBox } from "../CheckBox/CheckBox";
import { Icon } from "../Icon/Icon";


export default function ConfirmationModal({
    openModal,
    confirm_message,
    confirm_title,
    confirm_btn_title,
    cancel_btn_title,
    close,
    confirm,
    btnLoading,
    confirm_title_class,
    btn_reverse,
    cancel_btn_hide,
    custom_confirm_message,
    cssClass,
    after_btn_message,
    confirm_btn_disabled,
    confirm_btn_loading,
    dont_ask_again,
    askconfirm
}) {
    const closeModal = () => {
        close();
    };

    const navigate = useNavigate();
    const wrapperRef = useRef(null);

    const [dontAskAgain, setDontAskAgain] = useState(false);


    useEffect(()=>{
        setDontAskAgain(false);
    },[openModal])


    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                // console.log(event.target);
                // console.log(event.target.nodeName);
                // console.log("xyz");
                // console.log(ref.current);
                if (ref.current && !ref.current.contains(event.target)) {
                    // console.log("abc");
                    if(event.target.nodeName =="DIV"){
                        // console.log("abcd");
                        //closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className={`fade custom-modal-wrap  confirmation-modal-wrap ${confirm_title=="Change email"?"change-email-modal":''}  ${cssClass}`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <h3 className={`heading h3 ${confirm_title_class?confirm_title_class:''}`}>{confirm_title}</h3>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                            </button>
                        </div>
                        {custom_confirm_message &&
                        <>{confirm_message}</>
                        }
                        {!custom_confirm_message &&
                        <p className="body-text-2 medium-font gray-text">{confirm_message}</p>
                        }
                        {dont_ask_again &&
                        <div className="formbox mt-20">
                        <CheckBox name="dont_ask_again" label="Don’t show this message again" checked={dontAskAgain?true:false} onChange={()=>{setDontAskAgain(!dontAskAgain)} }  />
                        </div>
                        }
                        <div className="confirm-buttons-wrap">

                            {btn_reverse ?
                                <>
                                    <Button cssclass={"toast-close-icon"} type="secondary" size="large" label={confirm_btn_title ? confirm_btn_title : "OK"} onClick={() => { confirm(); }} microLoading={btnLoading}/>
                                    <Button cssclass={"toast-close-icon"} type="primary" size="large" label={cancel_btn_title ? cancel_btn_title : 'Cancel'} onClick={(e) => { e.preventDefault(); e.stopPropagation(); closeModal(); }} /> 

                                </>
                                :
                                <>
                                    {!cancel_btn_hide &&
                                    <Button cssclass={"toast-close-icon"} type="secondary" size="large" label={cancel_btn_title ? cancel_btn_title : 'Cancel'} onClick={(e) => { e.preventDefault(); e.stopPropagation(); closeModal(); }} />
                                    }
                                    <Button cssclass={"toast-close-icon"} type="primary" microLoading={confirm_btn_loading?confirm_btn_loading:false} size="large" state={confirm_btn_disabled?confirm_btn_disabled:"active"} label={confirm_btn_title ? confirm_btn_title : "OK"} onClick={() => { if(dont_ask_again){ askconfirm(dontAskAgain) } else { confirm(); }  }} />
                                </>
                            }
                        </div>
                        {after_btn_message}
                    </div>
                 }
            </Modal.Body>
        </Modal>
    );
}
