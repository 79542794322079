import React, {useEffect, useRef} from "react";
import { Link } from 'react-router-dom';
import { ReactSession } from "../../../lib/secure_reactsession";

export default function BillingNavigation(props) {
    const user = ReactSession.get("user");

    const containerRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            scrollToActiveElement();
        }, 1000); // Adjust the delay as needed
    }, []);

    const scrollToActiveElement = () => {
        var itemRef = document.getElementById('selected-index-item-' + props.activeMenu)
        if (containerRef.current && itemRef) {

            const container = containerRef.current;
            const containerWidth = container.clientWidth;
            const scrollWidth = container.scrollWidth;
            const itemWidth = itemRef.clientWidth;


            // Calculate the scroll position to center the clicked item
            const itemOffset = itemRef.offsetLeft;
            const scrollPosition = Math.max(itemOffset - (containerWidth - itemWidth) / 2, 0);


            // Set the scroll position with smooth behavior
            /*container.scrollTo({
              left: scrollPosition,
              behavior: 'smooth',
            });*/

            if(document.body.clientWidth < 767){

                const containers = document.getElementById('selected-index-item-' + props.activeMenu);
                containers.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });

                containers.scrollLeft += scrollPosition;
            }

        }
    };

    return (
        <div  className="profile-heading-section border-top-0 inner-page-menu accountnaviagtion-outer" ref={containerRef}>
            <span id={`selected-index-item-payment`} className={`${props.activeMenu=='payment' ? "active" :''}`}>
                <Link to={"/r/payment-methods"}>
                    Payment methods

                </Link>
            </span>
            <span id={`selected-index-item-billing`} className={`${props.activeMenu=='billing' ? "active" :''}`}>
                <Link to={"/r/billing-information"}>
                    Billing information
                </Link>
            </span>
            <span id={`selected-index-item-invoice`}  className={`${props.activeMenu=='invoice' ? "active" :''}`}>
                <Link to={"/r/invoice-history"}>
                    Invoice history
                </Link>
            </span>
        </div>
    )
}
