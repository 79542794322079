import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import toast from "react-hot-toast";
import InputField from "../../../layouts/elements/fields/input";
import { getFileType, matchExtensions, maxLengthCheck } from "../../../../lib/helpers";
import { addDesignService } from "../../../../services/test";

export default function EditPreferenceTestDesignModal({
	open,
	close,
	confirm,
    test,
    design
}) {
	
	const fileRef = useRef();

	const [fileType,setFileType] = useState(design ? design.type:"image");

	const [designFile,setDesignFile] = useState(null);

	const [previewUrl,setPreviewUrl] = useState(null);
	
	let user = ReactSession.get("user");
	const [formValues, setFormValues] = useState({
		title: design ? design.title:"",
		file_type: design ? design.type:"image",
        design_id: design ? design.id : "",
		color: design ? design.color : "",
	});
    const [formErrors, setFormErrors] = useState({
		title: null,
		design_file: null,
		color:null,
        "error_class":"input_error"
	});
    const [formLoading, setFormLoading] = useState(false);

	const showError = (error) => {
		toast(
			<div className="toastinner">
				{error}
				<a
					onClick={() => {
						toast.dismiss();
					}}
				>
					&times;
				</a>
			</div>,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: 3000,
			}
		);
	};
	const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
	const handleFile = (file) => {
		
		resetFormErrors();
		//let's grab the image file
		var error = false;
		if(file.size/1024 > 2048){

			setFormErrors({...formErrors,design_file:"Please select file <= 2MB.","error_class":"input_error"});

			error = true;
		}
		var fileType = getFileType(file);

		if(fileType!="image" && fileType!="video"){
			

			setFormErrors({...formErrors,design_file:"Only video and image files are accepted.","error_class":"input_error"});
			error = true;
		}
		if(!matchExtensions(["mp4","jpg","jpeg","gif","png","MP4","JPG","JPEG","GIF","PNG"],file)){
			

			setFormErrors({...formErrors,design_file:"Only video and image files are accepted with extensions mp4, jpg, jpeg, gif and png.","error_class":"input_error"});
			error = true;
		}

		if(!error){

			if(fileType=="image"){
				setFileType("image");

				setFormValues({...formValues,file_type:"image"});
			}
			if(fileType=="video"){
				setFileType("video");

				setFormValues({...formValues,file_type:"video"});
			}
			setDesignFile(file);

			setPreviewUrl(URL.createObjectURL(file));
		}
		
	  };
	
	  const handleOnDragOver = (event) => {
		event.preventDefault();
	  };
	  const handleOnDrop = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();
		//let's grab the image file
		let imageFile = event.dataTransfer.files[0];
	
		handleFile(imageFile);
	  };
	  const removeImage = (e) => {
		setDesignFile(null);
		setPreviewUrl(null);
		fileRef.current.value= null;
	  };
    const handleFileInput = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();

		handleFile(event.target.files[0]);
	};
    const submitForm = ()=>{

		var error = false;

		var form_errors = resetFormErrors();

		
		if(!formValues.title){

			error = true;

			form_errors = {
                ...form_errors,
                title:"Please enter title.",
				"error_class":"input_error"
            };
		}
		if(error){
			setFormErrors(form_errors);
		}
		if(!error){
			let token = ReactSession.get("token");

			const formData = new FormData;
			formData.append("test_id",test.id);

            formData.append("design_id",formValues.design_id);

			formData.append("design_file",designFile);

			formData.append("file_type",formValues.file_type);

			formData.append("title",formValues.title);

			formData.append("color",formValues.color);

			setFormLoading(true);

			addDesignService(
				formData,
				token
			).then((response) => {
				
				setFormLoading(false);

				if (response.success) {

					resetFormErrors();
							setDesignFile(null);

							setPreviewUrl(null);
							
							setFormValues({title:"",file_type:""})
					confirm(response.design);

					
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			});
		}
    }

	useEffect(() => {
		if(open){
			resetFormErrors();
							setDesignFile(null);
							setPreviewUrl(null);
							setFormValues({title:"",file_type:""})
		}
		if(design){
            setFormValues({...formValues,
                file_type:design.type,
                design_id:design.id,
                title:design.title, color:design.color});
            
                setPreviewUrl(process.env.REACT_APP_IMG_URL+"/"+design.design_file);
                
				setFileType(design.type);
        }
		
        
	}, [design, test, open]);

	return (
		<Modal
			show={open}
			centered
			className="fade custom-modal-wrap edit-design-modal"
			onHide={() => {
				close();
			}}
		>
			<Modal.Body className="p-0">
				<div className="modal-inner-text p-64">
					<button
						type="button"
						className="close"
						onClick={() => {
							
							close();
						}}
						aria-label="Close"
					>
						<span aria-hidden="true">
							<img
								src={process.env.REACT_APP_URL + "images/cross.svg"}
								alt="cross"
							/>
						</span>
					</button>
					<div className="modal-data-wrap publish-study-modal-data">
						<h2>Edit your design</h2>
						<p className="modal-full-width-p w-100">
							Upload your design option or drag and drop into the box below.<br/><br/>
							Video files must be under 4MB and images under 2MB.
						</p>
						<div className="preference-test-design-wrap w-100 p-0 add-desing-option-modal">
							<div className="prefer-upload-opton-wrap">
								{!previewUrl &&
								<div 
									class="upload-project-img position-relative"
									onDragOver={handleOnDragOver}
                    				onDrop={handleOnDrop}
								>
									<img  src={process.env.REACT_APP_URL+"images/upload-img.svg"} alt="img" />
									<p>
										<b>Upload
										<input type={"file"} 
											ref={fileRef}
											accept="image/*, video/*"
											onChange={handleFileInput} /></b>&nbsp;an image or video here</p>
                                            {formErrors.design_file != null && (
                                            <span className={formErrors.error_class}>
                                                {formErrors.design_file}
                                            </span>
                                            )}
								</div>
								}
								{previewUrl && (
								<div className="project-modal-img design-uploaded-img project-setting-image-wrap w-100 design-edit-image-option" onDragOver={handleOnDragOver}
								onDrop={handleOnDrop}>
									<div className="uploaded-project-img edit-design-img-wrap">
										<div className="prefer-upload-opton-wrap">

											{fileType=="image" &&
											<img  src={previewUrl} alt="img" width="100%"  />
											}
											{fileType=="video" &&
											<video src={previewUrl} width="100%" controls="1">
											</video>
											}
										</div>
										
										{/*<div class="change-img-option">
											<div onClick={()=>{fileRef.current.click();}}>
												<img src={process.env.REACT_APP_URL+"images/upload-img.svg"} alt="img" />
												<p>Change image or video</p>
											</div>
										</div>*/}
									</div>
									{/*<div className="upload-project-img edit-design-browse-wrap">
										<p>Upload an image, video or 
											<b>&nbsp; Browse
											<input type="file"
												ref={fileRef}
												accept="image/*, video/*"
												onChange={handleFileInput}
											/>
											</b>
										</p>
									</div>*/}
									<img
										className="remove-img"
										onClick={removeImage}
										src={process.env.REACT_APP_URL + "images/cross.svg"}
										alt="img"
									/>
								</div>
								)}
								
							
								<div className="prefer-test-title-wrap mt-64">
									
									<InputField 
										
										rtl={(test && test.language=="ar") ?true:false}
										label={"Name your design"} value={formValues.title}
										onChange={(e)=>{ setFormValues({...formValues, title:e.target.value}) }}
										maxLength={45}
										onInput={(e)=>{maxLengthCheck(e);
											if(e.target.value.length >0){
												setFormErrors({...formErrors, title:null});
											}
										}}
										error={formErrors.title}
										error_class={formErrors.error_class}
									/>
								</div>
								<div className="prefer-test-title-wrap mt-64 type-color-wrap">
									<InputField 
										type={"color"}
										rtl={(test && test.language=="ar") ?true:false}
										label={"Background color"} value={formValues.color}
										onChange={(e)=>{ setFormValues({...formValues, color:e.target.value}) }}
										error={formErrors.color}
										error_class={formErrors.error_class}
									/>
								</div>
							</div>
						</div>
						<div className="button-wrap d-flex flex-nowrap btn-50-wrap justify-content-between mt-32">
							<button
								className="button  secondary-btn"
								onClick={() => {
									
									close();
								}}
								type="button"
							>
								Cancel
							</button>
							<button
								className={`button  primary-btn `}
								onClick={() => {
									submitForm();
								}}
								type="button"
							>
                                
								{" "}
								Update
                                {formLoading && (
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        aria-hidden="true"
                                        style={{ marginLeft: "5px" }}
                                    ></i>
                                    )}
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
