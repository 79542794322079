import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { Icon } from  "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";


export default function PreviewPrototype({open, prototype_link, device, rtl, version, close}){

    const iframeRef = useRef(null);

    const closeModal = () => {
        close();
    };
    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                // console.log(event.target);
                // console.log(event.target.nodeName);
                // console.log("xyz");
                // console.log(ref.current);
                if (ref.current && !ref.current.contains(event.target)) {
                    // console.log("abc");
                    if(event.target.nodeName =="DIV"){
                        // console.log("abcd");
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }


    useOutsideAlerter(wrapperRef);

    const maximizeIframe = ()=>{
        if (iframeRef.current) {
            if (iframeRef.current.requestFullscreen) {
                iframeRef.current.requestFullscreen();
            } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
                iframeRef.current.mozRequestFullScreen();
            } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari, Opera
                iframeRef.current.webkitRequestFullscreen();
            } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
                iframeRef.current.msRequestFullscreen();
            }
        }
    }
    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap ${device && device=="mobile" ? "mobile-iframe-wrapper":""}  ${rtl?"arabic-wrapper":""}  confirmation-modal-wrap goal-screen-modal-wrap `}
            onHide={() => {
                closeModal();
            }}

        >
            <Modal.Body className=""  ref={wrapperRef}>

                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={'h3'}>Preview Prototype</Text>
                       
                        <button
                            type="button"
                            className="close"
                            onClick={() => {

                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
                        </button>
                    </div>
                    <div className="iframewrap-max-height">
                        <iframe
                            ref={iframeRef}
                            id="figma_prototype_iframe"
                            height="450"
                            width="800"
                            src={prototype_link}
                            allowfullscreen

                        />
                        
                        <div  onClick={(e)=>{maximizeIframe()}} className="figma-prototype-controls figma-prototype-control-maximize">
                            <Icon value={"maximize"} size={"medium"} />
                        </div>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
}