import React, {useState} from "react";
import PropTypes from 'prop-types';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Icon} from "../Icon/Icon";

export const ShareLink = ({ link,isDisabled }) => {
    const [linkCopied, setLinkCopied] = useState(false);
    return (
        <div className={`share-link-story copy-share-test-wrap ${isDisabled?'disabled-share-link':''}`}>
            <span className="copy-link">
                {link}
            </span>
            {!linkCopied &&
            <CopyToClipboard text={link}
                             onCopy={() => {
                                 if(!isDisabled){
                                     setLinkCopied(true);
                                     setTimeout(function () {
                                         setLinkCopied(false);
                                     },3000)
                                 }
                             }}>
                            <span  className="copy-link-icon cursor-pointer">
                                <Icon value={"link"} size={"medium"} />
                            </span>
            </CopyToClipboard>
            }
            {linkCopied &&
            <span className="copy-link-icon">
                <Icon value="check-mark" colorClass={'success-200-svg check-mark'} size={"medium"} />
            </span>
            }
        </div>
    );
};

ShareLink.propTypes = {
    link: PropTypes.string

};
ShareLink.defaultProps = {
    link: "",

};