import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import {Helmet} from "react-helmet";
import {renderTestTitle} from "../../../lib/helpers";
import {LayoutResearcher} from "../../themes/userq/Layouts/layout_researcher";


export default function ResultNotAvailable({isSharedLink}) {

    const navigate = useNavigate();

    const [show_support_panel, setShowSupportPanel] = useState(false);

    useEffect(() => {

    }, []);


    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >


            <Helmet>
                <title>Results not available  | UserQ</title>
            </Helmet>
            <div className="result-not-available">
                <div className="result-not-available-data-wrap">
                    <div className="not-found-page-text text-center">
                        <div className={'info-icon'}>
                            <Icon size={'extralarge'} value={"error-icon-grey"} />
                        </div>
                        <Text type={"subtitle-2"} fontWeight="medium-font">
                            Results are no longer available
                        </Text>

                        <Text type={"body-text-2"} cssClasses={'gray-color'} fontWeight="medium-font">
                            It looks like this link is no longer active. Please get in touch with the owner of the test to re-activate the link.
                        </Text>
                    </div>
                </div>
            </div>
        </LayoutResearcher>
    );
}