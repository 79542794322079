import React from "react";
import {Icon} from "../../../themes/userq/Icon/Icon";
import {Text} from "../../../themes/userq/Text/Text";
import {Button} from "../../../themes/userq/Button/Button";

export default function PrototypeTestIntroduction({goToFirstTask,testlanguage,test}){
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap card-sorting-introduction">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="selected-test-info-img">
                               
                                    <img src={process.env.REACT_APP_URL+"img/prototype-test.svg"}/>
                                
                            </div>
                        </div>


                        <div className={`selected-page-left-side ${testlanguage=="ar"?"arabic_wrapper":""}`}>
                            <Text type={"h1"}>
                                {testlanguage=="en"?"Prototype test":"اختبار النموذج المبدئي"}
                            </Text>
                            {test && test.prototype_test_setting && test.prototype_test_setting.objective == "closed"?
                            <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                {testlanguage=="en"?
                                    "You’ll be shown an interactive prototype, and be given a set of tasks. Please read the instructions carefully, and navigate through the screens to complete the given tasks. You may need to CLICK, SWIPE, or SCROLL through the pages, just as you would on a regular website or app."
                                    :"سيظهر لك نموذج أولي تفاعلي وسيكون هناك مجموعة من المهام. يرجى قراءة التعليمات بعناية، والتنقل عبر الشاشات لإكمال المهام المطلوبة. قد يحتاج ذلك إلى النقر أو السحب أو التمرير عبر الصفحات، تمامًا كما تفعل على موقع ويب أو تطبيق عادي."}
                            </Text>
                            :
                            <>
                            <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                {testlanguage=="en"?
                                    "Before taking any action, you’ll be asked to read some instructions. Then, you will be asked to explore an interactive prototype (an app or a website) in order to answer some specific questions related to it. You may need to CLICK, SWIPE, or SCROLL through the pages, just as you would on a regular website or app."
                                    :<>قبك اتخاذ أي إجراء، سيطلب منك قراءة بعض التعليمات.<br/>
                                    لاحقاً، سيطلب منك التجول في نموذج تفاعلي (لتطبيق أو موقع الكتروني) للإجابة على بعض الأسئلة المتعلقة به. قد يحتاج ذلك إلى النقر أو السحب أو التمرير عبر الصفحات، تماماً كما تفعل على الموقع الالكتروني او التطبيق العادي.                                    </>}
                            </Text>
                                <Text type={"body-text-1"} fontWeight={'medium-font'}>
                                {testlanguage=="en"?
                                    "Take all the time you need before providing an answer."
                                    :"خذ وقتك قبل تقديم الإجابة."}
                            </Text>
                            </>
                            }

                            <div className={'remember-text'}>
                                <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                    {testlanguage=="en"?"Remember, there’s no right or wrong answer here - just observe as you would do naturally."
                                        :"تذكر أنه لا توجد إجابة صحيحة أو خاطئة هنا - فقط لاحظ كما تفعل بشكل طبيعي."}
                                </Text>
                            </div>

                            <div className="button-wrapper none-for-mobile">
                                <Button
                                    size={'large'}
                                    iconRight={<Icon colorClass={"gray-50-svg"} value={testlanguage=="ar"?'back-arrow':'forward-arrow'}/>}
                                    label={testlanguage == "en" ? "Let’s start" : "لنبدأ"}
                                    onClick={() => {
                                        goToFirstTask();
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                </div>

                <div className="button-wrapper none-for-desktop taking-test-button">
                    <Button
                        size={'large'}
                        iconRight={<Icon colorClass={"gray-50-svg"} value={testlanguage=="ar"?'back-arrow':'forward-arrow'}/>}
                        label={testlanguage=="en"?"Let’s start":"لنبدأ"}
                        onClick={() => {
                            goToFirstTask();
                        }}
                    />
                </div>
            </div>
        </div>
    );
}