import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './QuestionsSkeleton.css';

const QuestionsSkeleton = ({ questionType }) => {
  const renderSkeletonByType = () => {
    switch (questionType) {
      case 'Free':
        return (
            <div className='free-questiontype-skeleton'>
            
            <div className='d-flex questionbox-top  added-question-readtmode-top-wrap'>
                
                <div className='d-flex question-topleftbox'>
                    <div className='questiondrag'>
                        <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                    </div> 
                    <div className='question-middlebox'>
                         <Skeleton width={200} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                </div>

                <div className='d-flex question-topbuttonbox'>
                    <div className='question-readmode-icon  '>
                        <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                    <div className='question-readmode-icon  '>
                        <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                    <div className='question-readmode-icon  '>
                        <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                </div>

            </div>

            <div className='questiondescription-s d-flex question-added-view-wrap '>
                <div className='added-question-left d-flex'>
                    <Skeleton width={200} height={24} style={{ borderRadius: '5px' }} />
                </div>
            </div>
            
        </div> 
        );
      case 'SingleChoice':
        return (
            <div className='single-questiontype-skeleton'>
                <div className='d-flex questionbox-top  added-question-readtmode-top-wrap'>
                    <div className='d-flex question-topleftbox'>
                        <div className='questiondrag'>
                            <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                        </div> 
                        <div className='question-middlebox'>
                            <Skeleton width={200} height={24} style={{ borderRadius: '5px' }} />
                        </div>
                    </div>

                    <div className='d-flex question-topbuttonbox'>
                        <div className='question-readmode-icon  '>
                            <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                        </div>
                        <div className='question-readmode-icon  '>
                            <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                        </div>
                        <div className='question-readmode-icon  '>
                            <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                        </div>
                    </div>

                </div>

                <div className='questiondescription-s d-flex question-added-view-wrap '>
                    <div className='added-question-left d-flex'>
                        <Skeleton width={200} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                </div>
                <div className='question-optionbox singlechoice-boxes '>
                    <div className='questionreadmode-left'>
                        <label className='radio-btn-wrap pl-0 mr-1 '>
                            <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                        </label>
                        <div className='option-value question-option-value-with-image '>
                            <Skeleton width={176} height={24} style={{ borderRadius: '5px' }} />
                        </div>
                        
                    </div>
                </div>
                <div className='question-optionbox singlechoice-boxes '>
                    <div className='questionreadmode-left'>
                        <label className='radio-btn-wrap pl-0 mr-1 '>
                            <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                        </label>
                        <div className='option-value question-option-value-with-image '>
                            <Skeleton width={176} height={24} style={{ borderRadius: '5px' }} />
                        </div>
                        
                    </div>
                </div>
            
             </div>
        );
      case 'MultipleChoice':
        return (
          // Skeleton layout for MultipleChoice type questions
          <div className='multiple-choice-question-skeleton'>
            {/* Customize the skeleton elements for MultipleChoice type */}
            {/* ... */}
          </div>
        );
      default:
        return (
            <div className='single-questiontype-skeleton'>
            <div className='d-flex questionbox-top  added-question-readtmode-top-wrap'>
                <div className='d-flex question-topleftbox'>
                    <div className='questiondrag'>
                        <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                    </div> 
                    <div className='question-middlebox'>
                        <Skeleton width={200} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                </div>

                <div className='d-flex question-topbuttonbox'>
                    <div className='question-readmode-icon  '>
                        <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                    <div className='question-readmode-icon  '>
                        <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                    <div className='question-readmode-icon  '>
                        <Skeleton width={24} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                </div>

            </div>

            <div className='questiondescription-s d-flex question-added-view-wrap '>
                <div className='added-question-left d-flex'>
                    <Skeleton width={200} height={24} style={{ borderRadius: '5px' }} />
                </div>
            </div>
            <div className='question-optionbox singlechoice-boxes '>
                <div className='questionreadmode-left'>
                    <label className='radio-btn-wrap pl-0 mr-1 '>
                        <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                    </label>
                    <div className='option-value question-option-value-with-image '>
                        <Skeleton width={176} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                    
                </div>
            </div>
            <div className='question-optionbox singlechoice-boxes '>
                <div className='questionreadmode-left'>
                    <label className='radio-btn-wrap pl-0 mr-1 '>
                        <Skeleton width={20} height={24} style={{ borderRadius: '5px' }} />
                    </label>
                    <div className='option-value question-option-value-with-image '>
                        <Skeleton width={176} height={24} style={{ borderRadius: '5px' }} />
                    </div>
                    
                </div>
            </div>
        
         </div>
        );
    }
  };

  return <div className='lightgraybox questions-skeleton-wrap mt-32'>{renderSkeletonByType()}</div>;
};

export default QuestionsSkeleton;
