import SortableTree from "react-sortable-tree";
import { maxLengthCheck, showToast } from "../../../../lib/helpers";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { Icon } from "../Icon/Icon";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import {
	addNodeUnderParent,
	getNodeAtPath,
	removeNodeAtPath,
} from "react-sortable-tree";
import { useRef, useState } from "react";
import AutosizeInput from "react-input-autosize";
import React from "react";
import moment from "moment";

export const EditSortableTree = React.forwardRef(({test, treeSize, editable_home, setEditableHome,  formValues, setFormValues, search, updateNodeTitle, confirmDeleteModal, setConfirmDeleteModal
, confirmModal, setConfirmModal,  removeAnswer, removeNodeApi, addChild, addNode, homeRef, nodeRef, cnodeRef, makeEditableNode, editable_text, setEditableText, saveTreeData, updateHomeNode,updateNodePosition, searchFocusOffset, searchCallback },ref)=>{

    let lessthan5children;

    let update_title_timer;

    const getNodeBg = (extendedNode) => {
		if (extendedNode.path.length == 2) {
			return "rgba(150, 255, 252, 0.8)";
		} else if (extendedNode.path.length == 3) {
			return "rgba(150, 255, 252, 0.35)";
		} else if (extendedNode.path.length >= 4) {
			return "rgba(150, 255, 252, 0.2)";
		} else {
			return "rgba(150, 255, 252, 1)";
		}
	};
	const checkIfMoreThan5Children = (tree, child_count) => {
		tree.forEach(function (item) {
			var child_count_loc = child_count + 1;

			if (child_count_loc > 6) {
				lessthan5children = false;

			}
			if (item.children.length > 0) {
				checkIfMoreThan5Children(item.children, child_count_loc);
			}
		})
	}
    return <div className="row">
															
                <div
                    className="col-md-12 tree-node-repeat-wrap"
                    style={{ height: "100%" }}
                >
                    
                    <div className={`treeScrollbar ${test && test.language  == "ar"? "arabic_wrapper": ""}`} style={{height:"300px"}}>
                        <div className={`ans-list-repeat selected-card-cat-repeat ${search && formValues.home_name.toLowerCase().includes(search)?'rst__rowSearchMatch':''} ${test && test.language  == "ar"? "arabic_wrapper": ""}`}>
                            <div className={`ans-data`}>
                                {editable_home &&
                                <AutosizeInput
                                    className={`form-control ${test && test.language  == "ar"
                                            ? "arabic_wrapper"
                                            : ""
                                        }`}
                                    type="text"
                                    ref={homeRef}
                                    placeholder={formValues.home_name}
                                    value={formValues.home_name}
                                    onChange={(e) => {
                                        setEditableHome(true);

                                        setFormValues({...formValues,home_name:e.target.value})
                                    }}
                                    onBlur={(e) => {
                                        setEditableHome(false);

                                        if(e.target.value){
                                            setFormValues({...formValues,home_name:e.target.value});

                                            updateHomeNode(e.target.value);

                                        } else {
                                            setFormValues({...formValues,home_name:"Home"});

                                            updateHomeNode("Home");
                                        }
                                        
                                    }}
                                    
                                    onKeyPress={(e) => { if(e.key === 'Enter'){ 

                                        
                                        setEditableHome(false);

                                        if(e.target.value){
                                            setFormValues({...formValues,home_name:e.target.value})
                                        } else {
                                            setFormValues({...formValues,home_name:"Home"})
                                        }
                                    } 
                                }
                            }
                                    maxLength="60"
                                    onInput={maxLengthCheck} 
                                />
                                }
                                {!editable_home &&
                                <span  onClick={()=>{setEditableHome(true)}} className={`${test && test.language == "ar" ? "cairo-font": ""}`}>{formValues.home_name}</span>
                                }
                            </div>
                            <Button
                                type={"ghost"}
                                onClick={addNode}
                                iconLeft={
                                    <Icon value={"add"} size={"small"} />
                                }
                                label={"Add Node"}
                                size={"small"}
                            />
                            {/*!editable_home && */
                            <div className={`ans-close-row ${editable_home?'edit-home-disabled':''} `}>
                                <span  onClick={()=>{if(!editable_home){ setEditableHome(true)} } }>
                                    <svg  width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.862 4.98676L19.5 7.62476M16.862 4.98676L18.549 3.29876C18.9007 2.94708 19.3777 2.74951 19.875 2.74951C20.3723 2.74951 20.8493 2.94708 21.201 3.29876C21.5527 3.65044 21.7502 4.12741 21.7502 4.62476C21.7502 5.12211 21.5527 5.59908 21.201 5.95076L6.832 20.3198C6.30332 20.8481 5.65137 21.2365 4.935 21.4498L2.25 22.2498L3.05 19.5648C3.26328 18.8484 3.65163 18.1964 4.18 17.6678L16.863 4.98676H16.862Z" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            }

                        </div>	
                        <SortableTree
                            searchQuery={search}
                            searchFocusOffset={searchFocusOffset}
                            onMoveNode={function(tree){
                                console.log(tree.nextParentNode);
                                console.log(tree.node)
                                console.log(tree)
                                var nextParentNode = tree.nextParentNode;

                                if(!tree.nextParentNode){
                                    nextParentNode = {"id":null,"children":[]};


                                    tree.treeData.forEach(function(node){
                                        var treeNode = Object.assign({},node);

                                        treeNode.children = [];

                                        nextParentNode.children.push(treeNode);

                                    })


                                }
                                

                                updateNodePosition({node_id:tree.node.id, nextParentNode: nextParentNode});

                                /*saveTreeData({
                                    ...formValues,
                                    ["tree"]: tree.treeData,
                                }, false);*/
                            }}
                            searchMethod={({ node, searchQuery }) => {
                                return searchQuery &&
                                    node.title &&
                                    node.title
                                        .toLowerCase()
                                        .indexOf(searchQuery.toLowerCase()) > -1
                                    ? true
                                    : false;
                            }}
                            searchFinishCallback={(matches)=>{
                                if(matches.length > 0){
                                    searchCallback(matches.length > 0 ? searchFocusOffset % matches.length : 0,matches.length);
                                } else {
                                    searchCallback( 0,0)
                                }
                            }}
                            treeData={formValues.tree}
                            ignoreCollapsed={true}
                            onChange={(tree) => {
                                

                                lessthan5children = true;

                                if(checkIfMoreThan5Children){
                                    checkIfMoreThan5Children(tree, 0);
                                }
                                if (lessthan5children) {

                                    setFormValues((oldValues) => ({
                                        ...oldValues,
                                        ["tree"]: tree,
                                    }));
                                    /*if(tree.length > 0){
                                        saveTreeData({
                                            ...formValues,
                                            ["tree"]: tree,
                                        }, false);
                                    }*/

                                    //autoAdjustHeight();

                                } else {
                                    showToast("You can add up to 5 child nodes only.","error");

                                    /*
                                    toast(
                                        <div className="toastinner">
                                            {<FormattedMessage id="You can add up to 5 child nodes only." />}
                                            <a
                                                onClick={() => {
                                                    toast.dismiss();
                                                }}
                                            >
                                                &times;
                                            </a>
                                        </div>,
                                        {
                                            className: "errortoast",
                                            position: "bottom-center",
                                        }
                                    );*/
                                }
                            }}
                            
                            generateNodeProps={(extendedNode) => ({
                                className: (extendedNode.node.error
                                    ? "error_class"
                                    : (!extendedNode.node.title || (!editable_text && extendedNode.node.editable)?"bg-error":"")),
                                style: {
                                    background: getNodeBg(extendedNode),
                                    width:
                                        "calc(100% - " +
                                        44 * (extendedNode.path.length - 1) +
                                        "px)",
                                },
                                title: (
                                    <span
                                        ref={
                                            extendedNode.node.editable
                                                ? cnodeRef
                                                : undefined
                                        }
                                        id={"inputnode"+extendedNode.node.id}
                                        style={{
                                            fontWeight:
                                                extendedNode.path.length > 1
                                                    ? "300"
                                                    : "600",
                                        }}
                                        onClick={makeEditableNode?
                                            makeEditableNode(
                                                extendedNode.path
                                            )
                                            :undefined}
                                    >
                                        {extendedNode.node.editable && (
                                            <AutosizeInput
                                                required={true}
                                                className={`form-control ${test && test.language == "ar"
                                                        ? "arabic_wrapper"
                                                        : ""
                                                    }`}
                                                type="text"
                                                placeholder={
                                                    extendedNode.path.length == 1
                                                        ? "Level 1"
                                                        : "This is your child node level " +
                                                        extendedNode.path.length
                                                }
                                                ref={
                                                    extendedNode.node.editable
                                                        ? nodeRef
                                                        : undefined
                                                }
                                                onBlur={() => {
                                                   
                                                    if(updateNodeTitle){
                                                        updateNodeTitle(extendedNode.path)
                                                    }
                                                }}
                                                onKeyPress={(e) => { 
                                                    
                                        
                                                        /*if(e.key === 'Enter'){ 
                                                            if(updateNodeTitle){
                                                                if(extendedNode.parentNode){
                                                                    updateNodeTitle(extendedNode.path, extendedNode.parentNode)
                                                                } else {
                                                                    updateNodeTitle(extendedNode.path, "root")
                                                                }
                                                            }
                                                        } */
                                                    }
                                                }
                                                
                                                value={editable_text}
                                                onChange={(e) => {
                                                    setEditableText(e.target.value);
                                                    
                                                    //console.log(moment().diff(prevStamp,"seconds"))
                                                    //moment().diff(prevStamp,"seconds")>2 && 
                                                    
                                                    if(e.target.value!=editable_text){
                                                        if(updateNodeTitle){

                                                            updateNodeTitle(extendedNode.path, false,e.target.value);
                                                            /*if(update_title_timer){
                                                                clearTimeout(update_title_timer);
                                                            }
                                                            update_title_timer = setTimeout(()=>{
                                                                
                                                            },3000);*/
                                                            

                                                            
                                                        }
                                                    }

                                                    
                                                }}
                                                maxLength="60"
                                                onInput={maxLengthCheck} 
                                            />
                                        )}
                                        {!extendedNode.node.editable && (
                                            <>
                                                {extendedNode.node.title && (
                                                    <span className={`${test && test.language == "ar"
                                                    ? "cairo-font"
                                                    : ""
                                                }`}>{extendedNode.node.title}</span>
                                                )}
                                                {!extendedNode.node.title &&
                                                    extendedNode.node.error && (
                                                        <>Give a title to the node</>
                                                    )}
                                                {!extendedNode.node.title &&
                                                    !extendedNode.node.error && (
                                                        <>
                                                            {extendedNode.path.length == 1
                                                                ? "Level 1"
                                                                : "This is your child node level " +
                                                                extendedNode.path.length}
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </span>
                                ),
                                buttons: [
                                    <Button
                                        type={"ghost"}
                                        onClick={()=>{ if(addChild){ addChild(extendedNode.path) } }} 
                                        iconLeft={
                                            <Icon value={"add"} />
                                        }
                                        label={"Add Child"}
                                    />,
                                    <span className={extendedNode.node.editable?"edit-icon-disabled":""} onClick={makeEditableNode ? makeEditableNode(
                                        extendedNode.path
                                    ):undefined}>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.862 4.98676L19.5 7.62476M16.862 4.98676L18.549 3.29876C18.9007 2.94708 19.3777 2.74951 19.875 2.74951C20.3723 2.74951 20.8493 2.94708 21.201 3.29876C21.5527 3.65044 21.7502 4.12741 21.7502 4.62476C21.7502 5.12211 21.5527 5.59908 21.201 5.95076L6.832 20.3198C6.30332 20.8481 5.65137 21.2365 4.935 21.4498L2.25 22.2498L3.05 19.5648C3.26328 18.8484 3.65163 18.1964 4.18 17.6678L16.863 4.98676H16.862Z" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>,
                                    <span onClick={
                                        () => {

                                            if (extendedNode.node.children.length > 0) {
                                                setConfirmModal({ ...confirmModal, open: true, node: extendedNode.node, path: extendedNode.path });
                                            } else {
                                                if(removeAnswer && removeNodeApi){
                                                    removeAnswer(extendedNode.node.id);

                                                    removeNodeApi(extendedNode.node, extendedNode.path);
                                                }
                                            }
                                        }
                                    }>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.74 9.00003L14.394 18M9.606 18L9.26 9.00003M19.228 5.79003C19.57 5.84203 19.91 5.89703 20.25 5.95603M19.228 5.79103L18.16 19.673C18.1164 20.2383 17.8611 20.7662 17.445 21.1513C17.029 21.5364 16.4829 21.7502 15.916 21.75H8.084C7.5171 21.7502 6.97102 21.5364 6.55498 21.1513C6.13894 20.7662 5.88359 20.2383 5.84 19.673L4.772 5.79003M19.228 5.79003C18.0739 5.61555 16.9138 5.48313 15.75 5.39303M3.75 5.95503C4.09 5.89603 4.43 5.84103 4.772 5.79003M4.772 5.79003C5.92613 5.61555 7.08623 5.48313 8.25 5.39303M15.75 5.39303V4.47703C15.75 3.29703 14.84 2.31303 13.66 2.27603C12.5536 2.24067 11.4464 2.24067 10.34 2.27603C9.16 2.31303 8.25 3.29803 8.25 4.47703V5.39303M15.75 5.39303C13.2537 5.20011 10.7463 5.20011 8.25 5.39303" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>,
                                ],
                            })}
                            rowDirection={"rtl"}
                        />
                        
                    </div>
                    <div className="add-node-wrap withoutborder">
                        <div className="add-new-memberbtn-option mb-0">
                            <Button
                                type={"primary"}
                                onClick={addNode}
                                iconLeft={
                                    <Icon value={"add"}colorClass={'gray-50-svg'} size={"medium"} />
                                }
                                label={"Add Node"}
                            />
                            {formValues.tree.length > 0 &&
                                <div className="tree-nodes-delete-all-count">
                                    <span>{treeSize.nodes} node{treeSize.nodes==1?'':'s'} and {treeSize.children} {treeSize.children==1?'child':'children'} </span>
                                    <Button
                                        type={"ghost"}
                                        onClick={() => {
                                            setConfirmDeleteModal({ ...confirmDeleteModal, open: true });
                                        }}
                                        iconRight={
                                            <Icon value={"delete"} size={"small"} />
                                        }
                                        size={"medium"}
                                        label={"Delete all"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>;
});