import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { Icon } from "../Icon/Icon";

let image_width = null;

export const Preview = ({open, loading, img, background, file_type, close, withMinimizeIcon, rtl})=>{

    const [scale, setScale] = useState(100);

    const imgRef = useRef();

    

    const [width, setWidth] = useState(null);

    const closeModal = () => {
        close();
    };
  const handleZoomIn = (event) => {
    setScale(prevScale => prevScale + 10);

    if(imgRef && imgRef.current){
          
        if(!image_width){
            image_width = imgRef.current.clientWidth;
            
        }
        setWidth(image_width*(scale+10)/100);
    }
  };

  const handleZoomOut = () => {
    setScale(prevScale => Math.max(10, prevScale - 10));

    if(imgRef && imgRef.current){

        if(!image_width){
            image_width = imgRef.current.clientWidth;
        }
        if(scale >10){
            setWidth(  image_width*(scale-10)/100);
        }
    }
  };
  useEffect(() => {
    setScale(100);
    setWidth(null);
    image_width = null;
  },[open])
  /*const handleKeyDown = (event) => {
    if (event.ctrlKey) {
      switch (event.key) {
        case '+':
            handleZoomIn();
          break;
        case '-':
            handleZoomOut();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);*/

    useEffect(()=>{
        if(open){
            setScale(100);
        }
    },[open]);
    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap ${rtl?"arabic-wrapper":""}  confirmation-modal-wrap designs-preview-modal ${withMinimizeIcon?"preview-min-icon":""}`}
            onHide={() => {
                closeModal();
            }}
            style={{background:background}}
        >
            <Modal.Body className=""  style={{background:background}}>
                {loading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text" style={{background:background}}>
                        <div className="modal-header-top">
                            {/*
                            <h2 className={`heading h2`}>Preview</h2>*/}

                            <button
                                type="button"
                                className="close black-bg dropdown-toggle-disabled"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <Icon value="Close" colorClass={'gray-50-svg'} size="medium" />
                                </span>
                            </button>
                        </div>
                        {file_type!="video" &&
                        <div className="zoom-controls-wrapper">
                            <div className="zoom-controls" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }} >
                                <span className="zoom-in" onClick={handleZoomIn}>
                                    <Icon value="add" colorClass={'gray-900-svg'} size="medium" />
                                </span>
                                <span className="zoom-out" onClick={handleZoomOut}>
                                    <Icon value="Minimize" colorClass={'gray-900-svg'} size="medium" />
                                </span>
                                
                            </div>
                            <span className="zoom-percentage">{scale+"%"}</span>
                        </div>
                        }
                        <div className="prefer-design-view-img-hold " style={{overflow:"auto"}} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); closeModal();}}>
                            {file_type=="video"
                                ?
                            <video controls>
                                <source src={img} />
                            </video>
                                :
                            <div className={`zoom-container ${scale>100?"active-zoom":""}`}  onClick={(e)=>{e.preventDefault(); e.stopPropagation(); }} >
                                <img ref={imgRef} style={{width: width!=null?width+"px":'auto'}} src={img}  />
                            </div>
                            }
                           
                        </div>

                    </div>
                }
            </Modal.Body>
        </Modal>
    );
    
}
