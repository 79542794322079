import React from 'react';
import { Navigate } from "react-router-dom";
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession';

export default function Auth({path}){
    ReactSession.setStoreType("localStorage");
    let ptoken = ReactSession.get("token");

    if(ptoken!="" && ptoken!="null" && ptoken!=null){
        
        return (<Navigate to="/dashboard" />);
        
    } else {
        return (<Navigate to={path} />);
    }
    
}