import { useNavigate, useParams, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Dropdown as SearchableDropdown } from "reactjs-dropdown-component";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { getTargetGroupOptions } from "../../../services/target_group.js";
import "react-multi-carousel/lib/styles.css";
import toast from "react-hot-toast";
import MultiRangeSlider from "multi-range-slider-react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import {DropdownIndicator, nFormatter, normalize, unnormalize} from "../../../lib/helpers";
import {
	updateTargetGroupService,
	deleteTargetGroupService,
	getTargetGroupService,
	renameTargetGroupService,
} from "../../../services/target_group.js";
import ConfirmModal from "../../dialog/confirm_modal";
import RenameModal from "./../../dialog/rename_model";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import CtaActionModal from '../../dialog/cta_action_modal';

export default function TargetGroup() {
	let educationSelect = useRef();

	let { target_group_id } = useParams();

	const [formLoading, setFormLoading] = useState(false);

	ReactSession.setStoreType("localStorage");

	const navigate = useNavigate();

	const [target_group_name, setTargetGroupName] = useState(null);

	const [isLoading, setLoading] = useState(false);

	const [successMsg, setSuccessMessage] = useState(
		ReactSession.get("successVerifiedMessage")
	);

	const [errorMsg, setErrorMessage] = useState(null);

	const [cta_modal, setCTAModal] = useState({ open: false });

	const [educationLabelClass, setEducationLabelClass] =
		useState("inactive_label");

	const [incomeLabelClass, setIncomeLabelClass] = useState("inactive_label");

	const [hoursLabelClass, setHoursLabelClass] = useState("inactive_label");

	const [employmentLabelClass, setEmploymentLabelClass] =
		useState("inactive_label");

	const [industryLabelClass, setIndustryLabelClass] =
		useState("inactive_label");

	const [departmentLabelClass, setDepartmentLabelClass] =
		useState("inactive_label");

	const [nationalityLabelClass, setNationalityLabelClass] =
		useState("inactive_label");

	const [emirateLabelClass, setEmirateLabelClass] = useState("inactive_label");

	const [ksaLabelClass, setKsaLabelClass] = useState("inactive_label");

	const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

	const [deviceLabelClass, setDeviceLabelClass] = useState("inactive_label");

	const [appLabelClass, setAppLabelClass] = useState("inactive_label");

	const [modal, setModal] = useState({
		modalHeading: null,
		modalMessage: null,
		modalOpen: false,
	});

	const [confirmModal, setConfirmModal] = useState({
		open: false,
		target_id: null,
	});

	const [formErrors, setFormErrors] = useState({
		image: null,
		project_name: null,
		error_class: null,
	});

	const [formValues, setFormValues] = useState({
		age_match: 1,
		gender_match: 1,
		min_age: 18,
		max_age: 90,
		male_perc: 50,
		female_perc: 50,
		nationality_match: 0,
		nationalities: [],
		residency_match: 0,
		emirates_match: 0,
		ksa_match: 0,
		countries_match: 0,
		countries: [],
		emirates: [],
		ksa: [],
		countries: [],
		education_match: 0,
		education: null,
		technology_match: 0,
		hours_spend_online: null,
		devices: [],
		apps: [],
		employment_match: 0,
		employment: [],
		industry: [],
		department: [],
		earnings_match: 0,
		household_members_condition: "All",
		people: 0,
		term: "Yearly",
		min_income: 0,
		max_income: 1000000,
		income_label_class:null,
	});
	const [options, setOptions] = useState({
		min_age: 0,
		max_age: 100,
		min_gender: 0,
		max_gender: 100,
		nationalities: [],
		group_nationalities: {
			"East Asian": [],
			"South Asian": [],
			"Arab Expats": [],
			Emirati: [],
			Saudi: [],
			Europeans: [],
		},
		emirates: [],
		ksa: [],
		countries: [],
		education: [],
		hours_spend_online: [],
		devices: [],
		apps: [],
		employment: [],
		industry: [],
		department: [],
		min_income: 0,
		max_income: 1,
		income_levels:[]
	});



	function showmore(divID) {
		//document.getElementById(divID).style.height = '600px';
		document.getElementById(divID).classList.add("expendit");
	}

	function showless(divID) {
		document.getElementById(divID).classList.remove("expendit");
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			var slides = document.getElementsByClassName("target-group-data-repeat");
			for (var i = 0; i < slides.length; i++) {
				var bbheight = 0;
				var ccheight = 0;
				var bb = slides.item(i).getElementsByClassName("targer-group-left-wrap")[0];
				var cc = slides.item(i).getElementsByClassName("target-group-info-repeat")[0];
				if (bb) {
					bbheight = bb.offsetHeight;
				}
				if (cc) {
					ccheight = cc.offsetHeight;
				}
				//console.log('>>'+bbheight+'<><>'+ccheight);
				if (bbheight < ccheight) {
					cc.classList.add('lessshow');
					cc.style.height = bbheight + 'px';
				} else {
					cc.classList.add('normalshow');
				}
			}



		}, 3000);
		return () => clearTimeout(timer);

	}, []);


	const [rename, setRename] = useState({ open: false, isLoading: false });
	const [title, setTitle] = useState(null);
	const [targetId, setTargetId] = useState(null);

	const deleteTargetGroup = (target_group_id) => {
		let token = ReactSession.get("token");

		setLoading(true);

		deleteTargetGroupService({ id: target_group_id }, token)
			.then((response) => {
				setLoading(false);

				if (response.success) {
					navigate(-1);
				}
			})
			.catch(function (err) {
				console.error(` Err: ${err}`);
			});
	};
	const getTargetGroup = () => {
		let token = ReactSession.get("token");

		setLoading(true);

		getTargetGroupService({ target_group_id: target_group_id }, token).then(
			(response) => {
				setLoading(false);

				if (response.success) {
					var target_group = response.target_group;

					setTargetGroupName(target_group.target_group_name);

					var ksa = [];

					// console.log(options['ksa'])

					if (target_group.countries) {
						options["ksa"].forEach(function (item) {
							target_group.countries
								.split(",")
								.forEach(function (item_country) {
									if (item_country == item.value) {
										ksa.push(item);
									}
								});
						});
					}
					ksa = ksa.filter(function(value, index, self) {
						return self.indexOf(value) === index;
					  });
					var emirates = [];
					if (target_group.countries) {
						options["emirates"].forEach(function (item) {
							target_group.countries
								.split(",")
								.forEach(function (item_country) {
									if (item_country == item.value) {
										emirates.push(item);
									}
								});
						});
					}

					var other_countries = [];

					if (target_group.countries) {
						options["countries"].forEach(function (item) {
							target_group.countries
								.split(",")
								.forEach(function (item_country) {
									if (item_country == item.value) {
										other_countries.push(item);
									}
								});
						});
					}
					var formValuesLocal = {
						...formValues,
						target_group_name: target_group.target_group_name,
						target_group_id: target_group_id,
						age_match: target_group.min_age != null ? 1 : 0,
						gender_match: target_group.gender != null ? 1 : 0,
						gender: target_group.gender != null ? target_group.gender : "",
						min_age:
							target_group.min_age != null
								? target_group.min_age
								: options.min_age,
						max_age:
							target_group.max_age != null
								? target_group.max_age
								: options.max_age,
						male_perc:
							target_group.gender_male_perc != null
								? target_group.gender_male_perc
								: 50,
						female_perc:
							target_group.gender_female_perc != null
								? target_group.gender_female_perc
								: 50,
						nationality_match: target_group.nationalities != null ? 1 : "",
						nationalities:
							target_group.nationalities != null
								? unnormalize(target_group.nationalities.split(","))
								: [],
						residency_match: target_group.countries != null ? 1 : 0,
						emirates_match: emirates.length > 0 ? 1 : 0,
						ksa_match: ksa.length > 0 ? 1 : 0,
						countries_match: other_countries.length > 0 ? 1 : 0,
						countries: other_countries,
						emirates: emirates,
						ksa: ksa,
						education_match: target_group.education != null && target_group.education != "" ? 1 : 0,
						education:
							target_group.education != null
								? {
									value: target_group.education,
									label: target_group.education,
								}
								: null,
						technology_match:
							target_group.hours_spend_online != null ||
								target_group.devices != null ||
								target_group.apps != null
								? 1
								: 0,
						hours_spend_online:
							target_group.hours_spend_online != null
								? {
									value: target_group.hours_spend_online,
									label: target_group.hours_spend_online,
								}
								: null,
						devices:
							target_group.devices != null
								? unnormalize(target_group.devices.split(","))
								: [],
						apps:
							target_group.apps != null
								? unnormalize(target_group.apps.split(","))
								: [],
						employment_match:
							target_group.employment != null ||
								target_group.industry != null ||
								target_group.department != null
								? 1
								: 0,
						employment:
							target_group.employment != null
								? unnormalize(target_group.employment.split(","))
								: [],
						industry:
							target_group.industry != null
								? unnormalize(target_group.industry.split("|"))
								: [],
						department:
							target_group.department != null
								? unnormalize(target_group.department.split(","))
								: [],
						earnings_match: target_group.earnings_match,
						household_members_condition:
							target_group.household_members_condition != null
								? target_group.household_members_condition
								: "All",
						people: target_group.people != null ? target_group.people : 0,
						term: target_group.term != null ? target_group.term : "Yearly",
						min_income:
							target_group.min_income != null ? target_group.min_income : 0,
						max_income:
							target_group.max_income != null
								? target_group.max_income
								: 1000000,
						income_slab: target_group.income_slab != null ? unnormalize(target_group.income_slab.split("|")) : [],

					};
					setFormValues(formValuesLocal);

					setNationalityLabelClass(
						formValuesLocal.nationalities.length > 0
							? "active_label"
							: "inactive_label"
					);

					setDepartmentLabelClass(
						formValuesLocal.department.length > 0
							? "active_label"
							: "inactive_label"
					);

					setEducationLabelClass(
						formValuesLocal.education != null
							? "active_label"
							: "inactive_label"
					);

					setHoursLabelClass(
						formValuesLocal.hours_spend_online != null
							? "active_label"
							: "inactive_label"
					);

					setEmploymentLabelClass(
						formValuesLocal.employment.length > 0
							? "active_label"
							: "inactive_label"
					);

					setIndustryLabelClass(
						formValuesLocal.industry.length > 0
							? "active_label"
							: "inactive_label"
					);

					setEmirateLabelClass(
						formValuesLocal.emirates.length > 0
							? "active_label"
							: "inactive_label"
					);

					setKsaLabelClass(
						formValuesLocal.ksa.length > 0 ? "active_label" : "inactive_label"
					);

					setCountryLabelClass(
						formValuesLocal.countries.length > 0
							? "active_label"
							: "inactive_label"
					);

					setDeviceLabelClass(
						formValuesLocal.devices.length > 0
							? "active_label"
							: "inactive_label"
					);

					setAppLabelClass(
						formValuesLocal.apps.length > 0 ? "active_label" : "inactive_label"
					);

					setIncomeLabelClass(
						formValuesLocal.income_slab.length > 0
							? "active_label"
							: "inactive_label"
					);
				} else {
					// toast.error(response.message)
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
							duration: 3000,
						}
					);
				}
			}
		);
	};
	const getTargetGroupOptionsApi = () => {
		let token = ReactSession.get("token");

		setLoading(true);

		getTargetGroupOptions({}, token).then((response) => {
			setLoading(false);

			if (response.success) {
				if (formValues.min_age < response.options.age_range.min) {
					setFormValue("min_age", response.options.age_range.min);
				}
				if (formValues.max_age > response.options.age_range.max) {
					setFormValue("max_age", response.options.age_range.max);
				}
				var other_countries = [];

                for(var  i=0; i<response.options.countries.length;i++){
                    if(response.options.countries[i].value!="United Arab Emirates" && response.options.countries[i].value!="Saudi Arabia"){
                        other_countries.push(response.options.countries[i]);
                    }
                }
				setOptions({
					min_age: response.options.age_range.min,
					max_age: response.options.age_range.max,
					min_gender: 0,
					max_gender: 100,
					nationalities: response.options.nationalities,
					group_nationalities: response.options.group_nationalities,
					emirates: response.options.emirates,
					ksa: response.options.ksa,
					countries: other_countries,
					education: response.options.education,
					hours_spend_online: response.options.hours_spend_online,
					devices: response.options.devices,
					apps: response.options.apps,
					employment: response.options.employment,
					industry: response.options.industry,
					department: response.options.department,
					min_income: response.options.income.min.value,
					max_income: response.options.income.max.value,
					min_income_label: response.options.income.min.label,
					max_income_label: response.options.income.max.label,
					income_levels: response.options.income_levels

				});
			} else {
				// toast.error(response.message)
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
						duration: 3000,
					}
				);
			}
		});
	};
	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Target Group";

		// setTargetGroupName(ReactSession.get("target_group_name"));

		//getTargetGroup();

		getTargetGroupOptionsApi();
	}, []);

	useEffect(() => {
		getTargetGroup();
	}, [options]);

	const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
	const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
	};
	const toggleCheck = (name, value) => {
		if (name == "age_match") {
			if (value !== 1) {
				setFormValue("min_age", options.min_age);
				setFormValue("max_age", options.max_age);
			}
		}
		if (name == "gender_match") {
			if (value !== 1) {
				setFormValue("male_perc", 50);
				setFormValue("female_perc", 50);
			}
		}
		if (name == "nationality_match") {
			if (value !== 1) {
				setFormValue("nationalities", []);

				setNationalityLabelClass("inactive_label");
			}
		}
		if (name == "residency_match") {
			if (value !== 1) {
				setFormValue("ksa", []);
				setFormValue("emirates", []);
				setFormValue("countries", []);

				setFormValue("ksa_match", 0);
				setFormValue("emirates_match", 0);
				setFormValue("countries_match", 0);

				setEmirateLabelClass("inactive_label");
				setKsaLabelClass("inactive_label");
				setCountryLabelClass("inactive_label");
			}
		}
		if (name == "emirates_match") {
			if (value !== 1) {
				setFormValue("emirates", []);
				setFormValue("emirates_match", 0);
				setEmirateLabelClass("inactive_label");
			}
		}
		if (name == "ksa_match") {
			if (value !== 1) {
				setFormValue("ksa", []);
				setFormValue("ksa_match", 0);
				setKsaLabelClass("inactive_label");
			}
		}
		if (name == "countries_match") {
			if (value !== 1) {
				setFormValue("countries", []);
				setFormValue("countries_match", 0);
				setCountryLabelClass("inactive_label");
			}
		}
		if (name == "education_match") {
			if (value !== 1) {
				setFormValue("education", []);
				setEducationLabelClass("inactive_label");
			}
		}
		if (name == "technology_match") {
			if (value !== 1) {
				setFormValue("hours_spend_online", null);

				setFormValue("devices", []);

				setFormValue("apps", []);

				setHoursLabelClass("inactive_label");

				setDeviceLabelClass("inactive_label");

				setAppLabelClass("inactive_label");
			}
		}
		if (name == "employment_match") {
			if (value !== 1) {
				setFormValue("employment", []);

				setFormValue("industry", []);

				setFormValue("department", []);

				setEmploymentLabelClass("inactive_label");

				setIndustryLabelClass("inactive_label");

				setDepartmentLabelClass("inactive_label");
			}
		}
		if (name == "earnings_match") {
			if (value !== 1) {
				setFormValue("household_members_condition", "All");

				setFormValue("people", 0);

				setFormValue("min_income", 0);

				setFormValue("term", "Yearly");

				setFormValue("income_slab", []);

				setIncomeLabelClass("inactive_label");
			}
		}
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
	};
	const onSubmit = async (event) => {
		event.preventDefault();

		saveTargetGroup(null);

		return false;
	};
	const saveTargetGroup = (action) => {
		resetFormErrors();

		if (!formLoading) {
			var error = false;

			if (!error) {
				setFormLoading(true);

				const token = ReactSession.get("token");

				var formData = Object.assign({}, formValues);

				formData["target_group_name"] = ReactSession.get("target_group_name");

				formData["target_group_id"] = target_group_id;

				console.log(formData["nationalities"]);

				formData["nationalities"] = normalize(formData["nationalities"]);

				formData["countries"] = normalize(formData["countries"]);

				formData["emirates"] = normalize(formData["emirates"]);

				formData["ksa"] = normalize(formData["ksa"]);

				formData["education"] = formData["education"]
					? formData["education"]["value"]
					: "";

				formData["devices"] = normalize(formData["devices"]);

				formData["hours_spend_online"] = formData["hours_spend_online"]
					? formData["hours_spend_online"]["value"]
					: "";

				formData["employment"] = normalize(formData["employment"]);

				formData["department"] = normalize(formData["department"]);

				formData["industry"] = normalize(formData["industry"]);

				formData["apps"] = normalize(formData["apps"]);

				formData["income_slab"] = normalize(formData["income_slab"]);

				formData["gender"] =
					formData["gender_match"] == 1 ? formData["gender"] : "";

				formData["household_members_condition"] =
					formData["earnings_match"] == 1
						? formData["household_members_condition"]
						: null;

				updateTargetGroupService(formData, token).then((response) => {
					setFormLoading(false);

					if (response.success) {
						if (action == "save_exit") {
							navigate(-1);
						}
						if (response.testers_count < 30 && response.form_updated) {

							setCTAModal({ open: true, 
								title:"Your target group has been updated!", 
								"message": <>Before you can start testing with this group, more testers are needed to meet your criteria. <Link to={'/researcher/support/contact?reason=Help with recruitment'}> Get in touch</Link> us so that create your perfect target group.</>,
								close_action: function () { setCTAModal({ ...cta_modal, open: false }); navigate(-1); } })

						} else {


							toast(
								<div className="toastinner">
									{response.message}
									<a
										onClick={() => {
											toast.dismiss();
										}}
									>
										&times;
									</a>
								</div>,
								{
									className: "successtoast",
									position: "bottom-center",
									duration: 3000,
								}
							);
						}
						// setSuccessMessage(response.message);
					} else {
						toast(
							<div className="toastinner">
								{response.message}
								<a
									onClick={() => {
										toast.dismiss();
									}}
								>
									&times;
								</a>
							</div>,
							{
								className: "errortoast",
								position: "bottom-center",
								duration: 3000,
							}
						);
						// setErrorMessage(response.message);
					}
				});
			}
		}
	};
	const nationalityChange = (items) => {
		if (items.length > 0) {
			setNationalityLabelClass("active_label");
		} else {
			setNationalityLabelClass("inactive_label");
		}
		setFormValue("nationalities", items);
	};

	const getFastClass = (name) => {
		let nationalities = formValues.nationalities;

		let check = true;

		let fast_nationalities = options["group_nationalities"][name];

		fast_nationalities.forEach(function (fast_item) {
			let exist = false;
			nationalities.forEach(function (item) {
				if (item.label == fast_item) {
					exist = true;
				}
			});
			if (!exist) {
				check = false;
			}
		});
		if (check) {
			return "active";
		}
		return "";
	};
	const selectFast = (name) => {
		return (e) => {
			if (formValues.nationality_match != 1) {
				return;
			}

			let nationalities = [...formValues.nationalities];

			let fast_nationalities = options["group_nationalities"][name];

			let fast_selection = [];

			fast_nationalities.forEach(function (fast_item) {
				fast_selection.push({
					value: fast_item,
					label: fast_item,
				});
			});

			if (getFastClass(name) != "active") {
				fast_selection.forEach(function (fast_item) {
					let exist = false;
					nationalities.forEach(function (item) {
						if (item.value == fast_item.value) {
							exist = true;
						}
					});
					if (!exist) {
						nationalities.push(fast_item);
					}
				});
			}
			if (getFastClass(name) == "active") {
				fast_selection.forEach(function (fast_item) {
					var exist = -1;
					nationalities.forEach(function (item, index) {
						if (item.value == fast_item.value) {
							exist = index;
						}
					});
					if (exist != -1) {
						nationalities.splice(exist, 1);
					}
				});
			}
			if (nationalities.length == 0) {
				setNationalityLabelClass("inactive_label");
			} else {
				setNationalityLabelClass("active_label");
			}
			setFormValue("nationalities", nationalities);

			return;
		};
	};
	const addPeople = () => {
		if (formValues.earnings_match !== 1) {
			return;
		}
		var people = Number(formValues.people);
		if (people < 10) {
			setFormValue("people", people + 1);
		}
	};
	const minusPeople = () => {
		if (formValues.earnings_match !== 1) {
			return;
		}
		var people = Number(formValues.people);
		if (people > 0) {
			setFormValue("people", people - 1);
		}
	};
	const changePeople = (e) => {
		const re = /^[0-9\b]+$/;

		// if value is not blank, then test the regex

		if (e.target.value === "" || re.test(e.target.value)) {
			setFormValue("people", Number(e.target.value));
		}
	};
	const resetFormErrors = () => {
		let formErrorsLocal = Object.assign({}, formErrors);

		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		setFormErrors(formErrorsLocal);
	};
	const clearErrorMessage = () => {
		setErrorMessage(null);
	};
	const clearSuccessMessage = () => {
		setSuccessMessage(null);
	};

	const deleteTargetGroupApi = (target_group_id) => {
		let token = ReactSession.get("token");

		setLoading(true);

		deleteTargetGroupService({ id: target_group_id }, token)
			.then((response) => {
				setLoading(false);

				if (response.success) {
					navigate(-1);
				}
			})
			.catch(function (err) {
				console.error(` Err: ${err}`);
			});
	};

	const renameTargetGroup = (targetId, title) => {
		let token = ReactSession.get("token");

		setRename({ ...rename, isLoading: true });

		renameTargetGroupService({ id: targetId, name: title }, token).then(
			(response) => {
				setLoading(false);

				if (response.success) {
					setTargetGroupName(title);
					ReactSession.set("target_group_name", title);
					setRename({ ...rename, open: false, isLoading: false });
					setTitle(null);
					setTargetId(null);
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};

	return (
		<LayoutResearcherFullWidth
			isLoading={false}
			fixed_header_target={true}
			skipCheck={false}
			activeMenu={"dashboard"}
		>
			<form onSubmit={onSubmit}>
				<div className="page-back-option-wrap target_group_fixed_header">
					<div className="container inner-page-container">
						<div className="profile-info-section">
							<div className="target-back-option-data">
								<div className="backto-list-wrap">
									<a
										href="#"
										onClick={(e) => {
											e.preventDefault();
											navigate(-1);
										}}
									>
										<img
											src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
											alt="img"
										/>{" "}
										Back
									</a>
								</div>
								<div className="profile-info-text">
									<div className="target-group-heading-top">
										<h1 className="h1">{target_group_name}</h1>
									</div>
									<div className="dashboard-top-right project-das-top-right target-group-btns-top top-wdropd">
										{/*<div className="top-buttons-wdropd auto-width-button">
											<button
												type="button"
												onClick={(e) => {
													e.preventDefault();
													setModal({
														modalHeading: "Email Sent",
														modalMessage:
															"We just sent an email! Click on the link in the email to generate a new password.",
														modalOpen: true,
													});
												}}
												className="btn form-btn secondary-btn"
											>
												Cancel
											</button>
											<button type="submit" className="btn form-btn">
												Save
												{formLoading && (
													<i
														className="fa fa-spinner fa-spin"
														aria-hidden="true"
														style={{ marginLeft: "5px" }}
													></i>
												)}
											</button>
												</div>*/}
										<span className="pro-menu-icon small-dropdown">
											<Dropdown>
												<Dropdown.Toggle
													id="dropdownMenuButton"
													className="dropdown-toggle"
												>
													<img
														src={
															process.env.REACT_APP_URL +
															"images/menu-bg-icon.svg"
														}
														alt="img"
													/>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<div className="drop-down-link-hold">
														<Dropdown.Item
															onClick={() => {
																setTitle(target_group_name);
																setRename({ open: true, isLoading: false });
																setTargetId(target_group_id);
															}}
														>
															Rename
															<span>
																<img
																	src={
																		process.env.REACT_APP_URL +
																		"images/edit-icon.svg"
																	}
																	alt="img"
																/>
															</span>
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => {
																setConfirmModal({
																	open: true,
																	confirm_btn_title: "Delete",
																	confirm_title: "Delete Target Group",
																	confirm_message:
																		" Are you sure you want to delete this target group?",
																	target_id: target_group_id,
																	confirmation_for: "targetgroupdelete",
																});
															}}
														>
															{" "}
															Delete
															<span>
																<img
																	src={
																		process.env.REACT_APP_URL +
																		"images/trash-black.svg"
																	}
																	alt="img"
																/>
															</span>
														</Dropdown.Item>
													</div>
												</Dropdown.Menu>
											</Dropdown>
										</span>
									</div>
								</div> 
							</div>
						</div>
					</div>
				</div>
				<div className="container inner-page-container create-new-target-group-page-wrap">
					<div className="target-group-data-wrapper">
						{/* {errorMsg && (
                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                  <div className="pro-lft-wrap">{errorMsg}</div>
                  <div className="pro-right-wrap">
                    <div className="delete-progress-row">
                      <img
                        onClick={clearErrorMessage}
                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              )} */}
						{/* {successMsg && (
                <div className="profile-complete-progress-wrap login-message-wrap">
                  <div className="pro-lft-wrap">{successMsg}</div>
                  <div className="pro-right-wrap">
                    <div className="delete-progress-row">
                      <img
                        onClick={clearSuccessMessage}
                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              )} */}
						<div className="target-group-data-repeat">
							<div className="targer-group-left-wrap">
								<h3>Demographics</h3>
								<div className="row">
									<div className="col-lg-6">
										<div className="target-form-wrap">
											<label className="check-switch">
												<input
													type="checkbox"
													checked={formValues.age_match}
													onChange={(event) =>
														toggleCheck(
															"age_match",
															event.currentTarget.checked ? 1 : 0
														)
													}
												/>
												<span className="check-slider"></span>
												<p>Age</p>
											</label>
											{formValues.age_match == 1 && (
												<div className="target-group-col-data">
													<MultiRangeSlider
														disabled={formValues.age_match == 1 ? true : false}
														min={options.min_age}
														max={options.max_age}
														step={1}
														ruler={false}
														label={false}
														preventWheel={false}
														minValue={formValues.min_age}
														maxValue={formValues.max_age}
														onInput={(e) => {
															setFormValue("min_age", e.minValue);
															setFormValue("max_age", e.maxValue);
															setFormValue("age_match", 1);
														}}
													/>

													<div className="gender-range-data">
														<span>
															<b>{formValues.min_age}</b> years
														</span>
														<span>
															<b>{formValues.max_age}</b> years
														</span>
													</div>
												</div>
											)}
										</div>
									</div>
									<div className="col-lg-6">
										<div className="target-form-wrap">
											<label className="check-switch">
												<input
													type="checkbox"
													checked={formValues.gender_match}
													onChange={(event) =>
														toggleCheck(
															"gender_match",
															event.currentTarget.checked ? 1 : 0
														)
													}
												/>
												<span className="check-slider"></span>
												<p>Gender</p>
											</label>
											{formValues.gender_match == 1 && (
												<div className="target-group-col-data">
													{/*<input
                              type="range"
                              min={0}
                              max={100}
                              step={1}
                              value={formValues.male_perc}
                              onChange={(e) => {
                                setFormValue("male_perc", e.target.value);
                                setFormValue(
                                  "female_perc",
                                  100 - e.target.value
                                );
                                setFormValue("gender_match", 1);
                              }}
                            />
                            <div className="gender-range-data">
                              <span>
                                <b>{formValues.male_perc}%</b> Male
                              </span>
                              <span>
                                <b>{formValues.female_perc}%</b> Female
                              </span>
                            </div>*/}
													<div className="checkboxes radio_btn_class gender_btn  d-flex">
														<Radiobtn
															name="gender"
															value="Male"
															id="male"
															label={"Male"}
															onChange={() => {
																setFormValue("gender", "Male");
															}}
															checked={
																formValues.gender == "Male" ? true : false
															}
														/>
														<Radiobtn
															name="gender"
															value="Female"
															id="female"
															label={"Female"}
															onChange={() => {
																setFormValue("gender", "Female");
															}}
															checked={
																formValues.gender == "Female" ? true : false
															}
														/>
														<Radiobtn
															name="gender"
															value="Any"
															id="any"
															label={"Any"}
															onChange={() => {
																setFormValue("gender", "Any");
															}}
															checked={
																formValues.gender == "Any" || !formValues.gender
																	? true
																	: false
															}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="target-group-info-repeat repaetinfobox1">
								<h3>Demographics</h3>
								<p>
									Choose the age range and gender split of your target audience.
								</p>

								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox1");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox1");
									}}
								>
									See less
								</div>
							</div>
						</div>

						<div className="target-group-data-repeat target-residency-data">
							<div className="targer-group-left-wrap">
								<div className="row">
									<div className="col-lg-12">
										<label className="check-switch">
											<input
												type="checkbox"
												checked={formValues.residency_match}
												onChange={(event) =>
													toggleCheck(
														"residency_match",
														event.currentTarget.checked ? 1 : 0
													)
												}
											/>
											<span className="check-slider"></span>
											<p className="big-label-text">Residency</p>
										</label>
									</div>
									{formValues.residency_match == 1 && (
										<>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="form-group custom-control custom-checkbox">
																<input
																	type="checkbox"
																	disabled={
																		formValues.residency_match == 1
																			? false
																			: true
																	}
																	checked={formValues.emirates_match}
																	onChange={(event) =>{
																		toggleCheck(
																			"emirates_match",
																			event.currentTarget.checked ? 1 : 0
																		);
                                                                        if(event.currentTarget.checked){
                                                                            setFormValue("emirates",[{value: "All emirates of residency", label: "All emirates of residency"}]);
                                                                        }
                                                                    }
																	}
																	className="custom-control-input"
																	id="customCheck1"
																/>
																<label
																	className="custom-control-label"
																	htmlFor="customCheck1"
																>
																	UAE Residents
																</label>
															</div>
															<div
																className={`input-field mb-0 ${formValues.emirates_match != 1
																		? "read-only-field"
																		: ""
																	}`}
															>
																<label className={`${emirateLabelClass}`}>
																	Emirate of residency
																</label>
																<Select
																	placeholder="Emirate of residency"
																	value={formValues.emirates}
																	options={options.emirates}
																	onChange={(items) => {

                                                                        if(items.length==2 && items[items.length-1].value!="All emirates of residency"){
                                                                        items = items.filter(function( obj ) {
                                                                        return obj.value !== 'All emirates of residency';
                                                                        });
                                                                        }
                                                                        if(items.length>=2 && items[items.length-1].value=="All emirates of residency"){
                                                                            items = items.filter(function( obj ) {
                                                                                return obj.value == 'All emirates of residency';
                                                                            });
                                                                        }

																		items.length > 0
																			? setEmirateLabelClass("active_label")
																			: setEmirateLabelClass("inactive_label");
																		setFormValue("emirates", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.emirates_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="form-group custom-control custom-checkbox">
																<input
																	type="checkbox"
																	disabled={
																		formValues.residency_match == 1
																			? false
																			: true
																	}
																	checked={formValues.ksa_match}
																	onChange={(event) =>{
																		toggleCheck(
																			"ksa_match",
																			event.currentTarget.checked ? 1 : 0
																		);
                                                                        if(event.currentTarget.checked){
                                                                            setFormValue("ksa",[{value: "All provinces of residency", label: "All provinces of residency"}]);
                                                                        }
                                                                    }
																	}
																	className="custom-control-input"
																	id="customCheck2"
																/>
																<label
																	className="custom-control-label"
																	htmlFor="customCheck2"
																>
																	Saudi Arabia residents
																</label>
															</div>
															<div
																className={`input-field mb-0 ${formValues.ksa_match != 1
																		? "read-only-field"
																		: ""
																	}`}
															>
																<label className={`${ksaLabelClass}`}>
																	Region of residency
																</label>
																<Select
																	placeholder="Region of residency"
																	value={formValues.ksa}
																	options={options.ksa}
																	onChange={(items) => {

                                                                        if(items.length==2 && items[items.length-1].value!="All provinces of residency"){
                                                                            items = items.filter(function( obj ) {
                                                                            return obj.value !== 'All provinces of residency';
                                                                            });
                                                                        }
                                                                        if(items.length>=2 && items[items.length-1].value=="All provinces of residency"){
                                                                            items = items.filter(function( obj ) {
                                                                                return obj.value == 'All provinces of residency';
                                                                            });
                                                                        }

																		items.length > 0
																			? setKsaLabelClass("active_label")
																			: setKsaLabelClass("inactive_label");
																		setFormValue("ksa", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.ksa_match == 1 ? false : true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="form-group custom-control custom-checkbox">
																<input
																	type="checkbox"
																	disabled={
																		formValues.residency_match == 1
																			? false
																			: true
																	}
																	checked={formValues.countries_match}
																	onChange={(event) =>
																		toggleCheck(
																			"countries_match",
																			event.currentTarget.checked ? 1 : 0
																		)
																	}
																	className="custom-control-input"
																	id="customCheck3"
																/>
																<label
																	className="custom-control-label"
																	htmlFor="customCheck3"
																>
																	Other countries
																</label>
															</div>
															<div
																className={`input-field mb-0 ${formValues.countries_match != 1
																		? "read-only-field"
																		: ""
																	}`}
															>
																<label className={`${countryLabelClass}`}>
																	Select countries
																</label>
																<Select
																	placeholder="Select countries"
																	value={formValues.countries}
																	options={options.countries}
																	onChange={(items) => {
																		items.length > 0
																			? setCountryLabelClass("active_label")
																			: setCountryLabelClass("inactive_label");
																		setFormValue("countries", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.countries_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
							<div id="repaetinfobox2" className="target-group-info-repeat repaetinfobox2">
								<h3>Residency</h3>
								<p>
									Choose participants from certain countries and regions.
								</p>
								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox2");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox2");
									}}
								>
									See less
								</div>
							</div>
						</div>
						<div className="target-group-data-repeat">
							<div className="targer-group-left-wrap">
								<div className="row">
									<div className="col-lg-12">
										<div className="target-form-wrap">
											<label className="check-switch">
												<input
													type="checkbox"
													checked={formValues.nationality_match}
													onChange={(event) =>
														toggleCheck(
															"nationality_match",
															event.currentTarget.checked ? 1 : 0
														)
													}
												/>
												<span className="check-slider"></span>
												<p className="big-label-text">Nationalities</p>
											</label>
											{formValues.nationality_match == 1 && (
												<div className="target-group-col-data">
													<div className="profile-form-group">
														<div className="input-field mb-0">
															<div className="">
																<label className={`${nationalityLabelClass}`}>
																	Select nationalities...
																</label>
																<Select
																	placeholder="Select nationalities..."
																	value={formValues.nationalities}
																	options={options.nationalities}
																	onChange={nationalityChange}
																	isMulti={true}
																	isDisabled={
																		formValues.nationality_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
									{formValues.nationality_match == 1 && (
										<div className="col-lg-12">
											<div className="profile-form-group mobile-appslist-outer add-target-first-selection">
												<div className="input-field static-label mb-0">
													<label>Quick selection</label>
													<div className="mobile-apps-list-wrap">
														<span
															className={getFastClass("Emirati")}
															onClick={selectFast("Emirati")}
														>
															Emirati
														</span>
														<span
															className={getFastClass("Saudi")}
															onClick={selectFast("Saudi")}
														>
															Saudi
														</span>
														<span
															className={getFastClass("East Asian")}
															onClick={selectFast("East Asian")}
														>
															East Asian
														</span>
														<span
															className={getFastClass("South Asian")}
															onClick={selectFast("South Asian")}
														>
															Asian Expats
														</span>
														<span
															className={getFastClass("Arab Expats")}
															onClick={selectFast("Arab Expats")}
														>
															Arab Expats
														</span>
														<span
															className={getFastClass("Europeans")}
															onClick={selectFast("Europeans")}
														>
															Europeans
														</span>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div id="repaetinfobox3" className="target-group-info-repeat repaetinfobox3">
								<h3>Nationalities</h3>
								<p>
									We have a diverse panel of participants. Choose the nationalities
									you would like to target.{" "}
								</p>
								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox3");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox3");
									}}
								>
									See less
								</div>
							</div>
						</div>
						<div className="target-group-data-repeat tg-highest-level-edu">
							<div className="targer-group-left-wrap">
								<div className="row">
									<div className="col-lg-12">
										<label className="check-switch">
											<input
												type="checkbox"
												checked={formValues.education_match}
												onChange={(event) =>
													toggleCheck(
														"education_match",
														event.currentTarget.checked ? 1 : 0
													)
												}
											/>
											<span className="check-slider"></span>
											<p className="big-label-text">
												Highest level of education
											</p>
										</label>
									</div>
									{formValues.education_match == 1 && (
										<div className="col-lg-6">
											<div className="target-form-wrap">
												<div className="target-group-col-data">
													<div className="profile-form-group">
														<div className="input-field static-label mb-0">
															<label className={`${educationLabelClass}`}>
																Select levels of education...
															</label>
															<Select
																isClearable
																placeholder={"Select levels of education..."}
																value={formValues.education}
																options={options.education}
																onChange={(item) => {
																	if (item == null) {
																		setEducationLabelClass("inactive_label");
																	} else {
																		setEducationLabelClass("active_label");
																	}
																	setFormValue("education", item);
																}}
																isDisabled={
																	formValues.education_match == 1 ? false : true
																}
																components={{DropdownIndicator}}
															/>
															{/* <SearchableDropdown
                                  name="education_level"
                                  title="Select levels of education..."
                                  searchable={[
                                    "Search for education level",
                                    "No matching education level",
                                  ]}
                                  list={options.education}
                                  onChange={(item) => {
                                    if (item == null) {
                                      setEducationLabelClass("inactive_label");
                                    } else {
                                      setEducationLabelClass("active_label");
                                    }
                                    setFormValue("education", item);
                                  }}
                                  ref={educationSelect}
                                /> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div id="repaetinfobox4" className="target-group-info-repeat repaetinfobox4">
								<h3>Education</h3>
								<p>
									Select the minimum education level you require for your
									participants.
								</p>

								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox4");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox4");
									}}
								>
									See less
								</div>
							</div>
						</div>
						<div className="target-group-data-repeat tg-confidence-level-tec">
							<div className="targer-group-left-wrap">
								<div className="row">
									<div className="col-lg-12">
										<label className="check-switch">
											<input
												type="checkbox"
												checked={formValues.technology_match}
												onChange={(event) =>
													toggleCheck(
														"technology_match",
														event.currentTarget.checked ? 1 : 0
													)
												}
											/>
											<span className="check-slider"></span>
											<p className="big-label-text">
												Confidence with technology
											</p>
										</label>
									</div>
									{formValues.technology_match == 1 && (
										<>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label mb-0">
																<label className={`${hoursLabelClass}`}>
																	Average time spent online per day
																</label>
																<Select
																	isClearable
																	placeholder={
																		"Average time spent online per day"
																	}
																	value={formValues.hours_spend_online}
																	options={options.hours_spend_online}
																	onChange={(item) => {
																		if (item == null) {
																			setHoursLabelClass("inactive_label");
																		} else {
																			setHoursLabelClass("active_label");
																		}
																		setFormValue("hours_spend_online", item);
																	}}
																	isDisabled={
																		formValues.technology_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label mb-0">
																<label className={`${deviceLabelClass}`}>
																	Select favorite device...
																</label>
																<Select
																	placeholder={"Select favorite device..."}
																	value={formValues.devices}
																	options={options.devices}
																	onChange={(items) => {
																		items.length > 0
																			? setDeviceLabelClass("active_label")
																			: setDeviceLabelClass("inactive_label");
																		setFormValue("devices", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.technology_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label mb-0">
																<label className={`${appLabelClass}`}>
																	Apps used
																</label>
																<Select
																	placeholder={"Apps used"}
																	value={formValues.apps}
																	options={options.apps}
																	onChange={(items) => {
																		items.length > 0
																			? setAppLabelClass("active_label")
																			: setAppLabelClass("inactive_label");
																		setFormValue("apps", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.technology_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
							<div id="repaetinfobox5" className="target-group-info-repeat repaetinfobox5">
								<h3>Technology</h3>
								<p>Choose participants with different technical competencies.</p>

								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox5");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox5");
									}}
								>
									See less
								</div>
							</div>
						</div>
						<div className="target-group-data-repeat tg-emp-status">
							<div className="targer-group-left-wrap">
								<div className="row">
									<div className="col-lg-12">
										<label className="check-switch">
											<input
												type="checkbox"
												checked={formValues.employment_match}
												onChange={(event) =>
													toggleCheck(
														"employment_match",
														event.currentTarget.checked ? 1 : 0
													)
												}
											/>
											<span className="check-slider"></span>
											<p className="big-label-text">Employment status</p>
										</label>
									</div>
									{formValues.employment_match == 1 && (
										<>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label mb-0">
																<label className={`${employmentLabelClass}`}>
																	Select curent employment status....
																</label>
																<Select
																	placeholder={
																		"Select curent employment status..."
																	}
																	value={formValues.employment}
																	options={options.employment}
																	onChange={(items) => {
																		setEmploymentLabelClass(
																			items.length > 0
																				? "active_label"
																				: "inactive_label"
																		);
																		setFormValue("employment", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.employment_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label mb-0">
																<label className={`${industryLabelClass}`}>
																	Select industry....
																</label>
																<Select
																	placeholder={"Select industry...."}
																	value={formValues.industry}
																	options={options.industry}
																	onChange={(items) => {
																		setIndustryLabelClass(
																			items.length > 0
																				? "active_label"
																				: "inactive_label"
																		);
																		setFormValue("industry", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.employment_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label mb-0">
																<label className={`${departmentLabelClass}`}>
																	Select department....
																</label>
																<Select
																	placeholder={"Select department...."}
																	value={formValues.department}
																	options={options.department}
																	onChange={(items) => {
																		setDepartmentLabelClass(
																			items.length > 0
																				? "active_label"
																				: "inactive_label"
																		);
																		setFormValue("department", items);
																	}}
																	isMulti={true}
																	isDisabled={
																		formValues.employment_match == 1
																			? false
																			: true
																	}
																	components={{DropdownIndicator}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
							<div id="repaetinfobox6" className="target-group-info-repeat repaetinfobox6">
								<h3>Employment</h3>
								<p>Choose different employment types for your participants. </p>


								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox6");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox6");
									}}
								>
									See less
								</div>
							</div>
						</div>
						<div className="target-group-data-repeat tg-earning-status">
							<div className="targer-group-left-wrap border-0">
								<div className="row">
									<div className="col-lg-12">
										<label className="check-switch">
											<input
												type="checkbox"
												checked={formValues.earnings_match}
												onChange={(event) =>
													toggleCheck(
														"earnings_match",
														event.currentTarget.checked ? 1 : 0
													)
												}
											/>
											<span className="check-slider"></span>
											<p className="big-label-text">Household</p>
										</label>
									</div>
									{formValues.earnings_match == 1 && (
										<>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="profile-form-group">
															<div className="input-field static-label">
																<label>Number of members in household</label>
																<div className="checkboxes radio_btn_class gender_btn  d-flex">
																	<label htmlFor="All" className="mt-0">
																		<input
																			id="All"
																			disabled={
																				formValues.earnings_match == 1
																					? false
																					: true
																			}
																			type="radio"
																			name="income"
																			checked={
																				formValues.household_members_condition ==
																					"All"
																					? true
																					: false
																			}
																			value="All"
																			className="hidden radio_btn_input"
																			onChange={(e) =>
																				setFormValue(
																					"household_members_condition",
																					"All"
																				)
																			}
																		/>
																		<span>
																			<img
																				className="empty-fill-icon"
																				src={
																					process.env.REACT_APP_URL +
																					"images/Polygon-blank.svg"
																				}
																			/>
																			<img
																				className="fill-icon"
																				src={
																					process.env.REACT_APP_URL +
																					"images/pol-fill.svg"
																				}
																			/>
																		</span>
																		<p>All</p>
																	</label>
																	<label htmlFor="minimum" className="mt-0">
																		<input
																			id="minimum"
																			disabled={
																				formValues.earnings_match == 1
																					? false
																					: true
																			}
																			type="radio"
																			name="income"
																			checked={
																				formValues.household_members_condition ==
																					"minimum"
																					? true
																					: false
																			}
																			value="minimum"
																			className="hidden radio_btn_input"
																			onChange={(e) =>
																				setFormValue(
																					"household_members_condition",
																					"minimum"
																				)
																			}
																		/>
																		<span>
																			<img
																				className="empty-fill-icon"
																				src={
																					process.env.REACT_APP_URL +
																					"images/Polygon-blank.svg"
																				}
																			/>
																			<img
																				className="fill-icon"
																				src={
																					process.env.REACT_APP_URL +
																					"images/pol-fill.svg"
																				}
																			/>
																		</span>
																		<p>Minimum</p>
																	</label>
																	<label htmlFor="upto" className="mt-0">
																		<input
																			id="upto"
																			disabled={
																				formValues.earnings_match == 1
																					? false
																					: true
																			}
																			type="radio"
																			name="income"
																			checked={
																				formValues.household_members_condition ==
																					"upto"
																					? true
																					: false
																			}
																			value="upto"
																			className="hidden radio_btn_input"
																			onChange={(e) =>
																				setFormValue(
																					"household_members_condition",
																					"upto"
																				)
																			}
																		/>
																		<span>
																			<img
																				className="empty-fill-icon"
																				src={
																					process.env.REACT_APP_URL +
																					"images/Polygon-blank.svg"
																				}
																			/>
																			<img
																				className="fill-icon"
																				src={
																					process.env.REACT_APP_URL +
																					"images/pol-fill.svg"
																				}
																			/>
																		</span>
																		<p>Up to</p>
																	</label>
																</div>
																<div className="qty-row-wrap">
																	<button
																		type="button"
																		className="sub"
																		onClick={minusPeople}
																	>
																		<img
																			src={
																				process.env.REACT_APP_URL +
																				"images/less-icon.svg"
																			}
																			alt="img"
																		/>
																	</button>
																	<input
																		type="number"
																		disabled={
																			formValues.earnings_match == 1
																				? false
																				: true
																		}
																		value={formValues.people}
																		onChange={changePeople}
																		min="0"
																		max="10"
																	/>
																	<button
																		type="button"
																		className="add"
																		onClick={addPeople}
																	>
																		<img
																			src={
																				process.env.REACT_APP_URL +
																				"images/plus-icon.svg"
																			}
																			alt="img"
																		/>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="target-form-wrap">
													<div className="target-group-col-data">
														<div className="input-field static-label mb-0">
                                                            <label className={`${incomeLabelClass}`}>
                                                                Household income $ per month
                                                            </label>
                                                            <Select
                                                                isClearable
                                                                name="income_slab"
                                                                title="Household income $ per month"
                                                                placeholder="Household income $ per month"
                                                                options={options.income_levels}
                                                                onChange={(items) => {

																	setIncomeLabelClass(
																		items.length > 0
																			? "active_label"
																			: "inactive_label"
																	);
																	setFormValue("income_slab", items);
                                                                    /*if (item == null) {
                                                                        setIncomeLabelClass("inactive_label");
                                                                        
                                                                        setFormValue("income_slab", null);
                                                                    } else {
                                                                        setIncomeLabelClass("active_label");
                                                                        setFormValue("income_slab", item.value);
                                                                    }*/
                                                                   
                                                                }}
																isMulti={true}
                                                                value={formValues.income_slab}
																components={{DropdownIndicator}}
                                                            />
                                                        </div>
														{/*<div className="input-field static-label mb-0">
															<label>Household income $ per </label>
															<div className="checkboxes radio_btn_class gender_btn  d-flex">
																<label htmlFor="Yearly" className="mt-0">
																	<input
																		id="Yearly"
																		disabled={
																			formValues.earnings_match == 1
																				? false
																				: true
																		}
																		type="radio"
																		name="term"
																		checked={
																			formValues.term == "Yearly" ? true : false
																		}
																		value="Yearly"
																		className="hidden radio_btn_input"
																		onChange={(e) =>
																			setFormValue("term", "Yearly")
																		}
																	/>
																	<span>
																		<img
																			className="empty-fill-icon"
																			src={
																				process.env.REACT_APP_URL +
																				"images/Polygon-blank.svg"
																			}
																		/>
																		<img
																			className="fill-icon"
																			src={
																				process.env.REACT_APP_URL +
																				"images/pol-fill.svg"
																			}
																		/>
																	</span>
																	<p>Year</p>
																</label>
																<label htmlFor="Monthly" className="mt-0">
																	<input
																		id="Monthly"
																		disabled={
																			formValues.earnings_match == 1
																				? false
																				: true
																		}
																		type="radio"
																		name="term"
																		checked={
																			formValues.term == "Monthly"
																				? true
																				: false
																		}
																		value="Monthly"
																		className="hidden radio_btn_input"
																		onChange={(e) =>
																			setFormValue("term", "Monthly")
																		}
																	/>
																	<span>
																		<img
																			className="empty-fill-icon"
																			src={
																				process.env.REACT_APP_URL +
																				"images/Polygon-blank.svg"
																			}
																		/>
																		<img
																			className="fill-icon"
																			src={
																				process.env.REACT_APP_URL +
																				"images/pol-fill.svg"
																			}
																		/>
																	</span>
																	<p>Month</p>
																</label>
															</div>
														</div>
														<MultiRangeSlider
															disabled={
																formValues.earings_match == 1 ? true : false
															}
															min={options.min_income}
															max={options.max_income}
															step={1}
															ruler={false}
															label={false}
															preventWheel={false}
															minValue={formValues.min_income}
															maxValue={formValues.max_income}
															onInput={(e) => {
																setFormValue("min_income", e.minValue);
																setFormValue("max_income", e.maxValue);
															}}
														/>
														<div className="gender-range-data">
															<span>
																<b> {nFormatter(formValues.min_income)}</b>
															</span>
															<span>
																<b>{nFormatter(formValues.max_income)}</b>
															</span>
														</div>*/}
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
							<div id="repaetinfobox7" className="target-group-info-repeat repaetinfobox7">
								<h3>Household</h3>
								<p>
									Choose from participants with specific household numbers and income
									levels.
								</p>


								<div
									className="seemorebox-sidebar"
									onClick={() => {
										showmore("repaetinfobox7");
									}}
								>
									See more
								</div>
								<div
									className="seelessbox-sidebar"
									onClick={() => {
										showless("repaetinfobox7");
									}}
								>
									See less
								</div>
							</div>
						</div>
						<div className="target-group-data-repeat">
							<div className="targer-group-left-wrap  border-0">
								<div className="delete-res-profile edit-target-grp-btns d-flex justify-content-between align-items-center target-group-bottom-btn" >
									<span
										className="delete-account-btn position-relative"
										onClick={() => {
											setConfirmModal({
												open: true,
												confirm_message: "Are you sure?",
												target_id: target_group_id,
											});
										}}
									>
										Delete Target Group{" "}
										<img
											src={process.env.REACT_APP_URL + "images/trash.svg"}
											alt="trash-icon"
										/>
									</span>
									<div className="top-buttons-wdropd auto-width-button">
										<button
											type="button"
											onClick={(e) => {
												e.preventDefault();
												setModal({
													modalHeading: "Email Sent",
													modalMessage:
														"We just sent an email! Click on the link in the email to generate a new password.",
													modalOpen: true,
												});
											}}
											className="btn form-btn secondary-btn"
										>
											Cancel
										</button>
										<button type="submit" className="btn form-btn">
											Save
											{formLoading && (
												<i
													className="fa fa-spinner fa-spin"
													aria-hidden="true"
													style={{ marginLeft: "5px" }}
												></i>
											)}
										</button>
									</div>
								</div>
								<ConfirmModal
									confirm_message={confirmModal.confirm_message}
									openModal={confirmModal.open}
									close={() => {
										setConfirmModal({ ...confirmModal, open: false });
									}}
									confirm={() => {
										setConfirmModal({ ...confirmModal, open: false });
										deleteTargetGroup(confirmModal.target_id);
									}}
								/>
							</div>
							<div className="target-group-info-repeat pt-0">
								<h3>Do you have additional requirements?</h3>
								<p>
									Tell us your project audience and demographic requirements and
									we’ll source the right participants for you.
								</p>
								<Link to={'/researcher/support/contact?reason=Help with recruitment'} className="create-btn">
									SEND REQUEST{" "}
									<span>
										<img
											src={process.env.REACT_APP_URL + "images/arrow-right.png"}
											alt="img"
										/>
									</span>
								</Link>
							</div>
						</div>
						<div
							className="target-group-right-side"
							style={{ display: "none" }}
						>
							<div className="target-group-info-repeat">
								<h3>Demographics</h3>
								<p>
									Choose the age range and gender split of your target audience.
								</p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Nationalities</h3>
								<p>
									We have a diverse panel of participants. Chosoe the nationalities
									you would like to target.{" "}
								</p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Residency</h3>
								<p>
								Choose participants from certain countries and regions.
								</p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Education</h3>
								<p>
									Select the minimum education level you require for your
									participants.{" "}
								</p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Technology</h3>
								<p>Choose participants with different technical competencies. </p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Employment</h3>
								<p>Choose participants of different employment types and statuses.</p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Household</h3>
								<p>
									Choose from participants with specific household numbers and income
									levels.
								</p>
							</div>
							<div className="target-group-info-repeat">
								<h3>Do you have additional requirements?</h3>
								<p>
									Tell us your project audience and demographic requirements and
									we’ll source the right participants for you.
								</p>
								<a href="https://userq.com/contact-us/" target={"_blank"}  className="create-btn">
									SEND REQUEST{" "}
									<span>
										<img
											src={process.env.REACT_APP_URL + "images/arrow-right.png"}
											alt="img"
										/>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</form>
			<Modal
				show={modal.modalOpen}
				centered
				className="fade custom-modal-wrap leave-page-modal-wrap"
			>
				<Modal.Body className="p-0">
					<div className="modal-inner-text">
						<button
							type="button"
							className="close"
							onClick={() => {
								setModal({
									modalHeading: null,
									modalMessage: null,
									modalOpen: false,
								});
							}}
							aria-label="Close"
						>
							<span aria-hidden="true">
								<img
									src={process.env.REACT_APP_URL + "images/cross.svg"}
									alt="cross"
								/>
							</span>
						</button>
						<div className="create-targetgroup-data-wrap">
							<h2 className="h2">Leave page?</h2>
							<p>
								You haven’t saved the changes on your target group. Do you want
								to leave this page without saving?
							</p>
						</div>
						<div className="create-project-form-btn d-block">
							<button
								type="button"
								className="btn form-btn w-100"
								onClick={() => {
									saveTargetGroup("save_exit");
								}}
							>
								SAVE &amp; EXIT
							</button>
							<button
								type="button"
								onClick={() => {
									navigate(-1);
								}}
								className="btn form-btn secondary-btn b-block w-100"
							>
								EXIT WITHOUT SAVING
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<RenameModal
				title={title ? title : ""}
				open={rename.open}
				heading={"Rename target group"}
				close={() => {
					setRename({ open: false, isLoading: false });
					setTargetId(null);
					setTitle(null);
				}}
				language={"en"}
				isLoading={rename.isLoading}
				confirm={(title) => {
					renameTargetGroup(targetId, title);
				}}
			/>
			<ConfirmModal
				confirm_message={confirmModal.confirm_message}
				confirm_btn_title={confirmModal.confirm_btn_title}
				confirm_title={confirmModal.confirm_title}
				openModal={confirmModal.open}
				close={() => {
					setConfirmModal({ ...confirmModal, open: false });
				}}
				confirm={() => {
					setConfirmModal({ ...confirmModal, open: false });
					deleteTargetGroupApi(confirmModal.target_id);
				}}
			/>
			<CtaActionModal close_action={cta_modal.close_action} open={cta_modal.open} title={cta_modal.title} message={cta_modal.message} />
		</LayoutResearcherFullWidth>
	);
}
