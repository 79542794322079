import React, { useEffect, useState } from "react";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Helmet } from "react-helmet";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Button } from "../../../themes/userq/Button/Button";
import "./workspace-no-access.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import {
    getWorkspacesListService,
    getWorkspaceService,
    acceptWorkInviteDashboardService,
} from "../../../../services/workspaces";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon.js";
import { showToast } from "../../../../lib/helpers.js";

const ProjectNoAccess = () => {
    let { workspaceid } = useParams();

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        
    }, []);
    

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass={"no-access-page-hold"}
        >
            <Helmet>
                <title>Access denied | UserQ</title>
            </Helmet>

            {false ? (
                <>
                    <div className="workspace-page-loader">
                        <div
                            className={
                                "page-loader accountloading-box creditloading-box"
                            }
                        >
                            <LoadingIcon />
                        </div>
                    </div>
                </>
            ) : (
                <div className="no-access-page-wrap">
                    <div className="no-access-inner-wrap  mb-32">
                        <div className="tester-disbled-account-notice mb-60">
                            <Icon
                                colorClass={"danger-red-200"}
                                value={"blocked"}
                                size="large"
                            />

                            <Text type={"subtitle-1"}>
                            It seems that you no longer have access to this project. 
                            </Text>
                            <Text type={"body-text-2"}>
                                Please get in touch with your team or{" "}
                                <a
                                    className="faq-support-link disabled-support-link"
                                    href={
                                        process.env.REACT_APP_URL +
                                        "r/support/contact"
                                    }
                                    target="_blank"
                                >
                                    UserQ support
                                </a>{" "}
                                if you are unsure why this has occurred.
                            </Text>
                        </div>

                        
                        <div className="back-dash-btn">
                            <Button
                                type="primary"
                                label="Back to dashboard"
                                size={"large"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                        "/wsp/"+workspaceid
                                    );
                                }}
                            />
                        </div>
                    
                    </div>
                </div>
            )}
        </LayoutResearcher>
    );
};
export default ProjectNoAccess;
