import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { ToastMessage } from "../../../../themes/userq/ToastMessage/ToastMessage";
import { Button } from "../../../../themes/userq/Button/Button";
import { Input } from "../../../../themes/userq/Input/Input";
import { Icon } from "../../../../themes/userq/Icon/Icon";
import { Text } from "../../../../themes/userq/Text/Text";

export default function LeaveWorkspaceModal({
    openModal,
    confirm_title,
    confirm_btn_title,
    cancel_btn_title,
    close,
    confirm,
    btnLoading,
    confirm_title_class,
    fullName,
}) {
    const closeModal = () => {
        if (!btnLoading) close();
    };

    const wrapperRef = useRef(null);
    const [formValues, setFormValues] = useState({ signature: "" });
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setFormValues({ signature: "" });
    }, [openModal]);

    const handleClick = () => {
        const signature = formValues.signature.toLowerCase();
        const nameToCompare = fullName?.toLowerCase();

        if (!nameToCompare || signature !== nameToCompare) {
            setErrorMessage(
                <ToastMessage
                    type={"error"}
                    message={
                        "Please enter your full name to leave the workspace"
                    }
                    closable={false}
                />
            );
            return;
        }

        leaveWorkspace();
    };

    const leaveWorkspace = () => {
        if (formValues.signature.toLowerCase()) {
            confirm(formValues.signature.toLowerCase());
        }
    };

    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap confirmation-modal-wrap leave-workspace-modal-wrap"
        >
            <Modal.Body ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <h3
                            className={`heading h3 ${
                                confirm_title_class || ""
                            }`}
                        >
                            {confirm_title}
                        </h3>
                        <button
                            type="button"
                            className="close"
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                        </button>
                    </div>
                    <Text
                        type="body-text-3"
                        cssClasses={"mb-12 gray-color"}
                        fontWeight="medium-font"
                    >
                        By continuing, you will lose access to all tests and
                        credits.
                    </Text>
                    <Text
                        type="body-text-3"
                        cssClasses={"gray-color"}
                        fontWeight="medium-font"
                    >
                        Kindly confirm by signing your full name:
                    </Text>
                    <div className="add-name-input mt-16">
                        <Input
                            cssClasses={"w-100"}
                            type="text"
                            label="Your full name"
                            value={formValues.signature}
                            onChange={(e) =>
                                setFormValues({ signature: e.target.value })
                            }
                            readOnly={btnLoading}
                        />
                    </div>
                    {errorMessage && (
                        <div className="mt-12">{errorMessage}</div>
                    )}
                    <div className="confirm-buttons-wrap">
                        <Button
                            type="primary"
                            size="large"
                            label={confirm_btn_title || "OK"}
                            onClick={handleClick}
                            microLoading={btnLoading}
                            state={
                                fullName
                                    ? !formValues.signature ||
                                      formValues.signature.toLowerCase() !==
                                          fullName.toLowerCase()
                                        ? "disabled"
                                        : ""
                                    : !formValues.signature
                                    ? "disabled"
                                    : ""
                            }
                        />
                        <Button
                            type="secondary"
                            size="large"
                            label={cancel_btn_title || "Cancel"}
                            onClick={closeModal}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
