import { ReactSession  as ReactJsSession } from "react-client-session";
import AES from 'crypto-js/aes';
import CryptoJS from "crypto-js";
import { isJsonString } from "./helpers";

const  set = (key, value)=>{
    var save_value = value;

    if(typeof value==='object'){
        save_value = JSON.stringify(value);
    }
    if(typeof value==='number'){
        save_value = "typenumber:"+value;
    }
    if(!save_value || save_value==''){
        ReactJsSession.set(key,save_value); 
    } else {
        var b64 = CryptoJS.AES.encrypt(save_value, process.env.REACT_APP_ENCKEY).toString();

        ReactJsSession.set(key,b64);
    }
}
const get = (key)=>{


    let cipherText = ReactJsSession.get(key);

    if(cipherText==null){
        return null;
    }
    if(cipherText==""){
        return "";
    }
    try{
    
        var decrypt = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_ENCKEY);

        var plaintext = decrypt.toString(CryptoJS.enc.Utf8);

        if(isJsonString(plaintext)){
        
            return JSON.parse(plaintext);
        } else {
            if (plaintext.includes("typenumber:")) {
                plaintext.replace("typenumber:","");
                return parseFloat(plaintext);
            }
            return plaintext;
        }
    } catch(err) {
        return null;
    }
}
const setStoreType  = (storetype) => {
    ReactJsSession.setStoreType(storetype);
}
export let ReactSession = {
    "set":set,
    "get":get,
    "setStoreType":setStoreType
};
    
    
    
