import React, { useState, useEffect } from 'react';
import { preventClick } from '../../../../lib/helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../../lib/secure_reactsession';
import { FormattedMessage } from 'react-intl';
import { LoadingIcon } from '../../../loader/loadingIconNew';
import { Icon } from '../../../themes/userq/Icon/Icon';
import { Text } from '../../../themes/userq/Text/Text';
import { Logo } from '../../../themes/userq/Logo/Logo';
import Cookies from 'js-cookie';
import { acceptInvitationCodeService } from '../../../../services/workspaces';
import { Helmet } from 'react-helmet';

export default function VerifyWorkspaceInviteLink() {

    const [invite_code, setInviteCode] = useState('');

    

    const navigate = useNavigate();

    const [isEmailVerified, setEmailVerified] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const param = searchParams.get('invite_code'); 
        if (param) {
            setInviteCode(param);
        } else{
            navigate("/404");
        }
        verifyWorkspaceInviteLink(param);

      }, []);

    const verifyWorkspaceInviteLink = (invite_code) => {
        
        setLoading(true);

        setErrorMessage(null);

        var verification_code = invite_code;

        var token = ReactSession.get('token');

        acceptInvitationCodeService({invite_code: verification_code}, token).then((response) => {
        
            setLoading(false);

            if (response.success) {
              
                let user = ReactSession.get("user");

                if(user){
                    navigate("/wsp/"+response.workspace.id);
                } else {
                    navigate("/researcher/login?redirect_to=/wsp/"+response.workspace.id);
                }

            } else {
                if(response.message=="Not found"){
                    navigate("/404");
                }
                setErrorMessage(response.message);
            }
        });
        
        
        
        
    }
    return (
        <div className="login-page-wrapper">
            <Helmet>
                <title>Accept invitation | UserQ</title>
            </Helmet>
            {isLoading &&
                <div  className={'dashboard-filter-loader verifyresarcherbox'} style={{height:"100vh"}}>
                    <LoadingIcon />
                    <div>
                    <Text type={"subtitle-1"} fontWeight={"medium-font"}>We are verifying your invitation...</Text>
                    </div>
                </div>
            }
            {!isLoading &&
            <>
            <div className="researcher-registerheader login-page-header">
                <div className="container">
                    <div className="login-hdr-left">
                        <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                            
                            <Logo theme={"black"} background={"white"} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        {isLoading &&
                            <div  className={'dashboard-filter-loader verifyresarcherbox'} >
                                <LoadingIcon />
                                <div>
                                <Text type={"subtitle-1"} fontWeight={"medium-font"}>We are accepting your invitation...</Text>
                                </div>
                            </div>
                        }
                        {/*!isLoading &&
                            <div>
                                {isEmailVerified &&
                                    <div>
                                        <h1 className="form-heading">Successfully Verified!</h1>
                                        <div className="login-form-inner-data">
                                            <div className="reset-btn-wrap">
                                                <Link to="/login"  className="btn btn-block form-btn">CONTINUE TO DASHBOARD</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!isEmailVerified &&
                                    <div>
                                        <div className={`no-participants-data no-questions-data no-questions-added`}>
                                            <div className="no-page-data-wrap" style={{marginTop:"80px"}}>
                                                <div className="no-data-icon">
                                                    <Icon value={"exclamation-gray"} size={"large"} />
                                                </div>
                                                <Text type={"subtitle-1"} fontWeight="medium-font">
                                                    It looks like this page doesn’t exist.
                                                </Text>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                            */}
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    )
}