import React from "react";
export const Footer = () => {
    return (
        <footer className="footer">
        <div className="container">
          <div className="footer-inner-data body-text body-text-3 medium-font ">
             <div  className="footer-left-data none-for-mobile">
               &copy; {new Date().getFullYear()} USERQ. All rights reserved.
             </div>
      
            <div  className="footer-right-data">
               <a href="https://userq.com/terms-of-use/" target={"_blank"}>Terms of use</a>
               <a href="https://userq.com/privacy-policy/" target={"_blank"}>Privacy policy</a>
             </div>
             <div  className="footer-left-data none-for-desktop">
               &copy; {new Date().getFullYear()} USERQ. All rights reserved.
             </div>
          </div>
        </div>
      </footer>
    );
};