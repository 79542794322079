import { useNavigate } from 'react-router-dom';
import React, {useEffect,useState} from "react";
import LayoutResearcher from '../../layouts/layout_researcher.js';
import { getPlansService, changePlanService } from '../../../services/plans';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import toast from 'react-hot-toast';
import CommonPlans from './common-plans.js';
import {  getCurrentPlanService } from '../../../services/user.js';
import ConfirmModal from "../../dialog/confirm_modal";
import {atom, useResetRecoilState} from 'recoil';
import {LoadingIcon} from "../../loader/loadingIcon";

export default function Plans() {

    const navigate  = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [plans, setPlans] = useState([]);

    const [term, setTerm] = useState(ReactSession.get("term") ? ReactSession.get("term") :"Monthly" );

    const [currentPlan, setCurrentPlan] = useState({plan_id:0, term:"month"});

    const [confirmModal, setConfirmModal] = useState({open:false, plan:null});

    const activePlanState = atom({
        "key":"active_plan",
        "default":null
    })
    const reset = useResetRecoilState(activePlanState);
    
    const getCurrentPlan = () => {
		let token = ReactSession.get("token");

        setLoading(true);

        getCurrentPlanService(token).then(response=> {
            setLoading(false);

            if(response.success){

                setCurrentPlan(response.plan);

                if(response.plan.term=="month"){
                    setTerm("Monthly");
                } else {    
                    setTerm("Yearly");
                }

            } else {
				

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
			}
        });
	}
    const getPlans = () => {
		let token = ReactSession.get("token");

        setLoading(true);

        getPlansService(token).then(response=> {
            setLoading(false);

            if(response.success){

                

                setPlans(response.plans);
                
                getCurrentPlan();

            } else {
				

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
			}
        });
	}
    useEffect(() => {

        document.title = process.env.REACT_APP_NAME+" - Plans";

        getPlans();

        reset();

    }, []);

    const selectPlan = (plan) => {

        return (e)=> {
            
            if(e){
                e.preventDefault();
            }
            var confirm_message = "";
            if(plan.plan_category=="FREE"){
                confirm_message = "Are you sure you want to switch to FREE plan. You won't be billed for next cycle";
            } else {
                confirm_message =
                  "Ready to switch to the " + term + " PRO plan?";
            }
            
            setConfirmModal({open: true, confirm_title:"Change plan", confirm_btn_title:"Change", confirm_message:confirm_message, plan:plan});
        }   
        
    }
    const updatePlan = (plan) => {
        setLoading(true);
    
    
        var token = ReactSession.get('token');

        changePlanService({plan_id:plan.id, term: term}, token).then(response=> {

            setLoading(false);

            reset();

            if(response.success){

                if(response.requires_action){
                    ReactSession.set("selected_plan",plan);

                    ReactSession.set("term",term);

                    navigate("/researcher/billing/subscribe");
                } else {
                    var id = toast((
                        <div className='toastinner'>
                            {response.message}
                            <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                        </div>), {
                        className: 'successtoast',
                        position: 'bottom-center'
                    }); 
                    navigate("/r/billing-information");
                }
            
            } else {
                

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
                
            }
        });
    }
    
    return (
        <LayoutResearcher skipCheck={true} extendedFooter={false}>   
            <div className="dashboard-data-wrapper">
                {isLoading &&
                <LoadingIcon/>
                }
                {!isLoading && 
                <>
                    <CommonPlans plans={plans} currentPlan={currentPlan} term={term} selectPlan={(plan)=>selectPlan(plan)} setTerm={(term)=>setTerm(term)} />
                    
                </>
                }
            </div>
            <ConfirmModal confirm_message={confirmModal.confirm_message} confirm_btn_title={confirmModal.confirm_btn_title} confirm_title={confirmModal.confirm_title} openModal={confirmModal.open} close={()=>{ setConfirmModal({...confirmModal, open:false });   } } confirm={()=>{ setConfirmModal({...confirmModal, open:false });  updatePlan(confirmModal.plan);} } />
        </LayoutResearcher>
    )
}