import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import Layout from "../../layouts/layout";
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
// import Accordian from '../../Accordian';
import { LayoutTester } from "../../themes/userq/Layouts/layout_tester";
import { Text } from '../../themes/userq/Text/Text';
import { Icon } from '../../themes/userq/Icon/Icon';
import TesterSupportNavigation from './tester_support_navigation';
import { Accordian } from '../../themes/userq/Accordion/Accordion';



export default function TesterSupport() {

    const navigate = useNavigate();
    const [faqSections, setFaqSections] = useState({ 'general': true, navigating: false, privacy: false, becomingTester: false, tests: false, takingTests: false, beingreported: false, rewards: false })

    const [accordianData, setAccordionData] = useState([
        {
            id: 1,
            open: true,
            title: 'What is UserQ?',
            info: 'UserQ is a user research platform that enables designers, managers and researchers to gain unique insights from testers in the MENA region about their digital products. You can build and publish your own tests, in English or Arabic, and easily analyse any data you collect through your UserQ account.\n' +
                '\n <br/><br/>' +
                'For testers, UserQ is a platform that pays you for your insights and opinions – it really is as simple as that.'
        },
        {
            id: 2,
            open: false,
            title: ' How does UserQ work?',
            info:
                'We bring researchers and testers together on an easily-accessible, powerful research platform. Researchers can easily create, publish and analyse tests that are filled out by real people in the MENA region (testers). Once a test goes live, the UserQ platform finds the perfect testers based on the parameters set by the researcher, and results can come in after as soon as a few minutes.\n' +
                '\n <br/><br/>' +
                'Using the UserQ platform, researchers get the invaluable insights they need to support their projects and design process in real time, and testers get paid for their time and thoughts.',
        }
    ]);

    const [accordianSecondData, setAccordionSecondData] = useState([
        {
            id: 1,
            open: true,
            title: '  Is my data secure on the UserQ platform?',
            info: 'All data that you provide in setting up your account, including private information and payment details – is safe with us. We are completely compliant with all data privacy laws in the UAE, and your security is our top priority.'
        },
        {
            id: 2,
            open: false,
            title: '   How does UserQ use my personal information?  ',
            info: 'The UserQ platform works by matching your profile to tests where researchers are looking for insights from a specific group of people. This means that we’ll use your demographic data such as gender, age or occupation to match you to the right tests. The researchers who create the tests won’t have access to this information – it’s only used by us at UserQ to make sure we send you the right tests.\n' +
                '\n <br/> <br/>' +
                'Your data is always stored securely and safely on UserQ – only you will be able to access your sensitive information such as payment details and passwords.'
        }
        
    ]);

    const [accordianThirdData, setAccordionThirdData] = useState([
        {
            id: 1,
            open: true,
            title: ' What happens if I forget my password?',
            info: 'If you forget your login details, all you have to do is click the forgot password link on the login page. You’ll receive a reset link on the email you signed up with, and choose a new password.'
        },
        {
            id: 2,
            open: false,
            title: '  I signed up as a tester, but I now want to run my own tests. How do I change my account? ',
            info: 'To run your own tests on the UserQ platform, you’ll need to have a research account with us using a different email address to your test account.'
        },
        {
            id: 3,
            open: false,
            title: ' How do I close my UserQ account?',
            info: 'If you need to close your UserQ account for any reason, it’s a simple process. Once you’re logged into your account, go to ‘Edit profile’, where you will see a ‘Delete your account’ option. We’ll delete your account, along with any personal information you’ve shared with us too.'
        }
    ]);

    const [accordianFourData, setAccordionFourData] = useState([
        {
            id: 1,
            open: true,
            title: '    What do I need to get started? ',
            info: `
                It’s easy to become a UserQ tester, and you just need to take a few steps to get started:
                <br/>
                <ul>
                    <li class="mb-2">Create a UserQ tester account on a PC or mobile device</li>
                    <li class="mb-2">Fill out your details so we can match you to the right tests</li>
                    <li class="mb-2">Wait to be selected!</li>
                </ul>`
        },
        {
            id: 2,
            open: false,
            title: '   How do I register with UserQ? ',
            info: 'Creating an account with UserQ is quick and easy – all you need to do is fill in a profile and start taking tests. You can register as a tester here.'
        },
        {
            id: 3,
            open: false,
            title: 'Do I need to verify my identity at sign-up?',
            info: 'Verifying your identity at sign-up is not a requirement for accessing or taking tests on UserQ. However, at the time of requesting a payout, you will be required to provide a valid ID document, in line with our Terms of Use. To avoid any delays or issues in the process, please ensure to provide accurate information that matches your ID document when creating your profile.'
        }
    ]);

    const [accordianFifthData, setAccordionFifthData] = useState([
        {
            id: 1,
            open: true,
            title: '   How often can I take UserQ tests?  ',
            info: 'Whenever you fit the right requirements that a researcher is looking for, we’ll send you an email or notification to let you know you’ve been selected, and you can take the test. Please note that you can only complete one test at a time – so make sure you complete the one you’re on before moving onto another.'
        },
        {
            id: 2,
            open: false,
            title: '    If I don’t complete a test, will I still be paid? ',
            info: "As a UserQ tester, you must get to the end of the test that you’re taking until you see a message that confirms that you’ve completed the test in order to be paid for your insights. <br/>Guest testers aren’t compensated for their time, but you can create an account with us at UserQ to start earning money for your insights."
        },
        {
            id: 3,
            open: false,
            title: '     How can I increase my chances of being selected for a test?  ',
            info: "Our community of researchers are actively gathering insights for a range of digital experiences, so make sure to sign-in regularly to check for tests and keep an eye out for our emails!\n" +
            '\n <br/><br/>' +
            "Additionally, it's important to maintain a high tester score. The higher your score, the more tests you will be able to see."
        }
    ]);

    const [accordianSixData, setAccordionSixData] = useState([
        // {
        //     id: 1,
        //     open: true,
        //     title: '    Why have I been reported and what are the potential consequences of being reported?',
        //     info: "If your responses are deemed poor quality or show that you have not fully engaged with the test, or your profile information appears to be inaccurate - you will be reported by a researcher or our quality assurance team.\n"+ 
        //     '\n <br/><br/>' +
        //     "Every reported case is manually verified by us and if found to be valid, your score will be lowered and in some cases, your account may be suspended or blocked.  In the case of a suspension or blocking, you will not be able to take any further tests and your payout will be frozen.\n" +
        //     '\n <br/><br/>' +
        //     "If you need any clarification regarding this or help moving forward, you can always reach out to us at <a class='faq-support-link' href='mailto:support@userq.com'>support@userq.com</a>"
            
        // },
        {
            id: 1,
            open: true,
            title: '    How can I improve my score?',
            info: `
                You can improve your rating by providing high-quality responses on future tests and even achieve a 100% rating over time. When taking a test, make sure to keep these simple tips in mind:
                <br/>
                <ul>
                    <li class="mb-2">
                        Carefully read all instructions and questions before attempting to answer or complete a task
                    </li>
                    <li class="mb-2">Take your time and avoid rushing through the test</li>
                    <li class="mb-2">Be clear and specific in your responses, provide as much detail as possible when asked to explain the reasoning behind your choice
                    </li>
                </ul>`
        },
        {
            id: 2,
            open: false,
            title: 'What is a tester score and how is it assessed?',
            info: "The tester score is an important measure of your performance as a tester. It is assessed by taking into consideration the quality of your test responses, your engagement and behaviour, and compliance with our Terms of Use. You can find your rating displayed in the profile section of your account and this will be updated regularly based on your activity on the platform. Providing inaccurate, incoherent or guessed responses will result in a negative impact on your score.\n"+
            '\n <br/><br/>' +
            "Please note that the UserQ quality assurance team manually reviews every entry and reports any tester who does not meet our standards. Failure to follow the guidelines can have a negative impact on your tester score and your account may be blocked or suspended in some cases. "
        },
        {
            id: 3,
            open: false,
            title: '    I think there is an error with the test I’m taking. What should I do?  ',
            info: "If you notice a glitch in your test or that something doesn’t seem to be working, you should get in touch with us at UserQ."
        },
        {
            id: 4,
            open: false,
            title: '    How do I take tests on UserQ? ',
            info: 'Each test you take on UserQ will be different, but you’ll find clear and easy to follow instructions on each one. All you have to do is open the test and follow along.\n' +
                '\n <br/><br/>' +
                'Remember, there are no wrong answers when taking a UserQ test – the most important thing is that you’re honest and express your opinions. Your insights are invaluable to the researchers who create the tests.'
        },
        {
            id: 5,
            open: false,
            title: '    How long does a test run for?  ',
            info: 'The researchers who create the test decide how long it runs for. That’s why it’s best that when you receive a notification for a test, you complete it as soon as you can. If you leave it for a few days, the test may end.'
        },
        {
            id: 6,
            open: false,
            title: '     How can I increase my chances of being selected for a test?  ',
            info: "Our community of researchers are actively gathering insights for a range of digital experiences, so make sure to sign-in regularly to check for tests and keep an eye out for our emails!\n" +
            '\n <br/><br/>' +
            "Additionally, it's important to maintain a high tester score. The higher your score, the more tests you will be able to see."
        },
        {
            id: 7,
            open: false,
            title: '    If I don’t complete a test, will I still be paid? ',
            info: "As a UserQ tester, you must get to the end of the test that you’re taking until you see a message that confirms that you’ve completed the test in order to be paid for your insights. <br/>Guest testers aren’t compensated for their time, but you can create an account with us at UserQ to start earning money for your insights."
        },
        {
            id: 8,
            open: false,
            title: '   How often can I take UserQ tests?  ',
            info: 'Whenever you fit the right requirements that a researcher is looking for, we’ll send you an email or notification to let you know you’ve been selected, and you can take the test. Please note that you can only complete one test at a time – so make sure you complete the one you’re on before moving onto another.'
        },
        
        
        
    ]);

    const [accordianEightData, setAccordionEightData] = useState([
        {
            id: 1,
            open: true,
            title: '    How long does it take to process my request? ',
            info: "Our team aims to fulfill payouts within 15 days of your request - pending quality check approvals. If we're experiencing a high volume of requests, it can take up to 30 days to review and process your payout."
        },
        {
            id: 2,
            open: false,
            title: 'What documentation do I need when requesting a payout?',
            info: "Along with a paypal or bank account, you will need to verify your identity before requesting a payout. You can verify your identity by following the instructions available on your dashboard. Please ensure to have a valid ID document (such as national identity card, driver’s licence etc.) on hand when beginning the verification process. It is also important to make sure that the information provided in your ID document matches with the information on your tester profile.\n"+
            '\n <br/><br/>' +
            "Additionally, your bank account or PayPal account will need to be registered under your verified name. If this is not the case, we will not be able to process a payout on your behalf."
        },
        {
            id: 3,
            open: false,
            title: '    When can I open a payout request?  ',
            info: "Once you’ve earned a minimum of $30 in your wallet, you will be eligible to request for a payout. "
        },
        {
            id: 4,
            open: false,
            title: '    Will you pay me in my local currency?  ',
            info: "You will always be paid in US dollars ($) for each test you take"
        },
        {
            id: 5,
            open: false,
            title: '   How much will I receive for each test I take? ',
            info: 'You will be paid a different amount depending on the type and length of the test you take. This information will be available to you before you start your test.'
        },
        {
            id: 6,
            open: false,
            title: '   When do I receive my rewards? ',
            info: 'Your UserQ wallet will be updated with your rewards soon after you complete a test.'
        },
        
        
        // {
        //     id: 6,
        //     open: false,
        //     title: '    Why did I get paid less than I requested?  ',
        //     info: "Each response you provide is reviewed by our quality assurance team. Poor quality, incomplete or rushed responses may not be accepted by the researcher and could result in you not getting paid. "
        // },
        
    ]);

    const [accordianSevenData, setAccordionSevenData] = useState([
        {
            id: 1,
            open: true,
            title: 'Why have I been reported?',
            info: "If your responses are deemed poor quality or show that you have not fully engaged with the test, or your profile information appears to be inaccurate - you will be reported by a researcher or our quality assurance team."
        },
        {
            id: 2,
            open: false,
            title: 'What are the consequences of being reported?',
            info: "Every reported case is manually verified by us and if found to be valid, your score will be lowered and in some cases, your account may be blocked or suspended.  In the case of a blocking or suspension, you will not be able to take any further tests and your payout will be frozen.\n" +
            '\n <br/><br/>' +
            "If you need any clarification regarding this or help moving forward, you can always reach out to us at <a class='faq-support-link' href='mailto:support@userq.com'>support@userq.com</a>"
        },
        {
            id: 3,
            open: false,
            title: 'Why has my account been blocked?',
            info: `Your account may be blocked due to one of the following reasons mentioned below:
                    <ol type="a" class="mt-2">
                        <li class="mb-2">
                            Tester score below 50%<br/>
                            Our system automatically blocks testers profiles whose scores have fallen below 50%. This means that your overall responses on tests were unsatisfactory and have been flagged by researchers over time - resulting in your score falling below 50%.
                        </li>
                        <li class="mb-2">
                            Duplicated identity<br/>
                            We frequently evaluate your profile details and activity on the platform to ensure you are in full compliance with our terms of use. If we identify that you are operating multiple accounts, we will proceed to block all accounts that could be related to such activity, as this is considered to be a direct violation of our terms of use. For guidance on how you can move forward, please get in touch with our support team at <a class='faq-support-link tc-link' href='mailto://support@userq.com' target='_blank'>support@userq.com</a>. 
                        </li>
                        <li class="mb-2">
                        Minor<br/>
                        Our platform adheres to age restrictions as outlined in our terms of use and as such, access is limited to individuals under the age of 18. If you fall in this category, we encourage you to review the terms of use for more information and explore the platform once you meet the necessary age criteria.
                        </li>
                        <li>
                        Repeated attempts to upload ID documentation<br/>
                        If you are unsuccessful in uploading your ID documentation within 2 attempts, our system will automatically impose a block on your account. You will need to wait 24 hours before you will be able to try again. 
                       <br/><br/>
                       Here are some tips you can follow when attempting to upload your ID document:
                            <ul>
                                <li class="mb-2">Ensure to upload a clear and high quality image</li>
                                <li class="mb-2">Ensure you're uploading an ID document (e.g., ID card, passport, driver's license)</li>
                                <li>Confirm that the document is valid and not expired.</li>
                            </ul>
                            <br/>
                            If you require further assistance or have any questions, please feel free to reach out to our support team for prompt help and guidance.
                        </li>
                    </ol>`
                    
        
            
        },
        {
            id: 4,
            open: false,
            title: 'What happens when my account is blocked?',
            info: "You will be restricted from accessing any further tests on UserQ and you will not be able to request a payout from your wallet."
            
        },
        {
            id: 5,
            open: false,
            title: 'How can I get my account unblocked?',
            info: "You can reach out to our <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile' target='_blank'>support team</a> for advice on how to move forward."
            
        },
    ]);

    useEffect(() => {
        document.title = "FAQs | "+process.env.REACT_APP_NAME;

    }, []);

    const checkUser = () => {
        if (ReactSession.get("token")) {
            return false;
        }
        return true;
    }

    return (
        <LayoutTester
            isLoading={false}
            isAccount={true}
            skipCheck={false}
            activeMenu={""}
            wrapClass={"rs-inner-profile-wrapper"}
        >
            <div className='page-heading-wrap accounts-page--heading'>
                <Text type="h1">Support</Text>
            </div>
            <TesterSupportNavigation activeMenu="faqs" />
            <div className='faqs-section'>
                <div className="faqs-section-left">
                    <div className='stepper-wrap test-result-nav-wrap'>
                        <ul className="test-results-navigation">
                            <li key={"general"} className={`${faqSections.general == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "general") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>General</>
                            </li>
                            <li key={"privacy"} className={`${faqSections.privacy == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "privacy") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>Privacy & security</>
                            </li>
                            <li key={"navigating"} className={`${faqSections.navigating == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "navigating") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>Navigating your account</>
                            </li>
                            <li key={"becomingTester"} className={`${faqSections.becomingTester == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "becomingTester") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>Becoming a tester</>
                            </li>
                            {/* <li key={"tests"} className={`${faqSections.tests == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "tests") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="info-white" size={"small"} /></span>
                                <>UserQ tests</>
                            </li> */}
                            <li key={"takingTests"} className={`${faqSections.takingTests == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "takingTests") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>Taking tests on UserQ</>
                            </li>
                            <li key={"beingreported"} className={`${faqSections.beingreported == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "beingreported") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>Being reported or blocked</>
                            </li>
                            <li key={"rewards"} className={`${faqSections.rewards == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "rewards") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon colorClass="gray-50-svg" value="information" size={"small"} /></span>
                                <>Your rewards</>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="faqs-section-right">
                    <div className="sp5-overview-data faq-data-wrapper">
                        <div className="accordion" id="faq">
                            {faqSections.general == true && (
                                <>
                                    <Text type="h4">General</Text>
                                    {accordianData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianData[index].open = false;
                                                } else {
                                                    accordianData[index].open = true;
                                                }
                                                setAccordionData([...accordianData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.privacy == true && (
                                <>
                                    <Text type="h4">Privacy and security</Text>
                                    {accordianSecondData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianSecondData[index].open = false;
                                                } else {
                                                    accordianSecondData[index].open = true;
                                                }
                                                setAccordionSecondData([...accordianSecondData]);
                                            }}
                                        >
                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.navigating == true && (
                                <>
                                    <Text type="h4">Navigating your account</Text>
                                    {accordianThirdData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianThirdData[index].open = false;
                                                } else {
                                                    accordianThirdData[index].open = true;
                                                }
                                                setAccordionThirdData([...accordianThirdData]);
                                            }}
                                        >
                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}


                            {faqSections.becomingTester == true && (
                                <>
                                    <Text type="h4">Becoming a tester</Text>
                                    {accordianFourData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianFourData[index].open = false;
                                                } else {
                                                    accordianFourData[index].open = true;
                                                }
                                                setAccordionFourData([...accordianFourData]);
                                            }}
                                        >
                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.tests == true && (
                                <>
                                    <Text type="h4">UserQ tests</Text>
                                    {accordianFifthData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianFifthData[index].open = false;
                                                } else {
                                                    accordianFifthData[index].open = true;
                                                }
                                                setAccordionFifthData([...accordianFifthData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.takingTests == true && (
                                <>
                                    <Text type="h4">Taking UserQ tests</Text>
                                    {accordianSixData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianSixData[index].open = false;
                                                } else {
                                                    accordianSixData[index].open = true;
                                                }
                                                setAccordionSixData([...accordianSixData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.beingreported == true && (
                                <>
                                    <Text type="h4">Being reported or blocked</Text>
                                    {accordianSevenData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianSevenData[index].open = false;
                                                } else {
                                                    accordianSevenData[index].open = true;
                                                }
                                                setAccordionSevenData([...accordianSevenData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.rewards == true && (
                                <>
                                    <Text type="h4">Your rewards</Text>
                                    {accordianEightData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianEightData[index].open = false;
                                                } else {
                                                    accordianEightData[index].open = true;
                                                }
                                                setAccordionEightData([...accordianEightData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </LayoutTester>
    )
}