import React, {useEffect, useState, useRef} from "react";
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Button } from "../Button/Button";
import {Text} from "../Text/Text";
import {CheckBox} from "../CheckBox/CheckBox";
import {ReactSession} from "../../../../lib/secure_reactsession";
import {Icon} from "../Icon/Icon";
import { Link } from "../Link/Link";

export default function ReportTesterInstructionModal({
                                                         openModal,
                                                         close,
                                                         confirm,
                                                         btnLoading
                                                     }) {
    const closeModal = () => {
        close();
    };

    const [formValues, setFormValues] = useState({  isShown: false });
    const wrapperRef = useRef(null);

    useEffect(() => {

        // reset form values when popup again open
        if(!openModal){
            setFormValues({isShown: false});
        }

    },[openModal]);


    const user = ReactSession.get("user");

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className={`fade custom-modal-wrap report-tester-instruction  confirmation-modal-wrap`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon/>
                    </div>
                    :
                    <div className="modal-inner-text">
                        <div className="modal-header-top">
                            <Text type={'h3'}>Report participants</Text>

                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                            <span aria-hidden="true">
                               <Icon value="Close" size="medium" hover={true} />
                            </span>
                            </button>
                        </div>



                        <div className={"report-tester-instruction-modal-data-wrap"}>

                            <Text type={"body-text-3"} fontWeight={"medium-font"} >
                                Before proceeding, please follow these instructions to report testers and ensure objective feedback:<br/>
                                <ol className={'download-xlsx-report-data-wrap'}>
                                    <li>Download the xlsx report of the test and analyze the full entry for each participant you are reporting.</li>
                                    <li>Select all the testers you want to report from the list and list all the violations you are reporting them for.</li>
                                    <li>Submit your reporting request once you are ready.</li>
                                    <li>We will review your request based on our guidelines and either approve or reject it. If your request is approved, you will receive a refund.</li>
                                </ol>
                                Please note that:
                                <ul className={'submit-request-data-wrap'}>
                                    <li>You can only submit the request once for each test.</li>
                                    <li>You can only report UQ testers, and not unknown testers, from the shared link.</li>
                                    <li>For the approved requests, results will be hidden from the test report.</li>
                                </ul>
                            </Text>

                            <div className="guide-report-link mt-20">
                                <Link target={"_blank"} url={process.env.REACT_APP_URL+"r/reporting-guidelines"} className="link-text">Read more about the reporting guidelines</Link>
                            </div>

                        </div>

                        <div className={"report-tester-instruction-checkbox mb-32"}>
                            <CheckBox
                                id="terms_accept_toggle"
                                checked={formValues.isShown ? true : false }
                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-3">
                                    Don’t show this again
                                </Text>}
                                onChange={(e)=>setFormValues({...formValues,"isShown":!formValues.isShown})}
                            />
                        </div>

                        <div className="confirm-buttons-wrap">


                            <Button type="secondary" size="large" label={'Cancel'} onClick={() => { closeModal(); }} />
                            <Button type="primary" size="large" microLoading={btnLoading} label={"Proceed"} onClick={() => { confirm(formValues.isShown); }} />



                        </div>

                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
